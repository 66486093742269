import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { __HTTPResponseType } from "../../enums/common.enum";
import { ApiService } from "../api.service";
import { SpaceDraftsShowResponse } from "../../models/responses/settings/drafts/space-draft-show.response";
import { SpaceDraft } from "../../models/new/drafts/space-draft.model";
import { SpaceDraftsGetResponse } from "../../models/responses/settings/drafts/space-draft-get.response";

@Injectable()
export class SpaceDraftsService {

  constructor(private apiService: ApiService) {
  }

  getSpaceDrafts(): Observable<SpaceDraftsGetResponse> {
    return this.apiService.get<SpaceDraftsGetResponse>("/admin/space-drafts", true);
  }

  getSpaceDraftById(draftId):Observable<SpaceDraftsShowResponse> {
    return this.apiService.get<SpaceDraftsShowResponse>("/admin/space-drafts/"+draftId,true);
  }

  createSpaceDraft(data: Partial<SpaceDraft>): Observable<SpaceDraftsShowResponse> {
    return this.apiService.post<SpaceDraftsShowResponse>("/admin/space-drafts", true, data);
  }

  updateSpaceDraft(draftId: number, data: any): Observable<SpaceDraftsShowResponse> {
    return this.apiService.put<SpaceDraftsShowResponse>("/admin/space-drafts/" + draftId, true, data);
  }

  deleteSpaceDraft(draftId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/space-drafts/" + draftId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}

