import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { BookingFull } from "../../../models/new/booking/booking-full.model";
import { ThreadFull } from "../../../models/new/inbox/thread-full";
import { ThreadRepository } from "../../../repository/thread.repository";
import { getAllReservationDeclineReasonTypes, SDEnum } from "../../../utils/utils";

@Component({
  selector: "sd-message-special-offer-popup",
  template: `
    <sd-modal-popup-layout title="Decline Reservation Request">
      <div fxLayout="column" fxLayoutGap="20px">
        <form fxLayout="column" [formGroup]="formGroup" (ngSubmit)="formGroup.valid && declineRequest()">

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <sd-select fxFlex="60%" placeholder="Reason For Declining"
                       [control]="reason" [options]="declineReasonsOptions"></sd-select>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field [color]="'accent'" fxFlex="100%">
              <textarea rows="3" matInput placeholder="Message For Guest" formControlName="guest_message"></textarea>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center"
               [class.sd-hidden]="reason.value === 'dates_not_available' || reason.value === 'waiting_for_better_reservation'">
            <mat-form-field [color]="'accent'" fxFlex="100%">
              <textarea rows="3" matInput placeholder="Message For Airbnb" formControlName="airbnb_message"></textarea>
            </mat-form-field>
          </div>

          <div fxLayoutGap="10px" fxLayoutAlign="center" fxFlexAlign="end">
            <mat-spinner color="accent" *ngIf="isUpdating" [diameter]="30" [strokeWidth]="4"></mat-spinner>
            <button mat-raised-button fxFlexAlign="end" [disabled]="isUpdating" [color]="'warn'" type="submit">
              Decline
            </button>
          </div>
        </form>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`
    hr {
      width: 100%;
    }

    mat-spinner {
      height: 30px;
      width: 30px;
    }
  `]
})
export class DeclineRequestPopupComponent implements OnInit {
  booking: BookingFull;
  thread: ThreadFull;

  formGroup: FormGroup;
  reason: FormControl;
  guestMessage: FormControl;
  airbnbMessage: FormControl;

  isUpdating = false;

  declineReasons: SDEnum[];
  declineReasonsOptions: {title: string, value: any}[];

  constructor(private threadRepo: ThreadRepository,
              @Inject(MAT_DIALOG_DATA) data: { booking: BookingFull, thread: ThreadFull },
              private dialogRef: MatDialogRef<DeclineRequestPopupComponent>) {
    this.booking = data.booking;
    this.thread = data.thread;

    this.declineReasons = getAllReservationDeclineReasonTypes();
    this.declineReasonsOptions = this.declineReasons.map(reason => {
      return {title: reason.title, value: reason.slug};
    });

    this.reason = new FormControl(this.declineReasons[0].slug, [Validators.required]);
    this.guestMessage = new FormControl(null, [Validators.required]);
    this.airbnbMessage = new FormControl(null, []);

    this.reason.valueChanges.subscribe((value) => {
      if (value === "dates_not_available" || value === "waiting_for_better_reservation") {
        this.airbnbMessage.clearValidators();
        this.airbnbMessage.setValue(null);
        this.airbnbMessage.updateValueAndValidity();
      } else {
        this.airbnbMessage.setValidators([Validators.required]);
        this.airbnbMessage.updateValueAndValidity();
      }
    });

    this.formGroup = new FormGroup({
      reason: this.reason,
      guest_message: this.guestMessage,
      airbnb_message: this.airbnbMessage,
    });
  }

  ngOnInit(): void {
    console.log("onInit sd-message-special-offer-popup");
  }

  declineRequest() {
    this.isUpdating = true;
    if (!!this.thread.booking_pal_thread_id) {
      this.threadRepo.declineReservationRequestMbp(this.thread.id, this.booking.id, this.formGroup.value).subscribe((success) => {
        this.isUpdating = false;
        this.dialogRef.close();
      }, (error) => {
        this.isUpdating = false;
      });
    } else {
      this.threadRepo.declineReservationRequest(this.thread.id, this.booking.id, this.formGroup.value).subscribe((success) => {
        this.isUpdating = false;
        this.dialogRef.close();
      }, (error) => {
        this.isUpdating = false;
      });
    }
  }
}
