import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import {
  MultiListingCreateRequest,
  MultiListingCreateSuccess,
  MultiListingDeleteRequest,
  MultiListingDeleteSuccess,
  MultiListingIndexRequest,
  MultiListingIndexSuccess,
  MultiListingUpdateRequest,
  MultiListingUpdateSuccess
} from "../actions/new/setttings/multi-listing";
import { MultiListing } from "../models/new/multi-listing.model";
import {
  getAllMultiListings,
  getIsMultiListingLoaded,
  getIsMultiListingLoading,
  getMultiListingById,
  State
} from "../reducers";
import { MultiListingGetResponse } from "../models/responses/settings/multi-listing-get.response";
import { MultiListingService } from "../services/multi-listing.service";

@Injectable()
export class MultiListingRepository {
  constructor(private store: Store<State>,
              private multiListingService: MultiListingService) {

  }

  /**
   * Loading & Loaded
   */
  getIsMultiListingLoading(): Observable<boolean> {
    return this.store.select(getIsMultiListingLoading);
  }

  getIsMultiListingLoaded(): Observable<boolean> {
    return this.store.select(getIsMultiListingLoaded);
  }

  getAllMultiListings(): Observable<MultiListing[]> {
    let loaded = false;
    this.getIsMultiListingLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loaded) {
      this.store.dispatch(new MultiListingIndexRequest());
      this.multiListingService.getMultiListings().subscribe((res: MultiListingGetResponse) => {
        this.store.dispatch(new MultiListingIndexSuccess(res.data));
      });
    }

    return this.store.select(getAllMultiListings);
  }

  getMultiListingById(multiListingId: number, force: boolean = false): Observable<MultiListing> {
    let loading = false;
    let loaded = false;
    this.getIsMultiListingLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsMultiListingLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new MultiListingIndexRequest());
      this.multiListingService.getMultiListings().subscribe((res: MultiListingGetResponse) => {
        this.store.dispatch(new MultiListingIndexSuccess(res.data));
      });
    }

    return this.store.select(state => getMultiListingById(state, multiListingId)).pipe(filter(f => !!f));
  }

  updateMultiListing(multiListingId: number, data: any): Observable<MultiListing> {
    this.store.dispatch(new MultiListingUpdateRequest(multiListingId));

    return this.multiListingService.updateMultiListing(multiListingId, data).pipe(map(res => {
      this.store.dispatch(new MultiListingUpdateSuccess(res.data));

      return res.data;
    }));
  }

  createMultiListing(data: Partial<MultiListing>): Observable<MultiListing> {
    this.store.dispatch(new MultiListingCreateRequest());
    return this.multiListingService.createMultiListing(data).pipe(map(res => {
      this.store.dispatch(new MultiListingCreateSuccess(res.data));
      return res.data;
    }));
  }

  attachListings(multiListingId: number, listingIds: number[]): Observable<MultiListing> {
    this.store.dispatch(new MultiListingUpdateRequest(multiListingId));

    return this.multiListingService.attachListing(multiListingId, listingIds).pipe(map(res => {
      this.store.dispatch(new MultiListingUpdateSuccess(res.data));

      return res.data;
    }));
  }

  deleteMultiListing(multiListingId: number): Observable<null> {
    this.store.dispatch(new MultiListingDeleteRequest(multiListingId));

    return this.multiListingService.deleteMultiListing(multiListingId).pipe(map(res => {
      this.store.dispatch(new MultiListingDeleteSuccess(multiListingId));

      return res;
    }));
  }
}
