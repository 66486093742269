import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter } from "rxjs/operators";

import { SideBarToggleAction } from "../../../actions/app";
import { UserDashboard } from "../../../enums/user.enum";
import { UserFull } from "../../../models/new/user/user-full.model";
import { UserModelUtil } from "../../../models/utils/user-model.util";
import { getAppIsMenuHidden, State } from "../../../reducers";
import { UserRepository } from "../../../repository/user-repository";

@Component({
  selector: "sd-owner-sidebar",
  template: `
    <!--<div style="min-width:265px;overflow-x: hidden !important;" class="requiredSidebarHeight">-->
    <mat-sidenav-container class="side-nav-container">

      <mat-sidenav #sidenav [mode]="mode" [opened]="open" fixedTopGap="78" fixedInViewport="true"
                   style="width:240px;" (openedChange)="checkPosition($event)">

        <!--Admin Home-->
        <!--Admin Access Only-->

        <button class="superMenuButton" mat-button *ngIf="user.is_super_admin || isOnlyDash || user?.managementContact?.data?.category === 'agent'" routerLink="/admin-home"
                routerLinkActive="superMenuActive" #rlaHome="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
            <mat-icon id="contentIcon">dashboard</mat-icon>
            <span id="contentInfo">Dashboard</span>
            <span
              id="contentIconRight">
              <mat-icon *ngIf="!this.showDashboardMenu()" matSuffix>keyboard_arrow_right</mat-icon>
              <mat-icon *ngIf="this.showDashboardMenu()" matSuffix>keyboard_arrow_down</mat-icon>
            </span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" *ngIf="isHidden" matSuffix>dashboard</mat-icon></span>
        </button>

        <!--Owner's Home-->
        <!--Owner Access Only-->

        <button class="superMenuButton" mat-button *ngIf="user.is_owner" routerLink="/home"
                routerLinkActive="superMenuActive" #rlaHome="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
            <mat-icon id="contentIcon">dashboard</mat-icon>
            <span id="contentInfo">Dashboard&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span id="contentIconRight"><mat-icon *ngIf="!isHidden" matSuffix>keyboard_arrow_right</mat-icon></span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" matSuffix *ngIf="isHidden">dashboard</mat-icon></span>
        </button>


        <!--Home Owner's Home-->
        <!--HomeOwner Access Only-->
        <button class="superMenuButton" mat-button
                *ngIf="user.type== 'management' && UserModelUtil.getManagementContact(user).category=='homeowner'"
                routerLink="/homeowner-home"
                routerLinkActive="superMenuActive" #rlaHomeOwner="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
            <mat-icon id="contentIcon">dashboard</mat-icon>
            <span id="contentInfo">Dashboard&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span
              id="contentIconRight"><mat-icon *ngIf="!isHidden" matSuffix>keyboard_arrow_right</mat-icon></span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" *ngIf="isHidden" matSuffix>schedule</mat-icon></span>
        </button>

        <!--Inbox -->
        <!--Admin Access Only-->
        <button class="superMenuButton" mat-button *ngIf="user.is_admin || user?.managementContact?.data?.category === 'agent'" routerLink="/inbox"
                routerLinkActive="superMenuActive" #rlaInbox="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
              <span id="contentIcon">
                <mat-icon>forum</mat-icon>
              </span>
            <span id="contentInfo">Inbox </span>
            <span id="contentIconRight">
                <mat-icon matSuffix>keyboard_arrow_right</mat-icon>
              </span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" matSuffix *ngIf="isHidden">forum</mat-icon></span>
        </button>
        <!--Admin Access Only-->

        <!--Leads-->
        <button class="superMenuButton" mat-button *ngIf="user.is_admin" routerLink="/leads"
                routerLinkActive="superMenuActive" #rlaLeads="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
              <span id="contentIcon">
                              <mat-icon>widgets</mat-icon>
              </span>
            <span id="contentInfo">Leads</span>
            <span id="contentIconRight">
                <mat-icon *ngIf="!this.showLeadsMenu()" matSuffix>keyboard_arrow_right</mat-icon>
                <mat-icon *ngIf="this.showLeadsMenu()" matSuffix>keyboard_arrow_down</mat-icon>
              </span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" *ngIf="isHidden" matSuffix>widgets</mat-icon></span>
        </button>


        <!--Listings-->
        <!--Both Access-->

        <button class="superMenuButton" mat-button routerLink="/listings" routerLinkActive="superMenuActive"
                #rlaListings="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
            <mat-icon id="contentIcon">view_list</mat-icon>
            <span id="contentInfo">Listings</span>
            <span
              id="contentIconRight"><mat-icon *ngIf="!rlaListings.isActive" matSuffix>keyboard_arrow_right</mat-icon>
            <mat-icon *ngIf="rlaListings.isActive" matSuffix>keyboard_arrow_down</mat-icon></span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" *ngIf="isHidden" matSuffix>view_list</mat-icon></span>
        </button>

        <!--Reservations-->
        <!--Admin Access Only-->
        <button class="superMenuButton" mat-button *ngIf="user.is_admin || user?.managementContact?.data?.category === 'agent'" routerLink="/reservations"
                routerLinkActive="superMenuActive" #rlaReservations="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
            <mat-icon id="contentIcon">schedule</mat-icon>
            <span id="contentInfo">Reservations</span>
            <span
              id="contentIconRight"><mat-icon *ngIf="!isHidden" matSuffix>keyboard_arrow_right</mat-icon></span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" *ngIf="isHidden" matSuffix>schedule</mat-icon></span>
        </button>

        <!--Multi-Calendar-->
        <!--Admin Access Only-->
        <button class="superMenuButton" mat-button *ngIf="user.is_admin || user?.managementContact?.data?.category === 'agent'" routerLink="/multi-calendar"
                routerLinkActive="superMenuActive" #rlaCalendar="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
            <mat-icon id="contentIcon">date_range</mat-icon>
            <span id="contentInfo">Multi-Calendar</span>
            <span
              id="contentIconRight"><mat-icon *ngIf="!isHidden" matSuffix>keyboard_arrow_right</mat-icon></span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" *ngIf="isHidden" matSuffix>date_range</mat-icon></span>
        </button>

        <!--Tasks-->

        <!--Owner Access Only-->


        <button class="superMenuButton" mat-button *ngIf="user.is_owner" routerLink="/owner-tasks"
                routerLinkActive="superMenuActive" #rlaTasks="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
            <mat-icon id="contentIcon">assignment</mat-icon>
            <span id="contentInfo">Tasks</span>
            <span
              id="contentIconRight"><mat-icon *ngIf="!isHidden" matSuffix>keyboard_arrow_right</mat-icon></span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" *ngIf="isHidden" matSuffix>assignment</mat-icon></span>
        </button>

        <button class="superMenuButton" mat-button *ngIf="user.is_admin || user?.managementContact?.data?.category === 'agent'" routerLink="/tasks"
                routerLinkActive="superMenuActive" #rlaTasks="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
            <mat-icon id="contentIcon">assignment</mat-icon>
            <span id="contentInfo">Tasks</span>
            <span
              id="contentIconRight"><mat-icon *ngIf="!isHidden" matSuffix>keyboard_arrow_right</mat-icon></span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" *ngIf="isHidden" matSuffix>assignment</mat-icon></span>
        </button>

        <!--Reviews-->

        <button class="superMenuButton" mat-button *ngIf="user.is_admin || user?.managementContact?.data?.category === 'agent'" routerLink="/reviews"
                routerLinkActive="superMenuActive" #rlaReviews="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
            <mat-icon id="contentIcon">rate_review</mat-icon>
            <span id="contentInfo">Reviews</span>
            <span id="contentIconRight"><mat-icon *ngIf="!isHidden" matSuffix>keyboard_arrow_right</mat-icon></span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" *ngIf="isHidden" matSuffix>rate_review</mat-icon></span>
        </button>

        <!--Contacts-->
        <!--Admin Access Only-->

        <button class="superMenuButton" mat-button *ngIf="user.is_admin || user?.managementContact?.data?.category === 'agent'" routerLink="/contacts/active"
                routerLinkActive="superMenuActive" #rlaContacts="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
            <mat-icon id="contentIcon">account_circle</mat-icon>
            <span id="contentInfo">Contacts</span>
            <span id="contentIconRight">
                <mat-icon *ngIf="!this.showContactsMenu()" matSuffix>keyboard_arrow_right</mat-icon>
                <mat-icon *ngIf="this.showContactsMenu()" matSuffix>keyboard_arrow_down</mat-icon>
              </span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" *ngIf="isHidden" matSuffix>account_circle</mat-icon></span>
        </button>

        <!--Settings-->
        <!--Admin Access Only-->

        <button class="superMenuButton" mat-button *ngIf="user.is_admin" routerLink="/settings"
                routerLinkActive="superMenuActive" #rlaSettings="routerLinkActive">
          <div [ngStyle]="{'visibility': hideMainMenu }">
            <mat-icon id="contentIcon">build</mat-icon>
            <span id="contentInfo">Settings</span>
            <span
              id="contentIconRight"><mat-icon *ngIf="!rlaSettings.isActive" matSuffix>keyboard_arrow_right</mat-icon>
            <mat-icon *ngIf="rlaSettings.isActive" matSuffix>keyboard_arrow_down</mat-icon> </span>
          </div>
          <!--Shrink Menu-->
          <span><mat-icon id="childShrinkIcon" *ngIf="isHidden" matSuffix>build</mat-icon></span>
        </button>


        <!--Leads Child Menu-->
        <div *ngIf="this.showLeadsMenu()" style="background-color: #D2D2D2;">
          <mat-button-toggle-group style="width:100%" [vertical]="true">

            <mat-button-toggle style="width: 100%;padding-left: 8%" [routerLink]="'/leads/prospects'" routerLinkActive
                               #rlaProspects="routerLinkActive"
                               [checked]="rlaProspects.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>event</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Prospects</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">event</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%;padding-left: 8%" [routerLink]="'/leads/quotes'" routerLinkActive
                               #rlaQuotes="routerLinkActive"
                               [checked]="rlaQuotes.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>note</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Quotes</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">note</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <!--Contacts Child Menu-->
        <div *ngIf="this.showContactsMenu()" style="background-color: #D2D2D2;">
          <mat-button-toggle-group style="width:100%" [vertical]="true">
            <mat-button-toggle style="width: 100%; padding-left: 8%" [routerLink]="'/contacts/active'"
                               routerLinkActive
                               #rlaActiveContacts="routerLinkActive"
                               [checked]="rlaActiveContacts.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>group</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Active</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">event</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 8%" [routerLink]="'/contacts/inactive'"
                               routerLinkActive
                               #rlaInActiveContacts="routerLinkActive"
                               [checked]="rlaInActiveContacts.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>highlight_off</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">In Active</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">note</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>


        <!--Setting Child Menu-->
        <div style="background-color: #D2D2D2;" *ngIf="showSettingsMenu()">

          <mat-button-toggle-group style="width:100%" [vertical]="true">
            <mat-button-toggle style="width: 100%; padding-left: 8%" routerLink="/settings/tools" routerLinkActive
                               #rlaSettingsTools="routerLinkActive"
                               [checked]="rlaSettingsTools.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>build</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Tools</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">build</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 8%" routerLink="/settings/integrations"
                               routerLinkActive #rlaSettingsIn="routerLinkActive"
                               [checked]="rlaSettingsIn.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>library_add</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Integrations</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">library_add</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 6%" routerLink="/settings/property-managers"
                               routerLinkActive #rlaSettingsIn="routerLinkActive"
                               [checked]="rlaSettingsIn.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="15px">
                <mat-icon>person</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Property Managers</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">person</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 8%" routerLink="/settings/channels" routerLinkActive
                               #rlaSettingsCh="routerLinkActive"
                               [checked]="rlaSettingsCh.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>theaters</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Channels</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">theaters</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 8%" routerLink="/settings/admins" routerLinkActive
                               #rlaSettingsCl="routerLinkActive"
                               [checked]="rlaSettingsCl.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>group</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Admins</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">group</mat-icon>
            </mat-button-toggle>


            <mat-button-toggle style="width: 100%; padding-left: 8%" routerLink="/settings/drafts" routerLinkActive
                               #rlaSettingsCl="routerLinkActive"
                               [checked]="rlaSettingsCl.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>drafts</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Drafts</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">group</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <!--Lisitngs Child Menu-->
        <div style="background-color: #D2D2D2;" *ngIf="rlaListings.isActive">
          <mat-button-toggle-group style="width:100%;" [vertical]="true">
            <mat-button-toggle style="width: 100%; padding-left: 8%" [routerLink]="'/listings/all'" routerLinkActive
                               #rlaListingsA="routerLinkActive"
                               [checked]="rlaListingsA.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>format_align_left</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">All</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">format_align_left</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 8%" [routerLink]="'/listings/draft'" routerLinkActive
                               #rlaListingsD="routerLinkActive"
                               [checked]="rlaListingsD.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>speaker_notes</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Drafts</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">speaker_notes</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 8%" [routerLink]="'/listings/pending'"
                               routerLinkActive #rlaListingsW="routerLinkActive"
                               [checked]="rlaListingsW.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>update</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Waiting</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">update</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 8%" [routerLink]="'/listings/accepted'"
                               routerLinkActive #rlaListingsAP="routerLinkActive"
                               [checked]="rlaListingsAP.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>done_all</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Approved</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">done_all</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 8%" [routerLink]="'/listings/rejected'"
                               routerLinkActive #rlaListingsR="routerLinkActive"
                               [checked]="rlaListingsR.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>clear</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Rejected</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">error_outline</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 8%" [routerLink]="'/listings/deactivated'"
                               routerLinkActive #rlaListingsR="routerLinkActive"
                               [checked]="rlaListingsR.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>error_outline</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Deactivated</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">error_outline</mat-icon>
            </mat-button-toggle>

          </mat-button-toggle-group>
        </div>

        <div style="background-color: #D2D2D2;" *ngIf="showDashboardMenu()">
          <mat-button-toggle-group style="width:100%;" [vertical]="true">

            <mat-button-toggle style="width: 100%; padding-left: 8%" [routerLink]="'/admin-home/net-income'"
                               routerLinkActive
                               #rlaDashboardC="routerLinkActive"
                               [checked]="rlaDashboardC.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>account_balance_wallet</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Net Income</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">format_align_left</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 8%" [routerLink]="'/admin-home/cleaning'"
                               *ngIf="!isOnlyDash"
                               routerLinkActive
                               #rlaDashboardA="routerLinkActive"
                               [checked]="rlaDashboardA.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>delete</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Cleaning</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">format_align_left</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 8%" [routerLink]="'/admin-home/commission'"
                               *ngIf="!isOnlyDash"
                               routerLinkActive
                               #rlaDashboardB="routerLinkActive"
                               [checked]="rlaDashboardB.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>account_balance</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Commission</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">format_align_left</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle style="width: 100%; padding-left: 8%" [routerLink]="'/admin-home/owner-income'"
                               *ngIf="!isOnlyDash"
                               routerLinkActive
                               #rlaDashboardB="routerLinkActive"
                               [checked]="rlaDashboardB.isActive">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                <mat-icon>account_balance_wallet</mat-icon>
                <div style="margin-left: 45px">
                  <span id="childContentInfo">Owner Income</span>
                </div>
              </div>
              <mat-icon *ngIf="isHidden" id="childShrinkIcon">format_align_left</mat-icon>
            </mat-button-toggle>

          </mat-button-toggle-group>
        </div>


        <div class="sidebarAutoScroll"></div>
        <!--should always be above </mat-sidenav> -->

      </mat-sidenav>

      <mat-sidenav-content>
        <ng-content></ng-content>
      </mat-sidenav-content>

    </mat-sidenav-container>
    <!--</div>-->
  `,
  styles: [`


    #mat-button-toggle-1 > label > div > div {
      color: #CFC6A4 !important;
    }

    .side-nav-container {
      height: 100%;
      overflow-x: hidden !important;
      min-height: 100vh !important;
      min-width: 100vw !important;
    }

    .sidebarAutoScroll {
      padding-bottom: 80px;
    }

    span {
      color: #ffffff !important;
      letter-spacing: 1.5px !important;
      font-weight: 600 !important;
      font-family: 'Montserrat', sans-serif !important;
    }

    #contentIcon {
      color: #ffffff;
      margin-top: 2%;
      margin-left: 5%;
      position: absolute !important;
    }

    #contentIconRight {
      margin-top: 2.5%;
      right: 5%;
      position: absolute !important;
    }

    #contentInfo {
      padding-left: 26%;
    }

    #contentChildIcon {
      display: inline;
      width: 30px;
      height: fit-content;
      margin-top: 2%;
      position: absolute;
    }

    #childContentInfo {
      /*float: right;*/
      /*margin-left: 28%;*/
      color: #413f40 !important;
      font-family: 'Montserrat', sans-serif !important;
    }

    .superMenuActive {
      background-color: #1e507b;
    }

    .menuActive {
      background-color: #ffff00;
    }

    #childShrinkIcon {
      /*color: #ffffff;*/
      position: absolute;
      top: 30%;
      right: 7.5%;
    }

    .superMenuButton {
      width: 100% !important;
      text-align: left !important;
      font-size: 16px !important;
      padding: 8px !important;
    }

    .superMenuButton:hover {
      background-color: #1e507b !important;
    }

  `]
})
export class OwnerSidebarComponent implements OnInit, OnDestroy {

  user: UserFull;
  isOnlyDash = false;
  isHidden: boolean;
  open: string;
  hideMainMenu = "visible";
  menuWidth: number;
  isAlive = true;
  UserModelUtil = UserModelUtil;
  mode = "side";

  constructor(private store: Store<State>,
              private userRepo: UserRepository,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private ngZone: NgZone
  ) {
    this.changeMode();
    window.onresize = (e) => {
      this.ngZone.run(() => {
        this.changeMode();
      });
    };
  }

  ngOnInit() {

    this.userRepo.getUser().pipe(filter(f => !!f)).subscribe((user) => {
      this.user = user;
      this.isOnlyDash = user.role === UserDashboard.DASHBOARD_ONLY;
    });
    this.store.select(getAppIsMenuHidden).subscribe((value) => {
      if (value) {
        this.open = "false";
      } else {
        this.open = "true";
      }
      this.changeMode();
    });

    // if (window.innerWidth <= 800 && window.innerHeight <= 600 && !this.isHidden) {
    //   this.store.dispatch(new MenuHiddenChangeAction());
    // }
  }

  showSettingsMenu() {
    return this.router.url.toString().includes("settings");
  }

  showLeadsMenu() {
    return this.router.url.toString().includes("leads");
  }

  showDashboardMenu() {
    return this.router.url.toString().includes("admin-home");
  }

  showContactsMenu() {
    return this.router.url.toString().includes("contacts");
  }

  changeMode() {
    this.menuWidth = window.innerWidth;
    if (this.menuWidth <= 800) {
      this.mode = "over";
    }
    if (this.menuWidth > 800) {
      this.mode = "side";
    }
  }

  checkPosition(event) {
    console.log("is hidden", !event);
    this.store.dispatch(new SideBarToggleAction(!event));
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  // // TODO Shift in service or repo
  // checkIfCurrentInboxSubmenu(slug: string) {
  //   return slug === localStorage.getItem("inbox_filter");
  // }

}
