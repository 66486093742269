import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as $ from "jquery";

import { AppModule } from "./app/app.module";
import { hubspotId } from "./app/modules/intercom/intercom.utils";
import { environment } from "./environments/environment";

(window as any).jQuery = $;
(window as any).$ = $;

if (environment.production) {
  enableProdMode();
}

const script = document.createElement("script");
script.async = true;
script.defer = true;
script.src = "https://maps.googleapis.com/maps/api/js?key=" + environment.GoogleMaps.apiKey + "&libraries=places";
document.head.appendChild(script);

const scriptElem = document.createElement("script");
scriptElem.type = "text/javascript";
// scriptElem.async = true;
// scriptElem.defer = true;
scriptElem.id = "hs-script-loader";
scriptElem.src = `//js.hs-scripts.com/${hubspotId}.js`;
document.head.appendChild(scriptElem);

platformBrowserDynamic().bootstrapModule(AppModule);
