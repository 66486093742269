import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Comment } from "../../../../models/new/tasks/comment";
import { TaskRepository } from "../../../../repository/task.repository";

@Component({
  selector: "sd-comment-delete",
  template: `
    <sd-modal-popup-layout title="Delete Confirmation Popup">
      <div fxLayout="column" fxLayoutGap="30px">
        <h4><b>Are you sure you want to delete this comment?</b></h4>
        <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px">
          <button mat-raised-button color="accent" (click)="delete()">Delete</button>
          <button mat-raised-button color="warn" (click)="cancel()">Cancel</button>
        </div>
      </div>
    </sd-modal-popup-layout>

  `,
  styles: [``]
})

export class DeleteCommentConfirmationPopupComponent {
  comment: Comment;
  taskId: number;

  constructor(private taskRepo: TaskRepository, private dialogRef: MatDialogRef<DeleteCommentConfirmationPopupComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    this.comment = data.comment;
    this.taskId = data.taskId;
  }

  delete() {
    this.taskRepo.deleteComment(this.taskId, this.comment.id).subscribe(res => {
      this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
