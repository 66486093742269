import { AfterViewInit, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { AutoTask } from "../../../models/auto-task";
import { AutoTaskCompact } from "../../../models/new/tasks/auto-task-compact.model";
import { AutoTaskRepository } from "../../../repository/auto-task.repository";
import { AppService } from "../../../services/app.service";

@Component({
  selector: "sd-setting-auto-tasks", template: `
    <div fxLayout="column" fxLayoutGap="30px" style="margin-top: 10px; margin-bottom: 50px" fxFlex="100%">

      <div fxLayoutAlign="start center" *ngIf="!listingId">
        <button mat-raised-button color="primary" (click)="openTools()">
          <mat-icon>arrow_back</mat-icon>
          Back
        </button>
      </div>

      <div fxLayout.lt-sm="column" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center">
        <div fxLayoutAlign.lt-sm="space-between center" fxLayout="column">
          <span class="heading">Auto Tasks</span>
          <span class="hint responsiveAlign">You can create tasks based on actions.</span>
        </div>
        <div>
          <button mat-icon-button (click)="refresh()">
            <mat-icon>autorenew</mat-icon>
          </button>
          <button mat-raised-button color="accent" (click)="createAutoTask()">Create Auto Task</button>
        </div>
      </div>

      <div id="spinner" *ngIf="autoTasksLoading" fxLayout="row" fxLayoutAlign="center center" fxFlex="100%">
        <mat-spinner color="accent" [diameter]="60" [strokeWidth]="6"></mat-spinner>
      </div>

      <div fxLayout="column" fxLayoutGap="20px" *ngIf="autoTasksLoaded">
        <h3 style="text-align: center" *ngIf="autoTasks.length === 0">
          No Auto Tasks Found
        </h3>
        <mat-card *ngFor="let autoTask of autoTasks" fxLayout="column" fxLayoutGap="5px" class="padding"
                  style="cursor: pointer" (click)="editAutoTask(autoTask)">
          <h3>{{ autoTask.title }}</h3>
          <span class="content">{{ autoTask.description }}</span>
        </mat-card>
      </div>
    </div>
  `, styles: [`


    .content {
      font-size: 10px;
    }

    .heading {
      font-weight: bolder;
      font-size: 22px;
    }

    .content {
      font-size: 12px;
      line-height: 130%;
    }

    .padding {
      padding: -10px -10px -10px -10px;
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    .mat-card {
      border: 1px solid lightgrey !important;
      box-shadow: none !important;
    }

    h3 {
      font-weight: bolder !important;
      letter-spacing: 1px !important;
      font-size: 20px !important;
      font-family: 'Montserrat', sans-serif !important;
    }

    .hint {
      font-size: 12px;
      margin-left: 2px;
    }

    #spinner {
      position: fixed;
      top: 45%;
      right: 40%
    }

    @media only screen and (max-width: 600px) {
      .responsiveAlign {
        text-align: center;
      }
    }


  `]
})
export class SettingsAutoTasksComponent implements OnInit, AfterViewInit, OnDestroy {
  dialogRef: MatDialogRef<any>;
  @Input() listingId: number | null;
  autoTasksLoading: boolean;
  autoTasksLoaded: boolean;
  autoTasks: AutoTaskCompact[] = [];
  private destroyed$ = new Subject();

  constructor(private dialog: MatDialog,
              private router: Router,
              private autoTaskRepo: AutoTaskRepository,
              private appService: AppService) {
  }

  ngOnInit() {
    this.setupTasks();
  }

  ngAfterViewInit() {
    this.appService.changeAppTitle("Settings - Auto-Tasks");
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  editAutoTask(autoTask: AutoTask) {
    this.router.navigate(["/settings/tools/auto-tasks/" + autoTask.id,
      {
        listingId: this.listingId
      }]
    );
  }

  createAutoTask() {
    this.router.navigate(["/settings/tools/auto-tasks/create",
      {
        listingId: this.listingId
      }
    ]);
  }

  openTools() {
    this.router.navigate(["/settings/tools"]);
  }

  refresh() {
    this.autoTaskRepo.getAllAutoTasks(true, this.listingId).pipe(takeUntil(this.destroyed$)).subscribe();
  }

  private setupTasks() {
    this.autoTaskRepo.getIsAutoTaskLoading().pipe(takeUntil(this.destroyed$)).subscribe(l => this.autoTasksLoading = l);
    this.autoTaskRepo.getIsAutoTaskLoaded().pipe(takeUntil(this.destroyed$)).subscribe(l => this.autoTasksLoaded = l);
    this.autoTaskRepo.getAllAutoTasks(false, this.listingId).subscribe(t => this.autoTasks = t);
  }
}
