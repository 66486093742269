import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import Quill from "quill";
import "quill-mention";
import { filter } from "rxjs/operators";

import { EditorPopupComponent } from "./editor-popup";
import { QuillUtils } from "../../../utils/quill.util";

@Component({
  selector: "sd-email-box",
  template: `
    <div fxLayout="column" fxLayoutGap="5px">


      <div class="textareaContainer" fxLayout="row" fxLayoutGap="4px">
        <input [(ngModel)]="subjectModel" disabled (ngModelChange)="subjectValueChange()" class="ta5 main" type="text"
               placeholder="Subject">
        <button mat-button matSuffix mat-icon-button fxFlexAlign="end" (click)="openFullScreen()">
          <mat-icon>fullscreen</mat-icon>
        </button>

      </div>
      <div fxLayout="column">


        <!--<div class="padding-both ta5">-->
        <!--<div fxLayoutAlign="center" fxLayoutGap="5px">-->
        <!--<mat-icon>format_bold</mat-icon>-->
        <!--<mat-icon>format_italic</mat-icon>-->
        <!--<mat-icon>strikethrough_s</mat-icon>-->
        <!--<span class="separator">|</span>-->
        <!--<mat-icon>format_list_bulleted</mat-icon>-->
        <!--<mat-icon>format_list_numbered</mat-icon>-->
        <!--<mat-icon>format_quote</mat-icon>-->
        <!--<span class="separator">|</span>-->
        <!--<mat-icon>link</mat-icon>-->
        <!--<mat-icon>link</mat-icon>-->
        <!--<mat-icon>flag</mat-icon>-->
        <!--<span class="separator">|</span>-->
        <!--<mat-icon>zoom_out_map</mat-icon>-->
        <!--</div>-->
        <!--<div fxLayoutAlign=" center" fxLayoutGap="5px">-->
        <!--<mat-select placeholder="Format">-->
        <!--<mat-option *ngFor="let selectOption of selectOptions" [value]="selectOption">-->
        <!--{{ selectOption.title }}-->
        <!--</mat-option>-->
        <!--</mat-select>-->
        <!--<span class="separator">|</span>-->
        <!--<mat-icon>content_paste</mat-icon>-->
        <!--<mat-icon>content_paste</mat-icon>-->
        <!--<mat-icon>format_clear</mat-icon>-->

        <!--<span class="separator">|</span>-->
        <!--<mat-icon>panorama</mat-icon>-->
        <!--<mat-icon>grid_on</mat-icon>-->
        <!--<mat-icon>flag</mat-icon>-->

        <!--<span class="separator">|</span>-->
        <!--<mat-icon>format_indent_decrease</mat-icon>-->
        <!--<mat-icon>format_indent_increase</mat-icon>-->

        <!--<span class="separator">|</span>-->
        <!--<mat-icon>reply</mat-icon>-->
        <!--<mat-icon>forward</mat-icon>-->

        <!--<span class="separator">|</span>-->
        <!--<mat-icon>description</mat-icon>-->
        <!--<span>Source</span>-->

        <!--</div>-->
        <!--</div>-->

        <div class="email-body" #emailBody></div>

        <!--<quill-editor style="min-height: 20%" [(ngModel)]="textModel" [modules]="modules" theme="snow"-->
        <!--(ngModelChange)="valuechange($event)">-->
        <!--</quill-editor>-->

      </div>
    </div>
  `,
  styles: [`
    .main {
      width: 92%;
    }

    .padding-both {
      padding-left: 10px;
      padding-right: 10px;
    }

    .separator {
      font-size: 20px;
    }

    .ta5 {
      border: 1.4px solid #765942 !important;
      border-radius: 3px !important;
      font-size: x-small;

    }

    .textareaContainer {
      padding: 0px 0px 5px 5px;
      font-size: x-small;
      height: auto;

    }

    .email-body {
      content-editable: true;
      width: 100%;
      min-height: 20%;
      height: 150px;
    }

    input[type=text], textarea {
      -webkit-transition: all 0.30s ease-in-out;
      -moz-transition: all 0.30s ease-in-out;
      -ms-transition: all 0.30s ease-in-out;
      -o-transition: all 0.30s ease-in-out;
      outline: none;
      padding: 5px 0px 5px 5px;
      margin: 5px 1px 3px 0px;
      border: 1px solid #DDDDDD;
      font-family: 'Roboto', sans-serif;
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      letter-spacing: 2px;
      color: #333333;
    }

    ::-moz-placeholder { /* Firefox 19+ */
      letter-spacing: 2px;
      color: #333333;
    }

    :-ms-input-placeholder { /* IE 10+ */
      letter-spacing: 2px;
      color: #333333;
    }

    :-moz-placeholder { /* Firefox 18- */
      letter-spacing: 2px;
      color: #333333;
    }

    [placeholder]:focus::-webkit-input-placeholder {
      transition: text-indent 0.5s 0.5s ease;
      text-indent: -100%;
      opacity: 1;
    }

    input[type=text]:focus, textarea:focus {
      box-shadow: 0 0 5px rgba(81, 203, 238, 1);
      padding: 5px 0px 5px 5px;
      margin: 5px 1px 3px 0px;
      border: 1px solid rgba(81, 203, 238, 1);
      font-family: 'Roboto', sans-serif;
    }
  `],
})

export class EmailBoxComponent implements OnInit {

  @Output() text = new EventEmitter<string>();
  @Output() subject = new EventEmitter<string>();
  @Input() subjectModel = "New message!";
  @ViewChild("emailBody", {read: ElementRef, static: true}) body: ElementRef;
  quillBody: Quill;
  titleVariables = QuillUtils.VARIABLES;
  private content;

  constructor(private dialog: MatDialog) {

  }

  @Input() set textModel(value) {
    this.content = value;
    if (this.quillBody) {
      this.quillBody.setText(this.content);

    }
  }

  ngOnInit(): void {

    const values = this.titleVariables;
    this.quillBody = new Quill(this.body.nativeElement, {
      theme: "snow",
      modules: {
        mention: {
          allowedChars: /^[_A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["{"],
          isolatedCharacter: false,
          source: function(searchTerm, renderList, mentionChar) {

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(values[i]);
                }
              }
              renderList(matches, searchTerm);
            }
          },
          renderItem: function(item, searchTerm) {
            return item.value.replace("{", "{{");
          },
          dataAttributes: ["value"]
        },
        toolbar: [
          ["bold", "italic", "underline", "strike"],        // toggled buttons
          ["blockquote", "code-block"],

          // [{ "header": 1 }, { "header": 2 }],               // custom button values
          // [{ "list": "ordered" }, { "list": "bullet" }],
          // [{ "indent": "-1" }, { "indent": "+1" }],          // outdent/indent

          // [{ "size": ["small", false, "large", "huge"] }],  // custom dropdown
          // [{ "header": [1, 2, 3, 4, 5, 6, false] }],

          [{color: []}, {background: []}],          // dropdown with defaults from theme
          [{font: []}],
          [{align: []}],
          ["clean"],
          ["image"]
        ]
      },
    });
    this.quillBody.on("text-change", () => {
      this.text.emit(this.quillBody.root.innerHTML);
      this.content = this.quillBody.root.innerHTML;
    });

    this.quillBody.setText(this.content);

  }

  openFullScreen() {
    const ref = this.dialog.open(EditorPopupComponent, {
      data: this.content
    });
    ref.updateSize("100%", "100%");

    ref.afterClosed().pipe(filter(t => !!t)).subscribe((data: { text: string }) => {
      this.quillBody.root.innerHTML = data.text;
    });
  }

  subjectValueChange() {
    this.subject.emit(this.subjectModel);
  }
}
