import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { AutoResponseGetResponse } from "../models/responses/auto-response-get.response";
import { AutoResponseShowResponse } from "../models/responses/auto-response-show.response";
import { Injectable } from "@angular/core";
import { AutoResponse } from "../models/new/automation/auto-response.model";
import { __HTTPResponseType } from "../enums/common.enum";

@Injectable()
export class AutoResponseService {

  constructor(private apiService: ApiService) {
  }

  getAutoResponses(): Observable<AutoResponseGetResponse> {
    return this.apiService.get<AutoResponseGetResponse>("/admin/auto-responses", true);
  }

  showAutoResponse(autoResponseId: number): Observable<AutoResponseShowResponse> {
    return this.apiService.get<AutoResponseShowResponse>("/admin/auto-responses/" + autoResponseId, true);
  }

  createAutoResponse(data: Partial<AutoResponse>): Observable<AutoResponseShowResponse> {
    return this.apiService.post<AutoResponseShowResponse>("/admin/auto-responses", true, data);
  }

  updateAutoResponse(autoResponseId: number, data: Partial<AutoResponse>): Observable<AutoResponseShowResponse> {
    return this.apiService.put<AutoResponseShowResponse>("/admin/auto-responses/" + autoResponseId, true, data);
  }

  deleteAutoResponse(autoResponseId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/auto-responses/" + autoResponseId, true,
      null, null, true, __HTTPResponseType.TEXT);
  }
}
