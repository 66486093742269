/**
 * Created by aditya on 18/9/17.
 */

import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { addDays } from "date-fns";
import { debounceTime, distinctUntilChanged, takeWhile } from "rxjs/operators";

import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { ListingRepository } from "../../../repository/listing.repository";
import { StayDuvetService } from "../../../services/stayduvet";
import { getDateObj, getSDDayObject, SDDay } from "../../../utils/calendar-utils";

import { NewReservationPopupComponent } from "./new-reservation-popup";

@Component({
  selector: "sd-new-reservation-details-popup",
  template: `
    <sd-modal-popup-layout title=" Create New Reservation">
      <div fxLayout="column" fxLayoutGap="12px">
        <form fxLayout="column" fxLayoutGap="20px" [formGroup]="formGroup"
              (ngSubmit)="formGroup.valid && saveButtonCLicked()">
          <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column" fxLayoutGap="5px" fxLayout="row">
            <mat-form-field [color]="'accent'" class="width-18">
              <input matInput [matDatepicker]="startPicker" placeholder="Start Date"
                     formControlName='start_date'>
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
            <mat-icon>arrow_forward</mat-icon>
            <mat-form-field [color]="'accent'" class="width-18">
              <input matInput [matDatepicker]="endPicker" [min]="startDate" placeholder="End Date"
                     formControlName='end_date'>
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field [color]="'accent'" class="width-10">
              <input matInput placeholder="No of Guests"
                     min="1"
                     type="number"
                     formControlName='no_of_guests'>
              <mat-error>Required</mat-error>
            </mat-form-field>
            <mat-form-field [color]="'accent'" class="width-10">
              <input matInput placeholder="No of Pets"
                     min="0"
                     type="number"
                     formControlName='no_of_pets'>
              <mat-error>Required</mat-error>
            </mat-form-field>
            <sd-select class="width-20" *ngIf="!fromCalendar" error="Required"
                       placeholder="Select Listing" [disable]="fromListing"
                       [control]="listing" [options]="listingsOptions"></sd-select>

            <mat-checkbox [formControl]="minStayControl">Override Min Stay</mat-checkbox>

            <button mat-raised-button *ngIf="!(confirmationShow)&&!isSaving"
                    [disabled]="!formGroup.valid || !isDateValid()"
                    color="accent" type="submit">
              Continue
            </button>
            <mat-spinner color="accent" *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          </div>
          <div fxLayout="row" fxLayoutAlign="end start">
            <mat-error style="font-size: x-small;">{{error}}</mat-error>
          </div>
        </form>
        <div *ngIf="listing.value" style="width:30%;">
          <sd-small-calendar [showArrowButtons]="showArrowButtons" [currentMonth]="currentMonth"
                             [listingId]="listing.value.id"></sd-small-calendar>
        </div>
        <div fxLayout="row" *ngIf="confirmationShow">
          <p style="color: red">Are you
            sure you want to create a reservation in past??</p>
          <span fxFlex="1 1 auto"></span>
          <div>
            <button style="margin: 0px 10px" (click)="okClicked()" mat-raised-button color="accent">Yes</button>
            <button style="margin: 0px 10px" (click)="noClicked()" mat-raised-button color="warn">No</button>
          </div>
        </div>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`
    hr {
      width: 100%;
    }

    mat-spinner {
      height: 30px;
      width: 30px;
    }

    .width-18 {
      width: 18%;
    }

    .width-20 {
      width: 20%;
    }

    .width-10 {
      width: 8%;
    }

    @media only screen and (max-width: 600px) {

      [class*="width-"] {
        width: 95%;
      }

      .responsiveAlign {
        text-align: center;
      }

      .responsiveWidth {
        width: 95%;
      }
    }

  `]
})
export class CreateReservationPopupComponent implements OnInit, OnDestroy {

  confirmationShow = false;

  formGroup: FormGroup;
  startDate: FormControl;
  endDate: FormControl;
  no_of_guests: FormControl;
  no_of_pets: FormControl;
  listing: FormControl;
  minStayControl: FormControl;

  listings: ListingCompact[] = [];
  listingsOptions: {title: string, value: any}[];

  isSaving = false;
  isAlive = true;

  minEndDate: FormControl;

  currentDate: Date;
  error = "";

  dialogRef: MatDialogRef<any>;
  fromCalendar = false;
  showArrowButtons = false;
  listingId: number;
  startingDate: Date;
  endingDate: Date;
  inPast = false;
  currentMonth: SDDay;

  fromListing = false;

  constructor(private service: StayDuvetService,
              private listingRepo: ListingRepository,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    if (data) {
      if (Object.keys(data).includes("from_calendar")) {
        this.fromCalendar = data.from_calendar;
        this.listingId = data.listing_id;
        this.startingDate = data.start_date;
        this.endingDate = data.end_date;
      } else if (Object.keys(data).includes("from_listing")) {
        this.fromListing = data.from_listing;
        this.listingId = data.listing_id;
      }
    }

    console.log("data", data);

    this.currentDate = getDateObj();

    this.startDate = new FormControl(this.fromCalendar ? this.startingDate : this.currentDate, [Validators.required]);
    this.endDate = new FormControl(this.fromCalendar ? this.endingDate : addDays(this.currentDate, 1), [Validators.required]);
    this.no_of_guests = new FormControl(1, [Validators.required]);
    this.no_of_pets = new FormControl(0, [Validators.required]);
    this.listing = new FormControl(null, [Validators.required]);
    this.minStayControl = new FormControl(false);
    if (data) {
      this.listingRepo.getCompactListingById(this.listingId).subscribe(res => {
        const listing = res as ListingCompact;
        this.listing.patchValue(listing);
      });
    }

    this.formGroup = new FormGroup({
      start_date: this.startDate,
      end_date: this.endDate,
      no_of_guests: this.no_of_guests,
      no_of_pets: this.no_of_pets,
      listing: this.listing,
      override_min_stay: this.minStayControl,
    });

    this.startDate.valueChanges.subscribe(value => {
      this.currentMonth = getSDDayObject(value);
      this.endDate.patchValue(addDays(value, 1));
    });

    this.currentMonth = getSDDayObject(this.startDate.value);

  }

  ngOnInit() {
    this.listingRepo.getAcceptedListings().pipe(takeWhile(() => this.isAlive)).subscribe(listings => {
      this.listings = listings;
      this.listingsOptions = this.listings.map(listing => {
        return {title: listing.title, value: listing};
      });
    });
  }

  saveButtonCLicked() {
    if (this.startDate.value.setHours(0, 0, 0, 0) < this.currentDate.setHours(0, 0, 0, 0)) {
      this.confirmationShow = true;
    } else {
      this.error = "";
      this.isSaving = true;
      const data: any = {
        listing: this.listing.value,
        check_in: this.startDate.value,
        check_out: this.endDate.value,
        guest_count: this.no_of_guests.value,
        pet_count: this.no_of_pets.value,
        override_min_stay: this.minStayControl.value,
      };

      const availabilityData: any = {
        start: this.startDate.value,
        end: this.endDate.value,
        number_of_guests: this.no_of_guests.value,
        number_of_pets: this.no_of_pets.value,
        override_min_stay: this.minStayControl.value,
      };

      if (this.fromCalendar) {
        availabilityData.force_book = true;
        data.force_book = true;
      }

      this.service.getBookingAvailability(this.listing.value.id, availabilityData).subscribe(res => {
          if (res.is_available) {
            this.dialog.closeAll();
            this.isSaving = false;
            this.dialogRef = this.dialog.open(NewReservationPopupComponent, {
              data: data
            });
            this.dialogRef.updateSize("100%", "100%");
          } else {
            this.isSaving = false;
            this.error = res.reason;
          }
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  okClicked() {
    this.error = "";
    this.isSaving = true;
    const data = {
      listing: this.listing.value,
      check_in: this.startDate.value,
      check_out: this.endDate.value,
      guest_count: this.no_of_guests.value,
      pet_count: this.no_of_pets.value,
      override_min_stay: this.minStayControl.value,
    };

    this.service.getBookingAvailability(this.listing.value.id, {
      start: this.startDate.value,
      end: this.endDate.value,
      number_of_guests: this.no_of_guests.value,
      number_of_pets: this.no_of_pets.value,
      override_min_stay: this.minStayControl.value,
    }).subscribe(res => {
        if (res.is_available) {
          this.dialog.closeAll();
          this.isSaving = false;
          this.dialogRef = this.dialog.open(NewReservationPopupComponent, {
            data: data
          });
          this.dialogRef.updateSize("100%", "100%");
        } else {
          this.isSaving = false;
          this.error = res.reason;
        }
      }
    );
  }

  noClicked() {
    this.confirmationShow = false;
  }

  isDateValid() {
    return this.startDate.value <= this.endDate.value;
  }
}
