import { Component, Input, OnDestroy, OnInit, ViewChildren } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import Quill from "quill";
import "quill-mention";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, takeUntil } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { AutoMessageOffsetReference } from "../../../enums/auto-message.enum";
import {
  AutoTaskAssigneeType,
  AutoTaskEnumHelper,
  AutoTaskExpenseType,
  AutoTaskOffsetReference, AutoTaskSources
} from "../../../enums/auto-task.enum";
import { AutomationOffsetPosition, AutomationRule, CommonEnumHelpers } from "../../../enums/common.enum";
import { TaskCategory, TaskEnumHelper, TaskPaymentBy } from "../../../enums/task.enum";
import { Booking } from "../../../models/booking";
import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { AutoTaskExpense } from "../../../models/new/tasks/auto-task-expense.model";
import { AutoTaskFull } from "../../../models/new/tasks/auto-task-full.model";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { UserModelUtil } from "../../../models/utils/user-model.util";
import { AutoTaskRepository } from "../../../repository/auto-task.repository";
import { ListingRepository } from "../../../repository/listing.repository";
import { OptionsRepository } from "../../../repository/options.repository";
import { CommonUtil } from "../../../utils/common.util";
import { QuillUtils } from "../../../utils/quill.util";
import { ItemDescriptionPopupComponent } from "../../tasks/components/popups/item-description-popup";
import { TaskNoteUpdatePopupComponent } from "../../tasks/components/popups/task-note-update-popup.component";

@Component({
  selector: "sd-settings-auto-task",
  template: `
      <sd-center-spinner *ngIf="isLoading && !autoTaskNotExist"></sd-center-spinner>
      <div *ngIf="!isLoading && !autoTaskNotExist" fxLayout="column">
          <mat-toolbar class="fixed-header" color="accent">
              <div>
                  <h2>{{headerTitle}}</h2>
              </div>
              <span class="spacer"></span>
              <div style="width: fit-content;" fxLayout="row" fxFlexAlign="flex-start center">
                  <button matTooltip="Print" mat-icon-button (click)="print()">
                      <mat-icon>print</mat-icon>
                  </button>
                  <button mat-icon-button (click)="close()">
                      <mat-icon>close</mat-icon>
                  </button>
              </div>
          </mat-toolbar>
          <div style="padding: 2%; margin-top: 70px" fxLayout="column" [formGroup]="formGroup">
              <div fxLayout="column" fxLayoutGap="15px" style="width: 100%">
                  <mat-form-field [color]="'accent'">
                      <input matInput placeholder="Title" formControlName='title'>
                      <mat-error *ngIf="titleError">Title is required.</mat-error>
                  </mat-form-field>

                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field [color]="'accent'" class="width50">
                        <mat-select placeholder="Listing" formControlName='property_ids'
                          (selectionChange)="selectedValueChanged()"
                          (openedChange)="!$event&&onMatSelectClose()"
                          multiple>

                              <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                                  <input matInput placeholder="Search Listing" [formControl]="listingFilter">
                              </mat-form-field>

                              <div fxLayout="column">
                                  <button class="select-button" mat-button (click)="onSelectAll()">Select All</button>
                                  <button class="select-button" mat-button (click)="onSelectNone()">Select None</button>
                              </div>
                              <mat-option *ngFor="let listing of filteredListings" [value]="listing.id">
                                  {{ listing.title }}
                              </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field [color]="'accent'" style="width: 300px;">
                      <mat-select
                        color="accent"
                        floatPlaceholder="never"
                        formControlName="listing_rule"
                        placeholder="Apply For Listing">
                        <mat-option *ngFor="let automationRule of automationRules" [value]="automationRule">
                          {{automationRule}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                <div  fxLayout="row" fxLayoutAlign="space-between center">
                  <mat-form-field [color]="'accent'" class="width50">
                    <mat-select
                      color="accent"
                      multiple
                      floatPlaceholder="never"
                      formControlName="source"
                      placeholder="Source">
                      <mat-option *ngFor="let source of sources" [value]="source">
                        {{AutoTaskEnumHelper.getAutoTaskSourceTitle(source)}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field [color]="'accent'" style="width: 300px;">
                    <mat-select
                      color="accent"
                      floatPlaceholder="never"
                      formControlName="source_rule"
                      placeholder="Apply For Source">
                      <mat-option *ngFor="let automationRule of automationRules" [value]="automationRule">
                        {{automationRule}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>


                  <div fxLayout="row" fxLayoutAlign="space-between center">


                      <mat-form-field [color]="'accent'" class="width50">
                          <mat-select placeholder="Who Will Pay?*" formControlName='payment_by'>
                              <mat-option *ngFor="let paymentByOption of paymentByOptions" [value]="paymentByOption">
                                  {{TaskEnumHelper.getTaskPaymentByTitle(paymentByOption)}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>


                      <mat-form-field [color]="'accent'" class="width50">
                          <mat-select placeholder="Category*" formControlName='type'>
                              <mat-option *ngFor="let category of categories" [value]="category">
                                  {{ TaskEnumHelper.getTaskCategoryTitle(category) }}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>

          <div fxLayout="column" style="width: 100%">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <mat-form-field [color]="'accent'" class="width50">
                <mat-select (selectionChange)="disablerFunction()"
                            [disabled]="!!assigneeID.value"
                            placeholder="Assign To Assignee Type*"
                            formControlName='assignee_type'
                            #assigneeTypeForm>
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let assignee of assigneeTypes" [value]="assignee">
                    {{AutoTaskEnumHelper.getAutoTaskAssigneeType(assignee)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field [color]="'accent'" class="width50">
                <mat-select (selectionChange)="disablerFunction()"
                            [disabled]="!!assigneeType.value"
                            placeholder="Assignee To Assignee*"
                            formControlName='assignee_id'
                            #assigneeIdForm>
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let assignee of assignees" [value]="assignee.id">
                    {{ UserModelUtil.getFullName(assignee) }}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>

                      <div style="display: inline-block ; text-align: center ; color: red"
                           *ngIf="showMatErrorForIDandType">
                          <p>Either Assignee type or Assignee ID needs to be selected</p>
                      </div>
                  </div>

                  <div fxLayout="row" style="transform: translateY(20px)">
                      <p style="font-size: 18px ; font-weight: bold">Directions</p>
                      <span fxFlex="1 1 auto"></span>
                      <button type="button" mat-button matSuffix mat-icon-button (click)="openDescriptionPopup()">
                          <mat-icon>fullscreen</mat-icon>
                      </button>
                  </div>
                  <div #messageTextArea id="descriptionMessage" class="textArea">
                  </div>

                  <div fxLayout="column">
                      <div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                          <span>Must be complete by:</span>
                          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                               style="width: 100%">
                              <mat-form-field [color]="'accent'" class="width-24">
                                  <mat-select placeholder="Hours"
                                              [(ngModel)]="hourSelected"
                                              [ngModelOptions]="{standalone: true}">
                                      <mat-option [value]="0">0 hour</mat-option>
                                      <mat-option [value]="1">1 hour</mat-option>
                                      <mat-option [value]="2">2 hours</mat-option>
                                      <mat-option [value]="3">3 hours</mat-option>
                                      <mat-option [value]="6">6 hours</mat-option>
                                      <mat-option [value]="12">12 hours</mat-option>
                                      <mat-option [value]="24">24 hours</mat-option>
                                  </mat-select>
                              </mat-form-field>
                              <mat-form-field [color]="'accent'" class="width-24">
                                  <mat-select placeholder="Minutes"
                                              [(ngModel)]="minuteSelected"
                                              [ngModelOptions]="{standalone: true}">
                                      <mat-option [value]="00">00 minutes</mat-option>
                                      <mat-option [value]="15">15 minutes</mat-option>
                                      <mat-option [value]="30">30 minutes</mat-option>
                                      <mat-option [value]="45">45 minutes</mat-option>
                                  </mat-select>
                              </mat-form-field>
                              <mat-form-field [color]="'accent'" class="width-24">
                                  <mat-select placeholder="Position"
                                              [(ngModel)]="offset_position"
                                              [disabled]="offset_reference === AutoTaskOffsetReference.BOOKING_CONFIRMATION"
                                              [ngModelOptions]="{standalone: true}">
                                      <mat-option *ngFor="let position of positions" [value]='position'>
                                          {{ CommonEnumHelpers.getAutomationOffsetPositionTitle(position)}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field>
                              <mat-form-field [color]="'accent'" class="width-24">
                                  <mat-select placeholder="Preference"
                                              [(ngModel)]="offset_reference"
                                              (ngModelChange)="onPreferanceChange($event)"
                                              [ngModelOptions]="{standalone: true}">
                                      <mat-option *ngFor="let reference of references" [value]='reference'>
                                          {{ AutoTaskEnumHelper.getAutoTaskOffsetReferenceTitle(reference) }}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field>
                          </div>
                      </div>
                  </div>

                  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                       style="margin-bottom: 10px; margin-top: 10px;">
                      <mat-checkbox formControlName='include_cleaning_cost_expense'>Add Cleaning Cost Expense
                      </mat-checkbox>
                      <mat-checkbox formControlName='include_state_tax_expense'>Add Tax Expense</mat-checkbox>
                      <mat-checkbox formControlName='include_commission_amount_expense'>Add Commission Amount Expense
                      </mat-checkbox>
                  </div>

                  <div fxLayoutAlign="space-between center" fxFlex="50%" class="responsiveWidth">
                      <span fxFlex="80%">Does this task have expenses?</span>
                      <mat-slide-toggle [checked]="showExpense" (change)="expenseToggleChange($event)">
                      </mat-slide-toggle>
                  </div>
                  <div *ngIf="showExpense" fxLayout="column">

                      <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px" class="heading">
                          <span style="width: 55%">Product</span>
                          <span style="width: 10%">Quantity</span>
                          <span style="width: 10%">Price</span>
                          <span style="width: 15%">Amount</span>
                      </div>
                      <hr>
                      <div fxLayout="column" *ngFor="let expense of expenses; let index = index">
                          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
                              <div style="width: 55%" fxLayout="row">
                                  <mat-form-field [color]="'accent'" style="width: 38%">
                                      <input matInput
                                             [(ngModel)]="expense.title"
                                             [ngModelOptions]="{standalone: true}"
                                             placeholder="Name">
                                  </mat-form-field>
                                  <mat-form-field [color]="'accent'" style="width: 60%">
                                      <input matInput
                                             [(ngModel)]="expense.desc"
                                             [ngModelOptions]="{standalone: true}"
                                             placeholder="Description">
                                      <button mat-button matSuffix mat-icon-button type="button"
                                              (click)="openItemDescriptionPopup(index)">
                                          <mat-icon>fullscreen</mat-icon>
                                      </button>
                                  </mat-form-field>
                              </div>

                              <mat-form-field [color]="'accent'" style="width: 10%">
                                  <input matInput
                                         [(ngModel)]="expense.quantity"
                                         [ngModelOptions]="{standalone: true}"
                                         placeholder="Quantity">
                              </mat-form-field>

                              <mat-form-field [color]="'accent'" style="width: 10%">
                                  <span matPrefix>$ &nbsp;</span>
                                  <input matInput
                                         [(ngModel)]="expense.price"
                                         [ngModelOptions]="{standalone: true}"
                                         placeholder="Price">
                              </mat-form-field>

                              <span style="width: 15%">$ {{amountCalculatePerItem(expense.quantity, expense.price)}}</span>
                              <mat-icon *ngIf="expenses.length > 0" (click)="removeItem(index,expense)"
                                        style="cursor:pointer">
                                  delete
                              </mat-icon>
                          </div>
                      </div>
                      <div style="margin-top: 10px; margin-bottom: 10px" fxLayoutAlign="center center">
                          <button mat-button style="color: #2d7cff"
                                  fxLayoutGap="10px" (click)="addItem()" type="button">
                              <mat-icon>add_circle</mat-icon>
                              <span style="font-size: medium"> Add a new item</span>
                          </button>
                      </div>
                      <hr>
                  </div>
              </div>

              <div fxLayoutGap="10px" fxLayoutAlign=" center" fxFlexAlign="end">
                  <mat-spinner color="accent" *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
                  <button mat-raised-button color="warn" type="button" *ngIf="autoTaskFull" [disabled]="isSaving"
                          (click)="deleteTask()">
                      Delete
                  </button>
                  <button mat-raised-button fxFlexAlign="end"
                          [disabled]="!formGroup.valid|| isSaving || this.showMatErrorForIDandType"
                          color="accent" (click)="saveButtonCLicked()">
                      {{buttonTitle}}
                  </button>
              </div>
          </div>
      </div>

      <div *ngIf="autoTaskNotExist">
          <h3>This Auto Task Does Not Exist.</h3>
      </div>

  `, styles: [`
        .textArea {
            width: 100%;
            min-height: 100px;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            border-color: #194267;
            padding: 10px;
            border-style: solid;
            margin: 2px;
            border-width: 1px;
            border-color: #194267;
            border-radius: 3px;
        }

        .select-button {
            padding: 6px;
            text-align: left;
            font-size: 17px;
            padding-left: 10px;
            font-weight: bolder;
        }

        .width50 {
            width: 48%;
            margin-top: -20px;
        }

        textarea {
            resize: none;
        }

        .UploadedPhoto {
            min-height: 50vh;
            padding: 10px;
        }

        .main-container {
            padding: 0px;
        }

        #titleBar {
            padding: 5px;
            background-color: dodgerblue;
        }

        hr {
            width: 100%;
        }

        mat-card {
            margin-top: 10px;
            width: 1in;
            height: 1in;
        }

        .icon {
            background: transparent;
            border: none !important;
            font-size: 0;
        }

        mat-spinner {
            height: 30px;
            width: 30px;
        }

        .width-24 {
            width: 24%;
        }

        @media only screen and (max-width: 600px) {

            [class*="width-"] {
                width: 95%;
            }

            .responsiveAlign {
                text-align: center;
            }

            .responsiveWidth {
                width: 95%;
            }
        }

        .spacer {
            flex: 1 1 auto;
        }

        .fixed-header {
            padding: 10px;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100% !important;
        }

  `]
})

export class AutoTaskShowComponent implements OnInit, OnDestroy {

  @ViewChildren("messageTextArea") body: any[];

  quillBody: Quill;
  message = "";
  titleVariables = QuillUtils.VARIABLES;

  showMatErrorForIDandType = true;

  isCreateTaskOpen = false;
  autoTaskFull: AutoTaskFull;
  @Input() listingId;
  autoTaskId: number;
  formGroup: FormGroup;
  title: FormControl;
  propertyIds: FormControl;
  assigneeType: FormControl;
  assigneeID: FormControl;
  type: FormControl;
  dueDate: FormControl;
  description: FormControl;
  paymentBy: FormControl;
  cleaningFeeExpense: FormControl;
  stateTaxExpense: FormControl;
  commissionAmountExpense: FormControl;
  listingRule: FormControl;
  sourceRule: FormControl;
  source: FormControl;
  filteredListings: ListingCompact[] = [];
  listingFilter: FormControl;
  listings: ListingCompact[] = [];
  getAllAlertTypes;
  headerTitle = "New Auto Task";
  titleError = false;
  AutomationRule = AutomationRule;
  isLoading: boolean;
  isLoaded: boolean;

  // Enums
  references = CommonUtil.getPropsOfEnum<AutoTaskOffsetReference>(AutoTaskOffsetReference);
  positions = CommonUtil.getPropsOfEnum<AutomationOffsetPosition>(AutomationOffsetPosition);
  categories = CommonUtil.getPropsOfEnum<TaskCategory>(TaskCategory);
  assigneeTypes = CommonUtil.getPropsOfEnum<AutoTaskAssigneeType>(AutoTaskAssigneeType);
  automationRules = CommonUtil.getPropsOfEnum<AutomationRule>(AutomationRule);
  paymentByOptions = CommonUtil.getPropsOfEnum<TaskPaymentBy>(TaskPaymentBy);
  sources = CommonUtil.getPropsOfEnum<AutoTaskSources>(AutoTaskSources);

  // Helpers
  AutoTaskOffsetReference = AutoTaskOffsetReference;
  TaskEnumHelper = TaskEnumHelper;
  UserModelUtil = UserModelUtil;

  expenses: AutoTaskExpense[] = [];
  hourSelected = 0;
  minuteSelected = 0;
  offset_reference = AutoTaskOffsetReference.CHECK_IN;
  offset_position = AutomationOffsetPosition.AFTER;
  assignees: UserCompact[] = [];
  bookings: Booking[] = [];
  assigneesLoading = false;
  assigneesLoaded = false;
  showTime = true;
  showExpense = false;
  isSaving = false;
  buttonTitle = "Create";
  AutoTaskExpenseType = AutoTaskExpenseType;
  AutoTaskEnumHelper = AutoTaskEnumHelper;
  CommonEnumHelpers = CommonEnumHelpers;
  autoTaskNotExist: boolean = false;
  private isAlive: boolean = true;
  private destroyed$ = new Subject();

  constructor(private dialog: MatDialog,
              private router: Router,
              private optionsRepo: OptionsRepository,
              private autoTaskRepo: AutoTaskRepository,
              private route: ActivatedRoute,
              private listingRepo: ListingRepository) {
    this.getAllAlertTypes = this.automationRules;
    this.title = new FormControl(null, [Validators.required]);
    this.propertyIds = new FormControl([]);
    this.assigneeID = new FormControl(null);
    this.assigneeType = new FormControl(null);
    this.type = new FormControl(null, [Validators.required]);
    this.dueDate = new FormControl(null, [Validators.required]);
    this.description = new FormControl();
    this.paymentBy = new FormControl(null, [Validators.required]);
    this.listingRule = new FormControl("include", [Validators.required]);
    this.sourceRule = new FormControl("include", [Validators.required]);
    this.source = new FormControl([]);
    this.cleaningFeeExpense = new FormControl(false);
    this.stateTaxExpense = new FormControl(false);
    this.commissionAmountExpense = new FormControl(false);
    this.listingFilter = new FormControl();

    this.formGroup = new FormGroup({
      title: this.title,
      property_ids: this.propertyIds,
      assignee_type: this.assigneeType,
      type: this.type,
      description: this.description,
      listing_rule: this.listingRule,
      source_rule: this.sourceRule,
      source: this.source,
      assignee_id: this.assigneeID,
      include_cleaning_cost_expense: this.cleaningFeeExpense,
      payment_by: this.paymentBy,
      include_state_tax_expense: this.stateTaxExpense,
      include_commission_amount_expense: this.commissionAmountExpense
    });
  }

  ngOnInit() {
    console.log("body", this.body);
    this.setupAssignees();
    this.route.params.subscribe(params => {

      console.log("[SEMPER] params", params);

      if (params.id !== "create") {
        this.autoTaskId = +params.id;
      }

      this.listingId = +params.listingId;
    });

    console.log("[SEMPER]", this.autoTaskId, isNullOrUndefined(this.autoTaskId));

    if (!isNullOrUndefined(this.autoTaskId) && !isNaN(this.autoTaskId)) {
      this.autoTaskRepo.getTaskById(this.autoTaskId).pipe(filter(c => !!c)).subscribe(autoTask => {
        this.autoTaskFull = autoTask;
        this.message = this.autoTaskFull.description;
      });
      this.autoTaskRepo.getIsFullAutoTaskLoading(this.autoTaskId).subscribe(l => {
        this.isLoading = l;
      });
      this.autoTaskRepo.getIsFullAutoTaskLoaded(this.autoTaskId).subscribe(l => {
        this.isLoaded = l;
        console.log("loaded", l);
        this.setupForEdit();
        if (this.isLoaded) {
          setTimeout(() => this.setupQuill());
        }
      });

      this.autoTaskRepo.getIsAutoTaskExist(this.autoTaskId).subscribe(res => {
        this.autoTaskNotExist = res;
      });
    } else {
      this.isCreateTaskOpen = true;
      setTimeout(() => this.setupQuill());
    }

    this.listingRepo.getAcceptedListings().pipe(takeUntil(this.destroyed$)).subscribe((listings) => {
      this.listings = listings;
      this.filteredListings = this.listings;
    });

    this.listingFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged(),).subscribe((value) => {

      if (value) {
        this.filteredListings = this.listings.filter(l => l.title.toLowerCase().includes(value));
      } else {
        this.filteredListings = this.listings;
      }

      if (this.filteredListings.length === 0) {
        this.filteredListings = this.listings;
      }

    });

  }

  onMatSelectClose() {
    this.filteredListings = this.listings;
  }

  openDescriptionPopup() {
    console.log("task notes update popup");
    const dialogRef = this.dialog.open(TaskNoteUpdatePopupComponent, {
      data: this.quillBody.root.innerHTML
    });

    dialogRef.updateSize("100%", "100%");

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.quillBody.root.innerHTML = result;
      }
    });
  }

  onSelectAll() {
    const listingIds = this.listings.map(listing => listing.id);
    this.propertyIds.setValue(listingIds);
  }

  onSelectNone() {
    this.propertyIds.setValue([]);
  }

  setupForEdit() {
    if (isNullOrUndefined(this.title.value)) {
      this.titleError = true;
    }
    if (!isNullOrUndefined(this.autoTaskFull) && !isNullOrUndefined(this.autoTaskFull.id)) {
      this.headerTitle = this.autoTaskFull.title;
      this.title.setValue(this.autoTaskFull.title);
      this.description.setValue(this.autoTaskFull.description);
      this.type.setValue(this.autoTaskFull.type);
      this.listingRule.setValue(AutoTaskEnumHelper.getAutoTaskAutomationRule(this.autoTaskFull.listing_rule));
      this.sourceRule.setValue(AutoTaskEnumHelper.getAutoTaskAutomationRule(this.autoTaskFull.source_rule));
      this.source.setValue(this.autoTaskFull.source);
      this.offset_position = this.autoTaskFull.offset_position;
      this.offset_reference = this.autoTaskFull.offset_reference;
      this.cleaningFeeExpense.setValue(this.autoTaskFull.include_cleaning_cost_expense);
      this.stateTaxExpense.setValue(this.autoTaskFull.include_state_tax_expense);
      this.commissionAmountExpense.setValue(this.autoTaskFull.include_commission_amount_expense);
      this.paymentBy.setValue(this.autoTaskFull.payment_by);

      const ids = this.autoTaskFull.property_ids.map(id => {
        return Number(id);
      });

      this.propertyIds.setValue(ids);

      const minutes = this.autoTaskFull.offset / 60;

      this.hourSelected = minutes / 60;
      this.minuteSelected = minutes % 60;

      if (!isNullOrUndefined(this.autoTaskFull.expenses)) {
        this.showExpense = (this.autoTaskFull.expenses.length > 0);
        for (const expense of this.autoTaskFull.expenses) {
          this.expenses.push({
            title: expense.title,
            desc: expense.desc,
            quantity: Number(expense.quantity),
            price: Number(expense.price * 100) / 100,
          });
        }
      }

      if (this.autoTaskFull.assignee_type !== null) {
        this.assigneeType.setValue(this.autoTaskFull.assignee_type);
      }

      if (this.autoTaskFull.assignee_id !== null) {
        this.assigneeID.setValue(this.autoTaskFull.assignee_id);
        // this.assigneeType.disable();
      }

      this.buttonTitle = "Save";
    } else {
      this.propertyIds.setValue([this.listingId]);
    }
    this.disablerFunction();
  }

  saveButtonCLicked() {
    this.isSaving = true;
    const data = {
      ...this.formGroup.value,
      description: QuillUtils.removeExtraLines(this.description.value || "")
    };
    if (this.showExpense) {
      const expensesArray = [];

      for (const expense of this.expenses) {
        expense.quantity = Number(expense.quantity);
        expense.price = Number(expense.price * 100) / 100;

        expensesArray.push(expense);
      }

      if (expensesArray.length > 0) {
        data.expenses = expensesArray;
      } else {
        data.expenses = [];

      }
    }

    data.offset_position = this.offset_position;
    data.offset_reference = this.offset_reference;

    data.offset = this.hourSelected * 60 * 60 + this.minuteSelected * 60;
    console.log(data);
    if (this.assigneeID.value) {
      delete data.assignee_type;
    } else {
      delete data.assignee_id;
    }

    if (isNullOrUndefined(this.autoTaskFull)) {
      this.autoTaskRepo.createAutoTask(data).subscribe(result => {
        this.isSaving = false;
        this.close();
      }, () => {
        this.isSaving = false;
      });
    } else {
      this.autoTaskRepo.updateAutoTask(this.autoTaskFull.id, data).subscribe(result => {
        this.isSaving = false;
        this.close();
      }, () => {
        this.isSaving = false;
      });
    }
    console.log(this.formGroup.value);
  }

  deleteTask() {
    this.isSaving = true;
    this.autoTaskRepo.deleteAutoTask(this.autoTaskFull.id).subscribe(result => {
      console.log("delete task");
      this.isSaving = false;
      this.close();
    }, () => {
      this.isSaving = false;
    });
  }

  selectedValueChanged() {
    const listingIds = this.propertyIds.value;
  }

  getTimeInDateFormat(hours: string, minutes: string) {
    if (hours && minutes) {
      return hours + ":" + minutes + ":00";
    }
    return "00:00:00";
  }

  timeToggleChange($event) {
    this.showTime = !$event.checked;
  }

  expenseToggleChange($event) {
    this.showExpense = $event.checked;
    if (this.showExpense) {
      this.expenses.push({
        title: "",
        desc: "",
        quantity: 0,
        price: 0
      });
    } else {
      this.expenses = [];
    }
  }

  amountCalculatePerItem(quant, price) {
    const quantity = Number(quant);
    const prices = Number(price);
    if (prices && quantity) {
      let amount = prices * quantity;
      if (amount % 1000 !== amount) {
        amount = amount / 1000;
        return (amount).toFixed(2) + "K";
      } else {
        return (amount).toFixed(2);
      }
    } else {
      return "0.00";
    }
  }

  totalAmount() {
    let amount = 0;
    this.expenses.map((expense) => {
      amount += (Number(expense.price) * Number(expense.quantity));
    });
    return amount;
  }

  addItem(key?) {
    this.expenses.push({
      title: "",
      desc: "",
      quantity: 0,
      price: 0
    });
  }

  removeItem(index, expense) {
    this.expenses.splice(index, 1);
  }

  checkNullString(string: string): string {
    if (string != null) {
      return " ".concat(string);
    } else {
      return "";
    }
  }

  print() {
    window.print();
  }

  close() {
    console.log("entered close");
    if (this.route.snapshot.params.from) {
      console.log("entered 1");
      const id = this.route.snapshot.params.task_id;
      this.router.navigate(["/tasks/" + id]);
      return;
    } else if (this.route.snapshot.params.reservation_id) {
      console.log("entered 2");
      const id = this.route.snapshot.params.reservation_id;
      this.router.navigate(["/reservations/" + id + "/tasks"]);
      return;
    } else if (!isNaN(this.listingId)) {
      console.log("entered 3");
      this.router.navigate(["/listings/" + this.listingId + "/automation"]);
    } else {
      console.log("entered 4");
      this.router.navigate(["/settings/tools/auto-tasks"]);
    }
  }

  openItemDescriptionPopup(expenseIndex: number) {
    const dialogRef = this.dialog.open(ItemDescriptionPopupComponent, {
      data: {data: this.expenses[expenseIndex].desc}
    });

    dialogRef.updateSize("100%", "100%");

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed");
      this.expenses[expenseIndex].desc = result;
      console.log(this.expenses);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onPreferanceChange(value: string) {
    if (value === AutoTaskOffsetReference.BOOKING_CONFIRMATION) {
      this.offset_position = AutomationOffsetPosition.AFTER;
    }
  }

  setupQuill() {
    const values = this.titleVariables;
    this.quillBody = new Quill("#descriptionMessage", {
      theme: "bubble",
      modules: {
        mention: {
          allowedChars: /^[_A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["{"],
          isolatedCharacter: false,
          source: (searchTerm, renderList, mentionChar) => {

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(values[i]);
                }
              }
              renderList(matches, searchTerm);
            }
          },
          renderItem: (item, searchTerm) => item.value.replace("{", "{{"),
          dataAttributes: ["value"]
        },
      },
    });

    if (this.description.value) {
      this.quillBody.setText(this.description.value);
      this.quillBody.root.innerHTML = QuillUtils.replaceMentionWithClass(this.quillBody.root.innerHTML);
    }

    this.quillBody.on("text-change", () => {
      this.description.setValue(this.quillBody.root.innerText);
    });
  }

  private disablerFunction() {
    this.showMatErrorForIDandType = (!this.assigneeID.value && !this.assigneeType.value);
  }

  private setupAssignees() {
    this.optionsRepo.getTaskAssignees().pipe(takeUntil(this.destroyed$))
      .subscribe((assignees) => {
        this.assignees = assignees;
        console.log("assignees", this.assignees);
      });
  }
}
