import { Image } from "../../../models/image";
import { ChecklistCategory } from "../../../models/new/checklist-category";
import { ListingChecklist } from "../../../models/new/listing/listing-checklist.model";
import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { ListingFull } from "../../../models/new/listing/listing-full.model";
import { ListingHomeawayReport } from "../../../models/new/listing/listing-homeaway-report";
import { ListingTag } from "../../../models/new/listing/listing-tag.model";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { Action } from "../../action";

export enum ListingActionTypes {
  INDEX_REQUEST = "[LISTING LISTING NEW] Index Request",
  INDEX_SUCCESS = "[LISTING LISTING NEW] Index Success",

  CREATE_REQUEST = "[LISTING LISTING NEW] Create Request",
  CREATE_SUCCESS = "[LISTING LISTING NEW] Create Success",

  SHOW_REQUEST = "[LISTING LISTING NEW] Show Request",
  SHOW_SUCCESS = "[LISTING LISTING NEW] Show Success",

  UPDATE_REQUEST = "[LISTING LISTING NEW] Update Request",
  UPDATE_SUCCESS = "[LISTING LISTING NEW] Update Success",
  ACTIVATE_SUCCESS = "[LISTING LISTING NEW] Activate Success",

  IMAGE_DELETE_SUCCESS = "[LISTING LISTING NEW] Delete Image Success",
  IMAGE_ALL_DELETE_SUCCESS = "[LISTING LISTING NEW] Delete All Image Success",
  IMAGE_SELECTED_DELETE_SUCCESS = "[LISTING LISTING NEW] Delete Selected Image Success",
  IMAGE_ADD_SUCCESS = "[LISTING LISTING NEW] Add Image Success",
  IMAGE_EDIT_SUCCESS = "[LISTING LISTING NEW] Edit Image Success",
  IMAGE_SORTING_SUCCESS = "[LISTING LISTING NEW] Image Sorting Success",

  CONTACT_ADD_SUCCESS = "[LISTING LISTING NEW] Add Contact Success",
  CONTACT_DELETE_SUCCESS = "[LISTING LISTING NEW] Delete Contact Success",

  TAG_ATTACH_SUCCESS = "[LISTING LISTING NEW] Tag Attach Success",
  TAG_DELETE_SUCCESS = "[LISTING LISTING NEW] Tag  Delete Success",

  CHECKLIST_ADD_SUCCESS = "[LISTING LISTING NEW] Add Check-List success",
  CHECKLIST_DELETE_SUCCESS = "[LISTING LISTING NEW] Delete Check-List success",
  CHECKLIST_RE_ARRANGE_SUCCESS = "[LISTING LISTING NEW] Re-Arrange Check-List success",
  CHECKLIST_CATEORY_RE_ARRANGE_SUCCESS = "[LISTING LISTING NEW] Re-Arrange Check-List Category success",
  CHECKLIST_CATEORY_DELETE_SUCCESS = "[LISTING LISTING NEW] Delete Check-List Category success",
  CHECKLIST_CATEORY_ADD_SUCCESS = "[LISTING LISTING NEW] Add Check-List Category success",
  CHECKLIST_UPDATE_SUCCESS = "[LISTING LISTING NEW] Update Check-List success",

  HOMEAWAY_REPORT_REQUEST = "[LISTING LISTING NEW] HomeAway Report Request",
  HOMEAWAY_REPORT_SUCCESS = "[LISTING LISTING NEW] HomeAway Report Success",

}


export class ListingIndexRequest implements Action {
  readonly type = ListingActionTypes.INDEX_REQUEST;
}

export class ListingIndexSuccess implements Action {
  readonly type = ListingActionTypes.INDEX_SUCCESS;

  constructor(public payload: ListingCompact[]) {
  }
}

export class ListingCreateRequest implements Action {
  readonly type = ListingActionTypes.CREATE_REQUEST;
}

export class ListingCreateSuccess implements Action {
  readonly type = ListingActionTypes.CREATE_SUCCESS;

  constructor(public payload: ListingFull) {
  }
}

export class ListingShowRequest implements Action {
  readonly type = ListingActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {
  }
}

export class ListingShowSuccess implements Action {
  readonly type = ListingActionTypes.SHOW_SUCCESS;

  constructor(public payload: ListingFull) {
  }
}

export class UpdateRequest implements Action {
  readonly type = ListingActionTypes.UPDATE_REQUEST;
}

export class UpdateSucess implements Action {
  readonly type = ListingActionTypes.UPDATE_SUCCESS;

  constructor(public payload: ListingFull) {
  }
}

export class ActivateSuccess implements Action {
  readonly type = ListingActionTypes.ACTIVATE_SUCCESS;

  constructor(public payload: number) {
  }
}

export class DeleteTagSuccess implements Action {
  readonly type = ListingActionTypes.TAG_DELETE_SUCCESS;

  constructor(public payload: { listingId: string, tagId: string }) {
  }
}

export class AttachTagSuccess implements Action {
  readonly type = ListingActionTypes.TAG_ATTACH_SUCCESS;

  constructor(public payload: { listingId: number, tag: ListingTag }) {
  }
}

export class ChecklistUpdateSuccess implements Action {
  readonly type = ListingActionTypes.CHECKLIST_UPDATE_SUCCESS;

  constructor(public payload: { listingId: number, checklist: ListingChecklist }) {
  }
}

export class ChecklistAddSuccess implements Action {
  readonly type = ListingActionTypes.CHECKLIST_ADD_SUCCESS;

  constructor(public payload: { listingId: number, checklist: ListingChecklist }) {
  }
}

export class ChecklistReArrangeSuccess implements Action {
  readonly type = ListingActionTypes.CHECKLIST_RE_ARRANGE_SUCCESS;

  constructor(public payload: { listingId: number, checklists: ChecklistCategory[] }) {
  }
}

export class ChecklistCategoryAddSuccess implements Action {
  readonly type = ListingActionTypes.CHECKLIST_CATEORY_ADD_SUCCESS;

  constructor(public payload: { listingId: number, category: ChecklistCategory }) {
  }
}

export class ChecklistCategoryReArrangeSuccess implements Action {
  readonly type = ListingActionTypes.CHECKLIST_CATEORY_RE_ARRANGE_SUCCESS;

  constructor(public payload: { listingId: number, checklists: ChecklistCategory[] }) {
  }
}

export class ChecklistCategoryDeleteSuccess implements Action {
  readonly type = ListingActionTypes.CHECKLIST_CATEORY_DELETE_SUCCESS;

  constructor(public payload: { listingId: number, checklists: ChecklistCategory[] }) {
  }
}

export class ChecklistDeleteSuccess implements Action {
  readonly type = ListingActionTypes.CHECKLIST_DELETE_SUCCESS;

  constructor(public payload: { listingId: number, checklistId: number, categoryId: number }) {
  }
}

export class DeleteImageSuccess implements Action {
  readonly type = ListingActionTypes.IMAGE_DELETE_SUCCESS;

  constructor(public payload: { property_id: string, image_id: string }) {
  }
}

export class DeleteAllImageSuccess implements Action {
  readonly type = ListingActionTypes.IMAGE_ALL_DELETE_SUCCESS;

  constructor(public payload: { property_id: string }) {
  }
}

export class DeleteSelectedImageSuccess implements Action {
  readonly type = ListingActionTypes.IMAGE_SELECTED_DELETE_SUCCESS;

  constructor(public payload: { property_id: number, property_image_ids: number[] }) {
  }
}

export class AddImageSuccess implements Action {
  readonly type = ListingActionTypes.IMAGE_ADD_SUCCESS;

  constructor(public payload: { property_id: string, image: Image }) {
  }
}

export class ImageSortingSuccess implements Action {
  readonly type = ListingActionTypes.IMAGE_SORTING_SUCCESS;

  constructor(public payload: { property_id: string, sortOrder: {}, images: Image[] }) {
  }
}

export class EditImageSuccess implements Action {
  readonly type = ListingActionTypes.IMAGE_EDIT_SUCCESS;

  constructor(public payload: { property_id: string, image: Image }) {
  }
}

// payload is listingId
export class HomeAwayReportRequest implements Action {
  readonly type = ListingActionTypes.HOMEAWAY_REPORT_REQUEST;

  constructor(public payload: number) {

  }
}

export class HomeAwayReportSuccess implements Action {
  readonly type = ListingActionTypes.HOMEAWAY_REPORT_SUCCESS;

  constructor(public payload: { listingId: number, report: ListingHomeawayReport }) {
  }
}

export class ContactAddSuccess implements Action {
  readonly type = ListingActionTypes.CONTACT_ADD_SUCCESS;

  constructor(public payload: { contact: UserCompact, listingId: number }) {
  }
}


export class ContactDeleteSuccess implements Action {
  readonly type = ListingActionTypes.CONTACT_DELETE_SUCCESS;

  constructor(public payload: { contact: UserCompact, listingId: number }) {
  }
}

