
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable,  of as observableOf, throwError as throwError } from "rxjs";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { catchError, map } from "rxjs/operators";
import { isNullOrUndefined, isUndefined } from "util";

import { environment } from "../../environments/environment";
import { __HTTPResponseType } from "../enums/common.enum";
import { TypeFormModel } from "../models/new/typeform.model";
import { Payment } from "../models/payment";
import { SearchResponse } from "../models/search-response";
import { dateToDateString, getDateObj } from "../utils/calendar-utils";

import { AppService } from "./app.service";

@Injectable()
export class StayDuvetService {

  /**
   * Constants
   */
  private BASE_URL = environment.apiBaseURL;

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private appService: AppService) {
  }

  private static injectTimezoneToData(data: any): any {
    const injection = {
      __timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    if (data) {
      return {
        ...data,
        ...injection
      };
    }

    return injection;
  }

  private static hasToken(): boolean {
    return !!localStorage.getItem("auth_token");
  }

  private static getToken(): string {
    return localStorage.getItem("auth_token");
  }

  private static getMfaToken(): string {
    return localStorage.getItem("mfa_token");
  }

  private static buildParams(data: any): HttpParams {
    let params = new HttpParams();

    if (data) {
      for (const key of Object.keys(data)) {
        console.log("KEY", key);
        // Beware .append is not mutable operation
        params = params.append(key, data[key]);
      }
    }

    return params.append("__timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);
  }

  getHashedQuote(hashedId: string): Observable<any> {
    return this.get<{ data: any }>("/quotes/hashed/" + hashedId + "?include=property,prospect", false).pipe(map((res) => {
      const quote = res.data;
      return quote;
    }), catchError(err => this.handleError(err)));
  }

  getHashedPayment(hashedId: string): Observable<Payment> {
    return this.get<{ data: any }>("/payments/hashed/" + hashedId + "?include=booking,booking.property,booking,guest", false).pipe(map((res) => {
      const payment = res.data;
      return Object.assign(new Payment(), payment);
    }), catchError(err => this.handleError(err)));
  }

  getContactReservation(id: number): Observable<any> {
    return this.get<{ data: any }>("/admin/contacts/" + id + "/reservations", true).pipe(map((res) => {
      const reservations = res.data;
      return reservations;
    }), catchError(err => this.handleError(err)));
  }

  // TODO Not Sure where to move

  payForCollection(paymentId: number, stripToken: string): Observable<any> {
    return this.post("/payments/" + paymentId + "/collect", true, {card_token: stripToken}).pipe(map(
      res => res
    ), catchError(err => this.handleError(err)));
  }

  // TODO Not Sure where to move
  payFromPlatform(bookingId: number, stripToken: string): Observable<any> {
    return this.post("/bookings/" + bookingId + "/pay", true, {card_token: stripToken}).pipe(map(
      res => res
    ), catchError(err => this.handleError(err)));
  }

  // TODO Not Sure where to move
  payForQuoteFromPlatform(quoteId: number, stripToken: string): Observable<any> {
    return this.post("/quotes/" + quoteId + "/pay", true, {card_token: stripToken}).pipe(map(
      res => res
    ), catchError(err => this.handleError(err)));
  }

  // TODO Not Sure where to move
  getBookingAvailability(listingId: string, data: {
    start?: string;
    end?: string;
    number_of_guests?: number;
    number_of_pets?: number;
    force_book?: any;
    override_min_stay?: any;
  }): Observable<any> {
    data.start = dateToDateString(getDateObj(data.start));
    data.end = dateToDateString(getDateObj(data.end));

    if (data.force_book) {
      data.force_book = 1;
    }

    data.override_min_stay = data.override_min_stay ? 1 : 0;

    return this.get("/admin/properties/" + listingId + "/available", true, data).pipe(map(res => {
      return res;
    }), catchError(err => this.handleError(err)));
  }

  // TODO booking.service

  globalSearch(data: { key: string, type: string }): Observable<SearchResponse> {
    return this.get<{ data: any }>("/search", true, data).pipe(map(res => {
      return res.data;
    }), catchError(err => this.handleError(err)));
  }

  getTypeforms(): Observable<TypeFormModel[]> {
    return observableOf([
      {
        title: "test",
        message: "test message"
      }
    ]);
  }

  get<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders, useBaseUrl: boolean = true, responseType?: __HTTPResponseType, handleError = true): Observable<T> {
    console.log("[api.service] get", url, data);

    const options: any = {
      params: StayDuvetService.buildParams(data),
      headers: this.buildHeaders(useAuthHeaders, headers)
    };

    if (responseType) {
      options.responseType = responseType;
    }

    let baseUrl = this.BASE_URL + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }

    console.log("[SEMPER]", url, data);

    const res$ = this.http.get<T>(baseUrl, options);

    return handleError ? res$.pipe(catchError(err => this.handleError(err))) : res$;
  }

  post<T>(url: string, useAuthHeaders: boolean, data: any, headers?: HttpHeaders, useBaseUrl: boolean = true, responseType?: __HTTPResponseType, fullResponse = false): Observable<T> {
    console.log("[api.service] post", url, data);

    let baseUrl = this.BASE_URL + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }

    const options: any = {
      headers: this.buildHeaders(useAuthHeaders, headers)
    };

    if (responseType) {
      options.responseType = responseType;
    }

    if (fullResponse) {
      options.observe = "response";
    }

    return this.http.post<T>(baseUrl, StayDuvetService.injectTimezoneToData(data), options).pipe(catchError(err => this.handleError(err)));
  }

  put<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders, useBaseUrl: boolean = true, responseType?: __HTTPResponseType): Observable<T> {
    console.log("[api.service] put", url, data);

    let baseUrl = this.BASE_URL + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }

    const options: any = {
      headers: this.buildHeaders(useAuthHeaders, headers),
    };

    if (responseType) {
      options.responseType = responseType;
    }

    return this.http.put<T>(baseUrl, StayDuvetService.injectTimezoneToData(data), options).pipe(catchError(err => this.handleError(err)));
  }

  delete<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders, useBaseUrl: boolean = true, responseType?: __HTTPResponseType, fullResponse = false): Observable<T> {
    console.log("[api.service] delete", url, data);

    const options: any = {
      params: StayDuvetService.buildParams(data),
      headers: this.buildHeaders(useAuthHeaders, headers),
    };

    if (responseType) {
      options.responseType = responseType;
    }

    let baseUrl = this.BASE_URL + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }

    if (fullResponse) {
      options.observe = "response";
    }

    return this.http.delete<T>(baseUrl, options).pipe(catchError(err => this.handleError(err)));
  }

  private buildHeaders(useAuthHeaders: boolean, headers?: HttpHeaders): HttpHeaders {
    if (!useAuthHeaders) {
      return headers;
    }

    let mutatedHeaders: HttpHeaders;

    if (!isNullOrUndefined(headers)) {
      mutatedHeaders = headers;
    } else {
      mutatedHeaders = new HttpHeaders();
    }

    mutatedHeaders = mutatedHeaders.append("Authorization", "bearer " + StayDuvetService.getToken());
    if (!!StayDuvetService.getMfaToken()) {
      mutatedHeaders = mutatedHeaders.append("mfa-token", "bearer " + StayDuvetService.getMfaToken());
    }
    return mutatedHeaders;
  }

  private handleError(response: HttpErrorResponse): ErrorObservable<any> | Observable<any> {
    console.log("[sd-error] start");
    console.log(response);
    console.log("[sd-error] end");

    const error = response.error || {};
    const keys = Object.keys(error);
    const key = keys[0];

    console.log("status", error.status_code);
    if (error.status_code === 401) {
      this.appService.logout();
      this.snackBar.open("Unauthorized", "", {
        duration: 4000,
      });
      return throwError({messages: ["Unauthorized"], error: null});
    }

    const errorObject = error;
    const errorResponse = errorObject.errors;

    const messages = [];
    if (isUndefined(errorResponse)) {
      if (typeof errorObject === "object") {
        messages.push(errorObject.message);
      } else {
        messages.push(JSON.parse(errorObject).message);
      }
    } else {
      if (typeof errorResponse === "string") {
        return throwError({messages: [errorResponse], error: errorObject});
      } else {
        for (const key in errorResponse) {
          if (errorResponse.hasOwnProperty(key)) {
            messages.push(errorResponse[key]);
          }
        }
      }
    }

    this.snackBar.open(messages[0], "", {
      duration: 4000,
    });
    return throwError({messages: messages, error: errorObject});
  }

}
