import { UserNameOnly } from "../new/user/user-name-only.model";
import { UserCategory } from "../../enums/user.enum";
import { UserCompact } from "../new/user/user-compact.model";
import { Owner } from "../new/user/owner.model";
import { UserFull } from "../new/user/user-full.model";
import { UserLog } from "../new/user/user-log.model";
import { Admin } from "../new/user/admin.model";
import { Guest } from "../new/user/guest.model";
import { ManagementContact } from "../new/user/management-contact.model";

export class UserModelUtil {

  static getFullName(user: UserNameOnly): string {
    if(user) {
      const lastName = user.last_name ? " " + user.last_name : "";
      return user.first_name + lastName;
    }
    else{
      return "";
    }
  }

  static getProfilePic(user: UserNameOnly): string {
    return user.pic_thumb_url ? user.pic_thumb_url : "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/user_placeholder.jpg";
  }

  static getManagementContact(user: UserCompact): ManagementContact {
    return user.managementContact.data;
  }

  static getAdmin(user: UserCompact): Admin {
    return user.admin.data;
  }

  static getOwner(user: UserCompact): Owner {
    return user.owner.data;
  }

  static getGuest(user: UserCompact): Guest {
    return user.guest.data;
  }

  static getLogs(user: UserFull): UserLog[] {
    return user.logs.data;
  }


  static getCategoryTitle(category: UserCategory): string {
    let title = "";

    switch (category) {
      case UserCategory.HVAC : {
        title = "HVAC";
        break;
      }

      case UserCategory.PLUMBER : {
        title = "Plumber";
        break;
      }

      case UserCategory.PAINTER : {
        title = "Painter";
        break;
      }

      case UserCategory.ELECTRICIAN : {
        title = "Electrician";
        break;
      }

      case UserCategory.INSPECTOR : {
        title = "Inspector";
        break;
      }

      case UserCategory.GENERAL_MAINTENANCE : {
        title = "General Maintenance";
        break;
      }

      case UserCategory.HOMEOWNER : {
        title = "Homeowner";
        break;
      }

      case UserCategory.HOUSEKEEPER : {
        title = "House Keeper";
        break;
      }

      default: {
        break;
      }
    }

    return title;
  }
}
