import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { UserFull } from "../../models/new/user/user-full.model";
import { UserModelUtil } from "../../models/utils/user-model.util";
import { UserRepository } from "../../repository/user-repository";
import { AppService } from "../../services/app.service";
import { getContactMethodTypes } from "../../utils/utils";
import { CommonUtil } from "../../utils/common.util";
import { ChangePasswordPopupComponent } from "../../modules/shared/components/change-password-popup";

@Component({
  selector: "sd-homeowner-profile",
  template: `
    <div class="requiredHeight" style="margin-bottom: 50px" fxFlex="100%" fxLayout="column"
         fxLayoutAlign="flex-start">

      <div fxLayout="column" fxLayoutAlign.lt-sm="center center" fxLayoutGap="10px" fxFlex="90%"
           style="padding-left: 20px; padding-right: 20px">

        <h2 style="margin-left: 30px;padding-right: 20px">Profile Details:</h2>
        <form fxLayout="column" fxFlex="100%" [formGroup]="userProfileForm"
              (ngSubmit)="userProfileForm.valid && profileUpdate()">
          <div style="margin-top:20px;padding-left: 20px;padding-right: 20px" fxLayout="column" fxFlex="95%"
               fxLayoutGap="20px">

            <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
              <mat-form-field [color]="'accent'" class="width-1" dividerColor="accent">
                <mat-icon style="color:#194267" matSuffix>contacts</mat-icon>
                <input matInput placeholder="First Name" formControlName='first_name'>
                <mat-error> First Name is required</mat-error>
              </mat-form-field>

              <mat-form-field [color]="'accent'" class="width-1" dividerColor="accent">
                <mat-icon style="color:#194267" matSuffix>contacts</mat-icon>
                <input matInput placeholder="Last Name" formControlName='last_name'>
                <mat-error> Last Name is required</mat-error>
              </mat-form-field>
            </div>
            <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
              <mat-form-field [color]="'accent'" class="width-1" dividerColor="accent">
                <mat-icon style="color:#194267" matSuffix>email</mat-icon>
                <input matInput placeholder="Email" formControlName='email'>
                <mat-error> Email is required</mat-error>
              </mat-form-field>
              <mat-form-field [color]="'accent'" class="width-1" dividerColor="accent">
                <mat-icon style="color:#194267" matSuffix>email</mat-icon>
                <input matInput placeholder="Secondary Email" formControlName='secondary_email'>
                <mat-error>Secondary Email is required</mat-error>
              </mat-form-field>
            </div>

            <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
              <mat-form-field [color]="'accent'" class="width-1" dividerColor="accent">
                <input matInput placeholder="Phone Number" formControlName='phone_number'>
                <mat-error> Phone Number is required</mat-error>
              </mat-form-field>
              <mat-form-field [color]="'accent'" class="width-1" dividerColor="accent">
                <input matInput placeholder="Secondary Phone Number" formControlName='secondary_phone_number'>
              </mat-form-field>
            </div>

            <sd-select *ngIf="!isHouseKeeper" class="width-2"
                       placeholder="Preferred Contact Method"
                       [control]="preferredContactMethod"
                       [options]="contactMethodsOptions"></sd-select>

            <div *ngIf="isHouseKeeper && !isEmployee" fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
              <sd-select class="width-1"
                         placeholder="Preferred Contact Method"
                         [control]="preferredContactMethod"
                         [options]="contactMethodsOptions"></sd-select>

              <div class="width-1" fxLayout="row">
                <span>Display Expenses</span>
                <mat-slide-toggle color="accent" formControlName="show_expenses"></mat-slide-toggle>
              </div>
            </div>


            <mat-form-field [color]="'accent'" dividerColor="accent" class="textArea width-2">
                            <textarea matInput placeholder="Your Notes" rows="4" formControlName='description'>
                            </textarea>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center" fxLayoutGap.lt-sm="20px">
            <div fxLayout="row" fxLayoutGap="10px">
              <button mat-raised-button [disabled]="isProfileUpdating" class="dangerButton" type="submit"><span
                class="successSpan"><b>UPDATE PROFILE DETAILS</b></span>
              </button>
              <mat-spinner id="primary" [diameter]="30" [strokeWidth]="4" *ngIf="isProfileUpdating"></mat-spinner>
            </div>

            <button mat-raised-button type="button" color="accent"
                    (click)="openchangePassForm()"><b>CHANGE PASSWORD</b>
            </button>
          </div>
        </form>
      </div>
    </div>
  `,
  styles: [`

    #spinner {
      position: fixed;
      top: 45%;
      right: 50%;
    }

    #primary {
      width: 24px;
      height: 24px;
    }

    .profileImage {
      display: block;
      width: 150px;
      height: 150px;
      margin: 1em auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-border-radius: 99em;
      -moz-border-radius: 99em;
      border-radius: 99em;
      border: 5px solid #eee;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
    }

    .pre1 {
      max-height: 40%;
      width: 100%;
      flex: 0 0 0% !important;
      display: table;
      background-image: url(https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/user_cover.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #464646;
    }

    .width-1 {
      width: 48%;
    }

    .width-2 {
      width: 98%;
    }

    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 95%;
      }
    }
  `]
})

export class HomeownerProfileComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() user: UserFull;
  isHouseKeeper = false;
  isEmployee = false;
  isProfileUpdating = false;
  userProfileForm: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  userEmail: FormControl;
  userSecondaryEmail: FormControl;
  userPhoneNumber: FormControl;
  userSecondaryPhoneNumber: FormControl;
  preferredContactMethod: FormControl;
  expenseControl: FormControl;
  userNotes: FormControl;
  contactMethods;
  contactMethodsOptions: {title: string, value: any}[];
  uploadProgress = 0;
  UserModelUtil = UserModelUtil;
  @ViewChild("addImageWrapper", {read: ElementRef, static: true}) addImageWrapper: ElementRef;
  private isAlive = true;

  constructor(private userRepo: UserRepository,
              private appService: AppService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.isHouseKeeper = CommonUtil.isHouseKeeper(this.user);
    this.isEmployee = CommonUtil.isEmployee(this.user);
    this.setupUser();
  }

  ngAfterViewInit() {
    this.appService.changeAppTitle("Profile");
  }

  setupUser() {
    this.contactMethods = getContactMethodTypes();
    this.contactMethodsOptions = this.contactMethods.map(method => {
      return {title: method.title, value: method.slug};
    });

    this.firstName = new FormControl(this.user.first_name, [Validators.required]);
    this.lastName = new FormControl(this.user.last_name, [Validators.required]);
    this.userEmail = new FormControl(this.user.email, [Validators.required, Validators.email]);
    this.userSecondaryEmail = new FormControl(this.user.secondary_email);
    this.userPhoneNumber = new FormControl(this.user.phone_number, [Validators.required]);
    this.userSecondaryPhoneNumber = new FormControl(this.user.secondary_phone_number);
    this.preferredContactMethod = new FormControl(this.user.preferred_contact_method, [Validators.required]);
    this.userNotes = new FormControl(this.user.description);
    this.expenseControl = new FormControl(this.user.show_expenses);

    this.userProfileForm = new FormGroup({
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.userEmail,
      secondary_email: this.userSecondaryEmail,
      phone_number: this.userPhoneNumber,
      secondary_phone_number: this.userSecondaryPhoneNumber,
      preferred_contact_method: this.preferredContactMethod,
      description: this.userNotes,
      show_expenses: this.expenseControl,
    });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  profileUpdate() {
    this.isProfileUpdating = true;
    const data = this.userProfileForm.value;

    if (!this.isHouseKeeper) {
      delete data.show_expenses;
    }

    if (this.userProfileForm.get("email").value === this.user.email) {
      delete data.email;
      console.log(data);
    }
    this.userRepo.updateProfile(data).subscribe(() => {
      this.isProfileUpdating = false;
    }, () => {
      this.isProfileUpdating = false;
    });
  }

  openchangePassForm() {
    const dialogRef = this.dialog.open(ChangePasswordPopupComponent);
    dialogRef.updateSize("100%");
  }

}
