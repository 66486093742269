import { Action } from "../../../actions/action";
import { TaskCommentActionTypes } from "../../../actions/new/tasks/comment";
import { Comment} from "../../../models/new/tasks/comment";
import { ThreadMessageState } from "../inbox/thread-message";
import * as _ from "lodash";

export interface TaskCommentState {
  comments: { [taskId: number]: number[] },
  loadingTasks: number[],
  loadedTasks: number[]
}

export const initialState: TaskCommentState = {
  comments: {},
  loadingTasks: [],
  loadedTasks: [],
};

export function taskCommentReducer(state: TaskCommentState = initialState, action: Action): TaskCommentState {

  switch (action.type) {

    case TaskCommentActionTypes.INDEX_REQUEST: {

      const taskId = action.payload as number;
      let loadingTaskIds = state.loadingTasks;
      const loadingIndex = _.indexOf(loadingTaskIds, taskId);

      // Adding if not present
      if (loadingIndex === -1) {
        loadingTaskIds = [
          ...loadingTaskIds,
          taskId
        ];
      }

      let loadedTaskId = state.loadedTasks;
      const loadedIndex = _.indexOf(loadedTaskId, taskId);

      // Removing if already added
      if (loadedIndex !== -1) {
        loadedTaskId = _.remove(loadedTaskId, taskId);
      }


      return {
        ...state,
        loadingTasks: loadingTaskIds,
        loadedTasks: loadedTaskId,
      };

    }
    case TaskCommentActionTypes.INDEX_SUCCESS: {

      const comments = action.payload.comments as Comment[];
      const taskId = action.payload.taskId as number;

      console.log("type", comments);
      const ids = comments.map(m => m.id);


      let loadingTaskIds = state.loadingTasks;
      const loadingIndex = _.indexOf(loadingTaskIds, taskId);

      // Adding if not present
      if (loadingIndex !== -1) {
        loadingTaskIds = _.remove(loadingTaskIds, taskId);
      }

      let loadedTaskIds = state.loadedTasks;
      const loadedIndex = _.indexOf(loadedTaskIds, taskId);

      // Removing if already added
      if (loadedIndex === -1) {
        loadedTaskIds = [
          ...loadedTaskIds,
          taskId
        ];
      }

      return {
        ...state,
        loadingTasks: loadingTaskIds,
        loadedTasks: loadedTaskIds,
        comments: {
          [taskId]: ids,
          ...state.comments
        }
      }

    }

    case TaskCommentActionTypes.CREATE_SUCCESS: {
      const payload = action.payload as { comment: Comment, taskId: number };

      const comments = state.comments[payload.taskId] || [];

      return {
        ...state,
        comments: {
          ...state.comments,
          [payload.taskId]: [
            ...comments,
            payload.comment.id
          ]
        }
      }

    }

    case TaskCommentActionTypes.DELETE_SUCCESS: {
      const payload = action.payload as {taskId: number , commentId: number};

      let commentIds = state.comments[payload.taskId];
      const index = _.indexOf(commentIds , payload.commentId);
      console.log(index);
      console.log("--",commentIds);
      if (index !== -1) {
        commentIds = [
          ...commentIds.slice(0, index),
          ...commentIds.slice(index + 1)
        ];
        console.log("--",commentIds);
      }

      return {
        ...state,
        comments: {
          ...state.comments,
          [payload.taskId]: commentIds
        }
      }
    }

    default: {
      return state;
    }


  }
}

export const _getCommentIdsForTaskId = (state: TaskCommentState, taskId: number) => {
  return state.comments[taskId] || [];
};

export const _getCommentsLoadingForTaskId = (state: TaskCommentState, taskId: number) => {
  return state.loadingTasks.indexOf(taskId) !== -1;
};

export const _getCommentsLoadedForTaskId = (state: TaskCommentState, taskId: number) => {
  return state.loadedTasks.indexOf(taskId) !== -1;
};

