import { SpaceDraft } from "../../../../models/new/drafts/space-draft.model";
import { Action } from "../../../action";

export enum SpaceDraftActionTypes {
  INDEX_REQUEST = "[Space Drafts NEW] Index Request",
  INDEX_SUCCESS = "[Space Drafts NEW] Index Success",

  CREATE_REQUEST = "[Space Drafts NEW] Create Request",
  CREATE_SUCCESS = "[Space Drafts NEW] Create Success",

  SHOW_REQUEST = "[Space Drafts NEW] Show Request",
  SHOW_SUCCESS = "[Space Drafts NEW] Show Success",

  UPDATE_REQUEST = "[Space Drafts NEW] Update Request",
  UPDATE_SUCCESS = "[Space Drafts NEW] Update Success",

  DELETE_REQUEST = "[Space Drafts NEW] Delete Request",
  DELETE_SUCCESS = "[Space Drafts NEW] Delete Success"
}

export class SpaceDraftIndexRequest implements Action {
  readonly type = SpaceDraftActionTypes.INDEX_REQUEST;
}

export class SpaceDraftIndexSuccess implements Action {
  readonly type = SpaceDraftActionTypes.INDEX_SUCCESS;

  constructor(public payload: SpaceDraft[]) {
  }
}

export class SpaceDraftCreateRequest implements Action {
  readonly type = SpaceDraftActionTypes.CREATE_REQUEST;
}

export class SpaceDraftCreateSuccess implements Action {
  readonly type = SpaceDraftActionTypes.CREATE_SUCCESS;

  constructor(public payload: SpaceDraft) {
  }
}

export class SpaceDraftShowRequest implements Action {
  readonly type = SpaceDraftActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {

  }
}

export class SpaceDraftShowSuccess implements Action {
  readonly type = SpaceDraftActionTypes.SHOW_SUCCESS;

  constructor(public payload: SpaceDraft) {
  }
}

export class SpaceDraftUpdateRequest implements Action {
  readonly type = SpaceDraftActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class SpaceDraftUpdateSuccess implements Action {
  readonly type = SpaceDraftActionTypes.UPDATE_SUCCESS;

  constructor(public payload: SpaceDraft) {
  }
}

export class SpaceDraftDeleteRequest implements Action {
  readonly type = SpaceDraftActionTypes.DELETE_REQUEST;

  constructor(public payload: number) {
  }
}

export class SpaceDraftDeleteSuccess implements Action {
  readonly type = SpaceDraftActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
