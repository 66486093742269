import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import * as _ from "lodash";
import { SavedNeighbourhoodDraft } from "../../../models/new/drafts/saved-neighbourhood-draft.model";
import { Action } from "../../../actions/action";
import { SavedNeighbourhoodDraftActionTypes } from "../../../actions/new/setttings/drafts/saved-neighbourhood-drafts";

export interface SavedNeighbourhoodDraftState extends EntityState<SavedNeighbourhoodDraft> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const savedNeighbourhoodDraftAdapter: EntityAdapter<SavedNeighbourhoodDraft> = createEntityAdapter
  < SavedNeighbourhoodDraft > ({
    selectId: (savedNeighbourhoodDraft: SavedNeighbourhoodDraft) => savedNeighbourhoodDraft.id
  });

export const initialState: SavedNeighbourhoodDraftState = savedNeighbourhoodDraftAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function savedNeighbourhoodDraftReducer(state: SavedNeighbourhoodDraftState = initialState, action: Action): SavedNeighbourhoodDraftState {
  switch (action.type) {
    case SavedNeighbourhoodDraftActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        isLoaded: false
      }
    }

    case SavedNeighbourhoodDraftActionTypes.INDEX_SUCCESS: {
      const savedNeighbourhoodDrafts = action.payload as SavedNeighbourhoodDraft[];
      return savedNeighbourhoodDraftAdapter.addAll(savedNeighbourhoodDrafts, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case SavedNeighbourhoodDraftActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }

    case SavedNeighbourhoodDraftActionTypes.CREATE_SUCCESS: {
      const savedNeighbourhoodDraft = action.payload as SavedNeighbourhoodDraft;
      return savedNeighbourhoodDraftAdapter.addOne(savedNeighbourhoodDraft,{
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case SavedNeighbourhoodDraftActionTypes.SHOW_REQUEST: {
      const savedNeighbourhoodDraftId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, savedNeighbourhoodDraftId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          savedNeighbourhoodDraftId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, savedNeighbourhoodDraftId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, savedNeighbourhoodDraftId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case SavedNeighbourhoodDraftActionTypes.SHOW_SUCCESS: {
      const savedNeighbourhoodDraft = action.payload as SavedNeighbourhoodDraft;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, savedNeighbourhoodDraft.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, savedNeighbourhoodDraft.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, savedNeighbourhoodDraft.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          savedNeighbourhoodDraft.id
        ];
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case SavedNeighbourhoodDraftActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case SavedNeighbourhoodDraftActionTypes.UPDATE_SUCCESS: {
      const savedNeighbourhoodDraft = action.payload as SavedNeighbourhoodDraft;
      return savedNeighbourhoodDraftAdapter.updateOne({
        id: savedNeighbourhoodDraft.id,
        changes: savedNeighbourhoodDraft
      }, state);
    }

    case SavedNeighbourhoodDraftActionTypes.DELETE_REQUEST: {
      return {
        ...state
      };
    }

    case SavedNeighbourhoodDraftActionTypes.DELETE_SUCCESS: {
      const savedNeighbourhoodDraftId = action.payload as number;

      return savedNeighbourhoodDraftAdapter.removeOne(savedNeighbourhoodDraftId, state);
    }

    default: {
      return state;
    }

  }
}

export const _getIsSavedNeighbourhoodDraftLoading = (state: SavedNeighbourhoodDraftState) => state.isLoading;
export const _getIsSavedNeighbourhoodDraftLoaded = (state: SavedNeighbourhoodDraftState) => state.isLoaded;

export const _getIsFullSavedNeighbourhoodDraftLoading = (state: SavedNeighbourhoodDraftState, draftId: number) => {
  return state.fullLoadingIds.indexOf(draftId) !== -1;
};
export const _getIsFullSavedNeighbourhoodDraftLoaded = (state: SavedNeighbourhoodDraftState, draftId: number) => {
  return state.fullLoadedIds.indexOf(draftId) !== -1;
};

