import { Injectable } from "@angular/core";

import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { ThreadFull } from "../models/new/inbox/thread-full";
import { ThreadShowResponse } from "../models/responses/inboxes/threads/thread-show-response";
import { Feed } from "../models/new/feed";
import { FeedFilterType } from "../enums/feeds.enum";

@Injectable()
export class FeedsService {

  private selectedFilters: string[] = [];

  constructor(private apiService: ApiService) {
  }

  indexFeeds(page: number, showOnlyUnread: boolean, filters: string[], assignees: string[], assigneeType: string): Observable<any> {

    const data = {
      page: page,
      show_unread_only: showOnlyUnread ? 1 : 0,
      types: filters,
      assignees: assignees,
    };

    if (assigneeType){
      data["assignee_type"] = assigneeType
    }

    return this.apiService.get("/admin/feeds", true, data);
  }

  toggleIsOpened(id: number): Observable<any> {
    return this.apiService.put("/admin/feeds/" + id + "/toggle-is-opened", true);
  }

  markAllAsRead(): Observable<any> {
    return this.apiService.post("/admin/feeds/read-all", true, {});
  }

  setSelectedFilters(filters: string[]) {
    this.selectedFilters = filters;
  }

  getSelectedFilters(): string[] {
    return this.selectedFilters;
  }

}
