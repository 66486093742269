import { Note } from "../../../models/new/inbox/note";
import { Action } from "../../action";

export const NOTES_FETCH_REQUEST = "[NOTES] Notes fetch request";
export const NOTES_FETCH_SUCCESS = "[NOTES] Notes fetch success";
export const NOTES_ADD_SUCCESS = "[NOTES] Notes add success";
export const NOTES_DELETE_SUCCESS = "[ALERTS] Alerts delete success";

export class NotesFetchRequestAction implements Action {
  readonly type = NOTES_FETCH_REQUEST;
}

export class NotesFetchSuccessAction implements Action {
  readonly type = NOTES_FETCH_SUCCESS;

  constructor(public payload: Note[]) {
  }
}

export class NotesAddSuccessAction implements Action {
  readonly type = NOTES_ADD_SUCCESS;

  constructor(public payload: Note) {
  }
}

export class NotesDeleteSuccessAction implements Action {
  readonly type = NOTES_DELETE_SUCCESS;

  constructor(public payload: Note) {
  }
}

export type Actions
  = NotesFetchRequestAction
  | NotesFetchSuccessAction
  | NotesAddSuccessAction
  | NotesDeleteSuccessAction;
