import moment from "moment";
import { isNullOrUndefined, isString } from "util";

import { SD_DayHalf, SD_Hour, SD_Minute } from "../enums/common.enum";
import { UserCompact } from "../models/new/user/user-compact.model";

export class CommonUtil {
  static IMAGE_LISTING_PLACEHOLDER = "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/image-placeholder.jpg";

  /**
   * Ideally, the parameter type any should be replaced by typeof T but the compiler (TS 2.4) can't understand this syntax.
   * @param enumRef
   * @returns {T[]}
   */
  static getPropsOfEnum<T>(enumRef: any): T[] {
    return Object.keys(enumRef).map(key => enumRef[key]);
  }

  static getIsEnum<T>(enumRef: any, value: T): boolean {
    for (const key of Object.keys(enumRef)) {
      if (enumRef[key] === value) {
        return true;
      }
    }

    return false;
  }

  static sortByKey(items: any[], key: string, direction = "asc") {
    if (!key || !direction) {
      return items;
    }
    return [...items].sort((a, b) => {
      const propertyA: number | string = a[key];
      const propertyB: number | string = b[key];
      let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      let valueB = isNaN(+propertyB) ? propertyB : +propertyB;
      valueA = valueA.toString().toLowerCase().trim();
      valueB = valueB.toString().toLowerCase().trim();
      return (valueA < valueB ? -1 : 1) * (direction === "asc" ? 1 : -1);
    });
  }

  static sortByDateKey(items: any[], key: string, direction = "asc") {
    if (!key || !direction) {
      return items;
    }
    return [...items].sort((a, b) => {
      const valueA: string | null = a[key];
      const valueB: string | null = b[key];
      if (valueA === null && valueB !== null) {
        return 1;
      }
      if (valueA !== null && valueB === null) {
        return -1;
      }
      if (valueA === null && valueB === null) {
        return -1;
      }
      return (moment.utc(valueA).diff(moment.utc(valueB)) < 0 ? -1 : 1) * (direction === "asc" ? 1 : -1);
    });
  }

  static isOnMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook/i
      .test(navigator.userAgent);
  }

  static getTimeInDateFormat(hour: SD_Hour, minute: SD_Minute, dayHalf: SD_DayHalf) {
    if (hour && minute) {
      let hourString = "";

      if (dayHalf === SD_DayHalf.PM) {

        if (hour === SD_Hour.HOUR_12) {
          hourString = SD_Hour.HOUR_00.toString();
        } else {
          let numericHour = +hour;

          numericHour += 12;
          hourString = String(numericHour);
        }

        if (hourString.length === 1) {
          hourString = "0" + hourString;
        }

      } else {
        hourString = hour.toString();
      }

      return hourString + ":" + minute.toString();
    }

    return "00:00";
  }

  static getNumbersInDateFormat(day: number, month: number, year: number) {
    let dayString = `${day}`;
    let monthString = `${month}`;

    if (day < 10) {
      dayString = "0" + dayString;
    }

    if (month < 10) {
      monthString = "0" + monthString;
    }

    return `${year}-${monthString}-${dayString}`;
  }

  static formatLargeNumber(num: number): string {
    if (num > 1000000000) {
      return `${Math.floor(num / 1000000000)}.${Math.floor((num % 1000000000) / 10000000)} B`;
    } else if (num > 1000000) {
      return `${Math.floor(num / 1000000)}.${Math.floor((num % 1000000) / 10000)} M`;
    } else if (num > 1000) {
      return `${Math.floor(num / 1000)}.${Math.floor((num % 1000) / 10)} K`;
    }
    return `${num.toFixed(2)}`;
  }

  static checkNullString(string: string): string {
    if (string != null) {
      return " ".concat(string);
    } else {
      return "";
    }
  }

  static trimString(input: string = "", length: number = 200, sign: string = "..."): string {
    if (isNullOrUndefined(input)) {
      return;
    }
    const actualLength = input.length;

    let formattedString = input;

    if (actualLength > length) {
      const lengthOfSign = sign.length;
      formattedString = input.substring(0, length - lengthOfSign);
      formattedString = formattedString + sign;
    }

    return formattedString;
  }

  static isVendor(user: UserCompact) {
    return user.type === "management" &&
      user?.managementContact?.data?.category !== "homeowner" && user?.managementContact?.data?.category !== "agent";
  }

  static isHomeOwner(user: UserCompact) {
    return user.type === "management" &&
      user.managementContact?user?.managementContact?.data?.category === "homeowner":false;
  }

  static isHouseKeeper(user: UserCompact) {
    return user.type === "management" &&
      user?.managementContact?.data?.category === "housekeeper";
  }

  static isEmployee(user: UserCompact){
    return this.isVendor(user) && (!!user.managementContact.data.creator.data.managementContact && user.managementContact.data.creator.data.managementContact.data.category === "housekeeper")
  }

  static getDocumentUrl(title: string): string {
    switch (title.substring(title.lastIndexOf(".") + 1, title.length)) {
      case "pdf": {
        return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/attachment-icons/file-pdf.svg";
      }

      case "txt":
      case "rtf":
      case "dat": {
        return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/attachment-icons/file-document.svg";
      }

      case "doc":
      case "docx":
      case "docm":
      case "odt": {
        return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/attachment-icons/file-word.svg";
      }

      case "xls":
      case "xlr":
      case "xlsx":
      case "xlsm":
      case "ods":
      case "csv":
      case "tsv": {
        return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/attachment-icons/file-excel.svg";
      }

      case "ppt":
      case "pps":
      case "odp":
      case "pptx": {
        return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/attachment-icons/file-powerpoint.svg";
      }

      case "rar":
      case "tar":
      case "7z":
      case "zip": {
        return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/attachment-icons/file-zip.svg";
      }

      default: {
        return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/attachment-icons/file-document.svg";
      }
    }
  }

}
