import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { addDays, isToday } from "date-fns";
import * as moment from "moment";
import { interval as observableInterval, Subscription } from "rxjs";
import { map, takeWhile } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { BookingFull } from "../../../models/new/booking/booking-full.model";
import { Message } from "../../../models/new/inbox/message";
import { Note } from "../../../models/new/inbox/note";
import { ThreadFull } from "../../../models/new/inbox/thread-full";
import { UserFull } from "../../../models/new/user/user-full.model";
import { ThreadModelUtil } from "../../../models/utils/thread-model.util";
import { UserModelUtil } from "../../../models/utils/user-model.util";
import { getAdmins, State } from "../../../reducers";
import { ThreadRepository } from "../../../repository/thread.repository";
import { getDateObj } from "../../../utils/calendar-utils";
import DateUtils from "../../../utils/date";
import { DateFixPipe } from "../../shared/pipes/date-fix.pipe";
import { UTCtoLocalTimeZonePipe } from "../../shared/pipes/utc-to-local-time.pipe";

import { DeclinePopupComponent } from "./decline-popup";
import { DeclineRequestPopupComponent } from "./decline-request-popup";
import { MessageSpecialOfferPopupComponent } from "./message-special-offer";
import { UpdateNotePopupComponent } from "./update-note-popup";

@Component({
  selector: "sd-message",
  template: `
    <div class="chat-container test-card">
      <div class="chat">
        <ul>
          <div *ngFor="let message of messages">
            <li *ngIf="!message.offer && !message.is_status_message"
                [ngClass]="{'owner': message.type ==='incoming', 'guest': message.type ==='outgoing'}">
              <a class="user"><img
                onerror="this.src='https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/avatar-placeholder.png'"
                [src]="getImage(message.type)"/></a>
              <div class="date">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                  <div class="sender_name" *ngIf="message.type ==='incoming'" style="color: gray">
                    {{thread?.guest?.data.first_name}} {{thread?.guest?.data.last_name}} ({{getMessageSource(message)}})
                  </div>
                  <div>{{message.sent_on | utcToLocalTime | date:'medium'}}</div>
                </div>
              </div>
              <div class="sender_name" *ngIf="message.type ==='outgoing' && !adminLoading">
                <div>
                  {{getSendersName(message)}}
                  <span *ngIf="message.automated"
                        style="font-style: italic; font-size: 12px !important;">Automated</span>
                  ({{getMessageSource(message)}})
                </div>
              </div>
              <div class="message"
                   [ngClass]="{
                          'message-incoming': message.type ==='incoming',
                          'message-automated':  message.type !=='incoming' && message.automated,
                          'message-white':  message.type !=='incoming' && !message.automated && message.source == 'airbnb',
                          'message-duvet':  message.type !=='incoming' && !message.automated && message.source !== 'airbnb' && !message.to || message.to === 'guest',
                          'message-third':  message.type !=='incoming' && !message.automated && message.source !== 'airbnb' && message.to && message.to !== 'guest'
                        }"
                   *ngIf="message.source ==='email' && message.automated"
              >
                <sd-read-more [text]="message.content" type="html"></sd-read-more>
              </div>
              <div class="message"
                   [ngClass]="{
                          'message-incoming': message.type ==='incoming',
                          'message-automated':  message.type !=='incoming' && message.automated,
                          'message-white':  message.type !=='incoming' && !message.automated && message.source == 'airbnb',
                          'message-duvet':  message.type !=='incoming' && !message.automated && message.source !== 'airbnb' && !message.to || message.to === 'guest',
                          'message-third':  message.type !=='incoming' && !message.automated && message.source !== 'airbnb' && message.to && message.to !== 'guest'
                        }"
                   *ngIf="message.source ==='email' && !message.automated"
                   [innerHTML]="message.content"
              >
              </div>
              <div class="message" style="white-space: unset!important;"
                   [ngClass]="{
                          'message-incoming': message.type ==='incoming',
                          'message-automated':  message.type !=='incoming' && message.automated,
                          'message-white':  message.type !=='incoming' && !message.automated && message.source == 'airbnb',
                          'message-duvet':  message.type !=='incoming' && !message.automated && message.source !== 'airbnb' && !message.to || message.to === 'guest',
                          'message-third':  message.type !=='incoming' && !message.automated && message.source !== 'airbnb' && message.to && message.to !== 'guest'
                        }"
                   *ngIf="message.source !=='email'">
                <p *ngIf="!message.automated" style="width: 95%;line-height: 150%">{{ message.content}}</p>
                <sd-read-more *ngIf="message.automated" [text]="message.content"></sd-read-more>
              </div>
            </li>

            <div class="action-container"
                 *ngIf="message.is_status_message"
                 fxLayout="column">

              <div fxLayout="row">
                <span class="space-filler"></span>
                <div class="date">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <div class="sender_name" *ngIf="message.type ==='incoming'" style="color: gray">
                      ({{getMessageSource(message)}})
                    </div>
                    <div>
                      {{message.sent_on | utcToLocalTime | date:'medium'}}
                    </div>
                  </div>
                </div>
                <div class="sender_name" *ngIf="message.type ==='outgoing' && !adminLoading">
                  <div>
                    {{getSendersName(message)}}
                    <span *ngIf="message.automated"
                          style="font-style: italic; font-size: 12px !important;">Automated</span>
                    ({{getMessageSource(message)}})
                  </div>
                </div>
              </div>

              <mat-card class="status-card">
                <mat-card-title fxLayoutAlign="center center">
            <span style="font-size: 15px; font-weight: bolder; text-space: 1px;">
              {{message.content | html}}
            </span>
                </mat-card-title>
              </mat-card>
            </div>

            <div *ngIf="message.is_preapproval || message.offer" class="airbnb-alert" fxLayout="row"
                 fxLayoutAlign="space-around center" fxLayoutGap="5px">
              <div fxFlex="23%">
                <hr/>
              </div>
              <div fxFlex="50%" style="text-align: center;">
                <span *ngIf="message.is_preapproval && !isCurrentDay(message.sent_on)"
                      class="airbnb-alert-text">PRE APPROVED ON: {{ message.sent_on | dateFix | date:'MMM, dd'
                  }}</span>
                <span *ngIf="message.is_preapproval && isCurrentDay(message.sent_on)"
                      class="airbnb-alert-text">PRE APPROVED ON: {{ message.sent_on | utcToLocalTime | dateFormatting
                  }}</span>
                <span *ngIf="!message.is_preapproval"
                      class="airbnb-alert-text">SPECIAL OFFER: \${{ message?.offer?.price_per_night | roundOff
                  }}/PER-NIGHT</span>
              </div>
              <div fxFlex="23%">
                <hr/>
              </div>
            </div>
          </div>
          <div *ngIf="!messages || messages.length === 0" style="text-align:center; font-size: 20px; padding: 100px;">
            There is no message exist.
          </div>
        </ul>
      </div>
      <div class="action-container"
           *ngIf="booking?.source === 'airbnb' && booking?.status == 'inquiry'"
           fxLayout="row" fxLayoutAlign="center center">
        <mat-card class="generalCard" style="width: 100%">
          <mat-card-title fxLayout="column" fxLayoutAlign="start center">
            <div fxLayout="row" *ngIf="!!booking.requires_response">
              <mat-icon>timer</mat-icon>
              <span>Inquiry not replied since:&nbsp;</span>
              <span style="color: red!important;">{{timer}}</span>
            </div>

            <div fxLayout="row" *ngIf="!!booking.so_sent_on">
              <mat-icon>timer</mat-icon>
              <span *ngIf="!isCountDownExpired">Approval/Offer expires in:&nbsp;</span>
              <span *ngIf="!isCountDownExpired" style="color: red!important;">{{countDown}}</span>
              <span *ngIf="isCountDownExpired">Approval/Offer expired !</span>
            </div>

            <span *ngIf="!booking.so_sent_on" style="font-size: 15px; font-weight: bolder; text-space: 1px;">
              Invite {{ ThreadModelUtil.getGuest(thread)?.first_name }} to book by pre-approving this trip.
            </span>
          </mat-card-title>
          <ng-container *ngIf="!booking.so_sent_on">
            <mat-card-content fxLayout="row" fxLayoutAlign="center center">
              <p>Your calendar is still open until the guest books</p>
            </mat-card-content>
            <mat-card-actions>
              <div fxLayout="row" fxLayoutAlign="space-around center">
                <button mat-button [disabled]="inquiryLoading" *ngIf="!booking?.pre_approved" (click)="preApprove()">
                  Pre-approve
                </button>
                <button mat-button [disabled]="inquiryLoading" *ngIf="!booking?.denied" (click)="decline()"
                        [color]="'warn'">Decline
                </button>
                <button mat-button [disabled]="inquiryLoading" (click)="specialOffer()" [color]="'accent'">
                  <ng-container *ngIf="thread.status === 'special_offer'">Re-</ng-container>
                  Send Special Offer
                </button>
              </div>
            </mat-card-actions>
            <mat-card-footer>
              <mat-progress-bar [color]="'accent'" *ngIf="inquiryLoading" mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
          </ng-container>
        </mat-card>
      </div>

      <!--<div class="action-container"-->
      <!--*ngIf="booking?.source === 'homeaway' && booking?.status == 'inquiry'"-->
      <!--fxLayout="row" fxLayoutAlign="center center">-->
      <!--<mat-card class="generalCard" style="width: 100%">-->
      <!--<mat-card-title fxLayout="column" fxLayoutAlign="start center">-->
      <!--<div fxLayout="row">-->
      <!--<mat-icon>timer</mat-icon>-->
      <!--<span>This inquiry was created &nbsp;</span>-->
      <!--<span>{{ countDown }}</span>-->
      <!--</div>-->
      <!--<span style="font-size: 15px; font-weight: bolder; text-space: 1px;">-->
      <!--Invite {{ ThreadModelUtil.getGuest(thread)?.first_name }} to book by pre-approving this trip.-->
      <!--</span>-->
      <!--</mat-card-title>-->
      <!--<mat-card-content fxLayout="row" fxLayoutAlign="center center">-->
      <!--<p>Your calendar is still open until the guest books</p>-->
      <!--</mat-card-content>-->
      <!--<mat-card-actions>-->
      <!--<div fxLayout="row" fxLayoutAlign="space-around center">-->
      <!--<button mat-button *ngIf="!booking?.pre_approved" (click)="preApprove()">Pre-approve</button>-->
      <!--<button mat-button *ngIf="!booking?.denied" (click)="decline()" [color]="'warn'">Decline-->
      <!--</button>-->
      <!--<button mat-button (click)="specialOffer()" [color]="'accent'">-->
      <!--<ng-container *ngIf="thread.status === 'special_offer'">Re-</ng-container>-->
      <!--Send Special Offer-->
      <!--</button>-->
      <!--</div>-->
      <!--</mat-card-actions>-->
      <!--<mat-card-footer>-->
      <!--<mat-progress-bar [color]="'accent'" *ngIf="inquiryLoading" mode="indeterminate"></mat-progress-bar>-->
      <!--</mat-card-footer>-->
      <!--</mat-card>-->
      <!--</div>-->


      <div class="action-container"
           *ngIf="booking?.source === 'airbnb' && (booking?.status === 'requested' || booking?.status === 'pending')"
           fxLayout="row"
           fxLayoutAlign="center center">
        <mat-card class="generalCard" style="width: 100%">
          <mat-card-title fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon>timer</mat-icon>
              <span *ngIf="!isCountDownExpired">Request expires in:&nbsp;</span>
              <span *ngIf="!isCountDownExpired" style="color: red!important;">{{countDown}}</span>
              <span *ngIf="isCountDownExpired">Request expired !</span>
            </div>
            <span *ngIf="!isCountDownExpired" style="font-size: 15px; font-weight: bolder; text-space: 1px;">
              {{ThreadModelUtil.getGuest(thread)?.first_name }} has requested to book.
            </span>
          </mat-card-title>
          <ng-container *ngIf="!isCountDownExpired">
            <mat-card-content>
              <p fxLayoutAlign="center center">Would you like to accept/decline the booking request?</p>
            </mat-card-content>
            <mat-card-actions>
              <div fxLayout="row" fxLayoutAlign="space-around center">
                <button mat-button (click)="acceptBookingRequest()">Accept</button>
                <button mat-button (click)="declineBookingRequest()" [color]="'warn'">Decline</button>
              </div>
            </mat-card-actions>
            <mat-card-footer>
              <mat-progress-bar *ngIf="reservationLoading" mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
          </ng-container>
        </mat-card>
      </div>
      <div *ngIf="(user?.is_admin || user?.managementContact.data.category === 'agent') && notes?.length > 0" class="chat-container">
        <hr>
        <div class="chat">
          <ul>
            <div *ngFor="let note of notes">
              <li class="owner">
                <a class="user"><img [src]="getNoteImage(note)"/></a>
                <div class="date">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <div class="sender_name" style="color: gray">
                      {{getCreatorName(note)}} (Note)
                    </div>
                    <div>{{note.created_at.date | utcToLocalTime | date:'medium'}}</div>
                  </div>
                </div>
                <div class="message note" style="white-space: unset!important;">
                  <div style="display: flex; justify-content: space-between">
                    <p style="width: 95%;line-height: 150%" [innerHTML]="note.description"></p>
                    <span style="display: flex;">
<!--                      <mat-icon style="font-size: medium; cursor: pointer" (click)="updateNote(note)">edit</mat-icon>-->
                      <mat-icon style="font-size: medium; cursor: pointer" (click)="closeNote(note)">clear</mat-icon>
                    </span>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  `,
  styles: [`

    .action-container {
      width: 98%;
      margin: 10px;
    }

    span {
      font-size: 18px !important;
      font-weight: bold !important;
      color: #364f66 !important;
    }

    hr {
      width: 100%;
      color: #0A253E;
    }

    .airbnb-alert {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .airbnb-alert-text {
      font-size: 14px;
      font-weight: bolder;
    }

    .message {
      min-height: 45px;
      white-space: pre-line;
    }

    .status-card {
      background: #F2F2F2;
      padding: 15px !important;
    }

    body {
      background-color: #D3D3D3;

    }

    .chat-container {
      background-color: #fff;
      padding: 5px;
      overflow-x: hidden !important;
    }

    .chat ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .chat ul li {
      margin: 45px 0 0 0;
      font-weight: 300;
    }

    .chat ul li a.user {
      margin: -30px 0 0 0;
      display: block;
      color: #333;
    }

    .chat ul li a.user img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background-color: #f3f3f3;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    }

    .chat ul li .date {
      font-size: 14px;
      color: #a6a6a6;
    }

    .chat ul li .sender_name {
      font-size: 14px;
      color: #373737;
    }

    .chat ul li .message {
      color: #000000;
      display: block;
      padding: 10px;
      position: relative;
      font-size: 15px;
      border-radius: 3px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    }

    .chat ul li .message:before {
      content: '';
      position: absolute;
      border-top: 16px solid rgba(0, 0, 0, 0.15);
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
    }

    .chat ul li .message:after {
      content: '';
      position: absolute;
      top: 0;
      border-left: 17px solid transparent;
      border-right: 17px solid transparent;
    }

    .chat ul li .message-automated:after {
      border-top: 17px solid #14738F;
    }

    .chat ul li .message-white:after {
      border-top: 17px solid white;
    }

    .chat ul li .message-duvet:after {
      border-top: 17px solid #B2CCD0;
    }

    .chat ul li .message-third:after {
      border-top: 17px solid #CD6949;
    }

    .chat ul li .message-incoming:after {
      border-top: 17px solid #5ABAC7;
    }

    .chat ul li .note:after {
      border-top: 17px solid yellow;
    }

    .chat ul li .message-automated {
      background-color: #14738F;
    }

    .chat ul li .message-white {
      background-color: white;
    }

    .chat ul li .message-duvet {
      background-color: #B2CCD0;
    }

    .chat ul li .message-third {
      background-color: #CD6949;
    }

    .chat ul li .message-incoming {
      background-color: #5ABAC7;
    }

    .chat ul li .note {
      background-color: yellow;
    }

    .chat ul li .message.blur p {
      -webkit-filter: blur(3px);
      -moz-filter: blur(3px);
      -o-filter: blur(3px);
      -ms-filter: blur(3px);
      filter: blur(3px);
    }

    .chat ul li .message.blur .hider {
      opacity: 1;
      z-index: 1;
    }

    .chat ul li .message p {
      margin: 0;
      padding: 0;
      transition: all 0.1s;
    }

    .chat ul li .message .hider {
      opacity: 0;
      z-index: -1;
      position: absolute;
      height: 100%;
      width: 100%;
      margin: -10px;
      text-align: center;
      cursor: pointer;
      transform-style: preserve-3d;
      transition: all 0.1s;
    }

    .chat ul li .message .hider span {
      display: block;
      position: relative;
      top: 50%;
      font-size: 16px;
      transform: translateY(-50%);
    }

    .chat ul li.guest a.user {
      float: right;
    }

    .chat ul li.guest .date {
      float: left;
      margin: 0 10px 0 0;
    }

    .chat ul li.guest .message {
      margin: 0 90px 0 0;
    }

    .chat ul li.guest .message:before {
      margin: -9px -16px 0 0;
      right: 0;
    }

    .chat ul li.guest .message:after {
      content: '';
      right: 0;
      margin: 0 -15px 0 0;
    }

    .chat ul li.owner a.user {
      float: left;
    }

    .chat ul li.owner .date {
      float: left;
      margin: -20px 0 0 10px;
    }

    .chat ul li.owner .sender_name {
      float: right;
      margin: 0 10px 0 0;
    }

    .chat ul li.owner .message {
      margin: 0 0 0 90px;
    }

    .chat ul li.owner .message:before {
      margin: -9px 0 0 -16px;
      left: 0;
    }

    .chat ul li.owner .message:after {
      content: '';
      left: 0;
      margin: 0 0 0 -15px;
    }
  `]
})
export class MessagesDisplayComponent implements OnInit, OnDestroy {

  defaultImage = "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/avatar-placeholder.png";

  inquiryLoading = false;
  reservationLoading = false;
  countDown: string;
  timer: string;
  isCountDownExpired = false;
  isAlive = true;

  admins: { [id: number]: string } = {};
  adminImg: { [id: number]: string } = {};
  // noteAdmins: {value: string, id: number}[] = [];
  adminLoading = true;
  ThreadModelUtil = ThreadModelUtil;

  @Input() messages: Message[];
  @Input() notes: Note[];
  @Input() thread: ThreadFull;
  @Input() booking: BookingFull;
  @Input() user: UserFull;

  private dialogRef: MatDialogRef<any>;
  private countDown$: Subscription;
  private timer$: Subscription;

  constructor(private threadRepo: ThreadRepository,
              private dialog: MatDialog,
              private store: Store<State>) {
  }

  ngOnInit(): void {
    console.log("OnInit chat-container");
    this.loadAdmins();

    if (this.booking && !!this.booking.requires_response) {
      const UTCtoLocal = new UTCtoLocalTimeZonePipe();
      const time = UTCtoLocal.transform(this.booking.inquiry_sent_on);
      this.setUpTimer(time);
    }

    if (this.booking && !!this.booking.so_sent_on) {
      const UTCtoLocal = new UTCtoLocalTimeZonePipe();
      const time = UTCtoLocal.transform(this.booking.so_sent_on);
      this.setUpCountDownTimer(time);
    }

    if (this.booking && !!this.booking.request_sent_on) {
      const UTCtoLocal = new UTCtoLocalTimeZonePipe();
      const time = UTCtoLocal.transform(this.booking.request_sent_on);
      this.setUpCountDownTimer(time);
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  loadAdmins() {
    this.adminLoading = true;
    this.store.select(getAdmins).pipe(takeWhile(() => this.isAlive)).subscribe((admins) => {
      for (let i = 0; i < admins.length; i++) {
        this.admins[admins[i].admin.data.id] = UserModelUtil.getFullName(admins[i]);
      }
      for (let i = 0; i < admins.length; i++) {
        this.adminImg[admins[i].admin.data.id] = UserModelUtil.getProfilePic(admins[i]);
      }
      // this.noteAdmins = admins.map(admin => {
      //   return {value: admin.first_name + " " + admin.last_name, id: admin.id};
      // });
      this.adminLoading = false;
    });
  }

  preApprove() {
    this.inquiryLoading = true;
    this.threadRepo.preApproveBooking(this.thread.id, this.booking.id).subscribe((success) => {
      this.inquiryLoading = false;
    }, err => {
      this.inquiryLoading = false;
    });

  }

  decline() {
    this.dialogRef = this.dialog.open(DeclinePopupComponent);

    this.dialogRef.updateSize("100%");
    const componentInstance = this.dialogRef.componentInstance;

    componentInstance.bookingId = this.booking.id;
    componentInstance.threadId = this.thread.id;
  }

  specialOffer() {
    this.dialogRef = this.dialog.open(MessageSpecialOfferPopupComponent, {
      data: {
        booking: this.booking,
        thread: this.thread
      }
    });
    this.dialogRef.updateSize("100%");
  }

  getImage(type: string): string {

    if (type === "incoming") {
      const guest = ThreadModelUtil.getGuest(this.thread);

      if (isNullOrUndefined(guest)) {
        return this.defaultImage;
      }

      return guest.pic_thumb_url;
    } else {
      const account = ThreadModelUtil.getAccount(this.thread);

      if (isNullOrUndefined(account)) {
        return this.defaultImage;
      }

      return account.pic_thumb_url;
    }
  }

  getNoteImage(note: Note) {
    if (isNullOrUndefined(note.creator_id)) {
      return this.defaultImage;
    } else {
      return this.adminImg[note.creator_id];
    }
  }

  getSendersName(message: Message) {
    if (isNullOrUndefined(message.sender_id)) {
      return "";
    } else {
      return this.admins[message.sender_id];
    }
  }

  getCreatorName(note: Note) {
    if (isNullOrUndefined(note.creator_id)) {
      return "";
    } else {
      return this.admins[note.creator_id];
    }
  }

  getMessageSource(message: Message) {
    if (message.source === "airbnb") {
      return "via Airbnb";
    }

    if (message.source === "email") {
      return "via Email";
    }

    if (message.source === "message") {
      return "via Text";
    }

    if (message.source === "homeaway") {
      return "via VRBO";
    }
  }

  acceptBookingRequest() {
    this.reservationLoading = true;
    if (!!this.thread.booking_pal_thread_id) {
      this.threadRepo.acceptReservationRequestMbp(this.thread.id, this.booking.id).subscribe((success) => {
        this.reservationLoading = false;
      });
    } else {
      this.threadRepo.acceptReservationRequest(this.thread.id, this.booking.id).subscribe((success) => {
        this.reservationLoading = false;
      });
    }
  }

  declineBookingRequest() {
    this.dialogRef = this.dialog.open(DeclineRequestPopupComponent, {
      data: {
        booking: this.booking,
        thread: this.thread
      }
    });
    this.dialogRef.updateSize("100%");
  }

  getText(string) {
    const UTCtoLocal = new UTCtoLocalTimeZonePipe();
    const datePipe = new DateFixPipe();
    const date = new Date(UTCtoLocal.transform(datePipe.transform(string)));
    return date.toDateString() + " " + date.toLocaleTimeString();
  }

  setUpCountDownTimer(end: Date) {
    const finishTime = addDays(end, 1);
    this.countDown$ = observableInterval(1000).pipe(
      takeWhile(() => this.isAlive),
      map(() => {
        return moment(finishTime.getTime() - getDateObj().getTime()).toDate();
      }),).subscribe((time: Date) => {
      const seconds = Math.floor(time.getTime() / 1000);
      if (seconds > 0) {
        this.countDown = DateUtils.formatTime(time);
        this.isCountDownExpired = false;
      } else {
        this.isCountDownExpired = true;
        this.countDown$.unsubscribe();
      }
    });

  }

  setUpTimer(startTime: Date) {
    this.timer$ = observableInterval(1000).pipe(
      takeWhile(() => this.isAlive),
      map(() => {
        return moment(getDateObj().getTime() - startTime.getTime()).toDate();
      }),)
      .subscribe((time: Date) => {
        this.timer = DateUtils.formatTime(time);
      });
  }

  isCurrentDay(date: string) {
    return isToday(getDateObj(date));
  }

  closeNote(note: Note) {
    this.threadRepo.closeNote(note.id).subscribe(res => {});
  }

  // updateNote(note: Note) {
  //   this.dialogRef = this.dialog.open(UpdateNotePopupComponent);
  //   this.dialogRef.updateSize("50%", "30%");
  //   const componentInstance = this.dialogRef.componentInstance;
  //   componentInstance.admins = this.noteAdmins;
  //   componentInstance.content = note.description;
  //   componentInstance.id = note.id;
  // }
}
