import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

import { Action } from "../../../actions/action";
import { ReviewResponseDraftActionTypes } from "../../../actions/new/setttings/drafts/review-response-drafts";
import { ReviewResponseDraft } from "../../../models/new/drafts/review-response-draft.model";

export interface ReviewResponseDraftState extends EntityState<ReviewResponseDraft> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const reviewResponseDraftAdapter: EntityAdapter<ReviewResponseDraft> = createEntityAdapter
  < ReviewResponseDraft > ({
    selectId: (reviewResponseDraft: ReviewResponseDraft) => reviewResponseDraft.id
  });

export const initialState: ReviewResponseDraftState = reviewResponseDraftAdapter.getInitialState({
  isLoading: false,
  isLoaded: false
});

export function reviewResponseDraftReducer(state: ReviewResponseDraftState = initialState, action: Action): ReviewResponseDraftState {
  switch (action.type) {
    case ReviewResponseDraftActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        isLoaded: false
      };
    }

    case ReviewResponseDraftActionTypes.INDEX_SUCCESS: {
      const reviewResponseDrafts = action.payload as ReviewResponseDraft[];
      return reviewResponseDraftAdapter.addAll(reviewResponseDrafts, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case ReviewResponseDraftActionTypes.CREATE_SUCCESS: {
      const reviewResponseDraft = action.payload as ReviewResponseDraft;
      return reviewResponseDraftAdapter.addOne(reviewResponseDraft, state);
    }

    case ReviewResponseDraftActionTypes.UPDATE_SUCCESS: {
      const reviewResponseDraft = action.payload as ReviewResponseDraft;
      return reviewResponseDraftAdapter.updateOne({
        id: reviewResponseDraft.id,
        changes: reviewResponseDraft
      }, state);
    }

    case ReviewResponseDraftActionTypes.DELETE_SUCCESS: {
      const reviewResponseDraftId = action.payload as number;
      return reviewResponseDraftAdapter.removeOne(reviewResponseDraftId, state);
    }

    default: {
      return state;
    }
  }
}

export const _getIsReviewResponseDraftLoading = (state: ReviewResponseDraftState) => state.isLoading;
export const _getIsReviewResponseDraftLoaded = (state: ReviewResponseDraftState) => state.isLoaded;
