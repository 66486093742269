import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BookingStatus } from "../enums/booking.enum";
import { __HTTPResponseType, SortOrder } from "../enums/common.enum";
import { ThreadFilter } from "../enums/thread-filter.enum";
import { Message } from "../models/new/inbox/message";
import { Note } from "../models/new/inbox/note";
import { ThreadFull } from "../models/new/inbox/thread-full";
import { ThreadGetResponse } from "../models/responses/inboxes/threads/thread-get-response";
import { ThreadShowResponse } from "../models/responses/inboxes/threads/thread-show-response";
import { dateToDateString, getDateObj } from "../utils/calendar-utils";

import { ApiService } from "./api.service";

@Injectable()
export class ThreadService {

  private THREAD_INDEX_INCLUDES = "bookings,guest,assignee";
  private THREAD_SHOW_INCLUDES = "owner,guest,bookings,airbnbAccount,assignee,bookings.tasks";

  constructor(private apiService: ApiService) {

  }

  // All Threads related API calls

  showThread(threadId: number, bookingId: number): Observable<ThreadFull> {
    const data: any = {
      include: this.THREAD_SHOW_INCLUDES
    };

    if (bookingId) {
      data.booking_id = bookingId;
    }

    return this.apiService.get<ThreadShowResponse>("/admin/threads/" + threadId, true, data).pipe(map(res => res.data));
  }

  updateThread(threadId: number, data: any): Observable<ThreadFull> {
    return this.apiService.put<ThreadShowResponse>("/admin/threads/" + threadId, true, {
      ...data,
      include: this.THREAD_SHOW_INCLUDES
    })
      .pipe(map(res => res.data));
  }

  preApproveBooking(threadId: number, bookingId: number): Observable<boolean> {
    return this.apiService.post<boolean>("/admin/bookings/" + bookingId + "/pre-approve", true, {thread_id: threadId}).pipe(map((res) => {
      return true;
    }));
  }

  declineBooking(threadId: number, bookingId: number, message: string): Observable<boolean> {
    return this.apiService.post<boolean>("/admin/bookings/" + bookingId + "/decline", true, {
      message: message,
      thread_id: threadId
    }).pipe(map((res) => {
      return true;
    }));
  }

  getThreadByBooking(bookingId): Observable<ThreadFull> {
    return this.apiService.get<{ data: ThreadFull }>("/admin/bookings/" + bookingId + "/thread", true, {
      include: this.THREAD_SHOW_INCLUDES
    }, null, true, null, false).pipe(map((res) => res.data));
  }

  unreadThread(threadId: number): Observable<ThreadFull> {
    return this.apiService.post<{ data: ThreadFull }>("/admin/threads/" + threadId + "/unread", true, {include: this.THREAD_SHOW_INCLUDES}).pipe(map((res) => res.data));
  }

  createThreadFromBooking(bookingId: number): Observable<ThreadFull> {
    return this.apiService.post<{ data: ThreadFull }>("/admin/bookings/" + bookingId + "/thread", true, {include: this.THREAD_SHOW_INCLUDES}).pipe(map((res) => res.data));
  }

  assignToThread(threadId: number, adminId: number): Observable<ThreadFull> {
    return this.apiService.put<{ data: ThreadFull }>("/admin/threads/" + threadId + "/assign/" + adminId, true, {include: this.THREAD_SHOW_INCLUDES})
      .pipe(map(res => res.data));
  }

  getThreads(page: number, threadFilter: ThreadFilter[], listingIds: number[], assigneeIds: number[], offset: string, offsetDate: string): Observable<ThreadGetResponse> {

    let data: any = {};

    threadFilter.forEach(filter => {
      data = {
        ...data,
        ...this.getDataByThreadFilter(filter, data.source)
      };
    });

    if (offset) {
      data = {
        ...data,
        offset: offset,
        offset_date: dateToDateString(getDateObj(offsetDate))
      };
    }

    console.log("data", data);

    const req: any = {
      page: page,
      listing_ids: listingIds,
      ...data,
      include: this.THREAD_INDEX_INCLUDES
    };
    if(!!assigneeIds.length) {
      req.assigned = 1;
      req.assignee_ids = assigneeIds;
    }
    return this.apiService.get<ThreadGetResponse>("/admin/threads-new", true, req);
  }

  getMessagesForThread(threadId: number): Observable<Message[]> {
    return this.apiService.get<{ data: Message[] }>("/admin/threads/" + threadId + "/messages", true).pipe(map((res) => res.data));
  }

  sendAirbnbMessage(data: { content: string, thread_id?: number }, threadId: number, bookingId: number): Observable<Message> {
    data.thread_id = threadId;
    return this.apiService.post<{ data: Message }>("/admin/bookings/" + bookingId + "/messages/airbnb", true, data).pipe(map((res) => res.data));
  }

  sendBookingPalMessage(data: { content: string, thread_id?: number }, threadId: number, bookingId: number): Observable<Message> {
    data.thread_id = threadId;
    return this.apiService.post<{ data: Message }>("/admin/bookings/" + bookingId + "/messages/bookingpal", true, data).pipe(map((res) => res.data));
  }

  sendSMSMessage(data: { content: string, thread_id?: number}, threadId: number, bookingId: number): Observable<Message> {
    data.thread_id = threadId;
    return this.apiService.post<{ data: Message }>("/admin/bookings/" + bookingId + "/messages/sms", true, data).pipe(map((res) => res.data));
  }

  sendEmailMessage(data: { subject: string, content: string, thread_id?: number }, threadId: number, bookingId: number): Observable<Message> {
    data.thread_id = threadId;
    return this.apiService.post<{ data: Message }>("/admin/bookings/" + bookingId + "/messages/email", true, data).pipe(map((res) => res.data));
  }

  addNote(data: { description: string, thread_id: number, mentions?: number[] }): Observable<Note> {
    return this.apiService.post<{ data: Note }>("/admin/notes", true, data).pipe(map(res => res.data));
  }

  getNotes(threadId: number): Observable<Note[]> {
    return this.apiService.get<{ data: Note[] }>("/admin/notes/" + threadId, true).pipe(map(res => res.data));
  }

  closeNote(noteId: number): Observable<Note> {
    return this.apiService.put<{ data: Note }>("/admin/notes/close/" + noteId, true).pipe(map(res => res.data));
  }

  updateNote(data: { description: string, mentions?: number[] }, noteId: number): Observable<Note> {
    return this.apiService.put<{ data: Note }>("/admin/notes/" + noteId, true, data).pipe(map(res => res.data));
  }

  acceptReservationRequest(threadId: number, bookingId: number): Observable<boolean> {
    return this.apiService.post("/admin/bookings/" + bookingId + "/res-req-accept-airbnb", true, {
      thread_id: threadId
    }, null, true, __HTTPResponseType.TEXT).pipe(map((res) => {
      return true;
    }));
  }

  acceptReservationRequestMbp(threadId: number, bookingId: number): Observable<boolean> {
    return this.apiService.post("/admin/bookings/" + bookingId + "/res-req-accept-mbp", true, {
      thread_id: threadId
    }, null, true, __HTTPResponseType.TEXT).pipe(map((res) => {
      return true;
    }));
  }

  declineReservationRequest(threadId: number, bookingId: number, data: {
    reason: string,
    guest_message: string,
    airbnb_message?: string,
    thread_id?: number
  }): Observable<boolean> {
    data.thread_id = threadId;
    return this.apiService.post("/admin/bookings/" + bookingId + "/res-req-decline-airbnb", true, data).pipe(map((res) => {
      return true;
    }));
  }

  declineReservationRequestMbp(threadId: number, bookingId: number, data: {
    block_dates: boolean,
    reason: string,
    guest_message: string,
    airbnb_message?: string,
    thread_id?: number
  }): Observable<boolean> {
    data.thread_id = threadId;
    return this.apiService.post("/admin/bookings/" + bookingId + "/res-req-decline-mbp", true, data).pipe(map((res) => {
      return true;
    }));
  }

  sendSpecialOffer(threadId: number, bookingId: number, data: {
    start: any,
    end: any,
    number_of_adults: number,
    number_of_children: number,
    number_of_infants: number,
    price: number,
    thread_id?: number,
  }): Observable<boolean> {
    data.thread_id = threadId;
    return this.apiService.post("/admin/bookings/" + bookingId + "/special-offer", true, data).pipe(map((res) => {
      return true;
    }));
  }

  bulkArchive(data) {
    return this.apiService.put("/admin/threads/bulk-archive", true, data).pipe(map(res => true));
  }

  getDataByThreadFilter(filter: ThreadFilter, source): any {
    switch (filter) {
      case ThreadFilter.UNREAD: {
        return {is_opened: 0};
      }

      case ThreadFilter.ARCHIVED: {
        return {is_archived: 1};
      }

      case ThreadFilter.ONGOING: {
        return {ongoing: 1};
      }

      case ThreadFilter.FOLLOW_UP: {
        return {is_marked_for_followup: 1};
      }

      case ThreadFilter.FAVOURITE: {
        return {is_favourite: 1};
      }

      case ThreadFilter.UPCOMING: {
        return {is_upcoming: 1};
      }

      case ThreadFilter.INQUIRIES: {
        return {inquiries: 1};
      }

      case ThreadFilter.OUTGOING: {
        return {outgoing: 1};
      }

      case ThreadFilter.AIRBNB: {
        if (!!source) {
          return {source: [...source, "airbnb"]};
        } else {
          return {source: ["airbnb"]};
        }
      }

      case ThreadFilter.HOMEAWAY: {
        if (!!source) {
          return {source: [...source, "homeaway"]};
        } else {
          return {source: ["homeaway"]};
        }
      }

      case ThreadFilter.STAY_DUVET: {
        if (!!source) {
          return {source: [...source, "stayduvet"]};
        } else {
          return {source: ["stayduvet"]};
        }
      }

      case ThreadFilter.MARRIOTT: {
        if (!!source) {
          return {source: [...source, "hvmi"]};
        } else {
          return {source: ["hvmi"]};
        }
      }

      case ThreadFilter.PLUM_GUIDE: {
        if (!!source) {
          return {source: [...source, "plum_guide"]};
        } else {
          return {source: ["plum_guide"]};
        }
      }

      case ThreadFilter.REFERRAL: {
        if (!!source) {
          return {source: [...source, "referral"]};
        } else {
          return {source: ["referral"]};
        }
      }

      default: {
        return;
      }
    }
  }

}
