import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { RoutingRule } from "../../../models/new/listing/routing-rule";
import { Action } from "../../../actions/action";
import { RoutingRulesActionTypes } from "../../../actions/new/listings/routing-rules";
import * as _ from "lodash";

export interface RoutingRulesState extends EntityState<RoutingRule> {
  loadingListingIds: number[];
  loadedListingIds: number[];

  ruleIdsForListing: { [listingId: number]: number[] };
}

export const routingRulesAdapter: EntityAdapter<RoutingRule> = createEntityAdapter<RoutingRule>({
  selectId: (rule: RoutingRule) => rule.id,
});

export const initialState: RoutingRulesState = routingRulesAdapter.getInitialState({
  loadingListingIds: [],
  loadedListingIds: [],
  ruleIdsForListing: {}
});

export function routingRuleReducer(state: RoutingRulesState = initialState, action: Action): RoutingRulesState {

  switch (action.type) {

    case RoutingRulesActionTypes.INDEX_REQUEST: {
      const listingId = action.payload as number;

      let loadingListingId = state.loadingListingIds;
      const loadingIndex = _.indexOf(loadingListingId, listingId);

      if (loadingIndex === -1) {
        loadingListingId = [
          ...loadingListingId,
          listingId
        ];
      }

      let loadedListingId = state.loadedListingIds;
      const loadedIndex = _.indexOf(loadedListingId, listingId);

      // Removing if already added
      if (loadedIndex !== -1) {
        loadedListingId = _.remove(loadedListingId, listingId);
      }

      return {
        ...state,
        loadingListingIds: loadingListingId,
        loadedListingIds: loadedListingId,
      }
    }

    case RoutingRulesActionTypes.INDEX_SUCCESS: {
      const payload = action.payload as { rules: RoutingRule[], listingId: number };

      let loadedListingId = state.loadedListingIds;
      const loadedIndex = _.indexOf(loadedListingId, payload.listingId);

      if (loadedIndex === -1) {
        loadedListingId = [
          ...loadedListingId,
          payload.listingId
        ];
      }

      let loadingListingId = state.loadingListingIds;
      const loadingIndex = _.indexOf(loadingListingId, payload.listingId);

      // Removing if already added
      if (loadingIndex !== -1) {
        loadingListingId = _.remove(loadingListingId, payload.listingId);
      }

      return routingRulesAdapter.addMany(payload.rules, {
        ...state,
        loadingListingIds: loadingListingId,
        loadedListingIds: loadedListingId,
        ruleIdsForListing: {
          ...state.ruleIdsForListing,
          [payload.listingId]: payload.rules.map(t => t.id)
        }
      })
    }


    case RoutingRulesActionTypes.UPDATE_SUCCESS: {
      const rule = action.payload as RoutingRule;

      const newState = routingRulesAdapter.addOne(rule, state);

      let listingRules = state.ruleIdsForListing[rule.property_id];

      const ruleIndex = _.indexOf(listingRules, rule.id);

      listingRules = [
        ...listingRules.slice(0, ruleIndex),
        rule.id,
        ...listingRules.slice(ruleIndex + 1)
      ];


      return routingRulesAdapter.updateOne({
        id: rule.id,
        changes: rule
      }, {
        ...newState,
        ruleIdsForListing: {
          ...state.ruleIdsForListing,
          [rule.property_id]: listingRules
        }
      })
    }

    case RoutingRulesActionTypes.DELETE_SUCCESS: {
      const payload = action.payload as { ruleId: number, listingId: number };

      let listingRules = state.ruleIdsForListing[payload.listingId] || [];

      const ruleIndex = _.indexOf(listingRules, payload.ruleId);

      listingRules = [
        ...listingRules.slice(0, ruleIndex),
        ...listingRules.slice(ruleIndex + 1)
      ];

      return routingRulesAdapter.removeOne(payload.ruleId, {
        ...state,
        ruleIdsForListing: {
          ...state.ruleIdsForListing,
          [payload.listingId]: listingRules,
        }
      })

    }

    case RoutingRulesActionTypes.CREATE_SUCCESS: {
      const payload  = action.payload as {rules: RoutingRule[], listingId: number};

      return routingRulesAdapter.addMany(payload.rules, {
        ...state,
        ruleIdsForListing: {
          ...state.ruleIdsForListing,
          [payload.listingId]:  payload.rules.map(r => r.id)
        }
      })
    }

    default: {
      return state;
    }

  }

}

export const _getRuleEntities = (state: RoutingRulesState) => {
  return state.entities;
};

export const _getIsRulesLoadingForListingId = (state: RoutingRulesState, listingId: number) => {
  return state.loadingListingIds.indexOf(listingId) !== -1;
};

export const _getIsRulesLoadedForListingId = (state: RoutingRulesState, listingId: number) => {
  return state.loadedListingIds.indexOf(listingId) !== -1;
};

export const _getRuleIdsForListingId = (state: RoutingRulesState, listingId: number) => {
  return state.ruleIdsForListing[listingId] || [];
};
