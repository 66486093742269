import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ReportService } from "app/services/report.service";
import { getDateObj } from "app/utils/calendar-utils";
import FormUtils from "app/utils/form";
import { startOfMonth } from "date-fns";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "sd-inquiry-popup",
  template: `
    <sd-modal-popup-layout title="Inquiries Report">
      <div fxLayout="column" class="dialog-content">
        <form fxLayoutGap="10px" [formGroup]="formGroup"
              (ngSubmit)="formGroup.valid && saveButtonCLicked()">
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <mat-form-field [color]="'accent'" class="width-3">
              <input matInput [matDatepicker]="startPicker" [(ngModel)]="minEndDate"
                     placeholder="Start Date"
                     formControlName='start_date'>
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
            <mat-icon>arrow_forward</mat-icon>
            <mat-form-field [color]="'accent'" class="width-3">
              <input matInput [matDatepicker]="endPicker" [min]="minEndDate" placeholder="End Date"
                     formControlName='end_date'>
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
            <sd-select style="width: 18%"
                       placeholder="Format"
                       [control]="format"
                       [options]="formats"></sd-select>
            <button mat-raised-button [disabled]="!formGroup.valid || isSaving" color="accent"
                    (click)="saveButtonCLicked()">
              <span>Download</span>
            </button>
          </div>
        </form>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`
    mat-spinner {
      height: 30px;
      width: 30px;
    }

    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 90%;
      }

      .dialog-content {
        overflow-y: scroll;
        height: 75vh
      }

    }
  `]
})
export class InquiryReportPopupComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject();
  formGroup: FormGroup;
  startDate: FormControl;
  endDate: FormControl;
  format: FormControl;
  minEndDate: Date;
  minDate: Date;
  formats: {title: string, value: any}[] = [
    { title: "csv", value: "csv" },
    { title: "xlsx", value: "xlsx" }
  ];
  isSaving: boolean = false;


  constructor(private dialogRef: MatDialogRef<InquiryReportPopupComponent>,
              private reportService: ReportService) {
    this.startDate = new FormControl(null, [Validators.required]);
    this.endDate = new FormControl(null, [Validators.required]);
    this.format = new FormControl("csv", [Validators.required]);

    this.formGroup = new FormGroup({
      start_date: this.startDate,
      end_date: this.endDate,
      format: this.format,
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.startDate.setValue(startOfMonth(getDateObj()));
  }

  saveButtonCLicked() {
    if (!this.formGroup.valid) {
      FormUtils.markAllAsTouched(this.formGroup);
      return;
    }
    this.isSaving = true;
    const data = {
      from: this.startDate.value,
      to: this.endDate.value,
      format: this.format.value,
    };

    this.reportService
      .downloadInquiresReport(data)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.isSaving = false, () => this.isSaving = false);
  }

  close() {
    this.dialogRef.close();
  }
}
