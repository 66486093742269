import { Pipe, PipeTransform } from "@angular/core";
import { CommonUtil } from "../../../utils/common.util";

@Pipe({
  name: "mobileText"
})
export class MobileTextPipe implements PipeTransform {
  transform(desktopValue: string,
            mobileValue: string): string {
    if (CommonUtil.isOnMobileDevice()) {
      return mobileValue;
    } else {
      return desktopValue;
    }
  }
}
