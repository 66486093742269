import {map, take} from "rxjs/operators";
import {
  getAllGuestAccessDrafts,
  getFullGuestAccessDraftById,
  getIsFullGuestAccessDraftLoaded,
  getIsFullGuestAccessDraftLoading,
  getIsGuestAccessDraftLoaded,
  getIsGuestAccessDraftLoading,
  State
} from "../../reducers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {GuestAccessDraftsService} from "../../services/drafts/guest-access.service";
import {GuestAccessDraft} from "../../models/new/drafts/guest-access-draft.model";
import {
  GuestAccessDraftCreateRequest,
  GuestAccessDraftCreateSuccess,
  GuestAccessDraftDeleteRequest,
  GuestAccessDraftDeleteSuccess,
  GuestAccessDraftIndexRequest,
  GuestAccessDraftIndexSuccess,
  GuestAccessDraftUpdateRequest,
  GuestAccessDraftUpdateSuccess
} from "../../actions/new/setttings/drafts/guest-access-drafts";
import {Injectable} from "@angular/core";

@Injectable()
export class GuestAccessDraftRepository {

  constructor(private store: Store<State>,
              private guestAccessDraftService: GuestAccessDraftsService) {
  }

  /**
   * Loading & Loaded
   */
  getIsGuestAccessDraftLoading(): Observable<boolean> {
    return this.store.select(getIsGuestAccessDraftLoading);
  }

  getIsGuestAccessDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsGuestAccessDraftLoaded);
  }

  getIsFullGuestAccessDraftLoading(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullGuestAccessDraftLoading(state, draftId));
  }

  getIsFullGuestAccessDraftLoaded(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullGuestAccessDraftLoaded(state, draftId));
  }

  /**
   * Service Methods
   */
  getAllGuestAccessDrafts(force: boolean): Observable<GuestAccessDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsGuestAccessDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsGuestAccessDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new GuestAccessDraftIndexRequest());

      this.guestAccessDraftService.getGuestAccessDrafts().subscribe(response => {
        this.store.dispatch(new GuestAccessDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(getAllGuestAccessDrafts).pipe(map(t => t as GuestAccessDraft[]));
  }

  getGuestAccessDraftById(draftId: number): Observable<GuestAccessDraft> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsGuestAccessDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsGuestAccessDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new GuestAccessDraftIndexRequest());

      this.guestAccessDraftService.getGuestAccessDrafts().subscribe(response => {
        this.store.dispatch(new GuestAccessDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(state => getFullGuestAccessDraftById(state, draftId)).pipe(
      map(t => t as GuestAccessDraft));
  }


  createGuestAccessDraft(data: Partial<GuestAccessDraft>): Observable<GuestAccessDraft> {
    this.store.dispatch(new GuestAccessDraftCreateRequest());
    return this.guestAccessDraftService.createGuestAccessDraft(data).pipe(map(res => {
      this.store.dispatch(new GuestAccessDraftCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateGuestAccessDraft(draftId: number, data: Partial<GuestAccessDraft>): Observable<GuestAccessDraft> {
    this.store.dispatch(new GuestAccessDraftUpdateRequest(draftId));
    return this.guestAccessDraftService.updateGuestAccessDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new GuestAccessDraftUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deleteGuestAccessDraft(draftId: number): Observable<null> {
    this.store.dispatch(new GuestAccessDraftDeleteRequest(draftId));
    return this.guestAccessDraftService.deleteGuestAccessDraft(draftId).pipe(map(res => {
      this.store.dispatch(new GuestAccessDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}

