import { ThreadFilter } from "../../../enums/thread-filter.enum";
import { ThreadCompact } from "../../../models/new/inbox/thread-compact";
import { ThreadFull } from "../../../models/new/inbox/thread-full";
import { Action } from "../../action";

export enum ThreadActionTypes {
  NEXT_PAGE_REQUEST = "[THREAD THREAD NEW] Next Page Request",
  NEXT_PAGE_SUCCESS = "[THREAD THREAD NEW] Next Page Success",
  RESET_DATA = "[THREAD THREAD NEW] Reset Data",

  CHANGE_LISTING_IDS = "[THREAD THREAD NEW] Change Listing Ids",
  CHANGE_ASSIGNEE_IDS = "[THREAD THREAD NEW] Change Assignee Ids",
  CHANGE_CURRENT_PAGE = "[THREAD THREAD NEW] Change Current Page",
  ADD_THREAD_FILTER = "[THREAD THREAD NEW] Add Thread Filter",
  REMOVE_THREAD_FILTER = "[THREAD THREAD NEW] Remove Thread Filter",
  CHANGE_THREAD_OFFSET = "[THREAD THREAD NEW] Change Thread Offset",
  SET_SELECTED_THREAD = "[THREAD THREAD NEW] Set Selected Thread",

  SHOW_REQUEST = "[THREAD THREAD NEW] Show Request",
  SHOW_SUCCESS = "[THREAD THREAD NEW] Show Success",
  UPDATE_SUCCESS = "[THREAD THREAD NEW] Update Success",
  REMOVE_REQUEST = "[THREAD THREAD NEW] Remove Request",
  REMOVE_THREAD_SUCCESS = "[THREAD THREAD NEW] Remove Thread Success",

  SPECIAL_OFFER_SUCCESS = "[THREAD THREAD NEW] Special Offer Sent Success",
}

export class ThreadNextPageRequest implements Action {
  readonly type = ThreadActionTypes.NEXT_PAGE_REQUEST;

  constructor(public payload: number) {
  }
}

export class ThreadNextPageSuccess implements Action {
  readonly type = ThreadActionTypes.NEXT_PAGE_SUCCESS;

  constructor(public payload: { threads: ThreadCompact[], currentPage: number, totalPages: number, totalCount: number }) {
  }
}

export class ThreadShowRequest implements Action {
  readonly type = ThreadActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {
  }
}

export class ThreadShowSuccess implements Action {
  readonly type = ThreadActionTypes.SHOW_SUCCESS;

  constructor(public payload: ThreadFull) {
  }
}

export class ThreadUpdateSuccess implements Action {
  readonly type = ThreadActionTypes.UPDATE_SUCCESS;

  constructor(public payload: ThreadFull) {
  }
}

export class ThreadChangeListingIds implements Action {
  readonly type = ThreadActionTypes.CHANGE_LISTING_IDS;

  constructor(public payload: number[]) {
  }
}

export class ThreadChangeAssigneeIds implements Action {
  readonly type = ThreadActionTypes.CHANGE_ASSIGNEE_IDS;

  constructor(public payload: number[]) {
  }
}

export class ThreadChangeCurrentPage implements Action {
  readonly type = ThreadActionTypes.CHANGE_CURRENT_PAGE;

  constructor(public payload: number) {

  }
}

export class ThreadAddFilter implements Action {
  readonly type = ThreadActionTypes.ADD_THREAD_FILTER;

  constructor(public payload: ThreadFilter[]) {

  }
}

export class ThreadRemoveFilter implements Action {
  readonly type = ThreadActionTypes.REMOVE_THREAD_FILTER;

  constructor(public payload: ThreadFilter) {

  }
}

export class ThreadSetSelectedId implements Action {
  readonly type = ThreadActionTypes.SET_SELECTED_THREAD;

  constructor(public payload: { thread_id: number, booking_id: number }) {

  }
}

export class ThreadResetData implements Action {
  readonly type = ThreadActionTypes.RESET_DATA;
}

export class ThreadRemoveThreadSuccess implements Action {
  readonly type = ThreadActionTypes.REMOVE_THREAD_SUCCESS;

  constructor(public payload: { thread_id: number }) {

  }
}

export class ThreadSendSpecialOfferSuccess implements Action {
  readonly type = ThreadActionTypes.SPECIAL_OFFER_SUCCESS;

  constructor(public payload: { threadId: number, bookingId: number }) {
  }
}

export class ThreadOffsetChange implements Action {
  readonly type = ThreadActionTypes.CHANGE_THREAD_OFFSET;

  constructor(public payload: { offset: string, date: string }) {
  }

}

