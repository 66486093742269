import { SortOrder } from "../../../enums/common.enum";
import { HomeownerReservationType, ReservationSortType, ReservationType } from "../../../enums/reservation.enum";
import { BookingGuest } from "../../../models/booking-guest";
import { BookingCompact } from "../../../models/new/booking/booking-compact.model";
import { BookingFull } from "../../../models/new/booking/booking-full.model";
import { ScheduledReview } from "../../../models/new/booking/scheduled-review";
import { TaskCompact } from "../../../models/new/tasks/task-compact.model";
import { Payment } from "../../../models/payment";
import { ScheduledMessage } from "../../../models/scheduled-message";
import { SecurityDeduction } from "../../../models/security-deduction";
import { Action } from "../../action";

export enum BookingActionTypes {
  INDEX_REQUEST = "[BOOKING BOOKING NEW] Index Request",
  INDEX_SUCCESS = "[BOOKING BOOKING NEW] Index Success",

  CREATE_REQUEST = "[BOOKING BOOKING NEW] Create Request",
  CREATE_SUCCESS = "[BOOKING BOOKING NEW] Create Success",

  SHOW_REQUEST = "[BOOKING BOOKING NEW] Show Request",
  SHOW_SUCCESS = "[BOOKING BOOKING NEW] Show Success",

  SORT_ORDER_CHANGE = "[BOOKING BOOKING NEW] Sort Order Change",
  TYPE_CHANGE = "[BOOKING BOOKING NEW] Type Change",
  LISTINGS_CHANGE = "[BOOKING BOOKING NEW] Listings Change",
  SOURCES_CHANGE = "[BOOKING BOOKING NEW] Sources Change",
  TAGS_CHANGE = "[BOOKING BOOKING NEW] Tags Change",
  SORT_TYPE_CHANGE = "[BOOKING BOOKING NEW] Sort Type Change",
  PER_PAGE_CHANGE = "[BOOKING BOOKING NEW] Per Page Change",
  PAGE_CHANGE = "[BOOKING BOOKING NEW] Page Change",
  REVIEW_RATING_CHANGE = "[BOOKING BOOKING NEW] Review Rating Change",
  SCHEDULED_MESSAGE_UPDATE_SUCCESS = "[BOOKING BOOKING NEW] Scheduled Message Update Success",
  SCHEDULED_REVIEW_UPDATE_SUCCESS = "[BOOKING BOOKING NEW] Scheduled Review Update Success",
  START_CHANGE = "[BOOKING BOOKING NEW] Start Change",
  END_CHANGE = "[BOOKING BOOKING NEW] End Change",

  ADD_GUEST_SUCCESS = "[BOOKING BOOKING NEW] Add Guest Success",
  GUEST_UPDATE_SUCCESS = "[BOOKING BOOKING NEW] Update Guest Success",
  DELETE_GUEST_SUCCESS = "[BOOKING BOOKING NEW] Delete Guest Success",

  BOOKING_PAYMENT_CREATE_REQUEST = "[BOOKING BOOKING NEW] Payment Create Request",
  BOOKING_PAYMENT_CREATE_SUCCESS = "[BOOKING BOOKING NEW] Payment Create Success",

  UPCOMING_OWNER_INDEX_REQUEST = "[BOOKING BOOKING NEW] Upcoming Onwer Index Request",

  UPCOMING_OWNER_INDEX_SUCCESS = "[BOOKING BOOKING NEW] Upcoming Owner Index Success",

  SECURITY_DEDUCTION_REQUEST = "[BOOKING BOOKING NEW] Security Deduction Request",
  SECURITY_DEDUCTION_SUCCESS = "[BOOKING BOOKING NEW] Security Deduction Success",

  UPDATE_REQUEST = "[BOOKING BOOKING NEW] Update Request",
  UPDATE_SUCCESS = "[BOOKING BOOKING NEW] Update Success",

  GUEST_SET_BADGE = "[BOOKING] Set guest badge",

  ADD_BOOKING_TO_NOT_EXIST = "[BOOKING BOOKING NEW] Add booking To Not Exist",
  VENDOR_TASKS_INDEX_REQUEST = "[BOOKING] Vendor Task Index Request",
  VENDOR_TASKS_INDEX_SUCCESS = "[BOOKING] Vendor Task Index Success",
}

export class BookingCreateRequest implements Action {
  readonly type = BookingActionTypes.CREATE_REQUEST;
}

export class BookingCreateSuccess implements Action {
  readonly type = BookingActionTypes.CREATE_SUCCESS;

  constructor(public payload: BookingFull) {
  }

}

export class BookingIndexRequest implements Action {
  readonly type = BookingActionTypes.INDEX_REQUEST;
}

export class BookingIndexSuccess implements Action {
  readonly type = BookingActionTypes.INDEX_SUCCESS;

  constructor(public payload: {
    bookings: BookingCompact[], currentPage: number,
    totalPages: number, perPageCount: number, total: number
  }) {
  }
}

export class BookingShowRequest implements Action {
  readonly type = BookingActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {
  }
}

export class BookingShowSuccess implements Action {
  readonly type = BookingActionTypes.SHOW_SUCCESS;

  constructor(public payload: BookingFull) {
  }
}

export class BookingSortOrderChange implements Action {
  readonly type = BookingActionTypes.SORT_ORDER_CHANGE;

  constructor(public payload: SortOrder) {
  }
}

export class BookingTypeChange implements Action {

  readonly type = BookingActionTypes.TYPE_CHANGE;

  constructor(public payload: ReservationType | HomeownerReservationType) {
  }
}

export class BookingListingsChange implements Action {

  readonly type = BookingActionTypes.LISTINGS_CHANGE;

  constructor(public payload: number[]) {
  }
}

export class BookingSourcesChange implements Action {

  readonly type = BookingActionTypes.SOURCES_CHANGE;

  constructor(public payload: string[]) {
  }
}

export class BookingTagsChange implements Action {

  readonly type = BookingActionTypes.TAGS_CHANGE;

  constructor(public payload: number[]) {
  }
}

export class BookingSortTypeChange implements Action {

  readonly type = BookingActionTypes.SORT_TYPE_CHANGE;

  constructor(public payload: ReservationSortType) {
  }
}

export class BookingPerPageChange implements Action {
  readonly type = BookingActionTypes.PER_PAGE_CHANGE;

  constructor(public payload: number) {
  }
}

export class BookingPageChange implements Action {
  readonly type = BookingActionTypes.PAGE_CHANGE;

  constructor(public payload: number) {
  }
}

export class BookingReviewRatingChange implements Action {
  readonly type = BookingActionTypes.REVIEW_RATING_CHANGE;

  constructor(public payload: number[]) {
  }
}

export class BookingStartChange implements Action {
  readonly type = BookingActionTypes.START_CHANGE;

  constructor(public payload: Date) {
  }
}

export class BookingEndChange implements Action {
  readonly type = BookingActionTypes.END_CHANGE;

  constructor(public payload: Date) {
  }
}

export class ScheduledMessageUpdateSuccess implements Action {
  readonly type = BookingActionTypes.SCHEDULED_MESSAGE_UPDATE_SUCCESS;

  constructor(public payload: { bookingId: number, scheduledMessage: ScheduledMessage }) {
  }
}

export class ScheduledReviewUpdateSuccess implements Action {
  readonly type = BookingActionTypes.SCHEDULED_REVIEW_UPDATE_SUCCESS;

  constructor(public payload: { bookingId: number, review: ScheduledReview }) {
  }
}

export class BookingGuestDeleteSuccess implements Action {
  readonly type = BookingActionTypes.DELETE_GUEST_SUCCESS;

  constructor(public payload: { bookingId: string, guestId: string }) {
  }
}

export class BookingGuestUpdateSuccess implements Action {
  readonly type = BookingActionTypes.GUEST_UPDATE_SUCCESS;

  constructor(public payload: { bookingId: string, guest: BookingGuest }) {
  }
}

export class AddBookingGuestSuccess implements Action {
  readonly type = BookingActionTypes.ADD_GUEST_SUCCESS;

  constructor(public payload: { bookingId: string, guest: BookingGuest }) {
  }
}

export class UpcomingOwnerBookingIndexRequest implements Action {
  readonly type = BookingActionTypes.UPCOMING_OWNER_INDEX_REQUEST;
}

export class UpcomingOwnerBookingIndexSuccess implements Action {
  readonly type = BookingActionTypes.UPCOMING_OWNER_INDEX_SUCCESS;

  constructor(public payload: BookingCompact[]) {
  }
}

export class BookingSecurityDeductionRequest implements Action {
  readonly type = BookingActionTypes.SECURITY_DEDUCTION_REQUEST;
}

export class BookingSecurityDeductionSuccess implements Action {
  readonly type = BookingActionTypes.SECURITY_DEDUCTION_SUCCESS;

  constructor(public payload: { bookingId: number, deduction: SecurityDeduction }) {
  }
}

export class BookingPaymentCreateRequest implements Action {
  readonly type = BookingActionTypes.BOOKING_PAYMENT_CREATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class BookingPaymentCreateSuccess implements Action {
  readonly type = BookingActionTypes.BOOKING_PAYMENT_CREATE_SUCCESS;

  constructor(public payload: { bookingId: number, payment: Payment }) {
  }
}

export class BookingUpdateRequest implements Action {
  readonly type = BookingActionTypes.UPDATE_REQUEST;
}

export class BookingUpdateSuccess implements Action {
  readonly type = BookingActionTypes.UPDATE_SUCCESS;

  constructor(public payload: BookingFull) {
  }
}

export class BookingGuestSetBadgeCountAction implements Action {
  readonly type = BookingActionTypes.GUEST_SET_BADGE;

  constructor(public payload: { bookingId: number, count: number }) {
  }
}

export class AddBookingToNotExist implements Action {
  readonly type = BookingActionTypes.ADD_BOOKING_TO_NOT_EXIST;

  constructor(public payload: number) {
  }
}

export class VendorTasksIndexRequest implements Action {
  readonly type = BookingActionTypes.VENDOR_TASKS_INDEX_REQUEST;

  constructor(public payload: number) {
  }
}

export class VendorTasksIndexSuccess implements Action {
  readonly type = BookingActionTypes.VENDOR_TASKS_INDEX_SUCCESS;

  constructor(public payload: { bookingId: number, tasks: TaskCompact[] }) {
  }
}
