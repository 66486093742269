import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { takeWhile } from "rxjs/operators";

import { Booking } from "../../../../models/booking";
import { UserFull } from "../../../../models/new/user/user-full.model";
import { UserModelUtil } from "../../../../models/utils/user-model.util";
import { BookingRepository } from "../../../../repository/booking.repository";
import { UserRepository } from "../../../../repository/user-repository";

@Component({
  selector: "sd-booking-notes-popup",
  template: `
    <sd-modal-popup-layout title="Booking Notes">
      <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px">
        <mat-form-field [color]="'accent'" class="detailField">
          <textarea rows="10" color="accent" matInput placeholder="Booking Notes" [formControl]="text"></textarea>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <mat-spinner *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <button mat-raised-button color="accent" [disabled]="isSaving" (click)="saveButtonCLicked()">Save</button>
        </div>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`

    .title {
      font-weight: bolder;
      font-size: 22px;
      padding-left: 10px;
      padding-right: 10px;
      height: 30px;
      width: 100%;
    }

    .detailField {
      padding-left: 10px;
      padding-right: 10px;
      width: 95%;
    }

    textarea {
      resize: vertical;
    }

    mat-spinner {
      width: 30px;
      height: 30px;
    }

  `]
})

export class BookingNotesPopupComponent implements OnInit, OnDestroy {

  booking: Booking;

  isSaving = false;
  text: FormControl;
  isAlive = true;
  user: UserFull;

  constructor(private bookingRepo: BookingRepository,
              private userRepo: UserRepository,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    this.booking = data;
    this.text = new FormControl(null, []);
  }

  ngOnInit() {
    // this.text.setValue(this.booking.booking_notes);
    this.userRepo
      .getUser().pipe(takeWhile(() => this.isAlive))
      .subscribe(u => this.user = u);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  saveButtonCLicked() {
    this.isSaving = true;

    let notes = this.booking.booking_notes;
    if (this.text.value && this.text.value.trim() !== "") {
      notes =  notes + "\n\n" + moment().format("LLL") + " - " + UserModelUtil.getFullName(this.user) + " : " + this.text.value;
    } else {
      notes = notes + this.text.value;
    }

    this.bookingRepo.updateBooking(this.booking.id, this.booking.thread_id, {booking_notes: notes}).subscribe((listing) => {
      this.isSaving = false;
      this.dialog.closeAll();
    });
  }
}
