import { Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "sd-task-notes-popup",
  template: `
    <sd-modal-popup-layout [preventDefaultClose]="true" [title]="title" (closeAction)="close($event)">
    </sd-modal-popup-layout>

    <div fxLayout="column" fxLayoutGap="30px">
      <div class="textField">
        <mat-form-field color="accent" class="textField">
          <textarea matInput rows="15" [formControl]="text">{{temp}}</textarea>
        </mat-form-field>
      </div>

      <div fxLayout="row" style="margin-top: 10px">
        <span fxFlex="1 1 auto"></span>
        <button (click)="updateNotesDescription()" color="accent" mat-raised-button>Done
          <mat-icon>check</mat-icon>
        </button>
      </div>
    </div>
  `,
  styles: [`
    .textField {
      width: 100%;
    }
  `]
})

export class TaskNotesPopupComponent {
  temp;
  title: string = "Notes:";
  text: FormControl;

  constructor(public dialog: MatDialogRef<string>,
              @Inject(MAT_DIALOG_DATA) public data: { text: string }) {
    this.temp = data.text;
    this.text = new FormControl(this.temp, [Validators.required]);
  }

  close(event: any) {
    if (event === true) {
      this.dialog.close(this.temp);
    }
    console.log(event);
  }

  updateNotesDescription() {
    this.dialog.close(this.text.value);
  }

}
