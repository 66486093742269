import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import {
  AirbnbAAccountUpdateSuccessAction,
  AirbnbAccountsFetchRequestAction,
  AirbnbAccountsFetchSuccessAction
} from "../actions/account";
import { ContactUpdateRequest, ContactUpdateSuccess } from "../actions/new/contacts/contact";
import {
  PropertyManagersAddSuccessAction,
  PropertyManagersFetchRequestAction,
  PropertyManagersFetchSuccessAction,
  PropertyManagersUpdateSuccessAction
} from "../actions/property-manager";
import * as fromUser from "../actions/user";
import { LoginSuccessAction } from "../actions/user";
import { AirbnbAccount } from "../models/airbnb_account";
import { UserCompact } from "../models/new/user/user-compact.model";
import { UserFull } from "../models/new/user/user-full.model";
import { UserModelUtil } from "../models/utils/user-model.util";
import { SentryService } from "../modules/sentry/sentry.service";
import {
  getAirbnbAccounts,
  getIsAccountsLoaded,
  getIsAccountsLoading, getIsManagersLoaded, getIsManagersLoading, getPropertyManagers,
  getUser,
  isLoggedIn,
  isLoggingIn,
  State
} from "../reducers";
import { UserService } from "../services/user.service";

@Injectable()
export class UserRepository {

  constructor(private store: Store<State>,
              private userService: UserService,
              private sentryService: SentryService) {
  }

  isAccountsLoading() {
    return this.store.select(getIsAccountsLoading);
  }

  isAccountsLoaded() {
    return this.store.select(getIsAccountsLoaded);
  }

  login(data: { email: string, password: string, "mfa-token"?: string }) {
    return this.userService.login(data).pipe(map(user => {
      this.store.dispatch(new LoginSuccessAction(user));
      this.sentryService.setUserContext(user.id, user.first_name);
      return user["mfa-token"];
    }));
  }

  generateCode(data) {
    return this.userService.generateCode(data);
  }

  validateCode(data) {
    return this.userService.validateCode(data);
  }

  signupViaEmail(data: { email: string, password: string, first_name: string, last_name: string }): Observable<UserFull> {
    return this.userService.signupViaEmail(data).pipe(map(user => {
      this.store.dispatch(new LoginSuccessAction(user));
      return user;
    }));
  }

  signupViaAirbnb(data: { forwarding_email: string, airbnb_username: string, airbnb_password: string }): Observable<any> {
    return this.userService.signupViaAirbnb(data);
  }

  forgotPassword(data: { email: string }): Observable<boolean | {}> {
    return this.userService.forgotPassword(data);
  }

  resetPassword(data: { email: string, code: string, password: string, password_confirmation: string }): Observable<boolean | {}> {
    return this.userService.resetPassword(data);
  }

  getIsAccountsLoading(): Observable<boolean> {
    return this.store.select(getIsAccountsLoading);
  }

  getIsAccountsLoaded(): Observable<boolean> {
    return this.store.select(getIsAccountsLoaded);
  }

  getAirbnbAccounts(force: boolean = false): Observable<AirbnbAccount[]> {
    let loading;
    let loaded;

    this.getIsAccountsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsAccountsLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new AirbnbAccountsFetchRequestAction());
      this.userService.getAirbnbAccounts().subscribe(res => {
        this.store.dispatch(new AirbnbAccountsFetchSuccessAction(res));
      });
    }
    return this.store.select(getAirbnbAccounts);
  }

  getAirbnbAccountById(id: number): Observable<AirbnbAccount> {
    return this.userService.getAirbnbAccountById(id);
  }

  attachAirbnbAccount(data: any): Observable<AirbnbAccount> {
    return this.userService.attachAirbnbAccount(data);
  }

  pingAirbnbAccount(accountId: number): Observable<AirbnbAccount> {
    return this.userService.pingAirbnbAccount(accountId).pipe(map(account => {
      this.store.dispatch(new AirbnbAAccountUpdateSuccessAction(account));
      return account;
    }));
  }

  updateAirbnbAccount(id: number, data: any): Observable<AirbnbAccount> {
    return this.userService.updateAirbnbAccount(id, data).pipe(map(account => {
      this.store.dispatch(new AirbnbAAccountUpdateSuccessAction(account));
      return account;
    }));
  }

  refreshAirbnbAccount(accountId: number, data: any): Observable<boolean | {}> {
    return this.userService.refreshAirbnbAccount(accountId, data);
  }

  resendAirbnbCodeByEmail(accountId: number): Observable<AirbnbAccount> {
    return this.userService.resendAirbnbCodeByEmail(accountId).pipe(map(account => {
      this.store.dispatch(new AirbnbAAccountUpdateSuccessAction(account));
      return account;
    }));
  }

  resendAirbnbCodeByText(accountId: number, phoneNumberId: number): Observable<AirbnbAccount> {
    return this.userService.resendAirbnbCodeByText(accountId, phoneNumberId).pipe(map(account => {
      this.store.dispatch(new AirbnbAAccountUpdateSuccessAction(account));
      return account;
    }));
  }

  resendAirbnbCodeByCall(accountId: number, phoneNumberId: number): Observable<AirbnbAccount> {
    return this.userService.resendAirbnbCodeByCall(accountId, phoneNumberId).pipe(map(account => {
      this.store.dispatch(new AirbnbAAccountUpdateSuccessAction(account));
      return account;
    }));
  }

  verifyAirbnbCodeByEmail(accountId: number, code: string): Observable<AirbnbAccount> {
    return this.userService.verifyAirbnbCodeByEmail(accountId, code).pipe(map(account => {
      this.store.dispatch(new AirbnbAAccountUpdateSuccessAction(account));
      return account;
    }));
  }

  verifyAirbnbCodeByText(accountId: number, code: string, phoneNumberId: number): Observable<AirbnbAccount> {
    return this.userService.verifyAirbnbCodeByText(accountId, code, phoneNumberId).pipe(map(account => {
      this.store.dispatch(new AirbnbAAccountUpdateSuccessAction(account));
      return account;
    }));
  }

  verifyAirbnbCodeByCall(accountId: number, code: string, phoneNumberId: number): Observable<AirbnbAccount> {
    return this.userService.verifyAirbnbCodeByCall(accountId, code, phoneNumberId).pipe(map(account => {
      this.store.dispatch(new AirbnbAAccountUpdateSuccessAction(account));
      return account;
    }));
  }

  me(): Observable<UserFull> {
    return this.userService.me().pipe(map(user => {
      this.store.dispatch(new fromUser.UpdateSuccessAction(user));
      this.sentryService.setUserContext(user.id, user.first_name);
      return user;
    }));
  }

  updateProfile(data: {
    first_name: string, last_name: string, email: string, secondary_email: string, phone_number: any,
    secondary_phone_number: any, preferred_contact_method: string, description: string, show_expenses: boolean,
  }): Observable<UserFull> {

    return this.userService.updateProfile(data).pipe(map(user => {
      this.store.dispatch(new fromUser.UpdateSuccessAction(user));
      return user;
    }));
  }

  updateUserPic(data: { image: File }): Observable<{ progress?: any, response?: any }> {
    const subject$ = new Subject<{ progress?: any, response?: any }>();
    this.userService.updateUserPic(data).subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round(100 * event.loaded / event.total);
          subject$.next({progress: progress});
        } else if (event instanceof HttpResponse) {
          subject$.next({response: event.body.data});
          subject$.complete();
          this.store.dispatch(new fromUser.UpdateSuccessAction(event.body.data));
        }
      }, (err) => {
        return subject$.error(err);
      }
    );
    return subject$.asObservable();
  }

  changePassword(data: { old_password: string, password: string, password_confirmation: string }): Observable<boolean | {}> {
    return this.userService.changePassword(data);
  }

  // TODO Repo methods for getIsUserVendor, getIsUserAdmin, etc
  getUser(force: boolean = false): Observable<UserFull> {
    let loading;
    let loaded;

    this.getIsUserLoggingIn().pipe(take(1)).subscribe(l => loading = l);
    this.getIsUserLoggedIn().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.me().subscribe();
    }

    return this.store.select(getUser).pipe(filter(u => !!u));
  }

  // TODO Repo methods for getIsUserVendor, getIsUserAdmin, etc
  getHomeOwners(force: boolean = false): Observable<UserCompact> {
    let loading;
    let loaded;

    this.getIsUserLoggingIn().pipe(take(1)).subscribe(l => loading = l);
    this.getIsUserLoggedIn().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.me().subscribe();
    }

    return this.store.select(getUser).pipe(filter(u => !!u));
  }

  getIsUserLoggedIn(): Observable<boolean> {
    return this.store.select(isLoggedIn);
  }

  getIsUserLoggingIn(): Observable<boolean> {
    return this.store.select(isLoggingIn);
  }

  updateUserType(userCategory: string, contactId: number): Observable<UserFull> {
    this.store.dispatch(new ContactUpdateRequest(contactId));
    return this.userService.updateUserType(userCategory, contactId).pipe(map(user => {
      this.store.dispatch(new ContactUpdateSuccess(user));
      return user;
    }));
  }

  checkEmailValidity(data: { email_prefix: string }): Observable<any> {
    return this.userService.checkEmailValidity(data);
  }

  changeEmail(id: number, data: { email_prefix: string }): Observable<AirbnbAccount> {
    return this.userService.changeEmail(id, data).pipe(map(res => {
      this.store.dispatch(new AirbnbAAccountUpdateSuccessAction(res));
      return res;
    }));
  }

  getEmployeeName(employeeId: number): string {

    let name = "";

    this.getUser().subscribe((user) => {
      if (user && user.managementContacts) {
        const contacts = user.managementContacts.data;
        const ids = contacts.map(r => r.id);
        const index = ids.indexOf(employeeId);

        if (index !== -1) {
          const employee = contacts[index];
          name = UserModelUtil.getFullName(employee);
        }
      }
    });
    return name;
  }

  getIsManagersLoading(): Observable<boolean> {
    return this.store.select(getIsManagersLoading);
  }

  getIsManagersLoaded(): Observable<boolean> {
    return this.store.select(getIsManagersLoaded);
  }

  getPropertyManagers(force: boolean = false) {
    let loading;
    let loaded;

    this.getIsManagersLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsManagersLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new PropertyManagersFetchRequestAction());
      this.userService.getPropertyManagers().subscribe(res => {
        this.store.dispatch(new PropertyManagersFetchSuccessAction(res));
      });
    }
    return this.store.select(getPropertyManagers);
  }

  addPropertyManager(data) {
    return this.userService.addPropertyManager(data).pipe(map(manager => {
      this.store.dispatch(new PropertyManagersAddSuccessAction(manager));
      return manager;
    }));
  }

  updatePropertyManager(id: number, data) {
    return this.userService.updatePropertyManager(id, data).pipe(map(manager => {
      this.store.dispatch(new PropertyManagersUpdateSuccessAction(manager));
      return manager;
    }));
  }

  getBookingPalUrl(): Observable<string> {
    return this.userService.getBookingPalUrl();
  }
  oauth(data) {
    return this.userService.oauth(data);
  }
}
