import {map, take} from "rxjs/operators";
import {
  getAllGettingAroundDrafts,
  getFullGettingAroundDraftById,
  getIsFullGettingAroundDraftLoaded,
  getIsFullGettingAroundDraftLoading,
  getIsGettingAroundDraftLoaded,
  getIsGettingAroundDraftLoading,
  State
} from "../../reducers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";

import {GettingAroundDraftsService} from "../../services/drafts/geting-around-drafts.service";
import {Injectable} from "@angular/core";
import {
  GettingAroundDraftCreateRequest,
  GettingAroundDraftCreateSuccess,
  GettingAroundDraftDeleteRequest,
  GettingAroundDraftDeleteSuccess,
  GettingAroundDraftIndexRequest,
  GettingAroundDraftIndexSuccess,
  GettingAroundDraftUpdateRequest,
  GettingAroundDraftUpdateSuccess
} from "../../actions/new/setttings/drafts/getting-around-drafts";
import {GettingAroundDraft} from "../../models/new/drafts/getting-around-draft.model";

@Injectable()
export class GettingAroundDraftRepository {

  constructor(private store: Store<State>,
              private gettingAroundDraftService: GettingAroundDraftsService) {
  }

  /**
   * Loading & Loaded
   */
  getIsGettingAroundDraftLoading(): Observable<boolean> {
    return this.store.select(getIsGettingAroundDraftLoading);
  }

  getIsGettingAroundDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsGettingAroundDraftLoaded);
  }

  getIsFullGettingAroundDraftLoading(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullGettingAroundDraftLoading(state, draftId));
  }

  getIsFullGettingAroundDraftLoaded(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullGettingAroundDraftLoaded(state, draftId));
  }

  /**
   * Service Methods
   */
  getAllGettingAroundDrafts(force: boolean): Observable<GettingAroundDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsGettingAroundDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsGettingAroundDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new GettingAroundDraftIndexRequest());

      this.gettingAroundDraftService.getGettingAroundDrafts().subscribe(response => {
        this.store.dispatch(new GettingAroundDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(getAllGettingAroundDrafts).pipe(map(t => t as GettingAroundDraft[]));
  }

  getGettingAroundDraftById(draftId: number): Observable<GettingAroundDraft> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsGettingAroundDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsGettingAroundDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new GettingAroundDraftIndexRequest());

      this.gettingAroundDraftService.getGettingAroundDrafts().subscribe(response => {
        this.store.dispatch(new GettingAroundDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(state => getFullGettingAroundDraftById(state, draftId)).pipe(
      map(t => t as GettingAroundDraft));
  }


  createGettingAroundDraft(data: Partial<GettingAroundDraft>): Observable<GettingAroundDraft> {
    this.store.dispatch(new GettingAroundDraftCreateRequest());
    return this.gettingAroundDraftService.createGettingAroundDraft(data).pipe(map(res => {
      this.store.dispatch(new GettingAroundDraftCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateGettingAroundDraft(draftId: number, data: Partial<GettingAroundDraft>): Observable<GettingAroundDraft> {
    this.store.dispatch(new GettingAroundDraftUpdateRequest(draftId));
    return this.gettingAroundDraftService.updateGettingAroundDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new GettingAroundDraftUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deleteGettingAroundDraft(draftId: number): Observable<null> {
    this.store.dispatch(new GettingAroundDraftDeleteRequest(draftId));
    return this.gettingAroundDraftService.deleteGettingAroundDraft(draftId).pipe(map(res => {
      this.store.dispatch(new GettingAroundDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}

