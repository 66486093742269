import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { __HTTPResponseType } from "../enums/common.enum";
import { ListingNameOnly } from "../models/new/listing/listing-name-only-model";
import { dateToDateString, getDateObj } from "../utils/calendar-utils";

import { ApiService } from "./api.service";
import { SnackbarService } from "./snackbar.service";

@Injectable()
export class ReportService {

  constructor(private apiService: ApiService,
              private snackbar: SnackbarService) {
  }


  downloadInquiresReport(data: {from: string, to: string}): Observable<boolean> {
    data.from = dateToDateString(getDateObj(data.from));
    data.to = dateToDateString(getDateObj(data.to));
    return this.apiService.post("/admin/inquiries-report", true, data, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        const contentDispositionHeader = res.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        const fileName = result.replace(/"/g, "");

        saveAs(res.body, fileName);

        return true;
      }));
  }


  // TODO Not Working
  downloadOwnerStatement(ownerId: number, data: {
    start_date: string,
    end_date?: string,
    format: string,
  }): Observable<boolean> {
    data.start_date = dateToDateString(getDateObj(data.start_date));
    data.end_date = dateToDateString(getDateObj(data.end_date));
    return this.apiService.post("/owners/" + ownerId + "/owner-statement-download", true, data, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        const contentDispositionHeader = res.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        const fileName = result.replace(/"/g, "");

        saveAs(res.body, fileName);

        return true;
      }));
  }

  mailOwnerStatement(ownerId: number, data: {
    start_date: string,
    end_date?: string,
    format: string,
  }): Observable<any> {
    data.start_date = dateToDateString(getDateObj(data.start_date));
    data.end_date = dateToDateString(getDateObj(data.end_date));
    return this.apiService.post("/owners/" + ownerId + "/owner-statement-mail", true, data, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map(res => {
        return res;
      }));
  }

  // TODO Not Working
  downloadHomeOwnerStatement(data: {
    start_date: string,
    end_date?: string,
    format: string,
    homeowner_ids: string
  }): Observable<boolean> {
    data.start_date = dateToDateString(getDateObj(data.start_date));
    data.end_date = dateToDateString(getDateObj(data.end_date));
    return this.apiService.post("/download-homeowner-statement", true, data, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        const contentDispositionHeader = res.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        const fileName = result.replace(/"/g, "");

        saveAs(res.body, fileName);

        return true;
      }));
  }

  // TODO Not Working
  downloadPropertyIncomeReport(data: {
    start_date: string,
    end_date?: string,
    format: string,
    property_ids: number[],
    offset: string,
    send_email: boolean
  }): Observable<boolean> {
    data.start_date = dateToDateString(getDateObj(data.start_date));
    data.end_date = dateToDateString(getDateObj(data.end_date));
    return this.apiService.post("/property-income-report", true, data, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        if (!data.send_email) {
          const contentDispositionHeader = res.headers.get("Content-Disposition");
          const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
          const fileName = result.replace(/"/g, "");

          saveAs(res.body, fileName);
        } else {
          this.snackbar.show("Property Income Report is being generated, will be sent to your email.", 4000);
        }

        return true;
      }));
  }

  downloadActiveListingsReport(): Observable<boolean> {
    return this.apiService.post("/admin/active-property-report", true, null, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        const contentDispositionHeader = res.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        const fileName = result.replace(/"/g, "");

        saveAs(res.body, fileName);
        return true;
      }));
  }

  downloadPayoutReport(data: {
    start_date: string,
    end_date?: string,
    format: string,
    send_email: boolean
  }): Observable<boolean> {
    data.start_date = dateToDateString(getDateObj(data.start_date));
    data.end_date = dateToDateString(getDateObj(data.end_date));
    return this.apiService.post("/admin/download-payout-report", true, data, null, true, data.send_email ? __HTTPResponseType.TEXT : __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        if (!data.send_email) {
          const contentDispositionHeader = res.headers.get("Content-Disposition");
          const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
          const fileName = result.replace(/"/g, "");

          saveAs(res.body, fileName);
        } else {
          this.snackbar.show("Payout Report is being generated, will be sent to your email.", 4000);
        }

        return true;
      }));
  }

  generateCompReport(params: {
    no_of_bedrooms: number,
    no_of_bathrooms: number,
    no_of_guests: number,
    property_info: string,
    no_parking: boolean,
    pets_allowed: boolean,
    include_deactivated_listings: boolean,
  }): Observable<ListingNameOnly[]> {
    const data = {
      ...params,
      no_parking: params.no_parking ? 0 : 1,
      pets_allowed: params.pets_allowed ? 1 : 0,
      include_deactivated_listings: params.include_deactivated_listings ? 1 : 0,
    };
    return this.apiService.get<{ data: ListingNameOnly[] }>("/admin/property-generate-comp-report", true, data)
      .pipe(map(res => res.data));
  }

  downloadCompReport(data: {
    format: string,
    property_ids: number[],
    offset: string,
    include_deactivated_listings: boolean
  }): Observable<boolean> {
    return this.apiService.post("/admin/property-comp-report", true, data, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        const contentDispositionHeader = res.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        const fileName = result.replace(/"/g, "");

        saveAs(res.body, fileName);

        return true;
      }));
  }

  mailHomeOwnerStatement(data: {
    start_date: string,
    end_date?: string,
    format: string,
    homeowner_ids: string
  }): Observable<any> {
    data.start_date = dateToDateString(getDateObj(data.start_date));
    data.end_date = dateToDateString(getDateObj(data.end_date));
    return this.apiService.post("/mail-homeowner-statement", true, data).pipe(map(res => {
      return res;
    }));
  }

  downloadVendorReservationReport(data: {
    start_date: string,
    end_date?: string,
    format: string,
    property_ids: number[],
    offset: string}): Observable<boolean> {
    return this.apiService.post("/property-vendor-reservation-report", true, data, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        const contentDispositionHeader = res.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        const fileName = result.replace(/"/g, "");

        saveAs(res.body, fileName);

        return true;
      }));
  }

  downloadPacingReport(year: number): Observable<boolean> {
    return this.apiService.post("/admin/pacing-report", true, {year: year}, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        const contentDispositionHeader = res.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        const fileName = result.replace(/"/g, "");

        saveAs(res.body, fileName);

        return true;
      }));
  }

  downloadReviewReport(data: {
    start_date: string,
    end_date?: string,
    format: string,
    property_ids: number[],
    offset: string
  }): Observable<boolean> {
    data.start_date = dateToDateString(getDateObj(data.start_date));
    data.end_date = dateToDateString(getDateObj(data.end_date));
    return this.apiService.post("/admin/review-report", true, data, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        const contentDispositionHeader = res.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        const fileName = result.replace(/"/g, "");

        saveAs(res.body, fileName);

        return true;
      }));
  }
}
