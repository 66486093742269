import {map, take} from "rxjs/operators";
import {
  getAllPrivateNotesDrafts,
  getFullPrivateNotesDraftById,
  getIsFullPrivateNotesDraftLoaded,
  getIsFullPrivateNotesDraftLoading,
  getIsPrivateNotesDraftLoaded,
  getIsPrivateNotesDraftLoading,
  State
} from "../../reducers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {PrivateNotesDraft} from "../../models/new/drafts/private-notes-draft.model";
import {
  PrivateNotesDraftCreateRequest,
  PrivateNotesDraftCreateSuccess,
  PrivateNotesDraftDeleteRequest,
  PrivateNotesDraftDeleteSuccess,
  PrivateNotesDraftIndexRequest,
  PrivateNotesDraftIndexSuccess,
  PrivateNotesDraftUpdateRequest,
  PrivateNotesDraftUpdateSuccess
} from "../../actions/new/setttings/drafts/private-notes-drafts";
import {PrivateNotesDraftsService} from "../../services/drafts/private-notes-drafts.service";

@Injectable()
export class PrivateNotesDraftRepository {

  constructor(private store: Store<State>,
              private privateNotesDraftService: PrivateNotesDraftsService) {
  }

  /**
   * Loading & Loaded
   */
  getIsPrivateNotesDraftLoading(): Observable<boolean> {
    return this.store.select(getIsPrivateNotesDraftLoading);
  }

  getIsPrivateNotesDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsPrivateNotesDraftLoaded);
  }

  getIsFullPrivateNotesDraftLoading(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullPrivateNotesDraftLoading(state, draftId));
  }

  getIsFullPrivateNotesDraftLoaded(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullPrivateNotesDraftLoaded(state, draftId));
  }

  /**
   * Service Methods
   */
  getAllPrivateNotesDrafts(force: boolean): Observable<PrivateNotesDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsPrivateNotesDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsPrivateNotesDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new PrivateNotesDraftIndexRequest());

      this.privateNotesDraftService.getPrivateNotesDrafts().subscribe(response => {
        this.store.dispatch(new PrivateNotesDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(getAllPrivateNotesDrafts).pipe(map(t => t as PrivateNotesDraft[]));
  }

  getPrivateNotesDraftById(draftId: number): Observable<PrivateNotesDraft> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsPrivateNotesDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsPrivateNotesDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new PrivateNotesDraftIndexRequest());

      this.privateNotesDraftService.getPrivateNotesDrafts().subscribe(response => {
        this.store.dispatch(new PrivateNotesDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(state => getFullPrivateNotesDraftById(state, draftId)).pipe(
      map(t => t as PrivateNotesDraft));
  }


  createPrivateNotesDraft(data: Partial<PrivateNotesDraft>): Observable<PrivateNotesDraft> {
    this.store.dispatch(new PrivateNotesDraftCreateRequest());
    return this.privateNotesDraftService.createPrivateNotesDraft(data).pipe(map(res => {
      this.store.dispatch(new PrivateNotesDraftCreateSuccess(res.data));
      return res.data;
    }));
  }

  updatePrivateNotesDraft(draftId: number, data: Partial<PrivateNotesDraft>): Observable<PrivateNotesDraft> {
    this.store.dispatch(new PrivateNotesDraftUpdateRequest(draftId));
    return this.privateNotesDraftService.updatePrivateNotesDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new PrivateNotesDraftUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deletePrivateNotesDraft(draftId: number): Observable<null> {
    this.store.dispatch(new PrivateNotesDraftDeleteRequest(draftId));
    return this.privateNotesDraftService.deletePrivateNotesDraft(draftId).pipe(map(res => {
      this.store.dispatch(new PrivateNotesDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}

