import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { environment } from "../../../../environments/environment";
import { MenuHiddenChangeAction, SetMenuHiddenAction } from "../../../actions/app";
import { FeedsComponent } from "../../../components/feeds/feeds";
import { BookingEnumHelper } from "../../../enums/booking.enum";
import { CommonEnumHelpers, SearchOptions } from "../../../enums/common.enum";
import { TaskEnumHelper } from "../../../enums/task.enum";
import { UserCategory } from "../../../enums/user.enum";
import { Booking } from "../../../models/booking";
import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { TaskCompact } from "../../../models/new/tasks/task-compact.model";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { UserFull } from "../../../models/new/user/user-full.model";
import { UserNameOnly } from "../../../models/new/user/user-name-only.model";
import { getAppIsMenuHidden, State } from "../../../reducers";
import { ListingRepository } from "../../../repository/listing.repository";
import { UserRepository } from "../../../repository/user-repository";
import { AppService } from "../../../services/app.service";
import { StayDuvetService } from "../../../services/stayduvet";
import { CommonUtil } from "../../../utils/common.util";
import { getContactMaintenanceCatagoryType, getFormattedConfirmationCode } from "../../../utils/utils";

@Component({
  selector: "sd-header",
  template: `
    <div style="width:100% ;position:fixed;z-index:1000;top:0px;">
      <mat-toolbar id="mainToolbar" fxLayout="row" fxLayoutAlign="flex-start center" color="primary">
        <button style="z-index:99999;"
                *ngIf="showToggleButton"
                (click)="toggleWidth()"
                [style.color]="hamburgerColor"
                mat-icon-button>
          <mat-icon>format_list_bulleted</mat-icon>
        </button>
        <img class="img-margin logo none c-pointer"
             (click)="redirectToHome()"
             [src]="isAdmin ? 'https://stayduvet-content.s3.us-west-2.amazonaws.com/static/images/new_logo.png' : 'https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/logo.png'"
             fxFlex="10%"
             fxLayout="row"
             fxFlex.lt-sm="20%"
             alt="Stay Duvet">

        <span class="space-filler none"></span>

        <div fxFlex="80%" fxLayout="row" fxLayoutAlign="end" *ngIf="isUserHomeOwner || isUserVendor || isEmployee"
             fxHide.lt-sm fxShow>
          <mat-toolbar fxLayoutAlign="end end" fxLayout="row" class="toolbar" fxFlex="50%" fxLayoutGap="5%">
            <div *ngIf="isUserHomeOwner" fxLayout="row" fxLayoutAlign="center center">
              <span class="smallText" (click)="openHome()">Dashboard</span>
            </div>
            <div *ngIf="isUserVendor || isUserHomeOwner" fxLayoutAlign="center center">
              <span class="smallText" (click)="openReservations()">Reservations</span>
            </div>
            <div *ngIf="isUserHomeOwner" fxLayoutAlign="center center">
              <span class="smallText" (click)="openListings()">Listings</span>
            </div>

            <div *ngIf="isUserHouseKeeper || isEmployee" fxLayoutAlign="center center">
              <span class="smallText" (click)="openMultiCalendar()">Calendar</span>
            </div>

            <div *ngIf="isUserVendor || isUserHomeOwner" fxLayoutAlign="center center">
              <span class="smallText" (click)="openTasks()">Tasks</span>
            </div>
          </mat-toolbar>
        </div>

        <div *ngIf="showSearchBar || isUserHouseKeeper" fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%;">
          <mat-form-field class="width-1" [color]="'accent'" onblur="loading=false">
            <mat-placeholder style="width: 100%">
              <mat-icon>search</mat-icon>
              Search
            </mat-placeholder>
            <input matInput #searchInput [matAutocomplete]="auto" [(ngModel)]="searchKey"
                   (input)="search($event);save($event)">
          </mat-form-field>
          <sd-select *ngIf="!isUserHouseKeeper" class="type" [control]="dropdownOption" [options]="searchOption"></sd-select>
          <button mat-raised-button color="primary" class="none" [disabled]="loading" (click)="refresh()">Search</button>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let listing of activeListings" [value]="listing?.title"
                        (onSelectionChange)="onListingSelected(listing)"
                        [ngStyle]="{'background':listing.is_deactivated?'red': 'initial'}">
              <span style="font-weight: bold; font-size:15px;color:#fff">{{ listing?.title }} {{ listing?.city }} | LISTING</span>
            </mat-option>
            <mat-option *ngFor="let listing of inactiveListings" [value]="listing?.title"
                        (onSelectionChange)="onListingSelected(listing)"
                        [ngStyle]="{'background':listing.is_deactivated?'red': 'initial'}">
              <span style="font-weight: bold; font-size:15px;color:#fff">{{ listing?.title }} {{ listing?.city }} | LISTING</span>
            </mat-option>
            <mat-option *ngFor="let user of admins" [value]="user?.first_name"
                        (onSelectionChange)="onUserSelected(user)" [matTooltip]="getUser(user)">
            <span style="color:#fff;font-weight: bold; font-size:15px;">{{ user?.first_name
              }} {{ user?.last_name}} | ADMIN</span>
            </mat-option>

            <mat-option *ngFor="let user of owners" [value]="user?.first_name"
                        (onSelectionChange)="onUserSelected(user)" [matTooltip]="getUser(user)">
            <span style="color:#fff;font-weight: bold; font-size:15px;">{{ user?.first_name
              }} {{ user?.last_name}} | OWNER</span>
            </mat-option>

            <mat-option *ngFor="let user of managements" [value]="user?.first_name"
                        (onSelectionChange)="onUserSelected(user)" [matTooltip]="getUser(user)">
            <span style="color:#fff;font-weight: bold; font-size:15px;">{{ user?.first_name
              }} {{ user?.last_name}} | {{ getCategory(user) | uppercase}}</span>
            </mat-option>

            <div *ngFor="let task of tasks" (click)="onTaskSelected(task)"
                 style="padding: 9px 17px; font-size:15px; cursor:pointer;" fxLayout="column">
              <span style="color:#fff;font-weight: bold">{{ task?.title}} | TASK</span>
              <span style="color:#fff;font-weight: bold">Due On: {{ task?.due_date | dateFix | date:'dd-MMM-yyyy' }}
                | {{ getTaskType(task)}} | {{ getTaskStatus(task) }}</span>
              <span
                style="color:#fff; font-weight: bold; font-style: italic;">Listing: {{ getListing(task.property_id)}}</span>
            </div>


            <div *ngFor="let guest of guests">
              <div *ngFor="let booking of guest['bookings']"
                   (click)="onGuestSelected(booking)"
                   style="padding: 9px 17px; font-size:15px; cursor:pointer;" fxLayout="column"
                   [style.background]="BookingEnumHelper.getBookingStatusTitleAndColor(booking.status).color">
            <span style="color:#fff;font-weight: bold">{{ guest?.first_name
              }} {{ guest?.last_name}} | GUEST </span>
                <span
                  style="font-style: italic; font-weight: 100; color:#fff;"> {{getListingTitle(booking)}}
                  on dates {{booking.start | date}} - {{booking.end | date}}</span>
              </div>
            </div>

            <mat-option *ngFor="let booking of bookings"
                        [style.background]="BookingEnumHelper.getBookingStatusTitleAndColor(booking.status).color"
                        [value]="getFormattedConfirmationCode(booking?.source,booking?.confirmation_code)"
                        (onSelectionChange)="onReservationSelected(booking)">
            <span
              style="color:#fff;font-weight: bold; font-size:15px;">{{ getFormattedConfirmationCode(booking?.source, booking?.confirmation_code)}}
              | RESERVATION | <span
                style="font-style: italic; font-weight: 100; color:#fff;"> {{getListingTitle(booking)}}
                on dates {{booking.start | date}} - {{booking.end | date}}</span></span>
            </mat-option>

            <mat-option style="color:#fff; height: 0px;" *ngIf="showSelectAll"></mat-option>

          </mat-autocomplete>
          <div fxFlex="10%" fxLayout="row">
            <mat-spinner *ngIf="loading" color="accent" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          </div>
        </div>
        <span class="fill"></span>

        <div fxLayout="row" fxLayoutGap="5px" class="margin">
          <button *ngIf="loggedInUser.is_admin" mat-icon-button (click)="openFeeds()">
            <mat-icon>notifications</mat-icon>
          </button>

          <button mat-icon-button [matMenuTriggerFor]="userSettingsMenu" fxHide.gt-sm fxHide.sm>
            <mat-icon>more_vert</mat-icon>
          </button>
          <button mat-button [matMenuTriggerFor]="userSettingsMenu" fxFlex="10%" fxLayout="row" fxHide.lt-sm>
            {{firstName$ | async | titlecase}}
            <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          </button>
        </div>
      </mat-toolbar>


    </div>

    <mat-menu [overlapTrigger]="false" #userSettingsMenu="matMenu">
      <button mat-menu-item (click)="openHome()" *ngIf="isUserHomeOwner" fxShow.lt-sm fxHide><span>Dashboard</span>
      </button>
      <button mat-menu-item (click)="openReservations()" *ngIf="isUserVendor || isUserHomeOwner" fxShow.lt-sm fxHide>
        <span>Reservations</span></button>
      <button mat-menu-item (click)="openListings()" *ngIf="isUserHomeOwner" fxShow.lt-sm fxHide><span>Listings</span>
      </button>
      <button mat-menu-item (click)="openTasks()" *ngIf="isUserVendor || isUserHomeOwner" fxShow.lt-sm fxHide><span>Tasks</span>
      </button>
      <button mat-menu-item (click)="openMultiCalendar()" *ngIf="isUserHouseKeeper || isEmployee" fxShow.lt-sm fxHide>
        <span>Multi Calendar</span></button>
      <!--<button mat-menu-item (click)="openPayouts()" *ngIf="isUserHomeOwner"><span>Payouts</span></button>-->
      <button mat-menu-item (click)="openUserSettings()">Profile Settings</button>
      <button mat-menu-item (click)="logout()">Logout</button>
      <button mat-menu-item><span class="version-code">Version: {{ version }}</span></button>
    </mat-menu>


  `,
  styles: [`
    mat-toolbar {
      padding: 10px;
    }

    .header-container {
      height: 50px;
    }

    .toolbar {
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
      height: 78px !important;
      padding-bottom: 22px;
      justify-content: center;
    }

    .smallText {
      font-size: 15px;
      cursor: pointer;
    }

    .logo {
      /*height: 45px;*/
      margin: 0 15px 3px 0;
      vertical-align: middle;
    }

    mat-spinner {
      height: 40px;
    }

    .width-1 {
      margin-left: 20px;
      width: 40%;
    }

    .img-margin {
      margin-left: 20px;
    }

    .none {
      display: flex;
    }

    .type {
      margin: 2%;
      width: 15%;
    }

    @media only screen and (max-width: 768px) {
      /* For tab view: */
      [class*="width-"] {
        width: 80%;
        margin-left: 50px;
      }

      .img-margin {
        margin-left: 10px;
      }
    }

    @media only screen and (max-width: 480px) {
      /* For mobile phones: */
      .none {
        display: none !important;
      }

      [class*="width-"] {
        width: 100%;
        margin-left: 0;
      }

      .type {
        margin: 1%;
        width: 10%;
      }
    }
  `]
})
export class HeaderComponent implements OnInit, OnDestroy {
  version = environment.version;
  BookingEnumHelper = BookingEnumHelper;

  firstName$: Observable<string>;
  loggedInUser: UserFull;
  SearchOptions = CommonUtil.getPropsOfEnum(SearchOptions);
  searchOption: {title: string, value: any}[];
  CommonUtil = CommonUtil;
  value: string;
  redirect = false;
  showSelectAll = false;
  showSearchBar = true;
  showToggleButton = true;

  isAlive = true;
  admins: UserNameOnly[];
  owners: UserNameOnly[];
  managements: UserCompact[];
  tasks: TaskCompact[];
  activeListings: ListingCompact[];
  inactiveListings: ListingCompact[];
  currentListings: ListingCompact[] = [];
  guests: UserNameOnly[];
  bookings: Booking[];
  loading = false;
  hamburgerColor = "#000000";
  getFormattedConfirmationCode = getFormattedConfirmationCode;
  // value$ = new BehaviorSubject<any>(null);
  searchOption$ = new BehaviorSubject<SearchOptions>(SearchOptions.ACTIVE_LISTING);
  url: string;
  dropdownOption: FormControl;
  isUserVendor = false;
  isUserHomeOwner = false;
  isUserHouseKeeper = false;
  isEmployee = false;
  isAdmin = false;
  searchKey = null;
  @ViewChild("searchInput", { read: ElementRef }) searchInput: ElementRef;
  private searchTerms = new Subject<string>();
  private _globalSubscription: Subscription;
  private dialogRef;

  constructor(private service: StayDuvetService,
              private userRepo: UserRepository,
              private appService: AppService,
              private router: Router,
              private listingRepo: ListingRepository,
              private dialog: MatDialog,
              private store: Store<State>) {
  }

  ngOnInit() {
    this.dropdownOption = new FormControl(SearchOptions.ACTIVE_LISTING);
    this.searchOption = this.SearchOptions.map((option: SearchOptions) => {
      return {title: CommonEnumHelpers.getSearchOptions(option), value: option};
    });

    this.dropdownOption.valueChanges.subscribe(() => {
      this.globalSearch();
    });
    console.log("onInit sd-header");
    this.firstName$ = this.userRepo.getUser().pipe(filter(u => !!u), map((user) => {
      return user.first_name;
    }),);

    this.userRepo.getUser().pipe(filter(u => !!u)).subscribe((user) => {
      this.loggedInUser = user;
      this.isAdmin = user.is_admin;
      if ((user.type === "owner" || CommonUtil.isVendor(user) || CommonUtil.isHomeOwner(user)) && user?.managementContact?.data?.category !== UserCategory.AGENT) {
        this.isUserVendor = CommonUtil.isVendor(user);
        this.isUserHomeOwner = CommonUtil.isHomeOwner(user);
        this.isUserHouseKeeper = CommonUtil.isHouseKeeper(user);
        this.showToggleButton = false;
        this.showSearchBar = false;
        if (this.isUserVendor && (!!user.managementContact.data.creator.data.managementContact && user.managementContact.data.creator.data.managementContact.data.category === "housekeeper")) {
          this.isEmployee = true;
        }
        this.store.dispatch(new SetMenuHiddenAction());
      }
    });

    this.searchTerms.pipe(
      distinctUntilChanged(),
      debounceTime(1000),)
      .subscribe(() => this.globalSearch());

    this.store.select(getAppIsMenuHidden).subscribe((value) => {
      if (value) {
        this.hamburgerColor = "#F7DC6F";
      } else {
        this.hamburgerColor = "#000000";
      }
    });

    this.listingRepo.getAllListings()
      .subscribe((listings) => {
        this.currentListings = listings;
      });

  }

  globalSearch() {

    if (!this.searchKey || this.searchKey.trim() === "") {
      return;
    }

    if (!isNullOrUndefined(this._globalSubscription)) {
      this._globalSubscription.unsubscribe();
    }

    this.loading = true;
    this._globalSubscription = this.service.globalSearch({
      key: this.searchKey,
      type: this.isUserHouseKeeper ? "property" : this.dropdownOption.value,
    }).subscribe(result => {
      this.showSelectAll = true;
      this.activeListings = result[0].active_properties;
      this.inactiveListings = result[0].inactive_properties;
      this.admins = result[0].admins;
      this.owners = result[0].owners;
      this.managements = result[0].managements;
      this.tasks = result[0].tasks;
      this.guests = result[0].guests;
      this.bookings = result[0].bookings;
      this.loading = false;
      this.searchInput.nativeElement.focus();
    }, err => {
      this.loading = false;
    });
  }

  search($event) {
    const val = $event.target.value;

    if (val === "") {
      this.admins = null;
      this.owners = null;
      this.managements = null;
      this.tasks = null;
      this.activeListings = null;
      this.inactiveListings = null;
      this.guests = null;
      this.bookings = null;
      return;
    }

    this.searchTerms.next(val);
  }

  toggleWidth() {
    this.store.dispatch(new MenuHiddenChangeAction());
  }

  logout() {
    this.appService.logout();
  }

  openReservations() {
    this.router.navigate(["/reservations"]);
  }

  openHome() {
    this.router.navigate(["/homeowner-home"]);
  }

  openTasks() {
    this.router.navigate(["/tasks"]);
  }

  home() {
    this.router.navigate(["/"]);
  }

  openUserSettings() {
    if (this.isUserHomeOwner) {
      this.router.navigate(["/homeowner-profile"]);
    } else if (this.isUserHouseKeeper) {
      this.router.navigate(["/housekeeper-profile"]);
    } else {
      this.router.navigate(["/profile"]);
    }
  }

  onUserSelected(contact: UserNameOnly) {
    this.router.navigate(["/contacts/", contact.id]);
  }

  onListingSelected(listing: ListingCompact) {
    this.router.navigate(["/listings/" + listing.id + "/calendar"]);

  }

  onReservationSelected(booking: Booking) {
    this.router.navigate(["/reservations/" + booking.id]);

  }

  onGuestSelected(booking) {
    this.router.navigate(["/reservations/" + booking.id + "/financials"]);
  }

  onTaskSelected(task) {
    this.router.navigate(["/tasks/" + task.id]);
  }

  openListings() {
    if (this.currentListings.length === 0 || this.currentListings.length > 1) {
      this.router.navigate(["/listings/all"]);
    } else {
      this.router.navigate(["/listings/" + this.currentListings[0].id + "/manage"]);
    }
  }

  openPayouts() {
    this.router.navigate(["/payouts"]);
  }

  openMultiCalendar() {
    if (this.currentListings.length > 1) {
      this.router.navigate(["/multi-calendar"]);
    } else if (this.currentListings.length === 1) {
      this.router.navigate(["/calendar/" + this.currentListings[0].id]);
    }
  }

  getListingTitle(booking) {
    const listing = booking.property;
    if (isNullOrUndefined(listing)) {
      return null;
    }
    return listing.title;
  }

  getUser(user: UserFull) {
    let returnString = "";

    returnString = "Name : " + user.first_name;
    if (user.last_name) {
      returnString = returnString + " " + user.last_name + "\n";
    } else {
      returnString = returnString + "\n";
    }

    if (user.phone_number) {
      returnString = returnString + "Phone : " + user.phone_number + "\n";
    }

    if (user.secondary_phone_number) {
      returnString = returnString + "Secondary Phone : " + user.secondary_phone_number + "\n";
    }

    if (user.email) {
      returnString = returnString + "Email : " + user.email + "\n";
    }

    if (user.secondary_email) {
      returnString = returnString + "Secondary Email : " + user.secondary_email + "\n";
    }

    return returnString;
  }

  optionChanged(option: SearchOptions) {
    this.searchOption$.next(option);
  }

  save(event) {
    this.value = event.target.value;
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  refresh() {
    this.router.navigate(["/search"], {
      queryParams: {
        value: this.value,
        search_filter: this.dropdownOption.value,

      }
    });

  }

  getListing(propertyId: number): string {
    return this.listingRepo.getListingTitle(propertyId);
  }

  getTaskType(task: TaskCompact) {
    return TaskEnumHelper.getTaskCategoryTitle(task.type);
  }

  getTaskStatus(task: TaskCompact) {
    return TaskEnumHelper.getTaskStatusEnum(task.status).title;
  }

  getCategory(user: any) {
    if (user.managementContact && user.managementContact.category) {
      return getContactMaintenanceCatagoryType(user.managementContact.category).title;
    }
    return "MANAGEMENT";
  }

  redirectToHome() {
    if (this.loggedInUser) {
      let route = "";
      // TODO: Revert back
      if (this.loggedInUser.is_super_admin) {
        console.log("inside tasks admin");
        route = "/admin-home";
      } else if (this.loggedInUser.is_owner) {
        console.log("inside tasks home");
        route = "/home";
      } else {
        console.log("inside home owner");
        if (this.loggedInUser?.managementContact?.data?.category === "homeowner") {
          route = "/homeowner-home";
        } else {
          console.log("inside tasks owner");
          route = "/tasks";
        }
      }
      this.router.navigate([route]);
    }
  }

  openFeeds() {
    this.dialogRef = this.dialog.open(FeedsComponent);
    this.dialogRef.updateSize("100%", "100%");
  }
}
