import {map, take} from "rxjs/operators";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {Injectable} from "@angular/core";
import {
  AutoResponseCreateRequest,
  AutoResponseCreateSuccess,
  AutoResponseDeleteRequest,
  AutoResponseDeleteSuccess,
  AutoResponseIndexRequest,
  AutoResponseIndexSuccess,
  AutoResponseUpdateRequest,
  AutoResponseUpdateSuccess
} from "../actions/new/automation/auto-response";
import {AutoResponseService} from "../services/auto-response.service";
import {AutoResponse} from "../models/new/automation/auto-response.model";
import {
  getAllAutoResponses,
  getAutoResponsesForListingId,
  getFullAutoResponseById,
  getIsAutoResponseLoaded,
  getIsAutoResponseLoading,
  State
} from "../reducers";


@Injectable()
export class AutoResponseRepository {

  constructor(private store: Store<State>,
              private autoResponseService: AutoResponseService) {
  }

  /**
   * Loading & Loaded
   */
  getIsAutoResponseLoading(): Observable<boolean> {
    return this.store.select(getIsAutoResponseLoading);
  }

  getIsAutoResponseLoaded(): Observable<boolean> {
    return this.store.select(getIsAutoResponseLoaded);
  }

  /**
   * Service Methods
   */
  getAllAutoResponses(force: boolean, listingId: number): Observable<AutoResponse[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsAutoResponseLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsAutoResponseLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new AutoResponseIndexRequest());

      this.autoResponseService.getAutoResponses().subscribe(response => {
        this.store.dispatch(new AutoResponseIndexSuccess(response.data));
      });
    }

    if (listingId) {
      return this.store.select(state => getAutoResponsesForListingId(state, listingId));
    }

    return this.store.select(getAllAutoResponses).pipe(map(t => t as AutoResponse[]));
  }

  getAutoResponseById(force: boolean, responseId): Observable<AutoResponse> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsAutoResponseLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsAutoResponseLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new AutoResponseIndexRequest());

      this.autoResponseService.getAutoResponses().subscribe(response => {
        this.store.dispatch(new AutoResponseIndexSuccess(response.data));
      });
    }

    return this.store.select((state) => getFullAutoResponseById(state, responseId));
  }

  createAutoResponse(data: Partial<AutoResponse>): Observable<AutoResponse> {
    this.store.dispatch(new AutoResponseCreateRequest());
    return this.autoResponseService.createAutoResponse(data).pipe(map(res => {
      this.store.dispatch(new AutoResponseCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateAutoResponse(autoResponseId: number, data: Partial<AutoResponse>): Observable<AutoResponse> {
    this.store.dispatch(new AutoResponseUpdateRequest(autoResponseId));
    return this.autoResponseService.updateAutoResponse(autoResponseId, data).pipe(map(res => {
      this.store.dispatch(new AutoResponseUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deleteAutoResponse(autoResponseId: number): Observable<null> {
    this.store.dispatch(new AutoResponseDeleteRequest(autoResponseId));
    return this.autoResponseService.deleteAutoResponse(autoResponseId).pipe(map(res => {
      this.store.dispatch(new AutoResponseDeleteSuccess(autoResponseId));
      return res;
    }));
  }
}
