import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { debounceTime, distinctUntilChanged, takeWhile } from "rxjs/operators";

import { MultiCalendarRule } from "../../../../models/new/multi-calendar-rule";
import { ListingRepository } from "../../../../repository/listing.repository";
import { MultiCalendarRulesRepository } from "../../../../repository/multi-calendar-rules.repository";

@Component({
  selector: "sd-create-multi-calendar-rule",
  template: `
    <sd-modal-popup-layout title="{{pageTitle}}" style="overflow: scroll">
      <div fxLayout="column">
        <div style="margin-top: 10px; margin-bottom: 10px" fxLayoutGap="10px" fxLayoutAlign="space-between start"
             fxLayout="column">
          <mat-form-field [color]="'accent'" style="width: 100%">
            <mat-select
              [disabled]="openForEdit || data.listingId"
              [formControl]="parentListingControl"
              (openedChange)="!$event&&onMatSelectClose()"
              placeholder="Select Parent Listing">

              <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                <input matInput placeholder="Search Listing" [formControl]="listingFilter">
              </mat-form-field>


              <mat-option *ngFor="let listingId of filteredListings" [value]="listingId">
                {{ getListingTitleFromId(listingId) }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field [color]="'accent'" style="width: 100%">
            <mat-select
              [formControl]="blockedListingControl"
              (openedChange)="!$event&&onMatSelectClose()"
              placeholder="Select Blocked Listing">

              <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                <input matInput placeholder="Search Listing" [formControl]="listingFilter">
              </mat-form-field>


              <mat-option *ngFor="let listingId of filteredListings" [value]="listingId">
                {{ getListingTitleFromId(listingId) }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-checkbox [formControl]="reverseRuleControl">Add a reverse rule to the target listing</mat-checkbox>

          <mat-checkbox [formControl]="syncLimitControl">Synchronize Future Booking Limit</mat-checkbox>

        </div>


        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <mat-spinner *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <button mat-raised-button *ngIf="openForEdit" color="warn" (click)="deleteRule()" [disabled]="isSaving">
            Delete
          </button>
          <button mat-raised-button color="accent" (click)="saveButtonClicked()"
                  [disabled]="isSaving || !formGroup.valid">{{openForEdit ? 'UPDATE' : 'CREATE'}}
          </button>
        </div>
      </div>


    </sd-modal-popup-layout>
  `,
  styles: [`
    .title {
      font-weight: bolder;
      font-size: 22px;
      padding-left: 10px;
      padding-right: 10px;
      height: 30px;
      width: 100%;
    }

    .detailField {
      padding-left: 10px;
      padding-right: 10px;
    }

    textarea {
      resize: vertical;
    }

    .error {
      color: red;
    }

    span {
      font-size: x-small;
    }

    mat-spinner {
      width: 30px;
      height: 30px;
    }

    .select-button {
      padding: 6px;
      text-align: left;
      font-size: 17px;
      padding-left: 10px;
      font-weight: bolder;
    }

    .width-30 {
      width: 30%;
    }

    .width-63 {
      width: 63%;
    }

    @media only screen and (max-width: 600px) {

      [class*="width-"] {
        width: 95%;
      }

      .responsiveAlign {
        text-align: center;
      }

      .responsiveWidth {
        width: 95%;
      }
    }
  `]
})

export class CreateMultiCalendarRuleComponent implements OnInit, OnDestroy {

  pageTitle;

  openForEdit: boolean;
  rule: MultiCalendarRule;

  allListingIds: number[] = [];

  isSaving = false;
  isAlive = true;

  parentListingControl: FormControl;
  blockedListingControl: FormControl;
  reverseRuleControl: FormControl;
  syncLimitControl: FormControl;
  formGroup: FormGroup;
  listingId: number;
  listingFilter: FormControl = new FormControl();
  filteredListings: number[] = [];

  constructor(private rulesRepo: MultiCalendarRulesRepository,
              private listingRepo: ListingRepository,
              private dialogRef: MatDialogRef<CreateMultiCalendarRuleComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { openForEdit: boolean, rule?: MultiCalendarRule, listingId: number }) {

    if (this.data && this.data.openForEdit) {
      this.openForEdit = this.data.openForEdit;
      if (this.data.rule) {
        this.rule = this.data.rule;
        this.pageTitle = "Edit Multi-Calendar Rule";
      }
    } else {
      this.pageTitle = "Add New Multi-Calendar Rule";
      if (this.data) {
        this.listingId = this.data.listingId;
      }
    }

    this.listingRepo.getAllActivatedListingIds().pipe(takeWhile(() => this.isAlive)).subscribe(ids => {
      this.allListingIds = ids;
      this.filteredListings = ids;
    });

  }

  ngOnInit() {

    this.parentListingControl = new FormControl(this.rule ? this.rule.parent_property_id : this.listingId, [Validators.required]);
    this.blockedListingControl = new FormControl(this.rule ? this.rule.blocked_property_id : null, [Validators.required]);
    this.reverseRuleControl = new FormControl(this.rule ? this.rule.reverse_rule_applied : false, [Validators.required]);
    this.syncLimitControl = new FormControl(this.rule ? this.rule.sync_future_booking_limit : true, [Validators.required]);

    this.formGroup = new FormGroup({
      parentPropertyId: this.parentListingControl,
      blockedPropertyId: this.blockedListingControl,
      reverseRuleApplied: this.reverseRuleControl,
      syncBookingLimit: this.syncLimitControl
    });

    this.listingFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged(),).subscribe((value) => {

      if (value) {
        this.filteredListings = this.allListingIds.filter(l => this.getListingTitleFromId(l).toLowerCase().includes(value));
      } else {
        this.filteredListings = this.allListingIds;
      }

      if (this.filteredListings.length === 0) {
        this.filteredListings = this.allListingIds;
      }

    });
  }

  onMatSelectClose() {
    this.filteredListings = this.allListingIds;
  }

  saveButtonClicked() {
    this.isSaving = true;
    if (!this.rule) {
      this.rulesRepo.createMultiCalendarRule(this.formGroup.value).subscribe((res) => {
        this.isSaving = false;
        this.close();
      }, error1 => {
        this.isSaving = false;
      });
    } else {
      this.rulesRepo.updateMultiCalendarRule(this.rule.id, this.blockedListingControl.value, this.reverseRuleControl.value, this.syncLimitControl.value).subscribe((res) => {
        this.isSaving = false;
        this.close();
        this.rule = res;
      }, error1 => {
        this.isSaving = false;
      });
    }
  }

  deleteRule() {
    this.isSaving = true;
    this.rulesRepo.deleteMultiCalendarRule(this.rule.id).subscribe(() => {
      this.isSaving = false;
      this.close();
    }, error1 => {
      this.isSaving = false;
    });
  }

  getListingTitleFromId(id: number): string {
    return this.listingRepo.getListingTitle(id);
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

}
