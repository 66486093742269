import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Setting } from "../models/setting";

import { ApiService } from "./api.service";

@Injectable()
export class SettingsService {

  constructor(private apiService: ApiService) {
  }

  getSettings(): Observable<Setting[]> {
    return this.apiService.get("/options/settings", true);
  }

  updateSettings(id: number, data: { value: string }): Observable<Setting> {
    return this.apiService.put<{ setting: Setting }>("/options/settings/" + id, true, data).pipe(map(res => res.setting));
  }

  syncPrice() {
    return this.apiService.post("/admin/price-labs/sync-rates", true, null);
  }
}
