export enum BookingSource {
  AIRBNB = "airbnb",
  STAYDUVET = "stayduvet",
  HOMEAWAY = "homeaway",
  PLUM_GUIDE = "plum_guide",
  MARRIOTT = "hvmi",
  REFERRAL = "referral",
  OWNER_BLOCK = "owner_block"
}

export enum BookingAlterationSource {
  AIRBNB = "airbnb",
  STAYDUVET = "stayduvet",
  HOMEAWAY = "homeaway",
  PLUM_GUIDE = "plum_guide",
  MARRIOTT = "hvmi"
}

export enum BookingAlterationStatus {
  PENDING,
  ACCEPTED,
  REJECTED,
  NOTHING,
  CANCELLED
}

export enum BookingAlterationInitiator {
  GUEST = "guest",
  HOST = "host",
  ADMIN = "admin",
}

export enum BookingStatus {
  INQUIRY = "inquiry",
  ACCEPTED = "accepted",
  CHECKED_IN = "checked_in",
  CHECKED_OUT = "checked_out",
  PENDING = "pending",
  AWAITING_PAYMENT = "awaiting_payment",
  REQUESTED = "requested",
  DENIED = "denied",
  CANCELLED = "cancelled",
  TIMEDOUT = "timedout",
  NOT_POSSIBLE = "not_possible",
  CLOSED = "closed",
  CHECKPOINT = "checkpoint",
}

export enum BookingPaymentMethod {
  FROM_SOURCE = "from_source",
  FROM_PLATFORM = "from_platform",
  OWNERS_CHARGE = "owners_charge",
  PAID = "paid",
  OVER_THE_PHONE = "over_the_phone"
}

export enum BookingLogType {
  CREATED = "created",
  UPDATED_STATUS = "updated_status",
  UPDATED_TIME = "updated_time",
  UPDATED_LISTING = "updated_listing",
  SCHEDULED_MESSAGE_ADDED = "scheduled_message_added",
  SCHEDULED_MESSAGE_SENT = "scheduled_message_sent",
  SCHEDULED_MESSAGE_DELETED = "scheduled_message_deleted",
  SCHEDULED_MESSAGE_UPDATED = "scheduled_message_updated",
  SCHEDULED_MESSAGE_NOT_SENT = "scheduled_message_not_sent",
  AUTO_TASK_ADDED = "auto_task_added",
  AUTO_TASK_FAILED = "auto_task_failed",
  TASK_ADDED = "task_added",
  TASK_DELETED = "task_deleted",
  TASK_ARCHIVED = "task_archived",
  SECURITY_DEDUCTED = "security_deducted",
  SECURITY_REFUNDED = "security_refunded",
  PAYMENT_RECEIVED = "payment_received"
}


export class BookingEnumHelper {
  static getBookingSourceTitle(source: BookingSource): string {
    let title = "";

    switch (source) {
      case BookingSource.AIRBNB: {
        title = "airbnb";
        break;
      }

      case BookingSource.STAYDUVET: {
        title = "stayduvet";
        break;
      }

      case BookingSource.HOMEAWAY: {
        title = "VRBO";
        break;
      }

      case BookingSource.PLUM_GUIDE: {
        title = "plum guide";
        break;
      }

      case BookingSource.MARRIOTT: {
        title = "marriott";
        break;
      }

      case BookingSource.REFERRAL: {
        title = "referral";
        break;
      }

      case BookingSource.OWNER_BLOCK: {
        title = "owner block";
        break;
      }
    }

    return title;
  }

  static getBookingStatusTitleAndColor(status: BookingStatus): { title: string, status: BookingStatus, color: string } {
    let title;
    let color;

    switch (status) {
      case BookingStatus.INQUIRY: {
        title = "Inquiry";
        color = "#6d777d";
        break;
      }
      case BookingStatus.ACCEPTED: {
        title = "Accepted";
        color = "#5cb85c";
        break;
      }
      case BookingStatus.CHECKED_IN: {
        title = "Checked In";
        color = "#6ec06e";
        break;
      }
      case BookingStatus.CHECKED_OUT: {
        title = "Checked Out";
        color = "#5cb85c";
        break;
      }
      case BookingStatus.AWAITING_PAYMENT:
      case BookingStatus.PENDING: {
        title = "Awaiting Payment";
        color = "#556b8d";
        break;
      }
      case BookingStatus.REQUESTED: {
        title = "Requested";
        color = "#556b8d";
        break;
      }
      case BookingStatus.CANCELLED: {
        title = "Cancelled";
        color = "#e25d5d";
        break;
      }
      case BookingStatus.DENIED: {
        title = "Declined";
        color = "#e25d5d";
        break;
      }
      case BookingStatus.CLOSED:
      case BookingStatus.TIMEDOUT: {
        title = "Closed";
        color = "#e25d5d";
        break;
      }
      case BookingStatus.NOT_POSSIBLE: {
        title = "Not Possible";
        color = "#808080";
        break;
      }
      case BookingStatus.CHECKPOINT: {
        title = "Checkpoint";
        color = "#d9cd30";
        break;
      }
    }

    return {title: title, status: status, color: color};
  }

  static getBookingStatusList(status: BookingStatus): BookingStatus[] {
    const allowedStatuses = [];

    switch (status) {
      case BookingStatus.INQUIRY : {
        allowedStatuses.push(BookingStatus.ACCEPTED);
        allowedStatuses.push(BookingStatus.PENDING);
        allowedStatuses.push(BookingStatus.REQUESTED);
        allowedStatuses.push(BookingStatus.CANCELLED);
        allowedStatuses.push(BookingStatus.DENIED);
        allowedStatuses.push(BookingStatus.TIMEDOUT);
        allowedStatuses.push(BookingStatus.NOT_POSSIBLE);

        break;
      }
      case BookingStatus.ACCEPTED : {
        allowedStatuses.push(BookingStatus.CHECKED_IN);
        allowedStatuses.push(BookingStatus.CHECKED_OUT);
        allowedStatuses.push(BookingStatus.CANCELLED);
        allowedStatuses.push(BookingStatus.DENIED);
        allowedStatuses.push(BookingStatus.TIMEDOUT);
        break;
      }
      case BookingStatus.CHECKED_IN : {
        allowedStatuses.push(BookingStatus.CHECKED_OUT);
        allowedStatuses.push(BookingStatus.CLOSED);
        break;
      }
      case BookingStatus.CHECKED_OUT : {
        allowedStatuses.push(BookingStatus.CLOSED);
        break;
      }
      case BookingStatus.PENDING : {
        allowedStatuses.push(BookingStatus.INQUIRY);
        allowedStatuses.push(BookingStatus.ACCEPTED);
        allowedStatuses.push(BookingStatus.REQUESTED);
        allowedStatuses.push(BookingStatus.CANCELLED);
        allowedStatuses.push(BookingStatus.DENIED);
        allowedStatuses.push(BookingStatus.TIMEDOUT);
        allowedStatuses.push(BookingStatus.NOT_POSSIBLE);
        break;
      }
      case BookingStatus.AWAITING_PAYMENT : {
        break;
      }
      case BookingStatus.REQUESTED : {
        allowedStatuses.push(BookingStatus.INQUIRY);
        allowedStatuses.push(BookingStatus.ACCEPTED);
        allowedStatuses.push(BookingStatus.PENDING);
        allowedStatuses.push(BookingStatus.CANCELLED);
        allowedStatuses.push(BookingStatus.DENIED);
        allowedStatuses.push(BookingStatus.TIMEDOUT);
        allowedStatuses.push(BookingStatus.NOT_POSSIBLE);
        break;
      }
      case BookingStatus.DENIED : {
        break;
      }
      case BookingStatus.CANCELLED : {
        allowedStatuses.push(BookingStatus.CANCELLED);
        allowedStatuses.push(BookingStatus.DENIED);
        allowedStatuses.push(BookingStatus.TIMEDOUT);
        allowedStatuses.push(BookingStatus.NOT_POSSIBLE);
        break;
      }
      case BookingStatus.TIMEDOUT : {
        break;
      }
      case BookingStatus.NOT_POSSIBLE : {
        break;
      }
    }

    return allowedStatuses;
  }

  static getBookingPaymentMethod(paymentMethod: BookingPaymentMethod): string {
    let title = "";

    switch (paymentMethod) {
      case BookingPaymentMethod.FROM_SOURCE: {
        title = "From Source";
        break;
      }
      case BookingPaymentMethod.FROM_PLATFORM: {
        title = "From Platform";
        break;
      }
      case BookingPaymentMethod.OWNERS_CHARGE: {
        title = "Owners Charge";
        break;
      }
      case BookingPaymentMethod.PAID: {
        title = "Paid";
        break;
      }
      case BookingPaymentMethod.OVER_THE_PHONE: {
        title = "Over the Phone"
        break;
      }
    }

    return title;
  }

  static getBookingLogType(logType: BookingLogType): string {
    let title = "";

    switch (logType) {
      case BookingLogType.CREATED: {
        title = "Created";
        break;
      }

      case BookingLogType.UPDATED_STATUS: {
        title = "updated status";
        break;
      }

      case BookingLogType.UPDATED_TIME: {
        title = "Updated Time";
        break;
      }

      case BookingLogType.UPDATED_LISTING: {
        title = "Updated Listing";
        break;
      }
      case BookingLogType.SCHEDULED_MESSAGE_ADDED: {
        title = "Schedule message added";
        break;
      }
      case BookingLogType.SCHEDULED_MESSAGE_SENT: {
        title = "Schedule message sent";
        break;
      }
      case BookingLogType.SCHEDULED_MESSAGE_DELETED: {
        title = "All schedule message deleted";
        break;
      }
      case BookingLogType.SCHEDULED_MESSAGE_UPDATED: {
        title = "Schedule message updated";
        break;
      }
      case BookingLogType.SCHEDULED_MESSAGE_NOT_SENT: {
        title = "Schedule message not sent";
        break;
      }
      case BookingLogType.AUTO_TASK_ADDED: {
        title = "Auto task Added";
        break;
      }
      case BookingLogType.AUTO_TASK_FAILED: {
        title = "Auto task failed";
        break;
      }
      case BookingLogType.TASK_ADDED: {
        title = "Task Added";
        break;
      }
      case BookingLogType.TASK_DELETED: {
        title = "Task Deleted";
        break;
      }
      case BookingLogType.TASK_ARCHIVED: {
        title = "Task Archived";
        break;
      }
      case BookingLogType.SECURITY_DEDUCTED: {
        title = "Security Deducted";
        break;
      }
      case BookingLogType.SECURITY_REFUNDED: {
        title = "Security refunded";
        break;
      }
      case BookingLogType.PAYMENT_RECEIVED: {
        title = "Payment Recived";
        break;
      }
    }
    return title;
  }

  static getBookingAlterationSource(source: BookingAlterationSource): string {
    switch (source) {
      case BookingAlterationSource.HOMEAWAY: {
        return "VRBO";
      }

      case BookingAlterationSource.AIRBNB: {
        return "Airbnb";
      }

      case BookingAlterationSource.STAYDUVET: {
        return "Stay Duvet";
      }

      case BookingAlterationSource.PLUM_GUIDE: {
        return "Plum Guide";
      }

      case BookingAlterationSource.MARRIOTT: {
        return "Marriott";
      }
    }
  }

  static getBookingInitiator(initiator: BookingAlterationInitiator): string {
    switch (initiator) {
      case BookingAlterationInitiator.HOST: {
        return "Host";
      }

      case BookingAlterationInitiator.GUEST: {
        return "Guest";
      }

      case BookingAlterationInitiator.ADMIN: {
        return "Admin";
      }
    }
  }

  static getBookingAlterationStatus(status: BookingAlterationStatus): string {
    switch (status) {

      case BookingAlterationStatus.PENDING: {
        return "Pending";
      }

      case BookingAlterationStatus.ACCEPTED: {
        return "Accepted";
      }

      case BookingAlterationStatus.REJECTED: {
        return "Rejected";
      }

      case BookingAlterationStatus.CANCELLED: {
        return "Cancelled";
      }

    }
  }

}
