import {map, take} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {
  getAllOwnerStoryDrafts,
  getFullOwnerStoryDraftById,
  getIsFullOwnerStoryDraftLoaded,
  getIsFullOwnerStoryDraftLoading,
  getIsOwnerStoryDraftLoaded,
  getIsOwnerStoryDraftLoading,
  State
} from "../../reducers";
import {OwnerStoryDraftsService} from "../../services/drafts/owner-story-drafts.service";
import {OwnerStoryDraft} from "../../models/new/drafts/owner-story-draft.model";
import {
  OwnerStoryDraftCreateRequest,
  OwnerStoryDraftCreateSuccess,
  OwnerStoryDraftDeleteRequest,
  OwnerStoryDraftDeleteSuccess,
  OwnerStoryDraftIndexRequest,
  OwnerStoryDraftIndexSuccess,
  OwnerStoryDraftUpdateRequest,
  OwnerStoryDraftUpdateSuccess
} from "../../actions/new/setttings/drafts/owner-story-drafts";

@Injectable()
export class OwnerStoryDraftRepository {

  constructor(private store: Store<State>,
              private ownerStoryService: OwnerStoryDraftsService) {
  }

  /**
   * Loading & Loaded
   */
  getIsOwnerStoryDraftLoading(): Observable<boolean> {
    return this.store.select(getIsOwnerStoryDraftLoading);
  }

  getIsOwnerStoryDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsOwnerStoryDraftLoaded);
  }

  getIsFullOwnerStoryDraftLoading(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullOwnerStoryDraftLoading(state, draftId));
  }

  getIsFullOwnerStoryDraftLoaded(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullOwnerStoryDraftLoaded(state, draftId));
  }

  /**
   * Service Methods
   */
  getAllOwnerStoryDrafts(force: boolean): Observable<OwnerStoryDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsOwnerStoryDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsOwnerStoryDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new OwnerStoryDraftIndexRequest());

      this.ownerStoryService.getOwnerStoryDrafts().subscribe(response => {
        this.store.dispatch(new OwnerStoryDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(getAllOwnerStoryDrafts).pipe(map(t => t as OwnerStoryDraft[]));
  }

  getOwnerStoryDraftById(draftId: number): Observable<OwnerStoryDraft> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsOwnerStoryDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsOwnerStoryDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new OwnerStoryDraftIndexRequest());

      this.ownerStoryService.getOwnerStoryDrafts().subscribe(response => {
        this.store.dispatch(new OwnerStoryDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(state => getFullOwnerStoryDraftById(state, draftId)).pipe(
      map(t => t as OwnerStoryDraft));
  }


  createOwnerStoryDraft(data: Partial<OwnerStoryDraft>): Observable<OwnerStoryDraft> {
    this.store.dispatch(new OwnerStoryDraftCreateRequest());
    return this.ownerStoryService.createOwnerStoryDraft(data).pipe(map(res => {
      this.store.dispatch(new OwnerStoryDraftCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateOwnerStoryDraft(draftId: number, data: Partial<OwnerStoryDraft>): Observable<OwnerStoryDraft> {
    this.store.dispatch(new OwnerStoryDraftUpdateRequest(draftId));
    return this.ownerStoryService.updateOwnerStoryDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new OwnerStoryDraftUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deleteOwnerStoryDraft(draftId: number): Observable<null> {
    this.store.dispatch(new OwnerStoryDraftDeleteRequest(draftId));
    return this.ownerStoryService.deleteOwnerStoryDraft(draftId).pipe(map(res => {
      this.store.dispatch(new OwnerStoryDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}

