import { Pipe, PipeTransform } from "@angular/core";
import { isNullOrUndefined } from "util";
import * as moment from "moment";

@Pipe({
  name: "dateFix"
})

export class DateFixPipe implements PipeTransform {
  transform(value: string, format?: string): string {
    if (isNullOrUndefined(value)) {
      return;
    }

    return moment(value).format();
  }
}
