import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";

import { ReportService } from "../../../services/report.service";

@Component({
  selector: "sd-pacing-report-popup",
  template: `
    <sd-modal-popup-layout title="Pacing Report">
      <div class="top" fxLayout="row" fxLayoutAlign="space-between center">
        <sd-select placeholder="Year" [control]="year" [options]="yearOptions"></sd-select>
        <button *ngIf="!isDownloading" mat-raised-button color="accent" (click)="download()" [disabled]="!year.value">Download</button>
        <mat-spinner *ngIf="isDownloading" [strokeWidth]="4" [diameter]="30"></mat-spinner>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`
    .top {
      margin-top: -60px;
    }
  `]
})
export class PacingReportPopupComponent {
  year = new FormControl(null, [Validators.required]);
  yearOptions: {title: string, value: any}[] = [];
  isDownloading = false;

  constructor(private reportService: ReportService,
              private dialog: MatDialog) {
    for (let i = 2021; i <= moment().add(7, "days").year(); i++) {
      this.yearOptions.push({
        title: String(i),
        value: i
      });
    }
  }

  download() {
    if (this.year.value) {
      this.isDownloading = true;
      this.reportService.downloadPacingReport(this.year.value).subscribe(res => {
        this.isDownloading = false;
        this.dialog.closeAll();
      }, err => this.isDownloading = false);
    }
  }
}
