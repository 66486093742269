import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import Utils from "app/utils/utils";

import { Alert } from "../../../../models/alert";

import { SettingsAlertPopupComponent } from "./check-in-instructions-popup";

@Component({
  selector: "sd-automated-messages-detail",
  template: `
    <mat-card class="padding" style="cursor: pointer">
      <mat-card-content fxLayout="column" fxLayoutGap.lt-sm="5px" fxLayoutGap="10px">

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <strong (click)="openDialog(alert)" class="responsiveAlign">{{alert.title}}</strong>
          <button mat-icon-button (click)="copyToClipboard()">
            <mat-icon>file_copy</mat-icon>
          </button>
        </div>

        <div (click)="openDialog(alert)" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5px"
             fxLayoutGap="10px"
             fxLayoutAlign="space-between center" fxLayoutAlign.lt-sm="center start">
          <span class="content responsiveAlign">{{Utils.trim(alert.message, 100)}}</span>

          <span class="mat-button"
                *ngIf="alert.property_ids.length === 0 && alert.alert_type === 'exclude'"
                style="background-color: darkorange;text-align: center">
                    On all listings
                  </span>
          <span class="mat-button"
                *ngIf="!(alert.property_ids.length === 0 && alert.alert_type === 'exclude')"
                style="background-color: darkorange; text-align: center;">
                    On Multiple listings
                  </span>

        </div>


      </mat-card-content>
    </mat-card>

  `,
  styles: [`
    @media only screen and (max-width: 600px) {
      .responsiveAlign {
        text-align: center;
      }
    }

    .content {
      font-size: 14px;
      line-height: 130%;
    }

    .padding {
      padding: -10px 0px -10px -5px;
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

  `]
})

export class SettingsAutomatedMessagesDetailComponent implements OnInit {

  @Input() alert: any;
  Utils = Utils;
  private dialogRef: MatDialogRef<any>;

  constructor(private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private clipboard: Clipboard) {
  }

  ngOnInit() {
  }

  openDialog(alert: Alert) {
    const data = {
      id: alert.id,
    };
    this.dialogRef = this.dialog.open(SettingsAlertPopupComponent, {
      data: data,
    });
    this.dialogRef.updateSize("100%", "100%");
  }

  copyToClipboard() {
    this.clipboard.copy(this.alert.message);
    this.snackBar.open("Copied to Clipboard", "", {
      duration: 4000,
    });
  }
}
