import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { startOfMonth } from "date-fns";
import * as moment from "moment";
import { filter } from "rxjs/operators";

import { State } from "../../../reducers";
import { UserRepository } from "../../../repository/user-repository";
import { ReportService } from "../../../services/report.service";
import { getDateObj } from "../../../utils/calendar-utils";
import { CommonUtil } from "../../../utils/common.util";

@Component({
  selector: "sd-payout-report-component",
  template: `

    <sd-modal-popup-layout title="Payout Report">
      <form fxLayout="column" fxLayoutGap="20px" [formGroup]="formGroup"
            (ngSubmit)="formGroup.valid && saveButtonCLicked()">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
          <mat-form-field [color]="'accent'" class="width-2">
            <input matInput [matDatepicker]="startPicker" [(ngModel)]="minEndDate" [min]="minDate"
                   placeholder="Start Date"
                   formControlName='start_date'>
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
          <mat-icon>arrow_forward</mat-icon>
          <mat-form-field [color]="'accent'" class="width-2">
            <input matInput [matDatepicker]="endPicker" [min]="minEndDate" placeholder="End Date"
                   formControlName='end_date'>
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
          <sd-select class="width-2" placeholder="Format"
                     [control]="option" [options]="formats"></sd-select>
          <button mat-raised-button [disabled]="!formGroup.valid || isSaving" color="accent" type="submit">
            <span>Download</span>
          </button>
          <div fxLayoutAlign="center center">
            <mat-spinner *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          </div>
        </div>
        <mat-checkbox [formControl]="sendEmail">Send Email</mat-checkbox>
      </form>
      <sd-date-picker-options *ngIf="!isHomeOwner" (thisWeek)="setDate($event)"
                              (lastWeek)="setDate($event)"
                              (thisMonth)="setDate($event)"
                              (lastMonth)="setDate($event)"
                              (thisYear)="setDate($event)"
                              (lastYear)="setDate($event)"></sd-date-picker-options>
    </sd-modal-popup-layout>
  `,
  styles: [`
    hr {
      width: 100%;
    }

    mat-spinner {
      height: 30px;
      width: 30px;
    }

    .container-box {
      border-style: solid;
      border-width: 0.1px;
      padding: 10px;
      border-color: #c8c8c8
    }

    .half-width {
      width: 45%;
    }

    .select-button {
      padding: 6px;
      text-align: left;
      font-size: 17px;
      padding-left: 10px;
      font-weight: bolder;
    }

    .entryInputs {
      width: 45%
    }

    .width-1 {
      width: 16.6%
    }

    .width-2 {
      width: 30%;
    }

    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 90%;
      }
    }
  `],
})

export class PayoutReportComponent implements OnInit, OnDestroy {
  formats: {title: string, value: any}[] = [
    { title: "csv", value: "csv" },
    { title: "xlsx", value: "xlsx" }
  ];
  formGroup: FormGroup;
  startDate: FormControl;
  endDate: FormControl;
  option: FormControl;
  sendEmail: FormControl;
  minEndDate: Date;
  minDate: Date;
  isHomeOwner = true;

  isSaving = false;

  constructor(private service: ReportService, private userRepo: UserRepository, private store: Store<State>, private dialog: MatDialog) {
    this.startDate = new FormControl(startOfMonth(getDateObj()), [Validators.required]);
    this.endDate = new FormControl(getDateObj(), [Validators.required]);
    this.option = new FormControl("xlsx", [Validators.required]);
    this.sendEmail = new FormControl(false, [Validators.required]);

    this.formGroup = new FormGroup({
      start_date: this.startDate,
      end_date: this.endDate,
      format: this.option,
      send_email: this.sendEmail
    });

    this.userRepo.getUser().pipe(filter(u => !!u)).subscribe((user) => {
      this.isHomeOwner = CommonUtil.isHomeOwner(user);
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.startDate.setValue(startOfMonth(getDateObj()));
    });
    this.startDate.valueChanges.subscribe(r => {
      const months = moment(this.endDate.value).diff(this.startDate.value, "months");
      if (months >= 4) {
        this.sendEmail.setValue(true);
      }
    });
    this.endDate.valueChanges.subscribe(r => {
      const months = moment(this.endDate.value).diff(this.startDate.value, "months");
      if (months >= 4) {
        this.sendEmail.setValue(true);
      }
    });
  }

  saveButtonCLicked() {
    this.isSaving = true;
    const data = this.formGroup.value;

    this.service.downloadPayoutReport(data).subscribe(res => {
      this.dialog.closeAll();
      this.isSaving = false;
    }, err => {
      this.isSaving = false;
    });
  }

  setDate(data) {
    this.startDate.patchValue(data.start);
    this.endDate.patchValue(data.end);
  }

  ngOnDestroy(): void { }
}
