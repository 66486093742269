import { ReviewsSortType, SortOrder } from "../../../enums/common.enum";
import { GuestReview } from "../../../models/new/reviews/guest-review";
import { Action } from "../../action";

export enum RespondReviewsActionTypes {
  INDEX_REQUEST = "[Respond Reviews NEW] Index Request",
  INDEX_SUCCESS = "[Respond Reviews NEW] Index Success",

  PAGE_CHANGE = "[Respond Reviews NEW] Page Change",
  PER_PAGE_CHANGE = "[Respond Reviews NEW] Per Page Change",

  SORT_ORDER_CHANGE = "[Respond Reviews NEW] Sort Order Change",
  SORT_TYPE_CHANGE = "[Respond Reviews NEW] Sort Type Change",

  RATING_CHANGE = "[Respond Reviews NEW] Rating Change",
  LISTINGS_CHANGE = "[Respond Reviews NEW] Listings Change",

  CREATE_RESPONSE = "[Respond Reviews NEW] Create Response",
  TASK_DATA_CHANGE = "[Rating Reviews NEW] Task Data Change"
}

export class RespondReviewsIndexRequest implements Action {
  readonly type = RespondReviewsActionTypes.INDEX_REQUEST;
}

export class RespondReviewsIndexSuccess implements Action {
  readonly type = RespondReviewsActionTypes.INDEX_SUCCESS;

  constructor(public payload: { reviews: GuestReview[], currentPage: number, perPage: number, total: number }) {
  }
}

export class RespondReviewsPageChange implements Action {
  readonly type = RespondReviewsActionTypes.PAGE_CHANGE;

  constructor(public payload: number) {
  }
}

export class RespondReviewsPerPageChange implements Action {
  readonly type = RespondReviewsActionTypes.PER_PAGE_CHANGE;

  constructor(public payload: number) {
  }
}

export class RespondReviewsSortOrderChange implements Action {
  readonly type = RespondReviewsActionTypes.SORT_ORDER_CHANGE;

  constructor(public payload: SortOrder) {
  }
}

export class RespondReviewsSortTypeChange implements Action {
  readonly type = RespondReviewsActionTypes.SORT_TYPE_CHANGE;

  constructor(public payload: ReviewsSortType) {
  }
}

export class RespondReviewsRatingChange implements Action {
  readonly type = RespondReviewsActionTypes.RATING_CHANGE;

  constructor(public payload: number[]) {
  }
}

export class RespondReviewsListingsChange implements Action {
  readonly type = RespondReviewsActionTypes.LISTINGS_CHANGE;

  constructor(public payload: number[]) {
  }
}

export class RespondReviewsCreateResponse implements Action {
  readonly type = RespondReviewsActionTypes.CREATE_RESPONSE;

  constructor(public payload: number) {
  }
}

export class RespondReviewsTaskData implements Action {
  readonly type = RespondReviewsActionTypes.TASK_DATA_CHANGE;

  constructor(public payload: { review: GuestReview, response: string, draftId: number }) {
  }
}
