import {map, take} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {ProspectService} from "../services/prospect.service";
import {
  getAllExpiredProspectsForCurrentPage,
  getAllPassedProspectsForCurrentPage,
  getAllRecentlyCreatedProspectsForCurrentPage,
  getAllTodayProspectsForCurrentPage,
  getAllUpcomingProspectsForCurrentPage,
  getExpiredProspectCurrentPage,
  getExpiredProspectTotalCount,
  getExpiredProspectTotalPage,
  getIsExpiredProspectLoadedForPageNumber,
  getIsExpiredProspectLoadingForPageNumber,
  getIsPassedProspectLoadedForPageNumber,
  getIsPassedProspectLoadingForPageNumber,
  getIsRecentlyCreatedProspectLoadedForPageNumber,
  getIsRecentlyCreatedProspectLoadingForPageNumber,
  getIsTodayProspectLoadedForPageNumber,
  getIsTodayProspectLoadingForPageNumber,
  getIsUpcomingProspectLoadedForPageNumber,
  getIsUpcomingProspectLoadingForPageNumber,
  getPassedProspectCurrentPage,
  getPassedProspectTotalCount,
  getPassedProspectTotalPage,
  getRecentlyCreatedProspectCurrentPage,
  getRecentlyCreatedProspectTotalCount,
  getRecentlyCreatedProspectTotalPage,
  getTodayProspectCurrentPage,
  getTodayProspectTotalCount,
  getTodayProspectTotalPage,
  getUpcomingProspectCurrentPage,
  getUpcomingProspectTotalPage,
  State
} from "../reducers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {
  ChangeExpiredProspectCurrentPage,
  ChangePassedProspectCurrentPage,
  ChangeRecentlyCreatedProspectCurrentPage,
  ChangeTodayProspectCurrentPage,
  ChangeUpcomingProspectCurrentPage,
  ProspectCreateRequestAction,
  ProspectCreateSuccessAction,
  ProspectIndexExpiredRequestAction,
  ProspectIndexExpiredSuccessAction,
  ProspectIndexPassedRequestAction,
  ProspectIndexPassedSuccessAction,
  ProspectIndexRecentlyCreatedRequestAction,
  ProspectIndexRecentlyCreatedSuccessAction,
  ProspectIndexTodayRequestAction,
  ProspectIndexTodaySuccessAction,
  ProspectIndexUpcomingRequestAction,
  ProspectIndexUpcomingSuccessAction
} from "../actions/prospect";
import {Prospect} from "../models/prospect";

@Injectable()
export class ProspectRepository {

  constructor(private prospectService: ProspectService, private store: Store<State>) {
  }

  getIsTodayProspectLoadingForPageNumber(page: number): Observable<boolean> {
    return this.store.select(state => getIsTodayProspectLoadingForPageNumber(state, page));
  }

  getIsTodayProspectLoadedForPageNumber(page: number): Observable<boolean> {
    return this.store.select(state => getIsTodayProspectLoadedForPageNumber(state, page));
  }

  getTodayProspectCurrentPage(): Observable<number> {
    return this.store.select(getTodayProspectCurrentPage);
  }

  getTodayProspectTotalPage(): Observable<number> {
    return this.store.select(getTodayProspectTotalPage);
  }

  getTodayProspectTotalCount(): Observable<number> {
    return this.store.select(getTodayProspectTotalCount);
  }


  getIsUpcomingProspectLoadingForPageNumber(page: number): Observable<boolean> {
    return this.store.select(state => getIsUpcomingProspectLoadingForPageNumber(state, page));
  }

  getIsUpcomingProspectLoadedForPageNumber(page: number): Observable<boolean> {
    return this.store.select(state => getIsUpcomingProspectLoadedForPageNumber(state, page));
  }

  getUpcomingProspectCurrentPage(): Observable<number> {
    return this.store.select(getUpcomingProspectCurrentPage);
  }

  getUpcomingProspectTotalPage(): Observable<number> {
    return this.store.select(getUpcomingProspectTotalPage);
  }

  getUpcomingProspectTotalCount(): Observable<number> {
    return this.store.select(getExpiredProspectTotalCount);
  }

  getIsExpiredProspectLoadingForPageNumber(page: number): Observable<boolean> {
    return this.store.select(state => getIsExpiredProspectLoadingForPageNumber(state, page));
  }

  getIsExpiredProspectLoadedForPageNumber(page: number): Observable<boolean> {
    return this.store.select(state => getIsExpiredProspectLoadedForPageNumber(state, page));
  }

  getExpiredProspectCurrentPage(): Observable<number> {
    return this.store.select(getExpiredProspectCurrentPage);
  }

  getExpiredProspectTotalPage(): Observable<number> {
    return this.store.select(getExpiredProspectTotalPage);
  }

  getExpiredProspectTotalCount(): Observable<number> {
    return this.store.select(getExpiredProspectTotalCount);
  }

  getIsPassedProspectLoadingForPageNumber(page: number): Observable<boolean> {
    return this.store.select(state => getIsPassedProspectLoadingForPageNumber(state, page));
  }

  getIsPassedProspectLoadedForPageNumber(page: number): Observable<boolean> {
    return this.store.select(state => getIsPassedProspectLoadedForPageNumber(state, page));
  }

  getPassedProspectCurrentPage(): Observable<number> {
    return this.store.select(getPassedProspectCurrentPage);
  }

  getPassedProspectTotalPage(): Observable<number> {
    return this.store.select(getPassedProspectTotalPage);
  }

  getPassedProspectTotalCount(): Observable<number> {
    return this.store.select(getPassedProspectTotalCount);
  }


  getIsRecentlyCreatedProspectLoadingForPageNumber(page: number): Observable<boolean> {
    return this.store.select(state => getIsRecentlyCreatedProspectLoadingForPageNumber(state, page));
  }

  getIsRecentlyCreatedProspectLoadedForPageNumber(page: number): Observable<boolean> {
    return this.store.select(state => getIsRecentlyCreatedProspectLoadedForPageNumber(state, page));
  }

  getRecentlyCreatedProspectCurrentPage(): Observable<number> {
    return this.store.select(getRecentlyCreatedProspectCurrentPage);
  }

  getRecentlyCreatedProspectTotalPage(): Observable<number> {
    return this.store.select(getRecentlyCreatedProspectTotalPage);
  }

  getRecentlyCreatedProspectTotalCount(): Observable<number> {
    return this.store.select(getRecentlyCreatedProspectTotalCount);
  }


  selectTodayProspectPageNumber(page: number, force: boolean = false) {

    this.store.dispatch(new ChangeTodayProspectCurrentPage(page));

    let loading;
    let loaded;

    this.getIsTodayProspectLoadingForPageNumber(page).pipe(take(1)).subscribe(l => loading = l);
    this.getIsTodayProspectLoadedForPageNumber(page).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new ProspectIndexTodayRequestAction(page));
      this.prospectService.getTodayProspects(page).subscribe((res) => {
        this.store.dispatch(new ProspectIndexTodaySuccessAction({
          prospects: res.data,
          currentPage: res.meta.pagination.current_page,
          totalPages: res.meta.pagination.total_pages,
          totalCount: res.meta.pagination.total
        }));
      })
    }
  }

  selectUpcomingProspectPageNumber(page: number, force: boolean = false) {
    this.store.dispatch(new ChangeUpcomingProspectCurrentPage(page));
    let loading;
    let loaded;

    this.getIsUpcomingProspectLoadingForPageNumber(page).pipe(take(1)).subscribe(l => loading = l);
    this.getIsUpcomingProspectLoadingForPageNumber(page).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new ProspectIndexUpcomingRequestAction(page));
      this.prospectService.getUpcomingProspects(page).subscribe((res) => {
        this.store.dispatch(new ProspectIndexUpcomingSuccessAction({
          prospects: res.data,
          currentPage: res.meta.pagination.current_page,
          totalPages: res.meta.pagination.total_pages,
          totalCount: res.meta.pagination.total
        }));
      })
    }
  }

  selectExpiredProspectPageNumber(page: number, force: boolean = false) {
    this.store.dispatch(new ChangeExpiredProspectCurrentPage(page));
    let loading;
    let loaded;

    this.getIsExpiredProspectLoadingForPageNumber(page).pipe(take(1)).subscribe(l => loading = l);
    this.getIsExpiredProspectLoadedForPageNumber(page).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new ProspectIndexExpiredRequestAction(page));
      this.prospectService.getExpiredProspects(page).subscribe((res) => {
        this.store.dispatch(new ProspectIndexExpiredSuccessAction({
          prospects: res.data,
          currentPage: res.meta.pagination.current_page,
          totalPages: res.meta.pagination.total_pages,
          totalCount: res.meta.pagination.total
        }));
      })
    }
  }

  selectPassedProspectPageNumber(page: number, force: boolean = false) {
    this.store.dispatch(new ChangePassedProspectCurrentPage(page));
    let loading;
    let loaded;

    this.getIsPassedProspectLoadingForPageNumber(page).pipe(take(1)).subscribe(l => loading = l);
    this.getIsPassedProspectLoadedForPageNumber(page).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new ProspectIndexPassedRequestAction(page));
      this.prospectService.getPassedProspects(page).subscribe((res) => {
        this.store.dispatch(new ProspectIndexPassedSuccessAction({
          prospects: res.data,
          currentPage: res.meta.pagination.current_page,
          totalPages: res.meta.pagination.total_pages,
          totalCount: res.meta.pagination.total
        }));
      })
    }

  }

  selectRecentlyCreatedProspectPageNumber(page: number, force: boolean = false) {
    this.store.dispatch(new ChangeRecentlyCreatedProspectCurrentPage(page));
    let loading;
    let loaded;

    this.getIsRecentlyCreatedProspectLoadingForPageNumber(page).pipe(take(1)).subscribe(l => loading = l);
    this.getIsRecentlyCreatedProspectLoadedForPageNumber(page).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new ProspectIndexRecentlyCreatedRequestAction(page));
      this.prospectService.getRecentlyCreatedProspects(page).subscribe((res) => {
        this.store.dispatch(new ProspectIndexRecentlyCreatedSuccessAction({
          prospects: res.data,
          currentPage: res.meta.pagination.current_page,
          totalPages: res.meta.pagination.total_pages,
          totalCount: res.meta.pagination.total
        }));
      })
    }

  }


  getTodayProspectsForCurrentPage(): Observable<Prospect[]> {
    return this.store.select(getAllTodayProspectsForCurrentPage);
  }

  getUpcomingProspectsForCurrentPage(): Observable<Prospect[]> {
    return this.store.select(getAllUpcomingProspectsForCurrentPage);
  }

  getExpiredProspectsForCurrentPage(): Observable<Prospect[]> {
    return this.store.select(getAllExpiredProspectsForCurrentPage);
  }

  getPassedProspectsForCurrentPage(): Observable<Prospect[]> {
    return this.store.select(getAllPassedProspectsForCurrentPage);
  }

  getRecentlyCreatedProspectsForCurrentPage(): Observable<Prospect[]> {
    return this.store.select(getAllRecentlyCreatedProspectsForCurrentPage);
  }

  createProspects(data: any): Observable<Prospect> {
    this.store.dispatch(new ProspectCreateRequestAction());
    return this.prospectService.createProspects(data).pipe(map(res => {
      this.store.dispatch(new ProspectCreateSuccessAction(res));
      return res;
    }));
  }

}
