import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { isNullOrUndefined } from "util";

import { BookingFull } from "../../../../models/new/booking/booking-full.model";
import { BookingRepository } from "../../../../repository/booking.repository";

@Component({
  selector: "sd-change-listing-popup",
  template: `
    <sd-modal-popup-layout [showCloseAction]="false" [title]="title">
      <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
          <span>Are you sure you want to change the listing from <b> {{previousListingTitle}} </b> to <b> {{newListingTitle}} </b> ?</span>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">
          <span class="space-filler"></span>
          <span *ngIf="isErrorReceived" style="color: red" fxFlexAlign="center">{{reason}}</span>
          <mat-spinner *ngIf="isLoading" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <button mat-raised-button color="accent" [disabled]="isLoading || !isAvailable"
                  (click)="onYesButtonClicked()">
            {{ yesButtonText }}
          </button>
          <button mat-raised-button color="primary" [disabled]="isLoading" (click)="close()"> No</button>
        </div>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`
    mat-spinner {
      width: 24px;
      height: 24px;
      margin-right: 20px;
    }
  `]
})

export class ChangeListingPopupComponent implements OnInit {
  title = "Change Listing - Confirmation";
  showCloseButton = true;
  showNoButton = true;

  yesButtonText = "Yes";
  isLoading = false;
  isAvailable: boolean;
  isErrorReceived: boolean;
  reason: string;

  @Output() success = new EventEmitter;
  @Output() error = new EventEmitter;

  @Input() previousListingTitle: string;
  @Input() newListingTitle: string;
  @Input() newListingId: number;
  @Input() booking: BookingFull;

  constructor(private dialogRef: MatDialogRef<ChangeListingPopupComponent>,
              private bookingRepo: BookingRepository,
              @Inject(MAT_DIALOG_DATA) private data: ChangeListingPopupData) {
    if (!isNullOrUndefined(data)) {
      this.previousListingTitle = data.previousListingTitle;
      this.newListingTitle = data.newListingTitle;
      this.booking = data.booking;
      this.newListingId = data.newListingId;
    }

  }

  ngOnInit() {
    this.isLoading = true;
    this.isLoading = false;
    this.isAvailable = true;
    this.isErrorReceived = !this.isAvailable;
  }

  onYesButtonClicked() {
    this.isLoading = true;
    this.bookingRepo.updateProperty(
      this.booking.id,
      this.newListingId
    ).subscribe((data) => {
      this.isLoading = false;
      this.isErrorReceived = !this.isAvailable;
      this.success.emit();
    }, (err) => {
      this.isLoading = false;
      this.isAvailable = false;
      this.reason = err.message;
    });
  }

  close() {
    this.error.emit();
  }
}

export interface ChangeListingPopupData {
  previousListingTitle: string;
  newListingTitle: string;
  newListingId: number;
  booking: BookingFull;
}
