import {LOGIN_REQUEST, LOGIN_SUCCESS, UPDATE_REQUEST, UPDATE_SUCCESS} from "../actions/user";
import {Action} from "../actions/action";
import {UserFull} from "../models/new/user/user-full.model";
import {ContactActionTypes} from "../actions/new/contacts/contact";
import {BankInfoModel} from "../models/bank-info.model";
import {W9Form} from "../models/new/user/w9-form.model";


export interface State {
  user: UserFull;
  loggedIn: boolean;
  loggingIn: boolean;
  updating: boolean;
}

export const initialState: State = {
  user: null,
  loggedIn: false,
  loggingIn: false,
  updating: false
};

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return Object.assign({}, state, {loggingIn: true});
    }
    case LOGIN_SUCCESS: {
      return Object.assign({}, state, {user: action.payload, loggedIn: true, loggingIn: false});
    }
    case UPDATE_REQUEST: {
      return Object.assign({}, state, {updating: true});
    }
    case UPDATE_SUCCESS: {
      return Object.assign({}, state, {user: action.payload, updating: false, loggedIn: true});
    }

    case ContactActionTypes.BANK_ACCOUNT_CREATE_SUCCESS: {
      const bankAccount = action.payload as BankInfoModel;

      if (state.user && bankAccount.user_id === state.user.id) {
        return {
          ...state,
          user: {
            ...state.user,
            bankDetails: {
              data: [
                ...state.user.bankDetails.data,
                bankAccount
              ]
            }
          }
        }
      }

      return state;

    }

    case ContactActionTypes.BANK_ACCOUNT_UPDATE_SUCCESS: {
      const bankAccount = action.payload as BankInfoModel;

      if (state.user && bankAccount.user_id === state.user.id) {


        let bankAccounts = state.user.bankDetails.data as BankInfoModel[];

        const index = bankAccounts.map(b => b.id).indexOf(bankAccount.id);

        if (index !== -1) {
          bankAccounts = [
            ...bankAccounts.slice(0, index),
            bankAccount,
            ...bankAccounts.slice(index + 1),
          ]
        }


        return {
          ...state,
          user: {
            ...state.user,
            bankDetails: {
              data: bankAccounts
            }
          }
        }
      }

      return state;
    }

    case ContactActionTypes.BANK_ACCOUNT_DELETE_SUCCESS: {
      const payload = action.payload as { bankAccountId: number, contactId: number };

      if (state.user && state.user.id === payload.contactId) {
        let bankAccounts = state.user.bankDetails.data as BankInfoModel[];

        const index = bankAccounts.map(b => b.id).indexOf(payload.bankAccountId);

        if (index !== -1) {
          bankAccounts = [
            ...bankAccounts.slice(0, index),
            ...bankAccounts.slice(index + 1),
          ]
        }

        return {
          ...state,
          user: {
            ...state.user,
            bankDetails: {
              data: bankAccounts
            }
          }
        }
      }

      return state;
    }

    case ContactActionTypes.W9_FORM_UPLOAD_SUCCESS: {
      const payload = action.payload as W9Form;

      if (state.user && state.user.id === payload.user_id) {

        return {
          ...state,
          user: {
            ...state.user,
            w9Forms: {
              data: [
                ...state.user.w9Forms.data,
                payload
              ]
            }
          }
        }


      }

      return state;

    }

    case ContactActionTypes.LISTING_ACTIVE_STATUS_SUCCESS: {
      const payload = action.payload as { contactId: number, active: boolean };

      let contacts = state.user.managementContacts.data;

      const index = contacts.map(c => c.id).indexOf(payload.contactId);

      if (index !== -1) {
        contacts = [
          ...contacts.slice(0, index),
          {
            ...contacts[index],
            is_active: payload.active
          },
          ...contacts.slice(index + 1)
        ];
      }
      return {
        ...state,
        user: {
          ...state.user,
          managementContacts: {
            data: contacts
          }
        }
      }
    }


    default: {
      return state;
    }
  }
}

export const getUser = (state: State) => state.user;

export const isLoggedIn = (state: State) => state.loggedIn;
export const isLoggingIn = (state: State) => state.loggingIn;
export const isUpdating = (state: State) => state.updating;
