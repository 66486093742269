export enum OnboardingTaskOffsetReference {
  FIRST_RESERVATION = "first_reservation",
  START_DATE = "start_date",
  CREATE_DATE = "create_date",
  GO_LIVE_DATE = "go_live_date"
}

export enum OnboardingTaskAssigneeType {
  HVAC = "hvac",
  PLUMBER = "plumber",
  PAINTER = "painter",
  ELECTRICIAN = "electrician",
  HOMEOWNER = "homeowner",
  INSPECTOR = "inspector",
  GENERAL_MAINTENANCE = "general_maintenance",
  HOUSEKEEPER = "housekeeper",
  ASSIGNEE = "assignee",
  SALES = "sales",
  PREVENTATIVE_MAINTENANCE = "preventative_maintenance",
  LANDSCAPING = "landscaping"
}

export enum OnboardingTaskExpenseType {
  CLEANING_FEE = "cleaning_fee",
  STATE_TAX_AMOUNT = "state_tax_amount",
  COMMISSION_AMOUNT = "commission_amount"
}

export class OnboardingTaskEnumHelper {
  static getOnboardingTaskOffsetReferenceTitle(ref: OnboardingTaskOffsetReference): string {
    let title = "";
    switch (ref) {
      case OnboardingTaskOffsetReference.FIRST_RESERVATION : {
        title = "First Reservation";
        break;
      }

      case OnboardingTaskOffsetReference.START_DATE : {
        title = "Start Date";
        break;
      }
      case OnboardingTaskOffsetReference.CREATE_DATE : {
        title = "Create Date";
        break;
      }
      case OnboardingTaskOffsetReference.GO_LIVE_DATE : {
        title = "Go Live Date";
        break;
      }
    }
    return title;
  }

  static getOnboardingTaskAssigneeType(ref: OnboardingTaskAssigneeType): string {
    let title = "";
    switch (ref) {
      case OnboardingTaskAssigneeType.HVAC : {
        title = " HVAC";
        break;
      }
      case OnboardingTaskAssigneeType.PLUMBER : {
        title = " Plumber";
        break;
      }
      case OnboardingTaskAssigneeType.PAINTER : {
        title = " Painter";
        break;
      }
      case OnboardingTaskAssigneeType.ELECTRICIAN : {
        title = " Electrician";
        break;
      }
      case OnboardingTaskAssigneeType.GENERAL_MAINTENANCE : {
        title = " General Maintenance";
        break;
      }
      case OnboardingTaskAssigneeType.HOMEOWNER : {
        title = " Home Owner";
        break;
      }
      case OnboardingTaskAssigneeType.INSPECTOR : {
        title = " Inspector";
        break;
      }
      case OnboardingTaskAssigneeType.HOUSEKEEPER : {
        title = " Housekeeper";
        break;
      }
      case OnboardingTaskAssigneeType.SALES : {
        title = "Sales";
        break;
      }

      case OnboardingTaskAssigneeType.PREVENTATIVE_MAINTENANCE : {
        title = "Preventative Maintenance";
        break;
      }

      case OnboardingTaskAssigneeType.LANDSCAPING : {
        title = "Landscaping";
        break;
      }
      case OnboardingTaskAssigneeType.ASSIGNEE : {
        title = " Assignee for the listing";
        break;
      }
    }
    return title;
  }
}
