import { isNullOrUndefined } from "util";

import { BookingCompact } from "../new/booking/booking-compact.model";
import { BookingFull } from "../new/booking/booking-full.model";
import { ListingFull } from "../new/listing/listing-full.model";
import { UserCompact } from "../new/user/user-compact.model";

export class BookingModelUtil {
  static getFormattedConfirmationCode(booking: BookingCompact | BookingFull): string {
    if (!booking.confirmation_code) {
      return "######";
    }

    let prefix = "";

    if (booking.source === "airbnb") {
      prefix = "AB";
    } else if (booking.source === "stayduvet") {
      prefix = "SD";
    } else if (booking.source === "homeaway") {
      prefix = "HA";
    }

    if (prefix) {
      return prefix + "-" + booking.confirmation_code;
    }

    return booking.confirmation_code;
  }

  static getGuest(booking: BookingFull): UserCompact {

    if (isNullOrUndefined(booking)) {
      return;
    }

    return booking.guest.data;
  }

  static getGuestName(booking: BookingFull): string {
    const guest = this.getGuest(booking);

    if (guest) {
      return guest.first_name + " " + guest.last_name;
    }

    return;
  }

  static getGuests(booking: BookingFull): UserCompact[] {
    return booking.guests.data;
  }

  static getListing(booking: BookingFull): ListingFull {
    return booking.property.data;
  }

  static hasNotes(booking: BookingCompact): boolean {

    if (isNullOrUndefined(booking.booking_notes) || booking.booking_notes === "") {
      return false;
    }

    return true;
  }

}
