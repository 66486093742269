import * as moment from "moment";
import { isNullOrUndefined } from "util";

import { ListingFull } from "../new/listing/listing-full.model";
import { UserCompact } from "../new/user/user-compact.model";
import { Prospect } from "../prospect";
import { Quote } from "../quote";

export class QuoteModelUtil {

  static getProperty(quote: Quote): ListingFull {
    if (isNullOrUndefined(quote["property"]["data"])) {
      return null;
    }

    return quote["property"]["data"];
  }

  static getProspect(quote: Quote): Prospect {
    return quote["prospect"]["data"];
  }

  static getGuest(quote: Quote): UserCompact {
    return this.getProspect(quote)["guest"]["data"];
  }


  static payableAmount(quote: Quote): number {
    let total = quote.base_amount + quote.cleaning_fee + quote.security_deposit_fee;

    if (quote.other_fee.extra_guest_fee) {
      total += quote.other_fee.extra_guest_fee;
    }

    if (quote.other_fee.pet_fee) {
      total += quote.other_fee.pet_fee;
    }
    if (quote.other_fee.other_fee) {
      total += quote.other_fee.other_fee;
    }

    return total;
  }

  static serviceFee(quote: Quote, percent: number = 7): number {

    if (!isNullOrUndefined(quote.service_fee)) {
      return quote.service_fee;
    }

    if (!isNullOrUndefined(quote.guest_channel_fee)) {
      return quote.guest_channel_fee;
    }

    return (this.payableAmount(quote)) * percent / 100;
  }

  static ccProcessFee(quote: Quote, percent: number = 3): number {

    if (!isNullOrUndefined(quote.cc_process_fee)) {
      return quote.cc_process_fee;
    }

    return (this.payableAmount(quote)) * percent / 100;
  }

  static homeawayCCProcessFee(quote: Quote, percent: number = 7): number {

    if (!isNullOrUndefined(quote.cc_process_fee)) {
      return quote.cc_process_fee;
    }

    return quote.base_amount * percent / 100;
  }

  static propertyDamageProtection(quote: Quote, check_out, check_in): number {

    if (!isNullOrUndefined(quote.property_damage_protection)) {
      return quote.property_damage_protection;
    }

    console.log(Math.floor((Date.parse(check_out) - Date.parse(check_in))/(1000*60*60*24)));
    return 8 * Math.floor((Date.parse(check_out) - Date.parse(check_in))/(1000*60*60*24));
  }

  static taxes(quote: Quote, percent: number = 20): number {

    if (!isNullOrUndefined(quote.occupancy_taxes)){
      return quote.occupancy_taxes;
    }

    return (quote.base_amount + quote.cleaning_fee) * quote.tax_rate / 100;
  }

  static total(quote: Quote): number {
    return this.payableAmount(quote) + this.serviceFee(quote) + this.ccProcessFee(quote)+ this.taxes(quote);
  }

  static otherFee(quote: Quote): number {
    return quote.other_fee.extra_guest_fee;
  }
}
