/**
 * Created by aditya on 18/9/17.
 */

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { startOfMonth } from "date-fns";
import * as moment from "moment";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, takeUntil } from "rxjs/operators";

import { BookingEnumHelper, BookingStatus } from "../../../enums/booking.enum";
import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { State } from "../../../reducers";
import { ListingRepository } from "../../../repository/listing.repository";
import { OptionsRepository } from "../../../repository/options.repository";
import { StatsRepository } from "../../../repository/stats.repository";
import { UserRepository } from "../../../repository/user-repository";
import { ReportService } from "../../../services/report.service";
import { getDateObj } from "../../../utils/calendar-utils";
import { CommonUtil } from "../../../utils/common.util";
import FormUtils from "../../../utils/form";

@Component({
  selector: "sd-property-income-report-popup",
  template: `
    <sd-modal-popup-layout title="Property Income Report">
      <div fxLayout="column" class="dialog-content">
        <form fxLayout="column" fxLayoutGap="20px" [formGroup]="formGroup"
              (ngSubmit)="formGroup.valid && saveButtonCLicked()">
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <mat-form-field [color]="'accent'" class="width-3">
              <input matInput [matDatepicker]="startPicker" [(ngModel)]="minEndDate" [min]="minDate"
                     placeholder="Start Date"
                     formControlName='start_date'>
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
            <mat-icon>arrow_forward</mat-icon>
            <mat-form-field [color]="'accent'" class="width-3">
              <input matInput [matDatepicker]="endPicker" [min]="minEndDate" placeholder="End Date"
                     formControlName='end_date'>
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field [color]="'accent'" class="width-2">
              <mat-select multiple placeholder="Select Listings" [(ngModel)]="selectedListingIds"
                          formControlName='property_ids'
                          (openedChange)="!$event&&onMatSelectClose()">


                <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                  <input matInput placeholder="Search Listing" [formControl]="listingFilter">
                </mat-form-field>

                <div fxLayout="column">
                  <button class="select-button" mat-button (click)="onSelectAll()">Select All</button>
                  <button class="select-button" mat-button (click)="onSelectNone()">Select None</button>
                </div>
                <mat-option *ngFor="let listing of filteredListings" [value]="listing.id"
                            [ngClass]="{'background-red': listing.is_deactivated}">
                  {{ listing.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <sd-select class="width-3" *ngIf="isAdmin" placeholder="Select Tag"
                       [control]="tagFilterControl" [options]="tagsOptions" [multiple]="true"
                       (selectionChanged)="propertyTagFilterChanged()"></sd-select>

            <sd-select class="width-1" placeholder="Format"
                       [control]="option" [options]="formats"></sd-select>

            <sd-select *ngIf="!isHomeOwner" class="width-3" placeholder="Offset"
                       [control]="offset" [options]="offsetOptions"></sd-select>
            <div fxLayoutAlign="center center">
              <mat-spinner *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
            </div>
          </div>
        </form>
        <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
          <sd-date-picker-options *ngIf="!isHomeOwner" (thisWeek)="setDate($event)"
                                  (lastWeek)="setDate($event)"
                                  (thisMonth)="setDate($event)"
                                  (lastMonth)="setDate($event)"
                                  (thisYear)="setDate($event)"
                                  (lastYear)="setDate($event)">
          </sd-date-picker-options>

          <sd-select class="width-3" placeholder="Status" [multiple]="true"
                     [control]="statuses" [options]="bookingOptions"></sd-select>

          <mat-checkbox [(ngModel)]="includeDeactivated" (change)="onIncludeDeactivatedChanged($event.checked)">Include
            Deactivated
          </mat-checkbox>

          <mat-checkbox [formControl]="sendEmail">Send Email</mat-checkbox>
        </div>
        <div fxFlexAlign="end">
          <button mat-raised-button [disabled]="!formGroup.valid || isSaving" color="accent"
                  (click)="saveButtonCLicked()">
            <span>Download</span>
          </button>
        </div>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`
    hr {
      width: 100%;
    }

    .background-red {
      background: red !important;
    }

    mat-spinner {
      height: 30px;
      width: 30px;
    }

    .container-box {
      border-style: solid;
      border-width: 0.1px;
      padding: 10px;
      border-color: #c8c8c8
    }

    .half-width {
      width: 45%;
    }

    .select-button {
      padding: 6px;
      text-align: left;
      font-size: 17px;
      padding-left: 10px;
      font-weight: bolder;
    }

    .entryInputs {
      width: 45%
    }

    .width-1 {
      width: 16.6%
    }

    .width-2 {
      width: 30%;
    }

    .width3 {
      width: 12%;
    }

    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 90%;
      }

      .dialog-content {
        overflow-y: scroll;
        height: 75vh
      }
    }
  `]
})
export class PropertyIncomeReportPopupComponent implements OnInit, OnDestroy {

  formats: {title: string, value: any}[] = [
    { title: "csv", value: "csv" },
    { title: "xlsx", value: "xlsx" }
  ];

  formGroup: FormGroup;
  startDate: FormControl;
  endDate: FormControl;
  option: FormControl;
  sendEmail: FormControl;
  properties: FormControl;
  offset: FormControl;
  statuses: FormControl;
  tagFilter = [];
  tagFilterControl: FormControl = new FormControl(this.tagFilter);
  includeDeactivated = false;
  filteredListings: ListingCompact[] = [];
  listingFilter: FormControl = new FormControl();
  minEndDate: Date;
  minDate: Date;
  isHomeOwner = true;
  tags = [];
  tagsOptions: {title: string, value: any}[] = [];
  offsetOptions: {title: string, value: any}[] = [
    { title: "Check In", value: "start" },
    { title: "Check Out", value: "end" },
    { title: "Created Date", value: "created_at" },
    { title: "Cancelled Date", value: "cancelled_at" },
  ];
  selectedListingIds = [];
  bookingStatuses = [
    "inquiry",
    "accepted",
    "checked_in",
    "checked_out",
    "awaiting_payment",
    "requested",
    "denied",
    "cancelled",
    "timedout",
    "not_possible"];
  bookingOptions: {title: string, value: any}[];
  BookingEnumHelper = BookingEnumHelper;
  @Input() listings: ListingCompact[] = [];
  @Input() selectedListings: ListingCompact[] = [];
  @Input() isAdmin = false;
  allListings: ListingCompact[] = [];
  isSaving = false;
  private destroyed$ = new Subject();

  constructor(private service: ReportService,
              private userRepo: UserRepository,
              private store: Store<State>,
              private dialog: MatDialog,
              private statsRepo: StatsRepository,
              private optionsRepo: OptionsRepository,
              private listingRepo: ListingRepository) {
    this.startDate = new FormControl(null, [Validators.required]);
    this.endDate = new FormControl(null, [Validators.required]);
    this.option = new FormControl("csv", [Validators.required]);
    this.properties = new FormControl(null, [Validators.required]);
    this.sendEmail = new FormControl(false, [Validators.required]);
    this.offset = new FormControl("end");
    this.statuses = new FormControl([]);

    this.formGroup = new FormGroup({
      start_date: this.startDate,
      end_date: this.endDate,
      format: this.option,
      property_ids: this.properties,
      offset: this.offset,
      statuses: this.statuses,
      send_email: this.sendEmail
    });

    this.userRepo.getUser().pipe(filter(u => !!u)).subscribe((user) => {
      this.isHomeOwner = CommonUtil.isHomeOwner(user);
    });

    this.listingRepo.getAllListings()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(listings => {
        if (listings && listings.length) {
          this.allListings = listings.filter(l => l.status === "accepted" && !!l.title && l.title.trim() !== "");
        }
      });
  }

  ngOnInit() {
    this.setUpTags();
    setTimeout(() => {
      if (this.selectedListings.length > 0) {
        this.properties.patchValue(this.selectedListings.map(lis => lis.id));
      } else {
        this.onSelectAll();
      }
      this.startDate.setValue(startOfMonth(getDateObj()));
    });
    this.filteredListings = this.listings;
    this.listingFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged()).subscribe((value) => {
      const listings = this.includeDeactivated ? this.allListings : this.listings;
      if (value) {
        this.filteredListings = listings.filter(l => l.title.toLowerCase().includes(value));
      } else {
        this.filteredListings = listings;
      }

      if (this.filteredListings.length === 0) {
        this.filteredListings = listings;
      }
    });

    setTimeout(() => {
      if (this.isHomeOwner) {
        this.minDate = getDateObj("2019-01-01");
        this.startDate.setValue(getDateObj("2019-01-01"));
        this.endDate.setValue(getDateObj("2019-01-31"));
      } else {
        this.startDate.setValue(startOfMonth(getDateObj()));
        this.endDate.setValue(getDateObj());
      }
    }, 100);

    this.bookingOptions = this.bookingStatuses.map((status: BookingStatus) => {
      return {title: BookingEnumHelper.getBookingStatusTitleAndColor(status).title, value: status};
    });

    this.offset
      .valueChanges
      .pipe(filter(v => !!v && v === "cancelled_at"))
      .subscribe(() => this.statuses.setValue(["denied", "cancelled"]));

    this.startDate.valueChanges.subscribe(r => {
      const months = moment(this.endDate.value).diff(this.startDate.value, "months");
      if (months >= 4) {
        this.sendEmail.setValue(true);
      }
    });
    this.endDate.valueChanges.subscribe(r => {
      const months = moment(this.endDate.value).diff(this.startDate.value, "months");
      if (months >= 4) {
        this.sendEmail.setValue(true);
      }
    });
  }

  saveButtonCLicked() {
    if (!this.formGroup.valid) {
      FormUtils.markAllAsTouched(this.formGroup);
      return;
    }
    this.isSaving = true;
    const data = {
      ...this.formGroup.value,
      start_date: this.startDate.value,
      end_date: this.endDate.value
    };
    data.property_ids = this.selectedListingIds;

    if (this.isHomeOwner) {
      data.offset = "end";
    }

    this.service.downloadPropertyIncomeReport(data).subscribe(res => {
      this.dialog.closeAll();
      this.isSaving = false;
    }, err => {
      this.isSaving = false;
    });
  }

  onSelectAll() {
    const listings = this.includeDeactivated ? this.allListings : this.listings;
    this.properties.setValue(listings.map(listing => listing.id));
  }

  onSelectNone() {
    this.properties.setValue([]);
  }

  setDate(data) {
    this.startDate.patchValue(data.start);
    this.endDate.patchValue(data.end);
  }

  onMatSelectClose() {
    this.filteredListings = this.includeDeactivated ? this.allListings : this.listings;
  }

  propertyTagFilterChanged() {
    this.tagFilter = this.tagFilterControl.value;
    console.log("called selected tag changed");
    const listings = this.includeDeactivated ? this.allListings : this.listings;
    if (this.tagFilter.length !== this.tagsOptions.length) {
      const properties = listings.filter(listing => {
        const tags = listing.tags.data.map(tag => tag.title);
        return this.tagFilter.some(tag => tags.includes(tag));
      });
      this.properties.patchValue(properties.map(prop => prop.id));
    } else {
      this.onSelectAll();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onIncludeDeactivatedChanged(value: boolean) {
    const listings = value ? this.allListings : this.listings;
    this.filteredListings = listings;
    if (value) {
      this.properties.patchValue(listings.filter(l => l.is_deactivated).map(p => p.id));
    }
  }

  private setUpTags() {
    this.optionsRepo.getAllTags().pipe(takeUntil(this.destroyed$))
      .subscribe(tags => {
        this.tags = tags;
        this.tags.map(tag => {
          this.tagFilter.push(tag.title);
          this.tagsOptions.push({title: tag.title, value: tag.title});
        });
        this.tagFilterControl.setValue(this.tagFilter);
      });
  }
}
