import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { __HTTPResponseType, PerPage, SortOrder } from "../enums/common.enum";
import { TaskPaymentBy, TaskRepeatFrequency, TaskSortBy, TaskStatus, TaskType } from "../enums/task.enum";
import { Comment } from "../models/new/tasks/comment";
import { TaskCompact } from "../models/new/tasks/task-compact.model";
import { TaskFull } from "../models/new/tasks/task-full.model";
import { UploadComplete } from "../models/new/upload-complete";
import { UploadFile } from "../models/new/upload-file";
import { TaskGetResponse } from "../models/responses/tasks/task-get.response";
import { TaskShowResponse } from "../models/responses/tasks/task-show.response";
import { UploadCompleteShowReponse } from "../models/responses/upload-complete-show.reponse";
import { UploadFileShowResponse } from "../models/responses/upload-file-show.response";
import { dateToDateString } from "../utils/calendar-utils";

import { ApiService } from "./api.service";
import { SnackbarService } from "./snackbar.service";

@Injectable()
export class TaskService {

  private INDEX_INCLUDES = "assignee,expenses,booking,property";
  private SHOW_INCLUDES = "attachments,expenses,assignee,booking,creator";

  constructor(private apiService: ApiService,
              private snackbar: SnackbarService) {
  }

  getTasks(page: number,
           per_page: PerPage,
           sortBy: TaskSortBy,
           sortOrder: SortOrder,
           type: TaskType,
           category: string[],
           assigneeIds: number[],
           employeeIds: number[],
           createdById: number[],
           listingIds: number[],
           status: string[],
           whoWillPay: TaskPaymentBy[],
           priceFilter: boolean,
           open_property: boolean,
           startDate: Date,
           endDate: Date,
           offset: string,
           employee_id?: number,
  ): Observable<TaskGetResponse> {
    const data: any = {
      page: page,
      per_page: +per_page,
      sort_by: sortBy,
      sort_order: sortOrder,
      type: type,
      category: category,
      assignee_ids: assigneeIds,
      employee_ids: employeeIds,
      creator_ids: createdById,
      include: this.INDEX_INCLUDES,
      listing_ids: listingIds,
      status: status,
      payment_by: whoWillPay,
      price_filter: priceFilter ? 1 : 0,
      open_property: open_property ? 1 : 0,
      start_date: startDate,
      end_date: endDate,
      offset: offset
    };

    if (!startDate && !endDate) {
      delete data.offset;
    }

    for (const key in data) {
      if (!data[key]) {
        delete data[key];
      }
    }

    if (data.start_date) {
      data.start_date = dateToDateString(data.start_date);
    }

    if (data.end_date) {
      data.end_date = dateToDateString(data.end_date);
    }

    let url = "/tasks";
    console.log("employee id", employee_id);
    if (employee_id) {
      url = "/employee-tasks/"+ employee_id;
    }

    return this.apiService.get<TaskGetResponse>(url, true, data);
  }

  showTask(taskId: number): Observable<TaskShowResponse> {
    return this.apiService.get<TaskShowResponse>("/tasks/" + taskId, true, {
      include: this.SHOW_INCLUDES
    }).pipe(map(res => {
      return {
        ...res,
        data: this.mapApiTaskFull(res.data)
      };
    }));
  }

  createTask(data: any): Observable<TaskShowResponse> {
    return this.apiService.post<TaskShowResponse>("/tasks", true, {
      ...data,
      include: this.SHOW_INCLUDES
    }).pipe(map(res => {
      return {
        ...res,
        data: this.mapApiTaskFull(res.data)
      };
    }));
  }

  duplicateTask(taskId: number, showExpense: boolean): Observable<TaskShowResponse> {

    const data = {
      include_expenses: showExpense ? 1 : 0,
      include: this.SHOW_INCLUDES
    };

    return this.apiService.get<TaskShowResponse>("/tasks/" + taskId + "/clone", true, data)
      .pipe(map(res => {
        return {
          ...res,
          data: this.mapApiTaskFull(res.data)
        };
      }));
  }

  updateTask(taskId: number, data: any): Observable<TaskShowResponse> {
    return this.apiService.put<TaskShowResponse>("/tasks/" + taskId, true, {
      ...data,
      include: this.SHOW_INCLUDES
    }).pipe(map(res => {
      return {
        ...res,
        data: this.mapApiTaskFull(res.data)
      };
    }));
  }

  bulkUpdateTasks(taskIds: number[], data: {
    listing_id?: number,
    status?: TaskStatus,
    assignee_id?: number,
    assignee_type?: string,
    due_date?: string
  }): Observable<null> {
    return this.apiService.put<null>("/tasks/bulk-update", true, {
      ...data,
      task_ids: taskIds
    }, null, true);
  }

  bulkCompleteTasks(taskIds: number[]): Observable<null> {
    return this.apiService.put<null>("/tasks/bulk-complete", true, { task_ids: taskIds }, null, true, __HTTPResponseType.TEXT);
  }

  deleteTask(taskId: number): Observable<any> {
    return this.apiService.delete<{}>("/tasks/" + taskId, true, null, null, true, __HTTPResponseType.TEXT);
  }

  deleteTaskImage(attachmentId: number): Observable<any> {
    return this.apiService.delete<{}>("/tasks-attachments/" + attachmentId, true, null, null, true, __HTTPResponseType.TEXT);
  }

  deleteTempTaskImage(tempAttachmentId: number): Observable<any> {
    return this.apiService.delete<{}>("/tasks-attachments-temp/" + tempAttachmentId, true, null, null, true, __HTTPResponseType.TEXT);
  }

  /**
   * Task Attachments
   */
  getPreSignedURL(type: string, title: string): Observable<UploadFile> {
    return this.apiService.post<UploadFileShowResponse>("/tasks-attachments", true, { type: type, title: title })
      .pipe(map(res => res.data));
  }

  uploadedImage(imageId: number): Observable<UploadComplete> {
    return this.apiService.post<UploadCompleteShowReponse>("/tasks-attachments/" + imageId.toString() + "/complete", true, null)
      .pipe(map(res => res.data));
  }

  createComment(taskId: number, data): Observable<Comment> {
    return this.apiService.post<any>("/tasks/" + taskId + "/comments", true, data).pipe(map(res => res.data));
  }

  getComments(taskId: number): Observable<any> {
    return this.apiService.get<{ data: Comment[] }>("/tasks/" + taskId + "/comments", true).pipe(map(res => res.data));
  }

  updateComment(commentId: number, data): Observable<any> {
    return this.apiService.put<{}>("/task-comment/" + commentId, true, data);
  }

  deleteComment(commentId: number): Observable<any> {
    return this.apiService.delete("/task-comment/" + commentId, true, null, null, true, __HTTPResponseType.TEXT);
  }

  getTasksForReport(data): Observable<any> {
    data.include = this.INDEX_INCLUDES;

    let url = "/admin/tasks/generate-report";

    if (data.employee_id) {
      url = "/admin/tasks/employee-generate-report/"+ data.employee_id;
    }
    return this.apiService.get<{ data: TaskCompact[] }>(url, true, data);
  }

  downloadReport(data): Observable<any> {

    let url = "/admin/tasks/download-report";

    if (data.employee_id) {
      url = "/admin/tasks/employee-download-report/"+data.employee_id;
    }

    return this.apiService.post(url, true, data, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        if (!data.send_email) {
          const contentDispositionHeader = res.headers.get("Content-Disposition");
          const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
          const fileName = result.replace(/"/g, "");
          saveAs(res.body, fileName);
        } else {
          this.snackbar.show("Task Report is being generated, will be sent to your email.", 4000);
        }
        return true;
      }));
  }

  downloadInspectionReport(): Observable<boolean> {
    return this.apiService.post("/admin/tasks/download-inspection-report", true, null, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        const contentDispositionHeader = res.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        const fileName = result.replace(/"/g, "");
        saveAs(res.body, fileName);
        return true;
      }));
  }

  /**
   * Helpers
   */
  private mapApiTaskFull(apiTask: TaskFull): TaskFull {
    let frequency = apiTask.repeat_frequency;
    let value = apiTask.repeat_value;

    switch (apiTask.repeat_frequency) {
      case TaskRepeatFrequency.WEEKLY: {
        if (value === 2) {
          frequency = TaskRepeatFrequency.BI_WEEKLY;
          value = 1;
        }
        break;
      }

      case TaskRepeatFrequency.MONTHLY: {
        if (value === 2) {
          frequency = TaskRepeatFrequency.EVERY_TWO_MONTHS;
          value = 1;
        } else if (value === 4) {
          frequency = TaskRepeatFrequency.EVERY_FOUR_MONTHS;
          value = 1;
        } else if (value === 6) {
          frequency = TaskRepeatFrequency.EVERY_SIX_MONTHS;
          value = 1;
        }
        break;
      }

      default: {
        break;
      }
    }

    return {
      ...apiTask,
      repeat_frequency: frequency,
      repeat_value: value
    };
  }
}
