import { Directive, ElementRef, EventEmitter, Output } from "@angular/core";

@Directive({
  selector: "[sdImgSizeOverlay]",
  host: {
    "(load)" : "getSize()"
  }
})
export class ImgSizeOverlayDirective {
  @Output() text: EventEmitter<boolean> = new EventEmitter();

  constructor(private elementRef: ElementRef) { }

  getSize() {
    const height = this.elementRef.nativeElement.naturalHeight;
    const width = this.elementRef.nativeElement.naturalWidth;
    if (width < 1024 || height < 768) {
      this.elementRef.nativeElement.classList.add("overlay");
      this.text.emit(true);
    }
  }
}
