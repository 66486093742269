import {createSelector} from "reselect";
import {
  _getIsGoalSetPercentagesDraftLoaded,
  _getIsGoalSetPercentagesDraftLoading,
  goalSetPercentagesDraftAdapter, goalSetPercentagesDraftReducer,
  GoalSetPercentagesDraftState
} from "./new/draft/goal-set-percentages-drafts";
import {
  _getIsReviewResponseDraftLoaded,
  _getIsReviewResponseDraftLoading,
  reviewResponseDraftAdapter,
  reviewResponseDraftReducer,
  ReviewResponseDraftState
} from "./new/draft/review-response-drafts";
import {
  _getIsOwnerWelcomeLetterLoaded,
  _getIsOwnerWelcomeLetterLoading,
  ownerWelcomeLetterAdapter,
  ownerWelcomeLetterReducer,
  OwnerWelcomeLetterState
} from "./new/owner-welcome-letter";
import {
  _getIsRatingReviewsLoaded,
  _getIsRatingReviewsLoading,
  _getRatingReviewsCurrentPage, _getRatingReviewsListing,
  _getRatingReviewsPerPage,
  _getRatingReviewsSortOrder,
  _getRatingReviewsTotal,
  ratingReviewsAdapter,
  ratingReviewsReducer,
  RatingReviewsState
} from "./new/reviews/rating-reviews";
import {
  _getIsRespondReviewsLoaded,
  _getIsRespondReviewsLoading,
  _getRespondReviewsCurrentPage, _getRespondReviewsListing,
  _getRespondReviewsPerPage, _getRespondReviewsRating,
  _getRespondReviewsSortOrder,
  _getRespondReviewsSortType, _getRespondReviewsTaskData, _getRespondReviewsTotal,
  respondReviewsAdapter,
  respondReviewsReducer,
  RespondReviewsState
} from "./new/reviews/respond-reviews";
/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromUser from "./user";
import * as fromAccount from "./account";
import * as fromPropertyManager from "./property-manager"
import * as fromApp from "./app";
import * as fromDiscount from "./discount";
import * as fromNote from "./new/inbox/note";
import * as fromMultiCalendar from "./multi-calendar";

import {
  _getAllAdmins,
  _getAllChecklistCategories,
  _getAllCountries,
  _getAllLocations,
  _getAllTags,
  _getAllVendors,
  _getIsAdminLoaded,
  _getIsAdminLoading,
  _getIsChecklistCategoryLoaded,
  _getIsChecklistCategoryLoading,
  _getIsCountryLoaded,
  _getIsCountryLoading,
  _getIsLocationLoaded,
  _getIsLocationLoading,
  _getIsStatesLoadedByCode,
  _getIsStatesLoadingByCode,
  _getIsTagLoaded,
  _getIsTagLoading,
  _getIsTaskAssigneesLoaded,
  _getIsTaskAssigneesLoading,
  _getIsVendorsLoaded,
  _getIsVendorsLoading,
  _getStatesByCode,
  _getTaskAssigneesEntities,
  optionReducer,
  OptionState,
} from "./new/shared/options";
import * as fromQuote from "./quote";
import * as fromSetting from "./setting";
import * as fromCustomVariable from "./custom-variable";
import * as fromMinStayTemplate from "./min-stay-templates";
import {
  _getIsFullListingLoaded,
  _getIsFullListingLoading,
  _getIsListingLoaded,
  _getIsListingLoading,
  _getIsReportLoaded,
  _getIsReportLoading,
  _getReportById,
  listingAdapter,
  listingReducer,
  ListingState
} from "./new/listings/listing";
import {ListingCompact} from "../models/new/listing/listing-compact.model";
import {ListingFull} from "../models/new/listing/listing-full.model";
import {ConstantsUtil} from "../utils/constants.util";
import {
  _getAllTaskIdsForPageNumber,
  _getIsFullTaskLoaded,
  _getIsFullTaskLoading,
  _getIsTaskLoadedForPageNumber,
  _getIsTaskLoadingForPageNumber,
  _getSelectedTaskIds, _getSelectedTaskStatus,
  _getTaskCurrentPage, _getTaskEndDate,
  _getTaskExist,
  _getTaskFilterAssigneeIds,
  _getTaskFilterCategory,
  _getTaskFilterCreatedBy, _getTaskFilterEmployeeIds,
  _getTaskFilterListingIds,
  _getTaskFilterPrice, _getTaskFilters,
  _getTaskFilterStatus,
  _getTaskFilterType,
  _getTaskFilterWhoWillPay, _getTaskOffset, _getTaskOpenProperty,
  _getTaskPerPage,
  _getTaskSortBy,
  _getTaskSortOrder, _getTaskStartDate,
  _getTaskTotalCount,
  _getTaskTotalPages,
  taskAdapter,
  taskReducer,
  TaskState,
} from "./new/tasks/task";
import {
  _getAutoTaskExist,
  _getIsAutoTaskLoaded,
  _getIsAutoTaskLoading,
  _getIsFullAutoTaskLoaded,
  _getIsFullAutoTaskLoading,
  autoTaskAdapter,
  autoTaskReducer,
  AutoTaskState
} from "./new/tasks/auto-task";
import {
  _getOnboardingTaskExist,
  _getIsOnboardingTaskLoaded,
  _getIsOnboardingTaskLoading,
  _getIsFullOnboardingTaskLoaded,
  _getIsFullOnboardingTaskLoading,
  onboardingTaskAdapter,
  onboardingTaskReducer,
  OnboardingTaskState
} from "./new/tasks/onboarding-task";
import {
  _getIsAutoResponseLoaded,
  _getIsAutoResponseLoading,
  autoResponseAdapter,
  autoResponseReducer,
  AutoResponseState,
} from "./new/automation/auto-response";
import {
  _getIsCannedResponseLoaded,
  _getIsCannedResponseLoading,
  _getIsFullCannedResponseLoaded,
  _getIsFullCannedResponseLoading,
  cannedResponseAdapter,
  cannedResponseReducer,
  CannedResponseState
} from "./new/automation/canned-response";
import {
  _getIsAutomatedMessageLoaded,
  _getIsAutomatedMessageLoading,
  _getIsFullAutomatedMessageLoaded,
  _getIsFullAutomatedMessageLoading,
  automatedMessageAdapter,
  automatedMessageReducer,
  AutomatedMessageState
} from "./new/automation/automated-message";
import {AutoTaskCompact} from "../models/new/tasks/auto-task-compact.model";
import {AutoTaskFull} from "../models/new/tasks/auto-task-full.model";
import {AutomationRule} from "../enums/common.enum";
import {AutoResponse} from "../models/new/automation/auto-response.model";
import {CannedResponseFull} from "../models/new/automation/canned-response-full.model";
import {AutomatedMessageFull} from "../models/new/automation/automated-messages-full.model";
import {CannedResponseCompact} from "../models/new/automation/canned-response-compact.model";
import {AutomatedMessageCompact} from "../models/new/automation/automated-messages-compact.model";
import {
  _getIsMultiListingLoaded,
  _getIsMultiListingLoading,
  multiListingAdapter,
  multiListingReducer,
  MultiListingState
} from "./new/multi-listing";
import {
  _getAllInventoryTags,
  _getIsFullInventoryLoaded,
  _getIsFullInventoryLoading,
  _getIsInventoryLoaded,
  _getIsInventoryLoading, _getUnTagged,
  inventoryAdapter,
  inventoryReducer,
  InventoryState
} from "./new/inventory";
import {
  _getAllActiveIds,
  _getAllContactIdsForPageNumber,
  _getAllHomeOwners,
  _getAllInActiveIds,
  _getContactCurrentPage,
  _getContactFilterType,
  _getContactPerPage,
  _getContactSortBy,
  _getContactSortOrder,
  _getContactStatus,
  _getContactTotalCount,
  _getContactTotalPage,
  _getIsActiveContactLoaded,
  _getIsActiveContactLoading,
  _getIsContactLoadedForPageNumber,
  _getIsContactLoadingForPageNumber,
  _getIsFullContactLoaded,
  _getIsFullContactLoading,
  _getIsHomeOwnersLoaded,
  _getIsHomeOwnersLoading,
  _getIsInActiveContactLoaded,
  _getIsInActiveContactLoading,
  contactAdapter,
  contactReducer,
  ContactState,
} from "./new/contacts/contact";
import {APP_STATE_RESET} from "../actions";
import {
  _getAdminStats,
  _getCleaningStats,
  _getCommissionStats,
  _getIsAdminStatsLoaded,
  _getIsAdminStatsLoading,
  _getIsCleaningStatsLoaded,
  _getIsCleaningStatsLoading,
  _getIsCommissionStatsLoaded,
  _getIsCommissionStatsLoading,
  _getIsMonthlyBreakdownLoaded,
  _getIsMonthlyBreakdownLoading,
  _getIsNetIncomeStatsLoaded,
  _getIsNetIncomeStatsLoading,
  _getIsOwnerStatsLoaded,
  _getIsOwnerStatsLoading,
  _getMonthlyBreakdown,
  _getNetIncomeStats, _getNetIncomeYear,
  _getOwnerStats,
  _getSelectedListings,
  statsReducer,
  StatsState
} from "./new/stats";
import * as fromListingCalendar from "./new/listings/listing-calendar";
import {_getCalendarData, _getIsCalendarDataLoaded, _getIsCalendarDataLoading} from "./new/listings/listing-calendar";
import {
  _getBadgeCount,
  _getBookingExist,
  _getCurrentPage, _getEnd,
  _getIsBookingsLoaded,
  _getIsBookingsLoading,
  _getIsFullBookingsLoaded,
  _getIsFullBookingsLoading,
  _getIsOwnerUpcomingLoaded,
  _getIsOwnerUpcomingLoading,
  _getListingIds,
  _getOwnerUpcomingAll,
  _getOwnerUpcomingEntities,
  _getPerPageCount,
  _getReservationSortType,
  _getReservationType, _getReviewRating,
  _getSortOrder,
  _getSources, _getStart, _getTags,
  _getTotalCount,
  _getTotalPages,
  _getVendorTasks,
  _getVendorTasksLoaded,
  _getVendorTasksLoading,
  bookingAdapter,
  bookingReducer,
  BookingsState
} from "./new/bookings/bookings";
import {
  _getAllExpiredProspectsForPageNumber,
  _getAllPassedProspectsForPageNumber,
  _getAllRecentlyCreatedProspectsForPageNumber,
  _getAllTodayProspectsForPageNumber,
  _getAllUpcomingProspectsForPageNumber,
  _getExpiredLoadedForPageNumber,
  _getExpiredLoadingForPageNumber,
  _getExpiredProspectAll,
  _getExpiredProspectCurrentPage,
  _getExpiredProspectTotalCount,
  _getExpiredProspectTotalPage,
  _getPassedLoadedForPageNumber,
  _getPassedLoadingForPageNumber,
  _getPassedProspectAll,
  _getPassedProspectCurrentPage,
  _getPassedProspectTotalCount,
  _getPassedProspectTotalPage,
  _getRecentlyCreatedLoadedForPageNumber,
  _getRecentlyCreatedLoadingForPageNumber,
  _getRecentlyCreatedProspectAll,
  _getRecentlyCreatedProspectCurrentPage,
  _getRecentlyCreatedProspectTotalCount,
  _getRecentlyCreatedProspectTotalPage,
  _getTodayLoadedForPageNumber,
  _getTodayLoadingForPageNumber,
  _getTodayProspectAll,
  _getTodayProspectCurrentPage,
  _getTodayProspectTotalCount,
  _getTodayProspectTotalPage,
  _getUpcomingLoadedForPageNumber,
  _getUpcomingLoadingForPageNumber,
  _getUpcomingProspectAll,
  _getUpcomingProspectCurrentPage,
  _getUpcomingProspectTotalCount,
  _getUpcomingProspectTotalPage,
  prospectAdapter,
  prospectReducer,
  ProspectState
} from "./prospect";
import {
  _getAllThreadIdsForPageNumber,
  _getIsFullThreadLoaded,
  _getIsFullThreadLoading,
  _getIsThreadLoadedForPageNumber,
  _getIsThreadLoadingForPageNumber,
  _getSelectedBookingId,
  _getSelectedThreadId,
  _getThreadAssigneeIds,
  _getThreadCurrentPage,
  _getThreadFilters,
  _getThreadListingIds,
  _getThreadOffset,
  _getThreadOffsetDate,
  _getThreadTotalCount,
  _getThreadTotalPages,
  threadAdapter,
  threadReducer,
  ThreadState
} from "./new/inbox/thread";
import {
  _getMessageIdsForThreadId,
  _getMessagesLoadedForThreadId,
  _getMessagesLoadingForThreadId,
  threadMessageReducer,
  ThreadMessageState
} from "./new/inbox/thread-message";
import {_getMessageEntities, messageAdapter, messageReducer, MessageState} from "./new/inbox/message";
import {
  _getIsFullSpaceDraftLoaded,
  _getIsFullSpaceDraftLoading,
  _getIsSpaceDraftLoaded,
  _getIsSpaceDraftLoading,
  spaceDraftAdapter,
  spaceDraftReducer,
  SpaceDraftState
} from "./new/draft/space-drafts";
import {
  _getIsFullSummaryDraftLoaded,
  _getIsFullSummaryDraftLoading,
  _getIsSummaryDraftLoaded,
  _getIsSummaryDraftLoading,
  summaryDraftAdapter,
  summaryDraftReducer,
  SummaryDraftState
} from "./new/draft/summary-drafts";
import {
  _getIsFullInteractionsDraftLoaded,
  _getIsFullInteractionsDraftLoading,
  _getIsInteractionsDraftLoaded,
  _getIsInteractionsDraftLoading,
  interactionsDraftAdapter,
  interactionsDraftReducer,
  InteractionsDraftState
} from "./new/draft/interactions-drafts";
import {
  _getIsFullOtherNotesDraftLoaded,
  _getIsFullOtherNotesDraftLoading,
  _getIsOtherNotesDraftLoaded,
  _getIsOtherNotesDraftLoading,
  otherNotesDraftAdapter,
  otherNotesDraftReducer,
  OtherNotesDraftState
} from "./new/draft/other-notes-drafts";
import {
  _getIsFullGettingAroundDraftLoaded,
  _getIsFullGettingAroundDraftLoading,
  _getIsGettingAroundDraftLoaded,
  _getIsGettingAroundDraftLoading,
  gettingAroundDraftAdapter,
  gettingAroundDraftReducer,
  GettingAroundDraftState
} from "./new/draft/getting-around-drafts";
import {
  _getIsFullGuestAccessDraftLoaded,
  _getIsFullGuestAccessDraftLoading,
  _getIsGuestAccessDraftLoaded,
  _getIsGuestAccessDraftLoading,
  guestAccessDraftAdapter,
  guestAccessDraftReducer,
  GuestAccessDraftState
} from "./new/draft/guest-access-drafts";
import {
  _getIsFullSavedNeighbourhoodDraftLoaded,
  _getIsFullSavedNeighbourhoodDraftLoading,
  _getIsSavedNeighbourhoodDraftLoaded,
  _getIsSavedNeighbourhoodDraftLoading,
  savedNeighbourhoodDraftAdapter,
  savedNeighbourhoodDraftReducer,
  SavedNeighbourhoodDraftState
} from "./new/draft/saved-neighbourhood-drafts";
import {_getCommentEntities, commentAdapter, commentReducer, CommentState} from "./new/tasks/comment";
import {
  _getCommentIdsForTaskId,
  _getCommentsLoadedForTaskId,
  _getCommentsLoadingForTaskId,
  taskCommentReducer,
  TaskCommentState
} from "./new/tasks/task-comment";
import {
  _getIsMultiCalendarRulesLoaded,
  _getIsMultiCalendarRulesLoading,
  multiCalendarRuleAdapter,
  multiCalendarRuleReducer,
  MultiCalendarRuleState
} from "./new/multi-calendar-rule";
import {
  _getIsRentalAgreementLoaded,
  _getIsRentalAgreementLoading,
  rentalAgreementAdapter,
  rentalAgreementReducer,
  RentalAgreementState
} from "./new/rental-agreement";
import {
  _getIsFullOwnerStoryDraftLoaded,
  _getIsFullOwnerStoryDraftLoading,
  _getIsOwnerStoryDraftLoaded,
  _getIsOwnerStoryDraftLoading,
  ownerStoryAdapter,
  OwnerStoryDraftState,
  ownerStoryReducer
} from "./new/draft/owner-story-drafts";
import {
  _getIsRulesLoadedForListingId,
  _getIsRulesLoadingForListingId,
  _getRuleEntities,
  _getRuleIdsForListingId,
  routingRuleReducer,
  routingRulesAdapter,
  RoutingRulesState
} from "./new/listings/routing-rules";
import {_getBlockById, _getBookingById, calendarDataReducer, CalendarDataState} from "./new/calendar-data";
import {
  _getIsFullPrivateNotesDraftLoaded,
  _getIsFullPrivateNotesDraftLoading,
  _getIsPrivateNotesDraftLoaded,
  _getIsPrivateNotesDraftLoading,
  privateNotesDraftAdapter,
  privateNotesDraftReducer,
  PrivateNotesDraftState
} from "./new/draft/private-notes-draft";
import {
  _getIsConciergeDraftLoaded,
  _getIsConciergeDraftLoading,
  _getIsFullConciergeDraftLoaded,
  _getIsFullConciergeDraftLoading,
  conciergeDraftAdapter,
  conciergeDraftReducer,
  ConciergeDraftState
} from "./new/draft/concierge-draft";
import {
  _getAutoReviewById,
  _getIsAutoReviewsLoaded,
  _getIsAutoReviewsLoading,
  autoReviewAdapter,
  autoReviewReducer,
  AutoReviewState
} from "./new/auto-review";
import {
  _isAffiliatesLoaded,
  _isAffiliatesLoading,
  affiliateAdapter,
  affiliateReducer,
  AffiliateState
} from "./new/affiliate";
import {_isCharitiesLoaded, _isCharitiesLoading, charityAdapter, charityReducer, CharityState} from "./new/charity";


/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  user: fromUser.State;
  account: fromAccount.State;
  manager: fromPropertyManager.State;
  app: fromApp.State;
  discount: fromDiscount.State;
  // message: fromMessage.State;
  note: fromNote.State;
  multiCalendar: fromMultiCalendar.State;
  prospect: ProspectState;
  quote: fromQuote.State;
  setting: fromSetting.State;
  customVariable: fromCustomVariable.State;
  minStayTemplate: fromMinStayTemplate.State;

  listing: ListingState;
  newOption: OptionState;
  newTask: TaskState;
  autoTask: AutoTaskState;
  onboardingTask: OnboardingTaskState;
  autoResponse: AutoResponseState;
  cannedResponse: CannedResponseState;
  spaceDraft: SpaceDraftState,
  summaryDraft: SummaryDraftState,
  interactionsDraft: InteractionsDraftState,
  conciergeDraft: ConciergeDraftState,
  otherNotesDraft: OtherNotesDraftState,
  privateNotesDraft: PrivateNotesDraftState,
  gettingAroundDraft: GettingAroundDraftState,
  reviewResponseDraft: ReviewResponseDraftState;
  respondReviews: RespondReviewsState;
  ratingReviews: RatingReviewsState;
  goalSetPercentagesDraft: GoalSetPercentagesDraftState;
  guestAccessDraft: GuestAccessDraftState,
  savedNeighbourhoodDraft: SavedNeighbourhoodDraftState,
  ownerStoryDraft: OwnerStoryDraftState,
  automatedMessage: AutomatedMessageState;
  multiListing: MultiListingState;
  newBooking: BookingsState;
  inventory: InventoryState;
  contact: ContactState;
  stats: StatsState;
  listingCalendar: fromListingCalendar.State;
  thread: ThreadState;
  message: MessageState;
  threadMessage: ThreadMessageState;
  taskComment: TaskCommentState;
  comment: CommentState;
  multiCalendarRules: MultiCalendarRuleState;
  rentalAgreement: RentalAgreementState;
  ownerWelcomeLetter: OwnerWelcomeLetterState;
  routingRules: RoutingRulesState;
  calendarData: CalendarDataState;
  autoReview: AutoReviewState;
  affiliate: AffiliateState;
  charity: CharityState;
}


/**
 * Because metareducers take a reducer function and return a new reducer,
 * we can use our compose helper to chain them together. Here we are
 * using combineReducers to make our top level reducer, and then
 * wrapping that in storeLogger. Remember that compose applies
 * the result from right to left.
 */
export const reducers = {
  user: fromUser.reducer,
  account: fromAccount.reducer,
  manager: fromPropertyManager.reducer,
  app: fromApp.reducer,
  discount: fromDiscount.reducer,
  // message: fromMessage.reducer,
  note: fromNote.reducer,
  multiCalendar: fromMultiCalendar.reducer,
  prospect: prospectReducer,
  quote: fromQuote.reducer,
  setting: fromSetting.reducer,
  customVariable: fromCustomVariable.reducer,
  minStayTemplate: fromMinStayTemplate.reducer,

  listing: listingReducer,
  newOption: optionReducer,
  newTask: taskReducer,
  autoTask: autoTaskReducer,
  onboardingTask: onboardingTaskReducer,
  autoResponse: autoResponseReducer,
  cannedResponse: cannedResponseReducer,
  spaceDraft: spaceDraftReducer,
  summaryDraft: summaryDraftReducer,
  interactionsDraft: interactionsDraftReducer,
  conciergeDraft: conciergeDraftReducer,
  otherNotesDraft: otherNotesDraftReducer,
  privateNotesDraft: privateNotesDraftReducer,
  gettingAroundDraft: gettingAroundDraftReducer,
  reviewResponseDraft: reviewResponseDraftReducer,
  respondReviews: respondReviewsReducer,
  ratingReviews: ratingReviewsReducer,
  goalSetPercentagesDraft: goalSetPercentagesDraftReducer,
  guestAccessDraft: guestAccessDraftReducer,
  savedNeighbourhoodDraft: savedNeighbourhoodDraftReducer,
  ownerStoryDraft: ownerStoryReducer,
  automatedMessage: automatedMessageReducer,
  multiListing: multiListingReducer,
  newBooking: bookingReducer,
  inventory: inventoryReducer,
  contact: contactReducer,
  stats: statsReducer,
  listingCalendar: fromListingCalendar.reducer,
  thread: threadReducer,
  message: messageReducer,
  threadMessage: threadMessageReducer,
  taskComment: taskCommentReducer,
  comment: commentReducer,
  multiCalendarRules: multiCalendarRuleReducer,
  rentalAgreement: rentalAgreementReducer,
  ownerWelcomeLetter: ownerWelcomeLetterReducer,
  routingRules: routingRuleReducer,
  calendarData: calendarDataReducer,
  autoReview: autoReviewReducer,
  affiliate: affiliateReducer,
  charity: charityReducer
};


/**
 * A selector function is a map function factory. We pass it parameters and it
 * returns a function that maps from the larger state tree into a smaller
 * piece of state. This selector simply selects the `books` state.
 *
 * Selectors are used with the `select` operator.
 *
 * ```ts
 * class MyComponent  {
 * 	constructor(state$: Observable<State>) {
 * 	  this.booksState$ = state$.select(getBooksState);
 * 	}
 * }
 * ```
 */

export function clearState(reducer) {
  return function (state, action) {

    if (action.type === APP_STATE_RESET) {
      state = undefined;
    }

    return reducer(state, action);
  };
}


export const getUserState = (state: State) => state.user;
export const getAccountState = (state: State) => state.account;
export const getPropertyManagerState = (state: State) => state.manager;
export const getAppState = (state: State) => state.app;
export const getDiscountState = (state: State) => state.discount;
export const getContactsState = (state: State) => state.contact;
export const getMessagesState = (state: State) => state.message;
export const getNotesState = (state: State) => state.note;
export const getMultiCalendarState = (state: State) => state.multiCalendar;
export const getProspectState = (state: State) => state.prospect;
export const getQuoteState = (state: State) => state.quote;
export const getSettingState = (state: State) => state.setting;
export const getCustomVariableState = (state: State) => state.customVariable;
export const getMinStayTemplateState = (state: State) => state.minStayTemplate;
export const getRentalAgreementState = (state: State) => state.rentalAgreement;
export const getOwnerWelcomeLetterState = (state: State) => state.ownerWelcomeLetter;
export const getCalendarDataState = (state: State) => state.calendarData;
export const getCharityState = (state: State) => state.charity;
export const getAffiliateState = (state: State) => state.affiliate;


/**
 * All the selectors from app state
 */
export const getAppLandingUrl = createSelector(getAppState, fromApp.getLandingUrl);
export const getAppIsBootstrapped = createSelector(getAppState, fromApp.getIsBootstrapped);
export const getAppIsMenuHidden = createSelector(getAppState, fromApp.getIsMenuHidden);

/**
 * All the selectors from user state
 */
export const getUser = createSelector(getUserState, fromUser.getUser);
export const isLoggingIn = createSelector(getUserState, fromUser.isLoggingIn);
export const isLoggedIn = createSelector(getUserState, fromUser.isLoggedIn);
export const isUserUpdating = createSelector(getUserState, fromUser.isUpdating);


/**
 * All the selectors from listing state
 */
/**
 * All the selectors from min stay template state
 */
export const getMinStayTemplates = createSelector(getMinStayTemplateState, fromMinStayTemplate.getMinStaysTemplates);
export const getIsMinStayTemplatesLoading = createSelector(getMinStayTemplateState, fromMinStayTemplate.isMinStaysTemplatesLoading);
export const getIsMinStayTemplatesLoaded = createSelector(getMinStayTemplateState, fromMinStayTemplate.isMinStaysTemplatesLoaded);

/**
 * All the selectors from rental Agreement state
 */

export const {
  selectIds: getRentalAgreementIds,
  selectEntities: getRentalAgreementEntities,
  selectAll: getAllRentalAgreements,
  selectTotal: getTotalRentalAgreement, // returns total count of listing
} = rentalAgreementAdapter.getSelectors(getRentalAgreementState);


export const getRentalAgreements = getAllRentalAgreements;
export const getIsRentalAgreementLoading = createSelector(getRentalAgreementState, _getIsRentalAgreementLoading);
export const getIsRentalAgreementLoaded = createSelector(getRentalAgreementState, _getIsRentalAgreementLoaded);

/**
 * All the selectors from owner welcome letter state
 */

export const {
  selectIds: getOwnerWelcomeLetterIds,
  selectEntities: getOwnerWelcomeLetterEntities,
  selectAll: getAllOwnerWelcomeLetters,
  selectTotal: getTotalOwnerWelcomeLetter, // returns total count
} = ownerWelcomeLetterAdapter.getSelectors(getOwnerWelcomeLetterState);


export const getOwnerWelcomeLetters = getAllOwnerWelcomeLetters;
export const getIsOwnerWelcomeLetterLoading = createSelector(getOwnerWelcomeLetterState, _getIsOwnerWelcomeLetterLoading);
export const getIsOwnerWelcomeLetterLoaded = createSelector(getOwnerWelcomeLetterState, _getIsOwnerWelcomeLetterLoaded);


/**
 * All the selectors from booking state
 */


/**
 * All the selectors from multicalendar state
 */
export const getIsMultiCalendarLoading = (state: State, month: number, year: number) => {
  return fromMultiCalendar.getIsMonthCalendarLoading(getMultiCalendarState(state), month, year);
};
export const getIsMultiCalendarLoaded = (state: State, month: number, year: number) => {
  return fromMultiCalendar.getIsMonthCalendarLoaded(getMultiCalendarState(state), month, year);
};
export const getMultiCalendarByMonth = (state: State, month: number, year: number) => {
  return fromMultiCalendar.getByMonth(getMultiCalendarState(state), month, year);
};

export const getCustomVariables = createSelector(getCustomVariableState, fromCustomVariable.getAll);
export const getIsCustomVariablesLoading = createSelector(getCustomVariableState, fromCustomVariable.getIsLoading);
export const getIsCustomVariablesLoaded = createSelector(getCustomVariableState, fromCustomVariable.getIsLoaded);
/**
 * All the selectors from prospect state
 */

export const {
  selectIds: getProspectIds,
  selectEntities: getProspectEntities,
  selectAll: getAllProspects,
  selectTotal: getTotalProspects, // returns total count of listing
} = prospectAdapter.getSelectors(getProspectState);


export const getTodayProspectCurrentPage = createSelector(getProspectState, _getTodayProspectCurrentPage);
export const getTodayProspectTotalPage = createSelector(getProspectState, _getTodayProspectTotalPage);
export const getTodayProspectTotalCount = createSelector(getProspectState, _getTodayProspectTotalCount);

export const getAllTodayProspects = createSelector(getProspectState, _getTodayProspectAll);
export const getIsTodayProspectLoadingForPageNumber = (state: State, pageNumber: number) => {
  return _getTodayLoadingForPageNumber(getProspectState(state), pageNumber);
};
export const getIsTodayProspectLoadedForPageNumber = (state: State, pageNumber: number) => {
  return _getTodayLoadedForPageNumber(getProspectState(state), pageNumber);
};

export const getAllTodayProspectIdsForPageNumber = (state: State, pageNumber: number) => {
  return _getAllTodayProspectsForPageNumber(getProspectState(state), pageNumber);
};

export const getAllTodayProspectsForCurrentPage = createSelector(
  getProspectState,
  getTodayProspectCurrentPage,
  getProspectEntities,
  (state, currentPage, entities) => {
    const ids = _getAllTodayProspectsForPageNumber(state, currentPage);
    return ids.map(id => entities[id]);
  }
);

export const getIsCurrentTodayPageLoading = createSelector(
  getProspectState,
  getTodayProspectCurrentPage,
  (state, currentPage) => {
    return _getTodayLoadingForPageNumber(state, currentPage);
  }
);

export const getIsCurrentTodayPageLoaded = createSelector(
  getProspectState,
  getTodayProspectCurrentPage,
  (state, currentPage) => {
    return _getTodayLoadedForPageNumber(state, currentPage);
  }
);

export const getUpcomingProspectCurrentPage = createSelector(getProspectState, _getUpcomingProspectCurrentPage);
export const getUpcomingProspectTotalPage = createSelector(getProspectState, _getUpcomingProspectTotalPage);
export const getUpcomingProspectTotalCount = createSelector(getProspectState, _getUpcomingProspectTotalCount);

export const getAllUpcomingProspects = createSelector(getProspectState, _getUpcomingProspectAll);
export const getIsUpcomingProspectLoadingForPageNumber = (state: State, pageNumber: number) => {
  return _getUpcomingLoadingForPageNumber(getProspectState(state), pageNumber);
};
export const getIsUpcomingProspectLoadedForPageNumber = (state: State, pageNumber: number) => {
  return _getUpcomingLoadedForPageNumber(getProspectState(state), pageNumber);
};

export const getAllUpcomingProspectIdsForPageNumber = (state: State, pageNumber: number) => {
  return _getAllUpcomingProspectsForPageNumber(getProspectState(state), pageNumber);
};

export const getAllUpcomingProspectsForCurrentPage = createSelector(
  getProspectState,
  getUpcomingProspectCurrentPage,
  getProspectEntities,
  (state, currentPage, entities) => {
    const ids = _getAllUpcomingProspectsForPageNumber(state, currentPage);
    return ids.map(id => entities[id]);
  }
);

export const getIsCurrentUpcomingPageLoading = createSelector(
  getProspectState,
  getUpcomingProspectCurrentPage,
  (state, currentPage) => {
    return _getUpcomingLoadingForPageNumber(state, currentPage);
  }
);

export const getIsCurrentUpcomingPageLoaded = createSelector(
  getProspectState,
  getUpcomingProspectCurrentPage,
  (state, currentPage) => {
    return _getUpcomingLoadedForPageNumber(state, currentPage);
  }
);

export const getExpiredProspectCurrentPage = createSelector(getProspectState, _getExpiredProspectCurrentPage);
export const getExpiredProspectTotalPage = createSelector(getProspectState, _getExpiredProspectTotalPage);
export const getExpiredProspectTotalCount = createSelector(getProspectState, _getExpiredProspectTotalCount);

export const getAllExpiredProspects = createSelector(getProspectState, _getExpiredProspectAll);
export const getIsExpiredProspectLoadingForPageNumber = (state: State, pageNumber: number) => {
  return _getExpiredLoadingForPageNumber(getProspectState(state), pageNumber);
};
export const getIsExpiredProspectLoadedForPageNumber = (state: State, pageNumber: number) => {
  return _getExpiredLoadedForPageNumber(getProspectState(state), pageNumber);
};

export const getAllExpiredProspectIdsForPageNumber = (state: State, pageNumber: number) => {
  return _getAllExpiredProspectsForPageNumber(getProspectState(state), pageNumber);
};

export const getAllExpiredProspectsForCurrentPage = createSelector(
  getProspectState,
  getExpiredProspectCurrentPage,
  getProspectEntities,
  (state, currentPage, entities) => {
    const ids = _getAllExpiredProspectsForPageNumber(state, currentPage);
    console.log("ids", ids);
    console.log("entities", ids.map(id => entities[id]));
    return ids.map(id => entities[id]);
  }
);

export const getIsCurrentExpiredPageLoading = createSelector(
  getProspectState,
  getExpiredProspectCurrentPage,
  (state, currentPage) => {
    return _getExpiredLoadingForPageNumber(state, currentPage);
  }
);

export const getIsCurrentExpiredPageLoaded = createSelector(
  getProspectState,
  getExpiredProspectCurrentPage,
  (state, currentPage) => {
    return _getExpiredLoadedForPageNumber(state, currentPage);
  }
);

export const getPassedProspectCurrentPage = createSelector(getProspectState, _getPassedProspectCurrentPage);
export const getPassedProspectTotalPage = createSelector(getProspectState, _getPassedProspectTotalPage);
export const getPassedProspectTotalCount = createSelector(getProspectState, _getPassedProspectTotalCount);

export const getAllPassedProspects = createSelector(getProspectState, _getPassedProspectAll);
export const getIsPassedProspectLoadingForPageNumber = (state: State, pageNumber: number) => {
  return _getPassedLoadingForPageNumber(getProspectState(state), pageNumber);
};
export const getIsPassedProspectLoadedForPageNumber = (state: State, pageNumber: number) => {
  return _getPassedLoadedForPageNumber(getProspectState(state), pageNumber);
};

export const getAllPassedProspectIdsForPageNumber = (state: State, pageNumber: number) => {
  return _getAllPassedProspectsForPageNumber(getProspectState(state), pageNumber);
};

export const getAllPassedProspectsForCurrentPage = createSelector(
  getProspectState,
  getPassedProspectCurrentPage,
  getProspectEntities,
  (state, currentPage, entities) => {
    const ids = _getAllPassedProspectsForPageNumber(state, currentPage);
    return ids.map(id => entities[id]);
  }
);

export const getIsCurrentPassedPageLoading = createSelector(
  getProspectState,
  getPassedProspectCurrentPage,
  (state, currentPage) => {
    return _getPassedLoadingForPageNumber(state, currentPage);
  }
);

export const getIsCurrentPassedPageLoaded = createSelector(
  getProspectState,
  getPassedProspectCurrentPage,
  (state, currentPage) => {
    return _getPassedLoadedForPageNumber(state, currentPage);
  }
);


export const getRecentlyCreatedProspectCurrentPage = createSelector(getProspectState, _getRecentlyCreatedProspectCurrentPage);
export const getRecentlyCreatedProspectTotalPage = createSelector(getProspectState, _getRecentlyCreatedProspectTotalPage);
export const getRecentlyCreatedProspectTotalCount = createSelector(getProspectState, _getRecentlyCreatedProspectTotalCount);

export const getAllRecentlyCreatedProspects = createSelector(getProspectState, _getRecentlyCreatedProspectAll);
export const getIsRecentlyCreatedProspectLoadingForPageNumber = (state: State, pageNumber: number) => {
  return _getRecentlyCreatedLoadingForPageNumber(getProspectState(state), pageNumber);
};
export const getIsRecentlyCreatedProspectLoadedForPageNumber = (state: State, pageNumber: number) => {
  return _getRecentlyCreatedLoadedForPageNumber(getProspectState(state), pageNumber);
};

export const getAllRecentlyCreatedProspectIdsForPageNumber = (state: State, pageNumber: number) => {
  return _getAllRecentlyCreatedProspectsForPageNumber(getProspectState(state), pageNumber);
};

export const getAllRecentlyCreatedProspectsForCurrentPage = createSelector(
  getProspectState,
  getRecentlyCreatedProspectCurrentPage,
  getProspectEntities,
  (state, currentPage, entities) => {
    const ids = _getAllRecentlyCreatedProspectsForPageNumber(state, currentPage);
    return ids.map(id => entities[id]);
  }
);

export const getIsCurrentRecentlyCreatedPageLoading = createSelector(
  getProspectState,
  getRecentlyCreatedProspectCurrentPage,
  (state, currentPage) => {
    return _getRecentlyCreatedLoadingForPageNumber(state, currentPage);
  }
);

export const getIsCurrentRecentlyCreatedPageLoaded = createSelector(
  getProspectState,
  getRecentlyCreatedProspectCurrentPage,
  (state, currentPage) => {
    return _getRecentlyCreatedLoadedForPageNumber(state, currentPage);
  }
);

/**
 * All the selectors from notes state
 */

export const getNotes = createSelector(getNotesState, fromNote.getAll);
export const getIsNotesLoading = createSelector(getNotesState, fromNote.getIsLoading);
export const getIsNotesLoaded = createSelector(getNotesState, fromNote.getIsLoaded);


/**
 * All the selectors from quote state
 */

export const getQuotes = createSelector(getQuoteState, fromQuote.getAll);
export const getQuoteEntities = createSelector(getQuoteState, fromQuote.getEntities);

export const getIsQuotesLoading = createSelector(getQuoteState, fromQuote.getIsLoading);
export const getIsQuotesLoaded = createSelector(getQuoteState, fromQuote.getIsLoaded);

/**
 * All the selectors from accounts state
 */

export const getAirbnbAccounts = createSelector(getAccountState, fromAccount.getAll);
export const getIsAccountsLoading = createSelector(getAccountState, fromAccount.getIsLoading);
export const getIsAccountsLoaded = createSelector(getAccountState, fromAccount.getIsLoaded);

export const getAirbnbAccountById = (state: State, id: number) => {
  const toReturn = fromAccount._getAccountById(getAccountState(state), id);
  return toReturn;
};

/**
 * All the selectors from property managers state
 */

export const getPropertyManagers = createSelector(getPropertyManagerState, fromPropertyManager.getAll);
export const getIsManagersLoading = createSelector(getPropertyManagerState, fromPropertyManager.getIsLoading);
export const getIsManagersLoaded = createSelector(getPropertyManagerState, fromPropertyManager.getIsLoaded);

/**
 * All the selectors from discount state
 */

export const getDiscounts = createSelector(getDiscountState, fromDiscount.getAll);
export const getDiscountEntities = createSelector(getDiscountState, fromDiscount.getEntities);
export const getIsDiscountsLoading = createSelector(getDiscountState, fromDiscount.getIsLoading);
export const getIsDiscountsLoaded = createSelector(getDiscountState, fromDiscount.getIsLoaded);
export const getDiscountById = (state: State, discountId: number) => {
  return fromDiscount.getById(getDiscountState(state), discountId);
};

/**
 * All the selectors from Setting state
 */

export const getSettings = createSelector(getSettingState, fromSetting.getAll);
export const getSettingEntities = createSelector(getSettingState, fromSetting.getEntities);

export const getIsSettingsLoading = createSelector(getSettingState, fromSetting.getIsLoading);
export const getIsSettingsLoaded = createSelector(getSettingState, fromSetting.getIsLoaded);

/**
 * New Listing Reducer
 */
export const getListingState = (state: State) => state.listing;

export const {
  selectIds: getListingIds,
  selectEntities: getListingEntities,
  selectAll: getAllListing,
  selectTotal: getTotalListing, // returns total count of listing
} = listingAdapter.getSelectors(getListingState);

export const getAllListings = getAllListing;


export const getFilteredListings = (state: State, status?: string) => {
  return getAllListings(state).filter((listing: ListingCompact | ListingFull) => {

    if (status === "deactivated") {
      return listing.is_deactivated;
    }

    if (!status || status === ConstantsUtil.LISTING_FILTER_SHOW_ALL) {
      // Return all listings if no filter or show_all filter is passed
      return !listing.is_deactivated;
    }
    return listing.status === status && !listing.is_deactivated;
  });
};

export const getIsFullListingLoading = (state: State, listingId: number) => {
  return _getIsFullListingLoading(getListingState(state), listingId);
};

export const getIsFullListingLoaded = (state: State, listingId: number) => {
  return _getIsFullListingLoaded(getListingState(state), listingId);
};

export const getFullListingById = (state: State, listingId: number) => {
  return getListingEntities(state)[listingId];
};

export const getIsReportLoading = (state: State, listingId: number) => {
  return _getIsReportLoading(getListingState(state), listingId);
};

export const getIsReportLoaded = (state: State, listingId: number) => {
  return _getIsReportLoaded(getListingState(state), listingId);
};

export const getReportById = (state: State, listingId: number) => {
  return _getReportById(getListingState(state), listingId);
};

export const getIsListingLoading = (state: State) => {
  return _getIsListingLoading(getListingState(state));
};

export const getIsListingLoaded = (state: State) => {
  return _getIsListingLoaded(getListingState(state));
};

/**
 * New Option Reducer
 */
export const getNewOptionState = (state: State) => state.newOption;

export const getAllLocations = createSelector(getNewOptionState, _getAllLocations);
export const getIsLocationLoading = createSelector(getNewOptionState, _getIsLocationLoading);
export const getIsLocationLoaded = createSelector(getNewOptionState, _getIsLocationLoaded);

export const getAllTags = createSelector(getNewOptionState, _getAllTags);
export const getIsTagLoading = createSelector(getNewOptionState, _getIsTagLoading);
export const getIsTagLoaded = createSelector(getNewOptionState, _getIsTagLoaded);

export const getIsCountryLoading = createSelector(getNewOptionState, _getIsCountryLoading);
export const getIsCountryLoaded = createSelector(getNewOptionState, _getIsCountryLoaded);
export const getAllCountries = createSelector(getNewOptionState, _getAllCountries);

export const getIsStatesLoadingByCountryCode = (state: State, countryCode: string) => {
  return _getIsStatesLoadingByCode(getNewOptionState(state), countryCode);
};
export const getIsStatesLoadedByCountryCode = (state: State, countryCode: string) => {
  return _getIsStatesLoadedByCode(getNewOptionState(state), countryCode);
};
export const getStatesByCountryCode = (state: State, countryCode: string) => {
  return _getStatesByCode(getNewOptionState(state), countryCode);
};

export const getIsAdminsLoading = createSelector(getNewOptionState, _getIsAdminLoading);
export const getIsAdminsLoaded = createSelector(getNewOptionState, _getIsAdminLoaded);
export const getAdmins = createSelector(getNewOptionState, _getAllAdmins);

export const getIsChecklistCategoryLoading = createSelector(getNewOptionState, _getIsChecklistCategoryLoading);
export const getIsChecklistCategoryLoaded = createSelector(getNewOptionState, _getIsChecklistCategoryLoaded);
export const getAllChecklistCategories = createSelector(getNewOptionState, _getAllChecklistCategories);

export const getAssigness = createSelector(getNewOptionState, _getTaskAssigneesEntities);
export const getIsAssigneesLoading = createSelector(getNewOptionState, _getIsTaskAssigneesLoading);
export const getIsAssgineesLoaded = createSelector(getNewOptionState, _getIsTaskAssigneesLoaded);

export const getIsVendorsLoading = createSelector(getNewOptionState, _getIsVendorsLoading);
export const getIsVendorsLoaded = createSelector(getNewOptionState, _getIsVendorsLoaded);
export const getAllVendors = createSelector(getNewOptionState, _getAllVendors);


/**
 * New Task Reducer
 */
export const getTaskState = (state: State) => state.newTask;

export const {
  selectIds: getTaskIds,
  selectEntities: getTaskEntities,
  selectAll: getAllTask,
  selectTotal: getTotalTask, // returns total count of tasks
} = taskAdapter.getSelectors(getTaskState);

export const getAllTasks = getAllTask;

export const getIsTaskLoadingForPageNumber = (state: State, pageNumber: number) => {
  return _getIsTaskLoadingForPageNumber(getTaskState(state), pageNumber);
};
export const getIsTaskLoadedForPageNumber = (state: State, pageNumber: number) => {
  return _getIsTaskLoadedForPageNumber(getTaskState(state), pageNumber);
};

export const getAllTaskIdsForPageNumber = (state: State, pageNumber: number) => {
  return _getAllTaskIdsForPageNumber(getTaskState(state), pageNumber);
};

export const getIsFullTaskLoading = (state: State, listingId: number) => {
  return _getIsFullTaskLoading(getTaskState(state), listingId);
};

export const getIsFullTaskLoaded = (state: State, listingId: number) => {
  return _getIsFullTaskLoaded(getTaskState(state), listingId);
};

export const getFullTaskById = (state: State, listingId: number) => {
  return getTaskEntities(state)[listingId];
};

export const getTaskExist = (state: State, taskId: number) => {
  return _getTaskExist(taskId, getTaskState(state));
};
export const getTaskStartDate = createSelector(getTaskState, _getTaskStartDate);
export const getTaskEndDate = createSelector(getTaskState, _getTaskEndDate);
export const getTaskOffset = createSelector(getTaskState, _getTaskOffset);
export const getTaskFilterType = createSelector(getTaskState, _getTaskFilterType);
export const getTaskFilterCategory = createSelector(getTaskState, _getTaskFilterCategory);
export const getTaskFilterStatus = createSelector(getTaskState, _getTaskFilterStatus);
export const getTaskFilterAssigneeIds = createSelector(getTaskState, _getTaskFilterAssigneeIds);
export const getTaskFilterEmployeeIds = createSelector(getTaskState, _getTaskFilterEmployeeIds);
export const getTaskFilterListingIds = createSelector(getTaskState, _getTaskFilterListingIds);
export const getTaskFilterCreatedBy = createSelector(getTaskState, _getTaskFilterCreatedBy);
export const getTaskFIlterWhoWillPay = createSelector(getTaskState, _getTaskFilterWhoWillPay);
export const getTaskFilterPrice = createSelector(getTaskState, _getTaskFilterPrice);
export const getTaskOpenProperty = createSelector(getTaskState, _getTaskOpenProperty);

export const getTaskPerPage = createSelector(getTaskState, _getTaskPerPage);

export const getTaskCurrentPage = createSelector(getTaskState, _getTaskCurrentPage);
export const getTaskTotalPages = createSelector(getTaskState, _getTaskTotalPages);
export const getTaskTotalCount = createSelector(getTaskState, _getTaskTotalCount);

export const getTaskSortBy = createSelector(getTaskState, _getTaskSortBy);
export const getTaskSortOrder = createSelector(getTaskState, _getTaskSortOrder);

export const getSelectedTaskIds = createSelector(getTaskState, _getSelectedTaskIds);
export const getSelectedTaskStatus = createSelector(getTaskState, _getSelectedTaskStatus);

export const getAllTasksForCurrentPage = createSelector(
  getTaskState,
  getTaskCurrentPage,
  getTaskEntities,
  (state, currentPage, entities) => {
    const taskIds = _getAllTaskIdsForPageNumber(state, currentPage);
    return taskIds.map(id => entities[id]);
  }
);

export const getIsCurrentPageLoading = createSelector(
  getTaskState,
  getTaskCurrentPage,
  (state, currentPage) => {
    return _getIsTaskLoadingForPageNumber(state, currentPage);
  }
);

export const getIsCurrentPageLoaded = createSelector(
  getTaskState,
  getTaskCurrentPage,
  (state, currentPage) => {
    return _getIsTaskLoadedForPageNumber(state, currentPage);
  }
);

export const getTaskFilters = createSelector(getTaskState, _getTaskFilters);

/**
 * New Auto-Task Reducer
 */
export const getAutoTaskState = (state: State) => state.autoTask;

export const {
  selectIds: getAutoTaskIds,
  selectEntities: getAutoTaskEntities,
  selectAll: getAllAutoTask,
  selectTotal: getTotalAutoTask, // returns total count of auto tasks
} = autoTaskAdapter.getSelectors(getAutoTaskState);

export const getAllAutoTasks = getAllAutoTask;

export const getAutoTasksForListingId = (state: State, listingId: number) => {
  return getAllAutoTask(state).filter((autoTask: AutoTaskCompact | AutoTaskFull) => {

    if (autoTask.listing_rule === AutomationRule.INCLUDE) {
      return autoTask.property_ids.indexOf(listingId) !== -1;
    } else {
      return autoTask.property_ids.indexOf(listingId) === -1;
    }
  });
};

export const getIsAutoTaskLoading = createSelector(getAutoTaskState, _getIsAutoTaskLoading);
export const getIsAutoTaskLoaded = createSelector(getAutoTaskState, _getIsAutoTaskLoaded);

export const getIsFullAutoTaskLoading = (state: State, listingId: number) => {
  return _getIsFullAutoTaskLoading(getAutoTaskState(state), listingId);
};

export const getIsFullAutoTaskLoaded = (state: State, listingId: number) => {
  return _getIsFullAutoTaskLoaded(getAutoTaskState(state), listingId);
};

export const getFullAutoTaskById = (state: State, listingId: number) => {
  return getAutoTaskEntities(state)[listingId];
};

export const getAutoTaskExist = (state: State, taskId: number) => {
  return _getAutoTaskExist(taskId, getAutoTaskState(state));
};

/**
 * New Onboarding-Task Reducer
 */
export const getOnboardingTaskState = (state: State) => state.onboardingTask;

export const {
  selectIds: getOnboardingTaskIds,
  selectEntities: getOnboardingTaskEntities,
  selectAll: getAllOnboardingTask,
  selectTotal: getTotalOnboardingTask, // returns total count of onboarding tasks
} = onboardingTaskAdapter.getSelectors(getOnboardingTaskState);

export const getAllOnboardingTasks = getAllOnboardingTask;

export const getIsOnboardingTaskLoading = createSelector(getOnboardingTaskState, _getIsOnboardingTaskLoading);
export const getIsOnboardingTaskLoaded = createSelector(getOnboardingTaskState, _getIsOnboardingTaskLoaded);

export const getIsFullOnboardingTaskLoading = (state: State, taskId: number) => {
  return _getIsFullOnboardingTaskLoading(getOnboardingTaskState(state), taskId);
};

export const getIsFullOnboardingTaskLoaded = (state: State, taskId: number) => {
  return _getIsFullOnboardingTaskLoaded(getOnboardingTaskState(state), taskId);
};

export const getFullOnboardingTaskById = (state: State, taskId: number) => {
  return getOnboardingTaskEntities(state)[taskId];
};

export const getOnboardingTaskExist = (state: State, taskId: number) => {
  return _getOnboardingTaskExist(taskId, getOnboardingTaskState(state));
};

/**
 * New Auto-Response Reducer
 */

export const getAutoResponseState = (state: State) => state.autoResponse;

export const {
  selectIds: getAutoResponseIds,
  selectEntities: getAutoResponseEntities,
  selectAll: getAllAutoResponse,
  selectTotal: getTotalAutoResponse, // returns total count of auto response
} = autoResponseAdapter.getSelectors(getAutoResponseState);

export const getAllAutoResponses = getAllAutoResponse;

export const getAutoResponsesForListingId = (state: State, listingId: number) => {
  return getAllAutoResponse(state).filter((autoResponse: AutoResponse) => {

    if (autoResponse.rule === AutomationRule.INCLUDE) {
      return autoResponse.property_ids.indexOf(listingId) !== -1;
    } else {
      return autoResponse.property_ids.indexOf(listingId) === -1;
    }
  });
};

export const getIsAutoResponseLoading = createSelector(getAutoResponseState, _getIsAutoResponseLoading);
export const getIsAutoResponseLoaded = createSelector(getAutoResponseState, _getIsAutoResponseLoaded);

export const getFullAutoResponseById = (state: State, listingId: number) => {
  return getAutoResponseEntities(state)[listingId];
};


/**
 * New Canned-Response Reducer
 */
export const getCannedResponseState = (state: State) => state.cannedResponse;

export const {
  selectIds: getCannedResponseIds,
  selectEntities: getCannedResponseEntities,
  selectAll: getAllCannedResponse,
  selectTotal: getTotalCannedResponse, // returns total count
} = cannedResponseAdapter.getSelectors(getCannedResponseState);

export const getAllCannedResponses = getAllCannedResponse;

export const getCannedResponsesForListingId = (state: State, listingId: number) => {
  return getAllCannedResponse(state).filter((cannedResponse: CannedResponseCompact | CannedResponseFull) => {

    if (cannedResponse.rule === AutomationRule.INCLUDE) {
      return cannedResponse.property_ids.indexOf(listingId) !== -1;
    } else {
      return cannedResponse.property_ids.indexOf(listingId) === -1;
    }
  });
};


export const getIsCannedResponseLoading = createSelector(getCannedResponseState, _getIsCannedResponseLoading);
export const getIsCannedResponseLoaded = createSelector(getCannedResponseState, _getIsCannedResponseLoaded);

export const getIsFullCannedResponseLoading = (state: State, listingId: number) => {
  return _getIsFullCannedResponseLoading(getCannedResponseState(state), listingId);
};

export const getIsFullCannedResponseLoaded = (state: State, listingId: number) => {
  return _getIsFullCannedResponseLoaded(getCannedResponseState(state), listingId);
};

export const getFullCannedResponseById = (state: State, listingId: number) => {
  return getCannedResponseEntities(state)[listingId];
};

/**
 * New Neighbourhood-Draft Reducer
 */

/**
 * New Space-Draft Reducer
 */

export const getSpaceDraftState = (state: State) => state.spaceDraft;

export const {
  selectIds: getSpaceDraftIds,
  selectEntities: getSpaceDraftEntities,
  selectAll: getAllSpaceDraft,
  selectTotal: getTotalSpaceDraft, // returns total count
} = spaceDraftAdapter.getSelectors(getSpaceDraftState);

export const getAllSpaceDrafts = getAllSpaceDraft;


export const getIsSpaceDraftLoading = createSelector(getSpaceDraftState, _getIsSpaceDraftLoading);
export const getIsSpaceDraftLoaded = createSelector(getSpaceDraftState, _getIsSpaceDraftLoaded);

export const getIsFullSpaceDraftLoading = (state: State, listingId: number) => {
  return _getIsFullSpaceDraftLoading(getSpaceDraftState(state), listingId);
};

export const getIsFullSpaceDraftLoaded = (state: State, listingId: number) => {
  return _getIsFullSpaceDraftLoaded(getSpaceDraftState(state), listingId);
};

export const getFullSpaceDraftById = (state: State, listingId: number) => {
  return getSpaceDraftEntities(state)[listingId];
};


/**
 * New OwnerStory-Draft Reducer
 */

export const getOwnerStoryDraftState = (state: State) => state.ownerStoryDraft;

export const {
  selectIds: getOwnerStoryDraftIds,
  selectEntities: getOwnerStoryDraftEntities,
  selectAll: getAllOwnerStoryDraft,
  selectTotal: getTotalOwnerStoryDraft, // returns total count
} = ownerStoryAdapter.getSelectors(getOwnerStoryDraftState);

export const getAllOwnerStoryDrafts = getAllOwnerStoryDraft;


export const getIsOwnerStoryDraftLoading = createSelector(getOwnerStoryDraftState, _getIsOwnerStoryDraftLoading);
export const getIsOwnerStoryDraftLoaded = createSelector(getOwnerStoryDraftState, _getIsOwnerStoryDraftLoaded);

export const getIsFullOwnerStoryDraftLoading = (state: State, listingId: number) => {
  return _getIsFullOwnerStoryDraftLoading(getOwnerStoryDraftState(state), listingId);
};

export const getIsFullOwnerStoryDraftLoaded = (state: State, listingId: number) => {
  return _getIsFullOwnerStoryDraftLoaded(getOwnerStoryDraftState(state), listingId);
};

export const getFullOwnerStoryDraftById = (state: State, listingId: number) => {
  return getOwnerStoryDraftEntities(state)[listingId];
};

/**
 * New Summary-Draft Reducer
 */

export const getSummaryDraftState = (state: State) => state.summaryDraft;

export const {
  selectIds: getSummaryDraftIds,
  selectEntities: getSummaryDraftEntities,
  selectAll: getAllSummaryDraft,
  selectTotal: getTotalSummaryDraft, // returns total count
} = summaryDraftAdapter.getSelectors(getSummaryDraftState);

export const getAllSummaryDrafts = getAllSummaryDraft;


export const getIsSummaryDraftLoading = createSelector(getSummaryDraftState, _getIsSummaryDraftLoading);
export const getIsSummaryDraftLoaded = createSelector(getSummaryDraftState, _getIsSummaryDraftLoaded);

export const getIsFullSummaryDraftLoading = (state: State, listingId: number) => {
  return _getIsFullSummaryDraftLoading(getSummaryDraftState(state), listingId);
};

export const getIsFullSummaryDraftLoaded = (state: State, listingId: number) => {
  return _getIsFullSummaryDraftLoaded(getSummaryDraftState(state), listingId);
};

export const getFullSummaryDraftById = (state: State, listingId: number) => {
  return getSummaryDraftEntities(state)[listingId];
};

/**
 * New Getting-Around-Draft Reducer
 */

export const getGettingAroundDraftState = (state: State) => state.gettingAroundDraft;

export const {
  selectIds: getGettingAroundDraftIds,
  selectEntities: getGettingAroundDraftEntities,
  selectAll: getAllGettingAroundDraft,
  selectTotal: getTotalGettingAroundDraft, // returns total count
} = gettingAroundDraftAdapter.getSelectors(getGettingAroundDraftState);

export const getAllGettingAroundDrafts = getAllGettingAroundDraft;


export const getIsGettingAroundDraftLoading = createSelector(getGettingAroundDraftState, _getIsGettingAroundDraftLoading);
export const getIsGettingAroundDraftLoaded = createSelector(getGettingAroundDraftState, _getIsGettingAroundDraftLoaded);

export const getIsFullGettingAroundDraftLoading = (state: State, listingId: number) => {
  return _getIsFullGettingAroundDraftLoading(getGettingAroundDraftState(state), listingId);
};

export const getIsFullGettingAroundDraftLoaded = (state: State, listingId: number) => {
  return _getIsFullGettingAroundDraftLoaded(getGettingAroundDraftState(state), listingId);
};

export const getFullGettingAroundDraftById = (state: State, listingId: number) => {
  return getGettingAroundDraftEntities(state)[listingId];
};

/**
 * New Review-Response-Draft Reducer
 */

export const getReviewResponseDraftState = (state: State) => state.reviewResponseDraft;

export const {
  selectIds: getReviewResponseDraftIds,
  selectEntities: getReviewResponseDraftEntities,
  selectAll: getAllReviewResponseDraft,
  selectTotal: getTotalReviewResponseDraft, // returns total count
} = reviewResponseDraftAdapter.getSelectors(getReviewResponseDraftState);

export const getAllReviewResponseDrafts = getAllReviewResponseDraft;

export const getIsReviewResponseDraftLoading = createSelector(getReviewResponseDraftState, _getIsReviewResponseDraftLoading);
export const getIsReviewResponseDraftLoaded = createSelector(getReviewResponseDraftState, _getIsReviewResponseDraftLoaded);

/**
 * New Goal-Set-Percentages-Draft Reducer
 */

export const getGoalSetPercentagesDraftState = (state: State) => state.goalSetPercentagesDraft;

export const {
  selectIds: getGoalSetPercentagesDraftIds,
  selectEntities: getGoalSetPercentagesDraftEntities,
  selectAll: getAllGoalSetPercentagesDraft,
  selectTotal: getTotalGoalSetPercentagesDraft, // returns total count
} = goalSetPercentagesDraftAdapter.getSelectors(getGoalSetPercentagesDraftState);

export const getAllGoalSetPercentagesDrafts = getAllGoalSetPercentagesDraft;

export const getIsGoalSetPercentagesDraftLoading = createSelector(getGoalSetPercentagesDraftState, _getIsGoalSetPercentagesDraftLoading);
export const getIsGoalSetPercentagesDraftLoaded = createSelector(getGoalSetPercentagesDraftState, _getIsGoalSetPercentagesDraftLoaded);

/**
 * New Guest-Access-Draft Reducer
 */

export const getGuestAccessDraftState = (state: State) => state.guestAccessDraft;

export const {
  selectIds: getGuestAccessDraftIds,
  selectEntities: getGuestAccessDraftEntities,
  selectAll: getAllGuestAccessDraft,
  selectTotal: getTotalGuestAccessDraft, // returns total count
} = guestAccessDraftAdapter.getSelectors(getGuestAccessDraftState);

export const getAllGuestAccessDrafts = getAllGuestAccessDraft;


export const getIsGuestAccessDraftLoading = createSelector(getGuestAccessDraftState, _getIsGuestAccessDraftLoading);
export const getIsGuestAccessDraftLoaded = createSelector(getGuestAccessDraftState, _getIsGuestAccessDraftLoaded);

export const getIsFullGuestAccessDraftLoading = (state: State, listingId: number) => {
  return _getIsFullGuestAccessDraftLoading(getGuestAccessDraftState(state), listingId);
};

export const getIsFullGuestAccessDraftLoaded = (state: State, listingId: number) => {
  return _getIsFullGuestAccessDraftLoaded(getGuestAccessDraftState(state), listingId);
};

export const getFullGuestAccessDraftById = (state: State, listingId: number) => {
  return getGuestAccessDraftEntities(state)[listingId];
};

/**
 * New Interactions-Draft Reducer
 */

export const getInteractionsDraftState = (state: State) => state.interactionsDraft;

export const {
  selectIds: getInteractionsDraftIds,
  selectEntities: getInteractionsDraftEntities,
  selectAll: getAllInteractionsDraft,
  selectTotal: getTotalInteractionsDraft, // returns total count
} = interactionsDraftAdapter.getSelectors(getInteractionsDraftState);

export const getAllInteractionsDrafts = getAllInteractionsDraft;


export const getIsInteractionsDraftLoading = createSelector(getInteractionsDraftState, _getIsInteractionsDraftLoading);
export const getIsInteractionsDraftLoaded = createSelector(getInteractionsDraftState, _getIsInteractionsDraftLoaded);

export const getIsFullInteractionsDraftLoading = (state: State, listingId: number) => {
  return _getIsFullInteractionsDraftLoading(getInteractionsDraftState(state), listingId);
};

export const getIsFullInteractionsDraftLoaded = (state: State, listingId: number) => {
  return _getIsFullInteractionsDraftLoaded(getInteractionsDraftState(state), listingId);
};

export const getFullInteractionsDraftById = (state: State, listingId: number) => {
  return getInteractionsDraftEntities(state)[listingId];
};

/**
 * New Concierge-Draft Reducer
 */

export const getConciergeDraftState = (state: State) => state.conciergeDraft;

export const {
  selectIds: getConciergeDraftIds,
  selectEntities: getConciergeDraftEntities,
  selectAll: getAllConciergeDraft,
  selectTotal: getTotalConciergeDraft, // returns total count
} = conciergeDraftAdapter.getSelectors(getConciergeDraftState);

export const getAllConciergeDrafts = getAllConciergeDraft;


export const getIsConciergeDraftLoading = createSelector(getConciergeDraftState, _getIsConciergeDraftLoading);
export const getIsConciergeDraftLoaded = createSelector(getConciergeDraftState, _getIsConciergeDraftLoaded);

export const getIsFullConciergeDraftLoading = (state: State, listingId: number) => {
  return _getIsFullConciergeDraftLoading(getConciergeDraftState(state), listingId);
};

export const getIsFullConciergeDraftLoaded = (state: State, listingId: number) => {
  return _getIsFullConciergeDraftLoaded(getConciergeDraftState(state), listingId);
};

export const getFullConciergeDraftById = (state: State, listingId: number) => {
  return getConciergeDraftEntities(state)[listingId];
};

/**
 * New Other-Notes-Draft Reducer
 */

export const getOtherNotesDraftState = (state: State) => state.otherNotesDraft;

export const {
  selectIds: getOtherNotesDraftIds,
  selectEntities: getOtherNotesDraftEntities,
  selectAll: getAllOtherNotesDraft,
  selectTotal: getTotalOtherNotesDraft, // returns total count
} = otherNotesDraftAdapter.getSelectors(getOtherNotesDraftState);

export const getAllOtherNotesDrafts = getAllOtherNotesDraft;


export const getIsOtherNotesDraftLoading = createSelector(getOtherNotesDraftState, _getIsOtherNotesDraftLoading);
export const getIsOtherNotesDraftLoaded = createSelector(getOtherNotesDraftState, _getIsOtherNotesDraftLoaded);

export const getIsFullOtherNotesDraftLoading = (state: State, listingId: number) => {
  return _getIsFullOtherNotesDraftLoading(getOtherNotesDraftState(state), listingId);
};

export const getIsFullOtherNotesDraftLoaded = (state: State, listingId: number) => {
  return _getIsFullOtherNotesDraftLoaded(getOtherNotesDraftState(state), listingId);
};

export const getFullOtherNotesDraftById = (state: State, listingId: number) => {
  return getOtherNotesDraftEntities(state)[listingId];
};

/**
 * New Private-Notes-Draft Reducer
 */

export const getPrivateNotesDraftState = (state: State) => state.privateNotesDraft;

export const {
  selectIds: getPrivateNotesDraftIds,
  selectEntities: getPrivateNotesDraftEntities,
  selectAll: getAllPrivateNotesDraft,
  selectTotal: getTotalPrivateNotesDraft, // returns total count
} = privateNotesDraftAdapter.getSelectors(getPrivateNotesDraftState);

export const getAllPrivateNotesDrafts = getAllPrivateNotesDraft;


export const getIsPrivateNotesDraftLoading = createSelector(getPrivateNotesDraftState, _getIsPrivateNotesDraftLoading);
export const getIsPrivateNotesDraftLoaded = createSelector(getPrivateNotesDraftState, _getIsPrivateNotesDraftLoaded);

export const getIsFullPrivateNotesDraftLoading = (state: State, listingId: number) => {
  return _getIsFullPrivateNotesDraftLoading(getPrivateNotesDraftState(state), listingId);
};

export const getIsFullPrivateNotesDraftLoaded = (state: State, listingId: number) => {
  return _getIsFullPrivateNotesDraftLoaded(getPrivateNotesDraftState(state), listingId);
};

export const getFullPrivateNotesDraftById = (state: State, listingId: number) => {
  return getPrivateNotesDraftEntities(state)[listingId];
};

/**
 * New Saved-Neighbourhood-Draft Reducer
 */

export const getSavedNeighbourhoodDraftState = (state: State) => state.savedNeighbourhoodDraft;

export const {
  selectIds: getSavedNeighbourhoodDraftIds,
  selectEntities: getSavedNeighbourhoodDraftEntities,
  selectAll: getAllSavedNeighbourhoodDraft,
  selectTotal: getTotalSavedNeighbourhoodDraft, // returns total count
} = savedNeighbourhoodDraftAdapter.getSelectors(getSavedNeighbourhoodDraftState);

export const getAllNeighbourhoodDrafts = getAllSavedNeighbourhoodDraft;


export const getIsNeighbourhoodDraftLoading = createSelector(getSavedNeighbourhoodDraftState, _getIsSavedNeighbourhoodDraftLoading);
export const getIsNeighbourhoodDraftLoaded = createSelector(getSavedNeighbourhoodDraftState, _getIsSavedNeighbourhoodDraftLoaded);

export const getIsFullNeighbourhoodDraftLoading = (state: State, listingId: number) => {
  return _getIsFullSavedNeighbourhoodDraftLoading(getSavedNeighbourhoodDraftState(state), listingId);
};

export const getIsFullNeighbourhoodDraftLoaded = (state: State, listingId: number) => {
  return _getIsFullSavedNeighbourhoodDraftLoaded(getSavedNeighbourhoodDraftState(state), listingId);
};

export const getFullNeighbourhoodDraftById = (state: State, listingId: number) => {
  return getSavedNeighbourhoodDraftEntities(state)[listingId];
};

/**
 * New Respond Reviews Reducer
 */

export const getRespondReviewsState = (state: State) => state.respondReviews;

export const {
  selectIds: getRespondReviewsIds,
  selectEntities: getRespondReviewsEntities,
  selectAll: getRespondReviews,
  selectTotal: getTotalRespondReviews, // returns total count
} = respondReviewsAdapter.getSelectors(getRespondReviewsState);

export const getAllRespondReviews = getRespondReviews;

export const getIsRespondReviewsLoading = createSelector(getRespondReviewsState, _getIsRespondReviewsLoading);
export const getIsRespondReviewsLoaded = createSelector(getRespondReviewsState, _getIsRespondReviewsLoaded);

export const getRespondReviewsCurrentPage = createSelector(getRespondReviewsState, _getRespondReviewsCurrentPage);
export const getRespondReviewsPerPage = createSelector(getRespondReviewsState, _getRespondReviewsPerPage);
export const getRespondReviewsTotal = createSelector(getRespondReviewsState, _getRespondReviewsTotal);

export const getRespondReviewsSortOrder = createSelector(getRespondReviewsState, _getRespondReviewsSortOrder);
export const getRespondReviewsSortType = createSelector(getRespondReviewsState, _getRespondReviewsSortType);

export const getRespondReviewsRating = createSelector(getRespondReviewsState, _getRespondReviewsRating);
export const getRespondReviewsListing = createSelector(getRespondReviewsState, _getRespondReviewsListing);
export const getRespondReviewsTaskData = createSelector(getRespondReviewsState, _getRespondReviewsTaskData);

/**
 * New Rating Reviews Reducer
 */

export const getRatingReviewsState = (state: State) => state.ratingReviews;

export const {
  selectIds: getRatingReviewsIds,
  selectEntities: getRatingReviewsEntities,
  selectAll: getRatingReviews,
  selectTotal: getTotalRatingReviews, // returns total count
} = ratingReviewsAdapter.getSelectors(getRatingReviewsState);

export const getAllRatingReviews = getRatingReviews;

export const getIsRatingReviewsLoading = createSelector(getRatingReviewsState, _getIsRatingReviewsLoading);
export const getIsRatingReviewsLoaded = createSelector(getRatingReviewsState, _getIsRatingReviewsLoaded);

export const getRatingReviewsCurrentPage = createSelector(getRatingReviewsState, _getRatingReviewsCurrentPage);
export const getRatingReviewsPerPage = createSelector(getRatingReviewsState, _getRatingReviewsPerPage);
export const getRatingReviewsTotal = createSelector(getRatingReviewsState, _getRatingReviewsTotal);

export const getRatingReviewsSortOrder = createSelector(getRatingReviewsState, _getRatingReviewsSortOrder);
export const getRatingReviewsListing = createSelector(getRatingReviewsState, _getRatingReviewsListing);

/**
 * New Automated-Message Reducer
 */

export const getAutomatedMessageState = (state: State) => state.automatedMessage;

export const {
  selectIds: getAutomatedMessageIds,
  selectEntities: getAutomatedMessageEntities,
  selectAll: getAllAutomatedMessage,
  selectTotal: getTotalAutomatedMessage, // returns total count of
} = automatedMessageAdapter.getSelectors(getAutomatedMessageState);

export const getAllAutomatedMessages = getAllAutomatedMessage;

export const getAutomatedMessagesForListingId = (state: State, listingId: number) => {
  return getAllAutomatedMessage(state).filter((automatedMessage: AutomatedMessageCompact | AutomatedMessageFull) => {

    if (automatedMessage.alert_type === "include") {
      return automatedMessage.property_ids.indexOf(listingId) !== -1;

    } else {
      return automatedMessage.property_ids.indexOf(listingId) === -1;
    }

  });
};

export const getIsAutomatedMessageLoading = createSelector(getAutomatedMessageState, _getIsAutomatedMessageLoading);
export const getIsAutomatedMessageLoaded = createSelector(getAutomatedMessageState, _getIsAutomatedMessageLoaded);

export const getIsFullAutomatedMessageLoading = (state: State, listingId: number) => {
  return _getIsFullAutomatedMessageLoading(getAutomatedMessageState(state), listingId);
};

export const getIsFullAutomatedMessageLoaded = (state: State, listingId: number) => {
  return _getIsFullAutomatedMessageLoaded(getAutomatedMessageState(state), listingId);
};

export const getFullAutomatedMessageById = (state: State, listingId: number) => {
  return getAutomatedMessageEntities(state)[listingId];
};

/**
 * New Multi_Listings Reducer
 */

export const getMultiListingState = (state: State) => state.multiListing;

export const {
  selectIds: getMultiListingIds,
  selectEntities: getMultiListingEntities,
  selectAll: getAllMultiListing,
  selectTotal: getTotalMultiListing, // returns total count of Multi Listings
} = multiListingAdapter.getSelectors(getMultiListingState);

export const getAllMultiListings = getAllMultiListing;

export const getIsMultiListingLoading = createSelector(getMultiListingState, _getIsMultiListingLoading);
export const getIsMultiListingLoaded = createSelector(getMultiListingState, _getIsMultiListingLoaded);

export const getMultiListingById = (state: State, listingId: number) => {
  return getMultiListingEntities(state)[listingId];
};

/**
 * New Booking reducer
 */
export const getBookingsState = (state: State) => state.newBooking;

export const {
  selectIds: getBookingsIds,
  selectEntities: getBookingsEntities,
  selectAll: getAllBooking,
  selectTotal: getTotalBookings, // returns total booking
} = bookingAdapter.getSelectors(getBookingsState);

export const getAllBookings = getAllBooking;

export const getIsBookingsLoading = createSelector(getBookingsState, _getIsBookingsLoading);
export const getIsBookingsLoaded = createSelector(getBookingsState, _getIsBookingsLoaded);
export const getSortOrder = createSelector(getBookingsState, _getSortOrder);
export const getReviewRating = createSelector(getBookingsState, _getReviewRating);
export const getReservationType = createSelector(getBookingsState, _getReservationType);
export const getReservationSortType = createSelector(getBookingsState, _getReservationSortType);
export const getTotalCount = createSelector(getBookingsState, _getTotalCount);
export const getPerPageCount = createSelector(getBookingsState, _getPerPageCount);
export const getTotalPages = createSelector(getBookingsState, _getTotalPages);
export const getCurrentPage = createSelector(getBookingsState, _getCurrentPage);
export const getReservationListingIds = createSelector(getBookingsState, _getListingIds);
export const getReservationSources = createSelector(getBookingsState, _getSources);
export const getReservationTags = createSelector(getBookingsState, _getTags);
export const getReservationStart = createSelector(getBookingsState, _getStart);
export const getReservationEnd = createSelector(getBookingsState, _getEnd);

export const getIsFullBookingsLoading = (state: State, bookingId: number) => {
  return _getIsFullBookingsLoading(getBookingsState(state), bookingId);
};
export const getIsFullBookingsLoaded = (state: State, bookingId: number) => {
  return _getIsFullBookingsLoaded(getBookingsState(state), bookingId);
};
export const getFullBookingsById = (state: State, bookingId: number) => {
  return getBookingsEntities(state)[bookingId];
};


export const getBookingSortType = createSelector(getBookingsState, _getReservationSortType);
export const getBookingSortOrder = createSelector(getBookingsState, _getSortOrder);

export const getOwnerUpcomingBookings = createSelector(getBookingsState, _getOwnerUpcomingAll);
export const getOwnerUpcomingBookingEntities = createSelector(getBookingsState, _getOwnerUpcomingEntities);

export const getIsOwnerUpcomingBookingLoading = createSelector(getBookingsState, _getIsOwnerUpcomingLoading);
export const getIsOwnerUpcomingBookingLoaded = createSelector(getBookingsState, _getIsOwnerUpcomingLoaded);

export const getBookingExist = (state: State, bookingId: number) => {
  return _getBookingExist(bookingId, getBookingsState(state));
};

export const getVendorTasksLoadingForBookingId = (state: State, bookingId: number) => {
  const vendorTasksLoading = _getVendorTasksLoading(getBookingsState(state));
  return vendorTasksLoading[bookingId];
};

export const getVendorTasksLoadedForBookingId = (state: State, bookingId: number) => {
  const vendorTasksLoaded = _getVendorTasksLoaded(getBookingsState(state));
  return vendorTasksLoaded[bookingId];
};

export const getVendorTasksForBookingId = (state: State, bookingId: number) => {
  const vendorTasks = _getVendorTasks(getBookingsState(state));
  return vendorTasks[bookingId] || [];
};

/**
 * New Inventory Reducer
 */
export const getInventoryState = (state: State) => state.inventory;

export const {
  selectIds: getInventoryIds,
  selectEntities: getInventoryEntities,
  selectAll: getAllInventory,
  selectTotal: getInventory, // returns total count of  inventories
} = inventoryAdapter.getSelectors(getInventoryState);

export const getAllInventories = getAllInventory;


export const getIsInventoryLoading = createSelector(getInventoryState, _getIsInventoryLoading);
export const getIsInventoryLoaded = createSelector(getInventoryState, _getIsInventoryLoaded);
export const getUnTagged = createSelector(getInventoryState, _getUnTagged);

export const getIsFullInventoryLoading = (state: State, listingId: number) => {
  return _getIsFullInventoryLoading(getInventoryState(state), listingId);
};

export const getIsFullInventoryLoaded = (state: State, listingId: number) => {
  return _getIsFullInventoryLoaded(getInventoryState(state), listingId);
};

export const getFullInventoryById = (state: State, listingId: number) => {
  return getInventoryEntities(state)[listingId];
};

export const getAllInventoryTags = createSelector(getInventoryState, _getAllInventoryTags);

/**
 * All the selectors from the contact state
 */
export const getContactState = (state: State) => state.contact;

export const {
  selectIds: getContactIds,
  selectEntities: getContactEntities,
  selectAll: getAllContacts,
  selectTotal: getTotalContact, // returns total contacts
} = contactAdapter.getSelectors(getContactState);


export const getAllActiveContacts = createSelector(
  getContactState,
  getContactEntities,
  (state, entities) => {
    const activeIds = _getAllActiveIds(state);
    return activeIds.map(id => entities[id]);
  }
);
export const getIsActiveContactLoading = createSelector(getContactsState, _getIsActiveContactLoading);
export const getIsActiveContactLoaded = createSelector(getContactsState, _getIsActiveContactLoaded);


export const getHomeOwners = createSelector(getContactsState, _getAllHomeOwners);
export const getIsHomeOwnerLoading = createSelector(getContactsState, _getIsHomeOwnersLoading);
export const getIsHomeOwnerLoaded = createSelector(getContactsState, _getIsHomeOwnersLoaded);


export const getAllInActiveContacts = createSelector(
  getContactState,
  getContactEntities,
  (state, entities) => {
    const inActiveIds = _getAllInActiveIds(state);
    return inActiveIds.map(id => entities[id]);
  }
);
export const getIsInActiveContactLoading = createSelector(getContactsState, _getIsInActiveContactLoading);
export const getIsInActiveContactLoaded = createSelector(getContactsState, _getIsInActiveContactLoaded);

export const getIsFullContactLoading = (state: State, contactId: number) => {
  return _getIsFullContactLoading(getContactState(state), contactId);
};
export const getIsFullContactLoaded = (state: State, contactId: number) => {
  return _getIsFullContactLoaded(getContactState(state), contactId);
};
export const getContactById = (state: State, contactId: number) => {
  return getContactEntities(state)[contactId];
};

export const getContactPerPage = createSelector(getContactState, _getContactPerPage);
export const getContactSortOrder = createSelector(getContactState, _getContactSortOrder);
export const getContactSortBy = createSelector(getContactState, _getContactSortBy);
export const getContactFilterType = createSelector(getContactState, _getContactFilterType);
export const getContactStatus = createSelector(getContactState, _getContactStatus);
export const getContactCurrentPage = createSelector(getContactState, _getContactCurrentPage);
export const getContactTotalPage = createSelector(getContactState, _getContactTotalPage);
export const getContactTotalCount = createSelector(getContactState, _getContactTotalCount);
export const getIsContactLoadingForPageNumber = (state: State, page: number) => {
  return _getIsContactLoadingForPageNumber(getContactState(state), page);
};

export const getIsContactLoadedForPageNumber = (state: State, page: number) => {
  return _getIsContactLoadedForPageNumber(getContactState(state), page);
};

export const getAllContactIdsForPageNumber = (state: State, page: number) => {
  return _getAllContactIdsForPageNumber(getContactState(state), page);
};

export const getIsContactCurrentPageLoading = createSelector(getContactState, getContactCurrentPage, (state, currentPage) => {
  return _getIsContactLoadingForPageNumber(state, currentPage);
});

export const getIsContactCurrentPageLoaded = createSelector(getContactState, getContactCurrentPage, (state, currentPage) => {
  return _getIsContactLoadedForPageNumber(state, currentPage);
});

export const getAllContactsForCurrentPage = createSelector(getContactState, getContactCurrentPage, getContactEntities,
  (state, currentPage, entities) => {
    const ids = _getAllContactIdsForPageNumber(state, currentPage);

    return ids.map(id => entities[id]);
  });

/**
 * Stats Reducer
 */
export const getStatsState = (state: State) => state.stats;

export const getIsOwnerStatsLoading = createSelector(getStatsState, _getIsOwnerStatsLoading);
export const getIsOwnerStatsLoaded = createSelector(getStatsState, _getIsOwnerStatsLoaded);
export const getOwnerStats = createSelector(getStatsState, _getOwnerStats);

export const getIsAdminStatsLoading = createSelector(getStatsState, _getIsAdminStatsLoading);
export const getIsAdminStatsLoaded = createSelector(getStatsState, _getIsAdminStatsLoaded);
export const getAdminStats = createSelector(getStatsState, _getAdminStats);

export const getIsCleaningStatsLoading = createSelector(getStatsState, _getIsCleaningStatsLoading);
export const getIsCleaningStatsLoaded = createSelector(getStatsState, _getIsCleaningStatsLoaded);
export const getCleaningStats = createSelector(getStatsState, _getCleaningStats);

export const getIsCommissionStatsLoading = createSelector(getStatsState, _getIsCommissionStatsLoading);
export const getIsCommissionStatsLoaded = createSelector(getStatsState, _getIsCommissionStatsLoaded);
export const getCommissionStats = createSelector(getStatsState, _getCommissionStats);

export const getIsNetIncomeStatsLoading = createSelector(getStatsState, _getIsNetIncomeStatsLoading);
export const getIsNetIncomeStatsLoaded = createSelector(getStatsState, _getIsNetIncomeStatsLoaded);
export const getNetIncomeStats = createSelector(getStatsState, _getNetIncomeStats);
export const getNetIncomeYear = createSelector(getStatsState, _getNetIncomeYear);

export const getIsMonthlyBreakdownLoading = createSelector(getStatsState, _getIsMonthlyBreakdownLoading);
export const getIsMonthlyBreakdownLoaded = createSelector(getStatsState, _getIsMonthlyBreakdownLoaded);
export const getMonthlyBreakdown = createSelector(getStatsState, _getMonthlyBreakdown);

export const getStatsSelectedListings = createSelector(getStatsState, _getSelectedListings);


export const getListingCalendarState = (state: State) => state.listingCalendar;

export const getIsCalendarDataLoading = (state: State, listingId: number, month: string) => {
  return _getIsCalendarDataLoading(getListingCalendarState(state), listingId, month);
};
export const getIsCalendarDataLoaded = (state: State, listingId: number, month: string) => {
  return _getIsCalendarDataLoaded(getListingCalendarState(state), listingId, month);
};
export const getCalendarData = (state: State, listingId: number, month: string) => {
  return _getCalendarData(getListingCalendarState(state), listingId, month);
};


export const getMessageState = (state: State) => state.message;

export const {
  selectIds: getMessageIds,
  selectEntities: getMessageEntities,
  selectAll: getAllMessages,
  selectTotal: getTotalMessage,
} = messageAdapter.getSelectors(getMessageState);


export const getThreadMessageState = (state: State) => state.threadMessage;

export const getThreadState = (state: State) => state.thread;

export const {
  selectIds: getThreadIds,
  selectEntities: getThreadEntities,
  selectAll: getAllThread,
  selectTotal: getTotalThread, // returns total count of tasks
} = threadAdapter.getSelectors(getThreadState);

export const getAllThreads = getAllThread;

export const getMessagesForThreadId = (state: State, threadId: number) => {
  const messageIds = _getMessageIdsForThreadId(getThreadMessageState(state), threadId);
  const messageEntities = _getMessageEntities(getMessageState(state));
  return messageIds.map(id => messageEntities[id]);
};


export const getMessagesLoadingForThreadId = (state: State, threadId: number) => {
  return _getMessagesLoadingForThreadId(getThreadMessageState(state), threadId);
};

export const getMessagesLoadedForThreadId = (state: State, threadId: number) => {
  return _getMessagesLoadedForThreadId(getThreadMessageState(state), threadId);
};

export const getIsThreadLoadingForPageNumber = (state: State, pageNumber: number) => {
  return _getIsThreadLoadingForPageNumber(getThreadState(state), pageNumber);
};
export const getIsThreadLoadedForPageNumber = (state: State, pageNumber: number) => {
  return _getIsThreadLoadedForPageNumber(getThreadState(state), pageNumber);
};

export const getAllThreadIdsForPageNumber = (state: State, pageNumber: number) => {
  return _getAllThreadIdsForPageNumber(getThreadState(state), pageNumber);
};

export const getIsFullThreadLoading = (state: State, threadId: number, bookingId: number) => {
  return _getIsFullThreadLoading(getThreadState(state), threadId) && getThreadEntities(state)[threadId] && getThreadEntities(state)[threadId].booking_id === bookingId;
};

export const getIsFullThreadLoaded = (state: State, threadId: number, bookingId: number) => {
  return _getIsFullThreadLoaded(getThreadState(state), threadId) && getThreadEntities(state)[threadId] && getThreadEntities(state)[threadId].booking_id === bookingId;
};

export const getFullThreadById = (state: State, threadId: number) => {
  return getThreadEntities(state)[threadId];
};

export const getSelectedThreadId = createSelector(getThreadState, _getSelectedThreadId);
export const getSelectedBookingId = createSelector(getThreadState, _getSelectedBookingId);

export const getThreadCurrentPage = createSelector(getThreadState, _getThreadCurrentPage);
export const getThreadTotalPages = createSelector(getThreadState, _getThreadTotalPages);
export const getThreadTotalCount = createSelector(getThreadState, _getThreadTotalCount);

export const getThreadFilters = createSelector(getThreadState, _getThreadFilters);
export const getThreadListingIds = createSelector(getThreadState, _getThreadListingIds);
export const getThreadAssigneeIds = createSelector(getThreadState, _getThreadAssigneeIds);
export const getThreadOffset = createSelector(getThreadState, _getThreadOffset);
export const getThreadOffsetDate = createSelector(getThreadState, _getThreadOffsetDate);


export const getAllThreadsForCurrentPage = createSelector(
  getThreadState,
  getThreadCurrentPage,
  getThreadEntities,
  (state, currentPage, entities) => {
    const threadIds = _getAllThreadIdsForPageNumber(state, currentPage);
    return threadIds.map(id => entities[id]);
  }
);

export const getIsThreadCurrentPageLoading = createSelector(
  getThreadState,
  getThreadCurrentPage,
  (state, currentPage) => {
    return _getIsThreadLoadingForPageNumber(state, currentPage);
  }
);

export const getIsThreadCurrentPageLoaded = createSelector(
  getThreadState,
  getThreadCurrentPage,
  (state, currentPage) => {
    return _getIsThreadLoadedForPageNumber(state, currentPage);
  }
);

export const getTaskCommentState = (state: State) => state.taskComment;

export const getCommentState = (state: State) => state.comment;

export const {
  selectIds: getCommentIds,
  selectEntities: getCommentEntities,
  selectAll: getAllComment,
  selectTotal: getComment, // returns total count of  inventories
} = commentAdapter.getSelectors(getCommentState);

export const getAllComments = getAllComment;


// export const getIsCommentLoading = createSelector(getCommentState, _getIsCommentLoading);
// export const getIsCommentLoaded = createSelector(getCommentState, _getIsCommentLoaded);

// export const getIsFullCommentLoading = (state: State, commentId: number) => {
//   return _getIsFullCommentLoading(getCommentState(state), commentId);
// };
//
// export const getIsFullCommentLoaded = (state: State, commentId: number) => {
//   return _getIsFullCommentLoaded(getCommentState(state), commentId);
// };

export const getCommentsLoadingForTaskId = (state: State, taskId: number) => {
  return _getCommentsLoadingForTaskId(getTaskCommentState(state), taskId);
};

export const getCommentsLoadedForTaskId = (state: State, taskId: number) => {
  return _getCommentsLoadedForTaskId(getTaskCommentState(state), taskId);
};

export const getCommentsForTaskId = (state: State, taskId: number) => {
  const commentIds = _getCommentIdsForTaskId(getTaskCommentState(state), taskId);
  const commentEntities = _getCommentEntities(getCommentState(state));
  console.log(commentEntities, commentIds);
  return commentIds.map(id => commentEntities[id]);
};

export const getMultiCalendarRuleState = (state: State) => state.multiCalendarRules;

export const {
  selectIds: getMultiCalendarRuleIds,
  selectEntities: getMultiCalendarRuleEntities,
  selectAll: getAllMultiCalendarRule,
  selectTotal: getTotalMultiCalendarRules, // returns total count of  inventories
} = multiCalendarRuleAdapter.getSelectors(getMultiCalendarRuleState);

export const getAllMultiCalendarRules = getAllMultiCalendarRule;
export const getIsMultiCalendarRulesLoading = createSelector(getMultiCalendarRuleState, _getIsMultiCalendarRulesLoading);
export const getIsMultiCalendarRulesLoaded = createSelector(getMultiCalendarRuleState, _getIsMultiCalendarRulesLoaded);

export const getBadgeCount = (state: State, bookingId: number) => {
  return _getBadgeCount(getBookingsState(state), bookingId)
};

export const getRoutingRuleState = (state: State) => state.routingRules;
export const {
  selectIds: getRoutingRuleIds,
  selectEntities: getRoutingRuleEntities,
  selectAll: getAllRoutingRules,
  selectTotal: geTotalRoutingRules,
} = routingRulesAdapter.getSelectors(getRoutingRuleState);

export const getRulesForListingId = (state: State, listingId: number) => {
  const ruleIds = _getRuleIdsForListingId(getRoutingRuleState(state), listingId);
  const entities = _getRuleEntities(getRoutingRuleState(state));
  console.log("rule", ruleIds, entities, ruleIds.map(i => entities[i]));
  return ruleIds.map(i => entities[i]);
};

export const getIsRulesLoadingForListingId = (state: State, listingId: number) => {
  return _getIsRulesLoadingForListingId(getRoutingRuleState(state), listingId);
};

export const getIsRulesLoadedForListingId = (state: State, listingId: number) => {
  return _getIsRulesLoadedForListingId(getRoutingRuleState(state), listingId);
};


// Calendar Data

export const getBlockById = (state: State, id: number) => {
  return _getBlockById(getCalendarDataState(state), id);
};

export const getBookingById = (state: State, id: number) => {
  return _getBookingById(getCalendarDataState(state), id);
};

export const getAutoReviewState = (state: State) => state.autoReview;

export const {
  selectIds: getAutoReviewIds,
  selectEntities: getAutoReviewEntities,
  selectAll: getAutoReviews,
  selectTotal: getTotalAutoReviews,
} = autoReviewAdapter.getSelectors(getAutoReviewState);

export const getAllAutoReviews = getAutoReviews;

export const getIsAutoReviewsLoading = createSelector(getAutoReviewState, _getIsAutoReviewsLoading);
export const getIsAutoReviewsLoaded = createSelector(getAutoReviewState, _getIsAutoReviewsLoaded);
export const getAutoReviewById = (state: State, id: number) => {
  return _getAutoReviewById(getAutoReviewState(state), id);
};

export const {
  selectIds: getCharityIds,
  selectEntities: getCharityEntities,
  selectAll: getCharities,
  selectTotal: getTotalCharities,
} = charityAdapter.getSelectors(getCharityState);

export const getAllCharities = getCharities;
export const getCharitiesLoading = createSelector(getCharityState, _isCharitiesLoading);
export const getCharitiesLoaded = createSelector(getCharityState, _isCharitiesLoaded);

export const {
  selectIds: getAffiliateIds,
  selectEntities: getAffiliateEntities,
  selectAll: getAffiliates,
  selectTotal: getTotalAffiliates,
} = affiliateAdapter.getSelectors(getAffiliateState);

export const getAllAffiliates = getAffiliates;
export const getAffiliatesLoading = createSelector(getAffiliateState, _isAffiliatesLoading);
export const getAffiliatesLoaded = createSelector(getAffiliateState, _isAffiliatesLoaded);
