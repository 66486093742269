import { UserCompact } from "../../../models/new/user/user-compact.model";
import { UserFull } from "../../../models/new/user/user-full.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../../../actions/action";
import { ContactActionTypes } from "../../../actions/new/contacts/contact";
import * as _ from "lodash";
import { W9Form } from "../../../models/new/user/w9-form.model";
import { SortOrder, TransformerType } from "../../../enums/common.enum";
import { ContactSortType, ContactStatus, ContactType } from "../../../enums/contact.enum";
import { BankInfoModel } from "../../../models/bank-info.model";

export interface ContactState extends EntityState<UserCompact | UserFull> {
  isLoading: boolean;
  isLoaded: boolean;

  loadingPagesId: number[];
  loadedPagesId: number[];
  contactIdsForPages: { [id: number]: number[] };

  sortOrder: SortOrder;
  sortBy: ContactSortType;
  status: ContactStatus;
  filterType: ContactType;
  currentPage: number;
  totalPages: number;
  perPage: number;
  totalCount: number;

  activeContactIds: number[];
  activeContactsLoading: boolean;
  activeContactsLoaded: boolean;

  homeOwners: UserCompact[];
  homeOwnersLoading: boolean;
  homeOwnersLoaded: boolean;

  inactiveContactIds: number[];
  inactiveContactsLoading: boolean;
  inactiveContactsLoaded: boolean;

  fullLoadingIds: number[],
  fullLoadedIds: number[],

}

export const contactAdapter: EntityAdapter<UserCompact | UserFull> = createEntityAdapter<UserCompact | UserFull>({
  selectId: (user: UserCompact | UserFull) => user.id
});

export const initialState: ContactState = contactAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  loadingPagesId: [],
  loadedPagesId: [],
  contactIdsForPages: {},

  sortOrder: SortOrder.ASC,
  sortBy: ContactSortType.FIRST_NAME,
  status: ContactStatus.ACTIVE,
  filterType: ContactType.ALL_CONTACTS,
  currentPage: 1,
  totalPages: 0,
  perPage: 15,
  totalCount: 0,

  activeContactIds: [],
  activeContactsLoading: false,
  activeContactsLoaded: false,

  inactiveContactIds: [],
  inactiveContactsLoading: false,
  inactiveContactsLoaded: false,

  homeOwners: [],
  homeOwnersLoading: false,
  homeOwnersLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],

});

export function contactReducer(state: ContactState = initialState, action: Action): ContactState {
  switch (action.type) {

    case ContactActionTypes.ACTIVE_INDEX_REQUEST: {
      return {
        ...state,
        activeContactsLoading: true
      };
    }

    case ContactActionTypes.ACTIVE_INDEX_SUCCESS: {
      const contacts = action.payload as UserCompact[];
      const ids = contacts.map(c => c.id);

      const changes = [];
      for (const contact of contacts) {
        changes.push({
          id: contact.id,
          changes: contact
        });
      }

      return contactAdapter.updateMany(changes, contactAdapter.addMany(contacts, {
        ...state,
        activeContactIds: ids,
        activeContactsLoading: false,
        activeContactsLoaded: true,
      }));
    }

    case ContactActionTypes.IN_ACTIVE_INDEX_REQUEST: {
      return {
        ...state,
        inactiveContactsLoading: true
      };
    }

    case ContactActionTypes.IN_ACTIVE_INDEX_SUCCESS: {
      const contacts = action.payload as UserCompact[];
      const ids = contacts.map(c => c.id);

      const changes = [];
      for (const contact of contacts) {
        changes.push({
          id: contact.id,
          changes: contact
        });
      }

      return contactAdapter.updateMany(changes, contactAdapter.addMany(contacts, {
        ...state,
        inactiveContactIds: ids,
        inactiveContactsLoading: false,
        inactiveContactsLoaded: true,
      }));
    }

    case ContactActionTypes.HOMEOWNERS_INDEX_REQUEST: {
      return {
        ...state,
        homeOwnersLoading: true
      };
    }

    case ContactActionTypes.HOMEOWNERS_INDEX_SUCCESS: {
      const contacts = action.payload as UserCompact[];
      return {
        ...state,
        homeOwners: contacts,
        homeOwnersLoading: false,
        homeOwnersLoaded: true,
      };
    }

    case ContactActionTypes.CREATE_REQUEST: {
      return state;
    }

    case ContactActionTypes.CREATE_SUCCESS: {
      const contact = action.payload as UserFull;

      return contactAdapter.updateOne({
        id: contact.id,
        changes: contact
      }, state);
    }

    case ContactActionTypes.SHOW_REQUEST: {
      const contactId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, contactId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          contactId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, contactId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, contactId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case ContactActionTypes.SHOW_SUCCESS: {
      const contact = action.payload as UserFull;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, contact.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, contact.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, contact.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          contact.id
        ];
      }

      return contactAdapter.updateOne({
        id: contact.id,
        changes: contact
      }, contactAdapter.addOne(contact, {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }));
    }

    case ContactActionTypes.UPDATE_REQUEST: {
      return state;
    }

    case ContactActionTypes.DELETE_STORE_REQUEST: {
      const contactIds = action.payload as number[];

      let fullyLoadedIds = state.fullLoadedIds;


      contactIds.forEach(id => {

        const loadedIndex = _.indexOf(fullyLoadedIds, id);

        if (loadedIndex === -1) {
          fullyLoadedIds = [
            ...fullyLoadedIds,
            id
          ];
        }
      });

      return {
        ...state,
        fullLoadedIds: fullyLoadedIds
      }
    }

    case ContactActionTypes.UPDATE_SUCCESS: {
      const updatedContact = action.payload as UserFull;

      return contactAdapter.updateOne({
        id: updatedContact.id,
        changes: updatedContact
      }, contactAdapter.addOne(updatedContact, state));
    }

    case ContactActionTypes.LISTING_ADD_REQUEST: {
      return state;
    }

    case ContactActionTypes.LISTING_ADD_SUCCESS: {
      const payload = action.payload as {
        contactId: number,
        listingId: number
      };

      if (!contactAdapter.getSelectors().selectEntities(state)[payload.contactId]) {
        return state;
      }

      const contact = contactAdapter.getSelectors().selectEntities(state)[payload.contactId] as UserCompact;

      return contactAdapter.updateOne({
        id: payload.contactId,
        changes: {
          managementContact: {
            data: {
              ...contact.managementContact.data,
              properties: [
                ...contact.managementContact.data.properties,
                payload.listingId
              ]
            }
          }
        }
      }, state);
    }

    case ContactActionTypes.LISTING_REMOVE_REQUEST: {
      return state;
    }

    case ContactActionTypes.LISTING_REMOVE_SUCCESS: {
      const payload = action.payload as {
        contactId: number,
        listingId: number
      };

      if (!contactAdapter.getSelectors().selectEntities(state)[payload.contactId]) {
        return state;
      }

      const contact = contactAdapter.getSelectors().selectEntities(state)[payload.contactId] as UserCompact;

      return contactAdapter.updateOne({
        id: payload.contactId,
        changes: {
          managementContact: {
            data: {
              ...contact.managementContact.data,
              properties: contact.managementContact.data.properties.filter(p => p !== payload.listingId)
            }
          }
        }
      }, state);
    }

    case ContactActionTypes.LISTING_ACTIVE_STATUS_REQUEST: {
      return state;
    }

    case ContactActionTypes.LISTING_ACTIVE_STATUS_SUCCESS: {
      const payload = action.payload as {
        contactId: number,
        active: boolean
      };

      return contactAdapter.updateOne({
        id: payload.contactId,
        changes: {
          is_active: payload.active
        }
      }, state);
    }

    case ContactActionTypes.W9_FORM_UPLOAD_SUCCESS: {
      const payload = action.payload as W9Form;

      const contact = contactAdapter.getSelectors().selectEntities(state)[payload.user_id];

      if (contact) {
        if (contact.___type === TransformerType.COMPACT) {
          return state;
        }

        const w9Forms = (contact as UserFull).w9Forms.data;

        return contactAdapter.updateOne({
          id: payload.user_id,
          changes: {
            w9Forms: {
              data: [
                payload,
                ...w9Forms
              ]
            }
          }
        }, state);
      }
      return state;
    }

    case ContactActionTypes.W9_FORM_DELETE_SUCCESS: {
      const payload = action.payload as {contactId: number, formId: number};

      const contact = contactAdapter.getSelectors().selectEntities(state)[payload.contactId];

      if (contact) {
        if (contact.___type === TransformerType.COMPACT) {
          return state;
        }

        let w9Forms = (contact as UserFull).w9Forms.data;

        const index = w9Forms.findIndex(f => f.id === payload.formId);

        if (index!==-1) {
          w9Forms = [
            ...w9Forms.slice(0, index),
            ...w9Forms.slice(index +1)
          ];
        }



        return contactAdapter.updateOne({
          id: payload.contactId,
          changes: {
            w9Forms: {
              data: [
                ...w9Forms
              ]
            }
          }
        }, state);
      }
      return state;
    }

    case ContactActionTypes.NEXT_PAGE_REQUEST: {
      const pageId = action.payload as number;


      let loadingPagesId = state.loadingPagesId;
      const loadingIndex = _.indexOf(loadingPagesId, pageId);

      // Adding if not present
      if (loadingIndex === -1) {
        loadingPagesId = [
          ...loadingPagesId,
          pageId
        ];
      }

      let loadedPagesId = state.loadedPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, pageId);

      // Removing if already added
      if (loadedIndex !== -1) {
        loadedPagesId = _.remove(loadedPagesId, pageId);
      }

      return {
        ...state,
        loadingPagesId: loadingPagesId,
        loadedPagesId: loadedPagesId
      };
    }

    case ContactActionTypes.NEXT_PAGE_SUCCESS: {
      const payload = action.payload as { contacts: UserCompact[], currentPage: number, totalPages: number, totalCount: number };

      let loadingPageIds = state.loadingPagesId;
      const loadingIndex = _.indexOf(loadingPageIds, payload.currentPage);
      console.log("Loading Page Id", loadingIndex, loadingPageIds);


      // Removing if loading
      if (loadingIndex !== -1) {
        loadingPageIds = _.remove(loadingPageIds, payload.currentPage);
      }

      let loadedPagesId = state.loadedPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, payload.currentPage);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        loadedPagesId = [
          ...loadedPagesId,
          payload.currentPage
        ];
      }


      return contactAdapter.addMany(payload.contacts, {
        ...state,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
        totalCount: payload.totalCount,
        loadingPagesId: loadingPageIds,
        loadedPagesId: loadedPagesId,
        contactIdsForPages: {
          ...state.contactIdsForPages,
          [payload.currentPage]: payload.contacts.map(t => t.id)
        }
      });

    }

    case ContactActionTypes.TYPE_CHANGE: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        contactIdsForPages: {},
        filterType: action.payload
      };
    }

    case ContactActionTypes.STATUS_CHANGE: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        contactIdsForPages: {},
        totalPages: 0,
        totalCount: 0,
        status: action.payload
      }
    }

    case ContactActionTypes.PER_PAGE_CHANGE: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        contactIdsForPages: {},
        perPage: action.payload
      };
    }

    case ContactActionTypes.SORT_TYPE_CHANGE: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        contactIdsForPages: {},
        sortBy: action.payload
      };
    }

    case ContactActionTypes.SORT_ORDER_CHANGE: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        contactIdsForPages: {},
        sortOrder: action.payload
      };
    }

    case ContactActionTypes.PAGE_CHANGE: {
      return {
        ...state,
        currentPage: action.payload
      }
    }

    case ContactActionTypes.BANK_ACCOUNT_CREATE_SUCCESS: {
      const bankAccount = action.payload as BankInfoModel;

      const index = state.fullLoadedIds.indexOf(bankAccount.user_id);

      if (index !== -1) {

        let bankAccounts = state.entities[bankAccount.user_id].bankDetails.data || [];

        bankAccounts = [
          ...bankAccounts,
          bankAccount
        ];


        return contactAdapter.updateOne({
            id: bankAccount.user_id,
            changes: {
              bankDetails: {
                data: bankAccounts
              }
            }
          }, state
        )

      }

      return state;

    }

    case ContactActionTypes.BANK_ACCOUNT_UPDATE_SUCCESS: {
      const bankAccount = action.payload as BankInfoModel;

      const index = state.fullLoadedIds.indexOf(bankAccount.user_id);

      if (index !== -1) {

        let bankAccounts = state.entities[bankAccount.user_id].bankDetails.data || [];

        const bankIndex = bankAccounts.map(b => b.id).indexOf(bankAccount.id);

        if (index !== -1) {
          bankAccounts = [
            ...bankAccounts.slice(0, bankIndex),
            bankAccount,
            ...bankAccounts.slice(bankIndex + 1)
          ];
        }
        return contactAdapter.updateOne({
            id: bankAccount.user_id,
            changes: {
              bankDetails: {
                data: bankAccounts
              }
            }
          }, state
        )

      }

      return state;
    }

    case ContactActionTypes.BANK_ACCOUNT_DELETE_SUCCESS: {
      const payload = action.payload as { bankAccountId: number, contactId: number };

      const index = state.fullLoadedIds.indexOf(payload.contactId);

      if (index !== -1) {

        let bankAccounts = state.entities[payload.contactId].bankDetails.data || [];

        const bankIndex = bankAccounts.map(b => b.id).indexOf(payload.bankAccountId);

        if (index !== -1) {
          bankAccounts = [
            ...bankAccounts.slice(0, bankIndex),
            ...bankAccounts.slice(bankIndex + 1)
          ];
        }
        return contactAdapter.updateOne({
            id: payload.contactId,
            changes: {
              bankDetails: {
                data: bankAccounts
              }
            }
          }, state
        )

      }

      return state;
    }


    default: {
      return state;
    }

  }
}

export const _getAllActiveIds = (state: ContactState) => state.activeContactIds;
export const _getIsActiveContactLoading = (state: ContactState) => state.activeContactsLoading;
export const _getIsActiveContactLoaded = (state: ContactState) => state.activeContactsLoaded;

export const _getAllInActiveIds = (state: ContactState) => state.inactiveContactIds;
export const _getIsInActiveContactLoading = (state: ContactState) => state.inactiveContactsLoading;
export const _getIsInActiveContactLoaded = (state: ContactState) => state.inactiveContactsLoaded;

export const _getAllHomeOwners = (state: ContactState) => state.homeOwners;
export const _getIsHomeOwnersLoading = (state: ContactState) => state.homeOwnersLoading;
export const _getIsHomeOwnersLoaded = (state: ContactState) => state.homeOwnersLoaded;

export const _getIsFullContactLoading = (state: ContactState, contactId: number) => {
  return state.fullLoadingIds.indexOf(contactId) !== -1;
};
export const _getIsFullContactLoaded = (state: ContactState, contactId: number) => {
  return state.fullLoadedIds.indexOf(contactId) !== -1;
};

export const _getContactPerPage = (state: ContactState) => state.perPage;
export const _getContactSortOrder = (state: ContactState) => state.sortOrder;
export const _getContactSortBy = (state: ContactState) => state.sortBy;
export const _getContactStatus = (state: ContactState) => state.status;
export const _getContactFilterType = (state: ContactState) => state.filterType;
export const _getContactCurrentPage = (state: ContactState) => state.currentPage;
export const _getContactTotalPage = (state: ContactState) => state.totalPages;
export const _getContactTotalCount = (state: ContactState) => state.totalCount;

export const _getIsContactLoadingForPageNumber = (state: ContactState, pageNumber: number) => {
  return state.loadingPagesId.indexOf(pageNumber) !== -1;
};

export const _getIsContactLoadedForPageNumber = (state: ContactState, pageNumber: number) => {
  return state.loadedPagesId.indexOf(pageNumber) !== -1;
};

export const _getAllContactIdsForPageNumber = (state: ContactState, pageNumber: number) => {
  let ids = [];

  if (state.contactIdsForPages[pageNumber]) {
    ids = state.contactIdsForPages[pageNumber];
  }

  return ids;
};
