import { BookingData, OwnerBlockData } from "../../models/calendar-response";
import { Action } from "../action";

export enum CalendarDataActionTypes {
  DATA_INDEX_SUCCESS = "[CALENDAR DATA] Calendar Data Index Success",
}

export class CalendarDataIndexSuccess implements Action {
  readonly type = CalendarDataActionTypes.DATA_INDEX_SUCCESS;

  constructor(public payload: { blocks: OwnerBlockData[], bookings: BookingData[] }) {
  }
}






