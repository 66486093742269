import { SortOrder } from "../../../enums/common.enum";
import { ContactSortType, ContactStatus, ContactType } from "../../../enums/contact.enum";
import { BankInfoModel } from "../../../models/bank-info.model";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { UserFull } from "../../../models/new/user/user-full.model";
import { W9Form } from "../../../models/new/user/w9-form.model";
import { Action } from "../../action";

export enum ContactActionTypes {

  HOMEOWNERS_INDEX_REQUEST = "[CONTACT CONTACT NEW] HomeOwner Index Request",
  HOMEOWNERS_INDEX_SUCCESS = "[CONTACT CONTACT NEW] HomeOwner Index Success",

  ACTIVE_INDEX_REQUEST = "[CONTACT CONTACT NEW] Active Index Request",
  ACTIVE_INDEX_SUCCESS = "[CONTACT CONTACT NEW] Active Index Success",

  IN_ACTIVE_INDEX_REQUEST = "[CONTACT CONTACT NEW] Inactive Index Request",
  IN_ACTIVE_INDEX_SUCCESS = "[CONTACT CONTACT NEW] Inactive Index Success",

  NEXT_PAGE_REQUEST = "[CONTACT CONTACT NEW] Next Page Request",
  NEXT_PAGE_SUCCESS = "[CONTACT CONTACT NEW] Next Page Success",

  SORT_ORDER_CHANGE = "[CONTACT CONTACT NEW] Sort Order Change",
  TYPE_CHANGE = "[CONTACT CONTACT NEW] Type Change",
  STATUS_CHANGE = "[CONTACT CONTACT NEW] Status Change",
  SORT_TYPE_CHANGE = "[CONTACT CONTACT NEW] Sort Type Change",
  PER_PAGE_CHANGE = "[CONTACT CONTACT NEW] Per Page Change",
  PAGE_CHANGE = "[CONTACT CONTACT NEW] Page Change",

  CREATE_REQUEST = "[CONTACT CONTACT NEW] Create Request",
  CREATE_SUCCESS = "[CONTACT CONTACT NEW] Create Success",

  SHOW_REQUEST = "[CONTACT CONTACT NEW] Show Request",
  SHOW_SUCCESS = "[CONTACT CONTACT NEW] Show Success",

  UPDATE_REQUEST = "[CONTACT CONTACT NEW] Update Request",
  UPDATE_SUCCESS = "[CONTACT CONTACT NEW] Update Success",

  DELETE_STORE_REQUEST = "[CONTACT CONTACT NEW] Delete Store Request",

  LISTING_ADD_REQUEST = "[CONTACT CONTACT NEW] Listing add Request",
  LISTING_ADD_SUCCESS = "[CONTACT CONTACT NEW] Listing add Success",

  LISTING_REMOVE_REQUEST = "[CONTACT CONTACT NEW] Listing Remove Request",
  LISTING_REMOVE_SUCCESS = "[CONTACT CONTACT NEW] Listing Remove Success",

  LISTING_ACTIVE_STATUS_REQUEST = "[CONTACT CONTACT NEW] Listing Active Status Request",
  LISTING_ACTIVE_STATUS_SUCCESS = "[CONTACT CONTACT NEW] Listing Active Status Success",

  W9_FORM_UPLOAD_SUCCESS = "[CONTACT CONTACT NEW] W9 FORM UPLOAD SUCCESS",
  W9_FORM_DELETE_SUCCESS = "[CONTACT CONTACT NEW] W9 FORM DELETE SUCCESS",

  BANK_ACCOUNT_CREATE_SUCCESS = "[CONTACT CONTACT NEW] Bank Account Create Success",
  BANK_ACCOUNT_UPDATE_SUCCESS = "[CONTACT CONTACT NEW] Bank Account Update Success",
  BANK_ACCOUNT_DELETE_SUCCESS = "[CONTACT CONTACT NEW] Bank Account Delete Success",
}

export class ContactSortOrderChange implements Action {
  readonly type = ContactActionTypes.SORT_ORDER_CHANGE;

  constructor(public payload: SortOrder) {
  }
}

export class ContactSortTypeChange implements Action {
  readonly type = ContactActionTypes.SORT_TYPE_CHANGE;

  constructor(public payload: ContactSortType) {
  }
}

export class ContactStatusChange implements Action {
  readonly type = ContactActionTypes.STATUS_CHANGE;

  constructor(public payload: ContactStatus) {
  }
}

export class ContactTypeChange implements Action {
  readonly type = ContactActionTypes.TYPE_CHANGE;

  constructor(public payload: ContactType) {
  }
}

export class ContactPerPageChange implements Action {
  readonly type = ContactActionTypes.PER_PAGE_CHANGE;

  constructor(public payload: number) {
  }
}

export class ContactCurrentPageChange implements Action {
  readonly type = ContactActionTypes.PAGE_CHANGE;

  constructor(public payload: number) {
  }
}

export class ContactNextPageRequest implements Action {
  readonly type = ContactActionTypes.NEXT_PAGE_REQUEST;

  constructor(public payload: number) {
  }
}

export class ContactNextPageSuccess implements Action {
  readonly type = ContactActionTypes.NEXT_PAGE_SUCCESS;

  constructor(public payload: { contacts: UserCompact[], currentPage: number, totalPages: number, totalCount: number }) {
  }
}

export class ContactActiveIndexRequest implements Action {
  readonly type = ContactActionTypes.ACTIVE_INDEX_REQUEST;
}

export class ContactActiveIndexSuccess implements Action {
  readonly type = ContactActionTypes.ACTIVE_INDEX_SUCCESS;

  constructor(public payload: UserCompact[]) {
  }
}

export class ContactInActiveIndexRequest implements Action {
  readonly type = ContactActionTypes.IN_ACTIVE_INDEX_REQUEST;
}

export class ContactInActiveIndexSuccess implements Action {
  readonly type = ContactActionTypes.IN_ACTIVE_INDEX_SUCCESS;

  constructor(public payload: UserCompact[]) {
  }
}

export class HomeOwnerIndexRequest implements Action {
  readonly type = ContactActionTypes.HOMEOWNERS_INDEX_REQUEST;
}

export class HomeOwnerIndexSuccess implements Action {
  readonly type = ContactActionTypes.HOMEOWNERS_INDEX_SUCCESS;

  constructor(public payload: UserCompact[]) {
  }
}

export class ContactCreateRequest implements Action {
  readonly type = ContactActionTypes.CREATE_REQUEST;
}

export class ContactCreateSuccess implements Action {
  readonly type = ContactActionTypes.CREATE_SUCCESS;

  constructor(public payload: UserFull) {
  }
}

export class ContactShowRequest implements Action {
  readonly type = ContactActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {
  }
}

export class ContactShowSuccess implements Action {
  readonly type = ContactActionTypes.SHOW_SUCCESS;

  constructor(public payload: UserFull) {
  }
}

export class ContactUpdateRequest implements Action {
  readonly type = ContactActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class ContactUpdateSuccess implements Action {
  readonly type = ContactActionTypes.UPDATE_SUCCESS;

  constructor(public payload: UserFull) {
  }
}

export class ContactDeleteFromStoreRequest implements Action {
  readonly type = ContactActionTypes.DELETE_STORE_REQUEST;

  constructor(public payload: number[]) {
  }
}

export class ContactAddToListingRequest implements Action {
  readonly type = ContactActionTypes.LISTING_ADD_REQUEST;

  constructor(public payload: { contactId: number, listingId: number }) {
  }
}

export class ContactAddToListingSuccess implements Action {
  readonly type = ContactActionTypes.LISTING_ADD_SUCCESS;

  constructor(public payload: { contactId: number, listingId: number }) {
  }
}

export class ContactRemoveFromListingRequest implements Action {
  readonly type = ContactActionTypes.LISTING_REMOVE_REQUEST;

  constructor(public payload: { contactId: number, listingId: number }) {
  }
}

export class ContactRemoveFromListingSuccess implements Action {
  readonly type = ContactActionTypes.LISTING_REMOVE_SUCCESS;

  constructor(public payload: { contactId: number, listingId: number }) {
  }
}

export class ContactActiveStatusRequest implements Action {
  readonly type = ContactActionTypes.LISTING_ACTIVE_STATUS_REQUEST;

  constructor(public payload: { contactId: number, active: boolean }) {
  }
}

export class ContactActiveStatusSuccess implements Action {
  readonly type = ContactActionTypes.LISTING_ACTIVE_STATUS_SUCCESS;

  constructor(public payload: { contactId: number, active: boolean }) {
  }
}

export class ContactW9FormUploadSuccess implements Action {
  readonly type = ContactActionTypes.W9_FORM_UPLOAD_SUCCESS;

  constructor(public payload: W9Form) {
  }
}

export class ContactW9FormDeleteSuccess implements Action {
  readonly type = ContactActionTypes.W9_FORM_DELETE_SUCCESS;

  constructor(public payload: {contactId: number, formId: number}) {
  }
}

export class ContactBankAccountCreateSuccess implements Action {
  readonly type = ContactActionTypes.BANK_ACCOUNT_CREATE_SUCCESS;

  constructor(public payload: BankInfoModel) {
  }
}

export class ContactBankAccountUpdateSuccess implements Action {
  readonly type = ContactActionTypes.BANK_ACCOUNT_UPDATE_SUCCESS;

  constructor(public payload: BankInfoModel) {
  }
}

export class ContactBankAccountDeleteSuccess implements Action {
  readonly type = ContactActionTypes.BANK_ACCOUNT_DELETE_SUCCESS;

  constructor(public payload: { bankAccountId: number, contactId: number }) {
  }
}
