import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import * as _ from "lodash";
import { isNullOrUndefined } from "util";

import { Action } from "../../../actions/action";
import { TaskCommentActionTypes } from "../../../actions/new/tasks/comment";
import { TaskActionTypes } from "../../../actions/new/tasks/task";
import { getDateObj } from "../../../utils/calendar-utils";
import { PerPage, SortOrder } from "../../../enums/common.enum";
import { TaskPaymentBy, TaskSortBy, TaskStatus, TaskType } from "../../../enums/task.enum";
import { TaskAttachment } from "../../../models/new/tasks/task-attachment.model";
import { TaskCompact } from "../../../models/new/tasks/task-compact.model";
import { TaskFull } from "../../../models/new/tasks/task-full.model";

export interface TaskState extends EntityState<TaskFull | TaskCompact> {
  isDeleting: boolean;
  isDeleted: boolean;

  currentPage: number;
  totalPages: number;
  totalCount: number;

  loadingPagesId: number[];
  loadedPagesId: number[];
  taskIdsForPages: { [key: number]: number[] };

  fullLoadingIds: number[];
  fullLoadedIds: number[];

  filterType: TaskType;
  filterCategory: string[]; // null = Show All
  filterStatus: string[]; // null = Show All
  filterAssigneeIds: number[];
  filterEmployeeIds: number[];
  filterListingIds: number[];
  filterCreatedBy: number[];
  filterWhoWillPay: TaskPaymentBy[];
  filterPrice: boolean;

  tasksNotExist: number[];

  perPage: PerPage;

  sortBy: TaskSortBy;
  sortOrder: SortOrder;

  selectedTasks: TaskCompact[];
  open_property: boolean;
  start_date?: Date;
  end_date?: Date;
  offset: string;
}

export const taskAdapter: EntityAdapter<TaskFull | TaskCompact> = createEntityAdapter<TaskFull | TaskCompact>({
  selectId: (task: TaskCompact | TaskFull) => task.id,
});

export const initialState: TaskState = taskAdapter.getInitialState({
  isDeleting: false,
  isDeleted: false,

  currentPage: 1,
  totalPages: 0,
  totalCount: 0,

  loadingPagesId: [],
  loadedPagesId: [],
  taskIdsForPages: {},

  fullLoadingIds: [],
  fullLoadedIds: [],

  filterType: TaskType.TODAY,
  filterCategory: [],
  filterStatus: [],
  filterAssigneeIds: [],
  filterEmployeeIds: [],
  filterListingIds: [],
  filterCreatedBy: [],
  filterWhoWillPay: [],
  filterPrice: false,

  tasksNotExist: [],
  offset: "due_date",
  open_property: false,

  perPage: PerPage.FIFTY,

  sortBy: TaskSortBy.DUE_ON,
  sortOrder: SortOrder.ASC,

  selectedTasks: [],
});

export function taskReducer(state: TaskState = initialState, action: Action): TaskState {
  switch (action.type) {

    case TaskActionTypes.CHANGE_PAYMENT_BY: {
      const filter = action.payload as TaskPaymentBy[];
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        filterWhoWillPay: filter
      };
    }

    case TaskActionTypes.CHANGE_START_DATE: {
      const startDate = action.payload as Date;
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        start_date: startDate
      }
    }

    case TaskActionTypes.CHANGE_END_DATE: {
      const endDate = action.payload as Date;
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        end_date: endDate
      }
    }

    case TaskActionTypes.CHANGE_OFFSET: {
      const offset = action.payload as string;
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        offset: offset
      }
    }

    case TaskActionTypes.CHANGE_OPEN_PROPERTY: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        open_property: action.payload
      };
    }

    case TaskActionTypes.CHANGE_PRICE_FILTER: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        filterPrice: action.payload
      };
    }

    case TaskActionTypes.RESET_DATA: {
      return initialState;
    }

    case TaskActionTypes.RESET_TASK: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        fullLoadingIds: [],
        fullLoadedIds: [],
        totalPages: 0,
        totalCount: 0,
        selectedTasks: []
      };
    }

    case TaskActionTypes.NEXT_PAGE_REQUEST: {
      const pageId = action.payload as number;

      let loadingPagesId = state.loadingPagesId;
      const loadingIndex = _.indexOf(loadingPagesId, pageId);

      // Adding if not present
      if (loadingIndex === -1) {
        loadingPagesId = [
          ...loadingPagesId,
          pageId
        ];
      }

      let loadedPagesId = state.loadedPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, pageId);

      // Removing if already added
      if (loadedIndex !== -1) {
        loadedPagesId = _.remove(loadedPagesId, pageId);
      }

      return {
        ...state,
        loadingPagesId: loadingPagesId,
        loadedPagesId: loadedPagesId
      };
    }

    case TaskActionTypes.NEXT_PAGE_SUCCESS: {
      const payload = action.payload as {
        tasks: TaskCompact[],
        currentPage: number,
        totalPages: number,
        totalCount: number,
      };

      let loadingPageIds = state.loadingPagesId;
      const loadingIndex = _.indexOf(loadingPageIds, payload.currentPage);
      console.log("Loading Page Id", loadingIndex, loadingPageIds);

      // Removing if loading
      if (loadingIndex !== -1) {
        loadingPageIds = _.remove(loadingPageIds, payload.currentPage);
      }

      let loadedPagesId = state.loadedPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, payload.currentPage);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        loadedPagesId = [
          ...loadedPagesId,
          payload.currentPage
        ];
      }

      const changes = [];
      for (const task of payload.tasks) {
        changes.push({
          id: task.id,
          changes: task
        });
      }

      let fullLoadedIds = state.fullLoadedIds || [];
      payload.tasks.map(t => t.id).forEach(id => {
        const fullLoadedIndex = _.indexOf(fullLoadedIds, id);

        if (fullLoadedIndex !== -1) {
          fullLoadedIds = _.remove(fullLoadedIds, id);
        }

      });

      const updatedState = taskAdapter.updateMany(changes, state);

      return taskAdapter.addMany(payload.tasks, {
        ...updatedState,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
        totalCount: payload.totalCount,
        loadingPagesId: loadingPageIds,
        loadedPagesId: loadedPagesId,
        fullLoadedIds: fullLoadedIds,
        taskIdsForPages: {
          ...updatedState.taskIdsForPages,
          [payload.currentPage]: payload.tasks.map(t => t.id)
        }
      });
    }

    case TaskActionTypes.CREATE_REQUEST: {
      return state;
    }

    case TaskActionTypes.CREATE_SUCCESS: {
      const updatedTask = action.payload as TaskFull;

      return taskAdapter.updateOne({
        id: updatedTask.id,
        changes: updatedTask
      }, taskAdapter.addOne(updatedTask, state));
    }

    case TaskActionTypes.SHOW_REQUEST: {
      const taskId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, taskId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          taskId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, taskId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, taskId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case TaskActionTypes.SHOW_SUCCESS: {
      const task = action.payload as TaskFull;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, task.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, task.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, task.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          task.id
        ];
      }

      const addedState = taskAdapter.addOne(task, state);

      return taskAdapter.updateOne({
        id: task.id,
        changes: task
      }, {
        ...addedState,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      });
    }

    case TaskActionTypes.UPDATE_REQUEST: {
      return state;
    }

    case TaskActionTypes.UPDATE_SUCCESS: {
      const updatedTask = action.payload as TaskFull;

      return taskAdapter.updateOne({
        id: updatedTask.id,
        changes: updatedTask
      }, taskAdapter.addOne(updatedTask, state));
    }

    case TaskActionTypes.CLEAR_FULL_LOADING_LOADED: {
      return {
        ...state,
        fullLoadedIds: [],
        fullLoadingIds: []
      };
    }

    case TaskActionTypes.CHANGE_TYPE: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        filterType: action.payload
      };
    }

    case TaskActionTypes.CHANGE_CATEGORY: {
      if (state.filterCategory !== action.payload) {
        return {
          ...state,
          currentPage: 1,
          loadingPagesId: [],
          loadedPagesId: [],
          taskIdsForPages: {},
          filterCategory: action.payload
        };
      }
      return state;
    }

    case TaskActionTypes.CHANGE_STATUS: {
      if (state.filterStatus !== action.payload) {
        return {
          ...state,
          currentPage: 1,
          loadingPagesId: [],
          loadedPagesId: [],
          taskIdsForPages: {},
          filterStatus: action.payload
        };
      }
      return state;
    }

    case TaskActionTypes.CHANGE_ASSIGNEE: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        filterAssigneeIds: action.payload
      };
    }

    case TaskActionTypes.CHANGE_EMPLOYEE: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        filterEmployeeIds: action.payload
      };
    }

    case TaskActionTypes.CHANGE_LISTINGS: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        filterListingIds: action.payload
      };
    }

    case TaskActionTypes.CHANGE_CREATED_BY: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        filterCreatedBy: action.payload
      };
    }

    case TaskActionTypes.CHANGE_PER_PAGE: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        perPage: action.payload
      };
    }

    case TaskActionTypes.CHANGE_SORT_BY: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        sortBy: action.payload
      };
    }

    case TaskActionTypes.CHANGE_SORT_ORDER: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
        sortOrder: action.payload
      };
    }

    case TaskActionTypes.CHANGE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload
      };
    }

    case TaskActionTypes.TASK_SELECT: {
      const task = action.payload;

      let tasks = state.selectedTasks;
      const index = _.indexOf(tasks, task);

      if (index === -1) {
        tasks = [
          ...tasks,
          task
        ];
      }

      return {
        ...state,
        selectedTasks: tasks
      };
    }

    case TaskActionTypes.TASK_DESELECT: {
      const taskId = action.payload;

      let tasks = state.selectedTasks;
      const index = _.indexOf(tasks.map(t => t.id), taskId);
      console.log(index);
      console.log(tasks);
      if (index !== -1) {
        tasks = [
          ...tasks.slice(0, index),
          ...tasks.slice(index + 1)
        ];
        console.log(tasks);
      }
      console.log(tasks);

      return {
        ...state,
        selectedTasks: tasks
      };
    }

    case TaskActionTypes.TASK_DESELECT_ALL: {
      return {
        ...state,
        selectedTasks: []
      };
    }

    case TaskActionTypes.BULK_UPDATE_REQUEST: {
      return state;
    }

    case TaskActionTypes.BULK_UPDATE_SUCCESS: {
      const payload = action.payload.data;
      const updates = payload?.map(task => ({id: task.id, changes: task}));
      return taskAdapter.updateMany(updates, state);
    }

    case TaskActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isDeleting: true
      };
    }

    case TaskActionTypes.DELETE_SUCCESS: {
      const taskId = +action.payload;
      let loadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(state.fullLoadedIds, taskId);

      if (loadedIndex !== -1) {
        loadedIds = [
          ...loadedIds.slice(0, loadedIndex),
          ...loadedIds.slice(loadedIndex + 1)
        ];
      }

      let taskIdsForPages = state.taskIdsForPages;
      const keys = Object.keys(taskIdsForPages);
      console.log("Keys", keys);
      const taskPageIndex = _.findIndex(keys, (key) => {
        const index = _.indexOf(taskIdsForPages[key], taskId);
        if (index !== -1) {
          return index;
        }
      });

      if (taskPageIndex !== -1) {
        const key = keys[taskPageIndex];
        let page = taskIdsForPages[key];
        const pageIndex = _.indexOf(page, taskId);

        if (pageIndex !== -1) {
          page = [
            ...page.slice(0, pageIndex),
            ...page.slice(pageIndex + 1)
          ];

          taskIdsForPages = {
            ...taskIdsForPages,
            [key]: page
          };

        }
      }

      return taskAdapter.removeOne(
        taskId,
        {
          ...state,
          fullLoadedIds: loadedIds,
          taskIdsForPages: taskIdsForPages,
          isDeleting: false,
          isDeleted: true
        }
      );
    }

    // case TaskActionTypes.ATTACHMENT_DELETE_REQUEST: {
    //   return state;
    // }

    case TaskActionTypes.ATTACHMENT_DELETE_SUCCESS: {
      const payload = action.payload as { property_id: string, image_id: string };

      // This check is required for deleting a temporary attachment
      if (isNullOrUndefined(payload.property_id)) {
        return state;
      }

      const task = taskAdapter.getSelectors().selectEntities(state)[+payload.property_id] as TaskFull;
      let newImages = [];
      const imageIndex = _.findIndex(task.attachments.data, (image: TaskAttachment) => {
        return image.id === +payload.image_id;
      });

      newImages = [
        ...task.attachments.data.slice(0, imageIndex),
        ...task.attachments.data.slice(imageIndex + 1)
      ];

      return taskAdapter.updateOne({
        id: +payload.property_id,
        changes: {
          attachments: {
            data: newImages
          }
        }
      }, state);
    }
    case TaskActionTypes.ADD_TASK_TO_NOT_EXIST: {
      const taskId = action.payload as number;
      const data = state.tasksNotExist;
      data.push(taskId);
      return {
        ...state,
        tasksNotExist: data
      };
    }

    case TaskCommentActionTypes.CREATE_SUCCESS: {
      const taskId = action.payload.taskId as number;
      return taskAdapter.updateOne({
        id: taskId,
        changes: {comments_count: (state.entities[taskId].comments_count + 1)}
      }, state);
    }

    case TaskCommentActionTypes.DELETE_SUCCESS: {
      const taskId = action.payload.taskId as number;
      let commentCount = state.entities[taskId].comments_count;
      if (commentCount > 0) {
        commentCount -= 1;
      }
      return taskAdapter.updateOne({id: taskId, changes: {comments_count: commentCount}}, state);
    }

    case TaskActionTypes.SET_FILTERS: {
      return {
        ...state,
        ...action.payload,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        taskIdsForPages: {},
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsTaskLoadingForPageNumber = (state: TaskState, pageNumber: number) => {
  return state.loadingPagesId.indexOf(pageNumber) !== -1;
};

export const _getIsTaskLoadedForPageNumber = (state: TaskState, pageNumber: number) => {
  return state.loadedPagesId.indexOf(pageNumber) !== -1;
};

export const _getAllTaskIdsForPageNumber = (state: TaskState, pageNumber: number) => {
  let ids = [];

  if (state.taskIdsForPages[pageNumber]) {
    ids = state.taskIdsForPages[pageNumber];
  }

  return ids;
};

export const _getIsFullTaskLoading = (state: TaskState, taskId: number) => {
  return state.fullLoadingIds.indexOf(taskId) !== -1;
};
export const _getIsFullTaskLoaded = (state: TaskState, taskId: number) => {
  return state.fullLoadedIds.indexOf(taskId) !== -1;
};

export const _getTaskStartDate = (state: TaskState) => state.start_date;
export const _getTaskEndDate = (state: TaskState) => state.end_date;
export const _getTaskOffset = (state: TaskState) => state.offset;
export const _getTaskFilterType = (state: TaskState) => state.filterType;
export const _getTaskFilterCategory = (state: TaskState) => state.filterCategory;
export const _getTaskFilterStatus = (state: TaskState) => state.filterStatus;
export const _getTaskFilterAssigneeIds = (state: TaskState) => state.filterAssigneeIds;
export const _getTaskFilterEmployeeIds = (state: TaskState) => state.filterEmployeeIds;
export const _getTaskFilterListingIds = (state: TaskState) => state.filterListingIds;
export const _getTaskFilterCreatedBy = (state: TaskState) => state.filterCreatedBy;
export const _getTaskFilterWhoWillPay = (state: TaskState) => state.filterWhoWillPay;
export const _getTaskFilterPrice = (state: TaskState) => state.filterPrice;
export const _getTaskOpenProperty = (state: TaskState) => state.open_property;

export const _getTaskExist = (taskId: number, state: TaskState) => {
  return state.tasksNotExist.indexOf(taskId) !== -1;
};

export const _getTaskPerPage = (state: TaskState) => state.perPage;

export const _getTaskCurrentPage = (state: TaskState) => state.currentPage;
export const _getTaskTotalPages = (state: TaskState) => state.totalPages;
export const _getTaskTotalCount = (state: TaskState) => state.totalCount;

export const _getTaskSortBy = (state: TaskState) => state.sortBy;
export const _getTaskSortOrder = (state: TaskState) => state.sortOrder;

export const _getSelectedTaskIds = (state: TaskState) => state.selectedTasks.map(t => t.id);
export const _getSelectedTaskStatus = (state: TaskState) => state.selectedTasks.map(t => t.status);

export const _getTaskFilters = (state: TaskState) => {
  return {
    start_date: state.start_date,
    end_date: state.end_date,
    offset: state.offset,
    filterType: state.filterType,
    filterCategory: state.filterCategory,
    filterStatus: state.filterStatus,
    filterAssigneeIds: state.filterAssigneeIds,
    filterEmployeeIds: state.filterEmployeeIds,
    filterListingIds: state.filterListingIds,
    filterCreatedBy: state.filterCreatedBy,
    filterWhoWillPay: state.filterWhoWillPay,
    filterPrice: state.filterPrice,
    sortBy: state.sortBy,
    sortOrder: state.sortOrder
  };
};
