import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { AutomatedMessageFull } from "../../../models/new/automation/automated-messages-full.model";
import { AutomatedMessageCompact } from "../../../models/new/automation/automated-messages-compact.model";
import { Action } from "../../../actions/action";
import { AutomatedMessageActionTypes } from "../../../actions/new/automation/automated-messages";
import * as _ from "lodash";

export interface AutomatedMessageState extends EntityState<AutomatedMessageFull | AutomatedMessageCompact> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const automatedMessageAdapter: EntityAdapter<AutomatedMessageCompact | AutomatedMessageFull> = createEntityAdapter<AutomatedMessageCompact | AutomatedMessageFull>({
  selectId: (automatedMessage: AutomatedMessageCompact | AutomatedMessageFull) => automatedMessage.id,
  sortComparer: sortByType
});

export function sortByTitle(first: AutomatedMessageCompact, second: AutomatedMessageCompact): number {
  if (first.title > second.title) {
    return 1;
  }

  if (first.title < second.title) {
    return -1;
  }

  return 0;
}

export function sortByType(first: AutomatedMessageCompact, second: AutomatedMessageCompact): number {
  if (first.offset_reference > second.offset_reference) {
    return 1;
  }

  if (first.offset_reference < second.offset_reference) {
    return -1;
  }

  return 0;
}

export const initialState: AutomatedMessageState = automatedMessageAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function automatedMessageReducer(state: AutomatedMessageState = initialState, action: Action): AutomatedMessageState {
  switch (action.type) {
    case AutomatedMessageActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true
      }
    }

    case AutomatedMessageActionTypes.INDEX_SUCCESS: {
      const automatedMessage = action.payload as AutomatedMessageCompact[];
      return automatedMessageAdapter.addAll(automatedMessage, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case AutomatedMessageActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case AutomatedMessageActionTypes.CREATE_SUCCESS: {
      const automatedMessage = action.payload as AutomatedMessageFull;
      return automatedMessageAdapter.addOne(automatedMessage, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case AutomatedMessageActionTypes.SHOW_REQUEST: {
      const automatedMessageId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, automatedMessageId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds, automatedMessageId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, automatedMessageId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, automatedMessageId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case AutomatedMessageActionTypes.SHOW_SUCCESS: {
      const automatedMessage = action.payload as AutomatedMessageFull;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, automatedMessage.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, automatedMessage.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, automatedMessage.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          automatedMessage.id
        ];
      }

      const changes = automatedMessageAdapter.updateOne({
        id: automatedMessage.id,
        changes: automatedMessage
      }, state);

      return automatedMessageAdapter.addOne(automatedMessage, {
        ...changes,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      });
    }

    case AutomatedMessageActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case AutomatedMessageActionTypes.UPDATE_SUCCESS: {
      const automatedMessage = action.payload as AutomatedMessageFull;
      return automatedMessageAdapter.updateOne({
        id: automatedMessage.id,
        changes: automatedMessage
      }, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case AutomatedMessageActionTypes.DELETE_SUCCESS: {
      const id = action.payload as number;


      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, id);

      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, id);
      }

      return automatedMessageAdapter.removeOne(id, {
        ...state,
        fullLoadedIds: fullyLoadedIds
      })
    }


    default: {
      return state;
    }

  }
}


export const _getIsAutomatedMessageLoading = (state: AutomatedMessageState) => state.isLoading;
export const _getIsAutomatedMessageLoaded = (state: AutomatedMessageState) => state.isLoaded;

export const _getIsFullAutomatedMessageLoading = (state: AutomatedMessageState, messageId: number) => {
  return state.fullLoadingIds.indexOf(messageId) !== -1;
};
export const _getIsFullAutomatedMessageLoaded = (state: AutomatedMessageState, messageId: number) => {
  return state.fullLoadedIds.indexOf(messageId) !== -1;
};
