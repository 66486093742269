import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { CannedResponseGetResponse } from "../models/responses/canned-response-get.response";
import { CannedResponseShowResponse } from "../models/responses/canned-response-show.response";
import { Injectable } from "@angular/core";
import { CannedResponseFull } from "../models/new/automation/canned-response-full.model";
import { __HTTPResponseType } from "../enums/common.enum";

@Injectable()
export class CannedResponseService {

  constructor(private apiService: ApiService) {
  }

  getCannedResponses(): Observable<CannedResponseGetResponse> {
    return this.apiService.get<CannedResponseGetResponse>("/admin/saved-messages", true);
  }

  showCannedResponse(cannedResponseId: number): Observable<CannedResponseShowResponse> {
    return this.apiService.get<CannedResponseShowResponse>("/admin/saved-messages/" + cannedResponseId, true);
  }

  createCannedResponse(data: Partial<CannedResponseFull>): Observable<CannedResponseShowResponse> {
    return this.apiService.post<CannedResponseShowResponse>("/admin/saved-messages", true, data);
  }

  updateCannedResponse(cannedResponseId: number, data: Partial<CannedResponseFull>): Observable<CannedResponseShowResponse> {
    return this.apiService.put<CannedResponseShowResponse>("/admin/saved-messages/" + cannedResponseId, true, data);
  }

  deleteCannedResponse(cannedResponseId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/saved-messages/" + cannedResponseId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}
