import {Component, Input, OnInit} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {FormControl, FormGroup} from "@angular/forms";
import {ListingRepository} from "../../../repository/listing.repository";
import {ListingFull} from "../../../models/new/listing/listing-full.model";

@Component({
  selector: "sd-listing-private-notes-popup",
  template: `
    <sd-modal-popup-layout title="Private Notes">
      <form style="height: calc(100vh - 160px); overflow-y: auto" [formGroup]="formGroup" (ngSubmit)="formGroup.valid && saveButtonCLicked()">
        <div fxLayout="column">
          <mat-form-field [color]="'accent'" style="width: 100%">
            <input matInput placeholder="KeyBox Number" formControlName="keybox_number">
          </mat-form-field>

          <mat-form-field [color]="'accent'" style="width: 100%">
            <input matInput placeholder="Contractors Lock Box" formControlName="contractors_lockbox">
          </mat-form-field>

          <mat-form-field [color]="'accent'" style="width: 100%">
            <input matInput placeholder="House Secrets" formControlName="house_secrets">
          </mat-form-field>

          <mat-form-field [color]="'accent'" style="width: 100%">
            <input matInput placeholder="Google Photos Link" formControlName="google_photos_link">
          </mat-form-field>

          <h4 style="padding: 20px 0">Utility Information</h4>

          <mat-form-field [color]="'accent'" style="width: 100%">
            <textarea rows="8" matInput placeholder="Thermostat Location" formControlName="thermostat_locations"></textarea>
          </mat-form-field>

          <mat-form-field [color]="'accent'" style="width: 100%">
            <input matInput placeholder="AC Filter Size" formControlName="ac_filter_size">
          </mat-form-field>

          <mat-form-field [color]="'accent'" style="width: 100%">
            <textarea rows="8" matInput placeholder="AC Filter Location" formControlName="ac_filter_location"></textarea>
          </mat-form-field>

          <mat-form-field [color]="'accent'" style="width: 100%">
            <textarea rows="8" matInput placeholder="WiFi/Cable acct." formControlName="wifi_cable_acct"></textarea>
          </mat-form-field>

          <mat-form-field [color]="'accent'" style="width: 100%">
            <textarea rows="8" matInput placeholder="Break Box Location" formControlName="break_box_location"></textarea>
          </mat-form-field>

          <mat-form-field [color]="'accent'" style="padding-top: 20px">
          <textarea
            matInput
            placeholder="Private Notes"
            rows="8"
            formControlName="private_notes">
          </textarea>
          </mat-form-field>

        </div>

        <div fxLayoutAlign="end center">
          <mat-spinner *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <button mat-raised-button color="accent">Save</button>
        </div>
      </form>
    </sd-modal-popup-layout>
  `,
  styles: [`
    .halfWidth {
      width: 48%;
    }

    textarea {
      resize: none;
    }

    mat-spinner {
      width: 30px;
      height: 30px;
    }
  `]
})

export class ListingPrivateNotesPopupComponent implements OnInit {
  @Input() listing: ListingFull;
  formGroup: FormGroup;
  privateNotes: FormControl;
  keyBoxNumber: FormControl;
  thermostatLocations: FormControl;
  acFilterSize: FormControl;
  acFilterLocation: FormControl;
  wifiCable: FormControl;
  breakBox: FormControl;
  contractorsLocation: FormControl;
  houseSecrets: FormControl;
  photosLink: FormControl;

  isSaving: boolean = false;

  constructor(private listingRepo: ListingRepository, private dialog: MatDialog) {
    this.privateNotes = new FormControl();
    this.keyBoxNumber = new FormControl();
    this.thermostatLocations = new FormControl();
    this.acFilterSize = new FormControl();
    this.acFilterLocation = new FormControl();
    this.wifiCable = new FormControl();
    this.breakBox = new FormControl();
    this.contractorsLocation = new FormControl();
    this.houseSecrets = new FormControl();
    this.photosLink = new FormControl();

    this.formGroup = new FormGroup({
      private_notes: this.privateNotes,
      keybox_number: this.keyBoxNumber,
      thermostat_locations: this.thermostatLocations,
      ac_filter_size: this.acFilterSize,
      ac_filter_location: this.acFilterLocation,
      wifi_cable_acct: this.wifiCable,
      break_box_location: this.breakBox,
      contractors_lockbox: this.contractorsLocation,
      house_secrets: this.houseSecrets,
      google_photos_link: this.photosLink,
    });
  }

  ngOnInit() {
    this.privateNotes.setValue(this.listing.private_notes);
    this.keyBoxNumber.setValue(this.listing.keybox_number);
    this.thermostatLocations.setValue(this.listing.thermostat_locations);
    this.acFilterSize.setValue(this.listing.ac_filter_size);
    this.acFilterLocation.setValue(this.listing.ac_filter_location);
    this.wifiCable.setValue(this.listing.wifi_cable_acct);
    this.breakBox.setValue(this.listing.break_box_location);
    this.contractorsLocation.setValue(this.listing.contractors_lockbox);
    this.houseSecrets.setValue(this.listing.house_secrets);
    this.photosLink.setValue(this.listing.google_photos_link);
  }

  saveButtonCLicked() {
    this.isSaving = true;
    const data = this.formGroup.value;
    console.log(data);
    this.listingRepo.updateListingDetails(data, String(this.listing.id)).subscribe((listing) => {
      this.isSaving = false;
      this.dialog.closeAll();
    }, () => {
      this.isSaving = false;
    });
  }
}
