import {map, take} from "rxjs/operators";
import {
  getAllNeighbourhoodDrafts,
  getFullNeighbourhoodDraftById,
  getIsFullNeighbourhoodDraftLoaded,
  getIsFullNeighbourhoodDraftLoading,
  getIsNeighbourhoodDraftLoaded,
  getIsNeighbourhoodDraftLoading,
  State
} from "../reducers";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {NeighbourhoodDraft} from "../models/new/neighbourhood-drafts.model";
import {NeighbourhoodDraftsService} from "../services/neighbourhood-drafts.service";
import {
  SavedNeighbourhoodDraftCreateRequest,
  SavedNeighbourhoodDraftCreateSuccess,
  SavedNeighbourhoodDraftDeleteRequest,
  SavedNeighbourhoodDraftDeleteSuccess,
  SavedNeighbourhoodDraftIndexRequest,
  SavedNeighbourhoodDraftIndexSuccess,
  SavedNeighbourhoodDraftUpdateRequest,
  SavedNeighbourhoodDraftUpdateSuccess
} from "../actions/new/setttings/drafts/saved-neighbourhood-drafts";
import {SavedNeighbourhoodDraft} from "../models/new/drafts/saved-neighbourhood-draft.model";

;

@Injectable()
export class NeighbourhoodDraftRepository {

  constructor(private store: Store<State>,
              private neighbourhoodDraftService: NeighbourhoodDraftsService) {
  }

  /**
   * Loading & Loaded
   */
  getIsNeighbourhoodDraftLoading(): Observable<boolean> {
    return this.store.select(getIsNeighbourhoodDraftLoading);
  }

  getIsNeighbourhoodDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsNeighbourhoodDraftLoaded);
  }

  getIsFullNeighbourhoodDraftLoading(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullNeighbourhoodDraftLoading(state, draftId));
  }

  getIsFullNeighbourhoodDraftLoaded(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullNeighbourhoodDraftLoaded(state, draftId));
  }

  /**
   * Service Methods
   */
  getAllNeighbourhoodDrafts(force: boolean): Observable<SavedNeighbourhoodDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsNeighbourhoodDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsNeighbourhoodDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new SavedNeighbourhoodDraftIndexRequest());

      this.neighbourhoodDraftService.getDrafts().subscribe(response => {
        this.store.dispatch(new SavedNeighbourhoodDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(getAllNeighbourhoodDrafts).pipe(map(t => t as SavedNeighbourhoodDraft[]));
  }

  getNeighbourhoodDraftById(draftId: number): Observable<SavedNeighbourhoodDraft> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsNeighbourhoodDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsNeighbourhoodDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new SavedNeighbourhoodDraftIndexRequest());

      this.neighbourhoodDraftService.getDrafts().subscribe(response => {
        this.store.dispatch(new SavedNeighbourhoodDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(state => getFullNeighbourhoodDraftById(state, draftId)).pipe(
      map(t => t as NeighbourhoodDraft));
  }


  createNeighbourhoodDraft(data: Partial<NeighbourhoodDraft>): Observable<SavedNeighbourhoodDraft> {
    this.store.dispatch(new SavedNeighbourhoodDraftCreateRequest());
    return this.neighbourhoodDraftService.createDraft(data).pipe(map(res => {
      this.store.dispatch(new SavedNeighbourhoodDraftCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateNeighbourhoodDraft(draftId: number, data: Partial<NeighbourhoodDraft>): Observable<SavedNeighbourhoodDraft> {
    this.store.dispatch(new SavedNeighbourhoodDraftUpdateRequest(draftId));
    return this.neighbourhoodDraftService.updateDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new SavedNeighbourhoodDraftUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deleteNeighbourhoodDraft(draftId: number): Observable<null> {
    this.store.dispatch(new SavedNeighbourhoodDraftDeleteRequest(draftId));
    return this.neighbourhoodDraftService.deleteDraft(draftId).pipe(map(res => {
      this.store.dispatch(new SavedNeighbourhoodDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}

