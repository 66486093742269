export enum FeedType {
  BOOKING_STATUS_CHANGED = "booking_status_changed",
  BOOKING_AUTOMATION_MESSAGE_SENT = "booking_automation_message_sent",
  PROPERTY_CREATED = "property_created",
  MANUAL_TASK_ADDED = "manual_task_added",
  TASK_COMMENT_LEFT = "task_comment_left",
  TASK_COMMENT_UPDATED = "task_comment_updated",
  TASK_COMMENT_MENTION = "task_comment_mention",
  TASK_ASSIGNEE_CHANGED = "task_assignee_changed",
  TASK_ATTACHMENT_ADDED = "task_attachment_added",
  AUTO_TASK_ADDED = "auto_task_added",
  BOOKING_CREATED = "booking_created",
  BOOKING_ALTERED = "booking_altered",
  BOOKING_INQUIRY_CREATED = "booking_inquiry_created",
  BOOKING_PRE_APPROVED = "booking_pre_approved",
  AUTO_TASK_COMPLETED = "auto_task_completed",
  MANUAL_TASK_COMPLETED = "manual_task_completed",
  HEALTH_SCORE_FAILED = "health_score_failed",
  BOOKING_CANCELLED = "booking_cancelled",
  RESOLUTION_CENTER_PAYMENTS = "resolution_center_payments",
  PROPERTY_CHANGED = "property_changed",
  AIRBNB_ALTERATION_REQUEST = "airbnb_alteration_request",
  AMOUNT_REFUNDED = "amount_refunded",
  AIRBNB_SUPPORT_EMAIL = "airbnb_support_email",
}

export enum FeedFilterType {
  MANUAL_TASKS = "manual_tasks",
  COMMENTS = "comments",
  AUTOMATION = "automation",
  AUTO_TASKS = "auto_tasks",
  INQUIRY = "inquiry",
  MENTIONS = "mentions",
  CREATED_BOOKINGS = "created_bookings",
  ALTERED_BOOKINGS = "altered_bookings",
  BOOKING_STATUS = "bookings_status",
  CREATED_PROPERTIES = "created_properties",
  CHANGED_ASSIGNEES = "changed_assignees",
  ADDED_ATTACHMENTS = "added_attachments",
  PRE_APPROVED = "pre_approved",
  HEALTH_SCORE = "health_score",
  CANCELLED_BOOKINGS = "cancelled_bookings",
  CHANGED_PROPERTIES = "changed_properties",
  AIRBNB_ALTERATIONS = "airbnb_alterations",
  REFUNDS = "refunds",
  SUPPORT_EMAIL = "support_emails",
}


export class FeedEnumHelpers {

  static getFeedFilterType(type: FeedFilterType): string {
    let title = "";

    switch (type) {
      case FeedFilterType.AUTOMATION: {
        title = "Automation";
        break;
      }
      case FeedFilterType.COMMENTS: {
        title = "Comments";
        break;
      }
      case FeedFilterType.CREATED_BOOKINGS: {
        title = "New Bookings";
        break;
      }
      case FeedFilterType.INQUIRY: {
        title = "New Inquiries";
        break;
      }
      case FeedFilterType.MENTIONS: {
        title = "Mentions";
        break;
      }
      case FeedFilterType.MANUAL_TASKS: {
        title = "Manual Tasks";
        break;
      }
      case FeedFilterType.ALTERED_BOOKINGS: {
        title = "Altered Bookings";
        break;
      }
      case FeedFilterType.BOOKING_STATUS: {
        title = "Booking Status Update";
        break;
      }
      case FeedFilterType.CHANGED_ASSIGNEES: {
        title = "Changed Assignees";
        break;
      }
      case FeedFilterType.ADDED_ATTACHMENTS: {
        title = "Task Attachments";
        break;
      }
      case FeedFilterType.CREATED_PROPERTIES: {
        title = "New Listing";
        break;
      }
      case FeedFilterType.PRE_APPROVED: {
        title = "Pre Approved";
        break;
      }
      case FeedFilterType.HEALTH_SCORE: {
        title = "Health Check";
        break;
      }
      case FeedFilterType.CANCELLED_BOOKINGS: {
        title = "Cancelled Bookings";
        break;
      }
      case FeedFilterType.CHANGED_PROPERTIES: {
        title = "Listings Update";
        break;
      }
      case FeedFilterType.AIRBNB_ALTERATIONS: {
        title = "Airbnb Alterations";
        break;
      }
      case FeedFilterType.REFUNDS: {
        title = "Refunds";
        break;
      }
      case FeedFilterType.AUTO_TASKS: {
        title = "Auto Tasks";
        break;
      }
      case FeedFilterType.SUPPORT_EMAIL: {
        title = "Support Emails";
        break;
      }
    }
    return title;
  }
}
