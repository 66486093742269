import { AutomatedMessageCompact } from "../../../models/new/automation/automated-messages-compact.model";
import { AutomatedMessageFull } from "../../../models/new/automation/automated-messages-full.model";
import { Action } from "../../action";

export enum AutomatedMessageActionTypes {
  INDEX_REQUEST = "[Automated Message NEW] Index Request",
  INDEX_SUCCESS = "[Automated Message NEW] Index Success",

  CREATE_REQUEST = "[Automated Message NEW] Create Request",
  CREATE_SUCCESS = "[Automated Message NEW] Create Success",

  SHOW_REQUEST = "[Automated Message NEW] Show Request",
  SHOW_SUCCESS = "[Automated Message NEW] Show Success",

  UPDATE_REQUEST = "[Automated Message NEW] Update Request",
  UPDATE_SUCCESS = "[Automated Message NEW] Update Success",

  DELETE_SUCCESS = "[Automated Message NEW] Delete Success"

}

export class AutomatedMessageIndexRequest implements Action {
  readonly type = AutomatedMessageActionTypes.INDEX_REQUEST;
}

export class AutomatedMessageIndexSuccess implements Action {
  readonly type = AutomatedMessageActionTypes.INDEX_SUCCESS;

  constructor(public payload: AutomatedMessageCompact[]) {
  }
}

export class AutomatedMessageCreateRequest implements Action {
  readonly type = AutomatedMessageActionTypes.CREATE_REQUEST;
}

export class AutomatedMessageCreateSuccess implements Action {
  readonly type = AutomatedMessageActionTypes.CREATE_SUCCESS;

  constructor(public payload: AutomatedMessageFull) {
  }
}

export class AutomatedMessageShowRequest implements Action {
  readonly type = AutomatedMessageActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {

  }
}

export class AutomatedMessageShowSuccess implements Action {
  readonly type = AutomatedMessageActionTypes.SHOW_SUCCESS;

  constructor(public payload: AutomatedMessageFull) {
  }
}

export class AutomatedMessageUpdateRequest implements Action {
  readonly type = AutomatedMessageActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class AutomatedMessageUpdateSuccess implements Action {
  readonly type = AutomatedMessageActionTypes.UPDATE_SUCCESS;

  constructor(public payload: AutomatedMessageFull) {
  }
}

export class AutomatedMessageDeleteSuccess implements Action {
  readonly type = AutomatedMessageActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}

