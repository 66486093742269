import { UserCompact } from "../new/user/user-compact.model";
import { AirbnbAccount } from "../airbnb_account";
import { isNullOrUndefined } from "util";
import { ThreadFull } from "../new/inbox/thread-full";
import { ThreadCompact } from "../new/inbox/thread-compact";
import { BookingCompact } from "../new/booking/booking-compact.model";

export class ThreadModelUtil {


  static getGuest(thread: ThreadCompact | ThreadFull): UserCompact {
    return thread["guest"]["data"];
  }

  static getOwner(thread: ThreadFull): UserCompact {
    return thread["owner"]["data"];
  }

  static getAccount(thread: ThreadFull): AirbnbAccount {
    if (isNullOrUndefined(thread["airbnbAccount"])) {
      return null;
    }

    return thread["airbnbAccount"]["data"];
  }

  static getBooking(thread: ThreadCompact | ThreadFull): BookingCompact {
    return thread["booking"]["data"];
  }

  static getAssignee(thread: ThreadFull): UserCompact {
    return thread["assignee"]["data"];
  }

}
