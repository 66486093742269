import { Action } from "../actions/action";
import { APP_IS_MENU_HIDDEN, APP_LANDING_URL, APP_SET_MENU_HIDDEN, APP_SIDE_BAR_TOGGLE } from "../actions/app";
import { APP_BOOTSTRAPPED } from "../actions/index";
import { LOGIN_SUCCESS, UPDATE_SUCCESS } from "../actions/user";
import { UserCompact } from "../models/new/user/user-compact.model";

export interface State {
  landing_url: string;
  landing_url_overridden: boolean;
  is_bootstrapped: boolean;
  isMenuHidden: boolean;
}

export const initialState: State = {
  landing_url: "/home",
  landing_url_overridden: false,
  is_bootstrapped: false,
  isMenuHidden: false,
};

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case APP_LANDING_URL: {
      return Object.assign({}, state, { landing_url: action.payload, landing_url_overridden: true });
    }
    case APP_BOOTSTRAPPED: {
      return Object.assign({}, state, { is_bootstrapped: true });
    }

    case APP_IS_MENU_HIDDEN: {
      // localStorage.setItem('side_collapsed', String(!state.isMenuHidden));
      return Object.assign({}, state, { isMenuHidden: !state.isMenuHidden });
    }

    case APP_SET_MENU_HIDDEN: {
      // localStorage.setItem('side_collapsed', String(!state.isMenuHidden));
      return Object.assign({}, state, { isMenuHidden: true });
    }

    case APP_SIDE_BAR_TOGGLE: {
      const isHidden = action.payload as boolean;
      return Object.assign({}, state, { isMenuHidden: isHidden });
    }
    case LOGIN_SUCCESS: {
      if (state.landing_url_overridden) {
        return state;
      }

      const user = action.payload;

      if (user.is_admin) {
        console.log("inside tasks admin");
        return Object.assign({}, state, { landing_url: "/tasks" });
      } else if (user.is_owner) {
        console.log("inside tasks home");
        return Object.assign({}, state, { landing_url: "/home" });
      } else {
        console.log("inside home owner");
        if (user?.managementContact?.data?.category === "homeowner") {
          return Object.assign({}, state, { landing_url: "/homeowner-home" });
        } else if (user?.managementContact?.data?.category === "agent") {
          return Object.assign({}, state, { landing_url: "/admin-home/owner-income" });
        } else {
          console.log("inside tasks owner");
          return Object.assign({}, state, { landing_url: "/tasks" });
        }
      }
    }
    case UPDATE_SUCCESS: {
      if (state.landing_url_overridden) {
        return state;
      }

      const user: UserCompact = action.payload;

      if (user.is_admin) {
        console.log("inside tasks admin");
        return Object.assign({}, state, { landing_url: "/tasks" });
      } else if (user.is_owner) {
        console.log("inside tasks home");
        return Object.assign({}, state, { landing_url: "/home" });
      } else {
        console.log("inside home owner");
        if (user?.managementContact?.data?.category === "homeowner") {
          return Object.assign({}, state, { landing_url: "/homeowner-home" });
        } else if (user?.managementContact?.data?.category === "agent") {
          return Object.assign({}, state, { landing_url: "/admin-home/owner-income" });
        } else {
          console.log("inside tasks owner");
          return Object.assign({}, state, { landing_url: "/tasks" });
        }
      }
    }
    default: {
      return state;
    }
  }
}

export const getLandingUrl = (state: State) => state.landing_url;
export const getIsBootstrapped = (state: State) => state.is_bootstrapped;
export const getIsMenuHidden = (state: State) => state.isMenuHidden;
