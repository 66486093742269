import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ReviewResponseDraft } from "../../models/new/drafts/review-response-draft.model";
import { ApiService } from "../api.service";

@Injectable()
export class ReviewResponseDraftService {
  constructor(private apiService: ApiService) {
  }

  getReviewResponseDrafts(): Observable<ReviewResponseDraft[]> {
    return this.apiService.get<{data: ReviewResponseDraft[]}>("/admin/review-response-drafts", true)
      .pipe(map(res => res.data));
  }

  createReviewResponseDraft(data: Partial<ReviewResponseDraft>): Observable<ReviewResponseDraft> {
    return this.apiService.post<{data: ReviewResponseDraft}>("/admin/review-response-drafts", true, data)
      .pipe(map(res => res.data));
  }

  updateReviewResponseDraft(draftId: number, data: Partial<ReviewResponseDraft>): Observable<ReviewResponseDraft> {
    return this.apiService.put<{data: ReviewResponseDraft}>("/admin/review-response-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));
  }

  deleteReviewResponseDraft(draftId: number) {
    return this.apiService.delete("/admin/review-response-drafts/" + draftId, true);
  }
}
