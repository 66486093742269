import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { takeWhile } from "rxjs/operators";

import { CannedResponseFull } from "../../../models/new/automation/canned-response-full.model";
import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { ListingRepository } from "../../../repository/listing.repository";
import { StayDuvetService } from "../../../services/stayduvet";
import { ListingCannedMessagePopupComponent } from "../../listings/components/popups/listing-canned-message-popup";

@Component({
  selector: "sd-canned-responses-detail",
  template: `
    <mat-card class="padding" style="cursor: pointer">
      <mat-card-content fxLayout="column" fxLayoutGap.lt-sm="5px" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h3>{{item.title}}</h3>
          <button mat-icon-button (click)="copyToClipboard()">
            <mat-icon>file_copy</mat-icon>
          </button>
        </div>

        <div fxLayout="row" (click)="editMessage(item)" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5px"
             fxLayoutGap="5px"
             fxLayoutAlign="space-between center" fxLayoutAlign.lt-sm="center flex-start">
          <span class="content responsiveAlign"> {{item.message}} </span>


          <span class="mat-button"
                style="background-color: darkorange;text-align: center; color: white">
                    {{getText(item)}}
                  </span>
        </div>
      </mat-card-content>
    </mat-card>

  `,
  styles: [`

    .content {
      font-size: 12px;
      line-height: 130%;
      max-width: 700px;
      text-align: justify;
      white-space: pre-line;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }

    .padding {
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    .mat-card {
      border: 1px solid lightgrey !important;
      box-shadow: none !important;
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    h3 {
      font-weight: bolder !important;
      letter-spacing: 1px !important;
      font-size: 20px !important;
      font-family: 'Montserrat', sans-serif !important;
    }

    mat-spinner {
      width: 30px;
      height: 30px;
    }

    @media only screen and (max-width: 600px) {
      .responsiveAlign {
        text-align: center;
      }
    }
  `]
})

export class SettingCannedResponsesDetailComponent implements OnInit {

  @Input() item: CannedResponseFull;
  @Input() alert: any;
  isAlive = true;
  listings: ListingCompact[] = [];
  private dialogRef: MatDialogRef<any>;

  constructor(private service: StayDuvetService, private dialog: MatDialog, private listingRepo: ListingRepository,
              private snackBar: MatSnackBar, private clipboard: Clipboard) {
  }

  ngOnInit() {
    this.listingRepo.getAcceptedListings().pipe(takeWhile(() => this.isAlive)).subscribe((listings) => {
      this.listings = listings;
    });
  }

  getText(savedMessage: CannedResponseFull) {

    const Ids = savedMessage.property_ids || [];
    const type = savedMessage.rule;
    const listingIds = this.listings.map(listing => listing.id);


    if (type === "include") {
      if (Ids.length === 0) {
        return "On No Listings";
      }

      if (listingIds.length === Ids.length) {
        return "On All Listings";
      }

      if (listingIds.length > Ids.length) {
        return "On Multiple Listings";
      }
    } else {
      if (Ids.length === 0) {
        return "On All Listings";
      }


      if (listingIds.length === Ids.length) {
        return "On No Listings";
      }

      if (listingIds.length > Ids.length) {
        return "On Multiple Listings";
      }
    }

  }

  editMessage(message: CannedResponseFull) {

    this.dialogRef = this.dialog.open(ListingCannedMessagePopupComponent);
    const instance = this.dialogRef.componentInstance;
    instance.messageId = message.id;
    instance.title = message.title;
    instance.message = message.message;
    instance.popUpTitle = "Edit Canned Response";
    instance.isEditType = true;
    instance.listingIds = message.property_ids;
    instance.listings = this.listings;
    instance.rule = message.rule;
    this.dialogRef.updateSize("100%", "100%");
  }

  copyToClipboard() {
    this.clipboard.copy(this.item.message);
    this.snackBar.open("Copied to Clipboard", "", {
      duration: 4000,
    });
  }
}
