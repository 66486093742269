import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AppService } from "../services/app.service";

@Injectable()
export class AnonymousGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!AppService.hasLoginToken()) {
      return true;
    }

    this.router.navigate(["/"]);
    return false;
  }
}
