import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { AppRoutingModule } from "./app.routing.module";
import { CheckoutComponent } from "./components/checkout";
import { CheckoutQuoteComponent } from "./components/checkout-quote";
import { CheckoutPageComponent } from "./components/checkout2";
import { AppComponent } from "./components/containers/app.component";
import { BootstrapComponent } from "./components/containers/bootstrap";
import { DashboardComponent } from "./components/containers/dashboard";
import { OwnerHomeComponent } from "./components/containers/owner-home";
import { HomeCreateOwnerBlockPopupComponent } from "./components/create-owner-block-home-popup";
import { FeedsComponent } from "./components/feeds/feeds";
import { InquiryReportPopupComponent } from "./components/feeds/inquiry-report-popup.component";
import { HomeownerProfileComponent } from "./components/homeowner/homeowner-profile";
import { HomeownerProfileContainerComponent } from "./components/homeowner/homeowner-profile-container";
import { OwnerDashboardViewComponent } from "./components/homeowner/owner-dashboard-view";
import { BookingPopoverComponent } from "./components/multi-calendar/booking-popover";
import { MultiCalendarComponent } from "./components/multi-calendar/multi-calendar";
import { MultiCalendarContainerComponent } from "./components/multi-calendar/multi-calendar-container";
import { MultiCalendarDateTileComponent } from "./components/multi-calendar/multi-calendar-date-tile";
import { PaymentPageComponent } from "./components/payment";
import { ProfileSettingComponent } from "./components/profile";
import { UpcomingBookingCardComponent } from "./components/upcoming-booking-card";
import { ContactChangePasswordComponent } from "./modules/contacts/components/popups/change-password.popup";
import { CreateRoutingRuleComponent } from "./modules/contacts/components/popups/create-routing-rule";
import { SavedContactViewPopupComponent } from "./modules/contacts/components/popups/saved-contact-view";
import { BankInfoTabComponent } from "./modules/contacts/components/tabs/bank-info";
import { UserProfileTabComponent } from "./modules/contacts/components/tabs/user-profile";
import { ContactsSharedModule } from "./modules/contacts/contacts.shared.module";
import { CoreModule } from "./modules/core/core.module";
import { InboxSharedModule } from "./modules/inbox/inbox.shared.module";
import { IntercomModule } from "./modules/intercom";
import { AirbnbPopupComponent } from "./modules/listings/components/owner-add-airbnb-popup";
import { AddRoutingRulePopupComponent } from "./modules/listings/components/popups/add-routing-rule-popup";
import { FutureBookConfirmationPopupComponent } from "./modules/listings/components/popups/confirm-future-book.popup";
import { ReservationsSharedModule } from "./modules/reservations/reservations.shared.module";
import { SentryModule } from "./modules/sentry/sentry.module";
import { AddAffiliatePopupComponent } from "./modules/settings/components/affiliate/add-affiliate.popup";
import { SettingAffiliateDetailComponent } from "./modules/settings/components/affiliate/setting-affiliate-detail";
import { SettingAffiliatesComponent } from "./modules/settings/components/affiliate/setting-affiliates";
import { ChangeAirbnbEmailPopupComponent } from "./modules/settings/components/change-airbnb-email.popup";
import { AddCharityPopupComponent } from "./modules/settings/components/charity/add-charity.popup";
import { SettingCharitiesComponent } from "./modules/settings/components/charity/setting-charities";
import { SettingCharityDetailComponent } from "./modules/settings/components/charity/setting-charity-detail";
import { ListingPrivateNotesPopupComponent } from "./modules/settings/components/listing-private-notes-popup";
import { SettingsSharedModule } from "./modules/settings/settings.shared.module";
import { AutoTaskShowComponent } from "./modules/shared/components/auto-task-show";
import { SharedModule } from "./modules/shared/shared.module";
import { clearState, reducers } from "./reducers";
import { OauthComponent } from "./components/oauth";

const entryComponents = [
  FutureBookConfirmationPopupComponent,
  ContactChangePasswordComponent,
  ChangeAirbnbEmailPopupComponent,
  AutoTaskShowComponent,
  AirbnbPopupComponent,
  HomeCreateOwnerBlockPopupComponent,
  AddRoutingRulePopupComponent,
  BookingPopoverComponent,
  CreateRoutingRuleComponent,
  FeedsComponent,
  InquiryReportPopupComponent,
  ListingPrivateNotesPopupComponent
];

@NgModule({
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule,
    SentryModule,
    IntercomModule,
    BrowserAnimationsModule,
    BrowserModule,
    StoreModule.forRoot(reducers, {
      metaReducers: [clearState],
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    StoreDevtoolsModule.instrument({maxAge: 50}),
    SharedModule,
    SettingsSharedModule,
    ContactsSharedModule,
    InboxSharedModule,
    ReservationsSharedModule,
  ],
  declarations: [
    ...entryComponents,
    AppComponent,
    CheckoutComponent,
    OwnerHomeComponent,
    UpcomingBookingCardComponent,
    DashboardComponent,
    BootstrapComponent,
    SavedContactViewPopupComponent,
    UserProfileTabComponent,
    BankInfoTabComponent,
    MultiCalendarDateTileComponent,
    MultiCalendarComponent,
    MultiCalendarContainerComponent,
    CheckoutPageComponent,
    ProfileSettingComponent,
    PaymentPageComponent,
    CheckoutQuoteComponent,
    HomeownerProfileComponent,
    HomeownerProfileContainerComponent,
    OwnerDashboardViewComponent,
    FeedsComponent,
    SettingAffiliateDetailComponent,
    SettingAffiliatesComponent,
    SettingCharitiesComponent,
    SettingCharityDetailComponent,
    InquiryReportPopupComponent,
    AddAffiliatePopupComponent,
    AddCharityPopupComponent,
    OauthComponent
    /**
     * Pipes
     */
  ],
  entryComponents: entryComponents,
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  //
}
