/**
 * Created by aditya on 24/8/17.
 */
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { skip, takeUntil } from "rxjs/operators";

import { UserFull } from "../../../models/new/user/user-full.model";
import { State } from "../../../reducers";
import { ContactRepository } from "../../../repository/contact.repository";
import { CommonUtil } from "../../../utils/common.util";
import { getContactMethodTypes, ValidateMobileNumber } from "../../../utils/utils";

import { ContactChangePasswordComponent } from "./popups/change-password.popup";

@Component({
  selector: "sd-contact-info",
  template: `
    <form fxLayout="column" fxFlex="100%" [formGroup]="userProfileForm"
          (ngSubmit)="userProfileForm.valid && userUpdate()">
      <div style="margin-top:20px;padding-left: 20px;padding-right: 20px" fxLayout="column" fxFlex="95%"
           fxLayoutGap="20px">

        <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
          <mat-form-field [color]="'accent'" class="width-48" dividerColor="accent">
            <mat-icon style="color:#194267" matSuffix>contacts</mat-icon>
            <input matInput placeholder="First Name" formControlName='first_name'>
            <mat-error> First Name is required</mat-error>
          </mat-form-field>

          <mat-form-field [color]="'accent'" class="width-48" dividerColor="accent">
            <mat-icon style="color:#194267" matSuffix>contacts</mat-icon>
            <input matInput placeholder="Last Name" formControlName='last_name'>
            <mat-error> Last Name is required</mat-error>
          </mat-form-field>
        </div>
        <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
          <mat-form-field [color]="'accent'" class="width-48" dividerColor="accent">
            <mat-icon style="color:#194267" matSuffix>email</mat-icon>
            <input matInput placeholder="Email" formControlName='email'>
            <mat-error> Email is required</mat-error>
          </mat-form-field>
          <mat-form-field [color]="'accent'" class="width-48" dividerColor="accent">
            <mat-icon style="color:#194267" matSuffix>email</mat-icon>
            <input matInput placeholder="Secondary Email" formControlName='secondary_email'>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
          <mat-form-field [color]="'accent'" class="width-48" dividerColor="accent">
            <input matInput placeholder="Phone Number" formControlName='phone_number'>
            <mat-error>Valid Phone Number is required</mat-error>
          </mat-form-field>
          <mat-form-field [color]="'accent'" class="width-48" dividerColor="accent">
            <input matInput placeholder="Secondary Phone Number" formControlName='secondary_phone_number'>
            <mat-error>Valid Phone Number is required</mat-error>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
          <mat-form-field class="width-48" dividerColor="accent">
            <input matInput placeholder="Company Name" formControlName='company_name'>
          </mat-form-field>
          <mat-form-field class="width-48" dividerColor="accent">
            <input matInput placeholder="Position in company" formControlName='position'>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column" *ngIf="!isEmployee">
          <mat-form-field class="width-48" dividerColor="accent">
            <input matInput placeholder="SSN/EIN" formControlName='identification_number'>
          </mat-form-field>

          <mat-form-field class="width-48" dividerColor="accent">
            <input matInput placeholder="Mailing Address" formControlName='mailing_address'>
          </mat-form-field>

        </div>

        <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
          <sd-select class="width-48" [control]="preferredContactMethod" [options]="methodOptions" placeholder="Preferred Contact Method"></sd-select>
        </div>

        <mat-form-field [color]="'accent'" dividerColor="accent" class="textArea">
                            <textarea matInput placeholder="Your Notes" rows="4" formControlName='description'>
                            </textarea>
        </mat-form-field>
      </div>

      <div fxLayout="row" style="margin-left: 10px;">

        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign.lt-sm="center center">
            <button mat-raised-button [disabled]="isUpdating"
                    class="dangerButton" type="submit"><span
              class="successSpan"><b>UPDATE USER DETAILS</b></span>
            </button>
            <button mat-raised-button [disabled]="isUpdating" *ngIf="!(contact.type==='guest')"
                    class="dangerButton" type="button" (click)="openPopup()"><span
              class="successSpan"><b>RESET PASSWORD</b></span>
            </button>
            <mat-spinner *ngIf="isUpdating" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          </div>
          <!--<button mat-raised-button [disabled]="isDeleting" color="accent" type="button" (click)="deleteContact()"><b>DELETE USER</b></button>-->
          <!--<mat-spinner *ngIf="isDeleting" [diameter]="30" [strokeWidth]="4"></mat-spinner>-->
        </div>

      </div>
    </form>

  `,
  styles: [`

    .textArea {
      width: 98%;
      min-height: 100px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-color: darkgray;
      padding: 5px;
      border-style: solid;
      border-width: 1px;
      border-radius: 5px;
    }

    .width-48 {
      width: 48%;
    }

    @media only screen and (max-width: 700px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 100%;
      }

      .responsivewidth {
        width: 80%;
      }

      .responsiveAlign {
        text-align: center;
      }
    }


  `]

})

export class ContactInfoComponent implements OnInit, OnDestroy {

  userProfileForm: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  userEmail: FormControl;
  userSecondaryEmail: FormControl;
  userPhoneNumber: FormControl;
  userSecondaryPhoneNumber: FormControl;
  userCompanyName: FormControl;
  userPositionInCompany: FormControl;
  identificationNumber: FormControl;
  preferredContactMethod: FormControl;
  userNotes: FormControl;
  mailingAddress: FormControl;
  @Input() set contact(user: UserFull) {
    this._contact = user;
    this.isEmployee = CommonUtil.isEmployee(user);
  }
  get contact(): UserFull {
    return this._contact;
  }
  contactMethods;
  methodOptions: {title: string, value: any}[];
  isUpdating = false;
  isDeleting = false;
  private destroyed$ = new Subject();
  userId: number;
  isEmployee = false;
  dialogRef;
  private _contact: UserFull;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private store: Store<State>,
              private contactRepo: ContactRepository,
              private dialog: MatDialog) {

  }

  ngOnInit() {
    this.contactMethods = getContactMethodTypes();
    this.methodOptions = this.contactMethods.map(method => {
      return {title: method.title, value: method.slug};
    });

    this.firstName = new FormControl(this.contact.first_name, [Validators.required]);
    this.lastName = new FormControl(this.contact.last_name);
    this.userEmail = new FormControl(this.contact.email, [Validators.required, Validators.email]);
    this.userSecondaryEmail = new FormControl(this.contact.secondary_email);
    this.userPhoneNumber = new FormControl(this.contact.phone_number, [Validators.required, ValidateMobileNumber]);
    this.userSecondaryPhoneNumber = new FormControl(this.contact.secondary_phone_number, [ValidateMobileNumber]);
    this.preferredContactMethod = new FormControl(this.contact.preferred_contact_method, [Validators.required]);
    this.userCompanyName = new FormControl(this.contact.company_name);
    this.userPositionInCompany = new FormControl(this.contact.position);
    this.identificationNumber = new FormControl(this.contact.identification_number);
    this.userNotes = new FormControl(this.contact.description);
    this.mailingAddress = new FormControl(this.contact.mailing_address);

    this.userProfileForm = new FormGroup({
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.userEmail,
      secondary_email: this.userSecondaryEmail,
      phone_number: this.userPhoneNumber,
      secondary_phone_number: this.userSecondaryPhoneNumber,
      company_name: this.userCompanyName,
      position: this.userPositionInCompany,
      identification_number: this.identificationNumber,
      preferred_contact_method: this.preferredContactMethod,
      description: this.userNotes,
      mailing_address: this.mailingAddress,
    });

    const route$ = this.route.params;
    route$.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      const contactId = +params.id;
      this.userId = contactId;

      const contact$ = this.contactRepo.getFullContactById(contactId);

      contact$.pipe(takeUntil(route$.pipe(skip(1)))).subscribe((contact) => {
        this.contact = contact;
        if (this.contact) {
          this.firstName.setValue(this.contact.first_name);
          this.lastName.setValue(this.contact.last_name);
          this.userEmail.setValue(this.contact.email);
          this.userSecondaryEmail.setValue(this.contact.secondary_email);
          this.userSecondaryPhoneNumber.setValue(this.contact.secondary_phone_number);
          this.userPhoneNumber.setValue(this.contact.phone_number);
          this.preferredContactMethod.setValue(this.contact.preferred_contact_method);
          this.userNotes.setValue(this.contact.description);
          this.mailingAddress.setValue(this.contact.mailing_address);
        }
      });
    });

  }

  ngOnDestroy(): void {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  deleteContact() {
    console.log(this.contact);
  }

  userUpdate() {
    this.isUpdating = true;
    const data = this.userProfileForm.value;
    if (this.userEmail.value === this.contact.email) {
      delete data.email;
    }
    if (this.firstName.value === this.contact.first_name) {
      delete data.first_name;
    }
    if (this.lastName.value === this.contact.last_name) {
      delete data.last_name;
    }
    if (this.userSecondaryEmail.value === this.contact.secondary_email) {
      delete data.secondary_email;
    }
    if (this.userPhoneNumber.value === this.contact.phone_number) {
      delete data.phone_number;
    }
    if (this.userSecondaryPhoneNumber.value === this.contact.secondary_phone_number) {
      delete data.secondary_phone_number;
    }
    if (this.userNotes.value === this.contact.description) {
      delete data.description;
    }
    if (this.preferredContactMethod.value === this.contact.preferred_contact_method) {
      delete data.preferred_contact_method;
    }
    if (this.mailingAddress.value === this.contact.mailing_address) {
      delete data.mailing_address;
    }
    if (this.userCompanyName.value === this.contact.company_name) {
      delete data.company_name;
    }
    if (this.identificationNumber.value === this.contact.identification_number) {
      delete data.identification_number;
    }
    if (this.userPositionInCompany.value === this.contact.position) {
      delete data.position;
    }
    this.contactRepo.updateContactById(this.contact.id, data).subscribe(contact => {
        this.isUpdating = false;
      },
      (error) => {
        this.isUpdating = false;
      });
  }

  changePassword() {
    this.isUpdating = true;
    const contact$ = this.contactRepo.resetPassword(this.userId).subscribe(res => {
      console.log(res);
      this.isUpdating = false;
    });

  }

  openPopup() {
    if (CommonUtil.isHomeOwner(this.contact) || CommonUtil.isVendor(this.contact) || this.contact?.managementContact?.data?.category === "agent") {
      this.dialogRef = this.dialog.open(ContactChangePasswordComponent, {data: {user_id: this.userId}});
      this.dialogRef.updateSize("100%", "45%");
    } else {
      this.changePassword();
    }
  }
}
