import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Message } from "app/models/new/inbox/message";
import { Observable } from "rxjs";
import { delay, filter, map, take, tap } from "rxjs/operators";

import { MessageCreateSuccess, MessageIndexRequest, MessageIndexSuccess } from "../actions/new/inbox/message";
import {
  ThreadAddFilter,
  ThreadChangeAssigneeIds,
  ThreadChangeCurrentPage,
  ThreadChangeListingIds,
  ThreadNextPageRequest,
  ThreadNextPageSuccess,
  ThreadOffsetChange,
  ThreadRemoveThreadSuccess,
  ThreadResetData,
  ThreadSendSpecialOfferSuccess,
  ThreadSetSelectedId,
  ThreadShowRequest,
  ThreadShowSuccess,
  ThreadUpdateSuccess,
} from "../actions/new/inbox/thread";
import { TransformerType } from "../enums/common.enum";
import { ThreadFilter } from "../enums/thread-filter.enum";
import { Note } from "../models/new/inbox/note";
import { ThreadCompact } from "../models/new/inbox/thread-compact";
import { ThreadFull } from "../models/new/inbox/thread-full";
import { ThreadGetResponse } from "../models/responses/inboxes/threads/thread-get-response";
import {
  getAllThreads,
  getFullThreadById,
  getIsFullThreadLoaded,
  getIsFullThreadLoading, getIsNotesLoaded, getIsNotesLoading,
  getIsThreadLoadedForPageNumber,
  getIsThreadLoadingForPageNumber,
  getMessagesForThreadId,
  getMessagesLoadedForThreadId,
  getMessagesLoadingForThreadId, getNotes,
  getSelectedBookingId,
  getSelectedThreadId,
  getThreadAssigneeIds,
  getThreadCurrentPage,
  getThreadFilters,
  getThreadListingIds,
  getThreadOffset,
  getThreadOffsetDate,
  getThreadTotalCount,
  getThreadTotalPages,
  State
} from "../reducers";
import { ThreadService } from "../services/thread.service";
import {
  NotesAddSuccessAction,
  NotesDeleteSuccessAction,
  NotesFetchRequestAction,
  NotesFetchSuccessAction
} from "../actions/new/inbox/note";

@Injectable()
export class ThreadRepository {

  constructor(private threadService: ThreadService, private store: Store<State>) {
  }

  getAllThreads(): Observable<ThreadCompact[]> {
    return this.store.select(getAllThreads).pipe(filter(t => !!t), map(t => t as ThreadCompact[]),);
  }

  getIsThreadLoading(page: number): Observable<boolean> {
    console.log("Thread", page);
    return this.store.select((state) => getIsThreadLoadingForPageNumber(state, page));
  }

  getIsThreadLoaded(page: number): Observable<boolean> {
    return this.store.select((state) => getIsThreadLoadedForPageNumber(state, page));
  }

  getIsFullThreadLoading(threadId: number, bookingId: number): Observable<boolean> {
    return this.store.select(state => getIsFullThreadLoading(state, threadId, bookingId));
  }

  getIsFullThreadLoaded(threadId: number, bookingId: number): Observable<boolean> {
    return this.store.select(state => getIsFullThreadLoaded(state, threadId, bookingId));
  }

  getThreadTotalPages(): Observable<number> {
    return this.store.select(getThreadTotalPages);
  }

  getTotalThreads(): Observable<number> {
    return this.store.select(getThreadTotalCount);
  }

  getThreadCurrentPage(): Observable<number> {
    return this.store.select(getThreadCurrentPage);
  }

  getThreadFilters(): Observable<ThreadFilter[]> {
    return this.store.select(getThreadFilters);
  }

  getThreadListingIds(): Observable<number[]> {
    return this.store.select(getThreadListingIds);
  }

  getThreadAssigneeIds(): Observable<number[]> {
    return this.store.select(getThreadAssigneeIds);
  }

  getSelectedThreadId(): Observable<number> {
    return this.store.select(getSelectedThreadId).pipe(filter(id => !!id));
  }

  getSelectedBookingId(): Observable<number> {
    return this.store.select(getSelectedBookingId);
  }

  getThreadOffset(): Observable<string> {
    return this.store.select(getThreadOffset);
  }

  getThreadOffsetDate(): Observable<string> {
    return this.store.select(getThreadOffsetDate);
  }

  setSelectedThreadId(threadId: number, bookingId: number) {
    return this.store.dispatch(new ThreadSetSelectedId({thread_id: threadId, booking_id: bookingId}));
  }

  setSelectedFilter(filter: ThreadFilter[]) {
    this.store.dispatch(new ThreadAddFilter(filter));
  }

  setSelectedListingIds(ids: number[]) {
    this.store.dispatch(new ThreadChangeListingIds(ids));
  }

  setSelectedAssigneeIds(ids: number[]) {
    this.store.dispatch(new ThreadChangeAssigneeIds(ids));
  }

  setFilters(filter: ThreadFilter[], ids: number[], assigneeIds: number[], offset: string, date: string) {
    this.store.dispatch(new ThreadResetData());
    this.setSelectedFilter(filter);
    this.setSelectedListingIds(ids);
    this.setSelectedAssigneeIds(assigneeIds);
    this.store.dispatch(new ThreadOffsetChange({offset, date}));
  }

  setCurrentPage(page: number, force: boolean = false) {
    this.store.dispatch(new ThreadChangeCurrentPage(page));
    let loading;
    let loaded;
    let totalPages: number;

    this.getIsThreadLoading(page).subscribe(l => loading = l);
    this.getIsThreadLoaded(page).subscribe(l => loaded = l);
    this.getThreadTotalPages().subscribe(t => totalPages = t);

    if ((totalPages !== 0) && (page > totalPages)) {
      return;
    }

    if (!loading && (!loaded || force)) {

      console.log("inside loading");

      let threadFilter: ThreadFilter[];
      let listingIds: number[];
      let assigneeIds: number[];
      let offset: string;
      let offsetDate: string;

      this.getThreadFilters().pipe(take(1)).subscribe(f => threadFilter = f);
      this.getThreadListingIds().pipe(take(1)).subscribe(ids => listingIds = ids);
      this.getThreadAssigneeIds().pipe(take(1)).subscribe(ids => assigneeIds = ids);
      this.getThreadOffset().pipe(take(1)).subscribe(o => offset = o);
      this.getThreadOffsetDate().pipe(take(1)).subscribe(d => offsetDate = d);

      this.store.dispatch(new ThreadNextPageRequest(page));
      this.threadService.getThreads(
        page,
        threadFilter,
        listingIds,
        assigneeIds,
        offset,
        offsetDate
      ).subscribe((res: ThreadGetResponse) => {
        this.store.dispatch(new ThreadNextPageSuccess({
          threads: res.data,
          currentPage: res.meta.pagination.current_page,
          totalPages: res.meta.pagination.total_pages,
          totalCount: res.meta.pagination.total
        }));

        // if (res.data && res.data.length > 0) {
        //   this.setSelectedThreadId(res.data[0].id);
        // }

      });
    }

  }

  getFullThreadById(threadId: number, bookingId = null, force: boolean = false): Observable<ThreadFull> {
    let loading;
    let loaded;

    this.getIsFullThreadLoading(threadId, bookingId).pipe(take(1)).subscribe(l => loading = l);
    this.getIsFullThreadLoaded(threadId, bookingId).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new ThreadShowRequest(threadId));
      this.threadService.showThread(threadId, bookingId).subscribe(t => {
        this.store.dispatch(new ThreadShowSuccess(t));
      });

    }
    return this.store.select(state => getFullThreadById(state, threadId)).pipe(
      filter(t => !!t),
      filter(t => t.__type === TransformerType.FULL),
      map(t => t as ThreadFull),);
  }

  updateThread(threadId: number, data: any): Observable<ThreadFull> {
    return this.threadService.updateThread(threadId, data).pipe(map(res => {
      delete res.booking_id;
      delete res.status;
      this.store.dispatch(new ThreadUpdateSuccess(res));
      return res;
    }));
  }

  archiveThread(threadId: number, data: any): Observable<ThreadFull> {
    return this.threadService.updateThread(threadId, data).pipe(map(res => {
      this.store.dispatch(new ThreadRemoveThreadSuccess({thread_id: threadId}));
      return res;
    }));
  }

  // TODO Store Integration if needed
  preApproveBooking(threadId: number, bookingId: number): Observable<boolean> {
    return this.threadService.preApproveBooking(threadId, bookingId).pipe(
      delay(1000),
      tap(() => {
        this.store.dispatch(new ThreadSendSpecialOfferSuccess({threadId: threadId, bookingId: bookingId}));
        this.getMessagesForThread(threadId, true).pipe(take(1)).subscribe();
      }),);
  }

  // TODO Store Integration if needed
  declineBooking(threadId: number, bookingId: number, message: string): Observable<boolean> {
    return this.threadService.declineBooking(threadId, bookingId, message).pipe(
      delay(1000),
      tap(() => {
        this.getMessagesForThread(threadId, true).pipe(take(1)).subscribe();
      }),);
  }

  getThreadByBooking(bookingId): Observable<ThreadFull> {
    return this.threadService.getThreadByBooking(bookingId).pipe(map((thread) => {
      this.store.dispatch(new ThreadShowSuccess(thread));
      return thread;
    }));
  }

  createThreadFromBooking(bookingId: number): Observable<ThreadFull> {
    return this.threadService.createThreadFromBooking(bookingId).pipe(map((thread) => {
      this.store.dispatch(new ThreadShowSuccess(thread));
      return thread;
    }));
  }

  unreadThread(threadId: number): Observable<ThreadFull> {
    return this.threadService.unreadThread(threadId).pipe(map((thread) => {
      delete thread.booking_id;
      delete thread.status;
      this.store.dispatch(new ThreadUpdateSuccess(thread));
      return thread;
    }));
  }

  assignToThread(threadId: number, adminId: number): Observable<ThreadFull> {
    return this.threadService.assignToThread(threadId, adminId).pipe(map((thread) => {
      delete thread.booking_id;
      delete thread.status;
      this.store.dispatch(new ThreadUpdateSuccess(thread));
      return thread;
    }));
  }

  getMessagesForThread(threadId: number, force: boolean = false): Observable<Message[]> {
    let loading;
    let loaded;

    this.getIsMessagesLoadingForThreadId(threadId).pipe(take(1)).subscribe(l => loading = l);
    this.getIsMessagesLoadedForThreadId(threadId).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new MessageIndexRequest(threadId));
      this.threadService.getMessagesForThread(threadId).subscribe(messages => {
        this.store.dispatch(new MessageIndexSuccess({messages: messages, threadId: threadId}));
      });
    }
    return this.store.select(state => getMessagesForThreadId(state, threadId)).pipe(filter(m => !!m));
  }

  sendAirbnbMessage(data: { content: string }, threadId: number, bookingId: number): Observable<Message> {
    return this.threadService.sendAirbnbMessage(data, threadId, bookingId).pipe(map(message => {
      this.store.dispatch(new MessageCreateSuccess({message: message, threadId: threadId, bookingId: bookingId}));
      return message;
    }));
  }

  sendBookingPalMessage(data: { content: string }, threadId: number, bookingId: number): Observable<Message> {
    return this.threadService.sendBookingPalMessage(data, threadId, bookingId).pipe(map(message => {
      this.store.dispatch(new MessageCreateSuccess({message: message, threadId: threadId, bookingId: bookingId}));
      return message;
    }));
  }

  sendSMSMessage(data: { content: string }, threadId: number, bookingId: number): Observable<Message> {
    return this.threadService.sendSMSMessage(data, threadId, bookingId).pipe(map(message => {
      this.store.dispatch(new MessageCreateSuccess({message: message, threadId: threadId, bookingId: bookingId}));
      return message;
    }));
  }

  sendEmailMessage(data: { subject: string, content: string }, threadId: number, bookingId: number): Observable<Message> {
    return this.threadService.sendEmailMessage(data, threadId, bookingId).pipe(map(message => {
      this.store.dispatch(new MessageCreateSuccess({message: message, threadId: threadId, bookingId: bookingId}));
      return message;
    }));
  }

  addNote(data: { description: string, thread_id: number, mentions?: number[] }): Observable<Note> {
    return this.threadService.addNote(data).pipe(map(note => {
      this.store.dispatch(new NotesAddSuccessAction(note));
      return note;
    }));
  }

  getIsNotesLoading(): Observable<boolean> {
    return this.store.select(getIsNotesLoading);
  }

  getIsNotesLoaded(): Observable<boolean> {
    return this.store.select(getIsNotesLoaded);
  }

  getNotes(threadId: number, force: boolean = false) {
    let loading;
    let loaded;

    this.getIsNotesLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsNotesLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new NotesFetchRequestAction());
      this.threadService.getNotes(threadId).subscribe(res => {
        this.store.dispatch(new NotesFetchSuccessAction(res));
      });
    }
    return this.store.select(getNotes);
  }

  closeNote(noteId: number): Observable<Note> {
    return this.threadService.closeNote(noteId).pipe(map(note => {
      this.store.dispatch(new NotesDeleteSuccessAction(note));
      return note;
    }));
  }

  updateNote(data: { description: string, mentions?: number[] }, noteId: number): Observable<Note> {
    return this.threadService.updateNote(data, noteId);
  }

  getIsMessagesLoadingForThreadId(threadId: number) {
    return this.store.select(state => getMessagesLoadingForThreadId(state, threadId));
  }

  getIsMessagesLoadedForThreadId(threadId: number) {
    return this.store.select(state => getMessagesLoadedForThreadId(state, threadId));
  }

  acceptReservationRequest(threadId, bookingId: number): Observable<boolean> {
    return this.threadService.acceptReservationRequest(threadId, bookingId);
  }

  acceptReservationRequestMbp(threadId, bookingId: number): Observable<boolean> {
    return this.threadService.acceptReservationRequestMbp(threadId, bookingId);
  }

  declineReservationRequest(threadId, bookingId: number, data: {
    reason: string,
    guest_message: string,
    airbnb_message?: string
  }): Observable<boolean | {}> {
    return this.threadService.declineReservationRequest(threadId, bookingId, data);
  }

  declineReservationRequestMbp(threadId, bookingId: number, data: {
    block_dates: boolean,
    reason: string,
    guest_message: string,
    airbnb_message?: string
  }): Observable<boolean | {}> {
    return this.threadService.declineReservationRequestMbp(threadId, bookingId, data);
  }

  sendSpecialOffer(threadId, bookingId: number, data: {
    start: any,
    end: any,
    number_of_adults: number,
    number_of_children: number,
    number_of_infants: number,
    price: number
  }): Observable<boolean> {
    return this.threadService.sendSpecialOffer(threadId, bookingId, data).pipe(delay(1000), map(res => {
      this.store.dispatch(new ThreadSendSpecialOfferSuccess({threadId: threadId, bookingId: bookingId}));
      this.getMessagesForThread(threadId, true).pipe(take(1)).subscribe();
      return res;
    }));
  }

  bulkArchive(data) {
    return this.threadService.bulkArchive(data).pipe(map(res => {
      data.thread_ids.forEach(id => {
        this.store.dispatch(new ThreadRemoveThreadSuccess({thread_id: id}));
      });
      return res;
    }));
  }
}
