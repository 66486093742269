import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FlexLayoutModule, LayoutGapStyleBuilder } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { HighchartsChartModule } from "highcharts-angular";

import { SelectComponent } from "../../components/select";
import { FlexAlignmentHackDirective } from "../../directives/flex-alignment-hack";
import { IFrameResizeDirective } from "../../directives/iframe-resize.directive";
import { ImgSizeOverlayDirective } from "../../directives/img-size-overlay.directive";
import { ResizeDetectorDirective } from "../../directives/resize-detector.directive";
import { ScrollDirective } from "../../directives/scroll.directive";
import { SafePipe } from "../../pipes/safe.pipe";
import { ReadMoreComponent } from "../inbox/components/read-more";
import { MapComponent } from "../listings/components/map.component";
import { ListingOwnerBlockDeletePopupComponent } from "../listings/components/popups/listing-owner-block-delete-popup";
import { ListingOwnerBlockPopupComponent } from "../listings/components/popups/listing-owner-block-popup";
import { MaterialModule } from "../material.module";
import { ChangeListingPopupComponent } from "../reservations/components/popups/change-listing-popup";
import { ExtendPaymentPopupComponent } from "../reservations/components/popups/extend-payment.popup";
import { BookingNotesPopupComponent } from "../reservations/components/popups/update-booking-notes";
import { VendorReservationReportPopupComponent } from "../reservations/components/popups/vendor-reservation-report-popup";
import { ReservationVendorTaskCardComponent } from "../reservations/components/reservation-vendor-task-card";
import { AddAccountPopupComponent } from "../settings/components/add-account.popup";
import { CreateMultiCalendarRuleComponent } from "../settings/components/multi-calendar-rules/create-multi-calendar-rule";
import { AddRulePopupComponent } from "../settings/components/routing-rules/add-rule.popup";
import { DateSelectComponent } from "../tasks/components/date-select";
import { OwnerTasksPageComponent } from "../tasks/components/owner-tasks-page";
import { AddInventoryPopupComponent } from "../tasks/components/popups/add-inventory-popup";
import { AssigneeChangePopupComponent } from "../tasks/components/popups/assignee-change-popup";
import { ConfirmTaskEscalatePopupComponent } from "../tasks/components/popups/confirm-escalate.popup";
import { DeleteCommentConfirmationPopupComponent } from "../tasks/components/popups/delete-comment-confirmation.popup";
import { DownloadReportPopupComponent } from "../tasks/components/popups/download-report-popup";
import { EmployeeChangePopupComponent } from "../tasks/components/popups/employee-change-popup";
import { ItemDescriptionPopupComponent } from "../tasks/components/popups/item-description-popup";
import { ListingChangePopupComponent } from "../tasks/components/popups/listing-change-popup";
import { MergeTasksPopupComponent } from "../tasks/components/popups/merge-tasks.popup";
import { NotesPopupComponent } from "../tasks/components/popups/notes-popup";
import { SlideShowPopupComponent } from "../tasks/components/popups/slide-show-popup";
import { TaskNoteUpdatePopupComponent } from "../tasks/components/popups/task-note-update-popup.component";
import { TaskNotesPopupComponent } from "../tasks/components/popups/task-notes.popup";
import { TaskShowCreateComponent } from "../tasks/components/task-show-create";
import { TasksComponent } from "../tasks/components/tasks-component";
import { TasksListComponent } from "../tasks/components/tasks-list-component";
import { TasksReportComponent } from "../tasks/components/tasks-report.component";

import { AddCardComponent } from "./components/add-card";
import { AirlockPopupComponent } from "./components/airlock-popup";
import { CentreSpinnerComponent } from "./components/centre-spinner";
import { ChangePasswordPopupComponent } from "./components/change-password-popup";
import { CompReportPopupComponent } from "./components/comp-report-popup";
import { GenericConfirmationPopupComponent } from "./components/confirmation-popup";
import { CounterComponent } from "./components/counter";
import { CreateProspectPopupComponent } from "./components/create-prospect-popup";
import { DatePickerOptionsComponent } from "./components/date-picker-options.component";
import { AdminDownloadReportPopupComponent } from "./components/download-report-admin-popup";
import { HomeOwnerReportDownloadPopupComponent } from "./components/download-report-home-popup";
import { ErrorComponent } from "./components/error";
import { ErrorPopupComponent } from "./components/error-popup";
import { HeaderComponent } from "./components/header";
import { ImageCardComponent } from "./components/image-card";
import { ImageSlideShowComponent } from "./components/image-slide-show";
import { LayoutMainComponent } from "./components/main";
import { ModalPopupLayoutComponent } from "./components/modal-popup-layout";
import { MonthlyBreakdownChartComponent } from "./components/monthly-breakdown-chart";
import { LayoutNoHeaderComponent } from "./components/noheader";
import { NotFoundComponent } from "./components/not-found";
import { LayoutOwnerMainComponent } from "./components/owner-main";
import { LayoutOwnerMainFullWidthComponent } from "./components/owner-main-fullwidth";
import { LayoutOwnerMainGenericComponent } from "./components/owner-main-generic";
import { OwnerSidebarComponent } from "./components/owner-sidebar";
import { OwnerTaskCardComponent } from "./components/owner-task-card";
import { PacingReportPopupComponent } from "./components/pacing-report-popup";
import { PayoutReportComponent } from "./components/payout-report.component";
import { PropertyIncomeReportPopupComponent } from "./components/property-income-report-popup";
import { QuantityInputComponent } from "./components/quantity-input";
import { RentalChannelBreakdownBarChartComponent } from "./components/rental-channel-breakdown-bar-chart";
import { RentalChannelBreakdownPieChartComponent } from "./components/rental-channel-breakdown-pie-chart";
import { SmallCalendarComponent } from "./components/small-calendar";
import { SmallCalendarDateTileComponent } from "./components/small-calendar-date-tile";
import { StickyComponent } from "./components/sticky-container";
import { CustomLayoutGapStyleBuilder } from "./custom-layout-gap-style-builder";
import { ActualTextPipe } from "./pipes/correct-text-display.pipe";
import { DateFixPipe } from "./pipes/date-fix.pipe";
import { DateFormattingPipe } from "./pipes/dateTime-format.pipe";
import { HTMLPipe } from "./pipes/html";
import { MobileTextPipe } from "./pipes/mobile-text.pipe";
import { NewLinePipe } from "./pipes/newline";
import { NumberToCurrencyPipe } from "./pipes/number-to-currency.pipe";
import { RoundOffPipe } from "./pipes/round";
import { SlugToStringPipe } from "./pipes/slug-to-string";
import { TimeAgoPipe } from "./pipes/time-ago";
import { TrimPipe } from "./pipes/trim";
import { TruncatePipe } from "./pipes/truncate";
import { UTCtoLocalTimeZonePipe } from "./pipes/utc-to-local-time.pipe";

const MODULES = [
  MaterialModule,
  CommonModule,
  RouterModule,
  ReactiveFormsModule,
  FormsModule,
  HttpClientModule,
  FlexLayoutModule,
  NgbModule,
  NgxChartsModule,
  HighchartsChartModule
];

const DIRECTIVES = [
  FlexAlignmentHackDirective,
  ScrollDirective,
  IFrameResizeDirective,
  ResizeDetectorDirective,
  ImgSizeOverlayDirective
];

const COMPONENTS = [
  LayoutMainComponent,
  ModalPopupLayoutComponent,
  LayoutNoHeaderComponent,
  LayoutOwnerMainComponent,
  LayoutOwnerMainFullWidthComponent,
  LayoutOwnerMainGenericComponent,
  HeaderComponent,
  CentreSpinnerComponent,
  ErrorComponent,
  OwnerSidebarComponent,
  RentalChannelBreakdownPieChartComponent,
  RentalChannelBreakdownBarChartComponent,
  MonthlyBreakdownChartComponent,
  CounterComponent,
  SmallCalendarComponent,
  SmallCalendarDateTileComponent,
  DatePickerOptionsComponent,
  MapComponent,
  NotFoundComponent,
  QuantityInputComponent,
  ImageSlideShowComponent,
  ImageCardComponent,
  AddCardComponent,
  StickyComponent,
  OwnerTasksPageComponent,
  OwnerTaskCardComponent,
  TasksListComponent,
  TasksComponent,
  TaskShowCreateComponent,
  TasksReportComponent,
  SelectComponent,
  ReservationVendorTaskCardComponent,
  ReadMoreComponent,
  ActualTextPipe,
  TruncatePipe,
  TrimPipe,
  NumberToCurrencyPipe,
  TimeAgoPipe,
  NewLinePipe,
  HTMLPipe,
  RoundOffPipe,
  UTCtoLocalTimeZonePipe,
  DateFormattingPipe,
  SlugToStringPipe,
  MobileTextPipe,
  DateFixPipe,
  SafePipe
];

const ENTRY_COMPONENTS = [
  PropertyIncomeReportPopupComponent,
  HomeOwnerReportDownloadPopupComponent,
  PayoutReportComponent,
  CompReportPopupComponent,
  VendorReservationReportPopupComponent,
  HomeOwnerReportDownloadPopupComponent,
  AdminDownloadReportPopupComponent,
  NotesPopupComponent,
  ListingChangePopupComponent,
  EmployeeChangePopupComponent,
  DateSelectComponent,
  CreateProspectPopupComponent,
  GenericConfirmationPopupComponent,
  BookingNotesPopupComponent,
  ExtendPaymentPopupComponent,
  ErrorPopupComponent,
  CreateMultiCalendarRuleComponent,
  ItemDescriptionPopupComponent,
  AirlockPopupComponent,
  AddRulePopupComponent,
  AddAccountPopupComponent,
  TaskNoteUpdatePopupComponent,
  ConfirmTaskEscalatePopupComponent,
  SlideShowPopupComponent,
  AddInventoryPopupComponent,
  AssigneeChangePopupComponent,
  MergeTasksPopupComponent,
  DeleteCommentConfirmationPopupComponent,
  TaskNotesPopupComponent,
  PacingReportPopupComponent,
  ListingOwnerBlockDeletePopupComponent,
  ListingOwnerBlockPopupComponent,
  DownloadReportPopupComponent,
  ChangePasswordPopupComponent,
  ChangeListingPopupComponent
];

@NgModule({
  declarations: [
    ...DIRECTIVES,
    ...COMPONENTS,
    ...ENTRY_COMPONENTS,
  ],
  entryComponents: [
    ...ENTRY_COMPONENTS,
  ],
  imports: [
    ...MODULES
  ],
  exports: [
    ...MODULES,
    ...DIRECTIVES,
    ...COMPONENTS,
    ...ENTRY_COMPONENTS,
  ],
  providers: [
    { provide: LayoutGapStyleBuilder, useClass: CustomLayoutGapStyleBuilder }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
}
