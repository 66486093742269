import { AfterViewInit, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { takeWhile } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { ListingCannedMessagePopupComponent } from "../../listings/components/popups/listing-canned-message-popup";
import { CannedResponseFull } from "../../../models/new/automation/canned-response-full.model";
import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { CannedResponseRepository } from "../../../repository/canned-response.repository";
import { ListingRepository } from "../../../repository/listing.repository";
import { AppService } from "../../../services/app.service";

/**
 * Created by ubuntu on 9/7/17.
 */
@Component({
  selector: "sd-settings-canned-responses",
  template: `
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px" style="margin-top: 10px;"
         class="bottom-padding">

      <div fxLayoutAlign="start center" *ngIf="!listingId">
        <button mat-raised-button color="primary" (click)="openTools()">
          <mat-icon>arrow_back</mat-icon>
          Back
        </button>
      </div>

      <div id="content" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">

        <div fxLayoutAlign="space-between start" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="20px"
             fxLayoutAlign.lt-sm="space-between center">
          <div fxLayout="column" fxLayoutAlign="center start" fxLayoutAlign.lt-sm="space-between center"
               fxLayoutGap="10px" class="para">
            <span class="heading">Canned Responses</span>
            <span class="hint">You can create canned responses.</span>
          </div>
          <div>
            <button mat-icon-button (click)="refresh()">
              <mat-icon>autorenew</mat-icon>
            </button>
            <button mat-raised-button color="accent" (click)="addCannedResponse()">
              Add New Canned Response
            </button>
          </div>
        </div>

        <hr>

        <div fxLayoutAlign="center center">

          <mat-spinner color="accent" *ngIf="isLoading" [diameter]="60" [strokeWidth]="5"></mat-spinner>
          <div fxLayout="column" *ngIf="isLoaded" fxLayoutGap="10px" fxFlex="100%">
            <h3 style="text-align: center" *ngIf="messages.length === 0">
              No Canned Responses Found
            </h3>

            <sd-canned-responses-detail *ngFor="let item of messages" [item]="item"></sd-canned-responses-detail>
          </div>
        </div>

      </div>


    </div>
  `,
  styles: [
      `
      .padding {
        padding: -10px -10px -10px -10px;
        background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
      }

      .content {
        font-size: 10px;
      }

      .heading {
        font-weight: bolder;
        font-size: 22px;
      }

      .content {
        font-size: 12px;
        line-height: 130%;
      }

      h3 {
        font-weight: bolder !important;
        letter-spacing: 1px !important;
        font-size: 20px !important;
        font-family: 'Montserrat', sans-serif !important;
      }

      .mat-card {
        border: 1px solid lightgrey !important;
        box-shadow: none !important;
      }

      .hint {
        font-size: 12px;
        margin-left: 2px;
      }

      #spinner {
        position: fixed;
        top: 45%;
        right: 40%
      }


    `
  ]
})
export class SettingsCannedResponsesComponent implements OnInit, AfterViewInit, OnDestroy {

  isAlive = true;
  isLoading = false;
  isLoaded = false;
  isDeleting = false;

  @Input() messages: CannedResponseFull[] = [];
  listings: ListingCompact[] = [];
  @Input() listingId;
  private dialogRef: MatDialogRef<any>;

  constructor(private router: Router, private route: ActivatedRoute,
              private dialog: MatDialog, private cannedResponseRepo: CannedResponseRepository,
              private listingrepo: ListingRepository,
              private appService: AppService) {
  }

  ngOnInit(): void {
    console.log("onInit sd-listing-channel-tab");

    if (!this.listingId) {
      console.log("if entered");
      this.setUpSavedMessages();
    } else {
      this.isLoaded = true;
      this.isLoading = false;
    }

    console.log(this.messages);

    this.listingrepo.getAcceptedListings().pipe(takeWhile(() => this.isAlive)).subscribe((listings) => {
      this.listings = listings;
    });
  }

  ngAfterViewInit() {
    this.appService.changeAppTitle("Settings - Canned-Responses");
  }

  setUpSavedMessages() {

    this.cannedResponseRepo.getIsCannedResponseLoading().subscribe(l => this.isLoading = l);
    this.cannedResponseRepo.getIsCannedResponseLoaded().subscribe(l => this.isLoaded = l);
    this.cannedResponseRepo.getAllCannedResponses(false, this.listingId).subscribe(messages => {
      console.log(messages);
      this.messages = messages;
      console.log(this.messages);
    });
  }

  addCannedResponse() {
    this.dialogRef = this.dialog.open(ListingCannedMessagePopupComponent);
    const instance = this.dialogRef.componentInstance;
    instance.popUpTitle = "Add New Canned Response";
    instance.listings = this.listings;
    instance.listingId = this.listingId;
    instance.rule = "include";
    this.dialogRef.updateSize("100%", "100%");
  }

  getListingsTitle(ids: number[]) {
    if (isNullOrUndefined(ids)) {
      return;
    }
    return this.listings.filter(listing => ids.includes(listing.id)).map(listing => listing.title).join(", ");
  }

  openTools() {
    this.router.navigate(["/settings/tools"]);
  }

  refresh() {
    this.cannedResponseRepo.getAllCannedResponses(true, this.listingId).subscribe(
      (messages) => {
        this.messages = messages;
        console.log(this.messages);
      }
      , (error) => {
        console.log(error);
      });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
