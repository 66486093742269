import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { takeWhile } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { CannedResponseFull } from "../../../models/new/automation/canned-response-full.model";
import { BookingFull } from "../../../models/new/booking/booking-full.model";
import { ThreadFull } from "../../../models/new/inbox/thread-full";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { UserFull } from "../../../models/new/user/user-full.model";
import { ThreadModelUtil } from "../../../models/utils/thread-model.util";
import { State } from "../../../reducers";
import { CannedResponseRepository } from "../../../repository/canned-response.repository";
import { ThreadRepository } from "../../../repository/thread.repository";
import { QuillUtils } from "../../../utils/quill.util";
import { NotesPopupComponent } from "../../tasks/components/popups/notes-popup";

@Component({
  selector: "sd-response-card",
  template: `
    <div fxLayout="column" class="border-visible">
      <div fxLayoutAlign="space-between center">

        <mat-tab-group class="full-width" [disableRipple]="true" [dynamicHeight]="true" #tabGroup
                       [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChanged($event)">
          <ng-container *ngFor="let tab of tabs">
            <ng-container [ngSwitch]="tab.slug">
              <ng-container *ngSwitchCase="'airbnb'">
                <mat-tab *ngIf="isAirbnbEnabled" class="label2"
                         [label]="selectedThread?.booking_pal_thread_id ? 'BOOKINGPAL' : 'AIRBNB'">
                  <sd-message-box (text)="airbnbChanged($event)" [textModel]="airbnb"></sd-message-box>
                </mat-tab>
              </ng-container>
              <ng-container *ngSwitchCase="'email'">
                <mat-tab *ngIf="isEmailEnabled" label="EMAIL">
                  <sd-email-box (subject)="subjectChanged($event)" (text)="emailChanged($event)"
                                [subjectModel]="subject"
                                [textModel]="email"></sd-email-box>
                </mat-tab>
              </ng-container>
              <ng-container *ngSwitchCase="'sms'">
                <mat-tab *ngIf="isPhoneEnabled" label="SMS">
                  <sd-message-box (text)="phoneChanged($event)" [textModel]="phone"></sd-message-box>
                </mat-tab>
              </ng-container>
              <ng-container *ngSwitchCase="'note'">
                <mat-tab *ngIf="user?.is_admin || user?.managementContact.data.category === 'agent'" label="NOTE">
                  <sd-message-box (text)="noteChanged($event)" (mentions)="mentionChanged($event)"
                                  [admins]="adminOptions" [textModel]="note"></sd-message-box>
                </mat-tab>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-tab-group>
      </div>


      <div fxLayout="column" fxLayoutAlign="center center" style="padding-top: 20px; padding-bottom: 10px;"
           *ngIf="!isAirbnbEnabled && !isEmailEnabled && !isPhoneEnabled">
        <span style="font-size: small">No channel exist for communication.</span>
      </div>
      <mat-error *ngIf="showError" style="font-size: x-small; margin-left: 10px;">The {{errorField}} field is
        required.
      </mat-error>


      <div fxLayout="row" fxLayout.lt-md="column" fxLayout.md="column" fxLayoutAlign="end center" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="start" fxLayoutGap="10px">
          <button (click)="refreshMessages()" mat-icon-button matTooltip="Refresh Messages" class="margin-10">
            <mat-icon>replay</mat-icon>
          </button>
          <mat-radio-button
            matTooltip="Mark For Followup"
            *ngIf="!isMarkingForFollowup"
            [checked]="selectedThread.is_marked_for_followup"
            (click)="alterArchived()">
          </mat-radio-button>
          <span *ngIf="!isMarkingForFollowup" style="font-size: small;">Mark for Follow-up</span>
          <div fxLayoutAlign="center center">
            <mat-spinner *ngIf="isMarkingForFollowup" color="accent" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          </div>
          <button mat-button matSuffix mat-icon-button fxFlexAlign="end" (click)="openFullScreen()"
                  *ngIf="!emailTabSelected">
            <mat-icon>fullscreen</mat-icon>
          </button>
          <button color="primary" mat-raised-button *ngIf="!isFromReservation" fxHide fxShow.lt-md fxShow.md
                  (click)="changeVisibility()">
            Show Details
          </button>
        </div>

        <div fxLayout="row">
          <div fxFlex="50%" fxFlex.lt-md="100%" fxFlex.md="100%" fxLayoutAlign="start center">
            <sd-select fxFill placeholder="Canned Response"
                       [control]="savedResponseControl"
                       [options]="savedResponsesOptions"
                       (selectionChanged)="savedResponseSelected($event)"></sd-select>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-spinner color="accent" *ngIf="sending" [diameter]="30" [strokeWidth]="4"></mat-spinner>
            <button mat-raised-button [disabled]="sending || !isAirbnbEnabled && !isEmailEnabled && !isPhoneEnabled"
                    color="accent" class="margin-10left" (click)="sendMessage()">{{buttonValue}}
            </button>
          </div>
        </div>
      </div>
    </div>

  `,
  styles: [`

    #options {
      height: 50px;
    }

    #text {
      height: 70px;
    }

    .margin-10 {
      margin-top: -10px;
    }

    .border-visible {
      border-width: 1px;
      border-color: gray;
      border-style: solid;
      padding-bottom: 10px;
    }

    .margin-10left {
      margin-left: 10px;
    }

    .full-width {
      width: 100%;
    }

    /deep/ .mat-tab-label {
      line-height: 38px !important;
      height: 38px !important;
    }

    /deep/ .mat-ink-bar {
      background-color: #194267 !important;
      height: 5px !important;
    }

    /deep/ .mat-tab-label {
      opacity: 1 !important;
      font-weight: bold;
    }

    .card {
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%); /* Chrome10+,Safari5.1+ */;
      padding: 10px !important;
      font-family: 'Roboto', sans-serif;
      font-size: x-small;
      white-space: pre-line;
      color: gray;
      display: flex;
      height: auto;
      flex-direction: column-reverse;
      overflow: scroll;
      overflow-x: hidden !important;
      cursor: pointer;
      max-height: 250px;
    }

  `]
})

export class ResponseCardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("tabGroup", {static: true}) tabGroup;
  isMarkedForFollowup;
  isMarkingForFollowup = false;

  savedResponseControl: FormControl = new FormControl(null);
  savedResponses: CannedResponseFull[] = [];
  savedResponsesOptions: { title: string, value: any }[];

  buttonValue = "Send";
  selectedIndex = 0;
  isLoading = false;
  isLoaded = false;
  listingId;
  email = "";
  airbnb = "";
  phone = "";
  note = "";
  emailMessage = "";
  airbnbMessage = "";
  phoneMessage = "";
  noteMessage = "";
  mentions: number[] = [];
  subject = "New message!";
  sending = false;
  errorField = "message";
  tabs: { title: string, url: string, slug: string }[] = [];
  selectedThread: ThreadFull;
  selectedBooking: BookingFull;
  showError = false;
  isAirbnbEnabled = true;
  isEmailEnabled = true;
  isPhoneEnabled = true;
  guest: UserCompact;
  @Output() refresh = new EventEmitter();
  @Output() visibilityChange = new EventEmitter();
  emailTabSelected: boolean;
  responseFilter: FormControl;
  @Input() isFromReservation = false;
  @Input() user: UserFull;
  @Input() adminOptions: { value: string, id: number }[];
  private isAlive = true;
  private dialogRef: MatDialogRef<any>;

  constructor(private threadRepo: ThreadRepository,
              private store: Store<State>,
              private cannedResponseRepo: CannedResponseRepository,
              private dialog: MatDialog) {
  }

  @Input() set thread(thread: ThreadFull) {
    this.selectedThread = thread;
    this.isAirbnbEnabled = !(this.selectedThread && this.selectedBooking && (this.selectedBooking.source === "referral" || this.selectedBooking.source === "stayduvet" || this.selectedBooking.source === "homeaway"));
    this.emailTabSelected = this.isAirbnbEnabled ? this.isEmailEnabled ? this.tabGroup.selectedIndex === 1 : false : this.isEmailEnabled ? this.tabGroup.selectedIndex === 0 : false;
    console.log("selected index", this.tabGroup.selectedIndex);
  }

  @Input() set booking(booking: BookingFull) {
    this.selectedBooking = booking;
    this.isAirbnbEnabled = !(this.selectedThread && this.selectedBooking && (this.selectedBooking.source === "referral" || this.selectedBooking.source === "stayduvet" || this.selectedBooking.source === "homeaway"));

    this.listingId = this.selectedBooking.property_id;
    this.setUpSavedMessages();

  }

  ngOnInit(): void {
    console.log("onInit ");
    this.guest = ThreadModelUtil.getGuest(this.selectedThread);

    if (this.selectedBooking.source === "airbnb") {
      this.tabs.push(
        {title: "AIRBNB", url: "airbnb", slug: "airbnb"}
      );
    }

    if (isNullOrUndefined(this.guest.email) || this.guest.email.trim() === "") {
      this.isEmailEnabled = false;
    } else {
      this.tabs.push(
        {title: "EMAIL", url: "email", slug: "email"}
      );
    }

    if (isNullOrUndefined(this.guest.phone_number) || this.guest.phone_number.trim() === "") {
      this.isPhoneEnabled = false;
    } else {
      this.tabs.push(
        {title: "SMS", url: "sms", slug: "sms"}
      );
    }

    this.tabs.push(
      {title: "NOTE", url: "note", slug: "note"}
    );
  }

  ngAfterViewInit() {
    this.emailTabSelected = this.isAirbnbEnabled ? false : this.isEmailEnabled;
  }

  airbnbChanged(newText: string) {
    this.airbnbMessage = newText;
    this.showError = false;
  }

  phoneChanged(newText: string) {
    this.phoneMessage = newText;
    this.showError = false;
  }

  emailChanged(newText: string) {
    this.emailMessage = newText;
    this.showError = false;
  }

  noteChanged(newText: string) {
    this.noteMessage = newText;
    this.showError = false;
  }

  mentionChanged(ids: number[]) {
    this.mentions = ids;
  }

  subjectChanged(newText: string) {
    this.subject = newText;
    this.showError = false;
  }

  sendMessage() {

    const selectedTab = this.tabs[this.selectedIndex];

    console.log("selected", selectedTab, this.tabs);

    switch (selectedTab.slug) {
      case "airbnb": {

        if (isNullOrUndefined(this.airbnbMessage) || this.airbnbMessage.trim() === "") {
          this.showError = true;
          this.errorField = "message";
          return;
        }

        this.sending = true;

        if (!!this?.selectedThread?.booking_pal_thread_id) {
          this.threadRepo.sendBookingPalMessage({content: QuillUtils.removeExtraLines(this.airbnbMessage)}, this.selectedThread.id, this.selectedBooking.id).subscribe((success) => {
            this.sending = false;
            this.airbnbMessage = "";
            this.airbnb = "";
          }, err => {
            this.sending = false;
          });
        } else {
          this.threadRepo.sendAirbnbMessage({content: QuillUtils.removeExtraLines(this.airbnbMessage)}, this.selectedThread.id, this.selectedBooking.id).subscribe((success) => {
            this.sending = false;
            this.airbnbMessage = "";
            this.airbnb = "";
          }, err => {
            this.sending = false;
          });
        }

        break;
      }
      case "email": {

        console.log("[Email]", this.emailMessage);

        if (isNullOrUndefined(this.emailMessage)) {
          this.showError = true;
          this.errorField = "message";
          return;
        } else {
          let message = this.emailMessage;
          message = message.replace(/(<([^>]+)>)/ig, "");
          if (message.trim() === "") {
            this.showError = true;
            this.errorField = "message";
            return;

          }
        }

        if (isNullOrUndefined(this.subject) || this.subject.trim() === "") {
          this.showError = true;
          this.errorField = "subject";
          return;
        }

        this.sending = true;
        this.emailMessage = QuillUtils.removeExtraLinesFromHtml(QuillUtils.removeMentionHtml(this.emailMessage));
        const data = {content: this.emailMessage, subject: this.subject};
        this.threadRepo.sendEmailMessage(data, this.selectedThread.id, this.selectedBooking.id).subscribe(() => {
          this.sending = false;
          this.email = "";
        }, err => {
          this.sending = false;
        });
        break;
      }
      case "sms": {

        if (isNullOrUndefined(this.phoneMessage) || this.phoneMessage.trim() === "") {
          this.showError = true;
          this.errorField = "message";
          return;
        }

        this.sending = true;
        this.threadRepo.sendSMSMessage({content: QuillUtils.removeExtraLines(this.phoneMessage)}, this.selectedThread.id, this.selectedBooking.id).subscribe(() => {
          this.sending = false;
          this.phoneMessage = "";
          this.phone = "";
        }, err => {
          this.sending = false;
        });
        break;
      }
      case "note": {

        if (isNullOrUndefined(this.noteMessage) || this.noteMessage.trim() === "") {
          this.showError = true;
          this.errorField = "message";
          return;
        }

        this.sending = true;
        this.threadRepo.addNote({
          description: QuillUtils.removeExtraLines(this.noteMessage),
          thread_id: this.selectedThread.id,
          mentions: this.mentions
        }).subscribe(res => {
          this.sending = false;
          this.noteMessage = "";
          this.note = "";
        }, err => {
          this.sending = false;
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  savedResponseSelected(savedResponse: any) {
    this.airbnb = savedResponse.message;
    this.phone = savedResponse.message;
    this.email = savedResponse.message;
    this.note = savedResponse.message;
  }

  alterArchived() {
    this.isMarkingForFollowup = true;
    this.threadRepo.updateThread(this.selectedThread.id, {
      ...this.selectedThread,
      is_marked_for_followup: !this.selectedThread.is_marked_for_followup
    }).subscribe((thread) => {
      this.isMarkingForFollowup = false;
      this.selectedThread = thread;
    });
  }

  setUpSavedMessages() {
    this.cannedResponseRepo.getAllCannedResponses(false, this.listingId).pipe(takeWhile(() => this.isAlive)).subscribe((messages) => {
      if (messages.length > 0) {
        this.savedResponses = messages.filter(message => {
          const propertyIds = message.property_ids || [];
          if (message.rule === "include") {
            return propertyIds.includes(this.listingId);
          } else {
            return !propertyIds.includes(this.listingId);
          }
        });
        this.savedResponsesOptions = this.savedResponses.map(response => {
          return {title: response.title, value: response};
        });
      }
    });
  }

  refreshMessages() {
    this.refresh.emit();
  }

  changeVisibility() {
    this.visibilityChange.emit();
  }

  openFullScreen() {

    if (this.isAirbnbEnabled) {
      this.dialogRef = this.dialog.open(NotesPopupComponent, {
        data: {
          text: QuillUtils.removeExtraLines(this.airbnbMessage),
          isInbox: true
        }
      });

      this.dialogRef.afterClosed().subscribe((text) => {
        this.airbnbChanged(text);
      });
    } else if (this.isPhoneEnabled) {
      this.dialogRef = this.dialog.open(NotesPopupComponent, {
        data: {
          text: QuillUtils.removeExtraLines(this.phoneMessage),
          isInbox: true
        }
      });

      this.dialogRef.afterClosed().subscribe((text) => {
        this.phoneChanged(text);
        this.phone = text;
      });
    } else {
      this.dialogRef = this.dialog.open(NotesPopupComponent, {
        data: {
          text: QuillUtils.removeExtraLines(this.noteMessage),
          isInbox: true
        }
      });

      this.dialogRef.afterClosed().subscribe((text) => {
        this.noteChanged(text);
        this.note = text;
      });
    }

    this.dialogRef.updateSize("100%", "100%");

  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  tabChanged(event) {
    if (event && event.tab) {
      this.emailTabSelected = event.tab.textLabel === "EMAIL";
    }
    if (event && event.tab.textLabel === "NOTE") {
      this.buttonValue = "Add Note";
    } else {
      this.buttonValue = "Send";
    }
  }
}
