import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import Quill from "quill";
import "quill-mention";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { ListingCompact } from "../../../../models/new/listing/listing-compact.model";
import { CannedResponseRepository } from "../../../../repository/canned-response.repository";
import { StayDuvetService } from "../../../../services/stayduvet";
import { getAllAlertTypes } from "../../../../utils/utils";
import { QuillUtils } from "../../../../utils/quill.util";

@Component({
  selector: "sd-listing-canned-message-popup",
  template: `
    <sd-modal-popup-layout title="{{popUpTitle}}" [showRefreshAction]="true">

      <form fxLayout="column" [formGroup]="formGroup" (ngSubmit)="formGroup.valid && saveButtonCLicked()">
        <mat-form-field [color]="'accent'" class="detailField">
          <input type="text" matInput color="accent" formControlName="title" placeholder="Title">
          <mat-error>This field is required.</mat-error>
        </mat-form-field>
        <div class="detailField1">
          <div id="message"></div>
        </div>
        <span style="color:red; margin-top: 2px;" *ngIf="showError">This field is required</span>
        <div fxLayout="column">
          <mat-form-field [color]="'accent'" style="width: 300px;">
            <mat-select
              color="accent"
              floatPlaceholder="never"
              formControlName="rule"
              placeholder="Apply For">
              <mat-option *ngFor="let alertType of getAllAlertTypes" [value]="alertType.slug">
                {{alertType.title}}
              </mat-option>
            </mat-select>
            <mat-error>required.</mat-error>
          </mat-form-field>


          <mat-form-field [color]="'accent'" style="width:50%">
            <mat-select multiple placeholder="Select Listings"
                        (openedChange)="!$event&&onMatSelectClose()"
                        floatPlaceholder="never" formControlName='property_ids'>

              <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                <input matInput placeholder="Search Listing" [formControl]="listingFilter">
              </mat-form-field>

              <div fxLayout="column">
                <button class="select-button" mat-button (click)="onSelectAll()">Select All</button>
                <button class="select-button" mat-button (click)="onSelectNone()">Select None</button>
              </div>

              <mat-option *ngFor="let listing of filteredListings" [value]="listing.id">
                {{ listing.title }}
              </mat-option>

            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <mat-spinner *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <button mat-raised-button color="warn" *ngIf="isEditType" [disabled]="isSaving" (click)="deleteMessage()">
            Delete
          </button>
          <button mat-raised-button color="accent" [disabled]="isSaving || showError">{{buttonText}}</button>
        </div>
      </form>


    </sd-modal-popup-layout>
  `,
  styles: [`

    .title {
      font-weight: bolder;
      font-size: 22px;
      padding-left: 10px;
      padding-right: 10px;
      height: 30px;
      width: 100%;
    }

    .detailField {
      content-editable: true;
      font-size: 18px;
      height: 200px;
      width: 100%;
      margin-top: 10px;
      border-style: solid;
      border-width: 0.1px;
      padding: 5px;
      border-color: #c8c8c8;
    }

    .detailField1 {
      content-editable: true;
      font-size: 18px;
      height: 300px;
      width: 100%;
      margin-top: 10px;
      border-style: solid;
      border-width: 0.1px;
      padding: 5px;
      border-color: #c8c8c8;
    }

    textarea {
      resize: vertical;
    }

    mat-error {
      font-size: x-small;
    }

    mat-spinner {
      width: 30px;
      height: 30px;
    }

    .select-button {
      padding: 6px;
      text-align: left;
      font-size: 17px;
      padding-left: 10px;
      font-weight: bolder;
    }

  `]
})

export class ListingCannedMessagePopupComponent implements OnInit, OnDestroy {
  @Input() popUpTitle;
  @Input() listingIds;
  @Input() messageId;
  @Input() title;
  @Input() message;
  @Input() isEditType = false;
  @Input() listingId;
  @Input() rule;

  quillBody: Quill;
  titleVariables = QuillUtils.VARIABLES;
  buttonText;
  showError = true;

  formGroup: FormGroup;
  messageTitle: FormControl;
  messageContent: FormControl;
  formListingIds: FormControl;
  ruleType: FormControl;
  listingFilter: FormControl = new FormControl();

  @Input() listings: ListingCompact[] = [];
  getAllAlertTypes;
  filteredListings: ListingCompact[] = [];
  isSaving = false;
  isAlive = true;

  constructor(private service: StayDuvetService,
              private cannedResponseRepo: CannedResponseRepository,
              private dialog: MatDialog) {

    this.getAllAlertTypes = getAllAlertTypes();
    this.messageTitle = new FormControl(null, [Validators.required]);
    this.messageContent = new FormControl(null, [Validators.required]);
    this.formListingIds = new FormControl([], []);
    this.ruleType = new FormControl("include", [Validators.required]);
    this.formGroup = new FormGroup({
      title: this.messageTitle,
      message: this.messageContent,
      property_ids: this.formListingIds,
      rule: this.ruleType
    });

  }

  ngOnInit() {
    if (this.isEditType) {
      this.buttonText = "Update";
      this.messageTitle.setValue(this.title);
      this.messageContent.setValue(this.message);
      this.formListingIds.setValue(this.listingIds);
      this.ruleType.setValue(this.rule);
    } else {
      this.buttonText = "Add";
      if (this.listingId) {
        this.formListingIds.setValue([this.listingId]);
        this.ruleType.setValue(this.rule);
      }
    }

    this.filteredListings = this.listings;

    const values = this.titleVariables;
    this.quillBody = new Quill("#message", {
      theme: "bubble",
      modules: {
        mention: {
          allowedChars: /^[_A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["{"],
          isolatedCharacter: false,
          source: (searchTerm, renderList, mentionChar) => {

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(values[i]);
                }
              }
              renderList(matches, searchTerm);
            }
          },
          renderItem: (item, searchTerm) => item.value.replace("{", "{{"),
          dataAttributes: ["value"]
        },
      },
    });

    if (this.messageContent.value) {
      this.quillBody.setText(this.messageContent.value);
      this.quillBody.root.innerHTML = QuillUtils.replaceMentionWithClass(this.quillBody.root.innerHTML);
    }

    this.quillBody.on("text-change", () => {
      this.messageContent.setValue(this.quillBody.root.innerText);
      this.showError = this.messageContent.value.trim() === "";
    });

    this.listingFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged(),).subscribe((value) => {

      if (value) {
        this.filteredListings = this.listings.filter(l => l.title.toLowerCase().includes(value));
      } else {
        this.filteredListings = this.listings;
      }

      if (this.filteredListings.length === 0) {
        this.filteredListings = this.listings;
      }

    });

  }

  onMatSelectClose() {
    this.filteredListings = this.listings;
  }

  saveButtonCLicked() {
    if (!this.showError) {
      this.isSaving = true;

      const data = {
        ...this.formGroup.value,
        message: QuillUtils.removeExtraLines(this.messageContent.value)
      };

      if (this.isEditType) {
        this.cannedResponseRepo.updateCannedResponse(this.messageId, data).subscribe(item => {
          this.dialog.closeAll();
        }, () => {
          this.isSaving = false;
        });
      } else {
        this.cannedResponseRepo.createCannedResponse(data).subscribe(item => {
          this.dialog.closeAll();
        }, () => {
          this.isSaving = false;
        });
      }
    }

  }

  onSelectAll() {
    this.formListingIds.setValue(this.listings.map(listing => listing.id));
  }

  onSelectNone() {
    this.formListingIds.setValue([]);
  }

  deleteMessage() {
    this.isSaving = true;
    this.cannedResponseRepo.deleteCannedResponse(this.messageId).subscribe(() => {
      this.isSaving = false;
      this.dialog.closeAll();
    }, () => {
      this.isSaving = false;
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
