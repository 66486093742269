import { ListingTag } from "../../../models/new/listing/listing-tag.model";
import { Action } from "../../../actions/action";
import { OptionActionTypes } from "../../../actions/new/option";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { StateSubDivision } from "../../../models/state";
import { ChecklistCategory } from "../../../models/new/checklist-category";
import { Country } from "../../../models/country";
import Utils from "../../../utils/utils";
import { createSelector } from "reselect";
import { ContactActionTypes } from "../../../actions/new/contacts/contact";
import { UserFull } from "../../../models/new/user/user-full.model";


export interface OptionState {

  adminLoading: boolean;
  adminLoaded: boolean;
  adminEntities: { [id: number]: UserCompact };
  adminIds: number[];

  locationLoading: boolean;
  locationLoaded: boolean;
  locations: string[];

  tagLoading: boolean;
  tagLoaded: boolean;
  tags: ListingTag[];

  countryLoading: boolean;
  countryLoaded: boolean;
  countries: Country[];

  states: { [countryCode: string]: StateSubDivision[] };
  statesLoading: { [countryCode: string]: boolean };
  statesLoaded: { [countryCode: string]: boolean };

  checklistCreating: boolean;
  checklistCategoryLoading: boolean;
  checklistCategoryLoaded: boolean;
  checklistCategories: ChecklistCategory[];

  taskAssignees: UserCompact[];
  taskAssigneesLoading: boolean;
  taskAssigneesLoaded: boolean;

  vendors: UserCompact[];
  vendorsLoading: boolean;
  vendorsLoaded: boolean;

}

const initialState: OptionState = {
  locationLoading: false,
  locationLoaded: false,
  locations: [],

  adminLoading: false,
  adminLoaded: false,
  adminIds: [],
  adminEntities: {},

  tagLoading: false,
  tagLoaded: false,
  tags: [],

  countryLoaded: false,
  countryLoading: false,
  countries: [],

  states: {},
  statesLoading: {},
  statesLoaded: {},

  checklistCreating: false,
  checklistCategoryLoading: false,
  checklistCategoryLoaded: false,
  checklistCategories: [],

  taskAssignees: [],
  taskAssigneesLoading: false,
  taskAssigneesLoaded: false,

  vendors: [],
  vendorsLoading: false,
  vendorsLoaded: false,
};


export function optionReducer(state: OptionState = initialState, action: Action): OptionState {
  switch (action.type) {

    case OptionActionTypes.ADMIN_INDEX_REQUEST: {
      return {
        ...state,
        adminLoading: true,
      };
    }
    case OptionActionTypes.ADMIN_INDEX_SUCCESS: {

      const admins = action.payload;
      const adminIds = admins.map(contact => contact.id);
      const adminEntities = Utils.normalize(admins);

      return {
        ...state,
        adminLoading: false,
        adminLoaded: true,
        adminIds: adminIds,
        adminEntities: adminEntities
      };
    }
    case OptionActionTypes.ADMIN_CREATE_SUCCESS: {
      const newAdmin = action.payload;
      return {
        ...state,
        adminEntities: {
          ...state.adminEntities,
          [newAdmin.id]: newAdmin
        },
        adminIds: [
          ...state.adminIds,
          newAdmin.id
        ]
      }
    }
    case OptionActionTypes.LOCATION_INDEX_REQUEST: {
      return {
        ...state,
        locationLoading: true
      };
    }

    case OptionActionTypes.LOCATION_INDEX_SUCCESS: {
      return {
        ...state,
        locationLoading: false,
        locationLoaded: true,
        locations: action.payload as string[]
      };
    }

    case OptionActionTypes.TAG_INDEX_REQUEST: {
      return {
        ...state,
        tagLoading: true
      };
    }

    case OptionActionTypes.TAG_INDEX_SUCCESS: {
      return {
        ...state,
        tagLoading: false,
        tagLoaded: true,
        tags: action.payload as ListingTag[]
      };
    }
    case OptionActionTypes.TAG_CREATE_SUCCESS: {
      return {
        ...state,
        tags: [
          ...state.tags,
          action.payload as ListingTag
        ]
      };
    }
    case OptionActionTypes.COUNTRY_INDEX_REQUEST: {
      return {
        ...state,
        countryLoading: true,
      };
    }
    case OptionActionTypes.COUNTRY_INDEX_SUCCESS: {
      return {
        ...state,
        countryLoaded: true,
        countryLoading: false,
        countries: action.payload as Country[]
      };
    }
    case OptionActionTypes.STATE_INDEX_REQUEST: {
      const countryCode = action.payload;

      return {
        ...state,
        statesLoading: {
          ...state.statesLoading,
          [countryCode]: true
        }
      };
    }
    case OptionActionTypes.STATE_INDEX_SUCCESS: {
      const countryCode = action.payload.countryCode;
      const states = action.payload.states;

      return {
        ...state,
        statesLoading: {
          ...state.statesLoading,
          [countryCode]: false
        },
        statesLoaded: {
          ...state.statesLoaded,
          [countryCode]: true
        },
        states: {
          ...state.states,
          [countryCode]: states
        }
      };
    }
    case OptionActionTypes.CHECKLIST_CATEGORY_INDEX_REQUEST: {
      return {
        ...state,
        checklistCategoryLoading: true,
      };
    }
    case OptionActionTypes.CHECKLIST_CATEGORY_INDEX_SUCCESS: {
      return {
        ...state,
        checklistCategoryLoaded: true,
        checklistCategoryLoading: false,
        checklistCategories: action.payload as ChecklistCategory[]
      }

    }
    case OptionActionTypes.CHECKLIST_CATEGORY_CREATE_REQUEST: {
      return {
        ...state,
        checklistCreating: true

      };
    }

    case OptionActionTypes.TASK_ASSIGNEES_INDEX_REQUEST: {
      return {
        ...state,
        taskAssigneesLoading: true
      }
    }
    case OptionActionTypes.TASK_ASSIGNEES_INDEX_SUCCESS: {
      const taskAssignees = action.payload;
      return {
        ...state,
        taskAssigneesLoading: false,
        taskAssigneesLoaded: true,
        taskAssignees: taskAssignees
      }
    }

    case OptionActionTypes.VENDOR_INDEX_REQUEST: {
      return {
        ...state,
        vendorsLoading: true
      }
    }

    case OptionActionTypes.VENDOR_INDEX_SUCCESS: {
      const vendors = action.payload;

      return {
        ...state,
        vendorsLoading: false,
        vendorsLoaded: true,
        vendors: vendors
      }
    }

    case ContactActionTypes.CREATE_SUCCESS: {
      const vendor = action.payload as UserFull;
      if(vendor.type === "management") {
        return {
          ...state,
          vendorsLoading: false,
          vendorsLoaded: true,
          vendors: [...state.vendors, vendor]
        }
      }
      else{
        return {...state}
      }
    }

    case ContactActionTypes.UPDATE_SUCCESS: {
      const vendor = action.payload as UserFull;
      if(vendor.type === "management") {
        return {
          ...state,
          vendorsLoading: false,
          vendorsLoaded: true,
          vendors: [...state.vendors, vendor]
        }
      }
      else{
        return {...state}
      }
    }

    default: {
      return state;
    }
  }

}


export const _getIsAdminLoading = (state: OptionState) => state.adminLoading;
export const _getIsAdminLoaded = (state: OptionState) => state.adminLoaded;
export const _getAdminEntities = (state: OptionState) => state.adminEntities;
export const _getAdminIds = (state: OptionState) => state.adminIds;
export const _getAllAdmins = createSelector(_getAdminEntities, _getAdminIds, (adminEntities, ids) => {
  return ids.map(id => adminEntities[id]);
});

export const _getIsLocationLoading = (state: OptionState) => state.locationLoading;
export const _getIsLocationLoaded = (state: OptionState) => state.locationLoaded;
export const _getAllLocations = (state: OptionState) => state.locations;

export const _getIsTagLoading = (state: OptionState) => state.tagLoading;
export const _getIsTagLoaded = (state: OptionState) => state.tagLoaded;
export const _getAllTags = (state: OptionState) => state.tags;

export const _getIsCountryLoading = (state: OptionState) => state.countryLoading;
export const _getIsCountryLoaded = (state: OptionState) => state.countryLoaded;
export const _getAllCountries = (state: OptionState) => state.countries;

export const _getIsStatesLoadingByCode = (state: OptionState, countryCode: string) => {
  return state.statesLoading[countryCode] !== null && state.statesLoading[countryCode];
};
export const _getIsStatesLoadedByCode = (state: OptionState, countryCode: string) => {
  return state.statesLoaded[countryCode] !== null && state.statesLoaded[countryCode];
};
export const _getStatesByCode = (state: OptionState, countryCode: string) => {
  return state.states[countryCode] !== null && state.states[countryCode];
};

export const _getIsChecklistCategoryLoading = (state: OptionState) => state.checklistCategoryLoading;
export const _getIsChecklistCategoryLoaded = (state: OptionState) => state.checklistCategoryLoaded;
export const _getAllChecklistCategories = (state: OptionState) => state.checklistCategories;

export const _getIsTaskAssigneesLoading = (state: OptionState) => state.taskAssigneesLoading;
export const _getIsTaskAssigneesLoaded = (state: OptionState) => state.taskAssigneesLoaded;
export const _getTaskAssigneesEntities = (state: OptionState) => state.taskAssignees;

export const _getIsVendorsLoading = (state: OptionState) => state.vendorsLoading;
export const _getIsVendorsLoaded = (state: OptionState) => state.vendorsLoaded;
export const _getAllVendors = (state: OptionState) => state.vendors;


