import { CustomVariable } from "../models/custom-variable";
import { MentionVariable } from "../models/mention-variable";

export class QuillUtils {

  static VARIABLES: MentionVariable[] = [
    {id: 1, value: "{user_full}}"},
    {id: 2, value: "{user_first}}"},
    {id: 3, value: "{host_full}}"},
    {id: 4, value: "{host_first}}"},
    {id: 5, value: "{airbnb_full}}"},
    {id: 6, value: "{airbnb_first}}"},
    {id: 7, value: "{homeowner_full}}"},
    {id: 8, value: "{homeowner_first}}"},
    {id: 9, value: "{checkin_date}}"},
    {id: 10, value: "{checkin_time}}"},
    {id: 11, value: "{checkout_date}}"},
    {id: 12, value: "{checkout_time}}"},
    {id: 13, value: "{planned_arrival_time}}"},
    {id: 14, value: "{planned_departure_time}}"},
    {id: 15, value: "{listing_title}}"},
    {id: 16, value: "{listing_headline}}"},
    {id: 17, value: "{listing_address}}"},
    {id: 18, value: "{listing_rooms_count}}"},
    {id: 19, value: "{listing_address_city}}"},
    {id: 20, value: "{notes_guest}}"},
    {id: 21, value: "{notes_cleaning}}"},
    {id: 22, value: "{notes_other}}"},
    {id: 23, value: "{recycling_frequency}}"},
    {id: 24, value: "{recycling_day}}"},
    {id: 25, value: "{trash_frequency}}"},
    {id: 26, value: "{trash_day}}"},
    {id: 27, value: "{listing_property_access}}"},
    {id: 28, value: "{listing_property_access_title}}"},
    {id: 29, value: "{listing_property_access_code}}"},
    {id: 30, value: "{listing_parking_note}}"},
    {id: 31, value: "{listing_entertainment_note}}"},
    {id: 32, value: "{listing_netflix_username}}"},
    {id: 33, value: "{listing_netflix_password}}"},
    {id: 34, value: "{listing_coffee_maker_type}}"},
    {id: 35, value: "{listing_wifi_note}}"},
    {id: 36, value: "{listing_wifi_network}}"},
    {id: 37, value: "{listing_wifi_password}}"},
    {id: 38, value: "{listing_checklist}}"},
    {id: 39, value: "{guest_full}}"},
    {id: 40, value: "{guest_first}}"},
    {id: 41, value: "{guest_email}}"},
    {id: 42, value: "{guest_phone}}"},
    {id: 43, value: "{fare_accommodation}}"},
    {id: 44, value: "{fare_cleaning}}"},
    {id: 45, value: "{fare_security}}"},
    {id: 46, value: "{host_payout}}"},
    {id: 47, value: "{host_service_fee}}"},
    {id: 48, value: "{host_taxes}}"},
    {id: 49, value: "{our_commission}}"},
    {id: 50, value: "{our_fee}}"},
    {id: 51, value: "{confirmation_code}}"},
    {id: 52, value: "{nights_count}}"},
    {id: 53, value: "{guests_count}}"},
    {id: 54, value: "{listing_concierge_information}}"},
    {id: 55, value: "{signature}}"},
    {id: 56, value: "{client_earning}}"},
    {id: 57, value: "{source}}"}
  ];

  static LISTING_VARIABLES: MentionVariable[] = [
    {id: 1, value: "{listing_title}}"},
    {id: 2, value: "{listing_headline}}"},
    {id: 3, value: "{listing_address}}"},
    {id: 4, value: "{listing_rooms_count}}"},
    {id: 5, value: "{listing_address_city}}"},
    {id: 6, value: "{notes_guest}}"},
    {id: 7, value: "{notes_cleaning}}"},
    {id: 8, value: "{notes_other}}"},
    {id: 9, value: "{recycling_frequency}}"},
    {id: 10, value: "{recycling_day}}"},
    {id: 11, value: "{trash_frequency}}"},
    {id: 12, value: "{trash_day}}"},
    {id: 13, value: "{listing_property_access}}"},
    {id: 14, value: "{listing_property_access_title}}"},
    {id: 15, value: "{listing_property_access_code}}"},
    {id: 16, value: "{listing_parking_note}}"},
    {id: 17, value: "{listing_entertainment_note}}"},
    {id: 18, value: "{listing_netflix_username}}"},
    {id: 19, value: "{listing_netflix_password}}"},
    {id: 20, value: "{listing_coffee_maker_type}}"},
    {id: 21, value: "{listing_wifi_note}}"},
    {id: 22, value: "{listing_wifi_network}}"},
    {id: 23, value: "{listing_wifi_password}}"},
    {id: 24, value: "{listing_checklist}}"},
    {id: 25, value: "{listing_concierge_information}}"}
  ];

  static removeMentionHtml(html: string, asText = false): string {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const elements = doc.getElementsByClassName("mention");

    console.log("element length is ", elements.length);

    while (elements.length > 0) {
      const element = elements[0];
      element.parentNode.replaceChild(doc.createTextNode(element.textContent), element);
    }

    return asText ? doc.body.textContent : doc.body.innerHTML;
  }

  static replaceMentionWithClass(text: string): string {
    return text.replace(new RegExp(/{{(.*?)}}/g), (match: string) => {

      const keyword = match.substr(1);
      const mentionVariable = this.VARIABLES.find(t => t.value === keyword);

      if (mentionVariable) {
        return `<span class=\"mention\" data-index=\"${mentionVariable.id}\" data-denotation-char=\"{\" data-id=\"${mentionVariable.id}\" data-value=\"${mentionVariable.value}\">﻿<span contenteditable=\"false\"><span class=\"ql-mention-denotation-char\">{</span>${mentionVariable.value}</span>﻿</span>`
      }
      return match;
    });
  }

  static removeExtraLinesFromHtml(html: string): string {
    return html.replace("<p><br></p>", "<br>");

  }

  static removeExtraLines(text: string): string {
    return text.replace(/\n{2}/g, "\n");
  }

  static getText(html: string): string {
    html = QuillUtils.removeMentionHtml(html);
    html = html.replace(/<\/p>\\*/g, "</p>\n");
    // html = html.replace(/<br>\\*/g, "\n");
    // html = html.replace(/<br\/>\\*/g, "\n");
    html = html.replace(/<[^>]+>/g, "");
    return html;
  }

  static addCustomVariables(variables: CustomVariable[]) {
    const length = this.VARIABLES.length;
    variables.forEach((v, index) => {
      const variableIndex = this.VARIABLES.map(variable => variable.value).indexOf("{" + v.key + "}}");
      if (variableIndex === -1) {
        this.VARIABLES.push({id: length + index, value: "{" + v.key + "}}"});
      }
    })
  }

}
