import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "sd-date-picker-options",
  template: `
    <div fxLayout="row" fxLayoutGap="10px" style="width:15%">
      <div fxLayout="column" fxLayoutGap="5px" style="cursor:pointer">
        <a (click)="thisWeekClicked()">This Week</a>
        <a (click)="thisMonthClicked()">This Month</a>
        <a (click)="thisYearClicked()">This Year</a>
      </div>
      <div fxLayout="column" fxLayoutGap="5px" style="cursor:pointer">
        <a (click)="lastWeekClicked()">Last Week</a>
        <a (click)="lastMonthClicked()">Last Month</a>
        <a (click)="lastYearClicked()">Last Year</a>
      </div>
    </div>
  `,
  styles: [``]
})

export class DatePickerOptionsComponent {

  @Output() thisWeek = new EventEmitter<{ start: Date, end: Date }>();
  @Output() lastWeek = new EventEmitter<{ start: Date, end: Date }>();
  @Output() thisMonth = new EventEmitter<{ start: Date, end: Date }>();
  @Output() lastMonth = new EventEmitter<{ start: Date, end: Date }>();
  @Output() thisYear = new EventEmitter<{ start: Date, end: Date }>();
  @Output() lastYear = new EventEmitter<{ start: Date, end: Date }>();

  date: Date;
  today;

  isUpdating = false;

  constructor() {
    this.today = new Date();
  }


  thisWeekClicked() {
    console.log(this.today.getDay(), this.today.getDate());
    const start = new Date(this.today.getFullYear(), this.today.getMonth(), (this.today.getDate() - (this.today.getDay() - 1)));
    const end = new Date(this.today.getFullYear(), this.today.getMonth(), (this.today.getDate() + (6 - (this.today.getDay() - 1))));
    this.thisWeek.emit({start: start, end: end});
  }


  lastWeekClicked() {
    const start = new Date(this.today.getFullYear(), this.today.getMonth(), (this.today.getDate() - ((this.today.getDay() + 6))));
    const end = new Date(this.today.getFullYear(), this.today.getMonth(), (this.today.getDate() - this.today.getDay()));
    this.lastWeek.emit({start: start, end: end});
  }


  thisMonthClicked() {
    let day;
    day = new Date(this.today.getFullYear(), (this.today.getMonth() + 1), 0).getDate();
    const start = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
    const end = new Date(this.today.getFullYear(), this.today.getMonth(), day);
    this.thisMonth.emit({start: start, end: end});
  }

  lastMonthClicked() {
    let day;
    day = new Date(this.today.getFullYear(), (this.today.getMonth()), 0).getDate();
    console.log(day);
    const start = new Date(this.today.getFullYear(), this.today.getMonth() - 1, 1);
    const end = new Date(this.today.getFullYear(), (this.today.getMonth() - 1), day);
    this.lastMonth.emit({start: start, end: end});
  }

  thisYearClicked() {
    const start = new Date(this.today.getFullYear(), 0, 1);
    const end = new Date(this.today.getFullYear(), 11, 31);
    this.thisYear.emit({start: start, end: end});
  }

  lastYearClicked() {
    const start = new Date((this.today.getFullYear() - 1), 0, 1);
    const end = new Date((this.today.getFullYear() - 1), 11, 31);
    this.lastYear.emit({start: start, end: end});
  }
}
