import {map, take} from "rxjs/operators";
import {
  getAllNeighbourhoodDrafts,
  getFullNeighbourhoodDraftById,
  getIsFullNeighbourhoodDraftLoaded,
  getIsFullNeighbourhoodDraftLoading,
  getIsNeighbourhoodDraftLoaded,
  getIsNeighbourhoodDraftLoading,
  State
} from "../../reducers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {SavedNeighbourhoodDraftsService} from "../../services/drafts/saved-neighbourhood-drafts.service";
import {SavedNeighbourhoodDraft} from "../../models/new/drafts/saved-neighbourhood-draft.model";
import {
  SavedNeighbourhoodDraftCreateRequest,
  SavedNeighbourhoodDraftCreateSuccess,
  SavedNeighbourhoodDraftDeleteRequest,
  SavedNeighbourhoodDraftDeleteSuccess,
  SavedNeighbourhoodDraftIndexRequest,
  SavedNeighbourhoodDraftIndexSuccess,
  SavedNeighbourhoodDraftUpdateRequest,
  SavedNeighbourhoodDraftUpdateSuccess
} from "../../actions/new/setttings/drafts/saved-neighbourhood-drafts";

@Injectable()
export class TheNeighbourhoodDraftRepository {

  constructor(private store: Store<State>,
              private neighbourhoodDraftService: SavedNeighbourhoodDraftsService) {
  }

  /**
   * Loading & Loaded
   */
  getIsSavedNeighbourhoodDraftLoading(): Observable<boolean> {
    return this.store.select(getIsNeighbourhoodDraftLoading);
  }

  getIsSavedNeighbourhoodDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsNeighbourhoodDraftLoaded);
  }

  getIsFullSavedNeighbourhoodDraftLoading(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullNeighbourhoodDraftLoading(state, draftId));
  }

  getIsFullSavedNeighbourhoodDraftLoaded(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullNeighbourhoodDraftLoaded(state, draftId));
  }

  /**
   * Service Methods
   */
  getAllSavedNeighbourhoodDrafts(force: boolean): Observable<SavedNeighbourhoodDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsSavedNeighbourhoodDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsSavedNeighbourhoodDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new SavedNeighbourhoodDraftIndexRequest());

      this.neighbourhoodDraftService.getNeighbourhoodDrafts().subscribe(response => {
        this.store.dispatch(new SavedNeighbourhoodDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(getAllNeighbourhoodDrafts).pipe(map(t => t as SavedNeighbourhoodDraft[]));
  }

  getSavedNeighbourhoodDraftById(draftId: number): Observable<SavedNeighbourhoodDraft> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsSavedNeighbourhoodDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsSavedNeighbourhoodDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new SavedNeighbourhoodDraftIndexRequest());
      this.neighbourhoodDraftService.getNeighbourhoodDrafts().subscribe(response => {
        this.store.dispatch(new SavedNeighbourhoodDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(state => getFullNeighbourhoodDraftById(state, draftId)).pipe(
      map(t => t as SavedNeighbourhoodDraft));
  }


  createSavedNeighbourhoodDraft(data: Partial<SavedNeighbourhoodDraft>): Observable<SavedNeighbourhoodDraft> {
    this.store.dispatch(new SavedNeighbourhoodDraftCreateRequest());
    return this.neighbourhoodDraftService.createNeighbourhoodDraft(data).pipe(map(res => {
      this.store.dispatch(new SavedNeighbourhoodDraftCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateSavedNeighbourhoodDraft(draftId: number, data: Partial<SavedNeighbourhoodDraft>): Observable<SavedNeighbourhoodDraft> {
    this.store.dispatch(new SavedNeighbourhoodDraftUpdateRequest(draftId));
    return this.neighbourhoodDraftService.updateNeighbourhoodDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new SavedNeighbourhoodDraftUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deleteSavedNeighbourhoodDraft(draftId: number): Observable<null> {
    this.store.dispatch(new SavedNeighbourhoodDraftDeleteRequest(draftId));
    return this.neighbourhoodDraftService.deleteNeighbourhoodDraft(draftId).pipe(map(res => {
      this.store.dispatch(new SavedNeighbourhoodDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}
