import { Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "sd-item-decription-popup",
  template: `
    <sd-modal-popup-layout [preventDefaultClose]="true" title="Enter the item description here:"
                           (closeAction)="close($event)">
    </sd-modal-popup-layout>
    <textarea id="myTextarea" [(ngModel)]="this.temp" [formControl]="detailControl"
              style="font-size: 18px;height: calc(100% - 110px); width: 100% ; margin-top: 10px"></textarea>
    <div fxLayout="row">
      <span fxFlex="1 1 auto"></span>
      <button (click)="updateItemDescription()" color="accent" mat-raised-button>Done
        <mat-icon>check</mat-icon>
      </button>
    </div>
  `,
  styles: [``]
})

export class ItemDescriptionPopupComponent {
  temp;

  detailControl = new FormControl(null, [Validators.required]);

  constructor(public dialog: MatDialogRef<ItemDescriptionPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { data: string, disabled?: boolean }) {
    this.temp = data.data;
    if (data.disabled) {
      this.detailControl.disable();
    }
    console.log(data, this.temp);
  }

  close(event: any) {
    if (event === true) {
      this.dialog.close(this.temp);
    }
    console.log(event);
  }

  updateItemDescription() {
    console.log(this.detailControl.value);
    this.dialog.close(this.detailControl.value);
  }
}
