import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Inventory } from "../../../../models/new/inventory.model";
import { InventoryRepository } from "../../../../repository/inventory.repository";
import { SnackbarService } from "../../../../services/snackbar.service";

@Component({
  selector: "sd-add-inventory-in-tasks-popup",
  template: `
    <sd-modal-popup-layout title="Add an Inventory">
      <form fxLayout="column" fxLayoutGap="20px" [formGroup]="formGroup"
            (ngSubmit)="formGroup.valid && saveButtonCLicked()">
        <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column" fxLayout="row">
          <mat-form-field fxFlex="45%" color="accent">
            <mat-select [(ngModel)]="inv"
                        [ngModelOptions]="{standalone: true}"
                        placeholder="Select an Inventory">
              <mat-option *ngFor="let inv of inventories" [value]="inv">
                {{ inv.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-spinner *ngIf="loading" color="accent" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <div fxLayout="column" fxLayoutGap="5px">
            <mat-form-field style="left: 3%" fxFlex="30%" color="accent">
              <input matInput type="number" min="1" placeholder="Add a Quantity"
                     formControlName='quantity'>
            </mat-form-field>
            <span *ngIf="invalidQuanity" class="mat-error">The maximum quantity could be {{inv.quantity}}</span>
          </div>
          <button mat-raised-button [disabled]="!formGroup.valid" color="accent" type="submit" fxFlex="20%">
            Add Inventory
          </button>
        </div>
      </form>
    </sd-modal-popup-layout>
  `,
  styles: [`

    .width-50 {
      width: 45%;
    }

    .width-40 {
      width: 40%;
    }

    @media only screen and (max-width: 600px) {

      [class*="width-"] {
        width: 95%;
      }

      .responsiveAlign {
        text-align: center;
      }

      .responsiveWidth {
        width: 95%;
      }
    }

  `]

})

export class AddInventoryPopupComponent implements OnInit {

  formGroup: FormGroup;
  inventories: Inventory[] = [];
  existingIds: number[] = [];
  titles: string[] = [];
  quantity: FormControl;

  invalidQuanity = false;
  inv: Inventory;
  loading = false;
  loaded = false;

  constructor(private dialog: MatDialogRef<AddInventoryPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private inventoryRepo: InventoryRepository,
              private snackbar: SnackbarService) {
    console.log("[SEMPER] expenses", this.data.expenses);
    this.existingIds = this.data.expenses.filter(v => v.inventory_id).map(e => e.inventory_id);
    this.quantity = new FormControl();
    this.formGroup = new FormGroup({
        quantity: this.quantity,
      }
    )
  }

  ngOnInit() {
    this.inventoryRepo.getIsInventoryLoading().subscribe(l => this.loading = l);
    this.inventoryRepo.getIsInventoryLoaded().subscribe(l => this.loaded = l);
    this.inventoryRepo.getAllInventories(false).subscribe(inventory => {
      console.log("[SEMPER] inventory", inventory);
      this.inventories = inventory.filter(i => this.existingIds.indexOf(i.id) === -1);
    });
  }

  saveButtonCLicked() {
    if (this.inv) {
      if (+this.quantity.value > this.inv.quantity) {
        this.invalidQuanity = true;
        this.snackbar.show("The maximum limit of quantity for this inventory is " + this.inv.quantity);
        return;
      }
    }
    this.dialog.close({inventory: this.inv, quantity: this.quantity.value, fromInventory: true});
  }

}


