import { AfterViewInit, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { OnboardingTaskCompact } from "../../../../models/new/tasks/onboarding-task-compact.model";
import { OnboardingTaskRepository } from "../../../../repository/onboarding-task.repository";
import { AppService } from "../../../../services/app.service";

import { OnboardingTaskAddEditComponent } from "./onboarding-task-add-edit";

@Component({
  selector: "sd-setting-onboarding-tasks",
  template: `
    <div fxLayout="column" fxLayoutGap="30px" style="margin-top: 10px; margin-bottom: 50px" fxFlex="100%">

      <div fxLayoutAlign="start center">
        <button mat-raised-button color="primary" (click)="openTools()">
          <mat-icon>arrow_back</mat-icon>
          Back
        </button>
      </div>

      <div fxLayout.lt-sm="column" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center">
        <div fxLayoutAlign.lt-sm="space-between center" fxLayout="column">
          <span class="heading">Onboarding Tasks</span>
          <span class="hint responsiveAlign">These are tasks that are created everytime a new listing is created.</span>
        </div>
        <div>
          <button mat-icon-button (click)="refresh()">
            <mat-icon>autorenew</mat-icon>
          </button>
          <button mat-raised-button color="accent" (click)="createOnboardingTask()">Create Onboarding Task</button>
        </div>
      </div>

      <div id="spinner" *ngIf="onboardingTasksLoading" fxLayout="row" fxLayoutAlign="center center" fxFlex="100%">
        <mat-spinner color="accent" [diameter]="60" [strokeWidth]="6"></mat-spinner>
      </div>

      <div fxLayout="column" fxLayoutGap="20px" *ngIf="onboardingTasksLoaded">
        <h3 style="text-align: center" *ngIf="onboardingTasks.length === 0">
          No Onboarding Tasks Found
        </h3>
        <mat-card *ngFor="let onboardingTask of onboardingTasks" fxLayout="column" fxLayoutGap="5px" class="padding"
                  style="cursor: pointer" (click)="editOnboardingTask(onboardingTask)">
          <h3>{{ onboardingTask.title }}</h3>
          <span class="content">{{ onboardingTask.description }}</span>
        </mat-card>
      </div>
    </div>
  `,
  styles: [`
    .content {
      font-size: 10px;
    }

    .heading {
      font-weight: bolder;
      font-size: 22px;
    }

    .content {
      font-size: 12px;
      line-height: 130%;
    }

    .padding {
      padding: -10px -10px -10px -10px;
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    .mat-card {
      border: 1px solid lightgrey !important;
      box-shadow: none !important;
    }

    h3 {
      font-weight: bolder !important;
      letter-spacing: 1px !important;
      font-size: 20px !important;
      font-family: 'Montserrat', sans-serif !important;
    }

    .hint {
      font-size: 12px;
      margin-left: 2px;
    }

    #spinner {
      position: fixed;
      top: 45%;
      right: 40%
    }

    @media only screen and (max-width: 600px) {
      .responsiveAlign {
        text-align: center;
      }
    }
  `]
})
export class SettingsOnboardingTasksComponent implements OnInit, AfterViewInit, OnDestroy {
  onboardingTasksLoading: boolean;
  onboardingTasksLoaded: boolean;
  onboardingTasks: OnboardingTaskCompact[] = [];
  private destroyed$ = new Subject();
  private dialogRef: MatDialogRef<any>;

  constructor(private router: Router,
              private appService: AppService,
              private dialog: MatDialog,
              private onboardingTaskRepo: OnboardingTaskRepository) {
  }

  ngOnInit() {
    this.setupTasks();
  }

  ngAfterViewInit() {
    this.appService.changeAppTitle("Settings - Onboarding-Tasks");
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openTools() {
    this.router.navigate(["/settings/tools"]);
  }

  refresh() {
    this.onboardingTaskRepo.getOnboardingTasks(true).pipe(takeUntil(this.destroyed$)).subscribe();
  }

  createOnboardingTask() {
    this.dialogRef = this.dialog.open(OnboardingTaskAddEditComponent);
    const instance = this.dialogRef.componentInstance;
    this.dialogRef.updateSize("100%", "100%");
  }

  editOnboardingTask(onboardingTask: OnboardingTaskCompact) {
    this.dialogRef = this.dialog.open(OnboardingTaskAddEditComponent);
    const instance = this.dialogRef.componentInstance;
    instance.onboardingTaskId = onboardingTask.id;
    this.dialogRef.updateSize("100%", "100%");
  }

  private setupTasks() {
    this.onboardingTaskRepo.getIsOnboardingTaskLoading().pipe(takeUntil(this.destroyed$)).subscribe(l => this.onboardingTasksLoading = l);
    this.onboardingTaskRepo.getIsOnboardingTaskLoaded().pipe(takeUntil(this.destroyed$)).subscribe(l => this.onboardingTasksLoaded = l);
    this.onboardingTaskRepo.getOnboardingTasks(false).subscribe(res => this.onboardingTasks = res);
  }
}
