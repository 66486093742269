import {map, take} from "rxjs/operators";
import {
  getAllOtherNotesDrafts,
  getFullOtherNotesDraftById,
  getIsFullOtherNotesDraftLoaded,
  getIsFullOtherNotesDraftLoading,
  getIsOtherNotesDraftLoaded,
  getIsOtherNotesDraftLoading,
  State
} from "../../reducers";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {OtherNotesDraftsService} from "../../services/drafts/other-notes.service";
import {Injectable} from "@angular/core";
import {
  OtherNotesDraftCreateRequest,
  OtherNotesDraftCreateSuccess,
  OtherNotesDraftDeleteRequest,
  OtherNotesDraftDeleteSuccess,
  OtherNotesDraftIndexRequest,
  OtherNotesDraftIndexSuccess,
  OtherNotesDraftUpdateRequest,
  OtherNotesDraftUpdateSuccess
} from "../../actions/new/setttings/drafts/other-notes-drafts";
import {OtherNotesDraft} from "../../models/new/drafts/other-notes-draft.model";

@Injectable()
export class OtherNotesDraftRepository {

  constructor(private store: Store<State>,
              private otherNotesDraftService: OtherNotesDraftsService) {
  }

  /**
   * Loading & Loaded
   */
  getIsOtherNotesDraftLoading(): Observable<boolean> {
    return this.store.select(getIsOtherNotesDraftLoading);
  }

  getIsOtherNotesDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsOtherNotesDraftLoaded);
  }

  getIsFullOtherNotesDraftLoading(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullOtherNotesDraftLoading(state, draftId));
  }

  getIsFullOtherNotesDraftLoaded(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullOtherNotesDraftLoaded(state, draftId));
  }

  /**
   * Service Methods
   */
  getAllOtherNotesDrafts(force: boolean): Observable<OtherNotesDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsOtherNotesDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsOtherNotesDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new OtherNotesDraftIndexRequest());

      this.otherNotesDraftService.getOtherNotesDrafts().subscribe(response => {
        this.store.dispatch(new OtherNotesDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(getAllOtherNotesDrafts).pipe(map(t => t as OtherNotesDraft[]));
  }

  getOtherNotesDraftById(draftId: number): Observable<OtherNotesDraft> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsOtherNotesDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsOtherNotesDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new OtherNotesDraftIndexRequest());

      this.otherNotesDraftService.getOtherNotesDrafts().subscribe(response => {
        this.store.dispatch(new OtherNotesDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(state => getFullOtherNotesDraftById(state, draftId)).pipe(
      map(t => t as OtherNotesDraft));
  }


  createOtherNotesDraft(data: Partial<OtherNotesDraft>): Observable<OtherNotesDraft> {
    this.store.dispatch(new OtherNotesDraftCreateRequest());
    return this.otherNotesDraftService.createOtherNotesDraft(data).pipe(map(res => {
      this.store.dispatch(new OtherNotesDraftCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateOtherNotesDraft(draftId: number, data: Partial<OtherNotesDraft>): Observable<OtherNotesDraft> {
    this.store.dispatch(new OtherNotesDraftUpdateRequest(draftId));
    return this.otherNotesDraftService.updateOtherNotesDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new OtherNotesDraftUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deleteOtherNotesDraft(draftId: number): Observable<null> {
    this.store.dispatch(new OtherNotesDraftDeleteRequest(draftId));
    return this.otherNotesDraftService.deleteOtherNotesDraft(draftId).pipe(map(res => {
      this.store.dispatch(new OtherNotesDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}

