import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of, Subject } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import { CalendarDataIndexSuccess } from "../actions/new/calendar-data";
import {
  ContactAddToListingSuccess,
  ContactRemoveFromListingSuccess,
  ContactUpdateSuccess
} from "../actions/new/contacts/contact";
import {
  ActivateSuccess,
  ChecklistAddSuccess,
  ChecklistCategoryAddSuccess,
  ChecklistCategoryDeleteSuccess,
  ChecklistCategoryReArrangeSuccess,
  ChecklistDeleteSuccess,
  ChecklistReArrangeSuccess,
  ChecklistUpdateSuccess,
  ContactAddSuccess,
  ContactDeleteSuccess,
  DeleteAllImageSuccess,
  DeleteImageSuccess, DeleteSelectedImageSuccess,
  EditImageSuccess,
  HomeAwayReportRequest,
  HomeAwayReportSuccess,
  ImageSortingSuccess,
  ListingCreateRequest,
  ListingCreateSuccess,
  ListingIndexRequest,
  ListingIndexSuccess,
  ListingShowRequest,
  ListingShowSuccess,
  UpdateRequest,
  UpdateSucess
} from "../actions/new/listings/listing";
import {
  ListingCalendarDelete,
  ListingCalendarRequest,
  ListingCalendarSuccess
} from "../actions/new/listings/listing-calendar";
import { OptionChecklistCategoryIndexRequest, OptionChecklistCategoryIndexSuccess, } from "../actions/new/option";
import { TransformerType } from "../enums/common.enum";
import { ListingStatus } from "../enums/listing.enum";
import { BookingData, CalendarResponse, OwnerBlockData } from "../models/calendar-response";
import { Image } from "../models/image";
import { ChecklistCategory } from "../models/new/checklist-category";
import { CalendarPrice } from "../models/new/listing/calendar-price";
import { ListingChecklist } from "../models/new/listing/listing-checklist.model";
import { ListingCompact } from "../models/new/listing/listing-compact.model";
import { ListingFull } from "../models/new/listing/listing-full.model";
import { ListingHomeawayReport } from "../models/new/listing/listing-homeaway-report";
import { ListingNameOnly } from "../models/new/listing/listing-name-only-model";
import { PropertyAccessDetails } from "../models/new/listing/property-access-details";
import { UserCompact } from "../models/new/user/user-compact.model";
import { UserFull } from "../models/new/user/user-full.model";
import { OwnerBlock } from "../models/owner-block";
import {
  getAllChecklistCategories,
  getAllListings,
  getBlockById,
  getBookingById,
  getCalendarData,
  getFilteredListings,
  getFullListingById,
  getIsCalendarDataLoaded,
  getIsCalendarDataLoading,
  getIsChecklistCategoryLoaded,
  getIsChecklistCategoryLoading,
  getIsFullListingLoaded,
  getIsFullListingLoading,
  getIsListingLoaded,
  getIsListingLoading,
  getIsReportLoaded,
  getIsReportLoading,
  getReportById,
  State
} from "../reducers";
import { ListingService } from "../services/listing.service";
import { SDDay } from "../utils/calendar-utils";
import { CommonUtil } from "../utils/common.util";
import { ConstantsUtil } from "../utils/constants.util";

@Injectable()
export class ListingRepository {

  private _eventBus: Subject<{ key: string; value?: any }>;

  constructor(private store: Store<State>,
              private listingService: ListingService) {
    this._eventBus = new Subject<{ key: string; value?: any }>();
  }

  getIsListingLoading(): Observable<boolean> {
    return this.store.select(getIsListingLoading);
  }

  getIsListingLoaded(): Observable<boolean> {
    return this.store.select(getIsListingLoaded);
  }

  getAllNameOnlyListings(): Observable<ListingNameOnly[]> {
    return this.getAllListings().pipe(map(listings => {
      return listings.map(listing => {
        return {
          id: listing.id,
          title: listing.title,
        } as ListingNameOnly;
      });
    }));
  }

  getAllActivatedListingIds(): Observable<number[]> {
    return this.getActivatedListings().pipe(map(listings => listings.map(l => l.id)));
  }

  getAllDraftListingIds(): Observable<number[]> {
    return this.getDraftListings().pipe(map(listings => listings.map(l => l.id)));
  }

  getAllListings(force: boolean = false): Observable<ListingCompact[]> {
    let loading;
    let loaded;

    this.getIsListingLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsListingLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.loadListings();
    }
    const listings$ = this.store.select(getAllListings);

    listings$.pipe(map(l => {
      return l as ListingCompact[];
    }));

    return listings$.pipe(map(listings => CommonUtil.sortByKey(listings, "title", "asc")));
  }

  loadListings() {
    this.store.dispatch(new ListingIndexRequest());
    this.listingService.getListings().pipe(filter(listings => !!listings))
      .subscribe((listings: ListingCompact[]) => {
        this.store.dispatch(new ListingIndexSuccess(listings));
      });
  }

  getAllAirbnbListings(): Observable<ListingCompact[]> {
    return this.getActivatedListings().pipe(map(listings => listings.filter(l => !!l.airbnb_listing_id)));
  }

  getDeactivatedListings(): Observable<ListingCompact[]> {
    return this.getAllListings().pipe(map(listings => listings.filter(listing => listing.is_deactivated)));
  }

  getActivatedListings(): Observable<ListingCompact[]> {
    return this.getAllListings().pipe(map(listings => listings.filter(listing => !listing.is_deactivated && listing.status !== ListingStatus.DRAFT)));
  }

  getInactivatedListings(): Observable<ListingCompact[]> {
    return this.getAllListings().pipe(map(listings => listings.filter(listing => listing.is_deactivated || listing.status === ListingStatus.DRAFT)));
  }

  getDraftListings(): Observable<ListingCompact[]> {
    return this.getAllListings().pipe(map(listings => listings.filter(listing => listing.status === ConstantsUtil.LISTING_FILTER_DRAFT)));
  }

  getPendingListings(): Observable<ListingCompact[]> {
    return this.getActivatedListings().pipe(map(listings => listings.filter(listing => listing.status === ConstantsUtil.LISTING_FILTER_PENDING)));

  }

  getAcceptedListings(): Observable<ListingCompact[]> {
    return this.getActivatedListings().pipe(map(listings => listings.filter(listing => listing.status === ConstantsUtil.LISTING_FILTER_ACCEPTED)));

  }

  getRejectedListings(): Observable<ListingCompact[]> {
    return this.getActivatedListings().pipe(map(listings => listings.filter(listing => listing.status === ConstantsUtil.LISTING_FILTER_REJECTED)));
  }

  getFilteredListings(filters: string): Observable<ListingCompact[]> {
    return this.store.select(state => getFilteredListings(state, filters)).pipe(map(l => l as ListingCompact[]));
  }

  getAcceptedAndDraftListings(): Observable<ListingCompact[]> {
    return this.getAllListings().pipe(map(listings => listings.filter(listing => listing.status === ConstantsUtil.LISTING_FILTER_ACCEPTED ||
      listing.status === ConstantsUtil.LISTING_FILTER_DRAFT)));
}

  getCompactListingById(listingId: number): Observable<ListingCompact> {
    return this.store.select(state => getFullListingById(state, listingId)).pipe(map(l => l as ListingCompact));
  }

  createListing(data: Partial<ListingFull>): Observable<ListingFull> {
    this.store.dispatch(new ListingCreateRequest());
    return this.listingService.createNewListing(data).pipe(map(value => {
      this.store.dispatch(new ListingCreateSuccess(value.data));
      return value.data;
    }));

  }

  getIsFullListingLoading(listingId: number): Observable<boolean> {
    return this.store.select(state => getIsFullListingLoading(state, listingId));
  }

  getIsFullListingLoaded(listingId: number): Observable<boolean> {
    return this.store.select(state => getIsFullListingLoaded(state, listingId));
  }

  // Second Observable is from service if the obj is being loaded for the first time
  getFullListingById(listingId: number, force: boolean = false): [
    Observable<ListingFull>,
    Observable<ListingFull> | null
  ] {
    const loading$ = this.getIsFullListingLoading(listingId);
    const loaded$ = this.getIsFullListingLoaded(listingId);
    let loaded = false;
    let loading = false;

    loading$.pipe(take(1)).subscribe(l => loading = l);
    loaded$.pipe(take(1)).subscribe(l => loaded = l);

    // let firstObs: Observable<ListingFull> | null = null;

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new ListingShowRequest(listingId));
      this.listingService.showListing(listingId).subscribe(listing => {
        this.store.dispatch(new ListingShowSuccess(listing));
        return listing;
      });
    }
    return [
      this.store.select(state => getFullListingById(state, listingId)).pipe(
        filter(l => !!l),
        filter(listing => listing.___type === TransformerType.FULL),
        map(l => l as ListingFull)),of()
    ];
  }

  getListingTitle(id: number): string {
    let title = "";

    this.getCompactListingById(id).pipe(take(1)).subscribe((listing: ListingCompact) => {
      if (listing && listing.title) {
        title = listing.title;
      }
    });

    return title;
  }

  getAirbnbListingId(id: number): string {
    let airbnbId: string;

    this.getCompactListingById(id).pipe(take(1)).subscribe((listing: ListingCompact) => {
      if (listing) {
        airbnbId = listing.airbnb_listing_id;
      }
    });

    return airbnbId;
  }

  getListingHeadLine(id: number): string {
    let headline = "";

    this.getCompactListingById(id).pipe(take(1)).subscribe((listing: ListingCompact) => {
      if (listing) {
        headline = listing.headline;
      }
    });

    return headline;
  }

  updateListingDetails(data: any, property_id: string): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.updateListingDetails(data, property_id).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));

      return res;
    }));
    //
    // return this.store.select((state) => getFullListingById(state, +property_id))
    //   .filter(v => !!v)
    //   .filter(listing => listing.___type === TransformerType.FULL)
    //   .map(listing => listing as ListingFull);
  }

  onboardingStep1(data: any, property_id: string): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.onboardingStep1(data, property_id).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));

      return res;
    }));
  }

  onboardingStep2(data: any, property_id: string): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.onboardingStep2(data, property_id).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));

      return res;
    }));
  }

  onboardingStep3(data: any, property_id: string): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.onboardingStep3(data, property_id).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));

      return res;
    }));
  }

  onboardingStep4(data: any, property_id: string): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.onboardingStep4(data, property_id).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));

      return res;
    }));
  }

  removeVendorContacts(propertyId: string, contact: UserCompact) {
    // store code here
    return this.listingService.removeVendorContacts(propertyId, contact).pipe(map(() => {
      this.store.dispatch(new ContactDeleteSuccess({ listingId: +propertyId, contact: contact }));
      this.store.dispatch(new ContactRemoveFromListingSuccess({ contactId: contact.id, listingId: +propertyId }));
    }));
  }

  getChecklistCategories(): Observable<ChecklistCategory[]> {
    let loading = false;
    let loaded = false;
    this.getIsChecklistCategoryLoading().subscribe(l => loading = l);
    this.getIsChecklistCategoryLoaded().subscribe(l => loaded = l);
    if (!loading && !loaded) {
      this.store.dispatch(new OptionChecklistCategoryIndexRequest());
      this.listingService.getChecklistCategories().subscribe(res => {
        this.store.dispatch(new OptionChecklistCategoryIndexSuccess(res.checklist_categories));
      });
    }
    return this.store.select(getAllChecklistCategories);
  }

  getIsChecklistCategoryLoading(): Observable<boolean> {
    return this.store.select(getIsChecklistCategoryLoading);
  }

  getIsChecklistCategoryLoaded(): Observable<boolean> {
    return this.store.select(getIsChecklistCategoryLoaded);
  }

  addCheckList(listingId: number, data: { title: string }): Observable<ListingChecklist> {

    return this.listingService.addCheckList(listingId, data).pipe(map(response => {
      this.store.dispatch(new ChecklistAddSuccess({
        listingId,
        checklist: response.data
      }));
      return response.data;
    }));
  }

  deleteCheckListCategory(listingId: number,categoryId: number) {
    return this.listingService.deleteCheckListCategory(listingId, categoryId).pipe(map(response => {
      console.log(response);
      this.store.dispatch(new ChecklistCategoryDeleteSuccess({listingId: listingId, checklists: response}));
      return response;
    }));
  }

  updateCheckList(listingId: number, checklistId: number, data: { is_complete: boolean }): Observable<ListingChecklist> {

    return this.listingService.updateCheckList(listingId, checklistId, data).pipe(map(response => {
      this.store.dispatch(new ChecklistUpdateSuccess({ listingId: listingId,checklist: response.data }));
      return response.data;
    }));
  }

  rearrangeChecklists(listingId: number, checklist_id: number, category_id: number, sort_order: number): Observable<ChecklistCategory[]> {
    return this.listingService.rearrangeChecklists(listingId, checklist_id, category_id, sort_order).pipe(map(response => {
      this.store.dispatch(new ChecklistReArrangeSuccess({ listingId: listingId, checklists: response }));
      return response;
    }));
  }

  rearrangeChecklistCategories(listingId: number, category_id: number, sort_order: number): Observable<ChecklistCategory[]> {
    return this.listingService.rearrangeChecklistCategory(listingId, category_id, sort_order).pipe(map(response => {
      this.store.dispatch(new ChecklistCategoryReArrangeSuccess({ listingId: listingId, checklists: response }));
      return response;
    }));
  }

  deleteCheckList(listingId: number, checklistId: number, categoryId: number): Observable<boolean> {
    return this.listingService.deleteCheckList(listingId, checklistId).pipe(map(res => {
      console.log("delete", listingId, checklistId, categoryId);
      this.store.dispatch(new ChecklistDeleteSuccess({
        listingId: listingId,
        checklistId: checklistId,
        categoryId: categoryId
      }));
      return res;
    }));
  }

  removeImages(property_id: string, image_id: string): Observable<boolean> {
    return this.listingService.removeImages(property_id, image_id).pipe(map(res => {
      this.store.dispatch(new DeleteImageSuccess({ property_id, image_id }));
      return true;
    }));
  }

  removeAllImages(property_id: string) {
    return this.listingService.removeAllImages(property_id).pipe(map(res => {
      this.store.dispatch(new DeleteAllImageSuccess({ property_id }));
      return true;
    }));
  }

  removeSelectedImages(data: { property_id: number, property_image_ids: number[] }): Observable<boolean> {
    return this.listingService.removeSelectedImages(data).pipe(map(res => {
      this.store.dispatch(new DeleteSelectedImageSuccess(data));
      return true;
    }));
  }

  rearrangeImages(listingId: number, data: { sort_order: {} }, images: Image[]) {
    return this.listingService.rearrangeListingImages(listingId, data, images).subscribe((res) => {
      const sortOrder = data.sort_order;
      this.store.dispatch(new ImageSortingSuccess({property_id: String(listingId), sortOrder, images}));
    });
  }

  editImageCaption(listingId: number, imageId: number, data: any) {
    console.log(data);
    return this.listingService.editImageCaption(listingId, imageId, data).pipe(map((res: any) => {
      const imageObj = Object.assign(new Image(), res.data);
      this.store.dispatch(new EditImageSuccess({property_id: String(listingId), image: imageObj}));
      return true;
    }));
  }

  getHomeAwayReports(listingId: number, force: boolean = false): Observable<ListingHomeawayReport> {
    const loading$ = this.store.select(state => getIsReportLoading(state, listingId));
    const loaded$ = this.store.select(state => getIsReportLoaded(state, listingId));
    let loaded = false;
    let loading = false;
    loaded$.subscribe(l => {
      loaded = l;
    });
    loading$.subscribe(l => {
      loading = l;
    });

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new HomeAwayReportRequest(listingId));
      this.listingService.getHomeAwayReports(listingId).pipe(take(1)).subscribe(l => {
        this.store.dispatch(new HomeAwayReportSuccess({listingId: listingId, report: l}));
      });
    }
    return this.store.select(state => getReportById(state, listingId));
  }

  sendListingForApproval(property_id: string): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.sendListingForApproval(property_id).pipe(map((listing) => {
      this.store.dispatch(new UpdateSucess(listing));
      return listing;
    }));
  }

  approveListing(property_id: string, data: {
    assignee_id: number,
    housekeeper_id: number,
    // TODO New Contact
    general_maintenance_id: number,
    painter_id: number,
    electrician_id: number,
    plumber_id: number,
    homeowner_id: number,
    cleaner_id: number,
    hvac_id: number,
    preventative_maintenance_id: number,
    sales_id: number,
    landscaping_id: number
  }): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.approveListing(property_id, data).pipe(map((res) => {
      this.store.dispatch(new UpdateSucess(res));
      return res;
    }));
  }

  rejectListing(property_id: string, data: { reason: string }): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.rejectListing(property_id, data).pipe(map((res) => {
      this.store.dispatch(new UpdateSucess(res));
      return res;
    }));
  }

  pushToBookingPal(propertyId: string): Observable<boolean | {}> {
    return this.listingService.pushToBookingPal(propertyId);
  }

  pushToAirbnb(propertyId: string): Observable<boolean | {}> {
    return this.listingService.pushToAirbnb(propertyId);
  }

  activateAirbnb(propertyId: number): void {
    this.store.dispatch(new UpdateRequest());
    this.listingService.activateAirbnb(propertyId)
      .subscribe(() => {
        this.store.dispatch(new ActivateSuccess(propertyId));
      });
  }

  updateVendorContacts(propertyId: string, contact: UserCompact): Observable<UserCompact> {
    return this.listingService.updateVendorContacts(propertyId, contact).pipe(map((res) => {
      this.store.dispatch(new ContactAddToListingSuccess({ listingId: +propertyId, contactId: contact.id }));
      this.store.dispatch(new ContactAddSuccess({ listingId: +propertyId, contact: res }));
      return res;
    }));
  }

  attachPropertiesToContact(managementContactId: number, propertyIds: number[]): Observable<UserFull> {
    return this.listingService.attachPropertiesToContact(managementContactId, propertyIds).pipe(map((res) => {
      this.store.dispatch(new ContactUpdateSuccess(res));
      return res;
    }));
  }

  // TODO loading, loaded
  getCalendar(listingId: number, data: { month: string }): Observable<CalendarResponse[]> {

    let loading = false;
    let loaded = false;
    this.store.select(state => getIsCalendarDataLoading(state, listingId, data.month)).subscribe(i => loading = i);
    this.store.select(state => getIsCalendarDataLoaded(state, listingId, data.month)).subscribe(i => loaded = i);

    if (!loading && !loaded) {
      console.log(loading, loaded, "entered if");
      this.store.dispatch(new ListingCalendarRequest({month: data.month, listingId: listingId}));
      this.listingService.getCalendar(listingId, data).subscribe((res) => {
        this.store.dispatch(new ListingCalendarSuccess({
          month: data.month,
          listingId: listingId,
          data: res.data
        }));

        this.store.dispatch(new CalendarDataIndexSuccess({
          blocks: res.blocks,
          bookings: res.bookings,
        }));
      });
    }
    console.log("exited if");
    return this.store.select(state => getCalendarData(state, listingId, data.month));
  }

  // TODO store integration for calendar prices
  getCalendarPrices(listingId: number, start: SDDay, end: SDDay): Observable<CalendarPrice[]> {
    return this.listingService.getCalendarPrices(listingId, start, end).pipe(map((res: CalendarPrice[]) => {
      return res;
    }));
  }

  createOwnerBlock(data: any, property_id: string): Observable<OwnerBlock[] | {}> {
    return this.listingService.createOwnerBlock(data, property_id).pipe(map((res: OwnerBlock[]) => {
      const blocks = res;
      return blocks;
    }));
  }

  deleteOwnerBlock(data: { start_date: string, end_date: string }, property_id: string): Observable<any> {
    return this.listingService.deleteOwnerBlock(data, property_id).pipe(map((res) => {
      return res;
    }));
  }

  updateSummaryAboutDraftListingDetails(data: any, property_id: string, draftId: number): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.updateSummaryAboutListingDetails(data, property_id, String(draftId)).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));

      return res;
    }));
  }

  updateTheSpaceDraftListingDetails(data: any, property_id: string, draftId: number): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.updateTheSpaceListingDetails(data, property_id, String(draftId)).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));

      return res;
    }));
  }

  updateGoalListingDetails(property_id: number, draftId: number, data): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.updateGoalListingDetails(property_id, draftId, data).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));
      return res;
    }));
  }

  updateOwnerStoryDraftListingDetails(data: any, property_id: string, draftId: number): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.updateOwnerStoryListingDetails(data, property_id, String(draftId)).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));

      return res;
    }));
  }

  updateGuestAccessDraftListingDetails(data: any, property_id: string, draftId: number): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.updateGuestAccessListingDetails(data, property_id, String(draftId)).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));

      return res;
    }));
  }

  updateInteractionWithGuestDraftListingDetails(data: any, property_id: string, draftId: number): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.updateInteractionWithListingDetails(data, property_id, String(draftId)).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));

      return res;
    }));
  }

  updateGettingAroundDraftListingDetails(data: any, property_id: string, draftId: number): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.updateGettingAroundListingDetails(data, property_id, String(draftId)).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));

      return res;
    }));
  }

  updateThingsToNoteListingDetails(data: any, property_id: string, draftId: number): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.updateThingsToNoteListingDetails(data, property_id, String(draftId)).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));
      return res;
    }));
  }

  updatePrivateNotesListingDetails(data: any, property_id: string, draftId: number): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.updatePrivateNotesListingDetails(data, property_id, String(draftId)).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));
      return res;
    }));
  }

  updateConciergeInfoListingDetails(data: any, property_id: string, draftId: number): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.updateConciergeInfoListingDetails(data, property_id, String(draftId)).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));
      return res;
    }));
  }

  updateNeighbourhoodDraftListingDetails(data: any, property_id: string, draftId: number): Observable<ListingFull> {
    this.store.dispatch(new UpdateRequest());
    return this.listingService.updateNeighbourhoodListingDetails(data, property_id, String(draftId)).pipe(map(res => {
      this.store.dispatch(new UpdateSucess(res));
      return res;
    }));
  }

  publishToBookingPal(propertyId: number, managerId: number) {
    return this.listingService.publishToBookingPal(propertyId, managerId);
  }

  publishToAirbnb(propertyId: number, airbnbAccountId: any) {
    return this.listingService.publishToAirbnb(propertyId, airbnbAccountId);
  }

  deleteCalendar(propertyId: number, month?: string) {
    this.store.dispatch(new ListingCalendarDelete({propertyId: propertyId, month: month}));
  }

  getPropertyAccessDetails(propertyId: number): Observable<PropertyAccessDetails> {
    return this.listingService.getPropertyAccessDetails(propertyId);
  }

  getBankAccountForListing(id: number) {
    return this.listingService.getBankAccountsForListing(id);
  }

  saveButtonClicked() {
    this._eventBus.next({key: "save_button"});
  }

  onSaveButtonClicked(): Observable<any> {
    return this._eventBus.asObservable().pipe(
      filter(e => e.key === "save_button"));
  }

  getIsSaving(): Observable<boolean> {
    return this._eventBus.asObservable().pipe(filter(e => e.key === "saving"),
      map(e => e.value as boolean),);
  }

  setIsSaving(value: boolean) {
    this._eventBus.next({key: "saving", value: value});
  }

  setSaveEnabled(value: boolean) {
    this._eventBus.next({key: "save_button_enabled", value: value});
  }

  getIsPublishButtonVisible() {
    return this._eventBus.asObservable().pipe(filter(e => e.key === "publish_button"),
      map(e => e.value as boolean));
  }

  setPublishButtonVisible(value: boolean) {
    this._eventBus.next({key: "publish_button", value: value});
  }

  getIsAirbnbPublishButtonVisible() {
    return this._eventBus.asObservable().pipe(filter(e => e.key === "airbnb_publish_button"),
      map(e => e.value as boolean),);
  }

  setAirbnbPublishButtonVisible(value: boolean) {
    this._eventBus.next({key: "airbnb_publish_button", value: value});
  }

  getIsSaveButtonEnabled(): Observable<boolean> {
    return this._eventBus.asObservable().pipe(filter(e => e.key === "save_button_enabled"),
      map(e => e.value as boolean));
  }

  getBooking(id: number): Observable<BookingData> {
    return this.store.select(state => getBookingById(state, id)).pipe(filter(v => !!v), take(1),);
  }

  getBlock(id: number): Observable<OwnerBlockData> {
    return this.store.select(state => getBlockById(state, id)).pipe(filter(v => !!v), take(1),);
  }

  createChecklistCategory(title: string, propertyId: number): Observable<ChecklistCategory> {
    return this.listingService.createChecklistCategory(title, propertyId).pipe(map(res => {
      this.store.dispatch(new ChecklistCategoryAddSuccess({listingId: propertyId, category: res}));
      return res;
    }));
  }

  getAvailableListings(data: { start: Date, end: Date, number_of_guests: number, number_of_pets: number }): Observable<number[]> {
    return this.listingService.getAvailableListings(data);
  }

  syncImages(listingId: number): Observable<void> {
    return this.listingService.syncImages(listingId);
  }

  syncImagesMbp(listingId: number): Observable<void> {
    return this.listingService.syncImagesMbp(listingId);
  }

  forceSync(listingId: number): Observable<string> {
    return this.listingService.forceSync(listingId);
  }

  cloneListing(listingId: number): Observable<ListingFull> {
    return this.listingService.cloneListing(listingId).pipe(map(res => {
      this.store.dispatch(new ListingCreateSuccess(res));
      return res;
    }));
  }

  syncPriceLabs(listingId: number) {
    return this.listingService.syncPriceLabs(listingId);
  }
}
