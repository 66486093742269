import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { filter, take } from "rxjs/operators";
import { ListingRepository } from "../../../../repository/listing.repository";
import { UserRepository } from "../../../../repository/user-repository";
import { StayDuvetService } from "../../../../services/stayduvet";
import { CommonUtil } from "../../../../utils/common.util";

/**
 * Created by ubuntu on 9/7/17.
 */

@Component({
  selector: "sd-owner-block-delete-popup",
  template: `
    <sd-modal-popup-layout title="Delete Owner's Block">
      <form fxLayout="column" fxLayoutGap="20px" [formGroup]="formGroup"
            (ngSubmit)="formGroup.valid && saveButtonCLicked()">
        <div fxLayoutAlign="space-between center">
          <mat-form-field [color]="'accent'" class="half-width">
            <input matInput [matDatepicker]="startPicker" [min]="minStartDate" placeholder="Start Date"
                   formControlName='start_date' [(ngModel)]="minEndDate">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
            <mat-error>required.</mat-error>
          </mat-form-field>

          <mat-icon>arrow_forward</mat-icon>

          <mat-form-field [color]="'accent'" class="half-width">
            <input matInput [matDatepicker]="endPicker" [min]="minEndDate" placeholder="End Date"
                   formControlName='end_date'>
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error>required.</mat-error>
          </mat-form-field>
        </div>
        <div fxLayoutGap="10px" fxLayoutAlign=" center" fxFlexAlign="end">
          <mat-spinner color="accent" *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <button mat-raised-button fxFlexAlign="end" [disabled]="isSaving" color="accent" type="submit">
            OK
          </button>
        </div>
      </form>
    </sd-modal-popup-layout>
  `,
  styles: [`
    hr {
      width: 100%;
    }

    mat-spinner {
      height: 30px;
      width: 30px;
    }

    .half-width {
      width: 45%;
    }

    .container-box {
      border-style: solid;
      border-width: 0.1px;
      padding: 10px;
      border-color: #c8c8c8
    }

    .entryInputs {
      width: 45%
    }
  `]
})
export class ListingOwnerBlockDeletePopupComponent implements OnInit {
  formGroup: FormGroup;
  startDate: FormControl;
  endDate: FormControl;

  minStartDate: Date;
  minEndDate: Date;

  isHomeOwner = false;
  @Output() onSuccess = new EventEmitter();
  @Output() onError = new EventEmitter();

  isSaving = false;

  constructor(private service: StayDuvetService,
              private listingRepo: ListingRepository,
              private userRepo: UserRepository,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data: OwnerBlockParams) {
    this.startDate = new FormControl(null, [Validators.required]);
    this.endDate = new FormControl(null, [Validators.required]);
    this.formGroup = new FormGroup({
      start_date: this.startDate,
      end_date: this.endDate,
    });

    this.minStartDate = data.startDate;
    this.minEndDate = data.startDate;
    this.startDate.setValue(data.startDate);
    this.endDate.setValue(data.endDate);
  }

  ngOnInit() {

    this.userRepo.getUser().pipe(
      filter(u => !!u),
      take(1),)
      .subscribe(user => {
        this.isHomeOwner = CommonUtil.isHomeOwner(user);
      });
  }

  getDateString(date: Date) {
    const fullDate = ("0" + date.getDate()).slice(-2);
    const fullMonth = ("0" + (date.getMonth() + 1)).slice(-2);
    const fullYear = date.getFullYear();
    const dateString = fullYear + "-" + fullMonth + "-" + fullDate;

    return dateString;
  }

  saveButtonCLicked() {
    this.isSaving = true;
    const data = {
      ...this.formGroup.value,
    };

    this.listingRepo.deleteOwnerBlock(data, String(this.data.listingId)).subscribe((response) => {
      this.isSaving = false;
      this.onSuccess.emit(response);
      this.dialog.closeAll();
    }, () => {
      this.isSaving = false;
      this.onError.emit();
    });
  }

}

export interface OwnerBlockParams {
  startDate: Date;
  endDate: Date;
  listingId: number;
}
