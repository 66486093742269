import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { CustomVariable } from "../models/custom-variable";
import { __HTTPResponseType } from "../enums/common.enum";
import { map } from "rxjs/operators";

@Injectable()
export class CustomVariableService {

  constructor(private apiService: ApiService) {
  }

  getCustomVariables(): Observable<CustomVariable[]> {
    return this.apiService.get<{ data: CustomVariable[] }>("/custom-variables", true).pipe(map((res) => res.data));
  }

  deleteCustomVariable(id: number): Observable<boolean> {
    return this.apiService.delete("/admin/custom-variables/" + id, true, null, null,true, __HTTPResponseType.TEXT)
      .pipe(map((res) => {
       return true;
      }));
  }

  addNewCustomVariable(data: { key?: string, replacement_text?: string }): Observable<CustomVariable> {
    return this.apiService.post<{ data: CustomVariable }>("/admin/custom-variables", true, data)
      .pipe(map((res) => res.data));
  }

  updateCustomVariable(id: number, data: { key?: string, replacement_text?: string }): Observable<CustomVariable> {
    return this.apiService.put<{ data: CustomVariable }>("/admin/custom-variables/" + id, true, data)
      .pipe(map((res) => res.data));
  }
}
