import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ListingFull } from "../../../models/new/listing/listing-full.model";
import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { Action } from "../../../actions/action";
import { ListingActionTypes } from "../../../actions/new/listings/listing";
import * as _ from "lodash";
import { ListingChecklist } from "../../../models/new/listing/listing-checklist.model";
import { ListingImage } from "../../../models/new/listing/listing-image.model";
import { ListingTag } from "../../../models/new/listing/listing-tag.model";
import { ListingHomeawayReport } from "../../../models/new/listing/listing-homeaway-report";
import { Image } from "../../../models/image";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { TransformerType } from "../../../enums/common.enum";
import { RentalAgreementActionTypes } from "../../../actions/new/setttings/rental-agreements";
import { isNullOrUndefined } from "util";
import {ChecklistCategory} from "../../../models/new/checklist-category";

export interface ListingState extends EntityState<ListingFull | ListingCompact> {
  isLoading: boolean;
  isLoaded: boolean;
  updating: boolean;
  updated: boolean;

  fullLoadingIds: number[],
  fullLoadedIds: number[],

  report: { [listingId: number]: ListingHomeawayReport },
  reportLoadingIds: number[],
  reportLoadedIds: number[],
}

export const listingAdapter: EntityAdapter<ListingFull | ListingCompact> = createEntityAdapter<ListingFull | ListingCompact>({
  selectId: (listing: ListingCompact | ListingFull) => listing.id,
  sortComparer: sortByTitle
});

export function sortByTitle(a: ListingCompact | ListingFull, b: ListingCompact | ListingFull): number {

  if (isNullOrUndefined(a.title)) {
    return 1;
  }

  if (isNullOrUndefined(b.title)) {
    return -1;
  }

  if (a.title > b.title) {
    return 1;
  }

  if (a.title < b.title) {
    return -1;
  }

  return 0;
}

export const initialState: ListingState = listingAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  updating: false,
  updated: false,

  fullLoadingIds: [],
  fullLoadedIds: [],

  report: {},
  reportLoadingIds: [],
  reportLoadedIds: [],
});

export function listingReducer(state: ListingState = initialState, action: Action): ListingState {
  switch (action.type) {

    case ListingActionTypes.INDEX_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ListingActionTypes.INDEX_SUCCESS: {
      const listings = action.payload as ListingCompact[];

      console.log("listings", listings);

      return listingAdapter.addAll(listings, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case ListingActionTypes.CREATE_REQUEST: {
      return state;
    }

    case ListingActionTypes.CREATE_SUCCESS: {
      const fullListing = action.payload as ListingFull;

      return listingAdapter.addOne(fullListing, state);
    }

    case ListingActionTypes.SHOW_REQUEST: {
      const listingId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, listingId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          listingId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, listingId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, listingId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case ListingActionTypes.SHOW_SUCCESS: {
      const listing = action.payload as ListingFull;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, listing.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, listing.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, listing.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          listing.id
        ];
      }

      return listingAdapter.updateOne({
        id: listing.id,
        changes: listing
      }, {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      });
    }

    case ListingActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        updating: true,
        updated: false,
      }
    }

    case ListingActionTypes.ACTIVATE_SUCCESS: {
      const listingId = action.payload as number;

      return listingAdapter.updateOne({
        id: listingId,
        changes: {
          airbnb_active: true
        }
      }, {
        ...state,
        updating: true,
        updated: false,
      });
    }

    case ListingActionTypes.UPDATE_SUCCESS: {
      const listing = action.payload as ListingFull;

      return listingAdapter.updateOne({
        id: listing.id,
        changes: listing
      }, {
        ...state,
        updated: true,
        updating: false
      });
    }

    case ListingActionTypes.CHECKLIST_CATEORY_ADD_SUCCESS: {
      const payload = action.payload as {listingId: number, category: ChecklistCategory};

      const listing = listingAdapter.getSelectors().selectEntities(state)[payload.listingId] as ListingFull;

      return listingAdapter.updateOne({
        id: payload.listingId,
        changes: {
          checklistCategories: {
            data: [
              ...listing.checklistCategories.data,
              payload.category
            ]
          }
        }
      }, state);

    }

    case ListingActionTypes.CHECKLIST_UPDATE_SUCCESS: {
      const payload = action.payload as { listingId: number, checklist: ListingChecklist };

      const categoryId = payload.checklist.category_id;

      const listing = listingAdapter.getSelectors().selectEntities(state)[payload.listingId] as ListingFull;
      const categories = listing.checklistCategories.data;

      const index = categories.map(res => res.id).indexOf(categoryId);

      if (index!==-1){

        const category = categories[index];

        const checklistIndex = category.checklists.data.map(c => c.id).indexOf(payload.checklist.id);

        let checkLists = category.checklists.data;

        if (checklistIndex!==-1) {
          checkLists = [
            ...category.checklists.data.slice(0, checklistIndex),
            payload.checklist,
            ...category.checklists.data.slice(checklistIndex + 1),
          ];
        }

        category.checklists.data = checkLists;

        return listingAdapter.updateOne({
          id: payload.listingId,
          changes: {
            checklistCategories: {
              data: [
                ...categories.slice(0, index),
                category,
                ...categories.slice(index+1)
              ]
            }
          }
        }, state);


      }
      return state;
    }

    case ListingActionTypes.CHECKLIST_ADD_SUCCESS: {
      const payload = action.payload as { listingId: number, checklist: ListingChecklist };

      const categoryId = payload.checklist.category_id;

      const listing = listingAdapter.getSelectors().selectEntities(state)[payload.listingId] as ListingFull;
      const categories = listing.checklistCategories.data;

      const index = categories.map(res => res.id).indexOf(categoryId);

      if (index!==-1){

        let category = categories[index];

        const checkLists = [
          ...category.checklists.data,
          payload.checklist
        ];

        category = {
          ...category,
          checklists: {
            data: checkLists
          }
        };

        return listingAdapter.updateOne({
          id: payload.listingId,
          changes: {
            checklistCategories: {
              data: [
                ...categories.slice(0, index),
                category,
                ...categories.slice(index+1)
              ]
            }
          }
        }, state);


      }
        return state;
    }

    case ListingActionTypes.CHECKLIST_RE_ARRANGE_SUCCESS: {
      const payload = action.payload as { listingId: number, checklists: ChecklistCategory[] };

      return listingAdapter.updateOne({
        id: payload.listingId,
        changes: {
          checklistCategories: {
            data: payload.checklists
          }
        }
      }, state);
    }

    case ListingActionTypes.CHECKLIST_CATEORY_RE_ARRANGE_SUCCESS: {
            const payload = action.payload as { listingId: number, checklists: ChecklistCategory[] };

              return listingAdapter.updateOne({
                id: payload.listingId,
                changes: {
                  checklistCategories: {
                      data: payload.checklists
                    }
                }
            }, state);
    }

    case ListingActionTypes.CHECKLIST_CATEORY_DELETE_SUCCESS: {
      const payload = action.payload as { listingId: number, checklists: ChecklistCategory[] };

      return listingAdapter.updateOne({
        id: payload.listingId,
        changes: {
          checklistCategories: {
            data: payload.checklists
          }
        }
      }, state);
    }

    case ListingActionTypes.CHECKLIST_DELETE_SUCCESS: {
      const payload = action.payload as { listingId: number, categoryId: number,  checklistId: number };

      const listing = listingAdapter.getSelectors().selectEntities(state)[payload.listingId] as ListingFull;
      const categories = listing.checklistCategories.data;

      const index = categories.map(res => res.id).indexOf(payload.categoryId);
      if (index!==-1){

        let category = categories[index];

        let newChecklists = category.checklists.data;
        const checklistIndex = _.findIndex(category.checklists.data, (checklist: ListingChecklist) => {
          return checklist.id === payload.checklistId;
        });

        if (checklistIndex!==-1) {
          newChecklists = [
            ...category.checklists.data.slice(0, checklistIndex),
            ...category.checklists.data.slice(checklistIndex + 1)
          ];
        }

        category = {
          ...category,
          checklists: {
            data: newChecklists
          }
        };

        return listingAdapter.updateOne({
          id: payload.listingId,
          changes: {
            checklistCategories: {
              data: [
                ...categories.slice(0, index),
                category,
                ...categories.slice(index+1)
              ]
            }
          }
        }, state);


      }
      return state;
    }

    case ListingActionTypes.IMAGE_DELETE_SUCCESS: {
      const payload = action.payload as { property_id: string, image_id: string };
      const listing = listingAdapter.getSelectors().selectEntities(state)[+payload.property_id] as ListingFull;
      let newImages = [];
      const imageIndex = _.findIndex(listing.images.data, (image: ListingImage) => {
        return image.id === +payload.image_id;
      });

      newImages = [
        ...listing.images.data.slice(0, imageIndex),
        ...listing.images.data.slice(imageIndex + 1)
      ];

      return listingAdapter.updateOne({
        id: +payload.property_id,
        changes: {
          images: {
            data: newImages
          }
        }
      }, state);
    }

    case ListingActionTypes.IMAGE_ALL_DELETE_SUCCESS: {
      const payload = action.payload;

      return listingAdapter.updateOne({
        id: +payload.property_id,
        changes: {
          images: {
            data: []
          }
        }
      }, state);
    }

    case ListingActionTypes.IMAGE_SELECTED_DELETE_SUCCESS: {
      const payload = action.payload;
      const listing = listingAdapter.getSelectors().selectEntities(state)[payload.property_id] as ListingFull;
      const newImages = [...listing.images.data].filter(image => !payload.property_image_ids.includes(image.id));

      return listingAdapter.updateOne({
        id: payload.property_id,
        changes: {
          images: {
            data: newImages
          }
        }
      }, state);
    }

    case ListingActionTypes.IMAGE_ADD_SUCCESS: {
      const payload = action.payload as { property_id: string, image: Image };
      const listing = listingAdapter.getSelectors().selectEntities(state)[+payload.property_id] as ListingFull;
      let newImages = [];

      newImages = [
        ...listing.images.data,
        payload.image
      ];

      return listingAdapter.updateOne({
        id: +payload.property_id,
        changes: {
          images: {
            data: newImages
          }
        }
      }, state);
    }

    case ListingActionTypes.IMAGE_EDIT_SUCCESS: {
      const payload = action.payload as { property_id: string, image: Image };
      const listing = listingAdapter.getSelectors().selectEntities(state)[+payload.property_id] as ListingFull;
      let newImages = [];
      const imageIndex = _.findIndex(listing.images.data, (image: ListingImage) => {
        return image.id === +payload.image.id;
      });

      console.log("image index: ", imageIndex);

      newImages = [
        ...listing.images.data.slice(0, imageIndex),
        payload.image,
        ...listing.images.data.slice(imageIndex + 1),
      ];

      console.log("new image array : ", newImages);

      return listingAdapter.updateOne({
        id: +payload.property_id,
        changes: {
          images: {
            data: newImages
          }
        }
      }, state);
    }

    case ListingActionTypes.IMAGE_SORTING_SUCCESS: {
      const payload = action.payload as { property_id: string, images: Image[], sortOrder: {} };
      const newImages = payload.images.map((img) => {
        const image = Object.assign({}, img);
        image["sort_order"] = payload.sortOrder[image["id"]];
        return image;
      });


      return listingAdapter.updateOne({
        id: +payload.property_id,
        changes: {
          images: {
            data: newImages
          }
        }
      }, state);
    }



    // TODO Issue in TAG ATTACH : Tags  are not updating in listing compact store object instead of listing full store object.
    case ListingActionTypes.TAG_ATTACH_SUCCESS: {
      const payload = action.payload as { listingId: number, tag: ListingTag };
      const listing = listingAdapter.getSelectors().selectEntities(state)[payload.listingId] as ListingFull;
      let newTags = [];
      newTags = [
        ...listing.tags.data,
        payload.tag
      ];
      console.log(newTags);
      return listingAdapter.updateOne({
        id: payload.listingId,
        changes: {
          tags: {
            data: [...newTags]
          }
        }
      }, state);
    }

    // TODO Issue in TAG DELETE : Tags  are not updating in listing compact store object instead of listing full store object.
    case ListingActionTypes.TAG_DELETE_SUCCESS: {
      const payload = action.payload as { listingId: string, tagId: string };
      let newTags = [];
      const listing = listingAdapter.getSelectors().selectEntities(state)[+payload.listingId] as ListingFull;
      const tagIndex = _.findIndex(listing.tags.data, (tag: ListingTag) => {
        return tag.id === +payload.tagId;
      });

      newTags = [
        ...listing.tags.data.slice(0, tagIndex),
        ...listing.tags.data.slice(tagIndex + 1)
      ];

      return listingAdapter.updateOne({
        id: payload.listingId,
        changes: {
          tags: {
            data: newTags
          }
        }
      }, state);
    }

    case ListingActionTypes.HOMEAWAY_REPORT_REQUEST: {
      const listingId = action.payload as number;
      let loadingReportIds = state.reportLoadingIds;
      const loadingIndex = _.indexOf(loadingReportIds, listingId);

      // Adding if not present
      if (loadingIndex === -1) {
        loadingReportIds = [
          ...loadingReportIds,
          listingId
        ];
      }

      let loadedReportIds = state.reportLoadedIds;
      const loadedIndex = _.indexOf(loadedReportIds, listingId);

      // Removing if already added
      if (loadedIndex !== -1) {
        loadedReportIds = _.remove(loadedReportIds, listingId);
      }
      return {
        ...state,
        reportLoadedIds: loadedReportIds,
        reportLoadingIds: loadingReportIds
      }
    }

    case ListingActionTypes.HOMEAWAY_REPORT_SUCCESS: {
      const payload = action.payload as { listingId: number, report: ListingHomeawayReport };

      let reportLoadingIds = state.reportLoadingIds;
      const loadingIndex = _.indexOf(reportLoadingIds, payload.listingId);

      // Removing if loading
      if (loadingIndex !== -1) {
        reportLoadingIds = _.remove(reportLoadingIds, payload.listingId);
      }

      let reportLoadedIds = state.reportLoadedIds;
      const loadedIndex = _.indexOf(reportLoadedIds, payload.listingId);

      if (loadedIndex === -1) {
        reportLoadedIds = [
          ...reportLoadedIds,
          payload.listingId
        ];
      }

      const r = {};
      r[payload.listingId] = payload.report;

      Object.keys(state.report).forEach(key => {
        const value = state.report[key];
        r[key] = value;
      });

      return {
        ...state,
        report: r,
        reportLoadingIds: reportLoadingIds,
        reportLoadedIds: reportLoadedIds
      }
    }

    case ListingActionTypes.CONTACT_ADD_SUCCESS: {
      const payload = action.payload;

      let managementContacts = [];

      if (state.entities[payload.listingId].___type === TransformerType.FULL) {
        const listingFull = state.entities[payload.listingId] as ListingFull;
        managementContacts = listingFull.managementContacts.data
      }

      return listingAdapter.updateOne(
        {
          id: payload.listingId,
          changes: {
            managementContacts: {
              data: [
                ...managementContacts,
                payload.contact
              ]
            }
          }
        },
        state
      )

    }

    case ListingActionTypes.CONTACT_DELETE_SUCCESS: {
      const payload = action.payload;

      if (state.entities[payload.listingId].___type !== TransformerType.FULL) {
        return state;
      }

      const listing = listingAdapter.getSelectors().selectEntities(state)[+payload.listingId] as ListingFull;
      const contactIndex = _.findIndex(listing.managementContacts.data, (contact: UserCompact) => {
        return contact.id === +payload.contact.id;
      });

      let newContacts = [];

      newContacts = [
        ...listing.managementContacts.data.slice(0, contactIndex),
        ...listing.managementContacts.data.slice(contactIndex + 1)
      ];

      return listingAdapter.updateOne(
        {
          id: payload.listingId,
          changes: {
            managementContacts: {
              data: newContacts
            }
          }
        },
        state
      )

    }

    case RentalAgreementActionTypes.UPDATE_CHECK_IN_CHECK_OUT : {
      const check_in_time = action.payload.check_in_time;
      const check_out_time = action.payload.check_out_time;

      const allIds = listingAdapter.getSelectors().selectIds(state) as number[];
      const changes = [];
      for (const id of allIds) {
        changes.push({
          id: id, changes: {
            check_out_time: check_out_time, check_in_time: check_in_time
          }
        })
      }
      return listingAdapter.updateMany(changes, state);
    }

    default: {
      return state;
    }
  }
}

export const _getIsListingLoading = (state: ListingState) => {
  return state.isLoading;
};
export const _getIsListingLoaded = (state: ListingState) => {
  return state.isLoaded;
};

export const _getIsFullListingLoading = (state: ListingState, listingId: number) => {
  return state.fullLoadingIds.indexOf(listingId) !== -1;
};
export const _getIsFullListingLoaded = (state: ListingState, listingId: number) => {
  return state.fullLoadedIds.indexOf(listingId) !== -1;
};
export const _getIsReportLoading = (state: ListingState, listingId: number) => {
  return state.reportLoadingIds.indexOf(listingId) !== -1;
};
export const _getIsReportLoaded = (state: ListingState, listingId: number) => {
  return state.reportLoadedIds.indexOf(listingId) !== -1;
};
export const _getReportById = (state: ListingState, listingId: number) => {
  return state.report[listingId];
};
