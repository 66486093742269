import { HttpErrorResponse } from "@angular/common/http";
/**
 * Created by aditya on 17/7/17.
 */
import { AfterContentInit, Component, HostListener, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { debounceTime, takeWhile } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { environment } from "../../../../environments/environment";
import { Guest } from "../../../models/guest";
import { CreditCard } from "../../../models/new/credit-card.model";
import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { UserNameOnly } from "../../../models/new/user/user-name-only.model";
import { Quote } from "../../../models/quote";
import { CreditCardUtil } from "../../../models/utils/credit-card.util";
import { QuoteModelUtil } from "../../../models/utils/quote-model.util";
import { UserModelUtil } from "../../../models/utils/user-model.util";
import { BookingRepository } from "../../../repository/booking.repository";
import { ContactRepository } from "../../../repository/contact.repository";
import { QuoteRepository } from "../../../repository/quote.repository";
import DateUtils from "../../../utils/date";
import FormUtils from "../../../utils/form";
import { getAllReservationPaymentTypes } from "../../../utils/utils";
import { ErrorPopupComponent } from "../../shared/components/error-popup";

@Component({
  selector: "sd-new-reservation-popup",
  template: `
    <sd-modal-popup-layout title="Create Reservation">
      <div style="width: 100%;" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px">
          <div matTooltip="Check In Date"><span
            class="label">Check-In:</span><span>{{_check_in | dateFix | date}}</span></div>
          <div matTooltip="Check Out Date"><span
            class="label">Check-Out:</span><span>{{_check_out | dateFix | date}}</span></div>
          <div matTooltip="Number of Guests"><span class="label"># Guests:</span><span>{{_guest_count}}</span></div>
          <div [matTooltip]="_pets_count > 0 ? 'Pet(s) Coming with guests' : 'No Pets'"><span
            class="label"># Pets:</span><span>{{_pets_count}}</span></div>
        </div>
        <hr>
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <mat-slide-toggle fxFlex="45%" (change)="isReturnGuest = $event.checked">Return Guest</mat-slide-toggle>
          <!--<mat-spinner *ngIf="isReturnGuest" [color]="'accent'" [diameter]="30"-->
          <!--[strokeWidth]="4"></mat-spinner>-->
          <mat-form-field [color]="'accent'" *ngIf="isReturnGuest" fxFlex="45%" style="margin-bottom: 20px;">
            <input matInput placeholder="Search guest name" [matAutocomplete]="auto" [formControl]="returnGuest">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getGuestName.bind(this)">
              <mat-option *ngFor="let guest of filteredGuests"
                          [ngStyle]="{'color': 'white'}"
                          [value]="guest">
                <div fxLayout="=row" fxLayoutGap="10px">
                  <span><img src="{{UserModelUtil.getProfilePic(guest)}}" height="30px" width="30px"></span>
                  <span>{{guest.first_name + " " + getLastName(guest)}}</span>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <form *ngIf="!isReturnGuest" [formGroup]="guestForm" fxLayout="row wrap"
              fxLayoutAlign="space-between start">
          <mat-form-field [color]="'accent'" fxFlex="45%">
            <input matInput placeholder="First Name" formControlName='first_name'>
            <mat-error>First Name is required</mat-error>
          </mat-form-field>
          <mat-form-field [color]="'accent'" fxFlex="45%">
            <input matInput placeholder="Last Name" formControlName='last_name'>
            <mat-error>Last Name is required</mat-error>
          </mat-form-field>
          <mat-form-field [color]="'accent'" fxFlex="45%">
            <input matInput placeholder="Email" formControlName='email'>
            <mat-error>Email is required</mat-error>
          </mat-form-field>
          <mat-form-field [color]="'accent'" fxFlex="45%">
            <input matInput placeholder="Phone" formControlName='phone'>
            <mat-error>Phone number is required</mat-error>
          </mat-form-field>
        </form>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between stretch">
          <div class="width-45">
            <p style="margin-bottom: 20px; font-weight: bold">{{listing.title}}</p>

            <div *ngIf="editedQuote" fxLayout="column" fxLayoutAlign="start stretch">
              <div fxLayoutAlign="space-between  start">
                <p>Total Fare</p>
                <p *ngIf="!affiliateId.value">{{QuoteModelUtil.total(editedQuote) | currency:'USD':true}}</p>
                <p *ngIf="!!affiliateId.value">{{QuoteModelUtil.total(editedQuote) - ((editedQuote.base_amount) * (affiliatePercentage || 0)/100) | currency:'USD':true}}</p>
              </div>
              <hr>
              <div fxLayoutAlign="space-between start">
                <p>{{(editedQuote.base_amount / numOfNights) | currency:'USD':true}} X {{numOfNights}}</p>
                <p *ngIf="!editMode">{{editedQuote.base_amount | currency:'USD':true}}</p>
                <input class="quote-field" type="number" *ngIf="editMode" matInput min="0"
                       [(ngModel)]="editedQuote.base_amount"/>
              </div>
              <hr>
              <div fxLayoutAlign="space-between start">
                <p>Cleaning Fee</p>
                <p *ngIf="!editMode">{{editedQuote.cleaning_fee | currency:'USD':true}}</p>
                <input class="quote-field" type="number" *ngIf="editMode" matInput min="0"
                       [(ngModel)]="editedQuote.cleaning_fee"/>
              </div>
              <hr>
              <div fxLayoutAlign="space-between start" *ngIf="editedQuote.other_fee.pet_fee">
                <p>Pet Fee</p>
                <p *ngIf="!editMode">{{editedQuote.other_fee.pet_fee | currency:'USD':true}}</p>
                <input class="quote-field" type="number" *ngIf="editMode" matInput min="0"
                       [(ngModel)]="editedQuote.other_fee.pet_fee"/>
              </div>
              <hr *ngIf="editedQuote.other_fee.pet_fee">
              <div fxLayoutAlign="space-between start" *ngIf="editedQuote.other_fee.extra_guest_fee">
                <p>Extra Guest Fee</p>
                <p *ngIf="!editMode">{{editedQuote.other_fee.extra_guest_fee | currency:'USD':true}}</p>
                <input class="quote-field" type="number" *ngIf="editMode" matInput min="0"
                       [(ngModel)]="editedQuote.other_fee.extra_guest_fee"/>
              </div>
              <hr *ngIf="editedQuote.other_fee.extra_guest_fee">
              <div fxLayoutAlign="space-between start">
                <p>Security Fee</p>
                <p *ngIf="!editMode">{{editedQuote.security_deposit_fee | currency:'USD':true}}</p>
                <input class="quote-field" type="number" *ngIf="editMode" matInput min="0"
                       [(ngModel)]="editedQuote.security_deposit_fee"/>
              </div>
              <hr>
              <div fxLayoutAlign="space-between start">
                <p>Service Fee</p>
                <p *ngIf="!editMode">{{editedQuote.service_fee | currency:'USD':true}}</p>
                <input class="quote-field" type="number" *ngIf="editMode" matInput min="0"
                       [(ngModel)]="editedQuote.service_fee"/>
              </div>
              <hr *ngIf="!!affiliateId.value">
              <div *ngIf="!!affiliateId.value" fxLayoutAlign="space-between  start">
                <p>Discount</p>
                <p style="color: red">{{((editedQuote.base_amount) * (affiliatePercentage || 0)/100) | currency:'USD':true}}</p>
              </div>
              <hr *ngIf="!!affiliateId.value">
              <div *ngIf="!!affiliateId.value" fxLayoutAlign="space-between  start">
                <p>Service Fee (After Discount)</p>
                <p style="color: red">{{(editedQuote.service_fee - ((editedQuote.base_amount) * (affiliatePercentage || 0)/100)) | currency:'USD':true}}</p>
              </div>
              <hr>
              <div fxLayoutAlign="space-between start">
                <p>CC Process Fee</p>
                <p *ngIf="!editMode">{{editedQuote.cc_process_fee | currency:'USD':true}}</p>
                <input class="quote-field" type="number" *ngIf="editMode" matInput min="0"
                       [(ngModel)]="editedQuote.cc_process_fee"/>
              </div>
              <hr>
              <div fxLayoutAlign="space-between" start>
                <p>Occupancy Taxes</p>
                <p *ngIf="!editMode">{{editedQuote.occupancy_taxes | currency:'USD':true}}</p>
                <input class="quote-field" type="number" *ngIf="editMode" matInput min="0"
                       [(ngModel)]="editedQuote.occupancy_taxes"/>
              </div>
              <hr>
              <div fxLayoutAlign="space-between" start>
                <p>Property Damage Protection</p>
                <p *ngIf="!editMode">{{editedQuote.property_damage_protection | currency:'USD':true}}</p>
                <input class="quote-field" type="number" *ngIf="editMode" matInput min="0"
                       [(ngModel)]="editedQuote.property_damage_protection"/>
              </div>
              <hr>
              <div fxLayoutAlign="space-between" start>
                <p>Other Fees</p>
                <p *ngIf="!editMode">{{editedQuote.other_fee.other_fee | currency:'USD':true}}</p>
                <input class="quote-field" type="number" *ngIf="editMode" matInput min="0"
                       [(ngModel)]="editedQuote.other_fee.other_fee"/>
              </div>
            </div>
            <div *ngIf="!editedQuote" class="loading">
              <sd-center-spinner></sd-center-spinner>
            </div>
          </div>
          <div class="width-45" fxLayout="column" fxLayoutAlign="start stretch">
            <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="isReturnGuest" style="margin-bottom: 10px;">
              <span *ngIf="!isValidReturnGuest()"
                    style="font-weight: bold; color:red; font-style: italic;">
                No Guest Selected
              </span>
              <mat-card *ngIf="isValidReturnGuest()" style="width: 90%">
                <mat-card-content>
                  <h2>{{returnGuest.value?.first_name}} {{returnGuest.value?.last_name}}</h2>
                  <div fxLayout="row" fxLayoutAlign="space-around">
                    <span style="font-weight: bold">Email: </span>
                    <span style="flex: 1 1 auto"></span>
                    <span>{{returnGuest.value?.email}}</span>
                  </div>

                  <div fxLayout="row">
                    <span style="font-weight: bold">Phone Number: </span>
                    <span style="flex: 1 1 auto"></span>
                    <span>{{returnGuest.value?.phone_number}}</span>
                  </div>
                </mat-card-content>
                <mat-card-footer>
                  <mat-progress-bar color="accent" value="100"></mat-progress-bar>
                </mat-card-footer>
              </mat-card>
            </div>
            <form [formGroup]="reservationForm" fxLayout="column" fxLayoutGap="15px">

              <div fxLayout="row">
                <sd-select [ngStyle]="{'width': cardsLoading? '80%': '100%'}"
                           placeholder="Payment Type"
                           [control]="payment"
                           [options]="allPaymentMethodsOptions"
                           (selectionChanged)="onPaymentTypeChanged($event)"></sd-select>
                <mat-spinner fxFlexAlign="center" *ngIf="cardsLoading" color="accent" [diameter]="27"
                             [strokeWidth]="4"></mat-spinner>
              </div>

              <mat-form-field [color]="'accent'" *ngIf="isSavedCardSelected">
                <mat-select placeholder="Select Card" formControlName="card">
                  <mat-option *ngFor="let card of cards" [value]="card.id">
                    <div fxLayout="=row" fxLayoutGap="10px">
                      <span><img [src]="sanitizeCardIcon(CreditCardUtil.getCreditCardBrandIcon(card.brand))"
                                 height="30px" width="30px"></span>
                      <span> ****   {{card.last4 + "  " + card.exp_month + "/" + card.exp_year}}</span>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <sd-select placeholder="Source" [control]="source" [options]="sourceOptions"></sd-select>
              <mat-form-field [color]="'accent'" fxFlex="35%">
                <input matInput placeholder="PROMO CODE" formControlName='promo_code'>
                <button style="color: black" *ngIf="!isApplying" mat-button color="primary" fxFlex="10%" (click)="validPromo()">APPLY</button>
                <mat-spinner *ngIf="isApplying" [diameter]="20" [strokeWidth]="3"></mat-spinner>
              </mat-form-field>
              <mat-slide-toggle [disabled]="isPlatform" [ngModelOptions]="{standalone: true}"
                                [matTooltip]="getText('auto_task')"
                                [(ngModel)]="createAutoTask">Create Auto Task
              </mat-slide-toggle>
              <mat-slide-toggle [disabled]="isPlatform" [ngModelOptions]="{standalone: true}"
                                [matTooltip]="getText('automation')"
                                [(ngModel)]="createAutomation">Create Automation
              </mat-slide-toggle>
            </form>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="end" fxLayoutAlign.lt-sm="center center"
                 fxLayoutGap="15px">
              <mat-spinner color="accent" *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
              <button mat-raised-button *ngIf="!editMode" [disabled]="isSaving" color="accent"
                      (click)="editMode = true">
                Edit Invoice
              </button>
              <button mat-raised-button *ngIf="!editMode" [disabled]="isSaving || payingOverPhone"
                      style="background-color: green;"
                      (click)="createReservation()">Complete Booking
              </button>
              <button mat-raised-button *ngIf="editMode" color="accent" (click)="saveInvoice()">Save</button>
              <button mat-raised-button *ngIf="editMode" color="primary" (click)="reset()">Reset</button>
            </div>
          </div>
        </div>
      </div>
      <sd-center-spinner *ngIf="isSaving || payingOverPhone"></sd-center-spinner>
    </sd-modal-popup-layout>
  `,
  styles: [`
    .padding {
      cursor: pointer;
      padding: -10px -10px -10px -10px;
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    .width-80 {
      width: 80%;
    }

    .content {
      font-size: 14px;
      line-height: 130%;
    }

    h3 {
      font-weight: bolder !important;
      letter-spacing: 1px !important;
      font-size: 22px !important;
      font-family: 'Montserrat', sans-serif !important;
    }

    .mat-card {
      border: 1px solid lightgrey !important;
      box-shadow: none !important;
    }

    .label {
      margin-right: 5px;
      color: #aaaaaa;
    }

    hr {
      width: 100%;
    }

    p {
      margin-bottom: 5px;
      margin-top: 5px;
      font-size: 16px;
    }

    .quote-field {
      text-align: right;
      width: auto;
      font-size: 20px;
      color: green;
    }

    .loading {
      position: relative;
      height: 360px;
      width: 100%;
    }

    .width-45 {
      width: 45%;
    }

    @media only screen and (max-width: 600px) {

      [class*="width-"] {
        width: 95%;
      }

      .responsiveAlign {
        text-align: center;
      }

      .responsiveWidth {
        width: 95%;
      }
    }
  `]
})
export class NewReservationPopupComponent implements OnInit, OnDestroy, AfterContentInit {
  _check_in: Date;
  _check_out: Date;
  _guest_count: number;
  _pets_count: number;
  _override_min_stay: boolean;

  stripeHandler: any;
  stripeToken = "";
  payingOverPhone = false;

  listing: ListingCompact;

  guestForm: FormGroup;
  reservationForm: FormGroup;
  fname: FormControl;
  lname: FormControl;
  email: FormControl;
  phone: FormControl;
  source: FormControl;
  payment: FormControl;
  card: FormControl;
  returnGuest: FormControl;
  promoCode: FormControl;
  affiliateId: FormControl;
  affiliatePercentage: number = 0;

  filteredGuests: any[];

  guests: UserNameOnly[] = [];
  isSaving = false;
  editMode = false;
  isReturnGuest = false;
  isAlive = true;
  isApplying = false;
  quote: Quote;
  editedQuote: Quote;
  originalQuote: Quote;
  numOfNights: number;
  cards: CreditCard[] = [];
  contactsLoading = false;
  contactsLoaded = false;
  cardsLoading = false;
  cardsLoaded = true;
  isSavedCardSelected = false;
  allPaymentMethods = getAllReservationPaymentTypes();
  allPaymentMethodsOptions: {title: string, value: any}[];
  sourceOptions: {title: string, value: any}[] = [];
  UserModelUtil = UserModelUtil;
  QuoteModelUtil = QuoteModelUtil;
  CreditCardUtil = CreditCardUtil;
  isOverThePhone = false;
  createAutoTask = true;
  createAutomation = true;
  isPlatform = false;
  forceBook = false;
  dialogRef: MatDialogRef<any>;

  constructor(private quotesRepo: QuoteRepository,
              private bookingRepo: BookingRepository,
              private contactRepo: ContactRepository,
              private dialog: MatDialog,
              private domSanitizer: DomSanitizer,
              private snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) private data: ReservationData,
              private router: Router) {
    this.listing = data.listing;
    this.numOfNights = DateUtils.daysBetweenDates(data.check_in, data.check_out);

    // Doing this to make the data readable outside TS Class scope.
    this._check_in = data.check_in;
    this._check_out = data.check_out;
    this._guest_count = data.guest_count;
    this._pets_count = data.pet_count;
    this.forceBook = data.force_book;
    this._override_min_stay = data.override_min_stay;
  }

  ngOnInit() {
    this.setupForms();
    this.setupGuests();

    this.allPaymentMethodsOptions = this.allPaymentMethods.map(method => {
      return {title: method.title, value: method.slug};
    });
    if (this.cards.length > 0) {
      this.allPaymentMethodsOptions.push(
        { title: "Saved Card", value : "saved_card"}
      );
    }

    if (!this.isOverThePhone) {
      this.sourceOptions.push({title: "AirBnb", value: "airbnb"});
      this.sourceOptions.push({title: "VRBO", value: "homeaway"});
    }
    this.sourceOptions.push({title: "StayDuvet", value: "stayduvet"});
    this.sourceOptions.push({title: "Plum Guide", value: "plum_guide"});
    this.sourceOptions.push({title: "Marriott", value: "hvmi"});
    this.sourceOptions.push({title: "Referral", value: "referral"});

    this.stripeHandler = StripeCheckout.configure({
      key: environment.Stripe.publishableKey,
      image: "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/logo.png",
      locale: "auto",
      allowRememberMe: false,
      token: token => {
        this.payingOverPhone = true;
        this.stripeToken = token.id;
        this.addBooking();
      }
    });

    this.returnGuest.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      console.log(value);
      if (value.length >= 2) {
        this.contactRepo.getSearchResults(value).subscribe(res => {
          console.log(res.data);
          this.filteredGuests = res.data;
        });
      }

      if (this.isValidReturnGuest()) {
        console.log("retun guesy", value);
        this.cardsLoading = true;
        this.contactRepo.getContactCreditCards(value.id).subscribe((cards) => {
          this.cards = cards;
          this.cardsLoading = false;
          this.cardsLoaded = true;
        }, (err) => {
          this.cardsLoading = false;
          this.cardsLoaded = false;
        });
      }

    });
  }

  ngAfterContentInit() {
    this.setupQuoteDetails();

    this.payment.setValue("from_platform");

  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  setupForms() {
    this.payment = new FormControl(null, [Validators.required]);
    this.card = new FormControl(null, []);
    this.source = new FormControl(null, [Validators.required]);
    this.fname = new FormControl(null, [Validators.required]);
    this.lname = new FormControl(null, [Validators.required]);
    this.email = new FormControl(null, [Validators.required]);
    this.phone = new FormControl(null, [Validators.required]);
    this.returnGuest = new FormControl(null, [Validators.required]);
    this.promoCode = new FormControl(null);
    this.affiliateId = new FormControl(null);

    this.guestForm = new FormGroup({
      first_name: this.fname,
      last_name: this.lname,
      email: this.email,
      phone: this.phone
    });

    this.reservationForm = new FormGroup({
      payment: this.payment,
      source: this.source,
      card: this.card,
      promo_code: this.promoCode
    });

    this.payment.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {

      this.isPlatform = value == "from_platform";

      if (value === "owners_charge") {
        this.source.setValue("stayduvet");
        this.source.disable();
      } else if (value === "from_platform" || value === "over_the_phone" || value === "saved_card") {
        this.source.setValue("stayduvet");
        this.isOverThePhone = true;
        this.source.enable();
        if (value === "saved_card") {
          this.card.setValue(this.cards[0].id);
        }
      } else {
        this.source.enable();
        this.isOverThePhone = false;
      }
    });
  }

  setupGuests() {

    this.contactRepo.getIsActiveContactLoading().pipe(takeWhile(() => this.isAlive)).subscribe((loading) => {
      this.contactsLoading = loading;
    });

    this.contactRepo.getIsActiveContactLoaded().pipe(takeWhile(() => this.isAlive)).subscribe((loading) => {
      this.contactsLoaded = loading;
    });
  }

  setupQuoteDetails() {
    this.quotesRepo.getQuote(this.listing.id, this.data.check_in, this.data.check_out, this.data.pet_count, 0, this._override_min_stay)
      .subscribe(value => {
        this.originalQuote = value;
        this.quote = {...value};
        this.editedQuote = this.quote;
        this.editedQuote.cc_process_fee = QuoteModelUtil.ccProcessFee(this.editedQuote);
        this.editedQuote.service_fee = QuoteModelUtil.serviceFee(this.editedQuote);
        this.editedQuote.occupancy_taxes = QuoteModelUtil.taxes(this.editedQuote);
        this.editedQuote.property_damage_protection = QuoteModelUtil.propertyDamageProtection(this.editedQuote, this._check_out, this._check_in);
        this.editedQuote.other_fee.other_fee = QuoteModelUtil.otherFee(this.editedQuote);
        this.quote.cc_process_fee = QuoteModelUtil.ccProcessFee(this.editedQuote);
        this.quote.service_fee = QuoteModelUtil.serviceFee(this.editedQuote);
        this.quote.occupancy_taxes = QuoteModelUtil.taxes(this.editedQuote);
        this.quote.property_damage_protection = QuoteModelUtil.propertyDamageProtection(this.editedQuote, this._check_out, this._check_in);
        this.quote.other_fee.other_fee = QuoteModelUtil.otherFee(this.editedQuote);

      }, (err: HttpErrorResponse) => {
        this.dialog.closeAll();
        this.dialogRef = this.dialog.open(ErrorPopupComponent);
        const instance = this.dialogRef.componentInstance as ErrorPopupComponent;
        instance.title = "Some Error Occurred!";
        instance.message = err.error.message;
        this.dialogRef.updateSize("100%");
      });
  }

  saveInvoice() {
    this.quote = this.editedQuote;
    this.editMode = false;
  }

  reset() {
    console.log("[quote]", this.quote);
    console.log("[original quote]", this.originalQuote);
    this.editedQuote = {...this.originalQuote};
    console.log("[edited quote]", this.editedQuote);
    this.editMode = false;
  }

  createReservation() {
    FormUtils.markAllAsTouched(this.reservationForm);
    this.isReturnGuest ? this.returnGuest.markAsTouched() : FormUtils.markAllAsTouched(this.guestForm);

    const formsInvalid = this.reservationForm.invalid ||
      (this.isReturnGuest && this.returnGuest.invalid) ||
      (!this.isReturnGuest && this.guestForm.invalid);

    if (formsInvalid) {
      return;
    }

    if (this.isReturnGuest && !this.isValidReturnGuest()) {
      return;
    }

    console.log(this.returnGuest.value);

    this.isSaving = true;

    if (this.payment.value === "over_the_phone") {
      const data = this.getData();

      let guestEmail = "";
      if (this.isReturnGuest) {
        guestEmail = this.returnGuest.value.email;
      } else {
        guestEmail = this.guestForm.value.email;
      }

      this.stripeHandler.open({
        name: "StayDuvet",
        description: "Payment For Booking",
        email: guestEmail,
        amount: QuoteModelUtil.total(this.quote) * 100,
        closed: function() {
          this.isSaving = false;
        }.bind(this)
      });
    } else {
      this.addBooking();
    }
  }

  getGuestName(guest: UserCompact) {
    if (isNullOrUndefined(guest)) {
      return "";
    }

    return guest.first_name + this.checkNullString(guest.last_name);
  }

  checkNullString(string: string): string {
    if (string != null) {
      return " ".concat(string);
    } else {
      return "";
    }
  }

  getData() {

    let data: any = {
      property_id: this.listing.id,
      start: DateUtils.toISODateString(this.data.check_in),
      end: DateUtils.toISODateString(this.data.check_out),
      check_in_time: this.listing.check_in_time,
      check_out_time: this.listing.check_out_time,
      number_of_guests: this.data.guest_count,
      security_deposit_fee: this.quote.security_deposit_fee,
      guest_channel_fee: this.quote.service_fee,
      cc_process_fee: this.quote.cc_process_fee,
      base_amount: this.quote.base_amount,
      cleaning_fee: this.quote.cleaning_fee,
      commission: this.quote.commission_rate,
      property_damage_protection: this.quote.property_damage_protection,
      payment_method: this.payment.value,
      total_tax: this.quote.occupancy_taxes,
      guest_source: this.source.value,
      create_auto_task: this.createAutoTask, // TODO: is this only required when not a returning guest
      create_automation: this.createAutomation
    };

    console.log("RAW DATA", data);

    if (this.isReturnGuest && this.returnGuest.value.guest) {
      data.guest_id = this.returnGuest.value.guest.data.id;
    } else {
      data = {...data, ...this.guestForm.value};
    }

    if (data.payment_method === "saved_card") {
      data.card_id = this.card.value;
    } else if (data.payment_method === "over_the_phone") {
      data.card_token = this.stripeToken;
    }

    if (this.quote.other_fee.pet_fee || this.quote.other_fee.extra_guest_fee || this.quote.other_fee.other_fee) {
      data.other_fee = [];
      if (this.quote.other_fee.pet_fee) {
        data.other_fee.push({
          slug: "pet_fee",
          description: "Fee for accommodating pets on property",
          amount: this.quote.other_fee.pet_fee,
        });
      }
      if (this.quote.other_fee.extra_guest_fee) {
        data.other_fee.push({
          slug: "extra_guest_fee",
          description: "Fee for accommodating extra guests on property",
          amount: this.quote.other_fee.pet_fee,
        });
      }
      if (this.quote.other_fee.other_fee) {
        data.other_fee.push({
          slug: "other_fee",
          description: "Any extra expenses",
          amount: this.quote.other_fee.other_fee,
        });
      }
    }

    if (this.forceBook) {
      data.force_book = true;
    }

    if (this.affiliateId.value) {
      data.affiliate_id = this.affiliateId.value;
    }

    console.log(data);

    return data;
  }

  addBooking() {
    this.bookingRepo.addBooking(this.getData()).subscribe((booking) => {
      console.log("booking: " + booking);
      this.isSaving = false;
      this.payingOverPhone = false;

      this.router.navigate(["/reservations/" + booking.id]);

      this.dialog.closeAll();
    }, (error) => {
      console.log("piyush", error);
      this.isSaving = false;
      this.payingOverPhone = false;
      // this.snackBar.open(messages[0], '', {
      //   duration: 4000,
      // });
    });
  }

  @HostListener("window:popstate")
  onPopstate() {
    console.log("popped");
    this.stripeHandler.close();
  }

  isValidReturnGuest() {
    if (isNullOrUndefined(this.returnGuest.value)) {
      return false;
    }

    return typeof this.returnGuest.value !== "string";
  }

  returnGuestChanged(guest: Guest) {
    this.returnGuest.setValue(guest);
  }

  onPaymentTypeChanged(event: any) {
    this.isSavedCardSelected = event.value === "saved_card";
  }

  sanitizeCardIcon(data: any): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(data);
  }

  getLastName(guest) {
    if (guest.last_name) {
      return guest.last_name;
    } else {
      return "";
    }
  }

  getText(value) {
    if (value === "auto_task") {
      if (this.isPlatform) {
        return "auto task will be created when payment is done";
      } else {
        return "create auto task";
      }
    }
    if (value === "automation") {
      if (this.isPlatform) {
        return "automation will be created when payment is done";
      } else {
        return "create automation";
      }
    }
  }

  validPromo() {
    this.isApplying = true;
    this.bookingRepo.validPromo(this.promoCode.value).subscribe(res => {
      this.isApplying = false;
      this.affiliateId.setValue(res.affiliate_id);
      this.affiliatePercentage = res.percentage;
    },
      err => {
        this.isApplying = false;
        this.affiliateId.setValue(null);
        this.affiliatePercentage = 0;
      });
  }
}

export interface ReservationData {
  listing: ListingCompact;
  check_in: Date;
  check_out: Date;
  guest_count: number;
  pet_count: number;
  force_book?: boolean;
  override_min_stay?: boolean;
}
