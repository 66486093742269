import {map, take} from "rxjs/operators";
import {
  getAllInteractionsDrafts,
  getFullInteractionsDraftById,
  getIsFullInteractionsDraftLoaded,
  getIsFullInteractionsDraftLoading,
  getIsInteractionsDraftLoaded,
  getIsInteractionsDraftLoading,
  State
} from "../../reducers";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {InteractionsDraftsService} from "../../services/drafts/interactions-drafts";
import {
  InteractionsDraftCreateRequest,
  InteractionsDraftCreateSuccess,
  InteractionsDraftDeleteRequest,
  InteractionsDraftDeleteSuccess,
  InteractionsDraftIndexRequest,
  InteractionsDraftIndexSuccess,
  InteractionsDraftUpdateRequest,
  InteractionsDraftUpdateSuccess
} from "../../actions/new/setttings/drafts/interactions-drafts";
import {InteractionsDraft} from "../../models/new/drafts/intearctions-draft.model";
import {Injectable} from "@angular/core";

@Injectable()
export class InteractionsDraftRepository {

  constructor(private store: Store<State>,
              private interactionsDraftService: InteractionsDraftsService) {
  }

  /**
   * Loading & Loaded
   */
  getIsInteractionsDraftLoading(): Observable<boolean> {
    return this.store.select(getIsInteractionsDraftLoading);
  }

  getIsInteractionsDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsInteractionsDraftLoaded);
  }

  getIsFullInteractionsDraftLoading(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullInteractionsDraftLoading(state, draftId));
  }

  getIsFullInteractionsDraftLoaded(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullInteractionsDraftLoaded(state, draftId));
  }

  /**
   * Service Methods
   */
  getAllInteractionsDrafts(force: boolean): Observable<InteractionsDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsInteractionsDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsInteractionsDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new InteractionsDraftIndexRequest());

      this.interactionsDraftService.getInteractionsDrafts().subscribe(response => {
        this.store.dispatch(new InteractionsDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(getAllInteractionsDrafts).pipe(map(t => t as InteractionsDraft[]));
  }

  getInteractionsDraftById(draftId: number): Observable<InteractionsDraft> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsInteractionsDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsInteractionsDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new InteractionsDraftIndexRequest());

      this.interactionsDraftService.getInteractionsDrafts().subscribe(response => {
        this.store.dispatch(new InteractionsDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(state => getFullInteractionsDraftById(state, draftId)).pipe(
      map(t => t as InteractionsDraft));
  }


  createInteractionsDraft(data: Partial<InteractionsDraft>): Observable<InteractionsDraft> {
    this.store.dispatch(new InteractionsDraftCreateRequest());
    return this.interactionsDraftService.createInteractionsDraft(data).pipe(map(res => {
      this.store.dispatch(new InteractionsDraftCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateInteractionsDraft(draftId: number, data: Partial<InteractionsDraft>): Observable<InteractionsDraft> {
    this.store.dispatch(new InteractionsDraftUpdateRequest(draftId));
    return this.interactionsDraftService.updateInteractionsDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new InteractionsDraftUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deleteInteractionsDraft(draftId: number): Observable<null> {
    this.store.dispatch(new InteractionsDraftDeleteRequest(draftId));
    return this.interactionsDraftService.deleteInteractionsDraft(draftId).pipe(map(res => {
      this.store.dispatch(new InteractionsDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}

