import {filter, map, take} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {MultiCalendarRulesService} from "../services/multi-calendar-rules.service";
import {Store} from "@ngrx/store";
import {
  getAllMultiCalendarRules,
  getIsMultiCalendarRulesLoaded,
  getIsMultiCalendarRulesLoading,
  State
} from "../reducers";
import {Observable} from "rxjs";
import {
  MultiCalendarRuleCreateSuccess,
  MultiCalendarRuleDeleteSuccess,
  MultiCalendarRuleIndexRequest,
  MultiCalendarRuleIndexSuccess,
  MultiCalendarRuleUpdateSuccess
} from "../actions/new/setttings/multi-calendar-rule";
import {MultiCalendarRule} from "../models/new/multi-calendar-rule";

@Injectable()
export class MultiCalendarRulesRepository {

  constructor(private rulesService: MultiCalendarRulesService,
              private store: Store<State>) {
  }


  getIsMultiCalendarRulesLoading(): Observable<boolean> {
    return this.store.select(getIsMultiCalendarRulesLoading);
  }

  getIsMultiCalendarRulesLoaded(): Observable<boolean> {
    return this.store.select(getIsMultiCalendarRulesLoaded);
  }

  getMultiCalendarRules(force: boolean = false, listingId: number = null): Observable<MultiCalendarRule[]> {

    let loading: boolean;
    let loaded: boolean;

    this.getIsMultiCalendarRulesLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsMultiCalendarRulesLoaded().pipe(take(1)).subscribe(l => loaded = l);


    if (!loading && (!loaded || force)) {

      this.store.dispatch(new MultiCalendarRuleIndexRequest());
      this.rulesService.getMultiCalendarRules().subscribe(rules => {
        this.store.dispatch(new MultiCalendarRuleIndexSuccess(rules));
      });
    }

    const res$ = this.store.select(getAllMultiCalendarRules).pipe(filter(r => !!r));

    if (listingId) {
      return res$.pipe(
        map(rules => {
            return rules.filter(rule => rule.parent_property_id === listingId || (rule.blocked_property_id === listingId && rule.reverse_rule_applied))
          }
        ))
    }

    return res$
  }



  createMultiCalendarRule(data: { parentPropertyId: number, blockedPropertyId: number, reverseRuleApplied: boolean, syncBookingLimit:boolean }): Observable<MultiCalendarRule> {
    return this.rulesService.createMultiCalendarRule(data).pipe(map(res => {
      this.store.dispatch(new MultiCalendarRuleCreateSuccess(res));
      return res;
    }));
  }

  updateMultiCalendarRule(id: number, blockedPropertyId: number, reverse_rule_applied: boolean,sync_booking_limit:boolean): Observable<MultiCalendarRule> {
    return this.rulesService.updateMultiCalendarRule(id, blockedPropertyId, reverse_rule_applied, sync_booking_limit).pipe(map(res => {
      this.store.dispatch(new MultiCalendarRuleUpdateSuccess(res));
      return res;
    }));
  }

  deleteMultiCalendarRule(id: number): Observable<any> {
    return this.rulesService.deleteMultiCalendarRule(id).pipe(map(res => {
      this.store.dispatch(new MultiCalendarRuleDeleteSuccess(id));
      return res;
    }));
  }

}
