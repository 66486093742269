import * as moment from "moment";

import { Action } from "../../actions/action";
import { StatsActionTypes } from "../../actions/new/stats";
import { AdminDashStats } from "../../models/admin-dash-stats";
import { CleaningStats } from "../../models/new/dashboard/cleaning-stats";
import { CommissionStats } from "../../models/new/dashboard/commission-stats";
import { NetIncomeStats } from "../../models/new/dashboard/net-income-stats";
import { ListingCompact } from "../../models/new/listing/listing-compact.model";
import { OwnerDashStats } from "../../models/owner-dash-stats";

export interface StatsState {
  ownerStatsLoading: boolean;
  ownerStatsLoaded: boolean;
  ownerStats: { [id: number]: OwnerDashStats };

  adminStatsLoading: boolean;
  adminStatsLoaded: boolean;
  adminStats: { [id: number]: AdminDashStats };

  commissionStatsLoading: boolean;
  commissionStatsLoaded: boolean;
  commissionStats: { [id: number]: CommissionStats };

  cleaningStatsLoading: boolean;
  cleaningStatsLoaded: boolean;
  cleaningStats: { [id: number]: CleaningStats };

  netIncomeStatsLoading: boolean;
  netIncomeStatsLoaded: boolean;
  netIncomeStats: { [id: number]: NetIncomeStats };
  netIncomeYear: number;

  monthlyBreakdownLoading: boolean;
  monthlyBreakdownLoaded: boolean;
  monthlyBreakdown: { [month: string]: { [propertyId: number]: number } };

  selectedListings: ListingCompact[];

}

export const initialState: StatsState = {
  ownerStatsLoading: false,
  ownerStatsLoaded: false,
  ownerStats: {},

  adminStatsLoading: false,
  adminStatsLoaded: false,
  adminStats: {},

  cleaningStatsLoading: false,
  cleaningStatsLoaded: false,
  cleaningStats: {},

  commissionStatsLoading: false,
  commissionStatsLoaded: false,
  commissionStats: {},

  netIncomeStatsLoading: false,
  netIncomeStatsLoaded: false,
  netIncomeStats: {},
  netIncomeYear: moment().add(7, "days").year(),

  monthlyBreakdownLoading: false,
  monthlyBreakdownLoaded: false,
  monthlyBreakdown: {},

  selectedListings: []
};


export function statsReducer(state: StatsState = initialState, action: Action): StatsState {
  switch (action.type) {

    case StatsActionTypes.OWNER_STATS_REQUEST: {
      return {
        ...state,
        ownerStatsLoading: true
      };
    }

    case StatsActionTypes.OWNER_STATS_SUCCESS: {
      return {
        ...state,
        ownerStatsLoading: false,
        ownerStatsLoaded: true,
        ownerStats: action.payload,
      };
    }

    case StatsActionTypes.ADMIN_STATS_REQUEST: {
      return {
        ...state,
        adminStatsLoading: true
      };
    }

    case StatsActionTypes.ADMIN_STATS_SUCCESS: {
      return {
        ...state,
        adminStatsLoading: false,
        adminStatsLoaded: true,
        adminStats: action.payload,
      };
    }

    case StatsActionTypes.COMMISSION_STATS_REQUEST: {
      return {
        ...state,
        commissionStatsLoading: true
      };
    }

    case StatsActionTypes.COMMISSION_STATS_SUCCESS: {
      return {
        ...state,
        commissionStatsLoading: false,
        commissionStatsLoaded: true,
        commissionStats: action.payload,
      };
    }

    case StatsActionTypes.CLEANING_STATS_REQUEST: {
      return {
        ...state,
        cleaningStatsLoading: true
      };
    }

    case StatsActionTypes.CLEANING_STATS_SUCCESS: {
      return {
        ...state,
        cleaningStatsLoading: false,
        cleaningStatsLoaded: true,
        cleaningStats: action.payload,
      };
    }

    case StatsActionTypes.NET_INCOME_STATS_REQUEST: {
      return {
        ...state,
        netIncomeStatsLoading: true
      };
    }

    case StatsActionTypes.NET_INCOME_STATS_SUCCESS: {
      return {
        ...state,
        netIncomeStatsLoading: false,
        netIncomeStatsLoaded: true,
        netIncomeStats: action.payload,
      };
    }

    case StatsActionTypes.MONTHLY_BREAKDOWN_REQUEST: {
      return {
        ...state,
        monthlyBreakdownLoading: true
      };
    }

    case StatsActionTypes.MONTHLY_BREAKDOWN_SUCCESS: {
      return {
        ...state,
        monthlyBreakdownLoading: false,
        monthlyBreakdownLoaded: true,
        monthlyBreakdown: action.payload,
      };
    }

    case StatsActionTypes.MONTHLY_BREAKDOWN_REMOVE: {
      return {
        ...state,
        monthlyBreakdownLoaded: false,
        monthlyBreakdown: {}
      };
    }

    case StatsActionTypes.SELECTING_LISTING_SUCCESS: {
      return {
        ...state,
        selectedListings: action.payload || []
      };
    }

    case StatsActionTypes.CHANGE_NET_INCOME_YEAR: {
      return {
        ...state,
        netIncomeYear: action.payload as number
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsOwnerStatsLoading = (state: StatsState) => state.ownerStatsLoading;
export const _getIsOwnerStatsLoaded = (state: StatsState) => state.ownerStatsLoaded;
export const _getOwnerStats = (state: StatsState) => state.ownerStats;

export const _getIsAdminStatsLoading = (state: StatsState) => state.adminStatsLoading;
export const _getIsAdminStatsLoaded = (state: StatsState) => state.adminStatsLoaded;
export const _getAdminStats = (state: StatsState) => state.adminStats;

export const _getIsCommissionStatsLoading = (state: StatsState) => state.commissionStatsLoading;
export const _getIsCommissionStatsLoaded = (state: StatsState) => state.commissionStatsLoaded;
export const _getCommissionStats = (state: StatsState) => state.commissionStats;

export const _getIsCleaningStatsLoading = (state: StatsState) => state.cleaningStatsLoading;
export const _getIsCleaningStatsLoaded = (state: StatsState) => state.cleaningStatsLoaded;
export const _getCleaningStats = (state: StatsState) => state.cleaningStats;

export const _getIsNetIncomeStatsLoading = (state: StatsState) => state.netIncomeStatsLoading;
export const _getIsNetIncomeStatsLoaded = (state: StatsState) => state.netIncomeStatsLoaded;
export const _getNetIncomeStats = (state: StatsState) => state.netIncomeStats;
export const _getNetIncomeYear = (state: StatsState) => state.netIncomeYear;

export const _getIsMonthlyBreakdownLoading = (state: StatsState) => state.monthlyBreakdownLoading;
export const _getIsMonthlyBreakdownLoaded = (state: StatsState) => state.monthlyBreakdownLoaded;
export const _getMonthlyBreakdown = (state: StatsState) => state.monthlyBreakdown;

export const _getSelectedListings = (state: StatsState) => state.selectedListings;
