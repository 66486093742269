import { InteractionsDraft } from "../../../../models/new/drafts/intearctions-draft.model";
import { Action } from "../../../action";

export enum InteractionsDraftActionTypes {
  INDEX_REQUEST = "[Interactions Drafts NEW] Index Request",
  INDEX_SUCCESS = "[Interactions Drafts NEW] Index Success",

  CREATE_REQUEST = "[Interactions Drafts NEW] Create Request",
  CREATE_SUCCESS = "[Interactions Drafts NEW] Create Success",

  SHOW_REQUEST = "[Interactions Drafts NEW] Show Request",
  SHOW_SUCCESS = "[Interactions Drafts NEW] Show Success",

  UPDATE_REQUEST = "[Interactions Drafts NEW] Update Request",
  UPDATE_SUCCESS = "[Interactions Drafts NEW] Update Success",

  DELETE_REQUEST = "[Interactions Drafts NEW] Delete Request",
  DELETE_SUCCESS = "[Interactions Drafts NEW] Delete Success"
}

export class InteractionsDraftIndexRequest implements Action {
  readonly type = InteractionsDraftActionTypes.INDEX_REQUEST;
}

export class InteractionsDraftIndexSuccess implements Action {
  readonly type = InteractionsDraftActionTypes.INDEX_SUCCESS;

  constructor(public payload: InteractionsDraft[]) {
  }
}

export class InteractionsDraftCreateRequest implements Action {
  readonly type = InteractionsDraftActionTypes.CREATE_REQUEST;
}

export class InteractionsDraftCreateSuccess implements Action {
  readonly type = InteractionsDraftActionTypes.CREATE_SUCCESS;

  constructor(public payload: InteractionsDraft) {
  }
}

export class InteractionsDraftShowRequest implements Action {
  readonly type = InteractionsDraftActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {

  }
}

export class InteractionsDraftShowSuccess implements Action {
  readonly type = InteractionsDraftActionTypes.SHOW_SUCCESS;

  constructor(public payload: InteractionsDraft) {
  }
}

export class InteractionsDraftUpdateRequest implements Action {
  readonly type = InteractionsDraftActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class InteractionsDraftUpdateSuccess implements Action {
  readonly type = InteractionsDraftActionTypes.UPDATE_SUCCESS;

  constructor(public payload: InteractionsDraft) {
  }
}

export class InteractionsDraftDeleteRequest implements Action {
  readonly type = InteractionsDraftActionTypes.DELETE_REQUEST;

  constructor(public payload: number) {
  }
}

export class InteractionsDraftDeleteSuccess implements Action {
  readonly type = InteractionsDraftActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
