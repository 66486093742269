export enum UserPreferance {
  EMAIL = "email",
  PHONE = "phone"
}

export enum UserRole {
  ADMIN = "admin",
  OWNER = "owner",
  MANAGEMENT = "management",
  GUEST = "guest"
}

export enum UserDashboard {
  DEFAULT = "default",
  DASHBOARD_ONLY = "dashboard_only"
}

export enum UserOtherRole {
  CREAT_EDIT_TASK = "can_create_and_edit_task"
}

export enum UserCategory {
  HVAC = "hvac",
  PLUMBER = "plumber",
  PAINTER = "painter",
  ELECTRICIAN = "electrician",
  INSPECTOR = "inspector",
  GENERAL_MAINTENANCE = "general_maintenance",
  HOMEOWNER = "homeowner",
  HOUSEKEEPER = "housekeeper",
  SALES = "sales",
  PREVENTATIVE_MAINTENANCE = "preventative_maintenance",
  LANDSCAPING = "landscaping",
  GUEST = "guest",
  OWNER = "owner",
  ASSIGNEE = "assignee",
  AGENT = "agent"
}

export enum TaskAssigneeCategory {
  HOMEOWNER = "homeowner",
  HOUSEKEEPER = "housekeeper",
  ASSIGNEE = "assignee"
}


export class UserEnumHelper {
  static getUserCategory(ref: UserCategory): string {
    let title = "";
    switch (ref) {
      case UserCategory.HVAC : {
        title = "HVAC";
        break;
      }
      case UserCategory.PLUMBER : {
        title = "Plumber";
        break;
      }
      case UserCategory.PAINTER : {
        title = "Painter";
        break;
      }
      case UserCategory.ELECTRICIAN : {
        title = "Electrician";
        break;
      }
      case UserCategory.INSPECTOR : {
        title = "Inspector";
        break;
      }
      case UserCategory.GENERAL_MAINTENANCE : {
        title = "General Maintenance";
        break;
      }
      case UserCategory.HOMEOWNER : {
        title = "HomeOwner";
        break;
      }
      case UserCategory.HOUSEKEEPER: {
        title = "Housekeeper";
        break;
      }
      case UserCategory.SALES: {
        title = "Sales";
        break;
      }
      case UserCategory.PREVENTATIVE_MAINTENANCE: {
        title = "Preventative Maintenance";
        break;
      }
      case UserCategory.LANDSCAPING: {
        title = "Landscaping";
        break;
      }
      case UserCategory.GUEST: {
        title = "Guest";
        break;
      }
      case UserCategory.OWNER: {
        title = "Owner";
        break;
      }
      case UserCategory.ASSIGNEE: {
        title = "Assignee";
        break;
      }
      case UserCategory.AGENT: {
        title = "Agent";
        break;
      }
    }
    return title;
  }
}
