import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../../../actions/action";
import { ConciergeDraft } from "../../../models/new/drafts/concierge-draft.model";
import { ConciergeDraftActionTypes } from "../../../actions/new/setttings/drafts/concierge-drafts";
import * as _ from "lodash";

export interface ConciergeDraftState extends EntityState<ConciergeDraft> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const conciergeDraftAdapter: EntityAdapter<ConciergeDraft> = createEntityAdapter
  < ConciergeDraft > ({
    selectId: (conciergeDraft: ConciergeDraft) => conciergeDraft.id
  });

export const initialState: ConciergeDraftState = conciergeDraftAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function conciergeDraftReducer(state: ConciergeDraftState = initialState, action: Action): ConciergeDraftState {
  switch (action.type) {
    case ConciergeDraftActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        isLoaded: false
      }
    }

    case ConciergeDraftActionTypes.INDEX_SUCCESS: {
      const conciergeDrafts = action.payload as ConciergeDraft[];
      return conciergeDraftAdapter.addAll(conciergeDrafts, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case ConciergeDraftActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }

    case ConciergeDraftActionTypes.CREATE_SUCCESS: {
      const conciergeDraft = action.payload as ConciergeDraft;
      return conciergeDraftAdapter.addOne(conciergeDraft,{
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case ConciergeDraftActionTypes.SHOW_REQUEST: {
      const conciergeDraftId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, conciergeDraftId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          conciergeDraftId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, conciergeDraftId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, conciergeDraftId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case ConciergeDraftActionTypes.SHOW_SUCCESS: {
      const conciergeDraft = action.payload as ConciergeDraft;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, conciergeDraft.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, conciergeDraft.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, conciergeDraft.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          conciergeDraft.id
        ];
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case ConciergeDraftActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case ConciergeDraftActionTypes.UPDATE_SUCCESS: {
      const conciergeDraft = action.payload as ConciergeDraft;
      return conciergeDraftAdapter.updateOne({
        id: conciergeDraft.id,
        changes: conciergeDraft
      }, state);
    }

    case ConciergeDraftActionTypes.DELETE_REQUEST: {
      return {
        ...state
      };
    }

    case ConciergeDraftActionTypes.DELETE_SUCCESS: {
      const conciergeDraftId = action.payload as number;

      return conciergeDraftAdapter.removeOne(conciergeDraftId, state);
    }

    default: {
      return state;
    }

  }
}

export const _getIsConciergeDraftLoading = (state: ConciergeDraftState) => state.isLoading;
export const _getIsConciergeDraftLoaded = (state: ConciergeDraftState) => state.isLoaded;

export const _getIsFullConciergeDraftLoading = (state: ConciergeDraftState, draftId: number) => {
  return state.fullLoadingIds.indexOf(draftId) !== -1;
};
export const _getIsFullConciergeDraftLoaded = (state: ConciergeDraftState, draftId: number) => {
  return state.fullLoadedIds.indexOf(draftId) !== -1;
};
