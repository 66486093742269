/**
 * Created by divyanshu on 04/09/17.
 */

import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { QuillUtils } from "app/utils/quill.util";
import Quill from "quill";
import "quill-mention";
import { debounceTime, distinctUntilChanged, filter, takeWhile } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { CustomVariable } from "../../../../models/custom-variable";
import { AutomatedMessageFull } from "../../../../models/new/automation/automated-messages-full.model";
import { ListingCompact } from "../../../../models/new/listing/listing-compact.model";
import { AutomatedMessageRepository } from "../../../../repository/automated-message.repository";
import { CustomVariableRepository } from "../../../../repository/custom-variable.repository";
import { ListingRepository } from "../../../../repository/listing.repository";
import {
  getAllAlertOffsetReferences,
  getAllAlertTypes,
  getAllOffsetPositions, getAllSendVias, getAllTimeDenominations, getContactMaintenanceCatagoryTypes,
  getStaticVariables
} from "../../../../utils/utils";
import { NotesPopupComponent } from "../../../tasks/components/popups/notes-popup";

@Component({
  selector: "sd-setting-check-in-instructions-popup",
  template: `
      <sd-modal-popup-layout title="{{popUpTitle}}" [showRefreshAction]="true">
          <div *ngIf="isAlertLoaded || !openForEdit" fxLayout="column" fxLayoutGap="20px">
              <div fxLayoutAlign="space-between center">
                  <div fxLayoutAlign="start center">
                      <span class="title">Auto Message &nbsp;</span>
                  </div>
                  <div fxLayoutAlign="start center" fxLayoutGap="10px">
                      <span style="text-align: center">Enabled</span>
                      <mat-slide-toggle [(ngModel)]="isAvailable"></mat-slide-toggle>
                  </div>
              </div>
              <hr class="fullWidth colorLight">
              <div fxLayout="column" class="container-box" style="padding-bottom: 20px">
                  <span class="bolder">Internal Name</span>
                  <mat-form-field [color]="'accent'" class="fullWidth" dividerColor="accent">
                      <input matInput [(ngModel)]="title">
                  </mat-form-field>
                  <mat-error *ngIf="titleError" style="font-size: x-small; margin-top: -20px;">This field is required
                  </mat-error>
              </div>

              <div fxLayoutAlign="start center" fxLayoutGap="10px" class="container-box">
                  <span class="bolder">Type Of Message</span>
                  <mat-form-field [color]="'accent'" fxFlex="32%" fxFlex.sm="95%">
                      <mat-select [(ngModel)]="offsetReference">
                          <mat-option *ngFor="let reference of getAllAlertOffsetReferences" [value]="reference.slug">
                              {{reference.title}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>

              <div fxLayoutAlign="start center" fxLayoutGap="15px" class="container-box">
                  <span class="bolder">Send a message via</span>
                  <mat-form-field [color]="'accent'" style="width: 300px;">
                      <mat-select
                              color="accent"
                              floatPlaceholder="never"
                              [(ngModel)]="sendVia"
                              [ngModelOptions]="{standalone: true}">
                          <mat-option *ngFor="let sendVia of getAllSendVias" [value]="sendVia.slug">
                              {{sendVia.title}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>

                  <span class="bolder">Source of booking</span>
                  <sd-select [multiple]="true" [control]="source" [options]="sourcesOptions"></sd-select>
              </div>

              <div fxLayoutAlign="start center" fxLayoutGap="10px" class="container-box">
                  <span class="bolder">Schedule to &nbsp;</span>
                  <div class="tenwidth">
                      <mat-form-field [color]="'accent'">
                          <input type="number" matInput [(ngModel)]="timeValue">
                      </mat-form-field>
                      <mat-error *ngIf="timeError" style="font-size: x-small; margin-top: -20px;">Required</mat-error>
                  </div>
                  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
                      <mat-form-field [color]="'accent'" fxFlex="32%" fxFlex.sm="95%">
                          <mat-select [(ngModel)]="timeDenomination">
                              <mat-option *ngFor="let denomination of getAllTimeDenominations"
                                          [value]="denomination.slug">
                                  {{denomination.title}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field [color]="'accent'" fxFlex="32%" fxFlex.sm="95%">
                          <mat-select [(ngModel)]="offsetPosition">
                              <mat-option *ngFor="let position of getAllOffsetPositions" [value]="position.slug">
                                  {{position.title}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>

              </div>

              <div fxLayout="column" fxLayoutGap="10px" class="container-box">


                  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" style="width: 100%">

                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="50%"
                           fxFlex.lt-sm="100%">
                          <span class="bolder">To:</span>
                          <mat-form-field [color]="'accent'" fxFlex="80%">
                              <mat-select [(ngModel)]="to">
                                  <mat-option *ngFor="let item of options"
                                              [value]="item.slug">{{item.title}}</mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>

                      <!--<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="50%" fxFlex.lt-sm="100%">-->
                      <!--<mat-form-field [color]="'accent'" fxFlex="80%">-->
                      <!--<mat-select-->
                      <!--color="accent"-->
                      <!--placeholder="Custom Variable"-->
                      <!--[(ngModel)]="selectedVariableId"-->
                      <!--[disabled]="isLoading"-->
                      <!--[ngModelOptions]="{standalone: true}">-->
                      <!--<mat-option *ngFor="let variable of customVariables" [value]="variable"-->
                      <!--(click)="addVariable(variable)">-->
                      <!--{{variable.replacement_text}}-->
                      <!--</mat-option>-->
                      <!--</mat-select>-->
                      <!--</mat-form-field>-->
                      <!--<mat-spinner *ngIf="isLoading" color="accent" [diameter]="30" [strokeWidth]="4"></mat-spinner>-->
                      <!--</div>-->

                  </div>


                  <div fxLayout="column" fxLayoutGap="5px">
                      <div fxLayout="row">
                          <span class="bolder">Message</span>
                          <span fxFlex="1 1 auto"></span>
                          <button type="button" mat-button matSuffix mat-icon-button
                                  (click)="openMessageDescriptionPopup()">
                              <mat-icon>fullscreen</mat-icon>
                          </button>
                      </div>

                      <div id="messageTextArea" #messageTextArea class="container-box"
                           style="padding: -10px; min-height: 200px"></div>

                      <mat-error *ngIf="messageError" style="font-size: x-small;">This field is required</mat-error>

                      <span style="color: #2d7cff;font-size: 13px" fxFlexAlign="end">Learn more about variables</span>
                  </div>
              </div>

              <hr class="fullWidth colorLight">

              <div fxLayout="column" fxLayoutGap="10px">
                  <span class="bolder">This information is relevant for these Listings:</span>
                  <div fxLayout="column">
                      <mat-form-field [color]="'accent'" style="width: 300px;">
                          <mat-select
                                  color="accent"
                                  floatPlaceholder="never"
                                  [(ngModel)]="alertType"
                                  [ngModelOptions]="{standalone: true}"
                                  placeholder="Apply For">
                              <mat-option *ngFor="let alertType of getAllAlertTypes" [value]="alertType.slug">
                                  {{alertType.title}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  <div fxLayout="column">
                      <mat-form-field [color]="'accent'" style="width: 300px;">
                          <mat-select
                                  multiple
                                  color="accent"
                                  floatPlaceholder="never"
                                  [(ngModel)]="propertyIds"
                                  [ngModelOptions]="{standalone: true}"
                                  (onClose)="onMatSelectClose()"
                                  placeholder="Listings to apply to">
                              <mat-select-trigger>
                  <span *ngIf="propertyIds.length === listings.length">
                          All Listings
                        </span>
                                  <span *ngIf="propertyIds.length === 0">
                          No Listing Selected
                        </span>

                                  {{
                                  propertyIds.length > 0 && propertyIds.length !== listings.length ? getListingTitleFromId(propertyIds[0]) : ""
                                  }}
                                  <span class="extra-text"
                                        *ngIf="propertyIds.length > 1 && propertyIds.length !== listings.length">
                          (+{{propertyIds.length - 1}} others)
                        </span>

                              </mat-select-trigger>

                              <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                                  <input matInput placeholder="Search Listing" [formControl]="listingFilter">
                              </mat-form-field>

                              <div fxLayout="column">

                                  <button class="select-button" mat-button
                                          (click)="onSelectAll()">Select All
                                  </button>
                                  <button class="select-button" mat-button
                                          (click)="onSelectNone()">Select None
                                  </button>
                              </div>

                              <mat-option *ngFor="let listing of filteredListings" [value]="listing.id">
                                  {{listing.title}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
              </div>

              <hr class="fullWidth colorLight">

              <div fxLayoutAlign="end center" fxLayoutGap="10px">
                  <mat-spinner *ngIf="isUpdating" [diameter]="30" [strokeWidth]="4"></mat-spinner>
                  <button mat-raised-button *ngIf="openForEdit" [disabled]="isUpdating" (click)="deleteAlert()"
                          color="warn">
                      Delete
                  </button>
                  <div fxFlexAlign="end" fxLayoutAlign="center end" fxLayout="row" fxLayoutGap="10px">
                      <button mat-raised-button
                              fxFlexAlign="end"
                              color="accent"
                              *ngIf="openForEdit"
                              [disabled]="isUpdating"
                              (click)="updateAlert()">
                          Update
                      </button>
                      <button mat-raised-button
                              fxFlexAlign="end"
                              color="accent"
                              *ngIf="!openForEdit"
                              [disabled]="isUpdating"
                              (click)="createAlert()">
                          Create
                      </button>
                  </div>
              </div>
          </div>

          <div *ngIf="openForEdit && isAlertLoading" fxFlex="100%" fxLayout="column"
               id="spinner"
               fxLayoutAlign="center center">
              <mat-spinner [diameter]="60" [strokeWidth]="5" color="accent"></mat-spinner>
              <span style="margin-top: 20px;">Loading Alerts...</span></div>

      </sd-modal-popup-layout>
  `,
  styles: [`

      mat-spinner {
          width: 24px;
          height: 24px;
      }

      .fullWidth {
          width: 100%;
      }

      .tenwidth {
          width: 10%;
      }

      textarea {
          resize: none;
      }

      .colorLight {
          color: #c8c8c8;
      }

      .title {
          font-size: 20px;
          font-weight: bolder;
      }

      .bolder {
          font-weight: bolder;
          font-size: 20px;
      }

      .container-box {
          border-style: solid;
          border-width: 0.1px;
          padding: 5px;
          border-color: #c8c8c8;
      }

  `]
})
export class SettingsAlertPopupComponent implements OnInit, OnDestroy {

  listings: ListingCompact[] = [];
  filteredListings: ListingCompact[] = [];
  listingFilter: FormControl = new FormControl();

  alertId: number;
  title = "New Auto Message";

  offsetPosition = "before";
  offsetReference = "check_in";

  message = "";
  alertType = "include";
  propertyIds: number[] = [];

  sendVia = "booking_channel";
  source = new FormControl(null);
  isAvailable = true;

  to = "guest";

  timeValue = 0;
  timeDenomination = "day";

  openForEdit: boolean;

  titleError = false;
  timeError = false;
  messageError = false;

  getAllTimeDenominations;
  getAllOffsetPositions;
  getAllAlertOffsetReferences;
  getAllAlertTypes;
  getAllSendVias;
  sourcesOptions: { title: string, value: any }[] = [];
  refresh;

  isUpdating = false;

  selectedVariableId;

  popUpTitle = "Create New Auto Message";

  customVariables = getStaticVariables();
  isLoading = false;
  isLoaded = false;
  isAlertLoading = false;
  isAlertLoaded = false;
  isAlive = true;

  options = [
    ...getContactMaintenanceCatagoryTypes(),
    {
      title: "Owner",
      slug: "owner"
    },
    {
      title: "Guest",
      slug: "guest"
    }
  ];

  @ViewChild("messageTextArea", { read: ElementRef }) elMessage: ElementRef;
  @ViewChild("messageTextArea", { read: ElementRef }) body: ElementRef;

  quillBody: Quill;

  titleVariables = QuillUtils.VARIABLES;

  constructor(private router: Router,
              private dialog: MatDialog,
              private automatedMessageRepo: AutomatedMessageRepository,
              private customVariableRepo: CustomVariableRepository,
              private listingRepo: ListingRepository,
              @Inject(MAT_DIALOG_DATA) private data: { id: number }) {

    this.getAllTimeDenominations = getAllTimeDenominations();
    this.getAllOffsetPositions = getAllOffsetPositions();
    this.getAllAlertOffsetReferences = getAllAlertOffsetReferences();
    this.getAllAlertTypes = getAllAlertTypes();
    this.getAllSendVias = getAllSendVias();

    this.alertId = data.id;

    if (this.alertId) {
      this.openForEdit = true;
    } else {
      this.openForEdit = false;
    }
  }

  ngOnInit() {

    if (this.alertId) {
      this.setUpAlert();
    } else {
      setTimeout(() => this.setUpQuill());
    }
    this.sourceOptions();

    this.setupCustomVariables();
    this.listingRepo.getAcceptedListings().subscribe((listings) => {
      this.listings = listings;
      this.filteredListings = this.listings;
    });

    this.listingFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged(),).subscribe((value) => {

      if (value) {
        this.filteredListings = this.listings.filter(l => l.title.toLowerCase().includes(value));
      } else {
        this.filteredListings = this.listings;
      }

      if (this.filteredListings.length === 0) {
        this.filteredListings = this.listings;
      }

    });

  }

  sourceOptions() {
    this.sourcesOptions.push({title: "Airbnb", value: "airbnb"});
    this.sourcesOptions.push({title: "VRBO", value: "homeaway"});
    this.sourcesOptions.push({title: "Plum Guide", value: "plum_guide"});
    this.sourcesOptions.push({title: "Marriott", value: "hvmi"});
    this.sourcesOptions.push({title: "Stayduvet", value: "stayduvet"});
  }

  onMatSelectClose() {
    this.filteredListings = this.listings;
  }

  setUpQuill() {
    const values = this.titleVariables;
    this.quillBody = new Quill("#messageTextArea", {
      theme: "bubble",
      modules: {
        mention: {
          allowedChars: /^[_A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["{"],
          isolatedCharacter: false,
          source: (searchTerm, renderList, mentionChar) => {

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(values[i]);
                }
              }
              renderList(matches, searchTerm);
            }
          },
          renderItem: (item, searchTerm) => {
            return item.value.replace("{", "{{");
          },
          dataAttributes: ["value"]
        },
      },
    });

    this.quillBody.setText(this.message);
    this.quillBody.root.innerHTML = QuillUtils.replaceMentionWithClass(this.quillBody.root.innerHTML);

    this.quillBody.on("text-change", () => {
      this.message = this.quillBody.root.innerText;
    });
  }

  updateAlert() {
    if (!this.validate()) {
      return;
    }
    this.isUpdating = true;
    this.automatedMessageRepo.updateAutomatedMessage(this.alertId, this.createData()).subscribe((success) => {
      this.isUpdating = false;
      this.closePopupClicked();
    }, () => {
      this.isUpdating = false;
    });
  }

  deleteAlert() {
    this.isUpdating = true;
    this.automatedMessageRepo.deleteAutomatedMessage(this.alertId).subscribe((success) => {
      this.isUpdating = false;
      this.closePopupClicked();
    }, () => {
      this.isUpdating = false;
    });
  }

  createAlert() {
    if (!this.validate()) {
      return;
    }
    this.isUpdating = true;
    this.automatedMessageRepo.createAutomatedMessage(this.createData()).subscribe((success) => {
      this.isUpdating = false;
      this.closePopupClicked();
    }, () => {
      this.isUpdating = false;
    });
  }

  closePopupClicked() {
    this.dialog.closeAll();
  }

  addVariable(varibale: CustomVariable) {

    const pos = this.elMessage.nativeElement.selectionStart;
    const message = this.message || "";
    this.message = message.substring(0, pos) + " {{" + varibale.key + "}} " + message.substring(pos);
  }

  setUpAlert() {

    this.automatedMessageRepo.getIsFullAutomatedMessageLoading(this.alertId).subscribe(l => this.isAlertLoading = l);
    this.automatedMessageRepo.getIsFullAutomatedMessageLoaded(this.alertId).subscribe(l => {
      this.isAlertLoaded = l;
      if (this.isAlertLoaded) {
        setTimeout(() => this.setUpQuill());
      }
    });

    this.automatedMessageRepo.getAutomatedMessageById(this.alertId).subscribe(alert => {
      this.alertId = alert.id;
      this.title = alert.title;
      this.popUpTitle = alert.title;

      this.offsetPosition = alert.offset_position;
      this.offsetReference = alert.offset_reference;

      this.message = alert.message;
      this.alertType = alert.alert_type;
      this.propertyIds = alert.property_ids;
      this.sendVia = alert.send_via;
      this.source.setValue(alert.source);
      this.isAvailable = alert.is_active;
      this.to = alert.to;

      const seconds = +alert.offset;

      if (seconds % 86400 === 0) {
        this.timeValue = seconds / 86400;
        this.timeDenomination = "day";
      } else if (seconds % 3600 === 0) {
        this.timeValue = seconds / 3600;
        this.timeDenomination = "hour";
      } else if (seconds % 60 === 0) {
        this.timeValue = seconds / 60;
        this.timeDenomination = "minute";
      } else {
        this.timeValue = seconds;
        this.timeDenomination = "second";
      }
    });
  }

  setupCustomVariables() {
    this.customVariableRepo.getCustomVariables(false).pipe(takeWhile(() => this.isAlive), filter(c => !!c),).subscribe((variables) => {
      this.customVariables = [...variables, ...this.customVariables];

    });

    this.customVariableRepo.getCustomVariablesLoading().pipe(takeWhile(() => this.isAlive))
      .subscribe(l => this.isLoading = l);

    this.customVariableRepo.getCustomVariablesLoaded().pipe(takeWhile(() => this.isAlive))
      .subscribe(l => this.isLoaded = l);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  openMessageDescriptionPopup() {
    console.log("Notes popup");
    const dialogRef = this.dialog.open(NotesPopupComponent, {
      data: {
        text: this.quillBody.root.innerHTML
      }
    });

    dialogRef.updateSize("100%", "100%");

    dialogRef.afterClosed().subscribe(result => {
      this.quillBody.root.innerHTML = result;
    });
  }

  onSelectAll() {
    this.propertyIds = this.listings.map(l => l.id);
  }

  onSelectNone() {
    this.propertyIds = [];
  }

  getListingTitleFromId(id: number): string {
    return this.listingRepo.getListingTitle(id);
  }

  private validate() {

    this.titleError = false;
    this.timeError = false;
    this.messageError = false;

    if (isNullOrUndefined(this.title) || this.title.trim() === "") {
      this.titleError = true;
      return false;
    }

    if (isNullOrUndefined(this.timeValue)) {
      this.timeError = true;
      return false;
    }

    if (isNullOrUndefined(this.message) || this.message.trim() === "") {
      this.messageError = true;
      return false;
    }

    this.titleError = false;
    this.timeError = false;
    this.messageError = false;

    return true;
  }

  private createData(): any {
    console.log(QuillUtils.getText(this.quillBody.root.innerHTML));
    const data: any = {};
    data.title = this.title;
    data.offset_position = this.offsetPosition;
    data.offset_reference = this.offsetReference;
    data.message = QuillUtils.getText(this.quillBody.root.innerHTML);
    data.alert_type = this.alertType;
    data.to = this.to;

    console.log(data);

    if (this.propertyIds.length === 0 && this.alertType === "exclude") {
      data.property_ids = [];
    }

    if (this.propertyIds.length === this.listings.length && this.alertType === "include") {
      data.property_ids = [];
      data.alert_type = "exclude";
    } else {
      data.property_ids = this.propertyIds;
    }
    data.send_via = this.sendVia;
    data.source = this.source.value;
    data.is_active = this.isAvailable;

    let offset;
    if (this.timeDenomination === "day") {
      offset = this.timeValue * 86400;
    } else if (this.timeDenomination === "hour") {
      offset = this.timeValue * 3600;
    } else if (this.timeDenomination === "minute") {
      offset = this.timeValue * 60;
    } else {
      offset = this.timeValue;
    }
    data.offset = offset;
    return data;
  }

}

export interface AlertPopupData {
  alert: AutomatedMessageFull;
}
