import { Clipboard } from "@angular/cdk/clipboard";
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { unescape } from "querystring";
import Quill from "quill";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, startWith, take, takeUntil, takeWhile } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { environment } from "../../../../environments/environment";
import { SD_DayHalf, SD_Hour, SD_Minute } from "../../../enums/common.enum";
import {
  HomeOwnerTaskCategory,
  TaskCategory,
  TaskEnumHelper,
  TaskPaymentBy,
  TaskRepeatFrequency,
  TaskStatus
} from "../../../enums/task.enum";
import { AttachmentEnumHelper, AttachmentType } from "../../../enums/uploading-enum";
import { TaskAssigneeCategory, UserCategory, UserEnumHelper, UserOtherRole } from "../../../enums/user.enum";
import { BookingCompact } from "../../../models/new/booking/booking-compact.model";
import { BookingFull } from "../../../models/new/booking/booking-full.model";
import { Inventory } from "../../../models/new/inventory.model";
import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { GuestReview } from "../../../models/new/reviews/guest-review";
import { Comment } from "../../../models/new/tasks/comment";
import { TaskAttachment } from "../../../models/new/tasks/task-attachment.model";
import { TaskExpense } from "../../../models/new/tasks/task-expense.model";
import { TaskFull } from "../../../models/new/tasks/task-full.model";
import { TaskUploadProgress } from "../../../models/new/tasks/task-upload-progress";
import { UploadComplete } from "../../../models/new/upload-complete";
import { UploadFile } from "../../../models/new/upload-file";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { UserFull } from "../../../models/new/user/user-full.model";
import { BookingModelUtil } from "../../../models/utils/booking-model.util";
import { TaskModelUtil } from "../../../models/utils/task-model.util";
import { UserModelUtil } from "../../../models/utils/user-model.util";
import { BookingRepository } from "../../../repository/booking.repository";
import { ListingRepository } from "../../../repository/listing.repository";
import { OptionsRepository } from "../../../repository/options.repository";
import { ReviewRepository } from "../../../repository/review.repository";
import { TaskRepository } from "../../../repository/task.repository";
import { UserRepository } from "../../../repository/user-repository";
import { AppService } from "../../../services/app.service";
import { SnackbarService } from "../../../services/snackbar.service";
import { getDateObj, getSDDayObject, SDDay } from "../../../utils/calendar-utils";
import { CommonUtil } from "../../../utils/common.util";
import DateUtils from "../../../utils/date";
import { QuillUtils } from "../../../utils/quill.util";
import { GenericConfirmationPopupComponent } from "../../shared/components/confirmation-popup";

import { AddInventoryPopupComponent } from "./popups/add-inventory-popup";
import { ConfirmTaskEscalatePopupComponent } from "./popups/confirm-escalate.popup";
import { DeleteCommentConfirmationPopupComponent } from "./popups/delete-comment-confirmation.popup";
import { ItemDescriptionPopupComponent } from "./popups/item-description-popup";
import { SlideShowPopupComponent } from "./popups/slide-show-popup";
import { TaskNotesPopupComponent } from "./popups/task-notes.popup";

@Component({
  selector: "sd-task-show-create",
  template: `
    <div class="container" *ngIf="!taskExist">
      <div class="header" fxFlex="row" fxLayoutAlign="start center">
        <mat-toolbar color="accent">
          <h2>
            <ng-container *ngIf="taskId && !allowEdit && task">{{ task.title }}</ng-container>
            <ng-container *ngIf="!taskId || allowEdit">New Task</ng-container>
          </h2>
          <span class="space-filler"></span>

          <button class="do-not-print" mat-icon-button *ngIf="taskId" (click)="refresh()">
            <mat-icon>autorenew</mat-icon>
          </button>
          <button mat-icon-button
                  *ngIf="taskId && !taskLoading"
                  class="do-not-print"
                 (click)="printButtonClicked()">
            <mat-icon>print</mat-icon>
          </button>
          <button class="do-not-print" mat-icon-button *ngIf="taskId" (click)="shareButtonClicked()">
            <mat-icon>share</mat-icon>
          </button>
          <button class="do-not-print" mat-icon-button (click)="closeButtonClicked()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar>
      </div>


      <div  id="print-section" class="body">
        <!--Displaying Task Content-->
        <div class="task-show-container" *ngIf="taskId && !allowEdit">

          <sd-center-spinner *ngIf="taskLoading"></sd-center-spinner>

          <div class="content" *ngIf="taskLoaded && !!task">

            <!--Task Details-->

            <div fxLayout="column" fxLayoutGap="10px">
              <mat-chip-list fxHide fxHide.xs="false" fxLayoutAlign="center center">
                <mat-chip *ngIf="task.cloned_from_id" style="background-color:red">Escalated Task</mat-chip>
                <mat-chip *ngIf="task.auto_task_id" style="background-color:yellow">Auto Task</mat-chip>
                <mat-chip *ngIf="task.creator_task_id" style="background-color:lightblue">Repeating Task</mat-chip>
                <mat-chip *ngIf="!task.cloned_from_id && !task.auto_task_id && !task.creator_task_id"
                          style="background-color:grey">Manual Task
                </mat-chip>
              </mat-chip-list>

              <div fxLayoutAlign="start center">
                <span class="heading matrix">Title:</span>
                <span class="truncate-1l" [matTooltip]="task.title">{{ task.title }}</span>
              </div>
              <div fxLayoutAlign="start center">
                <span class="heading matrix">Due Date:</span>
                <span>
                    {{task.due_date | dateFix | date}}
                  <ng-container *ngIf="task.due_time"> {{task.due_time}}</ng-container>
                  </span>
              </div>
              <div fxLayoutAlign="start center" *ngIf="showTaskCreatorName(task)">
                <span class="heading matrix">Created By:</span>
                <span>{{getTaskCreatorName(task) }}

                  <button class="do-not-print" mat-button *ngIf="task.creator_task_id || task.auto_task_id"
                          (click)="openCreatorTask()">
                      <mat-icon>launch</mat-icon>
                    </button>
                </span>
              </div>

              <div fxLayoutAlign="start center" *ngIf="task.cloned_from_id">
                <span class="heading matrix">Escalated From:</span>
                <span>{{task.cloned_from_id }}

                  <button mat-button
                          class="do-not-print"
                          (click)="openCreatorTask(true)">
                      <mat-icon>launch</mat-icon>
                    </button>
                </span>
              </div>
              <div fxLayoutAlign="start center">
                <span class="heading matrix">Created On:</span>
                <span>{{task.created_at | dateFix | utcToLocalTime | date:"MMM dd, yyyy hh:mm:ss"}}</span>
              </div>
              <div fxLayoutAlign="start center" *ngIf="!!this.task?.clonedTasks?.data?.length">
                <span class="heading matrix">Escalated Tasks:</span>
                <span style="min-width: 190px;">
                  <li style="list-style: none">
                    <ul style="padding: 0; margin: 0; font-size: 0.75rem;" *ngFor="let t of task.clonedTasks.data">
                      <span>
                        {{t.id}}
                        <button mat-button
                                class="do-not-print"
                                (click)="escalatedTasks(t.id)">
                          <mat-icon>launch</mat-icon>
                        </button>
                      </span>
                    </ul>
                  </li>
                </span>
              </div>
              <div fxLayoutAlign="start center">
                <span class="heading matrix">Assignee:</span>
                <span>{{ UserModelUtil.getFullName(task.assignee?.data) }}</span>
              </div>
              <div fxLayoutAlign="start center" *ngIf="isHouseKeeper || isEmployee && task.employee_id">
                <span class="heading matrix">Employee:</span>
                <span>{{ UserModelUtil.getFullName(task.employee?.data) }}</span>
              </div>
              <div fxLayoutAlign="start center">
                <span class="heading matrix">Category:</span>
                <span>{{ TaskEnumHelper.getTaskCategoryTitle(task.type) }}</span>
              </div>
              <div fxLayoutAlign="start center">
                <span class="heading matrix">Status:</span>
                <span>{{ TaskEnumHelper.getTaskStatusEnum(task.status).title }}</span>
              </div>
              <div fxLayoutAlign="start center">
                <span class="heading matrix">Repeat:</span>

                <span>
                  {{TaskEnumHelper.getTaskRepeatFrequencyTitle(task.repeat_frequency)}}
                </span>
              </div>

              <div fxLayoutAlign="start center" *ngIf="task.repeat_frequency !== TaskRepeatFrequency.NEVER">
                <span class="heading matrix">No Of Times:</span>
                <span *ngIf="task.no_of_times">{{ task.no_of_times }}</span>
                <span *ngIf="!task.no_of_times">Infinite</span>
              </div>

              <div fxLayoutAlign="start center" *ngIf="showHouseKeeperExpenses">
                <span class="heading matrix">Amount:</span>
                <span><b>$</b>{{task.amount}}</span>
              </div>
              <div fxLayoutAlign="start center">
                <span class="heading matrix">Who will pay:</span>
                <span>{{ TaskEnumHelper.getTaskPaymentByTitle(task.payment_by) }}</span>
              </div>
              <div fxLayoutAlign="start center" *ngIf="task.paid_on">
                <span class="heading matrix">Paid On:</span>
                <span>{{ task.paid_on | utcToLocalTime}}</span>
              </div>
              <div fxLayoutAlign="start center" *ngIf="task.completed_on">
                <span class="heading matrix">Completed On:</span>
                <span>{{ task.completed_on | utcToLocalTime}}</span>
              </div>
            </div>

            <!--Booking Details-->

            <ng-container *ngIf="task.booking_id">
              <hr>
              <div fxLayoutAlign="start center">
                <span class="heading matrix">Reservation:</span>
                <span>
                    {{ BookingModelUtil.getFormattedConfirmationCode(task.booking.data) }}
                  <button mat-button
                          *ngIf="isAdmin || isHouseKeeper || user?.managementContact?.data?.category === 'agent'"
                          class="do-not-print"
                          (click)="taskReservationClicked()">
                      <mat-icon>launch</mat-icon>
                    </button>
                  </span>
              </div>

              <div fxLayoutAlign="start center" *ngIf="task.booking.data.check_out_time">
                <span class="heading matrix">Guest Departure Time:</span>
                <span>
                    {{ task.booking.data.check_out_time }}
                  </span>
              </div>
            </ng-container>

            <!--Listing Details-->
            <ng-container>
              <hr>
              <div fxLayoutAlign="start center">
                <span class="heading matrix">Listing Name:
                </span>
                <span>{{ getListingTitle(task.property_id) }}
                  <button class="do-not-print" *ngIf="!isVendor" mat-icon-button (click)="redirectToListing(task.property_id)"><mat-icon>launch</mat-icon></button>
                </span>
                <span style="margin-left: 15px; font-weight: bold">On Going Tasks
                  <button class="do-not-print" *ngIf="!isVendor" mat-icon-button (click)="upcomingTasks()"><mat-icon>launch</mat-icon></button>
                </span>
              </div>

              <div fxLayoutAlign="start center" *ngIf="!!propertyAccessTitle">
                <span class="heading matrix">{{propertyAccessTitle}}: </span>
                <span>{{ propertyAccessKey }}</span>
              </div>

            </ng-container>


            <div *ngIf="showHouseKeeperExpenses" class="mt-10 expense-container" fxLayout="column" fxLayoutGap="10px">

              <hr>
              <div fxLayoutAlign="space-between center">
                <div fxFlex="55%">
                  <span fxLayoutAlign="start" class="price-heading">Item</span>
                </div>
                <div fxFlex="15%">
                  <span fxLayoutAlign="center" class="price-heading">{{ 'Quantity' | mobileText:'Qty.' }}</span>
                </div>
                <div fxFlex="15%">
                  <span fxLayoutAlign="end" class="price-heading">Price</span>
                </div>
                <div fxFlex="15%">
                  <span fxLayoutAlign="end" class="price-heading">{{ 'Amount' | mobileText:'Amt.' }}</span>
                </div>
              </div>
              <hr>

              <ng-container *ngIf="task.expenses.data.length === 0">
                <div *ngIf="task.expenses.data.length === 0" fxLayout="row" fxLayoutAlign="center center">
                  <span class="fs-10-i">No Expenses</span>
                </div>
                <hr>
              </ng-container>

              <ng-container *ngIf="task.expenses.data.length > 0">
                <ng-container *ngFor="let expense of task.expenses.data">
                  <div fxLayoutAlign="space-between center">
                    <div class="title-detail-container" fxFlex="55%">
                      <div class="c-pointer expense-title-description mr-5"
                           *ngIf="expense.desc">
                        <mat-icon color="accent" [matTooltip]="expense.desc"
                                  matTooltipPosition="right">help
                        </mat-icon>
                      </div>
                      <span fxLayoutAlign="start" class="detail task-title truncate-1l">{{ expense.title }}</span>
                    </div>
                    <div fxFlex="15%">
                      <span fxLayoutAlign="center" class="detail">{{ expense.quantity }}</span>
                    </div>
                    <div fxFlex="15%">
                      <span fxLayoutAlign="end" class="detail">\${{ expense.price }}</span>
                    </div>
                    <div fxFlex="15%">
                      <span fxLayoutAlign="end"
                            class="detail">\${{ CommonUtil.formatLargeNumber(expense.quantity * expense.price) }}</span>
                    </div>
                  </div>

                  <div class="expense-print-content print-only">
                    <span>{{ expense.desc }}</span>
                  </div>
                </ng-container>

                <div fxLayoutAlign="space-between center">
                  <span class="heading matrix">Sub Total</span>
                  <span><b>$</b> {{task.amount}}</span>
                </div>
                <hr>
                <div fxLayoutAlign="space-between center">
                  <span class="heading matrix">Tax</span>
                  <span><b>$</b> 0</span>
                </div>
                <hr>
                <div fxLayoutAlign="space-between center">
                  <span class="heading matrix">Total</span>
                  <span><b>$</b> {{task.amount}}</span>
                </div>
                <hr>
              </ng-container>
            </div>

            <div *ngIf="showAssignEmployeeInput && employees.length>0" id="assignEmployeeContainer" fxLayout="column"
                 fxLayoutGap="5px">
              <span class="largeFont"><b>Employee:</b></span>
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                <mat-form-field [color]="'accent'" fxFlex="100%">
                  <mat-select placeholder="Assign Task to*"
                              [disabled]="isUpdating"
                              [(ngModel)]="employeeId"
                              [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let employee of employees" [value]="employee.id">
                      {{UserModelUtil.getFullName(employee)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-raised-button color="accent" [disabled]="isUpdating" (click)="assignEmployee()">ASSIGN
                </button>
              </div>
            </div>

            <span class="largeFont"><b>Directions:</b></span>

            <div class="task-notes-container">
              <ng-container *ngIf="task.description">{{ task.description }}</ng-container>
              <ng-container *ngIf="!task.description" class="fs-i">No Directions.</ng-container>
            </div>

            <hr>


              <div  style="width:30%;" ngStyle.lt-sm="width:100%" *ngIf="(isAdmin || (isVendor && !isEmployee) || user?.managementContact?.data?.category === 'agent') && task.property_id">
                  <sd-small-calendar [showArrowButtons]="showArrowButtons" [listingId]="task.property_id"
                                     [currentMonth]="currentMonth"></sd-small-calendar>
              </div>

            <div class="attachments-container" *ngIf="task.attachments && task.attachments.data.length > 0">
              <div fxLayout="row">
                <span class="heading big-heading">Attachments:</span>
                <span fxFlex="1 1 auto"></span>
                <span class="do-not-print" style="align-self: center;">Preview</span>
                <button class="do-not-print" mat-icon-button color="accent" (click)="openSlideShowPopup()">
                  <mat-icon>slideshow</mat-icon>
                </button>
              </div>
              <ng-container>
                <mat-grid-list class="page-break" cols="5" gutterSize="2px">
                  <mat-grid-tile *ngFor="let image of task.attachments.data">
                    <div class="grid-tile-container">
                      <mat-grid-tile-header class="do-not-print" style="z-index: 3">
                        <div fxLayout="row" style="width: 100%;">
                          <button mat-icon-button (click)="preview(image.url)" matTooltip="Preview">
                            <mat-icon>visibility</mat-icon>
                          </button>
                          <span class="space-filler"></span>
                        </div>
                      </mat-grid-tile-header>
                      <img [src]="TaskModelUtil.getThumbnailUrl(image)"
                           [ngClass]="{ 'limit-width': image.type !== AttachmentType.IMAGE }" class="grid-content"/>
                      <mat-grid-tile-footer class="do-not-print">
                        <span
                          style="text-overflow: ellipsis;white-space: nowrap; overflow: hidden">{{image.title}}</span>
                      </mat-grid-tile-footer>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
              </ng-container>
            </div>
          </div>
        </div>

        <!--Editing and Create Form-->
        <div style="margin: 1%" class="task-form-container" *ngIf="!taskId || (taskId && allowEdit)">
          <form fxLayout="column" class="content" [formGroup]="taskForm">
            <div fxLayout="column" fxLayoutGap="15px">

              <ng-container
                *ngIf="user.is_admin || (user.is_management_contact&&(user?.managementContact?.data?.category === UserCategory.HOMEOWNER || user?.managementContact?.data?.category === UserCategory.AGENT)) || isVendorTask">

                <mat-form-field [color]="'accent'">
                  <input formControlName="title" matInput placeholder="Name the Task*" #titleRef>
                  <mat-error>name field is required.</mat-error>
                </mat-form-field>
                <mat-error *ngIf="titleRef.value.length >= 255">
                  Title length exceeded.Please specify the details in other section.
                </mat-error>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <mat-form-field [color]="'accent'" fxFlex="45%">
                    <mat-select formControlName="property_id" placeholder="Choose a Listing"
                                (selectionChange)="selectedValueChanged()"
                                (openedChange)="!$event&&onMatSelectClose()">


                      <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                        <input matInput placeholder="Search Listing" [formControl]="listingFilter">
                      </mat-form-field>

                      <mat-option *ngFor="let listing of filteredListings"
                                  [value]="listing.id">
                        {{listing.title}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Listing is Required</mat-error>
                  </mat-form-field>

                  <mat-form-field [color]="'accent'" fxFlex="45%">
                    <mat-select placeholder="Type Of Task*"
                                [formControl]="categoryControl">
                      <mat-option *ngFor="let category of categories"
                                  [value]="category">{{TaskEnumHelper.getTaskCategoryTitle(category)}}
                      </mat-option>
                    </mat-select>
                    <mat-error>type is required.</mat-error>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <mat-form-field [color]="'accent'" fxFlex="30%" *ngIf="!isUserHomeowner">
                    <mat-select placeholder="Assign Task to*"
                                [(ngModel)]="assigneeId"
                                [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="onSelectNone()"
                                (openedChange)="!$event&&onMatSelectClose()">
                      <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                        <input matInput placeholder="Search Assignee" [formControl]="assigneeFilter">
                      </mat-form-field>
                      <mat-option *ngFor="let assignee of filteredAssignees" [value]="assignee.id">
                        {{assignee.first_name}} {{assignee.last_name}}
                      </mat-option>
                    </mat-select>
                    <mat-error>required.</mat-error>
                  </mat-form-field>

                  <mat-form-field [color]="'accent'" fxFlex="30%" *ngIf="!isUserHomeowner">
                    <mat-select placeholder="Assignee Type"
                                [(ngModel)]="selectedAssigneeType"
                                (ngModelChange)="onAssigneeTypeChange()"
                                [ngModelOptions]="{standalone: true}">
                      <mat-option (click)="onSelectNone()">None</mat-option>
                      <mat-option *ngFor="let type of assigneeCategories" [value]="type">
                        {{UserEnumHelper.getUserCategory(type)}}
                      </mat-option>
                    </mat-select>
                    <mat-error>required.</mat-error>
                  </mat-form-field>

                  <mat-form-field *ngIf="!isUserHomeowner" [color]="'accent'" fxFlex="30%">
                    <mat-select placeholder="Status"
                                [(ngModel)]="status"
                                [ngModelOptions]="{standalone: true}">
                      <mat-option *ngFor="let status of statusTypes"
                                  [value]="status"
                                  [ngStyle]="{
                              'background-color': TaskEnumHelper.getTaskStatusEnum(status).color,
                              'color': status.slug == 'waiting_for_approval' ? 'white' : 'black'
                            }">
                        {{TaskEnumHelper.getTaskStatusEnum(status).title}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div *ngIf="!isUserHomeowner" fxLayout.lt-md="column" fxLayoutAlign="start center"
                     fxLayoutAlign.lt-md="start start"
                     fxLayoutGap="2%">
                  <div style="width:20%;">
                    <span style="font-weight: bold">Repeat Task</span>
                    <span [style.color]="!repeatTasks ? 'blue' : 'black' ">No</span>
                    <mat-slide-toggle [checked]="repeatTasks"
                                      (change)="repeatToggleChange($event)"></mat-slide-toggle>
                    <span [style.color]="repeatTasks ? 'blue' : 'black' ">Yes</span>
                  </div>

                  <div fxLayoutGap="2%" fxLayout="row" style="max-width: 100%; width: 100%">
                    <div style="max-width: 35%" fxLayout="column" fxLayoutGap="10px">
                      <mat-form-field [color]="'accent'" fxFlex="20%" *ngIf="repeatTasks">
                        <mat-select placeholder="Frequency of Repeat"
                                    [(ngModel)]="repeatFrequency"
                                    [ngModelOptions]="{standalone: true}">
                          <mat-option *ngFor="let taskFrequencyType of taskRepeatFrequencyTypes"
                                      [value]="taskFrequencyType">
                            {{TaskEnumHelper.getTaskRepeatFrequencyTitle(taskFrequencyType)}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <mat-form-field [color]="'accent'" style="max-width: 30%" fxFlex="20%"
                                    *ngIf="repeatTasks">
                      <mat-select (selectionChange)="noOfTimesChanged($event)" formControlName='no_of_times'
                                  placeholder="# of times">
                        <mat-option [value]="'1'"> Infinite</mat-option>
                        <mat-option [value]="'2'"> Custom Amount</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="repeatTasks && showCustomValueField" [color]="'accent'" fxFlex="20%">
                      <input matInput placeholder="Enter the number" formControlName="custom_no_of_times">
                    </mat-form-field>
                  </div>
                </div>


                <div fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="start start">
                  <div fxFlex="45%" fxLayout="column" fxLayoutGap="10px">
                    <mat-form-field [color]="'accent'" fxFlex="60%">
                      <input matInput [matDatepicker]="picker" placeholder="Due date"
                             formControlName='due_date'>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <div fxLayout="row" fxLayoutGap="15px">
                      <a style="cursor:pointer;" (click)="setDate('today')">Today</a>
                      <a style="cursor:pointer;" (click)="setDate('tomorrow')">Tomorrow</a>
                      <p *ngIf="!!propertyId.value && !!selectBookings[0]?.start"
                         style="cursor:pointer;" (click)="setDate('check')">Check In Date for next Reservation</p>
                    </div>
                  </div>

                  <div *ngIf="showTime" fxLayoutAlign.lt-md="start center" fxLayoutAlign="center center"
                       fxLayoutGap="5px" fxFlex="45%">
                    <mat-form-field style="flex: 1 1 100%" [color]="'accent'" fxFlex="45%" fxFlex.lt-md="20%">
                      <mat-select placeholder="HH"
                                  [(ngModel)]="hh"
                                  [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let hourType of hourTypes"
                                    [value]="hourType">{{hourType}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <span style="font-weight: bold">:</span>
                    <mat-form-field style="flex: 1 1 100%" [color]="'accent'" fxFlex="45%" fxFlex.lt-md="20%">
                      <mat-select placeholder="MM"
                                  [(ngModel)]="mm"
                                  [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let minuteType of minuteTypes" [value]="minuteType">{{minuteType}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field style="flex: 1 1 100%" [color]="'accent'" fxFlex.lt-md="15%">
                      <mat-select placeholder="AM"
                                  [(ngModel)]="meridian"
                                  [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let dayHalf of dayHalfTypes" [value]="dayHalf">{{dayHalf}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div>
                  <span style="font-weight: bold">Time</span>
                  <span [style.color]="!showTime ? 'blue' : 'black' ">No</span>
                  <mat-slide-toggle [checked]="showTime" (change)="timeToggleChange($event)"></mat-slide-toggle>
                  <span [style.color]="showTime ? 'blue' : 'black' ">Yes</span>
                </div>

                <div style="width:30%;" ngStyle.lt-sm="width:100%" *ngIf="(isAdmin || (isVendor && !isEmployee) || user?.managementContact?.data?.category === 'agent') && propertyId.value">
                  <sd-small-calendar [showArrowButtons]="showArrowButtons" [listingId]="propertyId.value"
                                     [currentMonth]="currentMonth"></sd-small-calendar>
                </div>

                <div *ngIf="!isUserHomeowner" fxLayoutAlign="space-between center" style="width: fit-content">
                  <span fxFlex="80%" style="margin-right: 15px">Does this task involve a reservation?</span>
                  <span [style.color]="!showGuest ? 'blue' : 'black' ">No</span>
                  <mat-slide-toggle (change)="reservationToggleChange($event)" [checked]="showGuest"
                                    [disabled]="!selectedListingId">
                  </mat-slide-toggle>
                  <span [style.color]="showGuest ? 'blue' : 'black' ">Yes</span>
                </div>

                <div style="display: flex; align-items: flex-end" *ngIf="showGuest">
                  <span>Select Guest</span>
                  <mat-icon style="cursor: pointer; margin-left: 20px" (click)="changeGuest()">
                    {{editGuest ? 'done' : 'edit'}}
                  </mat-icon>
                </div>

                <div *ngIf="!isUserHomeowner && editGuest">
                  <mat-form-field [color]="'accent'" *ngIf="showGuest" style="width: 48vw">
                    <input matInput placeholder="Search guest name" [matAutocomplete]="auto"
                           formControlName="booking_id">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getGuestName.bind(this)">
                      <mat-option *ngFor="let booking of filteredBookings | async"
                                  [ngStyle]="{'color': 'white'}"
                                  (click)="onGuestChange(booking.id)"
                                  [value]="booking.id">
                        {{booking.guest.data.first_name}} {{ booking.guest.data.last_name}} | <span
                        style="font-style: italic; font-weight: 100; color:#fff;"> {{getListingTitleFromId(booking.property_id)}}
                        on dates {{booking.start | date}} - {{booking.end | date}}</span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>

                  <mat-spinner *ngIf="loadingGuest && showGuest" color="accent" [diameter]="30"
                               [strokeWidth]="4"></mat-spinner>
                  <div *ngIf="selectedBooking" class="width 50">
                    {{getListingTitle(propertyId.value)}} :
                    {{selectedBooking?.start | dateFix | utcToLocalTime | date:'dd-MMM'}} to
                    {{selectedBooking?.end | dateFix | utcToLocalTime | date:'dd-MMM'}}
                  </div>
                </div>

                <div *ngIf="!isUserHomeowner" fxLayoutAlign="space-between center" style="width: fit-content">
                  <span fxFlex="80%" style="margin-right: 15px">Does this task have expenses?</span>
                  <span [style.color]="!showExpense ? 'blue' : 'black' ">No</span>
                  <mat-slide-toggle [checked]="showExpense" (change)="expenseToggleChange($event)">
                  </mat-slide-toggle>
                  <span [style.color]="showExpense ? 'blue' : 'black' ">Yes</span>
                </div>
                <div *ngIf="showExpense && !isUserHomeowner">
                  <div fxLayout="column">
                    <div fxLayout="row" fxLayoutAlign="start start">
                      <div fxFlex="4%">
                        <span fxLayoutAlign="start" class="price-heading"></span>
                      </div>
                      <div fxFlex="30%">
                        <span fxLayoutAlign="start" class="price-heading">Product</span>
                      </div>
                      <div fxFlex="31%">
                        <span fxLayoutAlign="start" class="price-heading">{{ 'Description' | mobileText:'Desc.'
                          }}</span>
                      </div>
                      <div fxFlex="12.5%">
                        <span fxLayoutAlign="start" class="price-heading">{{ 'Quantity' | mobileText:'Qty.' }}</span>
                      </div>
                      <div fxFlex="12.5%">
                        <span fxLayoutAlign="start" class="price-heading">{{ 'Price' | mobileText:'$$$' }}</span>
                      </div>
                      <div fxFlex="10%">
                        <span fxLayoutAlign="start" class="price-heading">{{ 'Amount' | mobileText:'Amt.' }}</span>
                      </div>
                    </div>
                    <hr>
                    <div fxLayout="column" *ngFor="let expense of expenses; let index = index">
                      <div fxLayout="row" fxLayoutAlign="start start" class="edit-expense-container">
                        <div fxFlex="4%" style="margin-top: 8px;">
                          <mat-icon *ngIf="expense.inventory_id">store_mall_directory</mat-icon>
                        </div>
                        <div fxFlex="30%">
                          <mat-form-field [color]="'accent'">
                            <input matInput
                                   [(ngModel)]="expense.title"
                                   [ngModelOptions]="{standalone: true}"
                                   [value]="expense.title"
                                   [disabled]="!expense.is_editable"
                                   placeholder="Enter item name">
                          </mat-form-field>
                        </div>
                        <div fxFlex="31%">
                          <mat-form-field [color]="'accent'">
                            <input matInput
                                   [(ngModel)]="expense.desc"
                                   [ngModelOptions]="{standalone: true}"
                                   [value]="expense.desc"
                                   [disabled]="!expense.is_editable"
                                   placeholder="Enter item description">
                            <button mat-button matSuffix mat-icon-button
                                    [disabled]="!expense.is_editable"
                                    (click)="openItemDescriptionPopup(index, false)"
                                    type="button">
                              <mat-icon>fullscreen</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
                        <div fxFlex="12.5%">
                          <mat-form-field [color]="'accent'">
                            <input matInput
                                   min="1"
                                   [disabled]="!expense.is_editable"
                                   [(ngModel)]="expense.quantity"
                                   [ngModelOptions]="{standalone: true}"
                                   placeholder="Quantity"
                                   [value]="expense.quantity">
                          </mat-form-field>
                        </div>
                        <div fxFlex="12.5%">
                          <mat-form-field [color]="'accent'">
                            <span matPrefix>$ &nbsp;</span>
                            <input matInput
                                   min="0"
                                   [disabled]="!expense.is_editable"
                                   [(ngModel)]="expense.price"
                                   [ngModelOptions]="{standalone: true}"
                                   [value]="expense.price"
                                   placeholder="Price">
                          </mat-form-field>
                        </div>
                        <div fxLayoutAlign="start center" fxFlex="10%">
                          <span fxFlex="70%">\${{ CommonUtil.formatLargeNumber(expense.price * expense.quantity)
                            }}</span>
                          <button fxFlex="30%" mat-icon-button color="'accent'"
                                  (click)="removeItem(index)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <button fxFlex="45%" mat-button style="color: #2d7cff"
                            fxLayoutGap="10px" (click)="addItem()" type="button">
                      <mat-icon>add_circle</mat-icon>
                      <span style="font-size: medium"> Add new item</span>
                    </button>
                    <button fxFlex="45%" mat-button style="color: #2d7cff"
                            fxLayoutGap="10px" (click)="openInventoryPopup()" type="button">
                      <mat-icon>add_circle</mat-icon>
                      <span style="font-size: medium"> Add item from inventory</span>
                    </button>
                  </div>

                  <hr>

                  <mat-form-field [color]="'accent'" style="width:40%;">
                    <mat-select placeholder="Who Will Pay?*"
                                [(ngModel)]="paymentByOption"
                                [ngModelOptions]="{standalone: true}">
                      <mat-option *ngFor="let paymentByOption of paymentByOptions" [value]="paymentByOption">
                        {{TaskEnumHelper.getTaskPaymentByTitle(paymentByOption)}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <mat-checkbox *ngIf="!isUserHomeowner"
                              (change)="changeStatus($event)" class="width50">
                  Task Complete
                </mat-checkbox>


                <div fxLayout="row">
                  <p style="font-weight: bold; font-size: 18px; padding-left: 5px;">Directions</p>
                  <span fxFlex="1 1 auto"></span>
                  <button type="button" mat-button matSuffix mat-icon-button (click)="openNotesDescriptionPopup()">
                    <mat-icon>fullscreen</mat-icon>
                  </button>
                </div>
                <div fxLayout="row">
                <textarea formControlName='description' class="textArea" placeholder="Directions" rows="5">
                </textarea>
                </div>

              </ng-container>


              <ng-container
                *ngIf="!user.is_admin && (user.is_management_contact && user?.managementContact?.data?.category !== UserCategory.HOMEOWNER) && !isVendorTask">

                <mat-form-field [color]="'accent'" fxFlex="100%" *ngIf="isHouseKeeper && !isEmployee">
                  <mat-select placeholder="Assign Task to*"
                              [(ngModel)]="employeeId"
                              [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let employee of employees" [value]="employee.id">
                      {{UserModelUtil.getFullName(employee)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-checkbox
                  (change)="changeStatus($event)" class="width50">
                  Task Complete
                </mat-checkbox>

                <div>
                  <div fxLayout="column">
                    <div fxLayout="row" fxLayoutAlign="start start">
                      <div fxFlex="4%">
                        <span fxLayoutAlign="start" class="price-heading"></span>
                      </div>
                      <div fxFlex="30%">
                        <span fxLayoutAlign="start" class="price-heading">Product</span>
                      </div>
                      <div fxFlex="31%">
                        <span fxLayoutAlign="start" class="price-heading">{{ 'Description' | mobileText:'Desc.'
                          }}</span>
                      </div>
                      <div fxFlex="12.5%">
                        <span fxLayoutAlign="start" class="price-heading">{{ 'Quantity' | mobileText:'Qty.' }}</span>
                      </div>
                      <div fxFlex="12.5%">
                        <span fxLayoutAlign="start" class="price-heading">{{ 'Price' | mobileText:'$$$' }}</span>
                      </div>
                      <div fxFlex="10%">
                        <span fxLayoutAlign="start" class="price-heading">{{ 'Amount' | mobileText:'Amt.' }}</span>
                      </div>
                    </div>
                    <hr>
                    <div fxLayout="column" *ngFor="let expense of expenses; let index = index">
                      <div fxLayout="row" fxLayoutAlign="start start" class="edit-expense-container">
                        <div fxFlex="4%" style="margin-top: 8px;">
                          <mat-icon *ngIf="expense.inventory_id">store_mall_directory</mat-icon>
                        </div>
                        <div fxFlex="30%">
                          <mat-form-field [color]="'accent'">
                            <input matInput
                                   disabled="true"
                                   [value]="expense.title"
                                   placeholder="Enter item name">
                          </mat-form-field>
                        </div>
                        <div fxFlex="31%">
                          <mat-form-field [color]="'accent'">
                            <input matInput
                                   disabled="true"
                                   [value]="expense.desc"
                                   placeholder="Enter item description">
                            <button mat-button matSuffix mat-icon-button (click)="openItemDescriptionPopup(index, true)"
                                    type="button">
                              <mat-icon>fullscreen</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
                        <div fxFlex="12.5%">
                          <mat-form-field [color]="'accent'">
                            <input matInput
                                   disabled="true"
                                   placeholder="Quantity"
                                   [value]="expense.quantity">
                          </mat-form-field>
                        </div>
                        <div fxFlex="12.5%">
                          <mat-form-field [color]="'accent'">
                            <span matPrefix>$ &nbsp;</span>
                            <input matInput
                                   disabled="true"
                                   [value]="expense.price"
                                   placeholder="Price">
                          </mat-form-field>
                        </div>
                        <div fxLayoutAlign="start center" fxFlex="10%">
                          <span
                            fxFlex="70%">$ {{ CommonUtil.formatLargeNumber(expense.price * expense.quantity)}}</span>
                          <button fxFlex="30%" mat-icon-button color="'accent'"
                                  disabled="true"
                                  (click)="removeItem(index)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div fxLayout="column" *ngFor="let expense of vendorExpenses; let index = index">
                      <div fxLayout="row" fxLayoutAlign="start start" class="edit-expense-container">
                        <div fxFlex="4%" style="margin-top: 8px;">
                          <mat-icon *ngIf="expense.inventory_id">store_mall_directory</mat-icon>
                        </div>
                        <div fxFlex="30%">
                          <mat-form-field [color]="'accent'">
                            <input matInput
                                   [(ngModel)]="expense.title"
                                   [ngModelOptions]="{standalone: true}"
                                   [value]="expense.title"
                                   placeholder="Enter item name">
                          </mat-form-field>
                        </div>
                        <div fxFlex="31%">
                          <mat-form-field [color]="'accent'">
                            <input matInput
                                   [(ngModel)]="expense.desc"
                                   [ngModelOptions]="{standalone: true}"
                                   [value]="expense.desc"
                                   placeholder="Enter item description">
                            <button mat-button matSuffix mat-icon-button
                                    (click)="openItemDescriptionPopup(index, false)"
                                    type="button">
                              <mat-icon>fullscreen</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
                        <div fxFlex="12.5%">
                          <mat-form-field [color]="'accent'">
                            <input matInput
                                   [(ngModel)]="expense.quantity"
                                   [ngModelOptions]="{standalone: true}"
                                   placeholder="Quantity"
                                   [value]="expense.quantity">
                          </mat-form-field>
                        </div>
                        <div fxFlex="12.5%">
                          <mat-form-field [color]="'accent'">
                            <span matPrefix>$ &nbsp;</span>
                            <input matInput
                                   [(ngModel)]="expense.price"
                                   [ngModelOptions]="{standalone: true}"
                                   [value]="expense.price"
                                   placeholder="Price">
                          </mat-form-field>
                        </div>
                        <div fxLayoutAlign="start center" fxFlex="10%">
                          <span fxFlex="70%">$ {{ CommonUtil.formatLargeNumber(expense.price * expense.quantity)
                            }}</span>
                          <button fxFlex="30%" mat-icon-button color="'accent'"
                                  (click)="removeVendorItem(index)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>


                  </div>


                  <div fxLayout="row" fxLayoutAlign="space-around center">
                    <button fxFlex="100%" mat-button style="color: #2d7cff"
                            fxLayoutGap="10px" (click)="addVendorItem()" type="button">
                      <mat-icon>add_circle</mat-icon>
                      <span style="font-size: medium"> Add new item</span>
                    </button>
                  </div>

                  <hr>
                </div>

              </ng-container>

              <!--<div fxLayoutAlign="space-between start">-->
              <!--<div fxLayout="column" fxLayoutGap="10px">-->
              <!--<button (click)="openUploadPopup()" mat-raised-button color="accent" style="width: 200px"-->
              <!--type="button">-->
              <!--UPLOAD FILES-->
              <!--</button>-->
              <!--<span style="font-size: 10px">Upload any recipient or any photos taken from this task*</span>-->
              <!--</div>-->
              <!--</div>-->

              <div class="attachments-container" *ngIf="task && task.attachments && task.attachments.data.length > 0">
                <div fxLayout="row">
                  <span style="font-weight: bold" class="heading big-heading">Attachments:</span>
                  <span fxFlex="1 1 auto"></span>
                </div>
                <ng-container>
                  <mat-grid-list cols="5" gutterSize="2px">
                    <mat-grid-tile *ngFor="let image of task.attachments.data">
                      <div class="grid-tile-container">
                        <mat-grid-tile-header style="z-index: 3">
                          <div fxLayout="row" style="width: 100%;">
                            <button mat-icon-button (click)="preview(image.url)" matTooltip="Preview">
                              <mat-icon>visibility</mat-icon>
                            </button>
                            <span class="space-filler"></span>
                            <button mat-icon-button [disabled]="isCompleting"
                                    (click)="deleteAttachment(image)" matTooltip="Delete">
                              <mat-icon>close</mat-icon>
                            </button>
                          </div>
                        </mat-grid-tile-header>
                        <img [src]="TaskModelUtil.getThumbnailUrl(image)"
                             [ngClass]="{ 'limit-width': image.type !== AttachmentType.IMAGE }" class="grid-content"/>
                        <mat-grid-tile-footer>
                        <span
                          style="text-overflow: ellipsis;white-space: nowrap; overflow: hidden">{{image.title}}</span>
                        </mat-grid-tile-footer>
                      </div>
                    </mat-grid-tile>
                  </mat-grid-list>
                </ng-container>
              </div>
            </div>
          </form>
        </div>

        <div class="attachments-container" *ngIf="taskUploadProgress.length > 0">
          <div class="heading-container">
            <span class="heading big-heading">New Attachments:</span>
            <span class="save-message" *ngIf="allowEdit">Hit Update to save the attachments</span>
            <span fxFlex="1 1 auto"></span>
          </div>
          <ng-container>
            <mat-grid-list cols="5" gutterSize="2px">
              <mat-grid-tile *ngFor="let pic of taskUploadProgress">
                <div class="grid-tile-container">
                  <mat-grid-tile-header style="z-index: 3">
                    <div fxLayout="row" style="width: 100%;">
                      <button mat-icon-button (click)="preview(pic.attachment.url)" matTooltip="Preview">
                        <mat-icon>visibility</mat-icon>
                      </button>
                      <span class="space-filler"></span>
                      <button mat-icon-button
                              *ngIf="!tempAttachmentDeletingIds[pic.attachment.id] && pic.isComplete &&allowEdit"
                              (click)="deleteTempAttachment(pic.attachment)" matTooltip="Delete">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-spinner *ngIf="tempAttachmentDeletingIds[pic.attachment.id]"
                                   [color]="'white'" [diameter]="24" [strokeWidth]="4">
                      </mat-spinner>
                    </div>
                  </mat-grid-tile-header>
                  <div class="upload-attachment-container" fxLayout="column" fxLayoutGap="2px"
                       fxLayoutAlign="start stretch" fxFlex="100%">
                    <img [src]="TaskModelUtil.getThumbnailUrl(pic.attachment)"
                         [ngClass]="{ 'limit-width': pic.attachment.type !== AttachmentType.IMAGE }"/>
                    <mat-progress-bar *ngIf="!pic.isComplete" [value]="pic.uploadProgress"
                                      [color]="'accent'"></mat-progress-bar>
                  </div>
                  <mat-grid-tile-footer>
                        <span
                          style="text-overflow: ellipsis;white-space: nowrap; overflow: hidden">{{pic.attachment.title}}</span>
                  </mat-grid-tile-footer>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </ng-container>
        </div>

        <input (change)="upload($event)" type="file" [multiple]="true"
               accept="image/*,video/*,audio/*, application/msword,
                              application/vnd.ms-excel, application/vnd.ms-powerpoint,
                              text/plain, application/pdf"
               style="visibility: hidden" #addImageWrapper>


        <span *ngIf="!commentsLoading && comments.length>0 && task?.comments_count>0" style="font-size:25px;"
              fxLayoutAlign="start"><b>Comments:</b></span>

        <mat-spinner *ngIf="commentsLoading" color="accent" [strokeWidth]="3" [diameter]="40"
                     style="margin-left:50%"></mat-spinner>

        <div #commentsContainer *ngIf="commentsLoaded && task?.comments_count>0">
          <mat-card *ngFor="let comment of comments" fxLayout="column" class="background"
                    [ngClass]="{'highlight': this.selectedCommentId === comment.id}" [id]="comment.id">
            <div fxLayout="row" fxFlex="100%">
              <div fxLayout="row" fxLayoutGap="10px" fxFlex="95%">
                <img mat-card-avatar [src]="getPicUrl(comment.creator_id)">
                <mat-card-title style="padding-top:5px">
                  {{getName(comment.creator_id)}} .
                </mat-card-title>
                <span style="padding-top:10px">
                      {{comment.created_at | utcToLocalTime | date:'medium'}}
                    </span>
                <div *ngIf="comment.last_edited_at"
                     style="padding-top: 12px;color:blue;font-size: 12px;font-weight: bolder"
                     matTooltip="{{comment.updated_at | utcToLocalTime | date:'medium'}}">edited
                </div>
              </div>
              <span class="space-filler"></span>
              <div fxLayout="row" *ngIf="user.id === comment.creator_id">
                <button mat-icon-button *ngIf="!isEditing[comment.id] && TaskModelUtil.getIsEditable(comment)"
                        (click)="makeEditable(comment)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button *ngIf="isEditing[comment.id]" (click)="makeEditable(comment)">
                  <mat-icon>cancel</mat-icon>
                </button>
                <button mat-icon-button (click)="copyCommentLink(comment)">
                  <mat-icon>link</mat-icon>
                </button>
                <button mat-icon-button *ngIf="TaskModelUtil.getIsDeletable(comment)" (click)="deleteComment(comment)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
            <div *ngIf="!isEditing[comment.id]" fxLayout="column" fxLayoutGap="20px">
              <div class="actualContent">
                {{comment.comment}}
              </div>
            </div>
            <div *ngIf="isEditing[comment.id]" color="accent">
              <mat-form-field style="width: 100%" color="accent">
                    <textarea matInput rows="10" cols="20" [formControl]="commentText">
                    </textarea>
              </mat-form-field>
            </div>
            <div style="padding-top:10px" *ngIf="isEditing[comment.id]" fxLayoutAlign="end">
              <button mat-raised-button color="accent" (click)="saveComment(comment)">update</button>
            </div>
          </mat-card>
        </div>

        <hr>

        <div *ngIf="showAddCommentInput" fxLayout="column" fxLayoutGap="5px">
          <p style="font-size: 18px ; font-weight: bold">Add a comment...</p>
          <div id="new_comment_input" class="textArea"></div>
          <div fxLayoutAlign="end" fxLayoutGap="5px">
            <button mat-raised-button color="warn" (click)="cancelAddComment()">Cancel</button>
            <button mat-raised-button color="accent" [disabled]="isEmptyComment" (click)="addComment()">Add Comment
            </button>
          </div>
        </div>


      </div>
      <div style="overflow-y: scroll" class="footer do-not-print" fxFlex="row" fxLayoutAlign="start center"
           fxLayoutGap="10px">
        <mat-toolbar>


          <mat-chip-list *ngIf="!!task" fxShow fxHide.lg="false" fxHide.xs="true" fxLayoutAlign="center center">
            <mat-chip *ngIf="task.cloned_from_id" style="background-color:red">Escalated Task</mat-chip>
            <mat-chip *ngIf="task.auto_task_id" style="background-color:yellow">Auto Task</mat-chip>
            <mat-chip *ngIf="task.creator_task_id" style="background-color:lightblue">Repeating Task</mat-chip>
            <mat-chip *ngIf="!task.cloned_from_id && !task.auto_task_id && !task.creator_task_id"
                      style="background-color:grey">Manual Task
            </mat-chip>
          </mat-chip-list>

          <span class="space-filler"></span>

          <mat-spinner *ngIf="isDeleting || isCompleting || isCreating || isUpdating || (taskId && taskLoading)"
                       [color]="'accent'" [diameter]="30" [strokeWidth]="4">
          </mat-spinner>

          <button
            mat-raised-button
            (click)="writeComment()"
            color="accent"
            *ngIf="taskId"
            fxShow fxHide.lg="false" fxHide.xs="true"
            [disabled]="isUpdating || isCreating || (taskId && taskLoading)"
          >
            <mat-icon>question_answer</mat-icon>
            COMMENT
          </button>

          <button
            mat-raised-button
            (click)="writeComment()"
            color="accent"
            *ngIf="taskId&&!allowEdit"
            fxHide fxHide.xs="false"
            matTooltip="Comment"
            [disabled]="isUpdating || isCreating || (taskId && taskLoading)"
          >
            <mat-icon>question_answer</mat-icon>
          </button>

          <button
            mat-raised-button
            (click)="openUploadPopup()"
            color="accent"
            *ngIf="(!allowEdit&&taskId) || (!taskId)"
            fxShow fxHide.lg="false" fxHide.xs="true"
            [disabled]="isUpdating || isCreating || (taskId && taskLoading)"
          >
            <mat-icon>cloud_upload</mat-icon>
            UPLOAD FILES
          </button>

          <button
            mat-raised-button
            (click)="openUploadPopup()"
            color="accent"
            matTooltip="Upload Files"
            fxHide fxHide.xs="false"
            [disabled]="isUpdating || isCreating || (taskId && taskLoading)"
          >
            <mat-icon>cloud_upload</mat-icon>
          </button>

          <button
            mat-raised-button
            (click)="duplicateTask()"
            color="accent"
            [disabled]="(taskId && taskLoading) || isCreating"
            fxShow fxHide.lg="false" fxHide.xs="true"
            *ngIf="taskId && !isVendor && !isUserHomeowner"
          >
            <mat-icon>explicit</mat-icon>
            ESCALATE
          </button>

          <button
            mat-raised-button
            (click)="duplicateTask()"
            color="accent"
            [disabled]="(taskId && taskLoading) || isCreating"
            matTooltip="Escalate"
            fxHide fxHide.xs="false"
            *ngIf="taskId && !isVendor && !isUserHomeowner"
          >
            <mat-icon>explicit</mat-icon>
          </button>

          <button mat-raised-button
                  color="warn"
                  *ngIf="taskId && allowEdit && !isVendor && !isUserHomeowner"
                  fxShow fxHide.lg="false" fxHide.xs="true"
                  [disabled]="isDeleting || (taskId && taskLoading)"
                  (click)="deleteButtonClicked()">
            <mat-icon>delete</mat-icon>
            DELETE
          </button>

          <button mat-raised-button
                  color="warn"
                  *ngIf="taskId && allowEdit && !isVendor && !isUserHomeowner"
                  fxHide fxHide.xs="false"
                  matTooltip="Delete Task"
                  [disabled]="isDeleting || (taskId && taskLoading)"
                  (click)="deleteButtonClicked()">
            <mat-icon>delete</mat-icon>
          </button>

          <button mat-raised-button
                  *ngIf="taskId && !allowEdit && ((!isUserHomeowner && !(isEmployee || isHouseKeeper)) || isVendorTask)"
                  [disabled]="taskId && taskLoading"
                  fxShow fxHide.lg="false" fxHide.xs="true"
                  (click)="editButtonClicked()">
            <mat-icon>edit</mat-icon>
            {{isVendor && !isVendorTask ? "FILE REPORT" : "EDIT"}}
          </button>

          <button mat-raised-button
                  *ngIf="taskId && isHouseKeeper && employees.length > 0"
                  [disabled]="taskId && taskLoading"
                  fxShow fxHide.lg="false" fxHide.xs="true"
                  (click)="assignButtonClicked()">
            <mat-icon>person</mat-icon>
            ASSIGN EMPLOYEE
          </button>

          <button mat-raised-button
                  *ngIf="taskId && !allowEdit && ((!isUserHomeowner && !(isEmployee || isHouseKeeper)) || isVendorTask)"
                  [disabled]="taskId && taskLoading"
                  fxHide fxHide.xs="false"
                  matTooltip="Edit Task"
                  (click)="editButtonClicked()">
            <mat-icon>edit</mat-icon>
          </button>

          <button mat-raised-button
                  *ngIf="taskId && isHouseKeeper && employees.length > 0"
                  [disabled]="taskId && taskLoading"
                  fxHide fxHide.xs="false"
                  matTooltip="Assign Employee"
                  (click)="assignButtonClicked()">
            <mat-icon>person</mat-icon>
          </button>

          <button mat-raised-button
                  *ngIf="taskId && !allowEdit && !completed && !isUserHomeowner"
                  [disabled]="isCompleting || (taskId && taskLoading)"
                  fxShow fxHide.lg="false" fxHide.xs="true"
                  class="complete-button"
                  (click)="completeButtonClicked()">
            <mat-icon>playlist_add_check</mat-icon>
            COMPLETE
          </button>

          <button mat-raised-button
                  *ngIf="taskId && !allowEdit && !completed && !isUserHomeowner"
                  [disabled]="isCompleting || (taskId && taskLoading)"
                  fxHide fxHide.xs="false"
                  class="complete-button"
                  matTooltip="Complete Task"
                  (click)="completeButtonClicked()">
            <mat-icon>playlist_add_check</mat-icon>
          </button>

          <button mat-raised-button
                  *ngIf="!taskId"
                  [disabled]="isCreating || (taskId && taskLoading) || (loadingGuest && showGuest) || !taskForm.valid"
                  fxShow fxHide.lg="false" fxHide.xs="true"
                  (click)="createButtonClicked()">
            <mat-icon>add_circle_outline</mat-icon>
            CREATE
          </button>

          <button mat-raised-button
                  *ngIf="!taskId"
                  [disabled]="isCreating || (taskId && taskLoading) || (loadingGuest && showGuest) || !taskForm.valid"
                  fxHide fxHide.xs="false"
                  matTooltip="Create Task"
                  (click)="createButtonClicked()">
            <mat-icon>add_circle_outline</mat-icon>
          </button>


          <button mat-raised-button
                  *ngIf="taskId && allowEdit && !isUserHomeowner"
                  [disabled]="!isAllUploadCompleted ||isUpdating || (taskId && taskLoading) || !taskForm.valid"
                  fxShow fxHide.lg="false" fxHide.xs="true"
                  (click)="updateButtonClicked()">
            <mat-icon>done</mat-icon>
            UPDATE
          </button>

          <button mat-raised-button
                  *ngIf="taskId && allowEdit && !isUserHomeowner"
                  [disabled]="!isAllUploadCompleted ||isUpdating || (taskId && taskLoading) || !taskForm.valid"
                  fxHide fxHide.xs="false"
                  matTooltip="Update Task"
                  (click)="updateButtonClicked()">
            <mat-icon>done</mat-icon>
          </button>

        </mat-toolbar>
      </div>
    </div>
    <div *ngIf="taskExist">
      <div class="header" fxFlex="row" fxLayoutAlign="start center">
        <mat-toolbar color="accent">
          <h2>
            Task Doesn't Exist
          </h2>
          <span class="space-filler"></span>

          <button mat-icon-button (click)="closeButtonClicked()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar>
      </div>
    </div>
  `,
  styles: [`
    .textArea {
      width: 100%;
      min-height: 100px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-color: #194267;
      padding: 10px;
      border-style: solid;
      margin: 2px;
      border-width: 1px;
      border-color: #194267;
      border-radius: 3px;
    }

    ::ng-deep.ql-mention-list {
      max-height: 260px !important;
      overflow-y: scroll !important;
    }
  `]
})
export class TaskShowCreateComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("addImageWrapper", { read: ElementRef }) addImageWrapper: ElementRef;

  taskExist = false;
  quillBody: Quill;
  mentions: number[] = [];

  filteredBookings: Observable<BookingCompact[]>;
  listings: ListingCompact[] = [];
  listingCompact: ListingCompact;
  taskForm: FormGroup;
  title: FormControl;
  propertyId: FormControl;
  dueDate: FormControl;
  description: FormControl;
  booking: FormControl;
  noOfTimes: FormControl;
  categoryControl: FormControl;
  customNoOfTimes: FormControl;
  repeatTasks = false;
  expenses: TaskExpense[] = [];
  vendorExpenses: TaskExpense[] = [];
  selectBookings: BookingCompact[] = [];
  selectedBooking: BookingCompact;
  reservationId: number;
  reservationsLoading = false;
  reservationsLoaded = false;
  reservationFull: BookingFull;
  propertyAccessTitle: string;
  propertyAccessKey: string;

  isEmptyComment = true;
  showArrowButtons = false;

  // Enums
  assigneeCategories = CommonUtil.getPropsOfEnum(TaskAssigneeCategory);
  categories = CommonUtil.getPropsOfEnum(TaskCategory);
  statusTypes = CommonUtil.getPropsOfEnum(TaskStatus);
  paymentByOptions = CommonUtil.getPropsOfEnum(TaskPaymentBy);
  hourTypes = CommonUtil.getPropsOfEnum(SD_Hour);
  minuteTypes = CommonUtil.getPropsOfEnum(SD_Minute);
  dayHalfTypes = CommonUtil.getPropsOfEnum(SD_DayHalf);
  taskRepeatFrequencyTypes = CommonUtil.getPropsOfEnum<TaskRepeatFrequency>(TaskRepeatFrequency);

  // Enum Helper
  TaskStatus = TaskStatus;
  TaskEnumHelper = TaskEnumHelper;
  UserEnumHelper = UserEnumHelper;
  UserModelUtil = UserModelUtil;
  TaskModelUtil = TaskModelUtil;
  CommonUtil = CommonUtil;
  BookingModelUtil = BookingModelUtil;
  AttachmentType = AttachmentType;
  TaskRepeatFrequency = TaskRepeatFrequency;
  UserOtherRole = UserOtherRole;
  currentMonth: SDDay;

  isAllUploadCompleted = true;
  imgURL: string;
  user: UserFull;
  assigneeId: number;
  selectedAssigneeType: string;
  employeeId: number;
  assignees: UserCompact[] = [];
  filteredAssignees: UserCompact[] = [];
  employees: UserCompact[] = [];
  allAssignees: UserCompact[] = [];
  taskUploadProgress: TaskUploadProgress[] = [];
  file: File;
  taskId: number;
  selectedListingId: number;
  taskLoading: boolean;
  taskLoaded: boolean;
  task: TaskFull;
  preSignedURL: string;
  uploading = false;
  uploadProgress = 0;
  tempAttachmentDeletingIds: { [id: number]: boolean } = {};

  // Boolean Flag
  isDirectedFromReservation = false;
  isDirectedFromInbox = false;
  isDirectedFromHomeOwnerHome = false;
  isDirectedFromOwnerListingPage = false;
  isDirectedFromReviews = false;

  assigneesLoaded = false;
  assigneesLoading = false;
  isUserHomeowner = false;
  isHouseKeeper = false;
  isEmployee = false;
  isVendor = false;
  isAdmin = false;
  isUserOwner = false;
  isVendorTask = false;
  showHouseKeeperExpenses = true;
  editGuest = false;
  allowEdit = false;
  isDeleting = false;
  isCompleting = false;
  isCreating = false;
  isUpdating = false;
  showGuest = false;
  showExpense = false;
  showTime = false;
  isAlive = true;
  isValid = true;
  completed = false;
  showCustomValueField = false;
  date: Date;
  // Data Binding Variables
  fileType;
  repeatFrequency: any = TaskRepeatFrequency.NEVER;
  status = TaskStatus.SCHEDULED;
  hh;
  mm;
  meridian;
  paymentByOption = TaskPaymentBy.OWNER_CHARGE;
  loadingGuest = false;
  uploadedTaskImages: UploadComplete[] = [];
  UserCategory = UserCategory;
  // comment:any = {id:1, comment:"test test",creator_name:"Jane Doe",timestamp:"6 days ago"};
  isEditing: { [id: number]: boolean } = {};
  commentText: FormControl;
  showAddCommentInput = false;
  newCommentText: FormControl;
  selectedCommentId: number;
  commentsLoading = false;
  commentsLoaded = false;
  showAssignEmployeeInput = false;
  comments: Comment[] = [];
  listingFilter: FormControl = new FormControl();
  assigneeFilter: FormControl = new FormControl();
  filteredListings: ListingCompact[] = [];
  taskData: { review: GuestReview, response: string, draftId: number };
  getSDDayObject = getSDDayObject;
  private dialogRef: MatDialogRef<any>;
  private destroyed$ = new Subject();

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private bookingRepo: BookingRepository,
              private domSanitizer: DomSanitizer,
              private appService: AppService,
              private taskRepo: TaskRepository,
              private listingRepo: ListingRepository,
              private snackBarService: SnackbarService,
              private optionRepo: OptionsRepository,
              private userRepo: UserRepository,
              private reviewRepo: ReviewRepository,
              private dialog: MatDialog,
              private renderer: Renderer2,
              private clipboard: Clipboard
  ) {

    this.title = new FormControl(null, [Validators.required]);
    this.propertyId = new FormControl(null, [Validators.required]);
    this.booking = new FormControl(null);
    this.dueDate = new FormControl(getDateObj(), [Validators.required]);
    this.description = new FormControl();
    this.noOfTimes = new FormControl("1", [Validators.required]);
    this.customNoOfTimes = new FormControl(1, []);
    this.commentText = new FormControl(null);
    this.newCommentText = new FormControl(null, []);
    this.categoryControl = new FormControl(null, [Validators.required]);

    this.dueDate.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        if (value) {
          this.currentMonth = this.getSDDayObject(value);
        }
      });

    this.reviewRepo.getTaskData().pipe(take(1)).subscribe(r => {
      this.taskData = r;

      if (!!this.taskData?.review) {
        this.reservationId = this.taskData.review.booking_id;
        this.isDirectedFromReviews = true;
        this.setupReservation();
        this.title.setValue("Review Recovery");
        this.categoryControl.setValue("guest_complaint");
        this.description.setValue(this.taskData.review.private_comments);
      }
    });

    this.taskForm = new FormGroup({
      title: this.title,
      property_id: this.propertyId,
      booking_id: this.booking,
      due_date: this.dueDate,
      description: this.description,
      no_of_times: this.noOfTimes,
      custom_no_of_times: this.customNoOfTimes,
      category: this.categoryControl
    });
    console.log("task route", this.activatedRoute.snapshot.params);
    if (this.activatedRoute.snapshot.params.task_id) {
      this.activatedRoute.params.subscribe(value => {
        console.log("Route params", value);
        this.taskId = +value.task_id;
        this.setupShowTaskStore();
      });
    } else {
      if (this.activatedRoute.snapshot.params.listing_id) {
        this.propertyId.setValue(+this.activatedRoute.snapshot.params.listing_id);
        this.selectedListingId = this.propertyId.value;
        console.log("selected listing task", this.propertyId.value);
        console.log("selecte listing asds", this.selectedListingId);
        this.date = null;
      } else {
        this.date = new Date();
        this.setupEditForm();
      }
    }

    if (this.activatedRoute.snapshot.params.from) {
      const from = this.activatedRoute.snapshot.params.from;
      this.isDirectedFromOwnerListingPage = from === "listing";
      this.isDirectedFromReservation = from === "reservations";
      this.isDirectedFromHomeOwnerHome = from === "owner-home";
      this.isDirectedFromInbox = from === "inbox";
      if (!isNullOrUndefined(this.isDirectedFromReservation) || !isNullOrUndefined(this.isDirectedFromInbox)) {
        this.reservationId = +this.activatedRoute.snapshot.params.id;
        this.setupReservation();
      }
    }

    if (this.activatedRoute.snapshot.params.comment_id) {
      this.activatedRoute.params.subscribe((params) => {
        if (!isNullOrUndefined(params.comment_id)) {
          this.selectedCommentId = +params.comment_id;
        }
      });
    }

    this.newCommentText.valueChanges.subscribe(value => {
      this.isEmptyComment = !value || value.trim() === "";
    });
  }

  ngOnInit() {
    this.userRepo.getUser().pipe(
      filter(u => !!u),
      takeWhile(() => this.isAlive),)
      .subscribe((user) => {
        this.user = user;
        if (this.user.is_admin || (this.user.is_management_contact && (this.user?.managementContact?.data?.category === UserCategory.HOMEOWNER || this.user?.managementContact?.data?.category === UserCategory.AGENT))) {
          console.log(true);
        }
        console.log(this.user);
        if (this.task && this.task.assignee_id) {
          this.assigneeId = this.task.assignee_id;
        } else {
          this.assigneeId = this.user.id;

        }
        console.log(this.assigneeId);
        if (CommonUtil.isHomeOwner(user)) {
          this.isUserHomeowner = true;
          this.paymentByOption = TaskPaymentBy.OWNER_CHARGE;
          this.categories = CommonUtil.getPropsOfEnum<HomeOwnerTaskCategory>(HomeOwnerTaskCategory);
        }

        this.isVendor = this.CommonUtil.isVendor(user);
        this.isHouseKeeper = this.CommonUtil.isHouseKeeper(user);
        this.isEmployee = this.CommonUtil.isEmployee(user);
        this.isVendorTask = user.role === UserOtherRole.CREAT_EDIT_TASK;

        if (this.isHouseKeeper && !this.isEmployee) {
          if (user.managementContacts && user.managementContacts.data) {
            this.employees = user.managementContacts.data;
          }
        }

        if (this.isVendor && (!!user.managementContact.data.creator.data.managementContact && user.managementContact.data.creator.data.managementContact.data.category === "housekeeper")) {
          this.showHouseKeeperExpenses = user.managementContact.data.creator.data.show_expenses;
          console.log("show expenses", this.showHouseKeeperExpenses);
        }

        if (user.type === "admin" || CommonUtil.isHomeOwner(user)) {
          this.isAdmin = true;
        }

        if (user.type === "owner") {
          this.categories = [TaskCategory.WORK_ORDER];
          this.categoryControl.setValue(TaskCategory.WORK_ORDER);
          this.isUserOwner = true;
        }
      });

    this.listingRepo.getActivatedListings().subscribe((listings) => {
      this.listings = [...this.listings, ...listings];
      this.filteredListings = [...this.filteredListings, ...listings];
    });

    this.listingRepo.getDraftListings().subscribe((listings) => {
      this.listings = [...this.listings, ...listings];
      this.filteredListings = [...this.filteredListings, ...listings];
    });

    this.listingFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged(),).subscribe((value) => {

      if (value) {
        this.filteredListings = this.listings.filter(l => l.title).filter(l => l.title.toLowerCase().includes(value));
      } else {
        this.filteredListings = this.listings;
      }

      if (this.filteredListings.length === 0) {
        this.filteredListings = this.listings;
      }

    });

    this.assigneeFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged(),).subscribe((value) => {

      if (value) {
        this.filteredAssignees = this.assignees.filter(l => UserModelUtil.getFullName(l).toLowerCase().includes(value));
      } else {
        this.filteredAssignees = this.assignees;
      }

      if (this.filteredAssignees.length === 0) {
        this.filteredAssignees = this.assignees;
      }

    });

    this.setUpAdmins();

    this.filteredBookings = this.booking.valueChanges.pipe(startWith(null), map(name => {
      const bookings = name ? this.filterGuests(name) : this.selectBookings.slice(0, 10);
      console.log("BOOKINGS ARE: ", bookings);
      return bookings;
    }),);

    // this.description.valueChanges.subscribe(value => {
    //   this.isValid = !!value;
    // });

    if (this.taskId) {

      this.taskRepo.getIsCommentsLoadingForTaskId(this.taskId).subscribe(l => this.commentsLoading = l);

      this.taskRepo.getIsCommentsLoadedForTaskId(this.taskId).subscribe(l => this.commentsLoaded = l);

      console.log(this.taskId);
      this.taskRepo.getCommentsForTask(this.taskId, true).pipe(takeUntil(this.destroyed$)).subscribe(res => {
        console.log(res);
        console.log(this.commentsLoading, this.commentsLoaded);
        this.comments = res as Comment[];
        console.log(this.comments);
        if (this.selectedCommentId) {
          setTimeout(() => {
            this.selectedCommentId = null;
          }, 5000);
        }
      });
    }

    this.taskForm
      .valueChanges
      .subscribe(v => console.log(this.taskForm));

  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.isAlive = false;
  }

  setupQuill() {
    const values = this.assignees.map(assignee => {
      return {id: assignee.id, value: assignee.first_name + " " + assignee.last_name};
    });
    this.quillBody = new Quill("#new_comment_input", {
      theme: "bubble",
      modules: {
        mention: {
          allowedChars: /^[_A-Za-z\sÅÄÖåäö]*$/,
          source: (searchTerm, renderList, mentionChar) => {

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(values[i]);
                }
              }
              renderList(matches, searchTerm);
            }
          },
          dataAttributes: ["value"]
        },
      },
    });

    if (this.newCommentText.value) {
      this.quillBody.setText(this.newCommentText.value);
      this.quillBody.root.innerHTML = QuillUtils.replaceMentionWithClass(this.quillBody.root.innerHTML);
    }

    this.quillBody.on("text-change", () => {
      this.newCommentText.setValue(this.quillBody.root.innerText);
    });
  }

  changeGuest() {
    this.editGuest = !this.editGuest;
  }

  extractMentionedData(noteHtml: string) {
    if (!!noteHtml) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(noteHtml, "text/html");
      const items = [];
      for (const i of Object.keys(doc.querySelectorAll(".mention"))) {
        if (doc.querySelectorAll(".mention")[i].dataset.denotationChar === "@") {
          const id = +doc.querySelectorAll(".mention")[i].dataset.id;
          if (items.indexOf(id) === -1) {
            items.push(id);
          }
        }
      }
      return items;
    } else {
      return [];
    }
  }

  addComment() {
    if (this.newCommentText.value) {
        const data = {comment: this.newCommentText.value, mentions: this.extractMentionedData(this.quillBody.root.innerHTML)};
        console.log(data);
        this.taskRepo.createComment(this.taskId, data).subscribe(res => {
          console.log("------------", res);
          this.showAddCommentInput = false;
          this.newCommentText.setValue(null);
          console.log(res);
        });
    }
  }

  /**getGuest
   * Button (click) Listeners
   */

  onMatSelectClose() {
    this.filteredListings = this.listings;
    this.filteredAssignees = this.assignees;
  }

  changeStatus($event) {
    if ($event.checked) {
      this.status = TaskStatus.COMPLETED;
    } else {
      this.status = TaskStatus.SCHEDULED;
    }
  }

  onGuestChange(bookingId: number) {
    this.selectedBooking = this.selectBookings.find(booking => booking.id === bookingId);
  }

  printButtonClicked() {
    window.scrollTo(0,0);
    setTimeout(() => window.print());
  }

  shareButtonClicked() {
    this.clipboard.copy(environment.dashboardURL + "/tasks/" + this.taskId);
    this.snackBarService.show("Link to the task has been copied to clipboard.");
  }

  closeButtonClicked(force?: boolean) {
    if (!this.allowEdit || force) {
      if (this.isDirectedFromReservation) {
        const id = this.activatedRoute.snapshot.params.id;
        this.router.navigate(["/reservations/" + id + "/tasks"]);
        return;
      }

      if (this.isDirectedFromInbox) {
        const id = this.activatedRoute.snapshot.params.thread_id;
        this.router.navigate(["/inbox/" + id]);
        return;
      }

      if (this.isDirectedFromHomeOwnerHome) {
        this.router.navigate(["/homeowner-home"]);
        return;
      }

      if (this.isDirectedFromOwnerListingPage) {
        const id = this.activatedRoute.snapshot.params.listing_id;
        this.router.navigate(["/listings/" + id + "/details"]);
        return;
      }

      if (this.isDirectedFromReviews) {
        this.router.navigate(["/reviews"]);
        return;
      }

      // TODO Need to replace with route data
      if (this.activatedRoute.snapshot.params.id) {
        console.log("entered");
        const id = this.activatedRoute.snapshot.params.id;
        if (this.activatedRoute.snapshot.params.is_onboarding) {
          this.router.navigate(["/listings/" + id + "/onboarding", {id: id}]);
        } else {
          this.router.navigate(["/listings/" + id + "/tasks", {id: id}],
            {
              queryParams: {
                tab: 5
              }
            });
        }
      } else {
        console.log("route params", this.activatedRoute.snapshot.queryParams);
        this.router.navigate(["/tasks", {assigneeId: "0"}], {queryParams: this.activatedRoute.snapshot.queryParams});
      }
    } else {
      this.allowEdit = false;
      this.editGuest = false;
      if (this.task) {
        this.currentMonth = this.getSDDayObject(this.task.due_date);
      }
    }

  }

  deleteButtonClicked() {
    this.dialogRef = this.dialog.open(GenericConfirmationPopupComponent);
    this.dialogRef.updateSize("100%");
    const instance = this.dialogRef.componentInstance;
    instance.title = "Delete Task";
    instance.description = "Are You Sure You Want To Delete this Task?";
    instance.showCloseButton = true;
    instance.showNoButton = true;
    instance.yesButtonText = "YES";
    instance.yesButtonClicked.subscribe(() => {
      this.dialogRef.close();
      this.taskRepo.deleteTask(this.taskId).subscribe(res => {
        this.isCompleting = false;
        this.closeButtonClicked(true);
      }, () => {
        this.isCompleting = false;
      });
    });
    instance.noButtonClicked.subscribe(() => {
      this.dialogRef.close();
    });
  }

  deleteAttachment(attachment: TaskAttachment) {
    this.isCompleting = true;
    this.taskRepo.deleteTaskAttachment(attachment.id, this.taskId).subscribe(res => {
      this.isCompleting = false;
    }, () => {
      this.isCompleting = false;
    });
  }

  deleteTempAttachment(attachment: UploadFile) {
    this.tempAttachmentDeletingIds[attachment.id] = true;

    this.taskRepo.deleteTempTaskAttachment(attachment.id).subscribe(res => {
      this.tempAttachmentDeletingIds[attachment.id] = false;
      this.taskUploadProgress = this.taskUploadProgress.filter(u => u.attachment.id !== attachment.id);
    }, () => {
      this.tempAttachmentDeletingIds[attachment.id] = false;
    });
  }

  editButtonClicked() {
    this.allowEdit = true;
    console.log("task current", this.task);
    this.setupEditForm(this.task);
  }

  completeButtonClicked() {
    if (this.task && ((this.isVendor && (this.task.type === TaskCategory.CLEANING || this.task.type === TaskCategory.INSPECTION))
      || (this.isAdmin && this.task.type === TaskCategory.CLEANING))
      && this.task.attachments.data.length === 0
      && this.task.comments_count === 0) {
      this.dialogRef = this.dialog.open(GenericConfirmationPopupComponent, {
        data: {
          title: "Complete your task",
          description: "Are you sure you have no damage to report ?",
          showCloseButton: true,
          showNoButton: true,
        }
      });
      this.dialogRef.updateSize("100%");
      const instance = this.dialogRef.componentInstance as GenericConfirmationPopupComponent;
      instance.yesButtonClicked.subscribe(() => {
        this.dialogRef.close();
        this.completeTask();
      });
      instance.noButtonClicked.subscribe(() => {
        return;
      });
    } else {
      this.completeTask();
    }
  }

  completeTask() {
    this.isCompleting = true;
    this.taskRepo.updateTaskById(this.taskId, {status: TaskStatus.COMPLETED}).subscribe(res => {
      this.isCompleting = false;
      this.closeButtonClicked();
    }, err => {
      this.isCompleting = false;
    });
  }

  createButtonClicked() {
    const data = this.appendOptionalData();
    this.isCreating = true;
    this.taskRepo.createTask(data).subscribe(res => {
      this.isCreating = false;
      if (this.isDirectedFromInbox) {
        const threadId = this.activatedRoute.snapshot.params.thread_id;
        const bookingId = this.activatedRoute.snapshot.params.id;
        this.router.navigate(["/tasks/" + res.id, {from: "inbox", id: bookingId, thread_id: threadId}]);
      } else if (this.isDirectedFromReviews) {
        this.router.navigate(["/reviews"]);
      } else {
        this.router.navigate(["/tasks/" + res.id]);
      }
      console.log(res);
    }, err => {
      this.isCreating = false;
    });
  }

  updateButtonClicked(pass?: boolean) {
    const data = this.appendOptionalData();
    if (!pass && this.task.status === TaskStatus.PAID && data.status !== TaskStatus.PAID) {
      this.confirmationPopup();
    } else {
      this.isUpdating = true;
      this.taskRepo.updateTaskById(this.taskId, data).subscribe(res => {
        this.isUpdating = false;
        this.allowEdit = false;
        this.editGuest = false;
        this.vendorExpenses = [];
        this.taskUploadProgress = [];
      }, err => {
        this.isUpdating = false;
      });
    }
  }

  confirmationPopup() {
    this.dialogRef = this.dialog.open(GenericConfirmationPopupComponent);
    this.dialogRef.updateSize("100%");
    const instance = this.dialogRef.componentInstance;
    instance.title = "Change Status";
    instance.description = "Are you sure you want to mark this task unpaid this payout has already been complete.";
    instance.showCloseButton = true;
    instance.showNoButton = true;
    instance.yesButtonText = "YES";
    instance.yesButtonClicked.subscribe(() => {
      this.dialogRef.close();
      this.updateButtonClicked(true);
    });
    instance.noButtonClicked.subscribe(() => {
      this.dialogRef.close();
    });
  }

  repeatToggleChange($event) {
    this.repeatTasks = $event.checked;
    if (!this.repeatTasks) {
      this.customNoOfTimes.setValue(null);
      this.repeatFrequency = TaskRepeatFrequency.NEVER;
      this.noOfTimes.setValue(1);
    } else {
      this.repeatFrequency = TaskRepeatFrequency.DAILY;
    }
  }

  selectedValueChanged() {
    this.selectedListingId = this.propertyId.value;
    this.loadingGuest = true;
    this.bookingRepo.getUpcomingBookingWithPropertyId(this.selectedListingId).subscribe((res: BookingCompact[]) => {
      this.selectBookings = res;
      this.loadingGuest = false;

    }, () => {
      this.loadingGuest = false;
    });

    if (this.isUserOwner) {
      const obs$ = this.listingRepo.getFullListingById(this.selectedListingId);
      obs$[0].pipe(takeWhile(() => this.isAlive)).subscribe(l => {
        this.listingCompact = l;
      });

      this.assignees = this.allAssignees;
    }
  }

  reservationToggleChange($event) {
    this.showGuest = $event.checked;
  }

  expenseToggleChange($event) {
    this.showExpense = $event.checked;
    if (!this.showExpense) {
      this.expenses = [];
    } else {
      this.expenses = this.checkFee(this.expenses);
    }
  }

  timeToggleChange($event) {
    this.showTime = $event.checked;
    if (!this.showTime) {
      this.hh = null;
      this.mm = null;
      this.meridian = null;
    } else {
      this.mm = SD_Minute.MIN_00;
    }
  }

  addItem() {
    this.expenses.push({
      title: "",
      desc: "",
      quantity: 0,
      price: 0,
      is_editable: true
    });
  }

  addVendorItem() {
    this.vendorExpenses.push({
      title: "",
      desc: "",
      quantity: 1,
      price: 0,
      is_editable: true
    });
  }

  removeItem(index) {
    this.expenses.splice(index, 1);
  }

  removeVendorItem(index) {
    this.vendorExpenses.splice(index, 1);
  }

  noOfTimesChanged(event) {
    this.showCustomValueField = event.value === "2";

    if (!this.showCustomValueField) {
      this.customNoOfTimes.setValue(null);
    }
  }

  taskReservationClicked() {
    if (this.isAdmin && !this.isUserHomeowner) {
      this.router.navigate(["/reservations/", this.task.booking_id]);
    } else {
      this.router.navigate(["/reservations/info/", this.task.booking_id]);
    }
  }

  /**
   * Helpers
   */
  getListingTitle(listingId: number): string {
    return this.listingRepo.getListingTitle(+listingId);
  }

  getTimeInDateFormat(hours: string, minutes: string, half: string) {
    if (hours && minutes) {
      let hourString = "";

      if (half === "PM" && hours !== "12") {
        let numericHour = +hours;
        numericHour += 12;

        hourString = String(numericHour);

        if (hourString.length === 1) {
          hourString = "0" + hourString;
        }
      } else {
        hourString = hours;
      }

      return hourString + ":" + minutes;
    }

    return "00:00";
  }

  getGuestName(id) {

    if (isNullOrUndefined(id)) {
      return "";
    }

    const booking = this.selectBookings.find(booking => booking.id === id);

    if (isNullOrUndefined(booking)) {
      return "";
    }

    return booking.guest.data.first_name + this.checkNullString(booking.guest.data.last_name);
  }

  filterGuests(name) {

    const fullResults = this.selectBookings.filter(booking => {
      return (booking.guest.data.first_name + this.checkNullString(booking.guest.data.last_name)).toLowerCase().indexOf(String(name).toLowerCase()) === 0;
    });

    const length = fullResults.length;

    return fullResults.slice(0, length > 10 ? 10 : length);
  }

  ngAfterViewInit(): void {
    console.log("ngAfterViewInit------called");
    if (this.selectedCommentId) {
      setTimeout(() => {
        const element = document.getElementById("" + this.selectedCommentId);
        console.log("selected comment id", "" + this.selectedCommentId);
        console.log("element", document.getElementById("" + this.selectedCommentId));
        element.scrollIntoView();
      }, 1000);
    }

    if (this.taskId) {
      this.appService.changeAppTitle("Task Details");
    } else {
      this.appService.changeAppTitle("New Task");
    }
  }

  totalAmount() {
    let amount = 0;
    this.expenses.map((expense) => {
      amount += (Number(expense.price) * Number(expense.quantity));
    });
    return amount;
  }

  totalVendorAmount() {
    let amount = 0;
    this.expenses.map((expense) => {
      amount += (Number(expense.price) * Number(expense.quantity));
    });

    this.vendorExpenses.map((expense) => {
      amount += (Number(expense.price) * Number(expense.quantity));
    });

    return amount;
  }

  getRepeatValue(): number {
    return TaskEnumHelper.getTaskRepeatValue(this.repeatFrequency);
  }

  getCorrectRepeatFrequency(): string {
    if (this.repeatFrequency === TaskRepeatFrequency.BI_WEEKLY) {
      return TaskRepeatFrequency.WEEKLY;
    } else if (this.repeatFrequency === TaskRepeatFrequency.EVERY_TWO_MONTHS) {
      return TaskRepeatFrequency.MONTHLY;
    } else if (this.repeatFrequency === TaskRepeatFrequency.EVERY_FOUR_MONTHS) {
      return TaskRepeatFrequency.MONTHLY;
    } else if (this.repeatFrequency === TaskRepeatFrequency.EVERY_SIX_MONTHS) {
      return TaskRepeatFrequency.MONTHLY;
    } else {
      return this.repeatFrequency;
    }
  }

  setCorrectRepeatFrequency(task: TaskFull): TaskRepeatFrequency {
    if (task.repeat_frequency === TaskRepeatFrequency.MONTHLY && task.repeat_value === 2) {
      return TaskRepeatFrequency.EVERY_TWO_MONTHS;
    } else if (task.repeat_frequency === TaskRepeatFrequency.WEEKLY && task.repeat_value === 2) {
      return TaskRepeatFrequency.BI_WEEKLY;
    } else if (task.repeat_frequency === TaskRepeatFrequency.MONTHLY && task.repeat_value === 4) {
      return TaskRepeatFrequency.EVERY_FOUR_MONTHS;
    } else if (task.repeat_frequency === TaskRepeatFrequency.MONTHLY && task.repeat_value === 6) {
      return TaskRepeatFrequency.EVERY_SIX_MONTHS;
    } else {
      return task.repeat_frequency;
    }
  }

  // TODO this function is to be refactored.
  upload($event) {
    const x = $event.target.files.length;
    const i = 0;
    const attachmentURL = null;
    this.uploadWork($event, i, x, attachmentURL);
  }

  uploadWork($event, i, x, attachmentURL) {
    this.attachFile($event, i).pipe(take(1)).subscribe(url => {
      attachmentURL = url;
      this.isAllUploadCompleted = false;
      let upload: TaskUploadProgress = null;
      const file = $event.target.files[i];
      this.file = this.dataURItoBlob(url, file);
      this.taskRepo.getPreSignedURL(this.getFileType(), file.name)
        .subscribe(res => {
          this.preSignedURL = res.pre_signed_url;
          this.uploadProgress = 0;
          upload = this.mapUploadingFileToTaskUpload(res, false, this.uploadProgress);
          this.taskUploadProgress.push(upload);
          this.taskRepo.uploadToAWS(this.preSignedURL, this.file)
            .subscribe(progress => {
              this.uploadProgress = progress;
              this.taskUploadProgress.map(p => {
                if (p.attachment.id === res.id) {
                  p.uploadProgress = this.uploadProgress;
                  if (res.type === AttachmentType.IMAGE) {
                    p.attachment.url = attachmentURL;
                  }
                }
                return p;
              });
            }, () => {
              this.taskUploadProgress.map(p => {
                if (p.attachment.id === res.id) {
                  p.isComplete = false;
                  p.uploadProgress = 0;
                  this.snackBarService.show(+"Uploading failed for " + p.attachment.title);
                }
                return p;
              });
              this.taskUploadProgress.filter(f => f.attachment.id !== res.id);
            }, () => {
              this.isAllUploadCompleted = true;
              this.taskUploadProgress.map(p => {
                if (p.attachment.id === res.id) {
                  p.isComplete = true;
                }
                return p;
              });
              this.taskRepo.uploadedImage(res.id).subscribe(res => {
                if (res.is_completed) {
                  this.uploadedTaskImages.push(res);
                  if (res.type === AttachmentType.IMAGE) {
                    this.taskUploadProgress.map(p => {
                      if (p.attachment.id === res.id) {
                        p.attachment.url = res.url;
                      }
                      return p;
                    });
                  }
                  --x;
                  ++i;
                  if(x>0) {
                    this.uploadWork($event, i, x , null);
                  } else {
                    if (!this.allowEdit && this.taskId) {
                      this.setupEditForm(this.task);
                      this.updateButtonClicked();
                    }
                  }
                }
              });
            });
        });
    });
  }

  openUploadPopup() {
    this.addImageWrapper.nativeElement.click();
  }

  checkFee(expenses: TaskExpense[]): TaskExpense[] {
    console.log("expenses", expenses);
    let newExpenses = expenses;
    if (expenses.length > 0 && this.categoryControl.value === TaskCategory.CLEANING) {
      const expense = {
        ...expenses[0],
        is_cleaning_fee: true
      };
      newExpenses = [
        expense,
        ...expenses.slice(1)
      ];
      return newExpenses;
    } else {
      return newExpenses;
    }
  }

  preview(url: string) {
    console.log(url);
    window.open(url);
  }

  getFileType() {
    this.fileType = AttachmentEnumHelper.getFileType(this.file).toString();
    return this.fileType;
  }

  openItemDescriptionPopup(expenseIndex: number, disabled: boolean) {
    const dialogRef = this.dialog.open(ItemDescriptionPopupComponent, {
      data: {
        data: this.expenses[expenseIndex].desc,
        disabled: disabled
      }

    });

    dialogRef.updateSize("100%", "100%");

    dialogRef.afterClosed().subscribe(result => {
      this.expenses[expenseIndex].desc = result;
      console.log(this.expenses);
    });
  }

  openNotesDescriptionPopup() {
    console.log("Notes popup");
    const dialogRef = this.dialog.open(TaskNotesPopupComponent, {
      data: {
        text: this.description.value
      }
    });

    dialogRef.updateSize("100%");

    dialogRef.afterClosed().subscribe(result => {
      this.description.setValue(result);
    });
  }

  openInventoryPopup() {
    this.dialogRef = this.dialog.open(AddInventoryPopupComponent, {
      data: {
        expenses: this.expenses
      }
    });
    this.dialogRef.updateSize("100%");
    this.dialogRef.afterClosed().pipe(filter(r => !!r)).subscribe(result => {

      if (result.inventory && result.quantity) {
        const inventory: Inventory = result.inventory;

        this.showExpense = true;
        this.expenses.push({
          title: inventory.title,
          desc: inventory.description,
          quantity: result.quantity,
          price: inventory.price,
          inventory_id: inventory.id,
          is_editable: true
        });
      }
    });
  }

  openSlideShowPopup() {
    console.log("Slide show popup");
    const dialogRef = this.dialog.open(SlideShowPopupComponent, {
      data: this.task.attachments.data,
      panelClass: "task-image-preview-dialog-container"
    });

    dialogRef.updateSize("100%", "100%");
    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed");
    });
  }

  getPDFUrl(attachment: TaskAttachment): SafeResourceUrl {
    console.log("[SEMPER] url is : ", attachment.url);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(attachment.url);
  }

  attachFile(event, i): Observable<string> {
    const localImgURL$ = new Subject<any>();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[i];
      const extension = file.name.split(".")[((file.name.split(".")).length - 1)];
      if (extension.toLowerCase() === "jpeg" || extension.toLowerCase() === "jpg") {
        const options: { canvas: boolean, orientation?: string } = {canvas: true};
        loadImage.parseMetaData(file, (data) => {
          if (data.exif) {
            options.orientation = data.exif.get("Orientation");
          }
          loadImage(file, (img) => {
            localImgURL$.next(img.toDataURL());
          }, options);
        });
      } else {
        const reader = new FileReader();
        reader.onload = function() {
          localImgURL$.next(this.result);
        };
        reader.readAsDataURL(file);
      }
    }

    return localImgURL$.asObservable().pipe(takeUntil(this.destroyed$));
  }

  getTaskCreatorName(task: TaskFull): string {

    if (task.creator_id) {
      return TaskModelUtil.getCreatorName(task);
    } else if (task.creator_task_id) {
      return "Repeating";
    } else if (task.auto_task_id) {
      return "Auto";
    }

    return;
  }

  showTaskCreatorName(task: TaskFull): boolean {

    if (task.creator_id) {
      return true;
    } else if (task.creator_task_id) {
      return true;
    } else if (task.auto_task_id) {
      return true;
    }

    return false;
  }


  openCreatorTask(isClone: boolean = false) {
    if (isClone) {
      this.router.navigate(["/tasks/" + this.task.cloned_from_id]);
    } else {
      if (this.task.creator_task_id) {
        this.router.navigate(["/tasks/" + this.task.creator_task_id]);
      } else {
        this.router.navigate(["/settings/tools/auto-tasks/" + this.task.auto_task_id, {
          from: "tasks",
          task_id: this.task.id
        }]);
      }
    }
  }

  duplicateTask() {
    this.dialogRef = this.dialog.open(ConfirmTaskEscalatePopupComponent, {data: {taskId: this.task.id}});
    this.dialogRef.updateSize("100%", "35%");
  }

  makeEditable(comment) {
    console.log("entered");
    const id = comment.id;
    if (this.isEditing[id]) {
      this.isEditing[id] = !this.isEditing[id];
    } else {
      this.isEditing[id] = true;
      this.commentText.setValue(comment.comment);
    }
  }

  saveComment(comment) {
    const data = {comment: this.commentText.value};
    this.taskRepo.updateComment(data, comment.id).subscribe(res => {
      console.log(res);
      this.isEditing[comment.id] = false;
    });
  }

  writeComment() {
    this.showAddCommentInput = true;
    setTimeout(() => {
      this.setupQuill();
      this.quillBody.focus();
    });
  }

  cancelAddComment() {
    this.showAddCommentInput = false;
    this.newCommentText.setValue(null);
  }

  getName(id) {
    const assignee = this.allAssignees.find(assignee => assignee.id === id);
    if (assignee) {
      return assignee.first_name + " " + assignee.last_name;
    }
  }

  getPicUrl(id) {
    const assignee = this.allAssignees.find(assignee => assignee.id === id);
    if (assignee) {
      return UserModelUtil.getProfilePic(assignee);
    }
  }

  deleteComment(comment) {
    this.dialogRef = this.dialog.open(DeleteCommentConfirmationPopupComponent, {
      data: {
        comment: comment,
        taskId: this.task.id
      }
    });
    this.dialogRef.updateSize("100%", "30%");
  }

  setUpListingAccess() {
    console.log("Inside", this.task.property_id);
    this.listingRepo.getPropertyAccessDetails(this.task.property_id).subscribe((details) => {
      this.propertyAccessTitle = details.property_access_title;
      this.propertyAccessKey = details.property_access_key;
    });
  }

  copyCommentLink(comment: Comment) {
    this.clipboard.copy(environment.dashboardURL + "/tasks/" + this.taskId + ";comment_id=" + comment.id);
    this.snackBarService.show("Comment link has been copied to clipboard.");
  }

  setFocus(selector: string) {
    setTimeout(() => {
      console.log("Root Element", this.renderer.selectRootElement(selector));
      this.renderer.selectRootElement(selector).scrollIntoView();
    }, 0);
  }

  refresh() {
    this.taskRepo.getFullTaskById(this.taskId, true).pipe(takeUntil(this.destroyed$)).subscribe(t => {
      this.task = t;
      this.assigneeId = this.task.assignee_id;
      this.completed = this.task.status === TaskStatus.COMPLETED || this.task.status ===  TaskStatus.PAID;
      this.appService.changeAppTitle(this.task.title);
    });
    this.taskRepo.getCommentsForTask(this.taskId, true).pipe(takeUntil(this.destroyed$)).subscribe(res => {
      console.log(res);
      console.log(this.commentsLoading, this.commentsLoaded);
      this.comments = res as Comment[];
      if (this.selectedCommentId) {
        setTimeout(() => {
          this.selectedCommentId = null;
        }, 5000);
      }
    });
  }

  getListingTitleFromId(id: number): string {
    return this.listingRepo.getListingTitle(id);
  }

  dataURItoBlob(dataURI, file) {
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1]);
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ia], file.name, {type: file.type, lastModified: Date.now()});
  }

  setDate(key) {
    const today = new Date();
    if (key === "today") {
      this.dueDate.patchValue(today);
    } else if (key === "tomorrow") {
      this.dueDate.patchValue(new Date(today.getFullYear(), today.getMonth(), (today.getDate() + 1)));
    } else {
      const due = this.selectBookings[0].start;
      this.dueDate.patchValue(new Date(due));
    }
  }

  redirectToListing(id: number) {
    this.router.navigate(["/listings/" + id + "/tasks", {id: id}]);
  }

  assignButtonClicked() {
    this.showAssignEmployeeInput = true;
    setTimeout(() => document.getElementById("assignEmployeeContainer").scrollIntoView());
  }

  assignEmployee() {
    this.isUpdating = true;
    this.taskRepo.updateTaskById(this.taskId, {employee_id: this.employeeId})
      .subscribe(() => {
        this.isUpdating = false;
        this.showAssignEmployeeInput = false;
      }, () => {
        this.isUpdating = false;
      });
  }

  onSelectNone() {
    this.selectedAssigneeType = null;
  }

  onAssigneeTypeChange() {
    this.assigneeId = null;
  }

  /**
   * Private Setup
   */
  private setupShowTaskStore() {
    this.taskRepo.getIsFullTaskLoading(this.taskId).pipe(takeUntil(this.destroyed$)).subscribe(l => this.taskLoading = l);
    this.taskRepo.getIsFullTaskLoaded(this.taskId).pipe(takeUntil(this.destroyed$)).subscribe(l => this.taskLoaded = l);
    this.taskRepo.getFullTaskById(this.taskId, false).pipe(takeUntil(this.destroyed$)).subscribe(t => {
      this.task = t;
      console.log("[task object]", this.task);
      this.assigneeId = this.task.assignee_id;
      this.employeeId = this.task.employee_id;
      this.completed = this.task.status === TaskStatus.COMPLETED || this.task.status ===  TaskStatus.PAID;
      this.appService.changeAppTitle(this.task.title);

      if (this.task.property_id) {
        this.setUpListingAccess();
        this.currentMonth = this.getSDDayObject(this.task.due_date);
      }

      if (this.isEditing) {
        this.setupEditForm(this.task);
      }

    });
    this.taskRepo.getIsTaskExist(this.taskId).subscribe(value => {
      this.taskExist = value;
    });
  }

  private setupEditFormForReservation(reservation: BookingFull) {
    this.propertyId.setValue(reservation.property_id);
    this.selectedListingId = reservation.property_id;
    this.showGuest = true;
    console.log("Reservation", reservation.start);
    const date = new Date(reservation.start);
    this.dueDate.setValue(new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)));
    this.bookingRepo.getUpcomingBookingWithPropertyId(reservation.property_id).subscribe((res: BookingCompact[]) => {
      this.selectBookings = res;
      this.loadingGuest = false;
      this.booking.setValue(reservation.id);
    }, () => {
      this.loadingGuest = false;
    });
  }

  private setupEditForm(taskFull?: TaskFull) {
    if (taskFull) {
      this.title.setValue(taskFull.title);
      this.propertyId.setValue(taskFull.property_id);
      this.status = taskFull.status;
      this.categoryControl.setValue(taskFull.type);
      const date = new Date(taskFull.due_date);
      this.dueDate.setValue(new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)));
      this.customNoOfTimes.setValue(taskFull.no_of_times);
      this.description.setValue(taskFull.description);
      this.paymentByOption = taskFull.payment_by;

      if (taskFull.property_id) {
        this.selectedListingId = taskFull.property_id;
      }

      if (taskFull.repeat_frequency && taskFull.repeat_frequency !== TaskRepeatFrequency.NEVER) {
        this.repeatTasks = true;
        // if the repeat task is checked.
        if (this.repeatTasks && this.task) {
          if (this.task.repeat_frequency !== TaskRepeatFrequency.NEVER && this.task.repeat_value) {
            this.repeatFrequency = TaskEnumHelper.getTaskRepeatSlugFull(this.setCorrectRepeatFrequency(this.task));
            if (!this.task.no_of_times) {
              this.noOfTimes.setValue("1");
            } else {
              this.noOfTimes.setValue("2");
              this.showCustomValueField = true;
            }
          }
        }
      }

      if (this.task.expenses.data.length !== 0) {
        this.showExpense = true;
        this.expenses = this.task.expenses.data.map(l => {
          if (isNullOrUndefined(l.inventory_id)) {
            delete l.inventory_id;
          }
          return l;
        });
        this.expenses = this.checkFee(this.task.expenses.data);
      }

      if (this.task && this.task.property_id) {
        this.selectedValueChanged();
      }

      if (taskFull.due_time !== "All Day") {
        this.showTime = true;

        // TODO replace with better approach
        // break time into hh, mm , and meridian
        const time = taskFull.due_time.split(":");
        if (this.showTime && this.task) {
          this.hh = time[0];
          this.mm = time[1];
          this.meridian = time[2] ? (time[2] as string).split(" ")[1] : null;
        }
      }

      if (taskFull.booking_id) {
        this.showGuest = true;
        this.booking.setValue(taskFull.booking_id);
        this.selectedBooking = taskFull?.booking?.data;
        this.bookingRepo.getUpcomingBookingWithPropertyId(this.selectedListingId).subscribe((res: BookingCompact[]) => {
          this.selectBookings = res;
          this.loadingGuest = false;
          this.booking.setValue(taskFull.booking_id);
        }, () => {
          this.loadingGuest = false;
        });
      }
    }
  }

  private setupReservation() {
    this.bookingRepo.getIsFullBookingLoading(this.reservationId).pipe(takeWhile(() => this.isAlive))
      .subscribe(l => this.reservationsLoading = l);

    this.bookingRepo.getIsFullBookingLoaded(this.reservationId).pipe(takeWhile(() => this.isAlive))
      .subscribe(l => this.reservationsLoaded = l);

    this.bookingRepo.getFullBookingById(this.reservationId).pipe(takeWhile(() => this.isAlive))
      .subscribe(b => {
        this.reservationFull = b;
        console.log("ID", this.reservationId, "B", b);
        if (b) {
          this.setupEditFormForReservation(b);
        }
      });
  }

  private checkNullString(str: string): string {
    if (str != null) {
      return " ".concat(str);
    } else {
      return "";
    }
  }

  private getFormData() {
    return {
      title: (this.allowEdit || !this.taskId) ? this.title.value : this.task ? this.task.title : "",
      property_id: (this.allowEdit || !this.taskId) ? this.propertyId.value : this.task ? this.task.property_id : null,
      type: (this.allowEdit || !this.taskId) ? this.isUserHomeowner ? TaskCategory.WORK_ORDER : this.categoryControl.value : this.task.type,
      status: (this.allowEdit || !this.taskId) ? this.status : this.task.status,
      assignee_id: (this.allowEdit || !this.taskId) ? this.assigneeId : this.task.assignee_id,
      employee_id: (this.allowEdit || !this.taskId) ? this.employeeId : this.task.employee_id,
      no_of_times: (this.allowEdit || !this.taskId) ? this.customNoOfTimes.value : this.task.no_of_times,
      due_date: (this.allowEdit || !this.taskId) ? DateUtils.toISODateString(new Date(this.dueDate.value)) : this.task.due_date,
      description: (this.allowEdit || !this.taskId) ? this.description.value : this.task.description,
      booking_id: (this.allowEdit || !this.taskId) ? this.booking.value : this.task.booking_id,
      payment_by: (this.allowEdit || !this.taskId) ? this.paymentByOption : this.task.payment_by
    };
  }

  private appendOptionalData() {

    let data = this.getFormData();

    let newData;
    if (this.task) {
      if (this.task.repeat_frequency) {
        newData = {
          ...data,
          repeat_frequency: this.getCorrectRepeatFrequency(),
          repeat_value: this.getRepeatValue()
        };
        data = newData;
      }
    } else {
      newData = data;
      if (this.repeatTasks) {
        newData = {
          ...data,
          repeat_frequency: this.getCorrectRepeatFrequency(),
          repeat_value: this.getRepeatValue()
        };
      } else {
        newData = {
          ...data,
          repeat_frequency: TaskRepeatFrequency.NEVER,
          repeat_value: 1
        };
      }
      data = newData;
    }
    if (this.showTime) {
      newData = {
        ...data,
        due_time: this.getTimeInDateFormat(this.hh, this.mm, this.meridian)
      };
      data = newData;
    } else {
      newData = {
        ...data,
        due_time: null
      };
      data = newData;
    }

    if (this.showExpense && (!this.isVendor || this.isVendorTask)) {
      newData = {
        ...data,
        expenses: this.checkFee(this.expenses),
        amount: this.totalAmount(),
      };
    } else {

      if (this.isVendor && !this.isVendorTask) {
        console.log("vendor expense", this.vendorExpenses, this.expenses);
        newData = {
          ...data,
          expenses: this.checkFee([...this.task.expenses.data, ...this.vendorExpenses]),
          amount: this.totalVendorAmount(),
        };

      } else {

        newData = {
          ...data,
          expenses: this.expenses,
          amount: this.totalAmount(),
        };
      }
    }

    if (this.uploadedTaskImages.length > 0) {
      newData = {
        ...newData,
        attachment_ids: this.uploadedTaskImages.map(i => i.id),
      };
    }
    if (this.isUserHomeowner) {
      newData = {
        ...newData,
        assignee_id: null
      };
    }

    if (this.selectedAssigneeType) {
      newData = {
        ...newData,
        assignee_type: this.selectedAssigneeType,
        assignee_id: null,
      };
    }

    if (!this.task && this.noOfTimes.value === "1") {
      delete newData.no_of_times;
    }

    console.log("new data", newData);
    return newData;
  }

  private mapUploadingFileToTaskUpload(uploadFile: UploadFile, isComplete: boolean, uploadProgress): TaskUploadProgress {
    const taskUploadProgress: TaskUploadProgress = null;
    return {
      ...taskUploadProgress,
      attachment: uploadFile,
      isComplete: isComplete,
      uploadProgress: uploadProgress
    };
  }

  private setUpAdmins() {
    this.optionRepo.getTaskAssignees().pipe(filter(a => !!a), takeWhile(() => this.isAlive),)
      .subscribe((assignees) => {
        this.allAssignees = assignees;

        console.log("[SEMPER]", this.allAssignees);
        if (this.isUserOwner) {
          this.assignees = [];
        } else {
          this.assignees = assignees;
        }
        this.filteredAssignees = this.assignees;
      });
  }

  escalatedTasks(id) {
    this.router.navigate(["/tasks/" + id]);
  }

  upcomingTasks() {
    window.open("/listings/" + this.task.property_id + "/tasks;id=" + this.task.property_id, "_blank");
  }
}
