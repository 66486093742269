import { Component, DoCheck, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import Quill from "quill";
import "quill-mention";
import { isNullOrUndefined } from "util";

import { QuillUtils } from "../../../utils/quill.util";

@Component({
  selector: "sd-editor-popup",
  template: `
    <div id="cwidth" fxLayout="column" fxLayoutGap="20px">
      <mat-dialog-content>
        <mat-toolbar style="position: fixed;z-index: 9;" [ngStyle]="{'width':clientWidth + 'px'}" color="accent">
          <h2>Stay Duvet Dashboard</h2>
          <span class="space-filler"></span>

          <button mat-icon-button (click)="close()">
            <mat-icon>check</mat-icon>
          </button>
          <button mat-icon-button (click)="dismiss()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar>

      </mat-dialog-content>
      <div style="padding-top:40px"></div>
      <!--<quill-editor style="height: 80vh" [(ngModel)]="text">-->
      <!--</quill-editor>-->

      <div #emailBody class="email-body"></div>

    </div>
  `,
  styles: [`
    mat-dialog-content {
      padding: 0px;
    }

    .email-body {
      height: 80vh;
      content-editable: true;
    }

    .mention {
      background-color: orange !important;
    }

    /deep/ mat-dialog-container {
      padding-top: 0px !important;
      /*padding-left: 24px !important;*/
      /*padding-right: 24px !important;*/
      /*padding-bottom: 24px !important;*/
    }
  `]
})
export class EditorPopupComponent implements OnInit, DoCheck {

  text: any;
  clientWidth: number;

  @ViewChild("emailBody", {read: ElementRef, static: true}) body: ElementRef;

  quillBody: Quill;

  titleVariables = QuillUtils.VARIABLES;

  constructor(public dialog: MatDialogRef<EditorPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.text = data;
  }

  ngOnInit() {

    const values = this.titleVariables;
    this.quillBody = new Quill(this.body.nativeElement, {
      theme: "snow",
      modules: {
        mention: {
          allowedChars: /^[_A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["{"],
          isolatedCharacter: false,
          source: function (searchTerm, renderList, mentionChar) {

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(values[i]);
                }
              }
              renderList(matches, "");
            }
          },
          renderItem: function (item, searchTerm) {
            return item.value.replace("{", "{{");
          },
          dataAttributes: ["value"]
        },
        toolbar: [
          ["bold", "italic", "underline", "strike"],        // toggled buttons
          ["blockquote", "code-block"],

          [{header: 1}, {header: 2}],               // custom button values
          [{list: "ordered"}, {list: "bullet"}],
          [{indent: "-1"}, {indent: "+1"}],          // outdent/indent

          [{size: ["small", false, "large", "huge"]}],  // custom dropdown
          [{header: [1, 2, 3, 4, 5, 6, false]}],

          [{color: []}, {background: []}],          // dropdown with defaults from theme
          [{font: []}],
          [{align: []}],

          ["clean"],
          ["image"]
        ]
      }
    });

    this.quillBody.root.innerHTML = this.text;

    this.quillBody.on("text-change", () => {
      this.text = this.quillBody.root.innerHTML;
    });

  }

  close() {
    this.dialog.close({text: this.text});
  }

  ngDoCheck() {
    if (!isNullOrUndefined(document.getElementById("cwidth"))) {
      this.clientWidth = document.getElementById("cwidth").offsetWidth + 48;
    }
  }

  dismiss() {
    this.dialog.close();
  }

}
