import { RoutingRule } from "../../../models/new/listing/routing-rule";
import { Action } from "../../action";

export enum RoutingRulesActionTypes {
  INDEX_REQUEST = "[Routing Rules] Routing Rules Index Request",
  INDEX_SUCCESS = "[Routing Rules] Routing Rules Index Success",
  UPDATE_SUCCESS = "[Routing Rules] Routing Rules Update Success",
  DELETE_SUCCESS = "[Routing Rules] Routing Rules Delete Success",
  CREATE_SUCCESS = "[Routing Rules] Routing Rules Create Success"
}

export class RoutingRulesIndexRequest implements Action {
  readonly type = RoutingRulesActionTypes.INDEX_REQUEST;

  constructor(public payload: number) {

  }
}

export class RoutingRulesIndexSuccess implements Action {
  readonly type = RoutingRulesActionTypes.INDEX_SUCCESS;

  constructor(public payload: { rules: RoutingRule[], listingId: number }) {

  }
}

export class RoutingRulesUpdateSuccess implements Action {
  readonly type = RoutingRulesActionTypes.UPDATE_SUCCESS;

  constructor(public payload: RoutingRule) {

  }
}

export class RoutingRulesDeleteSuccess implements Action {
  readonly type = RoutingRulesActionTypes.DELETE_SUCCESS;

  constructor(public payload: { listingId: number, ruleId: number }) {

  }
}

export class RoutingRulesCreateSuccess implements Action {
  readonly type = RoutingRulesActionTypes.CREATE_SUCCESS;

  constructor(public payload: { rules: RoutingRule[], listingId: number }) {

  }
}
