import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorObservable } from "rxjs-compat/observable/ErrorObservable";
import { Observable } from "rxjs/internal/Observable";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError } from "rxjs/operators";
import { isNullOrUndefined, isUndefined } from "util";

import { environment } from "../../environments/environment";
import { __HTTPResponseType } from "../enums/common.enum";

import { AppService } from "./app.service";

@Injectable()
export class ApiService {

  /**
   * Constants
   */
  private PROPERTY_INDEX_INCLUDES = "tags,coverImage";
  private PROPERTY_SHOW_INCLUDES = "managementContacts,owner,assignee,checklists,images";
  private BASE_URL = environment.apiBaseURL;

  constructor(private http: HttpClient,
              private appService: AppService,
              private snackBar: MatSnackBar) {
  }

  private static hasToken(): boolean {
    return !!localStorage.getItem("auth_token");
  }

  private static getToken(): string {
    return localStorage.getItem("auth_token");
  }

  private static getMfaToken(): string {
    return localStorage.getItem("mfa_token");
  }

  private static buildParams(data: any): HttpParams {
    let params = new HttpParams();

    if (data) {
      for (const key of Object.keys(data)) {
        console.log("KEY", key);
        // Beware .append is not mutable operation
        params = params.append(key, data[key]);
      }
    }

    return params.append("__timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);
  }

  private static injectTimezoneToData(data: any): any {
    const injection = {
      __timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    if (data) {
      return {
        ...data,
        ...injection
      };
    }

    return injection;
  }

  get<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders, useBaseUrl: boolean = true, responseType?: __HTTPResponseType, handleError = true): Observable<T> {
    console.log("[api.service] get", url, data);

    const options: any = {
      params: ApiService.buildParams(data),
      headers: this.buildHeaders(useAuthHeaders, headers)
    };

    if (responseType) {
      options.responseType = responseType;
    }

    let baseUrl = this.BASE_URL + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }

    console.log("[SEMPER]", url, data);

    const res$ = this.http.get<T>(baseUrl, options);

    // if (handleError) {
    //   res$.pipe(catchError(err => this.handleError(err)));
    // }

    return handleError ? res$.pipe(catchError(err => this.handleError(err))) : res$;
  }

  post<T>(url: string, useAuthHeaders: boolean, data: any, headers?: HttpHeaders, useBaseUrl: boolean = true, responseType?: __HTTPResponseType, fullResponse = false): Observable<T> {
    console.log("[api.service] post", url, data);

    let baseUrl = this.BASE_URL + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }

    const options: any = {
      headers: this.buildHeaders(useAuthHeaders, headers)
    };

    if (responseType) {
      options.responseType = responseType;
    }

    if (fullResponse) {
      options.observe = "response";
    }

    return this.http.post<T>(baseUrl, ApiService.injectTimezoneToData(data), options).pipe(catchError(err => this.handleError(err)));
  }

  put<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders, useBaseUrl: boolean = true, responseType?: __HTTPResponseType): Observable<T> {
    console.log("[api.service] put", url, data);

    let baseUrl = this.BASE_URL + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }

    const options: any = {
      headers: this.buildHeaders(useAuthHeaders, headers),
    };

    if (responseType) {
      options.responseType = responseType;
    }

    return this.http.put<T>(baseUrl, ApiService.injectTimezoneToData(data), options).pipe(catchError(err => this.handleError(err)));
  }

  delete<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders, useBaseUrl: boolean = true, responseType?: __HTTPResponseType, fullResponse = false): Observable<T> {
    console.log("[api.service] delete", url, data);

    const options: any = {
      params: ApiService.buildParams(data),
      headers: this.buildHeaders(useAuthHeaders, headers)
    };

    if (responseType) {
      options.responseType = responseType;
    }

    let baseUrl = this.BASE_URL + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }

    if (fullResponse) {
      options.observe = "response";
    }

    return this.http.delete<T>(baseUrl, options).pipe(catchError(err => this.handleError(err)));
  }

  upload<T>(url: string, method: string, file: File, useAuthHeaders: boolean, key?: string, headers?: HttpHeaders, useBaseUrl: boolean = true, responseType?: __HTTPResponseType): Observable<HttpEvent<T>> {
    let formData: any = file;
    if (key) {
      formData = new FormData();
      formData.append(key, file, file.name);
    }

    let baseUrl = this.BASE_URL + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }

    const req = new HttpRequest(method, baseUrl, formData, {
      reportProgress: true,
      responseType: responseType,
      headers: this.buildHeaders(useAuthHeaders, headers)
    });

    return this.http.request<T>(req);
  }

  private buildHeaders(useAuthHeaders: boolean, headers?: HttpHeaders): HttpHeaders {
    if (!useAuthHeaders) {
      return headers;
    }

    let mutatedHeaders: HttpHeaders;

    if (!isNullOrUndefined(headers)) {
      mutatedHeaders = headers;
    } else {
      mutatedHeaders = new HttpHeaders();
    }

    mutatedHeaders = mutatedHeaders.append("Authorization", "bearer " + ApiService.getToken());
    if (!!ApiService.getMfaToken()) {
      mutatedHeaders = mutatedHeaders.append("mfa-token", "bearer " + ApiService.getMfaToken());
    }
    return mutatedHeaders;
  }

  private handleError(response: HttpErrorResponse): ErrorObservable<any> | Observable<any> {
    console.log("[sd-error] start");
    console.log(response);
    console.log("[sd-error] end");

    const error = response.error || {};
    const keys = Object.keys(error);
    const key = keys[0];

    console.log("status", error.status_code);
    if (error.status_code === 401) {
      this.appService.logout();
      this.snackBar.open("Unauthorized", "", {
        duration: 4000,
      });
      return throwError({messages: ["Unauthorized"], error: null});
    }

    const errorObject = error;
    const errorResponse = errorObject.errors;

    const messages = [];
    if (isUndefined(errorResponse)) {
      if (typeof errorObject === "object") {
        messages.push(errorObject.message);
      } else {
        messages.push(JSON.parse(errorObject).message);
      }
    } else {
      if (typeof errorResponse === "string") {
        return throwError({messages: [errorResponse], error: errorObject});
      } else {
        for (const key in errorResponse) {
          if (errorResponse.hasOwnProperty(key)) {
            messages.push(errorResponse[key]);
          }
        }
      }
    }

    this.snackBar.open(messages[0], "", {
      duration: 4000,
    });
    return throwError({messages: messages, error: errorObject});
  }

}
