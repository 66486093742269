import {filter, map, take} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {AutoReviewService} from "../services/auto-review.service";
import {Store} from "@ngrx/store";
import {
  getAllAutoReviews,
  getAutoReviewById,
  getIsAutoReviewsLoaded,
  getIsAutoReviewsLoading,
  State
} from "../reducers";
import {Observable} from "rxjs";
import {AutoReview} from "../models/new/auto-review";
import {
  AutoReviewCreateSuccess,
  AutoReviewDeleteSuccess,
  AutoReviewIndexRequest,
  AutoReviewIndexSuccess,
  AutoReviewUpdateSuccess
} from "../actions/new/setttings/auto-review";

@Injectable()
export class AutoReviewRepository {

  constructor(private autoReviewService: AutoReviewService,
              private store: Store<State>) {
  }

  getIsAutoReviewsLoading(): Observable<boolean> {
    return this.store.select(getIsAutoReviewsLoading);
  }

  getIsAutoReviewsLoaded(): Observable<boolean> {
    return this.store.select(getIsAutoReviewsLoaded);
  }

  getAutoReviews(force: boolean = false): Observable<AutoReview[]> {

    let loading: boolean;
    let loaded: boolean;

    this.getIsAutoReviewsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsAutoReviewsLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new AutoReviewIndexRequest());
      this.autoReviewService.getAutoReviews()
        .subscribe(res => {
          this.store.dispatch(new AutoReviewIndexSuccess(res));
        });
    }
    return this.store.select(getAllAutoReviews).pipe(
      filter(r => !!r));
  }

  updateAutoReview(id: number, data: any): Observable<AutoReview> {
    return this.autoReviewService.updateAutoReview(id, data).pipe(
      map(res => {
        this.store.dispatch(new AutoReviewUpdateSuccess(res));
        return res;
      }));
  }

  getAutoReviewById(id: number): Observable<AutoReview> {
    return this.store.select((state: State) => getAutoReviewById(state, id));
  }

  createAutoReview(data: any): Observable<AutoReview> {
    return this.autoReviewService.createAutoReview(data).pipe(
      map(res => {
        this.store.dispatch(new AutoReviewCreateSuccess(res));
        return res;
      }));
  }

  deleteAutoReview(id: number): Observable<boolean> {
    return this.autoReviewService.deleteAutoReview(id).pipe(
      map(res => {
        this.store.dispatch(new AutoReviewDeleteSuccess(id));
        return res;
      }))
  }

}
