/**
 * Created by Piyush on 22-Jul-17.
 */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { TaskEnumHelper } from "app/enums/task.enum";
import { TaskModelUtil } from "app/models/utils/task-model.util";
import * as addDays from "date-fns/add_days";
import { filter, take } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { BookingEnumHelper } from "../../enums/booking.enum";
import { BookingData, CalendarShowResponse } from "../../models/calendar-response";
import { ListingCompact } from "../../models/new/listing/listing-compact.model";
import { MultiCalendarRule } from "../../models/new/multi-calendar-rule";
import { TaskCompact } from "../../models/new/tasks/task-compact.model";
import { ListingRepository } from "../../repository/listing.repository";
import { MultiCalendarRulesRepository } from "../../repository/multi-calendar-rules.repository";
import { MultiCalendarRepository } from "../../repository/multi-calendar.repository";
import { UserRepository } from "../../repository/user-repository";
import { StayDuvetService } from "../../services/stayduvet";
import { getDateObj } from "../../utils/calendar-utils";
import { ListingOwnerBlockDeletePopupComponent } from "../../modules/listings/components/popups/listing-owner-block-delete-popup";
import { ListingOwnerBlockPopupComponent } from "../../modules/listings/components/popups/listing-owner-block-popup";

@Component({
  selector: "sd-multi-calendar-date-tile",
  template: `

    <div [ngClass]="{'owners-block-first-half': data?.first_block && !data?.second_block,
         'owners-block-second-half': data?.second_block && !data?.first_block,
         'owners-block': data?.second_block && data?.first_block,
         'limit-block-full': isBlockedFromLimit(),
         'limit-block-second': isBlockedStartFromLimit()}"
         (click)="blockingEvent()"
         style="position: relative;">


      <div *ngIf="isFullBlock() || isFirstBlock() || isSecondBlock()"
           [ngClass]="{'block-first-half': isFirstBlock(),
         'block-second-half': isSecondBlock(),
         'block': isFullBlock()}"
           [ngbPopover]="popovers" #p="ngbPopover" container="body" triggers="manually"
           (mouseenter)="onBlockOpen(p)">
      </div>

      <div fxLayout="column" *ngIf="isBlocking" fxLayoutAlign="center center"
           style="height: 100%">
        <mat-spinner [color]="'accent'" [diameter]="30" [strokeWidth]="4"></mat-spinner>
      </div>

      <span *ngIf="!isBlockedFromLimit() && data?.first_booking"
            class="first-booking"
            (click)="redirect(data?.first_booking.id)"
            [ngbPopover]="popover" #p="ngbPopover" container="body" triggers="manually"
            (mouseenter)="onOpen(data?.first_booking, p)"
            [ngStyle]="{'background': getBookingSourceBackground(data?.first_booking)}">
      </span>

      <span *ngIf="!isBlockedStartFromLimit() &&
      !isBlockedFromLimit() && data?.second_booking"
            class="second-booking"
            (click)="redirect(data?.second_booking.id)"
            [ngbPopover]="popover" #p="ngbPopover" container="body" triggers="manually"
            (mouseenter)="onOpen(data?.second_booking, p)"
            [ngStyle]="{'background': getBookingSourceBackground(data?.second_booking)}">
      </span>


      <span *ngIf="!isBlockedStartFromLimit() &&!isBlockedFromLimit() && data?.booking"
            class="full-booking"
            (click)="redirect(data?.booking.id)"
            [ngbPopover]="popover" #p="ngbPopover" container="body" triggers="manually"
            (mouseenter)="onOpen(data?.booking, p)"
            [ngStyle]="{'background': getBookingSourceBackground(data?.booking)}">
      </span>

    </div>


    <ng-template #popover let-booking="booking" let-p="p">
      <div *ngIf="!!booking && !isHouseKeeper" fxLayout="column" fxFlex fxLayoutGap="10px" (mouseleave)="p.close()">

        <div fxLayout="row" fxLayoutAlign="space-between center" class="clean"
             [ngStyle]="{background: getBookingSourceBackground(booking)}">
          <span style="color: white;text-align: center"><b>#{{booking.confirmation_code}}</b></span>
          <mat-icon class="pointer" style="color: white" (click)="redirect(booking.id)">open_in_new</mat-icon>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
          <span>Guest:</span>
          <span>{{booking.guest_name}}</span>
        </div>

        <div *ngIf="booking.property_id !== listing?.id" fxLayout="row" fxLayoutAlign="space-between"
             fxLayoutGap="10px">
          <span>Property:</span>
          <span>{{getListingTitle(booking.property_id)}}</span>
        </div>

        <div *ngIf="booking.property_id !== listing?.id" fxLayout="row" fxLayoutAlign="space-between"
             fxLayoutGap="10px">
          <span>Start:</span>
          <span>{{booking.start | date: 'mediumDate'}}</span>
        </div>

        <div *ngIf="booking.property_id !== listing?.id" fxLayout="row" fxLayoutAlign="space-between"
             fxLayoutGap="10px">
          <span>End:</span>
          <span>{{booking.end | date: 'mediumDate'}}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
          <span>No. of Guests:</span>
          <span>{{booking.no_of_guests}}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
          <span>No. of Children:</span>
          <span>{{booking.no_of_children}}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
          <span>Source:</span>
          <span>{{BookingEnumHelper.getBookingSourceTitle(booking.source)}}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
          <span>Status:</span>
          <span>{{BookingEnumHelper.getBookingStatusTitleAndColor(booking.status).title}}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
          <span>Total Amount:</span>
          <span>{{booking.payout_amount | currency:'USD':true}}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
          <span>Total Paid:</span>
          <span>{{booking.total_paid | currency:'USD':true}}</span>
        </div>


        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
          <span>Cleaning Fee:</span>
          <span>{{booking.cleaning_fee | currency:'USD':true}}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
          <span>Revenue:</span>
          <span>{{booking.management_commission | currency:'USD':true}}</span>
        </div>

      </div>

      <ng-container *ngIf="!!booking && isHouseKeeper">
        <div class="height-350" fxLayout="column" fxLayoutGap="20px" (mouseleave)="p.close()">
          <div class="height-150" *ngFor="let task of booking.tasks" fxLayout="column" fxLayoutGap="10px"
               style="width: 100%">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="clean"
                 [ngStyle]="{background: getTaskDisplayInfo(task).color}">
              <span style="color: white;text-align: center"><b>{{getTaskDisplayInfo(task).title}}</b></span>
              <mat-icon class="pointer" style="color: white" (click)="redirectTask(task.id)">open_in_new</mat-icon>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <span>Title:</span>
              <span>{{task.title | truncate: '20'}}</span>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <span>Due Date:</span>
              <span>{{task.due_date | date: 'mediumDate'}}</span>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <span>Due Time:</span>
              <span>{{task.due_time}}</span>
            </div>

            <div *ngIf="!!task.employee_id" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <span>Employee:</span>
              <span>{{getTaskEmployeeName(task.employee_id)}}</span>
            </div>

            <div *ngIf="!!task.completed_on" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <span>Completed On:</span>
              <span>{{task.completed_on | date: 'mediumDate'}}</span>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <span>Amount:</span>
              <span><b>$</b>{{task.amount}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>


    <ng-template #popovers let-bookings="bookings" let-blocks="blocks" let-p="p" let-length="length">

      <div *ngIf="!isHouseKeeper" fxLayout="column" fxLayoutGap="20px" (mouseleave)="p.close()"
           [ngClass]="{'height-450': length>=2, 'height-230': length<2}">

        <div class="height-230" *ngFor="let booking of bookings" fxLayout="column" fxFlex fxLayoutGap="10px"
             style="width: 100%">

          <div fxLayout="row" fxLayoutAlign="space-between center" class="clean"
               [ngStyle]="{background: getBookingSourceBackground(booking)}">
            <span style="color: white;text-align: center"><b>#{{booking.confirmation_code}}</b></span>
            <mat-icon class="pointer" style="color: white" (click)="redirect(booking.id)">open_in_new</mat-icon>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <span>Guest:</span>
            <span>{{booking.guest_name}}</span>
          </div>

          <div *ngIf="booking.property_id !== listing?.id" fxLayout="row" fxLayoutAlign="space-between"
               fxLayoutGap="10px">
            <span>Property:</span>
            <span>{{getListingTitle(booking.property_id)}}</span>
          </div>

          <div *ngIf="booking.property_id !== listing?.id" fxLayout="row" fxLayoutAlign="space-between"
               fxLayoutGap="10px">
            <span>Start:</span>
            <span>{{booking.start | date: 'mediumDate'}}</span>
          </div>

          <div *ngIf="booking.property_id !== listing?.id" fxLayout="row" fxLayoutAlign="space-between"
               fxLayoutGap="10px">
            <span>End:</span>
            <span>{{booking.end | date: 'mediumDate'}}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <span>No. of Guests:</span>
            <span>{{booking.no_of_guests}}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <span>No. of Children:</span>
            <span>{{booking.no_of_children}}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <span>Source:</span>
            <span>{{BookingEnumHelper.getBookingSourceTitle(booking.source)}}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <span>Status:</span>
            <span>{{BookingEnumHelper.getBookingStatusTitleAndColor(booking.status).title}}</span>
          </div>


          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <span>Total Amount:</span>
            <span>{{booking.payout_amount | currency:'USD':true}}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <span>Total Paid:</span>
            <span>{{booking.total_paid | currency:'USD':true}}</span>
          </div>


          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <span>Cleaning Fee:</span>
            <span>{{booking.cleaning_fee | currency:'USD':true}}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <span>Revenue:</span>
            <span>{{booking.management_commission | currency:'USD':true}}</span>
          </div>
        </div>

        <div *ngFor="let block of blocks" fxLayout="column" fxFlex fxLayoutGap="10px" class="owner-block-popover">

          <div fxLayout="row" fxLayoutAlign="center center" class="clean">
            <span style="color: white; text-align: center"><b>Owner Block</b></span>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <span>Property:</span>
            <span>{{getListingTitle(block.property_id)}}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <span>Date:</span>
            <span>{{day.date | date: 'mediumDate'}}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between">
            <span>Reason:</span>
            <span style="text-align: end">{{block.reason}}</span>
          </div>

        </div>

      </div>

      <div *ngIf="isHouseKeeper" fxLayout="column" (mouseleave)="p.close()" style="width: 100%" class="height-350">
        <div *ngFor="let booking of bookings" fxLayout="column" style="width: 100%">
          <div class="height-150" *ngFor="let task of booking.tasks" fxLayout="column" fxLayoutGap="10px"
               style="width: 100%">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="clean"
                 [ngStyle]="{background: getTaskDisplayInfo(task).color}">
              <span style="color: white;text-align: center"><b>{{getTaskDisplayInfo(task).title}}</b></span>
              <mat-icon class="pointer" style="color: white" (click)="redirectTask(task.id)">open_in_new</mat-icon>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <span>Title:</span>
              <span>{{task.title | truncate: '20'}}</span>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <span>Due Date:</span>
              <span>{{task.due_date | date: 'mediumDate'}}</span>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <span>Due Time:</span>
              <span>{{task.due_time}}</span>
            </div>

            <div *ngIf="!!task.employee_id" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <span>Employee:</span>
              <span>{{getTaskEmployeeName(task.employee_id)}}</span>
            </div>

            <div *ngIf="!!task.completed_on" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <span>Completed On:</span>
              <span>{{task.completed_on | date: 'mediumDate'}}</span>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <span>Amount:</span>
              <span><b>$</b>{{task.amount}}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

  `,
  styles: [
      `

      .height-230 {
        min-height: 350px;
        overflow-y: hidden;
      }

      .height-150 {
        min-height: 170px;
        overflow-y: hidden;
      }

      .height-350 {
        height: 450px;
        overflow-y: scroll;
      }

      .height-450 {
        height: 550px;
        overflow-y: scroll;
      }

      .owner-block-popover {
        min-height: 150px;
        width: 100%;
      }


      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        border-radius: 10px;
      }

      ::-webkit-scrollbar {
        width: 4px;
        background-color: #F5F5F5;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
      }

      .pointer {
        cursor: pointer;
      }


      .clean {
        background: #6c6b65;
        padding: 5px;
      }

      .second-booking {
        position: absolute;
        top: 8px;
        right: 0;
        height: 18px;
        width: 60%;
        border-radius: 10px 0 0 10px;
        cursor: pointer;
      }

      .first-booking {
        position: absolute;
        top: 8px;
        left: 0;
        height: 18px;
        width: 30%;
        border-radius: 0 10px 10px 0;
        cursor: pointer;
      }

      .full-booking {
        position: absolute;
        top: 8px;
        left: 0;
        height: 18px;
        width: 100%;
        cursor: pointer;
      }


      .block {
        width: 100%;
        height: 100%;
        opacity: 0.7;
        background: #AEAEAB;
      }

      .block-second-half {
        opacity: 0.7;
        height: 100%;
        width: 100%;
        background: repeating-linear-gradient(
          135deg,
          #AEAEAB,
          #AEAEAB 10px,
          #AEAEAB 10px,
          #AEAEAB 20px
        ) repeat-y;
        background-size: 60% auto;
        background-position: right bottom
      }

      .block-first-half {
        width: 100%;
        height: 100%;
        opacity: 0.7;
        background: repeating-linear-gradient(
          135deg,
          #AEAEAB,
          #AEAEAB 10px,
          #AEAEAB 10px,
          #AEAEAB 20px
        ) repeat-y;
        background-size: 30% auto;
        background-position: left bottom

      }

      .owners-block-first-half {
        width: 100%;
        height: 100%;
        opacity: 0.2;
        background: repeating-linear-gradient(
          135deg,
          #6c6b65,
          #6c6b65 10px,
          #AEAEAB 10px,
          #AEAEAB 20px
        ) repeat-y;
        background-size: 30% auto;
        background-position: left bottom

      }


      .owners-block {
        width: 100%;
        height: 100%;
        opacity: 0.2;
        background: repeating-linear-gradient(
          135deg,
          #6c6b65,
          #6c6b65 10px,
          #AEAEAB 10px,
          #AEAEAB 20px
        ) repeat-y;
      }

      .owners-block-second-half {
        opacity: 0.2;
        height: 100%;
        background: repeating-linear-gradient(
          135deg,
          #6c6b65,
          #6c6b65 10px,
          #AEAEAB 10px,
          #AEAEAB 20px
        ) repeat-y;
        background-size: 60% auto;
        background-position: right bottom
      }

      .limit-block-full {
        background: repeating-linear-gradient(
          135deg,
          #add8e6,
          #add8e6 10px,
          #AEAEAB 10px,
          #AEAEAB 20px
        );
        opacity: 0.5;
        width: 100%;
        height: 100%;
      }

      .limit-block-second {
        opacity: 0.5;
        height: 100%;
        width: 100%;
        background: repeating-linear-gradient(
          135deg,
          #add8e6,
          #add8e6 10px,
          #AEAEAB 10px,
          #AEAEAB 20px
        ) no-repeat;
        background-size: 60% auto;
        background-position: right bottom
      }
    `
  ]
})
export class MultiCalendarDateTileComponent implements OnInit, OnChanges {
  data: CalendarShowResponse = {
    first_block: null,
    second_block: null,
    first_blocks: null,
    second_blocks: null,
    booking: null,
    first_booking: null,
    second_booking: null,
    first_bookings: null,
    second_bookings: null,
    bookings: null
  };
  @Input() currentMonth;
  @Input() currentYear;
  @Input() listing: ListingCompact;
  @Input() day: any;
  @Input() isHouseKeeper: boolean;

  BookingEnumHelper = BookingEnumHelper;
  TaskEnumHelper = TaskEnumHelper;
  TaskModelUtil = TaskModelUtil;
  @Output() onPopoverOpen = new EventEmitter<NgbPopover>();

  p: NgbPopover;

  isBlocking = false;
  futureBookingLimit = {
    one_month: "1 Month",
    three_months: "3 Months",
    six_months: "6 Months",
    nine_months: "9 Months",
    twelve_months: "12 Months"
  };
  endDate;
  limit;
  calendarRules: MultiCalendarRule[];
  listingIds: number[] = [];
  futureBookingLimits: any[] = [];
  date;
  private dialogRef: MatDialogRef<any>;

  constructor(private service: StayDuvetService,
              private listingRepo: ListingRepository,
              private userRepo: UserRepository,
              private dialog: MatDialog,
              private router: Router,
              private multiCalendarRepo: MultiCalendarRepository,
              private multicalendarRuleRepo: MultiCalendarRulesRepository) {
  }

  @Input() set calendarData(data) {

    this.data = {
      first_block: null,
      second_block: null,
      first_blocks: null,
      second_blocks: null,
      booking: null,
      first_booking: null,
      second_booking: null,
      first_bookings: null,
      second_bookings: null,
      bookings: null
    };

    if (!data) {
      this.data = data;
    } else {
      if (data.first_bookings) {
        this.data.first_bookings = [];
        data.first_bookings.forEach(id => {
          this.listingRepo.getBooking(id).subscribe(b => this.data.first_bookings.push(b));
        });
      }

      if (data.second_bookings) {
        this.data.second_bookings = [];
        data.second_bookings.forEach(id => {
          this.listingRepo.getBooking(id).subscribe(b => this.data.second_bookings.push(b));
        });
      }

      if (data.bookings) {
        this.data.bookings = [];
        data.bookings.forEach(id => {
          this.listingRepo.getBooking(id).subscribe(b => this.data.bookings.push(b));
        });
      }

      if (data.first_blocks) {
        this.data.first_blocks = [];
        data.first_blocks.forEach(id => {
          this.listingRepo.getBlock(id).subscribe(b => this.data.first_blocks.push(b));
        });
      }

      if (data.second_blocks) {
        this.data.second_blocks = [];
        data.second_blocks.forEach(id => {
          this.listingRepo.getBlock(id).subscribe(b => this.data.second_blocks.push(b));
        });
      }

      if (data.first_block) {
        this.listingRepo.getBlock(data.first_block).subscribe(b => this.data.first_block = b);
      }

      if (data.second_block) {
        this.listingRepo.getBlock(data.second_block).subscribe(b => this.data.second_block = b);
      }

      if (data.first_booking) {
        this.listingRepo.getBooking(data.first_booking).subscribe(b => this.data.first_booking = b);
      }

      if (data.second_booking) {
        this.listingRepo.getBooking(data.second_booking).subscribe(b => this.data.second_booking = b);
      }

      if (data.booking) {
        this.listingRepo.getBooking(data.booking).subscribe(b => this.data.booking = b);
      }
    }
  }

  ngOnInit(): void {
    console.log("onInit sd-calendar-date-tile");
    this.multicalendarRuleRepo.getMultiCalendarRules().pipe(take(1)).subscribe(res => {
      if (res) {
        this.calendarRules = res;
        this.calendarRules.map(rule => {
          if (rule.blocked_property_id === this.listing.id && rule.sync_future_booking_limit === true) {
            if (this.listingIds.indexOf(rule.parent_property_id) === -1) {
              this.listingIds.push(rule.parent_property_id);
            }
          }
          if (rule.parent_property_id === this.listing.id && rule.reverse_rule_applied === true && rule.sync_future_booking_limit === true) {
            if (this.listingIds.indexOf(rule.blocked_property_id) === -1) {
              this.listingIds.push(rule.blocked_property_id);
            }
          }
        });
      }

      if (this.listingIds.indexOf(this.listing.id) === -1) {
        this.listingIds.push(this.listing.id);
      }
      this.listingIds.map(id => {
        this.listingRepo.getCompactListingById(id).pipe(filter(l => !!l), take(1),).subscribe(listing => {
          this.futureBookingLimits.push(listing.future_booking_limit);
        }, (err) => {
          console.log(err);
        });
      });
      this.setupEndDate();

    }, (err) => {
      console.log(err);
    });

    this.date = new Date(this.currentYear, this.currentMonth - 1, this.day);

  }

  getTaskDisplayInfo(task: TaskCompact): { title: string, color: string, tooltipText: string } {
    if (!task) {
      return;
    }

    return TaskEnumHelper.getTaskStatusEnum(task.status);
  }

  getBookingSourceBackground(event) {

    if (!event) {
      return;
    }

    let color = "red";

    switch (event.source) {

      case "airbnb": {
        color = "#f55a5f";
        break;
      }

      case "homeaway": {
        color = "#28924c";
        break;
      }

      case "stayduvet": {
        color = "#12304c";
        break;
      }

      case "plum_guide": {
        color = "#926685";
        break;
      }

      case "hvmi": {
        color = "#ea9866";
        break;
      }

      case "referral": {
        color = "#ccb96b";
        break;
      }

      case "owner_block": {
        color = "grey";
        break;
      }
    }

    if (event.payment_method === "from_platform" && event.total_paid === 0) {
      color = "#f0a340";
    }

    if (event.status === "pending") {
      color = "#0000ff";
    }

    return color;
  }

  navigateToBooking(booking: BookingData) {
    if (booking != null) {
      const url = environment.dashboardURL + "/reservations/" + booking.id;
      window.open(url, "_blank");
    }
    event.preventDefault();
    event.stopPropagation();
  }

  setupEndDate() {
    let months = 0;
    if (this.futureBookingLimits.includes("one_month")) {
      months = 1;
      this.limit = "one_month";
    } else if (this.futureBookingLimits.includes("three_months")) {
      months = 3;
      this.limit = "three_months";
    } else if (this.futureBookingLimits.includes("six_months")) {
      months = 6;
      this.limit = "six_months";
    } else if (this.futureBookingLimits.includes("nine_months")) {
      months = 9;
      this.limit = "nine_months";
    } else if (this.futureBookingLimits.includes("twelve_months")) {
      months = 12;
      this.limit = "twelve_months";
    }
    if (months === 0) {
      this.endDate = null;
    } else {
      this.endDate = getDateObj();
      this.endDate.setHours(0, 0, 0, 0);
      this.endDate.setMonth(this.endDate.getMonth() + months);
      this.endDate.setDate(this.endDate.getDate() + 1);
    }
  }

  getToolTip() {
    return "Future Booking Limit is " + this.futureBookingLimit[this.limit];
  }

  isBlockedFromLimit() {
    if (!this.endDate || !this.date) {
      return false;
    }
    return this.date.getTime() > this.endDate.getTime();
  }

  isBlockedStartFromLimit() {
    if (!this.endDate || !this.date) {
      return false;
    }
    return this.date.getTime() === this.endDate.getTime();
  }

  isSecondBlock() {
    if (!this.data) {
      return;
    }

    return !this.isBlockedStartFromLimit() &&
      !this.isBlockedFromLimit() &&
      !this.isFullBlock() &&
      !this.data.booking &&
      !this.data.second_block &&
      !this.data.second_booking &&
      (this.data.second_blocks || this.data.second_bookings || (this.data.bookings && (this.data.first_block || this.data.first_booking)));
  }

  isFullBlock() {
    if (!this.data) {
      return;
    }

    return !this.isBlockedStartFromLimit() &&
      !this.isBlockedFromLimit() &&
      !this.data.booking &&
      !this.data.second_block &&
      !this.data.second_booking &&
      !this.data.first_block &&
      !this.data.first_booking &&
      (this.data.bookings || ((this.data.first_bookings || this.data.first_blocks) && (this.data.second_bookings || this.data.second_blocks)));
  }

  isFirstBlock() {
    if (!this.data) {
      return;
    }

    return !this.isBlockedFromLimit() &&
      !this.isFullBlock() &&
      !this.data.booking &&
      !this.data.first_block &&
      !this.data.first_booking &&
      (this.data.first_blocks || this.data.first_bookings || (this.data.bookings && (this.data.second_block || this.data.second_booking)));
  }

  getListingTitle(propertyId: number): string {
    return this.listingRepo.getListingTitle(propertyId);
  }

  redirect(bookingId: number) {
    if (bookingId == null) {
      return;
    }
    let url = environment.dashboardURL + "/reservations/" + bookingId;
    if (this.isHouseKeeper) {
      url = environment.dashboardURL + "/reservations/info/" + bookingId;
    }
    window.open(url, "_blank");
    event.preventDefault();
    event.stopPropagation();
  }

  onOpen(data: any, p: NgbPopover) {
    this.onPopoverOpen.emit(p);

    if (!!data && this.isHouseKeeper && data.tasks.length === 0) {
      return;
    }

    p.open({booking: data, p: p});
  }

  onBlockOpen(p: NgbPopover) {
    this.onPopoverOpen.emit(p);
    if (this.getToolTipData().length > 0) {

      let bookings = this.getToolTipData().bookings;

      if (this.isHouseKeeper) {
        bookings = bookings.filter(b => b.tasks && b.tasks.length > 0);
      }

      if (bookings.length > 0) {
        p.open({
          bookings: bookings, blocks: this.getToolTipData().blocks,
          length: bookings.length + this.getToolTipData().blocks.length, p: p
        });
      }
    }
  }

  getToolTipData() {
    if (!this.data) {
      return;
    }

    const bookings = [];
    const blocks = [];

    if (this.data.bookings) {
      bookings.push(...this.data.bookings);
    }

    if (this.data.second_bookings) {
      bookings.push(...this.data.second_bookings);
    }

    if (this.data.second_blocks) {
      blocks.push(...this.data.second_blocks);
    }

    return {
      bookings: bookings,
      blocks: blocks,
      length: bookings.length + blocks.length
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.day) {
      this.setupEndDate();
      this.date = new Date(this.currentYear, this.currentMonth - 1, this.day);

    }
  }

  blockingEvent() {
    if (this.day.isPast || this.isBlockedStartFromLimit() || this.isBlockedFromLimit() || this.isHouseKeeper) {
      return;
    }

    if (this.data
      && (this.data.second_block && this.data.second_booking
        || (this.data.first_block && this.data.first_booking)
        || (this.data.booking && this.data.first_block && this.data.second_block))) {
      return;
    }

    if (this.data
      && !this.data.second_block &&
      (this.data.second_blocks
        || this.data.second_booking
        || this.data.second_bookings
        || this.data.bookings
        || this.data.booking
      )) {
      return;
    }

    const startDate = new Date(this.currentYear, this.currentMonth - 1, this.day);

    const startFullDate = ("0" + startDate.getDate()).slice(-2);
    const startFullMonth = ("0" + (startDate.getMonth() + 1)).slice(-2);
    const startFullYear = startDate.getFullYear();
    const startDateString = startFullYear + "-" + startFullMonth + "-" + startFullDate;

    const endDate = addDays(startDate, 1);
    const endFullDate = ("0" + endDate.getDate()).slice(-2);
    const endFullMonth = ("0" + (endDate.getMonth() + 1)).slice(-2);
    const endFullYear = endDate.getFullYear();
    const endDateString = endFullYear + "-" + endFullMonth + "-" + endFullDate;

    console.log("[Blocking event]", this.data, startDate, endDate);
    if (!this.data || !this.data.second_block) {
      const data = {
        startDate: getDateObj(startDateString),
        endDate: getDateObj(endDateString),
        reason: "Applied From Calendar",
        listingId: this.listing.id
      };
      this.dialogRef = this.dialog.open(ListingOwnerBlockPopupComponent, {
        data: data
      });
      this.dialogRef.componentInstance.onSuccess.subscribe(() => {
        setTimeout(() => this.refreshCalendar(), 1000);
      });
      this.dialogRef.updateSize("100%");
    } else {
      const data = {
        startDate: getDateObj(startDateString),
        endDate: getDateObj(endDateString),
        listingId: this.listing.id
      };
      this.isBlocking = true;
      this.dialogRef = this.dialog.open(ListingOwnerBlockDeletePopupComponent, {
        data: data
      });
      this.dialogRef.componentInstance.onSuccess.subscribe((response) => {
        setTimeout(() => this.refreshCalendar(), 1000);
        if (response) {
          const bookingId = JSON.parse(response).booking_id;
          this.router.navigate(["/reservations/", bookingId]);
        }
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.isBlocking = false;
      });
      this.dialogRef.updateSize("100%");
    }
  }

  refreshCalendar() {
    this.multiCalendarRepo.refreshButtonClicked();
  }

  redirectTask(id: number) {
    if (!id) {
      return;
    }
    const url = environment.dashboardURL + "/tasks/" + id;
    window.open(url, "_blank");
    event.preventDefault();
    event.stopPropagation();
  }

  getTaskEmployeeName(employeeId: number) {
    if (!employeeId) {
      return;
    }
    return this.userRepo.getEmployeeName(employeeId);
  }

}
