import { GoalSetPercentagesDraft } from "../../../../models/new/drafts/goal-set-percentages.modal";
import { Action } from "../../../action";

export enum GoalSetPercentagesDraftActionTypes {
  INDEX_REQUEST = "[Goal Set Percentages Drafts NEW] Index Request",
  INDEX_SUCCESS = "[Goal Set Percentages Drafts NEW] Index Success",

  CREATE_SUCCESS = "[Goal Set Percentages Drafts NEW] Create Success",

  UPDATE_SUCCESS = "[Goal Set Percentages Drafts NEW] Update Success",

  DELETE_SUCCESS = "[Goal Set Percentages Drafts NEW] Delete Success"
}

export class GoalSetPercentagesDraftIndexRequest implements Action {
  readonly type = GoalSetPercentagesDraftActionTypes.INDEX_REQUEST;
}

export class GoalSetPercentagesDraftIndexSuccess implements Action {
  readonly type = GoalSetPercentagesDraftActionTypes.INDEX_SUCCESS;

  constructor(public payload: GoalSetPercentagesDraft[]) {
  }
}

export class GoalSetPercentagesDraftCreateSuccess implements Action {
  readonly type = GoalSetPercentagesDraftActionTypes.CREATE_SUCCESS;

  constructor(public payload: GoalSetPercentagesDraft) {
  }
}

export class GoalSetPercentagesDraftUpdateSuccess implements Action {
  readonly type = GoalSetPercentagesDraftActionTypes.UPDATE_SUCCESS;

  constructor(public payload: GoalSetPercentagesDraft) {
  }
}

export class GoalSetPercentagesDraftDeleteSuccess implements Action {
  readonly type = GoalSetPercentagesDraftActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
