/**
 * Created by aditya on 24/8/17.
 */
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subject } from "rxjs";

import { AccountType, ContactEnumHelper } from "../../../enums/contact.enum";
import { BankInfoModel } from "../../../models/bank-info.model";
import { RoutingRule } from "../../../models/new/listing/routing-rule";
import { UserFull } from "../../../models/new/user/user-full.model";
import { State } from "../../../reducers";
import { ContactRepository } from "../../../repository/contact.repository";
import { ListingRepository } from "../../../repository/listing.repository";
import { RoutingRulesRepository } from "../../../repository/routing-rules.repository";
import { UserRepository } from "../../../repository/user-repository";
import { CommonUtil } from "../../../utils/common.util";
import { AddAccountPopupComponent } from "../../settings/components/add-account.popup";

import { CreateRoutingRuleComponent } from "./popups/create-routing-rule";

@Component({
  selector: "sd-contact-bank-info",
  template: `
    <div fxLayoutAlign="space-between center" style="margin-top:50px;">
      <span style="font-size: large;font-weight: bold;">Bank Accounts:</span>
      <button mat-raised-button color="accent" (click)="openForm('create')">Add Account</button>
    </div>
    <div style="margin-top:20px;" fxLayoutGap="20px" fxLayout="column">
      <div *ngFor="let item of accounts; let i = index;">
        <div fxLayout="column" fxLayoutGap="20px" *ngIf="!isDeleting[item.id]">
          <mat-card fxLayout="column" fxLayoutGap="20px" class="background">

            <div fxLayout="row" fxLayoutGap="10px" fxFlexAlign="end">
              <button mat-icon-button color="accent" (click)="openForm('update',item)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="deleteForm(item.id)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <div fxLayout="row" fxLayoutGap="30px">
              <span class="text">Bank Name:</span>
              <span>{{item.bank_name}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="30px">
              <span class="text">Account Name:</span>
              <span>{{item.account_name}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="30px">
              <span class="text">Account Type:</span>
              <span>{{ContactEnumHelper.getAccountType(item.account_type)}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="30px">
              <span class="text">Routing Number:</span>
              <span>{{item.routing_number}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="30px">
              <span class="text">Account Number:</span>
              <span>{{item.account_number}}</span>
            </div>

            <hr *ngIf="showRoutingRules">

            <div fxLayout="column" *ngIf="showRoutingRules">


              <div fxLayout="row" fxFlexAlign="flex-start center">
                <span class="space-filler"></span>
                <button mat-raised-button color="accent" (click)="addRule(item)">Add Rule</button>
              </div>


              <div *ngFor="let rule of item.routingRules.data" fxLayout="row" fxLayoutGap="20px"
                   fxLayoutAlign="space-between center">


                 <span fxLayout="row" style="width: 35%" (click)="openRuleDetails(rule, item)" class="c-pointer">
                  Listing:&nbsp;<span>{{getListingTitle(rule.property_id)}}</span>
                </span>
                <span fxLayout="row" style="width: 10%" (click)="openRuleDetails(rule, item)" class="c-pointer">
                 Percentage:
              <span>{{rule.percentage}}%</span>
            </span>
                <mat-spinner *ngIf="isRuleDeleting[rule.id]" [color]="'accent'" [diameter]="30"
                             [strokeWidth]="4"></mat-spinner>
                <button *ngIf="!isRuleDeleting[rule.id]" mat-icon-button (click)="deleteRule(rule, item);">
                  <mat-icon style="color: red" matTooltip="Delete Rule">
                    cancel
                  </mat-icon>
                </button>


              </div>

              <div *ngIf="item.routingRules.data.length === 0" fxLayout="row" fxLayoutAlign="center center">
                <span> No Routing Rules Found</span>
              </div>


            </div>

          </mat-card>
        </div>
        <mat-spinner [diameter]="50" [strokeWidth]="6" *ngIf="isDeleting[item.id]" style="margin-left:50%"
                     color="accent"></mat-spinner>
      </div>
    </div>
  `,
  styles: [`
    .background {
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    .text {
      font-weight: bold;
      font-size: medium;
    }

    hr {
      border: none;
      height: 2px;
      color: darkgray;
      background-color: darkgray;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
    }

  `]

})

export class ContactBankInfoComponent implements OnInit, OnDestroy {

  accountTypes = CommonUtil.getPropsOfEnum<AccountType>(AccountType);
  ContactEnumHelper = ContactEnumHelper;
  form: FormGroup;
  bankName: FormControl;
  routingNumber: FormControl;
  accountNumber: FormControl;
  nameOnAccount: FormControl;
  typeOfAccount: FormControl;
  accountForm: FormGroup;
  accounts: BankInfoModel[] = [];
  @Input() showRoutingRules = true;
  isUpdating = false;
  showInput = false;
  dialogRef: MatDialogRef<any>;
  index: number;
  isDeleting: { [id: number]: boolean } = {};
  isRuleDeleting: { [id: number]: boolean } = {};
  private destroyed$ = new Subject();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private store: Store<State>,
              private contactRepo: ContactRepository,
              private rulesRepo: RoutingRulesRepository,
              private listingRepo: ListingRepository,
              private userRepo: UserRepository,
              private formBuilder: FormBuilder,
              private dialog: MatDialog) {

  }

  private _contact: UserFull;

  get contact(): UserFull {
    return this._contact;
  }

  @Input() set contact(contact: UserFull) {
    this._contact = contact;

    if (this.contact.bankDetails) {
      this.accounts = this.contact.bankDetails.data;
      console.log(this.accounts);
    }
  }

  ngOnInit() {

    // const route$ = this.route.params;
    // route$.takeUntil(this.destroyed$).subscribe(params => {
    //   const contactId = +params["id"];
    //
    //   const contact$ = this.contactRepo.getFullContactById(contactId);
    //
    //   contact$.takeUntil(route$.skip(1)).subscribe((contact) => {
    //     this.contact = contact;
    //     console.log(this.contact);
    //     if (this.contact.bankDetails) {
    //       this.accounts = contact.bankDetails.data;
    //       console.log(this.accounts);
    //     }
    //   });
    // });
  }

  deleteContact() {
    console.log(this.contact);
  }

  userUpdate() {
    console.log(this.accounts);
  }

  openForm(action: string, item?) {
    this.dialogRef = this.dialog.open(AddAccountPopupComponent, {
      data: {
        account: item,
        action: action,
        contactId: this.contact.id
      }
    });
    this.dialogRef.updateSize("100%", "70%");
  }

  deleteForm(id: number) {
    this.isDeleting[id] = true;
    this.contactRepo.deleteBankAccount(id, this.contact.id).subscribe(res => {
      this.isDeleting[id] = false;
    }, (error) => {
      this.isDeleting[id] = false;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  addRule(bankInfo: BankInfoModel) {
    this.dialogRef = this.dialog.open(CreateRoutingRuleComponent);

    const instance = this.dialogRef.componentInstance;
    instance.openForEdit = false;
    instance.bankInfo = bankInfo;
    instance.contact = this.contact;
    instance.createSuccess.subscribe(() => {
      this.refreshContact(this.contact.id);
    });

    this.dialogRef.updateSize("100%");

  }

  openRuleDetails(rule: RoutingRule, bankInfo: BankInfoModel) {
    this.dialogRef = this.dialog.open(CreateRoutingRuleComponent);
    const instance = this.dialogRef.componentInstance;
    instance.openForEdit = true;
    instance.routingRule = rule;
    instance.bankInfo = bankInfo;
    instance.contact = this.contact;
    instance.updateSuccess.subscribe(() => {
      this.refreshContact(this.contact.id);
    });

    this.dialogRef.updateSize("100%");

  }

  deleteRule(rule: RoutingRule, bankInfo: BankInfoModel) {
    this.isDeleting[rule.id] = true;
    this.rulesRepo.deleteRule(rule.id, rule.property_id)
      .subscribe(res => {

        const index = bankInfo.routingRules.data.indexOf(rule);

        if (index !== -1) {
          _.remove(bankInfo.routingRules.data, rule);
        }

        delete this.isRuleDeleting[rule.id];
      }, err => {
        delete this.isRuleDeleting[rule.id];
      });
  }

  getListingTitle(id: number): string {
    return this.listingRepo.getListingTitle(id);
  }

  refreshContact(contactId: number) {
    this.contactRepo.getFullContactById(contactId, true).subscribe();
  }

  refreshUser() {
    this.userRepo.getUser(true)
      .subscribe();
  }

}
