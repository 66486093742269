import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Quote } from "../models/quote";

import { ApiService } from "./api.service";

@Injectable()
export class QuoteService {

  constructor(private apiService: ApiService) {
  }


  getQuotes(): Observable<Quote[]> {
    return this.apiService.get<{ data: Quote[] }>("/admin/quotes", true).pipe(map((res) => res.data));
  }

  createQuote(data: any): Observable<Quote> {
    return this.apiService.post<{ data: Quote }>("/admin/quotes", true, data).pipe(map((res) => res.data));
  }

  getQuote(listingId: number, data): Observable<Quote> {
    return this.apiService.get<Quote>("/admin/properties/" + listingId + "/quote", true, data);
  }
}
