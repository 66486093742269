import {Action} from "../action";
import {Affiliate} from "../../models/affiliate";

export enum AffiliateActionTypes {
  INDEX_REQUEST = "[Affiliate NEW] Index Request",
  INDEX_SUCCESS = "[Affiliate NEW] Index Success",

  CREATE_SUCCESS = "[Affiliate NEW] Create Success",
  UPDATE_SUCCESS = "[Affiliate NEW] Update Success",
  DELETE_SUCCESS = "[Affiliate NEW] Delete Success",
}

export class AffiliateIndexRequest implements Action {
  readonly type = AffiliateActionTypes.INDEX_REQUEST;
}

export class AffiliateIndexSuccess implements Action {
  readonly type = AffiliateActionTypes.INDEX_SUCCESS;

  constructor(public payload: Affiliate[]) {
  }
}

export class AffiliateCreateSuccess implements Action {
  readonly type = AffiliateActionTypes.CREATE_SUCCESS;

  constructor(public payload: Affiliate) {
  }
}

export class AffiliateUpdateSuccess implements Action {
  readonly type = AffiliateActionTypes.UPDATE_SUCCESS;

  constructor(public payload: Affiliate) {
  }
}

export class AffiliateDeleteSuccess implements Action {
  readonly type = AffiliateActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
