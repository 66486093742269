import { Component, Input, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";

import { TaskRepository } from "../../../../repository/task.repository";

@Component({
  selector: "sd-download-task-report",
  template: `
    <sd-modal-popup-layout title="Download Task Report">
      <div class="box">
        <div class="width-30" fxLayoutGap="20px">
          <sd-select placeholder="Select Format" [control]="format" [options]="formats"></sd-select>
          <mat-checkbox [formControl]="email">Send Email</mat-checkbox>
        </div>
        <button *ngIf="!isDownloading" mat-raised-button color="accent" (click)="download()">Download</button>
        <mat-spinner *ngIf="isDownloading" [diameter]="30" [strokeWidth]="4"></mat-spinner>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`
    .box {
      margin-top: -60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .width-30 {
      width: 30%;
      display: flex;
      align-items: center;
    }
  `]
})
export class DownloadReportPopupComponent implements OnInit {
  @Input() data;
  isDownloading = false;
  format = new FormControl(null, [Validators.required]);
  email = new FormControl(false, [Validators.required]);
  formats: {title: string, value: any}[] = [
    { title: "xlsx", value: "xlsx" },
    { title: "xls", value: "xls" },
    { title: "csv", value: "csv" }
  ];

  constructor(private taskRepo: TaskRepository,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    const months = moment(this.data.end).diff(this.data.start, "months");
    if (months >= 2) {
      this.email.setValue(true);
    }
  }

  download() {
    if (!this.format.valid) {
      this.format.markAllAsTouched();
      return;
    }
    this.isDownloading = true;
    this.data.format = this.format.value;
    this.data.send_email = this.email.value;

    for (const key in this.data) {
      if (!this.data[key] || this.data[key].length === 0) {
        delete this.data[key];
      }
    }

    this.taskRepo.downloadReport(this.data).subscribe(res => {
      this.dialog.closeAll();
      this.isDownloading = false;
    }, err => this.isDownloading = false);
  }
}
