import { AfterViewInit, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { takeWhile } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { ListingAutoResponsePopupComponent } from "./listing-auto-response-popup";
import { AutoResponse } from "../../../models/auto-response";
import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { AutoResponseRepository } from "../../../repository/auto-response.repository";
import { ListingRepository } from "../../../repository/listing.repository";
import { AppService } from "../../../services/app.service";

@Component({
  selector: "sd-settings-auto-response",
  template: `
    <div fxLayout="column" fxLayoutAlign="flex-start stretch" fxLayoutGap="15px" style="margin-top: 10px;"
         class="bottom-padding">

      <div fxLayoutAlign="flex-start center" *ngIf="!listingId">
        <button mat-raised-button color="primary" (click)="openTools()">
          <mat-icon>arrow_back</mat-icon>
          Back
        </button>
      </div>

      <div id="content" fxLayout="column" fxLayoutAlign="flex-start stretch" fxLayoutGap="10px">

        <div fxLayoutAlign="space-between flex-start" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="space-between center"
             fxLayoutGap.lt-sm="5px">
          <div fxLayout="column" fxLayoutAlign="center flex-start" fxLayoutAlign.lt-sm="space-between center"
               fxLayoutGap="10px" class="para">
            <span class="heading">Auto Responses</span>
            <span class="hint responsiveAlign">Set predefined messages if you are'nt able to respond within a certain amount of time..</span>
          </div>
          <div>
            <button mat-icon-button (click)="refresh()">
              <mat-icon>autorenew</mat-icon>
            </button>
            <button mat-raised-button color="accent" (click)="addAutoResponse()">
              Add New Auto Response
            </button>
          </div>
        </div>

        <hr>

        <div fxLayoutAlign="center center">

          <mat-spinner color="accent" *ngIf="isLoading" [diameter]="60" [strokeWidth]="5"></mat-spinner>
          <div fxLayout="column" *ngIf="isLoaded" fxLayoutGap="10px" fxFlex="100%">
            <div fxLayout="column" fxLayoutGap="20px">

              <div fxLayout="row " fxLayoutGap="10px" fxLayoutAlign="flex-start center">
                <div class="vertical-line"></div>
                <h3>For Confirmed Guests</h3>
              </div>

              <h4 style="text-align: center; font-size: small" *ngIf="confirmedGuestResponses.length === 0">
                No Auto Responses Found For Confirmed Guests
              </h4>

              <div *ngFor="let item of confirmedGuestResponses">

                <mat-card class="padding" (click)="editResponse(item)" style="cursor: pointer">
                  <mat-card-content fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5px"
                                    fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign.lt-sm="space-between center"
                         fxLayoutAlign="center flex-start" class="max-content">
                      <h3>{{item.title}}</h3>

                      <div class="content">
                        {{item.message}}
                      </div>
                    </div>
                    <span class="mat-button"
                          style="background-color: darkorange;text-align: center; color: white">
                    {{getText(item)}}
                  </span>
                  </mat-card-content>
                </mat-card>

              </div>

            </div>

            <div fxLayout="column" fxLayoutGap="20px">

              <div fxLayout="row " fxLayoutGap="10px" fxLayoutAlign="flex-start center">
                <div class="vertical-line"></div>
                <h3>For Non-Confirmed Guests</h3>
              </div>

              <h4 style="text-align: center; font-size: small" *ngIf="nonConfirmedGuestResponses.length === 0">
                No Auto Responses Found For Non-Confirmed Guests
              </h4>

              <div *ngFor="let item of nonConfirmedGuestResponses">

                <mat-card class="padding" (click)="editResponse(item)" style="cursor: pointer">
                  <mat-card-content fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5px"
                                    fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign.lt-sm="space-between center"
                         fxLayoutAlign="center flex-start" class="max-content">
                      <h3>{{item.title}}</h3>

                      <div class="content">
                        {{item.message}}
                      </div>
                    </div>
                    <span class="mat-button"
                          style="background-color: darkorange;text-align: center; color: white">
                    {{getText(item.property_ids)}}
                  </span>
                  </mat-card-content>
                </mat-card>

              </div>

            </div>
          </div>
        </div>

      </div>


    </div>
  `,
  styles: [
      `
      .padding {
        padding: -10px -10px -10px -10px;
        background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
      }

      .content {
        font-size: 10px;
      }

      .heading {
        font-weight: bolder;
        font-size: 22px;
      }

      .content {
        font-size: 12px;
        line-height: 130%;
      }

      h3 {
        font-weight: bolder !important;
        letter-spacing: 1px !important;
        font-size: 20px !important;
        font-family: 'Montserrat', sans-serif !important;
      }

      .mat-card {
        border: 1px solid lightgrey !important;
        box-shadow: none !important;
      }

      .hint {
        font-size: 12px;
        margin-left: 2px;
      }

      #spinner {
        position: fixed;
        top: 45%;
        right: 40%
      }

      .max-content {
        max-width: 80%;
      }

      div.vertical-line {
        width: 5px; /* Line width */
        background-color: green; /* Line color */
        height: 20px; /* Override in-line if you want specific height. */
        float: left;
        /* Causes the line to float to left of content.
               You can instead use position:absolute or display:inline-block
               if this fits better with your design */
      }

      @media only screen and (max-width: 600px) {
        .responsiveAlign {
          text-align: center;
        }
      }

    `
  ]
})
export class SettingsAutoResponseComponent implements OnInit, AfterViewInit, OnDestroy {

  isAlive = true;
  isLoading = false;
  isLoaded = false;
  isDeleting = false;
  listings: ListingCompact[] = [];

  @Input() confirmedGuestResponses: AutoResponse[] = [];
  @Input() nonConfirmedGuestResponses: AutoResponse[] = [];
  @Input() listingId;
  private dialogRef: MatDialogRef<any>;

  constructor(private router: Router, private route: ActivatedRoute, private autoResponseRepo: AutoResponseRepository,
              private dialog: MatDialog, private listingRepo: ListingRepository, private appService: AppService) {
  }

  ngOnInit(): void {
    console.log("onInit sd-listing-channel-tab");

    if (!this.listingId) {
      this.setUpAutoResponses();
    } else {
      this.isLoaded = true;
      this.isLoading = false;
    }

    this.listingRepo.getAcceptedListings().pipe(takeWhile(() => this.isAlive)).subscribe((listings) => {
      this.listings = listings;
    });
  }

  ngAfterViewInit() {
    this.appService.changeAppTitle("Settings - Auto-Response");
  }

  setUpAutoResponses() {

    this.autoResponseRepo.getIsAutoResponseLoading().subscribe(l => this.isLoading = l);
    this.autoResponseRepo.getIsAutoResponseLoaded().subscribe(l => this.isLoaded = l);
    this.autoResponseRepo.getAllAutoResponses(false, this.listingId).pipe(takeWhile(() => this.isAlive)).subscribe((responses) => {

      if (responses) {
        this.confirmedGuestResponses = responses.filter(response => response.type.includes("confirmed"));
        this.nonConfirmedGuestResponses = responses.filter(response => !response.type.includes("confirmed"));

      }
    });

  }

  addAutoResponse() {
    this.dialogRef = this.dialog.open(ListingAutoResponsePopupComponent);
    const instance = this.dialogRef.componentInstance;
    instance.popUpTitle = "Add New Auto Response";
    instance.listings = this.listings;
    instance.listingId = this.listingId;
    this.dialogRef.updateSize("100%", "100%");
  }

  editResponse(response: AutoResponse) {

    this.dialogRef = this.dialog.open(ListingAutoResponsePopupComponent);
    const instance = this.dialogRef.componentInstance;
    instance.responseId = response.id;
    instance.title = response.title;
    instance.message = response.message;
    instance.type = response.type;
    instance.offset = response.offset;
    instance.rule = response.rule;
    instance.popUpTitle = "Edit Auto Response";
    instance.isEditType = true;
    instance.listingIds = response.property_ids;
    instance.listings = this.listings;
    this.dialogRef.updateSize("100%", "100%");
  }

  getText(response: AutoResponse) {

    const Ids = response.property_ids || [];
    const type = response.rule;
    const listingIds = this.listings.map(listing => listing.id);

    if (type === "include") {
      if (Ids.length === 0) {
        return "On No Listings";
      }

      if (listingIds.length === Ids.length) {
        return "On All Listings";
      }

      if (listingIds.length > Ids.length) {
        return "On Multiple Listings";
      }
    } else {
      if (Ids.length == 0) {
        return "On All Listings";
      }

      if (listingIds.length === Ids.length) {
        return "On No Listings";
      }

      if (listingIds.length > Ids.length) {
        return "On Multiple Listings";
      }
    }

  }

  getListingsTitle(ids: number[]) {
    if (isNullOrUndefined(ids)) {
      return;
    }
    return this.listings.filter(listing => ids.includes(listing.id)).map(listing => listing.title).join(", ");
  }

  openTools() {
    this.router.navigate(["/settings/tools"]);
  }

  refresh() {
    this.autoResponseRepo.getAllAutoResponses(true, this.listingId).subscribe((responses) => {
        console.log(responses);
        this.confirmedGuestResponses = responses.filter(response => response.type.includes("confirmed"));
        this.nonConfirmedGuestResponses = responses.filter(response => !response.type.includes("confirmed"));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
