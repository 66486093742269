import { MultiCalendarRule } from "../../../models/new/multi-calendar-rule";
import { Action } from "../../action";

export enum MultiCalendarRuleActionTypes {
  INDEX_REQUEST = "[MULTI_CALENDAR_RULE] Index  Request",
  INDEX_SUCCESS = "[MULTI_CALENDAR_RULE] Index  Success",

  CREATE_REQUEST = "[MULTI_CALENDAR_RULE] Create  Request",
  CREATE_SUCCESS = "[MULTI_CALENDAR_RULE] Create  Success",

  UPDATE_REQUEST = "[MULTI_CALENDAR_RULE] Update  Request",
  UPDATE_SUCCESS = "[MULTI_CALENDAR_RULE] Update  Success",

  DELETE_REQUEST = "[MULTI_CALENDAR_RULE] Delete  Request",
  DELETE_SUCCESS = "[MULTI_CALENDAR_RULE] Delete  Success",
}

export class MultiCalendarRuleIndexRequest implements Action {
  readonly type = MultiCalendarRuleActionTypes.INDEX_REQUEST;
}

export class MultiCalendarRuleIndexSuccess implements Action {
  readonly type = MultiCalendarRuleActionTypes.INDEX_SUCCESS;

  constructor(public payload: MultiCalendarRule[]) {
  }
}

export class MultiCalendarRuleCreateSuccess implements Action {
  readonly type = MultiCalendarRuleActionTypes.CREATE_SUCCESS;

  constructor(public payload: MultiCalendarRule) {
  }
}

export class MultiCalendarRuleDeleteSuccess implements Action {
  readonly type = MultiCalendarRuleActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}

export class MultiCalendarRuleUpdateSuccess implements Action {
  readonly type = MultiCalendarRuleActionTypes.UPDATE_SUCCESS;

  constructor(public payload: MultiCalendarRule) {
  }
}

