import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import Quill from "quill";
import "quill-mention";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { State } from "../../../reducers";
import { AutoResponseRepository } from "../../../repository/auto-response.repository";
import { getAllAlertTypes, getAutoResponseTypes } from "../../../utils/utils";
import { QuillUtils } from "../../../utils/quill.util";

@Component({
  selector: "sd-listing-auto-response-popup",
  template: `
    <sd-modal-popup-layout title="{{popUpTitle}}">

      <form fxLayout="column" [formGroup]="formGroup" (ngSubmit)="formGroup.valid && saveButtonCLicked()">
        <mat-form-field [color]="'accent'" class="detailField">
          <input type="text" matInput color="accent" formControlName="title" placeholder="Title">
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="5px" fxLayoutAlign="space-between center"
             class="detailField">

          <mat-form-field [color]="'accent'" class="width-35">
            <mat-select
              matInput
              style="width: 100%"
              formControlName="type"
              placeholder="Type">
              <mat-option *ngFor="let type of autoResponseTypes" [value]="type.slug">{{type.title}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field [color]="'accent'" class="width-20">
            <input type="number" min="0" matInput color="accent" formControlName="offset"
                   placeholder="Delay (In Minutes)">
            <mat-error>This field is required.</mat-error>
          </mat-form-field>
          <mat-form-field [color]="'accent'" style="width: 300px;">
            <mat-select
              color="accent"
              floatPlaceholder="never"
              formControlName="rule"
              placeholder="Apply For">
              <mat-option *ngFor="let alertType of getAllAlertTypes" [value]="alertType.slug">
                {{alertType.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field [color]="'accent'" class="width-40">
            <mat-select multiple placeholder="Select Listings"
                        (openedChange)="!$event&&onMatSelectClose()"
                        floatPlaceholder="never" formControlName='property_ids'>


              <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                <input matInput placeholder="Search Listing" [formControl]="listingFilter">
              </mat-form-field>

              <div fxLayout="column">
                <button class="select-button" mat-button (click)="onSelectAll()">Select All</button>
                <button class="select-button" mat-button (click)="onSelectNone()">Select None</button>
              </div>

              <mat-option *ngFor="let listing of filteredListings" [value]="listing.id">
                {{ listing.title }}
              </mat-option>

            </mat-select>
          </mat-form-field>

        </div>


        <div class="detailDiv">
          <div id="descriptionMessage" style="width: 100% ; height: 100%"></div>
        </div>
        <span style="color:red; margin-top: 2px;" *ngIf="showError">This field is required.</span>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" style="margin: 10px">
          <mat-spinner *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <button mat-raised-button color="warn" *ngIf="isEditType" [disabled]="isSaving" (click)="deleteMessage()">
            Delete
          </button>
          <button mat-raised-button color="accent" [disabled]="isSaving || showError">{{buttonText}}</button>
        </div>
      </form>


    </sd-modal-popup-layout>
  `,
  styles: [`

    .title {
      font-weight: bolder;
      font-size: 22px;
      padding-left: 10px;
      padding-right: 10px;
      height: 30px;
      width: 100%;
    }

    .detailField {
      padding-left: 10px;
      padding-right: 10px;
      width: 95%;
    }

    .detailDiv {
      content-editable: true;
      font-size: 18px;
      height: 400px;
      width: 100%;
      margin-top: 10px;
      border-style: solid;
      border-width: 0.1px;
      padding: 5px;
      border-color: #c8c8c8;
    }

    textarea {
      resize: vertical;
    }

    mat-error {
      font-size: x-small;
    }

    mat-spinner {
      width: 30px;
      height: 30px;
    }

    .select-button {
      padding: 6px;
      text-align: left;
      font-size: 17px;
      padding-left: 10px;
      font-weight: bolder;
    }

    .width-35 {
      width: 35%;
    }

    .width-20 {
      width: 20%;
    }

    .width-40 {
      width: 40%;
    }

    @media only screen and (max-width: 600px) {
      .responsivewidth {
        width: 95%;
      }

      [class*="width-"] {
        width: 100%;
      }
    }



  `]
})

export class ListingAutoResponsePopupComponent implements OnInit, OnDestroy {

  @Input() popUpTitle;
  @Input() listingIds;
  @Input() responseId;
  @Input() title;
  @Input() message;
  @Input() type;
  @Input() offset;
  @Input() isEditType = false;
  @Input() listingId;
  @Input() rule;

  quillBody: Quill;
  titleVariables = QuillUtils.VARIABLES;
  showError = true;

  buttonText;
  alertType = "include";
  getAllAlertTypes;

  formGroup: FormGroup;
  responseTitle: FormControl;
  responseMessage: FormControl;
  formListingIds: FormControl;
  responseType: FormControl;
  ruleType: FormControl;
  responseOffset: FormControl;
  listingFilter: FormControl = new FormControl();
  filteredListings: ListingCompact[] = [];
  @Input() listings: ListingCompact[] = [];

  autoResponseTypes = getAutoResponseTypes();

  isSaving = false;
  isAlive = true;

  constructor(private autoResponseRepo: AutoResponseRepository,
              private dialog: MatDialog,
              private store: Store<State>) {

    this.getAllAlertTypes = getAllAlertTypes();
    this.responseTitle = new FormControl(null, [Validators.required]);
    this.responseMessage = new FormControl(null, [Validators.required]);
    this.responseOffset = new FormControl(null, [Validators.required]);
    this.responseType = new FormControl(null, [Validators.required]);
    this.formListingIds = new FormControl([], []);
    this.ruleType = new FormControl("include", [Validators.required]);

    this.formGroup = new FormGroup({
      title: this.responseTitle,
      message: this.responseMessage,
      offset: this.responseOffset,
      type: this.responseType,
      property_ids: this.formListingIds,
      rule: this.ruleType,

    });
  }

  ngOnInit() {

    if (this.isEditType) {
      this.buttonText = "Update";
      this.responseTitle.setValue(this.title);
      this.responseMessage.setValue(this.message);
      this.responseType.setValue(this.type);
      this.responseOffset.setValue(this.offset);
      this.formListingIds.setValue(this.listingIds);
      this.ruleType.setValue(this.rule);

    } else {
      this.buttonText = "Add";
      if (this.listingId) {
        this.formListingIds.setValue([this.listingId]);
      }
    }

    this.setupQuill();
    this.filteredListings = this.listings;

    this.listingFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged(),).subscribe((value) => {

      if (value) {
        this.filteredListings = this.listings.filter(l => l.title.toLowerCase().includes(value));
      } else {
        this.filteredListings = this.listings;
      }

      if (this.filteredListings.length === 0) {
        this.filteredListings = this.listings;
      }

    });

  }

  onMatSelectClose() {
    this.filteredListings = this.listings;
  }

  saveButtonCLicked() {
    this.isSaving = true;

    const data = {
      ...this.formGroup.value,
      message: QuillUtils.removeExtraLines(this.responseMessage.value)
    };

    if (this.isEditType) {
      this.autoResponseRepo.updateAutoResponse(this.responseId, data).subscribe((item) => {
        this.dialog.closeAll();
        this.isSaving = false;
      }, () => {
        this.isSaving = false;
      });
    } else {
      this.autoResponseRepo.createAutoResponse(data).subscribe((item) => {
        this.dialog.closeAll();
      }, () => {
        this.isSaving = false;
      });
    }

  }

  onSelectAll() {
    this.formListingIds.setValue(this.listings.map(listing => listing.id));
  }

  onSelectNone() {
    this.formListingIds.setValue([]);
  }

  deleteMessage() {
    this.isSaving = true;
    this.autoResponseRepo.deleteAutoResponse(this.responseId).subscribe(() => {
      this.isSaving = false;
      this.dialog.closeAll();
    }, () => {
      this.isSaving = false;
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  setupQuill() {
    const values = this.titleVariables;
    this.quillBody = new Quill("#descriptionMessage", {
      theme: "bubble",
      modules: {
        mention: {
          allowedChars: /^[_A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["{"],
          isolatedCharacter: false,
          source: (searchTerm, renderList, mentionChar) => {

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(values[i]);
                }
              }
              renderList(matches, searchTerm);
            }
          },
          renderItem: (item, searchTerm) => item.value.replace("{", "{{"),
          dataAttributes: ["value"]
        },
      },
    });

    if (this.responseMessage.value) {
      this.quillBody.setText(this.responseMessage.value);
      this.quillBody.root.innerHTML = QuillUtils.replaceMentionWithClass(this.quillBody.root.innerHTML);
    }

    this.quillBody.on("text-change", () => {
      this.responseMessage.setValue(this.quillBody.root.innerText);
      this.showError = this.responseMessage.value.trim() === "";
    });
  }
}
