import { createSelector } from "reselect";

import { Action } from "../../../actions/action";
import {
  NOTES_ADD_SUCCESS,
  NOTES_DELETE_SUCCESS,
  NOTES_FETCH_REQUEST,
  NOTES_FETCH_SUCCESS
} from "../../../actions/new/inbox/note";
import { Note } from "../../../models/new/inbox/note";
import Utils from "../../../utils/utils";

export interface State {
  ids: number[];
  entities: {
    [id: number]: Note;
  };
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  ids: [],
  entities: {},
  loading: false,
  loaded: false
};

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case NOTES_FETCH_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case NOTES_FETCH_SUCCESS: {
      const notes = action.payload;
      const noteIds = notes.map(note => note.id);
      const entities = Utils.normalize(notes);
      return Object.assign({}, state, { ids: noteIds, entities: entities, loaded: true, loading: false });
    }
    case NOTES_ADD_SUCCESS: {
      const note = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [note.id]: note
        },
        ids: [
          ...state.ids,
          note.id
        ]
      };
    }
    case NOTES_DELETE_SUCCESS: {
      const id = action.payload.id;
      const newIds = state.ids.filter((elem) => elem !== id);
      const newEntities = Utils.removeKey(state.entities, id);
      return {
        ...state,
        ids: newIds,
        entities: newEntities
      };
    }
    default: {
      return state;
    }
  }
}

export const getIsLoading = (state: State) => state.loading;
export const getIsLoaded = (state: State) => state.loaded;
export const getEntities = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => {
  return ids.map(id => entities[id]);
});
