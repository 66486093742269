import { Component } from "@angular/core";

@Component({
  selector: "sd-not-found",
  template: `
    <h1>PAGE NOT FOUND</h1>
  `,
  styles: []
})

export class NotFoundComponent {

}
