import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { ProspectResponse } from "../models/new/prospect-response";
import { Prospect } from "../models/prospect";
import { map } from "rxjs/operators";

@Injectable()
export class ProspectService {

  constructor(private apiService: ApiService) {
  }

  getTodayProspects(page: number): Observable<ProspectResponse> {
    const offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.apiService.get<ProspectResponse>("/admin/prospects/today?page=" + page, true, {
      timezone: offset
    });
  }

  getUpcomingProspects(page: number): Observable<ProspectResponse> {
    const offset = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this.apiService.get<ProspectResponse>("/admin/prospects/upcoming?page=" + page, true, {
      timezone: offset
    });
  }

  getExpiredProspects(page: number): Observable<ProspectResponse> {
    const offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.apiService.get<ProspectResponse>("/admin/prospects/expired?page=" + page, true, {
      timezone: offset
    });
  }

  getPassedProspects(page: number): Observable<ProspectResponse> {
    const offset = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this.apiService.get<ProspectResponse>("/admin/prospects/passed?page=" + page, true, {
      timezone: offset
    });
  }

  getRecentlyCreatedProspects(page: number): Observable<ProspectResponse> {
    const offset = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this.apiService.get<ProspectResponse>("/admin/prospects/recently-created?page=" + page, true, {
      timezone: offset
    });
  }



  createProspects(data: any): Observable<Prospect> {
    return this.apiService.post<{ data: Prospect }>("/admin/prospects", true, data).pipe(map(res => res.data));
  }

}
