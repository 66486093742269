// Enum for backend response type. Currently not being used

export enum __HTTPResponseType {
  ARRAY_BUFFER = "arraybuffer",
  BLOB = "blob",
  TEXT = "text",
  JSON = "json",
}

export enum SD_Day {
  SUNDAY = "sunday",
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday"
}

export enum SD_Hour {
  HOUR_00 = "00",
  HOUR_01 = "01",
  HOUR_02 = "02",
  HOUR_03 = "03",
  HOUR_04 = "04",
  HOUR_05 = "05",
  HOUR_06 = "06",
  HOUR_07 = "07",
  HOUR_08 = "08",
  HOUR_09 = "09",
  HOUR_10 = "10",
  HOUR_11 = "11",
  HOUR_12 = "12"
}

export enum SD_Minute {
  MIN_00 = "00",
  MIN_15 = "15",
  MIN_30 = "30",
  MIN_45 = "45",
}

export enum SD_DayHalf {
  AM = "AM",
  PM = "PM"
}

export enum TransformerType {
  NAME = "name",
  NAME_ONLY = "name_only",
  COMPACT = "compact",
  FULL = "full",
}

export enum ReviewsSortType {
  START = "start"
}

export enum SortOrder {
  ASC = "asc",
  DSC = "desc",
}

export enum PerPage {
  FIVE = "5",
  TEN = "10",
  FIFTEEN = "15",
  TWENTY = "20",
  THIRTY = "30",
  FIFTY = "50"
}

export enum SearchOptions {
  ACTIVE_LISTING = "active_property",
  INACTIVE_LISTING = "inactive_property",
  RESERVATION = "booking",
  GUEST = "guest",
  MANAGEMENT_CONTACTS = "management_contact",
  ADMIN = "admin",
  TASK = "task",
  ALL = "all",
}

export enum AutomationOffsetPosition {
  BEFORE = "before",
  AFTER = "after"
}

export enum AutomationRule {
  INCLUDE = "include",
  EXCLUDE = "exclude",
}

export class CommonEnumHelpers {
  static getSortOrderIcon(category: SortOrder): string {
    let icon = "";

    switch (category) {
      case SortOrder.ASC : {
        icon = "arrow_drop_up";
        break;
      }

      case SortOrder.DSC : {
        icon = "arrow_drop_down";
        break;
      }
    }

    return icon;
  }

  static getSortOrderOptions(sort: SortOrder): string {
    let sortBy = "";

    switch (sort) {

      case SortOrder.ASC: {
        sortBy = "Ascending";
        break;
      }

      case SortOrder.DSC: {
        sortBy = "Descending";
        break;
      }
    }

    return sortBy;

  }

  static getSearchOptions(category: SearchOptions): string {
    let title = "";

    switch (category) {
      case SearchOptions.ACTIVE_LISTING : {
        title = "Active Listing";
        break;
      }
      case SearchOptions.INACTIVE_LISTING : {
        title = "Archived/Inactive Listing";
        break;
      }
      case SearchOptions.RESERVATION : {
        title = "Reservation";
        break;
      }
      case SearchOptions.GUEST : {
        title = "Guest";
        break;
      }
      case SearchOptions.TASK : {
        title = "Task";
        break;
      }
      case SearchOptions.ADMIN : {
        title = "Admin";
        break;
      }
      case SearchOptions.ALL : {
        title = "All";
        break;
      }
      case SearchOptions.MANAGEMENT_CONTACTS : {
        title = "Management Contacts";
        break;
      }
    }
    return title;
  }

  static getAutomationOffsetPositionTitle(position: AutomationOffsetPosition): string {
    let title = "";

    switch (position) {
      case AutomationOffsetPosition.BEFORE : {
        title = "Before";
        break;
      }
      case AutomationOffsetPosition.AFTER : {
        title = "After";
        break;
      }
    }

    return title;
  }

  static getAutomationRuleTitle(rule: AutomationRule): string {
    let title = "";

    switch (rule) {
      case AutomationRule.INCLUDE: {
        title = "Include";
        break;
      }

      case AutomationRule.EXCLUDE : {
        title = "Exclude";
        break;
      }
    }

    return title;
  }
}
