/**
 * Created by aditya on 24/8/17.
 */
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { skip, takeUntil } from "rxjs/operators";
import { UserCategory, UserRole } from "../../../enums/user.enum";
import { UserFull } from "../../../models/new/user/user-full.model";
import { UserModelUtil } from "../../../models/utils/user-model.util";
import { State } from "../../../reducers";
import { ContactRepository } from "../../../repository/contact.repository";
import { UserRepository } from "../../../repository/user-repository";
import { getContactMaintenanceCatagoryTypes, getContactMaintenanceCatagoryTypesForHouseKeeper } from "../../../utils/utils";
import { CommonUtil } from "../../../utils/common.util";

@Component({
  selector: "sd-contact-type-info",
  template: `
    <div style="margin:20px;" fxLayout="column" fxLayoutGap="20px">

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">

        <div class="width-40">
          <mat-card class="generalCard">
            <mat-radio-group fxLayout="column" class="radio-group" [(ngModel)]='contactType'>
              <mat-radio-button
                [disabled]="userCategory === UserCategory.HOMEOWNER || userCategory === UserCategory.HOUSEKEEPER"
                class="radio-button" fxFlex="100%" fxLayout="row"
                *ngFor="let option of userTypes"
                [value]="option.slug">
                          
                <span style="color:#194267;font-size: small">
                         {{option.title}}
                  <mat-icon>help</mat-icon>
                </span>

              </mat-radio-button>
            </mat-radio-group>
          </mat-card>

        </div>

        <div class="width-60">
          <mat-card class="generalCard">

            <span style="color:#194267;font-size: 16px"><b>Super User</b></span>
            <div style="border:0.5px solid gray;margin-top:5px;padding:15px;border-radius: 3px">
              <span style="font-size: small;">User Accesibility will go here</span>
            </div>

            <div style="padding-top: 20px"></div>

            <span style="color:#194267;font-size: 16px"><b>Reports</b></span>
            <div style="font-size:small;border:0.5px solid gray;margin-top:5px;padding:15px;border-radius: 3px">
              <span>- Owner Statements</span>
              <br>
              <span>- Comp Report</span>
            </div>

          </mat-card>
        </div>


      </div>

      <div fxLayout="row" fxLayoutGap="10px">
        <button mat-button
                [disabled]="(isUpdating || (userCategory === UserCategory.HOMEOWNER || userCategory === UserCategory.HOUSEKEEPER))"
                class="dangerButton" (click)="userUpdate()">
          <span class="successSpan"><b>UPDATE USER DETAILS</b></span>
        </button>
        <mat-spinner *ngIf="isUpdating" [diameter]="30" [strokeWidth]="4"></mat-spinner>

      </div>
    </div>

  `,
  styles: [`

    .radio-button {
      margin: 5px;
    }

    .radio-group {
      display: inline-flex;
      flex-direction: column;
    }

    .width-40 {
      width: 40%;
    }

    .width-60 {
      width: 60%;
    }

    @media only screen and (max-width: 700px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 95%;
      }
    }

  `]

})

export class ContactTypeInfoComponent implements OnInit, OnDestroy {

  isHouseKeeper: boolean = false;
  currentUserType;
  userCategory: UserCategory;
  contactType: string;
  type: string;
  @Input() contact: UserFull;
  userTypes = [];
  isUpdating = false;
  isDeleting = false;
  UserCategory = UserCategory;
  UserRole = UserRole;
  private destroyed$ = new Subject();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private store: Store<State>,
              private contactRepo: ContactRepository,
              private userRepo: UserRepository) {

  }

  ngOnInit() {

    this.userTypes = getContactMaintenanceCatagoryTypes();

    this.userRepo.getUser().pipe(
      takeUntil(this.destroyed$))
      .subscribe(user => {
        this.isHouseKeeper = CommonUtil.isHouseKeeper(user);
        if (this.isHouseKeeper) {
          this.userTypes = getContactMaintenanceCatagoryTypesForHouseKeeper();
        }
      });

    if (this.contact.managementContact && this.contact.managementContact.data) {
      this.userCategory = this.contact.managementContact.data.category;
      this.currentUserType = this.contact.type;
    }

    const route$ = this.route.params;
    route$.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      const contactId = +params["id"];
      this.contactRepo.getFullContactById(contactId).pipe(takeUntil(route$.pipe(skip(1)))).subscribe((contact: UserFull) => {
        this.contact = contact;
        if (this.contact) {

          if (this.contact.type === "management") {
            this.contactType = this.type = UserModelUtil.getManagementContact(this.contact).category;
          }
        }
      });
    });

  }

  deleteContact() {
    console.log(this.contact);
  }

  userUpdate() {
    this.isUpdating = true;
    const data = {};
    if (this.type !== this.contactType) {
      data["management_category"] = this.contactType;
    }
    console.log(data);
    // TODO api call

    this.userRepo.updateUserType(this.contactType, this.contact.id).subscribe(res => {
      this.isUpdating = false;
      this.contact = res;
      this.userCategory = this.contact.managementContact.data.category;
      this.currentUserType = this.contact.type;
    }, () => this.isUpdating = false)
  }

  ngOnDestroy(): void {
    this.destroyed$.next(1);
    this.destroyed$.complete();

  }
}


