import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { merge as observableMerge } from "rxjs";
import { filter, takeWhile } from "rxjs/operators";

import { AppBootstrapSuccessAction } from "../../actions";
import { UserFull } from "../../models/new/user/user-full.model";
import { getAppLandingUrl, State } from "../../reducers";
import { CustomVariableRepository } from "../../repository/custom-variable.repository";
import { ListingRepository } from "../../repository/listing.repository";
import { MultiCalendarRulesRepository } from "../../repository/multi-calendar-rules.repository";
import { OptionsRepository } from "../../repository/options.repository";
import { UserRepository } from "../../repository/user-repository";
import { AppService } from "../../services/app.service";

@Component({
  selector: "sd-bootstrap",
  template: `
    <sd-error *ngIf="failedLoading">Unable to load data</sd-error>
    <sd-center-spinner *ngIf="!failedLoading"></sd-center-spinner>
  `,
  styles: [`
    .page-container {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  `]
})
export class BootstrapComponent implements OnInit, OnDestroy {
  failedLoading = false;
  isListingsLoaded: boolean;
  isUserLoaded: boolean;
  isAdminsLoaded: boolean;
  isCustomVariableLoaded: boolean;
  user: UserFull;
  private isAlive = true;

  constructor(private userRepo: UserRepository,
              private optionRepo: OptionsRepository,
              private listingRepo: ListingRepository,
              private multicalendarRuleRepo: MultiCalendarRulesRepository,
              private customVariableRepo: CustomVariableRepository,
              private router: Router,
              private appService: AppService,
              private store: Store<State>) {
  }

  ngOnInit() {
    console.log("bootstrap component init");
    this.loadBootstrapData();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  private loadBootstrapData() {
    this.userRepo.me().pipe(takeWhile(() => this.isAlive)).subscribe(() => {
    }, err => {
      if (err && err.error.status_code === 401) {
        this.appService.logout();
      }
    });
    this.listingRepo.getAllListings(true).pipe(takeWhile(() => this.isAlive)).subscribe();
    this.multicalendarRuleRepo.getMultiCalendarRules(true).pipe(takeWhile(() => this.isAlive)).subscribe();
    this.optionRepo.getAdmins().pipe(takeWhile(() => this.isAlive)).subscribe();
    this.customVariableRepo.getCustomVariables().pipe(takeWhile(() => this.isAlive)).subscribe();

    this.userRepo.getIsUserLoggedIn().pipe(takeWhile(() => this.isAlive)).subscribe((loggedIn) => {
      this.isUserLoaded = loggedIn;
    });

    this.userRepo.getUser().pipe(filter(m => !!m), takeWhile(() => this.isAlive),).subscribe((user) => {
      this.user = user;
    });

    this.listingRepo.getIsListingLoaded().pipe(takeWhile(() => this.isAlive)).subscribe((listingsLoaded) => {
      this.isListingsLoaded = listingsLoaded;
    });

    this.optionRepo.getIsAdminsLoaded().pipe(takeWhile(() => this.isAlive)).subscribe((adminsLoaded) => {
      this.isAdminsLoaded = adminsLoaded;
    });

    this.customVariableRepo.getCustomVariablesLoaded().pipe(takeWhile(() => this.isAlive)).subscribe((customVariablesLoaded) => {
      this.isCustomVariableLoaded = customVariablesLoaded;
    });

    const bootstrap = observableMerge(
      this.userRepo.getIsUserLoggedIn(),
      this.listingRepo.getIsListingLoaded(),
      this.optionRepo.getIsAdminsLoaded(),
      this.customVariableRepo.getCustomVariablesLoaded(),
    );

    bootstrap.pipe(takeWhile(() => this.isAlive)).subscribe(
      (data) => {
        console.log("data", data);
        if (this.isListingsLoaded && this.isUserLoaded && this.isAdminsLoaded && this.isCustomVariableLoaded) {
          return this.store.select(getAppLandingUrl).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
            this.store.dispatch(new AppBootstrapSuccessAction());
            this.router.navigateByUrl(value);
          });
        }
      }
    );

  }

}
