import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import Quill from "quill";
import "quill-mention";

import { QuillUtils } from "../../../utils/quill.util";

@Component({
  selector: "sd-message-box",
  template: `
    <div fxLayout="column">
      <div id="messageTextArea" #messageTextArea class="textareaContainer ta5 main"></div>
    </div>
  `,
  styles: [`

    .ta5 {
      border: 1.4px solid #765942;
      border-radius: 3px;
      min-height: 65px;
      font-size: x-small;
      font-family: 'Roboto', sans-serif;
      content-editable: true;
    }

    :host ::ng-deep .ql-mention-list-container {
      height: 400px !important;
      overflow: scroll !important;
      top: 10px !important;
    }

    .textareaContainer {
      padding: 0px 0px 5px 5px;
      height: 70px;
    }

    .main {
      width: 98%;
    }

    input[type=text], textarea {
      -webkit-transition: all 0.30s ease-in-out;
      -moz-transition: all 0.30s ease-in-out;
      -ms-transition: all 0.30s ease-in-out;
      -o-transition: all 0.30s ease-in-out;
      outline: none;
      padding: 5px 0px 5px 5px;
      margin: 5px 1px 3px 0px;
      border: 1px solid #DDDDDD;
      font-family: 'Roboto', sans-serif;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      letter-spacing: 2px;
      color: #333333;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      letter-spacing: 2px;
      color: #333333;
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      letter-spacing: 2px;
      color: #333333;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      letter-spacing: 2px;
      color: #333333;
    }

    [placeholder]:focus::-webkit-input-placeholder {
      transition: text-indent 0.5s 0.5s ease;
      text-indent: -100%;
      opacity: 1;
    }

    input[type=text]:focus, textarea:focus {
      box-shadow: 0 0 5px rgba(81, 203, 238, 1);
      padding: 5px 0px 5px 5px;
      margin: 5px 1px 3px 0px;
      border: 1px solid rgba(81, 203, 238, 1);
      font-family: 'Roboto', sans-serif;
    }

    ::ng-deep.ql-mention-list {
      max-height: 60px !important;
      overflow-y: scroll !important;
    }
  `],
})

export class MessageBoxComponent implements OnInit {
  @Output() text = new EventEmitter<string>();
  @Output() mentions = new EventEmitter<number[]>();
  @ViewChild("messageTextArea", {read: ElementRef, static: true}) messageTextArea: ElementRef;
  quillBody: Quill;
  titleVariables = QuillUtils.VARIABLES;
  private content: string;
  @Input() admins: {value: string, id: number}[] = [];

  @Input() set textModel(text: string) {
    this.content = text;
    if (this.quillBody) {
      console.log("quill", text);
      this.quillBody.setText(text);
      this.quillBody.root.innerHTML = QuillUtils.replaceMentionWithClass(this.quillBody.root.innerHTML);
    }
  }

  ngOnInit(): void {
    console.log("onInit sd-message-box");

    this.quillBody = new Quill(this.messageTextArea.nativeElement, {
      theme: "bubble",
      placeholder: "write your message here...",
      modules: {
        mention: {
          allowedChars: /^[_A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["{", "@"],
          isolatedCharacter: false,
          source: (searchTerm, renderList, mentionChar) => {
            let values;
            if (mentionChar === "{") {
              values = this.titleVariables;
            } else {
              values = this.admins;
            }

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(values[i]);
                }
              }
              renderList(matches, searchTerm);
            }
          },
          dataAttributes: ["value"]
        },
      },
    });

    this.quillBody.root.innerHTML = QuillUtils.replaceMentionWithClass(this.content);

    this.quillBody.on("text-change", () => {
      this.content = QuillUtils.removeExtraLines(this.quillBody.root.innerText);
      this.valuechange(this.content, this.quillBody.root.innerHTML);
    });
  }

  extractMentionedData(noteHtml: string) {
    if (!!noteHtml) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(noteHtml, "text/html");
      const items = [];
      for (const i of Object.keys(doc.querySelectorAll(".mention"))) {
        if (doc.querySelectorAll(".mention")[i].dataset.denotationChar === "@") {
          const id = +doc.querySelectorAll(".mention")[i].dataset.id;
          items.push(id);
          if (items.indexOf(id) === -1) {
            items.push(id);
          }
        }
      }
      return items;
    } else {
      return [];
    }
  }

  valuechange(newValue, html) {
    if (!!this.admins) {
      this.mentions.emit(this.extractMentionedData(html));
      // this.text.emit(html);
      // return;
    }
    this.text.emit(newValue);
  }
}
