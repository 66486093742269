import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { isNullOrUndefined } from "util";

import { environment } from "../../environments/environment";
import { ListingFull } from "../models/new/listing/listing-full.model";
import { UserCompact } from "../models/new/user/user-compact.model";
import { BookingModelUtil } from "../models/utils/booking-model.util";
import { ListingModelUtil } from "../models/utils/listing-model.util";
import { BookingRepository } from "../repository/booking.repository";
import { StayDuvetService } from "../services/stayduvet";

@Component({
  selector: "sd-checkout-page",
  template: `

    <div class="page-container" fxLayout="column" *ngIf="isError" fxLayoutAlign="center center"
         fxLayoutGap="20px">
      <mat-icon>{{errorStyle}}</mat-icon>
      <span style="font-size: 25px; font-weight: bolder">{{errorHeadline}}</span>
      <span>{{errorMessage}}</span>
    </div>

    <div class="page-container" fxLayout="column" *ngIf="!isError && paymentSuccessful" fxLayoutAlign="center center"
         fxLayoutGap="20px">
      <mat-icon>{{errorStyle}}</mat-icon>
      <span style="font-size: 25px; font-weight: bolder">Thanks!</span>
      <span>We have successfully received the payment.</span>
      <span>You will shortly receive email with confirmation.</span>
    </div>

    <div class="page-container" fxFlex="100%" fxLayout="row" fxLayoutAlign="center center"
         *ngIf="!isError && !paymentSuccessful && loading">
      <mat-spinner [diameter]="70" [strokeWidth]="6" color="accent"></mat-spinner>
    </div>

    <div fxFlex="100%" fxLayout="row" fxLayoutAlign="center center" *ngIf="!isError && !paymentSuccessful && !loading"
         style="margin-top: 3%; margin-bottom: 5%">

      <div fxLayout="row" fxShow.gt-md fxShow.md fxHide fxFlex="78%" fxLayoutGap="40px" style="padding:30px;">

        <div fxLayout="column" fxLayoutGap="20px" fxFlex="100%">

          <div fxFlex="62%" fxLayout="row">
            <mat-card fxLayout="column" fxLayoutGap="15px">
              <div #termscontainer
                   fxLayoutAlign="center center"
                   style=" overflow-y: scroll; height: 300px; border: 1px solid #DDD;margin-right: 30px;">
                <iframe [src]="getAgreement()" style="height: 100%;width:100%;"></iframe>
              </div>
              <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%" fxLayoutAlign="end center">
                <mat-checkbox [(ngModel)]="agreementChecked"></mat-checkbox>
                <span style="font-weight: bold;font-size: small">I agree to the terms of this rental agreement</span>
              </div>
            </mat-card>
          </div>

          <div fxFlex="58%" fxLayout="column" fxLayoutGap="10px" style="height: 100%;">

            <mat-card>

              <div fxLayout="column" class="full-width">
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="flex-start center">
                  <mat-icon class="primary">person_outline</mat-icon>
                  <span class="bolder">GUEST DETAILS</span>
                </div>
                <hr>
              </div>

              <mat-card style="width: 90%">
                <mat-card-content>
                  <h2 style="word-wrap:break-word;">{{guest?.first_name}} {{guest?.last_name}}</h2>
                  <div fxLayout="row" fxLayout.lt-md="column" *ngIf="guest.email">
                    <span style="font-weight: bold">Email: </span>
                    <span style="flex: 1 1 auto"></span>
                    <span style="word-wrap:break-word;">{{guest?.email}}</span>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" *ngIf="guest.phone_number">
                    <span style="font-weight: bold">Phone Number: </span>
                    <span style="flex: 1 1 auto"></span>
                    <span>{{guest?.phone_number}}</span>
                  </div>
                </mat-card-content>
                <mat-card-footer>
                  <mat-progress-bar color="accent" value="100"></mat-progress-bar>
                </mat-card-footer>
              </mat-card>

              <div fxLayout="column" class="full-width" fxLayoutGap="2px">
                <div fxLayout="row" fxLayoutGap="5}px" fxLayoutAlign="flex-start center">
                  <mat-icon class="primary">credit_card</mat-icon>
                  <span class="bolder">PAYMENT DETAILS</span>
                </div>
                <hr>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
                <div fxLayout="row" fxLayoutGap="3px">
                  <mat-icon>check_circle</mat-icon>
                  <strong>CREDIT CARD</strong>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%">
                <button style="width: 200px;" mat-raised-button [color]="'accent'"
                        [disabled]="!agreementChecked"
                        (click)="openPayment()"> PAY NOW
                </button>
              </div>
            </mat-card>

          </div>
        </div>


        <mat-card fxFlex="40%" class="secondary" fxLayout="column" fxLayoutGap="20px">
          <img style="width: 100%; height: auto;" src="{{ListingModelUtil.getPosterUrl(listing)}}">
          <div fxLayout="column" style="padding-left: 20px;" fxLayoutAlign="flex-start flex-start" fxLayoutGap="10px"
               fxFlex="100%">
            <span style="font-size: 16px; font-weight: bolder;" fxFlexAlign.lt-md="center">{{listing.title}}</span>
            <span
              style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">{{getAddress()}}</span>

            <div fxLayout="column" fxLayoutGap="3px" style="width: 100%; padding-right: 20px">
              <hr style="width: 100%">
              <hr style="width: 100%">
            </div>

            <div style="padding-right: 20px; padding-bottom: 20px;width:100%;" fxLayoutAlign="center center"
                 fxFlexAlign="center">
              <mat-tab-group fxLayout="column" fxFlex="100%" style="overflow: hidden; magrin-bottom: 10px"
                             fxFlexAlign="center">
                <mat-tab label="SUMMARY">
                  <div fxLayout="column" fxLayoutGap="10px" style="margin-top: 10px;overflow: scroll">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">Check-In:</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">{{booking.start}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">Check-Out</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">{{booking.end}}</span>
                    </div>
                    <div *ngIf="listing.rooms" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">ROOMS</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">
                        {{listing.room_count}}</span>
                    </div>
                    <hr>
                    <div fxLayoutAlign="space-between">
                      <span class="bolder">TOTAL</span>
                      <span class="bolder">$ {{booking.amount_to_pay}}</span>
                    </div>
                    <hr>
                    <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">PAYMENT SCHEDULE</span>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="font-size: x-small">Payment 1 (On agreement)</span>
                      <strong style="font-size: x-small">$ {{booking.amount_to_pay}}</strong>
                    </div>
                    <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">CANCELLATION POLICY</span>
                    <span
                      style="color: #8c8c8c; font-size: 14px; text-align: justify">0% refundable if cancelled after</span>
                  </div>
                </mat-tab>

                <mat-tab label="DETAILS">
                  <div fxLayout="column" fxLayoutGap="10px" style="margin-top: 10px;overflow: scroll">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">ACCOMMODATION FEE</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.base_amount}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">CLEANING FEE</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.cleaning_fee}}</span>
                    </div>
                    <div fxLayout="row" *ngFor="let otherFee of booking.other_fee" fxLayoutAlign="space-between center">
                      <span *ngIf="otherFee.slug === 'pet_fee'"
                            style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">PET FEE</span>
                      <span *ngIf="otherFee.slug === 'extra_guest_fee'"
                            style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">EXTRA GUEST FEE</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{otherFee.amount}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">SUBTOTAL</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.subtotal_amount}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">GUEST FEE</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.guest_channel_fee}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">CC PROCESS FEE</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.cc_process_fee}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">TAXES</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.total_tax}}</span>
                    </div>
                    <hr>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">SECURITY DEPOSIT</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.security_deposit_fee}}</span>
                    </div>
                    <hr>
                    <div fxLayoutAlign="space-between">
                      <span class="bolder">TOTAL</span>
                      <span class="bolder">$ {{booking.amount_to_pay}}</span>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </mat-card>
      </div>


      <div fxLayout="column" fxShow.lt-md fxHide fxFlex="78%" fxLayoutGap="40px" style="padding:30px;">


        <mat-card fxFlex="40%" class="secondary" fxLayout="column" fxLayoutGap="20px">
          <img style="width: 100%; height: auto;" src="{{ListingModelUtil.getPosterUrl(listing)}}">
          <div fxLayout="column" style="padding-left: 20px;" fxLayoutAlign="flex-start flex-start" fxLayoutGap="10px"
               fxFlex="100%">
            <span style="font-size: 16px; font-weight: bolder;" fxFlexAlign.lt-md="center">{{listing.title}}</span>
            <span
              style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">{{getAddress()}}</span>

            <div fxLayout="column" fxLayoutGap="3px" style="width: 100%; padding-right: 20px">
              <hr style="width: 100%">
              <hr style="width: 100%">
            </div>

            <div style="padding-right: 20px; padding-bottom: 20px;width:100%;" fxLayoutAlign="center center"
                 fxFlexAlign="center">
              <mat-tab-group fxFlex="100%" style="overflow: hidden; magrin-bottom: 10px" fxFlexAlign="center">
                <mat-tab label="SUMMARY">
                  <div fxLayout="column" fxLayoutGap="10px" style="margin-top: 10px;overflow: scroll">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">Check-In:</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">{{booking.start}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">Check-Out</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">{{booking.end}}</span>
                    </div>
                    <div *ngIf="listing.rooms" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">ROOMS</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">
                        {{listing.room_count}}</span>
                    </div>
                    <hr>
                    <div fxLayoutAlign="space-between">
                      <span class="bolder">TOTAL</span>
                      <span class="bolder">$ {{booking.amount_to_pay}}</span>
                    </div>
                    <hr>
                    <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">PAYMENT SCHEDULE</span>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="font-size: x-small">Payment 1 (On agreement)</span>
                      <strong style="font-size: x-small">$ {{booking.amount_to_pay}}</strong>
                    </div>
                    <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">CANCELLATION POLICY</span>
                    <span
                      style="color: #8c8c8c; font-size: 14px; text-align: justify">0% refundable if cancelled after</span>
                  </div>
                </mat-tab>

                <mat-tab label="DETAILS">
                  <div fxLayout="column" fxLayoutGap="10px" style="margin-top: 10px;overflow: scroll">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">ACCOMMODATION FEE</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.base_amount}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">CLEANING FEE</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.cleaning_fee}}</span>
                    </div>
                    <div fxLayout="row" *ngFor="let otherFee of booking.other_fee" fxLayoutAlign="space-between center">
                      <span *ngIf="otherFee.slug === 'pet_fee'"
                            style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">PET FEE</span>
                      <span *ngIf="otherFee.slug === 'extra_guest_fee'"
                            style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">EXTRA GUEST FEE</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{otherFee.amount}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">SUBTOTAL</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.subtotal_amount}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">GUEST FEE</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.guest_channel_fee}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">CC PROCESS FEE</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.cc_process_fee}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">TAXES</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.total_tax}}</span>
                    </div>
                    <hr>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <span style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">SECURITY DEPOSIT</span>
                      <span
                        style="color: #8c8c8c;font-size: 16px; font-weight: bolder;">$\{{booking.security_deposit_fee}}</span>
                    </div>
                    <hr>
                    <div fxLayoutAlign="space-between">
                      <span class="bolder">TOTAL</span>
                      <span class="bolder">$ {{booking.amount_to_pay}}</span>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </mat-card>

        <div fxLayout="column" fxLayoutGap="20px" fxFlex="100%">

          <div fxFlex="62%">
            <mat-card fxLayout="column" fxLayoutGap="15px">
              <div #termscontainer
                   fxLayoutAlign="center center"
                   style=" overflow-y: scroll; height: 300px; border: 1px solid #DDD;padding: 10px; margin-right: 30px;">
                <iframe [src]="getAgreement()" style="height: 100%;width:100%;"></iframe>
              </div>
              <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%" fxLayoutAlign="end center">
                <mat-checkbox [(ngModel)]="agreementChecked"></mat-checkbox>
                <span style="font-weight: bold;font-size: small">I agree to the terms of this rental agreement</span>
              </div>
            </mat-card>
          </div>

          <div fxFlex="58%" fxLayout="column" fxLayoutGap="10px" style="height: 100%;">

            <mat-card>

              <div fxLayout="column" class="full-width">
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="flex-start center">
                  <mat-icon class="primary">person_outline</mat-icon>
                  <span class="bolder">GUEST DETAILS</span>
                </div>
                <hr>
              </div>

              <mat-card style="width: 90%">
                <mat-card-content>
                  <h2 style="word-wrap:break-word;">{{guest?.first_name}} {{guest?.last_name}}</h2>
                  <div fxLayout="row" fxLayout.lt-md="column" *ngIf="guest.email">
                    <span style="font-weight: bold">Email: </span>
                    <span style="flex: 1 1 auto"></span>
                    <span style="word-wrap:break-word;">{{guest?.email}}</span>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" *ngIf="guest.phone_number">
                    <span style="font-weight: bold">Phone Number: </span>
                    <span style="flex: 1 1 auto"></span>
                    <span>{{guest?.phone_number}}</span>
                  </div>
                </mat-card-content>
                <mat-card-footer>
                  <mat-progress-bar color="accent" value="100"></mat-progress-bar>
                </mat-card-footer>
              </mat-card>

              <div fxLayout="column" class="full-width" fxLayoutGap="2px">
                <div fxLayout="row" fxLayoutGap="5}px" fxLayoutAlign="flex-start center">
                  <mat-icon class="primary">credit_card</mat-icon>
                  <span class="bolder">PAYMENT DETAILS</span>
                </div>
                <hr>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
                <div fxLayout="row" fxLayoutGap="3px">
                  <mat-icon>check_circle</mat-icon>
                  <strong>CREDIT CARD</strong>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%">
                <button style="width: 200px;" mat-raised-button [color]="'accent'"
                        [disabled]="!agreementChecked"
                        (click)="openPayment()"> PAY NOW
                </button>
              </div>
            </mat-card>

          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .page-container {
      position: absolute;
      height: 100%;
      width: 100%;
    }

    mat-icon.primary {
      font-size: 24px;
      height: 24px;
      width: 24px;
    }

    hr {
      width: 100%;
      margin-top: 0px;
      padding-top: 0px;
    }

    hr.sec {
      width: 100%;
    }

    .bolder {
      font-size: large;
      font-weight: 700
    }

    .full-width {
      width: 100%;
    }

    ::-webkit-scrollbar {
      display: none;
    }

    mat-card.secondary {
      padding: 0px;
    }

    mat-spinner {
      width: 24px;
      height: 24px;
      margin-right: 20px;
    }

  `]
})

export class CheckoutPageComponent implements OnInit {

  ListingModelUtil = ListingModelUtil;

  @ViewChild("termscontainer", { read: ElementRef }) termsconditionContainer: ElementRef;
  isError = false;

  errorStyle;
  errorHeadline;
  errorMessage;

  loading = true;
  rentalAgreementLoading = false;
  booking;
  listing: ListingFull;
  guest: UserCompact;

  paymentSuccessful = false;
  agreementChecked = false;
  stripeHandler: any;
  bus = new Subject<{ scrollTop: number, totalDivHeight: number, screenHeight: number }>();

  shouldEnable = false;
  rentalAgreement: string;

  constructor(private router: ActivatedRoute,
              private service: StayDuvetService,
              private bookingRepo: BookingRepository,
              public sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.stripeHandler = StripeCheckout.configure({
      key: environment.Stripe.publishableKey,
      image: "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/logo.png",
      locale: "auto",
      allowRememberMe: false,
      token: token => {
        this.loading = true;
        this.service.payFromPlatform(this.booking.id, token.id).subscribe(() => {
          this.paymentSuccessful = true;
          this.loading = false;
          this.stripeHandler.close();
        });
      }
    });

    this.setupBooking();
    this.setUpRentalAgreement();
  }

  getAddress() {
    if (!isNullOrUndefined(this.listing)) {
      return ListingModelUtil.getFullAddress(this.listing);
    }
  }

  setupBooking() {
    this.router.params.subscribe(params => {
      const bookingId = params.id;

      this.loading = true;
      this.bookingRepo.getHashedBooking(bookingId).subscribe((booking) => {
        this.booking = booking;
        this.guest = BookingModelUtil.getGuest(this.booking);
        this.listing = BookingModelUtil.getListing(this.booking);

        this.loading = false;
      }, (error) => {
        if (error.error.code === 28) {
          this.errorStyle = "extension";
          this.errorHeadline = "Oops";
          this.errorMessage = "Booking doesn't exist";
        } else if (error.error.code === 56) {
          this.errorStyle = "thumb_up";
          this.errorHeadline = "Thanks!";
          this.errorMessage = "We have already received payment from you. We certainly don't overcharge";
        } else if (error.error.code === 57) {
          this.errorStyle = "hourglass_empty";
          this.errorHeadline = "Oops";
          this.errorMessage = "Booking doesn't exist";
        }

        this.isError = true;
      });
    });
  }

  openPayment() {
    this.stripeHandler.open({
      name: "StayDuvet",
      description: "Payment For Booking",
      email: this.booking.guest.data.email,
      amount: this.booking.amount_to_pay * 100
    });
  }

  setUpRentalAgreement() {
    this.rentalAgreementLoading = true;
    this.bookingRepo.getCurrentRentalAgreement()
      .subscribe(r => {
        this.rentalAgreement = r;
        this.rentalAgreementLoading = false;
      }, err => {
        this.rentalAgreementLoading = false;
      });
  }

  getAgreement() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.rentalAgreement + "#toolbar=0&navpanes=0");
  }
}
