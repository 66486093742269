import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../../../actions/action";
import { PrivateNotesDraft } from "../../../models/new/drafts/private-notes-draft.model";
import { PrivateNotesDraftActionTypes } from "../../../actions/new/setttings/drafts/private-notes-drafts";
import * as _ from "lodash";

export interface PrivateNotesDraftState extends EntityState<PrivateNotesDraft> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const privateNotesDraftAdapter: EntityAdapter<PrivateNotesDraft> = createEntityAdapter
  < PrivateNotesDraft > ({
    selectId: (privateNotesDraft: PrivateNotesDraft) => privateNotesDraft.id
  });

export const initialState: PrivateNotesDraftState = privateNotesDraftAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function privateNotesDraftReducer(state: PrivateNotesDraftState = initialState, action: Action): PrivateNotesDraftState {
  switch (action.type) {
    case PrivateNotesDraftActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        isLoaded: false
      }
    }

    case PrivateNotesDraftActionTypes.INDEX_SUCCESS: {
      const privateNotesDrafts = action.payload as PrivateNotesDraft[];
      return privateNotesDraftAdapter.addAll(privateNotesDrafts, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case PrivateNotesDraftActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }

    case PrivateNotesDraftActionTypes.CREATE_SUCCESS: {
      const privateNotesDraft = action.payload as PrivateNotesDraft;
      return privateNotesDraftAdapter.addOne(privateNotesDraft, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case PrivateNotesDraftActionTypes.SHOW_REQUEST: {
      const privateNotesDraftId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, privateNotesDraftId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          privateNotesDraftId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, privateNotesDraftId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, privateNotesDraftId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case PrivateNotesDraftActionTypes.SHOW_SUCCESS: {
      const privateNotesDraft = action.payload as PrivateNotesDraft;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, privateNotesDraft.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, privateNotesDraft.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, privateNotesDraft.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          privateNotesDraft.id
        ];
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case PrivateNotesDraftActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case PrivateNotesDraftActionTypes.UPDATE_SUCCESS: {
      const privateNotesDraft = action.payload as PrivateNotesDraft;
      return privateNotesDraftAdapter.updateOne({
        id: privateNotesDraft.id,
        changes: privateNotesDraft
      }, state);
    }

    case PrivateNotesDraftActionTypes.DELETE_REQUEST: {
      return {
        ...state
      };
    }

    case PrivateNotesDraftActionTypes.DELETE_SUCCESS: {
      const privateNotesDraftId = action.payload as number;

      return privateNotesDraftAdapter.removeOne(privateNotesDraftId, state);
    }

    default: {
      return state;
    }
  }
}

  export const _getIsPrivateNotesDraftLoading = (state: PrivateNotesDraftState) => state.isLoading;
  export const _getIsPrivateNotesDraftLoaded = (state: PrivateNotesDraftState) => state.isLoaded;

  export const _getIsFullPrivateNotesDraftLoading = (state: PrivateNotesDraftState, draftId: number) => {
    return state.fullLoadingIds.indexOf(draftId) !== -1;
  };
  export const _getIsFullPrivateNotesDraftLoaded = (state: PrivateNotesDraftState, draftId: number) => {
    return state.fullLoadedIds.indexOf(draftId) !== -1;
  };


