/**
 * Created by Piyush on 22-Jul-17.
 */
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { merge as observableMerge, Subject } from "rxjs";
import { debounceTime, filter, takeWhile } from "rxjs/operators";

import { environment } from "../../../../environments/environment";
import { getCalendarData, getIsCalendarDataLoaded, getIsCalendarDataLoading, State } from "../../../reducers";
import { ListingRepository } from "../../../repository/listing.repository";
import { UserRepository } from "../../../repository/user-repository";
import { StayDuvetService } from "../../../services/stayduvet";
import { addMonthToSDDate, getFirstDayOfMonth, getMonthCalendar, getWeekViewHeader, SDDay } from "../../../utils/calendar-utils";
import { CommonUtil } from "../../../utils/common.util";

@Component({
  selector: "sd-small-calendar",
  template: `

    <div fxLayout="row"
         style="width: 100%;margin-bottom: 5px; margin-top: 20px;"
         fxLayoutAlign="space-around center"
         fxLayoutGap="5px">
      <a *ngIf="showArrowButtons" mat-button (click)="previousMonth()" color="accent">
        <mat-icon>keyboard_backspace</mat-icon>
      </a>
      <span style="font-weight: bolder;font-size:15px;">{{ currentMonth.date | dateFix | date:'MMMM, y' }}</span>
      <a *ngIf="showArrowButtons" mat-button (click)="nextMonth()" color="accent">
        <mat-icon>trending_flat</mat-icon>
      </a>
    </div>
    <mat-card class="background">
      <mat-grid-list cols="7" rowHeight="40px">
        <mat-grid-tile
          *ngFor="let weekDay of weekDays"
          class="header">
          {{ weekDay }}
        </mat-grid-tile>
      </mat-grid-list>
      <div fxlayout="row" fxLayoutAlign="center center" *ngIf="isLoading" style="padding: 10px;">
        <mat-spinner [color]="'accent'" [diameter]="40" [strokeWidth]="3"></mat-spinner>
      </div>
      <mat-grid-list style="cursor: pointer" (click)="openListingCalendar()" cols="7" rowHeight="50px"
                     *ngIf="isLoaded">
        <mat-grid-tile
          *ngFor="let day of currentCalendar"
          class="date-cell"
          [ngClass]="{
         'same-month': day.inMonth,
         'different-month': !day.inMonth,
         'past-days': day.inMonth && day.isPast
         }">
          <sd-small-calendar-date-tile
            [isClickable]="isClickable"
            [isHouseKeeper]="isHouseKeeper"
            [listingId]="listingId"
            [amount]="getPrice(day)"
            [day]="day"
            [calendarData]="getData(day)"
            style="width:100%">
          </sd-small-calendar-date-tile>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card>
  `,
  styles: [`
    .header:hover {
      background-color: #e1e1e1;
    }

    .header {
      background-color: #ededed;
      font-size: 15px;
      font-weight: bold;
    }

    .date-cell {
      border: 1px solid #e1e1e1;
    }

    .background {
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
      padding: 0px !important;
    }
  `]
})
export class SmallCalendarComponent implements OnInit, OnDestroy, OnChanges {

  @Input() listingId: number;
  @Input() isClickable = true;
  @Input() showArrowButtons = true;
  isHouseKeeper = false;
  isLoading = false;
  isLoaded = false;
  events: any[] = [];
  weekDays: any;
  currentCalendar: SDDay[];
  @Input() currentMonth: SDDay;
  days = ["S", "M", "T", "W", "T", "F", "S"];
  private loadCalendar$ = new Subject<any>();
  calendarPrice: { [date: string]: number } = {};

  private isAlive = true;

  constructor(private service: StayDuvetService,
              private listingRepo: ListingRepository,
              private router: Router,
              private userRepo: UserRepository,
              private store: Store<State>) {
    this.weekDays = getWeekViewHeader();
    this.weekDays = this.weekDays.map(day => this.days[day.date.getDay()]);

    this.loadCalendar$.pipe(debounceTime(500)).subscribe(monthString => {
      this.listingRepo.getCalendar(this.listingId, {month: monthString}).pipe(debounceTime(500)).subscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentMonth) {
      this.refreshCalendar();
    }
    this.userRepo.getUser().pipe(takeWhile(() => this.isAlive), filter(f => !!f))
      .subscribe((user) => {
      this.isHouseKeeper = CommonUtil.isHouseKeeper(user);
    });
  }

  ngOnInit(): void {
    if (!this.currentMonth) {
      this.currentMonth = getFirstDayOfMonth();
      this.refreshCalendar();
    }
    console.log("onInit sd-calendar");
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  refreshCalendar() {

    this.currentCalendar = getMonthCalendar(this.currentMonth);
    const monthString = this.currentMonth.date.getFullYear() + "-" + ("0" + (this.currentMonth.date.getMonth() + 1)).slice(-2);

    this.store.select((state) => {
      return getIsCalendarDataLoading(state, this.listingId, monthString);
    }).pipe(takeWhile(() => this.isAlive)).subscribe((loading) => {
      this.isLoading = loading;
    });

    this.store.select((state) => {
      return getIsCalendarDataLoaded(state, this.listingId, monthString);
    }).pipe(takeWhile(() => this.isAlive)).subscribe((loading) => {
      this.isLoaded = loading;
    });

    this.store.select((state) => {
      return getCalendarData(state, this.listingId, monthString);
    }).pipe(takeWhile(() => this.isAlive)).subscribe((calendarData) => {
      if (calendarData) {
        this.events = calendarData;
      }
    });

    const combinedObservers = observableMerge(
      this.store.select((state) => {
        return getIsCalendarDataLoading(state, this.listingId, monthString);
      }),
      this.store.select((state) => {
        return getIsCalendarDataLoaded(state, this.listingId, monthString);
      }),
      this.store.select((state) => {
        return getCalendarData(state, this.listingId, monthString);
      })
    );

    combinedObservers.pipe(takeWhile(() => this.isAlive)).subscribe(
      (data) => {
        if (!this.isLoading && !this.isLoaded) {
          this.loadCalendar$.next(monthString);
        }
      }
    );
  }

  nextMonth() {
    this.currentMonth = addMonthToSDDate(this.currentMonth, 1);
    this.refreshCalendar();
  }

  previousMonth() {
    this.currentMonth = addMonthToSDDate(this.currentMonth, -1);
    this.refreshCalendar();
  }

  getData(day: SDDay): any {
    const currentDate = day.date;
    const fullDate = ("0" + currentDate.getDate()).slice(-2);
    const fullMonth = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const fullYear = currentDate.getFullYear();

    const key = fullYear + "-" + fullMonth + "-" + fullDate;

    if (this.events[key]) {
      return this.events[key];
    } else {
      return null;
    }
  }

  getPrice(day: SDDay): number {
    const currentDate = day.date;
    const fullDate = ("0" + currentDate.getDate()).slice(-2);
    const fullMonth = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const fullYear = currentDate.getFullYear();

    const key = fullYear + "-" + fullMonth + "-" + fullDate;

    return this.calendarPrice[key];
  }

  openListingCalendar() {
    if (!!this.isClickable) {
      const monthString = this.currentMonth.date.getFullYear() + "-" + ("0" + (this.currentMonth.date.getMonth() + 1)).slice(-2);
      window.open(environment.dashboardURL + "/listings/" + this.listingId + "/calendar;month=" + monthString);
    }
  }

}
