import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import * as _ from "lodash";

import { Action } from "../../../actions/action";
import { OnboardingTaskActionTypes } from "../../../actions/new/tasks/onboarding-task";
import { OnboardingTaskCompact } from "../../../models/new/tasks/onboarding-task-compact.model";
import { OnboardingTaskFull } from "../../../models/new/tasks/onboarding-task-full.model";

export interface OnboardingTaskState extends EntityState<OnboardingTaskCompact | OnboardingTaskFull> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];

  tasksNotExist: number[];
}

export const onboardingTaskAdapter: EntityAdapter<OnboardingTaskCompact | OnboardingTaskFull> = createEntityAdapter<OnboardingTaskCompact | OnboardingTaskFull>({
  selectId: (onboardingTask: OnboardingTaskCompact | OnboardingTaskFull) => onboardingTask.id
});

export const initialState: OnboardingTaskState = onboardingTaskAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],

  tasksNotExist: []
});

export function onboardingTaskReducer(state: OnboardingTaskState = initialState, action: Action): OnboardingTaskState {
  switch (action.type) {
    case OnboardingTaskActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true
      };
    }

    case OnboardingTaskActionTypes.INDEX_SUCCESS: {
      const onboardingTasks = action.payload as OnboardingTaskCompact[];
      return onboardingTaskAdapter.addAll(onboardingTasks, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case OnboardingTaskActionTypes.CREATE_SUCCESS: {
      const value = action.payload as OnboardingTaskFull;
      return onboardingTaskAdapter.updateOne({
        id: value.id,
        changes: value
      }, onboardingTaskAdapter.addOne(value, state));
    }

    case OnboardingTaskActionTypes.SHOW_REQUEST: {
      const onboardingTaskId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, onboardingTaskId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          onboardingTaskId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, onboardingTaskId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, onboardingTaskId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case OnboardingTaskActionTypes.SHOW_SUCCESS: {
      const onboardingTask = action.payload as OnboardingTaskFull;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, onboardingTask.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, onboardingTask.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, onboardingTask.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          onboardingTask.id
        ];
      }

      const addedState = onboardingTaskAdapter.addOne(onboardingTask, state);

      return onboardingTaskAdapter.updateOne({
        id: onboardingTask.id,
        changes: onboardingTask
      }, {
        ...addedState,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      });
    }

    case OnboardingTaskActionTypes.DELETE_SUCCESS: {
      const value = action.payload as number;

      return onboardingTaskAdapter.removeOne(value,state);
    }

    case OnboardingTaskActionTypes.UPDATE_SUCCESS: {
      const task = action.payload as OnboardingTaskFull;
      const newState = onboardingTaskAdapter.updateOne({
        id: task.id,
        changes: task
      }, state);

      return onboardingTaskAdapter.addOne(task, newState);
    }

    case OnboardingTaskActionTypes.ADD_ONBOARDING_TASK_TO_NOT_EXIST: {
      const taskId = action.payload as number;
      const data = state.tasksNotExist;
      data.push(taskId);
      return {
        ...state,
        tasksNotExist: data
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsOnboardingTaskLoading = (state: OnboardingTaskState) => state.isLoading;
export const _getIsOnboardingTaskLoaded = (state: OnboardingTaskState) => state.isLoaded;

export const _getIsFullOnboardingTaskLoading = (state: OnboardingTaskState, taskId: number) => {
  return state.fullLoadingIds.indexOf(taskId) !== -1;
};
export const _getIsFullOnboardingTaskLoaded = (state: OnboardingTaskState, taskId: number) => {
  return state.fullLoadedIds.indexOf(taskId) !== -1;
};

export const _getOnboardingTaskExist = (taskId: number, state: OnboardingTaskState) => {
  return state.tasksNotExist.indexOf(taskId) !== -1;
};
