import {
  APP_BOOTSTRAP_LISTENER,
  APP_INITIALIZER,
  NgModule
} from "@angular/core";

import { IntercomService } from "./intercom.service";
import { setupHubspot } from "./intercom.utils";

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    IntercomService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupHubspot,
      multi: true
    },
    // {
    //   provide: APP_BOOTSTRAP_LISTENER,
    //   useFactory: bootIntercom,
    //   multi: true
    // }
  ]
})
export class IntercomModule {
}
