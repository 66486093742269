import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../../actions/action";
import { InventoryActionTypes } from "../../actions/new/setttings/inventory";
import * as _ from "lodash";
import { Inventory } from "../../models/new/inventory.model";
import Utils from "../../utils/utils";


export interface InventoryState extends EntityState<Inventory> {
  isLoading: boolean;
  isLoaded: boolean;
  tags: any;
  unTagged: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const inventoryAdapter: EntityAdapter<Inventory> = createEntityAdapter<Inventory>({
  selectId: (inventory: Inventory) => inventory.id,
  sortComparer: sortByTitle
});

export function sortByTitle(a: Inventory, b: Inventory): number {
  if (a.title.toLowerCase() > b.title.toLowerCase()) {
    return 1;
  }

  if (a.title.toLowerCase() < b.title.toLowerCase()) {
    return -1;
  }

  return 0;
}

export const initialState: InventoryState = inventoryAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  tags:{},
  unTagged: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function inventoryReducer(state: InventoryState = initialState, action: Action): InventoryState {
  switch (action.type) {
    case InventoryActionTypes.INDEX_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case InventoryActionTypes.INDEX_SUCCESS: {
      const inventories = action.payload.inventories as Inventory[];
      const unTagged = action.payload.unTagged as boolean;
      return inventoryAdapter.addAll(inventories, {
        ...state,
        isLoading: false,
        isLoaded: true,
        unTagged: unTagged
      });
    }

    case InventoryActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case InventoryActionTypes.CREATE_SUCCESS: {
      const inventory = action.payload as Inventory;
      return inventoryAdapter.addOne(inventory, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case InventoryActionTypes.SHOW_REQUEST: {
      const inventoryId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, inventoryId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          inventoryId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, inventoryId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, inventoryId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case InventoryActionTypes.SHOW_SUCCESS: {
      const inventory = action.payload as Inventory;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, inventory.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, inventory.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, inventory.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          inventory.id
        ];
      }

      return inventoryAdapter.addOne(inventory, {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      });
    }

    case InventoryActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case InventoryActionTypes.UPDATE_SUCCESS: {
      const inventory = action.payload as Inventory;
      return {
        ...state,
        entities: {
          ...state.entities,
          [inventory.id]: inventory
        }
      }
    }

    case InventoryActionTypes.DELETE_SUCCESS: {
      const id = action.payload;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, id);

      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, id);

      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, id);
      }

      return inventoryAdapter.removeOne(id, {
        ...state,
        fullyLoadingIds,
        fullyLoadedIds
      });

    }

    case InventoryActionTypes.TAG_INDEX_SUCCESS: {
      const tags = action.payload;
      console.log(tags);
      const normalizedTags = Utils.normalize(tags);
      return {...state, tags: normalizedTags};
    }

    case InventoryActionTypes.ADD_TAG_SUCCESS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          [action.payload.id]: action.payload
        }
      }
    }

    default:
      return state;
  }
}

export const _getIsInventoryLoading = (state: InventoryState) => state.isLoading;
export const _getIsInventoryLoaded = (state: InventoryState) => state.isLoaded;
export const _getUnTagged = (state: InventoryState) => state.unTagged;

export const _getIsFullInventoryLoading = (state: InventoryState, inventoryId: number) => {
  return state.fullLoadingIds.indexOf(inventoryId) !== -1;
};
export const _getIsFullInventoryLoaded = (state: InventoryState, inventoryId: number) => {
  return state.fullLoadedIds.indexOf(inventoryId) !== -1;
};

export const _getAllInventoryTags = (state:InventoryState) => Utils.normalizedObjToArray(state.tags).sort((a,b) => {
  const nameA=(a["title"]).toLowerCase(), nameB=(b["title"]).toLowerCase();
  if(nameA < nameB) {
    return -1
  }
  if(nameA > nameB) {
    return 1
  }
});
