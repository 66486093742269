import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

import {
  SettingIndexRequestAction,
  SettingIndexSuccessAction,
  SettingUpdateRequestAction,
  SettingUpdateSuccessAction
} from "../actions/setting";
import { Setting } from "../models/setting";
import { getIsSettingsLoaded, getIsSettingsLoading, getSettings, State } from "../reducers";
import { SettingsService } from "../services/settings.service";

@Injectable()
export class SettingsRepository {

  constructor(private settingsService: SettingsService, private store: Store<State>) {
  }

  getSettingsLoading(): Observable<boolean> {
    return this.store.select(getIsSettingsLoading);
  }

  getSettingsLoaded(): Observable<boolean> {
    return this.store.select(getIsSettingsLoaded);
  }

  getSettings(force: boolean = false): Observable<Setting[]> {
    let loading = false;
    let loaded = false;

    this.getSettingsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getSettingsLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new SettingIndexRequestAction());
      this.settingsService.getSettings().subscribe(res => {
        this.store.dispatch(new SettingIndexSuccessAction(res));
      });
    }
    return this.store.select(getSettings);
  }

  updateSettings(id: number, data: { value: string }): Observable<Setting> {
    this.store.dispatch(new SettingUpdateRequestAction());
    return this.settingsService.updateSettings(id, data).pipe(map(res => {
      this.store.dispatch(new SettingUpdateSuccessAction(res));
      return res;
    }));
  }

  syncPrice() {
    return this.settingsService.syncPrice();
  }
}
