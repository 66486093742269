import {map} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiService} from "./api.service";
import {OptionCountryResponse} from "../models/responses/options/option-country.response";
import {OptionStateResponse} from "../models/responses/options/option-state.response";
import {OptionListingResponse} from "../models/responses/options/option-listing.response";
import {Tag} from "../models/tag";
import {OptionTagResponse} from "../models/responses/options/option-tag.response";
import {__HTTPResponseType} from "../enums/common.enum";
import {UserCompact} from "../models/new/user/user-compact.model";
import {ChecklistCategory} from "../models/new/checklist-category";
import {UserFull} from "../models/new/user/user-full.model";

@Injectable()
export class OptionService {

  constructor(private apiService: ApiService) {

  }

  getListingLocations(): Observable<string[]> {
    return this.apiService.get<string[]>("/options/listing-locations", true);
  }

  getTags(): Observable<OptionListingResponse> {
    return this.apiService.get<OptionListingResponse>("/options/tags", true);
  }

  getCountries(): Observable<OptionCountryResponse> {
    return this.apiService.get<OptionCountryResponse>("/options/countries", true);
  }

  getStates(data: { country_code: string }): Observable<OptionStateResponse> {
    return this.apiService.get<OptionStateResponse>("/options/states", true, data);
  }

  removeTag(property_id: string, tag: Tag): Observable<boolean> {
    const data = { tag_string: tag.title };
    return this.apiService.delete("/properties/" + property_id + "/tags", true,
      data, null, true, __HTTPResponseType.TEXT);
  }

  createTag(data: { tag_string: string }): Observable<OptionTagResponse> {
    return this.apiService.post<OptionTagResponse>("/tags", true, data);
  }

  attachTag(data: { tag_string: string }, property_id: string): Observable<OptionTagResponse> {
    return this.apiService.put<OptionTagResponse>("/properties/" + property_id + "/tags", true, data);
  }

  getAdmins(): Observable<UserCompact[]> {
    return this.apiService.get<{ data: UserCompact[] }>("/options/admins", true).pipe(
      map(res => res.data));
  }

  getListingAssignees(): Observable<UserCompact[]> {
    return this.apiService.get<{ data: UserCompact[] }>("/options/listing-assignees", true).pipe(map(res => res.data));
  }

  getAgents(): Observable<UserCompact[]> {
    return this.apiService.get<{ data: UserCompact[] }>("/admin/agents", true).pipe(map(res => res.data));
  }

  getVendors(): Observable<UserCompact[]> {
    return this.apiService.get<{ data: UserCompact[] }>("/options/vendors", true).pipe(map((res) => res.data));
  }

  createCategory(title: string): Observable<ChecklistCategory> {
    return this.apiService.post<{ checklist_category: ChecklistCategory }>("/options/checklist-categories/", true, {
      title: title
    }).pipe(map((res) => res.checklist_category));
  }

  getTaskAssignees(): Observable<UserCompact[]> {
    return this.apiService.get<{ data: UserCompact[] }>("/options/assignees", true).pipe(map((res) => res.data));
  }

  getHousekeeperEmployees(): Observable<UserCompact[]> {
    return this.apiService.get<{ data: UserCompact[] }>("/options/housekeeper-employees", true).pipe(map((res) => res.data));
  }

  createAdmin(data: { first_name: string, last_name: string, email: string }): Observable<UserFull> {
    return this.apiService.post<{ data: UserFull }>("/super-admin", true, data).pipe(map((res) => res.data));
  }

  getChecklistCategories(): Observable<ChecklistCategory[]> {
    return this.apiService.get<{ checklist_categories: ChecklistCategory[] }>("/options/checklist-checklistCategories", true).pipe(map((res) => res.checklist_categories));
  }

  getAgentHomeowners(): Observable<UserCompact[]> {
    return this.apiService.get<{ data: UserCompact[] }>("/options/homeowners", true).pipe(map(res => res.data));
  }
}
