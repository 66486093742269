import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "sd-future-book-confirmation", template: `
    <sd-modal-popup-layout title="Future Booking Popup">
      <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="25px">
        <h3>
          Are you sure you want to book ahead of the booking limit?
        </h3>
        <div fxLayout="row" fxFlexAlign="end" fxLayoutGap="5px">
          <button mat-raised-button color="accent" (click)="clicked('true')">Yes</button>
          <button mat-raised-button color="warn" (click)="clicked('false')">No</button>
        </div>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`

  `]
})
export class FutureBookConfirmationPopupComponent {

  constructor(private dialogRef: MatDialogRef<FutureBookConfirmationPopupComponent>) {

  }

  clicked(value) {
    this.dialogRef.close({open: value});
  }
}
