import {
  Component,
  Input,
  OnChanges,
  OnInit
} from "@angular/core";
import { Router } from "@angular/router";
import * as Highcharts from "highcharts";
import { isString } from "util";

@Component({
  selector: "sd-monthly-breakdown-chart",
  template: `
    <ngx-charts-bar-vertical-stacked
      *ngIf="!(isNetIncome || isOwner)"
      [results]="statsData"
      [xAxis]="true"
      [yAxis]="true"
      [legend]="showLegend"
      [legendTitle]="'Listings'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="true"
      [yAxisLabel]="yAxisLabel"
      (select)="onSelect($event)">
      <ng-template #tooltipTemplate let-model="model">
        <h2>{{ model.name }}</h2>
        <p *ngIf="!(isCommission || isCleaning)">
          Payout: {{ '$' + (model?.stats?.total_payout | number: '1.2-2')}}</p>
        <p *ngIf="!(isCommission || isCleaning)">
          Expense: {{ '$' + (model?.stats?.total_expenses | number: '1.2-2')}}</p>
        <p *ngIf="!(isCommission || isCleaning)">
          Earning: {{ '$' + (model?.stats?.total_earning | number: '1.2-2')}}</p>
        <p *ngIf="isCleaning">Fees: {{ '$' + ((model?.stats?.total_cleaning_fees) | number: '1.2-2')}}</p>
        <p *ngIf="isCleaning">Expenses: {{ '$' + ((model?.stats?.total_cleaning_cost) | number: '1.2-2')}}</p>
        <p *ngIf="isCleaning">
          Earnings: {{ '$' + ((model?.stats?.total_cleaning_fees - model?.stats?.total_cleaning_cost) | number: '1.2-2')}}</p>
        <p *ngIf="isCommission">
          Commission: {{ '$' + (model?.stats?.total_commission | number: '1.2-2')}}</p>
      </ng-template>
    </ngx-charts-bar-vertical-stacked>
    <div *ngIf="isNetIncome || isOwner" sdResizeDetector (dimensionChange)="setChartWidth($event)" id="container" style="width: 100%">
      <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart>
    </div>
  `,
  styles: [`
    ::ng-deep .highcharts-no-tooltip .highcharts-point {
      display: none;
    }
  `]
})
export class MonthlyBreakdownChartComponent implements OnInit, OnChanges {

  @Input() statsData: any = [];
  @Input() isNetIncome: boolean = false;
  @Input() isOwner: boolean = false;
  @Input() isCommission: boolean = false;
  @Input() isCleaning: boolean = false;
  @Input() goalPacing: number[];
  @Input() earning: number[];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  isFirst = true;

  showLegend = true;
  yAxisLabel: string;

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    console.log(this.statsData);
    this.showLegend = window.screen.availWidth > 500;

    if (this.isNetIncome || this.isOwner) {
      this.yAxisLabel = this.isNetIncome ? "Net Income (in $)" : "Earnings (in $)";
      const goalPacing = this.goalPacing;
      const netIncome = this.isNetIncome;
      const earning = this.earning;

      this.chartOptions = {
        title: {
          text: ""
        },
        chart: {
          type: "column"
        },
        xAxis: {
          categories: this.statsData[0].name.map(month => month)
        },
        yAxis: {
          title: {
            text: this.yAxisLabel
          }
        },
        plotOptions: {
          column: {
            stacking: "normal",
            grouping: false,
            shadow: false,
            borderWidth: 0
          },
          series: {
            events: {
              legendItemClick: (event) => {
                event.preventDefault();
                this.statsData[0].series.forEach(listing => {
                  if (listing.name === event.target.name) {
                    this.router.navigate(["listings", listing.stack, "calendar"]);
                  }
                });
              }
            }
          }
        },
        tooltip: {
          shared: true,
          formatter: function() {
            let str = "";
            if (netIncome) {
              if (!!this.points[0]?.total && this.points[0]?.series.name === "Goal Set") {
                str = str + "Goal Set : $"+ Math.round(this.points[0].total * 100) / 100;
              } else {
                str = str + "Net Income : $"+ Math.round(this.points[0].total * 100) / 100;
              }
              if (!!this.points[1]?.total) {
                str = str + "<br/>Net Income : $"+ Math.round(this.points[1].total * 100) / 100;
              }
            } else {
              if (!!this.points[0]?.total && this.points[0]?.series.name === "Goal Set") {
                str = str + "Goal Set : $"+ Math.round(this.points[0].total * 100) / 100;
              } else if (!!this.points[1]?.total) {
                str = str + "Expense : $"+ Math.round(this.points[1].total * 100) / 100;
              }
              if (!!this.points[2]?.total) {
                str = str + "<br/>Expense : $"+ Math.round(this.points[2].total * 100) / 100;
              }
              if (isFinite(earning[+this.points[0]?.point.category.split("-")[0] - 1])) {
                str = str + "<br/>Earning : $"+ Math.round(earning[+this.points[0].point.category.split("-")[0] - 1] * 100) / 100;
              }
            }
            if (isFinite(goalPacing[+this.points[0]?.point.category.split("-")[0] - 1])) {
              if (goalPacing[+this.points[0]?.point.category.split("-")[0] - 1] >= 0) {
                str = str + "<br/>Goal Pacing : $"+ Math.round(goalPacing[+this.points[0].point.category.split("-")[0] - 1] * 100) / 100;
              } else {
                str = str + "<br/>Goal Pacing : -$"+ Math.round(goalPacing[+this.points[0].point.category.split("-")[0] - 1] * -100) / 100;
              }
            }
            return str;
          }
        },
        legend: {
          enabled: this.showLegend,
          align: "right",
          layout: "vertical",
          verticalAlign: "top",
          labelFormatter: function() {
            if (netIncome) {
              return `<p style="color: ${(this as any).color}">${this.name}</p>`;
            } else {
              return this.name;
            }
          }
        },
        credits: {
          enabled: false
        },
        series: this.statsData[0].series
      };
    }

    if (this.isCommission) {
      this.yAxisLabel = "Commission (in $)";
    }

    if (this.isCleaning) {
      this.yAxisLabel = "Cleaning Earnings (in $)";
    }
  }

  ngOnChanges() {
    if (!this.isFirst) {
      this.refresh();
    }
    this.isFirst = false;
  }

  refresh() {
    this.chartOptions = {
      ...this.chartOptions,
      series: this.statsData[0].series
    };
    this.Highcharts.chart("container", this.chartOptions).redraw();
  }

  setChartWidth(event) {
    this.chartOptions = {
      ...this.chartOptions,
      chart: {
        ...this.chartOptions.chart,
        width: event.offsetWidth
      }
    };
    this.Highcharts.chart("container", this.chartOptions).redraw();
  }

  onSelect(event) {
    let id = null;
    if (isString(event)) {
      for (const item in this.statsData) {
        const data = this.statsData[item];
        for (const item in  data.series) {
          if (data.series[item].name === event) {
            id = data.series[item].id;
            break;
          }
        }
        if (id) {
          break;
        }
      }
    } else {
      id = event.id;
    }

    if (!id) {
      return;
    }
    this.router.navigate(["listings", id, "calendar"]);
  }
}
