import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Task } from "app/models/task";
import { distinctUntilChanged } from "rxjs/operators";

import { TaskRepository } from "../../../repository/task.repository";
import { getSDDayObject, SDDay } from "../../../utils/calendar-utils";
import DateUtils from "../../../utils/date";

@Component({
  selector: "sd-date", template: `
        <sd-modal-popup-layout title="Change Date">
        </sd-modal-popup-layout>
        <form [formGroup]="formGroup" (ngSubmit)="formGroup.valid && saveDate()">
            <div fxLayout="column">
                <div fxLayout="row" fxFlex="100%">
                    <mat-form-field [color]="'accent'" fxFlex="40%">
                        <input matInput [matDatepicker]="picker" formControlName='due_date'>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <div *ngIf="task" style="width:30%;">
                        <sd-small-calendar [currentMonth]="currentMonth"
                                           [listingId]="task.property_id"></sd-small-calendar>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%">
                    <button mat-raised-button color="primary" matDialogClose>Cancel</button>
                    <button [disabled]="isUpdating" mat-raised-button color="accent" type="submit">Save</button>
                    <mat-spinner [diameter]="30" [strokeWidth]="4" color="accent" *ngIf="isUpdating"></mat-spinner>
                </div>
            </div>
        </form>

  `,
  styles: [`

  `]
})
export class DateSelectComponent implements OnInit {
  @Input() task: Task;
  isUpdating: boolean;
  newDate: FormControl;
  formGroup: FormGroup;
  currentMonth: SDDay;

  constructor(private dialog: MatDialog, private taskRepo: TaskRepository) {
    this.newDate = new FormControl("", [Validators.required]);
    this.formGroup = new FormGroup({
      due_date: this.newDate
    });
  }

  ngOnInit() {
    this.newDate.setValue(DateUtils.stringToUTCDate(this.task.due_date));
    this.currentMonth = getSDDayObject(this.task.due_date);
    this.newDate
      .valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => {
        this.currentMonth = getSDDayObject(value);
        console.log("value", value, this.currentMonth);
      });
  }

  saveDate() {
    this.isUpdating = true;
    this.taskRepo.updateTaskById(this.task.id, {due_date: DateUtils.toISODateString(this.newDate.value)})
      .subscribe((task) => {
          this.isUpdating = false;
          this.dialog.closeAll();
        },
        (error) => this.isUpdating = false);

  }
}
