import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AutomationOffsetPosition, CommonEnumHelpers } from "app/enums/common.enum";
import Quill from "quill";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import {
  OnboardingTaskAssigneeType,
  OnboardingTaskEnumHelper,
  OnboardingTaskOffsetReference
} from "../../../../enums/onboarding-task.enum";
import { TaskCategory, TaskEnumHelper, TaskPaymentBy } from "../../../../enums/task.enum";
import { MentionVariable } from "../../../../models/mention-variable";
import { OnboardingTaskExpense } from "../../../../models/new/tasks/onboarding-task-expense.model";
import { OnboardingTaskFull } from "../../../../models/new/tasks/onboarding-task-full.model";
import { UserCompact } from "../../../../models/new/user/user-compact.model";
import { UserModelUtil } from "../../../../models/utils/user-model.util";
import { OnboardingTaskRepository } from "../../../../repository/onboarding-task.repository";
import { OptionsRepository } from "../../../../repository/options.repository";
import { CommonUtil } from "../../../../utils/common.util";
import { QuillUtils } from "../../../../utils/quill.util";
import { ItemDescriptionPopupComponent } from "../../../tasks/components/popups/item-description-popup";
import { TaskNoteUpdatePopupComponent } from "../../../tasks/components/popups/task-note-update-popup.component";

@Component({
  selector: "sd-add-edit-onboarding-task",
  template: `
    <sd-center-spinner *ngIf="isLoading && !onboardingTaskNotExist"></sd-center-spinner>
    <sd-modal-popup-layout *ngIf="!isLoading && !onboardingTaskNotExist" [title]="headerTitle">
      <form fxLayout="column" [formGroup]="formGroup">
        <div fxLayout="column" fxLayoutGap="15px" style="width: 100%">
          <mat-form-field [color]="'accent'">
            <input matInput placeholder="Title" [formControl]="title">
            <mat-error *ngIf="titleError">Title is required.</mat-error>
          </mat-form-field>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field [color]="'accent'" class="width50">
              <mat-select placeholder="Who Will Pay?*" [formControl]="paymentBy">
                <mat-option *ngFor="let paymentByOption of paymentByOptions" [value]="paymentByOption">
                  {{TaskEnumHelper.getTaskPaymentByTitle(paymentByOption)}}
                </mat-option>
              </mat-select>
            </mat-form-field>


            <mat-form-field [color]="'accent'" class="width50">
              <mat-select placeholder="Category*" [formControl]="type">
                <mat-option *ngFor="let category of categories" [value]="category">
                  {{ TaskEnumHelper.getTaskCategoryTitle(category) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="column" style="width: 100%">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <mat-form-field [color]="'accent'" class="width50">
                <mat-select (selectionChange)="disablerFunction()"
                            [disabled]="!!assigneeID.value"
                            placeholder="Assign To Assignee Type*"
                            [formControl]="assigneeType"
                            #assigneeTypeForm>
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let assignee of assigneeTypes" [value]="assignee">
                    {{OnboardingTaskEnumHelper.getOnboardingTaskAssigneeType(assignee)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field [color]="'accent'" class="width50">
                <mat-select (selectionChange)="disablerFunction()"
                            [disabled]="!!assigneeType.value"
                            placeholder="Assignee To Assignee*"
                            [formControl]="assigneeID"
                            #assigneeIdForm>
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let assignee of assignees" [value]="assignee.id">
                    {{ UserModelUtil.getFullName(assignee) }}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>

            <div style="display: inline-block ; text-align: center ; color: red"
                 *ngIf="showMatErrorForIDandType">
              <p>Either Assignee type or Assignee ID needs to be selected</p>
            </div>
          </div>

          <div fxLayout="row" style="transform: translateY(20px)">
            <p style="font-size: 18px ; font-weight: bold">Directions</p>
            <span fxFlex="1 1 auto"></span>
            <button type="button" mat-button matSuffix mat-icon-button (click)="openDescriptionPopup()">
              <mat-icon>fullscreen</mat-icon>
            </button>
          </div>
          <div #messageTextArea id="descriptionMessage" class="textArea">
          </div>

          <div fxLayout="column">
            <div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
              <span>Must be complete by:</span>
              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                   style="width: 100%">
                <mat-form-field [color]="'accent'" class="width-24">
                  <mat-select placeholder="Days"
                              [(ngModel)]="daysSelected"
                              [ngModelOptions]="{standalone: true}">
                    <mat-option [value]="1">1 day</mat-option>
                    <mat-option [value]="2">2 days</mat-option>
                    <mat-option [value]="3">3 days</mat-option>
                    <mat-option [value]="4">4 days</mat-option>
                    <mat-option [value]="5">5 days</mat-option>
                    <mat-option [value]="6">6 days</mat-option>
                    <mat-option [value]="7">7 days</mat-option>
                    <mat-option [value]="8">8 days</mat-option>
                    <mat-option [value]="9">9 days</mat-option>
                    <mat-option [value]="10">10 days</mat-option>
                    <mat-option [value]="11">11 days</mat-option>
                    <mat-option [value]="12">12 days</mat-option>
                    <mat-option [value]="13">13 days</mat-option>
                    <mat-option [value]="14">14 days</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field [color]="'accent'" class="width-24">
                  <mat-select placeholder="Position"
                              [(ngModel)]="offset_position"
                              [disabled]="offset_reference === OnboardingTaskOffsetReference.FIRST_RESERVATION"
                              [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let position of positions" [value]='position'>
                      {{ CommonEnumHelpers.getAutomationOffsetPositionTitle(position)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field [color]="'accent'" class="width-24">
                  <mat-select placeholder="Reference"
                              [(ngModel)]="offset_reference"
                              (ngModelChange)="onReferenceChange($event)"
                              [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let reference of references" [value]='reference'>
                      {{ OnboardingTaskEnumHelper.getOnboardingTaskOffsetReferenceTitle(reference) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
               style="margin-bottom: 10px; margin-top: 10px;">
            <mat-checkbox [formControl]="cleaningFeeExpense">Add Cleaning Cost Expense</mat-checkbox>
            <mat-checkbox [formControl]="stateTaxExpense">Add Tax Expense</mat-checkbox>
            <mat-checkbox [formControl]="commissionAmountExpense">Add Commission Amount Expense</mat-checkbox>
          </div>

          <div fxLayoutAlign="space-between center" fxFlex="50%" class="responsiveWidth">
            <span fxFlex="80%">Does this task have expenses?</span>
            <mat-slide-toggle [checked]="showExpense" (change)="expenseToggleChange($event)">
            </mat-slide-toggle>
          </div>
          <div *ngIf="showExpense" fxLayout="column">

            <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px" class="heading">
              <span style="width: 55%">Product</span>
              <span style="width: 10%">Quantity</span>
              <span style="width: 10%">Price</span>
              <span style="width: 15%">Amount</span>
            </div>
            <hr>
            <div fxLayout="column" *ngFor="let expense of expenses; let index = index">
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
                <div style="width: 55%" fxLayout="row">
                  <mat-form-field [color]="'accent'" style="width: 38%">
                    <input matInput
                           [(ngModel)]="expense.title"
                           [ngModelOptions]="{standalone: true}"
                           placeholder="Name">
                  </mat-form-field>
                  <mat-form-field [color]="'accent'" style="width: 60%">
                    <input matInput
                           [(ngModel)]="expense.desc"
                           [ngModelOptions]="{standalone: true}"
                           placeholder="Description">
                    <button mat-button matSuffix mat-icon-button type="button"
                            (click)="openItemDescriptionPopup(index)">
                      <mat-icon>fullscreen</mat-icon>
                    </button>
                  </mat-form-field>
                </div>

                <mat-form-field [color]="'accent'" style="width: 10%">
                  <input matInput
                         [(ngModel)]="expense.quantity"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="Quantity">
                </mat-form-field>

                <mat-form-field [color]="'accent'" style="width: 10%">
                  <span matPrefix>$ &nbsp;</span>
                  <input matInput
                         [(ngModel)]="expense.price"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="Price">
                </mat-form-field>

                <span style="width: 15%">$ {{amountCalculatePerItem(expense.quantity, expense.price)}}</span>
                <mat-icon *ngIf="expenses.length > 0" (click)="removeItem(index)"
                          style="cursor:pointer">
                  delete
                </mat-icon>
              </div>
            </div>
            <div style="margin-top: 10px; margin-bottom: 10px" fxLayoutAlign="center center">
              <button mat-button style="color: #2d7cff"
                      fxLayoutGap="10px" (click)="addItem()" type="button">
                <mat-icon>add_circle</mat-icon>
                <span style="font-size: medium"> Add a new item</span>
              </button>
            </div>
            <hr>
          </div>
        </div>

        <div fxLayoutGap="10px" fxLayoutAlign=" center" fxFlexAlign="end">
          <mat-spinner color="accent" *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <button mat-raised-button color="warn" type="button" *ngIf="onboardingTaskFull" [disabled]="isSaving"
                  (click)="deleteTask()">
            Delete
          </button>
          <button mat-raised-button fxFlexAlign="end"
                  [disabled]="!formGroup.valid|| isSaving || this.showMatErrorForIDandType"
                  color="accent" (click)="saveButtonCLicked()">
            {{buttonTitle}}
          </button>
        </div>
      </form>
    </sd-modal-popup-layout>

    <div *ngIf="onboardingTaskNotExist">
      <h3>This Onboarding Task Does Not Exist.</h3>
    </div>
  `,
  styles: [`
    .textArea {
      width: 100%;
      min-height: 100px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-color: #194267;
      padding: 10px;
      border-style: solid;
      margin: 2px;
      border-width: 1px;
      border-color: #194267;
      border-radius: 3px;
    }

    .width50 {
      width: 48%;
      margin-top: -20px;
    }

    textarea {
      resize: none;
    }

    hr {
      width: 100%;
    }

    mat-card {
      margin-top: 10px;
      width: 1in;
      height: 1in;
    }

    .icon {
      background: transparent;
      border: none !important;
      font-size: 0;
    }

    mat-spinner {
      height: 30px;
      width: 30px;
    }

    .width-24 {
      width: 24%;
    }

    @media only screen and (max-width: 600px) {

      [class*="width-"] {
        width: 95%;
      }

      .responsiveWidth {
        width: 95%;
      }
    }
  `]
})

export class OnboardingTaskAddEditComponent implements OnInit, OnDestroy {
  quillRenderList;
  quillBody: Quill;
  message = "";
  quillMentionList: MentionVariable[] = [];
  titleVariables = QuillUtils.VARIABLES;
  listingVariables = QuillUtils.LISTING_VARIABLES;

  headerTitle = "New Onboarding Task";
  buttonTitle = "Create";
  titleError = false;
  showMatErrorForIDandType = true;
  isSaving = false;
  offset_reference = OnboardingTaskOffsetReference.CREATE_DATE;
  oldReference = this.offset_reference;
  offset_position = AutomationOffsetPosition.AFTER;
  assignees: UserCompact[] = [];
  expenses: OnboardingTaskExpense[] = [];
  daysSelected: number;
  showExpense = false;
  onboardingTaskFull: OnboardingTaskFull;
  onboardingTaskId: number;
  isLoading: boolean;
  isLoaded: boolean;
  onboardingTaskNotExist: boolean = false;

  formGroup: FormGroup;
  assigneeType: FormControl;
  assigneeID: FormControl;
  title: FormControl;
  type: FormControl;
  dueDate: FormControl;
  description: FormControl;
  paymentBy: FormControl;
  cleaningFeeExpense: FormControl;
  stateTaxExpense: FormControl;
  commissionAmountExpense: FormControl;

  paymentByOptions = CommonUtil.getPropsOfEnum<TaskPaymentBy>(TaskPaymentBy);
  categories = CommonUtil.getPropsOfEnum<TaskCategory>(TaskCategory);
  positions = CommonUtil.getPropsOfEnum<AutomationOffsetPosition>(AutomationOffsetPosition);
  references = CommonUtil.getPropsOfEnum<OnboardingTaskOffsetReference>(OnboardingTaskOffsetReference);
  assigneeTypes = CommonUtil.getPropsOfEnum<OnboardingTaskAssigneeType>(OnboardingTaskAssigneeType);

  TaskEnumHelper = TaskEnumHelper;
  UserModelUtil = UserModelUtil;
  CommonEnumHelpers = CommonEnumHelpers;
  OnboardingTaskOffsetReference = OnboardingTaskOffsetReference;
  OnboardingTaskEnumHelper = OnboardingTaskEnumHelper;

  private destroyed$ = new Subject();

  constructor(private dialog: MatDialog,
              private optionsRepo: OptionsRepository,
              private snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<OnboardingTaskAddEditComponent>,
              private onboardingTaskRepo: OnboardingTaskRepository) {
    this.title = new FormControl(null, [Validators.required]);
    this.assigneeID = new FormControl(null);
    this.assigneeType = new FormControl(null);
    this.type = new FormControl(null, [Validators.required]);
    this.dueDate = new FormControl(null, [Validators.required]);
    this.description = new FormControl();
    this.paymentBy = new FormControl(null, [Validators.required]);
    this.cleaningFeeExpense = new FormControl(false);
    this.stateTaxExpense = new FormControl(false);
    this.commissionAmountExpense = new FormControl(false);

    this.formGroup = new FormGroup({
      title: this.title,
      assignee_type: this.assigneeType,
      type: this.type,
      description: this.description,
      assignee_id: this.assigneeID,
      payment_by: this.paymentBy,
      include_cleaning_fee_expense: this.cleaningFeeExpense,
      include_state_tax_expense: this.stateTaxExpense,
      include_commission_amount_expense: this.commissionAmountExpense
    });
  }

  ngOnInit() {
    this.setupAssignees();

    if (!isNullOrUndefined(this.onboardingTaskId) && !isNaN(this.onboardingTaskId)) {
      this.onboardingTaskRepo.getTaskById(this.onboardingTaskId).pipe(filter(c => !!c)).subscribe(task => {
        this.onboardingTaskFull = task;
        this.message = this.onboardingTaskFull.description;
      });

      this.onboardingTaskRepo.getIsFullOnboardingTaskLoading(this.onboardingTaskId).subscribe(l => {
        this.isLoading = l;
      });
      this.onboardingTaskRepo.getIsFullOnboardingTaskLoaded(this.onboardingTaskId).subscribe(l => {
        this.isLoaded = l;
        this.setupForEdit();
        if (this.isLoaded) {
          setTimeout(() => this.setupQuill());
        }
      });

      this.onboardingTaskRepo.getIsOnboardingTaskExist(this.onboardingTaskId).subscribe(res => {
        this.onboardingTaskNotExist = res;
      });
    } else {
      setTimeout(() => this.setupQuill());
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setQuillMentionList() {
    this.quillMentionList = this.offset_reference === OnboardingTaskOffsetReference.FIRST_RESERVATION ? this.titleVariables: this.listingVariables;
  }

  setupQuill() {
    this.setQuillMentionList();
    this.quillBody = new Quill("#descriptionMessage", {
      theme: "bubble",
      modules: {
        mention: {
          allowedChars: /^[_A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["{"],
          isolatedCharacter: false,
          source: (searchTerm, renderList, mentionChar) => {
            this.quillRenderList = renderList;
            if (searchTerm.length === 0) {
              renderList(this.quillMentionList, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < this.quillMentionList.length; i++) {
                if (this.quillMentionList[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(this.quillMentionList[i]);
                }
              }
              renderList(matches, searchTerm);
            }
          },
          renderItem: (item, searchTerm) => item.value.replace("{", "{{"),
          dataAttributes: ["value"]
        },
      },
    });

    if (this.description.value) {
      this.quillBody.setText(this.description.value);
      this.quillBody.root.innerHTML = QuillUtils.replaceMentionWithClass(this.quillBody.root.innerHTML);
    }

    this.quillBody.on("text-change", () => {
      this.description.setValue(this.quillBody.root.innerText);
    });
  }

  openDescriptionPopup() {
    const dialogRef = this.dialog.open(TaskNoteUpdatePopupComponent, {
      data: this.quillBody.root.innerHTML
    });

    dialogRef.updateSize("100%", "100%");

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.quillBody.root.innerHTML = result;
      }
    });
  }

  onReferenceChange(value: string) {
    this.setQuillMentionList();
    if (this.quillRenderList) {
      this.quillRenderList(this.quillMentionList);
    }
    const parser = new DOMParser();
    const desc = parser.parseFromString(this.description.value, "text/html").body.textContent;
    if (value === OnboardingTaskOffsetReference.FIRST_RESERVATION) {
      this.offset_position = AutomationOffsetPosition.AFTER;
    } else if (this.oldReference === OnboardingTaskOffsetReference.FIRST_RESERVATION && !!desc) {
      this.snackBar.open("Some of the variables might not work.", "Dismiss", {duration: 4000});
    }
    this.oldReference = value as OnboardingTaskOffsetReference;
  }

  expenseToggleChange($event) {
    this.showExpense = $event.checked;
    if (this.showExpense) {
      this.expenses.push({
        title: "",
        desc: "",
        quantity: 0,
        price: 0
      });
    } else {
      this.expenses = [];
    }
  }

  openItemDescriptionPopup(expenseIndex: number) {
    const dialogRef = this.dialog.open(ItemDescriptionPopupComponent, {
      data: {data: this.expenses[expenseIndex].desc}
    });

    dialogRef.updateSize("100%", "100%");

    dialogRef.afterClosed().subscribe(result => {
      this.expenses[expenseIndex].desc = result;
    });
  }

  amountCalculatePerItem(quant, price) {
    const quantity = Number(quant);
    const prices = Number(price);
    if (prices && quantity) {
      let amount = prices * quantity;
      if (amount % 1000 !== amount) {
        amount = amount / 1000;
        return (amount).toFixed(2) + "K";
      } else {
        return (amount).toFixed(2);
      }
    } else {
      return "0.00";
    }
  }

  removeItem(index) {
    this.expenses.splice(index, 1);
  }

  addItem() {
    this.expenses.push({
      title: "",
      desc: "",
      quantity: 0,
      price: 0
    });
  }

  deleteTask() {
    this.isSaving = true;
    this.onboardingTaskRepo.deleteOnboardingTask(this.onboardingTaskFull.id).subscribe(result => {
      this.isSaving = false;
      this.close();
    }, () => {
      this.isSaving = false;
    });
  }

  setupForEdit() {
    if (isNullOrUndefined(this.title.value)) {
      this.titleError = true;
    }
    if (!isNullOrUndefined(this.onboardingTaskFull) && !isNullOrUndefined(this.onboardingTaskFull.id)) {
      this.headerTitle = this.onboardingTaskFull.title;
      this.title.setValue(this.onboardingTaskFull.title);
      this.description.setValue(this.onboardingTaskFull.description);
      this.type.setValue(this.onboardingTaskFull.type);
      this.offset_position = this.onboardingTaskFull.offset_position;
      this.offset_reference = this.onboardingTaskFull.offset_reference;
      this.oldReference = this.offset_reference;
      this.cleaningFeeExpense.setValue(this.onboardingTaskFull.include_cleaning_fee_expense);
      this.stateTaxExpense.setValue(this.onboardingTaskFull.include_state_tax_expense);
      this.commissionAmountExpense.setValue(this.onboardingTaskFull.include_commission_amount_expense);
      this.paymentBy.setValue(this.onboardingTaskFull.payment_by);

      this.daysSelected = this.onboardingTaskFull.offset;

      if (!isNullOrUndefined(this.onboardingTaskFull.expenses)) {
        this.showExpense = (this.onboardingTaskFull.expenses.length > 0);
        for (const expense of this.onboardingTaskFull.expenses) {
          this.expenses.push({
            title: expense.title,
            desc: expense.desc,
            quantity: Number(expense.quantity),
            price: Number(expense.price * 100) / 100,
          });
        }
      }

      if (this.onboardingTaskFull.assignee_type !== null) {
        this.assigneeType.setValue(this.onboardingTaskFull.assignee_type);
      }

      if (this.onboardingTaskFull.assignee_id !== null) {
        this.assigneeID.setValue(this.onboardingTaskFull.assignee_id);
      }

      this.buttonTitle = "Save";
    }
    this.disablerFunction();
  }

  saveButtonCLicked() {
    this.isSaving = true;
    const data = {
      ...this.formGroup.value,
      description: QuillUtils.removeExtraLines(this.description.value || "")
    };
    if (this.showExpense) {
      const expensesArray = [];

      for (const expense of this.expenses) {
        expense.quantity = Number(expense.quantity);
        expense.price = Number(expense.price * 100) / 100;

        expensesArray.push(expense);
      }

      if (expensesArray.length > 0) {
        data.expenses = expensesArray;
      } else {
        data.expenses = [];
      }
    }

    data.offset_position = this.offset_position;
    data.offset_reference = this.offset_reference;
    data.offset = this.daysSelected;

    if (this.assigneeID.value) {
      delete data.assignee_type;
    } else {
      delete data.assignee_id;
    }

    if (isNullOrUndefined(this.onboardingTaskFull)) {
      this.onboardingTaskRepo.createOnboardingTask(data).subscribe(task => {
        this.isSaving = false;
        this.close();
      }, () => {
        this.isSaving = false;
      });
    } else {
      this.onboardingTaskRepo.updateOnboardingTask(this.onboardingTaskFull.id, data).subscribe(task => {
        this.isSaving = false;
        this.close();
      }, () => {
        this.isSaving = false;
      });
    }
  }

  disablerFunction() {
    this.showMatErrorForIDandType = (!this.assigneeID.value && !this.assigneeType.value);
  }

  close() {
    this.dialogRef.close();
  }

  private setupAssignees() {
    this.optionsRepo.getTaskAssignees().pipe(takeUntil(this.destroyed$))
      .subscribe((assignees) => {
        this.assignees = assignees;
      });
  }
}
