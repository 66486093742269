import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { filter, takeWhile } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { UserFull } from "../../models/new/user/user-full.model";
import { UserModelUtil } from "../../models/utils/user-model.util";
import { UserRepository } from "../../repository/user-repository";
import { AppService } from "../../services/app.service";
import { getContactMethodTypes } from "../../utils/utils";
import { CommonUtil } from "../../utils/common.util";
import { ChangePasswordPopupComponent } from "../../modules/shared/components/change-password-popup";

@Component({
  selector: "sd-homeowner-profile-container",
  template: `
    <sd-owner-main-layout-fullwidth>
      <div id="spinner" *ngIf="userLogging" fxLayout="row" fxLayoutAlign="center center" fxFlex="100%">
        <mat-spinner color="accent" [diameter]="60" [strokeWidth]="5"></mat-spinner>
      </div>
      <div *ngIf="userLogged" class="requiredHeight" style="margin-bottom: 50px" fxFlex="100%" fxLayout="column"
           fxLayoutAlign="flex-start">
        <div class="pre1" fxFlex="100%">
          <section class="content">
            <div fxLayout="column" class="image" style="margin-top:15%" fxLayoutAlign="center center">
              <img *ngIf="uploadProgress == 0" (click)="updateImage()" class="profileImage"
                   src="{{getProfilePic(user?.pic_thumb_url)}}">
              <input type="file" accept="image/*" style="visibility: hidden" #addImageWrapper>
              <span *vaFlexAlignmentHack></span>
              <div class="profileImage" fxLayoutAlign="center center" *ngIf="uploadProgress != 0">
                <span style="color: white; font-size: large">{{uploadProgress}} %</span>
              </div>
              <p style="color:#fff;font-size:26px" align="center"><b>{{UserModelUtil.getFullName(user)}}</b></p>
            </div>
          </section>
        </div>

        <div style="overflow-y: hidden;">

          <mat-tab-group style="margin-left: 30px; margin-bottom: 30px;margin-right: 30px;">
            <mat-tab label="USER PROFILE">
              <sd-homeowner-profile [user]="user"></sd-homeowner-profile>
            </mat-tab>

            <mat-tab label="DOCUMENTS">
              <sd-contact-w9-form-info [contact]="user"></sd-contact-w9-form-info>
            </mat-tab>

            <mat-tab *ngIf="!isHomeOwner" label="BANK INFO">
              <sd-contact-bank-info [showRoutingRules]="false" [contact]="user"></sd-contact-bank-info>
            </mat-tab>

            <mat-tab *ngIf="isHouseKeeper && !isEmployee" label="EMPLOYEE">
              <sd-contact-employee [contact]="user"></sd-contact-employee>
            </mat-tab>

          </mat-tab-group>

          <div style="margin-top:10px"></div>
        </div>


      </div>
    </sd-owner-main-layout-fullwidth>
  `,
  styles: [`

    #spinner {
      position: fixed;
      top: 45%;
      right: 50%;
    }

    #primary {
      width: 24px;
      height: 24px;
    }

    .profileImage {
      display: block;
      width: 150px;
      height: 150px;
      margin: 1em auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-border-radius: 99em;
      -moz-border-radius: 99em;
      border-radius: 99em;
      border: 5px solid #eee;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
    }

    .pre1 {
      max-height: 40%;
      width: 100%;
      flex: 0 0 0% !important;
      display: table;
      background-image: url(https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/user_cover.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #464646;
    }

    .width-1 {
      width: 48%;
    }

    .width-2 {
      width: 98%;
    }

    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 95%;
      }
    }
  `]
})

export class HomeownerProfileContainerComponent implements OnInit, OnDestroy, AfterViewInit {

  user: UserFull;
  userLogging: boolean;
  userLogged: boolean;
  showRoutingRules = false;
  isProfileUpdating = false;
  userProfileForm: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  userEmail: FormControl;
  userSecondaryEmail: FormControl;
  userPhoneNumber: FormControl;
  userSecondaryPhoneNumber: FormControl;
  preferredContactMethod: FormControl;
  userNotes: FormControl;
  contactMethods;
  uploadProgress = 0;
  UserModelUtil = UserModelUtil;
  isHouseKeeper = false;
  isHomeOwner = false;
  isEmployee = false;
  @ViewChild("addImageWrapper", {read: ElementRef, static: true}) addImageWrapper: ElementRef;
  private isAlive = true;

  constructor(private userRepo: UserRepository,
              private appService: AppService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.setupUser();
  }

  ngAfterViewInit() {
    this.appService.changeAppTitle("Profile");
  }

  setupUser() {
    this.contactMethods = getContactMethodTypes();

    this.userRepo.getIsUserLoggingIn().pipe(takeWhile(() => this.isAlive))
      .subscribe(l => this.userLogging = l);

    this.userRepo.getIsUserLoggedIn().pipe(takeWhile(() => this.isAlive))
      .subscribe(l => this.userLogged = l);

    this.userRepo.getUser().pipe(takeWhile(() => this.isAlive), filter(f => !!f),)
      .subscribe((user) => {
        this.user = user;
        this.isHouseKeeper = CommonUtil.isHouseKeeper(user);
        this.isHomeOwner = CommonUtil.isHomeOwner(user);
        this.isEmployee = CommonUtil.isEmployee(user);
        console.log("user repo", user);

        if (this.userLogged) {

          this.firstName = new FormControl(this.user.first_name, [Validators.required]);
          this.lastName = new FormControl(this.user.last_name, [Validators.required]);
          this.userEmail = new FormControl(this.user.email, [Validators.required, Validators.email]);
          this.userSecondaryEmail = new FormControl(this.user.secondary_email);
          this.userPhoneNumber = new FormControl(this.user.phone_number, [Validators.required]);
          this.userSecondaryPhoneNumber = new FormControl(this.user.secondary_phone_number);
          this.preferredContactMethod = new FormControl(this.user.preferred_contact_method, [Validators.required]);
          this.userNotes = new FormControl(this.user.description);

          this.userProfileForm = new FormGroup({
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.userEmail,
            secondary_email: this.userSecondaryEmail,
            phone_number: this.userPhoneNumber,
            secondary_phone_number: this.userSecondaryPhoneNumber,
            preferred_contact_method: this.preferredContactMethod,
            description: this.userNotes,
          });

        }
      });

  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  getProfilePic(image: string) {
    if (isNullOrUndefined(image)) {
      return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/user_placeholder.jpg";
    } else {
      return image;
    }
  }

  profileUpdate() {
    this.isProfileUpdating = true;
    const data = this.userProfileForm.value;
    if (this.userProfileForm.get("email").value === this.user.email) {
      delete data.email;
      console.log(data);
    }
    this.userRepo.updateProfile(data).subscribe(() => {
      this.isProfileUpdating = false;
    }, () => {
      this.isProfileUpdating = false;
    });
  }

  openchangePassForm() {
    const dialogRef = this.dialog.open(ChangePasswordPopupComponent);
    dialogRef.updateSize("100%");
  }

  updateImage() {
    this.addImageWrapper.nativeElement.click();

    this.addImageWrapper.nativeElement.onchange = (event) => {
      if (event.target.files.length > 0) {

        this.userRepo.updateUserPic({image: event.target.files[0]}).subscribe((event: { progress?: any, response?: any }) => {

          if (event.response) {
            this.uploadProgress = 0;
          } else {
            this.uploadProgress = event.progress;
          }
        });
      }
    };

  }
}
