import { SavedNeighbourhoodDraft } from "../../../../models/new/drafts/saved-neighbourhood-draft.model";
import { Action } from "../../../action";

export enum SavedNeighbourhoodDraftActionTypes {
  INDEX_REQUEST = "[Saved Neighbourhood Drafts NEW] Index Request",
  INDEX_SUCCESS = "[Saved Neighbourhood Drafts NEW] Index Success",

  CREATE_REQUEST = "[Saved Neighbourhood Drafts NEW] Create Request",
  CREATE_SUCCESS = "[Saved Neighbourhood Drafts NEW] Create Success",

  SHOW_REQUEST = "[Saved Neighbourhood Drafts NEW] Show Request",
  SHOW_SUCCESS = "[Saved Neighbourhood Drafts NEW] Show Success",

  UPDATE_REQUEST = "[Saved Neighbourhood Drafts NEW] Update Request",
  UPDATE_SUCCESS = "[Neighbourhood Drafts NEW] Update Success",

  DELETE_REQUEST = "[Saved Neighbourhood Drafts NEW] Delete Request",
  DELETE_SUCCESS = "[Saved Neighbourhood Drafts NEW] Delete Success"
}

export class SavedNeighbourhoodDraftIndexRequest implements Action {
  readonly type = SavedNeighbourhoodDraftActionTypes.INDEX_REQUEST;
}

export class SavedNeighbourhoodDraftIndexSuccess implements Action {
  readonly type = SavedNeighbourhoodDraftActionTypes.INDEX_SUCCESS;

  constructor(public payload: SavedNeighbourhoodDraft[]) {
  }
}

export class SavedNeighbourhoodDraftCreateRequest implements Action {
  readonly type = SavedNeighbourhoodDraftActionTypes.CREATE_REQUEST;
}

export class SavedNeighbourhoodDraftCreateSuccess implements Action {
  readonly type = SavedNeighbourhoodDraftActionTypes.CREATE_SUCCESS;

  constructor(public payload: SavedNeighbourhoodDraft) {
  }
}

export class SavedNeighbourhoodDraftShowRequest implements Action {
  readonly type = SavedNeighbourhoodDraftActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {

  }
}

export class SavedNeighbourhoodDraftShowSuccess implements Action {
  readonly type = SavedNeighbourhoodDraftActionTypes.SHOW_SUCCESS;

  constructor(public payload: SavedNeighbourhoodDraft) {
  }
}

export class SavedNeighbourhoodDraftUpdateRequest implements Action {
  readonly type = SavedNeighbourhoodDraftActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class SavedNeighbourhoodDraftUpdateSuccess implements Action {
  readonly type = SavedNeighbourhoodDraftActionTypes.UPDATE_SUCCESS;

  constructor(public payload: SavedNeighbourhoodDraft) {
  }
}

export class SavedNeighbourhoodDraftDeleteRequest implements Action {
  readonly type = SavedNeighbourhoodDraftActionTypes.DELETE_REQUEST;

  constructor(public payload: number) {
  }
}

export class SavedNeighbourhoodDraftDeleteSuccess implements Action {
  readonly type = SavedNeighbourhoodDraftActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
