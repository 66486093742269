import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { __HTTPResponseType } from "../enums/common.enum";
import { RoutingRule } from "../models/new/listing/routing-rule";
import { ApiService } from "./api.service";

@Injectable()
export class RoutingRulesService {

  constructor(private apiService: ApiService) {
  }


  getRules(listingId: number): Observable<RoutingRule[]> {
    return this.apiService.get<{ data: RoutingRule[] }>("/routing-rules/" + listingId, true, {
      include: "bankAccount"
    }).pipe(map(res => res.data));
  }

  updateRule(ruleId: number, listingId: number, bankAccountId: number, percentage: number): Observable<RoutingRule> {
    const data = {
      property_id: listingId,
      bank_account_id: bankAccountId,
      percentage: percentage,
      include: "bankAccount"
    };

    return this.apiService.put<{ data: RoutingRule }>("/routing-rules/" + ruleId, true, data)
      .pipe(map(res => res.data));
  }

  createRule(listingId: number, accounts: { bank_account_id: number, percentage: number }[]): Observable<RoutingRule[]> {
    const data = {
      property_id: listingId,
      bank_accounts: accounts,
      include: "bankAccount"
    };

    return this.apiService.post<{ data: RoutingRule[] }>("/routing-rules", true, data)
      .pipe(map(res => res.data));
  }

  deleteRule(ruleId: number): Observable<any> {
    return this.apiService.delete("/routing-rules/" + ruleId, true, null, null, true, __HTTPResponseType.TEXT);
  }

}
