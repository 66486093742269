import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import {
  RatingReviewsCreateReview,
  RatingReviewsIndexRequest,
  RatingReviewsIndexSuccess, RatingReviewsListingsChange, RatingReviewsPageChange,
  RatingReviewsPerPageChange, RatingReviewsSortOrderChange
} from "../actions/new/reviews/rating-reviews";
import {
  RespondReviewsCreateResponse,
  RespondReviewsIndexRequest,
  RespondReviewsIndexSuccess,
  RespondReviewsListingsChange,
  RespondReviewsPageChange,
  RespondReviewsPerPageChange,
  RespondReviewsRatingChange,
  RespondReviewsSortOrderChange,
  RespondReviewsSortTypeChange,
  RespondReviewsTaskData
} from "../actions/new/reviews/respond-reviews";
import { ReviewsSortType, SortOrder } from "../enums/common.enum";
import { GuestReview } from "../models/new/reviews/guest-review";
import { OwnerReview } from "../models/new/reviews/owner-review";
import {
  getAllRatingReviews,
  getAllRespondReviews,
  getIsRatingReviewsLoaded,
  getIsRatingReviewsLoading,
  getIsRespondReviewsLoaded,
  getIsRespondReviewsLoading,
  getRatingReviewsCurrentPage, getRatingReviewsListing,
  getRatingReviewsPerPage,
  getRatingReviewsSortOrder,
  getRatingReviewsTotal,
  getRespondReviewsCurrentPage,
  getRespondReviewsListing,
  getRespondReviewsPerPage,
  getRespondReviewsRating,
  getRespondReviewsSortOrder,
  getRespondReviewsSortType, getRespondReviewsTaskData,
  getRespondReviewsTotal,
  State
} from "../reducers";
import { ReviewService } from "../services/review.service";

@Injectable()
export class ReviewRepository {
  constructor(private reviewService: ReviewService,
              private store: Store<State>) {
  }

  getIsRespondReviewsLoading(): Observable<boolean> {
    return this.store.select(getIsRespondReviewsLoading);
  }

  getIsRespondReviewsLoaded(): Observable<boolean> {
    return this.store.select(getIsRespondReviewsLoaded);
  }

  getIsRatingReviewsLoading(): Observable<boolean> {
    return this.store.select(getIsRatingReviewsLoading);
  }

  getIsRatingReviewsLoaded(): Observable<boolean> {
    return this.store.select(getIsRatingReviewsLoaded);
  }

  getTotalCount(): Observable<number> {
    return this.store.select(getRespondReviewsTotal);
  }

  getCurrentPage(): Observable<number> {
    return this.store.select(getRespondReviewsCurrentPage);
  }

  getPerPageCount(): Observable<number> {
    return this.store.select(getRespondReviewsPerPage);
  }

  getRatingTotalCount(): Observable<number> {
    return this.store.select(getRatingReviewsTotal);
  }

  getRatingCurrentPage(): Observable<number> {
    return this.store.select(getRatingReviewsCurrentPage);
  }

  getRatingPerPageCount(): Observable<number> {
    return this.store.select(getRatingReviewsPerPage);
  }

  getReviewListingIds(): Observable<number[]> {
    return this.store.select(getRespondReviewsListing);
  }

  getRatingListingIds(): Observable<number[]> {
    return this.store.select(getRatingReviewsListing);
  }

  getReviewRating(): Observable<number[]> {
    return this.store.select(getRespondReviewsRating);
  }

  getSortOrder(): Observable<SortOrder> {
    return this.store.select(getRespondReviewsSortOrder);
  }

  getRatingSortOrder(): Observable<SortOrder> {
    return this.store.select(getRatingReviewsSortOrder);
  }

  getSortType(): Observable<ReviewsSortType> {
    return this.store.select(getRespondReviewsSortType);
  }

  getTaskData(): Observable<{ review: GuestReview, response: string, draftId: number }> {
    return this.store.select(getRespondReviewsTaskData);
  }

  setTaskData(data: { review: GuestReview, response: string, draftId: number }) {
    return this.store.dispatch(new RespondReviewsTaskData(data));
  }

  changePerPageCount(perPageCount: number) {
    return this.store.dispatch(new RespondReviewsPerPageChange(perPageCount));
  }

  changeCurrentPage(currentPage: number) {
    return this.store.dispatch(new RespondReviewsPageChange(currentPage));
  }

  changeRatingPerPageCount(perPageCount: number) {
    return this.store.dispatch(new RatingReviewsPerPageChange(perPageCount));
  }

  changeRatingCurrentPage(currentPage: number) {
    return this.store.dispatch(new RatingReviewsPageChange(currentPage));
  }

  changeSortOrder(sortOrder: SortOrder) {
    return this.store.dispatch(new RespondReviewsSortOrderChange(sortOrder));
  }

  changeRatingSortOrder(sortOrder: SortOrder) {
    return this.store.dispatch(new RatingReviewsSortOrderChange(sortOrder));
  }

  changeSortType(sortType: ReviewsSortType) {
    return this.store.dispatch(new RespondReviewsSortTypeChange(sortType));
  }

  changeReviewRating(rating: number[]) {
    return this.store.dispatch(new RespondReviewsRatingChange(rating));
  }

  changeReviewListings(listingIds: number[]) {
    return this.store.dispatch(new RespondReviewsListingsChange(listingIds));
  }

  changeRatingListings(listingIds: number[]) {
    return this.store.dispatch(new RatingReviewsListingsChange(listingIds));
  }

  getRespondReviews(data: {
    sort_type: string,
    sort_order: string,
    per_page: number,
    page: number,
    listing_ids: number[],
    rating: number[]
  },                force: boolean = false): Observable<GuestReview[]> {
    let loading: boolean;
    let loaded: boolean;

    this.getIsRespondReviewsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsRespondReviewsLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new RespondReviewsIndexRequest());
      this.reviewService.getRespondReviews(data)
        .subscribe(res => {
          this.store.dispatch(new RespondReviewsIndexSuccess({
            reviews: res.data,
            currentPage: res.meta.pagination.current_page,
            perPage: res.meta.pagination.per_page,
            total: res.meta.pagination.total
          }));
        });
    }
    return this.store.select(getAllRespondReviews).pipe(
      filter(r => !!r));
  }

  getRatingReviews(data: {
    sort_type: string,
    sort_order: string,
    per_page: number,
    page: number,
    listing_ids: number[]
  },               force: boolean = false): Observable<OwnerReview[]> {
    let loading: boolean;
    let loaded: boolean;

    this.getIsRatingReviewsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsRatingReviewsLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new RatingReviewsIndexRequest());
      this.reviewService.getRatingReviews(data)
        .subscribe(res => {
          this.store.dispatch(new RatingReviewsIndexSuccess({
            reviews: res.data,
            currentPage: res.meta.pagination.current_page,
            perPage: res.meta.pagination.per_page,
            total: res.meta.pagination.total
          }));
        });
    }
    return this.store.select(getAllRatingReviews).pipe(
      filter(r => !!r));
  }

  createOwnerReview(data): Observable<OwnerReview> {
    return this.reviewService.createOwnerReview(data).pipe(map(res => {
      this.store.dispatch(new RatingReviewsCreateReview(res.id));
      return res;
    }));
  }

  createResponse(data, reviewId): Observable<boolean> {
    return this.reviewService.createResponse(data, reviewId).pipe(map(res => {
      this.store.dispatch(new RespondReviewsCreateResponse(reviewId));
      return true;
    }));
  }
}
