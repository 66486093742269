import { environment } from "../../../environments/environment";

let isTracking = false;

export const hubspotId = environment.production
  ? "1835469"
  : "6866472";

const hubspotScript = `<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/${hubspotId}.js"></script>`;

export const setupHubspot = () => {
  document.write(hubspotScript);
  console.log("setup called");
  return () => {
  };
};

export const setupStaticHubspot = () => {
  const scriptElem = document.createElement("script");
  scriptElem.type = "text/javascript";
  scriptElem.async = true;
  scriptElem.defer = true;
  scriptElem.id = "hs-script-loader";
  scriptElem.src = `//js.hs-scripts.com/${hubspotId}.js`;
  document.head.appendChild(scriptElem);
};

export const bootHubspot = () => {
  if (!isTracking) {
    isTracking = true;
    (window as any).HubSpotConversations.widget.load();
    console.log("boot called");
  }
  return () => {
  };
};

export const openIM = () => {
  (window as any).HubSpotConversations.widget.open();
};
