import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { ContactRepository } from "../../../../repository/contact.repository";
import { ContactService } from "../../../../services/contact.service";

@Component({
  selector: "sd-contacts-change-password", template: `
    <sd-modal-popup-layout title="Change Password">
      <div *ngIf="!showInput" fxLayout="column" fxLayoutGap="20px">
        <h3><b>How Do You Want to Reset The Password</b></h3>
        <div>
          <mat-radio-group [formControl]="method" fxLayout="column" fxLayoutGap="10px">
            <mat-radio-button value="send_mail">Send Email To Reset Password</mat-radio-button>
            <mat-radio-button value="reset_manually">Reset Password Manually</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxLayout="row" fxLayoutGap="5px" fxFlexAlign="end" style="margin-top:15px;">
          <mat-spinner color="accent" [strokeWidth]="4" [diameter]="30" *ngIf="isUpdating"></mat-spinner>
          <button mat-raised-button color="accent" (click)="reset()" [disabled]="method.value===null">OK</button>
          <button mat-raised-button color="warn" (click)="close()">Cancel</button>
        </div>
      </div>
      <div *ngIf="showInput" fxLayout="column" fxLayoutGap="10px">
        <h3>Enter The Details</h3>
        <form [formGroup]="formGroup" (ngSubmit)="formGroup.valid && change()" fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row" style="width: 100%;">
            <mat-form-field color="accent" style="width: 90%;">
              <input matInput type="{{showPassword?'text':'password'}}" placeholder="Enter New Password"
                     formControlName="password">
            </mat-form-field>
            <button mat-icon-button type="button" (click)="show('password')">
              <mat-icon *ngIf="!showPassword">visibility_on</mat-icon>
              <mat-icon *ngIf="showPassword">visibility_off</mat-icon>
            </button>
          </div>
          <div fxLayout="row" style="width: 100%;">
            <mat-form-field color="accent" style="width: 90%;">
              <input matInput type="{{showConfirmPassword?'text':'password'}}" placeholder="Confirm Password"
                     formControlName="password_confirmation">
            </mat-form-field>
            <button mat-icon-button type="button" (click)="show('confirm_password')">
              <mat-icon *ngIf="!showConfirmPassword">visibility_on</mat-icon>
              <mat-icon *ngIf="showConfirmPassword">visibility_off</mat-icon>
            </button>
          </div>
          <div fxLayout="row" fxLayoutGap="5px" fxFlexAlign="end">
            <mat-spinner color="accent" [strokeWidth]="4" [diameter]="30" *ngIf="isUpdating"></mat-spinner>
            <button mat-raised-button color="accent">Reset Password</button>
            <button mat-raised-button color="warn" type="button" (click)="back()">Back</button>
          </div>
        </form>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`

  `]
})
export class ContactChangePasswordComponent implements OnInit {

  method: FormControl;
  newPassword: FormControl;
  confirmPassword: FormControl;
  showInput: boolean;
  isUpdating: boolean;
  formGroup: FormGroup;
  userId: number;
  showPassword = false;
  showConfirmPassword = false;

  constructor(private contactRepo: ContactRepository,
              private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) private data: any,
              private contactService: ContactService) {
    this.userId = this.data.user_id;
    this.method = new FormControl(null, []);
    this.newPassword = new FormControl(null, [Validators.required]);
    this.confirmPassword = new FormControl(null, [Validators.required]);

  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      password: this.newPassword,
      password_confirmation: this.confirmPassword
    });
  }

  reset() {
    if (this.method.value === "send_mail") {
      this.isUpdating = true;
      const contact$ = this.contactRepo.resetPassword(this.userId).subscribe(res => {
        console.log(res);
        this.isUpdating = false;
        this.close();
      }, (err) => {
        this.isUpdating = false;
      });
    } else if (this.method.value === "reset_manually") {
      this.showInput = true;
    }
  }

  close() {
    this.dialog.closeAll();
  }

  change() {
    this.isUpdating = true;
    const data = this.formGroup.value;
    this.contactService.changePassword(this.userId, data).subscribe(res => {
      console.log(res);
      this.isUpdating = false;
      this.dialog.closeAll();
    }, (err) => {
      console.log(err);
      this.isUpdating = false;
    });
  }

  back() {
    this.formGroup.reset();
    this.method.patchValue(null);
    this.showInput = false;
    this.showPassword = false;
    this.showConfirmPassword = false;
  }

  show(variable) {
    if (variable === "password") {
      this.showPassword = !this.showPassword;
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }
}
