import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import { ContactDocumentType } from "../../../enums/contact.enum";
import { AttachmentType } from "../../../enums/uploading-enum";
import { UserFull } from "../../../models/new/user/user-full.model";
import { W9Form } from "../../../models/new/user/w9-form.model";
import { ContactRepository } from "../../../repository/contact.repository";
import { AWSService } from "../../../services/aws.service";
import { CommonUtil } from "../../../utils/common.util";

@Component({
  selector: "sd-contact-w9-form-info",
  template: `
    <!--W-9 Form-->
    <div fxLayout="column">
      <div fxLayout="row">
        <h1>W9 Form</h1>
        <span fxFlex="1 1 auto"></span>
        <button style="height: fit-content; margin-top: 17px" mat-raised-button color="accent" *ngIf="!w9Uploading"
                (click)="uploadPDF(ContactDocumentType.W9_FORM)">Upload
        </button>
      </div>
      <mat-progress-bar *ngIf="w9Uploading" style="margin-bottom: 10px" mode="determinate" [value]="w9UploadProgress"
                        color="accent"></mat-progress-bar>
      <div *ngIf="w9Uploading" fxFlexAlign="center" fxLayout="row" fxLayoutGap="5px">
        <span>Uploading...</span>
        <mat-icon>cloud_upload</mat-icon>
      </div>
      <div *ngIf="w9Forms.length === 0&&!w9Uploading" class="error" fxLayout="row" fxLayoutAlign="center center"
           fxLayoutGap="5px">

        <div class="position">
          <mat-icon>error</mat-icon>
        </div>
        <span class="size">No W9 Form</span>
      </div>

      <ng-container *ngIf="w9Forms.length!==0">
        <mat-grid-list cols="6" gutterSize="2px">
          <mat-grid-tile *ngFor="let image of w9Forms">
            <div class="grid-tile-container">
              <mat-grid-tile-header style="z-index: 3">
                <div fxLayout="row" fxLayoutAlign="space-between center"  style="width: 100%;">
                  <button mat-icon-button (click)="preview(image.url)" matTooltip="Preview">
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <span class="space-filler"></span>
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                  <mat-spinner *ngIf="pdfDeleting[image.id]" color="primary" [strokeWidth]=2 [diameter]=20></mat-spinner>
                  <button *ngIf="!pdfDeleting[image.id]" mat-icon-button (click)="deletePDF(image.id)" matTooltip="Delete">
                    <mat-icon>delete</mat-icon>
                  </button>
                  </div>
                </div>
              </mat-grid-tile-header>
              <img [src]="getPreviewUrl(image)" class="grid-content limit-width"/>
              <mat-grid-tile-footer>
                        <span
                          style="text-overflow: ellipsis;white-space: nowrap; overflow: hidden">{{image.file_name}}</span>
              </mat-grid-tile-footer>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </ng-container>


    </div>

    <!--Permit & Business License Documents-->
    <div fxLayout="column">
      <div fxLayout="row">
        <h1>Permit & Business License Documents</h1>
        <span fxFlex="1 1 auto"></span>
        <button style="height: fit-content; margin-top: 17px" mat-raised-button color="accent" *ngIf="!licenseUploading"
                (click)="uploadPDF(ContactDocumentType.LICENSE)">Upload
        </button>
      </div>
      <mat-progress-bar *ngIf="licenseUploading" style="margin-bottom: 10px" mode="determinate"
                        [value]="licenseUploadProgress"
                        color="accent"></mat-progress-bar>
      <div *ngIf="licenseUploading" fxFlexAlign="center" fxLayout="row" fxLayoutGap="5px">
        <span>Uploading...</span>
        <mat-icon>cloud_upload</mat-icon>
      </div>
      <div *ngIf="licenses.length === 0&&!licenseUploading" class="error" fxLayout="row" fxLayoutAlign="center center"
           fxLayoutGap="5px">
        <div class="position">
          <mat-icon>error</mat-icon>
        </div>
        <span class="size">No Permit & Business License Documents</span>
      </div>
      <ng-container *ngIf="licenses.length!==0">
        <mat-grid-list cols="6" gutterSize="2px">
          <mat-grid-tile *ngFor="let image of licenses">
            <div class="grid-tile-container">
              <mat-grid-tile-header style="z-index: 3">
                <div fxLayout="row" style="width: 100%;">
                  <button mat-icon-button (click)="preview(image.url)" matTooltip="Preview">
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <span class="space-filler"></span>
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-spinner *ngIf="pdfDeleting[image.id]" color="primary" [strokeWidth]=2 [diameter]=20></mat-spinner>
                    <button *ngIf="!pdfDeleting[image.id]" mat-icon-button (click)="deletePDF(image.id)" matTooltip="Delete">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-grid-tile-header>
              <img [src]="getPreviewUrl(image)" class="grid-content limit-width"/>
              <mat-grid-tile-footer>
                        <span
                          style="text-overflow: ellipsis;white-space: nowrap; overflow: hidden">{{image.file_name}}</span>
              </mat-grid-tile-footer>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </ng-container>
    </div>

    <div fxLayout="column">
      <div fxLayout="row">
        <h1>Driver's License</h1>
        <span fxFlex="1 1 auto"></span>
        <button style="height: fit-content; margin-top: 17px" mat-raised-button color="accent"
                *ngIf="!voidedChecksUploading"
                (click)="uploadPDF(ContactDocumentType.VOIDED_CHECK)">Upload
        </button>
      </div>
      <mat-progress-bar *ngIf="voidedChecksUploading" style="margin-bottom: 10px" mode="determinate"
                        [value]="voidedChecksUploadProgress"
                        color="accent"></mat-progress-bar>
      <div *ngIf="voidedChecksUploading" fxFlexAlign="center" fxLayout="row" fxLayoutGap="5px">
        <span>Uploading...</span>
        <mat-icon>cloud_upload</mat-icon>
      </div>
      <div *ngIf="voidedChecks.length === 0&&!voidedChecksUploading" class="error" fxLayout="row"
           fxLayoutAlign="center center"
           fxLayoutGap="5px">
        <div class="position">
          <mat-icon>error</mat-icon>
        </div>
        <span class="size">No Driver's License</span>
      </div>

      <ng-container *ngIf="voidedChecks.length!==0">
        <mat-grid-list cols="6" gutterSize="2px">
          <mat-grid-tile *ngFor="let image of voidedChecks">
            <div class="grid-tile-container">
              <mat-grid-tile-header style="z-index: 3">
                <div fxLayout="row" style="width: 100%;">
                  <button mat-icon-button (click)="preview(image.url)" matTooltip="Preview">
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <span class="space-filler"></span>
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-spinner *ngIf="pdfDeleting[image.id]" color="primary" [strokeWidth]=2 [diameter]=20></mat-spinner>
                    <button *ngIf="!pdfDeleting[image.id]" mat-icon-button (click)="deletePDF(image.id)" matTooltip="Delete">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-grid-tile-header>
              <img [src]="getPreviewUrl(image)" class="grid-content limit-width"/>
              <mat-grid-tile-footer>
                        <span
                          style="text-overflow: ellipsis;white-space: nowrap; overflow: hidden">{{image.file_name}}</span>
              </mat-grid-tile-footer>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </ng-container>
    </div>

    <input #pdfInput type="file" accept="media_type" style="visibility: hidden"/>
  `,
  styles: [`
    .size {
      font-size: x-large;
    }

    .position {
      margin-top: 5px;
    }

    .limit-width {
      height: 80px;
      width: 80px;
    }

    .error {
      height: 200px;
    }

  `]
})

export class ContactW9FormInfoComponent implements OnInit, OnDestroy {

  w9Uploading = false;
  w9UploadProgress = 0;
  licenseUploading = false;
  licenseUploadProgress = 0;
  voidedChecksUploading = false;
  voidedChecksUploadProgress = 0;
  ContactDocumentType = ContactDocumentType;
  w9Forms: W9Form[] = [];
  licenses: W9Form[] = [];
  voidedChecks: W9Form[] = [];
  pdfDeleting: {[formId: number]: boolean} = {};
  @ViewChild("pdfInput", {read: ElementRef, static: true}) pdfInput: ElementRef;

  response: any;

  constructor(private domSanitizer: DomSanitizer,
              private awsService: AWSService,
              private contactRepository: ContactRepository) {
  }

  private _contact: UserFull;

  get contact(): UserFull {
    return this._contact;
  }

  @Input() set contact(contact: UserFull) {
    this._contact = contact;

    const forms = contact.w9Forms.data;

    this.w9Forms = forms.filter(f => f.type === ContactDocumentType.W9_FORM);
    this.licenses = forms.filter(f => f.type === ContactDocumentType.LICENSE);
    this.voidedChecks = forms.filter(f => f.type === ContactDocumentType.VOIDED_CHECK);

  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  getSanitizedUrl(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  uploadPDF(type: ContactDocumentType) {
    this.pdfInput.nativeElement.value = "";
    this.pdfInput.nativeElement.click();
    this.pdfInput.nativeElement.onchange = (event) => {

      if (type === ContactDocumentType.W9_FORM) {
        this.w9Uploading = true;
      } else if (type === ContactDocumentType.LICENSE) {
        this.licenseUploading = true;
      } else {
        this.voidedChecksUploading = true;
      }

      if (event.target.files.length > 0) {

        this.contactRepository.createW9Form(this.contact.id, event.target.files[0].name, type).subscribe(w9Form => {
          this.contactRepository.uploadW9Form(w9Form.pre_signed_url, event.target.files[0]).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {

              if (type === ContactDocumentType.W9_FORM) {
                this.w9UploadProgress = Math.round(100 * event.loaded / event.total);
              } else if (type === ContactDocumentType.LICENSE) {
                this.licenseUploadProgress = Math.round(100 * event.loaded / event.total);
              } else {
                this.voidedChecksUploadProgress = Math.round(100 * event.loaded / event.total);
              }

            } else if (event instanceof HttpResponse) {
              this.contactRepository.completeW9Form(w9Form.id).subscribe();
              if (type === ContactDocumentType.W9_FORM) {
                this.w9UploadProgress = 0;
                this.w9Uploading = false;
              } else if (type === ContactDocumentType.LICENSE) {
                this.licenseUploadProgress = 0;
                this.licenseUploading = false;
              } else {
                this.voidedChecksUploadProgress = 0;
                this.voidedChecksUploading = false;
              }
            }
          });

        });
      }
    };
  }

  deletePDF(formId: number) {
    this.pdfDeleting[formId] = true;
    this.contactRepository
      .deleteW9Form(this.contact.id, formId)
      .subscribe(() => delete this.pdfDeleting[formId], () => delete this.pdfDeleting[formId]);
  }

  preview(url: string) {
    window.open(url);
  }

  getFileType(fileName: string): string {
    switch (fileName.split("/")[0]) {

      case "jpg":
      case "jpeg":
      case "svg":
      case "png": {
        return AttachmentType.IMAGE;
      }

      default: {
        return AttachmentType.DOCUMENT;
      }
    }
  }

  getPreviewUrl(document: W9Form): string {

    switch (this.getFileType(document.file_name)) {
      case AttachmentType.IMAGE: {
        return document.url;
      }

      case AttachmentType.DOCUMENT: {
        return CommonUtil.getDocumentUrl(document.file_name);
      }

    }

  }

}
