import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { FeedEnumHelpers, FeedFilterType, FeedType } from "../../enums/feeds.enum";
import { Feed } from "../../models/new/feed";
import { UserCompact } from "../../models/new/user/user-compact.model";
import { UserModelUtil } from "../../models/utils/user-model.util";
import { OptionsRepository } from "../../repository/options.repository";
import { UserRepository } from "../../repository/user-repository";
import { FeedsService } from "../../services/feeds.service";
import { CommonUtil } from "../../utils/common.util";

import { InquiryReportPopupComponent } from "./inquiry-report-popup.component";

@Component({
  selector: "sd-feeds",
  template: `
    <sd-modal-popup-layout title="Feeds">

      <div fxLayout="column" fxLayoutGap="20px">

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutAlign.lt-sm="center center"
             fxLayoutAlign="end center">
          <button mat-raised-button color="accent" (click)="openInquiryReport()">
            Run Inquiries Report
          </button>
          <sd-select placeholder="Select Assignee Type"
                     [control]="selectedAssigneeType"
                     [options]="selectedAssigneeTypeOptions"
                     (selectionChanged)="refresh()"></sd-select>
          <sd-select placeholder="Select Assignees"
                     [multiple]="true"
                     [control]="selectedAssignees"
                     [options]="allAdminsOptions"
                     (selectionChanged)="refresh()"></sd-select>
          <sd-select placeholder="Select Filters"
                     [multiple]="true"
                     [control]="selectedFilters"
                     [options]="selectedFiltersOptions"
                     (selectionChanged)="refresh()"></sd-select>
          <button mat-raised-button (click)="toggleFilter()">
            <span *ngIf="showOnlyUnread">Show All</span>
            <span *ngIf="!showOnlyUnread">Show Unread Only</span>
          </button>
          <button mat-raised-button (click)="markAllAsRead()">
            Mark All As Read
          </button>

          <button mat-icon-button (click)="refresh()">
            <mat-icon matTooltip="Refresh">refresh</mat-icon>
          </button>
        </div>

        <div fxLayout="column" fxFlex="100%" fxLayoutAlign="center center" fxLayoutGap="5px">
          <mat-card fxLayout.lt-sm="column" fxLayout="row" fxLayoutGap="5px" class="padding" *ngFor="let feed of feeds"
                    style="width: 100%"
                    [style.background]="getBackGroundColor(feed)"
                    fxLayoutAlign.lt-sm="flex-start flex-start"
                    fxLayoutAlign="space-between center" (click)="redirect(feed)">
            <div fxFlex="80%" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutAlign="flex-start center"
                 fxLayoutAlign.lt-sm="center center">
              <div fxFlex="7%" fxLayoutAlign="center center">
                <button *ngIf="!readLoading[feed.id]" mat-icon-button (click)="toggleIsOpened(feed)">
                  <mat-icon matTooltip="Mark As Read" *ngIf="!feed.is_opened">visibility_off</mat-icon>
                  <mat-icon matTooltip="Mark As Unread" *ngIf="feed.is_opened">visibility</mat-icon>
                </button>
                <mat-spinner *ngIf="readLoading[feed.id]" color="accent" [diameter]="30"
                             [strokeWidth]="4"></mat-spinner>
              </div>

              <div fxFlex="15%">
                <img *ngIf="feed.user_id && feed.user.data.pic_thumb_url"
                     [matTooltip]="UserModelUtil.getFullName(feed.user.data)" mat-card-avatar
                     class="avatar" src="{{ UserModelUtil.getProfilePic(feed.user.data) }}">

                <span *ngIf="feed.user_id && !feed.user.data.pic_thumb_url">
                  {{UserModelUtil.getFullName(feed.user.data)}}
                </span>

                <img *ngIf="!feed.user_id" matTooltip="Auto Generated" class="avatar" src="../../assets/bg/auto.svg">
              </div>

              <span>{{feed.title.split(";")[0]}}</span>
            </div>
            <div fxFlex="15%" fxFlexAlign.lt-sm="100%" fxLayoutAlign.lt-sm="center center" fxLayoutAlign="flex-start center">
              <span>{{feed.created_at | date}}</span>
            </div>
            <div fxFlex="5%">
              <button mat-icon-button>
                <mat-icon>{{getIcon(feed)}}</mat-icon>
              </button>
            </div>
          </mat-card>
          <button style="margin-bottom: 10px" mat-button *ngIf="isMoreDataAvailable() && !isLoading && feeds.length > 0"
                  (click)="loadMore()">
            <span style="font-size: medium">Load More...</span>
          </button>
          <mat-spinner color="accent" [diameter]="60" [strokeWidth]="4" *ngIf="isLoading"></mat-spinner>
        </div>

        <div fxLayoutAlign="center center" *ngIf="feeds.length === 0 && !isLoading">
          <span>You will have feeds here for automation send, booking confirmation, comments on task etc.</span>
        </div>

      </div>


    </sd-modal-popup-layout>
  `,
  styles: [`
    .padding {
      cursor: pointer;
      padding: -10px -10px -10px -10px;
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    .avatar {
      width: 60px;
      height: 60px;
    }

    mat-card {
      border: 1px solid lightgrey !important;
      box-shadow: none !important;
    }
  `]
})

export class FeedsComponent implements OnInit, OnDestroy {

  isQuinn = false;
  isDevon = false;
  isLoading = false;
  showOnlyUnread = false;
  feeds: Feed[] = [];
  readLoading = {};
  selectedFilters: FormControl;
  selectedFiltersOptions: {title: string, value: any}[];
  selectedAssignees: FormControl;
  selectedAssigneeType: FormControl;
  selectedAssigneeTypeOptions: {title: string, value: any}[] = [
    { title: "Vendor", value: "vendor" },
    { title: "Employee", value: "employee" },
    { title: "Homeowner", value: "homeowner" },
  ];
  UserModelUtil = UserModelUtil;
  allAdmins: UserCompact[];
  allAdminsOptions: {title: string, value: any}[];
  private currentPage = 0;
  private totalPages = 1;
  private $loadFeeds = new Subject();
  private destroyed$ = new Subject();

  constructor(private feedsService: FeedsService,
              private router: Router,
              private optionsRepo: OptionsRepository,
              private userRepo: UserRepository,
              private dialog: MatDialog) {

    this.$loadFeeds.asObservable().pipe(debounceTime(200)).subscribe(() => {
      this.loadFeeds();
    });
  }

  ngOnInit() {
    this.selectedFilters = new FormControl([FeedFilterType.COMMENTS, FeedFilterType.ADDED_ATTACHMENTS, FeedFilterType.INQUIRY], []);
    this.selectedAssignees = new FormControl([], []);
    this.selectedAssigneeType = new FormControl(null, []);
    this.setUpFeedFilters();
    this.$loadFeeds.next();
    this.optionsRepo.getTaskAssignees(false).subscribe(value => {
      this.allAdmins = value;
      this.allAdminsOptions = this.allAdmins.map(admin => {
        return {title: admin.first_name + " " + admin.last_name, value: admin.id};
      });
      this.selectAllAssignees(!this.isQuinn);
    });
    this.selectedFiltersOptions = CommonUtil.getPropsOfEnum<FeedFilterType>(FeedFilterType).map(filter => {
      return {title: FeedEnumHelpers.getFeedFilterType(filter), value: filter};
    });

    this.userRepo.getUser()
      .pipe(
      takeUntil(this.destroyed$))
      .subscribe(u => {
        this.isQuinn = u.id === 3640;
        this.isDevon = u.id === 40177 || u.id === 59790 || u.id === 2;
        this.setUpCustomisedFeed();
      });

  }

  ngOnDestroy(): void {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  openInquiryReport() {
    this.dialog
      .open(InquiryReportPopupComponent).updateSize("100%");
  }

  setUpCustomisedFeed() {
    if (this.isQuinn) {
      this.selectedFilters.setValue([FeedFilterType.INQUIRY]);
      this.selectAllAssignees(false);
    } else if (this.isDevon) {
      this.selectedFilters.setValue([FeedFilterType.MENTIONS, FeedFilterType.AIRBNB_ALTERATIONS, FeedFilterType.SUPPORT_EMAIL]);
      this.selectAllAssignees(false);
    }
  }

  setUpFeedFilters() {

    const filters = this.feedsService.getSelectedFilters();

    if (filters.length > 0) {
      this.selectedFilters.patchValue(filters);
    }

    this.selectedFilters.valueChanges
      .subscribe(fs => {
        if (!!fs) {
          this.feedsService.setSelectedFilters(fs);
        }
      });
  }

  loadMore() {
    this.$loadFeeds.next();
  }

  refresh() {
    this.currentPage = 0;
    this.totalPages = 1;
    this.$loadFeeds.next();
  }

  loadFeeds() {
    console.log("[DD] before", this.currentPage, this.totalPages);

    if (!this.isMoreDataAvailable()) {
      return;
    }
    this.isLoading = true;
    if (this.currentPage === 0) {
      this.feeds = [];
    }
    console.log(this.selectedFilters);
    this.feedsService.indexFeeds(this.currentPage + 1, this.showOnlyUnread, this.selectedFilters.value, this.selectedAssignees.value, this.selectedAssigneeType.value).subscribe(res => {
      console.log("[Feeds]", res.data);
      this.feeds = [...this.feeds, ...res.data];
      const meta = res.meta.pagination;
      this.currentPage = meta.current_page;
      this.totalPages = meta.total_pages;
      console.log("[DD] after", res, meta, this.currentPage, this.totalPages);
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    });
  }

  isMoreDataAvailable() {
    return this.currentPage < this.totalPages;
  }

  toggleFilter() {
    this.showOnlyUnread = !this.showOnlyUnread;
    this.refresh();
  }

  toggleIsOpened(feed) {
    this.readLoading[feed.id] = true;
    this.feedsService.toggleIsOpened(feed.id).subscribe((r) => {
      delete this.readLoading[feed.id];
      r = r.data;
      this.feeds = this.feeds.map((f: Feed) => {
        if (f.id === feed.id) {
          f.is_opened = r.is_opened;
        }
        return f;
      });
    }, err => {
      delete this.readLoading[feed.id];
    });
    event.stopPropagation();
  }

  markAllAsRead() {
    this.feedsService.markAllAsRead().subscribe(() => {
      this.refresh();
    }, err => {
    });
  }

  getBackGroundColor(feed) {
    if (!feed.is_opened) {
      if (feed.type === FeedType.HEALTH_SCORE_FAILED) {
        return "red";
      }
      return "green";
    } else {
      return "white";
    }
  }

  redirect(feed: Feed) {
    if (!feed.is_opened) {
      this.toggleIsOpened(feed);
    }
    switch (feed.type) {
      case FeedType.BOOKING_AUTOMATION_MESSAGE_SENT: {
        window.open(environment.dashboardURL + "/reservations/" + feed.actionable_id + "/automation");
        break;
      }
      case FeedType.BOOKING_CREATED:
      case FeedType.BOOKING_ALTERED:
      case FeedType.BOOKING_INQUIRY_CREATED:
      case FeedType.BOOKING_CANCELLED:
      case FeedType.BOOKING_PRE_APPROVED:
      case FeedType.AMOUNT_REFUNDED:
      case FeedType.BOOKING_STATUS_CHANGED: {
        window.open(environment.dashboardURL + "/reservations/" + feed.actionable_id + "/financials");
        break;
      }
      case FeedType.MANUAL_TASK_ADDED:
      case FeedType.TASK_ASSIGNEE_CHANGED:
      case FeedType.TASK_ATTACHMENT_ADDED:
      case FeedType.TASK_COMMENT_LEFT:
      case FeedType.TASK_COMMENT_UPDATED:
      case FeedType.AUTO_TASK_ADDED:
      case FeedType.AUTO_TASK_COMPLETED:
      case FeedType.MANUAL_TASK_COMPLETED: {
        window.open(environment.dashboardURL + "/tasks/" + feed.actionable_id);
        break;
      }
      case FeedType.TASK_COMMENT_MENTION: {
        window.open(environment.dashboardURL + "/tasks/" + feed.actionable_id + ";" + feed.title.split(";")[1]);
        break;
      }
      case FeedType.RESOLUTION_CENTER_PAYMENTS: {
        // window.open(environment.dashboardURL + "/tasks/" + feed.actionable_id);
        break;
      }
      case FeedType.PROPERTY_CREATED:
      case FeedType.PROPERTY_CHANGED: {
        window.open(environment.dashboardURL + "/listings/" + feed.actionable_id + "/details");
        break;
      }
      case FeedType.AIRBNB_ALTERATION_REQUEST: {
        window.open(environment.dashboardURL + "/reservations/" + feed.actionable_id + "/alteration");
        break;
      }
      case FeedType.HEALTH_SCORE_FAILED: {
        window.open(environment.dashboardURL + "/settings/integrations" + feed.actionable_id);
        break;
      }
      case FeedType.AIRBNB_SUPPORT_EMAIL: {
        // const url = feed.title.split("$$")[1];
        // window.open(url, "_blank");
        break;
      }
    }
  }

  getIcon(feed) {
    switch (feed.type) {
      case FeedType.TASK_COMMENT_LEFT:
      case FeedType.TASK_COMMENT_UPDATED: {
        return "add_comment";
      }
      case FeedType.TASK_COMMENT_MENTION: {
        return "alternate_email";
      }
      case FeedType.BOOKING_INQUIRY_CREATED:
      case FeedType.BOOKING_PRE_APPROVED: {
        return "message";
      }
      case FeedType.BOOKING_AUTOMATION_MESSAGE_SENT: {
        return "help";
      }
      case FeedType.AUTO_TASK_ADDED:
      case FeedType.AUTO_TASK_COMPLETED: {
        return "supervisor_account";
      }
      case FeedType.MANUAL_TASK_ADDED:
      case FeedType.MANUAL_TASK_COMPLETED: {
        return "all_inclusive";
      }
      case FeedType.HEALTH_SCORE_FAILED: {
        return "warning";
      }
      case FeedType.BOOKING_CANCELLED: {
        return "cancel";
      }
      case FeedType.AIRBNB_ALTERATION_REQUEST:
      case FeedType.RESOLUTION_CENTER_PAYMENTS: {
        return "sync";
      }
      case FeedType.BOOKING_STATUS_CHANGED:
      case FeedType.BOOKING_ALTERED:
      case FeedType.BOOKING_CREATED: {
        return "beenhere";
      }
      case FeedType.PROPERTY_CHANGED:
      case FeedType.PROPERTY_CREATED: {
        return "edit";
      }
      case FeedType.TASK_ATTACHMENT_ADDED:
      case FeedType.TASK_ASSIGNEE_CHANGED: {
        return "description";
      }
      case FeedType.AMOUNT_REFUNDED: {
        return "account_balance_wallet";
      }
      case FeedType.AIRBNB_SUPPORT_EMAIL: {
        return "email";
      }
    }
  }

  selectAllAssignees(flag) {
    if (flag) {
      this.selectedAssignees.patchValue(this.allAdmins.map(admin => admin.id));
    } else {
      this.selectedAssignees.patchValue([]);
    }
    this.refresh();
  }
}
