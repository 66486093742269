import { Inventory } from "../../../models/new/inventory.model";
import { Action } from "../../action";

export enum InventoryActionTypes {
  INDEX_REQUEST = "[INVENTORY NEW] Index Request",
  INDEX_SUCCESS = "[INVENTORY NEW] Index Success",
  DELETE_SUCCESS = "[INVENTORY NEW] Delete Success",

  CREATE_REQUEST = "[INVENTORY NEW] Create Request",
  CREATE_SUCCESS = "[INVENTORY NEW] Create Success",

  SHOW_REQUEST = "[INVENTORY NEW] Show Request",
  SHOW_SUCCESS = "[INVENTORY NEW] Show Success",

  UPDATE_REQUEST = "[INVENTORY NEW] Update Request",
  UPDATE_SUCCESS = "[INVENTORY NEW] Update Success",

  TAG_INDEX_REQUEST = "[INVENTORY NEW] Tag Index Request",
  TAG_INDEX_SUCCESS = "[INVENTORY NEW] tag Index Success",

  ADD_TAG_REQUEST = "[INVENTORY NEW] Add Tag Request",
  ADD_TAG_SUCCESS = "[INVENTORY NEW] Add Tag Success",
  DELETE_INVENTORY_TAG_SUCCESS = "[INVENTORY NEW] Delete Tag Success"
}

export class InventoryIndexRequest implements Action {
  readonly type = InventoryActionTypes.INDEX_REQUEST;
}

export class InventoryIndexSuccess implements Action {
  readonly type = InventoryActionTypes.INDEX_SUCCESS;

  constructor(public payload: {inventories: Inventory[], unTagged: boolean}) {
  }
}

export class InventoryShowRequest implements Action {
  readonly type = InventoryActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {
  }
}

export class InventoryShowSuccess implements Action {
  readonly type = InventoryActionTypes.SHOW_SUCCESS;

  constructor(public payload: Inventory) {
  }
}

export class InventoryUpdateRequest implements Action {
  readonly type = InventoryActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class InventoryCreateRequest implements Action {
  readonly type = InventoryActionTypes.CREATE_REQUEST;
}

export class InventoryCreateSuccess implements Action {
  readonly type = InventoryActionTypes.CREATE_SUCCESS;

  constructor(public payload: Inventory) {
  }
}

export class InventoryUpdateSuccess implements Action {
  readonly type = InventoryActionTypes.UPDATE_SUCCESS;

  constructor(public payload: Inventory) {
  }
}

export class InventoryDeleteSuccess implements Action {
  readonly type = InventoryActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}

export class TagIndexSuccess implements Action {
  readonly type = InventoryActionTypes.TAG_INDEX_SUCCESS;

  constructor(public payload: { id: number, title: string }[]) {
  }
}

export class AddTagSuccess implements Action {
  readonly type = InventoryActionTypes.ADD_TAG_SUCCESS;

  constructor(public payload: { id: number, title: string }) {
  }
}

export class DeleteInventoryTagSuccess implements Action {
  readonly type = InventoryActionTypes.DELETE_INVENTORY_TAG_SUCCESS;

  constructor(public payload: number) {
  }
}

