import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { ListingCannedMessagePopupComponent } from "../listings/components/popups/listing-canned-message-popup";
import { SharedModule } from "../shared/shared.module";

import { SettingsAlertPopupComponent } from "./components/automated-messages-popup/check-in-instructions-popup";
import { SettingsAutomatedMessagesDetailComponent } from "./components/automated-messages-popup/settings-automated-messages-detail";
import { ListingAutoResponsePopupComponent } from "./components/listing-auto-response-popup";
import { CreateMultiCalendarRuleComponent } from "./components/multi-calendar-rules/create-multi-calendar-rule";
import { SettingMultiCalendarRulesComponent } from "./components/multi-calendar-rules/setting-multi-calendar-rules";
import { OnboardingTaskAddEditComponent } from "./components/onboarding-task/onboarding-task-add-edit";
import { SettingsOnboardingTasksComponent } from "./components/onboarding-task/settings-onboarding-tasks";
import { SettingCannedResponsesDetailComponent } from "./components/setting-canned-responses-detail";
import { SettingsAutoResponseComponent } from "./components/settings-auto-response";
import { SettingsAutoTasksComponent } from "./components/settings-auto-tasks";
import { SettingsAutomatedMessagesComponent } from "./components/settings-automated-messages";
import { SettingsCannedResponsesComponent } from "./components/settings-canned-responses";

const entryComponents = [
  SettingsAlertPopupComponent,
  ListingCannedMessagePopupComponent,
  ListingAutoResponsePopupComponent,
];

const components = [
  SettingsAutomatedMessagesComponent,
  SettingsCannedResponsesComponent,
  SettingsAutoResponseComponent,
  SettingsAutoTasksComponent,
  SettingsOnboardingTasksComponent,
  OnboardingTaskAddEditComponent,
  SettingMultiCalendarRulesComponent,
  SettingsAutomatedMessagesDetailComponent,
  SettingCannedResponsesDetailComponent,
];

@NgModule({
  imports: [SharedModule],
  exports: [...components],
  declarations: [...components, ...entryComponents],
  entryComponents: [...entryComponents, CreateMultiCalendarRuleComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SettingsSharedModule {
}
