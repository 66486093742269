import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Injectable()
export class SnackbarService {

  constructor(private snackbar: MatSnackBar) {
  }

  show(message: string, duration: number = 2000) {
    const config = new MatSnackBarConfig();
    config.duration = duration;
    config.verticalPosition = "top";
    config.horizontalPosition = "center";
    this.snackbar.open(message, null, config);
  }

}
