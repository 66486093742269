import { HttpEventType, HttpResponse } from "@angular/common/http";
/**
 * Created by aditya on 24/8/17.
 */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { skip, takeUntil } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { UserFull } from "../../../models/new/user/user-full.model";
import { UserModelUtil } from "../../../models/utils/user-model.util";
import { ContactRepository } from "../../../repository/contact.repository";
import { AppService } from "../../../services/app.service";
import { CommonUtil } from "../../../utils/common.util";

@Component({
  selector: "sd-contacts-user-profile",
  template: `
    <sd-owner-main-layout-fullwidth>

      <div id="spinner" *ngIf="!isContactLoaded" fxLayout="row" fxLayoutAlign="center center" fxFlex="100%">
        <mat-spinner color="accent" [diameter]="60" [strokeWidth]="5"></mat-spinner>
      </div>

      <div *ngIf="isContactLoaded" class="requiredHeight" style="width: 100%;overflow-x: initial" fxLayout="column"
           fxLayoutAlign="flex-start">
        <div class="pre1" fxFlex="100%">
          <section style="height: 230px">
            <div fxLayout="column" style="margin-top: 5%" fxLayoutAlign="center center">
              <img *ngIf="uploadProgress == 0" (click)="onUserImageClicked()" class="profileImage"
                   src="{{ UserModelUtil.getProfilePic(selectedContact) }}">
              <input type="file" accept="image/*" style="visibility: hidden" #addImageWrapper>
              <span *vaFlexAlignmentHack></span>
              <div fxLayoutAlign="center center" *ngIf="uploadProgress != 0">
                <span style="color: white; font-size: large">{{uploadProgress}} %</span>
              </div>
              <p style="color: white;font-size:26px" align="center">
                <b>{{ UserModelUtil.getFullName(selectedContact) }}</b></p>
            </div>
          </section>

          <section>

            <div fxFlex="100%" style="margin: 20px; color: white" fxLayout="row" fxLayoutAlign="end center"
                 fxLayoutAlign.lt-sm="center center">
              <mat-slide-toggle
                *ngIf="!spinning"
                (change)="onActiveStatusChanged($event)"
                [checked]="selectedContact.is_active">
                Active
              </mat-slide-toggle>

              <mat-spinner *ngIf="spinning" [diameter]="30" [strokeWidth]="4"></mat-spinner>
            </div>

          </section>
        </div>

        <div style="overflow-y: hidden;">

          <mat-tab-group style="margin-left: 30px; margin-bottom: 30px;margin-right: 30px;">
            <mat-tab label="USER PROFILE">
              <sd-contact-info [contact]="selectedContact"></sd-contact-info>
            </mat-tab>

            <mat-tab label="DOCUMENTS" *ngIf="selectedContact.is_management_contact">
              <sd-contact-w9-form-info [contact]="selectedContact"></sd-contact-w9-form-info>
            </mat-tab>

            <mat-tab *ngIf="showBankInfoTab" label="BANK INFO">
              <sd-contact-bank-info [contact]="selectedContact"></sd-contact-bank-info>
            </mat-tab>

            <mat-tab *ngIf="showUserTypeTab" label="USER TYPE">
              <sd-contact-type-info [contact]="selectedContact"></sd-contact-type-info>
            </mat-tab>

            <mat-tab *ngIf="showReservationInfoTab" label="RESERVATIONS">
              <sd-contact-reservations-info [contact]="selectedContact"></sd-contact-reservations-info>
            </mat-tab>

            <mat-tab *ngIf="showListingInfoTab" label="LISTINGS">
              <sd-contact-listing-info [contact]="selectedContact"></sd-contact-listing-info>
            </mat-tab>

            <mat-tab *ngIf="showEmployeeTab" label="EMPLOYEE">
              <sd-contact-employee [contact]="selectedContact"></sd-contact-employee>
            </mat-tab>

            <mat-tab label="LOG">
              <sd-contact-log-info [contact]="selectedContact"></sd-contact-log-info>
            </mat-tab>
            <mat-tab *ngIf="showTransactionHistoryTab" label="TRANSACTION HISTORY">
              <sd-contact-transaction-history></sd-contact-transaction-history>
            </mat-tab>
          </mat-tab-group>

          <div style="margin-top:10px"></div>
        </div>
      </div>
    </sd-owner-main-layout-fullwidth>
  `,
  styles: [`

    #spinner {
      position: fixed;
      top: 45%;
      right: 40%;
    }

    .profileImage {
      width: 150px;
      height: 150px;
      background-repeat: no-repeat;
      -webkit-border-radius: 99em;
      -moz-border-radius: 99em;
      border-radius: 99em;
      border: 5px solid #eee;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
    }

    .pre1 {
      width: 100%;
      padding-left: 1.5%;
      flex: 0 0 0% !important;
      display: table;
      /*TODO S3 IMAGE REPLACE*/
      background-image: url(https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/user_cover.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #464646;
    }

    /*Tabs*/

    /deep/
    .mat-ink-bar {
      background-color: #194267 !important;
      height: 5px !important;
    }

    /deep/
    .mat-tab-label {
      opacity: 1 !important;
      font-weight: bold;
    }
  `]

})

export class ContactsUserProfileComponent implements OnInit, OnDestroy {

  // Helpers
  UserModelUtil = UserModelUtil;

  isContactLoaded = false;
  contacts = [];
  contactId: number;
  selectedContact: UserFull;
  showBankInfoTab = false;
  showListingInfoTab = false;
  showUserTypeTab = false;
  showReservationInfoTab = false;
  showEmployeeTab = false;
  showTransactionHistoryTab = false;
  isEmployee = false;

  spinning = false;
  uploadProgress = 0;
  @ViewChild("addImageWrapper", { read: ElementRef }) addImageWrapper: ElementRef;
  private destroyed$ = new Subject();

  constructor(private route: ActivatedRoute,
              private appService: AppService,
              private router: Router,
              private contactRepo: ContactRepository) {
  }

  ngOnInit() {

    this.setUp();

  }

  setUp() {

    const route$ = this.route.params;
    route$.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.contactId = +params.id;

      const contact$ = this.contactRepo.getFullContactById(this.contactId, true);

      this.isContactLoaded = false;
      contact$.pipe(takeUntil(route$.pipe(skip(1)))).subscribe(contact => {
        this.isContactLoaded = true;
        this.selectedContact = contact;
        this.isEmployee = CommonUtil.isEmployee(contact);
        this.updateContact();
        this.appService.changeAppTitle(this.UserModelUtil.getFullName(this.selectedContact));
      });
    });
    console.log(" hello ", this.selectedContact);
  }

  onActiveStatusChanged($event) {
    this.spinning = true;
    if ($event.checked) {
      this.contactRepo.markContactActive(this.selectedContact.id).subscribe(res => {
        this.spinning = false;
      });
    } else {
      this.contactRepo.markContactInactive(this.selectedContact.id).subscribe(res => {
        this.spinning = false;
      });
    }
  }

  userUpdate() {
  }

  ngOnDestroy(): void {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  getProfilePic(image: string) {
    if (isNullOrUndefined(image)) {
      return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/user_placeholder.jpg";
    } else {
      return image;
    }
  }

  updateContact() {
    this.isContactLoaded = true;

    if (this.selectedContact.type === "management") {
      this.showBankInfoTab = !this.isEmployee;
      this.showListingInfoTab = true;
      this.showUserTypeTab = !this.isEmployee;
      this.showReservationInfoTab = false;
      this.showEmployeeTab = !this.isEmployee;

    } else if (this.selectedContact.type === "owner") {
      this.showBankInfoTab = true;
      this.showListingInfoTab = true;
      this.showUserTypeTab = false;
      this.showReservationInfoTab = false;
      this.showTransactionHistoryTab = true;

    } else if (this.selectedContact.type === "guest") {
      this.showReservationInfoTab = true;
      this.showBankInfoTab = false;
      this.showListingInfoTab = false;
      this.showUserTypeTab = false;
    } else {
      this.showReservationInfoTab = false;
      this.showBankInfoTab = false;
      this.showListingInfoTab = false;
      this.showUserTypeTab = false;
    }
  }

  onUserImageClicked() {
    console.log("selected contact", this.selectedContact, this.selectedContact.type === "guest");
    if (this.selectedContact.type === "guest") {
      window.open("https://www.airbnb.com/users/show/" + this.selectedContact.guest.data.airbnb_account_id)
    } else {
      this.updateImage();
    }
  }

  updateImage() {
    this.addImageWrapper.nativeElement.click();

    this.addImageWrapper.nativeElement.onchange = (event) => {
      if (event.target.files.length > 0) {

        this.contactRepo.updateProfilePicById(this.contactId, event.target.files[0]).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadProgress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.uploadProgress = 0;
          }
        });
      }
    };

  }

}
