export enum ThreadFilter {
  AIRBNB = "Airbnb",
  HOMEAWAY = "Home Away",
  STAY_DUVET = "Stay Duvet",
  PLUM_GUIDE = "Plum Guide",
  MARRIOTT = "Marriott",
  UNREAD = "Unread",
  FOLLOW_UP = "Follow Up",
  ONGOING = "Ongoing",
  ARCHIVED = "Archived",
  FAVOURITE = "Favourite",
  REFERRAL = "Referral",
  INQUIRIES = "Inquiries",
  UPCOMING = "Upcoming",
  OUTGOING = "Outgoing"
}
