export enum AccountType {
  PERSONAL = "personal",
  BUSINESS = "business"
}

export enum ContactSortType {
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  EMAIL = "email",
  PHONE = "phone_number",
  SOURCE = "guests.source",
  TYPE = "type"
}

export enum ContactType {
  ALL_CONTACTS = "all",
  GUESTS = "guest",
  ADMIN = "admin",
  MANAGEMENT = "management",
  OWNER = "owner",
}

export enum ContactDocumentType {
  W9_FORM = "w9_form",
  LICENSE = "license",
  VOIDED_CHECK = "voided_check",
}

export enum ContactStatus {
  INACTIVE = 0,
  ACTIVE
}

export class ContactEnumHelper {


  static getContactType(type: ContactType): string {

    switch (type) {

      case ContactType.GUESTS: {
        return "Guests";
      }

      case ContactType.ADMIN: {
        return "Admins";
      }

      case ContactType.MANAGEMENT: {
        return "Vendors";
      }

      case ContactType.OWNER: {
        return "Homeowners";
      }

      default: {
        return "All";
      }

    }

  }

  static getAccountType(type: AccountType): string {
    switch (type) {

      case AccountType.BUSINESS: {
        return "Business";
      }

      case AccountType.PERSONAL: {
        return "Personal";
      }
    }
  }

}
