import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { MultiCalendarRule } from "../models/new/multi-calendar-rule";
import { __HTTPResponseType } from "../enums/common.enum";
import { map } from "rxjs/operators";

@Injectable()
export class MultiCalendarRulesService {


  constructor(private apiService: ApiService) {
  }


  getMultiCalendarRules(): Observable<MultiCalendarRule[]> {
    return this.apiService.get<{ data: MultiCalendarRule[] }>("/calendar-rules", true).pipe(map(res => res.data));
  }


  createMultiCalendarRule(data: { parentPropertyId: number, blockedPropertyId: number, reverseRuleApplied: boolean, syncBookingLimit:boolean }): Observable<MultiCalendarRule> {

    const req = {
      parent_listing_id: data.parentPropertyId,
      blocked_listing_id: data.blockedPropertyId,
      reverse_rule_applied: data.reverseRuleApplied,
      sync_future_booking_limit:data.syncBookingLimit
    };

    return this.apiService.post<{ data: MultiCalendarRule }>("/admin/calendar-rules", true, req).pipe(map(res => res.data));
  }

  updateMultiCalendarRule(id: number, blockedPropertyId: number, reverse_rule_applied: boolean,sync_booking_limit:boolean): Observable<MultiCalendarRule> {

    const data = {
      blocked_listing_id: blockedPropertyId,
      reverse_rule_applied: reverse_rule_applied,
      sync_future_booking_limit: sync_booking_limit
    };

    return this.apiService.put<{ data: MultiCalendarRule }>("/admin/calendar-rules/" + id, true, data).pipe(map(res => res.data));

  }

  deleteMultiCalendarRule(id: number): Observable<any> {
    return this.apiService.delete("/admin/calendar-rules/" + id, true, null, null, true, __HTTPResponseType.TEXT);
  }

}
