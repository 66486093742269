import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { __HTTPResponseType } from "../../enums/common.enum";
import { ApiService } from "../api.service";
import { SummaryDraftsGetResponse } from "../../models/responses/settings/drafts/summary-draft-get.response";
import { SummaryDraft } from "../../models/new/drafts/summary-draft.model";
import { SummaryDraftsShowResponse } from "../../models/responses/settings/drafts/summary-draft-show.response";

@Injectable()
export class SummaryDraftsService {

  constructor(private apiService: ApiService) {
  }

  getSummaryDrafts(): Observable<SummaryDraftsGetResponse> {
    return this.apiService.get<SummaryDraftsGetResponse>("/admin/summary-drafts", true);
  }

  getSummaryDraftById(draftId):Observable<any> {
    return this.apiService.get<any>("/admin/summary-drafts/"+draftId,true);
  }

  createSummaryDraft(data: Partial<SummaryDraft>): Observable<SummaryDraftsShowResponse> {
    return this.apiService.post<SummaryDraftsShowResponse>("/admin/summary-drafts", true, data);
  }

  updateSummaryDraft(draftId: number, data: any): Observable<SummaryDraftsShowResponse> {
    return this.apiService.put<SummaryDraftsShowResponse>("/admin/summary-drafts/" + draftId, true, data);
  }

  deleteSummaryDraft(draftId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/summary-drafts/" + draftId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}

