import {filter, map, take} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {RoutingRulesService} from "../services/routing-rules.service";
import {Store} from "@ngrx/store";
import {getIsRulesLoadedForListingId, getIsRulesLoadingForListingId, getRulesForListingId, State} from "../reducers";
import {Observable} from "rxjs";
import {RoutingRule} from "../models/new/listing/routing-rule";
import {
  RoutingRulesCreateSuccess,
  RoutingRulesDeleteSuccess,
  RoutingRulesIndexRequest,
  RoutingRulesIndexSuccess,
  RoutingRulesUpdateSuccess
} from "../actions/new/listings/routing-rules";

@Injectable()
export class RoutingRulesRepository {


  constructor(private routingRuleService: RoutingRulesService,
              private store: Store<State>) {

  }

  getIsRulesLoadingForListingId(listingId: number): Observable<boolean>{
    return this.store.select((state => getIsRulesLoadingForListingId(state, listingId)));
  }

  getIsRulesLoadedForListingId(listingId: number): Observable<boolean>{
    return this.store.select((state => getIsRulesLoadedForListingId(state, listingId)));
  }

  getRules(listingId: number, force: boolean = false): Observable<RoutingRule[]> {

    let loading;
    let loaded;

    this.getIsRulesLoadingForListingId(listingId).pipe(take(1)).subscribe(l => loading = l);
    this.getIsRulesLoadedForListingId(listingId).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)){
      this.store.dispatch(new RoutingRulesIndexRequest(listingId));
      this.routingRuleService.getRules(listingId).subscribe(rules => {
        this.store.dispatch(new RoutingRulesIndexSuccess({rules: rules, listingId: listingId}))
      });
    }

    return this.store.select(state => getRulesForListingId(state, listingId)).pipe(
      filter(r => !!r));
  }

  updateRule(ruleId: number, listingId: number, bankAccountId: number, percentage: number): Observable<RoutingRule> {
    return this.routingRuleService.updateRule(ruleId, listingId, bankAccountId, percentage).pipe(
      map(r => {
        this.store.dispatch(new RoutingRulesUpdateSuccess(r));
        return r;
      }));
  }


  createRule(listingId: number,  accounts: {bank_account_id: number, percentage: number}[]): Observable<RoutingRule[]> {
    return this.routingRuleService.createRule(listingId, accounts).pipe(
      map(r => {
        this.store.dispatch(new RoutingRulesCreateSuccess({rules: r, listingId: listingId}));
        return r;
      }))
  }

  deleteRule(ruleId: number, listingId: number): Observable<any> {
    return this.routingRuleService.deleteRule(ruleId).pipe(
      map(r => {
        this.store.dispatch(new RoutingRulesDeleteSuccess({listingId: listingId, ruleId: ruleId}));
        return r;
      }))
  }

}
