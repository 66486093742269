import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import {
  AddOnboardingTaskToNotExist,
  OnbordingTaskCreateSuccess,
  OnbordingTaskDeleteSuccess,
  OnbordingTaskIndexRequest,
  OnbordingTaskIndexSuccess,
  OnbordingTaskShowRequest,
  OnbordingTaskShowSuccess,
  OnbordingTaskUpdateSuccess
} from "../actions/new/tasks/onboarding-task";
import { TransformerType } from "../enums/common.enum";
import { OnboardingTaskCompact } from "../models/new/tasks/onboarding-task-compact.model";
import { OnboardingTaskFull } from "../models/new/tasks/onboarding-task-full.model";
import {
  getAllOnboardingTasks, getFullOnboardingTaskById,
  getIsFullOnboardingTaskLoaded,
  getIsFullOnboardingTaskLoading,
  getIsOnboardingTaskLoaded,
  getIsOnboardingTaskLoading, getOnboardingTaskExist,
  State
} from "../reducers";
import { OnboardingTaskService } from "../services/onboarding-task.service";

@Injectable()
export class OnboardingTaskRepository {

  constructor(private store: Store<State>,
              private onboardingTaskService: OnboardingTaskService) {
  }

  /**
   * Loading & Loaded
   */
  getIsOnboardingTaskLoading(): Observable<boolean> {
    return this.store.select(getIsOnboardingTaskLoading);
  }

  getIsOnboardingTaskLoaded(): Observable<boolean> {
    return this.store.select(getIsOnboardingTaskLoaded);
  }

  getIsFullOnboardingTaskLoading(taskId: number): Observable<boolean> {
    return this.store.select(state => getIsFullOnboardingTaskLoading(state, taskId));
  }

  getIsFullOnboardingTaskLoaded(taskId: number): Observable<boolean> {
    return this.store.select(state => getIsFullOnboardingTaskLoaded(state, taskId));
  }

  /**
   * Service Methods
   */
  getOnboardingTasks(force: boolean): Observable<OnboardingTaskCompact[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsOnboardingTaskLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsOnboardingTaskLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new OnbordingTaskIndexRequest());
      this.onboardingTaskService.getAutoTasks().subscribe(res => {
        this.store.dispatch(new OnbordingTaskIndexSuccess(res));
      });
    }
    return this.store.select(getAllOnboardingTasks).pipe(map(t => t as OnboardingTaskCompact[]));
  }

  getTaskById(id: number): Observable<OnboardingTaskFull> {
    let loading = false;
    let loaded = false;
    this.getIsFullOnboardingTaskLoading(id).pipe(take(1)).subscribe(l => loading = l);
    this.getIsFullOnboardingTaskLoaded(id).pipe(take(1)).subscribe(l => loaded = l);
    if (!loading && !loaded) {
      this.store.dispatch(new OnbordingTaskShowRequest(id));
      this.onboardingTaskService.getTaskById(id).subscribe(res => {
        this.store.dispatch(new OnbordingTaskShowSuccess(res));
      }, err => {
        this.store.dispatch(new AddOnboardingTaskToNotExist(id));
      });
    }
    return this.store.select((state) => getFullOnboardingTaskById(state, id)).pipe(
      filter(v => !!v),
      filter(task => task.__type === TransformerType.FULL),
      map(task => task as OnboardingTaskFull));
  }

  createOnboardingTask(data): Observable<OnboardingTaskFull> {
    return this.onboardingTaskService.createOnboardingTask(data).pipe(map(value => {
      this.store.dispatch(new OnbordingTaskCreateSuccess(value));
      return value;
    }));
  }

  updateOnboardingTask(id: number, data): Observable<OnboardingTaskFull> {
    return this.onboardingTaskService.updateOnboardingTask(id, data).pipe(map(value => {
      this.store.dispatch(new OnbordingTaskUpdateSuccess(value));
      return value;
    }));
  }

  deleteOnboardingTask(id: number): Observable<null> {
    return this.onboardingTaskService.deleteOnboardingTask(id).pipe(map(value => {
      this.store.dispatch(new OnbordingTaskDeleteSuccess(id));
      return null;
    }));
  }

  getIsOnboardingTaskExist(taskId: number): Observable<boolean> {
    return this.store.select(state => getOnboardingTaskExist(state, taskId));
  }
}
