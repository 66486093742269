import { Action } from "../../../actions/action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import * as _ from "lodash";
import { GuestAccessDraft } from "../../../models/new/drafts/guest-access-draft.model";
import { GuestAccessDraftActionTypes } from "../../../actions/new/setttings/drafts/guest-access-drafts";


export interface GuestAccessDraftState extends EntityState<GuestAccessDraft> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const guestAccessDraftAdapter: EntityAdapter<GuestAccessDraft> = createEntityAdapter
  < GuestAccessDraft > ({
    selectId: (guestAccessDraft: GuestAccessDraft) => guestAccessDraft.id
  });

export const initialState: GuestAccessDraftState = guestAccessDraftAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function guestAccessDraftReducer(state: GuestAccessDraftState = initialState, action: Action): GuestAccessDraftState {
  switch (action.type) {
    case GuestAccessDraftActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        isLoaded: false
      }
    }

    case GuestAccessDraftActionTypes.INDEX_SUCCESS: {
      const guestAccessDrafts = action.payload as GuestAccessDraft[];
      return guestAccessDraftAdapter.addAll(guestAccessDrafts, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case GuestAccessDraftActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }

    case GuestAccessDraftActionTypes.CREATE_SUCCESS: {
      const guestAccessDraft = action.payload as GuestAccessDraft;
      return guestAccessDraftAdapter.addOne(guestAccessDraft,{
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case GuestAccessDraftActionTypes.SHOW_REQUEST: {
      const guestAccessDraftId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, guestAccessDraftId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          guestAccessDraftId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, guestAccessDraftId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, guestAccessDraftId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case GuestAccessDraftActionTypes.SHOW_SUCCESS: {
      const guestAccessDraft = action.payload as GuestAccessDraft;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, guestAccessDraft.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, guestAccessDraft.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, guestAccessDraft.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          guestAccessDraft.id
        ];
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case GuestAccessDraftActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case GuestAccessDraftActionTypes.UPDATE_SUCCESS: {
      const guestAccessDraft = action.payload as GuestAccessDraft;
      return guestAccessDraftAdapter.updateOne({
        id: guestAccessDraft.id,
        changes: guestAccessDraft
      }, state);
    }

    case GuestAccessDraftActionTypes.DELETE_REQUEST: {
      return {
        ...state
      };
    }

    case GuestAccessDraftActionTypes.DELETE_SUCCESS: {
      const guestAccessDraftId = action.payload as number;

      return guestAccessDraftAdapter.removeOne(guestAccessDraftId, state);
    }

    default: {
      return state;
    }

  }
}

export const _getIsGuestAccessDraftLoading = (state: GuestAccessDraftState) => state.isLoading;
export const _getIsGuestAccessDraftLoaded = (state: GuestAccessDraftState) => state.isLoaded;

export const _getIsFullGuestAccessDraftLoading = (state: GuestAccessDraftState, draftId: number) => {
  return state.fullLoadingIds.indexOf(draftId) !== -1;
};
export const _getIsFullGuestAccessDraftLoaded = (state: GuestAccessDraftState, draftId: number) => {
  return state.fullLoadedIds.indexOf(draftId) !== -1;
};
