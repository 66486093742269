import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Affiliate} from "../../models/affiliate";
import {Action} from "../../actions/action";
import {AffiliateActionTypes} from "../../actions/new/affiliate";

export interface AffiliateState extends EntityState<Affiliate> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const affiliateAdapter: EntityAdapter<Affiliate> = createEntityAdapter<Affiliate>({
  selectId: (inventory: Affiliate) => inventory.id,
  sortComparer: sortByTitle
});

export function sortByTitle(a: Affiliate, b: Affiliate): number {
  if (a.agent_name.toLowerCase() > b.agent_name.toLowerCase()) {
    return 1;
  }

  if (a.agent_name.toLowerCase() < b.agent_name.toLowerCase()) {
    return -1;
  }

  return 0;
}

const initialState = affiliateAdapter.getInitialState({
  isLoading: false,
  isLoaded: false
})

export function affiliateReducer(state: AffiliateState = initialState, action: Action): AffiliateState {
  switch (action.type) {
    case AffiliateActionTypes.INDEX_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }
    case AffiliateActionTypes.INDEX_SUCCESS: {
      return affiliateAdapter.addAll(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true
      })
    }
    case AffiliateActionTypes.CREATE_SUCCESS: {
      return affiliateAdapter.addOne(action.payload, state);
    }
    case AffiliateActionTypes.UPDATE_SUCCESS: {
      return affiliateAdapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
    }
    case AffiliateActionTypes.DELETE_SUCCESS: {
      return affiliateAdapter.removeOne(action.payload, state);
    }
    default: {
      return state;
    }
  }
}

export const _isAffiliatesLoading = (state: AffiliateState) => state.isLoading;
export const _isAffiliatesLoaded = (state: AffiliateState) => state.isLoaded;
