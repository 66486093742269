import { Action } from "../../actions/action";
import { BookingData, OwnerBlockData } from "../../models/calendar-response";
import { CalendarDataActionTypes } from "../../actions/new/calendar-data";
import Utils from "../../utils/utils";

export interface CalendarDataState {
  blocks: { [id: number]: OwnerBlockData },
  bookings: { [id: number]: BookingData },
}

export const initialState: CalendarDataState = {
  blocks: {},
  bookings: {}
};


export function calendarDataReducer(state: CalendarDataState = initialState, action: Action): CalendarDataState {
  switch (action.type) {

    case CalendarDataActionTypes.DATA_INDEX_SUCCESS: {
      return {
        ...state,
        blocks: {
          ...state.blocks,
          ...action.payload.blocks
        },
        bookings: {
          ...state.bookings,
          ...action.payload.bookings
        }
      };
    }
    default: {
      return state;
    }
  }
}

export const _getBlockById = (state: CalendarDataState, id: number) => {
  return state.blocks[id];
};

export const _getBookingById = (state: CalendarDataState, id: number) => {
  return state.bookings[id];
};

