/**
 * Created by aditya on 14/10/17.
 */
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { getAppIsMenuHidden, State } from "../../../reducers";

@Component({
  selector: "sd-owner-main-layout-generic",
  template: `
    <sd-layout style="overflow-x: hidden">
      <div id="main_content" fxLayout="row">
        <div class="accent-background" style="overflow-x: hidden">

          <sd-owner-sidebar>
            <ng-content></ng-content>
          </sd-owner-sidebar>
        </div>
      </div>
    </sd-layout>
  `,
  styles: []
})
export class LayoutOwnerMainGenericComponent implements OnInit {

  sidebarWidth: string;
  fullWidth: string;

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
    console.log("onInit sd-owner-generic-main-layout");
    this.store.select(getAppIsMenuHidden).subscribe((value) => {
      if (value) {
        this.sidebarWidth = "50px";
        this.fullWidth = "100%";
      } else {
        this.sidebarWidth = "250px";
        this.fullWidth = "97.5%";
      }
    });
  }
}
