import {map, take} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {SpaceDraftsService} from "../../services/drafts/space-drafts.service";
import {Injectable} from "@angular/core";
import {SpaceDraft} from "../../models/new/drafts/space-draft.model";
import {
  SpaceDraftCreateRequest,
  SpaceDraftCreateSuccess,
  SpaceDraftDeleteRequest,
  SpaceDraftDeleteSuccess,
  SpaceDraftIndexRequest,
  SpaceDraftIndexSuccess,
  SpaceDraftUpdateRequest,
  SpaceDraftUpdateSuccess
} from "../../actions/new/setttings/drafts/space-drafts";
import {
  getAllSpaceDrafts,
  getFullSpaceDraftById,
  getIsFullSpaceDraftLoaded,
  getIsFullSpaceDraftLoading,
  getIsSpaceDraftLoaded,
  getIsSpaceDraftLoading,
  State
} from "../../reducers";

@Injectable()
export class SpaceDraftRepository {

  constructor(private store: Store<State>,
              private spaceDraftService: SpaceDraftsService) {
  }

  /**
   * Loading & Loaded
   */
  getIsSpaceDraftLoading(): Observable<boolean> {
    return this.store.select(getIsSpaceDraftLoading);
  }

  getIsSpaceDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsSpaceDraftLoaded);
  }

  getIsFullSpaceDraftLoading(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullSpaceDraftLoading(state, draftId));
  }

  getIsFullSpaceDraftLoaded(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullSpaceDraftLoaded(state, draftId));
  }

  /**
   * Service Methods
   */
  getAllSpaceDrafts(force: boolean): Observable<SpaceDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsSpaceDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsSpaceDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new SpaceDraftIndexRequest());

      this.spaceDraftService.getSpaceDrafts().subscribe(response => {
        this.store.dispatch(new SpaceDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(getAllSpaceDrafts).pipe(map(t => t as SpaceDraft[]));
  }

  getSpaceDraftById(draftId: number): Observable<SpaceDraft> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsSpaceDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsSpaceDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new SpaceDraftIndexRequest());

      this.spaceDraftService.getSpaceDrafts().subscribe(response => {
        this.store.dispatch(new SpaceDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(state => getFullSpaceDraftById(state, draftId)).pipe(
      map(t => t as SpaceDraft));
  }


  createSpaceDraft(data: Partial<SpaceDraft>): Observable<SpaceDraft> {
    this.store.dispatch(new SpaceDraftCreateRequest());
    return this.spaceDraftService.createSpaceDraft(data).pipe(map(res => {
      this.store.dispatch(new SpaceDraftCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateSpaceDraft(draftId: number, data: Partial<SpaceDraft>): Observable<SpaceDraft> {
    this.store.dispatch(new SpaceDraftUpdateRequest(draftId));
    return this.spaceDraftService.updateSpaceDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new SpaceDraftUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deleteSpaceDraft(draftId: number): Observable<null> {
    this.store.dispatch(new SpaceDraftDeleteRequest(draftId));
    return this.spaceDraftService.deleteSpaceDraft(draftId).pipe(map(res => {
      this.store.dispatch(new SpaceDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}

