import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { skip, takeUntil } from "rxjs/operators";
import { BookingEnumHelper } from "../../../enums/booking.enum";
import { Booking } from "../../../models/booking";
import { UserFull } from "../../../models/new/user/user-full.model";
import { ContactRepository } from "../../../repository/contact.repository";
import { ListingRepository } from "../../../repository/listing.repository";
import { StayDuvetService } from "../../../services/stayduvet";

@Component({
  selector: "sd-contact-reservations-info",
  template: `
    <div fxLayoutAlign="center center" *ngIf="loading" style="padding-top: 15px">
      <mat-spinner [diameter]="40" [strokeWidth]="4" color="accent"></mat-spinner>
    </div>

    <mat-card class="padding" *ngIf="reservations.length == 0 && !loading" fxFlex="98%" fxLayoutAlign="center center">
      <span style="font-size: small;">No Reservation Found.</span>
    </mat-card>

    <div *ngIf="reservations.length > 0 && !loading" fxLayout="column" fxLayoutGap="10px" fxFlex="98%"
         style="padding-top: 10px;padding-bottom: 50px">
      <mat-card class="padding" *ngFor="let reservation of reservations" (click)="goToReservation(reservation.id)"
                fxFlex="100%"
                [style.background]="BookingEnumHelper.getBookingStatusTitleAndColor(reservation.status).color">
        <div fxLayout="row" fxLayoutAlign="space-between" style="font-size: small;">
          <div fxFlex="20%" fxLayout="row" fxLayoutGap="5px">
            <strong>Check In:</strong>
            <span>{{reservation.start}}</span>
          </div>
          <div fxFlex="20%" fxLayout="row" fxLayoutGap="5px">
            <strong>Check Out:</strong>
            <span>{{reservation.end}}</span>
          </div>
          <div fxFlex="50%" fxLayout="row" fxLayoutGap="5px">
            <strong>Listing Name:</strong>
            <span>{{getListingTitleFromId(reservation.property_id)}}</span>
          </div>
        </div>
      </mat-card>
    </div>

  `,
  styles: [`
    .padding {
      cursor: pointer;
      padding: -10px -10px -10px -10px;
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    mat-spinner {
      height: 40px;
      width: 40px;
    }
  `]
})

export class ContactReservationsInfoComponent implements OnInit, OnDestroy {

  @Input() contact: UserFull;
  reservations: Booking[] = [];
  loading = true;
  BookingEnumHelper = BookingEnumHelper;
  private destroyed$ = new Subject();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private service: StayDuvetService,
              private contactRepo: ContactRepository,
              private listingRepo: ListingRepository) {
  }

  ngOnInit() {

    const route$ = this.route.params.pipe(takeUntil(this.destroyed$));

    route$.subscribe(params => {
      const contactId = +params["id"];
      this.loading = true;
      this.contactRepo.getFullContactById(contactId).pipe(takeUntil(route$.pipe(skip(1)))).subscribe((contact: UserFull) => {
        this.contact = contact;
        if (this.contact) {
          this.service.getContactReservation(this.contact.id).subscribe((reservations) => {
            this.reservations = reservations;
            console.log(this.reservations);
            this.loading = false;
          }, () => {
            this.loading = false;
          });
        }
      });
    });

  }

  goToReservation(reservationId: number) {
    this.router.navigate(["/reservations/" + reservationId]);
  }

  getListingTitleFromId(id: number): string {
    return this.listingRepo.getListingTitle(id);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
