import {
  DedicatedGuestSpaceType,
  GuestInteractionTypes,
  ListingCoffeeMakerType,
  ListingCurrentState,
  ListingFrequency,
  ListingInstantBookVisibility, ListingPricingVendor,
  PropertyType, PropertyTypeBookingPal
} from "../../../enums/listing.enum";
import { AirbnbAccount } from "../../airbnb_account";
import { ChecklistCategory } from "../checklist-category";
import { UserCompact } from "../user/user-compact.model";

import { ListingCompact } from "./listing-compact.model";
import { ListingImage } from "./listing-image.model";
import { ListingLog } from "./listing-log.model";
import { ListingSeasonalStay } from "./listing-seasonal-stay.model";

export interface ListingFull extends ListingCompact {
  // Relationships
  parent_property_id: string;

  /**
   * Marketing Info
   */
  summary: string;
  the_space: string;
  guest_access: string;
  the_neighbourhood: string;
  getting_around: string;
  interaction_with_guests: string;
  owner_story: string;
  other_notes: string;
  the_neighbourhood_draft_id: number;
  guest_access_draft_id: number;
  interaction_with_guests_draft_id: number;
  other_notes_draft_id: number;
  summary_draft_id: number;
  getting_around_draft_id: number;
  the_space_draft_id: number;
  goal_percentage_draft_id: number;
  owner_story_draft_id: number;
  private_notes_draft_id: number;
  concierge_draft_id: number;


  /**
   * Property info
   */
  // Location
  lat: number;
  lng: number;
  time_zone_name: string;
  show_exact_location: boolean;
  namespace: string;

  // Moderation of Listing
  homeaway_active: boolean;
  airbnb_active: boolean;
  stayduvet_active: boolean;
  synchronization_category: string;
  ical_token: string;

  // Details
  area_size: number;
  property_type: PropertyType;
  property_type_mbp: PropertyTypeBookingPal;
  dining_seat_count: number;

  // Amenities
  amenities: string[];

  // House Rules
  house_rules: string;
  suitable_for_children: boolean;
  suitable_for_infants: boolean;
  pets_allowed: boolean;
  smoking_allowed: boolean;
  events_allowed: boolean;
  additional_rules: string;
  instant_book_type: ListingInstantBookVisibility;

  interaction_type: GuestInteractionTypes;
  keep_personal_belonging: DedicatedGuestSpaceType;

  // Property Access
  private_notes: string;
  concierge_information: string;
  property_access_note: string;
  keybox_number: string;
  thermostat_locations: string;
  ac_filter_size: string;
  ac_filter_location: string;
  wifi_cable_acct: string;
  break_box_location: string;
  contractors_lockbox: string;
  house_secrets: string;
  google_photos_link: string;
  airbnb_cancellation_policy: AirbnbCancellationPolicy;
  homeaway_cancellation_policy: HomeawayCancellationPolicy;
  wifi_note: string;
  wifi_network: string;
  wifi_password: string;

  parking_note: string;
  entertainment_note: string;
  coffee_maker_type: ListingCoffeeMakerType;

  current_state: ListingCurrentState;
  trash_day: string;
  trash_day_frequency: ListingFrequency;
  recycling_day: string;
  recycling_day_frequency: ListingFrequency;
  task_threshold: string;
  cleaning_instructions: string;

  /**
   * Booking and Pricing Details
   */
  // Booking Rules
  check_in_time_end: string;
  preparation_time: number;
  advance_notice: number;
  min_nights: number;
  max_nights: number;
  maximum_guest_number: number;
  seasonal_min_stays: ListingSeasonalStay[];

  // Pricing Details
  min_dynamic_price: number;
  pricing_vendor: ListingPricingVendor;
  base_price: number;
  base_weekend_price: number;
  // cleaning_fee: number;
  security_deposit: number;
  extra_guest_price: number;
  guests_included: number;
  pet_fee: number;
  tax_percent: number;
  management_fee: number;
  linens_fee: number;
  resort_fee: number;
  community_fee: number;
  management_fee_type: string;
  resort_fee_type: string;
  community_fee_type: string;


  must_climb_stairs: boolean;
  potential_for_noise: boolean;
  pets_live_on_property: boolean;
  no_parking_on_property: boolean;
  some_spaces_are_shared: boolean;
  amenity_limitations: boolean;
  surveillance_or_recording_devices_on_property: boolean;
  weapons_on_property: boolean;
  dangerous_animals_on_property: boolean;

  must_climb_stairs_details: string;
  potential_for_noise_details: string;
  pets_live_on_property_details: string;
  no_parking_on_property_details: string;
  some_spaces_are_shared_details: string;
  amenity_limitations_details: string;
  surveillance_or_recording_devices_on_property_details: string;
  weapons_on_property_details: string;
  dangerous_animals_on_property_details: string;

  weekly_discount: number;
  monthly_discount: number;
  discount_for_last_3_days: number;
  discount_for_last_7_days: number;
  discount_for_last_14_days: number;
  discount_for_last_21_days: number;
  discount_for_last_28_days: number;

  last_minute_discount: boolean;
  dynamic_pricing: boolean;

  exterior_lighting: boolean;
  deadbolt_lock: boolean;
  smoke_detector: boolean;
  carbon_monoxide_detector: boolean;
  fire_extinguisher: boolean;
  first_aid_kit: boolean;
  enhanced_cleaning_measures_implemented: boolean;
  disinfectant_used: boolean;
  high_touch_surfaces_cleaned: boolean;
  towels_washed: boolean;
  industry_association_cleaning_practices: boolean;
  no_person_contact: boolean;
  property_unavailable_between_bookings: boolean;
  smoke_detector_location?: string;
  carbon_monoxide_detector_location?: string;
  fire_extinguisher_location?: string;
  first_aid_kit_location?: string;
  emergency_exit_route?: string;
  hospital_contact?: string;
  police_contact?: string;
  fire_station_contact?: string;


  /**
   * Ratings and reviews
   */
  airbnb_rating: number;
  homeaway_rating: number;
  stayduvet_rating: number;

  /**
   * Timestamps
   */
  details_updated_at: string;
  settings_updated_at: string;
  rate_updated_at: string;
  avail_updated_at: string;
  active_since: string;
  last_cal_sync_on: string;


  airbnbAccount: {
    data: AirbnbAccount;
  };

  logs: {
    data: ListingLog[];
  };

  owner: {
    data: UserCompact;
  };

  assignee?: {
    data: UserCompact;
  };

  checklistCategories: {
    data: ChecklistCategory[];
  };

  images: {
    data: ListingImage[]
  };
}

export enum AirbnbCancellationPolicy {
  AIRBNB_CANCELLATION_POLICY_FLEXIBLE = "flexible",
  AIRBNB_CANCELLATION_POLICY_MODERATE = "moderate",
  AIRBNB_CANCELLATION_POLICY_STRICT = "strict_14_with_grace_period",
}

export enum HomeawayCancellationPolicy {
  HOMEAWAY_CANCELLATION_POLICY_RELAXED = "RELAXED",
  HOMEAWAY_CANCELLATION_POLICY_MODERATE = "MODERATE",
  HOMEAWAY_CANCELLATION_POLICY_FIRM = "FIRM",
  HOMEAWAY_CANCELLATION_POLICY_STRICT = "STRICT",
  HOMEAWAY_CANCELLATION_POLICY_NONE = "NOREFUND",
}
