import {filter, map, take} from "rxjs/operators";
import {
  getAllCannedResponses,
  getCannedResponsesForListingId,
  getFullCannedResponseById,
  getIsCannedResponseLoaded,
  getIsCannedResponseLoading,
  getIsFullCannedResponseLoaded,
  getIsFullCannedResponseLoading,
  State
} from "../reducers";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {Injectable} from "@angular/core";
import {CannedResponseService} from "../services/canned-response.service";
import {CannedResponseCompact} from "../models/new/automation/canned-response-compact.model";
import {
  CannedResponseCreateRequest,
  CannedResponseCreateSuccess,
  CannedResponseDeleteRequest,
  CannedResponseDeleteSuccess,
  CannedResponseIndexRequest,
  CannedResponseIndexSuccess,
  CannedResponseShowRequest,
  CannedResponseShowSuccess,
  CannedResponseUpdateRequest,
  CannedResponseUpdateSuccess
} from "../actions/new/automation/canned-response";
import {CannedResponseFull} from "../models/new/automation/canned-response-full.model";
import {TransformerType} from "../enums/common.enum";

@Injectable()
export class CannedResponseRepository {

  constructor(private store: Store<State>,
              private cannedResponseService: CannedResponseService) {
  }

  /**
   * Loading & Loaded
   */
  getIsCannedResponseLoading(): Observable<boolean> {
    return this.store.select(getIsCannedResponseLoading);
  }

  getIsCannedResponseLoaded(): Observable<boolean> {
    return this.store.select(getIsCannedResponseLoaded);
  }

  getIsFullCannedResponseLoading(responseId: number): Observable<boolean> {
    return this.store.select(state => getIsFullCannedResponseLoading(state, responseId));
  }

  getIsFullCannedResponseLoaded(responseId: number): Observable<boolean> {
    return this.store.select(state => getIsFullCannedResponseLoaded(state, responseId));
  }

  /**
   * Service Methods
   */
  getAllCannedResponses(force: boolean, listingId: number): Observable<CannedResponseCompact[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsCannedResponseLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsCannedResponseLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new CannedResponseIndexRequest());

      this.cannedResponseService.getCannedResponses().subscribe(response => {
        this.store.dispatch(new CannedResponseIndexSuccess(response.data));
      });
    }

    if (listingId) {
      return this.store.select(state => getCannedResponsesForListingId(state, listingId));
    }

    return this.store.select(getAllCannedResponses).pipe(map(t => t as CannedResponseCompact[]));
  }

  getCompactCannedResponseById(responseId: number): Observable<CannedResponseCompact> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsCannedResponseLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsCannedResponseLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new CannedResponseIndexRequest());

      this.cannedResponseService.getCannedResponses().subscribe(response => {
        this.store.dispatch(new CannedResponseIndexSuccess(response.data));
      });
    }

    return this.store.select(state => getFullCannedResponseById(state, responseId)).pipe(
      map(t => t as CannedResponseCompact));
  }

  getFullCannedResponseById(responseId: number): Observable<CannedResponseFull> {
    let loading = false;
    let loaded = false;

    this.getIsFullCannedResponseLoading(responseId).pipe(take(1)).subscribe(l => loading = l);
    this.getIsFullCannedResponseLoaded(responseId).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new CannedResponseShowRequest(responseId));
      this.cannedResponseService.showCannedResponse(responseId).subscribe(response => this.store.dispatch(
        new CannedResponseShowSuccess(response.data)));
    }

    return this.store.select((state) => getFullCannedResponseById(state, responseId)).pipe(
      filter(v => !!v),
      filter(r => r.__type === TransformerType.FULL),
      map(response => response as CannedResponseFull),);
  }

  createCannedResponse(data: Partial<CannedResponseFull>): Observable<CannedResponseFull> {
    this.store.dispatch(new CannedResponseCreateRequest());
    return this.cannedResponseService.createCannedResponse(data).pipe(map(res => {
      this.store.dispatch(new CannedResponseCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateCannedResponse(cannedResponseId: number, data: Partial<CannedResponseFull>): Observable<CannedResponseFull> {
    this.store.dispatch(new CannedResponseUpdateRequest(cannedResponseId));
    return this.cannedResponseService.updateCannedResponse(cannedResponseId, data).pipe(map(res => {
      this.store.dispatch(new CannedResponseUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deleteCannedResponse(cannedResponseId: number): Observable<null> {
    this.store.dispatch(new CannedResponseDeleteRequest(cannedResponseId));
    return this.cannedResponseService.deleteCannedResponse(cannedResponseId).pipe(map(res => {
      this.store.dispatch(new CannedResponseDeleteSuccess(cannedResponseId));
      return res;
    }));
  }
}
