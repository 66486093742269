export enum AttachmentType {
  IMAGE = "image",
  AUDIO = "audio",
  VIDEO = "video",
  DOCUMENT = "doc"
}

export class AttachmentEnumHelper {

  static getFileType(file: File): AttachmentType {
    switch (file.type.split("/")[0]) {

      case AttachmentType.IMAGE.toString(): {
        return AttachmentType.IMAGE;
      }
      case AttachmentType.AUDIO.toString(): {
        return AttachmentType.AUDIO;
      }
      case AttachmentType.VIDEO.toString(): {
        return AttachmentType.VIDEO;
      }
      default: {
        return AttachmentType.DOCUMENT;
      }
    }

  }
}
