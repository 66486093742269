import { AutoReview } from "../../../models/new/auto-review";
import { Action } from "../../action";

export enum AutoReviewActionTypes {
  INDEX_REQUEST = "[Neighbourhood Drafts NEW] Index Request",
  INDEX_SUCCESS = "[Neighbourhood Drafts NEW] Index Success",

  CREATE_SUCCESS = "[Neighbourhood Drafts NEW] Create Success",
  DELETE_SUCCESS = "[Neighbourhood Drafts NEW] Delete Success",

  UPDATE_REQUEST = "[Neighbourhood Drafts NEW] Update Request",
  UPDATE_SUCCESS = "[Neighbourhood Drafts NEW] Update Success",
}

export class AutoReviewIndexRequest implements Action {
  readonly type = AutoReviewActionTypes.INDEX_REQUEST;

  constructor() {
  }
}

export class AutoReviewIndexSuccess implements Action {
  readonly type = AutoReviewActionTypes.INDEX_SUCCESS;

  constructor(public payload: AutoReview[]) {
  }
}

export class AutoReviewUpdateSuccess implements Action {
  readonly type = AutoReviewActionTypes.UPDATE_SUCCESS;

  constructor(public payload: AutoReview) {
  }
}

export class AutoReviewCreateSuccess implements Action {
  readonly type = AutoReviewActionTypes.CREATE_SUCCESS;

  constructor(public payload: AutoReview) {
  }
}

export class AutoReviewDeleteSuccess implements Action {
  readonly type = AutoReviewActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
