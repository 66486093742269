import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { __HTTPResponseType } from "../enums/common.enum";
import { RentalAgreement } from "../models/new/rental-agreement.model";
import { map } from "rxjs/operators";

@Injectable()
export class RentalAgreementService {

  constructor(private apiService: ApiService) {
  }

  getRentalAgreements(): Observable<RentalAgreement[]> {
    return this.apiService.get<{ data: RentalAgreement[] }>("/admin/rental-agreements", true)
      .pipe(map(res => res.data));
  }

  createRentalAgreement(fileName: string): Observable<RentalAgreement> {
    return this.apiService.post<{ data: RentalAgreement }>("/admin/rental-agreements", true, {
      file_name: fileName
    }).pipe(map(res => res.data));
  }

  completeRentalAgreement(id: number): Observable<RentalAgreement> {
    return this.apiService.post<{ data: RentalAgreement }>("/admin/rental-agreements/" + id + "/complete", true, null).pipe(map(res => res.data));
  }

  setAsDefault(id: number): Observable<boolean> {
    return this.apiService.put("/admin/rental-agreements/" + id + "/default", true, null).pipe(map(res => true));
  }

  updateTime(data: any): Observable<boolean> {
    return this.apiService.post("/admin/update-time", true, data).pipe(map(() => true));
  }
}
