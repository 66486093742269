import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { filter, takeWhile } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { UserFull } from "../models/new/user/user-full.model";
import { UserModelUtil } from "../models/utils/user-model.util";
import { ChangePasswordPopupComponent } from "../modules/shared/components/change-password-popup";
import { UserRepository } from "../repository/user-repository";
import { AppService } from "../services/app.service";
import { getContactMethodTypes } from "../utils/utils";

@Component({
  selector: "sd-profile-setting",
  template: `
    <sd-owner-main-layout-fullwidth>
      <div id="spinner" *ngIf="userLogging" fxLayout="row" fxLayoutAlign="center center" fxFlex="100%">
        <mat-spinner color="accent" [diameter]="60" [strokeWidth]="5"></mat-spinner>
      </div>
      <div *ngIf="userLogged" class="requiredHeight" style="margin-bottom: 50px" fxFlex="100%" fxLayout="column"
           fxLayoutAlign="flex-start">
        <div class="pre1" fxFlex="100%">
          <section class="content">
            <div fxLayout="column" class="image" style="margin-top:15%" fxLayoutAlign="center center">
              <img *ngIf="uploadProgress == 0" (click)="updateImage()" class="profileImage"
                   src="{{getProfilePic(user?.pic_thumb_url)}}">
              <input type="file" accept="image/*" style="visibility: hidden" #addImageWrapper>
              <span *vaFlexAlignmentHack></span>
              <div class="profileImage" fxLayoutAlign="center center" *ngIf="uploadProgress != 0">
                <span style="color: white; font-size: large">{{uploadProgress}} %</span>
              </div>
              <p style="color:#fff;font-size:26px" align="center"><b>{{UserModelUtil.getFullName(user)}}</b></p>
            </div>
          </section>
        </div>

        <div *ngIf="userLogged" fxLayout="column" fxLayoutAlign.lt-sm="center center" fxLayoutGap="10px" fxFlex="90%"
             style="padding-left: 20px; padding-right: 20px">

          <h2 style="=margin-left: 30px;padding-right: 20px">Profile Details:</h2>
          <form fxLayout="column" fxFlex="100%" [formGroup]="userProfileForm"
                (ngSubmit)="userProfileForm.valid && profileUpdate()">
            <div style="margin-top:20px;padding-left: 20px;padding-right: 20px" fxLayout="column" fxFlex="95%"
                 fxLayoutGap="20px">

              <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
                <mat-form-field [color]="'accent'" class="width-1" dividerColor="accent">
                  <mat-icon style="color:#194267" matSuffix>contacts</mat-icon>
                  <input matInput placeholder="First Name" formControlName='first_name'>
                  <mat-error> First Name is required</mat-error>
                </mat-form-field>

                <mat-form-field [color]="'accent'" class="width-1" dividerColor="accent">
                  <mat-icon style="color:#194267" matSuffix>contacts</mat-icon>
                  <input matInput placeholder="Last Name" formControlName='last_name'>
                  <mat-error> Last Name is required</mat-error>
                </mat-form-field>
              </div>
              <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
                <mat-form-field [color]="'accent'" class="width-1" dividerColor="accent">
                  <mat-icon style="color:#194267" matSuffix>email</mat-icon>
                  <input matInput placeholder="Email" formControlName='email'>
                  <mat-error> Email is required</mat-error>
                </mat-form-field>
                <mat-form-field [color]="'accent'" class="width-1" dividerColor="accent">
                  <mat-icon style="color:#194267" matSuffix>email</mat-icon>
                  <input matInput placeholder="Secondary Email" formControlName='secondary_email'>
                  <mat-error>Secondary Email is required</mat-error>
                </mat-form-field>
              </div>

              <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
                <mat-form-field [color]="'accent'" class="width-1" dividerColor="accent">
                  <input matInput placeholder="Phone Number" formControlName='phone_number'>
                  <mat-error> Phone Number is required</mat-error>
                </mat-form-field>
                <mat-form-field [color]="'accent'" class="width-1" dividerColor="accent">
                  <input matInput placeholder="Secondary Phone Number" formControlName='secondary_phone_number'>
                </mat-form-field>
              </div>

              <sd-select class="width-2" placeholder="Preferred Contact Method"
                         [control]="preferredContactMethod" [options]="contactMethodsOptions"></sd-select>

              <mat-form-field [color]="'accent'" dividerColor="accent" class="textArea width-2">
                            <textarea matInput placeholder="Your Notes" rows="4" formControlName='description'>
                            </textarea>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center" fxLayoutGap.lt-sm="20px">
              <div fxLayout="row" fxLayoutGap="10px">
                <button mat-raised-button [disabled]="isProfileUpdating" class="dangerButton" type="submit"><span
                  class="successSpan"><b>UPDATE PROFILE DETAILS</b></span>
                </button>
                <mat-spinner id="primary" [diameter]="30" [strokeWidth]="4" *ngIf="isProfileUpdating"></mat-spinner>
              </div>

              <button mat-raised-button type="button" color="accent"
                      (click)="openchangePassForm()"><b>CHANGE PASSWORD</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </sd-owner-main-layout-fullwidth>
  `,
  styles: [`
    #spinner {
      position: fixed;
      top: 45%;
      right: 50%;
    }

    #primary {
      width: 24px;
      height: 24px;
    }

    .profileImage {
      display: block;
      width: 150px;
      height: 150px;
      margin: 1em auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-border-radius: 99em;
      -moz-border-radius: 99em;
      border-radius: 99em;
      border: 5px solid #eee;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
    }

    .pre1 {
      max-height: 40%;
      width: 100%;
      flex: 0 0 0% !important;
      display: table;
      background-image: url(https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/user_cover.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #464646;
    }

    .width-1 {
      width: 48%;
    }

    .width-2 {
      width: 98%;
    }

    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 95%;
      }
    }
  `]
})

export class ProfileSettingComponent implements OnInit, OnDestroy, AfterViewInit {
  user: UserFull;
  userLogging: boolean;
  userLogged: boolean;
  isProfileUpdating = false;
  userProfileForm: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  userEmail: FormControl;
  userSecondaryEmail: FormControl;
  userPhoneNumber: FormControl;
  userSecondaryPhoneNumber: FormControl;
  preferredContactMethod: FormControl;
  userNotes: FormControl;
  contactMethods;
  contactMethodsOptions: {title: string, value: any}[];
  uploadProgress = 0;
  UserModelUtil = UserModelUtil;
  @ViewChild("addImageWrapper", {read: ElementRef, static: true}) addImageWrapper: ElementRef;
  private isAlive = true;

  constructor(private userRepo: UserRepository,
              private appService: AppService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.setupUser();
  }

  ngAfterViewInit() {
    this.appService.changeAppTitle("Profile");
  }

  setupUser() {
    this.contactMethods = getContactMethodTypes();
    this.contactMethodsOptions = this.contactMethods.map(method => {
      return {title: method.title, value: method.slug};
    });

    this.userRepo.getIsUserLoggingIn().pipe(takeWhile(() => this.isAlive))
      .subscribe(l => this.userLogging = l);

    this.userRepo.getIsUserLoggedIn().pipe(takeWhile(() => this.isAlive))
      .subscribe(l => this.userLogged = l);

    this.userRepo.getUser().pipe(takeWhile(() => this.isAlive), filter(f => !!f),)
      .subscribe((user) => {
        this.user = user;
        if (this.userLogged) {
          this.firstName = new FormControl(this.user.first_name, [Validators.required]);
          this.lastName = new FormControl(this.user.last_name, [Validators.required]);
          this.userEmail = new FormControl(this.user.email, [Validators.required, Validators.email]);
          this.userSecondaryEmail = new FormControl(this.user.secondary_email);
          this.userPhoneNumber = new FormControl(this.user.phone_number, [Validators.required]);
          this.userSecondaryPhoneNumber = new FormControl(this.user.secondary_phone_number);
          this.preferredContactMethod = new FormControl(this.user.preferred_contact_method, [Validators.required]);
          this.userNotes = new FormControl(this.user.description);

          this.userProfileForm = new FormGroup({
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.userEmail,
            secondary_email: this.userSecondaryEmail,
            phone_number: this.userPhoneNumber,
            secondary_phone_number: this.userSecondaryPhoneNumber,
            preferred_contact_method: this.preferredContactMethod,
            description: this.userNotes,
          });
        }
      });

  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  getProfilePic(image: string) {
    if (isNullOrUndefined(image)) {
      return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/user_placeholder.jpg";
    } else {
      return image;
    }
  }

  profileUpdate() {
    this.isProfileUpdating = true;
    const data = this.userProfileForm.value;
    if (this.userProfileForm.get("email").value === this.user.email) {
      delete data.email;
      console.log(data);
    }
    this.userRepo.updateProfile(data).subscribe(() => {
      this.isProfileUpdating = false;
    }, () => {
      this.isProfileUpdating = false;
    });
  }

  openchangePassForm() {
    const dialogRef = this.dialog.open(ChangePasswordPopupComponent);
    dialogRef.updateSize("100%");
  }

  updateImage() {
    this.addImageWrapper.nativeElement.click();
    this.addImageWrapper.nativeElement.onchange = (event) => {
      if (event.target.files.length > 0) {

        this.userRepo.updateUserPic({image: event.target.files[0]}).subscribe((event: { progress?: any, response?: any }) => {

          if (event.response) {
            this.uploadProgress = 0;
          } else {
            this.uploadProgress = event.progress;
          }
        });
      }
    };
  }
}
