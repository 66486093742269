import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { CannedResponseFull } from "../../../models/new/automation/canned-response-full.model";
import { CannedResponseCompact } from "../../../models/new/automation/canned-response-compact.model";
import { Action } from "../../../actions/action";
import { CannedResponseActionTypes } from "../../../actions/new/automation/canned-response";
import * as _ from "lodash";
import { CommonUtil } from "../../../utils/common.util";
import { AutomatedMessageCompact } from "../../../models/new/automation/automated-messages-compact.model";

export interface CannedResponseState extends EntityState<CannedResponseFull | CannedResponseCompact> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const cannedResponseAdapter: EntityAdapter<CannedResponseCompact | CannedResponseFull> = createEntityAdapter
  < CannedResponseCompact | CannedResponseFull > ({
    selectId: (cannedResponse: CannedResponseCompact | CannedResponseFull) => cannedResponse.id,
    sortComparer: sortByTitle
  });

export const initialState: CannedResponseState = cannedResponseAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function sortByTitle(first: CannedResponseCompact, second: CannedResponseCompact): number {
  if (first.title > second.title) {
    return 1;
  }

  if (first.title < second.title) {
    return -1;
  }

  return 0;
}


export function cannedResponseReducer(state: CannedResponseState = initialState, action: Action): CannedResponseState {
  switch (action.type) {
    case CannedResponseActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true
      }
    }

    case CannedResponseActionTypes.INDEX_SUCCESS: {
      const cannedResponses = action.payload as CannedResponseCompact[];
      return cannedResponseAdapter.addAll(cannedResponses, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case CannedResponseActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case CannedResponseActionTypes.CREATE_SUCCESS: {
      const cannedResponse = action.payload as CannedResponseFull;
      return cannedResponseAdapter.addOne(cannedResponse, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case CannedResponseActionTypes.SHOW_REQUEST: {
      const cannedResponseId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, cannedResponseId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          cannedResponseId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, cannedResponseId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, cannedResponseId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case CannedResponseActionTypes.SHOW_SUCCESS: {
      const cannedResponse = action.payload as CannedResponseFull;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, cannedResponse.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, cannedResponse.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, cannedResponse.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          cannedResponse.id
        ];
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case CannedResponseActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case CannedResponseActionTypes.UPDATE_SUCCESS: {
      const cannedResponse = action.payload as CannedResponseFull;
      return cannedResponseAdapter.updateOne({
        id: cannedResponse.id,
        changes: cannedResponse
      }, state);
    }

    case CannedResponseActionTypes.DELETE_REQUEST: {
      return {
        ...state
      };
    }

    case CannedResponseActionTypes.DELETE_SUCCESS: {
      const cannedResponseId = action.payload as number;

      return cannedResponseAdapter.removeOne(cannedResponseId, state);
    }

    default: {
      return state;
    }

  }
}

export const _getIsCannedResponseLoading = (state: CannedResponseState) => state.isLoading;
export const _getIsCannedResponseLoaded = (state: CannedResponseState) => state.isLoaded;

export const _getIsFullCannedResponseLoading = (state: CannedResponseState, responseId: number) => {
  return state.fullLoadingIds.indexOf(responseId) !== -1;
};
export const _getIsFullCannedResponseLoaded = (state: CannedResponseState, responseId: number) => {
  return state.fullLoadedIds.indexOf(responseId) !== -1;
};
