import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { BankInfoModel } from "../../../../models/bank-info.model";
import { ListingCompact } from "../../../../models/new/listing/listing-compact.model";
import { RoutingRule } from "../../../../models/new/listing/routing-rule";
import { UserFull } from "../../../../models/new/user/user-full.model";
import { ContactRepository } from "../../../../repository/contact.repository";
import { ListingRepository } from "../../../../repository/listing.repository";
import { RoutingRulesRepository } from "../../../../repository/routing-rules.repository";

@Component({
  selector: "sd-create-routing-rule",
  template: `
    <sd-modal-popup-layout [title]="title">
      <div fxFlex="100%" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">


        <div fxFlex="100%" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"
             style="width: 100%; margin: 20px">
          <sd-select style="width: 60%" placeholder="Select Listing"
                     [control]="listingControl" [options]="filteredListingsOptions"></sd-select>

          <mat-form-field style="width: 30%" color="accent">
            <input matInput type="number" [formControl]="percentageControl" placeholder="Percentage">
          </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <mat-spinner *ngIf="isUpdating" [color]="'accent'" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <button mat-raised-button *ngIf="!openForEdit" [disabled]="!formGroup.valid"
                  color="accent" (click)="add()">
            SAVE
          </button>
          <button mat-raised-button *ngIf="openForEdit" color="accent"
                  (click)="update()">UPDATE
          </button>
          <button mat-raised-button color="warn" (click)="close()">CANCEL</button>
        </div>

      </div>
    </sd-modal-popup-layout>
  `,
})

export class CreateRoutingRuleComponent implements OnInit {
  title: string;
  isUpdating: boolean;
  formGroup: FormGroup;
  listingControl: FormControl;
  percentageControl: FormControl;
  oldRules: RoutingRule[] = [];

  listings: ListingCompact[] = [];
  filteredListings: ListingCompact[] = [];
  filteredListingsOptions: {title: string, value: any}[] = [];

  @Input() openForEdit: boolean;
  @Input() bankInfo: BankInfoModel;
  @Input() routingRule: RoutingRule;
  @Input() contact: UserFull;
  @Output() createSuccess: EventEmitter<any> = new EventEmitter();
  @Output() updateSuccess: EventEmitter<any> = new EventEmitter();

  constructor(private routingRepo: RoutingRulesRepository,
              private contactRepo: ContactRepository,
              private listingRepo: ListingRepository,
              private dialog: MatDialog) {

    this.listingControl = new FormControl(this.openForEdit ? this.routingRule.property_id : null, [Validators.required]);
    this.percentageControl = new FormControl(this.openForEdit ? this.routingRule.percentage : null, [Validators.required, Validators.min(0), Validators.max(100)]);

    this.formGroup = new FormGroup({
      property_id: this.listingControl,
      percentage: this.percentageControl,
    });
  }

  ngOnInit() {
    this.title = this.openForEdit ? "Update Routing Rule" : "Add New Routing Rule";
    this.setUpRoutingRules();
    this.setUpListings();
  }

  setUpRoutingRules() {
    this.oldRules = this.bankInfo.routingRules.data;
  }

  setUpListings() {
    this.listingRepo.getAcceptedListings().subscribe((listings) => {
      const listingIds = this.contact.managementContact.data.properties;
      this.listings = listings.filter(listing => listingIds.includes(listing.id));
      console.log(this.listings);
      this.filterRules();
    });
  }

  filterRules() {
    const listingIds = this.oldRules.map(b => b.property_id);
    this.filteredListings = this.listings.filter(f => listingIds.indexOf(f.id) === -1);
    this.filteredListingsOptions = this.filteredListings.map(listing => {
      return {title: listing.title, value: listing.id};
    });
  }

  add() {
    console.log("form", this.formGroup.value);
    this.isUpdating = true;

    const accounts = [
      {
        ...this.formGroup.value,
        bank_account_id: this.bankInfo.id
      }
    ];
    this.routingRepo.createRule(this.listingControl.value, accounts)
      .subscribe(res => {
        this.isUpdating = false;
        this.createSuccess.emit();
        this.close();
      }, error1 => {
        this.isUpdating = false;
      });
  }

  update() {
    this.isUpdating = true;
    this.routingRepo.updateRule(this.routingRule.id, this.listingControl.value, this.routingRule.bank_account_id, this.percentageControl.value)
      .subscribe((res) => {
        this.isUpdating = false;
        this.updateSuccess.emit();
        this.close();
      }, error1 => {
        this.isUpdating = false;
      });
  }

  close() {
    this.dialog.closeAll();
  }
}
