import { Action } from "../../../actions/action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { SpaceDraft } from "../../../models/new/drafts/space-draft.model";
import { SpaceDraftActionTypes } from "../../../actions/new/setttings/drafts/space-drafts";
import * as _ from "lodash";


export interface SpaceDraftState extends EntityState<SpaceDraft> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const spaceDraftAdapter: EntityAdapter<SpaceDraft> = createEntityAdapter
  < SpaceDraft > ({
    selectId: (spaceDraft: SpaceDraft) => spaceDraft.id
  });

export const initialState: SpaceDraftState = spaceDraftAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function spaceDraftReducer(state: SpaceDraftState = initialState, action: Action): SpaceDraftState {
  switch (action.type) {
    case SpaceDraftActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        isLoaded: false
      }
    }

    case SpaceDraftActionTypes.INDEX_SUCCESS: {
      const spaceDrafts = action.payload as SpaceDraft[];
      return spaceDraftAdapter.addAll(spaceDrafts, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case SpaceDraftActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }

    case SpaceDraftActionTypes.CREATE_SUCCESS: {
      const spaceDraft = action.payload as SpaceDraft;
      return spaceDraftAdapter.addOne(spaceDraft,{
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case SpaceDraftActionTypes.SHOW_REQUEST: {
      const spaceDraftId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, spaceDraftId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          spaceDraftId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, spaceDraftId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, spaceDraftId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case SpaceDraftActionTypes.SHOW_SUCCESS: {
      const spaceDraft = action.payload as SpaceDraft;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, spaceDraft.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, spaceDraft.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, spaceDraft.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          spaceDraft.id
        ];
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case SpaceDraftActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case SpaceDraftActionTypes.UPDATE_SUCCESS: {
      const spaceDraft = action.payload as SpaceDraft;
      return spaceDraftAdapter.updateOne({
        id: spaceDraft.id,
        changes: spaceDraft
      }, state);
    }

    case SpaceDraftActionTypes.DELETE_REQUEST: {
      return {
        ...state
      };
    }

    case SpaceDraftActionTypes.DELETE_SUCCESS: {
      const spaceDraftId = action.payload as number;

      return spaceDraftAdapter.removeOne(spaceDraftId, state);
    }

    default: {
      return state;
    }

  }
}

export const _getIsSpaceDraftLoading = (state: SpaceDraftState) => state.isLoading;
export const _getIsSpaceDraftLoaded = (state: SpaceDraftState) => state.isLoaded;

export const _getIsFullSpaceDraftLoading = (state: SpaceDraftState, draftId: number) => {
  return state.fullLoadingIds.indexOf(draftId) !== -1;
};
export const _getIsFullSpaceDraftLoaded = (state: SpaceDraftState, draftId: number) => {
  return state.fullLoadedIds.indexOf(draftId) !== -1;
};
