import { createSelector } from "reselect";

import { Action } from "../actions/action";
import {
  PROPERTY_MANAGER_UPDATE_SUCCESS, PROPERTY_MANAGERS_ADD_SUCCESS,
  PROPERTY_MANAGERS_FETCH_REQUEST,
  PROPERTY_MANAGERS_FETCH_SUCCESS
} from "../actions/property-manager";
import { PropertyManager } from "../models/property-manager.model";
import Utils from "../utils/utils";

export interface State {
  ids: number[];
  entities: {
    [id: number]: PropertyManager;
  };
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  ids: [],
  entities: {},
  loading: false,
  loaded: false
}

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case PROPERTY_MANAGERS_FETCH_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case PROPERTY_MANAGERS_FETCH_SUCCESS: {
      const managers = action.payload;
      const managerIds = managers.map(manager => manager.id);
      const entities = Utils.normalize(managers);
      return Object.assign({}, state, { ids: managerIds, entities: entities, loaded: true, loading: false });
    }
    case PROPERTY_MANAGERS_ADD_SUCCESS: {
      const manager = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [manager.id]: manager
        },
        ids: [
          ...state.ids,
          manager.id
        ]
      };
    }
    case PROPERTY_MANAGER_UPDATE_SUCCESS: {
      const manager = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [manager.id]: manager
        }
      };
    }
    default: {
      return state;
    }
  }
}

export const getIsLoading = (state: State) => state.loading;
export const getIsLoaded = (state: State) => state.loaded;
export const getEntities = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => {
  return ids.map(id => entities[id]);
});
