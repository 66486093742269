import { OwnerStoryDraft } from "../../../../models/new/drafts/owner-story-draft.model";
import { Action } from "../../../action";

export enum OwnerStoryActionTypes {
  INDEX_REQUEST = "[Owner Story Drafts NEW] Index Request",
  INDEX_SUCCESS = "[Owner Story Drafts NEW] Index Success",

  CREATE_REQUEST = "[Owner Story Drafts NEW] Create Request",
  CREATE_SUCCESS = "[Owner Story Drafts NEW] Create Success",

  SHOW_REQUEST = "[Owner Story Drafts NEW] Show Request",
  SHOW_SUCCESS = "[Owner Story Drafts NEW] Show Success",

  UPDATE_REQUEST = "[Owner Story Drafts NEW] Update Request",
  UPDATE_SUCCESS = "[Owner Story Drafts NEW] Update Success",

  DELETE_REQUEST = "[Owner Story Drafts NEW] Delete Request",
  DELETE_SUCCESS = "[Owner Story Drafts NEW] Delete Success"
}

export class OwnerStoryDraftIndexRequest implements Action {
  readonly type = OwnerStoryActionTypes.INDEX_REQUEST;
}

export class OwnerStoryDraftIndexSuccess implements Action {
  readonly type = OwnerStoryActionTypes.INDEX_SUCCESS;

  constructor(public payload: OwnerStoryDraft[]) {
  }
}

export class OwnerStoryDraftCreateRequest implements Action {
  readonly type = OwnerStoryActionTypes.CREATE_REQUEST;
}

export class OwnerStoryDraftCreateSuccess implements Action {
  readonly type = OwnerStoryActionTypes.CREATE_SUCCESS;

  constructor(public payload: OwnerStoryDraft) {
  }
}

export class OwnerStoryDraftShowRequest implements Action {
  readonly type = OwnerStoryActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {

  }
}

export class OwnerStoryDraftShowSuccess implements Action {
  readonly type = OwnerStoryActionTypes.SHOW_SUCCESS;

  constructor(public payload: OwnerStoryDraft) {
  }
}

export class OwnerStoryDraftUpdateRequest implements Action {
  readonly type = OwnerStoryActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class OwnerStoryDraftUpdateSuccess implements Action {
  readonly type = OwnerStoryActionTypes.UPDATE_SUCCESS;

  constructor(public payload: OwnerStoryDraft) {
  }
}

export class OwnerStoryDraftDeleteRequest implements Action {
  readonly type = OwnerStoryActionTypes.DELETE_REQUEST;

  constructor(public payload: number) {
  }
}

export class OwnerStoryDraftDeleteSuccess implements Action {
  readonly type = OwnerStoryActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
