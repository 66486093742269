/**
 * Created by aditya on 24/8/17.
 */
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { getAppIsMenuHidden, State } from "../../../reducers";

@Component({
  selector: "sd-owner-main-layout-fullwidth",
  template: `
    <sd-layout style="overflow-x: hidden">
      <div fxLayout="row" fxFlex="100%">
        <div class="accent-background" style="overflow-x: hidden">

          <sd-owner-sidebar>
            <ng-content></ng-content>
          </sd-owner-sidebar>
        </div>
      </div>
    </sd-layout>
  `,
  styles: []
})
export class LayoutOwnerMainFullWidthComponent implements OnInit {

  sidebarWidth: string;
  isSideBarHidden: boolean = false;

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
    console.log("onInit sd-owner-main-layout-fullwidth");
    this.store.select(getAppIsMenuHidden).subscribe((value) => {
      this.isSideBarHidden = value;
      if (value) {
        this.sidebarWidth = "50px";
      } else {
        this.sidebarWidth = "250px";
      }
    });
  }
}

