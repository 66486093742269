import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of as observableOf } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { HttpEventType, HttpResponse } from "../../../node_modules/@angular/common/http";
import {
  TaskCommentCreateRequest,
  TaskCommentCreateSuccess,
  TaskCommentDeleteSuccess,
  TaskCommentIndexRequest,
  TaskCommentIndexSuccess,
  TaskCommentUpdateRequest,
  TaskCommentUpdateSuccess
} from "../actions/new/tasks/comment";
import {
  AddTaskToNotExist,
  TaskAttachmentDeleteSuccess,
  TaskBulkUpdateRequest,
  TaskBulkUpdateSuccess,
  TaskChangeAssignee,
  TaskChangeCategory,
  TaskChangeCreatedBy,
  TaskChangeCurrentPage, TaskChangeEmployee, TaskChangeEndDate, TaskChangeFilters,
  TaskChangeListings, TaskChangeOffset,TaskChangeOpenProperty,
  TaskChangePerPage, TaskChangePriceFilter,
  TaskChangeSortBy,
  TaskChangeSortOrder, TaskChangeStartDate, TaskChangeStatus,
  TaskChangeType, TaskChangeWhoWillPay,
  TaskCreateRequest,
  TaskCreateSuccess,
  TaskDeleteRequest,
  TaskDeselect,
  TaskDeselectAll,
  TaskNextPageRequest,
  TaskNextPageSuccess,
  TaskResetData,
  TaskSelect,
  TaskShowRequest,
  TaskShowSuccess,
  TaskUpdateRequest,
  TaskUpdateSuccess
} from "../actions/new/tasks/task";
import { PerPage, SortOrder, TransformerType } from "../enums/common.enum";
import { TaskCategory, TaskPaymentBy, TaskSortBy, TaskStatus, TaskType } from "../enums/task.enum";
import { Comment } from "../models/new/tasks/comment";
import { TaskCompact } from "../models/new/tasks/task-compact.model";
import { TaskFull } from "../models/new/tasks/task-full.model";
import { UploadComplete } from "../models/new/upload-complete";
import { UploadFile } from "../models/new/upload-file";
import {
  getAllTasksForCurrentPage,
  getCommentsForTaskId,
  getCommentsLoadedForTaskId,
  getCommentsLoadingForTaskId,
  getFullTaskById,
  getIsCurrentPageLoaded,
  getIsCurrentPageLoading,
  getIsFullTaskLoaded,
  getIsFullTaskLoading,
  getIsTaskLoadedForPageNumber,
  getIsTaskLoadingForPageNumber,
  getSelectedTaskIds, getSelectedTaskStatus,
  getTaskCurrentPage, getTaskEndDate, getTaskExist,
  getTaskFilterAssigneeIds,
  getTaskFilterCategory,
  getTaskFilterCreatedBy, getTaskFilterEmployeeIds,
  getTaskFilterListingIds, getTaskFilterPrice, getTaskFilters, getTaskFilterStatus,
  getTaskFilterType, getTaskFIlterWhoWillPay, getTaskOffset, getTaskOpenProperty,
  getTaskPerPage,
  getTaskSortBy,
  getTaskSortOrder, getTaskStartDate,
  getTaskTotalCount,
  getTaskTotalPages,
  State
} from "../reducers";
import { AWSService } from "../services/aws.service";
import { TaskService } from "../services/task.service";
import DateUtils from "../utils/date";

@Injectable()
export class TaskRepository {

  constructor(private store: Store<State>,
              private taskService: TaskService,
              private awsService: AWSService) {
  }

  /**
   * Loading & Loaded
   */
  getIsTaskCurrentPageLoading(): Observable<boolean> {
    return this.store.select(getIsCurrentPageLoading);
  }

  getIsTaskCurrentPageLoaded(): Observable<boolean> {
    return this.store.select(getIsCurrentPageLoaded);
  }

  getIsFullTaskLoading(taskId: number): Observable<boolean> {
    return this.store.select(state => getIsFullTaskLoading(state, taskId));
  }

  getIsFullTaskLoaded(taskId: number): Observable<boolean> {
    return this.store.select(state => getIsFullTaskLoaded(state, taskId));
  }

  /**
   * Get Other Data
   */
  getTaskCurrentPage(): Observable<number> {
    return this.store.select(getTaskCurrentPage);
  }

  getTaskTotalPages(): Observable<number> {
    return this.store.select(getTaskTotalPages);
  }

  getTaskTotalCount(): Observable<number> {
    return this.store.select(getTaskTotalCount);
  }

  getSelectedTaskIds(): Observable<number[]> {
    return this.store.select(getSelectedTaskIds);
  }

  getSelectedTaskStatus(): Observable<string[]> {
    return this.store.select(getSelectedTaskStatus);
  }

  getTaskStartDate(): Observable<Date> {
    return this.store.select(getTaskStartDate);
  }

  getTaskEndDate(): Observable<Date> {
    return this.store.select(getTaskEndDate);
  }

  getTaskOffset(): Observable<string> {
    return this.store.select(getTaskOffset);
  }

  /**
   * Get Filters
   */
  getTaskFilterType(): Observable<TaskType> {
    return this.store.select(getTaskFilterType);
  }

  getTaskFilterCategory(): Observable<string[] | null> {
    return this.store.select(getTaskFilterCategory);
  }

  getTaskFilterStatus(): Observable<string[] | null> {
    return this.store.select(getTaskFilterStatus);
  }

  getTaskFilterPrice(): Observable<boolean> {
    return this.store.select(getTaskFilterPrice);
  }

  getTaskOpenProperty(): Observable<boolean> {
    return this.store.select(getTaskOpenProperty);
  }

  getTaskFilterAssigneeIds(): Observable<number[] | null> {
    return this.store.select(getTaskFilterAssigneeIds);
  }

  getTaskFilterEmployeeIds(): Observable<number[] | null> {
    return this.store.select(getTaskFilterEmployeeIds);
  }

  getTaskFilterCreatedBy(): Observable<number[]> {
    return this.store.select(getTaskFilterCreatedBy);
  }

  getTaskFilterWhoWillPay(): Observable<TaskPaymentBy[]> {
    return this.store.select(getTaskFIlterWhoWillPay);
  }

  getTaskFilterListingIds(): Observable<number[]> {
    return this.store.select(getTaskFilterListingIds);
  }

  getTaskPerPage(): Observable<PerPage> {
    return this.store.select(getTaskPerPage);
  }

  getTaskSortBy(): Observable<TaskSortBy> {
    return this.store.select(getTaskSortBy);
  }

  getTaskSortOrder(): Observable<SortOrder> {
    return this.store.select(getTaskSortOrder);
  }

  getAllTaskFilters(): Observable<any> {
    return this.store.select(getTaskFilters);
  }

  setTaskFilters(data: any) {
    this.store.dispatch(new TaskChangeFilters(data));
  }

  /**
   * Set Filters
   */
  setTaskFilterType(type: TaskType) {
    if (type === TaskType.RECENT) {
      this.store.dispatch(new TaskChangeSortBy(TaskSortBy.CREATED_AT));
      this.store.dispatch(new TaskChangeSortOrder(SortOrder.DSC));
    } else if (type === TaskType.TODAY) {
      this.store.dispatch(new TaskChangeSortBy(TaskSortBy.DUE_ON));
      this.store.dispatch(new TaskChangeSortOrder(SortOrder.DSC));
    } else if (type === TaskType.UPCOMING) {
      this.store.dispatch(new TaskChangeSortBy(TaskSortBy.DUE_ON));
      this.store.dispatch(new TaskChangeSortOrder(SortOrder.ASC));
    } else {
      this.store.dispatch(new TaskChangeSortBy(TaskSortBy.DUE_ON));
      this.store.dispatch(new TaskChangeSortOrder(SortOrder.ASC));
    }
    return this.store.dispatch(new TaskChangeType(type));
  }

  setTaskFilterCategory(category: string[]) {
    return this.store.dispatch(new TaskChangeCategory(category));
  }

  setTaskStatus(status: string[]) {
    return this.store.dispatch(new TaskChangeStatus(status));
  }

  setTaskFilterAssigneeIds(assigneeIds: number[]) {
    return this.store.dispatch(new TaskChangeAssignee(assigneeIds));
  }

  setTaskFilterEmployeeIds(employeeIds: number[]) {
    return this.store.dispatch(new TaskChangeEmployee(employeeIds));
  }

  setTaskFilterListingIds(listingIds: number[]) {
    return this.store.dispatch(new TaskChangeListings(listingIds));
  }

  setTaskPerPage(perPage: PerPage) {
    return this.store.dispatch(new TaskChangePerPage(perPage));
  }

  setTaskSortBy(sortBy: TaskSortBy) {
    return this.store.dispatch(new TaskChangeSortBy(sortBy));
  }

  setTaskSortOrder(sortOrder: SortOrder) {
    return this.store.dispatch(new TaskChangeSortOrder(sortOrder));
  }

  selectTask(task: TaskCompact) {
    this.store.dispatch(new TaskSelect(task));
  }

  setTaskFilterCreatedBy(adminIds: number[]) {
    return this.store.dispatch(new TaskChangeCreatedBy(adminIds));
  }

  setTaskFilterWhoWillPay(id: any) {
    return this.store.dispatch(new TaskChangeWhoWillPay(id));
  }

  setTaskFilterPrice(filter: boolean) {
    return this.store.dispatch(new TaskChangePriceFilter(filter));
  }

  setTaskOpenProperty(b: boolean) {
    return this.store.dispatch(new TaskChangeOpenProperty(b));
  }

  setTaskStartDate(date: Date) {
    return this.store.dispatch(new TaskChangeStartDate(date));
  }

  setTaskEndDate(date: Date) {
    return this.store.dispatch(new TaskChangeEndDate(date));
  }

  setTaskOffset(offset: string) {
    return this.store.dispatch(new TaskChangeOffset(offset));
  }

  deselectTask(id: number) {
    this.store.dispatch(new TaskDeselect(id));
  }

  deselectAllTasks() {
    this.store.dispatch(new TaskDeselectAll());
  }

  /**
   * Service Methods
   */
  createTask(data: any): Observable<TaskFull> {
    this.store.dispatch(new TaskCreateRequest());
    return this.taskService.createTask(data).pipe(map(res => {
      this.store.dispatch(new TaskCreateSuccess(res.data));

      return res.data;
    }));
  }

  duplicateTask(taskId: number, showExpense: boolean): Observable<TaskFull> {
    this.store.dispatch(new TaskCreateRequest());
    return this.taskService.duplicateTask(taskId, showExpense).pipe(map(res => {
      this.store.dispatch(new TaskCreateSuccess(res.data));

      return res.data;
    }));
  }

  selectTaskPageNumber(page: number, force: boolean = false, employeeId?: number) {
    this.store.dispatch(new TaskChangeCurrentPage(page));
    let loading;
    let loaded;
    this.store.select(state => getIsTaskLoadingForPageNumber(state, page)).subscribe(t => loading = t);
    this.store.select(state => getIsTaskLoadedForPageNumber(state, page)).subscribe(t => loaded = t);

    let totalPages: number;
    this.getTaskTotalPages().pipe(take(1)).subscribe(t => totalPages = t);

    if ((totalPages !== 0) && (page > totalPages)) {
      return;
    }

    if (!loading && (!loaded || force)) {
      let type: TaskType;
      let category: string[];
      let assigneeIds: number[];
      let employeeIds: number[];
      let listingIds: number[];
      let createdBy: number[];
      let whoWillPay: TaskPaymentBy[];

      let perPage: PerPage;

      let sortBy: TaskSortBy;
      let sortOrder: SortOrder;
      let status: string[] | null;
      let priceFilter: boolean;
      let open_property: boolean;
      let startDate: Date;
      let endDate: Date;
      let offset: string;

      this.getTaskFilterType().pipe(take(1)).subscribe(t => type = t);
      this.getTaskFilterCategory().pipe(take(1)).subscribe(c => category = c);
      this.getTaskFilterAssigneeIds().pipe(take(1)).subscribe(a => assigneeIds = a);
      this.getTaskFilterEmployeeIds().pipe(take(1)).subscribe(e => employeeIds = e);
      this.getTaskFilterListingIds().pipe(take(1)).subscribe(l => listingIds = l);
      this.getTaskFilterCreatedBy().pipe(take(1)).subscribe(a => createdBy = a);

      this.getTaskPerPage().pipe(take(1)).subscribe(s => perPage = s);

      this.getTaskSortBy().pipe(take(1)).subscribe(s => sortBy = s);
      this.getTaskSortOrder().pipe(take(1)).subscribe(s => sortOrder = s);
      this.getTaskFilterWhoWillPay().pipe(take(1)).subscribe(a => whoWillPay = a);
      this.getTaskFilterStatus().pipe(take(1)).subscribe(s => status = s);
      this.getTaskFilterPrice().pipe(take(1)).subscribe(s => priceFilter = s);
      this.getTaskOpenProperty().pipe(take(1)).subscribe(s => open_property = s);

      this.getTaskStartDate().pipe(take(1)).subscribe(s => startDate = s);
      this.getTaskEndDate().pipe(take(1)).subscribe(s => endDate = s);
      this.getTaskOffset().pipe(take(1)).subscribe(s => offset = s);

      console.log("task type", employeeId);

      this.store.dispatch(new TaskNextPageRequest(page));
      this.taskService.getTasks(
        page,
        perPage,
        sortBy,
        sortOrder,
        type,
        category,
        assigneeIds,
        employeeIds,
        createdBy,
        listingIds,
        status,
        whoWillPay,
        priceFilter,
        open_property,
        startDate,
        endDate,
        offset,
        employeeId,
      ).subscribe(response => {
        this.store.dispatch(new TaskNextPageSuccess({
          tasks: response.data,
          currentPage: response.meta.pagination.current_page,
          totalPages: response.meta.pagination.total_pages,
          totalCount: response.meta.pagination.total
        }));
      });
    }
  }

  getCompactTaskById(taskId: number): Observable<TaskCompact> {
    return this.store.select((state) => getFullTaskById(state, taskId)).pipe(
      map(task => task as TaskCompact));
  }

  getAllTasksForCurrentPage(): Observable<TaskCompact[]> {
    return this.store.select(getAllTasksForCurrentPage).pipe(map((res) => res as TaskCompact[]));
  }

  getFullTaskById(taskId: number, force: boolean): Observable<TaskFull> {
    let loading = false;
    let loaded = false;

    this.getIsFullTaskLoading(taskId).pipe(take(1)).subscribe(l => loading = l);
    this.getIsFullTaskLoaded(taskId).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new TaskShowRequest(taskId));
      this.taskService.showTask(taskId).subscribe(response => this.store.dispatch(new TaskShowSuccess(response.data)),
        error1 => {
          this.store.dispatch(new AddTaskToNotExist(taskId));
        });
    }

    return this.store.select((state) => getFullTaskById(state, taskId)).pipe(
      filter(v => !!v),
      filter(v => v.__type === TransformerType.FULL),
      map(task => task as TaskFull),);
  }

  updateTaskById(taskId: number, data: any): Observable<TaskFull> {
    this.store.dispatch(new TaskUpdateRequest(taskId));

    return this.taskService.updateTask(taskId, data).pipe(map(res => {
      this.store.dispatch(new TaskUpdateSuccess(res.data));

      return res.data;
    }));

  }

  bulkUpdateTasks(listingId: number, status: TaskStatus, dueDate: any, category: TaskCategory, title: string, assigneeId: number, type: string, employeeId: number): Observable<null> {
    let taskIds: number[];
    this.getSelectedTaskIds().pipe(take(1)).subscribe(t => taskIds = t);

    this.store.dispatch(new TaskBulkUpdateRequest());

    const data: any = {};

    if (!isNullOrUndefined(listingId)) {
      data.listing_id = listingId;
    }

    if (!isNullOrUndefined(status)) {
      data.status = status;
    }

    if (!isNullOrUndefined(assigneeId)) {
      data.assignee_id = assigneeId;
    }

    if (!isNullOrUndefined(employeeId)) {
      data.employee_id = employeeId;
    }

    if (!isNullOrUndefined(type)) {
      data.assignee_type = type;
    }

    if (!isNullOrUndefined(dueDate)) {
      data.due_date = DateUtils.toISODateString(dueDate);
    }

    if (!isNullOrUndefined(category)) {
      data.category = category;
    }

    if (!isNullOrUndefined(title) && title.trim() !== "") {
      data.title = title;
    }

    return this.taskService.bulkUpdateTasks(taskIds, data).pipe(map(res => {
      this.store.dispatch(new TaskBulkUpdateSuccess(res));
      return res;
    }));
  }

  bulkCompleteTasks() {
    let taskIds: number[];
    this.getSelectedTaskIds().pipe(take(1)).subscribe(t => taskIds = t);

    this.store.dispatch(new TaskBulkUpdateRequest());

    return this.taskService.bulkCompleteTasks(taskIds).pipe(map(res => {
      const changes: any = {};

      changes.status = TaskStatus.COMPLETED;

      this.store.dispatch(new TaskBulkUpdateSuccess(changes));

      return res;
    }));
  }

  deleteTask(taskId: number): Observable<boolean> {
    this.store.dispatch(new TaskDeleteRequest());
    return this.taskService.deleteTask(taskId).pipe(map(task => {
      this.store.dispatch(new TaskResetData());
      return true;
    }));
  }

  deleteTempTaskAttachment(tempAttachmentId: number): Observable<any> {
    return this.taskService.deleteTempTaskImage(tempAttachmentId);
  }

  deleteTaskAttachment(attachmentId: number, taskId: number): Observable<any> {
    return this.taskService.deleteTaskImage(attachmentId).pipe(map((res) => {
      this.store.dispatch(new TaskAttachmentDeleteSuccess({
        property_id: taskId,
        image_id: attachmentId
      }));
    }));
  }

  getPreSignedURL(type: string, title: string): Observable<UploadFile> {
    return this.taskService.getPreSignedURL(type, title);
  }

  uploadToAWS(preSignedUrl: string, file: any): Observable<number> {
    let uploadProgress = 0;
    return this.awsService.uploadToAWS(preSignedUrl, file).pipe(map(event => {
      if (event.type === HttpEventType.UploadProgress) {
        uploadProgress = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        uploadProgress = 0;
      }
      return uploadProgress;
    }));
  }

  uploadedImage(imageId: number): Observable<UploadComplete> {
    return this.taskService.uploadedImage(imageId);
  }

  resetTasks() {
    this.store.dispatch(new TaskResetData());
  }

  mergeTasks(taskIds): Observable<any> {
    console.log(taskIds);
    return observableOf(1);
  }

  // getIsCommentLoading(): Observable<boolean> {
  //   return this.store.select(getIsCommentLoading);
  // }
  //
  // getIsCommentLoaded(): Observable<boolean> {
  //   return this.store.select(getIsCommentLoaded);
  // }
  //
  // getIsFullCommentLoading(commentId: number): Observable<boolean> {
  //   return this.store.select(state => getIsFullCommentLoading(state, commentId));
  // }
  //
  // getIsFullCommentLoaded(commentId: number): Observable<boolean> {
  //   return this.store.select(state => getIsFullCommentLoaded(state, commentId));
  // }

  getIsCommentsLoadingForTaskId(taskId: number) {
    return this.store.select(state => getCommentsLoadingForTaskId(state, taskId));
  }

  getIsCommentsLoadedForTaskId(taskId: number) {
    return this.store.select(state => getCommentsLoadedForTaskId(state, taskId));
  }

  // getAllComments(force: boolean, taskId?: number): Observable<Comment[]> {
  //   let loading: boolean;
  //   let loaded: boolean;
  //   this.getIsCommentLoading().take(1).subscribe(l => loading = l);
  //   this.getIsCommentLoaded().take(1).subscribe(l => loaded = l);
  //   console.log(loading, loaded);
  //   if ( !loading && (!loaded || force) ) {
  //     this.store.dispatch(new TaskCommentIndexRequest());
  //
  //     this.taskService.getComments(taskId).subscribe(response => {
  //       this.store.dispatch(new TaskCommentIndexSuccess(response.data));
  //     });
  //   }

  getCommentsForTask(taskId: number, force: boolean = false): Observable<Comment[]> {
    let loading;
    let loaded;

    this.getIsCommentsLoadingForTaskId(taskId).pipe(take(1)).subscribe(l => loading = l);
    this.getIsCommentsLoadedForTaskId(taskId).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new TaskCommentIndexRequest(taskId));
      this.taskService.getComments(taskId).subscribe(comments => {
        this.store.dispatch(new TaskCommentIndexSuccess({comments: comments, taskId: taskId}));
      });
    }

    return this.store.select((state) => getCommentsForTaskId(state, taskId)).pipe(filter(t => !!t));
  }

  createComment(taskId: number, data: any): Observable<Comment> {
    this.store.dispatch(new TaskCommentCreateRequest());
    return this.taskService.createComment(taskId, data).pipe(map(res => {

      this.store.dispatch(new TaskCommentCreateSuccess({
        comment: res,
        taskId: taskId
      }));
      return res;
    }));
  }

  updateComment(data: any, commentId: number): Observable<Comment> {
    this.store.dispatch(new TaskCommentUpdateRequest(commentId));
    return this.taskService.updateComment(commentId, data).pipe(map((res) => {
      this.store.dispatch(new TaskCommentUpdateSuccess({comment: res.data}));
      return res;
    }));
  }

  deleteComment(taskId: number, commentId: number): Observable<boolean> {
    const data = {taskId: taskId, commentId: commentId};
    return this.taskService.deleteComment(commentId).pipe(map((res) => {
      this.store.dispatch(new TaskCommentDeleteSuccess(data));
      return res;
    }));
  }

  getTasksForReportPreview(data: {
    start: string,
    end: string,
    type?: string,
    category?: string,
    assignee_ids?: string,
    listing_ids?: string,
    payment_by?: string
    sort_by?: string,
    sort_order?: string,
    creator_ids?: string,
    status?: string,
    price_filter?: number,
    employee_id?: number,
  }) {
    return this.taskService.getTasksForReport(data).pipe(map(res => res.data));
  }

  downloadReport(data: {
    start: string,
    end: string,
    type?: string,
    category?: string,
    assignee_ids?: string,
    listing_ids?: string,
    payment_by?: string
    sort_by?: string,
    sort_order?: string,
    created_by?: string,
    format: string,
    send_email?: boolean,
    status?: string,
    employee_id?: number,
  }) {
    return this.taskService.downloadReport(data);
  }

  getIsTaskExist(taskId: number): Observable<boolean> {
    return this.store.select(state => getTaskExist(state, taskId));
  }

  downloadInspectionReport(): Observable<boolean> {
    return this.taskService.downloadInspectionReport();
  }
}
