import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { __HTTPResponseType } from "../enums/common.enum";
import { OnboardingTaskCompact } from "../models/new/tasks/onboarding-task-compact.model";
import { OnboardingTaskFull } from "../models/new/tasks/onboarding-task-full.model";

import { ApiService } from "./api.service";

@Injectable()
export class OnboardingTaskService {

  constructor(private apiService: ApiService) {
  }

  getAutoTasks(): Observable<OnboardingTaskCompact[]> {
    return this.apiService.get<{data: OnboardingTaskCompact[]}>("/admin/onboarding-tasks", true)
      .pipe(map(res => res.data));
  }

  getTaskById(id: number): Observable<OnboardingTaskFull> {
    return this.apiService.get<{data: OnboardingTaskFull}>("/admin/onboarding-tasks/" + id, true)
      .pipe(map(res => res.data));
  }

  createOnboardingTask(data): Observable<OnboardingTaskFull> {
    return this.apiService.post<{data: OnboardingTaskFull}>("/admin/onboarding-tasks", true, data)
      .pipe(map(res => res.data));
  }

  updateOnboardingTask(id: number, data): Observable<OnboardingTaskFull> {
    return this.apiService.put<{data: OnboardingTaskFull}>("/admin/onboarding-tasks/" + id, true, data)
      .pipe(map(res => res.data));
  }

  deleteOnboardingTask(id: number): Observable<boolean> {
    return this.apiService.delete("/admin/onboarding-tasks/" + id, true, null, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        const contentDispositionHeader = res.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        const fileName = result.replace(/"/g, "");
        saveAs(res.body, fileName);
        return true;
      }));
  }
}
