import { Directive, ElementRef, OnDestroy, OnInit, Output } from "@angular/core";
import onresize from "resize-event";
import { Subject } from "rxjs";

@Directive({
  selector: "[sdResizeDetector]"
})
export class ResizeDetectorDirective implements OnInit, OnDestroy {
  @Output() dimensionChange = new Subject<{ offsetWidth: number, offsetHeight: number }>();
  private _$resizeObserver;
  private _oldDimensions = "";

  constructor(private _host: ElementRef<HTMLElement>) { }

  ngOnInit() {
    this._$resizeObserver = onresize(this._host.nativeElement, () => {
      const element: HTMLElement = this._host.nativeElement;
      const newDimensions = element.offsetWidth.toString() + element.offsetHeight.toString();
      if (this._oldDimensions !== newDimensions) {
        this.dimensionChange.next({ offsetWidth: element.offsetWidth, offsetHeight: element.offsetHeight });
      }
      this._oldDimensions = newDimensions;
    });
  }

  ngOnDestroy() {
    this._$resizeObserver?.disconnect();
  }
}
