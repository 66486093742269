import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators";

import { GoalSetPercentagesDraft } from "../../models/new/drafts/goal-set-percentages.modal";
import { ApiService } from "../api.service";

@Injectable()
export class GoalSetPercentagesService {

  constructor(private apiService: ApiService) {
  }

  getGoalSetPercentagesDrafts(): Observable<GoalSetPercentagesDraft[]> {
    return this.apiService.get<any>("/admin/goal-percentage-drafts", true)
      .pipe(map(res => res.data));
  }

  createGoalSetPercentagesDraft(data: Partial<GoalSetPercentagesDraft>): Observable<GoalSetPercentagesDraft> {
    return this.apiService.post<any>("/admin/goal-percentage-drafts", true, data)
      .pipe(map(res => res.data));
  }

  updateGoalSetPercentagesDraft(draftId: number, data: Partial<GoalSetPercentagesDraft>): Observable<GoalSetPercentagesDraft> {
    return this.apiService.put<any>("/admin/goal-percentage-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));
  }

  deleteGoalSetPercentagesDraft(draftId: number) {
    return this.apiService.delete("/admin/goal-percentage-drafts/" + draftId, true);
  }
}
