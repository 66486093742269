
import { HttpEvent, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import {
  ContactActiveIndexRequest,
  ContactActiveIndexSuccess,
  ContactActiveStatusRequest,
  ContactActiveStatusSuccess,
  ContactBankAccountCreateSuccess,
  ContactBankAccountDeleteSuccess,
  ContactBankAccountUpdateSuccess,
  ContactCreateRequest,
  ContactCreateSuccess,
  ContactCurrentPageChange,
  ContactDeleteFromStoreRequest,
  ContactInActiveIndexRequest,
  ContactInActiveIndexSuccess,
  ContactNextPageRequest,
  ContactNextPageSuccess,
  ContactPerPageChange,
  ContactShowRequest,
  ContactShowSuccess,
  ContactSortOrderChange,
  ContactSortTypeChange,
  ContactStatusChange,
  ContactTypeChange,
  ContactUpdateRequest,
  ContactUpdateSuccess, ContactW9FormDeleteSuccess,
  ContactW9FormUploadSuccess,
  HomeOwnerIndexRequest,
  HomeOwnerIndexSuccess
} from "../actions/new/contacts/contact";
import { SortOrder, TransformerType } from "../enums/common.enum";
import { ContactDocumentType, ContactSortType, ContactStatus, ContactType } from "../enums/contact.enum";
import { BankInfoModel } from "../models/bank-info.model";
import { ContactGetResponse } from "../models/new/contact/contact-get-response";
import { CreditCard } from "../models/new/credit-card.model";
import { UserCompact } from "../models/new/user/user-compact.model";
import { UserFull } from "../models/new/user/user-full.model";
import { W9Form } from "../models/new/user/w9-form.model";
import { ContactShowResponse } from "../models/responses/tasks/contact-show.response";
import {
  getAllActiveContacts,
  getAllContactsForCurrentPage,
  getAllInActiveContacts,
  getContactById,
  getContactCurrentPage,
  getContactFilterType,
  getContactPerPage,
  getContactSortBy,
  getContactSortOrder,
  getContactStatus,
  getContactTotalCount,
  getContactTotalPage,
  getHomeOwners,
  getIsActiveContactLoaded,
  getIsActiveContactLoading,
  getIsContactCurrentPageLoaded,
  getIsContactCurrentPageLoading,
  getIsContactLoadedForPageNumber,
  getIsContactLoadingForPageNumber,
  getIsFullContactLoaded,
  getIsFullContactLoading,
  getIsHomeOwnerLoaded,
  getIsHomeOwnerLoading,
  getIsInActiveContactLoaded,
  getIsInActiveContactLoading,
  State
} from "../reducers";
import { ApiService } from "../services/api.service";
import { AWSService } from "../services/aws.service";
import { ContactService } from "../services/contact.service";

@Injectable()
export class ContactRepository {
  public constructor(private apiService: ApiService,
                     private store: Store<State>,
                     private contactService: ContactService,
                     private awsService: AWSService) {

  }

  getIsActiveContactLoading(): Observable<boolean> {
    return this.store.select(getIsActiveContactLoading);
  }

  getIsActiveContactLoaded(): Observable<boolean> {
    return this.store.select(getIsActiveContactLoaded);
  }

  getIsInActiveContactLoading(): Observable<boolean> {
    return this.store.select(getIsInActiveContactLoading);
  }

  getIsInActiveContactLoaded(): Observable<boolean> {
    return this.store.select(getIsInActiveContactLoaded);
  }

  getAllActiveContacts(force = false): [
    Observable<UserCompact[]> | null
    ] {
    let loading;
    let loaded;

    this.getIsActiveContactLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsActiveContactLoaded().pipe(take(1)).subscribe(l => loaded = l);


    if (!loading && (!loaded || force)) {
      console.log("inside");
      this.store.dispatch(new ContactActiveIndexRequest());
      this.contactService.getActiveContacts().subscribe(res => {
        this.store.dispatch(new ContactActiveIndexSuccess(res.data));
      });
    }

    return [
      this.store.select(getAllActiveContacts)
    ];
  }

  getAllInActiveContacts(force = false): [
    Observable<UserCompact[]> | null
    ] {
    let loading;
    let loaded;

    this.getIsInActiveContactLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsInActiveContactLoaded().pipe(take(1)).subscribe(l => loaded = l);


    if (!loading && (!loaded || force)) {
      this.store.dispatch(new ContactInActiveIndexRequest());
      this.contactService.getInActiveContacts().subscribe(res => {
        this.store.dispatch(new ContactInActiveIndexSuccess(res.data));
      });
    }

    return [
      this.store.select(getAllInActiveContacts)
    ];
  }


  getHomeOwners(force: boolean = false): Observable<UserCompact[]> {
    let loading;
    let loaded;

    this.getIsHomeOwnerLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsHomeOwnerLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new HomeOwnerIndexRequest());
      this.contactService.getHomeOwners().subscribe(res => {
        this.store.dispatch(new HomeOwnerIndexSuccess(res.data));
      });
    }

    return this.store.select(getHomeOwners).pipe(filter(u => !!u));
  }

  getIsHomeOwnerLoading(): Observable<boolean> {
    return this.store.select(getIsHomeOwnerLoading);
  }

  getIsHomeOwnerLoaded(): Observable<boolean> {
    return this.store.select(getIsHomeOwnerLoaded);
  }



  getCompactContactById(contactId: number): Observable<UserCompact> {
    return this.store.select(state => getContactById(state, contactId)).pipe(map(u => u as UserCompact));
  }

  // Second Observable is from service if the obj is being loaded for the first time
  getFullContactById(contactId: number, force: boolean = false): Observable<UserFull> {
    const loading$ = this.store.select(state => getIsFullContactLoading(state, contactId));
    const loaded$ = this.store.select(state => getIsFullContactLoaded(state, contactId));
    let loaded = false;
    let loading = false;


    loading$.subscribe(l => loading = l);
    loaded$.subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new ContactShowRequest(contactId));
      this.contactService.showContact(contactId).subscribe(res => {
        this.store.dispatch(new ContactShowSuccess(res.data));
      });
    }

    return this.store.select(state => getContactById(state, contactId)).pipe(
      filter(l => !!l),
      filter(contact => contact.___type === TransformerType.FULL),
      map(l => l as UserFull),);
  }

  getIsFullContactLoading(contactId: number): Observable<boolean> {
    return this.store.select(state => getIsFullContactLoading(state, contactId));
  }

  getIsFullContactLoaded(contactId: number): Observable<boolean> {
    return this.store.select(state => getIsFullContactLoaded(state, contactId));
  }

  updateContactById(contactId: number, data: Partial<UserFull>): Observable<UserFull> {
    this.store.dispatch(new ContactUpdateRequest(contactId));
    return this.contactService.updateContact(contactId, data).pipe(map(res => {
      this.store.dispatch(new ContactUpdateSuccess(res.data));

      return res.data;
    }));
  }

  updateProfilePicById(contactId: number, pic: File): Observable<HttpEvent<ContactShowResponse>> {
    this.store.dispatch(new ContactUpdateRequest(contactId));
    return this.contactService.updateContactProfilePic(contactId, pic).pipe(map(res => {
      if (res instanceof HttpResponse) {
        this.store.dispatch(new ContactUpdateSuccess(res.body.data));
      }

      return res;
    }));
  }

  createNewContact(data: Partial<UserFull>): Observable<UserFull> {
    this.store.dispatch(new ContactCreateRequest());
    return this.contactService.createContact(data).pipe(map(res => {
      this.store.dispatch(new ContactCreateSuccess(res.data));

      return res.data;
    }));
  }

  markContactActive(contactId): Observable<UserFull> {
    this.store.dispatch(new ContactActiveStatusRequest({ contactId: contactId, active: true }));
    return this.contactService.markActive(contactId).pipe(map(res => {
      this.store.dispatch(new ContactActiveStatusSuccess({ contactId: contactId, active: true }));

      return res.data;
    }));
  }

  markContactInactive(contactId): Observable<UserFull> {
    this.store.dispatch(new ContactActiveStatusRequest({ contactId: contactId, active: false }));
    return this.contactService.markInActive(contactId).pipe(map(res => {
      this.store.dispatch(new ContactActiveStatusSuccess({ contactId: contactId, active: false }));

      return res.data;
    }));
  }

  uploadW9Form(uploadURL: string, file: any) {
    return this.awsService.uploadToAWS(uploadURL, file);
  }

  createW9Form(contactId: number, fileName: string, type: ContactDocumentType): Observable<W9Form> {
    return this.contactService.createW9Form(contactId, fileName, type).pipe(map(res => res.data));
  }

  completeW9Form(w9FormId: number): Observable<W9Form> {
    return this.contactService.completeW9Form(w9FormId).pipe(map(res => {
      this.store.dispatch(new ContactW9FormUploadSuccess(res.data));
      return res.data;
    }));
  }

  deleteW9Form(contactId: number, w9FormId: number): Observable<void> {
    return this.contactService.deleteW9Form(w9FormId).pipe(map(res => {
      this.store.dispatch(new ContactW9FormDeleteSuccess({contactId: contactId, formId: w9FormId}));
      return;
    }));
  }

  getSearchResults(queryString: string) {
    return this.contactService.getSearchResults(queryString);
  }

  getSelectedContacts(contactIds: number[]) {
    return this.contactService.getSelectedContacts(contactIds);
  }

  getContactCreditCards(contactId: number): Observable<CreditCard[]> {
    return this.contactService.getContactCreditCards(contactId);
  }

  setContactFilterType(type: ContactType) {
    return this.store.dispatch(new ContactTypeChange(type));
  }

  setContactPerPage(perPage: number) {
    return this.store.dispatch(new ContactPerPageChange(perPage));
  }

  setContactSortOrder(order: SortOrder) {
    return this.store.dispatch(new ContactSortOrderChange(order));
  }

  setContactSortBy(sortBy: ContactSortType) {
    return this.store.dispatch(new ContactSortTypeChange(sortBy));
  }

  setContactStatus(status: ContactStatus) {
    return this.store.dispatch(new ContactStatusChange(status));
  }

  setContactCurrentPage(page: number) {
    return this.store.dispatch(new ContactCurrentPageChange(page));
  }

  getIsContactLoadingForPageNumber(page: number): Observable<boolean> {
    return this.store.select(state => getIsContactLoadingForPageNumber(state, page));
  }

  getIsContactLoadedForPageNumber(page: number): Observable<boolean> {
    return this.store.select(state => getIsContactLoadedForPageNumber(state, page));
  }

  getIsCurrentPageLoading(): Observable<boolean> {
    return this.store.select(getIsContactCurrentPageLoading);
  }

  getIsCurrentPageLoaded(): Observable<boolean> {
    return this.store.select(getIsContactCurrentPageLoaded);
  }

  getContactTotalPages(): Observable<number> {
    return this.store.select(getContactTotalPage);
  }

  getContactTotalContacts(): Observable<number> {
    return this.store.select(getContactTotalCount);
  }

  getContactFilterType(): Observable<ContactType> {
    return this.store.select(getContactFilterType);
  }

  getContactPerPage(): Observable<number> {
    return this.store.select(getContactPerPage);
  }

  getContactSortOrder(): Observable<SortOrder> {
    return this.store.select(getContactSortOrder);
  }

  getContactSortBy(): Observable<ContactSortType> {
    return this.store.select(getContactSortBy);
  }

  getContactStatus(): Observable<ContactStatus> {
    return this.store.select(getContactStatus);
  }

  getContactCurrentPage(): Observable<number> {
    return this.store.select(getContactCurrentPage);
  }

  selectContactPageNumber(page: number, data?: string, force: boolean = false) {
    this.store.dispatch(new ContactCurrentPageChange(page));

    let loading;
    let loaded;

    this.getIsContactLoadingForPageNumber(page).pipe(take(1)).subscribe(l => loading = l);
    this.getIsContactLoadedForPageNumber(page).pipe(take(1)).subscribe(l => loaded = l);

    let totalPages;
    this.getContactTotalPages().pipe(take(1)).subscribe(t => totalPages = t);

    if ((totalPages !== 0) && (page > totalPages)) {
      return;
    }

    if (!loading && (!loaded || force)) {
      let type: ContactType;
      let perPage: number;
      let sortOrder: SortOrder;
      let sortBy: ContactSortType;
      let status: ContactStatus;

      this.getContactFilterType().pipe(take(1)).subscribe(f => type = f);
      this.getContactPerPage().pipe(take(1)).subscribe(p => perPage = p);
      this.getContactSortOrder().pipe(take(1)).subscribe(s => sortOrder = s);
      this.getContactSortBy().pipe(take(1)).subscribe(s => sortBy = s);
      this.getContactStatus().pipe(take(1)).subscribe(s => status = s);

      this.store.dispatch(new ContactNextPageRequest(page));
      this.contactService.getContacts(page, perPage, sortBy, sortOrder, type, status, data).subscribe((response: ContactGetResponse) => {
        this.store.dispatch(new ContactNextPageSuccess({
          contacts: response.data,
          currentPage: response.meta.pagination.current_page,
          totalPages: response.meta.pagination.total_pages,
          totalCount: response.meta.pagination.total
        }));
      });
    }
  }


  getAllContactsForCurrentPage(): Observable<UserCompact[]> {
    return this.store.select(getAllContactsForCurrentPage).pipe(
      filter(c => !!c),
      map(c => c as UserCompact[]),);
  }

  resetPassword(id) {
    return this.contactService.resetPassword(id);
  }

  getBankAccounts(data: { property_id?: number, user_id?: number}): Observable<BankInfoModel[]> {
    return this.contactService.getBankAccounts(data);
  }

  updateBankAccount(id: number,data: BankInfoModel): Observable<any> {
    return this.contactService.updateBankAccount(id, data).pipe(map(res => {
      this.store.dispatch(new ContactBankAccountUpdateSuccess(res));
      return res;
    }));
  }

  createBankAccount(data: BankInfoModel): Observable<any> {
    return this.contactService.createBankAccount(data).pipe(map(res => {
      this.store.dispatch(new ContactBankAccountCreateSuccess(res));
      return res;
    }));
  }

  deleteBankAccount(id: number, contactId: number) {
    return this.contactService.deleteBankAccount(id).pipe(map(res => {
      this.store.dispatch(new ContactBankAccountDeleteSuccess({bankAccountId: id, contactId: contactId}));
      return res;
    }));
  }

  mergeContacts(data: any): Observable<UserFull> {
    return this.contactService.mergeContacts(data);
  }

  deleteContactsFromStore(ids: number[]) {
    this.store.dispatch(new ContactDeleteFromStoreRequest(ids));
  }

}
