import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { combineLatest as observableCombineLatest, Observable } from "rxjs";

import { map } from "rxjs/operators";
import { UserRepository } from "../repository/user-repository";

@Injectable()
export class ReservationAuthGuard implements CanActivate {

  private dialogRef: MatDialogRef<any>;

  constructor(private userRepo: UserRepository,
              private dialog: MatDialog,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    const observables = observableCombineLatest(
      this.userRepo.getUser(),
      (user) => {
        return {
          user: user
        };
      }
    );

    return observables.pipe(map((data) => {
      return true;
    }));
  }
}
