import { PropertyManager } from "../models/property-manager.model";

import { Action } from "./action";

export const PROPERTY_MANAGERS_FETCH_REQUEST = "[PROPERTY MANAGER] Property Managers fetch request";
export const PROPERTY_MANAGERS_FETCH_SUCCESS = "[PROPERTY MANAGER] Property Managers fetch success";
export const PROPERTY_MANAGERS_ADD_SUCCESS = "[PROPERTY MANAGER] Property Managers add success";
export const PROPERTY_MANAGER_UPDATE_SUCCESS = "[PROPERTY MANAGER] Property Manager update success";

export class PropertyManagersFetchRequestAction implements Action {
  readonly type = PROPERTY_MANAGERS_FETCH_REQUEST;
}

export class PropertyManagersFetchSuccessAction implements Action {
  readonly type = PROPERTY_MANAGERS_FETCH_SUCCESS;

  constructor(public payload: PropertyManager[]) {
  }
}

export class PropertyManagersAddSuccessAction implements Action {
  readonly type = PROPERTY_MANAGERS_ADD_SUCCESS;

  constructor(public payload: PropertyManager) {
  }
}

export class PropertyManagersUpdateSuccessAction implements Action {
  readonly type = PROPERTY_MANAGER_UPDATE_SUCCESS;

  constructor(public payload: PropertyManager) {
  }
}

export type Actions
  = PropertyManagersFetchRequestAction
  | PropertyManagersFetchSuccessAction
  | PropertyManagersAddSuccessAction
  | PropertyManagersUpdateSuccessAction;
