import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "sd-quantity-input",
  template: `
    <div fxLayout="row" fxLayoutAlign="space-between center" id="quantity">
      <button mat-mini-fab [color]="'warn'" (click)="minusClicked()">—</button>
      <h2>{{quantity}}</h2>
      <button mat-mini-fab (click)="plusClicked()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  `,
  styles: [`
    :host {
      cursor: pointer;
      min-width: 60px;
      min-height: 30px;
    }

    #quantity {
      width: 150px;
      height: 50px;
    }

  `]
})

export class QuantityInputComponent {
  @Output() valueUpdate = new EventEmitter<QuantityToggleEvent>();
  @Input() quantity = 0;
  @Input() step = 1;
  @Input() minimumVal = 0;
  @Input() disabled = false;


  minusClicked() {
    if (this.disabled) {
      return;
    }

    if (this.quantity > this.minimumVal) {
      this.quantity -= this.step;

      this.valueUpdate.emit({
        value: this.quantity,
        isIncrement: false
      });
    }
  }

  plusClicked() {
    if (this.disabled) {
      return;
    }

    this.quantity += this.step;

    this.valueUpdate.emit({
      value: this.quantity,
      isIncrement: true
    });
  }
}

export class QuantityToggleEvent {
  value: number;
  isIncrement: boolean;
}
