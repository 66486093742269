import { Setting } from "../models/setting";
import { INDEX_REQUEST, INDEX_SUCCESS, UPDATE_REQUEST, UPDATE_SUCCESS } from "../actions/setting";
import { Action } from "../actions/action";
import Utils from "../utils/utils";
import { createSelector } from "reselect";


export interface State {
  ids: number[];
  entities: { [id: number]: Setting };
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  ids: [],
  entities: {},
  loading: false,
  loaded: false
};

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {

    case INDEX_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false
      }
    }

    case INDEX_SUCCESS: {
      const settings = action.payload;
      const settingIds = settings.map(setting => setting.id);
      const entities = Utils.normalize(settings);
      return {
        ...state,
        loading: false,
        loaded: true,
        ids: settingIds,
        entities: entities
       }
    }

    case UPDATE_REQUEST: {
      return state;
    }

    case UPDATE_SUCCESS: {
      const setting = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [setting.id]: setting
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoading = (state: State) => state.loading;
export const getIsLoaded = (state: State) => state.loaded;

export const getEntities = (state: State) => state.entities;

export const getIds = (state: State) => state.ids;

export const getAll = createSelector(getEntities, getIds, (entities, ids) => {
  return ids.map(id => entities[id]);
});
