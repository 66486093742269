import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

import {
  ReviewResponseDraftCreateSuccess, ReviewResponseDraftDeleteSuccess,
  ReviewResponseDraftIndexRequest,
  ReviewResponseDraftIndexSuccess, ReviewResponseDraftUpdateSuccess
} from "../../actions/new/setttings/drafts/review-response-drafts";
import { ReviewResponseDraft } from "../../models/new/drafts/review-response-draft.model";
import {
  getAllReviewResponseDrafts,
  getIsReviewResponseDraftLoaded,
  getIsReviewResponseDraftLoading,
  State
} from "../../reducers";
import { ReviewResponseDraftService } from "../../services/drafts/review-response-draft.service";

@Injectable()
export class ReviewResponseDraftRepository {
  constructor(private store: Store<State>,
              private reviewDraftService: ReviewResponseDraftService) {
  }

  /**
   * Loading & Loaded
   */
  getIsReviewResponseDraftLoading(): Observable<boolean> {
    return this.store.select(getIsReviewResponseDraftLoading);
  }

  getIsReviewResponseDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsReviewResponseDraftLoaded);
  }

  /**
   * Service Methods
   */
  getReviewResponseDrafts(force: boolean): Observable<ReviewResponseDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsReviewResponseDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsReviewResponseDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new ReviewResponseDraftIndexRequest());

      this.reviewDraftService.getReviewResponseDrafts().subscribe(response => {
        this.store.dispatch(new ReviewResponseDraftIndexSuccess(response));
      });
    }

    return this.store.select(getAllReviewResponseDrafts).pipe(map(t => t as ReviewResponseDraft[]));
  }

  createReviewResponseDraft(data: Partial<ReviewResponseDraft>): Observable<ReviewResponseDraft> {
    return this.reviewDraftService.createReviewResponseDraft(data).pipe(map(res => {
      this.store.dispatch(new ReviewResponseDraftCreateSuccess(res));
      return res;
    }));
  }

  updateReviewResponseDraft(draftId: number, data: Partial<ReviewResponseDraft>): Observable<ReviewResponseDraft> {
    return this.reviewDraftService.updateReviewResponseDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new ReviewResponseDraftUpdateSuccess(res));
      return res;
    }));
  }

  deleteReviewResponseDraft(draftId: number) {
    return this.reviewDraftService.deleteReviewResponseDraft(draftId).pipe(map(res => {
      this.store.dispatch(new ReviewResponseDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}
