import { Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { BookingRepository } from "../../../../repository/booking.repository";

@Component({
  selector: "sd-extend-payment",
  template: `
    <sd-modal-popup-layout title="Extend Payment Time">
      <div fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between center">
        <div fxFlex="40%">
          <mat-form-field [color]="'accent'" style="width:100%;">
            <input matInput type="number" placeholder="Enter Number Of Hours" [formControl]="hours">
          </mat-form-field>
        </div>
        <div fxFlex="20%" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end">
          <mat-spinner color="accent" [diameter]="30" [strokeWidth]="4" *ngIf="isSaving"></mat-spinner>
          <button mat-raised-button color="accent" (click)="extendPayment()" [disabled]="isSaving"
                  style="height:fit-content;">Save
          </button>
          <button mat-raised-button color="warn" (click)="close()" [disabled]="isSaving" style="height:fit-content;">
            Close
          </button>
        </div>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: []
})
export class ExtendPaymentPopupComponent {
  isSaving = false;
  bookingId: number;
  hours: FormControl;

  constructor(private dialog: MatDialog,
              private bookingRepo: BookingRepository,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    this.bookingId = data.booking_id;
    this.hours = new FormControl(null, [Validators.required]);
  }

  extendPayment() {
    this.isSaving = true;
    const data = {
      hours: this.hours.value
    };
    this.bookingRepo.extendExpireTime(this.bookingId, data).subscribe(res => {
      this.isSaving = false;
      this.close();
    }, err => {
      this.isSaving = false;
    });
  }

  close() {
    this.dialog.closeAll();
  }
}
