import { Action } from "../../../actions/action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import * as _ from "lodash";
import { InteractionsDraft } from "../../../models/new/drafts/intearctions-draft.model";
import { InteractionsDraftActionTypes } from "../../../actions/new/setttings/drafts/interactions-drafts";


export interface InteractionsDraftState extends EntityState<InteractionsDraft> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const interactionsDraftAdapter: EntityAdapter<InteractionsDraft> = createEntityAdapter
  < InteractionsDraft > ({
    selectId: (interactionsDraft: InteractionsDraft) => interactionsDraft.id
  });

export const initialState: InteractionsDraftState = interactionsDraftAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function interactionsDraftReducer(state: InteractionsDraftState = initialState, action: Action): InteractionsDraftState {
  switch (action.type) {
    case InteractionsDraftActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        isLoaded: false
      }
    }

    case InteractionsDraftActionTypes.INDEX_SUCCESS: {
      const interactionsDrafts = action.payload as InteractionsDraft[];
      return interactionsDraftAdapter.addAll(interactionsDrafts, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case InteractionsDraftActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }

    case InteractionsDraftActionTypes.CREATE_SUCCESS: {
      const interactionsDraft = action.payload as InteractionsDraft;
      return interactionsDraftAdapter.addOne(interactionsDraft,{
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case InteractionsDraftActionTypes.SHOW_REQUEST: {
      const interactionsDraftId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, interactionsDraftId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          interactionsDraftId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, interactionsDraftId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, interactionsDraftId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case InteractionsDraftActionTypes.SHOW_SUCCESS: {
      const interactionsDraft = action.payload as InteractionsDraft;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, interactionsDraft.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, interactionsDraft.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, interactionsDraft.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          interactionsDraft.id
        ];
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case InteractionsDraftActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case InteractionsDraftActionTypes.UPDATE_SUCCESS: {
      const interactionsDraft = action.payload as InteractionsDraft;
      return interactionsDraftAdapter.updateOne({
        id: interactionsDraft.id,
        changes: interactionsDraft
      }, state);
    }

    case InteractionsDraftActionTypes.DELETE_REQUEST: {
      return {
        ...state
      };
    }

    case InteractionsDraftActionTypes.DELETE_SUCCESS: {
      const interactionsDraftId = action.payload as number;

      return interactionsDraftAdapter.removeOne(interactionsDraftId, state);
    }

    default: {
      return state;
    }

  }
}

export const _getIsInteractionsDraftLoading = (state: InteractionsDraftState) => state.isLoading;
export const _getIsInteractionsDraftLoaded = (state: InteractionsDraftState) => state.isLoaded;

export const _getIsFullInteractionsDraftLoading = (state: InteractionsDraftState, draftId: number) => {
  return state.fullLoadingIds.indexOf(draftId) !== -1;
};
export const _getIsFullInteractionsDraftLoaded = (state: InteractionsDraftState, draftId: number) => {
  return state.fullLoadedIds.indexOf(draftId) !== -1;
};
