import { AfterViewInit, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import * as moment from "moment";
import { combineLatest as observableCombineLatest, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { PerPage, SortOrder } from "../../../enums/common.enum";
import {
  HomeOwnerTaskCategory,
  TaskCategory,
  TaskEnumHelper,
  TaskPaymentBy,
  TaskSortBy,
  TaskStatus,
  TaskType
} from "../../../enums/task.enum";
import { TaskAssigneeCategory, UserEnumHelper, UserOtherRole } from "../../../enums/user.enum";
import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { TaskCompact } from "../../../models/new/tasks/task-compact.model";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { UserFull } from "../../../models/new/user/user-full.model";
import { UserNameOnly } from "../../../models/new/user/user-name-only.model";
import { UserModelUtil } from "../../../models/utils/user-model.util";
import { ListingRepository } from "../../../repository/listing.repository";
import { OptionsRepository } from "../../../repository/options.repository";
import { TaskRepository } from "../../../repository/task.repository";
import { UserRepository } from "../../../repository/user-repository";
import { StayDuvetService } from "../../../services/stayduvet";
import { addDaysToDate, dateToDateString } from "../../../utils/calendar-utils";
import { CommonUtil } from "../../../utils/common.util";
import { ListingTaskUtil } from "../../../utils/listing-task.util";
import { GenericConfirmationPopupComponent } from "../../shared/components/confirmation-popup";

import { DownloadReportPopupComponent } from "./popups/download-report-popup";
import { MergeTasksPopupComponent } from "./popups/merge-tasks.popup";

@Component({
  selector: "sd-tasks-page",
  template: `
    <div id="spinner" *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center" fxFlex="100%">
      <mat-spinner color="accent" [diameter]="70" [strokeWidth]="6"></mat-spinner>
    </div>
    <div style="font-size:x-small;" class="requiredHeight" *ngIf="!isLoading" fxFlex="100%">
      <div fxLayout="column">


        <div style="margin-bottom: 10px" fxLayout="column" fxLayoutAlign="center start"
             fxLayoutAlign.lt-sm="center center" fxLayoutGap="10px"
             *ngIf="isOnboarding"
             fxFlex="100%">

          <button fxFlexAlign="end"
                  mat-raised-button
                  color="accent"
                  (click)="createNewTask()">
            CREATE NEW TASK
          </button>
        </div>


        <div *ngIf="!isOnboarding" fxLayoutAlign="space-between center">
          <div *ngIf="(!isUserVendor || isVendorTask) &&selectedTaskIds.length > 0" fxLayout="column"
               style="background-color: lightgrey;position: relative" fxFlex="100%">
            <div fxLayout="row" style="padding-left:1%;" fxLayoutAlign="space-between center">
              <p class="pHeading">You Have Selected {{selectedTaskIds.length}} Tasks.</p>
              <button mat-icon-button (click)="deselectAllTasks()">
                <mat-icon color="warn">close</mat-icon>
              </button>
            </div>
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center" *ngIf="!forUpdate" style="margin-bottom:5px">
              <button mat-raised-button color="accent" (click)="mergeTasks()" *ngIf="selectedTaskIds.length>1 && !isVendorTask">Merge
                Selected Tasks
              </button>
              <button mat-raised-button color="accent" (click)="showUpdatePanel()">Update Selected Tasks</button>
            </div>
            <div fxLayout="row" style="width: 100%" fxLayout.lt-sm="column" fxLayoutAlign="space-between start"
                 fxLayoutAlign.lt-sm="space-between center"
                 fxLayoutWrap="wrap"
                 *ngIf="forUpdate && !isVendorTask">
              <mat-form-field [color]="'accent'">
                <mat-select
                  [formControl]="bulkListingId"
                  placeholder="Listing"
                  (openedChange)="!$event && onMatSelectClose()">
                  <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                    <input matInput placeholder="Search Listing" [formControl]="listingFilter">
                  </mat-form-field>
                  <mat-option *ngFor="let listingId of filteredListings" [value]="listingId">
                    {{ getListingTitleFromId(listingId) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field [color]="'accent'">
                <mat-select
                  [formControl]="bulkTaskStatus"
                  placeholder="Status">
                  <mat-option *ngFor="let option of taskStatuses" [value]="option">
                    {{ TaskEnumHelper.getTaskStatusEnum(option).title }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field [color]="'accent'">
                <mat-select
                  [formControl]="bulkAssigneeId"
                  placeholder="Assignee"
                  (selectionChange)="onAssigneeChange()"
                  (openedChange)="!$event && onMatSelectClose()">
                  <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                    <input matInput placeholder="Search Assignee" [formControl]="assigneeFilter">
                  </mat-form-field>
                  <mat-option *ngFor="let assignee of filteredAssignees" [value]="assignee.id">
                    {{UserModelUtil.getFullName(assignee)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <sd-select placeholder="Assignee Type" [control]="bulkAssigneeType"
                         [options]="assigneeTypes" (selectionChanged)="onTypeChange()"></sd-select>
            </div>
            <div fxLayout="row" style="width: 100%" fxLayout.lt-sm="column" fxLayoutAlign="space-between start"
                 fxLayoutAlign.lt-sm="space-between center"
                 fxLayoutWrap="wrap"
                 *ngIf="forUpdate">
                <mat-form-field *ngIf="!isVendorTask" [color]="'accent'">
                  <mat-select
                    [formControl]="bulkCategory"
                    placeholder="Category">
                    <mat-option *ngFor="let category of categories" [value]="category">
                      {{ TaskEnumHelper.getTaskCategoryTitle(category) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              <mat-form-field *ngIf="isVendorTask" [color]="'accent'">
                <mat-select
                  [formControl]="bulkTaskStatus"
                  placeholder="Status">
                  <mat-option *ngFor="let option of taskStatuses" [value]="option">
                    {{ TaskEnumHelper.getTaskStatusEnum(option).title }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="isHouseKeeper" [color]="'accent'">
                <mat-select
                  [formControl]="bulkEmployeeId"
                  placeholder="Employee"
                  (openedChange)="!$event && onMatSelectClose()">
                  <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                    <input matInput placeholder="Search Employee" [formControl]="assigneeFilter">
                  </mat-form-field>
                  <mat-option *ngFor="let assignee of filteredAssignees" [value]="assignee.id">
                    {{UserModelUtil.getFullName(assignee)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
                <mat-form-field [color]="'accent'" style="padding-top:3px;">
                  <input matInput [matDatepicker]="startPicker" placeholder="Due Date"
                         [formControl]="bulkDueDate">
                  <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                  <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field color="accent">
                  <input [formControl]="bulkTaskTitle" matInput placeholder="Task Title">
                </mat-form-field>

                <div fxLayout="row" fxLayoutGap="10px">
                  <button mat-icon-button (click)="clearBulkUpdateForm()">
                    <mat-icon>close</mat-icon>
                  </button>

                  <button mat-button [color]="'accent'"
                          (click)="confirmBulkUpdatePopup()">
                    Update
                    <mat-icon class="size">done</mat-icon>
                  </button>
                </div>
            </div>
          </div>


          <!--For Vendor Type Users-->
          <div *ngIf="(isUserVendor && !isHomeOwner && !isVendorTask) &&selectedTaskIds.length > 0" fxLayout="row"
               style="background-color: lightgrey;position: relative" fxFlex="100%">
            <p class="pHeading" fxFlexAlign="center">You Have Selected {{selectedTaskIds.length}} Tasks.</p>
            <div fxFlexAlign="center" fxLayout="row" style="position: absolute; right: 0">
              <button mat-button [color]="'accent'"
                      (click)="confirmBulkCompletePopup()">
                Mark as Completed
                <mat-icon class="size">done</mat-icon>
              </button>

              <button mat-icon-button (click)="deselectAllTasks()">
                <mat-icon color="warn">close</mat-icon>
              </button>
            </div>
          </div>


          <div fxLayout="column" fxLayoutAlign="center start" fxLayoutAlign.lt-sm="center center" fxLayoutGap="10px"
               *ngIf="!(isUserVendor) && selectedTaskIds.length === 0"
               fxFlex="100%">
            <span *ngIf="showListingsFilter" style="font-size: 25px; font-weight: bolder">Tasks</span>
            <div fxLayout="row" style="width: 100%" fxLayout.lt-sm="column" fxLayoutAlign="space-between start"
                 fxLayoutAlign.lt-sm="space-between center">
              <div style="width: 100%" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between start"
                     fxLayoutAlign.lt-sm="space-between center">

                  <div fxLayout="column"
                       style="width: 100%" fxLayoutGap="10px"
                       fxLayoutAlign.lt-sm="center center"
                       fxLayoutAlign="start center"
                       fxHide.gt-xs="true">
                    <button style="transform: translateY(-13px); width:90%"
                            mat-raised-button class="position"
                            [disabled]="!startDate || !endDate"
                            (click)="download()" color="accent" *ngIf="showTaskAndReport">
                      DOWNLOAD REPORT
                    </button>
                    <button style="transform: translateY(-13px); width:90%"
                            mat-raised-button class="position"
                            color="accent" (click)="createNewTask()"
                            *ngIf="!isUserVendor&& showTaskAndReport">
                      CREATE NEW TASK
                    </button>
                    <button style="transform: translateY(-13px); width:90%"
                            mat-raised-button class="position"
                            color="accent" (click)="downloadReport()"
                            *ngIf="isUserAdmin && showTaskAndReport && !isDownloading">
                      DOWNLOAD SCHEDULE INSPECTION REPORT
                    </button>
                    <mat-form-field color="accent" *ngIf="showListingsFilter" style="margin-right: 20px"
                                    ngClass.xs="width-2" ngClass.gt-xs="width-3">
                      <mat-select
                        multiple
                        *ngIf="showListingsFilter"
                        placeholder="Select Listing"
                        [(ngModel)]="selectedListingIds"
                        floatPlaceholder="never"
                        (openedChange)="!$event && onMatSelectClose();!$event &&taskListingsChanged(selectedListingIds)"
                        [ngModelOptions]="{standalone: true}">

                        <mat-select-trigger>
                        <span *ngIf="selectedListingIds.length === allListingIds.length">
                          All Listings
                        </span>
                          <span *ngIf="selectedListingIds.length === 0">
                          No Listing Selected
                        </span>
                          {{
                          selectedListingIds.length > 0 && selectedListingIds.length !== allListingIds.length ? getListingTitleFromId(selectedListingIds[0]) : ""
                          }}
                          <span class="extra-text"
                                *ngIf="selectedListingIds.length > 1 && selectedListingIds.length !== allListingIds.length">
                          (+{{selectedListingIds.length - 1}} others)
                        </span>
                        </mat-select-trigger>

                        <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                          <input matInput placeholder="Search Listing" [formControl]="listingFilter">
                        </mat-form-field>

                        <div fxLayout="column">
                          <button class="select-button" mat-button
                                  (click)="onSelectAll();taskListingsChanged(selectedListingIds)">Select All
                          </button>
                          <button class="select-button" mat-button
                                  (click)="onSelectNone();taskListingsChanged(selectedListingIds)">Select None
                          </button>
                        </div>

                        <mat-option *ngFor="let listingId of filteredListings" [value]="listingId">
                          {{ getListingTitleFromId(listingId) }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <sd-select class="width-1" *ngIf="showListingsFilter" placeholder="select tag"
                               [control]="tagFilterControl" [options]="tagsOptions" [multiple]="true"
                               (selectionChanged)="propertyTagFilterChanged()"></sd-select>

                    <mat-form-field [color]="'accent'" class="width-1" *ngIf="showListingsFilter">
                      <mat-select
                        multiple
                        placeholder="Status Type"
                        [(ngModel)]="selectedStatusFilters"
                        (openedChange)="!$event && taskStatusChanged(selectedStatusFilters)">

                        <div fxLayout="column">
                          <button class="select-button" (click)="selectAllStatus()" mat-button>Select All</button>
                          <button class="select-button" (click)="deselectAllStatus()" mat-button>Select None</button>
                        </div>

                        <mat-option *ngFor="let status of statusTypes" [value]="status" [ngStyle]="{
                              'background-color': TaskEnumHelper.getTaskStatusEnum(status).color,
                              'color': status == 'waiting_for_approval' ? 'white' : 'black'
                            }">
                          {{TaskEnumHelper.getTaskStatusEnum(status).title}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>


                  <div fxLayout="row"
                       style="width: 100%"
                       fxLayoutGap.gt-xs="10px"
                       fxLayoutAlign="space-between center"
                       fxLayout.xs="column">

                    <sd-select placeholder="Archived/Inactive Listings" *ngIf="showListingsFilter"
                               [control]="inactiveListing" [options]="inactiveListingOptions"
                               (selectionChanged)="redirectInactive()"></sd-select>

                    <mat-form-field color="accent" *ngIf="showListingsFilter" style="margin-right: 20px"
                                    ngClass.xs="width-2" ngClass.gt-xs="width-5" fxHide.xs="true">

                      <mat-select
                        multiple
                        *ngIf="showListingsFilter"
                        placeholder="Select Listing"
                        [(ngModel)]="selectedListingIds"
                        floatPlaceholder="never"
                        (openedChange)="!$event && onMatSelectClose(); !$event && taskListingsChanged(selectedListingIds)"
                        [ngModelOptions]="{standalone: true}">
                        <mat-select-trigger>
                        <span *ngIf="selectedListingIds.length === allListingIds.length">
                          All Listings
                        </span>
                          <span *ngIf="selectedListingIds.length === 0">
                          No Listing Selected
                        </span>
                          {{
                          selectedListingIds.length > 0 && selectedListingIds.length !== allListingIds.length ? getListingTitleFromId(selectedListingIds[0]) : ""
                          }}
                          <span class="extra-text"
                                *ngIf="selectedListingIds.length > 1 && selectedListingIds.length !== allListingIds.length">
                          (+{{selectedListingIds.length - 1}} others)
                        </span>
                        </mat-select-trigger>

                        <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                          <input matInput placeholder="Search Listing" [formControl]="listingFilter">
                        </mat-form-field>

                        <div fxLayout="column">
                          <button class="select-button" mat-button
                                  (click)="onSelectAll();taskListingsChanged(selectedListingIds)">Select All
                          </button>
                          <button class="select-button" mat-button
                                  (click)="onSelectNone();taskListingsChanged(selectedListingIds)">Select None
                          </button>
                        </div>

                        <mat-option *ngFor="let listingId of filteredListings" [value]="listingId">
                          {{ getListingTitleFromId(listingId) }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <sd-select class="width-1" *ngIf="showListingsFilter" placeholder="select tag"
                               [control]="tagFilterControl" [options]="tagsOptions" [multiple]="true"
                               (selectionChanged)="propertyTagFilterChanged()" fxHide.xs="true"></sd-select>

                    <mat-form-field [color]="'accent'" class="width-5" fxHide.xs="true">
                      <mat-select
                        multiple
                        placeholder="Status Type"
                        [(ngModel)]="selectedStatusFilters"
                        (openedChange)="!$event && taskStatusChanged(selectedStatusFilters)">

                        <div fxLayout="column">
                          <button class="select-button" (click)="selectAllStatus()" mat-button>Show All</button>
                          <button class="select-button" (click)="deselectAllStatus()" mat-button>Show None</button>
                        </div>

                        <mat-option *ngFor="let status of statusTypes" [value]="status" [ngStyle]="{
                              'background-color': TaskEnumHelper.getTaskStatusEnum(status).color,
                              'color': status == 'waiting_for_approval' ? 'white' : 'black'
                            }">
                          {{TaskEnumHelper.getTaskStatusEnum(status).title}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field [color]="'accent'" class="width-1" *ngIf="!showTaskAndReport">
                      <mat-select
                        placeholder="Task Type"
                        [(ngModel)]="selectedTypeFilter"
                        (ngModelChange)="taskTypeChanged(selectedTypeFilter)">
                        <mat-option *ngFor="let typeRef of types" [value]="typeRef">
                          {{ TaskEnumHelper.getTaskTypeTitle(typeRef) }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field [color]="'accent'" class="width-1" *ngIf="!showTaskAndReport" fxHide.xs="true">
                      <mat-select
                        multiple
                        placeholder="Category"
                        [(ngModel)]="selectedCategoryFilters"
                        (openedChange)="!$event && taskCategoryChanged(selectedCategoryFilters)">
                        <div fxLayout="column">
                          <button class="select-button" (click)="selectAllCategory()" mat-button>Show All</button>
                          <button class="select-button" (click)="deselectAllCategory()" mat-button>Show None</button>
                        </div>
                        <mat-option *ngFor="let category of categories" [value]="category">
                          {{ TaskEnumHelper.getTaskCategoryTitle(category) }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>


                    <button mat-raised-button style="transform: translateY(-13px);" [matMenuTriggerFor]="actionMenu"
                            *ngIf="(!isUserVendor || isUserAdmin) && showTaskAndReport" color="accent" class="position">
                      Action
                      <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #actionMenu="matMenu">
                      <button *ngIf="!isUserVendor" color="primary" mat-menu-item
                              [disabled]="!startDate || !endDate" (click)="download()">
                        DOWNLOAD REPORT
                      </button>
                      <button mat-menu-item color="primary" *ngIf="!isUserVendor" (click)="createNewTask()">
                        CREATE NEW TASK
                      </button>
                      <button mat-menu-item *ngIf="isUserAdmin && !isDownloading" color="primary" (click)="downloadReport()">
                        DOWNLOAD SCHEDULE INSPECTION REPORT
                      </button>
                      <mat-spinner *ngIf="isDownloading" [diameter]="30" [strokeWidth]="3" color="accent"></mat-spinner>
                    </mat-menu>
                  </div>

                  <div fxLayout="row" style="width: 100%" fxLayoutGap="15px" fxLayout.lt-sm="column"
                       fxLayoutAlign.lt-sm="center center"
                       fxLayoutAlign="start center">
                    <mat-form-field [color]="'accent'" class="width-1" *ngIf="showTaskAndReport">
                      <mat-select
                        placeholder="Task Type"
                        [(ngModel)]="selectedTypeFilter"
                        (ngModelChange)="taskTypeChanged(selectedTypeFilter)">
                        <mat-option *ngFor="let typeRef of types" [value]="typeRef">
                          {{ TaskEnumHelper.getTaskTypeTitle(typeRef) }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field [color]="'accent'" class="width-1" *ngIf="showTaskAndReport">
                      <mat-select
                        multiple
                        placeholder="Category"
                        [(ngModel)]="selectedCategoryFilters"
                        (openedChange)="!$event && taskCategoryChanged(selectedCategoryFilters)">
                        <div fxLayout="column">
                          <button class="select-button" (click)="selectAllCategory()" mat-button>Show All</button>
                          <button class="select-button" (click)="deselectAllCategory()" mat-button>Show None</button>
                        </div>
                        <mat-option *ngFor="let category of categories" [value]="category">
                          {{ TaskEnumHelper.getTaskCategoryTitle(category) }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field color="accent" class="width-1">
                      <mat-select
                        multiple
                        placeholder="Assignee"
                        floatPlaceholder="never"
                        [(ngModel)]="selectedAssigneeIds"
                        (openedChange)="!$event && onMatSelectClose();!$event && taskAssigneeChanged(selectedAssigneeIds)">
                        <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                          <input matInput placeholder="Search Assignee" [formControl]="assigneeFilter">
                        </mat-form-field>
                        <div fxLayout="column">
                          <button class="select-button" mat-button
                                  (click)="selectAllAssignees()">Select All
                          </button>
                          <button class="select-button" mat-button
                                  (click)="deselectAllAssignees()">Select None
                          </button>
                        </div>
                        <mat-option *ngFor="let assignee of filteredAssignees" [value]="assignee.id">
                          {{assignee.first_name}} {{assignee.last_name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field color="accent" class="width-1">
                      <mat-select
                        multiple
                        placeholder="Created By:"
                        floatPlaceholder="never"
                        [(ngModel)]="selectedCreatorIds"
                        (openedChange)="!$event && taskCreatedByChanged(selectedCreatorIds)">
                        <div fxLayout="column">
                          <button class="select-button" (click)="selectAllCreatedBy()" mat-button>Show All</button>
                          <button class="select-button" (click)="deselectAllCreatedBy()" mat-button>Show None</button>
                        </div>
                        <mat-option *ngFor="let admin of allAdmins" [value]="admin.id">
                          {{admin.first_name}} {{admin.last_name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field [color]="'accent'" class="width-1">
                      <mat-select
                        multiple
                        placeholder="Who Will Pay?*"
                        floatPlaceholder="never"
                        [(ngModel)]="selectedPaymentBy"
                        (openedChange)="!$event && whoWillPayChanged(selectedPaymentBy)"
                        [ngModelOptions]="{standalone: true}">
                        <div fxLayout="column">
                          <button class="select-button" (click)="selectAllPaymentBy()" mat-button>Show All</button>
                          <button class="select-button" (click)="deselectAllPaymentBy()" mat-button>Show None</button>
                        </div>
                        <mat-option *ngFor="let paymentByOption of paymentByOptions" [value]="paymentByOption">
                          {{TaskEnumHelper.getTaskPaymentByTitle(paymentByOption)}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-checkbox *ngIf="user.is_admin || user?.managementContact?.data?.category === 'agent'" [(ngModel)]="priceFilter"
                                  matTooltip="Show only tasks with expenses"
                                  (ngModelChange)="priceFilterChanged(priceFilter)"
                                  [ngModelOptions]="{standalone: true}">
                    </mat-checkbox>

                    <button mat-icon-button (click)="clearFilters()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>

                  <div fxLayout="row" style="width: 100%" fxLayoutGap="15px" fxLayout.lt-sm="column"
                       fxLayoutAlign.lt-sm="center center"
                       fxLayoutAlign="start center">

                    <mat-form-field [color]="'accent'" dividerColor="accent" ngClass.xs="width-2"
                                    ngClass.gt-xs="width-3">
                      <input matInput [matDatepicker]="start"
                             [(ngModel)]="startDate"
                             floatPlaceholder="never"
                             (ngModelChange)="startDateChanged($event)"
                             [ngModelOptions]="{standalone: true}"
                             placeholder="Start Date">
                      <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                      <mat-datepicker #start></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field [color]="'accent'" dividerColor="accent" ngClass.xs="width-2"
                                    ngClass.gt-xs="width-3">

                      <input matInput [matDatepicker]="end"
                             [min]="minDate"
                             [(ngModel)]="endDate"
                             (ngModelChange)="endDateChanged($event)"
                             [ngModelOptions]="{standalone: true}"
                             placeholder="End Date">
                      <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                      <mat-datepicker #end></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field [color]="'accent'" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                      <mat-select
                        placeholder="Offset"
                        [disabled]="!startDate && !endDate"
                        (ngModelChange)="offsetChanged($event)"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="offset">
                        <mat-option value="due_date">Due Date
                        </mat-option>
                        <mat-option value="paid_on">Paid On
                        </mat-option>
                        <mat-option value="created_at">Created On
                        </mat-option>
                        <mat-option value="completed_on">
                          Completed On
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-checkbox *ngIf="user.is_admin || user?.managementContact?.data?.category === 'agent'"
                                  [formControl]="openProperty" (ngModelChange)="openPropertyChanged()">
                      Show Open Property Tasks
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div fxLayout="column" fxLayoutAlign="center start" fxLayoutAlign.lt-sm="center center" fxLayoutGap="10px"
               *ngIf="(isUserVendor && isHomeOwner)"
               fxFlex="100%">

            <button fxFlexAlign="end"
                    mat-raised-button
                    *ngIf="showListingsFilter"
                    color="accent"
                    (click)="createNewTask()">
              CREATE NEW TASK
            </button>

            <div fxLayout="row" style="width: 100%" fxLayout.lt-sm="column" fxLayoutAlign="space-between start"
                 fxLayoutAlign.lt-sm="space-between center">

              <div style="width: 100%" fxLayout="row" fxLayoutAlign="space-between center">


                <div fxFlex="100%" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="space-between center"
                     fxLayoutAlign.lt-sm="space-between center">

                  <mat-form-field color="accent" *ngIf="showListingsFilter" style="margin-right: 20px"
                                  [ngStyle]="{'width': showListingsFilter ? '33%': '50%'}">

                    <mat-select
                      multiple
                      *ngIf="showListingsFilter"
                      placeholder="Select Listing"
                      [(ngModel)]="selectedListingIds"
                      floatPlaceholder="never"
                      (openedChange)="!$event && onMatSelectClose();!$event && taskListingsChanged(selectedListingIds)"
                      [ngModelOptions]="{standalone: true}">
                      <mat-select-trigger>
                        <span *ngIf="selectedListingIds.length === allListingIds.length">
                          All Listings
                        </span>
                        <span *ngIf="selectedListingIds.length === 0">
                          No Listing Selected
                        </span>
                        {{
                        selectedListingIds.length > 0 && selectedListingIds.length !== allListingIds.length ? getListingTitleFromId(selectedListingIds[0]) : ""
                        }}
                        <span class="extra-text"
                              *ngIf="selectedListingIds.length > 1 && selectedListingIds.length !== allListingIds.length">
                          (+{{selectedListingIds.length - 1}} others)
                        </span>
                      </mat-select-trigger>

                      <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                        <input matInput placeholder="Search Listing" [formControl]="listingFilter">
                      </mat-form-field>

                      <div fxLayout="column">
                        <button class="select-button" mat-button
                                (click)="onSelectAll();taskListingsChanged(selectedListingIds)">Select All
                        </button>
                        <button class="select-button" mat-button
                                (click)="onSelectNone();taskListingsChanged(selectedListingIds)">Select None
                        </button>
                      </div>

                      <mat-option *ngFor="let listingId of filteredListings" [value]="listingId">
                        {{ getListingTitleFromId(listingId) }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <sd-select class="width-1" *ngIf="showListingsFilter" placeholder="select tag"
                             [control]="tagFilterControl" [options]="tagsOptions" [multiple]="true"
                             (selectionChanged)="propertyTagFilterChanged()"></sd-select>

                  <mat-form-field color="accent" class="width-6"
                                  [ngStyle]="{'width': showListingsFilter ? '33%': '50%'}">
                    <mat-select
                      [(ngModel)]="selectedTypeFilter"
                      (ngModelChange)="taskTypeChanged(selectedTypeFilter)">
                      <mat-option *ngFor="let typeRef of types" [value]="typeRef">
                        {{ TaskEnumHelper.getTaskTypeTitle(typeRef) }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field [color]="'accent'" class="width-6"
                                  [ngStyle]="{'width': showListingsFilter ? '33%': '50%'}">
                    <mat-select
                      multiple
                      placeholder="Category"
                      [(ngModel)]="selectedCategoryFilters"
                      (openedChange)="!$event && taskCategoryChanged(selectedCategoryFilters)">
                      <div fxLayout="column">
                        <button class="select-button" (click)="selectAllCategory()" mat-button>Show All</button>
                        <button class="select-button" (click)="deselectAllCategory()" mat-button>Show None</button>
                      </div>
                      <mat-option *ngFor="let category of homeOwnerCategories" [value]="category">
                        {{ TaskEnumHelper.getTaskCategoryTitle(category) }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
              </div>
            </div>

            <div fxLayout="row" style="width: 100%" fxLayoutGap="15px" fxLayout.lt-sm="column"
                 fxLayoutAlign.lt-sm="center center"
                 fxLayoutAlign="start center">

              <mat-form-field [color]="'accent'" dividerColor="accent" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                <input matInput [matDatepicker]="start"
                       [(ngModel)]="startDate"
                       floatPlaceholder="never"
                       (ngModelChange)="startDateChanged($event)"
                       [ngModelOptions]="{standalone: true}"
                       placeholder="Start Date">
                <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                <mat-datepicker #start></mat-datepicker>
              </mat-form-field>

              <mat-form-field [color]="'accent'" dividerColor="accent" ngClass.xs="width-2" ngClass.gt-xs="width-3">

                <input matInput [matDatepicker]="end"
                       [min]="minDate"
                       [(ngModel)]="endDate"
                       (ngModelChange)="endDateChanged($event)"
                       [ngModelOptions]="{standalone: true}"
                       placeholder="End Date">
                <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                <mat-datepicker #end></mat-datepicker>
              </mat-form-field>

              <mat-form-field [color]="'accent'" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                <mat-select
                  placeholder="Offset"
                  [disabled]="!startDate && !endDate"
                  (ngModelChange)="offsetChanged($event)"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="offset">
                  <mat-option value="due_date">Due Date
                  </mat-option>
                  <mat-option value="paid_on">Paid On
                  </mat-option>
                  <mat-option value="created_at">Created On
                  </mat-option>
                  <mat-option value="completed_on">
                    Completed On
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>

          </div>


          <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutGap="15px" fxLayout.lt-sm="column"
                 fxLayoutAlign.lt-sm="center center"
                 fxLayoutAlign="start center"
                 *ngIf="isUserVendor && !isHomeOwner && !selectedTaskIds.length"
            >

              <mat-form-field ngClass.xs="width-2" ngClass.gt-xs="width-4" [color]="'accent'" style="width: 100%;">
                <mat-select
                  placeholder="Task Type"
                  [(ngModel)]="selectedTypeFilter"
                  (ngModelChange)="taskTypeChanged(selectedTypeFilter)">
                  <mat-option *ngFor="let typeRef of types" [value]="typeRef">
                    {{ TaskEnumHelper.getTaskTypeTitle(typeRef) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field [color]="'accent'" class="width-1" *ngIf="isVendorTask">
                <mat-select
                  multiple
                  placeholder="Status Type"
                  [(ngModel)]="selectedStatusFilters"
                  (openedChange)="!$event && taskStatusChanged(selectedStatusFilters)">

                  <div fxLayout="column">
                    <button class="select-button" (click)="selectAllStatus()" mat-button>Select All</button>
                    <button class="select-button" (click)="deselectAllStatus()" mat-button>Select None</button>
                  </div>

                  <mat-option *ngFor="let status of statusTypes" [value]="status" [ngStyle]="{
                              'background-color': TaskEnumHelper.getTaskStatusEnum(status).color,
                              'color': status == 'waiting_for_approval' ? 'white' : 'black'
                            }">
                    {{TaskEnumHelper.getTaskStatusEnum(status).title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field [color]="'accent'" class="width-1">
                <mat-select
                  multiple
                  placeholder="Category"
                  [(ngModel)]="selectedCategoryFilters"
                  (openedChange)="!$event && taskCategoryChanged(selectedCategoryFilters)">
                  <div fxLayout="column">
                    <button class="select-button" (click)="selectAllCategory()" mat-button>Show All</button>
                    <button class="select-button" (click)="deselectAllCategory()" mat-button>Show None</button>
                  </div>
                  <mat-option *ngFor="let category of categories" [value]="category">
                    {{ TaskEnumHelper.getTaskCategoryTitle(category) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <ng-container *ngIf="!isEmployee">
                <mat-form-field [color]="'accent'" dividerColor="accent" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                  <input matInput [matDatepicker]="start"
                         [(ngModel)]="startDate"
                         floatPlaceholder="never"
                         (ngModelChange)="startDateChanged($event)"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="Start Date">
                  <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                  <mat-datepicker #start></mat-datepicker>
                </mat-form-field>

                <mat-form-field [color]="'accent'" dividerColor="accent" ngClass.xs="width-2" ngClass.gt-xs="width-3">

                  <input matInput [matDatepicker]="end"
                         [min]="minDate"
                         [(ngModel)]="endDate"
                         (ngModelChange)="endDateChanged($event)"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="End Date">
                  <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                  <mat-datepicker #end></mat-datepicker>
                </mat-form-field>

                <mat-form-field [color]="'accent'" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                  <mat-select
                    placeholder="Offset"
                    [disabled]="!startDate && !endDate"
                    (ngModelChange)="offsetChanged($event)"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="offset">
                    <mat-option value="due_date">Due Date
                    </mat-option>
                    <mat-option value="paid_on">Paid On
                    </mat-option>
                    <mat-option value="created_at">Created On
                    </mat-option>
                    <mat-option value="completed_on">
                      Completed On
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <button *ngIf="isVendorTask"
                      mat-raised-button
                      color="accent"
                      style="width: 100%"
                      (click)="createNewTask()">
                CREATE TASK
              </button>

              <!--<button mat-raised-button class="position"-->
              <!--color="accent" (click)="generateTaskReport()"-->
              <!--&gt;-->
              <!--GENERATE REPORT-->
              <!--</button>-->

            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayout.lt-sm="column"
                 fxLayoutAlign.lt-sm="center center"
                 fxLayoutAlign="start center" style="width: 100%"
                 *ngIf="isHouseKeeper && !isEmployee && !selectedTaskIds.length"
            >
              <mat-form-field color="accent" *ngIf="showListingsFilter" style="margin-right: 20px">
                <mat-select
                  multiple
                  placeholder="Select Listing"
                  [(ngModel)]="selectedListingIds"
                  floatPlaceholder="never"
                  (openedChange)="!$event && onMatSelectClose();!$event && taskListingsChanged(selectedListingIds)"
                  [ngModelOptions]="{standalone: true}">
                  <mat-select-trigger>
                        <span *ngIf="selectedListingIds.length === allListingIds.length">
                          All Listings
                        </span>
                    <span *ngIf="selectedListingIds.length === 0">
                          No Listing Selected
                        </span>
                    {{
                    selectedListingIds.length > 0 && selectedListingIds.length !== allListingIds.length ? getListingTitleFromId(selectedListingIds[0]) : ""
                    }}
                    <span class="extra-text"
                          *ngIf="selectedListingIds.length > 1 && selectedListingIds.length !== allListingIds.length">
                          (+{{selectedListingIds.length - 1}} others)
                        </span>
                  </mat-select-trigger>

                  <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                    <input matInput placeholder="Search Listing" [formControl]="listingFilter">
                  </mat-form-field>

                  <div fxLayout="column">
                    <button class="select-button" mat-button
                            (click)="onSelectAll();taskListingsChanged(selectedListingIds)">Select All
                    </button>
                    <button class="select-button" mat-button
                            (click)="onSelectNone();taskListingsChanged(selectedListingIds)">Select None
                    </button>
                  </div>

                  <mat-option *ngFor="let listingId of filteredListings" [value]="listingId">
                    {{ getListingTitleFromId(listingId) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field color="accent">
                <mat-select
                  multiple
                  placeholder="Employee"
                  floatPlaceholder="never"
                  [(ngModel)]="selectedEmployeeIds"
                  (openedChange)="!$event && onMatSelectClose();!$event && taskEmployeeChanged(selectedEmployeeIds)">
                  <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                    <input matInput placeholder="Search Employee" [formControl]="assigneeFilter">
                  </mat-form-field>
                  <div fxLayout="column">
                    <button class="select-button" mat-button
                            (click)="selectAllEmployees()">Select All
                    </button>
                    <button class="select-button" mat-button
                            (click)="deselectAllEmployees()">Select None
                    </button>
                  </div>
                  <mat-option *ngFor="let assignee of filteredAssignees" [value]="assignee.id">
                    {{assignee.first_name}} {{assignee.last_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

        </div>
        <hr *ngIf="!isOnboarding" id="line">
        <div style="overflow-x: scroll;">
          <!--<div fxLayout="column" fxLayoutGap="5px" [ngStyle.lt-sm]="{'padding-right': '30px'}"-->
          <!--fxLayoutAlign.lt-sm="center center">-->
          <!--<p class="pHeading responsiveAlign">You have {{pendingTasks}} Pending tasks, and <span style="color: red">{{pendingLateTasks}}</span>-->
          <!--is Late.Get to-->
          <!--it.</p>-->
          <!--</div>-->

          <sd-tasks-list-component style="width: 100%; min-width: 800px; font-family:'Lato', sans-serif; !important"
                                   (sortByUpdate)="sortByChanged($event)"
                                   (sortOrderUpdate)="sortOrderChanged($event)"
                                   [tasks]="allTasks"
                                   [isOnboarding]="isOnboarding"
                                   [showExpenses]="showHouseKeeperExpenses"
                                   [listingIds]="selectedListingIds">
          </sd-tasks-list-component>

          <br>

          <div fxLayout="row" *ngIf="allTasks.length > 0" fxLayoutAlign="end center" style="min-width: 800px;">

            <div fxFlex fxLayoutAlign="start start">
              <mat-form-field [color]="'accent'">
                <mat-select
                  placeholder="Items per page"
                  [(ngModel)]="perPage"
                  fxFlex="100%"
                  (ngModelChange)="taskPerPageChanged($event)"
                  [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let option of perPageOptions" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <span style="font-size:xx-small; font-weight: bolder;">
              {{ ((currentPage - 1) * page) + 1}} -
              {{ (totalTasks > currentPage * page) ? currentPage * page : totalTasks }} of
              {{ totalTasks }}
            </span>
            <button mat-button [disabled]="isPrevDisabled" (click)="onPrev()">
              <mat-icon>navigate_before</mat-icon>
              Prev
            </button>
            <button mat-button [disabled]="isNextDisabled" (click)="onNext()">Next
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`

    /deep/ .mat-chip:not(.mat-basic-chip) {
      padding: 8px 4px 8px 4px !important;
      font-size: 13px !important;
    }

    #line {
      border: none;
      width: 100%;
      height: 5px;
      /* Set the hr color */
      margin-top: 5px;
      margin-bottom: 10px;
      color: lightgrey; /* old IE */
      background-color: lightgrey; /* Modern Browsers */
    }

    .size {
      height: 25px;
      line-height: 25px;
      width: 30px;
    }

    #spinner {
      position: fixed;
      top: 45%;
      right: 40%
    }

    .select-button {
      padding: 6px;
      text-align: left;
      font-size: 17px;
      padding-left: 10px;
      font-weight: bolder;
    }

    mat-chip {
      width: 150px;
      text-align: center;
    }

    hr {
      width: 100%;
    }

    #spinner {
      position: fixed;
      top: 45%;
      right: 40%
    }

    #spinner {
      position: fixed;
      top: 45%;
      right: 40%
    }

    :host /deep/ router-outlet + *:not(nav) {
      width: 100%;
    }

    b {
      font-size: 18px;
    }

    .width-1 {
      width: 28%;
    }

    .width-2 {
      width: 100%;
    }

    .width-3 {
      width: 85%;
    }

    .width-4 {
      width: 50%;
    }

    .width-5 {
      width: 30%;
    }

    .width-6 {
      width: 33%;
    }

    .extra-text {
      opacity: 0.75;
      font-size: 0.75em;
    }

    @media only screen and (max-width: 700px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 90%;
      }

      .position {
        width: 90% !important;
      }

      .responsivewidth {
        width: 80%;
      }

      .responsiveAlign {
        text-align: center;
      }
    }
  `]
})
export class OwnerTasksPageComponent implements OnInit, AfterViewInit, OnDestroy {

  // Dummy Models
  selectedTypeFilter: TaskType;
  selectedStatusFilters: string[] = [];
  // selectedCategoryFilter: TaskCategory;
  selectedCategoryFilters: string[] = [];
  selectedAssigneeIds: number[] = [];
  selectedEmployeeIds: number[] = [];
  selectedPaymentBy = [];
  selectedCreatorIds: number[] = [];
  selectedListingIds: number[] = [];
  inactiveListing = new FormControl(null);
  inactiveListingOptions: {title: string, value: any}[] = [];
  perPage: PerPage;
  page: number;
  allAdmins: UserCompact[];
  priceFilter = false;
  user: UserFull;
  paymentByOptions = CommonUtil.getPropsOfEnum<TaskPaymentBy>(TaskPaymentBy);

  // Enum
  homeOwnerCategories = CommonUtil.getPropsOfEnum<HomeOwnerTaskCategory>(HomeOwnerTaskCategory);
  categories = CommonUtil.getPropsOfEnum<TaskCategory>(TaskCategory);
  types = CommonUtil.getPropsOfEnum<TaskType>(TaskType);
  perPageOptions = CommonUtil.getPropsOfEnum<PerPage>(PerPage);
  statusTypes = CommonUtil.getPropsOfEnum<TaskStatus>(TaskStatus);

  // Bulk Update Vars
  bulkListingId: FormControl;
  bulkTaskStatus: FormControl;
  bulkAssigneeId: FormControl;
  bulkEmployeeId: FormControl;
  bulkDueDate: FormControl;
  bulkCategory: FormControl;
  bulkTaskTitle: FormControl;
  bulkAssigneeType: FormControl;

  tagFilter = [];
  tagFilterControl: FormControl = new FormControl(this.tagFilter);
  tags = [];
  tagsOptions: {title: string, value: any}[] = [];

  // Helpers
  TaskEnumHelper = TaskEnumHelper;
  UserModelUtil = UserModelUtil;
  CommonUtil = CommonUtil;
  TaskStatus = TaskStatus;
  UserOtherRole = UserOtherRole;
  taskStatuses: TaskStatus[] = CommonUtil.getPropsOfEnum<TaskStatus>(TaskStatus);

  tagsLoading = false;
  tagsLoaded = false;

  @Input() showListingsFilter = false;
  @Input() selectedListingId: number;
  @Input() listingId: number = null;
  @Input() isOnboarding = false;

  allTasks: TaskCompact[];

  isLoading: boolean;
  isLoaded: boolean;
  isDownloading = false;
  isAssigneeLoading: boolean;
  isAssigneeLoaded: boolean;

  sortOrder: any;
  sortBy: any;
  startDate: Date;
  endDate: Date;
  offset = "due_date";
  minDate: Date;

  allListingIds: number[] = [];
  assignees: UserNameOnly[] = [];
  filteredAssignees: UserNameOnly[] = [];
  assigneeTypes: {title: string, value: any}[];

  currentPage: number;
  totalPages: number;
  totalTasks: number;

  pendingTasks = 0;
  pendingLateTasks = 0;
  employeeId: number;
  allListings: ListingCompact[] = [];

  isPrevDisabled = true;
  isNextDisabled = false;
  selectedTaskIds: number[];
  selectedTaskStatus: string[];
  isUserVendor = false;
  isHomeOwner = false;
  isHouseKeeper = false;
  isVendorTask = false;
  showHouseKeeperExpenses = true;
  isEmployee = false;
  forUpdate = false;
  isUserAdmin = false;
  filteredListings: number[] = [];
  listingFilter: FormControl = new FormControl();
  assigneeFilter: FormControl = new FormControl();
  openProperty: FormControl = new FormControl(false);
  showTaskAndReport = true;
  private destroyed$ = new Subject();
  private dialogRef: MatDialogRef<any>;

  constructor(private stayDuvetService: StayDuvetService,
              private dialog: MatDialog,
              private listingRepo: ListingRepository,
              private taskRepo: TaskRepository,
              private userRepo: UserRepository,
              private optionsRepo: OptionsRepository,
              private activatedRoute: ActivatedRoute,
              private router: Router) {

    if (this.selectedListingId) {
      this.selectedListingIds = [this.selectedListingId];
    }

    this.listingRepo.getAllActivatedListingIds().subscribe((listingIds) => {
      this.allListingIds = [...this.allListingIds, ...listingIds];
      this.filteredListings = [...this.filteredListings, ...listingIds];
      console.log("[after activated", this.filteredListings);
    });

    this.listingRepo.getAllDraftListingIds().subscribe((listingIds) => {
      this.allListingIds = [...this.allListingIds, ...listingIds];
      this.filteredListings = [...this.filteredListings, ...listingIds];
      console.log("[after draft", this.filteredListings);
    });

    this.listingRepo.getAllListings()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(l => this.allListings = l);

    this.listingRepo.getInactivatedListings().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      res.forEach(l => {
        this.inactiveListingOptions.push({title: l.title, value: l.id});
      });
    });

    this.listingFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged(),).subscribe((value) => {

      if (value) {
        this.filteredListings = this.allListings.filter(l => l.title && l.title.toLowerCase().includes(value)).map(l => l.id);
      } else {
        this.filteredListings = this.allListingIds;
      }

      if (this.filteredListings.length === 0) {
        this.filteredListings = this.allListingIds;
      }

    });

    this.assigneeFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged(),).subscribe((value) => {

      if (value) {
        this.filteredAssignees = this.assignees.filter(l => UserModelUtil.getFullName(l).toLowerCase().includes(value));
      } else {
        this.filteredAssignees = this.assignees;
      }

      if (this.filteredAssignees.length === 0) {
        this.filteredAssignees = this.assignees;
      }

    });

    this.userRepo.getUser().pipe(takeUntil(this.destroyed$))
      .subscribe((user) => {
        this.user = user;
        this.isVendorTask = user.role === UserOtherRole.CREAT_EDIT_TASK;
        this.isUserVendor = user.type === "owner" || this.CommonUtil.isVendor(user) || this.CommonUtil.isHomeOwner(user);
        this.isHomeOwner = this.CommonUtil.isHomeOwner(user);
        this.isHouseKeeper = this.CommonUtil.isHouseKeeper(user);
        this.isUserAdmin = user.is_admin || user?.managementContact?.data?.category === "agent";

        if (this.CommonUtil.isVendor(user) && (!!user.managementContact.data.creator.data.managementContact && user.managementContact.data.creator.data.managementContact.data.category === "housekeeper")) {
          this.showHouseKeeperExpenses = user.managementContact.data.creator.data.show_expenses;
          this.employeeId = user.id;
          this.isEmployee = true;
        }

        if (this.isUserAdmin && !this.isOnboarding) {
          this.selectedCategoryFilters = CommonUtil.getPropsOfEnum<TaskCategory>(TaskCategory).filter(s => s !== TaskCategory.CLEANING && s !== TaskCategory.INSPECTION);
          this.selectedStatusFilters = CommonUtil.getPropsOfEnum<TaskStatus>(TaskStatus).filter(s => s !== TaskStatus.ACCOUNTS_RECEIVABLE);
          this.taskCategoryChanged(this.selectedCategoryFilters);
          this.taskStatusChanged(this.selectedStatusFilters);
          this.sortOrderChanged(SortOrder.DSC);
          this.sortByChanged(TaskSortBy.DUE_ON);
          console.log("user inside");
        } else if (this.isOnboarding) {
          this.taskTypeChanged(TaskType.ALL);
        }
      });

    this.setupBulkUpdateForm();
    this.setupAssignees();
    this.pullValuesFromQuery();
    this.pullValuesFromStore();
    this.setupTasks();
    this.setupAdmins();
  }

  ngOnInit(): void {
    console.log("[TASK] ngOnInit() owner-tasks-page destroyed");
    console.log("PerPage", this.perPageOptions);
    console.log("onInit sd-owner-tasks", this.isUserAdmin);

    this.showTaskAndReport = isNullOrUndefined(this.listingId);

    this.assigneeTypes = CommonUtil.getPropsOfEnum(TaskAssigneeCategory)
      .map((type: any) => ({title: UserEnumHelper.getUserCategory(type), value: type}));

    if (this.selectedListingId) {
      this.taskListingsChanged([this.selectedListingId]);
      if (!ListingTaskUtil.type) {
        this.selectedTypeFilter = TaskType.UPCOMING;
        this.taskTypeChanged(TaskType.UPCOMING);
        console.log("TASK TYPE CHANGED", this.selectedTypeFilter);
      } else {
        ListingTaskUtil.type = false;
      }
    }
    this.setUpTags();
  }

  ngAfterViewInit() {
    const filterWhoWillPay$ = this.taskRepo.getTaskFilterWhoWillPay();
    const filterType$ = this.taskRepo.getTaskFilterType();
    const filterCategory$ = this.taskRepo.getTaskFilterCategory();
    const filterAssigneeId$ = this.taskRepo.getTaskFilterAssigneeIds();
    const filterEmployeeId$ = this.taskRepo.getTaskFilterEmployeeIds();
    const filterListingIds$ = this.taskRepo.getTaskFilterListingIds();
    const createdBy$ = this.taskRepo.getTaskFilterCreatedBy();
    const perPage$ = this.taskRepo.getTaskPerPage().pipe(map(p => {
      this.page = +p;
      return p;
    }));
    const currentPage$ = this.taskRepo.getTaskCurrentPage();
    const sortOrder$ = this.taskRepo.getTaskSortOrder();
    const sortBy$ = this.taskRepo.getTaskSortBy();
    const taskStatus$ = this.taskRepo.getTaskFilterStatus();
    const filterPrice$ = this.taskRepo.getTaskFilterPrice();
    const openProperty$ = this.taskRepo.getTaskOpenProperty();
    const startDate$ = this.taskRepo.getTaskStartDate();
    const endDate$ = this.taskRepo.getTaskEndDate();
    const offset$ = this.taskRepo.getTaskOffset();

    sortOrder$.subscribe(value => {
      this.sortOrder = value;
    });

    sortBy$.subscribe(value => {
      this.sortBy = value;
    });

    const filterChanged$ = observableCombineLatest(
      filterType$,
      filterCategory$,
      filterAssigneeId$,
      filterEmployeeId$,
      filterListingIds$,
      perPage$,
      currentPage$,
      createdBy$,
      filterWhoWillPay$,
      sortOrder$,
      sortBy$,
      taskStatus$,
      filterPrice$,
      openProperty$,
      startDate$,
      endDate$,
      offset$,
      (filterType, filterCategory, filterAssignee, filterEmployee, filterListingsIds, perPage, currentPage, createdBy, whoWillPay, sortOrder, sortBy, taskStatus, filterPrice, openProperty, starDate, endDate) => {
        return {
          filterType: filterType,
          filterCategory: filterCategory,
          filterAssignee: filterAssignee,
          filterEmployee: filterEmployee,
          filterListingsIds: filterListingsIds,
          perPage: perPage,
          currentPage: currentPage,
          createdBy: createdBy,
          sortOrder: sortOrder,
          sortBy: sortBy,
          paymentBy: whoWillPay,
          status: taskStatus,
          priceFilter: filterPrice,
          openProperty: openProperty,
          startDate: starDate,
          endDate: endDate,
        };
      }
    );

    // Skipping the first load
    // Since we want to listen to filter changes only
    filterChanged$.pipe(debounceTime(100), takeUntil(this.destroyed$),).subscribe(
      (data) => {
        this.refreshRoute();
        this.taskRepo.selectTaskPageNumber(this.currentPage, false, this.employeeId);
      }
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Button Click Actions
   */
  onSelectAll() {
    this.selectedListingIds = this.allListingIds;
  }

  onSelectNone() {
    this.selectedListingIds = [];
  }

  selectAllPaymentBy() {
    this.selectedPaymentBy = [];
    Object.keys(this.paymentByOptions).forEach(key => {
      this.selectedPaymentBy.push(this.paymentByOptions[key]);
    });
    // this.whoWillPayChanged(this.selectedPaymentBy);
  }

  deselectAllPaymentBy() {
    this.selectedPaymentBy = [];
    // this.whoWillPayChanged(this.selectedPaymentBy);
  }

  selectAllCreatedBy() {
    this.selectedCreatorIds = [];
    this.allAdmins.forEach(admin => {
      this.selectedCreatorIds.push(admin.id);
    });
    // this.taskCreatedByChanged(this.selectedCreatorIds);
  }

  deselectAllCreatedBy() {
    this.selectedCreatorIds = [];
    // this.taskCreatedByChanged(this.selectedCreatorIds);
  }

  selectAllAssignees() {
    this.selectedAssigneeIds = [];
    this.assignees.forEach(assginee => {
      this.selectedAssigneeIds.push(assginee.id);
    });
    // this.taskAssigneeChanged(this.selectedAssigneeIds);
  }

  deselectAllAssignees() {
    this.selectedAssigneeIds = [];
    // this.taskAssigneeChanged(this.selectedAssigneeIds);
  }

  selectAllEmployees() {
    this.selectedEmployeeIds = [];
    this.assignees.forEach(assginee => {
      this.selectedEmployeeIds.push(assginee.id);
    });
  }

  deselectAllEmployees() {
    this.selectedEmployeeIds = [];
  }

  selectAllCategory() {
    this.selectedCategoryFilters = [];
    Object.keys(TaskCategory).forEach(key => {
      this.selectedCategoryFilters.push(TaskCategory[key]);
    });
    // this.taskCategoryChanged(this.selectedCategoryFilters);
  }

  deselectAllCategory() {
    this.selectedCategoryFilters = [];
    // this.taskCategoryChanged(this.selectedCategoryFilters);
  }

  selectAllStatus() {
    this.selectedStatusFilters = [];
    Object.keys(TaskStatus).forEach(key => {
      this.selectedStatusFilters.push(TaskStatus[key]);
    });
    // this.taskStatusChanged(this.selectedStatusFilters);
  }

  deselectAllStatus() {
    this.selectedStatusFilters = [];
    // this.taskStatusChanged(this.selectedStatusFilters);
  }

  redirectInactive() {
    this.router.navigate(["/listings/" + this.inactiveListing.value + "/calendar"]);
  }

  clearFilters() {
    this.selectedTypeFilter = TaskType.UPCOMING;
    this.currentPage = 1;
    this.selectedPaymentBy = [];
    this.selectedCreatorIds = [];
    this.priceFilter = false;
    this.selectedAssigneeIds = [];
    this.selectedEmployeeIds = [];
    this.selectedListingIds = [];
    this.selectedCategoryFilters = [];
    this.selectedStatusFilters = [];
    this.startDate = null;
    this.endDate = moment().add(30, "days").toDate();
    this.offset = null;
    this.openProperty.setValue(false);
    this.openPropertyChanged();
    this.taskAssigneeChanged([]);
    this.taskCategoryChanged([]);
    this.taskCreatedByChanged([]);
    this.whoWillPayChanged([]);
    this.taskListingsChanged([]);
    this.taskStatusChanged([]);
    this.priceFilterChanged(false);
    this.taskRepo.resetTasks();
  }

  createNewTask() {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      this.router.navigate(["/tasks/new", {listing_id: id}]);
    } else {
      this.router.navigate(["/tasks/new"]);
    }
  }

  downloadReport() {
    this.isDownloading = true;
    this.taskRepo.downloadInspectionReport().subscribe(res => this.isDownloading = false,
      err => this.isDownloading = false);
  }

  /**
   * Pagination Related
   */
  onPrev() {
    if (this.currentPage <= 1) {
      this.currentPage = 1;
      return;
    }
    this.currentPage -= 1;
    this.taskCurrentPageChanged(this.currentPage);
  }

  onNext() {
    if (this.currentPage >= this.totalPages) {
      this.currentPage = this.totalPages;
      return;
    }
    this.currentPage += 1;
    this.taskCurrentPageChanged(this.currentPage);
  }

  /**
   * (change) listeners
   */
  taskTypeChanged(type: TaskType) {
    this.currentPage = 1;
    this.taskRepo.setTaskFilterType(type);

    if (this.selectedTypeFilter === TaskType.UPCOMING) {
      this.endDate = moment().add(30, "days").toDate();
      this.endDateChanged(this.endDate);
    }

  }

  taskStatusChanged(status: string[]) {
    this.currentPage = 1;
    this.taskRepo.setTaskStatus(status);
  }

  taskCategoryChanged(category) {
    console.log("category changed", category);
    this.currentPage = 1;
    this.taskRepo.setTaskFilterCategory(category);
  }

  taskAssigneeChanged(assignees: number[]) {
    this.currentPage = 1;
    this.taskRepo.setTaskFilterAssigneeIds(assignees);
  }

  taskEmployeeChanged(employees: number[]) {
    this.currentPage = 1;
    this.taskRepo.setTaskFilterEmployeeIds(employees);
  }

  taskCreatedByChanged(createdBy) {
    this.currentPage = 1;
    this.taskRepo.setTaskFilterCreatedBy(createdBy);
  }

  whoWillPayChanged(assignee: any) {
    this.currentPage = 1;
    this.taskRepo.setTaskFilterWhoWillPay(assignee);
  }

  priceFilterChanged(priceFilter) {
    this.currentPage = 1;
    this.taskRepo.setTaskFilterPrice(priceFilter);
  }

  openPropertyChanged() {
    this.currentPage = 1;
    this.taskRepo.setTaskOpenProperty(this.openProperty.value);
  }

  startDateChanged(date) {
    this.minDate = date ? date : null;
    this.currentPage = 1;
    this.taskRepo.setTaskStartDate(date);
    if (!this.offset) {
      this.offsetChanged("due_date");
    }
  }

  endDateChanged(date) {
    this.currentPage = 1;
    this.taskRepo.setTaskEndDate(date);
    if (!this.offset) {
      this.offsetChanged("due_date");
    }
  }

  offsetChanged(offset) {
    this.currentPage = 1;
    this.taskRepo.setTaskOffset(offset);
  }

  taskListingsChanged(listingIds: number[]) {
    this.currentPage = 1;
    this.taskRepo.setTaskFilterListingIds(listingIds);
  }

  taskPerPageChanged(newValue: PerPage) {
    this.currentPage = 1;
    this.taskRepo.setTaskPerPage(newValue);
  }

  taskCurrentPageChanged(newValue: number) {
    this.taskRepo.selectTaskPageNumber(newValue, false, this.employeeId);
  }

  sortByChanged(sortBy: TaskSortBy) {
    this.currentPage = 1;
    this.taskRepo.setTaskSortBy(sortBy);
  }

  sortOrderChanged(sortOrder: SortOrder) {
    this.currentPage = 1;
    this.taskRepo.setTaskSortOrder(sortOrder);
  }

  /**
   * Helpers
   */
  getListingTitleFromId(id: number): string {
    return this.listingRepo.getListingTitle(id);
  }

  updatePaginationButtons() {
    this.isPrevDisabled = this.currentPage <= 1;
    this.isNextDisabled = this.currentPage >= this.totalPages;
  }

  refreshRoute() {
    console.log("[TASK] refreshRoute()", this.selectedListingId);
    // Preventing redirection from the listings/task tab
    if (this.selectedListingId) {
      return;
    }

    console.log("[REST] ", this.selectedCategoryFilters);

    this.router.navigate(["/tasks/"], {
      queryParams: {
        per_page: this.perPage,
        sort_order: this.sortOrder,
        sort_by: this.sortBy,
        current_page: this.currentPage,
        type: this.selectedTypeFilter,
        category: this.selectedCategoryFilters.length ? this.selectedCategoryFilters.join(",") : null,
        // sending 0 in assignee id if no assignee id is selected.
        assignee_ids: this.selectedAssigneeIds.length > 0 ? this.selectedAssigneeIds.join(",") : null,
        employee_ids: this.selectedEmployeeIds.length > 0 ? this.selectedEmployeeIds.join(",") : null,
        listing_ids: this.selectedListingIds.join(","),
        payment_by: this.selectedPaymentBy.length > 0 ? this.selectedPaymentBy.join(",") : null,
        price_filter: this.isUserVendor ? false : this.priceFilter,
        status: this.selectedStatusFilters.length > 0 ? this.selectedStatusFilters.join(",") : "",
        start_date: this.startDate ? dateToDateString(this.startDate) : null,
        end_date: this.endDate ? dateToDateString(this.endDate) : null,
        open_property: this.openProperty.value
      }
    });
  }

  clearBulkUpdateForm() {
    this.bulkListingId.setValue(null);
    this.bulkTaskStatus.setValue(null);
    this.bulkAssigneeId.setValue(null);
    this.bulkEmployeeId.setValue(null);
    this.bulkDueDate.setValue(null);
    this.bulkCategory.setValue(null);
    this.bulkTaskTitle.setValue(null);
    this.bulkAssigneeType.setValue(null);
  }

  confirmBulkUpdatePopup() {
    let data = {};
    let noOfPaidTasks = 0;
    this.selectedTaskStatus.forEach(s => {
      if (s === "paid") {
        noOfPaidTasks += 1;
      }
    });
    if (this.selectedTaskStatus.includes("paid") && !!this.bulkTaskStatus.value && this.bulkTaskStatus.value !== "paid") {
      data = {
        title: "Confirm Bulk Updating of Tasks",
        description: `Are you sure you want to Update these Tasks (Among them ${noOfPaidTasks} are paid tasks, and their payout has already completed).`,
        showCloseButton: true,
        showNoButton: true,
      };
    } else {
      data = {
        title: "Confirm Bulk Updating of Tasks",
        description: `Are You Sure You Want To Update ${this.selectedTaskIds.length} Tasks.`,
        showCloseButton: true,
        showNoButton: true,
      };
    }

    this.dialogRef = this.dialog.open(GenericConfirmationPopupComponent, { data: data });

    const instance = this.dialogRef.componentInstance;

    instance.yesButtonClicked.subscribe(() => {
      instance.isLoading = true;

      // this.selectedTaskIds.forEach(taskId => {
      //
      // })

      this.taskRepo.bulkUpdateTasks(
        this.bulkListingId.value,
        this.bulkTaskStatus.value,
        this.bulkDueDate.value,
        this.bulkCategory.value,
        this.bulkTaskTitle.value,
        this.bulkAssigneeId.value,
        this.bulkAssigneeType.value,
        this.bulkEmployeeId.value,
      ).subscribe(res => {
        instance.isLoading = false;
        this.clearBulkUpdateForm();
        this.deselectAllTasks();
        this.dialog.closeAll();
      });
    });

    this.dialogRef.updateSize("100%");
  }

  confirmBulkCompletePopup() {
    this.dialogRef = this.dialog.open(GenericConfirmationPopupComponent, {
      data: {
        title: "Confirm Bulk Updating of Tasks",
        description: `Are You Sure You Want To Update ${this.selectedTaskIds.length} Tasks.`,
        showCloseButton: true,
        showNoButton: true,
      }
    });

    const instance = this.dialogRef.componentInstance;

    instance.yesButtonClicked.subscribe(() => {
      instance.isLoading = true;

      this.taskRepo.bulkCompleteTasks().subscribe(res => {
        instance.isLoading = false;
        this.clearBulkUpdateForm();
        this.deselectAllTasks();
        this.dialog.closeAll();
      });
    });

    this.dialogRef.updateSize("100%");
  }

  deselectAllTasks() {
    this.forUpdate = false;
    this.taskRepo.deselectAllTasks();
  }

  showUpdatePanel() {
    this.forUpdate = true;
  }

  mergeTasks() {
    this.dialogRef = this.dialog.open(MergeTasksPopupComponent, {
      data: {
        selectedIds: this.selectedTaskIds,
        tasks: this.allTasks
      }
    });
    this.dialogRef.updateSize("100%", "100%");
  }

  generateTaskReport() {
    this.router.navigate(["tasks-report", {listing_id: this.listingId}]);
  }

  onMatSelectClose() {
    this.filteredListings = this.allListingIds;
    this.filteredAssignees = this.assignees;
  }

  propertyTagFilterChanged() {
    this.tagFilter = this.tagFilterControl.value;
    console.log("called selected tag changed");
    if (this.tagFilter.length !== this.tagsOptions.length) {
      const properties = this.allListings.filter(listing => {
        const tags = listing.tags.data.map(tag => tag.title);
        return this.tagFilter.some(tag => tags.includes(tag));
      });
      this.selectedListingIds = properties.map(prop => prop.id);
    } else {
      this.onSelectAll();
    }
    this.taskListingsChanged(this.selectedListingIds);
  }

  download() {
    const data = {
      start: this.startDate? dateToDateString(this.startDate): null,
      end: this.endDate? dateToDateString(this.endDate): null,
      type: this.selectedTypeFilter,
      offset: this.offset,
      category: this.selectedCategoryFilters && this.selectedCategoryFilters.length > 0 ? this.selectedCategoryFilters.join(",") : null,
      assignee_ids: this.selectedAssigneeIds && this.selectedAssigneeIds.length > 0 ? this.selectedAssigneeIds.join(",") : null,
      employee_ids: this.selectedEmployeeIds && this.selectedEmployeeIds.length > 0 ? this.selectedEmployeeIds.join(",") : null,
      listing_ids: this.selectedListingIds && this.selectedListingIds.length > 0 ? this.selectedListingIds.join(",") : null,
      status: this.selectedStatusFilters && this.selectedStatusFilters.length > 0 ? this.selectedStatusFilters.join(",") : null,
      payment_by: this.selectedPaymentBy && this.selectedPaymentBy.length > 0 ? this.selectedPaymentBy.join(",") : null,
      price_filter: this.priceFilter ? 1 : 0,
      sort_by: this.sortBy,
      sort_order: this.sortOrder,
      creator_ids: this.selectedCreatorIds && this.selectedCreatorIds.length > 0 ? this.selectedCreatorIds.join(",") : null,
      employee_id: this.employeeId,
      open_property: this.openProperty.value
    };

    this.dialogRef = this.dialog.open(DownloadReportPopupComponent);
    this.dialogRef.updateSize("60%", "20%");
    const instance = this.dialogRef.componentInstance;
    instance.data = data;
  }

  private setUpTags() {
    this.optionsRepo.getIsTagLoading().pipe(takeUntil(this.destroyed$)).subscribe((loading) => {
      this.tagsLoading = loading;
    });

    this.optionsRepo.getIsTagLoaded().pipe(takeUntil(this.destroyed$)).subscribe((loaded) => {
      this.tagsLoaded = loaded;
    });

    this.optionsRepo.getAllTags().pipe(takeUntil(this.destroyed$)).subscribe((tags) => {
      this.tags = tags;
      this.tags.map(tag => {
        this.tagFilter.push(tag.title);
        this.tagsOptions.push({title: tag.title, value: tag.title});
      });
      this.tagFilterControl.setValue(this.tagFilter);
    });
  }

  setDates() {
    if (this.allTasks.length) {
      this.endDate = moment(this.allTasks[0].fill_end_date).toDate();
      this.startDate = moment(this.allTasks[0].fill_start_date).toDate();
    }
  }

  /**
   * Private Methods called from Constructor
   */
  private setupBulkUpdateForm() {
    this.bulkListingId = new FormControl();
    this.bulkTaskStatus = new FormControl();
    this.bulkAssigneeId = new FormControl();
    this.bulkEmployeeId = new FormControl();
    this.bulkDueDate = new FormControl();
    this.bulkCategory = new FormControl();
    this.bulkTaskTitle = new FormControl();
    this.bulkAssigneeType = new FormControl();
  }

  private setupAssignees() {
    if (!this.isHouseKeeper) {
      this.optionsRepo.getTaskAssignees().pipe(takeUntil(this.destroyed$))
        .subscribe((assignees) => {
          this.assignees = assignees;
          if (this.selectedAssigneeIds.length > 0) {
            if (this.selectedAssigneeIds.length === 1 && this.selectedAssigneeIds[0] === 0) {
              this.userRepo.getUser().pipe(filter(a => !!a), take(1),).subscribe((user) => {
                this.selectedAssigneeIds = [user.id];
                this.taskAssigneeChanged([user.id]);
              });
            }

          }
          this.filteredAssignees = this.assignees;
        });
    } else {
      this.optionsRepo.getHousekeeperEmployees().pipe(takeUntil(this.destroyed$))
        .subscribe((employees) => {
          this.assignees = employees;
          if (this.selectedEmployeeIds.length > 0) {
            if (this.selectedEmployeeIds.length === 1 && this.selectedEmployeeIds[0] === 0) {
              this.userRepo.getUser().pipe(filter(a => !!a), take(1),).subscribe((user) => {
                this.selectedEmployeeIds = [user.id];
                this.taskAssigneeChanged([user.id]);
              });
            }

          }
          this.filteredAssignees = this.assignees;
        });
    }
  }

  private setupAdmins() {
    this.optionsRepo.getAdmins(true).subscribe(value => {
      this.allAdmins = value;
    });
  }

  private pullValuesFromQuery() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      if (params.per_page) {
        this.taskPerPageChanged(params.per_page);
      }

      if (params.type && CommonUtil.getIsEnum(TaskType, params.type)) {
        this.taskTypeChanged(params.type);
      }

      if (params.category) {
        const categories = params.category.split(",");
        // TODO Validate
        this.taskCategoryChanged(categories);
      }

      if (params.status) {
        const statuses = params.status.split(",");
        // TODO Validate
        this.taskStatusChanged(statuses);
      }

      if (params.sort_order && CommonUtil.getIsEnum(SortOrder, params.sort_order)) {
        this.sortOrderChanged(params.sort_order);
      }

      if (params.sort_by && CommonUtil.getIsEnum(TaskSortBy, params.sort_by)) {
        this.sortByChanged(params.sort_by);
      }

      if (!isNullOrUndefined(params.assignee_ids)) {
        const assigneeIds: number[] = params.assignee_ids.split(",").map(id => +id);
        console.log("assignee", +assigneeIds);
        if (assigneeIds.length > 0) {
          this.taskAssigneeChanged(assigneeIds);
        } else {
          this.userRepo.getUser().pipe(take(1)).subscribe((user) => {
            this.taskAssigneeChanged([user.id]);
          });
        }
      }

      if (params.listing_ids) {
        const paramListingIds = params.listing_ids.split(",").map(i => +i);
        const intersectIds = _.intersection(paramListingIds, this.allListingIds);

        if (intersectIds && intersectIds instanceof Array && intersectIds.length > 0) {
          this.taskListingsChanged(intersectIds);
        }
      }

      if (params.current_page) {
        this.taskCurrentPageChanged(+params.current_page);
      }

      if (params.payment_by) {
        const paymentBy = params.payment_by.split(",");
        this.whoWillPayChanged(paymentBy);
      }

      if (params.creator_ids) {
        const creatorIds = params.creator_ids.split(",");
        this.taskCreatedByChanged(creatorIds);
      }

      if (!isNullOrUndefined(params.price_filter)) {
        this.priceFilterChanged(params.price_fileter === "true");
      }

      if (!isNullOrUndefined(params.open_property)) {
        this.openProperty.setValue(params.open_property === "true");
        this.openPropertyChanged();
      }

      if (params.start_date && moment(params.start_date).isValid()) {
        this.startDateChanged(moment(params.start_date).toDate());
      }

      if (params.endDate && moment(params.end_date).isValid()) {
        this.endDateChanged(moment(params.end_date).toDate());
      }

      if (params.offset) {
        this.offsetChanged(params.offset);
      }

      console.log("route inside");
    });
  }

  private pullValuesFromStore() {
    // Component will only trigger these changes so no need to update.
    this.taskRepo.getTaskFilterType().pipe(take(1)).subscribe(t => this.selectedTypeFilter = t);
    this.taskRepo.getTaskFilterCategory().pipe(take(1)).subscribe(c => this.selectedCategoryFilters = c);
    this.taskRepo.getTaskFilterStatus().pipe(take(1)).subscribe(c => this.selectedStatusFilters = c);
    this.taskRepo.getTaskFilterAssigneeIds().pipe(take(1)).subscribe(a => this.selectedAssigneeIds = a);
    this.taskRepo.getTaskFilterEmployeeIds().pipe(take(1)).subscribe(e => this.selectedEmployeeIds = e);
    this.taskRepo.getTaskFilterListingIds().pipe(take(1)).subscribe(l => this.selectedListingIds = l);
    this.taskRepo.getTaskFilterWhoWillPay().pipe(takeUntil(this.destroyed$)).subscribe(l => this.selectedPaymentBy = l);
    this.taskRepo.getTaskFilterCreatedBy().pipe(takeUntil(this.destroyed$)).subscribe(l => this.selectedCreatorIds = l);
    this.taskRepo.getTaskFilterPrice().pipe(take(1)).subscribe(t => this.priceFilter = t);
    this.taskRepo.getTaskOpenProperty().pipe(take(1)).subscribe(t => this.openProperty.setValue(t));
    // These values can change outside component
    this.taskRepo.getTaskTotalPages().pipe(takeUntil(this.destroyed$)).subscribe(t => this.totalPages = t);
    this.taskRepo.getTaskTotalCount().pipe(takeUntil(this.destroyed$)).subscribe(t => this.totalTasks = t);
    this.taskRepo.getTaskPerPage().pipe(takeUntil(this.destroyed$)).subscribe(t => this.perPage = t);
    this.taskRepo.getTaskCurrentPage().pipe(take(1)).subscribe(t => this.currentPage = t);
    this.taskRepo.getTaskStartDate().pipe(take(1)).subscribe(t => this.startDate = t);
    this.taskRepo.getTaskEndDate().pipe(take(1)).subscribe(t => this.endDate = t);
    this.taskRepo.getTaskOffset().pipe(take(1)).subscribe(t => this.offset = t);

  }

  private setupTasks() {
    this.taskRepo.getAllTasksForCurrentPage().subscribe(res => {
      this.allTasks = res;
      this.setDates();
      this.updatePaginationButtons();
    });

    this.taskRepo.getIsTaskCurrentPageLoading().subscribe((loading) => this.isLoading = loading);
    this.taskRepo.getIsTaskCurrentPageLoaded().subscribe((loaded) => this.isLoaded = loaded);
    this.taskRepo.getSelectedTaskIds().subscribe(res => this.selectedTaskIds = res);
    this.taskRepo.getSelectedTaskStatus().subscribe(res => this.selectedTaskStatus = res);
  }

  onTypeChange() {
    this.bulkAssigneeId.setValue(null);
  }

  onAssigneeChange() {
    this.bulkAssigneeType.setValue(null);
  }
}
