import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "sd-error-popup",
  template: `
    <sd-modal-popup-layout [title]="title">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-icon style="color: red; font-size: 36px!important;">error_outline</mat-icon>
        <span style="margin-top: 20px">{{message}}</span>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`
  `]
})

export class ErrorPopupComponent implements OnInit {

  @Input() title = "";
  @Input() message = "";

  constructor() {
  }

  ngOnInit() {
  }
}
