import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";
import { __HTTPResponseType } from "../../enums/common.enum";
import { PrivateNotesDraft } from "../../models/new/drafts/private-notes-draft.model";
import { PrivateNotesDraftsGetResponse } from "../../models/responses/settings/drafts/private-notes-draft-get.response";
import { PrivateNotesDraftsShowResponse } from "../../models/responses/settings/drafts/private-notes-draft-show.response";

@Injectable()
export class PrivateNotesDraftsService {

  constructor(private apiService: ApiService) {
  }

  getPrivateNotesDrafts(): Observable<PrivateNotesDraftsGetResponse> {
    return this.apiService.get<PrivateNotesDraftsGetResponse>("/admin/private-notes-drafts", true);
  }

  getPrivateNotesDraftById(draftId):Observable<PrivateNotesDraftsShowResponse> {
    return this.apiService.get<PrivateNotesDraftsShowResponse>("/admin/private-notes-drafts/"+draftId,true);
  }

  createPrivateNotesDraft(data: Partial<PrivateNotesDraft>): Observable<PrivateNotesDraftsShowResponse> {
    return this.apiService.post<PrivateNotesDraftsShowResponse>("/admin/private-notes-drafts", true, data);
  }

  updatePrivateNotesDraft(draftId: number, data: any): Observable<PrivateNotesDraftsShowResponse> {
    return this.apiService.put<PrivateNotesDraftsShowResponse>("/admin/private-notes-drafts/" + draftId, true, data);
  }

  deletePrivateNotesDraft(draftId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/private-notes-drafts/" + draftId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}
