import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { __HTTPResponseType } from "../enums/common.enum";
import { Inventory } from "../models/new/inventory.model";
import { InventoryGetResponse } from "../models/responses/inventory-get.response";
import { InventoryShowResponse } from "../models/responses/inventory-show.response";

import { ApiService } from "./api.service";

@Injectable()
export class InventoryService {

  private INVENTORY_SHOW_INCLUDES = "logs";

  constructor(private apiService: ApiService) {
  }

  getInventoryList(unTagged: boolean): Observable<InventoryGetResponse> {
    if (!unTagged) {
      return this.apiService.get<InventoryGetResponse>("/admin/inventories", true, {
        include: this.INVENTORY_SHOW_INCLUDES
      });
    } else {
      return this.apiService.get<InventoryGetResponse>("/admin/inventories-not-tagged", true, {
        include: this.INVENTORY_SHOW_INCLUDES
      });
    }
  }

  deleteItem(inventoryId: number): Observable<boolean> {
    return this.apiService.delete("/admin/inventories/" + inventoryId, true, null,
      null, true, __HTTPResponseType.TEXT).pipe(map(() => {
      return true;
    }));
  }

  updateItem(data: Partial<Inventory>, id: number): Observable<Inventory> {
    return this.apiService.put<InventoryShowResponse>("/admin/inventories/" + id, true, {
      ...data,
      include: this.INVENTORY_SHOW_INCLUDES
    })
      .pipe(map(res => res.data));
  }

  addItem(data: Partial<Inventory>): Observable<InventoryShowResponse> {
    return this.apiService.post("/admin/inventories", true, data);
  }

  getInventoryById(inventoryId): Observable<Inventory> {
    return this.apiService.get<InventoryShowResponse>("/admin/inventories/" + inventoryId, true, {
      include: this.INVENTORY_SHOW_INCLUDES
    }).pipe(map(res => res.data));
  }

  exportInventory(data: any): Observable<any> {
    return this.apiService.post("/admin/inventories/export", true, data, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        const contentDispositionHeader = res.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        const fileName = result.replace(/"/g, "");

        saveAs(res.body, fileName);

        return true;
      }));
  }

  getTags(): Observable<any> {
    return this.apiService.get("/options/inventory-tags",true);
  }

  attachTag(id: number,title): Observable<any> {
    const data = {tag_string: title};
    return this.apiService.put("/admin/inventories/"+id+"/tags",true,data);
  }

  createTag(tagTitle): Observable<any> {
    const data = {tag_string: tagTitle};
    return this.apiService.post("/admin/inventory-tags", true,data);
  }

  deleteTag(id,title): Observable<any> {
    const data = {tag_string: title};
    return this.apiService.delete("/admin/inventories/"+id+"/tags",true,data,null,true,__HTTPResponseType.TEXT);
  }
}
