import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

import {
  GoalSetPercentagesDraftCreateSuccess, GoalSetPercentagesDraftDeleteSuccess,
  GoalSetPercentagesDraftIndexRequest,
  GoalSetPercentagesDraftIndexSuccess, GoalSetPercentagesDraftUpdateSuccess
} from "../../actions/new/setttings/drafts/goal-set-percentages-drafts";
import { GoalSetPercentagesDraft } from "../../models/new/drafts/goal-set-percentages.modal";
import {
  getAllGoalSetPercentagesDrafts,
  getIsGoalSetPercentagesDraftLoaded,
  getIsGoalSetPercentagesDraftLoading,
  State
} from "../../reducers";
import { GoalSetPercentagesService } from "../../services/drafts/goal-set-percentages.service";

@Injectable()
export class GoalSetPercentagesDraftRepository {

  constructor(private store: Store<State>,
              private goalSetPercentagesService: GoalSetPercentagesService) {
  }

  /**
   * Loading & Loaded
   */
  getIsGoalSetPercentagesDraftLoading(): Observable<boolean> {
    return this.store.select(getIsGoalSetPercentagesDraftLoading);
  }

  getIsGoalSetPercentagesDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsGoalSetPercentagesDraftLoaded);
  }

  /**
   * Service Methods
   */
  getGoalSetPercentagesDrafts(force: boolean): Observable<GoalSetPercentagesDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsGoalSetPercentagesDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsGoalSetPercentagesDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new GoalSetPercentagesDraftIndexRequest());

      this.goalSetPercentagesService.getGoalSetPercentagesDrafts().subscribe(response => {
        this.store.dispatch(new GoalSetPercentagesDraftIndexSuccess(response));
      });
    }

    return this.store.select(getAllGoalSetPercentagesDrafts).pipe(map(t => t as GoalSetPercentagesDraft[]));
  }

  createGoalSetPercentagesDraft(data: Partial<GoalSetPercentagesDraft>): Observable<GoalSetPercentagesDraft> {
    return this.goalSetPercentagesService.createGoalSetPercentagesDraft(data).pipe(map(res => {
      this.store.dispatch(new GoalSetPercentagesDraftCreateSuccess(res));
      return res;
    }));
  }

  updateGoalSetPercentagesDraft(draftId: number, data: Partial<GoalSetPercentagesDraft>): Observable<GoalSetPercentagesDraft> {
    return this.goalSetPercentagesService.updateGoalSetPercentagesDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new GoalSetPercentagesDraftUpdateSuccess(res));
      return res;
    }));
  }

  deleteGoalSetPercentagesDraft(draftId: number) {
    return this.goalSetPercentagesService.deleteGoalSetPercentagesDraft(draftId).pipe(map(res => {
      this.store.dispatch(new GoalSetPercentagesDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}
