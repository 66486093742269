import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Subject } from "rxjs";
import { filter, take, takeUntil } from "rxjs/operators";

import { BookingCompact } from "../../models/new/booking/booking-compact.model";
import { ListingCompact } from "../../models/new/listing/listing-compact.model";
import { UserFull } from "../../models/new/user/user-full.model";
import { OwnerDashStats } from "../../models/owner-dash-stats";
import { HomeOwnerReportDownloadPopupComponent } from "../../modules/shared/components/download-report-home-popup";
import { PropertyIncomeReportPopupComponent } from "../../modules/shared/components/property-income-report-popup";
import { BookingRepository } from "../../repository/booking.repository";
import { ListingRepository } from "../../repository/listing.repository";
import { StatsRepository } from "../../repository/stats.repository";
import { UserRepository } from "../../repository/user-repository";
import { AppService } from "../../services/app.service";
import { StayDuvetService } from "../../services/stayduvet";
import Utils from "../../utils/utils";
import { HomeCreateOwnerBlockPopupComponent } from "../create-owner-block-home-popup";

@Component({
  selector: "sd-owner-home",
  template: `
    <sd-owner-main-layout>
      <div fxFlex="95%" fxLayoutGap="10px" class="requiredHeight main-container">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutAlign="space-between center"
             style="font-size: x-small;">
          <button mat-raised-button color="accent" (click)="createOwnerBlockClicked()">Create Owner's Block</button>
          <button mat-raised-button color="accent" (click)="requestServiceClicked()">Request a Service</button>
          <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
            <span>Download a Report</span>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item color="accent" (click)="downloadReportClicked()">
              Homeowner Report
            </button>
            <button mat-menu-item color="accent" (click)="propertyIncomeClicked()">
              Property Income Report
            </button>
          </mat-menu>
          <sd-select placeholder="Select Listing" [multiple]="true"
                     [control]="selectedListingsControl" [options]="listingsOptions"
                     (selectionChanged)="selectedListingsChanged()"></sd-select>

          <sd-select placeholder="Year" [control]="selectedYearControl" [options]="years"
                     (selectionChanged)="setSelectedYear($event)"></sd-select>
        </div>
        <!--<mat-menu [overlapTrigger]="false" #listingMenu="matMenu">-->
        <!--<button mat-menu-item (click)="onSelectAll()">Show all.</button>-->
        <!--<button-->
        <!--mat-menu-item-->
        <!--*ngFor="let listing of listings"-->
        <!--(click)="selectListing(listing)">{{ listing.title }}-->
        <!--</button>-->
        <!--</mat-menu>-->
        <div class="well" fxLayout="row" fxLayoutAlign="space-around center" *ngIf="ownerStatsLoading">
          <mat-spinner color="accent" [diameter]="60" [strokeWidth]="4"></mat-spinner>
        </div>
        <div class="well" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center"
             *ngIf="!ownerStatsLoading">
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Total Income YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Total Revenue For Current year">
              <sd-counter
                [suffix]="''"
                [prefix]="'$'"
                [duration]="1"
                [countFrom]="0"
                [countTo]="aggregatedStats.stats.total_earning"
                [step]="100"></sd-counter>
            </div>
          </div>
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Total Expenses YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Total Expenses Till Date From Beginning Of Year">
              <sd-counter
                [suffix]="''"
                [prefix]="'$'"
                [duration]="1"
                [countFrom]="0"
                [countTo]="aggregatedStats.stats.total_expenses"
                [step]="100"></sd-counter>
            </div>
          </div>
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Confirmed Earnings
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Total Revenue Till Date From Beginning Of Year">
              <sd-counter
                [suffix]="''"
                [prefix]="'$'"
                [duration]="1"
                [countFrom]="0"
                [countTo]="aggregatedStats.stats.paid_earning"
                [step]="100"></sd-counter>
            </div>
          </div>
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Future Earnings YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Total Revenue From Current Date Till Year End">
              <sd-counter
                [suffix]="''"
                [prefix]="'$'"
                [duration]="1"
                [countFrom]="0"
                [countTo]="aggregatedStats.stats.future_earning"
                [step]="100"></sd-counter>
            </div>
          </div>
        </div>
        <div class="well" fxLayout="row" fxLayoutAlign="space-around center" *ngIf="breakdownLoading">
          <mat-spinner color="accent" [diameter]="60" [strokeWidth]="4"></mat-spinner>
        </div>
        <div class="well" fxLayout="row" *ngIf="!breakdownLoading">
          <sd-monthly-breakdown-chart
            [statsData]="aggregatedBreakdown"
            fxFlex="100%" style="height:400px"></sd-monthly-breakdown-chart>
        </div>
        <div class="well" fxLayout="row" fxLayoutAlign="space-around center" *ngIf="ownerStatsLoading">
          <mat-spinner color="accent" [diameter]="60" [strokeWidth]="4"></mat-spinner>
        </div>
        <div class="well" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center"
             *ngIf="!ownerStatsLoading">
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Days Booked YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Reservations confirmed for 2019">
              <sd-counter
                [suffix]="''"
                [prefix]="''"
                [duration]="1"
                [countFrom]="0"
                [countTo]="aggregatedStats.days_booked"
                [step]="100"></sd-counter>
            </div>
          </div>
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Occupancy Rate YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Reflects only reservations to date">
              <sd-counter
                [suffix]="'%'"
                [prefix]="''"
                [duration]="1"
                [countFrom]="0"
                [countTo]="aggregatedStats.occupancy_rate"
                [step]="100"></sd-counter>
            </div>
          </div>
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Revenue per booking YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Booked earnings / Confirmed reservations">
              <sd-counter
                [suffix]="''"
                [prefix]="'$'"
                [duration]="1"
                [countFrom]="0"
                [countTo]="revenuePerBooking"
                [step]="100"></sd-counter>
            </div>
          </div>
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Revenue per night YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Booked earnings / Total No Of Nights">
              <sd-counter
                [suffix]="''"
                [prefix]="'$'"
                [duration]="1"
                [countFrom]="0"
                [countTo]="revenuePerNight"
                [step]="100"></sd-counter>
            </div>
          </div>
        </div>

        <h4 *ngIf="filteredUpcomingBookings.length > 0">Upcoming Bookings: </h4>
        <div class="well" fxLayout="row" fxLayoutAlign="space-around center" *ngIf="upcomingBookingsLoading">
          <mat-spinner color="accent" [diameter]="60" [strokeWidth]="4"></mat-spinner>
        </div>
        <div class="well"
             fxLayout="row wrap"
             fxLayout.lt-sm="column wrap"
             fxLayoutAlign="space-around center"
             *ngIf="upcomingBookingsLoaded">
          <sd-upcoming-booking-card
            *ngFor="let booking of filteredUpcomingBookings"
            [booking]="booking"
            class="booking-card"
            fxFlex="23%"
            style="cursor: pointer">
          </sd-upcoming-booking-card>
          <!--<span *vaFlexAlignmentHack></span>-->
        </div>
      </div>
    </sd-owner-main-layout>
  `,
  styles: [`
    .well {
      background-color: #f5f5f5;
      border-radius: 5px;
      border: 5px black;
      margin: 20px 5px;
    }

    .stat-container {
      padding-top: 10px;
      padding-bottom: 20px;
    }

    .stat-header {
      font-size: 12px;
    }

    .stat-footer {
      padding-top: 5px;
      font-size: 30px;
      font-family: 'Montserrat', sans-serif;
    }

    .booking-card {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .main-container {
      margin: 30px;
    }

    .select-button {
      padding: 6px;
      text-align: left;
      font-size: 17px;
      padding-left: 10px;
      font-weight: bolder;
    }
  `]
})
export class OwnerHomeComponent implements OnInit, AfterViewInit, OnDestroy {
  years: { title: string, value: any }[] = [];
  ownerStatsLoading = false;
  ownerStatsLoaded = false;
  stats: { [id: number]: OwnerDashStats } = {};
  aggregatedStats: OwnerDashStats = {
    stats: {
      total_earning: 0,
      paid_earning: 0,
      future_earning: 0,
      total_expenses: 0,
    },
    days_booked: 0,
    total_bookings: 0,
    occupancy_rate: 0
  };
  breakdownLoading = false;
  breakdownLoaded = false;
  breakdown: {};
  aggregatedBreakdown = [];
  upcomingBookingsLoading = false;
  upcomingBookingsLoaded = false;
  upcomingBookings = [];
  filteredUpcomingBookings = [];
  selectedListings: ListingCompact[] = [];
  selectedListingsControl: FormControl = new FormControl([]);
  listings: ListingCompact[] = [];
  listingsOptions: { title: string, value: any }[];
  user: UserFull;
  revenuePerNight = 0;
  revenuePerBooking = 0;
  selectedYear = moment().year();
  selectedYearControl: FormControl = new FormControl(this.selectedYear);
  showPreviousYear = 2019 === moment().year();
  private destroyed$ = new Subject();
  private dialogRef: MatDialogRef<any>;

  constructor(private service: StayDuvetService,
              private bookingRepo: BookingRepository,
              private appService: AppService,
              private userRepo: UserRepository,
              private router: Router,
              private statsRepo: StatsRepository,
              private listingRepo: ListingRepository,
              private dialog: MatDialog) {
  }

  ngAfterViewInit() {
    this.appService.changeAppTitle("Owner Home");
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    for (let i = 2019; i <= moment().add(7, "days").year() + 1; i++) {
      this.years.push({
        title: String(i),
        value: i
      });
    }
    console.log("onInit sd-owner-home");

    this.userRepo.getUser().pipe(filter(u => !!u), takeUntil(this.destroyed$),).subscribe((user) => {
      this.user = user;
    });

    this.listingRepo.getAcceptedListings().pipe(takeUntil(this.destroyed$)).subscribe((listings) => {
      this.listings = listings;
      this.listingsOptions = this.listings.map(listing => {
        return {title: listing.title, value: listing};
      });
      this.selectedListings = this.listings;
      this.selectedListingsControl.setValue(this.selectedListings);
    });

    this.setupUpcomingBookings();
    this.setupMonthlyBreakdown();
    this.setupStats();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setupUpcomingBookings() {
    this.bookingRepo.getIsOwnerUpcomingBookingLoading().subscribe(l => this.upcomingBookingsLoading = l);
    this.bookingRepo.getIsOwnerUpcomingBookingLoaded().subscribe(l => this.upcomingBookingsLoaded = l);

    this.bookingRepo.getOwnerUpcomingBookings().pipe(filter(r => !!r), takeUntil(this.destroyed$))
      .subscribe(res => {
        console.log("upcoming loading", this.upcomingBookingsLoading);
        this.upcomingBookings = res;
        this.filterBookings();
      });
  }

  filterBookings() {
    const listingIds = this.selectedListings.map(l => l.id);
    this.filteredUpcomingBookings = this.upcomingBookings
      .filter((b: BookingCompact) => listingIds.indexOf(b.property_id) !== -1);
  }

  setupStats() {
    this.statsRepo.getSelectedYear().pipe(takeUntil(this.destroyed$)).subscribe(y => this.selectedYear = y);
    this.selectedYearControl.setValue(this.selectedYear);
    this.statsRepo.getIsOwnerStatsLoading().pipe(takeUntil(this.destroyed$)).subscribe(l => this.ownerStatsLoading = l);
    this.statsRepo.getIsOwnerStatsLoaded().pipe(takeUntil(this.destroyed$)).subscribe(l => this.ownerStatsLoaded = l);
    this.statsRepo.getOwnerStats().pipe(takeUntil(this.destroyed$), filter(v => !!v),).subscribe((ownerStats) => {
      if (Object.keys(ownerStats).length > 0) {
        this.stats = ownerStats;
        this.aggregateStats();
      }
    });
  }

  setupMonthlyBreakdown() {
    this.statsRepo.getIsMonthlyBreakdownLoading().pipe(takeUntil(this.destroyed$)).subscribe(l => this.breakdownLoading = l);
    this.statsRepo.getIsMonthlyBreakdownLoaded().pipe(takeUntil(this.destroyed$)).subscribe(l => this.breakdownLoaded = l);
    this.statsRepo.getMonthlyBreakdown().pipe(takeUntil(this.destroyed$), filter(v => !!v),).subscribe((breakdown) => {
      this.breakdown = breakdown;
      this.aggregateBreakdown();
    });
  }

  selectedListingsChanged() {
    this.selectedListings = this.selectedListingsControl.value;
    this.filterBookings();
    this.aggregateStats();
    this.aggregateBreakdown();
  }

  aggregateStats() {
    let selectedStats: OwnerDashStats[] = [];

    if (this.selectedListings == null) {
      selectedStats = Utils.normalizedObjToArray(this.stats);
    } else {
      for (let i = 0; i < this.selectedListings.length; i++) {
        selectedStats.push(this.stats[this.selectedListings[i].id]);
      }
    }

    const aggregatedStats = {
      stats: {
        total_earning: 0,
        paid_earning: 0,
        future_earning: 0,
        total_expenses: 0,
      },
      days_booked: 0,
      occupancy_rate: 0,
      total_bookings: 0,
    };
    let numberOfDays = 0;
    let sumOccupancyRate = 0;

    for (let i = 0; i < selectedStats.length; i++) {
      aggregatedStats.stats.total_earning += selectedStats[i].stats.total_earning;
      aggregatedStats.stats.paid_earning += selectedStats[i].stats.paid_earning;
      aggregatedStats.stats.future_earning += selectedStats[i].stats.future_earning;
      aggregatedStats.stats.total_expenses += selectedStats[i].stats.total_expenses;
      aggregatedStats.days_booked += selectedStats[i].days_booked;
      aggregatedStats.total_bookings += selectedStats[i].total_bookings;
      sumOccupancyRate += selectedStats[i].occupancy_rate;
      numberOfDays += 1;
    }

    this.revenuePerNight = aggregatedStats.stats.total_earning / aggregatedStats.days_booked || 0;
    this.revenuePerBooking = aggregatedStats.stats.total_earning / aggregatedStats.total_bookings || 0;
    aggregatedStats.occupancy_rate = Math.ceil(sumOccupancyRate / numberOfDays) || 0;

    this.aggregatedStats = aggregatedStats;
  }

  aggregateBreakdown() {
    let selectedListings = this.selectedListings;

    if (selectedListings == null) {
      selectedListings = this.listings;
    }

    const aggregatedBreakdown = [];
    const months = Object.keys(this.breakdown);
    for (let i = 0; i < months.length; i++) {
      const statForMonth = this.breakdown[months[i]];

      const stats = [];
      for (const listing of selectedListings) {
        if (statForMonth[listing.id]) {
          const value = statForMonth[listing.id].total_payout;
          console.log("[Stats]", statForMonth);
          stats.push({
            name: listing.title,
            id: listing.id,
            stats: statForMonth[listing.id],
            value: value
          });
        }
      }
      aggregatedBreakdown.push({
        name: months[i],
        series: stats
      });
    }

    this.aggregatedBreakdown = aggregatedBreakdown;
  }

  setSelectedYear(year: number) {
    this.selectedYear = this.selectedYearControl.value;
    this.statsRepo.setSelectedYear(year);
    this.statsRepo.getMonthlyBreakdown([], null, true).pipe(take(1)).subscribe();
    this.statsRepo.getOwnerStats(null, true).pipe(take(1)).subscribe();
  }

  createOwnerBlockClicked() {
    this.dialogRef = this.dialog.open(HomeCreateOwnerBlockPopupComponent);
    this.dialogRef.updateSize("100%");
  }

  requestServiceClicked() {
    this.router.navigate(["/tasks/new", {
      from: "owner-home"
    }]);
  }

  downloadReportClicked() {
    this.dialogRef = this.dialog.open(HomeOwnerReportDownloadPopupComponent);
    this.dialogRef.updateSize("100%");
  }

  propertyIncomeClicked() {
    this.dialogRef = this.dialog.open(PropertyIncomeReportPopupComponent);
    this.dialogRef.componentInstance.listings = this.listings;
    this.dialogRef.updateSize("100%");
  }
}
