import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { take, tap } from "rxjs/operators";

import {
  OwnerWelcomeLetterCreateRequest, OwnerWelcomeLetterCreateSuccess,
  OwnerWelcomeLetterIndexRequest,
  OwnerWelcomeLetterIndexSuccess, OwnerWelcomeLetterSetAsDefault
} from "../actions/new/setttings/owner-welcome-letter";
import { OwnerWelcomeLetter } from "../models/new/owner-welcome-letter.model";
import {
  getIsOwnerWelcomeLetterLoaded,
  getIsOwnerWelcomeLetterLoading, getOwnerWelcomeLetters,
  State
} from "../reducers";
import { AWSService } from "../services/aws.service";
import { OwnerWelcomeLetterService } from "../services/owner-welcome-letter.service";

@Injectable()
export class OwnerWelcomeLetterRepository {

  constructor(private store: Store<State>,
              private ownerWelcomeLetterService: OwnerWelcomeLetterService,
              private awsService: AWSService) {
  }

  getOwnerWelcomeLetterLoading(): Observable<boolean> {
    return this.store.select(getIsOwnerWelcomeLetterLoading);
  }

  getOwnerWelcomeLetterLoaded(): Observable<boolean> {
    return this.store.select(getIsOwnerWelcomeLetterLoaded);
  }

  getOwnerWelcomeLetters(force: boolean = false): Observable<OwnerWelcomeLetter[]> {
    let loading = false;
    let loaded = false;

    this.getOwnerWelcomeLetterLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getOwnerWelcomeLetterLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new OwnerWelcomeLetterIndexRequest());
      this.ownerWelcomeLetterService.getOwnerWelcomeLetters().subscribe(res => {
        this.store.dispatch(new OwnerWelcomeLetterIndexSuccess(res));
      });
    }
    return this.store.select(getOwnerWelcomeLetters);
  }

  createOwnerWelcomeLetter(fileName: string): Observable<OwnerWelcomeLetter> {
    return this.ownerWelcomeLetterService.createOwnerWelcomeLetter(fileName);
  }

  completeOwnerWelcomeLetter(id: number): Observable<OwnerWelcomeLetter> {
    this.store.dispatch(new OwnerWelcomeLetterCreateRequest());
    return this.ownerWelcomeLetterService.completeOwnerWelcomeLetter(id).pipe(tap(res => {
      this.store.dispatch(new OwnerWelcomeLetterCreateSuccess(res));
    }));
  }

  setAsDefault(id: number): Observable<boolean> {
    return this.ownerWelcomeLetterService.setAsDefault(id).pipe(tap(res => {
      this.store.dispatch(new OwnerWelcomeLetterSetAsDefault(id));
    }));
  }

  uploadOwnerWelcomeLetter(uploadURL: string, file: any) {
    return this.awsService.uploadToAWS(uploadURL, file);
  }
}
