import {filter, map, take} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {
  getAdmins,
  getAllChecklistCategories,
  getAllCountries,
  getAllLocations,
  getAllTags,
  getAllVendors,
  getAssigness,
  getIsAdminsLoaded,
  getIsAdminsLoading,
  getIsAssgineesLoaded,
  getIsAssigneesLoading,
  getIsChecklistCategoryLoaded,
  getIsChecklistCategoryLoading,
  getIsCountryLoaded,
  getIsCountryLoading,
  getIsLocationLoaded,
  getIsLocationLoading,
  getIsStatesLoadedByCountryCode,
  getIsStatesLoadingByCountryCode,
  getIsTagLoaded,
  getIsTagLoading,
  getIsVendorsLoaded,
  getIsVendorsLoading,
  getStatesByCountryCode,
  State
} from "../reducers";
import {Observable} from "rxjs";
import {ListingTag} from "../models/new/listing/listing-tag.model";
import {StayDuvetService} from "../services/stayduvet";
import {Country} from "../models/new/country.model";
import {OptionService} from "../services/option.service";
import {
  OptionAdminCreateSuccess,
  OptionAdminIndexRequest,
  OptionAdminIndexSuccess,
  OptionChecklistCategoryCreateRequest,
  OptionChecklistCategoryCreateSuccess,
  OptionChecklistCategoryIndexRequest,
  OptionChecklistCategoryIndexSuccess,
  OptionCountryIndexRequest,
  OptionCountryIndexSuccess,
  OptionLocationIndexRequest,
  OptionLocationIndexSuccess,
  OptionStateIndexRequest,
  OptionStateIndexSuccess,
  OptionTagCreateSuccess,
  OptionTagIndexRequest,
  OptionTagIndexSuccess,
  TaskAssigneesIndexRequest,
  TaskAssigneesIndexSuccess,
  VendorIndexRequest,
  VendorIndexSuccess
} from "../actions/new/option";
import {StateSubDivision} from "../models/new/state-subdivision.model";
import {Tag} from "../models/tag";
import {AttachTagSuccess, DeleteTagSuccess} from "../actions/new/listings/listing";
import {UserCompact} from "../models/new/user/user-compact.model";
import {ChecklistCategory} from "../models/new/checklist-category";
import {UserFull} from "../models/new/user/user-full.model";
import {CommonUtil} from "../utils/common.util";

@Injectable()
export class OptionsRepository {
  constructor(private store: Store<State>,
              private stayDuvetService: StayDuvetService,
              private optionsService: OptionService) {

  }

  getIsTagLoading(): Observable<boolean> {
    return this.store.select(getIsTagLoading);
  }

  getIsTagLoaded(): Observable<boolean> {
    return this.store.select(getIsTagLoaded);
  }

  getAllTags(force: boolean = false): Observable<ListingTag[]> {
    let loaded = false;
    let loading = false;
    this.getIsTagLoaded().pipe(take(1)).subscribe(l => loaded = l);
    this.getIsTagLoading().pipe(take(1)).subscribe(l => loading = l);
    if (!loading && (!loading || force)) {
      this.store.dispatch(new OptionTagIndexRequest());
      this.optionsService.getTags().pipe(map(res => res.data)).subscribe(tags => {
        this.store.dispatch(new OptionTagIndexSuccess(tags));
      });
    }

    return this.store.select(getAllTags).pipe(filter(t => !!t), map(tags => {
      console.log("tags", CommonUtil.sortByKey(tags, "title"));
      return CommonUtil.sortByKey(tags, "title");
    }),);
  }

  getIsLocationLoading(): Observable<boolean> {
    return this.store.select(getIsLocationLoading);
  }

  getIsLocationLoaded(): Observable<boolean> {
    return this.store.select(getIsLocationLoaded);
  }

  getAllLocations(force: boolean = false): Observable<string[]> {
    let loaded = false;
    let loading = false;
    this.getIsLocationLoaded().pipe(take(1)).subscribe(l => loaded = l);
    this.getIsLocationLoading().pipe(take(1)).subscribe(l => loading = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new OptionLocationIndexRequest());
      this.optionsService.getListingLocations().subscribe(locations => {
        this.store.dispatch(new OptionLocationIndexSuccess(locations));
      });
    }
    return this.store.select(getAllLocations);
  }


  getIsCountriesLoading(): Observable<boolean> {
    return this.store.select(getIsCountryLoading);
  }

  getIsCountriesLoaded(): Observable<boolean> {
    return this.store.select(getIsCountryLoaded);
  }

  getAllCountries(force: boolean = false): Observable<Country[]> {
    let loaded = false;
    let loading = false;
    this.getIsCountriesLoaded().subscribe(l => {
      loaded = l;
    });
    this.getIsCountriesLoading().subscribe(l => {
      loading = l;
    });
    if (!loading && (!loaded || force)) {
      this.store.dispatch(new OptionCountryIndexRequest());
      this.optionsService.getCountries().pipe(map(res => res.countries)).subscribe(countries => {
        this.store.dispatch(new OptionCountryIndexSuccess(countries));
      });
    }
    return this.store.select(getAllCountries).pipe(filter(c => !!c));
  }

  getIsStateLoading(country_code: string): Observable<boolean> {
    return this.store.select((state: State) => getIsStatesLoadingByCountryCode(state, country_code));
  }

  getIsStateLoaded(country_code: string): Observable<boolean> {
    return this.store.select((state: State) => getIsStatesLoadedByCountryCode(state, country_code));
  }

  getState(country_code: string, force: boolean = false): Observable<StateSubDivision[]> {
    let loading = false;
    let loaded = false;
    this.getIsStateLoading(country_code).pipe(take(1)).subscribe(l => {
      loading = l;
    });

    this.getIsStateLoaded(country_code).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new OptionStateIndexRequest(country_code));
      const state = { country_code: country_code };
      this.optionsService.getStates(state).pipe(map(res => res.states)).subscribe(states => {
        this.store.dispatch(new OptionStateIndexSuccess({ countryCode: country_code, states: states }));
      });
    }
    return this.store.select((state: State) => getStatesByCountryCode(state, country_code));
  }

  removeTag(property_id: string, tag: Tag): Observable<boolean> {
    return this.optionsService.removeTag(property_id, tag).pipe(map(res => {
      this.store.dispatch(new DeleteTagSuccess({ listingId: property_id, tagId: String(tag.id) }));
      return true;
    }))
  }

  createTag(data: { tag_string: string }): Observable<ListingTag> {
    return this.optionsService.createTag(data).pipe(map(res => {
      this.store.dispatch(new OptionTagCreateSuccess(res.data));
      return res.data;
    }));
  }


  attachTag(data: { tag_string: string }, property_id: string): Observable<ListingTag> {
    return this.optionsService.attachTag(data, property_id).pipe(map(res => {
      this.store.dispatch(new AttachTagSuccess({ listingId: +property_id, tag: res.data }));
      return res.data;
    }));
  }


  getIsAdminsLoading(): Observable<boolean>{
    return this.store.select(getIsAdminsLoading);
  }

  getIsAdminsLoaded(): Observable<boolean>{
    return this.store.select(getIsAdminsLoaded);
  }

  getAdmins(force: boolean = false): Observable<UserCompact[]> {
    let loaded = false;
    let loading = false;

    this.getIsAdminsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsAdminsLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {

      this.store.dispatch(new OptionAdminIndexRequest());
      this.optionsService.getAdmins().subscribe(admins => {
        this.store.dispatch(new OptionAdminIndexSuccess(admins));
      });
    }

    return this.store.select(getAdmins);
  }

  getListingAssignees(): Observable<UserCompact[]> {
    return this.optionsService.getListingAssignees();
  }

  getAgents(): Observable<UserCompact[]> {
    return this.optionsService.getAgents();
  }

  getVendorsLoading(): Observable<boolean> {
    return this.store.select(getIsVendorsLoading);
  }

  getVendorsLoaded(): Observable<boolean> {
    return this.store.select(getIsVendorsLoaded);
  }

  getVendors(force: boolean = false): Observable<UserCompact[]> {

    let loaded = false;
    let loading = false;

    this.getVendorsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getVendorsLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {

      this.store.dispatch(new VendorIndexRequest());
      this.optionsService.getVendors().subscribe((res) => {
        this.store.dispatch(new VendorIndexSuccess(res));
      });
    }

    return this.store.select(getAllVendors);
  }

  createCategory(title: string): Observable<ChecklistCategory> {
    this.store.dispatch(new OptionChecklistCategoryCreateRequest());
    return this.optionsService.createCategory(title).pipe(map(res => {
      this.store.dispatch(new OptionChecklistCategoryCreateSuccess(res));
      return res;
    }));
  }

  getTaskAssignees(force: boolean = false): Observable<UserCompact[]> {
    let loaded = false;
    let loading = false;

    this.store.select(getIsAssigneesLoading).subscribe(l => loading = l);
    this.store.select(getIsAssgineesLoaded).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new TaskAssigneesIndexRequest());
      this.optionsService.getTaskAssignees().subscribe((assignees) => {
        this.store.dispatch(new TaskAssigneesIndexSuccess(assignees));
      });
    }
    return this.store.select(getAssigness);
  }

  getHousekeeperEmployees(force: boolean = false): Observable<UserCompact[]> {
    let loaded = false;
    let loading = false;

    this.store.select(getIsAssigneesLoading).subscribe(l => loading = l);
    this.store.select(getIsAssgineesLoaded).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new TaskAssigneesIndexRequest());
      this.optionsService.getHousekeeperEmployees().subscribe((employees) => {
        this.store.dispatch(new TaskAssigneesIndexSuccess(employees));
      });
    }
    return this.store.select(getAssigness);
  }

  createAdmin(data: { first_name: string, last_name: string, email: string }): Observable<UserFull> {
    return this.optionsService.createAdmin(data).pipe(map(admin => {
      this.store.dispatch(new OptionAdminCreateSuccess(admin));
      return admin;
    }));
  }

  getIsChecklistCategoryLoading(): Observable<boolean> {
    return this.store.select(getIsChecklistCategoryLoading);
  }

  getIsChecklistCategoryLoaded(): Observable<boolean> {
    return this.store.select(getIsChecklistCategoryLoaded);
  }

  getChecklistCategories(force: boolean = false): Observable<ChecklistCategory[]> {
    let loading;
    let loaded;

    this.getIsChecklistCategoryLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsChecklistCategoryLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new OptionChecklistCategoryIndexRequest());
      this.optionsService.getChecklistCategories().subscribe(res => {
        this.store.dispatch(new OptionChecklistCategoryIndexSuccess(res));
      });
    }
    return this.store.select(getAllChecklistCategories);
  }

  getAgentHomeowners(): Observable<UserCompact[]> {
    return this.optionsService.getAgentHomeowners();
  }
}
