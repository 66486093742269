import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { addDays } from "date-fns";
import * as _ from "lodash";
import { BehaviorSubject, combineLatest as observableCombineLatest, Subject } from "rxjs";

import { debounceTime, distinctUntilChanged, skip, take, takeUntil } from "rxjs/operators";
import { isNullOrUndefined } from "util";
import { SortOrder } from "../../../enums/common.enum";
import {
  HouseKeeperTaskStatus,
  TaskCategory,
  TaskEnumHelper,
  TaskPaymentBy,
  TaskSortBy,
  TaskStatus,
  TaskTypeForReport
} from "../../../enums/task.enum";
import { UserModelUtil } from "../../../models/utils/user-model.util";
import { ListingRepository } from "../../../repository/listing.repository";
import { OptionsRepository } from "../../../repository/options.repository";
import { TaskRepository } from "../../../repository/task.repository";
import { UserRepository } from "../../../repository/user-repository";
import { CommonUtil } from "../../../utils/common.util";
import { addDaysToDate, dateToDateString, getDateObj, removeDaysFromDate } from "../../../utils/calendar-utils";

@Component({
  selector: "sd-tasks-report",
  template: `

    <div fxLayout="column">
      <div class="header" fxFlex="row" fxLayoutAlign="start center">
        <mat-toolbar color="accent">
          <h2>
            <ng-container>Tasks Report</ng-container>
          </h2>
          <span class="space-filler"></span>

          <button mat-icon-button *ngIf="!isDownloading && !tasksLoading" [matMenuTriggerFor]="menu">
            <mat-icon>cloud_download</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button *ngIf="!isHouseKeeper && !isEmployee" mat-menu-item (click)="download('csv')">csv</button>
            <button mat-menu-item (click)="download('xls')">xls</button>
            <button mat-menu-item (click)="download('xlsx')">xlsx</button>
          </mat-menu>
          <mat-spinner [diameter]="30" [strokeWidth]="4" *ngIf="isDownloading">
          </mat-spinner>
          <button mat-icon-button (click)="redirectToTasks()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar>
      </div>


      <div class="body">
        <!--Displaying Tasks List-->
        <div class="task-show-container">

          <sd-center-spinner *ngIf="tasksLoading"></sd-center-spinner>

          <div class="content" *ngIf="!tasksLoading" style="padding: 10px;">

            <div *ngIf="!isEmployee&&!isHouseKeeper" fxLayout="row" style="width: 100%" fxLayoutGap="20px"
                 fxLayout.lt-sm="column"
                 fxLayoutAlign.lt-sm="center center"
                 fxLayoutAlign="center center">

              <mat-form-field [color]="'accent'" dividerColor="accent" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                <input matInput [matDatepicker]="start"
                       [disabled]="isDisabled"
                       [(ngModel)]="startDate"
                       floatPlaceholder="never"
                       (ngModelChange)="startDateChanged($event)"
                       [ngModelOptions]="{standalone: true}"
                       placeholder="Start Date">
                <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                <mat-datepicker #start></mat-datepicker>
              </mat-form-field>

              <mat-form-field [color]="'accent'" dividerColor="accent" ngClass.xs="width-2" ngClass.gt-xs="width-3">

                <input matInput [matDatepicker]="end"
                       [min]="minDate"
                       [disabled]="isDisabled"
                       [(ngModel)]="endDate"
                       floatPlaceholder="never"
                       (ngModelChange)="endDateChanged()"
                       [ngModelOptions]="{standalone: true}"
                       placeholder="End Date">
                <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                <mat-datepicker #end></mat-datepicker>
              </mat-form-field>

              <mat-form-field [color]="'accent'" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                <mat-select
                  placeholder="Offset"
                  [disabled]="isDisabled"
                  [formControl]="selectedOffset">
                  <mat-option value="due_date">Due Date
                  </mat-option>
                  <mat-option value="paid_on">Paid On
                  </mat-option>
                  <mat-option value="created_at">Created On
                  </mat-option>
                  <mat-option value="completed_on">
                      Completed On
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="!isHouseKeeper && !isEmployee" [color]="'accent'" ngClass.xs="width-2"
                              ngClass.gt-xs="width-3">
                <mat-select
                  placeholder="Task Type"
                  [disabled]="isDisabled"
                  [(ngModel)]="selectedTypeFilter"
                  (ngModelChange)="taskTypeChanged(selectedTypeFilter)">
                  <mat-option *ngFor="let typeRef of types" [value]="typeRef">
                    {{ TaskEnumHelper.getTaskTypeTitle(typeRef) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field color="accent"
                              ngClass.xs="width-2" ngClass.gt-xs="width-3">
                <mat-select
                  multiple
                  placeholder="Select Listing"
                  [disabled]="isDisabled"
                  [(ngModel)]="selectedListingIds"
                  floatPlaceholder="never"
                  (ngModelChange)="taskListingsChanged(selectedListingIds)"
                  [ngModelOptions]="{standalone: true}"
                  (openedChange)="!$event&&onMatSelectClose()"
                >
                  <mat-select-trigger>
                        <span *ngIf="selectedListingIds.length === allListingIds.length">
                          All Listings
                        </span>
                    <span *ngIf="selectedListingIds.length === 0">
                          No Listing Selected
                        </span>
                    {{
                    selectedListingIds.length > 0 && selectedListingIds.length !== allListingIds.length ? getListingTitleFromId(selectedListingIds[0]) : ""
                    }}
                    <span class="extra-text"
                          *ngIf="selectedListingIds.length > 1 && selectedListingIds.length !== allListingIds.length">
                          (+{{selectedListingIds.length - 1}} others)
                        </span>
                  </mat-select-trigger>


                  <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                    <input matInput placeholder="Search Listing" [formControl]="listingFilter">
                  </mat-form-field>

                  <div fxLayout="column">
                    <button class="select-button" mat-button
                            (click)="onSelectAll();taskListingsChanged(selectedListingIds)">Select All
                    </button>
                    <button class="select-button" mat-button
                            (click)="onSelectNone();taskListingsChanged(selectedListingIds)">Select None
                    </button>
                  </div>

                  <mat-option *ngFor="let listingId of filteredListings" [value]="listingId">
                    {{ getListingTitleFromId(listingId) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>


              <mat-form-field [color]="'accent'" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                <mat-select
                  multiple
                  [disabled]="isDisabled"
                  placeholder="Status Type"
                  [(ngModel)]="selectedStatusFilters"
                  (ngModelChange)="taskStatusChanged(selectedStatusFilters)">

                  <div fxLayout="column">
                    <button class="select-button" (click)="selectAllStatus()" mat-button>Select All</button>
                    <button class="select-button" (click)="deselectAllStatus()" mat-button>Select None</button>
                  </div>

                  <mat-option *ngFor="let status of statusTypes" [value]="status" [ngStyle]="{
                              'background-color': TaskEnumHelper.getTaskStatusEnum(status).color,
                              'color': status.slug == 'waiting_for_approval' ? 'white' : 'black'
                            }">
                    {{TaskEnumHelper.getTaskStatusEnum(status).title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>

            <div *ngIf="isEmployee || isHouseKeeper" fxLayout="column" fxLayoutGap="20px">

              <div fxLayout="row" style="width: 100%" fxLayoutGap="20px" fxLayout.lt-sm="column"
                   fxLayoutAlign.lt-sm="center center"
                   fxLayoutAlign="center center">

                <mat-form-field [color]="'accent'" dividerColor="accent" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                  <input matInput [matDatepicker]="start"
                         [disabled]="isDisabled"
                         [(ngModel)]="startDate"
                         floatPlaceholder="never"
                         (ngModelChange)="startDateChanged($event)"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="Start Date">
                  <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                  <mat-datepicker #start></mat-datepicker>
                </mat-form-field>

                <mat-form-field [color]="'accent'" dividerColor="accent" ngClass.xs="width-2" ngClass.gt-xs="width-3">

                  <input matInput [matDatepicker]="end"
                         [min]="minDate"
                         [disabled]="isDisabled"
                         [(ngModel)]="endDate"
                         floatPlaceholder="never"
                         (ngModelChange)="endDateChanged()"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="End Date">
                  <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                  <mat-datepicker #end></mat-datepicker>
                </mat-form-field>

                <mat-form-field [color]="'accent'" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                  <mat-select
                    placeholder="Offset"
                    [disabled]="isDisabled"
                    [formControl]="selectedOffset">
                    <mat-option value="due_date">Due Date
                    </mat-option>
                    <mat-option value="paid_on">Paid On
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxLayout="row" style="width: 100%" fxLayoutGap="20px" fxLayout.lt-sm="column"
                   fxLayoutAlign.lt-sm="center center"
                   fxLayoutAlign="center center">

                <mat-form-field color="accent"
                                ngClass.xs="width-2" ngClass.gt-xs="width-3">
                  <mat-select
                    multiple
                    placeholder="Select Listing"
                    [disabled]="isDisabled"
                    [(ngModel)]="selectedListingIds"
                    floatPlaceholder="never"
                    (ngModelChange)="taskListingsChanged(selectedListingIds)"
                    [ngModelOptions]="{standalone: true}"
                    (openedChange)="!$event&&onMatSelectClose()"
                  >
                    <mat-select-trigger>
                        <span *ngIf="selectedListingIds.length === allListingIds.length">
                          All Listings
                        </span>
                      <span *ngIf="selectedListingIds.length === 0">
                          No Listing Selected
                        </span>
                      {{
                      selectedListingIds.length > 0 && selectedListingIds.length !== allListingIds.length ? getListingTitleFromId(selectedListingIds[0]) : ""
                      }}
                      <span class="extra-text"
                            *ngIf="selectedListingIds.length > 1 && selectedListingIds.length !== allListingIds.length">
                          (+{{selectedListingIds.length - 1}} others)
                        </span>
                    </mat-select-trigger>


                    <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                      <input matInput placeholder="Search Listing" [formControl]="listingFilter">
                    </mat-form-field>

                    <div fxLayout="column">
                      <button class="select-button" mat-button
                              (click)="onSelectAll();taskListingsChanged(selectedListingIds)">Select All
                      </button>
                      <button class="select-button" mat-button
                              (click)="onSelectNone();taskListingsChanged(selectedListingIds)">Select None
                      </button>
                    </div>

                    <mat-option *ngFor="let listingId of filteredListings" [value]="listingId">
                      {{ getListingTitleFromId(listingId) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>


                <mat-form-field [color]="'accent'" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                  <mat-select
                    multiple
                    [disabled]="isDisabled"
                    placeholder="Status Type"
                    [(ngModel)]="selectedStatusFilters"
                    (ngModelChange)="taskStatusChanged(selectedStatusFilters)">

                    <div fxLayout="column">
                      <button class="select-button" (click)="selectAllStatus()" mat-button>Select All</button>
                      <button class="select-button" (click)="deselectAllStatus()" mat-button>Select None</button>
                    </div>

                    <mat-option *ngFor="let status of statusTypes" [value]="status" [ngStyle]="{
                              'background-color': TaskEnumHelper.getTaskStatusEnum(status).color,
                              'color': status.slug == 'waiting_for_approval' ? 'white' : 'black'
                            }">
                      {{TaskEnumHelper.getTaskStatusEnum(status).title}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <button [disabled]="isDisabled" mat-icon-button (click)="clearFilters()">
                  <mat-icon>close</mat-icon>
                </button>
                <button mat-icon-button (click)="toggleEdit()">
                  <mat-icon *ngIf="isDisabled">edit</mat-icon>
                  <mat-icon *ngIf="!isDisabled">done</mat-icon>
                </button>

              </div>

            </div>

            <div *ngIf="!isEmployee && !isHouseKeeper" fxLayout="row" style="width: 100%" fxLayoutGap="20px"
                 fxLayout.lt-sm="column"
                 fxLayoutAlign.lt-sm="center center"
                 fxLayoutAlign="center center">

              <mat-form-field [color]="'accent'" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                <mat-select
                  multiple
                  [disabled]="isDisabled"
                  placeholder="Category"
                  [(ngModel)]="selectedCategoryFilters"
                  (ngModelChange)="taskCategoryChanged(selectedCategoryFilters)">
                  <div fxLayout="column">
                    <button class="select-button" (click)="selectAllCategory()" mat-button>Show All</button>
                    <button class="select-button" (click)="deselectAllCategory()" mat-button>Show None</button>
                  </div>
                  <mat-option *ngFor="let category of categories" [value]="category">
                    {{ TaskEnumHelper.getTaskCategoryTitle(category) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field color="accent" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                <mat-select
                  multiple
                  [disabled]="isDisabled"
                  placeholder="Assignee"
                  floatPlaceholder="never"
                  [(ngModel)]="selectedAssigneeIds"
                  (openedChange)="!$event&&onMatSelectClose()"
                  (ngModelChange)="taskAssigneeChanged(selectedAssigneeIds)">
                  <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                    <input matInput placeholder="Search Assignee" [formControl]="assigneeFilter">
                  </mat-form-field>
                  <div fxLayout="column">
                    <button class="select-button" mat-button
                            (click)="selectAllAssignees()">Select All
                    </button>
                    <button class="select-button" mat-button
                            (click)="deselectAllAssignees()">Select None
                    </button>
                  </div>
                  <mat-option *ngFor="let assignee of filteredAssignees" [value]="assignee.id">
                    {{UserModelUtil.getFullName(assignee)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field color="accent" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                <mat-select
                  multiple
                  placeholder="Created By:"
                  floatPlaceholder="never"
                  [disabled]="isDisabled"
                  [(ngModel)]="selectedCreatorIds"
                  (ngModelChange)="taskCreatedByChanged(selectedCreatorIds)">
                  <div fxLayout="column">
                    <button class="select-button" (click)="selectAllCreatedBy()" mat-button>Show All</button>
                    <button class="select-button" (click)="deselectAllCreatedBy()" mat-button>Show None</button>
                  </div>
                  <mat-option *ngFor="let admin of allAdmins" [value]="admin.id">
                    {{admin.first_name}} {{admin.last_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field [color]="'accent'" ngClass.xs="width-2" ngClass.gt-xs="width-3">
                <mat-select
                  multiple
                  placeholder="Who Will Pay?*"
                  floatPlaceholder="never"
                  [disabled]="isDisabled"
                  [(ngModel)]="selectedPaymentBy"
                  (ngModelChange)="whoWillPayChanged(selectedPaymentBy)"
                  [ngModelOptions]="{standalone: true}">
                  <div fxLayout="column">
                    <button class="select-button" (click)="selectAllPaymentBy()" mat-button>Show All</button>
                    <button class="select-button" (click)="deselectAllPaymentBy()" mat-button>Show None</button>
                  </div>
                  <mat-option *ngFor="let paymentByOption of paymentByOptions" [value]="paymentByOption">
                    {{TaskEnumHelper.getTaskPaymentByTitle(paymentByOption)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-checkbox [disabled]="isDisabled" [(ngModel)]="priceFilter"
                            (ngModelChange)="priceFilterChanged(priceFilter)"
                            matTooltip="Show only tasks with expenses"
                            [ngModelOptions]="{standalone: true}">
              </mat-checkbox>

              <button [disabled]="isDisabled" mat-icon-button (click)="clearFilters()">
                <mat-icon>close</mat-icon>
              </button>
              <button mat-icon-button (click)="toggleEdit()">
                <mat-icon *ngIf="isDisabled">edit</mat-icon>
                <mat-icon *ngIf="!isDisabled">done</mat-icon>
              </button>
            </div>


            <hr id="line">
            <div style="overflow-x: scroll;">
              <sd-tasks-list-component style="width: 100%; min-width: 800px; font-family:'Lato', sans-serif; !important"
                                       (sortByUpdate)="sortByChanged($event)"
                                       (sortOrderUpdate)="sortOrderChanged($event)"
                                       [tasks]="filteredTasks"
                                       [showExpenses]="showHouseKeeperExpenses"
                                       [listingIds]="selectedListingIds"
                                       [forReport]="true">
              </sd-tasks-list-component>
            </div>
          </div>
        </div>
      </div>
    </div>

  `,
  styles: [`
    .width-1 {
      width: 28%;
    }

    .width-2 {
      width: 100%;
    }

    .width-3 {
      width: 75%;
    }

    #line {
      border: none;
      width: 100%;
      height: 5px;
      /* Set the hr color */
      margin-top: 5px;
      margin-bottom: 10px;
      color: lightgrey; /* old IE */
      background-color: lightgrey; /* Modern Browsers */
    }
  `]
})

export class TasksReportComponent implements OnInit, OnDestroy, AfterViewInit {

  isHouseKeeper: boolean = false;
  isEmployee: boolean = false;
  tasksLoading = false;
  isDownloading = false;
  isDisabled = true;
  allListingIds = [];
  assignees = [];
  filteredAssignees = [];
  allAdmins = [];
  allTasks = [];
  filteredTasks = [];
  selectedAssigneeIds = [];
  selectedCreatorIds = [];
  selectedPaymentBy = [];
  priceFilter = false;
  employeeId: number;
  showHouseKeeperExpenses: boolean = true;
  start: number = 0;
  listingId: number = null;


  selectedTypeFilter;
  selectedCategoryFilters;
  selectedListingIds;
  selectedStatusFilters;
  selectedOffset = new FormControl("due_date");
  startDate = removeDaysFromDate("", 7);
  endDate = getDateObj();
  minDate = addDays(this.startDate, 1);
  sortOrder = SortOrder.DSC;
  sortBy = TaskSortBy.DUE_ON;

  // Helpers
  TaskEnumHelper = TaskEnumHelper;
  UserModelUtil = UserModelUtil;
  CommonUtil = CommonUtil;

  // Enum
  categories = CommonUtil.getPropsOfEnum<TaskCategory>(TaskCategory);
  types = CommonUtil.getPropsOfEnum<TaskTypeForReport>(TaskTypeForReport);
  paymentByOptions = CommonUtil.getPropsOfEnum(TaskPaymentBy);
  statusTypes: string[] = CommonUtil.getPropsOfEnum<TaskStatus>(TaskStatus);


  filteredListings: number[] = [];
  listingFilter: FormControl = new FormControl();
  assigneeFilter: FormControl = new FormControl();


  private destroyed$ = new Subject();
  private onDateChanged = new BehaviorSubject<any>(true);

  constructor(private listingRepo: ListingRepository,
              private optionsRepo: OptionsRepository,
              private activatedRoute: ActivatedRoute,
              private userRepo: UserRepository,
              private taskRepo: TaskRepository,
              private router: Router,
              private route: ActivatedRoute) {

    this.listingRepo.getAllActivatedListingIds().subscribe((listingIds) => {
      this.allListingIds = listingIds;
      this.filteredListings = listingIds;

    });

    this.setupAssignees();
    this.pullValuesFromQuery();
    this.pullValuesFromStore();
    this.setupAdmins();
  }


  ngOnInit(): void {

    this.userRepo.getUser().pipe(
      takeUntil(this.destroyed$))
      .subscribe(user => {
        this.isHouseKeeper = CommonUtil.isHouseKeeper(user);

        if (this.CommonUtil.isVendor(user) && (!!user.managementContact.data.creator.data.managementContact && user.managementContact.data.creator.data.managementContact.data.category === "housekeeper")) {
          this.showHouseKeeperExpenses = user.managementContact.data.creator.data.show_expenses;
          this.employeeId = user.id;
          this.isEmployee = true;
        }
        if (this.isHouseKeeper || this.isEmployee) {
          this.statusTypes = CommonUtil.getPropsOfEnum<HouseKeeperTaskStatus>(HouseKeeperTaskStatus);
        }

      });

    this.route.params.subscribe(params => {
      if (params["listing_id"]) {
        this.listingId = +params["listing_id"];
      }
    });

    this.listingFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged(),).subscribe((value) => {

      if (value) {
        this.filteredListings = this.allListingIds.filter(l => this.listingRepo.getListingTitle(l).toLowerCase().includes(value));
      } else {
        this.filteredListings = this.allListingIds;
      }

      if (this.filteredListings.length === 0) {
        this.filteredListings = this.allListingIds;
      }


    });

    this.assigneeFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged(),).subscribe((value) => {

      if (value) {
        this.filteredAssignees = this.assignees.filter(l => UserModelUtil.getFullName(l).toLowerCase().includes(value));
      } else {
        this.filteredAssignees = this.assignees;
      }

      if (this.filteredAssignees.length === 0) {
        this.filteredAssignees = this.assignees;
      }

    });

    this.selectedOffset.valueChanges.subscribe(v => {
      this.setupTasks();
    });

    window.addEventListener("scroll", this.setUpScrolling.bind(this), true);

  }

  onMatSelectClose() {
    this.filteredListings = this.allListingIds;
    this.filteredAssignees = this.assignees;
  }

  ngAfterViewInit() {
    this.refreshRoute();

    const sortOrder$ = this.taskRepo.getTaskSortOrder();
    const sortBy$ = this.taskRepo.getTaskSortBy();

    sortOrder$.subscribe(value => {
      this.sortOrder = value;
    });

    sortBy$.subscribe(value => {
      this.sortBy = value;
    });

    const filterChanged$ = observableCombineLatest(
      sortOrder$,
      sortBy$,
      (sortOrder, sortBy) => {
        return {
          sortOrder: sortOrder,
          sortBy: sortBy,
        };
      }
    );

    // Skipping the first load
    // Since we want to listen to filter changes only
    filterChanged$.pipe(debounceTime(100), takeUntil(this.destroyed$),).subscribe(
      (data) => {
        this.refreshRoute();
      }
    );

  }

  refreshRoute() {
    const params = {
      start: dateToDateString(this.startDate),
      end: dateToDateString(this.endDate),
      sort_order: this.sortOrder,
      sort_by: this.sortBy,
      type: this.selectedTypeFilter,
      price_filter: this.priceFilter
    };

    if (this.selectedCategoryFilters && this.selectedCategoryFilters.length > 0) {
      params["category"] = this.selectedCategoryFilters.join(",");
    }

    if (this.selectedStatusFilters && this.selectedStatusFilters.length > 0) {
      params["status"] = this.selectedStatusFilters.join(",");
    }

    if (this.selectedAssigneeIds && this.selectedAssigneeIds.length > 0) {
      params["assignee_ids"] = this.selectedAssigneeIds.join(",");
    }

    if (this.selectedListingIds && this.selectedListingIds.length > 0) {
      params["listing_ids"] = this.selectedListingIds.join(",");
    }

    if (this.selectedPaymentBy && this.selectedPaymentBy.length > 0) {
      params["payment_by"] = this.selectedPaymentBy.join(",");
    }

    if (this.selectedCreatorIds && this.selectedCreatorIds.length > 0) {
      params["creator_ids"] = this.selectedCreatorIds.join(",");
    }

    this.router.navigate(["/tasks-report/"], {
      queryParams: params
    });
  }

  /**
   * Helpers
   */
  getListingTitleFromId(id: number): string {
    return this.listingRepo.getListingTitle(id);
  }

  /**
   * (change) listeners
   */
  taskTypeChanged(type) {
    this.taskRepo.setTaskFilterType(type);
  }

  taskCategoryChanged(category) {
    this.taskRepo.setTaskFilterCategory(category);
  }

  taskAssigneeChanged(assignees: number[]) {
    this.taskRepo.setTaskFilterAssigneeIds(assignees);
  }

  taskCreatedByChanged(assignees: number[]) {
    this.taskRepo.setTaskFilterCreatedBy(assignees);
  }

  whoWillPayChanged(assignee: any) {
    this.taskRepo.setTaskFilterWhoWillPay(assignee);
  }

  taskListingsChanged(listingIds: number[]) {
    this.taskRepo.setTaskFilterListingIds(listingIds);
  }

  sortByChanged(sortBy: TaskSortBy) {
    this.taskRepo.setTaskSortBy(sortBy);
  }

  sortOrderChanged(sortOrder: SortOrder) {
    this.taskRepo.setTaskSortOrder(sortOrder);
  }

  startDateChanged($event) {
    this.minDate = addDaysToDate($event, 1);
    this.onDateChanged.next(true);
  }

  endDateChanged() {
    this.onDateChanged.next(true);
  }

  taskStatusChanged(status: TaskStatus[]) {
    this.taskRepo.setTaskStatus(status);
  }

  priceFilterChanged(priceFilter) {
    this.taskRepo.setTaskFilterPrice(priceFilter);
  }

  download(format) {
    this.isDownloading = true;
    const data = {
      start: dateToDateString(this.startDate),
      end: dateToDateString(this.endDate),
      type: this.selectedTypeFilter,
      offset: this.selectedOffset.value,
      category: this.selectedCategoryFilters && this.selectedCategoryFilters.length > 0 ? this.selectedCategoryFilters.join(",") : null,
      assignee_ids: this.selectedAssigneeIds && this.selectedAssigneeIds.length > 0 ? this.selectedAssigneeIds.join(",") : null,
      listing_ids: this.selectedListingIds && this.selectedListingIds.length > 0 ? this.selectedListingIds.join(",") : null,
      status: this.selectedStatusFilters && this.selectedStatusFilters.length > 0 ? this.selectedStatusFilters.join(",") : null,
      payment_by: this.selectedPaymentBy && this.selectedPaymentBy.length > 0 ? this.selectedPaymentBy.join(",") : null,
      price_filter: this.priceFilter ? 1 : 0,
      sort_by: this.sortBy,
      sort_order: this.sortOrder,
      creator_ids: this.selectedCreatorIds && this.selectedCreatorIds.length > 0 ? this.selectedCreatorIds.join(",") : null,
      format: format,
      employee_id: this.employeeId,
    };

    for (const key in data) {
      if (!data[key] || data[key].length === 0) {
        delete data[key];
      }
    }

    this.taskRepo.downloadReport(data).subscribe(tasks => {
      this.isDownloading = false;
    }, err => this.isDownloading = false);
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", null, true);
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Button Click Actions
   */
  onSelectAll() {
    this.selectedListingIds = this.allListingIds;
  }

  onSelectNone() {
    this.selectedListingIds = [];
  }

  selectAllPaymentBy() {
    this.selectedPaymentBy = [];
    Object.keys(this.paymentByOptions).forEach(key => {
      this.selectedPaymentBy.push(this.paymentByOptions[key]);
    });
    this.whoWillPayChanged(this.selectedPaymentBy);
  }

  deselectAllPaymentBy() {
    this.selectedPaymentBy = [];
    this.whoWillPayChanged(this.selectedPaymentBy);
  }

  selectAllCreatedBy() {
    this.selectedCreatorIds = [];
    this.allAdmins.forEach(admin => {
      this.selectedCreatorIds.push(admin.id);
    });
    this.taskCreatedByChanged(this.selectedCreatorIds);
  }

  deselectAllCreatedBy() {
    this.selectedCreatorIds = [];
    this.taskCreatedByChanged(this.selectedCreatorIds);
  }

  selectAllAssignees() {
    this.selectedAssigneeIds = [];
    this.assignees.forEach(assignee => {
      this.selectedAssigneeIds.push(assignee.id);
    });
    this.taskAssigneeChanged(this.selectedAssigneeIds);
  }

  deselectAllAssignees() {
    this.selectedAssigneeIds = [];
    this.taskAssigneeChanged(this.selectedAssigneeIds);
  }

  selectAllCategory() {
    this.selectedCategoryFilters = [];
    Object.keys(TaskCategory).forEach(key => {
      this.selectedCategoryFilters.push(TaskCategory[key]);
    });
    this.taskCategoryChanged(this.selectedCategoryFilters);
  }

  deselectAllCategory() {
    this.selectedCategoryFilters = [];
    this.taskCategoryChanged(this.selectedCategoryFilters);
  }

  selectAllStatus() {
    this.selectedStatusFilters = [];
    Object.keys(TaskStatus).forEach(key => {
      this.selectedStatusFilters.push(TaskStatus[key]);
    });
    this.taskStatusChanged(this.selectedStatusFilters);
  }

  deselectAllStatus() {
    this.selectedStatusFilters = [];
    this.taskStatusChanged(this.selectedStatusFilters);
  }

  clearFilters() {
    this.selectedPaymentBy = [];
    this.selectedCreatorIds = [];
    this.priceFilter = false;
    this.selectedTypeFilter = TaskTypeForReport.ALL;
    this.selectedAssigneeIds = [];
    this.selectedListingIds = [];
    this.selectedCategoryFilters = [];
    this.selectedStatusFilters = [];
    this.taskAssigneeChanged([]);
    this.taskCategoryChanged([]);
    this.taskCreatedByChanged([]);
    this.whoWillPayChanged([]);
    this.taskListingsChanged([]);
    this.taskStatusChanged([]);
    this.priceFilterChanged(false);
  }

  toggleEdit() {
    this.isDisabled = !this.isDisabled;
    if (this.isDisabled) {
      this.refreshRoute();
    }
  }

  redirectToTasks() {
    if (this.listingId) {
      this.router.navigate(["/listings/" + this.listingId + "/tasks", {id: this.listingId}]);
    } else {
      this.router.navigate(["/tasks"]);
    }
  }

  setUpScrolling() {
    if (this.filteredTasks) {
      if ((document.body.scrollHeight - document.body.offsetHeight) <= (Math.round(window.scrollY) + 1500) && this.allTasks.length > this.filteredTasks.length) {
        console.log("bottom", this.start, this.filteredTasks.length, this.allTasks.length);
        this.start += 50;

        if (this.allTasks.length - this.filteredTasks.length > 50) {
          this.filteredTasks = [...this.filteredTasks, ...this.allTasks.slice(this.start, (this.start + 50))];
        } else {
          this.filteredTasks = this.allTasks;
        }
      }
    }
  }

  /**
   * Private Methods called from Constructor
   */


  private setupAssignees() {
    this.optionsRepo.getTaskAssignees().pipe(takeUntil(this.destroyed$))
      .subscribe((assignees) => {
        this.assignees = assignees;
        this.filteredAssignees = assignees;
        console.log("assignees", this.assignees);
      });
  }

  private setupAdmins() {
    this.optionsRepo.getAdmins(true).subscribe(value => {
      this.allAdmins = value;
    });
  }

  private pullValuesFromQuery() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {

      this.taskTypeChanged(TaskTypeForReport.ALL);

      if (params.category) {
        const categories = params.category.split(",");
        // TODO Validate
        this.taskCategoryChanged(categories);
      }

      if (params.status) {
        const statuses = params.status.split(",");
        // TODO Validate
        this.taskStatusChanged(statuses);
      }

      if (params.sort_order && CommonUtil.getIsEnum(SortOrder, params.sort_order)) {
        this.sortOrderChanged(params.sort_order);
      }

      if (params.sort_by && CommonUtil.getIsEnum(TaskSortBy, params.sort_by)) {
        this.sortByChanged(params.sort_by);
      }

      if (params.start) {
        this.startDate = getDateObj(params.start);
      }

      if (params.end) {
        this.endDate = getDateObj(params.endDate);
      }

      if (!isNullOrUndefined(params.assignee_ids)) {
        const assigneeIds: number[] = params.assignee_ids.split(",").map(id => +id);
        console.log("assignee", +assigneeIds);
        if (assigneeIds.length > 0) {
          this.taskAssigneeChanged(assigneeIds);
        }
      }

      if (params.listing_ids) {
        const paramListingIds = params.listing_ids.split(",").map(i => +i);
        const intersectIds = _.intersection(paramListingIds, this.allListingIds);

        if (intersectIds && intersectIds instanceof Array && intersectIds.length > 0) {
          this.taskListingsChanged(intersectIds);
        }
      }


      if (params.payment_by) {
        const paymentBy = params.payment_by.split(",");
        this.whoWillPayChanged(paymentBy);
      }

      if (params.creator_ids) {
        const creatorIds = params.creator_ids.split(",");
        this.taskCreatedByChanged(creatorIds);
      }

      if (!isNullOrUndefined(params.price_filter)) {
        this.priceFilterChanged(params.price_filter);
      }

    });
    this.activatedRoute.queryParams.pipe(skip(1)).subscribe(params => {
      this.setupTasks();
    });
  }

  private pullValuesFromStore() {
    // Component will only trigger these changes so no need to update.
    this.taskRepo.getTaskFilterType().pipe(take(1)).subscribe(t => this.selectedTypeFilter = t);
    this.taskRepo.getTaskFilterCategory().pipe(take(1)).subscribe(c => this.selectedCategoryFilters = c);
    this.taskRepo.getTaskFilterAssigneeIds().pipe(take(1)).subscribe(a => this.selectedAssigneeIds = a);
    this.taskRepo.getTaskFilterListingIds().pipe(take(1)).subscribe(l => this.selectedListingIds = l);
    this.taskRepo.getTaskFilterWhoWillPay().pipe(takeUntil(this.destroyed$)).subscribe(l => this.selectedPaymentBy = l);
    this.taskRepo.getTaskFilterCreatedBy().pipe(takeUntil(this.destroyed$)).subscribe(l => this.selectedCreatorIds = l);
    this.taskRepo.getTaskFilterStatus().pipe(takeUntil(this.destroyed$)).subscribe(l => this.selectedStatusFilters = l);
    this.taskRepo.getTaskFilterPrice().pipe(takeUntil(this.destroyed$)).subscribe(l => this.priceFilter = l);

  }

  private setupTasks() {
    this.tasksLoading = true;
    const data = {
      start: dateToDateString(this.startDate),
      end: dateToDateString(this.endDate),
      type: this.selectedTypeFilter,
      offset: this.selectedOffset.value,
      category: this.selectedCategoryFilters && this.selectedCategoryFilters.length > 0 ? this.selectedCategoryFilters.join(",") : null,
      assignee_ids: this.selectedAssigneeIds && this.selectedAssigneeIds.length > 0 ? this.selectedAssigneeIds.join(",") : null,
      listing_ids: this.selectedListingIds && this.selectedListingIds.length > 0 ? this.selectedListingIds.join(",") : null,
      status: this.selectedStatusFilters && this.selectedStatusFilters.length > 0 ? this.selectedStatusFilters.join(",") : null,
      payment_by: this.selectedPaymentBy && this.selectedPaymentBy.length > 0 ? this.selectedPaymentBy.join(",") : null,
      sort_by: this.sortBy,
      sort_order: this.sortOrder,
      creator_ids: this.selectedCreatorIds && this.selectedCreatorIds.length > 0 ? this.selectedCreatorIds.join(",") : null,
      price_filter: this.priceFilter ? 1 : 0,
      employee_id: this.employeeId
    };

    for (const key in data) {
      if (!data[key] || data[key].length === 0) {
        delete data[key];
      }
    }
    this.taskRepo.getTasksForReportPreview(data).subscribe(tasks => {
      this.tasksLoading = false;
      this.allTasks = tasks;
      this.filteredTasks = [];
      this.start = 0;
      this.filteredTasks = [...this.allTasks.slice(this.start, (this.start + 50))];
    }, err => this.tasksLoading = false);
  }
}
