import { Action } from "../../../actions/action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import {Comment} from "../../../models/new/tasks/comment";
import { TaskCommentActionTypes } from "../../../actions/new/tasks/comment";
import * as _ from "lodash";
import { cannedResponseAdapter } from "../automation/canned-response";
import { CannedResponseFull } from "../../../models/new/automation/canned-response-full.model";
import { MessageActionTypes } from "../../../actions/new/inbox/message";
import { messageAdapter, MessageState } from "../inbox/message";
import { Message } from "../../../models/new/inbox/message";

export interface CommentState extends EntityState<Comment> {
  // isLoading:boolean,
  // isLoaded:boolean,
  //
  // fullLoadingIds: number[];
  // fullLoadedIds: number[];
}

export const commentAdapter: EntityAdapter<Comment> = createEntityAdapter<Comment>({
  selectId: (comment: Comment) => comment.id
});

export const initialState: CommentState = commentAdapter.getInitialState({
  // isLoading:false,
  // isLoaded:false,
  //
  // fullLoadingIds: [],
  // fullLoadedIds: [],
});

export function commentReducer(state: CommentState = initialState, action: Action): CommentState {
  switch ( action.type ) {
    //   case TaskCommentActionTypes.INDEX_REQUEST: {
    //     return {
    //       ...initialState,
    //       isLoading: true,
    //       isLoaded: false
    //     }
    //   }
    //
    //   case TaskCommentActionTypes.INDEX_SUCCESS: {
    //     const comments = action.payload as Comment[];
    //     return commentAdapter.addAll(comments, {
    //       ...state,
    //       isLoading: false,
    //       isLoaded: true
    //     });
    //   }
    //
    //   case TaskCommentActionTypes.CREATE_REQUEST: {
    //     return {
    //       ...state,
    //       isLoading: true,
    //       isLoaded: false
    //     }
    //   }
    //
    //   case TaskCommentActionTypes.CREATE_SUCCESS: {
    //     const comment = action.payload as Comment;
    //     return commentAdapter.addOne(comment, {
    //       ...state,
    //       isLoading: false,
    //       isLoaded: true
    //     });
    //   }
    //
    //   case TaskCommentActionTypes.UPDATE_REQUEST: {
    //     return {
    //       ...state,
    //     }
    //   }
    //
    //
    //   case TaskCommentActionTypes.DELETE_REQUEST: {
    //     return {
    //       ...state,
    //       isLoading: true,
    //       isLoaded: false
    //     }
    //   }
    //
    //   case TaskCommentActionTypes.DELETE_SUCCESS: {
    //     const id = action.payload;
    //
    //     let fullyLoadingIds = state.fullLoadingIds;
    //     const loadingIndex = _.indexOf(fullyLoadingIds, id);
    //
    //     if (loadingIndex !== -1) {
    //       fullyLoadingIds = _.remove(fullyLoadingIds, id);
    //     }
    //
    //     let fullyLoadedIds = state.fullLoadedIds;
    //     const loadedIndex = _.indexOf(fullyLoadedIds, id);
    //
    //     if (loadedIndex !== -1) {
    //       fullyLoadedIds = _.remove(fullyLoadedIds, id);
    //     }
    //
    //     return commentAdapter.removeOne(id, {
    //       ...state,
    //       fullyLoadingIds,
    //       fullyLoadedIds
    //     });
    //
    //   }
    //
    //   default:
    //     return state;
    // }
    case TaskCommentActionTypes.INDEX_REQUEST: {
      return state;
    }

    case TaskCommentActionTypes.INDEX_SUCCESS: {
      const payload = action.payload as { comments: Comment[], taskId: number };
      console.log("payload", payload);
      return commentAdapter.addMany(payload.comments, state);
    }

    case TaskCommentActionTypes.CREATE_SUCCESS: {
      const payload = action.payload as { comment: Comment, taskId: number };

      return commentAdapter.addOne(payload.comment, state);

    }

    case TaskCommentActionTypes.UPDATE_SUCCESS: {
      const comment = action.payload.comment as Comment;
      return commentAdapter.updateOne({
        id: comment.id,
        changes: comment
      }, state);
    }


    default: {
      return {
        ...state
      };
    }
  }
}

// export const _getIsCommentLoading = (state: CommentState) => state.isLoading;
// export const _getIsCommentLoaded = (state: CommentState) => state.isLoaded;
//
// export const _getIsFullCommentLoading = (state: CommentState, commentId: number) => {
//   return state.fullLoadingIds.indexOf(commentId) !== -1;
// };
// export const _getIsFullCommentLoaded = (state: CommentState, commentId: number) => {
//   return state.fullLoadedIds.indexOf(commentId) !== -1;
// };
export const _getCommentEntities = (state: CommentState) => state.entities;
