import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { CreateReservationPopupComponent } from "./components/create-reservation-popup";
import { NewReservationPopupComponent } from "./components/new-reservation-popup";

const COMPONENTS = [];

const ENTRY_COMPONENTS = [
  CreateReservationPopupComponent,
  NewReservationPopupComponent
];

@NgModule({
  imports: [
    SharedModule,
  ],
  exports: [
    ...COMPONENTS,
    ...ENTRY_COMPONENTS,
  ],
  declarations: [
    ...COMPONENTS,
    ...ENTRY_COMPONENTS,
  ],
  entryComponents: [
    ...ENTRY_COMPONENTS
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReservationsSharedModule {
}
