import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { __HTTPResponseType } from "../enums/common.enum";

import { ApiService } from "./api.service";

@Injectable()
export class AWSService {

  constructor(private httpClient: HttpClient,
              private apiService: ApiService) {
  }

  uploadToAWS(preSignedUrl: string, file: any): Observable<HttpEvent<any>> {
    console.log("[SEMPER] file", preSignedUrl, file);
    const req = new HttpRequest("PUT", preSignedUrl, file, {
      reportProgress: true,
      responseType: __HTTPResponseType.TEXT,
    });
    return this.httpClient.request(req);
  }

  getSignedUrl(fileName: string): Observable<string> {
    return this.apiService.get<{signed_url: string}>("/temp-signed-url", true, {filename: fileName})
      .pipe(map(r => r.signed_url));
  }


}
