import { AfterViewInit, Component, OnDestroy, OnInit, } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { isToday } from "date-fns";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, takeWhile } from "rxjs/operators";

import { MultiCalendarDeleteMonthSuccess } from "../../actions/multi-calendar";
import { MultiCalendar } from "../../models/multi-calendar";
import { ListingCompact } from "../../models/new/listing/listing-compact.model";
import { ListingModelUtil } from "../../models/utils/listing-model.util";
import { NewReservationPopupComponent } from "../../modules/reservations/components/new-reservation-popup";
import { CreateProspectPopupComponent } from "../../modules/shared/components/create-prospect-popup";
import { getAppIsMenuHidden, getIsMultiCalendarLoaded, getIsMultiCalendarLoading, State } from "../../reducers/index";
import { ListingRepository } from "../../repository/listing.repository";
import { MultiCalendarRepository } from "../../repository/multi-calendar.repository";
import { OptionsRepository } from "../../repository/options.repository";
import { UserRepository } from "../../repository/user-repository";
import { AppService } from "../../services/app.service";
import { StayDuvetService } from "../../services/stayduvet";
import ArrayUtils from "../../utils/array";
import { getDateObj } from "../../utils/calendar-utils";
import { CommonUtil } from "../../utils/common.util";
import { months } from "../../utils/constants";
import DateUtils from "../../utils/date";
import ObjectUtils from "../../utils/object";

@Component({
  selector: "sd-multi-calendar-container",
  template: `

    <sd-owner-main-layout style="overflow-y: hidden">
      <div *ngIf="calendarLoaded" fxLayout="column" class="requiredHeight" fxFlex="100%" fxLayoutGap.lt-sm="0px"
           fxLayoutAlign.lt-sm="center center" fxLayoutGap="20px"
           class="main-container">
        <span style="font-size: 25px; font-weight: bolder">Multi Calendar</span>
        <form *ngIf="!isHouseKeeper" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start start"
              [formGroup]="formGroup"
              fxLayoutAlign.lt-sm="center center" fxLayoutGap="20px" fxLayoutGap.lt-sm="5px" style="width: 94%;">
          <div fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutGap="20px" style="height:45px;">
              <mat-form-field [color]="'accent'" dividerColor="accent" class="width-120">
                <input matInput [matDatepicker]="checkin"
                       placeholder="Check in"
                       [(ngModel)]="startDate"
                       [ngModelOptions]="{standalone:true}"
                       (ngModelChange)="checkDateValidity()">
                <mat-datepicker-toggle matSuffix [for]="checkin"></mat-datepicker-toggle>
                <mat-datepicker #checkin></mat-datepicker>
              </mat-form-field>

              <mat-form-field [color]="'accent'" dividerColor="accent" class="width-120">

                <input matInput [matDatepicker]="checkout"
                       placeholder="Check out"
                       [(ngModel)]="endDate"
                       [ngModelOptions]="{standalone:true}"
                       (ngModelChange)="checkDateValidity()"
                       [min]="startDate">
                <mat-datepicker-toggle matSuffix [for]="checkout"></mat-datepicker-toggle>
                <mat-datepicker #checkout></mat-datepicker>
              </mat-form-field>
            </div>
            <button mat-raised-button color="primary" style="width:fit-content;" (click)="thisWeek()">
              This Week
            </button>
          </div>

          <mat-form-field [color]="'accent'" dividerColor="accent" class="width-120">
            <input formControlName='number_of_guests' matInput type="number" min="1"
                   placeholder="No. of guests?">
            <mat-error> Required</mat-error>
          </mat-form-field>

          <mat-form-field [color]="'accent'" dividerColor="accent" class="width-120">
            <input formControlName='number_of_pets' matInput type="number" min="0"
                   placeholder="No. of pets?">
            <mat-error> Required</mat-error>
          </mat-form-field>

          <mat-form-field [color]="'accent'" dividerColor="accent" class="width-120">
            <input formControlName='number_of_bedrooms' matInput type="number" min="0"
                   placeholder="No. of Bedrooms?">
            <mat-error> Required</mat-error>
          </mat-form-field>

          <div fxFlexAlign="center" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">

            <button mat-icon-button type="button" color="accent"
                    [disabled]="!formGroup.valid"
                    (click)="getAvailableListings()">
              <mat-icon>check</mat-icon>
            </button>
            <button mat-icon-button type="button" color="accent"
                    (click)="resetFilters()">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <span fxFlex="1 1 auto"></span>
          <div fxLayout="row" fxLayoutGap="10px" style="padding:5px">
            <button
              mat-raised-button
              [disabled]="selectedListings.length === 0"
              (click)="formGroup.valid && openCreateProspectPopup(); formGroup.markAsTouched()"
              style="height: 50px !important;"
              class="responsiveWidth"
              type="submit"
              color="primary"><span>Create Prospect</span>
            </button>
            <button
              mat-raised-button
              [disabled]="selectedListings.length !== 1"
              (click)="formGroup.valid && openNewReservationPopup(); formGroup.markAsTouched()"
              style="height: 50px !important;"
              class="responsiveWidth"
              type="submit"
              color="primary"><span>New Reservation</span>
            </button>
          </div>
        </form>
        <hr>
        <div fxLayout="column"
             style="width: 95%"
             class="calendar-header"
             fxLayoutAlign="center center"
             fxLayoutGap="2px">
          <div fxFlexAlign.gt-sm="end" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center"
               fxLayoutGap="10px" style="max-width: 100vw;">

            <button mat-icon-button (click)="refresh()">
              <mat-icon>refresh</mat-icon>
            </button>

            <sd-select class="width-35" placeholder="Month"
                       [control]="currentMonthControl"
                       [options]="monthsOptions"
                       (selectionChanged)="updateCalendarData()"></sd-select>

            <sd-select class="width-35" placeholder="Year"
                       [control]="currentYearControl"
                       [options]="years"
                       (selectionChanged)="updateCalendarData()"></sd-select>

            <sd-select *ngIf="!isHouseKeeper" class="width-35"
                       [multiple]="true" placeholder="Filter City"
                       [control]="selectedLocationFilterControl"
                       [options]="locationsOptions"
                       (selectionChanged)="updateFilteredListings()"></sd-select>

            <sd-select *ngIf="!isHouseKeeper" class="width-35"
                       [multiple]="true" placeholder="Filter By Tag"
                       [control]="selectedTagFilterControl"
                       [options]="tagsOptions"
                       (selectionChanged)="updateFilteredListings()"></sd-select>

            <div fxLayout="row" fxLayoutAlign="end none" fxLayoutAlign.lt-sm="center center">
              <div>
                <button mat-raised-button color="accent" [matMenuTriggerFor]="sortMenu"
                        class="my-menu responsiveWidth">
                  Sort By: {{selectedSortingBy.title}}
                  <mat-icon>{{ selectedSortingBy.order === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                  </mat-icon>
                </button>
              </div>
            </div>
            <mat-menu #sortMenu="matMenu">
              <button mat-menu-item color="accent" *ngFor="let item of sortingByTypes"
                      (click)="selectedSortByChanged(item)">
                {{item.title}}
                <mat-icon>{{ item.order === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up' }}</mat-icon>
              </button>
            </mat-menu>
          </div>

        </div>


        <div id="fixed-scroll-row" *ngIf="calendarLoaded && filteredListings.length > 0">
          <div class="scroll-row" id="scroll-row">
            <span *ngFor="let iterator of currentDatesOnDisplay"
                  [ngClass]="{'selected-cell':isDateSelected(iterator),'today-cell': isDateToday(iterator)}">
                {{ iterator | dateFix | date:'dd'}}
              <br>
              {{ iterator | dateFix | date:'EEE'}}
            </span>
          </div>
        </div>

        <div fxLayout="column" *ngIf="calendarLoaded && filteredListings.length > 0">
          <sd-multi-calendar
            [isHouseKeeper]="isHouseKeeper"
            [listings]="filteredListings"
            [_calendarData]="calendarData"
            [currentMonth]="currentMonth"
            [currentYear]="currentYear"
            [_startDate]="startDate"
            (onScrollXChanged)="scrollXChange($event)"
            (onSelectChanged)="onselectChanged($event)"
            [daysInMonth]="daysInCurrentMonth">
          </sd-multi-calendar>
          <br>
          <mat-spinner *ngIf="isMoreLoading" fxFlexAlign="center" color="accent" [diameter]="50"
                       [strokeWidth]="5"></mat-spinner>
          <br>
        </div>
        <div *ngIf="calendarLoaded && showFiltered && filteredListings.length === 0" style="width: 100%" fxFlex="100%"
             fxLayoutAlign="center center">
          <span>No Available Listings Found For Selected Date Range !</span>
        </div>
      </div>
      <sd-center-spinner *ngIf="calendarLoading"></sd-center-spinner>
    </sd-owner-main-layout>
  `,
  styles: [`

    #qwer {
      max-height: 50px !important;
    }

    #spinner {
      position: fixed;
      top: 45%;
      right: 40%
    }

    .select-button {
      padding: 6px;
      text-align: left;
      font-size: 17px;
      padding-left: 10px;
      font-weight: bolder;
      background-color: yellow;
    }

    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #ccc;
      margin: 1em 0;
      padding: 0;
    }

    #dropdown {
      color: #00a3cc;
      cursor: pointer;
      font-size: 18px;
      margin-top: 10px;
      font-weight: bolder;

    }

    .main-container {
      padding-left: 30px;
      margin-top: 90px;
    }

    .today-cell {
      background-color: #13304b;
    }

    .width-120 {
      width: 120px;
    }

    .width-35 {
      width: 130px;
    }

    #fixed-scroll-row {
      height: 50px;
      background-color: #B0B5B7;
      color: #FFF;
      /*padding-top: 5px;*/
      margin-right: 60px;
      z-index: 1099;
    }

    .scroll-row {
      margin-left: 255px;
      overflow-x: scroll;
      white-space: nowrap;
      display: flex;
      flex-flow: row nowrap;
      width: auto;
      box-sizing: border-box;
      text-align: center;
    }

    .scroll-row::-webkit-scrollbar {
      display: none;
    }

    .scroll-row span {
      min-width: 100px;
      height: 50px;
    }

    .selected-cell {
      background-color: #f0a340;
    }

    @media only screen and (max-width: 600px) {

      [class*="width-"] {
        width: 95%;
      }

      .responsiveAlign {
        text-align: center;
      }

      .responsiveWidth {
        width: 95%;
      }
    }
  `]
})
export class MultiCalendarContainerComponent implements OnInit, OnDestroy, AfterViewInit {

  operators = ["AND", "OR"];
  listings: ListingCompact[] = [];
  filteredListings: ListingCompact[] = [];
  rawListings: ListingCompact[] = [];
  filterListingIds: number[] = [];
  locations: string[] = [];
  locationsOptions: {title: string, value: any}[];
  selectedLocationFilter: string[];
  selectedLocationFilterControl: FormControl = new FormControl([]);
  tags = [];
  tagsOptions: {title: string, value: any}[];
  selectedTagFilter = [];
  selectedTagFilterControl: FormControl = new FormControl([]);
  isPrevDisabled = true;
  isNextDisabled = false;
  isHouseKeeper = false;
  itemsPerPage = 50;
  currentPage = -1;
  start = 0;
  offset = 30;
  loading$: Subscription;
  loaded$: Subscription;
  calendar$: Subscription;
  end = 0;
  total: number;
  isMoreLoading = true;
  sortingByTypes = [
    {
      title: "Title Asc.",
      slug: "title_asc",
      order: "asc",
      param: "title"
    },
    {
      title: "Title Dsc.",
      slug: "title_dsc",
      order: "dsc",
      param: "title"
    },
    {
      title: "City Asc.",
      slug: "city_asc",
      order: "asc",
      param: "city"
    },
    {
      title: "City Dsc.",
      slug: "city_dsc",
      order: "dsc",
      param: "city"
    }
  ];
  selectedSortingBy = this.sortingByTypes[0];
  formGroup: FormGroup;
  startDate: Date;
  endDate: Date;
  guests: FormControl;
  pets: FormControl;
  bedrooms: FormControl;
  selectedListings: ListingCompact[] = [];
  calendarLoading = true;
  calendarLoaded = false;
  calendarData: MultiCalendar = {};
  calendarWidth = 18;
  endDateToShow = this.calendarWidth;
  startDateToShow = 1;
  currentDatesOnDisplay: Date[];
  daysInCurrentMonth: number;
  today;
  currentMonth;
  currentMonthControl: FormControl = new FormControl(null);
  currentYear;
  currentYearControl: FormControl = new FormControl(null);
  months = months;
  monthsOptions: {title: string, value: any}[];
  scrollX;
  scrollY;
  years: {title: string, value: any}[] = [];
  showFiltered = false;
  private dialogRef: MatDialogRef<any>;
  private isAlive = true;

  isLoading: boolean;
  isLoaded: boolean;
  isSideBarHidden = false;

  constructor(private store: Store<State>,
              private dialog: MatDialog,
              private listingRepo: ListingRepository,
              private optionRepo: OptionsRepository,
              private userRepo: UserRepository,
              private appService: AppService,
              private service: StayDuvetService,
              private multicalendarRepo: MultiCalendarRepository) {
    const currentDate = getDateObj();
    this.currentMonth = currentDate.getMonth() + 1;
    this.currentMonthControl.setValue(this.currentMonth);
    this.currentYear = currentDate.getFullYear();
    this.currentYearControl.setValue(this.currentYear);
    this.today = currentDate;

    this.updateCalendarData();

    this.startDate = getDateObj();
    this.endDate = DateUtils.addDays(getDateObj(), 1);
    this.guests = new FormControl(0, [Validators.required]);
    this.pets = new FormControl(0, [Validators.required]);
    this.bedrooms = new FormControl(0, [Validators.required]);

    this.formGroup = new FormGroup({
      number_of_guests: this.guests,
      number_of_pets: this.pets,
      number_of_bedrooms: this.bedrooms
    });

    this.pets.setValue(0);

    for (let i = 2016; i <= moment().add(7, "days").year() + 1; i++) {
      this.years.push({
        title: String(i),
        value: i
      });
    }
  }

  fixHeader() {
    this.scrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const fix = document.getElementById("fixed-scroll-row");
    if (!fix) {
      return;
    }
    if (this.scrollY >= 273) {
      fix.style.position = "fixed";
      fix.style.width = "100%";
      if (this.isSideBarHidden) {
        fix.style.left = "31px";
        fix.style.right = "70px";
        fix.style.width = "95%";
      } else {
        fix.style.left = "270px";
        fix.style.right = "60px";
        fix.style.width = "79%";
      }
      fix.style.top = "80px";
    } else {
      fix.style.position = "static";
      fix.style.width = "auto";
    }
  }

  ngOnInit(): void {
    this.userRepo.getUser().pipe(
      takeWhile(() => this.isAlive),
      filter(u => !!u),)
      .subscribe(user => {
        this.isHouseKeeper = CommonUtil.isHouseKeeper(user) || CommonUtil.isEmployee(user);
      });

    this.store.select(getAppIsMenuHidden).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      this.isSideBarHidden = value;
      this.fixHeader();
    });

    window.addEventListener("scroll", () => {
      this.fixHeader();
      this.setUpCalendar();
    });
    console.log("onInit sd-calendar");
    this.setUpTags();
    this.setUpLocations();
    this.listingRepo.getAcceptedListings().pipe(filter(l => !!l), takeWhile(() => this.isAlive),).subscribe((listings) => {
      this.listings = listings;
      this.rawListings = this.listings;
      this.updateFilteredListings();

    });

    this.monthsOptions = this.months.map(month => {
      return {title: month.title, value: month.id};
    });

    this.multicalendarRepo
      .onRefreshButtonClicked().pipe(
      takeWhile(() => this.isAlive))
      .subscribe(() => {
        this.refresh();
      });

  }

  onMatSelectClose() {
    // this.filteredTags = this.tags;
  }

  ngAfterViewInit() {
    this.appService.changeAppTitle("MultiCalendar");
  }

  openCreateProspectPopup() {
    this.dialogRef = this.dialog.open(CreateProspectPopupComponent);
    const instance = this.dialogRef.componentInstance;
    instance.checkedListings = this.selectedListings;
    instance.noOfGuests = this.guests.value;
    instance.checkInDate = this.startDate;
    instance.checkOutDate = this.endDate;
    instance.numberOfDays = DateUtils.daysBetweenDates(this.startDate, this.endDate);
    this.dialogRef.updateSize("100%", "100%");
  }

  scrollXChange(scrollX) {
    this.scrollX = scrollX;
    document.getElementById("scroll-row").scrollLeft = this.scrollX + 20;
  }

  openNewReservationPopup() {
    const data = {
      listing: this.selectedListings[0],
      check_in: this.startDate,
      check_out: this.endDate,
      guest_count: this.guests.value,
      pet_count: this.pets.value
    };
    this.dialogRef = this.dialog.open(NewReservationPopupComponent, {
      data: data
    });
    this.dialogRef.updateSize("100%", "100%");
  }

  nextDates() {
    this.startDateToShow = this.startDateToShow + 1;
    this.endDateToShow = this.endDateToShow + 1;

    this.updateCalendar();
  }

  previousDates() {
    this.startDateToShow = this.startDateToShow - 1;
    this.endDateToShow = this.endDateToShow - 1;

    this.updateCalendar();
  }

  resetDates() {
    const isCurrentMonth = this.currentMonth === (this.today.getMonth() + 1);

    if (isCurrentMonth && this.today.getDate() >= this.calendarWidth) {
      this.startDateToShow = this.daysInCurrentMonth - this.calendarWidth + 1;
      this.endDateToShow = this.daysInCurrentMonth;
    } else {
      this.startDateToShow = 1;
      this.endDateToShow = this.calendarWidth;
    }

    this.updateCalendar();
  }

  // Update Calendar's view because change of date
  updateCalendar() {
    const currentDatesOnDisplay = [];
    const days = new Date(this.currentYear, this.currentMonth, 0).getDate();
    for (let _i = this.startDateToShow; _i <= days; _i++) {
      const date = new Date(this.currentYear, this.currentMonth - 1, _i);
      currentDatesOnDisplay.push(date);
    }

    this.currentDatesOnDisplay = currentDatesOnDisplay;
  }

  // Update Calendar's view because change of month/year
  updateCalendarData() {
    this.currentMonth = this.currentMonthControl.value;
    this.currentYear = this.currentYearControl.value;

    if (this.loading$ && !this.loading$.closed) {
      this.loading$.unsubscribe();
    }

    if (this.loaded$ && !this.loaded$.closed) {
      this.loaded$.unsubscribe();
    }

    if (this.calendar$ && !this.calendar$.closed) {
      this.calendar$.unsubscribe();
    }
    this.daysInCurrentMonth = DateUtils.daysInMonth(this.currentMonth, this.currentYear);

    this.loading$ = this.store.select((state) => {
      return getIsMultiCalendarLoading(state, this.currentMonth, this.currentYear);
    }).pipe(takeWhile(() => this.isAlive)).subscribe((loading) => {
      this.calendarLoading = loading;
    });

    this.loaded$ = this.store.select((state) => {
      return getIsMultiCalendarLoaded(state, this.currentMonth, this.currentYear);
    }).pipe(takeWhile(() => this.isAlive)).subscribe((loading) => {
      this.calendarLoaded = loading;
    });

    this.calendar$ = this.multicalendarRepo.getMultiCalendar({month: this.currentMonth, year: this.currentYear}).pipe(
      filter(v => !!v)).subscribe(res => {
      this.calendarData = res;
      this.start = 0;
      this.filteredListings = this.rawListings.slice(this.start, this.start + 30);
      this.isLoading = false;
    });

    this.updateCalendar();
  }

  updateFilteredListings() {
    this.selectedLocationFilter = this.selectedLocationFilterControl.value;
    this.selectedTagFilter = this.selectedTagFilterControl.value;
    this.currentPage = -1;
    this.onNext(true, true);
  }

  selectedSortByChanged(order) {
    this.selectedSortingBy = order;
    this.currentPage = -1;
    this.onNext(false, true);
  }

  sortListings() {

    this.rawListings = ObjectUtils.sortByKey(this.rawListings, this.selectedSortingBy.param, this.selectedSortingBy.order);
  }

  onselectChanged(data) {

    if (data.checked) {
      this.selectedListings.push(data.listing);
    } else {
      this.selectedListings = this.selectedListings.filter(listing => listing.id !== data.listing.id);
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  onNext(filterListings: boolean, sortListings: boolean) {
    this.currentPage++;

    if (filterListings) {
      this.processListings();
    }

    if (sortListings) {
      this.sortListings();
    }
    this.isMoreLoading = false;
    this.start = 0;
    this.filteredListings = this.rawListings.slice(this.start, this.start + 30);
  }

  onPrev() {
    this.currentPage--;

    this.start = this.currentPage * this.itemsPerPage;
    this.end = this.itemsPerPage + this.start;

    if (this.currentPage < 0) {
      return;
    }

    this.isPrevDisabled = false;
    this.isNextDisabled = false;

    if (this.currentPage === 0) {
      this.isPrevDisabled = true;
    }
    this.total = this.rawListings.length;
    console.log("Filtered Listings", this.filteredListings);
    this.filteredListings = this.rawListings.slice(this.start, this.end);
  }

  processListings() {
    this.selectedListings = [];

    if (this.showFiltered) {
      this.rawListings = this.listings.filter(l => this.filterListingIds.indexOf(l.id) !== -1);
    } else {
      this.rawListings = this.listings;
    }

    if (this.selectedLocationFilter && this.selectedLocationFilter.length > 0) {
      this.rawListings = this.rawListings.filter(listing =>
        this.selectedLocationFilter.includes(listing.city)
      );
    }

    if (this.selectedTagFilter.length !== 0) {
      this.rawListings = this.rawListings.filter(listing => {
        const tags = ListingModelUtil.getTags(listing).map(tag => tag.title);
        const bedroomTags = this.selectedTagFilter.filter(tag => tag.includes("Bedroom"));
        const bathroomTags = this.selectedTagFilter.filter(tag => tag.includes("Bathroom"));
        const remainingTags = this.selectedTagFilter.filter(tag => !bathroomTags.includes(tag)&&!bedroomTags.includes(tag));
        return (bedroomTags.length ? this.contains(bedroomTags, tags) : true) && (bathroomTags.length ? this.contains(bathroomTags, tags) : true) && (remainingTags.length ? this.isSubset(remainingTags, tags) : true);
      });
    } else {
      if (this.showFiltered) {
        this.rawListings = this.listings.filter(l => this.filterListingIds.indexOf(l.id) !== -1);
        console.log("raw listings", this.rawListings);
      } else {
        this.rawListings = this.listings;
      }
    }

  }

  private setUpLocations() {
    this.optionRepo.getAllLocations()
      .pipe(filter(l => !!l), takeWhile(() => this.isAlive))
      .subscribe((locations) => {
        this.locations = locations;
        this.locationsOptions = this.locations.map(location => {
          return {title: location, value: location};
        });
      });
  }

  private setUpTags() {
    this.optionRepo.getAllTags()
      .pipe(filter(t => !!t), takeWhile(() => this.isAlive))
      .subscribe((tags) => {
        this.tags = tags;
        this.tagsOptions = this.tags.map(tag => {
          return {title: tag.title, value: tag.title};
        });
        console.log(tags);
      });
  }

  refresh() {
    this.store.dispatch(new MultiCalendarDeleteMonthSuccess({month: this.currentMonth, year: this.currentYear}));
    this.updateCalendarData();
  }

  setUpCalendar() {
    if ((document.body.scrollHeight - document.body.offsetHeight) <= (Math.round(window.scrollY) + 10) && this.rawListings.length > this.filteredListings.length) {
      this.start += this.offset;

      if (this.rawListings.length - this.filteredListings.length > this.offset) {
        this.filteredListings = [...this.filteredListings, ...this.rawListings.slice(this.start, this.start + this.offset)];
      } else {
        this.filteredListings = this.rawListings;
      }

      this.isMoreLoading = this.filteredListings.length !== this.rawListings.length;
    }
  }

  isDateToday(date: Date): boolean {
    return isToday(date);
  }

  getAvailableListings() {
    const data = {
      start: this.startDate,
      end: this.endDate,
      ...this.formGroup.value
    };
    this.calendarLoading = true;
    this.calendarLoaded = false;
    this.listingRepo.getAvailableListings(data).subscribe((res) => {
      this.calendarLoading = false;
      this.calendarLoaded = true;
      this.filterListingIds = res;
      this.showFiltered = true;
      this.setCurrentCalendar(this.startDate);
      this.updateFilteredListings();
    }, err => {
      this.calendarLoading = false;
      this.calendarLoaded = true;
    });
  }

  resetFilters() {
    this.formGroup.reset();
    this.filterListingIds = [];
    this.showFiltered = false;
    this.startDate = getDateObj();
    this.endDate = DateUtils.addDays(getDateObj(), 1);
    this.setCurrentCalendar(getDateObj());
    this.updateFilteredListings();
  }

  setCurrentCalendar(date: Date) {
    if(this.currentMonth !== date.getMonth()+1) {
      this.currentMonth =  date.getMonth() + 1;
      this.currentMonthControl.setValue(this.currentMonth);
    }
    if (this.currentYear !== date.getFullYear()) {
      this.currentYear = date.getFullYear();
      this.currentYearControl.setValue(this.currentYear);
    }
    this.updateCalendarData();
  }

  private isSubset(array1,array2) {
    let value = true;
    array1.forEach(item => {
      if(!array2.includes(item)) {
        value = false;
      }
    });
    return value;
  }

  private contains(array1: string[], array2: string[]) {
    let value = false;
    for(let i =0; i< array1.length; i++) {
        if(array2.includes(array1[i])) {
          value = true;
          break;
        }
    }
    return value;
  }

  isDateSelected(date: Date) {
    return date.getTime() >= this.startDate.getTime() && date.getTime() < this.endDate.getTime() && !this.isDateToday(date);
  }

  checkDateValidity() {
    if (this.startDate) {
      if (!this.endDate || DateUtils.daysBetweenDates(this.startDate, this.endDate) <= 0) {
        this.endDate = DateUtils.addDays(this.startDate, 1);
      }
    }
  }

  thisWeek() {
    console.log("entered fn");
    this.startDate = getDateObj();
    const days = 7 - (this.today.getDay() - 1);
    this.endDate = getDateObj();
    this.endDate.setDate(this.startDate.getDate() + days);
    this.getAvailableListings();
  }
}
