import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

import { Action } from "../../../actions/action";
import { RatingReviewsActionTypes } from "../../../actions/new/reviews/rating-reviews";
import { SortOrder } from "../../../enums/common.enum";
import { OwnerReview } from "../../../models/new/reviews/owner-review";

export interface RatingReviewsState extends EntityState<OwnerReview> {
  isLoading: boolean;
  isLoaded: boolean;

  currentPage: number;
  perPage: number;
  total: number;

  sort_order: SortOrder;
  listingIds: number[];
}

export const ratingReviewsAdapter: EntityAdapter<OwnerReview> = createEntityAdapter<OwnerReview>({
  selectId: (review: OwnerReview) => review.id,
});

export const initialState: RatingReviewsState = ratingReviewsAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  currentPage: 1,
  perPage: 0,
  total: 0,

  sort_order: SortOrder.DSC,
  listingIds: []
});

export function ratingReviewsReducer(state: RatingReviewsState = initialState, action: Action): RatingReviewsState {
  switch (action.type) {
    case RatingReviewsActionTypes.INDEX_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      };
    }

    case RatingReviewsActionTypes.INDEX_SUCCESS: {
      const reviews = action.payload as {
        reviews: OwnerReview[], currentPage: number, perPage: number, total: number
      };
      return ratingReviewsAdapter.addAll(reviews.reviews, {
        ...state,
        isLoading: false,
        isLoaded: true,
        currentPage: reviews.currentPage,
        perPage: reviews.perPage,
        total: reviews.total
      });
    }

    case RatingReviewsActionTypes.CREATE_REVIEW: {
      const reviewId = action.payload as number;
      const newTotal = state.total - 1;
      return ratingReviewsAdapter.removeOne(reviewId, {
        ...state,
        total: newTotal
      });
    }

    case RatingReviewsActionTypes.PER_PAGE_CHANGE: {
      return {
        ...state,
        perPage: action.payload as number
      };
    }

    case RatingReviewsActionTypes.PAGE_CHANGE: {
      return {
        ...state,
        currentPage: action.payload as number
      };
    }

    case RatingReviewsActionTypes.SORT_ORDER_CHANGE: {
      return {
        ...state,
        sort_order: action.payload as SortOrder
      };
    }

    case RatingReviewsActionTypes.LISTINGS_CHANGE: {
      return {
        ...state,
        listingIds: action.payload as number[]
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsRatingReviewsLoading = (state: RatingReviewsState) => state.isLoading;
export const _getIsRatingReviewsLoaded = (state: RatingReviewsState) => state.isLoaded;

export const _getRatingReviewsCurrentPage = (state: RatingReviewsState) => state.currentPage;
export const _getRatingReviewsPerPage = (state: RatingReviewsState) => state.perPage;
export const _getRatingReviewsTotal = (state: RatingReviewsState) => state.total;

export const _getRatingReviewsSortOrder = (state: RatingReviewsState) => state.sort_order;
export const _getRatingReviewsListing = (state: RatingReviewsState) => state.listingIds;
