/**
 * Created by aditya on 18/9/17.
 */

import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { startOfMonth } from "date-fns";
import { filter, takeWhile } from "rxjs/operators";

import { UserCompact } from "../../../models/new/user/user-compact.model";
import { UserFull } from "../../../models/new/user/user-full.model";
import { ContactRepository } from "../../../repository/contact.repository";
import { UserRepository } from "../../../repository/user-repository";
import { ReportService } from "../../../services/report.service";
import { getDateObj } from "../../../utils/calendar-utils";
import { CommonUtil } from "../../../utils/common.util";
import ObjectUtils from "../../../utils/object";

@Component({
  selector: "sd-download-report-home-popup",
  template: `
    <sd-modal-popup-layout title="Homeowner's Statement">
      <div fxLayout="column" fxLayoutGap="10px">
        <form fxLayout="column" fxLayoutGap="20px" [formGroup]="formGroup"
              (ngSubmit)="formGroup.valid && saveButtonCLicked()">
          <div fxLayoutAlign="space-between center" fxLayout.lt-sm="column" fxLayout="row">

            <mat-form-field [color]="'accent'" class="width-1">
              <input matInput [matDatepicker]="startPicker" [min]="minStartDate"
                     placeholder="Start Date"
                     formControlName='start_date'>
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
            <mat-icon>arrow_forward</mat-icon>
            <mat-form-field [color]="'accent'" class="width-1">
              <input matInput [matDatepicker]="endPicker" [min]="startDate.value" placeholder="End Date"
                     formControlName='end_date'>
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>

            <sd-select class="width-2" placeholder="Format"
                       [control]="option" [options]="formats"></sd-select>
            <div *ngIf="isContactLoading" fxLayoutAlign="center center" style="width: 20%">
              <mat-spinner color="accent" [diameter]="30" [strokeWidth]="4"></mat-spinner>
            </div>
            <sd-select *ngIf="!isContactLoading" class="width-2" placeholder="Homeowner" [multiple]="true"
                       [control]="homeowners" [options]="allHomeownersOptions"></sd-select>

            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
              <button mat-raised-button [disabled]="!formGroup.valid || isSaving" color="accent" type="submit">
                <span>Download</span>
              </button>
              <button *ngIf="!isHomeOwner" mat-raised-button (click)="emailReport()"
                      [disabled]="!formGroup.valid || isSaving" color="accent"
                      type="button">
                Email
              </button>
              <div fxLayoutAlign="center center">
                <mat-spinner *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
              </div>
            </div>
          </div>
        </form>
        <sd-date-picker-options *ngIf="!isHomeOwner" (thisWeek)="setDate($event)"
                                (lastWeek)="setDate($event)"
                                (thisMonth)="setDate($event)"
                                (lastMonth)="setDate($event)"
                                (thisYear)="setDate($event)"
                                (lastYear)="setDate($event)"></sd-date-picker-options>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`
    hr {
      width: 100%;
    }

    mat-spinner {
      height: 30px;
      width: 30px;
    }

    .container-box {
      border-style: solid;
      border-width: 0.1px;
      padding: 10px;
      border-color: #c8c8c8
    }

    .half-width {
      width: 45%;
    }

    .entryInputs {
      width: 45%
    }

    .width-1 {
      width: 16%
    }

    .width-2 {
      width: 22%;
    }

    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 90%;
      }
    }
  `]
})
export class HomeOwnerReportDownloadPopupComponent implements OnInit, OnDestroy {
  formats: {title: string, value: any}[] = [
    { title: "csv", value: "csv" },
    { title: "xlsx", value: "xlsx" }
  ];
  formGroup: FormGroup;
  startDate: FormControl;
  endDate: FormControl;
  option: FormControl;
  minEndDate: Date;
  minStartDate: Date;
  homeowners: FormControl;
  allHomeowners: UserCompact[] = [];
  allHomeownersOptions: {title: string, value: any}[];

  isContactLoading = false;
  isContactLoaded = false;

  isSaving = false;
  isAlive = true;
  isHomeOwner = false;

  constructor(private service: ReportService, private contactRepo: ContactRepository, private userRepo: UserRepository, private dialog: MatDialog) {
    this.startDate = new FormControl(null, [Validators.required]);
    this.endDate = new FormControl(null, [Validators.required]);
    this.option = new FormControl("csv", [Validators.required]);
    this.homeowners = new FormControl([], [Validators.required]);

    console.log("start data", this.minStartDate);

    this.formGroup = new FormGroup({
      start_date: this.startDate,
      end_date: this.endDate,
      format: this.option,
      homeowner_ids: this.homeowners,
    });
  }

  ngOnInit() {

    this.userRepo.getUser().pipe(takeWhile(() => this.isAlive), filter(v => !!v),).subscribe((user: UserFull) => {
      this.isHomeOwner = CommonUtil.isHomeOwner(user);
      if (this.isHomeOwner) {
        this.allHomeowners = [user];
        this.allHomeownersOptions = this.allHomeowners.map(homeowner => {
          return {title: homeowner.first_name + " " + homeowner.last_name, value: homeowner};
        });
        this.homeowners.setValue([user]);
        this.homeowners.disable({onlySelf: true});
      } else {
        this.setUpHomeOwners();
      }

      setTimeout(() => {
        if (this.isHomeOwner) {
          this.minStartDate = getDateObj("2019-01-01");
          this.startDate.setValue(getDateObj("2019-01-01"));
        } else {
          this.startDate.setValue(startOfMonth(getDateObj()));
          this.endDate.setValue(getDateObj());
        }
      }, 100);
    });
  }

  saveButtonCLicked() {
    this.isSaving = true;
    const data = {
      ...this.formGroup.value,
      start_date: this.startDate.value,
      end_date: this.endDate.value
    };
    const homeownerIds = this.homeowners.value.map(homeowner => homeowner.managementContact.data.id);
    data.homeowner_ids = homeownerIds.join(", ");
    this.service.downloadHomeOwnerStatement(data).subscribe(res => {
      this.dialog.closeAll();
      this.isSaving = false;
    }, err => {
      this.isSaving = false;
    });
  }

  setUpHomeOwners() {
    this.contactRepo.getIsHomeOwnerLoading().pipe(takeWhile(() => this.isAlive)).subscribe((loading) => {
      this.isContactLoading = loading;
    });

    this.contactRepo.getIsHomeOwnerLoaded().pipe(takeWhile(() => this.isAlive)).subscribe((loaded) => {
      this.isContactLoaded = loaded;
    });

    this.contactRepo.getHomeOwners().pipe(takeWhile(() => this.isAlive)).subscribe((contacts: UserCompact[]) => {
      if (contacts.length > 0) {
        this.allHomeowners = contacts;
        this.allHomeowners = ObjectUtils.sortByKey(this.allHomeowners, "first_name", "asc");
        this.allHomeownersOptions = this.allHomeowners.map(homeowner => {
          return {title: homeowner.first_name + " " + homeowner.last_name, value: homeowner};
        });
      }
    });
  }

  emailReport() {
    this.isSaving = true;
    const data = this.formGroup.value;
    const homeownerIds = this.homeowners.value.map(homeowner => homeowner.managementContact.data.id);
    data.homeowner_ids = homeownerIds.join(", ");
    this.service.mailHomeOwnerStatement(data).subscribe(res => {
      this.isSaving = false;
      this.dialog.closeAll();
    }, err => {
      this.isSaving = false;
    });
  }

  setDate(data) {
    this.startDate.patchValue(data.start);
    this.endDate.patchValue(data.end);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
