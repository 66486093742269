/**
 * Created by Piyush on 22-Jul-17.
 */
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { isSameDay } from "date-fns";
import { take } from "rxjs/operators";

import { environment } from "../../../../environments/environment";
import { CalendarShowResponse } from "../../../models/calendar-response";
import { MultiCalendarRule } from "../../../models/new/multi-calendar-rule";
import { ListingRepository } from "../../../repository/listing.repository";
import { MultiCalendarRulesRepository } from "../../../repository/multi-calendar-rules.repository";
import { StayDuvetService } from "../../../services/stayduvet";
import { getDateObj } from "../../../utils/calendar-utils";

@Component({
  selector: "sd-small-calendar-date-tile",
  template: `
    <div fxLayout="column"
         *ngIf="!isBlocking"
         [ngClass]="{'owners-block-first-half': data?.first_block && !data?.second_block,
         'owners-block-second-half': data?.second_block && !data?.first_block,
         'owners-block': data?.second_block && data?.first_block,
         'today': isToday()}"
         fxLayoutAlign="start start"
         style="height: 60px">
      <!--date-->
      <div fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="10%" fxFlex="30%"
           style="width:100%; font-size: 10px;">
        {{ day.date | date:'d' }}
      </div>


      <div *ngIf="isFullBlock() || isFirstBlock() || isSecondBlock()" fxLayout="row"
           [ngClass]="{'block-first-half': isFirstBlock(),
         'block-second-half': isSecondBlock(),
         'block': isFullBlock()}"
           fxLayoutAlign="start start"
           style="height: 60px; width: 100%">
      </div>

      <div *ngIf="isBlockedFromLimit()"
           fxLayout="row"
           class="block-full"
           fxLayoutAlign="center center"
           style="height: 60px; width: 100%">
        <span style="font-size: 8px;">Blocked</span>
      </div>

      <div *ngIf="isBlockedStartFromLimit()"
           fxLayout="row"
           class="block-second"
           fxLayoutAlign="start center"
           style="height: 60px; width: 100%">
        <span style="font-size: 8px;">Blocked</span>
      </div>


      <!--start of booking-->
      <div fxLayout="row"
           fxLayoutAlign="start center"
           fxFlex="33.33%"
           style="width: 100%"
           *ngIf="!isBlockedFromLimit() && !isBlockedStartFromLimit() && !data?.first_booking && data?.second_booking">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="40%" style="height: 100%"></div>
        <div fxLayout="column"
             (click)="openReservation(data?.second_booking.id)" class="second-half" fxFlex="60%"
             [ngStyle]="{'background': getBackground(data?.second_booking)}"
             style="height: 100%">
        </div>
      </div>

      <!--end of booking-->
      <div fxLayout="row"
           fxLayoutAlign="start center"
           fxFlex="33.33%"
           style="width: 100%"
           *ngIf="!isBlockedFromLimit() && data?.first_booking && !data?.second_booking">
        <div fxLayout="column"
             (click)="openReservation(data?.first_booking.id)" class="first-half" fxLayoutAlign="center center"
             fxFlex="40%"
             [ngStyle]="{'background': getBackground(data?.first_booking)}"
             style="height: 100%"></div>
      </div>

      <!--Both Half of booking-->
      <div fxLayout="row"
           fxLayoutAlign="start center"
           fxFlex="33.33%"
           fxLayoutGap="10%"
           style="width: 100%"
           *ngIf="!isBlockedFromLimit() && !isBlockedStartFromLimit() && data?.first_booking && data?.second_booking">
        <div fxLayout="column"
             (click)="openReservation(data?.first_booking.id)" class="first-half" fxLayoutAlign="center center"
             fxFlex="35%"
             [ngStyle]="{'background': getBackground(data.first_booking)}"
             style="height: 100%"></div>
        <div fxLayout="column"
             (click)="openReservation(data.second_booking.id)" class="second-half" fxFlex="55%"
             [ngStyle]="{'background': getBackground(data.second_booking)}"
             style="height: 100%">
        </div>
      </div>

      <!--full day of booking-->
      <div fxLayout="row"
           fxLayoutAlign="center center"
           fxFlex="33.33%"
           style="width: 100%"
           *ngIf="!isBlockedFromLimit() && !isBlockedStartFromLimit() && data?.booking">
        <div
          (click)="openReservation(data?.booking.id)" fxLayout="column" fxLayoutAlign="center center" fxFlex="100%"
          [ngStyle]="{'background': getBackground(data.booking)}"
          style="height: 100%"></div>
      </div>


      <!--Blocked because of other listing-->


      <div fxLayout="row"
           fxLayoutAlign="center center"
           fxFlex="20%"
           fxFlexAlign="end"
           style="width: 100%; "
           *ngIf="!isBlockedFromLimit() && !isBlockedStartFromLimit() && (data?.second_block || isFullBlock() || isSecondBlock())">
        <span style="font-size: 8px;">Blocked</span>
      </div>

    </div>
    <div fxLayout="column" *ngIf="isBlocking" fxLayoutAlign="center center"
         style="height: 150px">
      <mat-spinner [color]="'accent'" [diameter]="30" [strokeWidth]="4"></mat-spinner>
    </div>




  `,
  styles: [
      `
      .pointer {
        cursor: pointer;
      }

      .today {
        background: #29e0e0;
      }

      .owners-block {
        background: repeating-linear-gradient(
          135deg,
          #6c6b65,
          #6c6b65 10px,
          #AEAEAB 10px,
          #AEAEAB 20px
        );
        opacity: 0.5;
      }

      .owners-block-first-half {
        opacity: 0.5;
        background: repeating-linear-gradient(
          135deg,
          #6c6b65,
          #6c6b65 10px,
          #AEAEAB 10px,
          #AEAEAB 20px
        ) repeat-y;
        background-size: 40% auto;
      }

      .owners-block-second-half {
        opacity: 0.5;
        background: repeating-linear-gradient(
          135deg,
          #6c6b65,
          #6c6b65 10px,
          #AEAEAB 10px,
          #AEAEAB 20px
        ) no-repeat;
        background-size: 60% auto;
        background-position: right bottom
      }

      .block {
        background: #AEAEAB;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      .block-first-half {
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 40% !important;
        background: #AEAEAB;
        background-size: 40% auto;
      }

      .block-second-half {
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 40%;
        width: 60%;
        height: 100%;
        background: #AEAEAB;
        background-size: 60% auto;
        background-position: right bottom
      }

      .block-full {
        background: grey;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      .block-second {
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 40%;
        width: 60%;
        height: 100%;
        background: grey;
        background-size: 60% auto;
        background-position: right bottom
      }


      .first-half {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
      }

      .second-half {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        overflow: hidden;
      }

      .price-container {
        font-size: 20px;
        color: #1a1a1a;
        font-family: 'Montserrat', sans-serif;
        font-weight: bolder;
      }
    `
  ]
})
export class SmallCalendarDateTileComponent implements OnInit {
  @Input() isClickable = true;
  @Input() isHouseKeeper = false;
  @Input() listingId: number;
  @Input() day: any;
  @Input() amount: any;
  endDate;
  limit;
  calendarRules: MultiCalendarRule[];
  listingIds: number[] = [];
  futureBookingLimits: any[] = [];

  isBlocking = false;

  data: CalendarShowResponse = {
    first_block: null,
    second_block: null,
    first_blocks: null,
    second_blocks: null,
    booking: null,
    first_booking: null,
    second_booking: null,
    first_bookings: null,
    second_bookings: null,
    bookings: null
  };

  @Input() set calendarData(data) {

    this.data = {
      first_block: null,
      second_block: null,
      first_blocks: null,
      second_blocks: null,
      booking: null,
      first_booking: null,
      second_booking: null,
      first_bookings: null,
      second_bookings: null,
      bookings: null
    };

    if (!data) {
      this.data = data;
    } else {
      if (data.first_bookings) {
        this.data.first_bookings = [];
        data.first_bookings.forEach(id => {
          this.listingRepo.getBooking(id).subscribe(b => this.data.first_bookings.push(b));
        });
      }

      if (data.second_bookings) {
        this.data.second_bookings = [];
        data.second_bookings.forEach(id => {
          this.listingRepo.getBooking(id).subscribe(b => this.data.second_bookings.push(b));
        });
      }

      if (data.bookings) {
        this.data.bookings = [];
        data.bookings.forEach(id => {
          this.listingRepo.getBooking(id).subscribe(b => this.data.bookings.push(b));
        });
      }

      if (data.first_blocks) {
        this.data.first_blocks = [];
        data.first_blocks.forEach(id => {
          this.listingRepo.getBlock(id).subscribe(b => this.data.first_blocks.push(b));
        });
      }

      if (data.second_blocks) {
        this.data.second_blocks = [];
        data.second_blocks.forEach(id => {
          this.listingRepo.getBlock(id).subscribe(b => this.data.second_blocks.push(b));
        });
      }

      if (data.first_block) {
        this.listingRepo.getBlock(data.first_block).subscribe(b => this.data.first_block = b);
      }

      if (data.second_block) {
        this.listingRepo.getBlock(data.second_block).subscribe(b => this.data.second_block = b);
      }

      if (data.first_booking) {
        this.listingRepo.getBooking(data.first_booking).subscribe(b => this.data.first_booking = b);
      }

      if (data.second_booking) {
        this.listingRepo.getBooking(data.second_booking).subscribe(b => this.data.second_booking = b);
      }

      if (data.booking) {
        this.listingRepo.getBooking(data.booking).subscribe(b => this.data.booking = b);
      }
    }
  }

  constructor(private service: StayDuvetService,
              private listingRepo: ListingRepository,
              private dialog: MatDialog, private router: Router,
              private multicalendarRuleRepo: MultiCalendarRulesRepository) {
  }

  ngOnInit(): void {
    console.log("onInit sd-calendar-date-tile");
    this.multicalendarRuleRepo.getMultiCalendarRules().pipe(take(1)).subscribe(res => {
      if (res) {
        this.calendarRules = res;
        this.calendarRules.map(rule => {
          if (rule.blocked_property_id === this.listingId && rule.sync_future_booking_limit === true) {
            if (this.listingIds.indexOf(rule.parent_property_id) === -1) {
              this.listingIds.push(rule.parent_property_id);
            }
          }
          if (rule.parent_property_id === this.listingId && rule.reverse_rule_applied === true && rule.sync_future_booking_limit === true) {
            if (this.listingIds.indexOf(rule.blocked_property_id) === -1) {
              this.listingIds.push(rule.blocked_property_id);
            }
          }
        });
      }

      if (this.listingIds.indexOf(this.listingId) === -1) {
        this.listingIds.push(this.listingId);
      }
      this.listingIds.map(id => {
        this.listingRepo.getCompactListingById(id).pipe(take(1)).subscribe(listing => {
          if (listing) {
            this.futureBookingLimits.push(listing.future_booking_limit);
          }
        }, (err) => {
          console.log(err);
        });
      });
      this.setupEndDate();

    }, (err) => {
      console.log(err);
    });

  }

  isToday(): boolean {
    return isSameDay(getDateObj(), this.day.date);
  }

  getBackground(event) {

    if (!event) {
      return;
    }

    let color = "red";

    switch (event.source) {

      case "airbnb": {
        color = "#f55a5f";
        break;
      }

      case "homeaway": {
        color = "#28924c";
        break;
      }

      case "stayduvet": {
        color = "#12304c";
        break;
      }

      case "plum_guide": {
        color = "#926685";
        break;
      }

      case "hvmi": {
        color = "#ea9866";
        break;
      }

      case "referral": {
        color = "#ccb96b";
        break;
      }

      case "owner_block": {
        color = "grey";
        break;
      }

    }

    if (event.payment_method === "from_platform" && event.total_paid === 0) {
      color = "#f0a340";
    }

    if (event.status === "pending") {
      color = "#0000ff";
    }

    if (event.property_id !== this.listingId) {
      color = "black";
    }

    return color;

  }

  isSecondBlock() {
    if (!this.data) {
      return;
    }

    return !this.isBlockedStartFromLimit() &&
      !this.isBlockedFromLimit() &&
      !this.isFullBlock() &&
      !this.data.booking &&
      !this.data.second_block &&
      !this.data.second_booking &&
      (this.data.second_blocks || this.data.second_bookings || (this.data.bookings && (this.data.first_block || this.data.first_booking)));
  }

  isFullBlock() {
    if (!this.data) {
      return;
    }

    return !this.isBlockedStartFromLimit() &&
      !this.isBlockedFromLimit() &&
      !this.data.booking &&
      !this.data.second_block &&
      !this.data.second_booking &&
      !this.data.first_block &&
      !this.data.first_booking &&
      (this.data.bookings || ((this.data.first_bookings || this.data.first_blocks) && (this.data.second_bookings || this.data.second_blocks)));
  }

  isFirstBlock() {
    if (!this.data) {
      return;
    }

    return !this.isBlockedFromLimit() &&
      !this.isFullBlock() &&
      !this.data.booking &&
      !this.data.first_block &&
      !this.data.first_booking &&
      (this.data.first_blocks || this.data.first_bookings || (this.data.bookings && (this.data.second_block || this.data.second_booking)));
  }

  openReservation(id) {
    if (this.isClickable) {
      if (this.isHouseKeeper) {
        window.open(environment.dashboardURL + "/reservations/info/" + id);
      } else {
        window.open(environment.dashboardURL + "/reservations/" + id);
      }
      event.stopPropagation();
    }
  }

  isBlockedFromLimit() {
    if (!this.endDate) {
      return false;
    }
    return this.day.date.getTime() > this.endDate.getTime();
  }

  isBlockedStartFromLimit() {
    if (!this.endDate) {
      return false;
    }
    return this.day.date.getTime() === this.endDate.getTime();
  }

  setupEndDate() {
    let months = 0;
    if (this.futureBookingLimits.includes("one_month")) {
      months = 1;
    } else if (this.futureBookingLimits.includes("three_months")) {
      months = 3;
    } else if (this.futureBookingLimits.includes("six_months")) {
      months = 6;
    } else if (this.futureBookingLimits.includes("nine_months")) {
      months = 9;
    } else if (this.futureBookingLimits.includes("twelve_months")) {
      months = 12;
    }
    if (months === 0) {
      this.endDate = null;
    } else {
      this.endDate = getDateObj();
      this.endDate.setHours(0, 0, 0, 0);
      this.endDate.setMonth(this.endDate.getMonth() + months);
      this.endDate.setDate(this.endDate.getDate() + 1);
    }
  }

}
