import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { __HTTPResponseType } from "../enums/common.enum";
import { AirbnbAccount } from "../models/airbnb_account";
import { UserFull } from "../models/new/user/user-full.model";
import { PropertyManager } from "../models/property-manager.model";

import { ApiService } from "./api.service";

@Injectable()
export class UserService {

  private USER_SHOW_INCLUDES = "managementContact,logs,owner,admin,guest";

  constructor(private apiService: ApiService) {
  }

  login(data: { email: string, password: string, "mfa-token"?: string }): Observable<UserFull> {
    return this.apiService.get<{ token: string, user: UserFull }>("/authenticate", false, data)
      .pipe(map(res => {
        localStorage.setItem("auth_token", res.token);
        return res.user;
      }));
  }

  signupViaEmail(data: { email: string, password: string, first_name: string, last_name: string }): Observable<UserFull> {
    return this.apiService.post<{ token: string, user: UserFull }>("/owner/register", false, data).pipe(map(res => {
      localStorage.setItem("auth_token", res.token);
      return res.user;
    }));
  }

  signupViaAirbnb(data: { forwarding_email: string, airbnb_username: string, airbnb_password: string }): Observable<{ user: UserFull, airbnb_account: AirbnbAccount }> {
    return this.apiService.post<{ airbnb_account: AirbnbAccount, user: UserFull, token: string }>("/owner/register", false, data).pipe(map(res => {
      localStorage.setItem("auth_token", res.token);
      return {user: res.user, airbnb_account: res.airbnb_account};
    }));
  }

  forgotPassword(data: { email: string }): Observable<any> {
    return this.apiService.post("/password/forgot", false, data, null, true, __HTTPResponseType.TEXT)
      .pipe(map(() => true));
  }

  resetPassword(data: { email: string, code: string, password: string, password_confirmation: string }): Observable<boolean | {}> {
    return this.apiService.post("/password/reset/code", true, data, null, true, __HTTPResponseType.TEXT).pipe(map(res => {
      return true;
    }));
  }

  generateCode(data) {
    return this.apiService.get("/generate-mfa", false, data).pipe(map(res => true));
  }

  validateCode(data) {
    return this.apiService.get("/validate-mfa", false, data).pipe(map((res: any) => {
      localStorage.setItem("mfa_token", res["mfa-token"]);
      return true;
    }));
  }

  getAirbnbAccounts(): Observable<AirbnbAccount[]> {
    return this.apiService.get<{ data: AirbnbAccount[] }>("/airbnb/accounts", true).pipe(map((res) => res.data));
  }

  getAirbnbAccountById(id: number): Observable<AirbnbAccount> {
    return this.apiService.get<{ data: AirbnbAccount }>("/airbnb/accounts/" + id, true).pipe(map((res) => res.data));
  }

  attachAirbnbAccount(data: any): Observable<AirbnbAccount> {
    return this.apiService.post<AirbnbAccount>("/airbnb/accounts", true, data);
  }

  pingAirbnbAccount(account_id: number): Observable<AirbnbAccount> {
    return this.apiService.get<{ data: AirbnbAccount }>("/airbnb/accounts/" + account_id + "/ping", true).pipe(map(res => res.data));
  }

  updateAirbnbAccount(id: number, data: any): Observable<AirbnbAccount> {
    return this.apiService.put<{ data: AirbnbAccount }>("/airbnb/accounts/" + id, true, data).pipe(map(res => res.data));
  }

  refreshAirbnbAccount(account_id: number, data: any): Observable<boolean | {}> {
    return this.apiService.get("/airbnb/accounts/" + account_id + "/refresh", true, data, null, true, __HTTPResponseType.TEXT).pipe(map(res => {
      return true;
    }));
  }

  resendAirbnbCodeByEmail(accountId: number): Observable<AirbnbAccount> {
    return this.apiService.post<{ data: AirbnbAccount }>("/airbnb/accounts/" + accountId + "/resend/email", true, null).pipe(map(res => res.data));
  }

  resendAirbnbCodeByText(accountId: number, phoneNumberId: number): Observable<AirbnbAccount> {
    return this.apiService.post<{ data: AirbnbAccount }>("/airbnb/accounts/" + accountId + "/resend/text", true, {
      phone_number_id: phoneNumberId
    }).pipe(map(res => res.data));
  }

  resendAirbnbCodeByCall(accountId: number, phoneNumberId: number): Observable<AirbnbAccount> {
    return this.apiService.post<{ data: AirbnbAccount }>("/airbnb/accounts/" + accountId + "/resend/call", true, {
      phone_number_id: phoneNumberId
    }).pipe(map(res => res.data));
  }

  verifyAirbnbCodeByEmail(accountId: number, code: string): Observable<AirbnbAccount> {
    return this.apiService.post<{ data: AirbnbAccount }>("/airbnb/accounts/" + accountId + "/verify/email", true, {
      code: code
    }).pipe(map(res => res.data));
  }

  verifyAirbnbCodeByText(accountId: number, code: string, phoneNumberId: number): Observable<AirbnbAccount> {
    return this.apiService.post<{ data: AirbnbAccount }>("/airbnb/accounts/" + accountId + "/verify/text", true, {
      code: code,
      phone_number_id: phoneNumberId
    }).pipe(map(res => res.data));
  }

  verifyAirbnbCodeByCall(accountId: number, code: string, phoneNumberId: number): Observable<AirbnbAccount> {
    return this.apiService.post<{ data: AirbnbAccount }>("/airbnb/accounts/" + accountId + "/verify/call", true, {
      code: code,
      phone_number_id: phoneNumberId
    }).pipe(map(res => res.data));
  }

  me(): Observable<UserFull> {
    return this.apiService.get<{ data: UserFull }>("/me", true).pipe(map(res => res.data));
  }

  updateProfile(data: {
    first_name: string, last_name: string, email: string, secondary_email: string, phone_number: any,
    secondary_phone_number: any, preferred_contact_method: string, description: string, show_expenses: boolean,
  }): Observable<UserFull> {
    return this.apiService.put<{ data: UserFull }>("/me", true, data).pipe(map((res) => res.data));
  }

  updateUserPic(data: { image: File }): Observable<any> {
    return this.apiService.upload("/me/update-pic", "POST", data.image, true, "image");
  }

  changePassword(data: { old_password: string, password: string, password_confirmation: string }): Observable<boolean | {}> {
    return this.apiService.put("/me/password", true, data, null, true, __HTTPResponseType.TEXT).pipe(map(() => {
      return true;
    }));
  }

  updateUserType(userCategory: string, contactId: number): Observable<UserFull> {
    return this.apiService.put<{ data: UserFull }>("/admin/contacts/" + contactId + "?include=" + this.USER_SHOW_INCLUDES, true, {management_category: userCategory}).pipe(map(res => res.data));
  }

  checkEmailValidity(data: { email_prefix: string }): Observable<any> {
    return this.apiService.post("/airbnb/accounts/check-email", true, data);
  }

  changeEmail(id: number, data: { email_prefix: string }): Observable<AirbnbAccount> {
    return this.apiService.post<{ data: AirbnbAccount }>("/airbnb/accounts/" + id + "/change-domain", true, data)
      .pipe(map(res => res.data));
  }

  getPropertyManagers() {
    return this.apiService.get<{data: PropertyManager[]}>("/admin/mbp/accounts", true).pipe(map(res => res.data));
  }

  addPropertyManager(data) {
    return this.apiService.post<{data: PropertyManager}>("/super-admin/mbp/accounts", true, data).pipe(map(res => res.data));
  }

  updatePropertyManager(id: number, data) {
    return this.apiService.put<{data: PropertyManager}>("/super-admin/mbp/accounts/" + id, true, data).pipe(map(res => res.data));
  }

  getBookingPalUrl(): Observable<string> {
    return this.apiService.get<{url: string}>("/super-admin/mbp/fetch-url", true)
      .pipe(map(r => r.url));
  }
  oauth(data) {
    return this.apiService.get("/admin/airbnb/access-token-from-code", true, data);
  }
}
