import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { AutoTaskCompact } from "../../../models/new/tasks/auto-task-compact.model";
import { AutoTaskFull } from "../../../models/new/tasks/auto-task-full.model";
import { Action } from "../../../actions/action";
import { AutoTaskActionTypes } from "../../../actions/new/tasks/auto-task";
import * as _ from "lodash";
import { TaskState } from "./task";
import { TaskActionTypes } from "../../../actions/new/tasks/task";

export interface AutoTaskState extends EntityState<AutoTaskCompact | AutoTaskFull> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];

  tasksNotExist: number[];
}

export const autoTaskAdapter: EntityAdapter<AutoTaskCompact | AutoTaskFull> = createEntityAdapter<AutoTaskCompact | AutoTaskFull>({
  selectId: (autoTask: AutoTaskCompact | AutoTaskFull) => autoTask.id
});

export const initialState: AutoTaskState = autoTaskAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],

  tasksNotExist: []
});

export function autoTaskReducer(state: AutoTaskState = initialState, action: Action): AutoTaskState {
  switch (action.type) {
    case AutoTaskActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true
      }
    }

    case AutoTaskActionTypes.INDEX_SUCCESS: {
      const autoTasks = action.payload as AutoTaskCompact[];
      return autoTaskAdapter.addAll(autoTasks, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case AutoTaskActionTypes.CREATE_SUCCESS: {
      const value = action.payload as AutoTaskFull;

      return autoTaskAdapter.updateOne({
        id: value.id,
        changes: value
      }, autoTaskAdapter.addOne(value, state));
    }


    case AutoTaskActionTypes.SHOW_REQUEST: {
      const autoTaskId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, autoTaskId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          autoTaskId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, autoTaskId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, autoTaskId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case AutoTaskActionTypes.SHOW_SUCCESS: {
      const autoTask = action.payload as AutoTaskFull;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, autoTask.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, autoTask.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, autoTask.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          autoTask.id
        ];
      }

      const addedState = autoTaskAdapter.addOne(autoTask, state);

      return autoTaskAdapter.updateOne({
        id: autoTask.id,
        changes: autoTask
      }, {
        ...addedState,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      });
    }

    case AutoTaskActionTypes.DELETE_SUCCESS: {
      const value = action.payload as number;

      return autoTaskAdapter.removeOne(value,state);
    }

    case AutoTaskActionTypes.UPDATE_SUCCESS: {
      const task = action.payload as AutoTaskFull;

      const newState = autoTaskAdapter.updateOne({
        id: task.id,
        changes: task
      }, state);

      return autoTaskAdapter.addOne(task, newState);

    }

    case AutoTaskActionTypes.ADD_AUTO_TASK_TO_NOT_EXIST: {
      const taskId = action.payload as number;
      const data = state.tasksNotExist;
      data.push(taskId);
      return {
        ...state,
        tasksNotExist: data
      }
    }


    default: {
      return state;
    }
  }
}


export const _getIsAutoTaskLoading = (state: AutoTaskState) => state.isLoading;
export const _getIsAutoTaskLoaded = (state: AutoTaskState) => state.isLoaded;

export const _getIsFullAutoTaskLoading = (state: AutoTaskState, taskId: number) => {
  return state.fullLoadingIds.indexOf(taskId) !== -1;
};
export const _getIsFullAutoTaskLoaded = (state: AutoTaskState, taskId: number) => {
  return state.fullLoadedIds.indexOf(taskId) !== -1;
};

export const _getAutoTaskExist = (taskId: number, state: AutoTaskState) => {
  return state.tasksNotExist.indexOf(taskId) !== -1;
};
