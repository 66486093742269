import { Action } from "../../../actions/action";
import { ListingCalendarActionTypes } from "../../../actions/new/listings/listing-calendar";
import { isNullOrUndefined } from "util";


export interface State {
  calendar: { [month: string]: any[] };
  calendarLoadingIds: string[];
  calendarLoadedIds: string[];
}

export const initialState: State = {
  calendar: {},
  calendarLoadingIds: [],
  calendarLoadedIds: []
};

export function reducer(state = initialState, action: Action): State {

  switch (action.type) {

    case ListingCalendarActionTypes.CALENDAR_REQUEST: {
      const listingId = action.payload.listingId;
      const month = action.payload.month;

      const index = String(listingId) + "-" + month;

      let loadingIds = state.calendarLoadingIds;

      if (state.calendarLoadingIds.indexOf(index) === -1) {
        loadingIds = [
          ...state.calendarLoadingIds,
          index
        ];
      }

      return {
        ...state,
        calendarLoadingIds: loadingIds,
      };
    }

    case ListingCalendarActionTypes.CALENDAR_SUCCESS: {
      const listingId = action.payload.listingId;
      const month = action.payload.month;
      const data = action.payload.data;

      const index = String(listingId) + "-" + month;

      const newLoadingIds = state.calendarLoadingIds.filter(item => {
        return item !== index;
      });

      let newLoadedIds = state.calendarLoadedIds;
      if (state.calendarLoadedIds.indexOf(index) === -1) {
        newLoadedIds = [
          ...state.calendarLoadedIds,
          index
        ];
      }
      return {
        ...state,
        calendarLoadingIds: newLoadingIds,
        calendarLoadedIds: newLoadedIds,
        calendar: {
          ...state.calendar,
          [index]: data
        }
      };
    }

    case ListingCalendarActionTypes.BLOCK_ADD_SUCCESS: {
      const payload = action.payload;
      const listingId = payload.listingId;
      const month = payload.month;
      const block = payload.block;
      const index = String(listingId) + "-" + month;

      if (state.calendar[index]) {

        return {
          ...state,
          calendar: {
            ...state.calendar,
            [index]: [
              ...state.calendar[index],
              ...block
            ]
          }
        };
      }

      return state;

    }

    case ListingCalendarActionTypes.BLOCK_DELETE_SUCCESS: {

      const listingId = action.payload.listingId;
      const month = action.payload.month;
      const date = action.payload.date;

      const index = String(listingId) + "-" + month;

      if (state.calendar[index]) {
        const newEntities = state.calendar[index].filter(item => {
          return item.start !== date;
        });


        return {
          ...state,
          calendar: {
            ...state.calendar,
            [index]: newEntities
          },
        };
      }

      return state;
    }

    case ListingCalendarActionTypes.CALENDAR_DELETE: {

      const listingId = action.payload.propertyId;
      const month = action.payload.month;
      const dates = [];
      let newLoadedIds = [...state.calendarLoadedIds];
      if (isNullOrUndefined(month)) {
        for (const key in state.calendar) {
          const keysArray = key.split("-");
          if (keysArray[0] === String(listingId)) {
            dates.push(key);
          }
        }
      } else {
        dates.push(listingId + "-" + month);
      }


      console.log("[Delete]", dates);

      const localCalendar = { ...state.calendar };

      dates.forEach(date => {
        delete localCalendar[date];
        newLoadedIds = newLoadedIds.filter(item => {
          return item !== date;
        });
      });

      if (dates.length > 0) {
        return {
          ...state,
          calendar: localCalendar,
          calendarLoadedIds: [...newLoadedIds]
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
}


export const _getIsCalendarDataLoading = (state: State, listingId: number, month: string) => {
  const index = String(listingId) + "-" + month;
  return state.calendarLoadingIds.indexOf(index) > -1;
};
export const _getIsCalendarDataLoaded = (state: State, listingId: number, month: string) => {
  const index = String(listingId) + "-" + month;
  return state.calendarLoadedIds.indexOf(index) > -1;
};
export const _getCalendarData = (state: State, listingId: number, month: string) => {
  const index = String(listingId) + "-" + month;
  return state.calendar[index];
};

