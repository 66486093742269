import { SummaryDraft } from "../../../../models/new/drafts/summary-draft.model";
import { Action } from "../../../action";

export enum SummaryDraftActionTypes {
  INDEX_REQUEST = "[Summary Drafts NEW] Index Request",
  INDEX_SUCCESS = "[Summary Drafts NEW] Index Success",

  CREATE_REQUEST = "[Summary Drafts NEW] Create Request",
  CREATE_SUCCESS = "[Summary Drafts NEW] Create Success",

  SHOW_REQUEST = "[Summary Drafts NEW] Show Request",
  SHOW_SUCCESS = "[Summary Drafts NEW] Show Success",

  UPDATE_REQUEST = "[Summary Drafts NEW] Update Request",
  UPDATE_SUCCESS = "[Summary Drafts NEW] Update Success",

  DELETE_REQUEST = "[Summary Drafts NEW] Delete Request",
  DELETE_SUCCESS = "[Summary Drafts NEW] Delete Success"
}

export class SummaryDraftIndexRequest implements Action {
  readonly type = SummaryDraftActionTypes.INDEX_REQUEST;
}

export class SummaryDraftIndexSuccess implements Action {
  readonly type = SummaryDraftActionTypes.INDEX_SUCCESS;

  constructor(public payload: SummaryDraft[]) {
  }
}

export class SummaryDraftCreateRequest implements Action {
  readonly type = SummaryDraftActionTypes.CREATE_REQUEST;
}

export class SummaryDraftCreateSuccess implements Action {
  readonly type = SummaryDraftActionTypes.CREATE_SUCCESS;

  constructor(public payload: SummaryDraft) {
  }
}

export class SummaryDraftShowRequest implements Action {
  readonly type = SummaryDraftActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {

  }
}

export class SummaryDraftShowSuccess implements Action {
  readonly type = SummaryDraftActionTypes.SHOW_SUCCESS;

  constructor(public payload: SummaryDraft) {
  }
}

export class SummaryDraftUpdateRequest implements Action {
  readonly type = SummaryDraftActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class SummaryDraftUpdateSuccess implements Action {
  readonly type = SummaryDraftActionTypes.UPDATE_SUCCESS;

  constructor(public payload: SummaryDraft) {
  }
}

export class SummaryDraftDeleteRequest implements Action {
  readonly type = SummaryDraftActionTypes.DELETE_REQUEST;

  constructor(public payload: number) {
  }
}

export class SummaryDraftDeleteSuccess implements Action {
  readonly type = SummaryDraftActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
