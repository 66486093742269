import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import {
  AddBookingGuestSuccess,
  AddBookingToNotExist,
  BookingCreateSuccess, BookingEndChange,
  BookingGuestDeleteSuccess,
  BookingGuestSetBadgeCountAction,
  BookingGuestUpdateSuccess,
  BookingIndexRequest,
  BookingIndexSuccess,
  BookingListingsChange,
  BookingPageChange,
  BookingPaymentCreateSuccess,
  BookingPerPageChange, BookingReviewRatingChange,
  BookingSecurityDeductionRequest,
  BookingSecurityDeductionSuccess,
  BookingShowRequest,
  BookingShowSuccess,
  BookingSortOrderChange,
  BookingSortTypeChange,
  BookingSourcesChange, BookingStartChange, BookingTagsChange,
  BookingTypeChange,
  BookingUpdateRequest,
  BookingUpdateSuccess,
  ScheduledMessageUpdateSuccess,
  ScheduledReviewUpdateSuccess,
  UpcomingOwnerBookingIndexRequest,
  UpcomingOwnerBookingIndexSuccess,
  VendorTasksIndexRequest,
  VendorTasksIndexSuccess
} from "../actions/new/bookings/bookings";
import { BookingStatus } from "../enums/booking.enum";
import { SortOrder, TransformerType } from "../enums/common.enum";
import { HomeownerReservationType, ReservationSortType, ReservationType } from "../enums/reservation.enum";
import { BookingAvailability } from "../models/new/booking/booking-availability";
import { BookingCompact } from "../models/new/booking/booking-compact.model";
import { BookingFull } from "../models/new/booking/booking-full.model";
import { ScheduledMessage } from "../models/new/booking/scheduled-message";
import { ScheduledReview } from "../models/new/booking/scheduled-review";
import { Review } from "../models/new/review";
import { TaskCompact } from "../models/new/tasks/task-compact.model";
import { UserCompact } from "../models/new/user/user-compact.model";
import { Payment } from "../models/payment";
import { SecurityDeduction } from "../models/security-deduction";
import {
  getAllBookings,
  getBadgeCount,
  getBookingExist,
  getCurrentPage,
  getFullBookingsById,
  getIsBookingsLoaded,
  getIsBookingsLoading,
  getIsFullBookingsLoaded,
  getIsFullBookingsLoading,
  getIsOwnerUpcomingBookingLoaded,
  getIsOwnerUpcomingBookingLoading,
  getOwnerUpcomingBookings,
  getPerPageCount,
  getReservationEnd,
  getReservationListingIds,
  getReservationSortType,
  getReservationSources,
  getReservationStart, getReservationTags,
  getReservationType, getReviewRating,
  getSortOrder,
  getTotalCount,
  getTotalPages,
  getVendorTasksForBookingId,
  getVendorTasksLoadedForBookingId,
  getVendorTasksLoadingForBookingId,
  State
} from "../reducers";
import { BookingService } from "../services/booking.service";
import Utils from "../utils/utils";

@Injectable()
export class BookingRepository {

  constructor(private store: Store<State>,
              private bookingService: BookingService) {
  }

  getIsBookingLoading(): Observable<boolean> {
    return this.store.select(getIsBookingsLoading);
  }

  getIsBookingLoaded(): Observable<boolean> {
    return this.store.select(getIsBookingsLoaded);
  }

  getIsFullBookingLoading(bookingId: number): Observable<boolean> {
    return this.store.select(state => getIsFullBookingsLoading(state, bookingId));
  }

  getIsFullBookingLoaded(bookingId: number): Observable<boolean> {
    return this.store.select(state => getIsFullBookingsLoaded(state, bookingId));
  }

  changeSortOrder(sortOrder: SortOrder) {
    return this.store.dispatch(new BookingSortOrderChange(sortOrder));
  }

  changeReviewRating(rating: number[]) {
    return this.store.dispatch(new BookingReviewRatingChange(rating));
  }

  getReviewRating(): Observable<number[]> {
    return this.store.select(getReviewRating);
  }

  getSortOrder(): Observable<SortOrder> {
    return this.store.select(getSortOrder);
  }

  changeReservationType(reservationType: ReservationType | HomeownerReservationType) {
    return this.store.dispatch(new BookingTypeChange(reservationType));
  }

  changeReservationListings(listingIds: number[]) {
    return this.store.dispatch(new BookingListingsChange(listingIds));
  }

  changeReservationSources(sources: string[]) {
    return this.store.dispatch(new BookingSourcesChange(sources));
  }

  changeReservationTags(tags: number[]) {
    return this.store.dispatch(new BookingTagsChange(tags));
  }

  changeReservationStart(date: Date) {
    return this.store.dispatch(new BookingStartChange(date));
  }

  changeReservationEnd(date: Date) {
    return this.store.dispatch(new BookingEndChange(date));
  }

  getReservationType(): Observable<ReservationType | HomeownerReservationType> {
    return this.store.select(getReservationType);
  }

  getReservationListingIds(): Observable<number[]> {
    return this.store.select(getReservationListingIds);
  }

  getReservationSources(): Observable<string[]> {
    return this.store.select(getReservationSources);
  }

  getReservationTags(): Observable<number[]> {
    return this.store.select(getReservationTags);
  }

  getReservationStart(): Observable<Date> {
    return this.store.select(getReservationStart);
  }

  getReservationEnd(): Observable<Date> {
    return this.store.select(getReservationEnd);
  }

  changeReservationSortType(sortType: ReservationSortType) {
    return this.store.dispatch(new BookingSortTypeChange(sortType));
  }

  getReservationSortType(): Observable<ReservationSortType> {
    return this.store.select(getReservationSortType);
  }

  getTotalCount(): Observable<number> {
    return this.store.select(getTotalCount);
  }

  changePerPageCount(perPageCount: number) {
    return this.store.dispatch(new BookingPerPageChange(perPageCount));
  }

  getPerPageCount(): Observable<number> {
    return this.store.select(getPerPageCount);
  }

  getTotalPages(): Observable<number> {
    return this.store.select(getTotalPages);
  }

  changeCurrentPage(currentPage: number) {
    return this.store.dispatch(new BookingPageChange(currentPage));
  }

  getCurrentPage(): Observable<number> {
    return this.store.select(getCurrentPage);
  }

  getAllBookings(force: boolean, is_review_responded?: boolean, owner?: boolean, is_owner_review?: boolean): Observable<BookingCompact[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsBookingLoading().pipe(take(1)).subscribe(l => {
      loading = l;
    });
    this.getIsBookingLoaded().pipe(take(1)).subscribe(l => {
      loaded = l;
    });

    if (!loading && (!loaded || force)) {
      let type: ReservationType | HomeownerReservationType;
      let sortOrder: SortOrder;
      let sortType: ReservationSortType;
      let review: number[];
      let page: number;
      let perPage: number;
      let listingIds: number[] = [];
      let sources: string[] = [];
      let tags: number[];
      let start: Date;
      let end: Date;
      this.store.dispatch(new BookingIndexRequest());
      this.getSortOrder().pipe(take(1)).subscribe(s => {
        sortOrder = s;
      });
      this.getReservationType().pipe(take(1)).subscribe(s => {
        type = s;
      });
      this.getReservationSortType().pipe(take(1)).subscribe(s => {
        sortType = s;
      });
      this.getReviewRating().pipe(take(1)).subscribe(s => review = s);
      this.getCurrentPage().pipe(take(1)).subscribe(s => page = s);
      this.getPerPageCount().pipe(take(1)).subscribe(s => perPage = s);
      this.getReservationListingIds().pipe(take(1)).subscribe(s => listingIds = s);
      this.getReservationSources().pipe(take(1)).subscribe(s => sources = s);
      this.getReservationTags().pipe(take(1)).subscribe(s => tags = s);
      this.getReservationStart().pipe(take(1)).subscribe(s => start = s);
      this.getReservationEnd().pipe(take(1)).subscribe(s => end = s);

      this.bookingService.getBookings(
        type,
        sortOrder,
        sortType,
        review,
        page,
        perPage,
        listingIds,
        sources,
        tags,
        start,
        end,
        is_review_responded,
        owner,
        is_owner_review
      ).subscribe(response => {
        this.store.dispatch(new BookingIndexSuccess({
          bookings: response.data,
          currentPage: response.meta.pagination.current_page,
          totalPages: response.meta.pagination.total_pages,
          perPageCount: response.meta.pagination.per_page,
          total: response.meta.pagination.total
        }));
      });
    }
    return this.store.select(getAllBookings).pipe(filter(t => !!t), map(t => t as BookingCompact[]),);
  }

  getFullBookingById(bookingId: number, force: boolean = false, includesAll: boolean = true): Observable<BookingFull> {
    let loading = false;
    let loaded = false;

    this.getIsFullBookingLoading(bookingId).pipe(take(1)).subscribe(l => loading = l);
    this.getIsFullBookingLoaded(bookingId).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new BookingShowRequest(+bookingId));
      this.bookingService.getBookingById(+bookingId, includesAll)
        .subscribe(response => {
          this.store.dispatch(new BookingShowSuccess(response));
        }, (err) => {
          this.store.dispatch(new AddBookingToNotExist(+bookingId));
        });
    }
    return this.store.select((state) => getFullBookingsById(state, +bookingId)).pipe(
      filter(v => !!v),
      filter(b => b.__type === TransformerType.FULL),
      map(b => b as BookingFull),);
  }

  // TODO Piyush: check here
  getCompactBookingById(bookingId: number): Observable<BookingCompact> {
    return this.store.select((state) => getFullBookingsById(state, +bookingId)).pipe(
      map(booking => booking as BookingCompact));
  }

  // TODO Store Integration
  getUpcomingBookingWithPropertyId(propertyId: number): Observable<BookingCompact[]> {
    return this.bookingService.getUpcomingBookingWithPropertyId(propertyId);
  }

  getHashedBooking(hashedId: string): Observable<any> {
    return this.bookingService.getHashedBooking(hashedId);
  }

  getCurrentRentalAgreement(): Observable<string> {
    return this.bookingService.getCurrentRentalAgreement();
  }

  updateScheduledMessage(bookingId: number, scheduledMessageId: number, data: any): Observable<ScheduledMessage> {
    return this.bookingService.updateScheduledMessage(bookingId, scheduledMessageId, data).pipe(map(res => {
      this.store.dispatch(new ScheduledMessageUpdateSuccess({scheduledMessage: res, bookingId: bookingId}));
      return res;
    }));

  }

  updateScheduledReview(bookingId: number, reviewId: number, data: any): Observable<ScheduledReview> {
    return this.bookingService.updateScheduledReview(reviewId, data).pipe(map(res => {
      this.store.dispatch(new ScheduledReviewUpdateSuccess({review: res, bookingId: bookingId}));
      return res;
    }));
  }

  attachAutoReview(bookingId: number, autoReviewId: number): Observable<ScheduledReview> {
    return this.bookingService.attachAutoReview(bookingId, autoReviewId).pipe(
      map(res => {
        this.store.dispatch(new ScheduledReviewUpdateSuccess({bookingId: bookingId, review: res}));
        return res;
      }));
  }

  addBooking(data: {
    property_id: number, start: string, end: string, number_of_guests: number,
    security_deposit_fee: number, guest_channel_fee: number, base_amount: number,
    cleaning_fee: number, commission: number, payment_method: string, guest_id?: number,
    first_name?: string, last_name?: string, email?: string, phone?: string,
    create_auto_task?: boolean, create_automation?: boolean
  }): Observable<BookingFull> {
    return this.bookingService.addBooking(data).pipe(map((res) => {
      this.store.dispatch(new BookingCreateSuccess(res));
      return res;
    }));
  }

  deleteBookingGuest(guestId: string, bookingId: string): Observable<boolean> {
    return this.bookingService.deleteBookingGuest(guestId, bookingId).pipe(map((res) => {
      this.store.dispatch(new BookingGuestDeleteSuccess({guestId: guestId, bookingId: bookingId}));
      return true;
    }));
  }

  updateBookingGuest(guestId: string, bookingId: string, data: {
    first_name?: string; last_name?: string; email?: string; phone_number?: string;
  }): Observable<UserCompact> {
    return this.bookingService.updateBookingGuest(guestId, bookingId, data).pipe(map((res) => {
      this.store.dispatch(new BookingGuestUpdateSuccess({bookingId: bookingId, guest: res}));
      return res;
    }));
  }

  addBookingGuest(bookingId: string, data: {
    first_name?: string; last_name?: string; email?: string; phone_number?: string;
  }): Observable<UserCompact> {
    return this.bookingService.addBookingGuest(bookingId, data).pipe(map((res) => {
      this.store.dispatch(new AddBookingGuestSuccess({bookingId: bookingId, guest: res}));
      return res;
    }));
  }

  getIsOwnerUpcomingBookingLoading(): Observable<boolean> {
    return this.store.select(getIsOwnerUpcomingBookingLoading);
  }

  getIsOwnerUpcomingBookingLoaded(): Observable<boolean> {
    return this.store.select(getIsOwnerUpcomingBookingLoaded);
  }

  getOwnerUpcomingBookings(homeOwnerId?: number, force: boolean = false): Observable<BookingCompact[]> {

    let loading = false;
    let loaded = false;

    this.getIsOwnerUpcomingBookingLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsOwnerUpcomingBookingLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {

      this.store.dispatch(new UpcomingOwnerBookingIndexRequest());
      this.bookingService.getOwnerUpcomingBookings(homeOwnerId).subscribe((res) => {
        this.store.dispatch(new UpcomingOwnerBookingIndexSuccess(res));
      });
    }

    return this.store.select(getOwnerUpcomingBookings);
  }

  deductSecurityFee(bookingId: number, data: { amount: number, description: string }): Observable<SecurityDeduction> {
    this.store.dispatch(new BookingSecurityDeductionRequest());
    return this.bookingService.deductSecurityFee(bookingId, data).pipe(map(res => {
      this.store.dispatch(new BookingSecurityDeductionSuccess({bookingId: bookingId, deduction: res}));
      return res;
    }));
  }

  collectPayment(bookingId: number, data: { amount: number, description: string, method: string, card_token?: string }): Observable<Payment> {
    const dataObj = Utils.removeNullFields(data);
    return this.bookingService.collectPayment(bookingId, dataObj).pipe(map(res => {
      this.store.dispatch(new BookingPaymentCreateSuccess({bookingId: bookingId, payment: res}));
      return res;
    }));
  }

  refundSecurity(bookingId: number): Observable<boolean> {
    return this.bookingService.refundSecurity(bookingId);
  }

  partialRefund(bookingId: number, data: {
    refund_amount: number,
    refund_security: boolean
  }): Observable<boolean> {
    return this.bookingService.partialRefund(bookingId, data);
  }

  fullRefund(bookingId: number): Observable<boolean> {
    return this.bookingService.fullRefund(bookingId);
  }

  importBookingFromAIRBNB(propertyId: number, bookingCode: string) {
    return this.bookingService.importBookingFromAIRBNB(propertyId, bookingCode).pipe(tap(booking => {
      this.store.dispatch(new BookingCreateSuccess(booking));
    }));
  }

  getGuestBadges(guestId: number, reservationId: number) {

    const badges$ = this.store.select(state => getBadgeCount(state, reservationId));

    let totalBadges;
    badges$.pipe(take(1)).subscribe(v => totalBadges = v);

    if (isNullOrUndefined(totalBadges)) {
      this.bookingService.getGuestBadgeCount(guestId, reservationId).subscribe(res => {
        const totalBadges = res.data;
        this.store.dispatch(new BookingGuestSetBadgeCountAction({
          bookingId: reservationId,
          count: totalBadges
        }));
      });
    }

    return badges$.pipe(filter(v => !!v), take(1),);

  }

  updateBookingStatus(bookingId: number, status: string): Observable<BookingFull> {
    this.store.dispatch(new BookingUpdateRequest());
    return this.bookingService.updateBookingStatus(bookingId, status).pipe(map(booking => {
      this.store.dispatch(new BookingUpdateSuccess(booking));
      return booking;
    }));
  }

  updateBooking(booking_id: number, thread_id: number = null, data: {
    listing_id?: number,
    status?: string,
    check_in_time?: string,
    check_out_time?: string,
    checked_in?: boolean,
    booking_notes?: string,
    auto_review_enabled?: boolean
  }): Observable<BookingFull> {

    this.store.dispatch(new BookingUpdateRequest());
    return this.bookingService.updateBooking(booking_id, thread_id, data).pipe(map(booking => {
      this.store.dispatch(new BookingUpdateSuccess(booking));
      return booking;
    }));
  }

  checkAvailability(booking_id: number, start: Date, end: Date, property_id: number): Observable<BookingAvailability> {
    return this.bookingService.checkAvailability(booking_id, start, end, property_id);
  }

  updateProperty(bookingId: number, propertyId: number): Observable<BookingFull> {
    return this.bookingService.updateProperty(bookingId, propertyId).pipe(
      map(res => {
        this.store.dispatch(new BookingUpdateSuccess(res));
        return res;
      }));
  }

  checkAirbnbAvailability(data: {
    booking_id: number,
    check_in: Date,
    check_out: Date,
    listing_id: number,
    accommodation_fee?: number,
    guests: number
  }): Observable<any> {
    return this.bookingService.checkAirbnbAvailability(data);
  }

  alterBooking(data: any): Observable<BookingFull> {
    this.store.dispatch(new BookingUpdateRequest());
    return this.bookingService.alterBooking(data).pipe(map(booking => {
      this.store.dispatch(new BookingUpdateSuccess(booking));
      return booking;
    }));
  }

  alterAirbnbBooking(data): Observable<BookingFull> {
    this.store.dispatch(new BookingUpdateRequest());
    return this.bookingService.alterAirbnbBooking(data).pipe(map(booking => {
      this.store.dispatch(new BookingUpdateSuccess(booking));
      return booking;
    }));
  }

  updateBookingCommission(bookingId: number, commision: number): Observable<BookingFull> {
    this.store.dispatch(new BookingUpdateRequest());
    return this.bookingService.updateBookingCommission(bookingId, commision).pipe(map(booking => {
      this.store.dispatch(new BookingUpdateSuccess(booking));
      return booking;
    }));
  }

  getBookingStatus(currentStatus: BookingStatus): BookingStatus[] {
    switch (currentStatus) {
      case BookingStatus.CHECKED_IN: {
        return [BookingStatus.CHECKED_OUT, BookingStatus.CANCELLED, BookingStatus.DENIED];
      }
      case BookingStatus.CHECKED_OUT: {
        return [BookingStatus.DENIED, BookingStatus.CANCELLED];
      }
      case BookingStatus.NOT_POSSIBLE: {
        return [];
      }
      case BookingStatus.AWAITING_PAYMENT: {
        return [BookingStatus.ACCEPTED, BookingStatus.CANCELLED, BookingStatus.DENIED];
      }
      case BookingStatus.ACCEPTED: {
        return [BookingStatus.CHECKED_IN, BookingStatus.CANCELLED, BookingStatus.DENIED];
      }
      case BookingStatus.DENIED: {
        return [];
      }
      case BookingStatus.PENDING: {
        return [BookingStatus.ACCEPTED, BookingStatus.CANCELLED, BookingStatus.DENIED, BookingStatus.AWAITING_PAYMENT, BookingStatus.TIMEDOUT, BookingStatus.NOT_POSSIBLE];
      }
      case BookingStatus.REQUESTED: {
        return [BookingStatus.PENDING, BookingStatus.AWAITING_PAYMENT, BookingStatus.TIMEDOUT, BookingStatus.NOT_POSSIBLE, BookingStatus.ACCEPTED, BookingStatus.CANCELLED, BookingStatus.DENIED];
      }
      case BookingStatus.INQUIRY: {
        return [BookingStatus.CANCELLED, BookingStatus.REQUESTED, BookingStatus.ACCEPTED, BookingStatus.AWAITING_PAYMENT, BookingStatus.DENIED, BookingStatus.PENDING, BookingStatus.NOT_POSSIBLE];
      }
      case BookingStatus.CANCELLED: {
        return [];
      }
      default:
        return [];
    }
  }

  getHomeOwnerBookingStatus(currentStatus: BookingStatus): BookingStatus[] {
    switch (currentStatus) {
      case BookingStatus.ACCEPTED: {
        return [BookingStatus.CANCELLED];
      }
      default: {
        return [];
      }
    }
  }

  acceptAlterationRequest(alterationId: number) {
    return this.bookingService.acceptAlterationRequest(alterationId).pipe(map(res => {
      this.store.dispatch(new BookingUpdateSuccess(res));
      return res;
    }));
  }

  rejectAlterationRequest(alterationId: number) {
    return this.bookingService.rejectAlterationRequest(alterationId).pipe(map(res => {
      this.store.dispatch(new BookingUpdateSuccess(res));
      return res;
    }));
  }

  cancelAlterationRequest(alterationId: number) {
    return this.bookingService.rejectAlterationRequest(alterationId).pipe(map(res => {
      this.store.dispatch(new BookingUpdateSuccess(res));
      return res;
    }));
  }

  requestSendMoney(bookingId: number, data: { status: string, amount: number, message: string, reason: string }): Observable<any> {
    return this.bookingService.requestSendMoney(bookingId, data);
  }

  getBookingInquiriesForContact(bookingId: number): Observable<BookingCompact[]> {
    return this.bookingService.getBookingInquiriesForContact(bookingId);
  }

  getGuestReviews(bookingId: number): Observable<Review[]> {
    return this.bookingService.getGuestReviews(bookingId);
  }

  resendPaymentLink(bookingId: number) {
    return this.bookingService.resendPaymentLink(bookingId);
  }

  extendExpireTime(bookingId: number, data: any) {
    return this.bookingService.extentPaymentExpireTime(bookingId, data).pipe(map(res => {
      this.store.dispatch(new BookingUpdateSuccess(res));
      return res;
    }));
  }

  getIsBookingExist(bookingId: number): Observable<boolean> {
    return this.store.select(state => getBookingExist(state, bookingId));
  }

  getVendorTasksLoadingForBooking(bookingId: number): Observable<boolean> {
    return this.store.select(state => getVendorTasksLoadingForBookingId(state, bookingId));
  }

  getVendorTasksLoadedForBooking(bookingId: number): Observable<boolean> {
    return this.store.select(state => getVendorTasksLoadedForBookingId(state, bookingId));
  }

  getVendorTasksForBooking(bookingId: number): Observable<TaskCompact[]> {
    let loading: boolean;
    let loaded: boolean;

    this.getVendorTasksLoadingForBooking(bookingId)
      .pipe(take(1)).subscribe(l => loading = l);

    this.getVendorTasksLoadedForBooking(bookingId)
      .pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new VendorTasksIndexRequest(bookingId));
      this.bookingService.getVendorTasksForBooking(bookingId)
        .subscribe(tasks => this.store.dispatch(new VendorTasksIndexSuccess({bookingId, tasks})));
    }

    return this.store.select(state => getVendorTasksForBookingId(state, bookingId)).pipe(filter(v => !!v));
  }

  validPromo(code: string) {
    return this.bookingService.validPromo(code);
  }
}
