import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Task } from "app/models/task";
import { isNullOrUndefined } from "util";

import { TaskEnumHelper } from "../../../enums/task.enum";
import { TaskCompact } from "../../../models/new/tasks/task-compact.model";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { State } from "../../../reducers";
import { BookingRepository } from "../../../repository/booking.repository";
import { StayDuvetService } from "../../../services/stayduvet";
import {takeWhile} from "rxjs/operators";


@Component({
  selector: "sd-reservation-vendor-task-card",
  template: `
      <mat-card class="generalCard">
          <span style="color:#194267;font-size: 16px"><b>TASK</b></span>
          <br>
          <span style="color:gray;font-weight:100 !important;">These are the tasks that are auto created after
   every confirmed reservation for this listing.</span>
          <div style="padding-top: 30px"></div>
          <div fxLayout="column" style="margin-left:10px" fxLayoutGap="10px">
              <div class="headerSpan" fxLayout="row" fxFlex="100%">
                  <span [style.flex]="'0 0 24%'" style="font-size: 11px"><b>Title</b></span>
                  <span [style.flex]="'0 0 16%'" style="font-size: 11px"><b>Assignee</b></span>
                  <span [style.flex]="'0 0 13%'" style="font-size: 11px"><b>Due On</b></span>
                  <span [style.flex]="'0 0 17%'" style="font-size: 11px"><b>Category</b></span>
                  <span [style.flex]="'0 0 17%'" style="font-size: 11px"><b>Status</b></span>
                  <span [style.flex]="'0 0 10%'" style="font-size: 11px"><b>Price</b></span>
              </div>
              <div fxLayout="row" fxFlex="100%">
                  <hr class="sectionBorder">
              </div>
              <div *ngFor="let task of tasks" fxLayout="column">
                  <div fxLayout="row" fxFlex="100%">
                      <div [style.flex]="'0 0 24%'" style="margin-top:8px;cursor:pointer">
                          <span style="font-size: 11px" *ngIf="task.title"
                                (click)="openTask(task)">{{ task.title }}</span>
                          <span style="font-size: 11px" *ngIf="!task.title">------</span>
                      </div>
                      <div [style.flex]="'0 0 16%'" style="margin-top:8px">
              <span style="font-size: 11px" *ngIf="task.assignee.data.id">{{ task.assignee.data.first_name
                  }} {{task.assignee.data.last_name}}</span>
                          <span *ngIf="!task.assignee.data.id">------</span>
                      </div>
                      <div [style.flex]="'0 0 13%'" style="margin-top:8px">
                          <span *ngIf="task.due_date"
                                style="font-size: 11px;">{{task.due_date | dateFix | date:'dd-MMM'}}</span>
                          <span *ngIf="!task.due_date" style="font-size: 11px;">------</span>
                      </div>
                      <div [style.flex]="'0 0 17%'" style="margin-top:8px">
              <span *ngIf="task.type" style="font-size:11px ">{{ TaskEnumHelper.getTaskCategoryTitle(task.type)
                  }}</span>
                          <span *ngIf="!task.type" style="font-size:11px ">------</span>
                      </div>
                      <div [style.flex]="'0 0 17%'" style="margin-left: -2%">
                          <mat-chip-list>
                              <mat-chip selected="true"
                                        matTooltip="{{ TaskEnumHelper.getTaskStatusEnum(task.status).tooltipText }}"
                                        [matTooltipPosition]="'above'"
                                        [ngStyle]="{
                                 'background': TaskEnumHelper.getTaskStatusEnum(task.status).color,
                                 'text-align':'center',
                                 'color': task.status === 'scheduled' ? 'black' : 'white'
                               }">
                        <span *ngIf="task.status" style="width: 100%;text-align: center">
                          {{ TaskEnumHelper.getTaskStatusEnum(task.status).title }}
                        </span>
                                  <span *ngIf="!task.status">------</span>
                              </mat-chip>
                          </mat-chip-list>
                      </div>
                      <div [style.flex]="'0 0 10%'" style="margin-top:8px; margin-left: 2%">
                          <span *ngIf="task.amount">{{task.amount | currency:'USD':true}}</span>
                          <span *ngIf="!task.amount">------</span>
                      </div>
                  </div>
                  <div fxLayout="row" fxFlex="100%">
                      <hr fxFlexAlign="center" class="contentBorder">
                  </div>
              </div>
              <div fxLayoutAlign="center" *ngIf="tasks.length === 0">
                  <span>No Automated Tasks with this reservation.</span>
              </div>
          </div>
      </mat-card>
  `,
  styles: [`

      span {
          font-size: 14px;
      }

      .headerSpan > span {
          color: #13304b !important;
          font-size: 16px !important;
      }

      .generalCard {
          background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%); /* Chrome10+,Safari5.1+ */;
          padding: 15px !important;
      }

      .financialsText span {
          font-size: 13px;
      !important;

      }

      .sectionBorder {
          border: none;
          /* Set the hr color */
          color: #cdcdcd; /* old IE */
          background-color: #cdcdcd; /* Modern Browsers */
          height: 1px;
          width: 100%;
      }

      .contentBorder {
          border: none;
          /* Set the hr color */
          color: #cccccc; /* old IE */
          background-color: #cccccc; /* Modern Browsers */
          height: 1px;
          width: 100%;
      }

      /deep/ .mat-chip:not(.mat-basic-chip) {
          padding: 8px 4px 8px 4px !important;
          font-size: 13px !important;
      }

      mat-chip {
          width: 80%;
          text-align: center;
      }

      .size {
          font-size: 5px;
      }

  `]
})
export class ReservationVendorTaskCardComponent implements OnInit, OnDestroy {
  tasks: TaskCompact[] = [];
  assignees: UserCompact[] = [];
  reservationsLoaded = false;
  reservationsLoading = false;
  updating: { [id: number]: boolean } = {};
  TaskEnumHelper = TaskEnumHelper;
  private dialogRef: MatDialogRef<any>;
  private isAlive: boolean = true;

  constructor(private service: StayDuvetService,
              private bookingRepo: BookingRepository,
              private store: Store<State>,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe(params => {
        const reservationId = +params.id;
        this.bookingRepo.getVendorTasksLoadingForBooking(reservationId)
          .pipe(takeWhile(() => this.isAlive))
          .subscribe(l => this.reservationsLoading = l);

        this.bookingRepo.getVendorTasksLoadedForBooking(reservationId)
          .pipe(takeWhile(() => this.isAlive))
          .subscribe(l => this.reservationsLoaded = l);

        this.bookingRepo.getVendorTasksForBooking(reservationId)
          .pipe(takeWhile(() => this.isAlive))
          .subscribe(b => {
            this.tasks = b;
          });
      });
  }


  ngOnDestroy(): void {
    this.isAlive = false;
  }

  getAssigneeName(task: Task) {
    const assignee = this.assignees.find(data => data.id === task.assignee_id);

    if (isNullOrUndefined(assignee)) {
      let name = task.assignee.data.first_name;
      if (!isNullOrUndefined(task.assignee.data.last_name)) {
        name = name + " " + task.assignee.data.last_name;
      }

      return name;
    }

    let name = assignee.first_name;
    if (!isNullOrUndefined(assignee.last_name)) {
      name = name + " " + assignee.last_name;
    }

    return name;
  }

  openTask(task) {
    this.router.navigate(["/tasks/" + task.id, {
      from: "reservations",
      id: task.booking_id
    }]);
  }
}
