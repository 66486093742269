import { ConciergeDraft } from "../../../../models/new/drafts/concierge-draft.model";
import { Action } from "../../../action";

export enum ConciergeDraftActionTypes {
  INDEX_REQUEST = "[Concierge Drafts NEW] Index Request",
  INDEX_SUCCESS = "[Concierge Drafts NEW] Index Success",

  CREATE_REQUEST = "[Concierge Drafts NEW] Create Request",
  CREATE_SUCCESS = "[Concierge Drafts NEW] Create Success",

  SHOW_REQUEST = "[Concierge Drafts NEW] Show Request",
  SHOW_SUCCESS = "[Concierge Drafts NEW] Show Success",

  UPDATE_REQUEST = "[Concierge Drafts NEW] Update Request",
  UPDATE_SUCCESS = "[Concierge Drafts NEW] Update Success",

  DELETE_REQUEST = "[Concierge Drafts NEW] Delete Request",
  DELETE_SUCCESS = "[Concierge Drafts NEW] Delete Success"
}

export class ConciergeDraftIndexRequest implements Action {
  readonly type = ConciergeDraftActionTypes.INDEX_REQUEST;
}

export class ConciergeDraftIndexSuccess implements Action {
  readonly type = ConciergeDraftActionTypes.INDEX_SUCCESS;

  constructor(public payload: ConciergeDraft[]) {
  }
}

export class ConciergeDraftCreateRequest implements Action {
  readonly type = ConciergeDraftActionTypes.CREATE_REQUEST;
}

export class ConciergeDraftCreateSuccess implements Action {
  readonly type = ConciergeDraftActionTypes.CREATE_SUCCESS;

  constructor(public payload: ConciergeDraft) {
  }
}

export class ConciergeDraftShowRequest implements Action {
  readonly type = ConciergeDraftActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {

  }
}

export class ConciergeDraftShowSuccess implements Action {
  readonly type = ConciergeDraftActionTypes.SHOW_SUCCESS;

  constructor(public payload: ConciergeDraft) {
  }
}

export class ConciergeDraftUpdateRequest implements Action {
  readonly type = ConciergeDraftActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class ConciergeDraftUpdateSuccess implements Action {
  readonly type = ConciergeDraftActionTypes.UPDATE_SUCCESS;

  constructor(public payload: ConciergeDraft) {
  }
}

export class ConciergeDraftDeleteRequest implements Action {
  readonly type = ConciergeDraftActionTypes.DELETE_REQUEST;

  constructor(public payload: number) {
  }
}

export class ConciergeDraftDeleteSuccess implements Action {
  readonly type = ConciergeDraftActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
