import {Component, Input} from "@angular/core";

@Component({
  selector: "sd-read-more",
  template: `
    <div class="content" [class.collapsed]="isCollapsed">
      <ng-container *ngIf="type === 'text'">
      {{text}}
      </ng-container>
      <div *ngIf="type === 'html'" [innerHTML]="text"></div>
    </div>
    <span *ngIf="text && text.length > 100" style="cursor: pointer; font-weight: bold"
          (click)="isCollapsed = !isCollapsed">{{isCollapsed ? "Read more" : "Read Less"}}</span>
  `,
  styles: [`
    div.collapsed {
      max-height: 150px;
      overflow: hidden;
    }

    .content {
      margin: 0;
      padding: 0;
      transition: all 0.1s;
      white-space: pre-line!important;
      word-break: break-word;
    }

  `]
})

export class ReadMoreComponent {
  isCollapsed = true;
  @Input() text: string;
  @Input() type: string = "text";
}
