import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { TaskFull } from "../../../../models/new/tasks/task-full.model";
import { UserNameOnly } from "../../../../models/new/user/user-name-only.model";
import { UserModelUtil } from "../../../../models/utils/user-model.util";
import { OptionsRepository } from "../../../../repository/options.repository";
import { TaskRepository } from "../../../../repository/task.repository";

@Component({
  selector: "sd-employee-change", template: `
    <sd-modal-popup-layout title="Change Employee">
      <div fxLayout="row" style="align-items: center">
        <div fxLayout="row" fxFlex="100%">
          <mat-form-field [color]="'accent'" fxFlex="40%" fxLayout="row" style="margin-top:2%;">
            <mat-select
              placeholder="Select Employee" [(ngModel)]="selectedEmployeeId">
              <mat-option *ngFor="let emp of employees" [value]="emp.id">
                {{ UserModelUtil.getFullName(emp) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%" style="height: fit-content">
          <button mat-raised-button color="primary" matDialogClose>Cancel</button>
          <button [disabled]="isUpdating" mat-raised-button color="accent" (click)="changeEmployee()">Save</button>
          <mat-spinner [diameter]="30" [strokeWidth]="4" color="accent" *ngIf="isUpdating"></mat-spinner>
        </div>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [``]
})

export class EmployeeChangePopupComponent implements OnInit, OnDestroy {

  @Input() task: TaskFull;

  isUpdating: boolean = false;
  employees: UserNameOnly[] = [];
  selectedEmployeeId: number;

  UserModelUtil = UserModelUtil;

  private destroyed$ = new Subject();

  constructor(private optionsRepo: OptionsRepository,
              private dialogRef: MatDialogRef<EmployeeChangePopupComponent>,
              private taskRepo: TaskRepository) {
  }

  ngOnInit() {
    this.selectedEmployeeId = this.task.employee_id;
    this.optionsRepo.getHousekeeperEmployees().pipe(takeUntil(this.destroyed$))
      .subscribe((employees) => {
        this.employees = employees;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  changeEmployee() {
    this.isUpdating = true;
    this.taskRepo.updateTaskById(this.task.id, {employee_id: this.selectedEmployeeId}).subscribe(res => {
      this.isUpdating = false;
      this.dialogRef.close();
    });
  }
}
