import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatest as observableCombineLatest, Observable } from "rxjs";

import { map, take } from "rxjs/operators";
import { getAppIsBootstrapped, getAppLandingUrl, State } from "../reducers/index";
import { UserRepository } from "../repository/user-repository";
import { AppService } from "../services/app.service";

@Injectable()
export class BootstrapGuard implements CanActivate {

  constructor(private router: Router,
              private userRepo: UserRepository,
              private store: Store<State>) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {

    if (!AppService.hasLoginToken()) {
      console.log("called login");
      this.router.navigate(["/login"]);
      return false;
    }

    const observables = observableCombineLatest(
      this.userRepo.getIsUserLoggedIn(),
      this.store.select(getAppIsBootstrapped),
      (isLoggedIn, isBootstrapped) => {
        return {
          isLoggedIn: isLoggedIn,
          isBootstrapped: isBootstrapped
        };
      }
    );

    return observables.pipe(map((data) => {
      if (data.isLoggedIn && data.isBootstrapped) {
        this.store.select(getAppLandingUrl).pipe(take(1)).subscribe((value) => {
          this.router.navigate([value]);
        });
      }

      return !data.isBootstrapped;
    }));
  }
}
