import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

import { Action } from "../../../actions/action";
import { RespondReviewsActionTypes } from "../../../actions/new/reviews/respond-reviews";
import { ReviewsSortType, SortOrder } from "../../../enums/common.enum";
import { BookingCompact } from "../../../models/new/booking/booking-compact.model";
import { GuestReview } from "../../../models/new/reviews/guest-review";

export interface RespondReviewsState extends EntityState<GuestReview> {
  isLoading: boolean;
  isLoaded: boolean;

  currentPage: number;
  perPage: number;
  total: number;

  sort_order: SortOrder;
  sort_type: ReviewsSortType;

  rating: number[];
  listingIds: number[];

  taskData: {
    review: GuestReview;
    response: string;
    draftId: number;
  };
}

export const respondReviewsAdapter: EntityAdapter<GuestReview> = createEntityAdapter<GuestReview>({
  selectId: (review: GuestReview) => review.id,
});

export const initialState: RespondReviewsState = respondReviewsAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  currentPage: 1,
  perPage: 0,
  total: 0,

  sort_order: SortOrder.DSC,
  sort_type: ReviewsSortType.START,

  rating: [1,2,3,4,5],
  listingIds: [],

  taskData: {
    review: null,
    response: null,
    draftId: null
  }
});

export function respondReviewsReducer(state: RespondReviewsState = initialState, action: Action): RespondReviewsState {
  switch (action.type) {
    case RespondReviewsActionTypes.INDEX_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      };
    }

    case RespondReviewsActionTypes.INDEX_SUCCESS: {
      const reviews = action.payload as {
        reviews: GuestReview[], currentPage: number, perPage: number, total: number
      };
      return respondReviewsAdapter.addAll(reviews.reviews, {
        ...state,
        isLoading: false,
        isLoaded: true,
        currentPage: reviews.currentPage,
        perPage: reviews.perPage,
        total: reviews.total
      });
    }

    case RespondReviewsActionTypes.CREATE_RESPONSE: {
      const reviewId = action.payload as number;
      const newTotal = state.total - 1;
      return respondReviewsAdapter.removeOne(reviewId, {
        ...state,
        total: newTotal
      });
    }

    case RespondReviewsActionTypes.TASK_DATA_CHANGE: {
      const data = action.payload as { review: GuestReview, response: string, draftId: number };
      return {
        ...state,
        taskData: {
          ...state.taskData,
          review: data.review,
          response: data.response,
          draftId: data.draftId
        }
      };
    }

    case RespondReviewsActionTypes.PER_PAGE_CHANGE: {
      return {
        ...state,
        perPage: action.payload as number,
      };
    }

    case RespondReviewsActionTypes.PAGE_CHANGE: {
      return {
        ...state,
        currentPage: action.payload as number,
      };
    }

    case RespondReviewsActionTypes.SORT_ORDER_CHANGE: {
      return {
        ...state,
        sort_order: action.payload as SortOrder
      };
    }

    case RespondReviewsActionTypes.SORT_TYPE_CHANGE: {
      return {
        ...state,
        sort_type: action.payload as ReviewsSortType
      };
    }

    case RespondReviewsActionTypes.RATING_CHANGE: {
      return {
        ...state,
        rating: action.payload as number[]
      };
    }

    case RespondReviewsActionTypes.LISTINGS_CHANGE: {
      return {
        ...state,
        listingIds: action.payload as number[]
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsRespondReviewsLoading = (state: RespondReviewsState) => state.isLoading;
export const _getIsRespondReviewsLoaded = (state: RespondReviewsState) => state.isLoaded;

export const _getRespondReviewsCurrentPage = (state: RespondReviewsState) => state.currentPage;
export const _getRespondReviewsPerPage = (state: RespondReviewsState) => state.perPage;
export const _getRespondReviewsTotal = (state: RespondReviewsState) => state.total;

export const _getRespondReviewsSortOrder = (state: RespondReviewsState) => state.sort_order;
export const _getRespondReviewsSortType = (state: RespondReviewsState) => state.sort_type;

export const _getRespondReviewsRating = (state: RespondReviewsState) => state.rating;
export const _getRespondReviewsListing = (state: RespondReviewsState) => state.listingIds;
export const _getRespondReviewsTaskData = (state: RespondReviewsState) => state.taskData;
