import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import * as _ from "lodash";
import * as moment from "moment";

import { Action } from "../../../actions/action";
import { BookingActionTypes } from "../../../actions/new/bookings/bookings";
import { MessageActionTypes } from "../../../actions/new/inbox/message";
import { ThreadActionTypes } from "../../../actions/new/inbox/thread";
import { ThreadFilter } from "../../../enums/thread-filter.enum";
import { BookingCompact } from "../../../models/new/booking/booking-compact.model";
import { BookingFull } from "../../../models/new/booking/booking-full.model";
import { ThreadCompact } from "../../../models/new/inbox/thread-compact";
import { ThreadFull } from "../../../models/new/inbox/thread-full";

export interface ThreadState extends EntityState<ThreadCompact | ThreadFull> {
  loadingPagesId: number[];
  loadedPagesId: number[];
  threadIdsForPages: { [key: number]: number[] };

  fullLoadingIds: number[];
  fullLoadedIds: number[];

  selectedThreadId?: number;
  selectedBookingId?: number;


  currentPage: number;
  totalPages: number;
  totalCount: number;

  threadFilters: ThreadFilter[];
  listingIds: number[];
  assigneeIds: number[];
  offset?: string;
  offset_date?: string;
}

export const threadAdapter: EntityAdapter<ThreadCompact | ThreadFull> = createEntityAdapter<ThreadCompact | ThreadFull>({
  selectId: (thread: ThreadCompact) => thread.id
});

export const initialState: ThreadState = threadAdapter.getInitialState({
  loadingPagesId: [],
  loadedPagesId: [],
  threadIdsForPages: {},
  fullLoadingIds: [],
  fullLoadedIds: [],

  currentPage: 1,
  totalPages: 0,
  totalCount: 0,

  threadFilters: [],
  listingIds: [],
  assigneeIds: [],
});

export function threadReducer(state: ThreadState = initialState, action: Action): ThreadState {
  switch (action.type) {

    case ThreadActionTypes.RESET_DATA: {
      return initialState;
    }

    case ThreadActionTypes.NEXT_PAGE_REQUEST: {
      const pageId = action.payload as number;

      let loadingPagesId = state.loadingPagesId;
      const loadingIndex = _.indexOf(loadingPagesId, pageId);

      // Adding if not present
      if (loadingIndex === -1) {
        loadingPagesId = [
          ...loadingPagesId,
          pageId
        ];
      }

      let loadedPagesId = state.loadedPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, pageId);

      // Removing if already added
      if (loadedIndex !== -1) {
        loadedPagesId = _.remove(loadedPagesId, pageId);
      }


      return {
        ...state,
        loadingPagesId: loadingPagesId,
        loadedPagesId: loadedPagesId
      };
    }

    case ThreadActionTypes.NEXT_PAGE_SUCCESS: {
      const payload = action.payload as {
        threads: ThreadCompact[],
        currentPage: number,
        totalPages: number,
        totalCount: number,
      };

      let loadingPageIds = state.loadingPagesId;
      const loadingIndex = _.indexOf(loadingPageIds, payload.currentPage);

      // Removing if loading
      if (loadingIndex !== -1) {
        loadingPageIds = _.remove(loadingPageIds, payload.currentPage);
      }

      let loadedPagesId = state.loadedPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, payload.currentPage);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        loadedPagesId = [
          ...loadedPagesId,
          payload.currentPage
        ];
      }

      return threadAdapter.addMany(payload.threads, {
        ...state,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
        totalCount: payload.totalCount,
        loadingPagesId: loadingPageIds,
        loadedPagesId: loadedPagesId,
        threadIdsForPages: {
          ...state.threadIdsForPages,
          [payload.currentPage]: payload.threads.map(t => t.id)
        }
      });
    }

    case ThreadActionTypes.SHOW_REQUEST: {
      const threadId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, threadId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          threadId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, threadId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, threadId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case ThreadActionTypes.SHOW_SUCCESS: {
      const thread = action.payload as ThreadFull;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, thread.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, thread.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, thread.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          thread.id
        ];
      }

      const addedState = threadAdapter.addOne(thread, state);


      return threadAdapter.updateOne({
        id: thread.id,
        changes: thread
      }, {
        ...addedState,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      });
    }


    case ThreadActionTypes.UPDATE_SUCCESS: {
      const thread = action.payload as ThreadFull;

      return threadAdapter.updateOne({
        id: thread.id,
        changes: thread
      }, state);
    }

    case ThreadActionTypes.CHANGE_LISTING_IDS: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        threadIdsForPages: {},
        listingIds: action.payload as number[]
      };
    }

    case ThreadActionTypes.CHANGE_ASSIGNEE_IDS: {
      return {
        ...state,
        currentPage: 1,
        loadingPagesId: [],
        loadedPagesId: [],
        threadIdsForPages: {},
        assigneeIds: action.payload as number[]
      };
    }

    case ThreadActionTypes.CHANGE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload
      };
    }

    case ThreadActionTypes.ADD_THREAD_FILTER: {
      const filter = action.payload as ThreadFilter[];

      return {
        ...state,
        threadFilters: [...filter]
      };
    }

    case ThreadActionTypes.REMOVE_THREAD_FILTER: {
      const filter = action.payload as ThreadFilter;

      let filters = state.threadFilters;
      const index = filters.indexOf(filter);

      if (index !== -1) {
        filters = [
          ...filters.slice(0, index),
          ...filters.slice(index + 1)
        ];
      }

      return {
        ...state,
        threadFilters: filters
      };

    }

    case ThreadActionTypes.CHANGE_THREAD_OFFSET: {
      const payload = action.payload as { offset: string, date: string };
      return {
        ...state,
        offset: payload.offset,
        offset_date: payload.date,
      };
    }

    case ThreadActionTypes.SET_SELECTED_THREAD: {
      const threadId = action.payload.thread_id as number;
      const bookingId = action.payload.booking_id as number;


      return {
        ...state,
        selectedThreadId: threadId,
        selectedBookingId: bookingId
      };

    }

    case ThreadActionTypes.REMOVE_THREAD_SUCCESS: {
      const threadId = action.payload.thread_id as number;

      let threadIds = state.threadIdsForPages[state.currentPage];
      threadIds = _.remove(threadIds, threadId);

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, threadId);

      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, threadId);
      }

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, threadId);

      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, threadId);
      }

      console.log("threads", threadIds, fullyLoadedIds, fullyLoadingIds, threadId, state.selectedThreadId);

      return threadAdapter.removeOne(threadId, {
        ...state,
        threadIdsForPages: {
          ...state.threadIdsForPages,
          [state.currentPage]: threadIds
        },
        fullLoadedIds: fullyLoadedIds,
        fullLoadingIds: fullyLoadingIds
      });
    }

    case ThreadActionTypes.SPECIAL_OFFER_SUCCESS: {
      const payload = action.payload as { threadId: number, bookingId: number };

      const thread = state.entities[payload.threadId];

      if (thread) {

        let bookings = thread.bookings.data || [];

        const index = bookings.map(b => b.id).indexOf(payload.bookingId);

        if (index !== -1) {

          let booking = bookings[index] as BookingCompact;

          const date = moment().utc().format("YYYY-DD-MM HH:mm:ss");

          booking = {
            ...booking,
            so_sent_on: date
          };

          bookings = [
            ...bookings.slice(0, index),
            booking,
            ...bookings.slice(index + 1),
          ];
        }

        return threadAdapter.updateOne({
          id: payload.threadId,
          changes: {
            bookings: {
              data: bookings
            }
          }
        }, state);

      }
      return state;
    }

    case MessageActionTypes.CREATE_SUCCESS: {
      const payload = action.payload as { threadId: number, bookingId: number };

      const thread = state.entities[payload.threadId];

      if (thread) {

        let bookings = thread.bookings.data || [];

        const index = bookings.map(b => b.id).indexOf(payload.bookingId);

        if (index !== -1) {

          let booking = bookings[index] as BookingCompact;

          booking = {
            ...booking,
            requires_response: false,
          };

          bookings = [
            ...bookings.slice(0, index),
            booking,
            ...bookings.slice(index + 1),
          ];
        }

        return threadAdapter.updateOne({
          id: payload.threadId,
          changes: {
            bookings: {
              data: bookings
            }
          }
        }, state);

      }
      return state;
    }

    case BookingActionTypes.UPDATE_SUCCESS: {
      const booking = action.payload as BookingFull;
      const threadId = booking.thread_id;

      if (threadId) {
        const thread = state.entities[threadId];

        if (thread) {

          let bookings = thread.bookings.data;

          const index = bookings.map(b => b.id).indexOf(booking.id);

          if (index !== -1) {

            bookings = [
              ...bookings.slice(0, index),
              booking,
              ...bookings.slice(index + 1)
            ];
          }

          return threadAdapter.updateOne({
            id: threadId,
            changes: {
              bookings: {
                data: bookings
              }
            }
          }, state);
        }
      }
      return state;
    }

    default: {
      return {
        ...state
      };
    }
  }
}


export const _getIsThreadLoadingForPageNumber = (state: ThreadState, pageNumber: number) => {
  return state.loadingPagesId.indexOf(pageNumber) !== -1;
};

export const _getIsThreadLoadedForPageNumber = (state: ThreadState, pageNumber: number) => {
  return state.loadedPagesId.indexOf(pageNumber) !== -1;
};

export const _getAllThreadIdsForPageNumber = (state: ThreadState, pageNumber: number) => {
  let ids = [];

  if (state.threadIdsForPages[pageNumber]) {
    ids = state.threadIdsForPages[pageNumber];
  }

  return ids;
};

export const _getIsFullThreadLoading = (state: ThreadState, threadId: number) => {
  return state.fullLoadingIds.indexOf(threadId) !== -1;
};
export const _getIsFullThreadLoaded = (state: ThreadState, threadId: number) => {
  return state.fullLoadedIds.indexOf(threadId) !== -1;
};

export const _getSelectedThreadId = (state: ThreadState) => state.selectedThreadId;
export const _getSelectedBookingId = (state: ThreadState) => state.selectedBookingId;

export const _getThreadCurrentPage = (state: ThreadState) => state.currentPage;
export const _getThreadTotalPages = (state: ThreadState) => state.totalPages;
export const _getThreadTotalCount = (state: ThreadState) => state.totalCount;

export const _getThreadFilters = (state: ThreadState) => state.threadFilters;
export const _getThreadListingIds = (state: ThreadState) => state.listingIds;
export const _getThreadAssigneeIds = (state: ThreadState) => state.assigneeIds;
export const _getThreadOffset = (state: ThreadState) => state.offset;
export const _getThreadOffsetDate = (state: ThreadState) => state.offset_date;
