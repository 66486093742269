import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { isNullOrUndefined } from "util";

import { __HTTPResponseType } from "../enums/common.enum";
import { Image } from "../models/image";
import { ChecklistCategory } from "../models/new/checklist-category";
import { CalendarPrice } from "../models/new/listing/calendar-price";
import { ListingCompact } from "../models/new/listing/listing-compact.model";
import { ListingFull } from "../models/new/listing/listing-full.model";
import { ListingHomeawayReport } from "../models/new/listing/listing-homeaway-report";
import { PropertyAccessDetails } from "../models/new/listing/property-access-details";
import { UserCompact } from "../models/new/user/user-compact.model";
import { UserFull } from "../models/new/user/user-full.model";
import { OwnerBlock } from "../models/owner-block";
import { ListingShowResponse } from "../models/responses/listings/listing-show.response";
import { ChecklistCategoryGetResponse } from "../models/responses/options/checklist-category-get.response";
import { ChecklistGetResponse } from "../models/responses/options/checklist-get.response";
import { ChecklistShowResponse } from "../models/responses/options/checklist-show.response";
import { dateToDateString, getDateObj, SDDay } from "../utils/calendar-utils";
import { DateUtil } from "../utils/date.utils";

import { ApiService } from "./api.service";


@Injectable()
export class ListingService {

  private PROPERTY_INDEX_INCLUDES = "tags,coverImage";
  private PROPERTY_SHOW_INCLUDES = "managementContacts,owner,assignee,checklistCategories,images,logs,tags,airbnbAccount";

  constructor(private apiService: ApiService) {
  }

  createNewListing(data: Partial<ListingFull>): Observable<ListingShowResponse> {
    return this.apiService.post<ListingShowResponse>("/properties", true, {
      ...data,
      include: this.PROPERTY_SHOW_INCLUDES
    });
  }

  getListings(): Observable<ListingCompact[] | {}> {
    return this.apiService.get<{data: ListingCompact[]}>("/properties?include=" + this.PROPERTY_INDEX_INCLUDES, true).pipe(map((res) => res.data));
  }

  showListing(listingId: number): Observable<ListingFull> {
    return this.apiService.get<ListingShowResponse>("/properties/" + String(listingId) + "?include=" + this.PROPERTY_SHOW_INCLUDES
      , true).pipe(map(res => res.data));
  }

  updateListingDetails(data: any, property_id: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/properties/" + property_id + "?include=" + this.PROPERTY_SHOW_INCLUDES, true, data)
      .pipe(map(res => res.data));
  }

  onboardingStep1(data: any, property_id: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/properties/" + property_id + "/onboarding-step1?include=" + this.PROPERTY_SHOW_INCLUDES, true, data)
      .pipe(map(res => res.data));
  }

  onboardingStep2(data: any, property_id: string): Observable<ListingFull> {

    data.rooms.forEach(room => {
      if (isNullOrUndefined(room.type)) {
        delete room.type;
      }
    });

    return this.apiService.put<ListingShowResponse>("/properties/" + property_id + "/onboarding-step2?include=" + this.PROPERTY_SHOW_INCLUDES, true, data)
      .pipe(map(res => res.data));
  }

  onboardingStep3(data: any, property_id: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/properties/" + property_id + "/onboarding-step3?include=" + this.PROPERTY_SHOW_INCLUDES, true, data)
      .pipe(map(res => res.data));
  }

  onboardingStep4(data: any, property_id: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/properties/" + property_id + "/onboarding-step4?include=" + this.PROPERTY_SHOW_INCLUDES, true, data)
      .pipe(map(res => res.data));
  }

  updateSummaryAboutListingDetails(data: any, property_id: string , draftId: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/admin/properties/" + property_id + "/summary-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));
  }

  updateTheSpaceListingDetails(data: any, property_id: string , draftId: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/admin/properties/" + property_id + "/space-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));
  }

  updateGoalListingDetails(property_id: number, draftId: number, data): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/admin/properties/" + property_id + "/goal-percentage-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));
  }

  updateOwnerStoryListingDetails(data: any, property_id: string , draftId: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/admin/properties/" + property_id + "/owner-story-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));
  }

  updatePrivateNotesListingDetails(data: any, property_id: string , draftId: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/admin/properties/" + property_id + "/private-notes-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));
  }

  updateConciergeInfoListingDetails(data: any, property_id: string , draftId: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/admin/properties/" + property_id + "/concierge-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));

  }

  updateNeighbourhoodListingDetails(data: any, property_id: string , draftId: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/admin/properties/" + property_id + "/saved-neighbourhood-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));
  }

  updateGuestAccessListingDetails(data: any, property_id: string , draftId: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/admin/properties/" + property_id + "/guest-access-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));
  }

  updateInteractionWithListingDetails(data: any, property_id: string , draftId: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/admin/properties/" + property_id + "/interaction-with-guests-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));
  }

  updateGettingAroundListingDetails(data: any, property_id: string , draftId: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/admin/properties/" + property_id + "/getting-around-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));
  }

  updateThingsToNoteListingDetails(data: any, property_id: string , draftId: string): Observable<ListingFull> {
    return this.apiService.put<ListingShowResponse>("/admin/properties/" + property_id + "/other-notes-drafts/" + draftId, true, data)
      .pipe(map(res => res.data));
  }

  publishToBookingPal(propertyId: number, managerId: number): Observable<any> {
    return this.apiService.post("/properties/" + propertyId + "/bookingpal-account/" + managerId, true , null);
  }

  publishToAirbnb(propertyId: number, airbnbAccountId: any): Observable<any> {
    return this.apiService.post("/properties/" + propertyId + "/airbnb-account/" + airbnbAccountId, true , null);
  }

  addImages(data: { image: File, caption?: string }, property_id: string): Observable<any> {
    return this.apiService.upload<any>("/properties/" + property_id + "/images", "POST", data.image, true, "image");
  }

  editImageCaption(listingId: number, imageId: number, data: any): Observable<any> {
    console.log(data);
    return this.apiService.put("/property-images/" + imageId, true, data);
  }

  removeVendorContacts(propertyId: string, contact: UserCompact) {
    return this.apiService.delete("/properties/" + propertyId + "/contacts/" + contact.managementContact.data.id, true, null, null, true, __HTTPResponseType.TEXT);
  }

  getChecklistCategories(): Observable<ChecklistCategoryGetResponse> {
    return this.apiService.get<ChecklistCategoryGetResponse>("/options/checklist-categories"+ "?include=checklists", true);
  }

  // We are fetching list of all checklists in order to get the updated order of checklists
  addCheckList(listingId: number, data: { title: string }): Observable<ChecklistGetResponse> {
    return this.apiService.post<ChecklistGetResponse>("/properties/" + listingId + "/checklists", true, data);
  }

  deleteCheckListCategory(listingId: number,categoryId: number): Observable<ChecklistCategory[]> {
    return this.apiService.delete<{data: ChecklistCategory[]}>("/checklist-categories/" + categoryId+ "?include=checklists", true, null, )
      .pipe(map(res => res.data));
  }

  updateCheckList(listingId: number, checklistId: number, data: { is_complete: boolean }): Observable<ChecklistShowResponse> {
    return this.apiService.put<ChecklistShowResponse>("/checklists/" + checklistId, true, data);
  }

  // any return type needs verification.

  rearrangeChecklists(listingId: number, checklist_id: number, category_id: number, sort_order: number): Observable<ChecklistCategory[]> {

    const  data = {
      checklist_id: checklist_id,
      category_id: category_id,
      sort_order: sort_order,
    };

    return this.apiService.put<{data: ChecklistCategory[]}>("/properties/" + listingId + "/checklists/re-arrange"+ "?include=checklists", true, data)
      .pipe(map(res => res.data));
  }

  rearrangeChecklistCategory(listingId: number,  category_id: number, sort_order: number): Observable<ChecklistCategory[]> {

    const  data = {
      id: category_id,
      sort_order: sort_order,
    };

    return this.apiService.put<{data: ChecklistCategory[]}>("/properties/" + listingId + "/checklist-categories/re-arrange"+ "?include=checklists", true, data)
      .pipe(map(res => res.data));
  }

  deleteCheckList(listingId: number, checklistId: number): Observable<boolean> {
    return this.apiService.delete<{}>("/checklists/" + checklistId, true, null, null, true, __HTTPResponseType.TEXT)
      .pipe(map(res => true));
  }

  removeImages(property_id: string, image_id: string): Observable<any> {
    return this.apiService.delete<boolean>("/property-images/" + image_id, true, null, null, true, __HTTPResponseType.TEXT);
  }

  removeAllImages(property_id: string): Observable<any> {
    return this.apiService.delete<boolean>("/properties/"+property_id+"/delete-images", true, null, null, true, __HTTPResponseType.TEXT);
  }

  removeSelectedImages(data: { property_id: number, property_image_ids: number[] }): Observable<any> {
    return this.apiService.post("/property-images", true, data);
  }

  rearrangeListingImages(listingId: number, data: { sort_order: {} }, images: Image[]): Observable<boolean | {}> {
    return this.apiService.post("/properties/" + listingId + "/images/re-arrange", true, data,null,true,__HTTPResponseType.TEXT);
  }

  getHomeAwayReports(listingId: number): Observable<ListingHomeawayReport> {
    return this.apiService.get<ListingHomeawayReport>("/properties/" + listingId + "/homeaway-report", true);
  }

  sendListingForApproval(property_id: string): Observable<ListingFull> {
    return this.apiService.post<{ data: ListingFull }>("/properties/" + property_id + "/approve-request?include=" + this.PROPERTY_SHOW_INCLUDES, true, null).pipe(map((res) => res.data));
  }

  approveListing(property_id: string, data: {
    assignee_id: number,
    housekeeper_id: number,
    // TODO New Contact
    general_maintenance_id: number,
    painter_id: number,
    electrician_id: number,
    plumber_id: number,
    homeowner_id: number,
    cleaner_id: number,
    hvac_id: number,
    preventative_maintenance_id: number,
    sales_id: number,
    landscaping_id: number
  }): Observable<ListingFull> {

    return this.apiService.post("/admin/properties/" + property_id + "/approve?include=" + this.PROPERTY_SHOW_INCLUDES, true, data);
  }

  rejectListing(property_id: string, data: { reason: string }): Observable<ListingFull> {
    return this.apiService.post<{data: ListingFull}>("/admin/properties/" + property_id + "/reject?include=" + this.PROPERTY_SHOW_INCLUDES, true, data)
      .pipe(map(res => res.data));
  }

  pushToBookingPal(propertyId: string): Observable<boolean | {}> {
    return this.apiService.post("/properties/" + propertyId + "/push-bookingpal", true, null, null,true, __HTTPResponseType.TEXT);
  }

  pushToAirbnb(propertyId: string): Observable<boolean | {}> {
    return this.apiService.post("/properties/" + propertyId + "/push-airbnb", true, null, null,true, __HTTPResponseType.TEXT);
  }

  activateAirbnb(propertyId: number): Observable<boolean | {}> {
    return this.apiService.post("/properties/" + propertyId + "/activate-airbnb", true, null, null,true, __HTTPResponseType.TEXT);
  }

  updateVendorContacts(propertyId: string, contact: UserCompact): Observable<UserCompact> {
    return this.apiService.put<{data: UserCompact}>("/properties/" + propertyId + "/contacts/" + contact.managementContact.data.id, true).pipe(map(res => res.data));
  }

  attachPropertiesToContact(managementContactId: number, propertyIds: number[]): Observable<UserFull> {
    const data = {
      property_ids: propertyIds
    };
    return this.apiService.put<{data: UserFull}>("/contacts/" + managementContactId + "/attach", true, data).pipe(map(res => res.data));
  }

  getCalendar(listingId: number, data: { month: string }): Observable<any> {
    return this.apiService.get("/properties/" + listingId + "/calendar", true, data).pipe(map(res => res));
  }

  getCalendarPrices(listingId: number, start: SDDay, end: SDDay): Observable<CalendarPrice[]> {
    return this.apiService.get<{data: CalendarPrice[]}>("/properties/" + listingId + "/rates", true, {
      start: DateUtil.convertToDateString(start.date),
      end: DateUtil.convertToDateString(end.date),
    }).pipe(map(res => res.data));
  }

  createOwnerBlock(data: { start_date: string, end_date: string, reason: string }, property_id: string): Observable<OwnerBlock[] | {}> {
    data.start_date = dateToDateString(getDateObj(data.start_date));
    data.end_date = dateToDateString(getDateObj(data.end_date));
    return this.apiService.post<{data: OwnerBlock[]}>("/properties/" + property_id + "/owners-block", true, data).pipe(map((res) => res.data));
  }

  deleteOwnerBlock(data: { start_date: string, end_date: string }, property_id: string): Observable<boolean | {}> {
    data.start_date = dateToDateString(getDateObj(data.start_date));
    data.end_date = dateToDateString(getDateObj(data.end_date));
    return this.apiService.delete("/properties/" + property_id + "/owners-block", true, data, null, true, __HTTPResponseType.TEXT);
  }

  getPropertyAccessDetails(propertyId: number): Observable<PropertyAccessDetails> {
    return this.apiService.get("/properties/"+propertyId+"/task-details", true);
  }

  getBankAccountsForListing(id: number) {
    return this.apiService.get("/properties/"+id+"/bank-info",true);
  }

  createChecklistCategory(title: string, propertyId: number): Observable<ChecklistCategory> {
    return this.apiService.post<{data: ChecklistCategory}>("/properties/"+propertyId+"/checklist-categories"+ "?include=checklists",true, {title: title})
      .pipe(map(res => res.data));
  }

  getAvailableListings(data: {start: any, end: any, number_of_guests: number, number_of_pets: number}): Observable<number[]> {
    data.start = DateUtil.convertToDateString(data.start);
    data.end = DateUtil.convertToDateString(data.end);
    return this.apiService.get<{property_ids: number[]}>("/admin/properties/available-properties", true, data)
      .pipe(map(res => res.property_ids));
  }

  syncImages(listingId: number): Observable<void> {
    return this.apiService.post<void>(`/properties/${listingId}/sync-image-airbnb`, true, null);
  }

  syncImagesMbp(listingId: number): Observable<void> {
    return this.apiService.post<void>(`/properties/${listingId}/sync-image-mbp`, true, null);
  }

  forceSync(listingId: number): Observable<string> {
    return this.apiService.post<string>(`/properties/${listingId}/force-sync-images`, true, null);
  }

  cloneListing(listingId: number): Observable<ListingFull> {
    return this.apiService.post<any>(`/properties/${listingId}/clone`, true, null)
      .pipe(map(res => res.data));
  }

  syncPriceLabs(listingId: number) {
    return this.apiService.post(`/admin/price-labs/sync-rate/${listingId}`, true, null);
  }
}
