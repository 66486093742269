/**
 * Created by divyanshu on 14/09/17.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { getDateObj } from "../../../utils/calendar-utils";
import { isNullOrUndefined } from "util";

@Pipe({
  name: "utcToLocalTime"
})
export class UTCtoLocalTimeZonePipe implements PipeTransform {
  transform(value: string): Date {

    let dateUtc;

    if (isNullOrUndefined(value)) {
      dateUtc = getDateObj("");
    }
    else {
      dateUtc = getDateObj(value);
    }

    return new Date(Date.UTC(
      dateUtc.getFullYear(),
      dateUtc.getMonth(),
      dateUtc.getDate(),
      dateUtc.getHours(),
      dateUtc.getMinutes(),
      dateUtc.getSeconds())
    );
  }
}
