import {map} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {AlertService} from "../services/alert.service";
import {State} from "../reducers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {Alert} from "../models/alert";
import {
  AlertsCreateRequestAction,
  AlertsCreateSuccessAction,
  AlertsDeleteRequestAction,
  AlertsDeleteSuccessAction,
  AlertsUpdateRequestAction,
  AlertsUpdateSuccessAction
} from "../actions/alert";

@Injectable()
export class AlertRepository {

  constructor(private alertService: AlertService, private store: Store<State>) {
  }

  createAlert(data: any): Observable<Alert> {
    this.store.dispatch(new AlertsCreateRequestAction());
    return this.alertService.createAlert(data).pipe(map(res => {
      this.store.dispatch(new AlertsCreateSuccessAction(res));
      return res;
    }));
  }

  updateAlert(id: number, data: any): Observable<Alert> {
    this.store.dispatch(new AlertsUpdateRequestAction(id));
    return this.alertService.updateAlert(id, data).pipe(map(res => {
      this.store.dispatch(new AlertsUpdateSuccessAction(res));
      return res;
    }));
  }

  deleteAlert(id: number): Observable<boolean> {
    this.store.dispatch(new AlertsDeleteRequestAction(id));
    return this.alertService.deleteAlert(id).pipe(map(res => {
      this.store.dispatch(new AlertsDeleteSuccessAction(id));
      return true;
    }));
  }
}
