import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { ContactEmployeeComponent } from "./components/tabs/contact-employee";
import { AdminCreatePopup } from "../settings/components/admin-create-popup";
import { SharedModule } from "../shared/shared.module";

import { ContactBankInfoComponent } from "./components/contact-bank-info";
import { ContactInfoComponent } from "./components/contact-info";
import { ContactListingInfoComponent } from "./components/contact-listing-info";
import { ContactLogInfoComponent } from "./components/contact-log-info";
import { ContactReservationsInfoComponent } from "./components/contact-reservations-info";
import { ContactTypeInfoComponent } from "./components/contact-type-info";
import { ContactW9FormInfoComponent } from "./components/contact-w9-form-info";
import { ContactsUserProfileComponent } from "./components/contacts-user-profile";
import { CreateContactPopupComponent } from "./components/popups/create-contact-popup";
import { ContactTransactionHistoryComponent } from "./components/tabs/contacts-transaction-history";

const entryComponents = [
  AdminCreatePopup,
  CreateContactPopupComponent,
];

const components = [
  ContactsUserProfileComponent,
  ContactInfoComponent,
  ContactBankInfoComponent,
  ContactTypeInfoComponent,
  ContactListingInfoComponent,
  ContactTransactionHistoryComponent,
  ContactLogInfoComponent,
  ContactEmployeeComponent,
  ContactReservationsInfoComponent,
  ContactW9FormInfoComponent,
];

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    ...entryComponents,
    ...components,
  ],
  declarations: [
    ...entryComponents,
    ...components
  ],
  entryComponents: [
    ...entryComponents
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ContactsSharedModule {
}
