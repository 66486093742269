export default class DateUtils {

  static daysBetweenDates(dateA: Date, dateB: Date): number {
    const one = new Date(dateA.getFullYear(), dateA.getMonth(), dateA.getDate());
    const two = new Date(dateB.getFullYear(), dateB.getMonth(), dateB.getDate());

    return (two.getTime() - one.getTime()) / 86400000;
  }

  // add days to passed date. Except negative numbers as well.
  static addDays(date: Date, days: number): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
  }

  static daysInMonth(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
  }

  static toISODateString(date: Date): string {
    const fullDate = ("0" + date.getDate()).slice(-2);
    const fullMonth = ("0" + (date.getMonth() + 1)).slice(-2);
    const fullYear = date.getFullYear();

    return fullYear + "-" + fullMonth + "-" + fullDate;
  }

  static stringToUTCDate(stringDate: string): Date {
    const tempDate = new Date(stringDate);
    const userTimezoneOffset = tempDate.getTimezoneOffset() * 60000;
    const newDate = new Date(tempDate.getTime() + userTimezoneOffset);

    console.log(tempDate, userTimezoneOffset, newDate);

    return newDate;
  }

  static formatTime(date: Date){
    let time = "";
    let seconds = Math.floor(date.getTime() / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    hours = hours % 24;
    minutes = minutes % 60;
    seconds = seconds % 60;
    if (days > 0) {
      time+= days + " days ";
    }
    if (hours > 0) {
      time += (hours < 10 ? "0" + hours : hours) + ":";
    } else {
      time+= "00:";
    }
    if (minutes > 0) {
      time += (minutes < 10 ? "0" + minutes : minutes) + ":";
    } else {
      time+= "00:";
    }
    if (seconds > 0){
      time+= (seconds < 10 ? "0" + seconds : seconds)
    }  else {
      time+="00";
    }
    return time;
  }

}
