/**
 * Created by aditya on 24/8/17.
 */
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { debounceTime, distinctUntilChanged, takeWhile } from "rxjs/operators";

import { ListingCompact } from "../../../models/new/listing/listing-compact.model";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { UserFull } from "../../../models/new/user/user-full.model";
import { State } from "../../../reducers";
import { ListingRepository } from "../../../repository/listing.repository";
import { StayDuvetService } from "../../../services/stayduvet";

@Component({
  selector: "sd-contact-listing-info",
  template: `
    <div fxLayout="column" fxLayoutGap="20px" style="margin: 20px">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center" fxLayoutGap="20px"
           *ngIf="!isContactOwner">

        <div class="width-30">

          <mat-card style="font-size: x-small;" class="generalCard" fxLayoutGap="10px" fxLayout="column">
            <div style="height: 10px;"></div>
            <sd-select [multiple]="true" placeholder="Select Listings" [control]="selectedList" [options]="listingOptions"></sd-select>
            <mat-error style="font-size: xx-small;" *ngIf="showSelectListingError">Please select listing to assign
            </mat-error>

            <div fxLayout="row" fxLayoutGap="10px">
              <button mat-raised-button color="accent" type="button" (click)="assignToListing()"><b>Add Listings</b>
              </button>
              <mat-spinner id="spinner" color="accent" *ngIf="isAssigning" [diameter]="30"
                           [strokeWidth]="4"></mat-spinner>
            </div>
            <div style="height: 10px;"></div>

          </mat-card>
        </div>

        <div class="width-70">
          <mat-card class="generalCard">

            <div *ngFor="let listing of listings">
              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center" fxLayoutGap="20px"
                   style=" cursor: pointer; border: 1px solid darkcyan; padding: 10px; margin: 10px; font-size: x-small;">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex fxLayoutGap="10px">
                  <div (click)="openListing(listing)" style="font-size: medium">{{listing.title}}</div>
                  <div *ngIf="contact.type === 'management' && contact.managementContact.data.category === 'homeowner'"
                       fxFlex="35%"
                       fxLayout="row"
                       fxLayoutAlign="end center">
                    <span>Commission : </span>
                    <mat-form-field color="accent" style="width:60px;">
                      <input #commission matInput style="width: 40px;" min="0" max="99" type="number"
                             [(ngModel)]="listing.commission_rate">
                    </mat-form-field>
                    %
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
                  <mat-spinner id="spinner" color="accent" *ngIf="isLoading(listing)" [diameter]="30"
                               [strokeWidth]="4"></mat-spinner>
                  <button
                    *ngIf="contact.type === 'management' && contact.managementContact.data.category === 'homeowner'"
                    mat-icon-button [disabled]="isLoading(listing)"
                    (click)="updateCommission(listing)">
                    <mat-icon>done</mat-icon>
                  </button>
                  <button mat-icon-button [disabled]="isLoading(listing)" (click)="detachListing(listing)" color="warn">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div *ngIf="listings.length == 0" fxLayoutAlign="center">
              <h5>No Listings Assigned to this Contact</h5>
            </div>

          </mat-card>
        </div>

      </div>

      <div fxLayout="row" fxLayoutGap="20px" *ngIf="isContactOwner">

        <div fxFlex="100%" fxLayout="row">
          <mat-card class="generalCard">
            <div *ngFor="let listing of listings" fxLayout="column">
              <div fxLayout="row" fxLayoutAlign="space-between center"
                   style=" cursor: pointer; border: 1px solid darkcyan; padding: 8px; margin: 10px; font-size: x-small;">
                <span (click)="openListing(listing)">{{listing.title}}</span>
              </div>
            </div>

            <div *ngIf="listings.length == 0" fxLayoutAlign="center">
              <h5>No Listings Exist</h5>
            </div>

          </mat-card>
        </div>

      </div>

      <div style="height: 10px;"></div>
    </div>





  `,
  styles: [`
    .radio-button {
      margin: 15px;
    }

    .width-70 {
      width: 70%;
    }

    .width-30 {
      width: 30%;
    }

    @media only screen and (max-width: 700px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 95%;
      }

      .responsivewidth {
        width: 80%;
      }

      .responsiveAlign {
        text-align: center;
      }
    }
  `]

})

export class ContactListingInfoComponent implements OnInit, OnDestroy, OnChanges {

  @Input() contact: UserFull;
  allListings: ListingCompact[] = [];
  listings: ListingCompact[] = [];
  remListings: ListingCompact[] = [];
  listingOptions: {title: string, value: any}[];
  filteredListings: ListingCompact[] = [];
  listingFilter: FormControl = new FormControl();
  selectedList: FormControl = new FormControl([]);

  loadingIds = [];
  selectedListings: number[] = [];
  showSelectListingError = false;
  isAssigning = false;
  isContactOwner = true;
  private isAlive = true;

  constructor(private store: Store<State>,
              private service: StayDuvetService,
              private listingRepo: ListingRepository,
              private router: Router) {

  }

  ngOnInit() {
    this.listingRepo.getAcceptedListings().pipe(takeWhile(() => this.isAlive)).subscribe((listings) => {
      this.allListings = listings;
      this.updateListing();
    });
  }

  updateListing() {
    if (this.contact.type === "management") {
      this.isContactOwner = false;
      const listingIds = this.contact.managementContact.data.properties;
      this.listings = this.allListings.filter(listing => listingIds.includes(listing.id));
      this.remListings = this.allListings.filter(listing => !listingIds.includes(listing.id));
      this.filteredListings = this.remListings;

    } else if (this.contact.type === "owner") {
      this.isContactOwner = true;
      const listingIds = this.contact.owner.data.properties;
      this.listings = this.allListings.filter(listing => listingIds.includes(listing.id));
      this.remListings = this.allListings.filter(listing => !listingIds.includes(listing.id));
      this.filteredListings = this.remListings;
    }

    this.listingOptions = this.remListings.map(listing => {
      return {title: listing.title, value: listing.id};
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateListing();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  assignToListing() {
    this.selectedListings = this.selectedList.value;
    if (this.selectedListings.length === 0) {
      this.showSelectListingError = true;
      return;
    }
    this.showSelectListingError = false;
    this.isAssigning = true;

    this.listingRepo.attachPropertiesToContact(this.contact.managementContact.data.id, this.selectedListings).subscribe(() => {
        this.isAssigning = false;
        this.selectedListings = [];
        this.selectedList.setValue([]);
        console.log("success");
      }, () => {
        this.isAssigning = false;
        this.selectedListings = null;
        this.selectedList.setValue([]);
    });

  }

  detachListing(listing) {
    this.loadingIds.push(listing.id);
    this.listingRepo.removeVendorContacts(String(listing.id), this.contact as UserCompact).subscribe(() => {
      this.loadingIds = this.loadingIds.filter(id => id !== listing.id);
    }, err => {
      this.loadingIds = this.loadingIds.filter(id => id !== listing.id);
    });
  }

  openListing(listing) {
    this.router.navigate(["/listings/" + listing.id]);
  }

  isLoading(listing) {
    return this.loadingIds.includes(listing.id);
  }

  updateCommission(listing) {
    this.loadingIds.push(listing.id);
    this.listingRepo.updateListingDetails({commission_rate: listing.commission_rate}, listing.id).subscribe(() => {
      this.loadingIds = this.loadingIds.filter(id => id !== listing.id);
    }, err => {
      this.loadingIds = this.loadingIds.filter(id => id !== listing.id);
    });
  }
}
