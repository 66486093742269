/**
 * Created by divyanshu on 07/09/17.
 */
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { debounceTime, distinctUntilChanged, filter, takeWhile } from "rxjs/operators";

import { ListingCompact } from "../models/new/listing/listing-compact.model";
import { ListingRepository } from "../repository/listing.repository";
import { StayDuvetService } from "../services/stayduvet";
import { getDateObj } from "../utils/calendar-utils";

@Component({
  selector: "sd-create-owner-block-home-popup",
  template: `
    <sd-modal-popup-layout title="Create Owner's Block">
      <form fxLayout="column" fxLayoutGap="20px" [formGroup]="formGroup"
            (ngSubmit)="formGroup.valid && saveButtonCLicked()">
        <div fxLayoutAlign="space-between center">
          <mat-form-field [color]="'accent'" class="quar-width">
            <input matInput [matDatepicker]="startPicker" [min]="minStartDate" placeholder="Start Date"
                   formControlName='start_date'>
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
            <mat-error>required.</mat-error>
          </mat-form-field>

          <mat-form-field [color]="'accent'" class="quar-width">
            <input matInput [matDatepicker]="endPicker" [min]="minEndDate" placeholder="End Date"
                   formControlName='end_date'>
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error>required.</mat-error>
          </mat-form-field>

          <sd-select class="half-width" placeholder="Select Listing*" error="Required"
                     [control]="selectedListing" [options]="allListingsOptions"></sd-select>
        </div>


        <textarea class="container-box" placeholder="Block Reason*" rows="4" formControlName='reason'></textarea>

        <div fxLayoutAlign="space-between center" style="width: fit-content">
          <span style="margin-right: 15px">Duvet will clean</span>
          <mat-slide-toggle [checked]="createAutoTask"
                            fxFlexAlign="center"
                            (change)="autoTaskToggleChanged($event)">
          </mat-slide-toggle>
        </div>

        <div fxLayoutGap="10px" fxLayoutAlign=" center" fxFlexAlign="end">
          <mat-spinner color="accent" *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <button mat-raised-button fxFlexAlign="end" [disabled]="isSaving" color="accent" type="submit">
            OK
          </button>
        </div>
      </form>
    </sd-modal-popup-layout>
  `,
  styles: [`
    hr {
      width: 100%;
    }

    mat-spinner {
      height: 30px;
      width: 30px;
    }

    .container-box {
      border-style: solid;
      border-width: 0.1px;
      padding: 10px;
      border-color: #c8c8c8
    }

    .half-width {
      width: 45%;
    }

    .quar-width {
      width: 25%;
    }

    .entryInputs {
      width: 45%
    }
  `]
})
export class HomeCreateOwnerBlockPopupComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  startDate: FormControl;
  endDate: FormControl;
  reason: FormControl;
  minStartDate: Date;
  minEndDate: Date;
  createBooking = true;
  createAutoTask = true;

  isSaving = false;
  allListings: ListingCompact[];
  allListingsOptions: {title: string, value: any}[];
  selectedListing: FormControl;
  private isAlive = true;

  constructor(private service: StayDuvetService, private dialog: MatDialog,
              private listingRepo: ListingRepository) {
    this.startDate = new FormControl(null, [Validators.required]);
    this.endDate = new FormControl(null, [Validators.required]);
    this.reason = new FormControl(null, [Validators.required]);
    this.selectedListing = new FormControl(null, [Validators.required]);

    this.formGroup = new FormGroup({
      start_date: this.startDate,
      end_date: this.endDate,
      reason: this.reason,
      selected_listing: this.selectedListing
    });

    this.minStartDate = getDateObj();
    this.minEndDate = getDateObj();
    this.startDate.setValue(this.minStartDate);
    this.endDate.setValue(new Date(this.minStartDate.getTime() + 86400000));

    this.startDate.valueChanges.pipe(filter(v => !!v)).subscribe(value => {
      this.endDate.setValue(new Date(value.getTime() + 86400000));
    });

  }

  ngOnInit() {
    this.listingRepo.getAcceptedListings().pipe(takeWhile(() => this.isAlive)).subscribe((listings) => {
      this.allListings = listings;
      this.allListingsOptions = this.allListings.map(listing => {
        return {title: listing.title, value: listing};
      });
    });
  }

  autoTaskToggleChanged(event: any) {
    this.createAutoTask = event.checked;
  }

  saveButtonCLicked() {
    this.isSaving = true;
    const data = {
      ...this.formGroup.value,
      create_auto_task: this.createAutoTask,
      create_booking: this.createBooking
    };
    this.listingRepo.createOwnerBlock(data, String(this.selectedListing.value.id)).subscribe((listing) => {
      this.isSaving = false;
      this.dialog.closeAll();
    }, err => {
      this.isSaving = false;
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
