import {ListingCompact} from "../new/listing/listing-compact.model";
import {CommonUtil} from "../../utils/common.util";
import {ListingImage} from "../new/listing/listing-image.model";
import {ListingFull} from "../new/listing/listing-full.model";
import {UserCategory} from "../../enums/user.enum";
import {UserCompact} from "../new/user/user-compact.model";
import {Tag} from "../tag";

export class ListingModelUtil {
  static getProgress(listing: ListingCompact) {
    return 40;
  }

  static getNumberOfRooms(listing: ListingCompact) {
    if (listing.rooms !== null) {
      // Removing the common space count
      return listing.bedroom_count;
    }

    return 0;
  }

  /**
   * Listing Address Util Methods
   */
  static getFullAddress(listing: ListingCompact): string {
    return listing.address_string;
  }

  /**
   * Listing Images Util Methods
   */
  static getThumbnailUrl(listing: ListingCompact): string {
    const image = this.getCoverImage(listing);

    return image ? image.thumb_url : CommonUtil.IMAGE_LISTING_PLACEHOLDER;
  }

  static getPosterUrl(listing: ListingCompact): string {
    const image = this.getCoverImage(listing);

    return image ? image.medium_url : CommonUtil.IMAGE_LISTING_PLACEHOLDER;
  }

  static getFullUrl(listing: ListingCompact): string {
    const image = this.getCoverImage(listing);

    return image ? image.original_url : CommonUtil.IMAGE_LISTING_PLACEHOLDER;
  }

  static getCoverImage(listing: ListingCompact): ListingImage | null {
    if (listing.coverImage) {
      return listing.coverImage.data;
    }

    return null;
  }


  /**
   * Contacts related
   */
  static getMaintenanceContacts(listing: ListingFull): UserCompact[] {
    const sortIndex = {
      [UserCategory.HVAC]: 80,
      [UserCategory.GENERAL_MAINTENANCE]: 70,
      [UserCategory.HOUSEKEEPER]: 60,
      [UserCategory.INSPECTOR]: 50,
      [UserCategory.HOMEOWNER]: 40,
      [UserCategory.PAINTER]: 30,
      [UserCategory.PLUMBER]: 20,
      [UserCategory.ELECTRICIAN]: 10,
      [UserCategory.PREVENTATIVE_MAINTENANCE]: 9,
      [UserCategory.SALES]: 8,
      [UserCategory.LANDSCAPING]: 7,
    };
    if (listing.managementContacts) {
      return listing.managementContacts.data.sort((first: UserCompact, second: UserCompact) => {
        return sortIndex[second.managementContact.data.category] - sortIndex[first.managementContact.data.category];
      });
    } else {
      return [];
    }
  }

  static getMaintenances(listing): UserCompact[] {
    const maintenancesArray = [];
    const maintenances = ListingModelUtil.getMaintenanceContacts(listing);
    if (maintenances && maintenances.length > 0) {

    }
    return maintenances;
  }

  static getTags(listing): Tag[] {
    if (listing["tags"]) {
      return listing["tags"]["data"];
    }
    return [];
  }
}
