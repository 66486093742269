import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { MinimumStay } from "../models/minimum-stay";
import { Observable } from "rxjs";
import { __HTTPResponseType } from "../enums/common.enum";
import { dateToDateString, getDateObj } from "../utils/calendar-utils";
import { MinStayTemplate } from "../models/min-stay-template";
import { map } from "rxjs/operators";

@Injectable()
export class MinStaysTemplateService {

  constructor(private apiService: ApiService) {
  }

  getMinStayTemplates(): Observable<MinStayTemplate[]> {
    return this.apiService.get<{ data: MinStayTemplate[] }>("/admin/min-stays-templates", true).pipe(map(res => res.data));
  }

  deleteMinStayTemplate(templateId: number): Observable<boolean> {
    return this.apiService.delete("/admin/min-stays-templates/" + templateId, true, null, null, true, __HTTPResponseType.TEXT)
      .pipe(map((res) => {
        return true;
      }));
  }

  addNewMinStayTemplate(data: any): Observable<MinStayTemplate> {
    data.start = dateToDateString(getDateObj(data.start));
    data.end = dateToDateString(getDateObj(data.end));
    return this.apiService.post<{ data: MinStayTemplate }>("/admin/min-stays-templates", true, data).pipe(map((res) => res.data));
  }

  updateMinStayTemplate(templateId: number, data: any): Observable<MinStayTemplate> {
    data.start = dateToDateString(getDateObj(data.start));
    data.end = dateToDateString(getDateObj(data.end));
    return this.apiService.put<{ data: MinStayTemplate }>("/admin/min-stays-templates/" + templateId, true, data)
      .pipe(map((res) => res.data));

  }
}
