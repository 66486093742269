import { differenceInHours } from "date-fns";
import { isNullOrUndefined } from "util";

import { getDateObj, getDateObjInLocal } from "../../utils/calendar-utils";
import { AttachmentType } from "../../enums/uploading-enum";
import { CommonUtil } from "../../utils/common.util";
import { Comment } from "../new/tasks/comment";
import { TaskAttachment } from "../new/tasks/task-attachment.model";
import { TaskCompact } from "../new/tasks/task-compact.model";
import { TaskFull } from "../new/tasks/task-full.model";
import { UploadFile } from "../new/upload-file";

export class TaskModelUtil {

  static getDate(task: TaskCompact) {
    //
  }

  static hasAttachments(task: TaskCompact): boolean {
    if (task.attachments_count > 0) {
      return true;
    } else {
      return false;
    }
  }

  static getThumbnailUrl(attachment: TaskAttachment): string {
    switch (attachment.type) {
      case AttachmentType.IMAGE: {
        return attachment.url;
      }

      case AttachmentType.AUDIO: {
        return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/attachment-icons/file-mic.svg";
      }

      case AttachmentType.DOCUMENT: {
        return CommonUtil.getDocumentUrl(attachment.title);
      }

      case AttachmentType.VIDEO: {
        return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/attachment-icons/file-video.svg";
      }
    }
  }

  static getUploadThumbnailUrl(attachment: UploadFile): string {
    switch (attachment.type) {
      case AttachmentType.IMAGE: {
        return attachment.url;
      }

      case AttachmentType.AUDIO: {
        return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/attachment-icons/file-mic.svg";
      }

      case AttachmentType.DOCUMENT: {
        return CommonUtil.getDocumentUrl(attachment.title);
      }

      case AttachmentType.VIDEO: {
        return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/attachment-icons/file-video.svg";
      }
    }
  }

  static isPdf(attachment: TaskAttachment): boolean {
    return (attachment.title.lastIndexOf("pdf") === (attachment.title.length - 3));
  }

  static getCreatorName(task: TaskFull): string {
    if (isNullOrUndefined(task) || isNullOrUndefined(task.creator)) {
      return;
    }

    return task.creator.data.first_name + " " + task.creator.data.last_name;
  }

  static getIsEditable(comment: Comment): boolean {
    if (isNullOrUndefined(comment)) {
      return;
    }

    const now = getDateObj();
    const commentTime = getDateObjInLocal(comment.created_at);

    if (differenceInHours(now, commentTime) <= 2) {
      return true;
    }

  }

  static getIsDeletable(comment: Comment): boolean {
    if (isNullOrUndefined(comment)) {
      return;
    }

    const now = getDateObj();
    const commentTime = getDateObjInLocal(comment.created_at);

    if (differenceInHours(now, commentTime) <= 4) {
      return true;
    }

  }
}
