import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "sd-add-card",
  template: `
    <mat-card matRipple fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" (click)="addButtonClicked()">
      <button mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>
      <span>
        <ng-content></ng-content>
      </span>
    </mat-card>
  `,
  styles: [`
    :host {
      cursor: pointer;
      min-width: 150px;
      min-height: 150px;
    }

    mat-card {
      height: 100%;
      width: 100%;
    }

    span {
      font-size: 20px;
      text-align: center;
    }

    button {
      margin-bottom: 0px !important;
    }

    mat-icon {
      font-size: 45px;
      height: 45px;
      width: 45px;
    }
  `]
})

export class AddCardComponent {
  @Output() addCardClicked = new EventEmitter<any>();

  addButtonClicked() {
    this.addCardClicked.emit();
  }
}
