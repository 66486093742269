import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { __HTTPResponseType } from "../../enums/common.enum";
import { ApiService } from "../api.service";
import { GuestAccessDraftsShowResponse } from "../../models/responses/settings/drafts/guest-access-draft-show.response";
import { GuestAccessDraft } from "../../models/new/drafts/guest-access-draft.model";
import { GuestAccessDraftsGetResponse } from "../../models/responses/settings/drafts/guest-access-draft-get.response";

@Injectable()
export class GuestAccessDraftsService {

  constructor(private apiService: ApiService) {
  }

  getGuestAccessDrafts(): Observable<GuestAccessDraftsGetResponse> {
    return this.apiService.get<GuestAccessDraftsGetResponse>("/admin/guest-access-drafts", true);
  }

  getGuestAccessDraftById(draftId):Observable<GuestAccessDraftsShowResponse> {
    return this.apiService.get<GuestAccessDraftsShowResponse>("/admin/guest-access-drafts/"+draftId,true);
  }

  createGuestAccessDraft(data: Partial<GuestAccessDraft>): Observable<GuestAccessDraftsShowResponse> {
    return this.apiService.post<GuestAccessDraftsShowResponse>("/admin/guest-access-drafts", true, data);
  }

  updateGuestAccessDraft(draftId: number, data: any): Observable<GuestAccessDraftsShowResponse> {
    return this.apiService.put<GuestAccessDraftsShowResponse>("/admin/guest-access-drafts/" + draftId, true, data);
  }

  deleteGuestAccessDraft(draftId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/guest-access-drafts/" + draftId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}

