import {filter, map, take} from "rxjs/operators";
import {AutoTaskCompact} from "../models/new/tasks/auto-task-compact.model";
import {AutoTaskFull} from "../models/new/tasks/auto-task-full.model";
import {AutoTaskShowResponse} from "../models/responses/tasks/auto-task-show.response";
import {TransformerType} from "../enums/common.enum";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {
  getAllAutoTasks,
  getAutoTaskExist,
  getAutoTasksForListingId,
  getFullAutoTaskById,
  getIsAutoTaskLoaded,
  getIsAutoTaskLoading,
  getIsFullAutoTaskLoaded,
  getIsFullAutoTaskLoading,
  State
} from "../reducers";
import {AutoTaskService} from "../services/auto-task.service";
import {Observable} from "rxjs";
import {
  AddAutoTaskToNotExist,
  AutoTaskCreateSuccess,
  AutoTaskDeleteSuccess,
  AutoTaskIndexRequest,
  AutoTaskIndexSuccess,
  AutoTaskShowRequest,
  AutoTaskShowSuccess,
  AutoTaskUpdateRequest,
  AutoTaskUpdateSuccess
} from "../actions/new/tasks/auto-task";

@Injectable()
export class AutoTaskRepository {

  constructor(private store: Store<State>,
              private autoTaskService: AutoTaskService) {
  }

  /**
   * Loading & Loaded
   */
  getIsAutoTaskLoading(): Observable<boolean> {
    return this.store.select(getIsAutoTaskLoading);
  }

  getIsAutoTaskLoaded(): Observable<boolean> {
    return this.store.select(getIsAutoTaskLoaded);
  }

  getIsFullAutoTaskLoading(taskId: number): Observable<boolean> {
    return this.store.select(state => getIsFullAutoTaskLoading(state, taskId));
  }

  getIsFullAutoTaskLoaded(taskId: number): Observable<boolean> {
    return this.store.select(state => getIsFullAutoTaskLoaded(state, taskId));
  }

  /**
   * Service Methods
   */
  getAllAutoTasks(force: boolean, listingId: number): Observable<AutoTaskCompact[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsAutoTaskLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsAutoTaskLoaded().pipe(take(1)).subscribe(l => loaded = l);
    console.log(loading, loaded);
    if (!loading && (!loaded || force)) {
      this.store.dispatch(new AutoTaskIndexRequest());

      this.autoTaskService.getAutoTasks().subscribe(response => {
        this.store.dispatch(new AutoTaskIndexSuccess(response.data));
      });
    }

    if (listingId) {
      return this.store.select(state => getAutoTasksForListingId(state, listingId));
    }

    return this.store.select(getAllAutoTasks).pipe(map(t => t as AutoTaskCompact[]));
  }

  getTaskById(taskId: number): Observable<AutoTaskFull> {
    let loading = false;
    let loaded = false;

    this.getIsFullAutoTaskLoading(taskId).pipe(take(1)).subscribe(l => loading = l);
    this.getIsFullAutoTaskLoaded(taskId).pipe(take(1)).subscribe(l => loaded = l);
    console.log("LOADING", loading, "LOADED", loaded);
    if (!loading && !loaded) {
      this.store.dispatch(new AutoTaskShowRequest(taskId));
      this.autoTaskService.showAutoTask(taskId).subscribe(
        response => {
          console.log("API CALL", response);
          this.store.dispatch(new AutoTaskShowSuccess(response.data))
        },(err) => {
          this.store.dispatch(new AddAutoTaskToNotExist(taskId));
        });
    }
    console.log("STORE CALL");
    return this.store.select((state) => getFullAutoTaskById(state, taskId)).pipe(
      filter(v => !!v),
      filter(task => task.__type === TransformerType.FULL),
      map(task => task as AutoTaskFull),);
  }

  createAutoTask(data: any): Observable<AutoTaskFull> {
    return this.autoTaskService.createAutoTask(data).pipe(map(value => {
      this.store.dispatch(new AutoTaskCreateSuccess(value.data));
      return value.data;
    }));
  }

  updateAutoTask(autoTaskId: number, data: any): Observable<AutoTaskShowResponse> {
    this.store.dispatch(new AutoTaskUpdateRequest(autoTaskId));
    return this.autoTaskService.updateAutoTask(autoTaskId, data).pipe(map(value => {
      this.store.dispatch(new AutoTaskUpdateSuccess(value.data));
      return value;
    }));
  }

  deleteAutoTask(autoTaskId: number): Observable<null> {
    return this.autoTaskService.deleteAutoTask(autoTaskId).pipe(map(value => {
      this.store.dispatch(new AutoTaskDeleteSuccess(autoTaskId));
      return null;
    }));
  }


  getIsAutoTaskExist(taskId: number): Observable<boolean> {
    return this.store.select(state => getAutoTaskExist(state, taskId));
  }
}
