import { Action } from "../../../actions/action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import * as _ from "lodash";
import { GettingAroundDraft } from "../../../models/new/drafts/getting-around-draft.model";
import { GettingAroundDraftActionTypes } from "../../../actions/new/setttings/drafts/getting-around-drafts";


export interface GettingAroundDraftState extends EntityState<GettingAroundDraft> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const gettingAroundDraftAdapter: EntityAdapter<GettingAroundDraft> = createEntityAdapter
  < GettingAroundDraft > ({
    selectId: (gettingAroundDraft: GettingAroundDraft) => gettingAroundDraft.id
  });

export const initialState: GettingAroundDraftState = gettingAroundDraftAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function gettingAroundDraftReducer(state: GettingAroundDraftState = initialState, action: Action): GettingAroundDraftState {
  switch (action.type) {
    case GettingAroundDraftActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        isLoaded: false
      }
    }

    case GettingAroundDraftActionTypes.INDEX_SUCCESS: {
      const gettingAroundDrafts = action.payload as GettingAroundDraft[];
      return gettingAroundDraftAdapter.addAll(gettingAroundDrafts, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case GettingAroundDraftActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }

    case GettingAroundDraftActionTypes.CREATE_SUCCESS: {
      const gettingAroundDraft = action.payload as GettingAroundDraft;
      return gettingAroundDraftAdapter.addOne(gettingAroundDraft,{
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case GettingAroundDraftActionTypes.SHOW_REQUEST: {
      const gettingAroundDraftId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, gettingAroundDraftId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          gettingAroundDraftId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, gettingAroundDraftId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, gettingAroundDraftId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case GettingAroundDraftActionTypes.SHOW_SUCCESS: {
      const gettingAroundDraft = action.payload as GettingAroundDraft;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, gettingAroundDraft.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, gettingAroundDraft.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, gettingAroundDraft.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          gettingAroundDraft.id
        ];
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case GettingAroundDraftActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case GettingAroundDraftActionTypes.UPDATE_SUCCESS: {
      const gettingAroundDraft = action.payload as GettingAroundDraft;
      return gettingAroundDraftAdapter.updateOne({
        id: gettingAroundDraft.id,
        changes: gettingAroundDraft
      }, state);
    }

    case GettingAroundDraftActionTypes.DELETE_REQUEST: {
      return {
        ...state
      };
    }

    case GettingAroundDraftActionTypes.DELETE_SUCCESS: {
      const gettingAroundDraftId = action.payload as number;

      return gettingAroundDraftAdapter.removeOne(gettingAroundDraftId, state);
    }

    default: {
      return state;
    }

  }
}

export const _getIsGettingAroundDraftLoading = (state: GettingAroundDraftState) => state.isLoading;
export const _getIsGettingAroundDraftLoaded = (state: GettingAroundDraftState) => state.isLoaded;

export const _getIsFullGettingAroundDraftLoading = (state: GettingAroundDraftState, draftId: number) => {
  return state.fullLoadingIds.indexOf(draftId) !== -1;
};
export const _getIsFullGettingAroundDraftLoaded = (state: GettingAroundDraftState, draftId: number) => {
  return state.fullLoadedIds.indexOf(draftId) !== -1;
};
