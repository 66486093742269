import { Component } from "@angular/core";

@Component({
  selector: "sd-contact-transaction-history",
  template: `
    <div fxLayout="column" fxFlex="70%" style="margin-left:30%;margin-top:5%;margin-right: 3%" fxLayoutGap="10%">
      <mat-card fxLayout="column" fxLayoutGap="5%" class="gradient">
        <span><b>Payout Methods</b></span>
        <div>
          <table style="width: 100%;font-size: smaller">
            <tr style="text-align: left">
              <th style="width: 25%">Method</th>
              <th style="width: 50%">Details</th>
              <th style="width: 10%">Status</th>
              <th style="width: 15%"></th>
            </tr>
            <tr>
              <td>cash</td>
              <td>payment through cash</td>
              <td>accepted</td>
              <td>
                <div>
                  <button mat-raised-button color="primary" [matMenuTriggerFor]="menu" class="my-menu">
                    options
                    <mat-icon>arrow_drop_down</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item color="accent">
                      Make Default
                    </button>
                    <button mat-menu-item color="accent">
                      Remove
                    </button>
                  </mat-menu>
                </div>
              </td>
            </tr>
            <tr>
              <td>cash</td>
              <td>payment through cash</td>
              <td>accepted</td>
              <td>
                <div>
                  <button mat-raised-button color="primary" [matMenuTriggerFor]="menu" class="my-menu">
                    options
                    <mat-icon>arrow_drop_down</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item color="accent">
                      Make Default
                    </button>
                    <button mat-menu-item color="accent">
                      Remove
                    </button>
                  </mat-menu>
                </div>
              </td>
            </tr>
            <tr>
              <td>cash</td>
              <td>payment through cash</td>
              <td>accepted</td>
              <td>
                <div>
                  <button mat-raised-button color="primary" [matMenuTriggerFor]="menu" class="my-menu">
                    options
                    <mat-icon>arrow_drop_down</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item color="accent">
                      Make Default
                    </button>
                    <button mat-menu-item color="accent">
                      Remove
                    </button>
                  </mat-menu>
                </div>
              </td>
            </tr>
          </table>

        </div>
        <div>
          <button mat-raised-button color="accent">Add Payout Method</button>
        </div>
      </mat-card>
      <mat-card fxLayout="column" fxLayoutGap="5%" class="gradient">
        <span><b>Payout Routing Rules</b></span>
        <span style="font-size: smaller">Routing rules let you split payouts between methods, or direct payouts for certain 
    properties to different methods</span>
        <div>
          <table style="width: 100%;font-size: smaller">
            <tr style="text-align: left">
              <th style="width: 40%">Property</th>
              <th style="width: 15%">Percentage</th>
              <th style="width: 10%">Pay To</th>
              <th style="width: 15%">Percentage</th>
              <th style="width: 10%">Pay To</th>
              <th style="width: 10%"></th>
            </tr>
            <tr>
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>
                <div fxLayout="column" fxLayoutGap="1px">
                  <button mat-raised-button color="primary">edit</button>
                  <button mat-raised-button color="primary">delete</button>
                </div>
              </td>

            </tr>
            <tr>
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>
                <div fxLayout="column" fxLayoutGap="1px">
                  <button mat-raised-button color="primary">edit</button>
                  <button mat-raised-button color="primary">delete</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>
                <div fxLayout="column" fxLayoutGap="1px">
                  <button mat-raised-button color="primary">edit</button>
                  <button mat-raised-button color="primary">delete</button>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <button mat-raised-button color="accent">Add Payout Method</button>
        </div>
      </mat-card>
    </div>





















  `,
  styles: [`

    .gradient {
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    button {

      height: 25%;
      font-size: x-small;

    }

  `]
})
export class ContactTransactionHistoryComponent {

}
