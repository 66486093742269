import { Injectable } from "@angular/core";
import { getIsMultiCalendarLoaded, getIsMultiCalendarLoading, getMultiCalendarByMonth, State } from "../reducers";
import { Store } from "@ngrx/store";
import { MultiCalendarService } from "../services/multi-calendar.service";
import { MultiCalendarIndexRequestAction, MultiCalendarIndexSuccessAction } from "../actions/multi-calendar";
import { Observable ,  Subject } from "rxjs";
import { MultiCalendar } from "../models/multi-calendar";
import { CalendarDataIndexSuccess } from "../actions/new/calendar-data";

@Injectable()
export class MultiCalendarRepository {

  private _eventBus: Subject<any>;


  constructor(private store: Store<State>,
              private multicalendarService: MultiCalendarService) {
    this._eventBus = new Subject<any>();

  }

  getisMultiCalendarLoading(month: number,year: number){
    return this.store.select((state)=> getIsMultiCalendarLoading(state, month, year));
  }

  getIsMultiCalendarLoaded(month: number, year: number){
    return this.store.select((state)=> getIsMultiCalendarLoaded(state, month, year));
  }

  getMultiCalendar(data: { month: number, year: number }): Observable<any>{
    const index = data.month + "/" + data.year;
     let loading,loaded;
     this.getisMultiCalendarLoading(data.month,data.year).subscribe(l=> loading = l);
     this.getIsMultiCalendarLoaded(data.month,data.year).subscribe(l=> loaded = l);
     if(!loading&&!loaded){
       this.store.dispatch(new MultiCalendarIndexRequestAction(index));
       this.multicalendarService.getMultiCalendar(data).subscribe((res)=>{
         const result = {
           key: index, data: res["data"]
         };
         this.store.dispatch(new MultiCalendarIndexSuccessAction(result));
         this.store.dispatch(new CalendarDataIndexSuccess({
           blocks: res["blocks"],
           bookings: res["bookings"],
         }));
       });
     }
     return this.store.select((state)=> getMultiCalendarByMonth(state,data.month,data.year));
  }

  refreshButtonClicked() {
    this._eventBus.next();
  }

  onRefreshButtonClicked(): Observable<any> {
    return this._eventBus.asObservable();
  }


}
