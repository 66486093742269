import { GuestAccessDraft } from "../../../../models/new/drafts/guest-access-draft.model";
import { Action } from "../../../action";

export enum GuestAccessDraftActionTypes {
  INDEX_REQUEST = "[Guest Access Drafts NEW] Index Request",
  INDEX_SUCCESS = "[Guest Access Drafts NEW] Index Success",

  CREATE_REQUEST = "[Guest Access Drafts NEW] Create Request",
  CREATE_SUCCESS = "[Guest Access Drafts NEW] Create Success",

  SHOW_REQUEST = "[Guest Access Drafts NEW] Show Request",
  SHOW_SUCCESS = "[Guest Access Drafts NEW] Show Success",

  UPDATE_REQUEST = "[Guest Access Drafts NEW] Update Request",
  UPDATE_SUCCESS = "[Guest Access Drafts NEW] Update Success",

  DELETE_REQUEST = "[Guest Access Drafts NEW] Delete Request",
  DELETE_SUCCESS = "[Guest Access Drafts NEW] Delete Success"
}

export class GuestAccessDraftIndexRequest implements Action {
  readonly type = GuestAccessDraftActionTypes.INDEX_REQUEST;
}

export class GuestAccessDraftIndexSuccess implements Action {
  readonly type = GuestAccessDraftActionTypes.INDEX_SUCCESS;

  constructor(public payload: GuestAccessDraft[]) {
  }
}

export class GuestAccessDraftCreateRequest implements Action {
  readonly type = GuestAccessDraftActionTypes.CREATE_REQUEST;
}

export class GuestAccessDraftCreateSuccess implements Action {
  readonly type = GuestAccessDraftActionTypes.CREATE_SUCCESS;

  constructor(public payload: GuestAccessDraft) {
  }
}

export class GuestAccessDraftShowRequest implements Action {
  readonly type = GuestAccessDraftActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {

  }
}

export class GuestAccessDraftShowSuccess implements Action {
  readonly type = GuestAccessDraftActionTypes.SHOW_SUCCESS;

  constructor(public payload: GuestAccessDraft) {
  }
}

export class GuestAccessDraftUpdateRequest implements Action {
  readonly type = GuestAccessDraftActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class GuestAccessDraftUpdateSuccess implements Action {
  readonly type = GuestAccessDraftActionTypes.UPDATE_SUCCESS;

  constructor(public payload: GuestAccessDraft) {
  }
}

export class GuestAccessDraftDeleteRequest implements Action {
  readonly type = GuestAccessDraftActionTypes.DELETE_REQUEST;

  constructor(public payload: number) {
  }
}

export class GuestAccessDraftDeleteSuccess implements Action {
  readonly type = GuestAccessDraftActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
