import * as _ from "lodash";

import { Action } from "../../../actions/action";
import { MessageActionTypes } from "../../../actions/new/inbox/message";
import { Message } from "../../../models/new/inbox/message";

export interface ThreadMessageState {
  messages: { [threadId: number]: number[] };
  loadingThreads: number[];
  loadedThreads: number[];
}

export const initialState: ThreadMessageState = {
  messages: {},
  loadingThreads: [],
  loadedThreads: [],
};

export function threadMessageReducer(state: ThreadMessageState = initialState, action: Action): ThreadMessageState {

  switch (action.type) {

    case MessageActionTypes.INDEX_REQUEST: {

      const threadId = action.payload as number;
      let loadingThreadIds = state.loadingThreads;
      const loadingIndex = _.indexOf(loadingThreadIds, threadId);

      // Adding if not present
      if (loadingIndex === -1) {
        loadingThreadIds = [
          ...loadingThreadIds,
          threadId
        ];
      }

      let loadedThreadId = state.loadedThreads;
      const loadedIndex = _.indexOf(loadedThreadId, threadId);

      // Removing if already added
      if (loadedIndex !== -1) {
        loadedThreadId = _.remove(loadedThreadId, threadId);
      }


      return {
        ...state,
        loadingThreads: loadingThreadIds,
        loadedThreads: loadedThreadId,
      };

    }
    case MessageActionTypes.INDEX_SUCCESS: {

      const messages = action.payload.messages as Message[];
      const threadId = action.payload.threadId as number;

      const ids = messages.map(m => m.id);


      let loadingThreadIds = state.loadingThreads;
      const loadingIndex = _.indexOf(loadingThreadIds, threadId);

      // Adding if not present
      if (loadingIndex !== -1) {
        loadingThreadIds = _.remove(loadingThreadIds, threadId);
      }

      let loadedThreadIds = state.loadedThreads;
      const loadedIndex = _.indexOf(loadedThreadIds, threadId);

      // Removing if already added
      if (loadedIndex === -1) {
        loadedThreadIds = [
          ...loadedThreadIds,
          threadId
        ];
      }

      return {
        ...state,
        loadingThreads: loadingThreadIds,
        loadedThreads: loadedThreadIds,
        messages: {
          ...state.messages,
          [threadId]: ids
        }
      };

    }

    case MessageActionTypes.CREATE_SUCCESS: {
      const payload = action.payload as { message: Message, threadId: number };

      let messages = state.messages[payload.threadId];
      messages = [
        ...messages,
        payload.message.id
      ];

      return {
        ...state,
        messages: {
          ...state.messages,
          [payload.threadId]: messages
        }
      };

    }

    default: {
      return state;
    }


  }
}


export const _getMessageIdsForThreadId = (state: ThreadMessageState, threadId: number) => {
  return state.messages[threadId] || [];
};

export const _getMessagesLoadingForThreadId = (state: ThreadMessageState, threadId: number) => {
  return state.loadingThreads.indexOf(threadId) !== -1;
};

export const _getMessagesLoadedForThreadId = (state: ThreadMessageState, threadId: number) => {
  return state.loadedThreads.indexOf(threadId) !== -1;
};
