import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";
import { __HTTPResponseType } from "../../enums/common.enum";
import { ConciergeDraftsGetResponse } from "../../models/responses/settings/drafts/concierge-draft-get.response";
import { ConciergeDraftsShowResponse } from "../../models/responses/settings/drafts/concierge-draft-show.model";
import { ConciergeDraft } from "../../models/new/drafts/concierge-draft.model";

@Injectable()
export class ConciergeDraftsService {

  constructor(private apiService: ApiService) {
  }

  getConciergeDrafts(): Observable<ConciergeDraftsGetResponse> {
    return this.apiService.get<ConciergeDraftsGetResponse>("/admin/concierge-drafts", true);
  }

  getConciergeDraftById(draftId):Observable<ConciergeDraftsShowResponse> {
    return this.apiService.get<ConciergeDraftsShowResponse>("/admin/concierge-drafts/"+draftId,true);
  }

  createConciergeDraft(data: Partial<ConciergeDraft>): Observable<ConciergeDraftsShowResponse> {
    return this.apiService.post<ConciergeDraftsShowResponse>("/admin/concierge-drafts", true, data);
  }

  updateConciergeDraft(draftId: number, data: any): Observable<ConciergeDraftsShowResponse> {
    return this.apiService.put<ConciergeDraftsShowResponse>("/admin/concierge-drafts/" + draftId, true, data);
  }

  deleteConciergeDraft(draftId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/concierge-drafts/" + draftId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}

