import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { __HTTPResponseType } from "../../enums/common.enum";
import { ApiService } from "../api.service";
import { GettingAroundDraftsShowResponse } from "../../models/responses/settings/drafts/getting-around-draft-show.response";
import { GettingAroundDraft } from "../../models/new/drafts/getting-around-draft.model";
import { GettingAroundDraftsGetResponse } from "../../models/responses/settings/drafts/getting-around-draft-get.response";

@Injectable()
export class GettingAroundDraftsService {

  constructor(private apiService: ApiService) {
  }

  getGettingAroundDrafts(): Observable<GettingAroundDraftsGetResponse> {
    return this.apiService.get<GettingAroundDraftsGetResponse>("/admin/getting-around-drafts", true);
  }

  getGettingAroundDraftById(draftId):Observable<GettingAroundDraftsShowResponse> {
    return this.apiService.get<GettingAroundDraftsShowResponse>("/admin/getting-around-drafts/"+draftId,true);
  }

  createGettingAroundDraft(data: Partial<GettingAroundDraft>): Observable<GettingAroundDraftsShowResponse> {
    return this.apiService.post<GettingAroundDraftsShowResponse>("/admin/getting-around-drafts", true, data);
  }

  updateGettingAroundDraft(draftId: number, data: any): Observable<GettingAroundDraftsShowResponse> {
    return this.apiService.put<GettingAroundDraftsShowResponse>("/admin/getting-around-drafts/" + draftId, true, data);
  }

  deleteGettingAroundDraft(draftId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/getting-around-drafts/" + draftId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}

