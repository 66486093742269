import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "sd-select",
  template: `
    <mat-form-field style="width: 100%" dividerColor="accent">
      <mat-select style="width:98%" (selectionChange)="onSelectionChanged($event.value)"
                  [formControl]="control"
                  [multiple]="multiple"
                  [disabled]="disable"
                  [placeholder]="placeholder"
                  (closed)="onClosed()"
                  (openedChange)="$event?searchField.focus():null">
        <mat-form-field style="width: 100%" color="primary" floatPlaceholder="auto">
          <input [formControl]="searchControl" matInput placeholder="Search" #searchField>
        </mat-form-field>
        <div style="overflow-y: scroll;max-height: 200px;">
          <ng-container *ngIf="multiple">
            <div>
              <button style="width: 100%; text-align: left" (click)="selectAll()" mat-button>Select All</button>
            </div>
            <div>
              <button style="width: 100%; text-align: left" (click)="selectNone()" mat-button>Select None</button>
            </div>
          </ng-container>
          <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
            {{option.title}}
          </mat-option>
        </div>
      </mat-select>
      <mat-error *ngIf="error">{{error}}</mat-error>
    </mat-form-field>
  `,
  styles: [``]
})

export class SelectComponent implements OnInit {
  @Output() selectionChanged = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Input() control: FormControl;
  @Input() placeholder: string;
  @Input() multiple: boolean;
  @Input() error: string;
  @Input() disable: boolean;
  searchControl = new FormControl();
  filteredOptions: { title: string, value: any }[] = [];
  private _options: { title: string, value: any }[] = [];

  get options() {
    return this._options || [];
  }

  @Input() set options(options) {
    this._options = options || [];
    this.filteredOptions = options || [];
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .subscribe((value: string) => {
        if (value) {
          this.filteredOptions = this.options
            .filter(option => option.title && option.title.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        } else {
          this.filteredOptions = this.options;
        }
      });
  }

  selectAll() {
    if (this.control) {
      this.control.setValue(this.options.map((option) => option.value));
    }
    this.onSelectionChanged(this.options.map((option) => option.value));
  }

  selectNone() {
    if (this.control) {
      this.control.setValue([]);
    }
    this.onSelectionChanged([]);
  }

  onSelectionChanged(value: any) {
    this.selectionChanged.emit(value);
  }

  onClosed() {
    this.closed.emit(this.control.value);
    this.filteredOptions = this.options;
    this.searchControl.setValue("");
  }
}
