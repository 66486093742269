import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, take } from "rxjs/operators";

import {
  AdminStatsRequest,
  AdminStatsSuccess,
  CleaningStatsRequest,
  CleaningStatsSuccess,
  CommissionStatsRequest,
  CommissionStatsSuccess, MonthlyBreakdownRemove,
  MonthlyBreakdownRequest,
  MonthlyBreakdownSuccess,
  NetIncomeStatsRequest,
  NetIncomeStatsSuccess,
  OwnerStatsRequest,
  OwnerStatsSuccess,
  StatsChangeNetIncomeYear,
  StatsSelectedListingsSuccess
} from "../actions/new/stats";
import { ListingCompact } from "../models/new/listing/listing-compact.model";
import {
  getAdminStats,
  getCleaningStats,
  getCommissionStats,
  getIsAdminStatsLoaded,
  getIsAdminStatsLoading,
  getIsCleaningStatsLoaded,
  getIsCleaningStatsLoading,
  getIsCommissionStatsLoaded,
  getIsCommissionStatsLoading,
  getIsMonthlyBreakdownLoaded,
  getIsMonthlyBreakdownLoading, getIsNetIncomeStatsLoaded, getIsNetIncomeStatsLoading,
  getIsOwnerStatsLoaded,
  getIsOwnerStatsLoading,
  getMonthlyBreakdown,
  getNetIncomeStats,
  getNetIncomeYear,
  getOwnerStats,
  getStatsSelectedListings,
  State
} from "../reducers";
import { StatsService } from "../services/stats.service";

@Injectable()
export class StatsRepository {

  constructor(private store: Store<State>,
              private statsService: StatsService) {
  }

  /**
   * Loading & Loaded
   */
  getIsOwnerStatsLoading(): Observable<boolean> {
    return this.store.select(getIsOwnerStatsLoading);
  }

  getIsOwnerStatsLoaded(): Observable<boolean> {
    return this.store.select(getIsOwnerStatsLoaded);
  }

  getIsAdminStatsLoading(): Observable<boolean> {
    return this.store.select(getIsAdminStatsLoading);
  }

  getIsAdminStatsLoaded(): Observable<boolean> {
    return this.store.select(getIsAdminStatsLoaded);
  }

  getIsCommissionStatsLoading(): Observable<boolean> {
    return this.store.select(getIsCommissionStatsLoading);
  }

  getIsCommissionStatsLoaded(): Observable<boolean> {
    return this.store.select(getIsCommissionStatsLoaded);
  }

  getIsCleaningStatsLoading(): Observable<boolean> {
    return this.store.select(getIsCleaningStatsLoading);
  }

  getIsCleaningStatsLoaded(): Observable<boolean> {
    return this.store.select(getIsCleaningStatsLoaded);
  }

  getIsNetIncomeStatsLoading(): Observable<boolean> {
    return this.store.select(getIsNetIncomeStatsLoading);
  }

  getIsNetIncomeStatsLoaded(): Observable<boolean> {
    return this.store.select(getIsNetIncomeStatsLoaded);
  }

  getIsMonthlyBreakdownLoading(): Observable<boolean> {
    return this.store.select(getIsMonthlyBreakdownLoading);
  }

  getIsMonthlyBreakdownLoaded(): Observable<boolean> {
    return this.store.select(getIsMonthlyBreakdownLoaded);
  }

  /**
   * CORE
   */
  getOwnerStats(homeOwnerId?: number, force = false) {
    let loading = false;
    let loaded = false;
    let year: number;

    this.getIsOwnerStatsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsOwnerStatsLoaded().pipe(take(1)).subscribe(l => loaded = l);
    this.getSelectedYear().pipe(take(1)).subscribe(y => year = y);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new OwnerStatsRequest());
      this.statsService.loadOwnerStats(homeOwnerId, year).subscribe((res) => {
        this.store.dispatch(new OwnerStatsSuccess(res));
      });
    }

    return this.store.select(getOwnerStats);
  }

  getAdminStats(sources: string[] = [], force = false) {
    let loading = false;
    let loaded = false;

    this.getIsAdminStatsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsAdminStatsLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new AdminStatsRequest());
      this.statsService.loadAdminStats(sources).subscribe((res) => {
        this.store.dispatch(new AdminStatsSuccess(res));
      });
    }

    return this.store.select(getAdminStats);
  }

  getCommissionStats(sources: string[] = [], force = false) {
    let loading = false;
    let loaded = false;
    let year: number;

    this.getIsCommissionStatsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsCommissionStatsLoaded().pipe(take(1)).subscribe(l => loaded = l);
    this.getSelectedYear().pipe(take(1)).subscribe(y => year = y);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new CommissionStatsRequest());
      this.statsService.loadCommissionStats(sources, year).subscribe((res) => {
        this.store.dispatch(new CommissionStatsSuccess(res));
      });
    }

    return this.store.select(getCommissionStats);
  }

  getCleaningStats(sources: string[] = [], force = false) {
    let loading = false;
    let loaded = false;
    let year: number;

    this.getIsCleaningStatsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsCleaningStatsLoaded().pipe(take(1)).subscribe(l => loaded = l);
    this.getSelectedYear().pipe(take(1)).subscribe(y => year = y);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new CleaningStatsRequest());
      this.statsService.loadCleaningStats(sources, year).subscribe((res) => {
        this.store.dispatch(new CleaningStatsSuccess(res));
      });
    }

    return this.store.select(getCleaningStats);
  }

  getNetIncomeStats(sources: string[] = [], force = false) {
    let loading = false;
    let loaded = false;
    let year: number;

    this.getIsNetIncomeStatsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsNetIncomeStatsLoaded().pipe(take(1)).subscribe(l => loaded = l);
    this.getSelectedYear().pipe(take(1)).subscribe(y => year = y);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new NetIncomeStatsRequest());
      this.statsService.loadNetIncomeStats(sources, year).subscribe((res) => {
        this.store.dispatch(new NetIncomeStatsSuccess(res));
      });
    }

    return this.store.select(getNetIncomeStats);
  }

  getMonthlyBreakdown(sources: string[] = [], homeOwnerId?: number, force = false) {
    let loading = false;
    let loaded = false;
    let year: number;

    this.getIsMonthlyBreakdownLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsMonthlyBreakdownLoaded().pipe(take(1)).subscribe(l => loaded = l);
    this.getSelectedYear().pipe(take(1)).subscribe(y => year = y);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new MonthlyBreakdownRequest());
      this.statsService.loadMonthlyBreakdown(sources, homeOwnerId, year).subscribe((res) => {
        this.store.dispatch(new MonthlyBreakdownSuccess(res));
      });
    }

    return this.store.select(getMonthlyBreakdown);
  }

  removeMonthlyBreakdown() {
    this.store.dispatch(new MonthlyBreakdownRemove());
  }

  setSelectedListings(listings: ListingCompact[]) {
    this.store.dispatch(new StatsSelectedListingsSuccess(listings));
  }

  getSelectedListings(): Observable<ListingCompact[]> {
    return this.store.select(getStatsSelectedListings).pipe(filter(l => !!l));
  }

  getSelectedYear(): Observable<number> {
    return this.store.select(getNetIncomeYear);
  }

  setSelectedYear(year: number) {
    this.store.dispatch(new StatsChangeNetIncomeYear(year));
  }

}
