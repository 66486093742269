import {map, take} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {DiscountService} from "../services/discount.service";
import {getDiscounts, getIsDiscountsLoaded, getIsDiscountsLoading, State} from "../reducers";
import {Store} from "@ngrx/store";
import {DiscountRule} from "../models/rule";
import {Observable} from "rxjs";
import {LastMinuteDiscount} from "../models/last-minute-discount";
import {
  DiscountAddRequestAction,
  DiscountADDSuccessAction,
  DiscountIndexRequestAction,
  DiscountIndexSuccessAction,
  DiscountUpdateRequestAction,
  DiscountUpdateSuccessAction
} from "../actions/discount";

@Injectable()
export class DiscountRepository {

  constructor(private discountService: DiscountService,
              private store: Store<State>) {
  }

  addSpecialDiscount(data: { title: string, description: string, rules: DiscountRule[] }): Observable<LastMinuteDiscount> {
    this.store.dispatch(new DiscountAddRequestAction());
    return this.discountService.addSpecialDiscount(data).pipe(map(res => {
      this.store.dispatch(new DiscountADDSuccessAction(res));
      return res;
    }));
  }

  updateSpecialDiscount(discountId: number, data: { title: string, description: string, rules: DiscountRule[] }): Observable<LastMinuteDiscount> {
    this.store.dispatch(new DiscountUpdateRequestAction());
    return this.discountService.updateSpecialDiscount(discountId, data).pipe(map(res => {
      this.store.dispatch(new DiscountUpdateSuccessAction(res));
      return res;
    }));
  }

  getIsDiscountsLoading(){
    return this.store.select(getIsDiscountsLoading);
  }

  getIsDiscountsLoaded(){
    return this.store.select(getIsDiscountsLoaded);
  }

  fetchSpecialDiscounts(force: boolean): Observable<LastMinuteDiscount[]> {
    let loading = false;
    let loaded = false;
    this.getIsDiscountsLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsDiscountsLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new DiscountIndexRequestAction());
      this.discountService.fetchSpecialDiscounts().subscribe((discounts) => {
        this.store.dispatch(new DiscountIndexSuccessAction(discounts));
      });
    }

    return this.store.select(getDiscounts);
  }

}
