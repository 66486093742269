import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { TaskRepository } from "../../../../repository/task.repository";
import { SnackbarService } from "../../../../services/snackbar.service";

@Component({
  selector: "sd-confirm-escalate", template: `
    <sd-modal-popup-layout title="Confirm Escalate Task">
      <div fxLayout="column" fxLayoutGap="30px">
        <span>
          <h4 style="font-weight: bold;">Are you sure you want to escalate this task?</h4>
        </span>
        <span>
          <mat-checkbox [(ngModel)]="showExpense">Include Expenses For Task</mat-checkbox>
        </span>
        <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px">
          <mat-spinner color="accent" [diameter]="30" [strokeWidth]="4" *ngIf="loading"></mat-spinner>
          <button mat-raised-button color="accent" (click)="yesClicked()">Yes</button>
          <button mat-raised-button color="warn" (click)="noClicked()">No</button>
        </div>
      </div>
    </sd-modal-popup-layout>

  `,
  styles: [`

  `]
})
export class ConfirmTaskEscalatePopupComponent {
  dialogRef: MatDialogRef<any>;
  showExpense: boolean = false;
  taskId: number;
  loading: boolean = false;

  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) private data: any,
              private taskRepo: TaskRepository, private snackBarService: SnackbarService,
              private router: Router) {
    this.taskId = data.taskId;
  }


  yesClicked() {
    this.loading = true;
    this.taskRepo.duplicateTask(this.taskId, this.showExpense).subscribe(res => {
      this.loading = false;
      this.snackBarService.show("Your Task was escalated successfully!");
      this.router.navigate(["/tasks/" + res.id]);
      this.dialog.closeAll();
    }, err => {
      this.loading = false;
      this.dialog.closeAll();
    });
  }

  noClicked() {
    this.dialog.closeAll();
  }
}

