import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { __HTTPResponseType } from "../../enums/common.enum";
import { ApiService } from "../api.service";
import { OtherNotesDraftsShowResponse } from "../../models/responses/settings/drafts/other-notes-draft-show.response";
import { OtherNotesDraft } from "../../models/new/drafts/other-notes-draft.model";
import { OtherNotesDraftsGetResponse } from "../../models/responses/settings/drafts/other-notes-draft-get.response";


@Injectable()
export class OtherNotesDraftsService {

  constructor(private apiService: ApiService) {
  }

  getOtherNotesDrafts(): Observable<OtherNotesDraftsGetResponse> {
    return this.apiService.get<OtherNotesDraftsGetResponse>("/admin/other-notes-drafts", true);
  }

  getOtherNotesDraftById(draftId):Observable<OtherNotesDraftsShowResponse> {
    return this.apiService.get<OtherNotesDraftsShowResponse>("/admin/other-notes-drafts/"+draftId,true);
  }

  createOtherNotesDraft(data: Partial<OtherNotesDraft>): Observable<OtherNotesDraftsShowResponse> {
    return this.apiService.post<OtherNotesDraftsShowResponse>("/admin/other-notes-drafts", true, data);
  }

  updateOtherNotesDraft(draftId: number, data: any): Observable<OtherNotesDraftsShowResponse> {
    return this.apiService.put<OtherNotesDraftsShowResponse>("/admin/other-notes-drafts/" + draftId, true, data);
  }

  deleteOtherNotesDraft(draftId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/other-notes-drafts/" + draftId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}

