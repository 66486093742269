import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { MultiCalendarIndexRequestAction, MultiCalendarIndexSuccessAction } from "../actions/multi-calendar";
import { MultiCalendar } from "../models/multi-calendar";
@Injectable()
export class MultiCalendarService {

  constructor(private apiService: ApiService) {
  }

  getMultiCalendar(data: { month: number, year: number }): Observable<MultiCalendar> {
    const index = data.month + "/" + data.year;
    return this.apiService.get<any>("/multi-calendar", true, data);

  }
}
