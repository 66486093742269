import {Action} from "../action";
import {Charity} from "../../models/charity";

export enum CharityActionTypes {
  INDEX_REQUEST = "[CHARITY NEW] Index Request",
  INDEX_SUCCESS = "[CHARITY NEW] Index Success",

  CREATE_SUCCESS = "[CHARITY NEW] Create Success",
  UPDATE_SUCCESS = "[CHARITY NEW] Update Success",
  DELETE_SUCCESS = "[CHARITY NEW] Delete Success",
}

export class CharityIndexRequest implements Action {
  readonly type = CharityActionTypes.INDEX_REQUEST;
}

export class CharityIndexSuccess implements Action {
  readonly type = CharityActionTypes.INDEX_SUCCESS;

  constructor(public payload: Charity[]) {
  }
}

export class CharityCreateSuccess implements Action {
  readonly type = CharityActionTypes.CREATE_SUCCESS;

  constructor(public payload: Charity) {
  }
}

export class CharityUpdateSuccess implements Action {
  readonly type = CharityActionTypes.UPDATE_SUCCESS;

  constructor(public payload: Charity) {
  }
}

export class CharityDeleteSuccess implements Action {
  readonly type = CharityActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
