import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { MultiCalendarRule } from "../../models/new/multi-calendar-rule";
import { Action } from "../../actions/action";
import { MultiCalendarRuleActionTypes } from "../../actions/new/setttings/multi-calendar-rule";

export interface MultiCalendarRuleState extends EntityState<MultiCalendarRule>{
 isLoading: boolean;
 isLoaded: boolean;
}

export const multiCalendarRuleAdapter: EntityAdapter<MultiCalendarRule> = createEntityAdapter<MultiCalendarRule>({
  selectId: (rule: MultiCalendarRule) => rule.id
});

export const initialState: MultiCalendarRuleState = multiCalendarRuleAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
});

export function multiCalendarRuleReducer(state: MultiCalendarRuleState = initialState, action: Action): MultiCalendarRuleState {

  switch (action.type) {

    case MultiCalendarRuleActionTypes.INDEX_REQUEST: {
      return {
        ...state,
        isLoading:true,
        isLoaded: false,
      }
    }

    case MultiCalendarRuleActionTypes.INDEX_SUCCESS: {
      const rules = action.payload as MultiCalendarRule[];

      return multiCalendarRuleAdapter.addAll(rules, {
        ...state,
        isLoading: false,
        isLoaded: true
      })
    }

    case MultiCalendarRuleActionTypes.UPDATE_REQUEST: {
      return state;
    }

    case MultiCalendarRuleActionTypes.UPDATE_SUCCESS: {
      const rule = action.payload as MultiCalendarRule;

      const newState = multiCalendarRuleAdapter.addOne(rule, state);

      return multiCalendarRuleAdapter.updateOne({id: rule.id, changes: rule}, newState);
    }

    case MultiCalendarRuleActionTypes.CREATE_SUCCESS: {
      const rule = action.payload as MultiCalendarRule;

      return multiCalendarRuleAdapter.addOne(rule, state);
    }

    case MultiCalendarRuleActionTypes.DELETE_SUCCESS: {
      const id = action.payload as number;

      return multiCalendarRuleAdapter.removeOne(id, state);
    }


    default: {
      return state;
    }

  }
}


export const _getIsMultiCalendarRulesLoading = (state: MultiCalendarRuleState) => state.isLoading;
export const _getIsMultiCalendarRulesLoaded = (state: MultiCalendarRuleState) => state.isLoaded;
