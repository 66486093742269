import { Component, OnInit } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { UserFull } from "../../models/new/user/user-full.model";
import { UserRepository } from "../../repository/user-repository";
import { CommonUtil } from "../../utils/common.util";

@Component({
  selector: "sd-app",
  template: `
    <ng-container *ngIf="showSpinner">
      <sd-center-spinner></sd-center-spinner>
    </ng-container>

    <ng-container *ngIf="!showSpinner">
      <router-outlet></router-outlet>
    </ng-container>
  `,
  styles: [],
  providers: []
})
export class AppComponent implements OnInit {

  showSpinner: boolean;
  subscription$: Subscription;

  constructor(dateAdapter: DateAdapter<Date>, private userRepo: UserRepository, private router: Router) {
    // TODO: remove this later. need to do this for now as MatDatePicker has a bug for some locales
    // including Indian locale due to which it interprets 11 Oct as 10 Nov and similar.
    dateAdapter.setLocale("en-us");
  }

  ngOnInit() {
    if ((window as any).HubSpotConversations) {
      console.log("The api is ready already");
      setTimeout(() => this.setUpUser(), 500);
    } else {
      (window as any).hsConversationsOnReady = [
        () => {
          console.log("Now the api is ready");
          setTimeout(() => this.setUpUser(), 500);
        },
      ];
    }
  }

  setUpUser() {
    this.userRepo.getIsUserLoggedIn().subscribe(l => {
      if (!l) {
        this.hideIntercom(true);
      } else {
        if (this.subscription$ && !this.subscription$.closed) {
          this.subscription$.unsubscribe();
        }
        this.userRepo.getUser().subscribe(user => {
          this.setUpHubspot(user);
        });
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.showSpinner = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.showSpinner = false;
      }
    });
  }

  setUpHubspot(user?: UserFull) {
    if (user && (CommonUtil.isVendor(user) || CommonUtil.isHomeOwner(user))) {

      const _hsq = (window as any)._hsq = (window as any)._hsq || [];

      _hsq.push(["identify", {
        id: user.id,
        email: user.email,
        firstname: user.first_name,
        lastname: user.last_name
      }]);

      console.log("called", _hsq);

      this.hideIntercom(false);
    } else {
      this.hideIntercom(true);
    }
  }

  hideIntercom(hide: boolean) {
    const element = document.getElementById("hubspot-messages-iframe-container");
    console.log("inside display", element);
    if (element) {
      if (hide) {
        element.style.cssText = "display:none !important";
      } else {
        element.style.cssText = "display:initial !important;width: 276px;height: 250px;";
      }
    }
  }

}
