import { OwnerWelcomeLetter } from "../../../models/new/owner-welcome-letter.model";
import { Action } from "../../action";

export enum OwnerWelcomeLetterActionTypes {
  INDEX_REQUEST = "[Owner Welcome Letter] Index Request",
  INDEX_SUCCESS = "[Owner Welcome Letter] Index Success",

  CREATE_REQUEST = "[Owner Welcome Letter] Create Request",
  CREATE_SUCCESS = "[Owner Welcome Letter] Create Success",

  SET_AS_DEFAULT = "[Owner Welcome Letter] Set as default",
}

export class OwnerWelcomeLetterIndexRequest implements Action {
  readonly type = OwnerWelcomeLetterActionTypes.INDEX_REQUEST;
}

export class OwnerWelcomeLetterIndexSuccess implements Action {
  readonly type = OwnerWelcomeLetterActionTypes.INDEX_SUCCESS;

  constructor(public payload: OwnerWelcomeLetter[]) {
  }
}

export class OwnerWelcomeLetterCreateRequest implements Action {
  readonly type = OwnerWelcomeLetterActionTypes.CREATE_REQUEST;
}

export class OwnerWelcomeLetterCreateSuccess implements Action {
  readonly type = OwnerWelcomeLetterActionTypes.CREATE_SUCCESS;

  constructor(public payload: OwnerWelcomeLetter) {
  }
}

export class OwnerWelcomeLetterSetAsDefault implements Action {
  readonly type = OwnerWelcomeLetterActionTypes.SET_AS_DEFAULT;

  constructor(public payload: number) {
  }
}
