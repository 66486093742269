export enum ReservationType {
  TODAYS_BOOKINGS = "today_bookings",
  RECENT_BOOKINGS = "recent_bookings",
  TODAYS_CHECKOUT = "today_checkout",
  UPCOMING_BOOKINGS = "upcoming_bookings",
  PAST_BOOKINGS = "past_bookings",
  ONGOING_BOOKINGS = "ongoing_bookings",
  RECENTLY_CANCELLED = "recently_cancelled",
  RECENTLY_REVIEWED = "recently_reviewed",
  AWAITING_PAYMENT = "awaiting_payment",
}

export enum HomeownerReservationType {
  TODAYS_BOOKINGS = "today_bookings",
  RECENT_BOOKINGS = "recent_bookings",
  TODAYS_CHECKOUT = "today_checkout",
  UPCOMING_BOOKINGS = "upcoming_bookings",
  PAST_BOOKINGS = "past_bookings",
  ONGOING_BOOKINGS = "ongoing_bookings",
  RECENTLY_CANCELLED = "recently_cancelled",
  OWNER_BLOCK = "owner_block",
  RECENTLY_REVIEWED = "recently_reviewed",
}


export enum ReservationSortType {
  LISTINGS = "properties.title",
  FIRST_NAME = "users.first_name",
  CONFIRMATION_CODE = "confirmation_code",
  SOURCE = "source",
  CHECK_IN_TIME = "check_in_time",
  CHECK_OUT_TIME = "check_out_time",
  START = "start",
  END = "end",
  CREATED_AT = "created_at",
  CANCELLED_AT = "cancelled_at",
  OWNERS_REVENUE = "owners_revenue"
}

export enum RequestSendMoneyEnum {
  REQUESTED = "request",
  SEND = "send",
}

export enum SendReasons {
  UNCLEAN_LISTING = "unclean_inaccurate",
  OTHER_TRIP_SEND = "other_trip_send"
}

export enum RequestReasons {
  EXTRA_SERVICES = "extra_services",
  OTHER_TRIP_REQUEST = "other_trip_request"
}

export enum ReservationLogType {
  CREATED = "created",
  ALTERED = "altered",
  UPDATED_STATUS = "updated_status",
  UPDATED_ARRIVAL_TIME = "updated_arrival_time",
  UPDATED_DEPARTURE_TIME = "updated_departure_time",
  UPDATED_LISTING = "updated_listing",
  SCHEDULED_MESSAGE_ADDED = "scheduled_message_added",
  SCHEDULED_MESSAGE_SENT = "scheduled_message_sent",
  SCHEDULED_MESSAGE_NOT_SENT = "scheduled_message_not_sent",
  SCHEDULED_MESSAGE_UPDATED = "scheduled_message_updated",
  SCHEDULED_MESSAGE_DELETED = "scheduled_message_deleted",
  AUTO_TASK_ADDED = "auto_task_added",
  AUTO_TASK_FAILED = "auto_task_failed",
  TASK_ADDED = "task_added",
  TASK_DELETED = "task_deleted",
  TASK_ARCHIVED = "task_archived",
  SECURITY_DEDUCTED = "security_deducted",
  SECURITY_REFUNDED = "security_refunded",
  PAYMENT_RECEIVED = "payment_received",
  COMMISSION_UPDATED = "commission_updated",
  MONEY_REQUESTED = "money_requested",
  MONEY_SENT = "money_sent",
  PARTIAL_REFUND = "partial_refund",
  OWNER_BLOCK_CREATED = "owner_block_created",
}


export class ReservationEnumHelper {

  static getReservationTypeTitle(reservationType: ReservationType | HomeownerReservationType) {
    let title = "";
    switch (reservationType) {
      case ReservationType.TODAYS_BOOKINGS: {
        title = "Today's Check In";
        break;
      }
      case ReservationType.RECENT_BOOKINGS: {
        title = "Recently Booked";
        break;
      }
      case ReservationType.TODAYS_CHECKOUT: {
        title = "Today's Checkout";
        break;
      }
      case ReservationType.UPCOMING_BOOKINGS: {
        title = "Upcoming Reservation";
        break;
      }
      case ReservationType.PAST_BOOKINGS: {
        title = "Past Reservation";
        break;
      }
      case ReservationType.ONGOING_BOOKINGS: {
        title = "Ongoing Reservation";
        break;
      }
      case ReservationType.RECENTLY_CANCELLED: {
        title = "Recently Cancelled";
        break;
      }
      case ReservationType.RECENTLY_REVIEWED: {
        title = "Recently Reviewed";
        break;
      }
      case ReservationType.AWAITING_PAYMENT: {
        title = "Awaiting Payment";
        break;
      }
      case HomeownerReservationType.OWNER_BLOCK: {
        title = "Owner Blocks";
        break;
      }
    }
    return title;
  }


  static getReservationTypeSlug(reservationType: ReservationType | HomeownerReservationType) {
    let slug = "";
    switch (reservationType) {
      case ReservationType.TODAYS_BOOKINGS: {
        slug = "today_bookings";
        break;
      }
      case ReservationType.RECENT_BOOKINGS: {
        slug = "recent_bookings";
        break;
      }
      case ReservationType.TODAYS_CHECKOUT: {
        slug = "today_checkout";
        break;
      }
      case ReservationType.UPCOMING_BOOKINGS: {
        slug = "upcoming_bookings";
        break;
      }
      case ReservationType.PAST_BOOKINGS: {
        slug = "past_bookings";
        break;
      }
      case ReservationType.ONGOING_BOOKINGS: {
        slug = "ongoing_bookings";
        break;
      }

      case ReservationType.RECENTLY_CANCELLED: {
        slug = "recently_cancelled";
        break;
      }

      case ReservationType.RECENTLY_REVIEWED: {
        slug = "recently_reviewed";
        break;
      }

      case ReservationType.AWAITING_PAYMENT: {
        slug = "awaiting_payment";
        break;
      }

      case HomeownerReservationType.OWNER_BLOCK: {
        slug = "owner_block";
        break;
      }
    }
    return slug;
  }

  static getReservationTypeURLParams(reservationType: ReservationType | HomeownerReservationType) {
    let params = "";
    switch (reservationType) {
      case ReservationType.TODAYS_BOOKINGS: {
        params = "today";
        break;
      }
      case ReservationType.RECENT_BOOKINGS: {
        params = "recent";
        break;
      }
      case ReservationType.TODAYS_CHECKOUT: {
        params = "checkout-today";
        break;
      }
      case ReservationType.UPCOMING_BOOKINGS: {
        params = "upcoming";
        break;
      }
      case ReservationType.PAST_BOOKINGS: {
        params = "past";
        break;
      }
      case ReservationType.ONGOING_BOOKINGS: {
        params = "ongoing";
        break;
      }

      case ReservationType.RECENTLY_CANCELLED: {
        params = "recently-cancelled";
        break;
      }

      case ReservationType.RECENTLY_REVIEWED: {
        params = "recently-reviewed";
        break;
      }

      case ReservationType.AWAITING_PAYMENT: {
        params = "awaiting-payment";
        break;
      }

      case HomeownerReservationType.OWNER_BLOCK: {
        params = "owner-block";
        break;
      }
    }
    return params;
  }

  static getReservationLogType(type: ReservationLogType) {
    let title = "";
    switch (type) {
      case ReservationLogType.CREATED: {
        title = "Booking Was Created";
        break;
      }
      case ReservationLogType.UPDATED_STATUS: {
        title = "Booking Status Was Updated";
        break;
      }
      case ReservationLogType.UPDATED_ARRIVAL_TIME: {
        title = "Booking Arrival Time Was Changed";
        break;
      }
      case ReservationLogType.UPDATED_DEPARTURE_TIME: {
        title = "Booking Departure Time Was Changed";
        break;
      }
      case ReservationLogType.UPDATED_LISTING: {
        title = "Booking Listing Was Updated";
        break;
      }
      case ReservationLogType.SCHEDULED_MESSAGE_ADDED: {
        title = "Automated Message Was Added";
        break;
      }
      case ReservationLogType.SCHEDULED_MESSAGE_SENT: {
        title = "Automated Message Was Sent";
        break;
      }
      case ReservationLogType.SCHEDULED_MESSAGE_NOT_SENT: {
        title = "Automated Message Could Not Be Sent";
        break;
      }
      case ReservationLogType.SCHEDULED_MESSAGE_DELETED: {
        title = "Automated Message Was Deleted";
        break;
      }
      case ReservationLogType.SCHEDULED_MESSAGE_UPDATED: {
        title = "Automated Message Was Updated";
        break;
      }
      case ReservationLogType.AUTO_TASK_ADDED: {
        title = "Auto Task Was Added";
        break;
      }
      case ReservationLogType.AUTO_TASK_FAILED: {
        title = "Auto Task Failed";
        break;
      }
      case ReservationLogType.TASK_ADDED: {
        title = "Task Was Added";
        break;
      }
      case ReservationLogType.TASK_DELETED: {
        title = "task Was Asigneed To Another Booking";
        break;
      }
      case ReservationLogType.TASK_ARCHIVED: {
        title = "Task Was Archived";
        break;
      }
      case ReservationLogType.SECURITY_DEDUCTED: {
        title = "Security Was Deducted";
        break;
      }
      case ReservationLogType.SECURITY_REFUNDED: {
        title = "Security Was Refunded";
        break;
      }
      case ReservationLogType.PAYMENT_RECEIVED: {
        title = "Payment Was Recieved";
        break;
      }

      case ReservationLogType.ALTERED: {
        title = "Booking was Altered";
        break;
      }

      case ReservationLogType.COMMISSION_UPDATED: {
        title = "Commission Rate was Updated";
        break;
      }

      case ReservationLogType.MONEY_REQUESTED: {
        title = "Money was Requested";
        break;
      }

      case ReservationLogType.MONEY_SENT: {
        title = "Money was Sent";
        break;
      }
      case ReservationLogType.PARTIAL_REFUND: {
        title = "Amount was Refunded";
        break;
      }
      case ReservationLogType.OWNER_BLOCK_CREATED: {
        title = "Owner Block was Created";
        break;
      }
    }
    return title;
  }


  static getRequestSendMoneyTitle(type: RequestSendMoneyEnum): string {
    let title = "";
    switch (type) {

      case RequestSendMoneyEnum.REQUESTED: {
        title = "Request Money";
        break;
      }

      case RequestSendMoneyEnum.SEND: {
        title = "Send Money";
        break;
      }
    }
    return title;
  }

  static getReasonTitle(reason: RequestReasons | SendReasons): string {
    let title = "";

    switch (reason) {

      case RequestReasons.EXTRA_SERVICES: {
        title = "Extra Services";
        break;
      }

      case SendReasons.UNCLEAN_LISTING: {
        title = "Unclean or inaccurate listing";
        break;
      }

      case RequestReasons.OTHER_TRIP_REQUEST:
      case SendReasons.OTHER_TRIP_SEND : {
        title = "Other trip-related issues";
      }
    }
    return title;
  }
}


