import { AutoResponse } from "../../../models/new/automation/auto-response.model";
import { Action } from "../../action";

export enum AutoResponseActionTypes {
  INDEX_REQUEST = "[AUTO RESPONSE NEW] Index Request",
  INDEX_SUCCESS = "[AUTO RESPONSE NEW] Index Success",

  CREATE_REQUEST = "[AUTO RESPONSE NEW] Create Request",
  CREATE_SUCCESS = "[AUTO RESPONSE NEW] Create Success",

  UPDATE_REQUEST = "[AUTO RESPONSE NEW] Update Request",
  UPDATE_SUCCESS = "[AUTO RESPONSE NEW] Update Success",

  DELETE_REQUEST = "[AUTO RESPONSE NEW] Delete Request",
  DELETE_SUCCESS = "[AUTO RESPONSE NEW] Delete Success",
}

export class AutoResponseIndexRequest implements Action {
  readonly type = AutoResponseActionTypes.INDEX_REQUEST;
}

export class AutoResponseIndexSuccess implements Action {
  readonly type = AutoResponseActionTypes.INDEX_SUCCESS;

  constructor(public payload: AutoResponse[]) {
  }
}

export class AutoResponseCreateRequest implements Action {
  readonly type = AutoResponseActionTypes.CREATE_REQUEST;
}

export class AutoResponseCreateSuccess implements Action {
  readonly type = AutoResponseActionTypes.CREATE_SUCCESS;

  constructor(public payload: AutoResponse) {
  }
}

export class AutoResponseUpdateRequest implements Action {
  readonly type = AutoResponseActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class AutoResponseUpdateSuccess implements Action {
  readonly type = AutoResponseActionTypes.UPDATE_SUCCESS;

  constructor(public payload: AutoResponse) {
  }
}

export class AutoResponseDeleteRequest implements Action {
  readonly type = AutoResponseActionTypes.DELETE_REQUEST;

  constructor(public payload: number) {
  }
}

export class AutoResponseDeleteSuccess implements Action {
  readonly type = AutoResponseActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
