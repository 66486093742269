import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../../../actions/action";
import { Message } from "../../../models/new/inbox/message";
import { MessageActionTypes } from "../../../actions/new/inbox/message";

export interface MessageState extends EntityState<Message> {
}

export const messageAdapter: EntityAdapter<Message> = createEntityAdapter<Message>({
  selectId: (message: Message) => message.id
});

export const initialState: MessageState = messageAdapter.getInitialState();

export function messageReducer(state: MessageState = initialState, action: Action): MessageState {
  switch (action.type) {

    case MessageActionTypes.INDEX_REQUEST: {
      return state;
    }

    case MessageActionTypes.INDEX_SUCCESS: {
      const payload = action.payload as { messages: Message[], threadId: number };
      return messageAdapter.addMany(payload.messages, state);
    }

    case MessageActionTypes.CREATE_SUCCESS: {
      const payload = action.payload as { message: Message, threadId: number };

      return messageAdapter.addOne(payload.message, state);

    }

    default: {
      return {
        ...state
      };
    }
  }
}

export const _getMessageEntities = (state: MessageState) => state.entities;
