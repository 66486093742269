import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { MultiListing } from "../../models/new/multi-listing.model";
import { Action } from "../../actions/action";
import { MultiListingActionTypes } from "../../actions/new/setttings/multi-listing";

export interface MultiListingState extends EntityState<MultiListing> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const multiListingAdapter: EntityAdapter<MultiListing> = createEntityAdapter<MultiListing>({
  selectId: (multiListing: MultiListing) => multiListing.id
});

export const initialState: MultiListingState = multiListingAdapter.getInitialState({
  isLoading: false,
  isLoaded: false
});


export function multiListingReducer(state: MultiListingState = initialState, action: Action): MultiListingState {
  switch (action.type) {
    case MultiListingActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true
      }
    }

    case MultiListingActionTypes.INDEX_SUCCESS: {
      const multiListings = action.payload as MultiListing[];
      return multiListingAdapter.addAll(multiListings, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case MultiListingActionTypes.CREATE_REQUEST: {
        return {
          ...state,
          isLoading: true
        }
    }

    case MultiListingActionTypes.CREATE_SUCCESS: {
      const multiListing = action.payload as MultiListing;

      return multiListingAdapter.addOne(multiListing,{
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case MultiListingActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case MultiListingActionTypes.UPDATE_SUCCESS: {
      const multiListing = action.payload as MultiListing;

      return multiListingAdapter.updateOne({
        id: multiListing.id,
        changes: multiListing
      }, state);
    }

    case MultiListingActionTypes.DELETE_REQUEST: {
      return {
        ...state,
      }
    }

    case MultiListingActionTypes.DELETE_SUCCESS: {
      const multiListingId = action.payload as number;

      return multiListingAdapter.removeOne(multiListingId, state);
    }


    default: {
      return state;
    }
  }
}

export const _getIsMultiListingLoading = (state: MultiListingState) => state.isLoading;
export const _getIsMultiListingLoaded = (state: MultiListingState) => state.isLoaded;
