import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

import { Action } from "../../../actions/action";
import { GoalSetPercentagesDraftActionTypes } from "../../../actions/new/setttings/drafts/goal-set-percentages-drafts";
import { GoalSetPercentagesDraft } from "../../../models/new/drafts/goal-set-percentages.modal";

export interface GoalSetPercentagesDraftState extends EntityState<GoalSetPercentagesDraft> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const goalSetPercentagesDraftAdapter: EntityAdapter<GoalSetPercentagesDraft> = createEntityAdapter
  < GoalSetPercentagesDraft > ({
    selectId: (goalSetPercentagesDraft: GoalSetPercentagesDraft) => goalSetPercentagesDraft.id
  });

export const initialState: GoalSetPercentagesDraftState = goalSetPercentagesDraftAdapter.getInitialState({
  isLoading: false,
  isLoaded: false
});

export function goalSetPercentagesDraftReducer(state: GoalSetPercentagesDraftState = initialState, action: Action): GoalSetPercentagesDraftState {
  switch (action.type) {
    case GoalSetPercentagesDraftActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        isLoaded: false
      };
    }

    case GoalSetPercentagesDraftActionTypes.INDEX_SUCCESS: {
      const goalSetPercentagesDrafts = action.payload as GoalSetPercentagesDraft[];
      return goalSetPercentagesDraftAdapter.addAll(goalSetPercentagesDrafts, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case GoalSetPercentagesDraftActionTypes.CREATE_SUCCESS: {
      const goalSetPercentagesDraft = action.payload as GoalSetPercentagesDraft;
      return goalSetPercentagesDraftAdapter.addOne(goalSetPercentagesDraft, state);
    }

    case GoalSetPercentagesDraftActionTypes.UPDATE_SUCCESS: {
      const goalSetPercentagesDraft = action.payload as GoalSetPercentagesDraft;
      return goalSetPercentagesDraftAdapter.updateOne({
        id: goalSetPercentagesDraft.id,
        changes: goalSetPercentagesDraft
      }, state);
    }

    case GoalSetPercentagesDraftActionTypes.DELETE_SUCCESS: {
      const goalSetPercentagesDraftId = action.payload as number;
      return goalSetPercentagesDraftAdapter.removeOne(goalSetPercentagesDraftId, state);
    }

    default: {
      return state;
    }
  }
}

export const _getIsGoalSetPercentagesDraftLoading = (state: GoalSetPercentagesDraftState) => state.isLoading;
export const _getIsGoalSetPercentagesDraftLoaded = (state: GoalSetPercentagesDraftState) => state.isLoaded;
