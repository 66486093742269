import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { __HTTPResponseType } from "../enums/common.enum";
import { NeighbourhoodDraftsGetResponse } from "../models/responses/settings/neighbourhood-drafts-get.response";
import { NeighbourhoodDraft } from "../models/new/neighbourhood-drafts.model";
import { NeighbourhoodDraftsShowResponse } from "../models/responses/settings/neighbourhood-drafts-show.response";

@Injectable()
export class NeighbourhoodDraftsService {

  constructor(private apiService: ApiService) {
  }

  getDrafts(): Observable<NeighbourhoodDraftsGetResponse> {
    return this.apiService.get<NeighbourhoodDraftsGetResponse>("/admin/saved-neighbourhood-drafts", true);
  }

  createDraft(data: Partial<NeighbourhoodDraft>): Observable<NeighbourhoodDraftsShowResponse> {
    return this.apiService.post<NeighbourhoodDraftsShowResponse>("/admin/saved-neighbourhood-drafts", true, data);
  }

  updateDraft(draftId: number, data: any): Observable<NeighbourhoodDraftsShowResponse> {
    return this.apiService.put<NeighbourhoodDraftsShowResponse>("/admin/saved-neighbourhood-drafts/" + draftId, true, data);
  }

  deleteDraft(draftId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/saved-neighbourhood-drafts/" + draftId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}

