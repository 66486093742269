import { Injectable } from "@angular/core";
import {TaskAttachment} from "../models/new/tasks/task-attachment.model";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import { AppStateResetAction } from "../actions";
import { State } from "../reducers";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";

@Injectable()
export class AppService {
  constructor(private domSanitizer: DomSanitizer,
              private store: Store<State>,
              private router: Router) {
  }

  static hasLoginToken(): boolean {
    return !!localStorage.getItem("auth_token");
  }

  changeAppTitle(title: string) {
    document.title = title + " - StayDuvet";
  }

  getPDFUrl(attachment: TaskAttachment): SafeResourceUrl {
    console.log("[SEMPER] url is : ", attachment.url);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(attachment.url);
  }

  logout() {
    console.log("Auth Token");
    localStorage.removeItem("auth_token");
    localStorage.removeItem("mfa_token");
    this.store.dispatch(new AppStateResetAction());
    this.router.navigate(["/login"]);
  }

}
