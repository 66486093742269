import {map, take} from "rxjs/operators";
import {
  getAllSummaryDrafts,
  getFullSummaryDraftById,
  getIsFullSummaryDraftLoaded,
  getIsFullSummaryDraftLoading,
  getIsSummaryDraftLoaded,
  getIsSummaryDraftLoading,
  State
} from "../../reducers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {SummaryDraftsService} from "../../services/drafts/summary-drafts.service";
import {Injectable} from "@angular/core";
import {
  SummaryDraftCreateRequest,
  SummaryDraftCreateSuccess,
  SummaryDraftDeleteRequest,
  SummaryDraftDeleteSuccess,
  SummaryDraftIndexRequest,
  SummaryDraftIndexSuccess,
  SummaryDraftUpdateRequest,
  SummaryDraftUpdateSuccess
} from "../../actions/new/setttings/drafts/summary-drafts";
import {SummaryDraft} from "../../models/new/drafts/summary-draft.model";

@Injectable()
export class SummaryDraftRepository {

  constructor(private store: Store<State>,
              private summaryDraftService: SummaryDraftsService) {
  }

  /**
   * Loading & Loaded
   */
  getIsSummaryDraftLoading(): Observable<boolean> {
    return this.store.select(getIsSummaryDraftLoading);
  }

  getIsSummaryDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsSummaryDraftLoaded);
  }

  getIsFullSummaryDraftLoading(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullSummaryDraftLoading(state, draftId));
  }

  getIsFullSummaryDraftLoaded(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullSummaryDraftLoaded(state, draftId));
  }

  /**
   * Service Methods
   */
  getAllSummaryDrafts(force: boolean): Observable<SummaryDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsSummaryDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsSummaryDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new SummaryDraftIndexRequest());

      this.summaryDraftService.getSummaryDrafts().subscribe(response => {
        this.store.dispatch(new SummaryDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(getAllSummaryDrafts).pipe(map(t => t as SummaryDraft[]));
  }

  getSummaryDraftById(draftId: number): Observable<SummaryDraft> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsSummaryDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsSummaryDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new SummaryDraftIndexRequest());

      this.summaryDraftService.getSummaryDrafts().subscribe(response => {
        this.store.dispatch(new SummaryDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(state => getFullSummaryDraftById(state, draftId)).pipe(
      map(t => t as SummaryDraft));
  }


  createSummaryDraft(data: Partial<SummaryDraft>): Observable<SummaryDraft> {
    this.store.dispatch(new SummaryDraftCreateRequest());
    return this.summaryDraftService.createSummaryDraft(data).pipe(map(res => {
      this.store.dispatch(new SummaryDraftCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateSummaryDraft(draftId: number, data: Partial<SummaryDraft>): Observable<SummaryDraft> {
    this.store.dispatch(new SummaryDraftUpdateRequest(draftId));
    return this.summaryDraftService.updateSummaryDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new SummaryDraftUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deleteSummaryDraft(draftId: number): Observable<null> {
    this.store.dispatch(new SummaryDraftDeleteRequest(draftId));
    return this.summaryDraftService.deleteSummaryDraft(draftId).pipe(map(res => {
      this.store.dispatch(new SummaryDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}

