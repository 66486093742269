import { Amenity } from "../models/amenity";


export const THE_BASICS = "The Basics";
export const KITCHEN_AND_DINING = "Kitchen & Dining";
export const ENTERTAINMENT = "Entertainment";
export const FACILITIES = "Facilities";
export const OUTDOOR = "Outdoor";
export const PARKING = "Parking";
export const PET_FRIENDLY = "Pet Friendly";
export const CHILDREN = "Children";
export const BUSINESS_TRAVEL = "Business Travel";
export const ACCESSIBILITY = "Accessibility";
export const SAFETY = "Safety";
export const EXTRAS = "Extras";
export const SERVICES = "Services";
export const THEMES = "Themes";
export const LOCATION = "Location";


export const months = [
  {
    title: "January",
    id: 1
  },
  {
    title: "February",
    id: 2
  },
  {
    title: "March",
    id: 3
  },
  {
    title: "April",
    id: 4
  },
  {
    title: "May",
    id: 5
  },
  {
    title: "June",
    id: 6
  },
  {
    title: "July",
    id: 7
  },
  {
    title: "August",
    id: 8
  },
  {
    title: "September",
    id: 9
  },
  {
    title: "October",
    id: 10
  },
  {
    title: "November",
    id: 11
  },
  {
    title: "December",
    id: 12
  }
];

export const amenitiesObject: { [key: string]: Amenity } = {
  living_room: {
    exists: false,
    icon: "weekend",
    title: "Living Room",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  kitchen: {
    exists: false,
    icon: "kitchen",
    title: "Kitchen",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  laundromat: {
    exists: false,
    icon: "http",
    title: "Laundromat",
    category: SERVICES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: true,
      hvmi: true
    }
  },

  internet: {
    exists: false,
    icon: "http",
    title: "Internet",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: true,
      hvmi: false
    }
  },

  washer: {
    exists: false,
    icon: "local_laundry_service",
    title: "Washer",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  dryer: {
    exists: false,
    icon: "local_laundry_service",
    title: "Dryer",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  bed_linens: {
    exists: false,
    icon: "hotel",
    title: "Bed Linens",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: false
    }
  },

  towels_provided: {
    exists: false,
    icon: "hotel",
    title: "Towels Provided",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: true,
      hvmi: false
    }
  },

  hair_dryer: {
    exists: false,
    icon: "toys",
    title: "Hair dryer",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  iron: {
    exists: false,
    icon: "power_input",
    title: "Iron",
    category: THE_BASICS,
    available_on: {
      airbnb: true,
      stayduvet: true,
      homeaway: true,
      hvmi: true
    }
  },

  hangers: {
    exists: false,
    icon: "events",
    title: "Hangers",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  shampoo: {
    exists: false,
    icon: "battery_full",
    title: "Shampoo",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: false
    }
  },

  shower_chair: {
    exists: false,
    icon: "whatshot",
    title: "Shower chair",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: false
    }
  },

  extra_pillows_and_blankets: {
    exists: false,
    icon: "layers",
    title: "Extra pillows and blankets",
    category: THE_BASICS,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  tv: {
    exists: false,
    icon: "tv",
    title: "TV",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  cable_tv: {
    exists: false,
    icon: "live_tv",
    title: "Cable TV",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  essentials: {
    exists: false,
    icon: "local_grocery_store",
    title: "Essentials",
    category: THE_BASICS,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  ceiling_fan: {
    exists: false,
    icon: "toys",
    title: "Ceiling Fan",
    category: THE_BASICS,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  toilet_paper: {
    exists: false,
    icon: "whatshot",
    title: "Toilet Paper",
    category: THE_BASICS,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: true,
      hvmi: false
    }
  },

  body_soap: {
    exists: false,
    icon: "local_mall",
    title: "Body Soap",
    category: THE_BASICS,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: true,
      hvmi: false
    }
  },

  conditioner: {
    exists: false,
    icon: "local_mall",
    title: "Conditioner",
    category: THE_BASICS,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: true,
      hvmi: false
    }
  },

  cooking_basics: {
    exists: false,
    icon: "restaurant_menu",
    title: "Cooking Basics",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  refrigerator: {
    exists: false,
    icon: "kitchen",
    title: "Refrigerator",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  dishwasher: {
    exists: false,
    icon: "local_drink",
    title: "Dishwasher",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  oven: {
    exists: false,
    icon: "room_service",
    title: "Oven",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },


  stove: {
    exists: false,
    icon: "whatshot",
    title: "Stove",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  microwave: {
    exists: false,
    icon: "room_service",
    title: "Microwave",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  toaster: {
    exists: false,
    icon: "room_service",
    title: "Toaster",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: true,
      hvmi: false
    }
  },

  coffee_maker: {
    exists: false,
    icon: "free_breakfast",
    title: "Coffee maker",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  kitchenette: {
    exists: false,
    icon: "whatshot",
    title: "Kitchenette",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  wood_stove: {
    exists: false,
    icon: "whatshot",
    title: "Wood Stove",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  mini_fridge: {
    exists: false,
    icon: "kitchen",
    title: "Mini Fridge",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: true,
      hvmi: false
    }
  },

  freezer: {
    exists: false,
    icon: "kitchen",
    title: "Freezer",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: true,
      hvmi: false
    }
  },

  dvd_player: {
    exists: false,
    icon: "play_circle_outline",
    title: "DVD Player",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  games: {
    exists: false,
    icon: "games",
    title: "Games",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  game_room: {
    exists: false,
    icon: "games",
    title: "Game Room",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  pool_table: {
    exists: false,
    icon: "games",
    title: "Pool Table",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  foosball: {
    exists: false,
    icon: "games",
    title: "Foosball",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  stereo: {
    exists: false,
    icon: "surround_sound",
    title: "Stereo",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  video_games: {
    exists: false,
    icon: "featured_play_list",
    title: "Video Games",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  private_pool: {
    exists: false,
    icon: "games",
    title: "Private Pool",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  indoor_pool: {
    exists: false,
    icon: "pool",
    title: "Indoor Pool",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  heated_pool: {
    exists: false,
    icon: "pool",
    title: "Heated Pool",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  outdoor_pool: {
    exists: false,
    icon: "pool",
    title: "Outdoor Pool",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  hot_tub: {
    exists: false,
    icon: "hot_tub",
    title: "Hot Tub",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  gym: {
    exists: false,
    icon: "whatshot",
    title: "Gym",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  internet_access: {
    exists: false,
    icon: "whatshot",
    title: "Internet access",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  sauna: {
    exists: false,
    icon: "spa",
    title: "Sauna",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  ev_charger: {
    exists: false,
    icon: "ev_station",
    title: "EV Charger",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  single_level_home: {
    exists: false,
    icon: "home",
    title: "Single Level Home",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  balcony: {
    exists: false,
    icon: "filter_drama",
    title: "Balcony",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  canoe: {
    exists: false,
    icon: "filter_drama",
    title: "Canoe",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  beach_chairs: {
    exists: false,
    icon: "filter_drama",
    title: "Beach Chairs",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  kayak: {
    exists: false,
    icon: "filter_drama",
    title: "Kayak",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  boat: {
    exists: false,
    icon: "filter_drama",
    title: "Boat",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  beach_essentials: {
    exists: false,
    icon: "filter_drama",
    title: "Beach Essentials",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  car_necessary: {
    exists: false,
    icon: "filter_drama",
    title: "Car Necessary",
    category: PARKING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  car_not_necessary: {
    exists: false,
    icon: "filter_drama",
    title: "Car Not Necessary",
    category: PARKING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  car_recommended: {
    exists: false,
    icon: "filter_drama",
    title: "Car Recommended",
    category: PARKING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  tennis: {
    exists: false,
    icon: "filter_drama",
    title: "Tennis",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  veranda: {
    exists: false,
    icon: "filter_drama",
    title: "Porch/Veranda",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  patio_balcony: {
    exists: false,
    icon: "whatshot",
    title: "Patio/Balcony",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  golf: {
    exists: false,
    icon: "golf_course",
    title: "Golf",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  lanai: {
    exists: false,
    icon: "filter_drama",
    title: "Lanai/Gazebo",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  outdoor_furniture: {
    exists: false,
    icon: "event_seat",
    title: "Outdoor Furniture",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  water_sports_gear: {
    exists: false,
    icon: "whatshot",
    title: "Water Sports Gear",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  pet_allowed: {
    exists: false,
    icon: "pets",
    title: "Pet(s) Allowed",
    category: PET_FRIENDLY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: true,
      hvmi: true
    }
  },

  outlet_covers: {
    exists: false,
    icon: "whatshot",
    title: "Outlet Covers",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },
  baby_bath: {
    exists: false,
    icon: "child_friendly",
    title: "Baby Bath",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },
  baby_sitter_recommendations: {
    exists: false,
    icon: "child_friendly",
    title: "Baby Sitter Recommendation",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },
  baby_monitor: {
    exists: false,
    icon: "child_friendly",
    title: "Baby Monitor",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },
  pank_n_play_travel_crib: {
    exists: false,
    icon: "child_friendly",
    title: "Pack 'n Play/Travel Crib",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },
  changing_table: {
    exists: false,
    icon: "whatshot",
    title: "Changing Table",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },
  high_chair: {
    exists: false,
    icon: "accessible",
    title: "High Chair",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },
  stair_gates: {
    exists: false,
    icon: "whatshot",
    title: "Stair Gates",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  window_guards: {
    exists: false,
    icon: "grid_on",
    title: "Window Guards",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },
  table_corner_guards: {
    exists: false,
    icon: "whatshot",
    title: "Table Corner Guards",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  childrens_dinnerware: {
    exists: false,
    icon: "local_dining",
    title: "Children's Dinnerware",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  room_darkening_shades: {
    exists: false,
    icon: "home",
    title: "Room Darkening Shades",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  fireplace_guards: {
    exists: false,
    icon: "whatshot",
    title: "Fireplace Guards",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  bathtub: {
    exists: false,
    icon: "hot_tub",
    title: "Bathtub",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  laptop_friendly_workspace: {
    exists: false,
    icon: "laptop_mac",
    title: "Laptop Friendly Workspace",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  luggage_dropoff_allowed: {
    exists: false,
    icon: "laptop_mac",
    title: "Luggage Dropoff Allowed",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  ceiling_hoist: {
    exists: false,
    icon: "whatshot",
    title: "Ceiling hoist",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  chapel_shrine: {
    exists: false,
    icon: "whatshot",
    title: "Chapel/shrine",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  children_not_allowed: {
    exists: false,
    icon: "laptop_mac",
    title: "Children not allowed",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  children_television_networks: {
    exists: false,
    icon: "laptop_mac",
    title: "Children television networks",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  children_welcome: {
    exists: false,
    icon: "laptop_mac",
    title: "Children welcome",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  chocolate_cookies: {
    exists: false,
    icon: "laptop_mac",
    title: "Chocolate/Cookies",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  city_gateway: {
    exists: false,
    icon: "laptop_mac",
    title: "City gateway",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  common_space_step_free_access: {
    exists: false,
    icon: "laptop_mac",
    title: "Common space step free access",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  common_space_wide_doorway: {
    exists: false,
    icon: "whatshot",
    title: "Common space wide doorway",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  carbon_monoxide_detector: {
    exists: false,
    icon: "whatshot",
    title: "Carbon monoxide detector",
    category: SAFETY,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  child_safety_socket_covers: {
    exists: false,
    icon: "whatshot",
    title: "Child safety socket covers",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  smoke_detector: {
    exists: false,
    icon: "whatshot",
    title: "Smoke Detector",
    category: SAFETY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },
  deadbolt_lock: {
    exists: false,
    icon: "whatshot",
    title: "Deadbolt Lock",
    category: SAFETY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },
  first_aid_kit: {
    exists: false,
    icon: "add",
    title: "First Aid Kit",
    category: SAFETY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },
  safety_card: {
    exists: false,
    icon: "add_box",
    title: "Safety Card",
    category: SAFETY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  wide_hallway_clearance: {
    exists: false,
    icon: "zoom_out_map",
    title: "Wide Hallway Clearance",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  elevator: {
    exists: false,
    icon: "zoom_out_map",
    title: "Elevator",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  private_entrance: {
    exists: false,
    icon: "whatshot",
    title: "Private Entrance",
    category: THE_BASICS,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  ethernet_connection: {
    exists: false,
    icon: "import_export",
    title: "Ethernet Connection",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  pocket_wifi: {
    exists: false,
    icon: "wifi",
    title: "Pocket Wifi",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  long_term_stays_allowed: {
    exists: false,
    icon: "whatshot",
    title: "Long Term Stays Allowed",
    category: EXTRAS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  cleaning_before_checkout: {
    exists: false,
    icon: "whatshot",
    title: "Cleaning Before Checkout",
    category: EXTRAS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  safe: {
    exists: false,
    icon: "whatshot",
    title: "Safe",
    category: EXTRAS,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  chauffeur: {
    exists: false,
    icon: "whatshot",
    title: "Chauffeur",
    category: SERVICES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  concierge: {
    exists: false,
    icon: "whatshot",
    title: "Concierge",
    category: SERVICES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  fireplace: {
    exists: false,
    icon: "whatshot",
    title: "Indoor fireplace",
    category: EXTRAS,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  hospital: {
    exists: false,
    icon: "local_hospital",
    title: "Hospital",
    category: SERVICES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  massage: {
    exists: false,
    icon: "local_hospital",
    title: "Massage",
    category: SERVICES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  atm_bank: {
    exists: false,
    icon: "local_hospital",
    title: "ATM/Bank",
    category: SERVICES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  fitness_center: {
    exists: false,
    icon: "fitness_center",
    title: "Fitness Center",
    category: SERVICES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },


  adventure: {
    exists: false,
    icon: "whatshot",
    title: "Adventure",
    category: THEMES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  historic: {
    exists: false,
    icon: "whatshot",
    title: "Historic",
    category: THEMES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  away_from_it_all: {
    exists: false,
    icon: "whatshot",
    title: "Away From It All",
    category: THEMES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  budget: {
    exists: false,
    icon: "local_mall",
    title: "Budget",
    category: THEMES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  romantic: {
    exists: false,
    icon: "favorite",
    title: "Romantic",
    category: THEMES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  tourist_attractions: {
    exists: false,
    icon: "whatshot",
    title: "Tourist Attractions",
    category: THEMES,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  lake_access: {
    exists: false,
    icon: "whatshot",
    title: "Lake Access",
    category: LOCATION,
    available_on: {
      homeaway: false,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  waterfront: {
    exists: false,
    icon: "whatshot",
    title: "Waterfront",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  ski_in_out_facilities: {
    exists: false,
    icon: "whatshot",
    title: "Ski-in/Ski-out",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  lakefront: {
    exists: false,
    icon: "whatshot",
    title: "Lakefront",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  lake_view: {
    exists: false,
    icon: "whatshot",
    title: "Lake View",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  ocean_view: {
    exists: false,
    icon: "whatshot",
    title: "Ocean View",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  beach_view: {
    exists: false,
    icon: "whatshot",
    title: "Beach View",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  water_view: {
    exists: false,
    icon: "whatshot",
    title: "Water View",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  rural: {
    exists: false,
    icon: "whatshot",
    title: "Rural",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  downtown: {
    exists: false,
    icon: "whatshot",
    title: "Downtown",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  mountain_view: {
    exists: false,
    icon: "whatshot",
    title: "Mountain View",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  resort: {
    exists: false,
    icon: "whatshot",
    title: "Resort",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  river: {
    exists: false,
    icon: "pool",
    title: "River",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  village: {
    exists: false,
    icon: "whatshot",
    title: "Village",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: false,
      stayduvet: false,
      hvmi: false
    }
  },

  connecting_rooms: {
    exists: false,
    icon: "whatshot",
    title: "Connecting Rooms",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  plunge_pool: {
    exists: false,
    icon: "whatshot",
    title: "Plunge pool",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  no_pets_allowed	 : {
    exists: false,
    icon: "whatshot",
    title: "No Pets Allowed",
    category: PET_FRIENDLY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  pets_allowed_on_request	 : {
    exists: false,
    icon: "whatshot",
    title: "Pets allowed on request",
    category: PET_FRIENDLY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  adjoining_rooms: {
    exists: false,
    icon: "whatshot",
    title: "Adjoining Rooms",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  bathrobe: {
    exists: false,
    icon: "whatshot",
    title: "Bathrobe",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  telephone: {
    exists: false,
    icon: "whatshot",
    title: "Telephone",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  bathroom_amenities: {
    exists: false,
    icon: "whatshot",
    title: "Bathroom Amenities",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  vcr_movies: {
    exists: false,
    icon: "whatshot",
    title: "Vcr Movies",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  bathtub_and_whirlpool_separate: {
    exists: false,
    icon: "whatshot",
    title: "Bathtub And Whirlpool Separate",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  cd_player: {
    exists: false,
    icon: "whatshot",
    title: "Cd Player",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  foam_pillows: {
    exists: false,
    icon: "whatshot",
    title: "Foam Pillows",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  bathtub_only: {
    exists: false,
    icon: "whatshot",
    title: "Bathtub Only",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  oversized_bathtub: {
    exists: false,
    icon: "whatshot",
    title: "Oversized Bathtub",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  shower: {
    exists: false,
    icon: "whatshot",
    title: "Shower",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  soundproofed_room: {
    exists: false,
    icon: "whatshot",
    title: "Soundproofed Room",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  bathtub_or_shower: {
    exists: false,
    icon: "whatshot",
    title: "Bathtub Or Shower",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  separate_tub_and_shower: {
    exists: false,
    icon: "whatshot",
    title: "Separate Tub And Shower",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  bidet: {
    exists: false,
    icon: "whatshot",
    title: "Bidet",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  hypoallergenic_bed: {
    exists: false,
    icon: "whatshot",
    title: "Hypoallergenic Bed",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  tea_maker: {
    exists: false,
    icon: "whatshot",
    title: "Tea Maker",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  meal_included_dinner: {
    exists: false,
    icon: "whatshot",
    title: "Meal Included Dinner",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  meal_included_lunch: {
    exists: false,
    icon: "whatshot",
    title: "Meal Included Lunch",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  shared_bathroom: {
    exists: false,
    icon: "whatshot",
    title: "Shared Bathroom",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  color_television: {
    exists: false,
    icon: "whatshot",
    title: "Color Television",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  complimentary_high_speed_internet_in_room: {
    exists: false,
    icon: "whatshot",
    title: "Complimentary High Speed Internet In Room",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  satellite_television: {
    exists: false,
    icon: "whatshot",
    title: "Satellite Television",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  video_on_demand: {
    exists: false,
    icon: "whatshot",
    title: "Video On Demand",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  premium_movie_channels: {
    exists: false,
    icon: "whatshot",
    title: "Premium Movie Channels",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  slippers: {
    exists: false,
    icon: "whatshot",
    title: "Slippers",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  pillow_top_mattress: {
    exists: false,
    icon: "whatshot",
    title: "Pillow Top Mattress",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  luxury_linen_type: {
    exists: false,
    icon: "whatshot",
    title: "Luxury Linen Type",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  hot_water: {
    exists: false,
    icon: "whatshot",
    title: "Hot Water",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  video_game_player: {
    exists: false,
    icon: "whatshot",
    title: "Video Game Player",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  dining_room_seats: {
    exists: false,
    icon: "whatshot",
    title: "Dining Room Seats",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  movies: {
    exists: false,
    icon: "whatshot",
    title: "Movies",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  safe_large_enough_to_accommodate_a_laptop: {
    exists: false,
    icon: "whatshot",
    title: "Safe Large Enough To Accommodate A Laptop",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  bluray_player: {
    exists: false,
    icon: "whatshot",
    title: "Bluray Player",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  pillow_type: {
    exists: false,
    icon: "whatshot",
    title: "Pillow Type",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  widescreen_tv: {
    exists: false,
    icon: "whatshot",
    title: "Widescreen Tv",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  separate_tub_or_shower: {
    exists: false,
    icon: "whatshot",
    title: "Separate Tub Or Shower",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  "children\'s_playpen": {
    exists: false,
    icon: "whatshot",
    title: "children\'s_playpen",
    category: CHILDREN,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  desk: {
    exists: false,
    icon: "whatshot",
    title: "Desk",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  free_local_calls: {
    exists: false,
    icon: "whatshot",
    title: "Free Local Calls",
    category: EXTRAS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  video_library: {
    exists: false,
    icon: "whatshot",
    title: "Video Library",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  music_library: {
    exists: false,
    icon: "whatshot",
    title: "Music Library",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  full_kitchen: {
    exists: false,
    icon: "whatshot",
    title: "Full Kitchen",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  bath: {
    exists: false,
    icon: "whatshot",
    title: "Bath",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  spa_bath: {
    exists: false,
    icon: "whatshot",
    title: "Spa Bath",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  dining_area: {
    exists: false,
    icon: "whatshot",
    title: "Dining Area",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  kettle: {
    exists: false,
    icon: "whatshot",
    title: "Kettle",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  dining_table: {
    exists: false,
    icon: "whatshot",
    title: "Dining Table",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  baby_sitter: {
    exists: false,
    icon: "whatshot",
    title: "Baby Sitter",
    category: SERVICES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  whirlpool: {
    exists: false,
    icon: "whatshot",
    title: "Whirlpool",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  kitchen_supplies: {
    exists: false,
    icon: "whatshot",
    title: "Kitchen Supplies",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  separate_entrance: {
    exists: false,
    icon: "whatshot",
    title: "Separate Entrance",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  fire_extinguisher: {
    exists: false,
    icon: "whatshot",
    title: "Fire Extinguisher",
    category: SAFETY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  golf_course_view: {
    exists: false,
    icon: "whatshot",
    title: "Golf Course View",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  ground_floor: {
    exists: false,
    icon: "whatshot",
    title: "Ground Floor",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  city_view: {
    exists: false,
    icon: "whatshot",
    title: "City View",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  harbour_view: {
    exists: false,
    icon: "whatshot",
    title: "Harbour View",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  patio: {
    exists: false,
    icon: "whatshot",
    title: "Patio",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  ipad: {
    exists: false,
    icon: "whatshot",
    title: "Ipad",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  "outdoor-dining_area": {
    exists: false,
    icon: "whatshot",
    title: "Outdoor-Dining Area",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  game_console_xbox_360: {
    exists: false,
    icon: "whatshot",
    title: "Game Console Xbox 360",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  game_console_PS2: {
    exists: false,
    icon: "whatshot",
    title: "Game Console PS2",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  game_console_PS3: {
    exists: false,
    icon: "whatshot",
    title: "Game Console PS3",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  game_console_nintendo_wii: {
    exists: false,
    icon: "whatshot",
    title: "Game Console Nintendo Wii",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  cleaning_included: {
    exists: false,
    icon: "whatshot",
    title: "Cleaning Included",
    category: SERVICES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  childrens_books_and_toys: {
    exists: false,
    icon: "whatshot",
    title: "children\'s_books_and_toys",
    category: CHILDREN,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  house_cleaning_optional: {
    exists: false,
    icon: "whatshot",
    title: "House Cleaning Optional",
    category: SERVICES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  dining_room: {
    exists: false,
    icon: "whatshot",
    title: "Dining Room",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  wheelchair_accessible: {
    exists: false,
    icon: "whatshot",
    title: "Wheelchair Accessible",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  breakfast: {
    exists: false,
    icon: "whatshot",
    title: "Breakfast",
    category: EXTRAS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  fixed_grab_bars_for_shower: {
    exists: false,
    icon: "whatshot",
    title: "Fixed Grab Bar For Showers",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  fixed_grab_bars_for_toilet: {
    exists: false,
    icon: "whatshot",
    title: "Fixed Grab Bar For Toilet",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  rollin_shower: {
    exists: false,
    icon: "whatshot",
    title: "Rollin Shower",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: false
    }
  },

  toddler_bed: {
    exists: false,
    icon: "whatshot",
    title: "Toddler Bed",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  high_speed_wifi: {
    exists: false,
    icon: "whatshot",
    title: "High Speed Wifi",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  balcony_garden_view: {
    exists: false,
    icon: "whatshot",
    title: "Balcony Garden View",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  balcony_city_view: {
    exists: false,
    icon: "whatshot",
    title: "Balcony City View",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  balcony_ocean_view: {
    exists: false,
    icon: "whatshot",
    title: "Balcony Ocean View",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  balcony_mountain_view: {
    exists: false,
    icon: "whatshot",
    title: "Balcony Mountain View",
    category: LOCATION,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  fireplace_wood_burning: {
    exists: false,
    icon: "whatshot",
    title: "Fireplace Wood Burning",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  fireplace_gas: {
    exists: false,
    icon: "whatshot",
    title: "Fireplace Gas",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  fireplace_electric: {
    exists: false,
    icon: "whatshot",
    title: "Fireplace Electric",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  pizza_oven: {
    exists: false,
    icon: "whatshot",
    title: "Pizza Oven",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  air_conditioning_central: {
    exists: false,
    icon: "whatshot",
    title: "Air Conditioning Central",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  air_conditioning_partial: {
    exists: false,
    icon: "whatshot",
    title: "Air Conditioning Partial",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  air_conditioning_in_rooms: {
    exists: false,
    icon: "whatshot",
    title: "Air Conditioning In Rooms",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  air_conditioning: {
    exists: false,
    icon: "whatshot",
    title: "Air Conditioning",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  crib: {
    exists: false,
    icon: "whatshot",
    title: "Crib",
    category: CHILDREN,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  barbeque_grill_gas: {
    exists: false,
    icon: "whatshot",
    title: "Barbeque Grill Gas",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  barbeque_grill_propane: {
    exists: false,
    icon: "whatshot",
    title: "Barbeque Grill Propane",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  barbeque_grill_charcoal: {
    exists: false,
    icon: "whatshot",
    title: "Barbeque Grill Charcoal",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  premium_linens_and_towels: {
    exists: false,
    icon: "whatshot",
    title: "Premium Linens And Towels",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  kitchen_essentials: {
    exists: false,
    icon: "whatshot",
    title: "Kitchen Essentials",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  in_unit_washer: {
    exists: false,
    icon: "whatshot",
    title: "In Unit Washer",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  wired_internet: {
    exists: false,
    icon: "whatshot",
    title: "Wired internet",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  wireless_internet: {
    exists: false,
    icon: "whatshot",
    title: "Wireless Internet",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  self_controlled_heating_system: {
    exists: false,
    icon: "whatshot",
    title: "Self Controlled Heating System",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  self_controlled_cooling_system: {
    exists: false,
    icon: "whatshot",
    title: "Self Controlled Cooling System",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  security_system: {
    exists: false,
    icon: "whatshot",
    title: "Security System",
    category: SAFETY,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  private_dock: {
    exists: false,
    icon: "whatshot",
    title: "Private Dock",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  indoor_place: {
    exists: false,
    icon: "whatshot",
    title: "Indoor Place",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  large_desk: {
    exists: false,
    icon: "whatshot",
    title: "Large Desk",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  minibar: {
    exists: false,
    icon: "whatshot",
    title: "Minibar",
    category: FACILITIES,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  bbq_grill: {
    exists: false,
    icon: "whatshot",
    title: "BBQ Grill",
    category: OUTDOOR,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  plates_and_bowls: {
    exists: false,
    icon: "whatshot",
    title: "Plates And Bowls",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  private_bathroom: {
    exists: false,
    icon: "whatshot",
    title: "Private Bathroom",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  refrigerator_with_ice_maker: {
    exists: false,
    icon: "whatshot",
    title: "Refrigerator With Ice Maker",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  shower_only: {
    exists: false,
    icon: "whatshot",
    title: "Shower Only",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  silverware_utensils: {
    exists: false,
    icon: "whatshot",
    title: "Silverware/Utensils",
    category: KITCHEN_AND_DINING,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  sitting_area: {
    exists: false,
    icon: "whatshot",
    title: "Sitting Area",
    category: THE_BASICS,
    available_on: {
      homeaway: true,
      airbnb: true,
      stayduvet: false,
      hvmi: true
    }
  },

  heater: {
    exists: false,
    icon: "whatshot",
    title: "Heater",
    category: THE_BASICS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  well_lit_path_to_entrance: {
    exists: false,
    icon: "whatshot",
    title: "Well Lit Path To Entrance",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  private_chef: {
    exists: false,
    icon: "whatshot",
    title: "Private Chef",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  shared_pool: {
    exists: false,
    icon: "whatshot",
    title: "Shared Pool",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  free_parking_on_premises: {
    exists: false,
    icon: "whatshot",
    title: "Free Parking On Premises",
    category: PARKING,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  free_parking_off_premises: {
    exists: false,
    icon: "whatshot",
    title: "Free Parking Off Premises",
    category: PARKING,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  paid_parking_on_premises: {
    exists: false,
    icon: "whatshot",
    title: "Paid Parking On Premises",
    category: PARKING,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  paid_parking_off_premises: {
    exists: false,
    icon: "whatshot",
    title: "Paid Parking Off Premises",
    category: PARKING,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  step_free_access: {
    exists: false,
    icon: "whatshot",
    title: "Step Free Access",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  wide_doorway: {
    exists: false,
    icon: "whatshot",
    title: "Wide Doorway",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  flat_path_to_front_door: {
    exists: false,
    icon: "whatshot",
    title: "Flat Path To Front Door",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  beachfront: {
    exists: false,
    icon: "whatshot",
    title: "Beachfront",
    category: LOCATION,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  accessible_parking: {
    exists: false,
    icon: "whatshot",
    title: "Accessible Parking",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  executive_floor: {
    exists: false,
    icon: "whatshot",
    title: "Executive Floor",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  wheelchair_access: {
    exists: false,
    icon: "whatshot",
    title: "Wheelchair Access",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  water_sports: {
    exists: false,
    icon: "whatshot",
    title: "Water Sports",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  ski_rental: {
    exists: false,
    icon: "whatshot",
    title: "Ski Rental",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  racquetball: {
    exists: false,
    icon: "whatshot",
    title: "Racquetball",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  snow_sports: {
    exists: false,
    icon: "whatshot",
    title: "Snow Sports",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  water_skiing: {
    exists: false,
    icon: "whatshot",
    title: "Water Skiing",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  horseback_riding: {
    exists: false,
    icon: "whatshot",
    title: "Horseback Riding",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  snow_skiing: {
    exists: false,
    icon: "whatshot",
    title: "Snow Skiing",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  petanque: {
    exists: false,
    icon: "whatshot",
    title: "Petanque",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  business_center: {
    exists: false,
    icon: "whatshot",
    title: "Business Center",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  business_library: {
    exists: false,
    icon: "whatshot",
    title: "Business Library",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  meeting_rooms: {
    exists: false,
    icon: "whatshot",
    title: "Meeting Rooms",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  dry_cleaning: {
    exists: false,
    icon: "whatshot",
    title: "Dry Cleaning",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  onsite_laundry: {
    exists: false,
    icon: "whatshot",
    title: "Onsite Laundry",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  coin_operated_laundry: {
    exists: false,
    icon: "whatshot",
    title: "Coin Operated Laundry",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  housekeeping_daily: {
    exists: false,
    icon: "whatshot",
    title: "Housekeeping Daily",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  shoe_shine_stand: {
    exists: false,
    icon: "whatshot",
    title: "Shoe Shine Stand",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  full_service_housekeeping: {
    exists: false,
    icon: "whatshot",
    title: "Full Service Housekeeping",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  housekeeping_weekly: {
    exists: false,
    icon: "whatshot",
    title: "Housekeeping Weekly",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  non_smoking_rooms_generic: {
    exists: false,
    icon: "whatshot",
    title: "Non Smoking Rooms Generic",
    category: THE_BASICS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  nightclub: {
    exists: false,
    icon: "whatshot",
    title: "Nightclub",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  casino: {
    exists: false,
    icon: "whatshot",
    title: "Casino",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  childrens_play_area: {
    exists: false,
    icon: "whatshot",
    title: "Children's Play Area",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  disco: {
    exists: false,
    icon: "whatshot",
    title: "Disco",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  live_entertainment: {
    exists: false,
    icon: "whatshot",
    title: "Live Entertainment",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  restaurant: {
    exists: false,
    icon: "whatshot",
    title: "Restaurant",
    category: EXTRAS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  snack_bar: {
    exists: false,
    icon: "whatshot",
    title: "Snack Bar",
    category: EXTRAS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  bbq_picnic_area: {
    exists: false,
    icon: "whatshot",
    title: "Bbq Picnic Area",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  all_inclusive_meal_plan: {
    exists: false,
    icon: "whatshot",
    title: "All Inclusive Meal Plan",
    category: EXTRAS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  breakfast_served_in_restaurant: {
    exists: false,
    icon: "whatshot",
    title: "Breakfast Served In Restaurant",
    category: EXTRAS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  catering_services: {
    exists: false,
    icon: "whatshot",
    title: "Catering Services",
    category: EXTRAS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  continental_breakfast: {
    exists: false,
    icon: "whatshot",
    title: "Continental Breakfast",
    category: EXTRAS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  near_a_train_subway_station: {
    exists: false,
    icon: "whatshot",
    title: "Near A Train Subway Station",
    category: LOCATION,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  nearby_local_nightlife: {
    exists: false,
    icon: "whatshot",
    title: "Nearby Local Nightlife",
    category: LOCATION,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  shared_residential_building: {
    exists: false,
    icon: "whatshot",
    title: "Shared Residential Building",
    category: LOCATION,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  "24_7_support": {
    exists: false,
    icon: "whatshot",
    title: "24 X 7 Support",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  in_person_check_in: {
    exists: false,
    icon: "whatshot",
    title: "In Person Check In",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  carbon_monoxide_detector_smoke_detector: {
    exists: false,
    icon: "whatshot",
    title: "Carbon Monoxide Detector Smoke Detector",
    category: EXTRAS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  professionally_cleaned: {
    exists: false,
    icon: "whatshot",
    title: "Professionally Cleaned",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  basketball_court: {
    exists: false,
    icon: "whatshot",
    title: "Basketball Court",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  disabled_parking_spot: {
    exists: false,
    icon: "whatshot",
    title: "Disabled Parking Spot",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  garden: {
    exists: false,
    icon: "whatshot",
    title: "Garden",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  indoor_parking: {
    exists: false,
    icon: "whatshot",
    title: "Indoor Parking",
    category: PARKING,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  library: {
    exists: false,
    icon: "whatshot",
    title: "Library",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  oceanfront: {
    exists: false,
    icon: "whatshot",
    title: "Oceanfront",
    category: LOCATION,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  private_parking: {
    exists: false,
    icon: "whatshot",
    title: "Private Parking",
    category: PARKING,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  street_side_parking: {
    exists: false,
    icon: "whatshot",
    title: "Street Side Parking",
    category: PARKING,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  jet_ski: {
    exists: false,
    icon: "whatshot",
    title: "Jet Ski",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  table_tennis: {
    exists: false,
    icon: "whatshot",
    title: "Table Tennis",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  valet_parking: {
    exists: false,
    icon: "whatshot",
    title: "Valet Parking",
    category: PARKING,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  accessible_parking_indoor: {
    exists: false,
    icon: "whatshot",
    title: "Accessible Parking Indoor",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  accessible_parking_indoor_1_spot: {
    exists: false,
    icon: "whatshot",
    title: "Accessible Parking Indoor 1 Spot",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  accessible_parking_indoor_2_spots: {
    exists: false,
    icon: "whatshot",
    title: "Accessible Parking Indoor 2 Spots",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  accessible_parking_outdoor: {
    exists: false,
    icon: "whatshot",
    title: "Accessible Parking Outdoor",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  accessible_parking_outdoor_1_spot: {
    exists: false,
    icon: "whatshot",
    title: "Accessible Parking Outdoor 1 Spot",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  accessible_parking_outdoor_2_spots: {
    exists: false,
    icon: "whatshot",
    title: "Accessible Parking Outdoor 2 Spots",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  beach_access: {
    exists: false,
    icon: "whatshot",
    title: "Beach Access",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  business_facilities: {
    exists: false,
    icon: "whatshot",
    title: "Business Facilities",
    category: BUSINESS_TRAVEL,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  courtyard: {
    exists: false,
    icon: "whatshot",
    title: "Courtyard",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  courtyard_shared: {
    exists: false,
    icon: "whatshot",
    title: "Courtyard Shared",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  courtyard_private_use: {
    exists: false,
    icon: "whatshot",
    title: "Courtyard Private Use",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  fire_pit: {
    exists: false,
    icon: "whatshot",
    title: "Fire Pit",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  fitness_center_access_to_community_facilities: {
    exists: false,
    icon: "whatshot",
    title: "Fitness Center Access To Community Facilities",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  fitness_center_private: {
    exists: false,
    icon: "whatshot",
    title: "Fitness Center Private",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  heli_pad: {
    exists: false,
    icon: "whatshot",
    title: "Heli Pad",
    category: ACCESSIBILITY,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  home_theatre_system: {
    exists: false,
    icon: "whatshot",
    title: "Home Theatre System",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  jacuzzi: {
    exists: false,
    icon: "whatshot",
    title: "Jacuzzi",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  outdoor_dining_area_private: {
    exists: false,
    icon: "whatshot",
    title: "Outdoor Dining Area Private",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  outdoor_dining_area_shared: {
    exists: false,
    icon: "whatshot",
    title: "Outdoor Dining Area Shared",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  outdoor_dining_area_screened_in: {
    exists: false,
    icon: "whatshot",
    title: "Outdoor Dining Area Screened In",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  outdoor_dining_area_seats_10: {
    exists: false,
    icon: "whatshot",
    title: "Outdoor Dining Area Seats 10",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  outdoor_dining_area_seats_12: {
    exists: false,
    icon: "whatshot",
    title: "Outdoor Dining Area Seats 12",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  "outdoor_dining_area_seats_20+": {
    exists: false,
    icon: "whatshot",
    title: "Outdoor Dining Area Seats 20+",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  outdoor_furniture_area: {
    exists: false,
    icon: "whatshot",
    title: "Outdoor Furniture Area",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  outdoor_furniture_area_sun_loungers: {
    exists: false,
    icon: "whatshot",
    title: "Outdoor Furniture Area Sun Loungers",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  outdoor_furniture_area_dining_area: {
    exists: false,
    icon: "whatshot",
    title: "Outdoor Furniture Area Dining Area",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  primary_home: {
    exists: false,
    icon: "whatshot",
    title: "Primary Home",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  pool_infinity_edge: {
    exists: false,
    icon: "whatshot",
    title: "Pool Infinity Edge",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  pool_saltwater: {
    exists: false,
    icon: "whatshot",
    title: "Pool Saltwater",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  pool_community_access: {
    exists: false,
    icon: "whatshot",
    title: "Pool Community Access",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  riverfront: {
    exists: false,
    icon: "whatshot",
    title: "Riverfront",
    category: LOCATION,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  sauna_private: {
    exists: false,
    icon: "whatshot",
    title: "Sauna Private",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  sauna_shared: {
    exists: false,
    icon: "whatshot",
    title: "Sauna Shared",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  sauna_en_suite: {
    exists: false,
    icon: "whatshot",
    title: "Sauna En Suite",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  seafront: {
    exists: false,
    icon: "whatshot",
    title: "Seafront",
    category: LOCATION,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  ski_rental_available_for_additional_fee: {
    exists: false,
    icon: "whatshot",
    title: "Ski Rental Available For Additional Fee",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  ski_rental_rental_cost_included_with_stay: {
    exists: false,
    icon: "whatshot",
    title: "Ski Rental Rental Cost Included With Stay",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  steam_room: {
    exists: false,
    icon: "whatshot",
    title: "Steam Room",
    category: FACILITIES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  bathroom_amenities_robe_included: {
    exists: false,
    icon: "whatshot",
    title: "Bathroom Amenities Robe Included",
    category: THE_BASICS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  childrens_play_area_indoor: {
    exists: false,
    icon: "whatshot",
    title: "Children's Play Area Indoor",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  childrens_play_area_outdoor: {
    exists: false,
    icon: "whatshot",
    title: "Children's Play Area Outdoor",
    category: CHILDREN,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  table_tennis_indoor: {
    exists: false,
    icon: "whatshot",
    title: "Table Tennis Indoor",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  table_tennis_outdoor: {
    exists: false,
    icon: "whatshot",
    title: "Table Tennis Outdoor",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  television_plasma: {
    exists: false,
    icon: "whatshot",
    title: "Television Plasma",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  television_flat_screen: {
    exists: false,
    icon: "whatshot",
    title: "Television Flat Screen",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  television_smart: {
    exists: false,
    icon: "whatshot",
    title: "Television Smart",
    category: ENTERTAINMENT,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  water_sports_available_for_rent: {
    exists: false,
    icon: "whatshot",
    title: "Water Sports Available For Rent",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  water_sports_available_for_additional_fee: {
    exists: false,
    icon: "whatshot",
    title: "Water Sports Available For Additional Fee",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  baby_sitting_available_for_additional_fee: {
    exists: false,
    icon: "whatshot",
    title: "Baby Sitting Available For Additional Fee",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  bartender: {
    exists: false,
    icon: "whatshot",
    title: "Bartender",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  bartender_on_request: {
    exists: false,
    icon: "whatshot",
    title: "Bartender On Request",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  bartender_available_for_additional_fee: {
    exists: false,
    icon: "whatshot",
    title: "Bartender Available For Additional Fee",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  butler: {
    exists: false,
    icon: "whatshot",
    title: "Butler",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  butler_on_request: {
    exists: false,
    icon: "whatshot",
    title: "Butler On Request",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  butler_available_for_additional_fee: {
    exists: false,
    icon: "whatshot",
    title: "Butler Available For Additional Fee",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  chef_services_included: {
    exists: false,
    icon: "whatshot",
    title: "Chef Services Included",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  chef_services_available_for_additional_fee: {
    exists: false,
    icon: "whatshot",
    title: "Chef Services Available For Additional Fee",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  chef_services_working_schedule: {
    exists: false,
    icon: "whatshot",
    title: "Chef Services Working Schedule",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  concierge_services_pm_only: {
    exists: false,
    icon: "whatshot",
    title: "Concierge Services PM Only",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  concierge_services_weekends_only: {
    exists: false,
    icon: "whatshot",
    title: "Concierge Services Weekends Only",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  dry_cleaning_on_request: {
    exists: false,
    icon: "whatshot",
    title: "Dry Cleaning On Request",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  dry_cleaning_available_for_additional_fee: {
    exists: false,
    icon: "whatshot",
    title: "Dry Cleaning Available For Additional Fee",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  gardener: {
    exists: false,
    icon: "whatshot",
    title: "Gardener",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  grocery_shopping_service_available: {
    exists: false,
    icon: "whatshot",
    title: "Grocery Shopping Service Available",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  grocery_shopping_service_available_on_request: {
    exists: false,
    icon: "whatshot",
    title: "Grocery Shopping Service Available On Request",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  grocery_shopping_service_available_available_for_additional_fee: {
    exists: false,
    icon: "whatshot",
    title: "Grocery Shopping Service Available For Additional Fee",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  housekeeping_2x_daily: {
    exists: false,
    icon: "whatshot",
    title: "Housekeeping 2x Daily",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  housekeeping_additional_services: {
    exists: false,
    icon: "whatshot",
    title: "Housekeeping Additional Services",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  housekeeping_additional_services_available_on_request: {
    exists: false,
    icon: "whatshot",
    title: "Housekeeping Additional Services Available On Request",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  housekeeping_additional_services_available_for_extra_fee: {
    exists: false,
    icon: "whatshot",
    title: "Housekeeping Additional Services Available For Extra Fee",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  house_manager: {
    exists: false,
    icon: "whatshot",
    title: "House Manager",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  in_person_welcome: {
    exists: false,
    icon: "whatshot",
    title: "In Person Welcome",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  laundry_services: {
    exists: false,
    icon: "whatshot",
    title: "Laundry Services",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  laundry_services_availabe_for_additional_fee: {
    exists: false,
    icon: "whatshot",
    title: "Laundry Services Available For Additional Fee",
    category: SERVICES,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  meal_included_breakfast: {
    exists: false,
    icon: "whatshot",
    title: "Meal Included Breakfast",
    category: EXTRAS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  meal_included_3_meals_day: {
    exists: false,
    icon: "whatshot",
    title: "Meal Included 3 Meals Day",
    category: EXTRAS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  meal_included_available_for_additional_fee: {
    exists: false,
    icon: "whatshot",
    title: "Meal Included Available For Additional Fee",
    category: EXTRAS,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  valet_parking_on_request: {
    exists: false,
    icon: "whatshot",
    title: "Valet Parking On Request",
    category: PARKING,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  valet_parking_available_for_additional_fee: {
    exists: false,
    icon: "whatshot",
    title: "Valet Parking Available For Additional Fee",
    category: PARKING,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  },

  ski_storage: {
    exists: false,
    icon: "whatshot",
    title: "Ski Storage",
    category: OUTDOOR,
    available_on: {
      homeaway: false,
      airbnb: false,
      stayduvet: false,
      hvmi: true
    }
  }
};
