import { Action } from "../../../actions/action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import * as _ from "lodash";
import { OtherNotesDraft } from "../../../models/new/drafts/other-notes-draft.model";
import { OtherNotesDraftActionTypes } from "../../../actions/new/setttings/drafts/other-notes-drafts";


export interface OtherNotesDraftState extends EntityState<OtherNotesDraft> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const otherNotesDraftAdapter: EntityAdapter<OtherNotesDraft> = createEntityAdapter
  < OtherNotesDraft > ({
    selectId: (otherNotesDraft: OtherNotesDraft) => otherNotesDraft.id
  });

export const initialState: OtherNotesDraftState = otherNotesDraftAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function otherNotesDraftReducer(state: OtherNotesDraftState = initialState, action: Action): OtherNotesDraftState {
  switch (action.type) {
    case OtherNotesDraftActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        isLoaded: false
      }
    }

    case OtherNotesDraftActionTypes.INDEX_SUCCESS: {
      const otherNotesDrafts = action.payload as OtherNotesDraft[];
      return otherNotesDraftAdapter.addAll(otherNotesDrafts, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case OtherNotesDraftActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }

    case OtherNotesDraftActionTypes.CREATE_SUCCESS: {
      const otherNotesDraft = action.payload as OtherNotesDraft;
      return otherNotesDraftAdapter.addOne(otherNotesDraft,{
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case OtherNotesDraftActionTypes.SHOW_REQUEST: {
      const otherNotesDraftId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, otherNotesDraftId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          otherNotesDraftId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, otherNotesDraftId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, otherNotesDraftId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case OtherNotesDraftActionTypes.SHOW_SUCCESS: {
      const otherNotesDraft = action.payload as OtherNotesDraft;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, otherNotesDraft.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, otherNotesDraft.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, otherNotesDraft.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          otherNotesDraft.id
        ];
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case OtherNotesDraftActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case OtherNotesDraftActionTypes.UPDATE_SUCCESS: {
      const otherNotesDraft = action.payload as OtherNotesDraft;
      return otherNotesDraftAdapter.updateOne({
        id: otherNotesDraft.id,
        changes: otherNotesDraft
      }, state);
    }

    case OtherNotesDraftActionTypes.DELETE_REQUEST: {
      return {
        ...state
      };
    }

    case OtherNotesDraftActionTypes.DELETE_SUCCESS: {
      const otherNotesDraftId = action.payload as number;

      return otherNotesDraftAdapter.removeOne(otherNotesDraftId, state);
    }

    default: {
      return state;
    }

  }
}

export const _getIsOtherNotesDraftLoading = (state: OtherNotesDraftState) => state.isLoading;
export const _getIsOtherNotesDraftLoaded = (state: OtherNotesDraftState) => state.isLoaded;

export const _getIsFullOtherNotesDraftLoading = (state: OtherNotesDraftState, draftId: number) => {
  return state.fullLoadingIds.indexOf(draftId) !== -1;
};
export const _getIsFullOtherNotesDraftLoaded = (state: OtherNotesDraftState, draftId: number) => {
  return state.fullLoadedIds.indexOf(draftId) !== -1;
};
