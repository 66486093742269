import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { ThreadRepository } from "../../../repository/thread.repository";

@Component({
  selector: "sd-decline-popup",
  template: `
    <sd-modal-popup-layout title="Decline Message">

      <div fxLayout="column" fxLayoutGap="10px">

      <textarea [formControl]="messageControl" rows="4">

      </textarea>

        <div fxLayoutAlign="center" fxFlexAlign="center">

          <mat-spinner *ngIf="isLoading" [diameter]="30" [strokeWidth]="4"></mat-spinner>

          <button
            mat-raised-button
            color="accent"
            [disabled]="!messageControl.valid"
            (click)="decline()">
            DECLINE
          </button>

        </div>


      </div>

    </sd-modal-popup-layout>`,
  styles: [``]
})

export class DeclinePopupComponent implements OnInit {

  message = "Sorry, we can't host you.";
  messageControl: FormControl;
  isLoading: boolean;

  @Input() bookingId: number;
  @Input() threadId: number;

  constructor(private threadRepo: ThreadRepository,
              private matDialogRef: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data: number) {
    this.bookingId = data;
  }

  ngOnInit() {
    this.messageControl = new FormControl(this.message, [Validators.required]);
  }

  decline() {
    this.isLoading = true;
    this.threadRepo.declineBooking(this.threadId, this.bookingId, this.messageControl.value).subscribe((success) => {
      this.isLoading = false;
      this.close();
    }, error1 => {
      this.isLoading = false;
    });
  }

  close() {
    this.matDialogRef.closeAll();
  }

}
