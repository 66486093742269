import { OwnerBlock } from "../../../models/owner-block";
import { Action } from "../../action";

export enum ListingCalendarActionTypes {
  CALENDAR_REQUEST = "[LISTING CALENDAR] Calendar Request",
  CALENDAR_SUCCESS = "[LISTING CALENDAR] Calendar Success",

  CALENDAR_DELETE = "[LISTING CALENDAR] Calendar Delete",

  BLOCK_ADD_SUCCESS = "[LISTING CALENDAR] Block Add",
  BLOCK_DELETE_SUCCESS = "[LISTING CALENDAR] Block Delete",
}

export class ListingCalendarRequest implements Action {
  readonly type = ListingCalendarActionTypes.CALENDAR_REQUEST;

  constructor(public payload: { month: string, listingId: number }) {
  }

}

export class ListingCalendarSuccess implements Action {
  readonly type = ListingCalendarActionTypes.CALENDAR_SUCCESS;

  constructor(public payload: { month: string, listingId: number, data: any }) {
  }
}

export class BlockAddSuccess implements Action {
  readonly type = ListingCalendarActionTypes.BLOCK_ADD_SUCCESS;

  constructor(public payload: { month: string, listingId: number, block: OwnerBlock[] }) {
  }
}

export class BlockDeleteSuccess implements Action {
  readonly type = ListingCalendarActionTypes.BLOCK_DELETE_SUCCESS;

  constructor(public payload: { month: string, listingId: number, date: string }) {
  }
}

export class ListingCalendarDelete implements Action {
  readonly type = ListingCalendarActionTypes.CALENDAR_DELETE;

  constructor(public payload: { propertyId: number, month: string }) {
  }
}
