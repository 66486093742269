import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";

@Injectable()
export class StatsService {

  constructor(private apiService: ApiService) {
  }

  loadOwnerStats(homeOwnerId?: number, year?: number): Observable<any> {

    const data = {};
    if (homeOwnerId) {
      data["homeowner_id"] = homeOwnerId;
    }

    if (year) {
      data["year"] = year;
    }

    return this.apiService.get("/total-stats", true, data);
  }

  loadAdminStats(sources: string[]): Observable<any> {
    return this.apiService.get("/admin/sales-stats", true, {
      source: sources
    });
  }

  loadCommissionStats(sources: string[], year: number): Observable<any> {
    return this.apiService.get("/admin/commission-stats", true, {
      source: sources,
      year
    });
  }

  loadCleaningStats(sources: string[], year: number): Observable<any> {
    return this.apiService.get("/admin/cleaning-stats", true, {
      source: sources,
      year
    });
  }

  loadNetIncomeStats(sources: string[], year: number): Observable<any> {
    return this.apiService.get("/admin/net-income-stats", true, {
      source: sources,
      year
    });
  }

  loadMonthlyBreakdown(sources: string[], homeOwnerId?: number, year?: number): Observable<any> {
    const data = {
      source: sources,
    };

    if (year) {
      data["year"] = year;
    }

    if (homeOwnerId) {
      data["homeowner_id"] = homeOwnerId;
    }

    return this.apiService.get("/bookings/monthly-breakdown", true, data);
  }
}
