import { MultiListing } from "../../../models/new/multi-listing.model";
import { Action } from "../../action";

export enum MultiListingActionTypes {
  INDEX_REQUEST = "[Multi Listing NEW] Index Request",
  INDEX_SUCCESS = "[Multi Listing NEW] Index Success",

  CREATE_REQUEST = "[Multi Listing NEW] Create Request",
  CREATE_SUCCESS = "[Multi Listing NEW] Create Success",

  UPDATE_REQUEST = "[Multi Listing NEW] Update Request",
  UPDATE_SUCCESS = "[Multi Listing NEW] Update Success",

  DELETE_REQUEST = "[Multi Listing NEW] Delete Request",
  DELETE_SUCCESS = "[Multi Listing NEW] Delete Success",
}

export class MultiListingIndexRequest implements Action {
  readonly type = MultiListingActionTypes.INDEX_REQUEST;
}

export class MultiListingIndexSuccess implements Action {
  readonly type = MultiListingActionTypes.INDEX_SUCCESS;

  constructor(public payload: MultiListing[]) {
  }
}

export class MultiListingCreateRequest implements Action {
  readonly type = MultiListingActionTypes.CREATE_REQUEST;
}

export class MultiListingCreateSuccess implements Action {
  readonly type = MultiListingActionTypes.CREATE_SUCCESS;

  constructor(public payload: MultiListing) {
  }
}

export class MultiListingUpdateRequest implements Action {
  readonly type = MultiListingActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class MultiListingUpdateSuccess implements Action {
  readonly type = MultiListingActionTypes.UPDATE_SUCCESS;

  constructor(public payload: MultiListing) {
  }
}

export class MultiListingDeleteRequest implements Action {
  readonly type = MultiListingActionTypes.DELETE_REQUEST;

  constructor(public payload: number) {
  }
}

export class MultiListingDeleteSuccess implements Action {
  readonly type = MultiListingActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
