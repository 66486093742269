import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { DeclinePopupComponent } from "./components/decline-popup";
import { DeclineRequestPopupComponent } from "./components/decline-request-popup";
import { EditorPopupComponent } from "./components/editor-popup";
import { EmailBoxComponent } from "./components/email-box";
import { MessageBoxComponent } from "./components/message-box";
import { MessagesDisplayComponent } from "./components/message-component";
import { ResponseCardComponent } from "./components/response-card";

const COMPONENTS = [
  MessagesDisplayComponent,
  ResponseCardComponent,
  MessageBoxComponent,
  EmailBoxComponent,
];

const ENTRY_COMPONENTS = [
  EditorPopupComponent,
  DeclineRequestPopupComponent,
  DeclinePopupComponent,
];

@NgModule({
  imports: [SharedModule],
  exports: [
    ...COMPONENTS,
    ...ENTRY_COMPONENTS,
  ],
  declarations: [
    ...COMPONENTS,
    ...ENTRY_COMPONENTS,
  ],
  entryComponents: [...ENTRY_COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InboxSharedModule {
}
