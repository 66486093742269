import { AdminDashStats } from "../../models/admin-dash-stats";
import { CleaningStats } from "../../models/new/dashboard/cleaning-stats";
import { CommissionStats } from "../../models/new/dashboard/commission-stats";
import { NetIncomeStats } from "../../models/new/dashboard/net-income-stats";
import { ListingCompact } from "../../models/new/listing/listing-compact.model";
import { OwnerDashStats } from "../../models/owner-dash-stats";
import { Action } from "../action";

export enum StatsActionTypes {
  OWNER_STATS_REQUEST = "[STATS NEW] Owner Stats request",
  OWNER_STATS_SUCCESS = "[STATS NEW] Owner Stats success",

  ADMIN_STATS_REQUEST = "[STATS NEW] Admin Stats request",
  ADMIN_STATS_SUCCESS = "[STATS NEW] Admin Stats success",

  CLEANING_STATS_REQUEST = "[STATS NEW] Cleaning Stats request",
  CLEANING_STATS_SUCCESS = "[STATS NEW] Cleaning Stats success",

  COMMISSION_STATS_REQUEST = "[STATS NEW] Commission Stats request",
  COMMISSION_STATS_SUCCESS = "[STATS NEW] Commission Stats success",

  NET_INCOME_STATS_REQUEST = "[STATS NEW] Net Income Stats request",
  NET_INCOME_STATS_SUCCESS = "[STATS NEW] Net Income Stats success",
  CHANGE_NET_INCOME_YEAR = "[STATS NEW] Change Net Income year",

  MONTHLY_BREAKDOWN_REQUEST = "[STATS NEW] Monthly Breakdown request",
  MONTHLY_BREAKDOWN_SUCCESS = "[STATS NEW] Monthly Breakdown success",
  MONTHLY_BREAKDOWN_REMOVE = "[STATS NEW] Monthly Breakdown remove",

  SELECTING_LISTING_SUCCESS = "[STATS NEW] Selected Listing success"

}

export class OwnerStatsRequest implements Action {
  readonly type = StatsActionTypes.OWNER_STATS_REQUEST;
}

export class OwnerStatsSuccess implements Action {
  readonly type = StatsActionTypes.OWNER_STATS_SUCCESS;

  constructor(public payload: { [id: number]: OwnerDashStats }) {
  }
}

export class AdminStatsRequest implements Action {
  readonly type = StatsActionTypes.ADMIN_STATS_REQUEST;
}

export class AdminStatsSuccess implements Action {
  readonly type = StatsActionTypes.ADMIN_STATS_SUCCESS;

  constructor(public payload: { [id: number]: AdminDashStats }) {
  }
}

export class CommissionStatsRequest implements Action {
  readonly type = StatsActionTypes.COMMISSION_STATS_REQUEST;
}

export class CommissionStatsSuccess implements Action {
  readonly type = StatsActionTypes.COMMISSION_STATS_SUCCESS;

  constructor(public payload: { [id: number]: CommissionStats }) {
  }
}

export class CleaningStatsRequest implements Action {
  readonly type = StatsActionTypes.CLEANING_STATS_REQUEST;
}

export class CleaningStatsSuccess implements Action {
  readonly type = StatsActionTypes.CLEANING_STATS_SUCCESS;

  constructor(public payload: { [id: number]: CleaningStats }) {
  }
}

export class NetIncomeStatsRequest implements Action {
  readonly type = StatsActionTypes.NET_INCOME_STATS_REQUEST;
}

export class NetIncomeStatsSuccess implements Action {
  readonly type = StatsActionTypes.NET_INCOME_STATS_SUCCESS;

  constructor(public payload: { [id: number]: NetIncomeStats }) {
  }
}

export class MonthlyBreakdownRequest implements Action {
  readonly type = StatsActionTypes.MONTHLY_BREAKDOWN_REQUEST;
}

export class MonthlyBreakdownSuccess implements Action {
  readonly type = StatsActionTypes.MONTHLY_BREAKDOWN_SUCCESS;

  constructor(public payload: { [id: number]: { [month: string]: number } }) {
  }
}

export class MonthlyBreakdownRemove implements Action {
  readonly type = StatsActionTypes.MONTHLY_BREAKDOWN_REMOVE;
}

export class StatsSelectedListingsSuccess implements Action {
  readonly type = StatsActionTypes.SELECTING_LISTING_SUCCESS;

  constructor(public payload: ListingCompact[]) {
  }
}

export class StatsChangeNetIncomeYear implements Action {
  readonly type = StatsActionTypes.CHANGE_NET_INCOME_YEAR;

  constructor(public payload: number) {
  }
}
