import {filter, map, take} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {CustomVariableService} from "../services/custom-variable.service";
import {Observable} from "rxjs";
import {CustomVariable} from "../models/custom-variable";
import {Store} from "@ngrx/store";
import {getCustomVariables, getIsCustomVariablesLoaded, getIsCustomVariablesLoading, State} from "../reducers";
import {
  CustomVariableAddRequestAction,
  CustomVariableAddSuccessAction,
  CustomVariableDeleteRequestAction,
  CustomVariableDeleteSuccessAction,
  CustomVariableIndexRequestAction,
  CustomVariableIndexSuccessAction,
  CustomVariableUpdateRequestAction,
  CustomVariableUpdateSuccessAction
} from "../actions/custom-variable";
import {QuillUtils} from "../utils/quill.util";

@Injectable()
export class CustomVariableRepository {

  constructor(private service: CustomVariableService, private store: Store<State>) {
  }

  getCustomVariablesLoading(): Observable<boolean> {
    return this.store.select(getIsCustomVariablesLoading);
  }

  getCustomVariablesLoaded(): Observable<boolean> {
    return this.store.select(getIsCustomVariablesLoaded);
  }

  getCustomVariables(force: boolean =false): Observable<CustomVariable[]> {
    let loading = false;
    let loaded = false;
    this.getCustomVariablesLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getCustomVariablesLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new CustomVariableIndexRequestAction());
      this.service.getCustomVariables().subscribe(res => {
        this.store.dispatch(new CustomVariableIndexSuccessAction(res));
        QuillUtils.addCustomVariables(res);
      });
    }
    return this.store.select(getCustomVariables).pipe(filter(c => !!c));
  }

  deleteCustomVariable(id: number): Observable<boolean> {
    this.store.dispatch(new CustomVariableDeleteRequestAction());
    return this.service.deleteCustomVariable(id).pipe(map(res => {
      this.store.dispatch(new CustomVariableDeleteSuccessAction(id));
      return true;
    }));
  }

  addNewCustomVariable(data: { key?: string, replacement_text?: string }): Observable<CustomVariable> {
    this.store.dispatch(new CustomVariableAddRequestAction());
    return this.service.addNewCustomVariable(data).pipe(map(res => {
      this.store.dispatch(new CustomVariableAddSuccessAction(res));
      QuillUtils.addCustomVariables([res]);
      return res;
    }));
  }

  updateCustomVariable(id: number, data: { key?: string, replacement_text?: string }): Observable<CustomVariable> {
    this.store.dispatch(new CustomVariableUpdateRequestAction());
    return this.service.updateCustomVariable(id, data).pipe(map(res => {
      this.store.dispatch(new CustomVariableUpdateSuccessAction(res));
      return res;
    }));
  }
}
