import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { Alert } from "../models/alert";
import { __HTTPResponseType } from "../enums/common.enum";
import { map } from "rxjs/operators";

@Injectable()
export class AlertService {

  constructor(private apiService: ApiService) {
  }

  createAlert(data: any): Observable<Alert> {
    return this.apiService.post<{ data: Alert }>("/admin/alerts", true, data).pipe(map(res => res.data));
  }

  updateAlert(id: number, data: any): Observable<Alert> {
    return this.apiService.put<{ data: Alert }>("/admin/alerts/" + id, true, data).pipe(map(res => res.data));
  }

  deleteAlert(id: number): Observable<boolean> {
    return this.apiService.delete("/admin/alerts/" + id, true, null, null, true, __HTTPResponseType.TEXT).pipe(map((res) => {
      return true;
    }));
  }

}
