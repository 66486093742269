// The actions in this file will modify ThreadState and ThreadMessageState.
import { Message } from "../../../models/new/inbox/message";
import { Action } from "../../action";

export enum MessageActionTypes {
  INDEX_REQUEST = "[MESSAGE MESSAGE NEW] Index Request",
  INDEX_SUCCESS = "[MESSAGE MESSAGE NEW] Index Success",
  CREATE_SUCCESS = "[MESSAGE MESSAGE NEW] Create Success",
}

export class MessageIndexRequest implements Action {
  readonly type = MessageActionTypes.INDEX_REQUEST;

  constructor(public payload: number) {

  }
}

export class MessageIndexSuccess implements Action {
  readonly type = MessageActionTypes.INDEX_SUCCESS;

  constructor(public payload: { messages: Message[], threadId: number }) {
  }
}

export class MessageCreateSuccess implements Action {
  readonly type = MessageActionTypes.CREATE_SUCCESS;

  constructor(public payload: { message: Message, threadId: number, bookingId: number }) {
  }
}
