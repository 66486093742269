import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {AutoReview} from "../../models/new/auto-review";
import {Action} from "../../actions/action";
import {AutoReviewActionTypes} from "../../actions/new/setttings/auto-review";

export interface AutoReviewState extends EntityState<AutoReview> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const autoReviewAdapter: EntityAdapter<AutoReview> = createEntityAdapter<AutoReview>({
  selectId: (review: AutoReview) => review.id,
});

export const initialState: AutoReviewState = autoReviewAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
});

export function autoReviewReducer(state: AutoReviewState = initialState, action: Action): AutoReviewState {
  switch (action.type) {

    case AutoReviewActionTypes.INDEX_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }

    case AutoReviewActionTypes.INDEX_SUCCESS: {
      const reviews = action.payload as AutoReview[];
      return autoReviewAdapter.addAll(reviews, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case AutoReviewActionTypes.CREATE_SUCCESS: {
      const review = action.payload as AutoReview;

      return autoReviewAdapter.addOne(review, state);
    }

    case AutoReviewActionTypes.DELETE_SUCCESS: {
      const autoReviewId = action.payload as number;

      return autoReviewAdapter.removeOne(autoReviewId, state);
    }

    case AutoReviewActionTypes.UPDATE_SUCCESS: {
      const review = action.payload as AutoReview;

      const newState = autoReviewAdapter.addOne(review, state);

      return autoReviewAdapter.updateOne({
        id: review.id,
        changes: review,
      }, newState);
    }

    default: {
      return state;
    }
  }
}


export const _getIsAutoReviewsLoading = (state: AutoReviewState) => state.isLoading;
export const _getIsAutoReviewsLoaded = (state: AutoReviewState) => state.isLoaded;
export const _getAutoReviewById = (state: AutoReviewState, id: number) => state.entities[id];
