import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import Quill from "quill";
import "quill-mention";

import { QuillUtils } from "../../../../utils/quill.util";

@Component({
  selector: "sd-task-note-update-popup",
  template: `
    <sd-modal-popup-layout title="Update Task Notes:" [preventDefaultClose]="true" (closeAction)="close($event)">
    </sd-modal-popup-layout>

    <div #messageTextArea class="message-box"></div>

    <div fxLayout="row">
      <span class="space-filler"></span>
      <button (click)="updateNotesDescription()" color="accent" mat-raised-button>Done
        <mat-icon>check</mat-icon>
      </button>
    </div>
  `,
  styles: [`
    .message-box {
      content-editable: true;
      font-size: 18px;
      height: calc(100% - 110px);
      width: 100%;
      margin-top: 10px;
      border-style: solid;
      border-width: 0.1px;
      padding: 5px;
      border-color: #c8c8c8
    }
  `]
})

export class TaskNoteUpdatePopupComponent implements OnInit {

  temp;
  @ViewChild("messageTextArea", { read: ElementRef }) body: ElementRef;
  quillBody: Quill;
  titleVariables = QuillUtils.VARIABLES;
  detailControl = new FormControl(null, [Validators.required]);

  constructor(public dialog: MatDialogRef<string>,
              @Inject(MAT_DIALOG_DATA) public data: { data: string }) {
    this.temp = data;
  }

  close(event: any) {
    if (event === true) {
      this.dialog.close(this.temp);
    }
    console.log(event);
  }

  updateNotesDescription() {
    this.dialog.close(this.temp);
  }

  ngOnInit(): void {

    const values = this.titleVariables;
    this.quillBody = new Quill(this.body.nativeElement, {
      theme: "bubble",
      modules: {
        mention: {
          allowedChars: /^[_A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["{"],
          isolatedCharacter: false,
          source: (searchTerm, renderList, mentionChar) => {

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(values[i]);
                }
              }
              renderList(matches, searchTerm);
            }
          },
          renderItem: (item, searchTerm) => {
            return item.value.replace("{", "{{");
          },
          dataAttributes: ["value"]
        },
      },
    });

    this.quillBody.root.innerHTML = this.temp;

    this.quillBody.on("text-change", () => {
      this.temp = this.quillBody.root.innerHTML;
    });

  }
}
