import { Prospect } from "../models/prospect";

import { Action } from "./action";

export const INDEX_TODAY_REQUEST = "[PROSPECT] Index Today Request";
export const INDEX_TODAY_SUCCESS = "[PROSPECT] Index Today Success";

export const INDEX_UPCOMING_REQUEST = "[PROSPECT] Index Upcoming Request";
export const INDEX_UPCOMING_SUCCESS = "[PROSPECT] Index Upcoming Success";

export const INDEX_EXPIRED_REQUEST = "[PROSPECT] Index Expired Request";
export const INDEX_EXPIRED_SUCCESS = "[PROSPECT] Index Expired Success";

export const INDEX_PASSED_REQUEST = "[PROSPECT] Index Passed Request";
export const INDEX_PASSED_SUCCESS = "[PROSPECT] Index Passed Success";

export const INDEX_RECENTLY_CREATED_REQUEST = "[PROSPECT] Index Recently Created Request";
export const INDEX_RECENTLY_CREATED_SUCCESS = "[PROSPECT] Index Recently Created Success";

export const CREATE_REQUEST = "[PROSPECT] Create Request";
export const CREATE_SUCCESS = "[PROSPECT] Create Success";

export const CHANGE_TODAY_CURRENT_PAGE = "[PROSPECT] Change Today Current Page";
export const CHANGE_UPCOMING_CURRENT_PAGE = "[PROSPECT] Change Upcoming Current Page";
export const CHANGE_EXPIRED_CURRENT_PAGE = "[PROSPECT] Change Expired Current Page";
export const CHANGE_PASSED_CURRENT_PAGE = "[PROSPECT] Change Passed Current Page";
export const CHANGE_RECENTLY_CREATED_CURRENT_PAGE = "[PROSPECT] Change Recently Created Current Page";

export class ProspectIndexTodayRequestAction implements Action {
  readonly type = INDEX_TODAY_REQUEST;

  constructor(public  payload: number) {
  }
}

export class ProspectIndexTodaySuccessAction implements Action {
  readonly type = INDEX_TODAY_SUCCESS;

  constructor(public payload: { prospects: Prospect[], currentPage: number, totalPages: number, totalCount: number }) {
  }
}

export class ProspectIndexUpcomingRequestAction implements Action {
  readonly type = INDEX_UPCOMING_REQUEST;

  constructor(public  payload: number) {
  }
}

export class ProspectIndexUpcomingSuccessAction implements Action {
  readonly type = INDEX_UPCOMING_SUCCESS;

  constructor(public payload: { prospects: Prospect[], currentPage: number, totalPages: number, totalCount: number }) {
  }
}

export class ProspectIndexExpiredRequestAction implements Action {
  readonly type = INDEX_EXPIRED_REQUEST;

  constructor(public  payload: number) {
  }
}

export class ProspectIndexExpiredSuccessAction implements Action {
  readonly type = INDEX_EXPIRED_SUCCESS;

  constructor(public payload: { prospects: Prospect[], currentPage: number, totalPages: number, totalCount: number }) {
  }
}

export class ProspectIndexPassedRequestAction implements Action {
  readonly type = INDEX_PASSED_REQUEST;

  constructor(public  payload: number) {
  }
}

export class ProspectIndexPassedSuccessAction implements Action {
  readonly type = INDEX_PASSED_SUCCESS;

  constructor(public payload: { prospects: Prospect[], currentPage: number, totalPages: number, totalCount: number }) {
  }
}

export class ProspectIndexRecentlyCreatedRequestAction implements Action {
  readonly type = INDEX_RECENTLY_CREATED_REQUEST;

  constructor(public  payload: number) {
  }
}

export class ProspectIndexRecentlyCreatedSuccessAction implements Action {
  readonly type = INDEX_RECENTLY_CREATED_SUCCESS;

  constructor(public payload: { prospects: Prospect[], currentPage: number, totalPages: number, totalCount: number }) {
  }
}

export class ProspectCreateRequestAction implements Action {
  readonly type = CREATE_REQUEST;
}

export class ProspectCreateSuccessAction implements Action {
  readonly type = CREATE_SUCCESS;

  constructor(public payload: Prospect) {
  }
}

export class ChangeTodayProspectCurrentPage implements Action {
  readonly type = CHANGE_TODAY_CURRENT_PAGE;

  constructor(public  payload: number) {
  }
}

export class ChangeUpcomingProspectCurrentPage implements Action {
  readonly type = CHANGE_UPCOMING_CURRENT_PAGE;

  constructor(public  payload: number) {
  }
}

export class ChangeExpiredProspectCurrentPage implements Action {
  readonly type = CHANGE_EXPIRED_CURRENT_PAGE;

  constructor(public  payload: number) {
  }
}

export class ChangePassedProspectCurrentPage implements Action {
  readonly type = CHANGE_PASSED_CURRENT_PAGE;

  constructor(public  payload: number) {
  }
}

export class ChangeRecentlyCreatedProspectCurrentPage implements Action {
  readonly type = CHANGE_RECENTLY_CREATED_CURRENT_PAGE;

  constructor(public  payload: number) {
  }
}

export type Actions
  = ProspectIndexTodayRequestAction
  | ProspectIndexTodaySuccessAction
  | ProspectIndexUpcomingRequestAction
  | ProspectIndexUpcomingSuccessAction
  | ProspectIndexExpiredRequestAction
  | ProspectIndexExpiredSuccessAction
  | ProspectIndexPassedRequestAction
  | ProspectIndexPassedSuccessAction
  | ProspectIndexRecentlyCreatedRequestAction
  | ProspectIndexRecentlyCreatedSuccessAction
  | ProspectCreateRequestAction
  | ProspectCreateSuccessAction;
