import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { debounceTime } from "rxjs/operators";

import { environment } from "../../../../environments/environment.test";
import { AirbnbAccount } from "../../../models/airbnb_account";
import { UserRepository } from "../../../repository/user-repository";

@Component({
  selector: "sd-change-airbnb-email",
  template: `
    <sd-modal-popup-layout title="Change Airbnb Email">
    </sd-modal-popup-layout>
    <div fxLayout="column" style="margin:2%">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutGap="15px">
          <div>
            <mat-form-field [color]="'accent'">
              <input matInput color="accent" placeholder="Enter Email" [formControl]="prefixText">
            </mat-form-field>
          </div>
          <span style="padding-top:4%;font-weight: bold;font-size:18px"> @ {{environment.key}}</span>
          <mat-icon *ngIf="available" style="padding-top: 4%" color="accent">check_circle</mat-icon>
          <mat-icon *ngIf="!available" style="padding-top: 4%" color="warn">highlight_off</mat-icon>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">

          <mat-spinner *ngIf="loading" [color]="'accent'" [diameter]="30" [strokeWidth]="4"></mat-spinner>

          <button mat-raised-button color="accent"
                  [disabled]="!available && !prefixText.valid"
                  style="padding-top:2%"
                  (click)="updateEmail()">UPDATE
          </button>
        </div>
      </div>
      <span *ngIf="available">This Email is Available, Change Email from your Airbnb Account then click update.</span>
    </div>
  `,
  styles: [`

  `]
})
export class ChangeAirbnbEmailPopupComponent implements OnInit {
  prefixText: FormControl = new FormControl(null, Validators.required);
  environment = environment;
  available: boolean;
  account: AirbnbAccount;
  loading: boolean;

  constructor(private dialog: MatDialog,
              private userRepo: UserRepository,
              @Inject(MAT_DIALOG_DATA) public data: AirbnbAccount
  ) {
    this.account = data;
  }

  ngOnInit() {
    this.prefixText.valueChanges.pipe(debounceTime(200)).subscribe(data => {
      this.checkValidity(data);
    });

  }

  checkValidity(prefix) {
    const data = {
      email_prefix: prefix
    };
    this.userRepo.checkEmailValidity(data).subscribe(res => {
      console.log(res);
      this.available = res.available;
      console.log(this.available);
    });
  }

  updateEmail() {

    const prefix = this.prefixText.value;
    this.loading = true;
    this.userRepo.changeEmail(this.account.id, {email_prefix: prefix}).subscribe((account) => {
      this.loading = false;
      this.dialog.closeAll();
    }, () => {
      this.loading = false;
    });
  }

}
