import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { OwnerWelcomeLetter } from "../models/new/owner-welcome-letter.model";

import { ApiService } from "./api.service";

@Injectable()
export class OwnerWelcomeLetterService {

  constructor(private apiService: ApiService) {
  }

  getOwnerWelcomeLetters(): Observable<OwnerWelcomeLetter[]> {
    return this.apiService.get<{ data: OwnerWelcomeLetter[] }>("/admin/owner-welcome-letters", true)
      .pipe(map(res => res.data));
  }

  createOwnerWelcomeLetter(fileName: string): Observable<OwnerWelcomeLetter> {
    return this.apiService.post<{ data: OwnerWelcomeLetter }>("/admin/owner-welcome-letters", true, {
      file_name: fileName
    }).pipe(map(res => res.data));
  }

  completeOwnerWelcomeLetter(id: number): Observable<OwnerWelcomeLetter> {
    return this.apiService.post<{ data: OwnerWelcomeLetter }>("/admin/owner-welcome-letters/" + id + "/complete", true, null).pipe(map(res => res.data));
  }

  setAsDefault(id: number): Observable<boolean> {
    return this.apiService.put("/admin/owner-welcome-letters/" + id + "/default", true, null).pipe(map(res => true));
  }
}
