import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "sd-map",
  template: `
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100%">
      <mat-form-field color="accent" style="width: 80%">
        <input #search [formControl]="searchControl" matInput placeholder="Search Place">
      </mat-form-field>
      <div #map style="height: 400px;width: 100%"></div>
    </div>
  `,
  styles: [``]
})

export class MapComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() location: { lat: number, lng: number } = {lat: 32.797306, lng: -79.93692};
  @Output() selectedLocation: EventEmitter<{ lat: number, lng: number }> = new EventEmitter();
  @Output() addressChanged: EventEmitter<{ street?: string, route?: string, city?: string, state?: string, country?: string, pincode?: string }> = new EventEmitter();
  @ViewChild("map", {read: ElementRef, static: true}) mapRef: ElementRef;
  @ViewChild("search", {read: ElementRef, static: true}) searchRef: ElementRef;
  map: any;
  marker: any;
  searchBox: any;
  zoom = 12;
  searchControl: FormControl = new FormControl();

  constructor() {
  }

  _isEditable = false;

  get isEditable(): boolean {
    return this._isEditable;
  }

  @Input() set isEditable(value) {
    this._isEditable = value;
    this._isEditable ? this.searchControl.enable() : this.searchControl.disable();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.map = new google.maps.Map(this.mapRef.nativeElement, {
      zoom: this.zoom,
      center: this.location,
      mapTypeControl: false,
      panControl: false,
      zoomControl: true,
      streetViewControl: false
    });
    this.marker = new google.maps.Marker({
      position: this.location,
      map: this.map,
      draggable: false
    });

    google.maps.event.addListener(this.map, "click", (event) => {
      this.updateMarker(event.latLng);
      this.geoCodeLocation(event.latLng);
    });

    this.searchBox = new google.maps.places.SearchBox(this.searchRef.nativeElement);

    this.searchBox.addListener("places_changed", () => {
      const places = this.searchBox.getPlaces() as any[];

      if (places.length > 0) {
        const place = places[0];
        this.updateAddress(place);
        this.updateMarker(place.geometry.location);
      }

    });

  }

  geoCodeLocation(location: any) {
    if (this.isEditable) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({location: location}, (results, status) => {
        if (status === "OK") {
          this.updateAddress(results[0]);
          console.log("[results]", results[0]);
          this.searchControl.setValue(results[0].formatted_address);
        }
      });
    }
  }

  updateMarker(location: any) {
    if (this.isEditable) {
      this.map.panTo(location);
      this.marker.setPosition(location);
      this.selectedLocation.emit({lat: location.lat(), lng: location.lng()});
    }
  }

  ngOnDestroy() {
    google.maps.event.clearInstanceListeners(this.map);
  }

  updateAddress(place: any) {
    if (place && place.address_components.length > 0) {

      const address: { street?: string, route?: string, city?: string, state?: string, country?: string, pincode?: string, plus_code?: string } = {};

      place.address_components.forEach(a => {

        if (a.types.indexOf("street_number") >= 0) {
          address.street = a.short_name;
        }

        if (a.types.indexOf("route") >= 0) {
          address.route = a.short_name;
        }

        if (a.types.indexOf("locality") >= 0) {
          address.city = a.short_name;
        }

        if (a.types.indexOf("postal_code") >= 0) {
          address.pincode = a.short_name;
        }

        if (a.types.indexOf("administrative_area_level_1") >= 0) {
          address.state = a.short_name;
        }

        if (a.types.indexOf("country") >= 0) {
          address.country = a.short_name;
        }

      });

      if (place?.plus_code?.global_code) {
        address.plus_code = place.plus_code.global_code.substr(0, 4);
      }

      this.addressChanged.emit(address);

    }
  }

}
