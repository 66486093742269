import { DatePipe } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { AdminGuard } from "../../guards/admin";
import { AnonymousGuard } from "../../guards/anonymous";
import { AuthGuard } from "../../guards/auth";
import { BootstrapGuard } from "../../guards/bootstrap";
import { HomeOwnerGuard } from "../../guards/homeowner";
import { OwnerGuard } from "../../guards/owner";
import { ReservationAuthGuard } from "../../guards/reservation-auth";
import { TaskAuthGuard } from "../../guards/task-auth";
import { AlertRepository } from "../../repository/alert.repository";
import { AutoResponseRepository } from "../../repository/auto-response.repository";
import { AutoReviewRepository } from "../../repository/auto-review.repository";
import { AutoTaskRepository } from "../../repository/auto-task.repository";
import { AutomatedMessageRepository } from "../../repository/automated-message.repository";
import { BookingRepository } from "../../repository/booking.repository";
import { CannedResponseRepository } from "../../repository/canned-response.repository";
import { ContactRepository } from "../../repository/contact.repository";
import { CustomVariableRepository } from "../../repository/custom-variable.repository";
import { DiscountRepository } from "../../repository/discount.repository";
import { ConciergeDraftRepository } from "../../repository/drafts/concierge-info-draft.repository";
import { GettingAroundDraftRepository } from "../../repository/drafts/getting-around-draft.repository";
import { GoalSetPercentagesDraftRepository } from "../../repository/drafts/goal-set-percentages-draft.repository";
import { GuestAccessDraftRepository } from "../../repository/drafts/guest-access-draft.repository";
import { InteractionsDraftRepository } from "../../repository/drafts/interactions-draft.repository";
import { OtherNotesDraftRepository } from "../../repository/drafts/other-notes-draft.repository";
import { OwnerStoryDraftRepository } from "../../repository/drafts/owner-story-draft.repository";
import { PrivateNotesDraftRepository } from "../../repository/drafts/private-notes-draft.repository";
import { ReviewResponseDraftRepository } from "../../repository/drafts/review-response-draft.repository";
import { SpaceDraftRepository } from "../../repository/drafts/space-draft.repository";
import { SummaryDraftRepository } from "../../repository/drafts/summary-draft.repository";
import { TheNeighbourhoodDraftRepository } from "../../repository/drafts/the-neighbourhood-draft.repository";
import { InventoryRepository } from "../../repository/inventory.repository";
import { ListingRepository } from "../../repository/listing.repository";
import { MinStaysTemplateRepository } from "../../repository/min-stays-template.repository";
import { MultiCalendarRulesRepository } from "../../repository/multi-calendar-rules.repository";
import { MultiCalendarRepository } from "../../repository/multi-calendar.repository";
import { MultiListingRepository } from "../../repository/multi-listing.repository";
import { NeighbourhoodDraftRepository } from "../../repository/neighbourhood-draft.repository";
import { OnboardingTaskRepository } from "../../repository/onboarding-task.repository";
import { OptionsRepository } from "../../repository/options.repository";
import { OwnerWelcomeLetterRepository } from "../../repository/owner-welcome-letter.repository";
import { ProspectRepository } from "../../repository/prospect.repository";
import { QuoteRepository } from "../../repository/quote.repository";
import { RentalAgreementRepository } from "../../repository/rental-agreement.repository";
import { ReviewRepository } from "../../repository/review.repository";
import { RoutingRulesRepository } from "../../repository/routing-rules.repository";
import { SettingsRepository } from "../../repository/settings.repository";
import { StatsRepository } from "../../repository/stats.repository";
import { TaskRepository } from "../../repository/task.repository";
import { ThreadRepository } from "../../repository/thread.repository";
import { UserRepository } from "../../repository/user-repository";
import { AlertService } from "../../services/alert.service";
import { ApiService } from "../../services/api.service";
import { AppService } from "../../services/app.service";
import { AutoResponseService } from "../../services/auto-response.service";
import { AutoReviewService } from "../../services/auto-review.service";
import { AutoTaskService } from "../../services/auto-task.service";
import { AutomatedMessageService } from "../../services/automated-message.service";
import { AWSService } from "../../services/aws.service";
import { BookingService } from "../../services/booking.service";
import { CannedResponseService } from "../../services/canned-response.service";
import { ContactService } from "../../services/contact.service";
import { CustomVariableService } from "../../services/custom-variable.service";
import { DiscountService } from "../../services/discount.service";
import { ConciergeDraftsService } from "../../services/drafts/concierge-info.service";
import { GettingAroundDraftsService } from "../../services/drafts/geting-around-drafts.service";
import { GoalSetPercentagesService } from "../../services/drafts/goal-set-percentages.service";
import { GuestAccessDraftsService } from "../../services/drafts/guest-access.service";
import { InteractionsDraftsService } from "../../services/drafts/interactions-drafts";
import { OtherNotesDraftsService } from "../../services/drafts/other-notes.service";
import { OwnerStoryDraftsService } from "../../services/drafts/owner-story-drafts.service";
import { PrivateNotesDraftsService } from "../../services/drafts/private-notes-drafts.service";
import { ReviewResponseDraftService } from "../../services/drafts/review-response-draft.service";
import { SavedNeighbourhoodDraftsService } from "../../services/drafts/saved-neighbourhood-drafts.service";
import { SpaceDraftsService } from "../../services/drafts/space-drafts.service";
import { SummaryDraftsService } from "../../services/drafts/summary-drafts.service";
import { FeedsService } from "../../services/feeds.service";
import { InventoryService } from "../../services/inventory.service";
import { ListingService } from "../../services/listing.service";
import { MinStaysTemplateService } from "../../services/min-stays-template.service";
import { MultiCalendarRulesService } from "../../services/multi-calendar-rules.service";
import { MultiCalendarService } from "../../services/multi-calendar.service";
import { MultiListingService } from "../../services/multi-listing.service";
import { NeighbourhoodDraftsService } from "../../services/neighbourhood-drafts.service";
import { OnboardingTaskService } from "../../services/onboarding-task.service";
import { OptionService } from "../../services/option.service";
import { OwnerWelcomeLetterService } from "../../services/owner-welcome-letter.service";
import { ProspectService } from "../../services/prospect.service";
import { QuoteService } from "../../services/quote.service";
import { RentalAgreementService } from "../../services/rental-agreement.service";
import { ReportService } from "../../services/report.service";
import { ReviewService } from "../../services/review.service";
import { RoutingRulesService } from "../../services/routing-rules.service";
import { SettingsService } from "../../services/settings.service";
import { SnackbarService } from "../../services/snackbar.service";
import { StatsService } from "../../services/stats.service";
import { StayDuvetService } from "../../services/stayduvet";
import { TaskService } from "../../services/task.service";
import { ThreadService } from "../../services/thread.service";
import { UserService } from "../../services/user.service";

@NgModule({
  providers: [
    ConciergeDraftRepository,
    ConciergeDraftsService,
    PrivateNotesDraftsService,
    PrivateNotesDraftRepository,
    OtherNotesDraftRepository,
    InteractionsDraftRepository,
    TheNeighbourhoodDraftRepository,
    GuestAccessDraftRepository,
    SummaryDraftRepository,
    SpaceDraftRepository,
    OwnerStoryDraftRepository,
    GettingAroundDraftRepository,
    GoalSetPercentagesDraftRepository,
    SummaryDraftsService,
    SpaceDraftsService,
    OwnerStoryDraftsService,
    GettingAroundDraftsService,
    GoalSetPercentagesService,
    GuestAccessDraftsService,
    OtherNotesDraftsService,
    InteractionsDraftsService,
    SavedNeighbourhoodDraftsService,
    NeighbourhoodDraftsService,
    NeighbourhoodDraftRepository,
    DiscountRepository,
    MultiCalendarService,
    MultiCalendarRepository,
    StatsService,
    StatsRepository,
    MultiListingService,
    MultiListingRepository,
    AutoResponseService,
    AutoResponseRepository,
    CannedResponseService,
    CannedResponseRepository,
    AutomatedMessageService,
    AutomatedMessageRepository,
    ContactRepository,
    InventoryRepository,
    InventoryService,
    StayDuvetService,
    AuthGuard,
    AnonymousGuard,
    BootstrapGuard,
    OwnerGuard,
    AdminGuard,
    HomeOwnerGuard,
    TaskAuthGuard,
    ReservationAuthGuard,
    DatePipe,
    ApiService,
    ListingRepository,
    OptionsRepository,
    TaskRepository,
    TaskService,
    AutoTaskService,
    AutoTaskRepository,
    OnboardingTaskService,
    OnboardingTaskRepository,
    SnackbarService,
    ListingService,
    OptionService,
    AWSService,
    BookingService,
    ContactService,
    BookingRepository,
    AppService,
    UserService,
    UserRepository,
    ReportService,
    DiscountService,
    QuoteService,
    CustomVariableService,
    FeedsService,
    QuoteRepository,
    CustomVariableRepository,
    SettingsService,
    SettingsRepository,
    AlertService,
    AlertRepository,
    ProspectService,
    ProspectRepository,
    MinStaysTemplateService,
    MinStaysTemplateRepository,
    ThreadService,
    ThreadRepository,
    MultiCalendarRulesService,
    MultiCalendarRulesRepository,
    RentalAgreementService,
    RentalAgreementRepository,
    OwnerWelcomeLetterService,
    OwnerWelcomeLetterRepository,
    RoutingRulesService,
    RoutingRulesRepository,
    AutoReviewService,
    AutoReviewRepository,
    ReviewService,
    ReviewRepository,
    ReviewResponseDraftService,
    ReviewResponseDraftRepository
  ],
})
export class CoreModule {
}
