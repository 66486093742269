import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { __HTTPResponseType, SortOrder } from "../enums/common.enum";
import {
  HomeownerReservationType,
  ReservationEnumHelper,
  ReservationSortType,
  ReservationType
} from "../enums/reservation.enum";
import { BookingAvailability } from "../models/new/booking/booking-availability";
import { BookingCompact } from "../models/new/booking/booking-compact.model";
import { BookingFull } from "../models/new/booking/booking-full.model";
import { ScheduledMessage } from "../models/new/booking/scheduled-message";
import { ScheduledReview } from "../models/new/booking/scheduled-review";
import { Review } from "../models/new/review";
import { TaskCompact } from "../models/new/tasks/task-compact.model";
import { UserCompact } from "../models/new/user/user-compact.model";
import { Payment } from "../models/payment";
import { ReservationGetResponse } from "../models/responses/reservations/reservation-get.response";
import { ReservationShowResponse } from "../models/responses/reservations/reservation-show.response";
import { SecurityDeduction } from "../models/security-deduction";
import { dateToDateString, getDateObj } from "../utils/calendar-utils";

import { ApiService } from "./api.service";

@Injectable()
export class BookingService {

  private RESERVATION_INDEX_INCLUDES = "guest";
  private RESERVATION_REVIEW_INCLUDES = "guest,scheduledReview";
  private RESERVATION_SHOW_INCLUDES = "guest,securityDeductions,tasks,logs,logs.creator,payments,scheduledMessages,alterations,scheduledReview,charity,affiliate";

  constructor(private apiService: ApiService) {

  }

  getBookings(type: ReservationType | HomeownerReservationType,
              sortOrder: SortOrder,
              sortType: ReservationSortType,
              review: number[],
              page: number,
              perPage: number,
              listingIds: number[],
              sources: string[],
              tags: number[],
              start?: Date,
              end?: Date,
              is_review_responded?: boolean,
              owner?: boolean,
              is_owner_review?: boolean): Observable<ReservationGetResponse> {

    const url = "/bookings/" + ReservationEnumHelper.getReservationTypeURLParams(type);
    const data: any = {
      include: type === ReservationType.RECENTLY_REVIEWED ? this.RESERVATION_INDEX_INCLUDES + ",airbnbAccount" : this.RESERVATION_INDEX_INCLUDES,
      sort_type: sortType,
      sort_order: sortOrder.toString(),
      review: review,
      page: page,
      per_page: perPage,
      listing_ids: listingIds,
      sources: sources
    };
    if (is_review_responded) {
      data.is_review_responded = false;
    }

    if (is_owner_review) {
      data.is_owner_review = false;
      delete data.review;
    }

    if (owner) {
      data.include = type === ReservationType.RECENTLY_REVIEWED ? this.RESERVATION_REVIEW_INCLUDES + ",airbnbAccount" : this.RESERVATION_REVIEW_INCLUDES;
    }

    if(tags.length) {
      data.tags = tags;
    }

    if (start) {
      data.start = moment(start).format("YYYY-MM-DD");
    }

    if (end) {
      data.end = moment(end).format("YYYY-MM-DD");
    }

    return this.apiService.get<ReservationGetResponse>(url, true, data);
  }

  getBookingById(bookingId: number, includesAll: boolean = true): Observable<BookingFull> {
    const data: any = {};
    if (includesAll) {
      data.include = this.RESERVATION_SHOW_INCLUDES;
    } else {
      data.include = this.RESERVATION_INDEX_INCLUDES;
    }
    return this.apiService.get<ReservationShowResponse>("/bookings/" + bookingId, true, data).pipe(map((res) => res.data));
  }

  getHashedBooking(hashedId: string): Observable<any> {
    return this.apiService.get<{ data: BookingFull }>("/bookings/hashed/" + hashedId + "?include=guest,property,property.coverImage", false).pipe(map((res) => res.data));
  }

  getUpcomingBookingWithPropertyId(propertyId: number): Observable<BookingCompact[]> {
    return this.apiService.get<{ data: BookingCompact[] }>("/properties/" + propertyId + "/bookings", true).pipe(map((res) => res.data));
  }

  getCurrentRentalAgreement(): Observable<string> {
    return this.apiService.get<{ rental_agreement: string }>("/default-rental-agreement", false)
      .pipe(map(r => r.rental_agreement));
  }

  updateScheduledMessage(bookingId: number, scheduledMessageId: number, data: any): Observable<ScheduledMessage> {
    return this.apiService.put<{ data: ScheduledMessage }>("/admin/scheduled-messages/" + scheduledMessageId, true, data).pipe(map(res => res.data));
  }

  updateScheduledReview(reviewId: number, data: any): Observable<ScheduledReview> {
    return this.apiService.put<{ data: ScheduledReview }>("/scheduled-reviews/" + reviewId, true, data).pipe(map(res => res.data));
  }

  addBooking(data: {
    property_id: number, start: string, end: string, number_of_guests: number,
    security_deposit_fee: number, guest_channel_fee: number, base_amount: number,
    cleaning_fee: number, commission: number, payment_method: string, guest_id?: number,
    first_name?: string, last_name?: string, email?: string, phone?: string,
    create_auto_task?: boolean, create_automation?: boolean
  }): Observable<BookingFull> {
    data.start = dateToDateString(getDateObj(data.start));
    data.end = dateToDateString(getDateObj(data.end));

    const req = {
      ...data,
      create_auto_task: data.create_auto_task ? 1 : 0,
      create_automation: data.create_automation ? 1 : 0
    };

    return this.apiService.post<{ data: BookingFull }>("/admin/bookings?include=guest,securityDeductions,tasks,logs,payments,scheduledMessages", true, req)
      .pipe(map((res) => res.data));
  }

  deleteBookingGuest(guestId: string, bookingId: string): Observable<boolean> {
    return this.apiService.delete("/admin/booking-guests/" + guestId, true, null, null, true, __HTTPResponseType.TEXT).pipe(map(res => {
      return true;
    }));
  }

  updateBookingGuest(guestId: string, bookingId: string, data: {
    first_name?: string; last_name?: string; email?: string; phone_number?: string;
  }): Observable<UserCompact> {
    return this.apiService.put<{ data: UserCompact }>("/admin/booking-guests/" + guestId, true, data).pipe(map(res => res.data));
  }

  addBookingGuest(bookingId: string, data: {
    first_name?: string; last_name?: string; email?: string; phone_number?: string;
  }): Observable<UserCompact> {
    return this.apiService.post<{ data: UserCompact }>("/admin/bookings/" + bookingId + "/guests", true, data).pipe(map(res => res.data));
  }

  getOwnerUpcomingBookings(homeOwnerId?: number): Observable<BookingCompact[]> {
    const data: any = {
      include: "guest",
      sort_type: "end",
      sort_order: "asc"
    };

    if (homeOwnerId) {
      data.homeowner_id = homeOwnerId;
    }

    return this.apiService.get<{ data: BookingCompact[] }>("/bookings/owner-upcoming", true, data).pipe(map((res) => res.data));
  }

  deductSecurityFee(bookingId: number, data: { amount: number, description: string }): Observable<SecurityDeduction> {
    return this.apiService.post<{ data: SecurityDeduction }>("/admin/bookings/" + bookingId + "/security-deduction", true, data).pipe(map(res => res.data));
  }

  collectPayment(bookingId: number, data: { amount: number, description: string, method: string, card_token?: string }): Observable<Payment> {
    return this.apiService.post<{ data: Payment }>("/admin/bookings/" + bookingId + "/collect", true, data).pipe(map(res => res.data));
  }

  refundSecurity(bookingId: number): Observable<boolean> {
    return this.apiService.post("/admin/bookings/" + bookingId + "/security-refund", true, null).pipe(map(res => {
      return true;
    }));
  }

  partialRefund(bookingId: number, data: {
    refund_amount: number,
    refund_security: boolean
  }): Observable<boolean> {
    return this.apiService.post("/admin/bookings/" + bookingId + "/refund", true, data).pipe(map(res => {
      return true;
    }));
  }

  fullRefund(bookingId: number): Observable<boolean> {
    return this.apiService.post("/admin/bookings/" + bookingId + "/full-refund", true, null)
      .pipe(map(res => {
        return true;
      }));
  }

  importBookingFromAIRBNB(propertyId: number, bookingCode: string) {
    return this.apiService.post<{ data: BookingFull }>("/admin/bookings/import-airbnb-booking", true, {
      property_id: propertyId,
      booking_code: bookingCode
    }).pipe(map(res => {
      return res.data;
    }));
  }

  getGuestBadgeCount(guestId: number, reservationId: number) {
    return this.apiService.get<{ data: number }>("/guests/" + guestId + "/badges", true, {
      booking_id: reservationId
    });
  }

  updateBooking(booking_id: number, thread_id: number = null, data: {
    listing_id?: number,
    status?: string,
    check_in_time?: string,
    check_out_time?: string,
    checked_in?: boolean,
    booking_notes?: string
  }): Observable<BookingFull> {
    return this.apiService.put<{ data: BookingFull }>("/bookings/" + booking_id, true, data).pipe(map(b => b.data));
  }

  updateBookingStatus(booking_id: number, status: string): Observable<BookingFull> {
    return this.apiService.put<{ data: BookingFull }>("/bookings/" + booking_id + "/status", true, {
      status: status
    }).pipe(map(b => b.data));
  }

  checkAvailability(booking_id: number, start: Date, end: Date, property_id: number): Observable<BookingAvailability> {

    const data = {
      booking_id: booking_id,
      start: dateToDateString(start),
      end: dateToDateString(end),
      property_id: property_id
    };
    return this.apiService.get<BookingAvailability>("/admin/bookings/" + booking_id + "/avail-check", true, data);
  }

  checkAirbnbAvailability(data): Observable<any> {
    const _data = {
      ...data,
      check_in: dateToDateString(data.check_in),
      check_out: dateToDateString(data.check_out),
    };
    return this.apiService.get<BookingAvailability>("/admin/bookings/" + data.booking_id + "/airbnb-avail-check", true, _data);
  }

  alterBooking(data: any): Observable<BookingFull> {
    data.start = dateToDateString(data.start);
    data.end = dateToDateString(data.end);
    data.include = this.RESERVATION_SHOW_INCLUDES;
    return this.apiService.post<{ data: BookingFull }>("/admin/bookings/" + data.booking_id + "/alter", true, data)
      .pipe(map(res => res.data));
  }

  alterAirbnbBooking(data): Observable<BookingFull> {
    const _data = {
      ...data,
      check_in: dateToDateString(data.check_in),
      check_out: dateToDateString(data.check_out),
    };
    _data.include = this.RESERVATION_SHOW_INCLUDES;

    return this.apiService.post<{ data: BookingFull }>("/admin/bookings/" + data.booking_id + "/airbnb-alter", true, _data)
      .pipe(map(res => res.data));
  }

  updateBookingCommission(bookingId: number, commision: number): Observable<BookingFull> {
    const data = {commission: commision};
    return this.apiService.post<{ data: BookingFull }>("/admin/bookings/" + bookingId + "/update-commission", true, data)
      .pipe(map(res => res.data));
  }

  acceptAlterationRequest(alterationId: number): Observable<BookingFull> {
    return this.apiService.post<{ data: BookingFull }>("/admin/accept-alteration/" + alterationId, true, null)
      .pipe(map(res => res.data));
  }

  rejectAlterationRequest(alterationId: number): Observable<BookingFull> {
    return this.apiService.post<{ data: BookingFull }>("/admin/reject-alteration/" + alterationId, true, null)
      .pipe(map(res => res.data));
  }

  cancelAlterationRequest(alterationId: number): Observable<BookingFull> {
    return this.apiService.post<{ data: BookingFull }>("/admin/cancel-alteration/" + alterationId, true, null)
      .pipe(map(res => res.data));
  }

  requestSendMoney(bookingId: number, data: { status: string, amount: number, message: string, reason: string }): Observable<any> {
    return this.apiService.post<{ data: BookingFull }>("/admin/bookings/" + bookingId + "/airbnb-resolution", true, data, null, true, __HTTPResponseType.TEXT);
  }

  getBookingInquiriesForContact(bookingId: number): Observable<BookingCompact[]> {
    return this.apiService.get<{ data: BookingCompact[] }>("/admin/bookings/" + bookingId + "/booking-inquiries", true, null)
      .pipe(map(res => res.data));
  }

  updateProperty(bookingId: number, propertyId: number): Observable<BookingFull> {
    return this.apiService.put<{ data: BookingFull }>("/admin/bookings/" + bookingId + "/property/" + propertyId, true, {include: this.RESERVATION_SHOW_INCLUDES})
      .pipe(map(res => res.data));
  }

  getGuestReviews(bookingId: number): Observable<Review[]> {
    return this.apiService.get<{ data: Review[] }>("/admin/bookings/" + bookingId + "/review?include=guest", true)
      .pipe(map(res => res.data));
  }

  attachAutoReview(bookingId: number, autoReviewId: number): Observable<ScheduledReview> {
    return this.apiService.put<{ data: ScheduledReview }>("/bookings/" + bookingId + "/auto-reviews/" + autoReviewId, true)
      .pipe(map(res => res.data));
  }

  resendPaymentLink(bookingId: number): Observable<any> {
    return this.apiService.post("/admin/bookings/" + bookingId + "/resend-payment-link", true, null);
  }

  extentPaymentExpireTime(bookingId: number, data: any): Observable<BookingFull> {
    return this.apiService.put<{ data: BookingFull }>("/admin/bookings/" + bookingId + "/extend-payment-time", true, data)
      .pipe(map(res => res.data));
  }

  getVendorTasksForBooking(bookingId: number): Observable<TaskCompact[]> {
    return this.apiService.get<{ data: TaskCompact[] }>(`/vendor-tasks/${bookingId}`, true, null)
      .pipe(map(res => res.data));
  }

  validPromo(code: string) {
    return this.apiService.get<{valid: boolean, affiliate_id: number, percentage: number, is_active: boolean}>(`/public/promo-code/${code}/valid`, true, null);
  }
}
