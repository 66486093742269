import { PerPage, SortOrder } from "../../../enums/common.enum";
import { TaskCategory, TaskSortBy, TaskStatus, TaskType } from "../../../enums/task.enum";
import { TaskCompact } from "../../../models/new/tasks/task-compact.model";
import { TaskFull } from "../../../models/new/tasks/task-full.model";
import { Action } from "../../action";

export enum TaskActionTypes {
  RESET_DATA = "[TASK TASK NEW] Task Reset Data",
  RESET_TASK = "[TASK TASK NEW] Task Reset",

  NEXT_PAGE_REQUEST = "[TASK TASK NEW] Next Page Request",
  NEXT_PAGE_SUCCESS = "[TASK TASK NEW] Next Page Success",

  CREATE_REQUEST = "[TASK TASK NEW] Create Request",
  CREATE_SUCCESS = "[TASK TASK NEW] Create Success",

  SHOW_REQUEST = "[TASK TASK NEW] Show Request",
  SHOW_SUCCESS = "[TASK TASK NEW] Show Success",

  UPDATE_REQUEST = "[TASK TASK NEW] Update Request",
  UPDATE_SUCCESS = "[TASK TASK NEW] Update Success",

  CLEAR_FULL_LOADING_LOADED = "[TASK TASK NEW] Clear Full Loading Loaded",

  CHANGE_TYPE = "[TASK TASK NEW] Change Type",
  CHANGE_CATEGORY = "[TASK TASK NEW] Change Category",
  CHANGE_STATUS = "[TASK TASK NEW] Change Status",
  CHANGE_ASSIGNEE = "[TASK TASK NEW] Change Assignee",
  CHANGE_EMPLOYEE = "[TASK TASK NEW] Change Employee",
  CHANGE_LISTINGS = "[TASK TASK NEW] Change Listings",
  CHANGE_CREATED_BY = "[TASK TASK NEW] Change Created By",

  CHANGE_PER_PAGE = "[TASK TASK NEW] Change Per Page",

  CHANGE_SORT_BY = "[TASK TASK NEW] Change Sort By",
  CHANGE_SORT_ORDER = "[TASK TASK NEW] Change Sort Order",
  CHANGE_CURRENT_PAGE = "[TASK TASK NEW] Change Current Page",
  CHANGE_PAYMENT_BY = "[TASK TASK NEW] Change Payment By",
  CHANGE_PRICE_FILTER = "[TASK TASK NEW] Change Price Filter",
  CHANGE_START_DATE = "[TASK TASK NEW] Change Start Date",
  CHANGE_END_DATE = "[TASK TASK NEW] Change End Date",
  CHANGE_OFFSET = "[TASK TASK NEW] Change Offset",
  CHANGE_OPEN_PROPERTY = "[TASK TASK NEW] Change Open Property",
  SET_FILTERS = "[TASK TASK NEW] Set Filters",

  TASK_SELECT = "[TASK TASK NEW] Select",
  TASK_DESELECT = "[TASK TASK NEW] Deselect",
  TASK_DESELECT_ALL = "[TASK TASK NEW] Deselect All",

  ADD_TASK_TO_NOT_EXIST = "[TASK TASK NEW] Add Task To Not Exist",

  BULK_UPDATE_REQUEST = "[TASK TASK NEW] Bulk Update Request",
  BULK_UPDATE_SUCCESS = "[TASK TASK NEW] Bulk Update Success",

  DELETE_REQUEST = "[TASK TASK NEW] Delete Request",
  DELETE_SUCCESS = "[TASK TASK NEW] Delete Success",

  ATTACHMENT_DELETE_REQUEST = "[TASK TASK NEW] Attachment Delete Request",
  ATTACHMENT_DELETE_SUCCESS = "[TASK TASK NEW] Attachment Delete Success",
}

export class TaskNextPageRequest implements Action {
  readonly type = TaskActionTypes.NEXT_PAGE_REQUEST;

  constructor(public payload: number) {
  }
}

export class TaskNextPageSuccess implements Action {
  readonly type = TaskActionTypes.NEXT_PAGE_SUCCESS;

  constructor(public payload: { tasks: TaskCompact[], currentPage: number, totalPages: number, totalCount: number }) {
  }
}

export class TaskCreateRequest implements Action {
  readonly type = TaskActionTypes.CREATE_REQUEST;

}

export class TaskCreateSuccess implements Action {
  readonly type = TaskActionTypes.CREATE_SUCCESS;

  constructor(public payload: TaskFull) {
  }
}

export class TaskShowRequest implements Action {
  readonly type = TaskActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {
  }
}

export class TaskShowSuccess implements Action {
  readonly type = TaskActionTypes.SHOW_SUCCESS;

  constructor(public payload: TaskFull) {
  }
}

export class AddTaskToNotExist implements Action {
  readonly type = TaskActionTypes.ADD_TASK_TO_NOT_EXIST;

  constructor(public payload: number) {
  }
}

export class TaskUpdateRequest implements Action {
  readonly type = TaskActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class TaskUpdateSuccess implements Action {
  readonly type = TaskActionTypes.UPDATE_SUCCESS;

  constructor(public payload: TaskFull) {
  }
}

export class TaskClearFullLoadingLoaded implements Action {
  readonly type = TaskActionTypes.CLEAR_FULL_LOADING_LOADED;

  constructor() {
  }
}

export class TaskChangeType implements Action {
  readonly type = TaskActionTypes.CHANGE_TYPE;

  constructor(public payload: TaskType) {
  }
}

export class TaskChangeCategory implements Action {
  readonly type = TaskActionTypes.CHANGE_CATEGORY;

  constructor(public payload: string[]) {
  }
}

export class TaskChangeStatus implements Action {
  readonly type = TaskActionTypes.CHANGE_STATUS;

  constructor(public payload: string[]) {
  }
}

export class TaskChangeAssignee implements Action {
  readonly type = TaskActionTypes.CHANGE_ASSIGNEE;

  constructor(public payload: number[] | null) {
  }
}

export class TaskChangeEmployee implements Action {
  readonly type = TaskActionTypes.CHANGE_EMPLOYEE;

  constructor(public payload: number[] | null) {
  }
}

export class TaskChangeListings implements Action {
  readonly type = TaskActionTypes.CHANGE_LISTINGS;

  constructor(public payload: number[]) {
  }
}

export class TaskChangeCreatedBy implements Action {
  readonly type = TaskActionTypes.CHANGE_CREATED_BY;

  constructor(public payload: number[]) {
  }

}

export class TaskChangeWhoWillPay implements Action {
  readonly type = TaskActionTypes.CHANGE_PAYMENT_BY;

  constructor(public payload: number[]) {
  }

}

export class TaskChangePriceFilter implements Action {
  readonly type = TaskActionTypes.CHANGE_PRICE_FILTER;

  constructor(public payload: boolean) {
  }

}

export class TaskChangeSortBy implements Action {
  readonly type = TaskActionTypes.CHANGE_SORT_BY;

  constructor(public payload: TaskSortBy) {
  }
}

export class TaskChangePerPage implements Action {
  readonly type = TaskActionTypes.CHANGE_PER_PAGE;

  constructor(public payload: PerPage) {
  }
}

export class TaskChangeSortOrder implements Action {
  readonly type = TaskActionTypes.CHANGE_SORT_ORDER;

  constructor(public payload: SortOrder) {
  }
}

export class TaskChangeCurrentPage implements Action {
  readonly type = TaskActionTypes.CHANGE_CURRENT_PAGE;

  constructor(public payload: number) {

  }
}

export class TaskSelect implements Action {
  readonly type = TaskActionTypes.TASK_SELECT;

  constructor(public payload: TaskCompact) {
  }
}

export class TaskDeselect implements Action {
  readonly type = TaskActionTypes.TASK_DESELECT;

  constructor(public payload: number) {
  }
}

export class TaskDeselectAll implements Action {
  readonly type = TaskActionTypes.TASK_DESELECT_ALL;
}

export class TaskResetTasks implements Action {
  readonly type = TaskActionTypes.RESET_TASK;
}

export class TaskResetData implements Action {
  readonly type = TaskActionTypes.RESET_DATA;
}

export class TaskBulkUpdateRequest implements Action {
  readonly type = TaskActionTypes.BULK_UPDATE_REQUEST;
}

export class TaskBulkUpdateSuccess implements Action {
  readonly type = TaskActionTypes.BULK_UPDATE_SUCCESS;

  constructor(public payload: {
    property_id?: number,
    status?: TaskStatus,
    assignee_id?: number,
    employee_id?: number,
    assignee_type?: string,
    due_date?: string,
    category?: TaskCategory,
    title?: string
  }) {
  }
}

export class TaskDeleteRequest implements Action {
  readonly type = TaskActionTypes.DELETE_REQUEST;
}

export class TaskDeleteSuccess implements Action {
  readonly type = TaskActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}

export class TaskAttachmentDeleteRequest implements Action {
  readonly type = TaskActionTypes.ATTACHMENT_DELETE_REQUEST;
}

export class TaskAttachmentDeleteSuccess implements Action {
  readonly type = TaskActionTypes.ATTACHMENT_DELETE_SUCCESS;

  constructor(public payload: { property_id: number, image_id: number }) {
  }
}

export class TaskChangeStartDate implements Action {
  readonly type = TaskActionTypes.CHANGE_START_DATE;

  constructor(public payload: Date) {
  }
}

export class TaskChangeEndDate implements Action {
  readonly type = TaskActionTypes.CHANGE_END_DATE;

  constructor(public payload: Date) {
  }
}

export class TaskChangeOffset implements Action {
  readonly type = TaskActionTypes.CHANGE_OFFSET;

  constructor(public payload: string) {
  }
}

export class TaskChangeOpenProperty implements Action {
  readonly type = TaskActionTypes.CHANGE_OPEN_PROPERTY;

  constructor(public payload: boolean) {
  }
}

export class TaskChangeFilters implements Action {
  readonly type = TaskActionTypes.SET_FILTERS;

  constructor(public payload: any) {
  }
}
