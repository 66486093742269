/**
 * Created by aditya on 17/7/17.
 */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { isNullOrUndefined } from "util";
import { AirbnbAccount } from "../../../models/airbnb_account";
import { UserRepository } from "../../../repository/user-repository";

@Component({
  selector: "sd-add-airbnb-popup",
  template: `
    <sd-modal-popup-layout title="Add Airbnb Account">
      <form [formGroup]="addAccountForm" (ngSubmit)="addAccountForm.valid && onSubmit()">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="40px" fxLayoutGap.lt-sm="10px"
             fxLayoutAlign="space-between">
          <div class="width-60">
            <p>Credentials for your Airbnb Account</p>
            <div fxLayout="column" fxLayoutAlign="center stretch" fxFlexAlign="center" fxLayoutGap="10px">
              <mat-form-field [color]="'accent'" class="responsiveWidth">
                <input matInput placeholder="Forwarding Email" type="email" formControlName="forwarding_email">
                <mat-error>Enter an email address where we will forward the 'airbnb emails'</mat-error>
              </mat-form-field>
              <mat-form-field [color]="'accent'" class="responsiveWidth">
                <input matInput placeholder="Airbnb Username" type="email" formControlName="airbnb_username">
                <mat-error>Enter the Airbnb Email for login</mat-error>
              </mat-form-field>
              <mat-form-field [color]="'accent'" class="responsiveWidth">
                <input fxFlex="80%" matInput placeholder="Airbnb Password"
                       [type]="showPassword.checked ? 'text' : 'password'" formControlName="airbnb_password">
                <mat-checkbox fxFlex="20%" #showPassword fxHide.lt-sm>Show Password</mat-checkbox>
                <mat-error>Enter the Airbnb Password for login</mat-error>
              </mat-form-field>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" [style.margin-top]="'0px'">
                 <span>
                    <mat-icon>info</mat-icon>
                    Changing Airbnb password can be tricky. Airbnb enforces some
                    strict rule on changing password. It has to be more than 8 letter and it cannot be a password
                    that you've previously used. We will try to update your Airbnb account to use your new password.
                    and if we fail doing so, we will use a random password and send you an email about it.
                 </span>
              </div>
            </div>
          </div>
          <div class="width-40">
            <div>
              <p style="font-weight: bold">Forwarding</p>
              <mat-slide-toggle (change)="allEmailToggleChanged($event)">All emails</mat-slide-toggle>
            </div>
            <div fxLayout="column" fxLayoutGap="5px" style="margin-top: 20px">
              <p>I want only these emails</p>
              <mat-slide-toggle [checked]="false" formControlName="email_changed">Email Changes
              </mat-slide-toggle>
              <mat-slide-toggle [checked]="false" formControlName="reservation_confirmation">Reservation confirmations
              </mat-slide-toggle>
              <mat-slide-toggle [checked]="false" formControlName="reservation_requests">Reservation request
              </mat-slide-toggle>
              <mat-slide-toggle [checked]="true" formControlName="inquiries">Inquiries</mat-slide-toggle>
              <mat-slide-toggle [checked]="false" formControlName="reminders">Reminders</mat-slide-toggle>
              <mat-slide-toggle [checked]="true" formControlName="reviews">Reviews</mat-slide-toggle>
              <mat-slide-toggle [checked]="false" formControlName="emails_from_guests">Email from guests
              </mat-slide-toggle>
              <p>Note: All email's from Airbnb's support will still be forwarded</p>
              <div>
                <hr>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center" fxLayoutGap.lt-sm="10px">
          <mat-spinner color="accent" *ngIf="loading" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <button *ngIf="!update" mat-raised-button [disabled]="loading"
                  color="accent">
            Add Account
          </button>
          <button *ngIf="update" mat-raised-button [disabled]="loading"
                  color="accent">
            Update Account
          </button>
        </div>
      </form>
    </sd-modal-popup-layout>
  `,
  styles: [`
    mat-spinner {
      width: 24px;
      height: 24px;
      margin-right: 20px;
    }

    .width-60 {
      width: 60%;
    }

    .width-40 {
      width: 40%;
    }

    @media only screen and (max-width: 700px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 95%;
      }

      .responsiveWidth {
        width: 95%;
      }
    }
  `]
})
export class AirbnbPopupComponent implements OnInit {
  addAccountForm: FormGroup;
  loading = false;

  update = false;
  @Input() account: AirbnbAccount;
  @Output() accountAdded = new EventEmitter<any>();

  constructor(private userRepo: UserRepository,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>) {
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.account)) {
      this.update = true;
      this.addAccountForm = new FormGroup({
        "forwarding_email": new FormControl(this.account.forwarding_email, [Validators.required, Validators.email]),
        "airbnb_username": new FormControl(this.account.airbnb_username, [Validators.required, Validators.email]),
        "airbnb_password": new FormControl(this.account.airbnb_password, [Validators.required]),

        "email_changed": new FormControl(this.account.reservation_confirmation, []),
        "reservation_confirmation": new FormControl(this.account.reservation_confirmation, []),
        "reservation_requests": new FormControl(this.account.reservation_requests, []),
        "inquiries": new FormControl(this.account.inquiries, []),
        "reminders": new FormControl(this.account.reminders, []),
        "reviews": new FormControl(this.account.reviews, []),
        "emails_from_guests": new FormControl(this.account.emails_from_guests, []),
      });
    } else {
      this.addAccountForm = new FormGroup({
        "forwarding_email": new FormControl(null, [Validators.required, Validators.email]),
        "airbnb_username": new FormControl(null, [Validators.required, Validators.email]),
        "airbnb_password": new FormControl(null, [Validators.required]),

        "email_changed": new FormControl(null, []),
        "reservation_confirmation": new FormControl(null, []),
        "reservation_requests": new FormControl(null, []),
        "inquiries": new FormControl(null, []),
        "reminders": new FormControl(null, []),
        "reviews": new FormControl(null, []),
        "emails_from_guests": new FormControl(null, []),
      });
    }

  }

  onSubmit() {
    const data = this.addAccountForm.value;
    if (!this.update) {
      this.loading = true;
      this.userRepo.attachAirbnbAccount(data).subscribe((res) => {
        this.loading = false;
        this.accountAdded.emit(res);
        this.dialogRef.close();
      }, () => {
        this.loading = false;
      });
    } else {
      if (this.account.airbnb_username === data["airbnb_username"] && this.account.airbnb_password === data["airbnb_password"]) {
        delete data["airbnb_username"];
        delete data["airbnb_password"];
      }

      this.loading = true;
      console.log(data);
      this.userRepo.updateAirbnbAccount(this.account.id, this.addAccountForm.value).subscribe((res) => {
        this.loading = false;
        this.accountAdded.emit(res);
        this.dialogRef.close();
      }, () => {
        this.loading = false;
      });
    }
  }

  allEmailToggleChanged(event: MatSlideToggleChange) {
    this.addAccountForm.patchValue({
      "email_changed": event.checked,
      "reservation_confirmation": event.checked,
      "reservation_requests": event.checked,
      "inquiries": event.checked,
      "reminders": event.checked,
      "reviews": event.checked,
      "emails_from_guests": event.checked,
    });
  }

}
