import { AfterViewInit, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { environment } from "../../../../../environments/environment";
import { MultiCalendarRule } from "../../../../models/new/multi-calendar-rule";
import { ListingRepository } from "../../../../repository/listing.repository";
import { MultiCalendarRulesRepository } from "../../../../repository/multi-calendar-rules.repository";
import { AppService } from "../../../../services/app.service";

import { CreateMultiCalendarRuleComponent } from "./create-multi-calendar-rule";

@Component({
  selector: "sd-setting-multi-calendar-rules",
  template: `
    <div fxLayout="column" fxLayoutGap="30px" style="margin: 20px; margin-bottom: 50px" fxFlex="100%">

      <div fxLayoutAlign="start center">
        <button mat-raised-button color="primary" *ngIf="!listingId" (click)="openTools()">
          <mat-icon>arrow_back</mat-icon>
          Back
        </button>
      </div>

      <div id="content" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">

        <div fxLayoutAlign="space-between start" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center">
          <div fxLayout="column" fxLayoutAlign="center start" class="para">
            <span style="font-size: 30px;font-weight: bolder; text-align: center">Multi-Calendar Rules</span>
          </div>
          <div>
            <button mat-icon-button (click)="refresh()">
              <mat-icon>autorenew</mat-icon>
            </button>
            <button mat-raised-button color="accent" (click)="addMultiCalendarRule()">
              Add New Multi-Calendar Rule
            </button>
          </div>
        </div>

        <hr>

        <div fxLayoutAlign="center center">

          <mat-spinner color="accent" *ngIf="isLoading" [diameter]="60" [strokeWidth]="5"></mat-spinner>
          <div fxLayout="column" *ngIf="!isLoading && isLoaded" fxLayoutGap="10px" fxFlex="100%">
            <h3 style="text-align: center" *ngIf="multiCalendarRules.length === 0">
              No Multi-Calendar Rules Found
            </h3>


            <mat-card *ngFor="let rule of multiCalendarRules" (click)="editRule(rule)" style="cursor: pointer">
              <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
                <span fxFlex="40%"
                      fxLayoutAlign="start center">
                  <p (click)="open(rule.parent_property_id)">{{getListingTitleFromId(rule.parent_property_id)}}</p>
                   </span>
                <mat-icon fxFlex="20%" fxLayoutAlign="center center" *ngIf="rule.reverse_rule_applied"> repeat
                </mat-icon>
                <mat-icon fxFlex="20%" fxLayoutAlign="center center" *ngIf="!rule.reverse_rule_applied"> arrow_forward
                </mat-icon>
                <mat-icon fxFlex="20%" *ngIf="rule.sync_future_booking_limit"
                          matTooltip="Future Booking limit Synchronized">sync
                </mat-icon>
                <mat-icon fxFlex="20%" *ngIf="!rule.sync_future_booking_limit"
                          matTooltip="Future Booking limit Not Synchronized">sync_disabled
                </mat-icon>
                <span fxFlex="40%"
                      fxLayoutAlign="end center">
                  <p
                    (click)="open(rule.blocked_property_id)">{{getListingTitleFromId(rule.blocked_property_id)}}</p></span>
              </div>
            </mat-card>

          </div>
        </div>

      </div>

    </div>
  `,
  styles: [`
    mat-card {
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
      border: 1px solid lightgrey !important;
      box-shadow: none !important;
    }
  `]
})

export class SettingMultiCalendarRulesComponent implements OnInit, OnDestroy, AfterViewInit {

  isLoading: boolean;
  isLoaded: boolean;

  @Input() multiCalendarRules: MultiCalendarRule[] = [];

  destroyed$ = new Subject();
  @Input() listingId;

  private dialogRef: MatDialogRef<any>;

  constructor(private router: Router,
              private dialog: MatDialog,
              private listingRepo: ListingRepository,
              private rulesRepo: MultiCalendarRulesRepository,
              private appService: AppService) {
  }

  ngOnInit() {

    if (!this.listingId) {
      console.log("if entered");
      this.setUpCalendarRules();
    } else {
      this.isLoaded = true;
      this.isLoading = false;
    }

  }

  setUpCalendarRules() {

    this.rulesRepo.getIsMultiCalendarRulesLoading().pipe(takeUntil(this.destroyed$)).subscribe(l => this.isLoading = l);
    this.rulesRepo.getIsMultiCalendarRulesLoaded().pipe(takeUntil(this.destroyed$)).subscribe(l => this.isLoaded = l);

    this.rulesRepo.getMultiCalendarRules().subscribe((rules) => {
      this.multiCalendarRules = rules;
    });
  }

  ngAfterViewInit() {
    this.appService.changeAppTitle("Settings - Multi-Calendar Rules");
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openTools() {
    this.router.navigate(["/settings/tools"]);
  }

  addMultiCalendarRule() {
    this.dialogRef = this.dialog.open(CreateMultiCalendarRuleComponent,
      {
        data: {
          listingId: this.listingId,
        }
      });

    this.dialogRef.updateSize("100%");
  }

  editRule(rule: MultiCalendarRule) {
    this.dialogRef = this.dialog.open(CreateMultiCalendarRuleComponent, {
      data: {
        openForEdit: true,
        rule: rule
      }
    });
    this.dialogRef.updateSize("100%");
  }

  refresh() {
    this.rulesRepo.getMultiCalendarRules(true).subscribe();
  }

  getListingTitleFromId(id: number): string {
    return this.listingRepo.getListingTitle(id);
  }

  open(id) {
    event.preventDefault();
    event.stopPropagation();
    window.open(environment.dashboardURL + "/listings/" + id, "_blank");
  }

}
