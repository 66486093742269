import {map, take} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {MinStaysTemplateService} from "../services/min-stays-template.service";
import {Store} from "@ngrx/store";
import {getIsMinStayTemplatesLoaded, getIsMinStayTemplatesLoading, getMinStayTemplates, State} from "../reducers";
import {Observable} from "rxjs";
import {
  CreateMinStaysTemplateRequestAction,
  CreateMinStaysTemplateSuccessAction,
  DeleteMinStaysTemplateRequestAction,
  DeleteMinStaysTemplateSuccessAction,
  IndexMinStaysTemplateRequestAction,
  IndexMinStaysTemplateSuccessAction,
  UpdateMinStaysTemplateRequestAction,
  UpdateMinStaysTemplateSuccessAction
} from "../actions/min-stays-template";
import {MinStayTemplate} from "../models/min-stay-template";

@Injectable()
export class MinStaysTemplateRepository {

  constructor(private minStaysService: MinStaysTemplateService, private store: Store<State>) {
  }

  getMinStayTemplatesLoading(): Observable<boolean> {
    return this.store.select(getIsMinStayTemplatesLoading);
  }

  getMinStayTemplatesLoaded(): Observable<boolean> {
    return this.store.select(getIsMinStayTemplatesLoaded);
  }

  getMinStayTemplates(force: boolean = false): Observable<MinStayTemplate[]> {
    let loading;
    let loaded;

    this.getMinStayTemplatesLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getMinStayTemplatesLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new IndexMinStaysTemplateRequestAction());
       this.minStaysService.getMinStayTemplates().subscribe(res => {
        this.store.dispatch(new IndexMinStaysTemplateSuccessAction({ templates: res }));
      });
    }

    return this.store.select(getMinStayTemplates);
  }

  deleteMinStayTemplate(templateId: number): Observable<boolean> {
    this.store.dispatch(new DeleteMinStaysTemplateRequestAction());
    return this.minStaysService.deleteMinStayTemplate(templateId).pipe(map((res) => {
      this.store.dispatch(new DeleteMinStaysTemplateSuccessAction({ templateId: templateId }));
      return res;
    }));
  }

  addNewMinStayTemplate(data: any): Observable<MinStayTemplate> {
    this.store.dispatch(new CreateMinStaysTemplateRequestAction());
    return this.minStaysService.addNewMinStayTemplate(data).pipe(map(res => {
      this.store.dispatch(new CreateMinStaysTemplateSuccessAction({
        template: res
      }));
      return res;
    }));
  }

  updateMinStayTemplate(templateId: number, data: any): Observable<MinStayTemplate> {
    this.store.dispatch(new UpdateMinStaysTemplateRequestAction());
    return this.minStaysService.updateMinStayTemplate(templateId, data).pipe(map(res => {
      this.store.dispatch(new UpdateMinStaysTemplateSuccessAction({
        template: res
      }));
      return res;
    }));
  }
}
