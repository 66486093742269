export class ConstantsUtil {

  static LISTING_FILTER_DRAFT = "draft";
  static LISTING_FILTER_PENDING = "pending";
  static LISTING_FILTER_ACCEPTED = "accepted";
  static LISTING_FILTER_REJECTED = "rejected";
  static LISTING_FILTER_SHOW_ALL = "all";

  static L_TYPE_ENTIRE_HOME = "entire_home";
  static L_TYPE_PRIVATE_ROOM = "private_room";
  static L_TYPE_SHARED_ROOM = "shared_room";

  static AUTO_RES_CONF_TYPE_PREFIX = "confirmed_";
}
