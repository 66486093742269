import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { DiscountRule } from "../models/rule";
import { Observable } from "rxjs";
import { LastMinuteDiscount } from "../models/last-minute-discount";
import { map } from "rxjs/operators";

@Injectable()
export class DiscountService {

  constructor(private apiService: ApiService) {
  }

  addSpecialDiscount(data: { title: string, description: string, rules: DiscountRule[] }): Observable<LastMinuteDiscount> {
    return this.apiService.post<{ data: LastMinuteDiscount }>("/admin/special-discounts", true, data)
      .pipe(map(res => res.data));
  }

  updateSpecialDiscount(discountId: number, data: { title: string, description: string, rules: DiscountRule[] }): Observable<LastMinuteDiscount> {
    return this.apiService.put<{ data: LastMinuteDiscount }>("/admin/special-discounts/" + discountId, true, data).pipe(map((res) => res.data));
  }

  fetchSpecialDiscounts(): Observable<LastMinuteDiscount[]> {
    return this.apiService.get<{ data: LastMinuteDiscount[] }>("/admin/special-discounts", true).pipe(map((res) => res.data));
  }

}
