import {map} from "rxjs/operators";
import {ApiService} from "./api.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ContactGetResponse} from "../models/responses/tasks/contact-get.response";
import {ContactShowResponse} from "../models/responses/tasks/contact-show.response";
import {UserFull} from "../models/new/user/user-full.model";
import {HttpEvent} from "@angular/common/http";
import {W9FormCreateResponse} from "../models/responses/tasks/w9-form-create.response";
import {CreditCard, CreditCardResponse} from "../models/new/credit-card.model";
import {__HTTPResponseType, SortOrder} from "../enums/common.enum";
import {ContactDocumentType, ContactSortType, ContactStatus, ContactType} from "../enums/contact.enum";
import {BankInfoModel} from "../models/bank-info.model";

@Injectable()
export class ContactService {
  constructor(private apiService: ApiService) {
  }

  getActiveContacts(): Observable<ContactGetResponse> {
    return this.apiService.get<ContactGetResponse>("/admin/contacts/active", true);
  }

  getInActiveContacts(): Observable<ContactGetResponse> {
    return this.apiService.get<ContactGetResponse>("/admin/contacts/in-active", true);
  }

  getHomeOwners(): Observable<ContactGetResponse> {
    return this.apiService.get<ContactGetResponse>("/admin/homeowners", true);
  }

  showContact(contactId: number): Observable<ContactShowResponse> {
    return this.apiService.get<ContactShowResponse>("/admin/contacts/" + contactId, true, { include: "logs, owner, admin, managementContact, guest" });
  }

  createContact(data: Partial<UserFull>): Observable<ContactShowResponse> {
    return this.apiService.post<ContactShowResponse>("/contacts", true, data);
  }

  updateContact(contactId: number, data: Partial<UserFull>): Observable<ContactShowResponse> {
    return this.apiService.put<ContactShowResponse>("/admin/contacts/" + contactId, true, data);
  }

  updateContactProfilePic(contactId: number, pic: File): Observable<HttpEvent<ContactShowResponse>> {
    return this.apiService.upload<ContactShowResponse>("/admin/contacts/" + contactId + "/update-pic", "POST", pic, true, "image");
  }

  markActive(contactId: number): Observable<ContactShowResponse> {
    return this.apiService.put<ContactShowResponse>("/admin/contacts/" + contactId + "/active", true);
  }

  markInActive(contactId: number): Observable<ContactShowResponse> {
    return this.apiService.put<ContactShowResponse>("/admin/contacts/" + contactId + "/in-active", true);
  }

  createW9Form(contactId: number, fileName: string, type: ContactDocumentType): Observable<W9FormCreateResponse> {
    const data = {
      file_name: fileName,
      type: type
    };
    return this.apiService.post<W9FormCreateResponse>("/contacts/" + contactId + "/create-w9-form", true, data);
  }

  completeW9Form(w9FormId: number): Observable<W9FormCreateResponse> {
    return this.apiService.post<W9FormCreateResponse>("/w9-forms/" + w9FormId + "/complete", true, null);
  }

  deleteW9Form(w9FormId: number): Observable<void> {
    return this.apiService.delete<void>("/w9-forms/" + w9FormId, true, null, null, true, __HTTPResponseType.TEXT);
  }

  getSearchResults(query: string): Observable<any> {
    return this.apiService.get<any>("/admin/users/search", true, {
      query: query,
      include: "guest"
    });
  }

  getContactCreditCards(contactId: number): Observable<CreditCard[]> {
    return this.apiService.get<CreditCardResponse>("/admin/users/" + contactId + "/saved-cards", true).pipe(
      map(card => card.data));
  }

  getContacts(page: number, perPage: number, sortBy: ContactSortType, sortOrder: SortOrder, type: ContactType, status: ContactStatus, search_query?: string): Observable<ContactGetResponse> {

    const data = {
      page: page,
      per_page: perPage,
      sort_type: sortBy,
      sort_order: sortOrder,
      role: type,
      is_active: status,
      include: "managementContact"
    };
    if(search_query) {
      data["search_query"] = search_query;
    }
    return this.apiService.get<ContactGetResponse>("/admin/contacts", true, data);
  }

  getSelectedContacts(contactIds: number[]): Observable<UserFull[]> {
    const data = {
      user_ids: contactIds
    };
    return this.apiService.get<{ data: UserFull[] }>("/admin/users/index-users", true, data).pipe(map(res => res.data));
  }

  resetPassword(id) {
    return this.apiService.get("/admin/users/" + id + "/reset-password", true,null,null,true,__HTTPResponseType.TEXT);
  }

  getBankAccounts(data: { property_id?: number, user_id?: number}): Observable<BankInfoModel[]> {
    return this.apiService.get<{data: BankInfoModel[]}>("/bank-info", true, data)
      .pipe(map(res => res.data));
  }

  updateBankAccount(id: number, data: BankInfoModel): Observable<BankInfoModel> {
    return this.apiService.put<{data: BankInfoModel}>("/bank-info/" + id, true, data)
      .pipe(map(res => res.data));
  }

  createBankAccount(data: BankInfoModel): Observable<BankInfoModel> {
    return this.apiService.post<{data: BankInfoModel}>("/bank-info", true, data)
      .pipe(map(res => res.data));
  }

  deleteBankAccount(id: number) {
    return this.apiService.delete("/bank-info/" + id, true, null, null, true, __HTTPResponseType.TEXT);
  }

  mergeContacts(data: any): Observable<UserFull>{
    return this.apiService.put<{data: UserFull}>("/admin/users/merge-contacts", true, data)
      .pipe(map(res => res.data));
  }
  changePassword(id:number,data:any){
    return this.apiService.put("/admin/users/"+id+"/change-password",true,data);
  }

}
