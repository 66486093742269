import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

import { Action } from "../../actions/action";
import { OwnerWelcomeLetterActionTypes } from "../../actions/new/setttings/owner-welcome-letter";
import { OwnerWelcomeLetter } from "../../models/new/owner-welcome-letter.model";

export interface OwnerWelcomeLetterState extends EntityState<OwnerWelcomeLetter> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const ownerWelcomeLetterAdapter: EntityAdapter<OwnerWelcomeLetter> = createEntityAdapter<OwnerWelcomeLetter>({
  selectId: (letter: OwnerWelcomeLetter) => letter.id
});

export const initialState: OwnerWelcomeLetterState = ownerWelcomeLetterAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
});

export function ownerWelcomeLetterReducer(state: OwnerWelcomeLetterState = initialState, action: Action): OwnerWelcomeLetterState {
  switch (action.type) {
    case OwnerWelcomeLetterActionTypes.INDEX_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case OwnerWelcomeLetterActionTypes.INDEX_SUCCESS: {
      const letters = action.payload as OwnerWelcomeLetter[];

      return ownerWelcomeLetterAdapter.addMany(letters, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }


    case OwnerWelcomeLetterActionTypes.CREATE_SUCCESS: {
      const letter = action.payload as OwnerWelcomeLetter;
      return ownerWelcomeLetterAdapter.addOne(letter, state);
    }


    case OwnerWelcomeLetterActionTypes.SET_AS_DEFAULT: {
      const id = action.payload;
      let allIds = ownerWelcomeLetterAdapter.getSelectors().selectIds(state) as number[];
      allIds = allIds.filter(item => item !== id);
      const changes = [{ id: id, changes: { is_default: true } }];
      for (const id of allIds) {
        changes.push({ id: id, changes: { is_default: false } });
      }
      return ownerWelcomeLetterAdapter.updateMany(changes, state);
    }

    default: {
      return state;
    }
  }
}

export const _getIsOwnerWelcomeLetterLoading = (state: OwnerWelcomeLetterState) => state.isLoading;
export const _getIsOwnerWelcomeLetterLoaded = (state: OwnerWelcomeLetterState) => state.isLoaded;
