import { Action } from "../../../actions/action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import * as _ from "lodash";
import { OwnerStoryDraft } from "../../../models/new/drafts/owner-story-draft.model";
import { OwnerStoryActionTypes } from "../../../actions/new/setttings/drafts/owner-story-drafts";


export interface OwnerStoryDraftState extends EntityState<OwnerStoryDraft> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const ownerStoryAdapter: EntityAdapter<OwnerStoryDraft> = createEntityAdapter
  < OwnerStoryDraft > ({
    selectId: (ownerStory: OwnerStoryDraft) => ownerStory.id
  });

export const initialState: OwnerStoryDraftState = ownerStoryAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function ownerStoryReducer(state: OwnerStoryDraftState = initialState, action: Action): OwnerStoryDraftState {
  switch (action.type) {
    case OwnerStoryActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        isLoaded: false
      }
    }

    case OwnerStoryActionTypes.INDEX_SUCCESS: {
      const ownerStory = action.payload as OwnerStoryDraft[];
      return ownerStoryAdapter.addAll(ownerStory, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case OwnerStoryActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }

    case OwnerStoryActionTypes.CREATE_SUCCESS: {
      const ownerStory = action.payload as OwnerStoryDraft;
      return ownerStoryAdapter.addOne(ownerStory,{
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case OwnerStoryActionTypes.SHOW_REQUEST: {
      const ownerStoryId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, ownerStoryId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          ownerStoryId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, ownerStoryId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, ownerStoryId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case OwnerStoryActionTypes.SHOW_SUCCESS: {
      const ownerStory = action.payload as OwnerStoryDraft;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, ownerStory.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, ownerStory.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, ownerStory.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          ownerStory.id
        ];
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case OwnerStoryActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case OwnerStoryActionTypes.UPDATE_SUCCESS: {
      const ownerStory = action.payload as OwnerStoryDraft;
      return ownerStoryAdapter.updateOne({
        id: ownerStory.id,
        changes: ownerStory
      }, state);
    }

    case OwnerStoryActionTypes.DELETE_REQUEST: {
      return {
        ...state
      };
    }

    case OwnerStoryActionTypes.DELETE_SUCCESS: {
      const ownerStoryId = action.payload as number;

      return ownerStoryAdapter.removeOne(ownerStoryId, state);
    }

    default: {
      return state;
    }

  }
}

export const _getIsOwnerStoryDraftLoading = (state: OwnerStoryDraftState) => state.isLoading;
export const _getIsOwnerStoryDraftLoaded = (state: OwnerStoryDraftState) => state.isLoaded;

export const _getIsFullOwnerStoryDraftLoading = (state: OwnerStoryDraftState, draftId: number) => {
  return state.fullLoadingIds.indexOf(draftId) !== -1;
};
export const _getIsFullOwnerStoryDraftLoaded = (state: OwnerStoryDraftState, draftId: number) => {
  return state.fullLoadedIds.indexOf(draftId) !== -1;
};
