import { AutomationRule } from "./common.enum";

export enum AutoTaskOffsetReference {
  CHECK_IN = "check_in",
  CHECK_OUT = "check_out",
  BOOKING_CONFIRMATION = "booking_confirmation"
}

export enum AutoTaskAssigneeType {
  HVAC = "hvac",
  PLUMBER = "plumber",
  PAINTER = "painter",
  ELECTRICIAN = "electrician",
  HOMEOWNER = "homeowner",
  INSPECTOR = "inspector",
  GENERAL_MAINTENANCE = "general_maintenance",
  HOUSEKEEPER = "housekeeper",
  ASSIGNEE = "assignee",
  PREVENTATIVE_MAINTENANCE = "preventative_maintenance",
  LANDSCAPING = "landscaping",
  GUEST = "guest",
}

export enum AutoTaskExpenseType {
  CLEANING_FEE = "cleaning_fee",
  STATE_TAX_AMOUNT = "state_tax_amount",
  COMMISSION_AMOUNT = "commission_amount"
}

export enum AutoTaskSources {
  AIRBNB = "airbnb",
  STAYDUVET = "stayduvet",
  HOMEAWAY = "homeaway",
  PLUM_GUIDE = "plum_guide",
  MARRIOTT = "hvmi",
  REFERRAL = "referral",
  OWNER_BLOCK = "owner_block"
}

export class AutoTaskEnumHelper {
  static getAutoTaskOffsetReferenceTitle(ref: AutoTaskOffsetReference): string {
    let title = "";
    switch (ref) {
      case AutoTaskOffsetReference.CHECK_IN : {
        title = "Check In";
        break;
      }

      case AutoTaskOffsetReference.CHECK_OUT : {
        title = "Check Out";
        break;
      }
      case AutoTaskOffsetReference.BOOKING_CONFIRMATION : {
        title = "Booking Confirmation";
        break;
      }
    }
    return title;
  }

  static getAutoTaskAssigneeType(ref: AutoTaskAssigneeType): string {
    let title = "";
    switch (ref) {
      case AutoTaskAssigneeType.HVAC : {
        title = " HVAC";
        break;
      }
      case AutoTaskAssigneeType.PLUMBER : {
        title = " Plumber";
        break;
      }
      case AutoTaskAssigneeType.PAINTER : {
        title = " Painter";
        break;
      }
      case AutoTaskAssigneeType.ELECTRICIAN : {
        title = " Electrician";
        break;
      }
      case AutoTaskAssigneeType.GENERAL_MAINTENANCE : {
        title = " General Maintenance";
        break;
      }
      case AutoTaskAssigneeType.HOMEOWNER : {
        title = " Home Owner";
        break;
      }
      case AutoTaskAssigneeType.INSPECTOR : {
        title = " Inspector";
        break;
      }
      case AutoTaskAssigneeType.HOUSEKEEPER : {
        title = " Housekeeper";
        break;
      }

      case AutoTaskAssigneeType.PREVENTATIVE_MAINTENANCE : {
        title = "Preventative Maintenance";
        break;
      }

      case AutoTaskAssigneeType.LANDSCAPING : {
        title = "Landscaping";
        break;
      }
      case AutoTaskAssigneeType.ASSIGNEE : {
        title = " Assignee for the listing";
        break;
      }
      case AutoTaskAssigneeType.GUEST : {
        title = " Guest";
        break;
      }
    }
    return title;
  }

  static getAutoTaskAutomationRule(ref: AutomationRule): string {
    let title = "";
    switch (ref) {
      case AutomationRule.INCLUDE: {
        title = "include";
        break;
      }
      case AutomationRule.EXCLUDE: {
        title = "exclude";
        break;
      }
    }
    return title;
  }

  static getAutoTaskSourceTitle(ref: AutoTaskSources): string {
    let title = "";
    switch (ref) {
      case AutoTaskSources.AIRBNB : {
        title = "Airbnb";
        break;
      }

      case AutoTaskSources.STAYDUVET : {
        title = "Stay Duvet";
        break;
      }
      case AutoTaskSources.HOMEAWAY : {
        title = "VRBO";
        break;
      }
      case AutoTaskSources.PLUM_GUIDE : {
        title = "Plum Guide";
        break;
      }

      case AutoTaskSources.MARRIOTT : {
        title = "Marriott";
        break;
      }
      case AutoTaskSources.REFERRAL : {
        title = "Referral";
        break;
      }
      case AutoTaskSources.OWNER_BLOCK : {
        title = "Owner Block";
        break;
      }
    }
    return title;
  }
}
