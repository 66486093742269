import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { BankInfoModel } from "../../../../models/bank-info.model";
import { RoutingRule } from "../../../../models/new/listing/routing-rule";
import { ContactRepository } from "../../../../repository/contact.repository";
import { RoutingRulesRepository } from "../../../../repository/routing-rules.repository";

@Component({
  selector: "sd-add-routing-rule-popup",
  template: `
    <sd-modal-popup-layout [title]="title">
      <div *ngIf="bankAccountsLoaded" fxFlex="100%" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">

        <div *ngIf="!openForEdit" fxLayout="column" fxFlex="100%" style="width: 100%; margin: 20px"
             [formGroup]="formGroup">

          <div fxLayout="row">
            <span class="space-filler"></span>
            <button mat-raised-button (click)="addRule()" fxFlexAlign="end" color="accent">
              <mat-icon>add</mat-icon>
              <span>Add Rule</span>
            </button>
          </div>

          <div *ngFor="let rule of formGroup.get('accounts').controls;let i =index" formArrayName="accounts">

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" [formGroupName]="i">
              <mat-form-field style="width: 60%" color="accent">
                <mat-select
                  formControlName="bank_account_id"
                  placeholder="Select Bank Account">
                  <mat-option *ngFor="let account of filteredBankAccounts" [value]="account.id">
                    {{account.account_name}} {{account.account_number + " (" + account.bank_name + ")"}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field style="width: 30%" color="accent">
                <input matInput type="number" formControlName="percentage"
                       placeholder="Percentage">
              </mat-form-field>

              <button mat-icon-button (click)="deleteRule(i)">
                <mat-icon style="color: red" matTooltip="Delete Rule">
                  cancel
                </mat-icon>
              </button>

            </div>
          </div>
        </div>

        <div *ngIf="openForEdit" fxFlex="100%" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"
             style="width: 100%; margin: 20px">
          <mat-form-field style="width: 60%" color="accent">
            <mat-select
              placeholder="Select Bank Account"
              [disabled]="'true'"
              [ngModel]="routingRule.bank_account_id">
              <mat-option *ngFor="let account of bankAccounts" [value]="account.id">
                {{account.account_name}} {{account.account_number + " (" + account.bank_name + ")"}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="width: 30%" color="accent">
            <input matInput type="number" [formControl]="percentageControl" placeholder="Percentage">
          </mat-form-field>

        </div>


        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <mat-spinner *ngIf="isUpdating" [color]="'accent'" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <span *ngIf="!isPercentageValid" style="color: red">Percentage cannot be greater than 100</span>
          <button mat-raised-button *ngIf="!openForEdit" [disabled]="!isPercentageValid || !formGroup.valid"
                  color="accent" (click)="add()">
            SAVE
          </button>
          <button mat-raised-button *ngIf="openForEdit" [disabled]="!percentageControl.valid" color="accent"
                  (click)="update()">UPDATE
          </button>
          <button mat-raised-button color="warn" (click)="close()">CANCEL</button>
        </div>

      </div>
      <div *ngIf="bankAccountsLoading" fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner [color]="'accent'" [diameter]="60" [strokeWidth]="4"></mat-spinner>
        <span>Loading...</span>
      </div>
    </sd-modal-popup-layout>`,
  styles: [``]
})

export class AddRoutingRulePopupComponent implements OnInit, OnDestroy {

  bankAccountsLoading: boolean;
  bankAccountsLoaded: boolean;
  title: string;
  bankAccounts: BankInfoModel[] = [];
  isUpdating: boolean;
  isPercentageValid = true;
  oldRules: RoutingRule[] = [];
  formGroup: FormGroup;
  accounts: FormArray;
  percentageControl: FormControl;
  filteredBankAccounts: BankInfoModel[] = [];

  @Input() listingId: number;
  @Input() userId: number;
  @Input() openForEdit: boolean;
  @Input() routingRule: RoutingRule;

  constructor(private routingRepo: RoutingRulesRepository,
              private contactRepo: ContactRepository,
              private dialog: MatDialog,
              private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.title = this.openForEdit ? "Update Routing Rule" : "Add New Routing Rules";
    this.setUpRoutingRules();
    this.setUpBankDetails();
    if (!this.openForEdit) {
      this.formGroup = this.formBuilder.group({
        accounts: this.formBuilder.array([this.initRule()])
      });

      this.formGroup.valueChanges.subscribe(form => {
        const accounts = form.accounts as { bank_account_id: number, percentage: number }[];

        if (accounts) {
          let sum = 0;
          accounts.forEach(a => {
            sum += a.percentage;
          });

          this.isPercentageValid = sum <= 100;
        }

      });

    } else {
      this.percentageControl = new FormControl(this.routingRule.percentage, [Validators.required, Validators.min(0), Validators.max(100)]);
    }
  }

  setUpRoutingRules() {
    this.routingRepo.getRules(this.listingId).subscribe(rules => {
      this.oldRules = rules;
    });
  }

  setUpBankDetails() {
    this.bankAccountsLoading = true;

    let data = {};

    if (this.listingId) {
      data = {property_id: this.listingId};
    } else {
      data = {user_id: this.userId};
    }

    this.contactRepo.getBankAccounts(data).subscribe(b => {
      this.bankAccounts = b;
      this.bankAccountsLoading = false;
      this.bankAccountsLoaded = true;
      this.filterRules();
    }, error1 => {
      this.bankAccountsLoading = false;
    });
  }

  filterRules() {
    const accountIds = this.oldRules.map(b => b.bank_account_id);
    this.filteredBankAccounts = this.bankAccounts.filter(f => accountIds.indexOf(f.id) === -1);
  }

  addRule() {
    this.accounts = this.formGroup.get("accounts") as FormArray;
    this.accounts.push(this.initRule());
  }

  initRule(): FormGroup {
    return this.formBuilder.group({
      bank_account_id: new FormControl(null, [Validators.required]),
      percentage: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
    });
  }

  deleteRule(index: number) {

    this.accounts = this.formGroup.get("accounts") as FormArray;

    if (this.accounts.length > 1) {
      this.accounts.removeAt(index);
    }
  }

  add() {
    console.log("form", this.formGroup.get("accounts").value);
    this.isUpdating = true;

    const data = {
      ...this.formGroup.get("accounts").value
    };

    const bankAccountIds = this.formGroup.get("accounts").value.map(r => r.bank_account_id);

    this.routingRepo.createRule(this.listingId, data)
      .subscribe(res => {
        this.isUpdating = false;
        this.refreshContacts(bankAccountIds);
        this.close();
      }, error1 => {
        this.isUpdating = false;
      });
  }

  update() {
    this.isUpdating = true;
    this.routingRepo.updateRule(this.routingRule.id, this.routingRule.property_id, this.routingRule.bank_account_id, this.percentageControl.value)
      .subscribe((res) => {
        this.isUpdating = false;
        this.refreshContacts([this.routingRule.bank_account_id]);
        this.close();
      }, error1 => {
        this.isUpdating = false;
      });
  }

  refreshContacts(bankInfoIds: number[]) {
    console.log(bankInfoIds);
    const bankAccountIds = this.bankAccounts.map(b => b.id);
    console.log(bankAccountIds);
    const contactIds = bankInfoIds.map(id => {
      const index = bankAccountIds.indexOf(id);
      return this.bankAccounts[index].user_id;
    });

    this.contactRepo.deleteContactsFromStore(contactIds);

  }

  close() {
    this.dialog.closeAll();
  }

  ngOnDestroy() {
  }
}
