import { AirbnbCancellationPolicy, HomeawayCancellationPolicy } from "../models/new/listing/listing-full.model";

export enum ListingStatus {
  DRAFT = "draft",
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export enum ListingCurrentState {
  CLEAN = "clean",
  DIRTY = "dirty",
}

export enum ListingPricingVendor {
  PRICE_LABS = "price_labs",
  BEYOND = "beyond"
}

export enum ListingCategory {
  ALL_LISTINGS = "all",
  WAITING_LISTINGS = "pending",
  APPROVED_LISTINGS = "accepted",
  REJECTED_LISTINGS = "rejected",
  DRAFT_LISTINGS = "draft",
  DEACTIVATED_LISTINGS = "deactivated",
}


export enum PropertyType {
  GUESTHOUSE = "guesthouse",
  HOUSE = "house",
  MINSU = "minsu",
  BUNGALOW = "bungalow",
  COTTAGE = "cottage",
  SHEPHERD_HUT = "shepherd_hut",
  TIPI = "tipi",
  TREE_HOUSE = "tree_house",
  TRULLO = "trullo",
  WINDMILL = "windmill",
  YURT = "yurt",
  APARTMENT = "apartment",
  TENT = "tent",
  VILLA = "villa",
  CASTLE = "castle",
  PENSION = "pension",
  CABIN_OR_BUNGALOW = "cabin",
  CHALET = "chalet"
}

export enum PropertyTypeBookingPal {
  TOWNHOME = "townhome",
  SINGLE_ROOM = "single_room",
  DOUBLE_ROOM = "double_room",
  TWIN = "double",
  TWIN_DOUBLE="twin_double",
  TRIPLE_ROOM = "triple_room",
  QUADRUPLE = "quadruple",
  FAMILY = "family",
  SUITE = "suite",
  STUDIO = "studio",
  BUNGALOW = "bungalow",
  PRIVATE_ROOM = "private_room",
  SHARED_ROOM = "shared_room",
  COTTAGE = "cottage",
  APART_HOTEL = "appart_hotel",
  NARROW_BOAT = "narrow_boat",
  RIAD = "riad",
  SHEPHERD_HUT = "shepherd_hut",
  TIPI = "tipi",
  CRUISE = "cruise",
  TOWER= "tower",
  TREE_HOUSE = "tree_house",
  TRULLO = "trullo",
  WATERMILL = "watermill",
  WINDMILL = "windmill",
  YACHT = "yacht",
  YURT = "yurt",
  LOG_CABIN = "log_cabin",
  PENTHOUSE = "penthouse",
  FERRY = "ferry",
  GUEST_FARM = "guest_farm",
  GUEST_HOUSE_LIMITED_SERVICE = "guest_house_limited_service",
  HOLIDAY_RESORT = "holiday_resort",
  HOSTEL = "hostel",
  HOTEL = "hotel",
  INN = "inn",
  LODGE = "lodge",
  MEETING_RESORT = "meeting_resort",
  MOBILE_HOME = "mobile_home",
  MONASTERY = "monastry",
  MOTEL = "motel",
  RANCH = "ranch",
  RESIDENTIAL_APARTMENT = "residential_apartment",
  APARTMENT = "apartment",
  RESORT = "resort",
  SAILING_SHIP = "sailing_ship",
  SELF_CATERING_ACCOMMODATION = "self_catering_accomodation",
  TENT = "tent",
  VACATION_HOME = "vacation_home",
  VILLA = "villa",
  WILDLIFE_RESERVE = "wildlife_reserve",
  CASTLE = "castle",
  BED_AND_BREAKFAST = "bed_and_breakfast",
  PENSION = "pension",
  SKI_CHALET = "ski_chalet",
  BOATEL = "boatel",
  BOUTIQUE = "boutique",
  EFFICIENCY_STUDIO = "efficiency",
  CABIN_OR_BUNGALOW = "cabin",
  RECREATIONAL_VEHICLE_PARK = "recreational_vehicle_park",
  CHARM_HOTEL = "charm_hotel",
  MANOR = "manor",
  CAMPGROUND= "campground",
  CHALET = "chalet",
  CONDOMINIUM = "condominium"
}

export enum MaintainenceCostExceed {
  $500 = 500,
  $700 = 700,
  $1000 = 1000,
  all = 0,
}

export enum ListingCoffeeMakerType {
  DRIP = "drip",
  FRENCH_PRESS = "french_press",
  KEURIG = "keurig",
  EXPRESSO = "expresso",
}

export enum ListingInstantBookVisibility {
  GOVERNMENT_ISSUED_ID = "government_id",
  RECOMMENDED_BY_OTHER_HOSTS = "experienced",
  GOVERNMENT_ISSUED_ID_AND_RECOMMENDED_BY_OTHER_HOSTS = "experienced_guest_with_government_id",
  EVERYONE = "everyone",
  OFF = "off"
}

export enum ListingFrequency {
  EVERY = "every",
  EVERY_OTHER = "every_other",
  EVERY_THIRD = "every_third"
}

export enum ListingDDCategory {
  ACTIVE_LISTINGS = "active_listings",
  INACTIVE_LISTINGS = "inactive_listings",
  UNLISTED_LISTINGS = "unlisted_listings",
  LISTED_LISTINGS = "listed_listings",
}

export enum ListingSortBy {
  CITY = "city",
  TITLE = "title",
  START_DATE = "start_date",
  CREATE_DATE = "created_at",
  GO_LIVE_DATE = "go_live_date"
}

export enum ListingApartmentType {
  ENTIRE_HOME = "entire_home",
  PRIVATE_ROOM = "private_room",
  SHARED_ROOM = "shared_room",
}

export enum ListingBedType {
  KING_BED = "king_bed",
  QUEEN_BED = "queen_bed",
  DOUBLE_BED = "double_bed",
  SINGLE_BED = "single_bed",
  SOFA_BED = "sofa_bed",
  COUCH = "couch",
  CRIB = "cribs",
  AIR_MATRRESS = "air_mattress",
  BUNK_BED = "bunk_bed",
  FLOOR_MATTRESS = "floor_mattress",
  TODDLER_BED = "toddler_bed",
  HAMMOCK = "hammock",
  WATER_BED = "water_bed"
}

export enum ListingBedBookingPalType {
  KING_BED = "king_bed",
  QUEEN_BED = "queen_bed",
  SOFA_BED = "sofa_bed",
  TWIN_BED = "twin_bed",
  FUTON = "futon",
  MURPHY_BED = "murphy_bed",
  SINGLE_BED = "single_bed",
  DOUBLE_BED = "double_bed",
  BUNK_BED = "bunk_bed",
  CRIB = "cribs",
  EXTRA_BED = "extra_bed",
  COUCH = "couch",
  AIR_MATRRESS = "air_mattress",
  FLOOR_MATTRESS = "floor_mattress",
  TODDLER_BED = "toddler_bed",
  HAMMOCK = "hammock"
}

export enum ListingErrorReason {
  REQUIRED = "required",
  MAX_NOT_MET = "max_not_met",
  MIN_NOT_MET = "min_not_met",
  START_WITH_NUMBER = "start_with_number",
  INVALID = "invalid"
}

export enum ListingErrorType {
  ERROR = "error",
  WARNING = "warning"
}

export enum ListingReportError {
  SUMMARY = "summary",
  THE_SPACE = "the_space",
  HEADLINE = "headline",
  OWN_STORY = "owner_story",
  TITLE = "title",
  STREET = "street",
  BUILDING = "building",
  CITY = "city",
  STATE = "state",
  COUNTRY_CODE = "country_code",
  PINCODE = "pincode",
  LAT = "lat",
  LNG = "lng",
  GETTING_AROUND = "getting_around",
  BATHROOMS = "bathrooms"
}

export enum ListingLogType {
  LISTING_CREATED = "listing_created",
  LISTING_SENT_FOR_APPROVAL = "listing_sent_for_approval",
  LISTING_ACCEPTED = "listing_accepted",
  LISTING_REJECTED = "listing_rejected",
  LISTING_DEACTIVATED = "listing_deactivated",
  LISTING_REACTIVATED = "listing_reactivated",
  LISTING_AIRBNB_ACTIVATED = "listing_airbnb_activated",
  LISTING_AIRBNB_INACTIVATED = "listing_airbnb_inactivated",
  LISTING_HOMEAWAY_ACTIVATED = "listing_homeaway_activated",
  LISTING_HOMEAWAY_INACTIVATED = "listing_homeaway_inactivated",
  LISTING_ASSIGNEE_UPDATED = "listing_assignee_updated",
  LISTING_MARKET_INFO_UPDATED = "listing_market_info_updated",
  LISTING_DETAILS_UPDATED = "listing_details_updated",
  LISTING_LOCATION_UPDATED = "listing_location_updated",
  LISTING_PROPERTY_ACCESS_UPDATED = "listing_property_access_updated",
  LISTING_GUEST_RULE_UPDATED = "listing_guest_rule_updated",
  LISTING_PRICING_UPDATED = "listing_pricing_updated",
  LISTING_AMENITIES_UPDATED = "listing_amenities_updated",
  LISTING_AVAILABILITY_RULE_UPDATED = "listing_availability_rule_updated",
  LISTING_OWNER_BLOCK_ADDED = "listing_owner_block_added",
  LISTING_OWNER_BLOCK_REMOVED = "listing_owner_block_removed",
  LISTING_AUTOMATION_UPDATED = "listing_automation_updated",
  LISTING_IMAGE_ADDED = "listing_image_added",
  LISTING_IMAGE_REMOVED = "listing_image_removed",
  LISTING_CONTACT_ATTACHED = "listing_contact_attached",
  LISTING_CONTACT_DETACHED = "listing_contact_detached",
  LISTING_FUTURE_BOOKING_LIMIT_UPDATED = "listing_future_booking_limit_updated",
  LISTING_STAYDUVET_ACTIVATED = "listing_stayduvet_activated",
  LISTING_STAYDUVET_INACTIVATED = "listing_stayduvet_inactivated",
  LISTING_FEATUERED_ENABLED = "listing_featured_enabled",
  LISTING_FEATURED_DISABLED = "listing_featured_disabled",
  LISTING_AIRBNB_PLUS_ENABLED = "listing_airbnb_plus_enabled",
  LISTING_AIRBNB_PLUS_DISABLED = "listing_airbnb_plus_disabled",
  LISTING_STARRED_ENABLED = "listing_starred_enabled",
  LISTING_STARRED_DISABLED = "listing_starred_disabled",
}

export enum GuestInteractionTypes {
  HOST_INTERACTION_SOCIAL_HOST = "host_interaction_social_host",
  HOST_INTERACTION_AVAILABLE_HOST = "host_interaction_available_host",
  HOST_INTERACTION_REMOTE_HOST = "host_interaction_remote_host"
}

export enum DedicatedGuestSpaceType {
  FOR_GUEST = "keep_personal_belonging_here",
  NOT_FOR_GUEST = "not_keep_personal_belonging_here",
}

export enum ImageTag {
  NUMBER_1 = "1",
NUMBER_2 = "2",
NUMBER_3 = "3",
NUMBER_4 = "4",
NUMBER_5 = "5",
NUMBER_6 = "6",
NUMBER_7 = "7",
NUMBER_8 = "8",
NUMBER_10 = "10",
NUMBER_43 = "43",
NUMBER_11 = "11",
NUMBER_13 = "13",
NUMBER_14 = "14",
NUMBER_37 = "37",
NUMBER_41 = "41",
NUMBER_42 = "42",
NUMBER_50 = "50",
NUMBER_55 = "55",
NUMBER_61 = "61",
NUMBER_70 = "70",

NUMBER_74 = "74",

NUMBER_81 = "81",

NUMBER_82 = "82",

NUMBER_87 = "87",

NUMBER_89 = "89",

NUMBER_90 = "90",

NUMBER_94 = "94",

NUMBER_95 = "95",

NUMBER_96 = "96",

NUMBER_97 = "97",

NUMBER_100 = "100",

NUMBER_102 = "102",

NUMBER_103 = "103",

NUMBER_104 = "104",

NUMBER_106 = "106",

NUMBER_107 = "107",

NUMBER_141 = "141",

NUMBER_108 = "108",

NUMBER_112 = "112",

NUMBER_113 = "113",

NUMBER_114 = "114",

NUMBER_115 = "115",

NUMBER_116 = "116",

NUMBER_124 = "124",

NUMBER_125 = "125",

NUMBER_128 = "128",

NUMBER_131 = "131",

NUMBER_137 = "137",

NUMBER_133 = "133",

NUMBER_134 = "134",

NUMBER_143 = "143",

NUMBER_153 = "153",

NUMBER_154 = "154",

NUMBER_155 = "155",

NUMBER_156 = "156",

NUMBER_157 = "157",

NUMBER_158 = "158",

NUMBER_159 = "159",

NUMBER_160 = "160",

NUMBER_161 = "161",

NUMBER_164 = "164",

NUMBER_165 = "165",

NUMBER_167 = "167",

NUMBER_172 = "172",

NUMBER_173 = "173",

NUMBER_177 = "177",

NUMBER_178 = "178",

NUMBER_179 = "179",

NUMBER_182 = "182",

NUMBER_183 = "183",

NUMBER_184 = "184",

NUMBER_185 = "185",

NUMBER_186 = "186",

NUMBER_187 = "187",

NUMBER_188 = "188",

NUMBER_189 = "189",

NUMBER_190 = "190",

NUMBER_191 = "191",

NUMBER_192 = "192",
NUMBER_193 = "193",

NUMBER_194 = "194",

NUMBER_197 = "197",

NUMBER_198 = "198",

NUMBER_199 = "199",

NUMBER_204 = "204",

NUMBER_205 = "205",

NUMBER_240 = "240",

NUMBER_241 = "241",

NUMBER_242 = "242",

NUMBER_245 = "245",

NUMBER_246 = "246",

NUMBER_247 = "247",

NUMBER_248 = "248",

NUMBER_249 = "249",

NUMBER_250 = "250",

NUMBER_251 = "251",

NUMBER_252 = "252",

NUMBER_253 = "253",

NUMBER_254 = "254",

NUMBER_255 = "255",

NUMBER_256 = "256",

NUMBER_257 = "257",

NUMBER_258 = "258",

NUMBER_259 = "259",

NUMBER_260 = "260",

NUMBER_261 = "261",

NUMBER_262 = "262",

NUMBER_263 = "263",

NUMBER_264 = "264",

NUMBER_265 = "265",

NUMBER_266 = "266",

NUMBER_267 = "267",

NUMBER_268 = "268",

NUMBER_269 = "269",

NUMBER_270 = "270",

NUMBER_271 = "271",

NUMBER_272 = "272",

NUMBER_273 = "273",

NUMBER_274 = "274",

NUMBER_275 = "275",

NUMBER_276 = "276",

NUMBER_277 = "277",

NUMBER_278 = "278",

NUMBER_279 = "279",

NUMBER_280 = "280",

NUMBER_281 = "281",

NUMBER_282 = "282",

NUMBER_283 = "283",

NUMBER_284 = "284",

NUMBER_285 = "285",

NUMBER_286 = "286",

NUMBER_287 = "287",

NUMBER_288 = "288",

NUMBER_289 = "289",

NUMBER_290 = "290",

NUMBER_291 = "291",
}



export class ListingEnumHelpers {

  static getImageTagTitle(tag: ImageTag): string {
    switch (tag) {
      case ImageTag.NUMBER_1: {
        return "Shower";
      }
      case ImageTag.NUMBER_2: {
        return "Toilet";
      }
      case ImageTag.NUMBER_3: {
        return "Property building";
      }
      case ImageTag.NUMBER_4: {
        return "patio";
      }
      case ImageTag.NUMBER_5: {
        return "Nearby landmark";
      }
      case ImageTag.NUMBER_6: {
        return "Staff";
      }
      case ImageTag.NUMBER_7: {
        return "Restaurant/places to eat";
      }
      case ImageTag.NUMBER_8: {
        return "Communal lounge/ TV room";
      }
      case ImageTag.NUMBER_10: {
        return "Facade/entrance";
      }
      case ImageTag.NUMBER_43: {
        return "People";
      }
      case ImageTag.NUMBER_11: {
        return "Spring";
      }
      case ImageTag.NUMBER_13: {
        return "Bed";
      }
      case ImageTag.NUMBER_14: {
        return "Off site";
      }
      case ImageTag.NUMBER_37: {
        return "Food close-up";
      }
      case ImageTag.NUMBER_41: {
        return "Day";
      }
      case ImageTag.NUMBER_42: {
        return "Night";
      }
      case ImageTag.NUMBER_50: {
        return "Property logo or sign";
      }
      case ImageTag.NUMBER_55: {
        return "Neighbourhood";
      }
      case ImageTag.NUMBER_61: {
        return "Natural landscape";
      }
      case ImageTag.NUMBER_70: {
        return "Activities";
      }
      case ImageTag.NUMBER_74: {
        return "Bird's eye view";
      }
      case ImageTag.NUMBER_81: {
        return "Winter";
      }
      case ImageTag.NUMBER_82: {
        return "Summer";
      }
      case ImageTag.NUMBER_87: {
        return "BBQ facilities";
      }
      case ImageTag.NUMBER_89: {
        return "Billiard";
      }
      case ImageTag.NUMBER_90: {
        return "Bowling";
      }
      case ImageTag.NUMBER_94: {
        return "Casino";
      }
      case ImageTag.NUMBER_95: {
        return "Place of worship";
      }
      case ImageTag.NUMBER_96: {
        return "Children play ground";
      }
      case ImageTag.NUMBER_97: {
        return "Darts";
      }
      case ImageTag.NUMBER_100: {
        return "Fishing";
      }
      case ImageTag.NUMBER_102: {
        return "Game Room";
      }
      case ImageTag.NUMBER_103: {
        return "Garden";
      }
      case ImageTag.NUMBER_104: {
        return "Golf course";
      }
      case ImageTag.NUMBER_106: {
        return "Horse-riding";
      }
      case ImageTag.NUMBER_107: {
        return "Hot Spring Bath";
      }
      case ImageTag.NUMBER_141: {
        return "Table tennis";
      }
      case ImageTag.NUMBER_108: {
        return "Hot Tub";
      }
      case ImageTag.NUMBER_112: {
        return "Karaoke";
      }
      case ImageTag.NUMBER_113: {
        return "Library";
      }
      case ImageTag.NUMBER_114: {
        return "Massage";
      }
      case ImageTag.NUMBER_115: {
        return "Minigolf";
      }
      case ImageTag.NUMBER_116: {
        return "Nightclub / DJ";
      }
      case ImageTag.NUMBER_124: {
        return "Sauna";
      }
      case ImageTag.NUMBER_125: {
        return "On-site shops";
      }
      case ImageTag.NUMBER_128: {
        return "Ski School";
      }
      case ImageTag.NUMBER_131: {
        return "Skiing";
      }
      case ImageTag.NUMBER_137: {
        return "Squash";
      }
      case ImageTag.NUMBER_133: {
        return "Snorkeling";
      }
      case ImageTag.NUMBER_134: {
        return "Solarium";
      }
      case ImageTag.NUMBER_143: {
        return "Steam room";
      }
      case ImageTag.NUMBER_153: {
        return "Bathroom";
      }
      case ImageTag.NUMBER_154: {
        return "TV and multimedia";
      }
      case ImageTag.NUMBER_155: {
        return "Coffee/tea facilities";
      }
      case ImageTag.NUMBER_156: {
        return "View (from property/room)";
      }
      case ImageTag.NUMBER_157: {
        return "Balcony/Terrace";
      }
      case ImageTag.NUMBER_158: {
        return "Kitchen or kitchenette";
      }
      case ImageTag.NUMBER_159: {
        return "Living room";
      }
      case ImageTag.NUMBER_160: {
        return "Lobby or reception";
      }
      case ImageTag.NUMBER_161: {
        return "Lounge or bar";
      }
      case ImageTag.NUMBER_164: {
        return "Spa and wellness centre/facili";
      }
      case ImageTag.NUMBER_165: {
        return "Fitness centre/facilities";
      }
      case ImageTag.NUMBER_167: {
        return "Food and drinks";
      }
      case ImageTag.NUMBER_172: {
        return "Other";
      }
      case ImageTag.NUMBER_173: {
        return "Photo of the whole room";
      }
      case ImageTag.NUMBER_177: {
        return "Business facilities";
      }
      case ImageTag.NUMBER_178: {
        return "Banquet/Function facilities";
      }
      case ImageTag.NUMBER_179: {
        return "Decorative detail";
      }
      case ImageTag.NUMBER_182: {
        return "Seating area";
      }
      case ImageTag.NUMBER_183: {
        return "Floor plan";
      }
      case ImageTag.NUMBER_184: {
        return "Dining area";
      }
      case ImageTag.NUMBER_185: {
        return "Beach";
      }
      case ImageTag.NUMBER_186: {
        return "Aqua park";
      }
      case ImageTag.NUMBER_187: {
        return "Tennis court";
      }
      case ImageTag.NUMBER_188: {
        return "Windsurfing";
      }
      case ImageTag.NUMBER_189: {
        return "Canoeing";
      }
      case ImageTag.NUMBER_190: {
        return "Hiking";
      }
      case ImageTag.NUMBER_191: {
        return "Cycling";
      }
      case ImageTag.NUMBER_192: {
        return "Diving";
      }
      case ImageTag.NUMBER_193: {
        return "Kids's club";
      }
      case ImageTag.NUMBER_194: {
        return "Evening entertainment";
      }
      case ImageTag.NUMBER_197: {
        return "Logo/Certificate/Sign";
      }
      case ImageTag.NUMBER_198: {
        return "Animals";
      }
      case ImageTag.NUMBER_199: {
        return "Bedroom";
      }
      case ImageTag.NUMBER_204: {
        return "Communal kitchen";
      }
      case ImageTag.NUMBER_205: {
        return "Autumn";
      }
      case ImageTag.NUMBER_240: {
        return "On site";
      }
      case ImageTag.NUMBER_241: {
        return "Meeting/conference room";
      }
      case ImageTag.NUMBER_242: {
        return "Food";
      }
      case ImageTag.NUMBER_245: {
        return "Text overlay";
      }
      case ImageTag.NUMBER_246: {
        return "Pets";
      }
      case ImageTag.NUMBER_247: {
        return "Guests";
      }
      case ImageTag.NUMBER_248: {
        return "City view";
      }
      case ImageTag.NUMBER_249: {
        return "Garden view";
      }
      case ImageTag.NUMBER_250: {
        return "Lake view";
      }
      case ImageTag.NUMBER_251: {
        return "Landmark view";
      }
      case ImageTag.NUMBER_252: {
        return "Mountain view";
      }
      case ImageTag.NUMBER_253: {
        return "Pool view";
      }
      case ImageTag.NUMBER_254: {
        return "River view";
      }
      case ImageTag.NUMBER_255: {
        return "Sea view";
      }
      case ImageTag.NUMBER_256: {
        return "Street view";
      }
      case ImageTag.NUMBER_257: {
        return "Area and facilities";
      }
      case ImageTag.NUMBER_258: {
        return "Supermarket/grocery shop";
      }
      case ImageTag.NUMBER_259: {
        return "Shopping Area";
      }
      case ImageTag.NUMBER_260: {
        return "Swimming pool";
      }
      case ImageTag.NUMBER_261: {
        return "Sports";
      }
      case ImageTag.NUMBER_262: {
        return "Entertainment";
      }
      case ImageTag.NUMBER_263: {
        return "Meals";
      }
      case ImageTag.NUMBER_264: {
        return "Breakfast";
      }
      case ImageTag.NUMBER_265: {
        return "Continental breakfast";
      }
      case ImageTag.NUMBER_266: {
        return "Buffet breakfast";
      }
      case ImageTag.NUMBER_267: {
        return "Asian breakfast";
      }
      case ImageTag.NUMBER_268: {
        return "Italian breakfast";
      }
      case ImageTag.NUMBER_269: {
        return "English/Irish breakfast";
      }
      case ImageTag.NUMBER_270: {
        return "American breakfast";
      }
      case ImageTag.NUMBER_271: {
        return "Lunch";
      }
      case ImageTag.NUMBER_272: {
        return "Dinner";
      }
      case ImageTag.NUMBER_273: {
        return "Drinks";
      }
      case ImageTag.NUMBER_274: {
        return "Alcoholic drinks";
      }
      case ImageTag.NUMBER_275: {
        return "Non alcoholic drinks";
      }
      case ImageTag.NUMBER_276: {
        return "Seasons";
      }
      case ImageTag.NUMBER_277: {
        return "Time of day";
      }
      case ImageTag.NUMBER_278: {
        return "Location";
      }
      case ImageTag.NUMBER_279: {
        return "Sunrise";
      }
      case ImageTag.NUMBER_280: {
        return "Sunset";
      }
      case ImageTag.NUMBER_281: {
        return "children";
      }
      case ImageTag.NUMBER_282: {
        return "young children";
      }
      case ImageTag.NUMBER_283: {
        return "older children";
      }
      case ImageTag.NUMBER_284: {
        return "group of guests";
      }
      case ImageTag.NUMBER_285: {
        return "cot";
      }
      case ImageTag.NUMBER_286: {
        return "bunk bed";
      }
      case ImageTag.NUMBER_287: {
        return "Certificate/Award";
      }
      case ImageTag.NUMBER_288: {
        return "ADAM";
      }
      case ImageTag.NUMBER_289: {
        return "Open Air Bath";
      }
      case ImageTag.NUMBER_290: {
        return "Public Bath";
      }
      case ImageTag.NUMBER_291: {
        return "Family";
      }
    }
  }

  static getListingReportTitle(report: ListingReportError): string {
    let title = "";

    switch (report) {
      case ListingReportError.SUMMARY: {
        title = "Summary";
        break;
      }
      case ListingReportError.THE_SPACE: {
        title = "The Space";
        break;
      }
      case ListingReportError.HEADLINE: {
        title = "Headline";
        break;
      }
      case ListingReportError.TITLE: {
        title = "Title";
        break;
      }
      case ListingReportError.OWN_STORY: {
        title = "Owner\'s Story";
        break;
      }
      case ListingReportError.STREET: {
        title = "Street";
        break;
      }
      case ListingReportError.BUILDING: {
        title = "Building";
        break;
      }
      case ListingReportError.CITY: {
        title = "City";
        break;
      }
      case ListingReportError.STATE: {
        title = "State";
        break;
      }
      case ListingReportError.COUNTRY_CODE: {
        title = "Country Code";
        break;
      }
      case ListingReportError.PINCODE: {
        title = "Pincode";
        break;
      }
      case ListingReportError.LAT: {
        title = "Latitude";
        break;
      }
      case ListingReportError.LNG: {
        title = "Longitude";
        break;
      }
      case ListingReportError.GETTING_AROUND: {
        title = "Getting Around";
        break;
      }
      case ListingReportError.BATHROOMS: {
        title = "Bathrooms";
        break;
      }

    }
    return title;
  }

  static getPropertyTypeTitle(type: PropertyType): string {
    let title = "";

    switch (type) {
      case PropertyType.HOUSE: {
        title = "House";
        break;
      }
      case PropertyType.GUESTHOUSE: {
        title = "Guest House";
        break;
      }
      case PropertyType.MINSU: {
        title = "Minsu";
        break;
      }
      case PropertyType.BUNGALOW: {
        title = "Bungalow";
        break;
      }
      case PropertyType.COTTAGE: {
        title = "Cottage";
        break;
      }
      case PropertyType.SHEPHERD_HUT: {
        title = "Shepherd Hut";
        break;
      }
      case PropertyType.TIPI: {
        title = "Tipi";
        break;
      }
      case PropertyType.TREE_HOUSE: {
        title = "Tree house";
        break;
      }
      case PropertyType.TRULLO: {
        title = "Trullo";
        break;
      }
      case PropertyType.WINDMILL: {
        title = "Windmill";
        break;
      }
      case PropertyType.YURT: {
        title = "Yurt";
        break;
      }
      case PropertyType.APARTMENT: {
        title = "Apartment";
        break;
      }
      case PropertyType.TENT: {
        title = "Tent";
        break;
      }
      case PropertyType.VILLA: {
        title = "Villa";
        break;
      }
      case PropertyType.CASTLE: {
        title = "Castle";
        break;
      }
      case PropertyType.PENSION: {
        title = "Pension";
        break;
      }
      case PropertyType.CABIN_OR_BUNGALOW: {
        title = "Cabin Or Bungalow";
        break;
      }
      case PropertyType.CHALET: {
        title = "Chalet";
        break;
      }
    }

    return title;
  }

  static getPropertyTypeBookingPalTitle(type: PropertyTypeBookingPal): string {
    let title = "";

    switch (type) {
      case PropertyTypeBookingPal.TOWNHOME: {
        title = "Townhome";
        break;
      }
      case PropertyTypeBookingPal.SINGLE_ROOM: {
        title = "Single Room";
        break;
      }
      case PropertyTypeBookingPal.TWIN: {
        title = "Twin";
        break;
      }
      case PropertyTypeBookingPal.DOUBLE_ROOM: {
        title = "Double Room";
        break;
      }
      case PropertyTypeBookingPal.TWIN_DOUBLE: {
        title = "Twin/Double";
        break;
      }
      case PropertyTypeBookingPal.TRIPLE_ROOM: {
        title = "Triple Room";
        break;
      }
      case PropertyTypeBookingPal.QUADRUPLE: {
        title = "Quadruple";
        break;
      }
      case PropertyTypeBookingPal.FAMILY: {
        title = "Family";
        break;
      }
      case PropertyTypeBookingPal.SUITE: {
        title = "Suite";
        break;
      }
      case PropertyTypeBookingPal.STUDIO: {
        title = "Studio";
        break;
      }
      case PropertyTypeBookingPal.BUNGALOW: {
        title = "Bungalow";
        break;
      }
      case PropertyTypeBookingPal.PRIVATE_ROOM: {
        title = "Private Room";
        break;
      }
      case PropertyTypeBookingPal.SHARED_ROOM: {
        title = "Shared Room";
        break;
      }
      case PropertyTypeBookingPal.COTTAGE: {
        title = "Cottage";
        break;
      }
      case PropertyTypeBookingPal.APART_HOTEL: {
        title = "Apart Hotel";
        break;
      }
      case PropertyTypeBookingPal.NARROW_BOAT: {
        title = "Narrow Boat";
        break;
      }
      case PropertyTypeBookingPal.RIAD: {
        title = "Riad";
        break;
      }
      case PropertyTypeBookingPal.SHEPHERD_HUT: {
        title = "Shepherd Hut";
        break;
      }
      case PropertyTypeBookingPal.TIPI: {
        title = "Tipi";
        break;
      }
      case PropertyTypeBookingPal.CRUISE: {
        title = "Cruise";
        break;
      }
      case PropertyTypeBookingPal.TOWER: {
        title = "Tower";
        break;
      }
      case PropertyTypeBookingPal.TREE_HOUSE: {
        title = "Tree House";
        break;
      }
      case PropertyTypeBookingPal.TRULLO: {
        title = "Trullo";
        break;
      }
      case PropertyTypeBookingPal.WATERMILL: {
        title = "Watermill";
        break;
      }
      case PropertyTypeBookingPal.WINDMILL: {
        title = "Windmill";
        break;
      }
      case PropertyTypeBookingPal.YACHT: {
        title = "Yacht";
        break;
      }
      case PropertyTypeBookingPal.YURT: {
        title = "Yurt";
        break;
      }
      case PropertyTypeBookingPal.LOG_CABIN: {
        title = "Log Cabin";
        break;
      }
      case PropertyTypeBookingPal.PENTHOUSE: {
        title = "Penthouse";
        break;
      }
      case PropertyTypeBookingPal.FERRY: {
        title = "Ferry";
        break;
      }
      case PropertyTypeBookingPal.GUEST_FARM: {
        title = "Guest Farm";
        break;
      }
      case PropertyTypeBookingPal.GUEST_HOUSE_LIMITED_SERVICE: {
        title = "Guest House Limited Service";
        break;
      }
      case PropertyTypeBookingPal.HOLIDAY_RESORT: {
        title = "Holiday Resort";
        break;
      }
      case PropertyTypeBookingPal.HOSTEL: {
        title = "Hostel";
        break;
      }
      case PropertyTypeBookingPal.HOTEL: {
        title = "Hotel";
        break;
      }
      case PropertyTypeBookingPal.INN: {
        title = "Inn";
        break;
      }
      case PropertyTypeBookingPal.LODGE: {
        title = "Lodge";
        break;
      }
      case PropertyTypeBookingPal.MEETING_RESORT: {
        title = "Meeting Resort";
        break;
      }
      case PropertyTypeBookingPal.MOBILE_HOME: {
        title = "Mobile-home";
        break;
      }
      case PropertyTypeBookingPal.MONASTERY: {
        title = "Monastery";
        break;
      }
      case PropertyTypeBookingPal.MOTEL: {
        title = "Motel";
        break;
      }
      case PropertyTypeBookingPal.RANCH: {
        title = "Ranch";
        break;
      }
      case PropertyTypeBookingPal.RESIDENTIAL_APARTMENT: {
        title = "Residential Apartment";
        break;
      }
      case PropertyTypeBookingPal.APARTMENT: {
        title = "Apartment";
        break;
      }
      case PropertyTypeBookingPal.RESORT: {
        title = "Resort";
        break;
      }
      case PropertyTypeBookingPal.SAILING_SHIP: {
        title = "Sailing Ship";
        break;
      }
      case PropertyTypeBookingPal.SELF_CATERING_ACCOMMODATION: {
        title = "Self Catering Accommodation";
        break;
      }
      case PropertyTypeBookingPal.TENT: {
        title = "Tent";
        break;
      }
      case PropertyTypeBookingPal.VACATION_HOME: {
        title = "Vacation Home";
        break;
      }
      case PropertyTypeBookingPal.VILLA: {
        title = "Villa";
        break;
      }
      case PropertyTypeBookingPal.WILDLIFE_RESERVE: {
        title = "Wildlife Reserve";
        break;
      }
      case PropertyTypeBookingPal.CASTLE: {
        title = "Castle";
        break;
      }
      case PropertyTypeBookingPal.BED_AND_BREAKFAST: {
        title = "Bed And Breakfast";
        break;
      }
      case PropertyTypeBookingPal.PENSION: {
        title = "Pension";
        break;
      }
      case PropertyTypeBookingPal.SKI_CHALET: {
        title = "Ski Chalet";
        break;
      }
      case PropertyTypeBookingPal.BOATEL: {
        title = "Boatel";
        break;
      }
      case PropertyTypeBookingPal.BOUTIQUE: {
        title = "Boutique";
        break;
      }
      case PropertyTypeBookingPal.EFFICIENCY_STUDIO: {
        title = "Efficiency/Studio";
        break;
      }
      case PropertyTypeBookingPal.CABIN_OR_BUNGALOW: {
        title = "Cabin Or Bungalow";
        break;
      }
      case PropertyTypeBookingPal.RECREATIONAL_VEHICLE_PARK: {
        title = "Recreational Vehicle Park";
        break;
      }
      case PropertyTypeBookingPal.CHARM_HOTEL: {
        title = "Charm Hotel";
        break;
      }
      case PropertyTypeBookingPal.MANOR: {
        title = "Manor";
        break;
      }
      case PropertyTypeBookingPal.CAMPGROUND: {
        title = "Campground";
        break;
      }
      case PropertyTypeBookingPal.CHALET: {
        title = "Chalet";
        break;
      }
      case PropertyTypeBookingPal.CONDOMINIUM: {
        title = "Condominium";
        break;
      }
    }

    return title;
  }

  static getReportErrorTypeTitle(type: ListingErrorType): string {
    let title = "";

    switch (type) {
      case ListingErrorType.ERROR: {
        title = "Error";
        break;
      }
      case ListingErrorType.WARNING: {
        title = "Warning";
        break;
      }
    }
    return title;
  }

  static getReportErrorReasonTitle(reason: ListingErrorReason): string {
    let title = "";

    switch (reason) {
      case ListingErrorReason.REQUIRED: {
        title = "required";
        break;
      }
      case ListingErrorReason.INVALID: {
        title = "invalid";
        break;
      }
      case ListingErrorReason.START_WITH_NUMBER: {
        title = "start with Number";
        break;
      }
      case ListingErrorReason.MIN_NOT_MET: {
        title = "minimum";
        break;
      }
      case ListingErrorReason.INVALID: {
        title = "maximum";
        break;
      }
    }
    return title;
  }


  static getListingCategory(category: ListingCategory): string {
    let title = "";

    switch (category) {

      case ListingCategory.ALL_LISTINGS: {
        title = "All Listings";
        break;
      }

      case ListingCategory.APPROVED_LISTINGS: {
        title = "Accepted Listings";
        break;
      }

      case ListingCategory.WAITING_LISTINGS: {
        title = "Waiting Listings";
        break;
      }

      case ListingCategory.REJECTED_LISTINGS: {
        title = "Rejected Listings";
        break;
      }

      case ListingCategory.DRAFT_LISTINGS: {
        title = "Draft Listings";
        break;
      }

      case ListingCategory.DEACTIVATED_LISTINGS: {
        title = "Deactivated Listings";
        break;
      }
    }

    return title;
  }

  static getListingDDCategoryTitle(category: ListingDDCategory): string {
    let title = "";

    switch (category) {
      case ListingDDCategory.ACTIVE_LISTINGS : {
        title = "Active Listings";
        break;
      }

      case ListingDDCategory.INACTIVE_LISTINGS : {
        title = "Inactive Listings";
        break;
      }

      case ListingDDCategory.UNLISTED_LISTINGS : {
        title = "Unlisted Listings";
        break;
      }

      case ListingDDCategory.LISTED_LISTINGS : {
        title = "Listed Listings";
        break;
      }

      default: {
        title = "Auto Approve";
        break;
      }
    }

    return title;
  }

  static getListingSortByTitle(category: ListingSortBy): string {
    let title = "";

    switch (category) {
      case ListingSortBy.CITY : {
        title = "City";
        break;
      }

      case ListingSortBy.TITLE : {
        title = "Title";
        break;
      }

      case ListingSortBy.START_DATE : {
        title = "Start Date";
        break;
      }

      case ListingSortBy.CREATE_DATE : {
        title = "Create Date";
        break;
      }

      case ListingSortBy.GO_LIVE_DATE : {
        title = "Go Live Date";
        break;
      }

      default: {
        break;
      }
    }

    return title;
  }

  static getListingInstantBookVisibilityTitle(category: ListingInstantBookVisibility) {
    let title: string;
    switch (category) {
      case ListingInstantBookVisibility.GOVERNMENT_ISSUED_ID : {
        title = "Government-issued ID";
        break;
      }

      case ListingInstantBookVisibility.RECOMMENDED_BY_OTHER_HOSTS : {
        title = "Recommended by other hosts";
        break;
      }

      case ListingInstantBookVisibility.GOVERNMENT_ISSUED_ID_AND_RECOMMENDED_BY_OTHER_HOSTS : {
        title = "Government-issued ID and recommended by other hosts";
        break;
      }

      case ListingInstantBookVisibility.EVERYONE : {
        title = "Everyone";
        break;
      }

      case ListingInstantBookVisibility.OFF : {
        title = "Off";
        break;
      }
    }
    return title;
  }

  static getListingApartmentType(type: ListingApartmentType): string {
    let title = "";

    switch (type) {
      case ListingApartmentType.ENTIRE_HOME : {
        title = "Entire Home";
        break;
      }

      case ListingApartmentType.PRIVATE_ROOM : {
        title = "Private Room";
        break;
      }

      case ListingApartmentType.SHARED_ROOM : {
        title = "Shared Room";
        break;
      }
    }

    return title;
  }


  static getListingBedType(category: ListingBedType): string {
    let title = "";

    switch (category) {
      case ListingBedType.KING_BED : {
        title = "King Bed";
        break;
      }

      case ListingBedType.QUEEN_BED : {
        title = "Queen Bed";
        break;
      }

      case ListingBedType.DOUBLE_BED : {
        title = "Double Bed";
        break;
      }

      case ListingBedType.SINGLE_BED : {
        title = "Single Bed";
        break;
      }

      case ListingBedType.SOFA_BED : {
        title = "Sofa Bed";
        break;
      }

      case ListingBedType.COUCH : {
        title = "Couch";
        break;
      }

      case ListingBedType.CRIB : {
        title = "Crib";
        break;
      }

      case ListingBedType.AIR_MATRRESS : {
        title = "Air Matrress";
        break;
      }

      case ListingBedType.BUNK_BED : {
        title = "Bunk Bed";
        break;
      }

      case ListingBedType.FLOOR_MATTRESS : {
        title = "Floor Mattress";
        break;
      }

      case ListingBedType.TODDLER_BED : {
        title = "Toddler Bed";
        break;
      }

      case ListingBedType.WATER_BED : {
        title = "Water Bed";
        break;
      }

      case ListingBedType.HAMMOCK : {
        title = "Hammock";
        break;
      }

      default: {
        break;
      }
    }
    return title;
  }

  static getListingBedBookingPalType(category: ListingBedBookingPalType): string {
    let title = "";

    switch (category) {
      case ListingBedBookingPalType.KING_BED : {
        title = "King Bed";
        break;
      }

      case ListingBedBookingPalType.QUEEN_BED : {
        title = "Queen Bed";
        break;
      }

      case ListingBedBookingPalType.SOFA_BED : {
        title = "Sofa Bed";
        break;
      }

      case ListingBedBookingPalType.TWIN_BED: {
        title = "Twin Bed";
        break;
      }

      case ListingBedBookingPalType.FUTON: {
        title = "Futon";
        break;
      }

      case ListingBedBookingPalType.MURPHY_BED: {
        title = "Murphy Bed";
        break;
      }

      case ListingBedBookingPalType.EXTRA_BED: {
        title = "Extra Bed";
        break;
      }

      case ListingBedBookingPalType.DOUBLE_BED : {
        title = "Double Bed";
        break;
      }

      case ListingBedBookingPalType.SINGLE_BED : {
        title = "Single Bed";
        break;
      }

      case ListingBedBookingPalType.COUCH : {
        title = "Couch";
        break;
      }

      case ListingBedBookingPalType.CRIB : {
        title = "Crib";
        break;
      }

      case ListingBedBookingPalType.AIR_MATRRESS : {
        title = "Air Matrress";
        break;
      }

      case ListingBedBookingPalType.BUNK_BED : {
        title = "Bunk Bed";
        break;
      }

      case ListingBedBookingPalType.FLOOR_MATTRESS : {
        title = "Floor Mattress";
        break;
      }

      case ListingBedBookingPalType.TODDLER_BED : {
        title = "Toddler Bed";
        break;
      }

      case ListingBedBookingPalType.HAMMOCK : {
        title = "Hammock";
        break;
      }

      default: {
        break;
      }
    }
    return title;
  }

  static getListingLogTitle(category: ListingLogType): { message: string, title: string, color: string } {
    let message = "";
    let title = "";
    let color = "";

    switch (category) {
      case ListingLogType.LISTING_CREATED : {
        message = "Listing Created";
        title = "Created";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_SENT_FOR_APPROVAL : {
        message = "Listing Sent For Approval";
        title = "Approval";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_ACCEPTED : {
        message = "Listing Accepted";
        title = "Accepted";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_REJECTED : {
        message = "Listing Rejected";
        title = "Rejected";
        color = "#FF0000";
        break;
      }
      case ListingLogType.LISTING_DEACTIVATED : {
        message = "Listing Deactivated";
        title = "Deactivated";
        color = "#FF0000";
        break;
      }
      case ListingLogType.LISTING_REACTIVATED : {
        message = "Listing Reactivated";
        title = "Reactivated";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_AIRBNB_ACTIVATED : {
        message = "Listing Airbnb Activated";
        title = "Activated";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_AIRBNB_INACTIVATED : {
        message = "Listing Airbnb Inactivated";
        title = "Inactivated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_HOMEAWAY_ACTIVATED : {
        message = "Listing VRBO Activated";
        title = "Activated";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_HOMEAWAY_INACTIVATED : {
        message = "Listing VRBO Inactivated";
        title = "Inactivated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_STAYDUVET_ACTIVATED : {
        message = "Listing StayDuvet Activated";
        title = "Activated";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_STAYDUVET_INACTIVATED : {
        message = "Listing StayDuvet Inactivated";
        title = "Inactivated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_AIRBNB_PLUS_ENABLED : {
        message = "Listing Airbnb Plus Enabled";
        title = "Enabled";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_AIRBNB_PLUS_DISABLED : {
        message = "Listing Airbnb Plus Disabled";
        title = "Disabled";
        color = "#D17B26";
        break;
      }

      case ListingLogType.LISTING_STARRED_ENABLED : {
        message = "Listing Starred";
        title = "Starred";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_STARRED_DISABLED : {
        message = "Listing Unstarred";
        title = "Unstarred";
        color = "#D17B26";
        break;
      }

      case ListingLogType.LISTING_FEATUERED_ENABLED : {
        message = "Listing Featured Enabled";
        title = "Enabled";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_FEATURED_DISABLED : {
        message = "Listing Feature Disabled";
        title = "Disabled";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_ASSIGNEE_UPDATED : {
        message = "Listing Assignee Updated";
        title = "Updated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_MARKET_INFO_UPDATED : {
        message = "Listing Market Info Updated";
        title = "Updated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_DETAILS_UPDATED : {
        message = "Listing Details Updated";
        title = "Updated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_LOCATION_UPDATED : {
        message = "Listing Location Updated";
        title = "Updated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_PROPERTY_ACCESS_UPDATED : {
        message = "Listing Property Access Updated";
        title = "Updated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_GUEST_RULE_UPDATED : {
        message = "Listing Guest Rule Updated";
        title = "Updated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_AMENITIES_UPDATED : {
        message = "Listing Amenities Updated";
        title = "Updated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_PRICING_UPDATED : {
        message = "Listing Pricing Updated";
        title = "Updated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_AVAILABILITY_RULE_UPDATED : {
        message = "Listing Availability Rule Updated";
        title = "Updated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_OWNER_BLOCK_ADDED : {
        message = "Listing Owner Block Added";
        title = "Added";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_OWNER_BLOCK_REMOVED : {
        message = "Listing Owner Block Removed";
        title = "Removed";
        color = "#FF0000";
        break;
      }
      case ListingLogType.LISTING_AUTOMATION_UPDATED : {
        message = "Listing Automation Updated";
        title = "Updated";
        color = "#D17B26";
        break;
      }
      case ListingLogType.LISTING_IMAGE_ADDED : {
        message = "Listing Image Added";
        title = "Added";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_IMAGE_REMOVED : {
        message = "Listing Image Removed";
        title = "Deactivated";
        color = "#FF0000";
        break;
      }
      case ListingLogType.LISTING_CONTACT_ATTACHED : {
        message = "Listing Contact Attached";
        title = "Attached";
        color = "#4F8A10";
        break;
      }
      case ListingLogType.LISTING_CONTACT_DETACHED : {
        message = "Listing Contact Detached";
        title = "Detached";
        color = "#FF0000";
        break;
      }
      case ListingLogType.LISTING_FUTURE_BOOKING_LIMIT_UPDATED : {
        message = "Listing Future Booking Limit Updated";
        title = "Updated";
        color = "#D17B26";
        break;
      }
      default: {
        break;
      }
    }
    return {message: message, title: title, color: color};
  }

  static getGuestInteractionTypesTitle(type: GuestInteractionTypes): string {
    let title = "";

    switch (type) {
      case GuestInteractionTypes.HOST_INTERACTION_AVAILABLE_HOST: {
        title = "Available";
        break;
      }

      case GuestInteractionTypes.HOST_INTERACTION_REMOTE_HOST : {
        title = "Not Available";
        break;
      }

      case GuestInteractionTypes.HOST_INTERACTION_SOCIAL_HOST : {
        title = "Social";
        break;
      }
    }
    return title;
  }

  static getDedicatedGuestSpaceTypeTitle(spaceType: DedicatedGuestSpaceType): string {
    let title = "";

    switch (spaceType) {
      case DedicatedGuestSpaceType.FOR_GUEST: {
        title = "Yes, it’s primarily set up for guests";
        break;
      }

      case DedicatedGuestSpaceType.NOT_FOR_GUEST :
      default: {
        title = "No, I keep my personal belongings here";
        break;
      }
    }

    return title;
  }

  static getListingStatusTitle(statusType: ListingStatus): string {
    let title = "";

    switch (statusType) {
      case ListingStatus.ACCEPTED: {
        title = "Accepted";
        break;
      }

      case ListingStatus.DRAFT : {
        title = "Draft";
        break;
      }

      case ListingStatus.PENDING : {
        title = "Pending";
        break;
      }

      case ListingStatus.REJECTED : {
        title = "Rejected";
        break;
      }

      default: {
        break;
      }
    }
    return title;
  }

  static getAirbnbCancellationPolicyTitle(policy: AirbnbCancellationPolicy): string {
    switch (policy) {
      case AirbnbCancellationPolicy.AIRBNB_CANCELLATION_POLICY_FLEXIBLE: {
        return "Flexible";
      }
      case AirbnbCancellationPolicy.AIRBNB_CANCELLATION_POLICY_MODERATE: {
        return "Moderate";
      }
      case AirbnbCancellationPolicy.AIRBNB_CANCELLATION_POLICY_STRICT: {
        return "Strict";
      }
    }
  }

  static getAirbnbCancellationPolicyDescription(policy: AirbnbCancellationPolicy): string {
    switch (policy) {
      case AirbnbCancellationPolicy.AIRBNB_CANCELLATION_POLICY_FLEXIBLE: {
        return "Full refund 1 day prior to arrival";
      }
      case AirbnbCancellationPolicy.AIRBNB_CANCELLATION_POLICY_MODERATE: {
        return "Full refund 5 days prior to arrival";
      }
      case AirbnbCancellationPolicy.AIRBNB_CANCELLATION_POLICY_STRICT: {
        return "Full refund 48 hours after booking, up to 14 days before check-in";
      }
    }
  }

  static getHomeawayCancellationPolicyDescription(policy: HomeawayCancellationPolicy): string {
    switch (policy) {
      case HomeawayCancellationPolicy.HOMEAWAY_CANCELLATION_POLICY_RELAXED: {
        return "Travelers who cancel at least 14 days before check-in will get back 100% of the amount they've paid. If they cancel between 7 and 14 days before check-in, they'll get back 50%. Otherwise, they won't get a refund.";
      }
      case HomeawayCancellationPolicy.HOMEAWAY_CANCELLATION_POLICY_MODERATE: {
        return "Travelers who cancel at least 30 days before check-in will get back 100% of the amount they've paid. If they cancel between 14 and 30 days before check-in, they'll get back 50%. Otherwise, they won't get a refund.";
      }
      case HomeawayCancellationPolicy.HOMEAWAY_CANCELLATION_POLICY_FIRM: {
        return "Travelers who cancel at least 60 days before check-in will get back 100% of the amount they've paid. If they cancel between 30 and 60 days before check-in, they'll get back 50%. Otherwise, they won't get a refund.";
      }
      case HomeawayCancellationPolicy.HOMEAWAY_CANCELLATION_POLICY_STRICT: {
        return "Travelers who cancel at least 60 days before check-in will get back 100% of the amount they've paid. If they cancel after that point, they will not get a refund.";
      }
      case HomeawayCancellationPolicy.HOMEAWAY_CANCELLATION_POLICY_NONE: {
        return "The traveler will not get a refund if they cancel their booking at any time after it is accepted.";
      }
    }
  }


}



