import { Injectable } from "@angular/core";
import * as Raven from "raven-js";
import { RavenOptions } from "raven-js";

import { environment } from "../../../environments/environment";

@Injectable()
export class SentryService {
  captureMessage(msg: string, options?: RavenOptions) {
    if (environment.production) {
      Raven.captureMessage(msg, options);
    }
  }

  setUserContext(id: number, firstName: string) {
    if (environment.production) {
      Raven.setUserContext({ id, firstName });
    }
  }
}
