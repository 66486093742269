import { Component, Input, OnInit } from "@angular/core";

import { AdminDashStats } from "../../../models/admin-dash-stats";

@Component({
  selector: "sd-rental-channel-breakdown-bar-chart",
  template: `
    <ngx-charts-bar-vertical
      [view]="view"
      [scheme]="colorScheme"
      [results]="data"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      barPadding="20"
      showYAxisLabel="true"
      [yAxisLabel]="yAxisLabel"
      (select)="onSelect($event)">
      <ng-template #tooltipTemplate let-model="model">
        <p>{{ model.name }} : {{'$' + model.value.toFixed(2)}}</p>
      </ng-template>
    </ngx-charts-bar-vertical>
  `,
  styles: [`

  `]
})
export class RentalChannelBreakdownBarChartComponent implements OnInit {


  showXAxis = true;
  showYAxis = true;
  gradient = false;
  yAxisLabel = "Earnings (in $)";

  @Input() data: AdminDashStats;
  @Input() isNetIncome: boolean = false;
  @Input() isCommission: boolean = false;


  view: any[] = [300, 300];

  colorScheme = {
    domain: ["#12304c", "#f55a5f", "#28924c", "#926685", "#ea9866", "#000000"]
  };

  onSelect(event) {
  }

  ngOnInit(): void {
    if (this.isNetIncome) {
      this.yAxisLabel = "Net Income (in $)";
    }

    if (this.isCommission) {
      this.yAxisLabel = "Commission (in $)";
    }
  }


}
