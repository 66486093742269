import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { __HTTPResponseType } from "../../enums/common.enum";
import { ApiService } from "../api.service";
import { InteractionsDraftsShowResponse } from "../../models/responses/settings/drafts/intearctions-draft-show.response";
import { InteractionsDraft } from "../../models/new/drafts/intearctions-draft.model";
import { InteractionsDraftsGetResponse } from "../../models/responses/settings/drafts/intearctions-draft-get.response";

@Injectable()
export class InteractionsDraftsService {

  constructor(private apiService: ApiService) {
  }

  getInteractionsDrafts(): Observable<InteractionsDraftsGetResponse> {
    return this.apiService.get<InteractionsDraftsGetResponse>("/admin/interaction-with-guests-drafts", true);
  }

  getInteractionsDraftById(draftId):Observable<InteractionsDraftsShowResponse> {
    return this.apiService.get<InteractionsDraftsShowResponse>("/admin/interaction-with-guests-drafts/"+draftId,true);
  }

  createInteractionsDraft(data: Partial<InteractionsDraft>): Observable<InteractionsDraftsShowResponse> {
    return this.apiService.post<InteractionsDraftsShowResponse>("/admin/interaction-with-guests-drafts", true, data);
  }

  updateInteractionsDraft(draftId: number, data: any): Observable<InteractionsDraftsShowResponse> {
    return this.apiService.put<InteractionsDraftsShowResponse>("/admin/interaction-with-guests-drafts/" + draftId, true, data);
  }

  deleteInteractionsDraft(draftId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/interaction-with-guests-drafts/" + draftId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}

