import { Component } from "@angular/core";

/**
 * Created by piyushkantm on 03/07/17.
 */

@Component({
  selector: "sd-owner-main-layout",
  template: `
    <sd-layout style="overflow-x: hidden">
      <div id="main_content" fxLayout="row" fxLayoutGap="10px">
        <div class="accent-background" style="overflow-x: hidden">

          <sd-owner-sidebar>
            <ng-content></ng-content>
          </sd-owner-sidebar>
        </div>
      </div>
    </sd-layout>
  `,
  styles: []
})
export class LayoutOwnerMainComponent {

  constructor() {
  }
}
