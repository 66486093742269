import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

import {
  QuoteCreateRequestAction,
  QuoteCreateSuccessAction,
  QuoteIndexRequestAction,
  QuoteIndexSuccessAction
} from "../actions/quote";
import { Quote } from "../models/quote";
import { getIsQuotesLoaded, getIsQuotesLoading, getQuotes, State } from "../reducers";
import { QuoteService } from "../services/quote.service";
import DateUtils from "../utils/date";

@Injectable()
export class QuoteRepository {

  constructor(private quoteService: QuoteService, private store: Store<State>) {
  }

  getQuotesLoading(): Observable<boolean> {
    return this.store.select(getIsQuotesLoading);
  }

  getQuotesLoaded(): Observable<boolean> {
    return this.store.select(getIsQuotesLoaded);
  }

  getQuotes(force: boolean = false): Observable<Quote[]> {
    let loading = false;
    let loaded = false;

    this.getQuotesLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getQuotesLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new QuoteIndexRequestAction());
      this.quoteService.getQuotes().subscribe(quotes => {
        this.store.dispatch(new QuoteIndexSuccessAction(quotes));
      });
    }

    return this.store.select(getQuotes);
  }

  createQuote(data: any): Observable<Quote> {
    this.store.dispatch(new QuoteCreateRequestAction());
    return this.quoteService.createQuote(data).pipe(map(res => {
      this.store.dispatch(new QuoteCreateSuccessAction(res));
      return res;
    }));
  }

  getQuote(listingId: number, start: Date, end: Date, petsCount: number = 0, extraGuestCount: number = 0, overrideMinStay = false, oldBookingId?: number): Observable<Quote> {
    const data: any = {
      start: DateUtils.toISODateString(start),
      end: DateUtils.toISODateString(end),
      pets_count: petsCount,
      extra_guest_count: extraGuestCount,
      override_min_stay: overrideMinStay ? 1 : 0
    };
    if (!!oldBookingId) {
      data.old_booking_id = oldBookingId;
    }
    return this.quoteService.getQuote(listingId, data);
  }
}
