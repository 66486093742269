import { AbstractControl } from "@angular/forms";
import { isValidNumber, parse, ParsedNumber } from "libphonenumber-js";
import * as moment from "moment";
import { isNullOrUndefined } from "util";

import { BookingSource } from "../enums/booking.enum";
import { TaskRepeatFrequency } from "../enums/task.enum";
import { Booking } from "../models/booking";
import { CustomVariable } from "../models/custom-variable";
import { Thread } from "../models/thread";

import { getDateObj } from "./calendar-utils";

export default class Utils {
  static objToSearchParams(obj): URLSearchParams {
    const params: URLSearchParams = new URLSearchParams();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        params.set(key, obj[key]);
      }
    }
    return params;
  }

  static getThreadType(thread: Thread, adminId: number): string {
    let filter = null;

    if (isNullOrUndefined(thread)) {
      return filter;
    }

    if (this.isUnreadThread(thread)) {
      filter = "unread";
    } else if (this.isFollowUpThread(thread)) {
      filter = "followup";
    } else if (this.isAssignedThread(thread, adminId)) {
      filter = "assigned";
    } else if (this.isArchivedThread(thread)) {
      filter = "archived";
    } else if (this.isRequestThread(thread)) {
      filter = "requests";
    } else if (this.isOngoingThread(thread)) {
      filter = "ongoing";
    } else if (this.isBookedThread(thread)) {
      filter = "booked";
    } else {
      filter = "ongoing";
    }

    return filter;
  }

  static isUnreadThread(thread: Thread) {
    return !thread.is_opened;
  }

  static isAssignedThread(thread: Thread, adminId: number) {
    console.log(adminId);
    console.log(thread.assignee_id);
    return String(thread.assignee_id) === String(adminId);
  }

  static isFollowUpThread(thread: Thread) {
    return thread.is_marked_for_followup;
  }

  static isArchivedThread(thread: Thread) {
    return thread.is_archived;
  }

  static isRequestThread(thread: Thread) {
    return thread.reservation_request ||
      thread.status === "pending" ||
      thread.status === "inquiry" ||
      thread.status === "special_offer";
  }

  static isOngoingThread(thread: Thread) {
    if (thread.status === "accepted" || thread.status === "awaiting_payment") {
      const startDate = moment(thread.getBooking().start);
      const endDate = moment(thread.getBooking().end);

      const currentDate = getDateObj();
      const currentDateString = currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate();
      const currentDateMoment = moment(currentDateString);

      if (endDate.diff(currentDateMoment) >= 0 && currentDateMoment.diff(startDate) >= 0) {
        return true;
      }
    }

    return false;
  }

  static isBookedThread(thread: Thread) {
    if (thread.status === "accepted" || thread.status === "awaiting_payment") {
      const startDate = moment(thread.getBooking().start);
      const endDate = moment(thread.getBooking().end);

      const currentDate = getDateObj();
      const currentDateString = currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate();
      const currentDateMoment = moment(currentDateString);

      if (endDate.diff(currentDateMoment) > 0 || currentDateMoment.diff(startDate) > 0) {
        return true;
      }
    }

    return false;
  }

  static isOtherThread(thread: Thread) {
    return true;
  }

  static getBookingType(booking: Booking): string {
    let filter = null;

    if (isNullOrUndefined(booking)) {
      return filter;
    }

    const temp = getDateObj();
    const currentDateString = temp.getFullYear() + "-" + (temp.getMonth() + 1) + "-" + temp.getDate();
    const currentDateMoment = moment(currentDateString);

    const startDateMoment = moment(booking.start);
    const endDateMoment = moment(booking.end);

    if (booking.payments && booking.payments.paid_on) {
      const paidDateOn = moment(booking.payments.paid_on);
      if (paidDateOn.diff(currentDateMoment) > 0) {
        filter = "recent";
        return filter;
      }
    }

    if (currentDateMoment.diff(startDateMoment) === 0) {
      filter = "today";
    } else if (startDateMoment.diff(currentDateMoment) > 0) {
      filter = "upcoming";
    } else if (currentDateMoment.diff(endDateMoment) > 0) {
      filter = "past";
    } else if (endDateMoment.diff(currentDateMoment) === 0) {
      filter = "today-checkout";
    } else if (currentDateMoment.diff(startDateMoment) > 0 && endDateMoment.diff(currentDateMoment) > 0) {
      filter = "ongoing";
    }

    return filter;
  }

  static trim(subject: string, length: number): string {
    if (subject.length > length) {
      return subject.substr(0, length);
    }
    return subject;
  }

  static removeKey(obj, deleteKey) {
    return Object.keys(obj)
      .filter(key => key !== deleteKey)
      .reduce((result, current) => {
        result[current] = obj[current];
        return result;
      }, {});
  }

  static normalize(entityArray: Entity[]) {
    const result = {};
    for (let i = 0; i < entityArray.length; i++) {
      result[entityArray[i].id] = entityArray[i];
    }

    return result;
  }

  static normalizedObjToArray(object: { [id: number]: any }) {
    const result = [];
    for (let i = 1; i <= Object.keys(object).length; i++) {
      result.push(object[i]);
    }

    return result;
  }

  static getObjectValues(object: { [id: number]: any }) {
    const vals = [];
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        vals.push(object[key]);
      }
    }
    return vals;
  }

  static removeNullFields(data: any) {
    Object.keys(data).forEach((key) => ((data[key] == null) || data[key] === "") && delete data[key]);
    return data;
  }

  static objectIsEquivalent(obj1, obj2) {
    // Create arrays of key names
    const obj1keys = Object.keys(obj1);
    const obj2keys = Object.keys(obj2);

    // If number of properties is different,
    // objects are not equivalent
    if (obj1keys.length !== obj2keys.length) {
      return false;
    }

    for (let i = 0; i < obj1keys.length; i++) {
      const keyName = obj1keys[i];
      // If values of same property are not equal,
      // objects are not equivalent
      if (obj1[keyName] !== obj2[keyName]) {
        return false;
      }
    }
    // If we made it this far, objects
    // are considered equivalent
    return true;
  }
}

interface Entity {
  id: number;
}

export interface SDEnum {
  title: string;
  slug: string;
}

export function getThreadStatColor(slug: string): { color: string } {
  let color;

  switch (slug) {
    case "inquiry": {
      color = "#6d777d";
      break;
    }
    case "reserved": {
      color = "#f0ad40";
      break;
    }
    case "accepted": {
      color = "#5cb85c";
      break;
    }
    case "awaiting_payment": {
      color = "#556b8d";
      break;
    }
    case "requested": {
      color = "#556b8d";
      break;
    }
    case "cancelled": {
      color = "#e25d5d";
      break;
    }
    case "denied": {
      color = "#e25d5d";
      break;
    }
    case "expired": {
      color = "#e25d5d";
      break;
    }
    case "closed": {
      color = "#808080";
      break;
    }

    case "checked_in": {
      color = "#5cb85c";
      break;
    }

    case "checked_out": {
      color = "#ff0000";
      break;
    }

    default: {
      color = "#ff0000";
    }
  }

  return {color: color};
}

export function getAllPetsAllowedTypes(): SDEnum[] {
  const allValues = [];

  allValues.push(getPetsAllowedType("yes"));
  allValues.push(getPetsAllowedType("all_sizes"));
  allValues.push(getPetsAllowedType("small_sizes"));
  allValues.push(getPetsAllowedType("no"));

  return allValues;
}

export function getPetsAllowedType(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "yes": {
      title = "Yes";
      break;
    }
    case "all_sizes": {
      title = "All Sizes";
      break;
    }
    case "small_sizes": {
      title = "Small Sizes";
      break;
    }
    default: {
      title = "No";
    }
  }

  return {title: title, slug: slug};
}

export function getAllSources(): SDEnum[] {
  const allValues = [];

  allValues.push(getSourceType("airbnb"));
  allValues.push(getSourceType("stayduvet"));
  allValues.push(getSourceType("homeaway"));
  allValues.push(getSourceType("plum_guide"));
  allValues.push(getSourceType("hvmi"));
  allValues.push(getSourceType("referral"));

  return allValues;
}

export function getSourceType(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "airbnb": {
      title = "Airbnb";
      break;
    }
    case "stayduvet": {
      title = "Stay Duvet";
      break;
    }
    case "homeaway": {
      title = "VRBO";
      break;
    }
    case "plum_guide": {
      title = "Plum Guide";
      break;
    }
    case "hvmi": {
      title = "Marriott";
      break;
    }
    case "owner_block": {
      title = "Block";
      break;
    }
    case "referral": {
      title = "Referral";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getAutoResponseTypes(): SDEnum[] {
  const allValues = [];

  allValues.push(getAutoResponseType("confirmed_before_check_in"));
  allValues.push(getAutoResponseType("confirmed_on_the_day_of_check_in"));
  allValues.push(getAutoResponseType("confirmed_on_the_day_of_check_out"));
  allValues.push(getAutoResponseType("confirmed_during_stay"));
  allValues.push(getAutoResponseType("confirmed_after_check_out"));
  allValues.push(getAutoResponseType("after_first_message"));
  allValues.push(getAutoResponseType("any_subsequent_message"));

  return allValues;
}

export function getAutoResponseType(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "confirmed_before_check_in": {
      title = "Before checking in";
      break;
    }
    case "confirmed_on_the_day_of_check_in": {
      title = "On the day of check in";
      break;
    }
    case "confirmed_on_the_day_of_check_out": {
      title = "On the day of check out";
      break;
    }
    case "confirmed_during_stay": {
      title = " During stay";
      break;
    }
    case "confirmed_after_check_out": {
      title = "After checking out";
      break;
    }
    case "after_first_message": {
      title = "For the first message";
      break;
    }
    case "any_subsequent_message": {
      title = "For any subsequent message";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getAllSuitableForEventsTypes(): SDEnum[] {
  const allValues = [];

  allValues.push(getSuitableForEventsType("events_only"));
  allValues.push(getSuitableForEventsType("filming_only"));
  allValues.push(getSuitableForEventsType("call_before"));
  allValues.push(getSuitableForEventsType("no"));

  return allValues;
}

export function getSuitableForEventsType(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "events_only": {
      title = "Events Only";
      break;
    }
    case "filming_only": {
      title = "Filming Only";
      break;
    }
    case "call_before": {
      title = "Call Before";
      break;
    }
    default: {
      title = "No";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getDays(): SDEnum[] {
  const getOptions = [];

  getOptions.push(getDay("sunday"));
  getOptions.push(getDay("monday"));
  getOptions.push(getDay("tuesday"));
  getOptions.push(getDay("wednesday"));
  getOptions.push(getDay("thursday"));
  getOptions.push(getDay("friday"));
  getOptions.push(getDay("saturday"));

  return getOptions;
}

export function getDay(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "sunday": {
      title = "Sunday";
      break;
    }
    case "monday": {
      title = "Monday";
      break;
    }
    case "tuesday": {
      title = "Tuesday";
      break;
    }
    case "wednesday": {
      title = "Wednesday";
      break;
    }
    case "thursday": {
      title = "Thursday";
      break;
    }
    case "friday": {
      title = "Friday";
      break;
    }
    case "saturday": {
      title = "Saturday";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getFrequencies(): SDEnum[] {
  const getOptions = [];

  getOptions.push(getFrequency("every"));
  getOptions.push(getFrequency("every_other"));
  getOptions.push(getFrequency("every_third"));

  return getOptions;
}

export function getFrequency(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "every": {
      title = "Every";
      break;
    }
    case "every_other": {
      title = "Every Other";
      break;
    }
    case "every_third": {
      title = "Every Third";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getPaymentByOptions(): SDEnum[] {
  const getOptions = [];

  getOptions.push(getOption("guests"));
  getOptions.push(getOption("management"));
  getOptions.push(getOption("owner_charge"));
  getOptions.push(getOption("insurance_claim"));

  return getOptions;
}

export function getOption(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "guests": {
      title = "Guest";
      break;
    }
    case "management": {
      title = "Management";
      break;
    }
    case "owner_charge": {
      title = "Owner";
      break;
    }
    case "insurance_claim": {
      title = "Insurance Claim";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getProspectsFilterTypes(): SDEnum[] {
  const prospectTypes = [];
  prospectTypes.push(getProspectFilterType("today_prospects"));
  prospectTypes.push(getProspectFilterType("upcoming_prospects"));
  prospectTypes.push(getProspectFilterType("expired_prospects"));
  prospectTypes.push(getProspectFilterType("passed_prospects"));
  prospectTypes.push(getProspectFilterType("recently_created"));
  return prospectTypes;
}

export function getProspectFilterType(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "today_prospects": {
      title = "Today's Prospects";
      break;
    }
    case "upcoming_prospects": {
      title = "Upcoming Prospects";
      break;
    }
    case "expired_prospects": {
      title = "Expired Prospects";
      break;
    }
    case "passed_prospects": {
      title = "Passed Prospects";
      break;
    }
    case "recently_created": {
      title = "Recently Created";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getQuotesFilterTypes(): SDEnum[] {
  const quoteTypes = [];
  quoteTypes.push(getQuoteFilterType("today_quotes"));
  quoteTypes.push(getQuoteFilterType("upcoming_quotes"));
  quoteTypes.push(getQuoteFilterType("expired_quotes"));
  return quoteTypes;
}

export function getQuoteFilterType(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "today_quotes": {
      title = "Today's Quotes";
      break;
    }
    case "upcoming_quotes": {
      title = "Upcoming Quotes";
      break;
    }
    case "expired_quotes": {
      title = "Expired Quotes";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getAllMaintenanceThresholdTypes(): { title: string, slug: string }[] {
  const allValues = [];

  allValues.push(getMaintenanceThresholdType("confirm_first"));
  allValues.push(getMaintenanceThresholdType("150-499"));
  allValues.push(getMaintenanceThresholdType("500-999"));
  allValues.push(getMaintenanceThresholdType("approved"));

  return allValues;
}

export function getMaintenanceThresholdType(slug: string): { title: string, slug: string } {
  let title;

  switch (slug) {
    case "confirm_first": {
      title = "Confirm First";
      break;
    }
    case "150-499": {
      title = "$150 - $499";
      break;
    }
    case "500-999": {
      title = "$500 - $999";
      break;
    }
    default: {
      title = "Auto Approve";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getCoffeeMakerTypes(): { title: string, slug: string }[] {
  const allValues = [];

  allValues.push(getCoffeeMakerType("drip"));
  allValues.push(getCoffeeMakerType("french_press"));
  allValues.push(getCoffeeMakerType("keurig"));
  allValues.push(getCoffeeMakerType("expresso"));

  return allValues;
}

export function getCoffeeMakerType(slug: string): { title: string, slug: string } {
  let title;

  switch (slug) {
    case "drip": {
      title = "Drip";
      break;
    }
    case "french_press": {
      title = "French Press";
      break;
    }
    case "keurig": {
      title = "Keurig";
      break;
    }
    case "expresso": {
      title = "Expresso";
      break;
    }
    default: {
      title = "Auto Approve";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getContactMaintenanceCatagoryTypes(): { title: string, slug: string }[] {
  const allValues = [];

  allValues.push(getContactMaintenanceCatagoryType("hvac"));
  allValues.push(getContactMaintenanceCatagoryType("plumber"));
  allValues.push(getContactMaintenanceCatagoryType("painter"));
  allValues.push(getContactMaintenanceCatagoryType("electrician"));
  allValues.push(getContactMaintenanceCatagoryType("homeowner"));
  allValues.push(getContactMaintenanceCatagoryType("inspector"));
  allValues.push(getContactMaintenanceCatagoryType("general_maintenance"));
  allValues.push(getContactMaintenanceCatagoryType("housekeeper"));
  allValues.push(getContactMaintenanceCatagoryType("preventative_maintenance"));
  allValues.push(getContactMaintenanceCatagoryType("sales"));
  allValues.push(getContactMaintenanceCatagoryType("agent"));
  allValues.push(getContactMaintenanceCatagoryType("landscaping"));
  allValues.push(getContactMaintenanceCatagoryType("concierge"));

  return allValues;
}

export function getContactMaintenanceCatagoryTypesForHouseKeeper(): { title: string, slug: string }[] {
  const allValues = [];

  allValues.push(getContactMaintenanceCatagoryType("hvac"));
  allValues.push(getContactMaintenanceCatagoryType("plumber"));
  allValues.push(getContactMaintenanceCatagoryType("painter"));
  allValues.push(getContactMaintenanceCatagoryType("electrician"));
  allValues.push(getContactMaintenanceCatagoryType("inspector"));
  allValues.push(getContactMaintenanceCatagoryType("general_maintenance"));
  allValues.push(getContactMaintenanceCatagoryType("preventative_maintenance"));
  allValues.push(getContactMaintenanceCatagoryType("sales"));
  allValues.push(getContactMaintenanceCatagoryType("landscaping"));
  allValues.push(getContactMaintenanceCatagoryType("concierge"));

  return allValues;
}

export function getContactMaintenanceCatagoryType(slug: string): { title: string, slug: string } {
  let title;

  switch (slug) {
    case "hvac": {
      title = "HVAC";
      break;
    }
    case "plumber": {
      title = "Plumber";
      break;
    }
    case "painter": {
      title = "Painter";
      break;
    }
    case "electrician": {
      title = "Electrician";
      break;
    }
    case "general_maintenance": {
      title = "General Maintenance";
      break;
    }
    case "homeowner": {
      title = "Homeowner";
      break;
    }
    case "inspector": {
      title = "Inspector";
      break;
    }
    case "housekeeper": {
      title = "House Keeper";
      break;
    }
    case "preventative_maintenance": {
      title = "Preventative Maintenance";
      break;
    }
    case "sales": {
      title = "Sales";
      break;
    }
    case "agent": {
      title = "Agent";
      break;
    }
    case "landscaping": {
      title = "Landscaping";
      break;
    }
    case "concierge": {
      title = "Concierge";
      break;
    }
    default: {
      title = "Auto Approve";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getContactSourcesTypes(): { title: string, slug: string }[] {
  const allValues = [];

  allValues.push(getContactSourcesType("airbnb"));
  allValues.push(getContactSourcesType("homeaway"));
  allValues.push(getContactSourcesType("stayduvet"));
  allValues.push(getContactSourcesType("plum_guide"));
  allValues.push(getContactSourcesType("hvmi"));

  return allValues;
}

export function getContactSourcesType(slug: string): { title: string, slug: string } {
  let title;

  switch (slug) {
    case "airbnb": {
      title = "Airbnb";
      break;
    }
    case "homeaway": {
      title = "VRBO";
      break;
    }
    case "stayduvet": {
      title = "StayDuvet";
      break;
    }
    case "plum_guide": {
      title = "Plum Guide";
      break;
    }
    case "hvmi": {
      title = "Marriott";
      break;
    }
    default: {
      title = "Auto Approve";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getContactMethodTypes(): { title: string, slug: string }[] {
  const allValues = [];

  allValues.push(getContactMethodType("phone"));
  allValues.push(getContactMethodType("email"));
  return allValues;
}

export function getContactMethodType(slug: string): { title: string, slug: string } {
  let title;

  switch (slug) {
    case "phone": {
      title = "Phone";
      break;
    }
    case "email": {
      title = "Email";
      break;
    }
    default: {
      title = "Auto Approve";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getApartmentTypes(): { title: string, slug: string }[] {
  const allValues = [];

  allValues.push(getApartmentType("entire_home"));
  allValues.push(getApartmentType("private_room"));
  allValues.push(getApartmentType("shared_room"));
  return allValues;
}

export function getApartmentType(slug: string): { title: string, slug: string } {
  let title;

  switch (slug) {
    case "entire_home": {
      title = "Entire Home";
      break;
    }
    case "private_room": {
      title = "Private Rooms";
      break;
    }
    case "shared_room": {
      title = "Shared Rooms";
      break;
    }
    default: {
      title = "Auto Approve";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getBedTypes(): { title: string, slug: string }[] {
  const allValues = [];

  allValues.push(getBedType("king_bed"));
  allValues.push(getBedType("queen_bed"));
  allValues.push(getBedType("double_bed"));
  allValues.push(getBedType("single_bed"));
  allValues.push(getBedType("sofa_bed"));
  allValues.push(getBedType("couch"));
  allValues.push(getBedType("crib"));

  allValues.push(getBedType("air_mattress"));
  allValues.push(getBedType("bunk_bed"));
  allValues.push(getBedType("floor_mattress"));
  allValues.push(getBedType("toddler_bed"));
  allValues.push(getBedType("water_bed"));
  allValues.push(getBedType("hammock"));
  return allValues;
}

export function getBedType(slug: string): { title: string, slug: string } {
  let title;

  switch (slug) {
    case "king_bed": {
      title = "King Bed";
      break;
    }
    case "queen_bed": {
      title = "Queen Bed";
      break;
    }
    case "double_bed": {
      title = "Full/Double";
      break;
    }
    case "single_bed": {
      title = "Single Bed";
      break;
    }
    case "sofa_bed": {
      title = "Sofa Bed";
      break;
    }
    case "couch": {
      title = "Couch";
      break;
    }
    case "crib": {
      title = "Crib";
      break;
    }

    case "air_mattress": {
      title = "Air Mattress";
      break;
    }
    case "bunk_bed": {
      title = "Bunk Bed";
      break;
    }
    case "floor_mattress": {
      title = "Floor Mattress";
      break;
    }
    case "toddler_bed": {
      title = "Toddler Bed";
      break;
    }
    case "water_bed": {
      title = "Water Bed";
      break;
    }
    case "hammock": {
      title = "Hammock";
      break;
    }

    default: {
      title = "Auto Approve";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getAllAlertOffsetReferences(): SDEnum[] {
  const allValues = [];

  allValues.push(getAlertOffsetReference("check_in"));
  allValues.push(getAlertOffsetReference("check_out"));
  allValues.push(getAlertOffsetReference("booking_confirmation"));
  return allValues;
}

export function getAlertOffsetReference(slug: string): { title: string, slug: string } {
  let title;

  switch (slug) {
    case "check_in": {
      title = "Check In";
      break;
    }
    case "check_out": {
      title = "Check Out";
      break;
    }

    default: {
      title = "Reservation Confirmation";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getAllSendVias(): SDEnum[] {
  const allValues = [];

  allValues.push(getSendVia("text"));
  allValues.push(getSendVia("email"));
  allValues.push(getSendVia("booking_channel"));
  return allValues;
}

export function getSendVia(slug: string): { title: string, slug: string } {
  let title;

  switch (slug) {
    case "text": {
      title = "Text";
      break;
    }
    case "email": {
      title = "Email";
      break;
    }

    default: {
      title = "Booking Channel";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getAllOffsetPositions(): SDEnum[] {
  const allValues = [];

  allValues.push(getOffsetPosition("before"));
  allValues.push(getOffsetPosition("after"));
  return allValues;
}

export function getOffsetPosition(slug: string): { title: string, slug: string } {
  let title;

  switch (slug) {
    case "before": {
      title = "Before";
      break;
    }

    default: {
      title = "After";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getAllAlertTypes(): SDEnum[] {
  const allValues = [];

  allValues.push(getAlertType("include"));
  allValues.push(getAlertType("exclude"));
  return allValues;
}

export function getAlertType(slug: string): { title: string, slug: string } {
  let title;

  switch (slug) {
    case "include": {
      title = "Include";
      break;
    }

    default: {
      title = "Exclude";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getAllTimeDenominations(): SDEnum[] {
  const allValues = [];

  allValues.push(getTimeDenomination("day"));
  allValues.push(getTimeDenomination("hour"));
  allValues.push(getTimeDenomination("minute"));
  allValues.push(getTimeDenomination("second"));
  return allValues;
}

export function getTimeDenomination(slug: string): { title: string, slug: string } {
  let title;

  switch (slug) {
    case "day": {
      title = "Day(s)";
      break;
    }
    case "hour": {
      title = "Hour(s)";
      break;
    }
    case "minute": {
      title = "Minute(s)";
      break;
    }

    default: {
      title = "Second(s)";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getSourceIcon(source) {
  console.log("[SEMPER]", source);
  if (source === BookingSource.AIRBNB) {
    return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/airbnb_icon.png";
  } else if (source === BookingSource.STAYDUVET) {
    return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/logo.png";
  } else if (source === BookingSource.HOMEAWAY) {
    return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/vrbo_icon.png";
  } else if (source === BookingSource.PLUM_GUIDE) {
    return "../../assets/bg/plumguide.png";
  } else if (source === BookingSource.MARRIOTT) {
    return "../../assets/bg/marriot.png";
  } else if (source === BookingSource.OWNER_BLOCK) {
    return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/red-cross.png";
  } else {
    return "https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/image-placeholder.jpg";
  }
}

export enum CalendarDayStatus {
  first_half,
  second_half,
  full_day,
  none,
  blocked
}

export function getAllBookingLogTypes(): { title: string, slug: string, color: string }[] {
  const allValues = [];

  allValues.push(getBookingLogType("created"));
  allValues.push(getBookingLogType("updated"));
  allValues.push(getBookingLogType("deleted"));
  allValues.push(getBookingLogType("success"));
  allValues.push(getBookingLogType("error"));
  allValues.push(getBookingLogType("altered"));

  return allValues;
}

export function getBookingLogType(slug: string): { title: string, slug: string, color: string } {
  let title;
  let color;

  switch (slug) {
    case "created": {
      title = "Created";
      color = "#4F8A10";

      break;
    }

    case "auto_task_added": {
      title = "Added";
      color = "#4F8A10";

      break;
    }

    case "task_added": {
      title = "Added";
      color = "#4F8A10";

      break;
    }

    case "scheduled_message_added": {
      title = "Added";
      color = "#4F8A10";

      break;
    }

    case "security_refunded": {
      title = "Refunded";
      color = "#4F8A10";

      break;
    }

    case "payment_received": {
      title = "Received";
      color = "#4F8A10";

      break;
    }

    case "money_sent": {
      title = "Sent";
      color = "#4F8A10";

      break;
    }

    case "money_requested": {
      title = "Requested";
      color = "#4F8A10";

      break;
    }

    case "scheduled_message_sent": {
      title = "Created";
      color = "#4F8A10";

      break;
    }

    case "scheduled_message_updated": {
      title = "Updated";
      color = "#D17B26";

      break;
    }

    case "owner_block_created": {
      title = "Updated";
      color = "#D17B26";

      break;
    }

    case "partial_refund": {
      title = "Refunded";
      color = "#D17B26";

      break;
    }

    case "updated": {
      title = "Updated";
      color = "#D17B26";

      break;
    }

    case "updated_departure_time": {
      title = "Updated";
      color = "#D17B26";

      break;
    }

    case "updated_status": {
      title = "Updated";
      color = "#D17B26";

      break;
    }

    case "updated_arrival_time": {
      title = "Updated";
      color = "#D17B26";

      break;
    }

    case "updated_listing": {
      title = "Updated";
      color = "#D17B26";

      break;
    }

    case "altered": {
      title = "Altered";
      color = "#D17B26";
      break;
    }

    case "task_archived": {
      title = "Archived";
      color = "#D17B26";
      break;
    }

    case "security_deducted": {
      title = "Deducted";
      color = "#D17B26";
      break;
    }

    case "commission_updated": {
      title = "Updated";
      color = "#D17B26";
      break;
    }

    case "deleted": {
      title = "Deleted";
      color = "#FF0000";

      break;
    }

    case "scheduled_message_deleted": {
      title = "Deleted";
      color = "#FF0000";

      break;
    }

    case "scheduled_message_not_sent": {
      title = "Not Sent";
      color = "#FF0000";

      break;
    }

    case "auto_task_failed": {
      title = "Failed";
      color = "#FF0000";

      break;
    }

    case "task_deleted": {
      title = "Deleted";
      color = "#FF0000";

      break;
    }

    case "success": {
      title = "Success";
      color = "#4F8A10";

      break;
    }
    case "error": {
      title = "Error";
      color = "#FF0000";

      break;
    }
  }

  return {title: title, slug: slug, color: color};
}

export function getAllCollectionMethodTypes(): SDEnum[] {
  const allValues = [];

  allValues.push(getCollectionMethodType("cash"));
  allValues.push(getCollectionMethodType("credit_card"));
  allValues.push(getCollectionMethodType("invoice"));

  return allValues;
}

export function getCollectionMethodType(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "cash": {
      title = "Cash";
      break;
    }
    case "paid": {
      title = "Paid";
      break;
    }
    case "airbnb": {
      title = "Airbnb";
      break;
    }
    case "credit_card": {
      title = "Credit Card";
      break;
    }
    case "invoice": {
      title = "Invoice";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getAllReservationPaymentTypes(): SDEnum[] {
  const allValues = [];

  // allValues.push(getReservationPaymentType('from_source'));
  allValues.push(getReservationPaymentType("from_platform"));
  allValues.push(getReservationPaymentType("owners_charge"));
  allValues.push(getReservationPaymentType("paid"));
  allValues.push(getReservationPaymentType("over_the_phone"));
  allValues.push(getReservationPaymentType("booking_platform"));

  return allValues;
}

export function getReservationPaymentType(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "from_source": {
      title = "From Source";
      break;
    }
    case "from_platform": {
      title = "From Platform";
      break;
    }
    case "booking_platform": {
      title = "Booking Platform";
      break;
    }
    case "owners_charge": {
      title = "Owner's Charge";
      break;
    }
    case "paid": {
      title = "Paid";
      break;
    }
    case "over_the_phone": {
      title = "Over The Phone";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getAllReservationDeclineReasonTypes(): SDEnum[] {
  const allValues = [];

  allValues.push(getReservationDeclineReasonType("dates_not_available"));
  allValues.push(getReservationDeclineReasonType("not_a_good_fit"));
  allValues.push(getReservationDeclineReasonType("waiting_for_better_reservation"));
  allValues.push(getReservationDeclineReasonType("not_comfortable"));

  return allValues;
}

export function getReservationDeclineReasonType(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "dates_not_available": {
      title = "Dates are unavailable.";
      break;
    }
    case "not_a_good_fit": {
      title = "My Listing Doesn't fit the guests needs.";
      break;
    }
    case "waiting_for_better_reservation": {
      title = "I want a different price, or change in reservation.";
      break;
    }
    case "not_comfortable": {
      title = "Uncomfortable with the reservation.";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getAllTaskRepeatTypes(): SDEnum[] {
  const allValues = [];

  allValues.push(getTaskRepeatType("never"));
  allValues.push(getTaskRepeatType("daily"));
  allValues.push(getTaskRepeatType("weekly"));
  allValues.push(getTaskRepeatType("monthly"));
  allValues.push(getTaskRepeatType("yearly"));

  return allValues;
}

export function getTaskRepeatType(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "never": {
      title = "Never";
      break;
    }
    case "daily": {
      title = "Daily";
      break;
    }
    case "weekly": {
      title = "Weekly";
      break;
    }
    case "monthly": {
      title = "Monthly";
      break;
    }
    case "yearly": {
      title = "Yearly";
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getAllTaskRepeatFrequencyTypes(): SDEnum[] {
  const allValues = [];

  allValues.push(getTaskFrequencyType("1"));
  allValues.push(getTaskFrequencyType("2"));
  allValues.push(getTaskFrequencyType("3"));
  allValues.push(getTaskFrequencyType("4"));
  allValues.push(getTaskFrequencyType("5"));

  return allValues;
}

export function getTaskFrequencyType(slug: string): SDEnum {
  let title;

  switch (slug) {
    case "1": {
      title = TaskRepeatFrequency.DAILY;
      break;
    }
    case "2": {
      title = TaskRepeatFrequency.WEEKLY;
      break;
    }
    case "3": {
      title = TaskRepeatFrequency.BI_WEEKLY;
      break;
    }
    case "4": {
      title = TaskRepeatFrequency.MONTHLY;
      break;
    }
    case "5": {
      title = TaskRepeatFrequency.ANNUALLY;
      break;
    }
    default: {
      break;
    }
  }

  return {title: title, slug: slug};
}

export function getFormattedConfirmationCode(source: string, code: string): string {

  let prefix;
  if (source === "airbnb") {
    prefix = "AB";
  }
  if (source === "stayduvet") {
    prefix = "SD";
  }
  if (source === "homeaway") {
    prefix = "HA";
  }
  if (source === "plum_guide") {
    prefix = "PG";
  }
  if (source === "hvmi") {
    prefix = "MR";
  }

  if (!code) {
    return "######";
  }

  if (prefix) {
    return prefix + "-" + code;
  }

  return code;
}

export function ValidateMobileNumber(control: AbstractControl) {
  if (control.value && (!valid(control.value))) {
    return {validNo: true};
  } else if (control.value && (valid(control.value))) {
    return null;
  }
}

function valid(number: string) {
  const parsedNumber: ParsedNumber = parse(number);
  if (isValidNumber(number, parsedNumber.country || "US")) {
    console.log(parsedNumber.country);
    return true;
  } else {
    return false;
  }

}

export function getStaticVariables(): CustomVariable[] {

  const data: CustomVariable[] = [
    {
      key: "user_full",
      replacement_text: "User Full Name"
    }, {
      key: "user_first",
      replacement_text: "User First Name"
    }, {
      key: "host_full",
      replacement_text: "Host Full Name"
    }, {
      key: "host_first",
      replacement_text: "Host First Name"
    }, {
      key: "airbnb_full",
      replacement_text: "Airbnb Full Name"
    }, {
      key: "airbnb_first",
      replacement_text: "Airbnb First Name"
    }, {
      key: "homeowner_full",
      replacement_text: "Homeowner Full Name"
    }, {
      key: "homeowner_first",
      replacement_text: "Homeowner First Name"
    }, {
      key: "checkin_date",
      replacement_text: "Check In Date"
    }, {
      key: "checkin_time",
      replacement_text: "Check In Time"
    }, {
      key: "checkout_date",
      replacement_text: "Check Out Date"
    }, {
      key: "checkout_time",
      replacement_text: "Check Out Time"
    }, {
      key: "planned_arrival_time",
      replacement_text: "Planned Arrival Time"
    }, {
      key: "planned_departure_time",
      replacement_text: "Planned Departure Time"
    }, {
      key: "listing_title",
      replacement_text: "Listing Title"
    }, {
      key: "listing_headline",
      replacement_text: "Listing Headline"
    }, {
      key: "listing_address",
      replacement_text: "Listing Address"
    }, {
      key: "listing_rooms_count",
      replacement_text: "Listing Rooms Count"
    }, {
      key: "listing_address_city",
      replacement_text: "Listing Address City"
    }, {
      key: "notes_guest",
      replacement_text: "Guest Notes"
    }, {
      key: "notes_cleaning",
      replacement_text: "Cleaning Notes"
    }, {
      key: "notes_other",
      replacement_text: "Other Notes"
    }, {
      key: "recycling_frequency",
      replacement_text: "Recycling Frequency"
    }, {
      key: "recycling_day",
      replacement_text: "Recycling Day"
    }, {
      key: "trash_frequency",
      replacement_text: "Trash Frequency"
    }, {
      key: "trash_day",
      replacement_text: "Trash Day"
    }, {
      key: "listing_property_access",
      replacement_text: "Listing Property Access"
    }, {
      key: "listing_property_access_title",
      replacement_text: "Listing Property Access Title"
    }, {
      key: "listing_property_access_code",
      replacement_text: "Listing Property Access Code"
    }, {
      key: "listing_parking_note",
      replacement_text: "Listing Parking Note"
    }, {
      key: "listing_entertainment_note",
      replacement_text: "Listing Entertainment Note"
    }, {
      key: "listing_netflix_username",
      replacement_text: "Listing Netflix Username"
    }, {
      key: "listing_netflix_password",
      replacement_text: "Listing Netflix Password"
    }, {
      key: "listing_coffee_maker_type",
      replacement_text: "Listing Coffee Maker Type"
    }, {
      key: "listing_wifi_note",
      replacement_text: "Listing Wifi Note"
    }, {
      key: "listing_wifi_network",
      replacement_text: "Listing Wifi Network"
    }, {
      key: "listing_wifi_password",
      replacement_text: "Listing Wifi Password"
    }, {
      key: "listing_checklist",
      replacement_text: "Listing Check List"
    }, {
      key: "guest_full",
      replacement_text: "Guest Full Name"
    }, {
      key: "guest_first",
      replacement_text: "Guest First Name"
    }, {
      key: "guest_email",
      replacement_text: "Guest Email"
    }, {
      key: "guest_phone",
      replacement_text: "Guest Phone"
    }, {
      key: "fare_accommodation",
      replacement_text: "Accommodation Fare"
    }, {
      key: "fare_cleaning",
      replacement_text: "Cleaning Fare"
    }, {
      key: "fare_security",
      replacement_text: "Security Fare"
    }, {
      key: "host_payout",
      replacement_text: "Host Payout"
    }, {
      key: "host_service_fee",
      replacement_text: "Host Service Fee"
    }, {
      key: "host_taxes",
      replacement_text: "Host Taxes"
    }, {
      key: "our_commission",
      replacement_text: "Our Commission"
    }, {
      key: "our_fee",
      replacement_text: "Our Fee"
    }, {
      key: "confirmation_code",
      replacement_text: "Confirmation Code"
    }, {
      key: "nights_count",
      replacement_text: "Nights Count"
    }, {
      key: "guests_count",
      replacement_text: "Guests Count"
    }, {
      key: "signature",
      replacement_text: "Signature"
    }
  ];

  return data;
}

export function compareTwoDatesWithoutTime(date1: Date, date2: Date): boolean {
  const copyDate1 = date1;
  const copyDate2 = date2;
  return (copyDate1.setHours(0, 0, 0, 0) > copyDate2.setHours(0, 0, 0, 0));
}
