import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { filter } from "rxjs/operators";

import { TaskCategory, TaskEnumHelper, TaskStatus } from "../../../../enums/task.enum";
import { AttachmentType } from "../../../../enums/uploading-enum";
import { ListingCompact } from "../../../../models/new/listing/listing-compact.model";
import { TaskCompact } from "../../../../models/new/tasks/task-compact.model";
import { TaskExpense } from "../../../../models/new/tasks/task-expense.model";
import { UserCompact } from "../../../../models/new/user/user-compact.model";
import { TaskModelUtil } from "../../../../models/utils/task-model.util";
import { ListingRepository } from "../../../../repository/listing.repository";
import { OptionsRepository } from "../../../../repository/options.repository";
import { TaskRepository } from "../../../../repository/task.repository";
import { CommonUtil } from "../../../../utils/common.util";

import { SlideShowPopupComponent } from "./slide-show-popup";

@Component({
  selector: "sd-merge-task-popup", template: `
    <sd-modal-popup-layout title="Merge Tasks">
    </sd-modal-popup-layout>
    <div fxLayout="column" fxLayoutGap="30px" style="margin:10px">
      <div fxLayout="column" fxLayoutGap="30px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field [color]="'accent'" fxFlex="45%" fxLayout="row">
            <mat-select [formControl]="propertyId" placeholder="Choose a Listing">
              <mat-option *ngFor="let listing of listings"
                          [value]="listing.id">
                {{listing.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="!propertyId">Listing is Required</mat-error>

          <mat-form-field [color]="'accent'" fxFlex="45%" fxLayout="row">
            <mat-select placeholder="Type Of Task*"
                        [(ngModel)]="category"
                        [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let category of categories"
                          [value]="category">{{TaskEnumHelper.getTaskCategoryTitle(category)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field [color]="'accent'" fxFlex="45%" fxLayout="row">
            <mat-select placeholder="Assign Task to*"
                        [(ngModel)]="assigneeId"
                        [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let assignee of assignees" [value]="assignee.id">
                {{assignee.first_name}} {{assignee.last_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field [color]="'accent'" fxFlex="45%" fxLayout="row">
            <mat-select placeholder="Status"
                        [(ngModel)]="status"
                        [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let status of statusTypes"
                          [value]="status"
                          [ngStyle]="{
                              'background-color': TaskEnumHelper.getTaskStatusEnum(status).color,
                              'color': status.slug == 'waiting_for_approval' ? 'white' : 'black'
                            }">
                {{TaskEnumHelper.getTaskStatusEnum(status).title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <h4 style="font-weight: bolder"><b>Combined Expenses:</b></h4>

      <span *ngIf="expenses.length === 0"><b>No Expenses For Selected Tasks</b></span>
      <div *ngIf="expenses.length>0">
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="start start">
            <div fxFlex="59%" fxLayout="row">
              <span fxLayoutAlign="start" class="price-heading">Product</span>
            </div>
            <div fxFlex="11" fxLayout="row">
              <span fxLayoutAlign="center" class="price-heading">{{ 'Quantity' | mobileText:'Qty.' }}</span>
            </div>
            <div fxFlex="15" fxLayout="row">
              <span fxLayoutAlign="end" class="price-heading">{{ 'Price' | mobileText:'$$$' }}</span>
            </div>
            <div fxFlex="15" fxLayout="row">
              <span fxLayoutAlign="end" class="price-heading">{{ 'Amount' | mobileText:'Amt.' }}</span>
            </div>
          </div>
          <hr>

          <ng-container *ngIf="expenses.length > 0">
            <ng-container *ngFor="let expense of expenses">
              <div>
                <div class="title-detail-container" fxFlex="100%" fxLayout="row">
                  <div class="c-pointer expense-title-description mr-5"
                       [ngbPopover]="expense.desc"
                       popoverTitle="Details"
                       placement="right"
                       triggers="mouseenter:mouseleave"
                       *ngIf="expense.desc"
                  >
                  </div>
                  <div fxLayout="row" fxFlex="100%">
                    <div fxFlex="55%" fxLayout="row">
                      <span fxLayoutAlign="start" class="detail task-title truncate-1l">{{ expense.title }}</span>
                    </div>
                    <div fxFlex="15%" fxLayout="row">
                      <span fxLayoutAlign="center" class="detail">{{ expense.quantity }}</span>
                    </div>
                    <div fxFlex="15%" fxLayout="row">
                      <span fxLayoutAlign="end" class="detail">\${{ expense.price }}</span>
                    </div>
                    <div fxFlex="15%" fxLayout="row">
                      <span fxLayoutAlign="end"
                            class="detail">\${{ CommonUtil.formatLargeNumber(expense.quantity * expense.price) }}</span>
                    </div>
                  </div>
                </div>

                <div class="expense-print-content print-only">
                  <span>{{ expense.desc }}</span>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <h4 style="font-weight: bolder"><b>Combined Attachments:</b></h4>

          <span *ngIf="attachments.length === 0"><b>No Attachments For Selected Tasks</b></span>

          <div class="attachments-container" *ngIf="attachments && attachments.length > 0">
            <div fxLayout="row">
              <span fxFlex="1 1 auto"></span>
              <span style="align-self: center;">Preview</span>
              <button mat-icon-button color="accent" (click)="openSlideShowPopup()">
                <mat-icon>slideshow</mat-icon>
              </button>
            </div>
            <ng-container>
              <mat-grid-list cols="5" gutterSize="2px">
                <mat-grid-tile *ngFor="let image of attachments">
                  <div class="grid-tile-container">
                    <mat-grid-tile-header style="z-index: 3">
                      <div fxLayout="row" style="width: 100%;">
                        <button mat-icon-button (click)="preview(image.url)" matTooltip="Preview">
                          <mat-icon>visibility</mat-icon>
                        </button>
                        <span class="space-filler"></span>
                      </div>
                    </mat-grid-tile-header>
                    <img [src]="TaskModelUtil.getThumbnailUrl(image)"
                         [ngClass]="{ 'limit-width': image.type !== AttachmentType.IMAGE }" class="grid-content"/>
                    <mat-grid-tile-footer>
                        <span
                          style="text-overflow: ellipsis;white-space: nowrap; overflow: hidden">{{image.title}}</span>
                    </mat-grid-tile-footer>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
            </ng-container>
          </div>
        </div>
      </div>

      <button mat-raised-button color="accent" class="button">MERGE</button>
    </div>



  `,
  styles: [`
    .button {
      width: 40px;
      margin-left: 95%;
    }

  `]
})
export class MergeTasksPopupComponent implements OnInit {
  status: TaskStatus;
  assigneeId: number;
  category: TaskCategory;
  selectedTaskIds: number[];
  propertyId: FormControl;
  tasks: TaskCompact[];
  filteredTasks: TaskCompact[] = [];
  task: TaskCompact;
  attachments: any[] = [];
  expenses: TaskExpense[] = [];

  assignees: UserCompact[] = [];
  listings: ListingCompact[] = [];
  categories = CommonUtil.getPropsOfEnum(TaskCategory);
  statusTypes = CommonUtil.getPropsOfEnum(TaskStatus);
  TaskEnumHelper = TaskEnumHelper;
  CommonUtil = CommonUtil;
  TaskModelUtil = TaskModelUtil;
  AttachmentType = AttachmentType;

  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) data: any,
              private taskRepo: TaskRepository, private optionRepo: OptionsRepository, private listingRepo: ListingRepository) {
    this.selectedTaskIds = data.selectedIds;
    this.tasks = data.tasks;
    this.task = this.tasks[0];
    console.log(this.tasks, this.task, this.selectedTaskIds);
  }

  ngOnInit() {

    this.setupForm();
    this.propertyId = new FormControl(null, [Validators.required]);

    this.status = this.task.status;
    this.assigneeId = this.task.assignee_id;
    this.propertyId.setValue(this.task.property_id);

    this.filteredTasks = this.tasks.filter(task => this.selectedTaskIds.includes(task.id));

    console.log(this.filteredTasks);

    this.filteredTasks.forEach(task => {
      if (task.expenses.data.length > 0) {
        this.expenses = this.expenses.concat(task.expenses.data);
      }
      // if (task.attachments.data.length > 0) {
      //   console.log("entered att");
      //   this.attachments = this.attachments.concat(task.attachments.data);
      // }
    });

    console.log(this.attachments);
    console.log(this.expenses);
  }

  openSlideShowPopup() {
    console.log("Slide show popup");
    const dialogRef = this.dialog.open(SlideShowPopupComponent, {
      data: this.attachments,
      panelClass: "task-image-preview-dialog-container"
    });

    dialogRef.updateSize("100%", "100%");
    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed");
    });
  }

  preview(url: string) {
    window.open(url);
  }

  setupForm() {
    this.optionRepo.getTaskAssignees().pipe(filter(a => !!a)).subscribe((assignees) => {
      this.assignees = assignees;
    });

    this.listingRepo.getActivatedListings().subscribe((listings) => {
      this.listings = listings;
    });
  }


}
