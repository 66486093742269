import {map, take} from "rxjs/operators";
import {
  getAllConciergeDrafts,
  getFullConciergeDraftById,
  getIsConciergeDraftLoaded,
  getIsConciergeDraftLoading,
  getIsFullConciergeDraftLoaded,
  getIsFullConciergeDraftLoading,
  State
} from "../../reducers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {ConciergeDraftsService} from "../../services/drafts/concierge-info.service";
import {Injectable} from "@angular/core";
import {
  ConciergeDraftCreateRequest,
  ConciergeDraftCreateSuccess,
  ConciergeDraftDeleteRequest,
  ConciergeDraftDeleteSuccess,
  ConciergeDraftIndexRequest,
  ConciergeDraftIndexSuccess,
  ConciergeDraftUpdateRequest,
  ConciergeDraftUpdateSuccess
} from "../../actions/new/setttings/drafts/concierge-drafts";
import {ConciergeDraft} from "../../models/new/drafts/concierge-draft.model";

@Injectable()
export class ConciergeDraftRepository {

  constructor(private store: Store<State>,
              private conciergeDraftService: ConciergeDraftsService) {
  }

  /**
   * Loading & Loaded
   */
  getIsConciergeDraftLoading(): Observable<boolean> {
    return this.store.select(getIsConciergeDraftLoading);
  }

  getIsConciergeDraftLoaded(): Observable<boolean> {
    return this.store.select(getIsConciergeDraftLoaded);
  }

  getIsFullConciergeDraftLoading(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullConciergeDraftLoading(state, draftId));
  }

  getIsFullConciergeDraftLoaded(draftId: number): Observable<boolean> {
    return this.store.select(state => getIsFullConciergeDraftLoaded(state, draftId));
  }

  /**
   * Service Methods
   */
  getAllConciergeDrafts(force: boolean): Observable<ConciergeDraft[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsConciergeDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsConciergeDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new ConciergeDraftIndexRequest());

      this.conciergeDraftService.getConciergeDrafts().subscribe(response => {
        this.store.dispatch(new ConciergeDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(getAllConciergeDrafts).pipe(map(t => t as ConciergeDraft[]));
  }

  getConciergeDraftById(draftId: number): Observable<ConciergeDraft> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsConciergeDraftLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsConciergeDraftLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new ConciergeDraftIndexRequest());

      this.conciergeDraftService.getConciergeDrafts().subscribe(response => {
        this.store.dispatch(new ConciergeDraftIndexSuccess(response.data));
      });
    }

    return this.store.select(state => getFullConciergeDraftById(state, draftId)).pipe(
      map(t => t as ConciergeDraft));
  }


  createConciergeDraft(data: Partial<ConciergeDraft>): Observable<ConciergeDraft> {
    this.store.dispatch(new ConciergeDraftCreateRequest());
    return this.conciergeDraftService.createConciergeDraft(data).pipe(map(res => {
      this.store.dispatch(new ConciergeDraftCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateConciergeDraft(draftId: number, data: Partial<ConciergeDraft>): Observable<ConciergeDraft> {
    this.store.dispatch(new ConciergeDraftUpdateRequest(draftId));
    return this.conciergeDraftService.updateConciergeDraft(draftId, data).pipe(map(res => {
      this.store.dispatch(new ConciergeDraftUpdateSuccess(res.data));
      return res.data;
    }));
  }

  deleteConciergeDraft(draftId: number): Observable<null> {
    this.store.dispatch(new ConciergeDraftDeleteRequest(draftId));
    return this.conciergeDraftService.deleteConciergeDraft(draftId).pipe(map(res => {
      this.store.dispatch(new ConciergeDraftDeleteSuccess(draftId));
      return res;
    }));
  }
}

