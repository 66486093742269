import { OnboardingTaskCompact } from "../../../models/new/tasks/onboarding-task-compact.model";
import { OnboardingTaskFull } from "../../../models/new/tasks/onboarding-task-full.model";
import { Action } from "../../action";

export enum OnboardingTaskActionTypes {
  INDEX_REQUEST = "[ONBOARDING TASK NEW] Index Request",
  INDEX_SUCCESS = "[ONBOARDING TASK NEW] Index Success",

  SHOW_REQUEST = "[ONBOARDING TASK NEW] Show Request",
  SHOW_SUCCESS = "[ONBOARDING TASK NEW] Show Success",

  CREATE_SUCCESS = "[ONBOARDING TASK NEW] Create Success",
  UPDATE_SUCCESS = "[ONBOARDING TASK NEW] Update Success",
  DELETE_SUCCESS = "[ONBOARDING TASK NEW] DELETE Success",

  ADD_ONBOARDING_TASK_TO_NOT_EXIST = "[ONBOARDING TASK NEW] Add Onboarding Task To Not Exist"
}

export class OnbordingTaskIndexRequest implements Action {
  readonly type = OnboardingTaskActionTypes.INDEX_REQUEST;
}

export class OnbordingTaskIndexSuccess implements Action {
  readonly type = OnboardingTaskActionTypes.INDEX_SUCCESS;

  constructor(public payload: OnboardingTaskCompact[]) {
  }
}

export class OnbordingTaskShowRequest implements Action {
  readonly type = OnboardingTaskActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {
  }
}

export class OnbordingTaskShowSuccess implements Action {
  readonly type = OnboardingTaskActionTypes.SHOW_SUCCESS;

  constructor(public payload: OnboardingTaskFull) {
  }
}

export class OnbordingTaskCreateSuccess implements Action {
  readonly type = OnboardingTaskActionTypes.CREATE_SUCCESS;

  constructor(public payload: OnboardingTaskFull) {
  }
}

export class OnbordingTaskUpdateSuccess implements Action {
  readonly type = OnboardingTaskActionTypes.UPDATE_SUCCESS;

  constructor(public payload: OnboardingTaskFull) {
  }
}

export class OnbordingTaskDeleteSuccess implements Action {
  readonly type = OnboardingTaskActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}

export class AddOnboardingTaskToNotExist implements Action {
  readonly type = OnboardingTaskActionTypes.ADD_ONBOARDING_TASK_TO_NOT_EXIST;

  constructor(public payload: number) {
  }
}
