export enum AutoMessageOffsetReference {
  CHECK_IN = "check_in",
  CHECK_OUT = "check_out",
  BOOKING_CONFIRMATION = "booking_confirmation"
}

export enum AutoMessageSendVia {
  TEXT = "text",
  EMAIL = "email",
  BOOKING_CHANNEL = "booking_channel"
}

export enum AutoMessageTo {
  HVAC = "hvac",
  PLUMBER = "plumber",
  PAINTER = "painter",
  ELECTRICIAN = "electrician",
  INSPECTOR = "inspector",
  GENERAL_MAINTENANCE = "general_maintenance",
  HOMEOWNER = "homeowner",
  HOUSEKEEPER = "housekeeper",
  SALES = "sales",
  PREVENTIVE_MAINTENANCE = "preventive_maintenance",
  LANDSCAPING = "landscaping",
  GUEST = "guest",
  OWNER = "owner"
}

export class AutoMessageEnumHelper {

  static getAutoMessageOffsetReferenceTitle(ref: AutoMessageOffsetReference): string {
    let title = "";
    switch (ref) {
      case AutoMessageOffsetReference.CHECK_IN : {
        title = "Check In";
        break;
      }

      case AutoMessageOffsetReference.CHECK_OUT : {
        title = "Check Out";
        break;
      }

      case AutoMessageOffsetReference.BOOKING_CONFIRMATION : {
        title = "Booking Confirmation";
        break;
      }
    }
    return title;
  }


  static getAutoMessageSendViaTitle(ref: AutoMessageSendVia): string {
    let title = "";
    switch (ref) {
      case AutoMessageSendVia.TEXT : {
        title = "Text";
        break;
      }

      case AutoMessageSendVia.EMAIL : {
        title = "Email";
        break;
      }

      case AutoMessageSendVia.BOOKING_CHANNEL : {
        title = "Booking Channel";
        break;
      }
    }
    return title;
  }


  static getAutoMessageToTitle(ref: AutoMessageTo): string {
    let title = "";
    switch (ref) {
      case AutoMessageTo.HVAC : {
        title = "Hvac";
        break;
      }

      case AutoMessageTo.PLUMBER : {
        title = "Plumber";
        break;
      }

      case AutoMessageTo.PAINTER : {
        title = "Painter";
        break;
      }

      case AutoMessageTo.ELECTRICIAN : {
        title = "Electrician";
        break;
      }

      case AutoMessageTo.INSPECTOR : {
        title = "Inspector";
        break;
      }

      case AutoMessageTo.GENERAL_MAINTENANCE : {
        title = "General Maintenance";
        break;
      }

      case AutoMessageTo.HOMEOWNER : {
        title = "Homeowner";
        break;
      }

      case AutoMessageTo.HOUSEKEEPER: {
        title = "Housekeeper";
        break;
      }

      case AutoMessageTo.SALES : {
        title = "Sales";
        break;
      }

      case AutoMessageTo.PREVENTIVE_MAINTENANCE : {
        title = "Preventive Maintenance";
        break;
      }

      case AutoMessageTo.LANDSCAPING : {
        title = "Landscaping";
        break;
      }

      case AutoMessageTo.GUEST : {
        title = "Guest";
        break;
      }

      case AutoMessageTo.OWNER: {
        title = "Owner";
        break;
      }
    }

    return title;
  }

}
