import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { MultiListingGetResponse } from "../models/responses/settings/multi-listing-get.response";
import { MultiListingShowResponse } from "../models/responses/settings/multi-listing-show.response";
import { MultiListing } from "../models/new/multi-listing.model";
import { __HTTPResponseType } from "../enums/common.enum";

@Injectable()
export class MultiListingService {

  public constructor(private apiService: ApiService) {
  }

  getMultiListings(): Observable<MultiListingGetResponse> {
    return this.apiService.get<MultiListingGetResponse>("/admin/multi-listings", true);
  }

  // TODO Currently not supported by the backend.
  // showMultiListing(cannedResponseId: number): Observable<MultiListingShowResponse> {
  //   return this.apiService.get<MultiListingShowResponse>("/admin/multi-listings/" + cannedResponseId, true);
  // }

  createMultiListing(data: any): Observable<MultiListingShowResponse> {
    return this.apiService.post<MultiListingShowResponse>("/admin/multi-listings", true, data);
  }

  updateMultiListing(multiListingId: number, data: any): Observable<MultiListingShowResponse> {
    return this.apiService.put<MultiListingShowResponse>("/admin/multi-listings/" + multiListingId, true, data);
  }

  attachListing(multiListingId: number, listingIds: number[]): Observable<MultiListingShowResponse> {
    return this.apiService.put<MultiListingShowResponse>("/admin/multi-listings/" + multiListingId + "/attach", true, {
      listing_ids: listingIds
    });
  }

  deleteMultiListing(cannedResponseId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/multi-listings/" + cannedResponseId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}
