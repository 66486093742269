import { Component, Input, OnInit } from "@angular/core";

import { TaskFull } from "../../../../models/new/tasks/task-full.model";
import { ListingRepository } from "../../../../repository/listing.repository";
import { TaskService } from "../../../../services/task.service";

@Component({
  selector: "sd-assignee-change", template: `
    <sd-modal-popup-layout title="Change Listing">
    </sd-modal-popup-layout>
    <div fxLayout="column">
      <div fxLayout="row" fxFlex="100%">
        <mat-form-field [color]="'accent'" fxFlex="40%" fxLayout="row" style="margin-top:2%;">
          <mat-select
            placeholder="Select Listing" [(ngModel)]="selectedListingId">
            <mat-option *ngFor="let listingId of allListingIds" [value]="listingId">
              {{ getListingTitleFromId(listingId) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%">
        <button mat-raised-button color="primary" matDialogClose>Cancel</button>
        <button [disabled]="isUpdating" mat-raised-button color="accent" (click)="changeListing()">Save</button>
        <mat-spinner [diameter]="30" [strokeWidth]="4" color="accent" *ngIf="isUpdating"></mat-spinner>

      </div>
    </div>

  `,
  styles: [`

  `]
})

export class ListingChangePopupComponent implements OnInit {

  @Input() task: TaskFull;

  isUpdating: boolean = false;
  allListingIds: number[];
  selectedListingId: number;

  constructor(private listingRepo: ListingRepository,
              private service: TaskService) {

  }

  ngOnInit() {
    this.selectedListingId = this.task.property_id;
    this.listingRepo.getAllActivatedListingIds().subscribe((listingIds) => {
      this.allListingIds = listingIds;
    });
    console.log(this.task);
    console.log(this.selectedListingId);
  }

  getListingTitleFromId(id: number): string {
    return this.listingRepo.getListingTitle(id);
  }

  changeListing() {
    this.isUpdating = true;
    this.service.updateTask(this.task.id, {property_id: this.selectedListingId}).subscribe(res => {
      this.isUpdating = false;
      console.log("success");
    });
  }
}
