import { AfterViewInit, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

import { AutoMessageOffsetReference } from "../../../enums/auto-message.enum";
import { Alert } from "../../../models/alert";
import { AutomatedMessageCompact } from "../../../models/new/automation/automated-messages-compact.model";
import { AutomatedMessageFull } from "../../../models/new/automation/automated-messages-full.model";
import { State } from "../../../reducers";
import { AutomatedMessageRepository } from "../../../repository/automated-message.repository";
import { AppService } from "../../../services/app.service";
import { StayDuvetService } from "../../../services/stayduvet";
import Utils from "../../../utils/utils";

import { SettingsAlertPopupComponent } from "./automated-messages-popup/check-in-instructions-popup";

@Component({
  selector: "sd-setting-automated-messages",
  template: `
    <div fxLayout="column" fxLayoutGap="10px" fxFlex="100%">
      <div fxLayoutAlign="start center" *ngIf="!listingId">
        <button mat-raised-button color="primary" (click)="openTools()">
          <mat-icon>arrow_back</mat-icon>
          Back
        </button>
      </div>
      <div fxLayout="column" fxLayoutGap="5px" fxFlex="80%">

        <div style="margin-top: 10px;" fxLayoutAlign="space-between start">
          <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px" class="para">
            <span class="heading"></span>
          </div>
          <div>
            <button mat-icon-button (click)="refresh()">
              <mat-icon>autorenew</mat-icon>
            </button>
            <button mat-raised-button color="accent" (click)="openCreateDialog()">Add a new Auto Message</button>
          </div>
        </div>
        <hr>

        <div fxLayoutAlign="center center" *ngIf="isLoading" style="height: 400px; width: 100%">
          <mat-spinner [color]="'accent'" [diameter]="60" [strokeWidth]="5"></mat-spinner>
        </div>

        <div *ngIf="isLoaded" fxLayout="column" fxLayoutGap="10px">
          <div>
            <div style="margin-left: 2%; margin-top: 15px; margin-bottom: 30px ;margin-right: 2%" fxLayout="column"
                 *ngFor="let section of sections"
                 fxLayoutGap="10px" fxLayoutAlign.lt-sm="space-between start">
              <div fxLayout="row " fxLayoutGap="10px" fxLayoutAlign="start center">
                <div class="vertical-line"></div>
                <h3>{{section.title}}</h3>
              </div>

              <h4 style="text-align: center; font-size: small" *ngIf="section.showAlerts.length ==0 ">
                No Auto Messages Found For {{section.title}}.
              </h4>
              <div fxFlex="100%" fxLayoutGap="10px">
                <sd-automated-messages-detail *ngFor="let alert of section.showAlerts"
                                              [alert]="alert">
                </sd-automated-messages-detail>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  `,
  styles: [`

    mat-spinner {
      width: 100px;
      height: 100px;
    }

    .mat-button {
      height: 30px;
      line-height: 20px;
      min-height: 25px;
      vertical-align: top;
      font-size: 13px;
      color: white;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin: 0;
    }

    .padding {
      padding: -10px 0px -10px -5px;
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    .card {
      padding: 15px;
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    .mat-card {
      border: 1px solid lightgrey !important;
      box-shadow: none !important;
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    .content {
      font-size: 14px;
      line-height: 130%;
    }

    strong {
      font-weight: bolder !important;
      letter-spacing: 1px !important;
      font-size: 18px !important;
      font-family: 'Roboto', sans-serif !important;
    }

    .heading {
      font-weight: bolder;
      font-size: 22px;
    }

    div.vertical-line {
      width: 5px; /* Line width */
      background-color: green; /* Line color */
      height: 20px; /* Override in-line if you want specific height. */
      float: left;
      /* Causes the line to float to left of content. 
             You can instead use position:absolute or display:inline-block
             if this fits better with your design */
    }`]
})
export class SettingsAutomatedMessagesComponent implements OnInit, AfterViewInit, OnDestroy {

  destroyed$ = new Subject();
  sections: { title: string, showAlerts: (AutomatedMessageCompact | AutomatedMessageFull)[] }[] = [];


  isLoading: boolean;
  isLoaded: boolean;
  alerts: (AutomatedMessageCompact | AutomatedMessageFull)[];


  Utils = Utils;

  @Input() checkInAutomatedMessages: (AutomatedMessageCompact | AutomatedMessageFull)[] = [];
  @Input() checkOutAutomatedMessages: (AutomatedMessageCompact | AutomatedMessageFull)[] = [];
  @Input() reservedAutomatedMessages: (AutomatedMessageCompact | AutomatedMessageFull)[] = [];

  @Input() listingId;
  private dialogRef: MatDialogRef<any>;

  constructor(private dialog: MatDialog,
              private service: StayDuvetService,
              private store: Store<State>,
              private automatedMessageRepo: AutomatedMessageRepository,
              private router: Router,
              private appService: AppService) {
  }

  ngOnInit(): void {
    console.log("onInit sd-setting-automated-messages");

    if (!this.listingId) {
      this.setUpAutomatedMessages();
    } else {
      this.sections = [
        {
          title: "Check In",
          showAlerts: this.checkInAutomatedMessages
        },
        {
          title: "Check Out",
          showAlerts: this.checkOutAutomatedMessages
        },
        {
          title: "Reservation Confirmed",
          showAlerts: this.reservedAutomatedMessages
        }
      ];

      this.isLoaded = true;
      this.isLoading = false;
    }
  }

  ngAfterViewInit() {
    this.appService.changeAppTitle("Settings - Automated-Messages");
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setUpAutomatedMessages() {
    this.automatedMessageRepo.getIsAutomatedMessageLoading().pipe(takeUntil(this.destroyed$)).subscribe(l => this.isLoading = l);
    this.automatedMessageRepo.getIsAutomatedMessageLoaded().pipe(takeUntil(this.destroyed$)).subscribe(l => this.isLoaded = l);

    this.automatedMessageRepo.getAllAutomatedMessages(false, this.listingId).subscribe((alerts) => {
      this.alerts = alerts;
      this.filterAlerts();
    });
  }

  filterAlerts() {
    const checkIn = [];
    const checkOut = [];
    const reservation = [];

    console.log("[SEMPER]", this.alerts);

    this.alerts.map((alert) => {
      if (alert.offset_reference === AutoMessageOffsetReference.CHECK_IN) {
        checkIn.push(alert);
      } else if (alert.offset_reference === AutoMessageOffsetReference.CHECK_OUT) {
        checkOut.push(alert);
      } else {
        reservation.push(alert);
      }
    });

    this.sections = [
      {
        title: "Check In",
        showAlerts: checkIn
      },
      {
        title: "Check Out",
        showAlerts: checkOut
      },
      {
        title: "Reservation Confirmed",
        showAlerts: reservation
      }
    ];

    console.log("[SEMPER]", this.sections);
  }

  openDialog(alert: Alert) {
    const data = {
      id: alert.id
    };
    this.dialogRef = this.dialog.open(SettingsAlertPopupComponent, {
      data: data
    });
    this.dialogRef.updateSize("100%", "100%");
  }

  openCreateDialog() {
    this.dialogRef = this.dialog.open(SettingsAlertPopupComponent, {
      data: {
        alert: null
      }
    });
    this.dialogRef.updateSize("100%", "100%");
  }

  openTools() {
    this.router.navigate(["/settings/tools"]);
  }

  refresh() {
    this.isLoading = true;
    this.isLoaded = false;
    this.automatedMessageRepo.getAllAutomatedMessages(true, this.listingId).pipe(take(1)).subscribe();
  }
}
