import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { BookingFull } from "../../../models/new/booking/booking-full.model";
import { ThreadFull } from "../../../models/new/inbox/thread-full";
import { ThreadRepository } from "../../../repository/thread.repository";
import { getDateObj, getSDDayObject, SDDay } from "../../../utils/calendar-utils";

@Component({
  selector: "sd-message-special-offer-popup",
  template: `
    <sd-modal-popup-layout title="Send Special Offer to Guest">
      <div fxLayout="column" fxLayoutGap="20px">
        <form fxLayout="column" [formGroup]="formGroup" (ngSubmit)="formGroup.valid && saveButtonCLicked()">
          <div fxLayoutAlign="space-between center">
            <mat-form-field [color]="'accent'" class="equal-width">
              <input matInput [matDatepicker]="checkInPicker" [min]="minStartDate" placeholder="Check In"
                     formControlName='start' [(ngModel)]="minEndDate">
              <mat-datepicker-toggle matSuffix [for]="checkInPicker"></mat-datepicker-toggle>
              <mat-datepicker #checkInPicker></mat-datepicker>
            </mat-form-field>

            <mat-icon>arrow_forward</mat-icon>

            <mat-form-field [color]="'accent'" class="equal-width">
              <input matInput [matDatepicker]="checkOutPicker" [min]="minEndDate" placeholder="Check Out"
                     formControlName='end'>
              <mat-datepicker-toggle matSuffix [for]="checkOutPicker"></mat-datepicker-toggle>
              <mat-datepicker #checkOutPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field [color]="'accent'" class="equal-width">
              <input matInput placeholder="Number Of Adults" type="number"
                     formControlName='number_of_adults'>
            </mat-form-field>

            <mat-form-field [color]="'accent'" class="equal-width">
              <input matInput placeholder="Offer Price" type="number"
                     formControlName='price'>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="80px">
            <mat-form-field [color]="'accent'" class="equal-width">
              <input matInput placeholder="Number Of Children" type="number"
                     formControlName='number_of_children'>
            </mat-form-field>

            <mat-form-field [color]="'accent'" class="equal-width">
              <input matInput placeholder="Number Of Infants" type="number"
                     formControlName='number_of_infants'>
            </mat-form-field>
          </div>

          <div *ngIf="!!booking" style="width:30%;">
            <sd-small-calendar [showArrowButtons]="showArrowButtons" [currentMonth]="currentMonth"
                               [listingId]="booking.property_id"></sd-small-calendar>
          </div>

          <div fxLayoutGap="10px" fxLayoutAlign=" center" fxFlexAlign="end">
            <mat-spinner color="accent" *ngIf="isUpdating" [diameter]="30" [strokeWidth]="4"></mat-spinner>
            <button mat-raised-button fxFlexAlign="end" [disabled]="isUpdating" color="accent" type="submit">
              OK
            </button>
          </div>
        </form>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`
    hr {
      width: 100%;
    }

    mat-spinner {
      height: 30px;
      width: 30px;
    }

    .equal-width {
      width: 22%;
    }

    .entryInputs {
      width: 45%
    }
  `]
})
export class MessageSpecialOfferPopupComponent implements OnInit {
  booking: BookingFull;
  thread: ThreadFull;

  formGroup: FormGroup;
  checkIn: FormControl;
  checkOut: FormControl;
  adultCount: FormControl;
  childCount: FormControl;
  infantCount: FormControl;
  price: FormControl;

  minStartDate: Date;
  minEndDate: Date;
  currentMonth: SDDay;

  isUpdating = false;
  showArrowButtons: boolean = false;

  constructor(private threadRepo: ThreadRepository,
              @Inject(MAT_DIALOG_DATA) data: { booking: BookingFull, thread: ThreadFull },
              private dialogRef: MatDialogRef<MessageSpecialOfferPopupComponent>) {
    this.booking = data.booking;
    this.thread = data.thread;

    this.checkIn = new FormControl(null, [Validators.required]);
    this.checkOut = new FormControl(null, [Validators.required]);
    this.adultCount = new FormControl(1, [Validators.required, Validators.min(1)]);
    this.childCount = new FormControl(null);
    this.infantCount = new FormControl(null);
    this.price = new FormControl(null, [Validators.required, Validators.min(10)]);

    this.formGroup = new FormGroup({
      start: this.checkIn,
      end: this.checkOut,
      number_of_adults: this.adultCount,
      number_of_children: this.childCount,
      number_of_infants: this.infantCount,
      price: this.price
    });

    this.minStartDate = getDateObj();
    this.minEndDate = getDateObj();
    this.currentMonth = getSDDayObject(this.minStartDate);
  }

  ngOnInit(): void {
    console.log("onInit sd-message-special-offer-popup");

    this.checkIn.valueChanges
      .subscribe((value) => {
        this.currentMonth = getSDDayObject(value);
      });

  }

  saveButtonCLicked() {
    this.isUpdating = true;
    const data = this.formGroup.value;

    this.threadRepo.sendSpecialOffer(this.thread.id, this.booking.id, this.formGroup.value).subscribe((success) => {
      this.isUpdating = false;
      this.dialogRef.close();
    }, (error) => {
      this.isUpdating = false;
    });
  }
}
