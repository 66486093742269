import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, takeWhile } from "rxjs/operators";

import { AccountType, ContactEnumHelper } from "../../../../enums/contact.enum";
import { ListingCompact } from "../../../../models/new/listing/listing-compact.model";
import { ContactRepository } from "../../../../repository/contact.repository";
import { ListingRepository } from "../../../../repository/listing.repository";
import { UserRepository } from "../../../../repository/user-repository";
import { StayDuvetService } from "../../../../services/stayduvet";
import { CommonUtil } from "../../../../utils/common.util";
import Utils, { getContactMaintenanceCatagoryTypes, getContactMethodTypes, ValidateMobileNumber } from "../../../../utils/utils";

@Component({
  selector: "sd-create-contact-popup",
  template: `
    <sd-modal-popup-layout title="Create Contact" [showPrintAction]="true" (printAction)="printButtonClicked()">
      <form fxLayout="column" fxLayoutGap="20px" [formGroup]="formGroup"
            (ngSubmit)="formGroup.valid && saveButtonCLicked()">
        <div fxLayoutAlign="space-between center">
          <mat-form-field [color]="'accent'" class="width45">
            <input matInput placeholder="First Name" formControlName='first_name'>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
          <mat-form-field [color]="'accent'" class="width45">
            <input matInput placeholder="Last Name" formControlName='last_name'>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>
        <div fxLayoutAlign="space-between center">
          <mat-form-field [color]="'accent'" class="width45">
            <input matInput placeholder="Company Name" formControlName='company_name'>
          </mat-form-field>
          <mat-form-field [color]="'accent'" class="width45">
            <input matInput placeholder="Position in the Company" formControlName='position'>
          </mat-form-field>
        </div>
        <div fxLayoutAlign="space-between center">
          <mat-form-field [color]="'accent'" class="width45">
            <input matInput placeholder="Email" formControlName='email'>
            <mat-error>Valid email is required</mat-error>
          </mat-form-field>
          <mat-form-field [color]="'accent'" class="width45">
            <input matInput placeholder="Secondary Email" formControlName='secondary_email'>
            <mat-error>Valid email is required</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" style="width:100%">
          <mat-form-field [color]="'accent'" class="width45">
            <input matInput placeholder="Phone" formControlName='phone_number'>
            <mat-error>Enter the valid Phone No.</mat-error>
          </mat-form-field>

          <mat-form-field [color]="'accent'" class="width45">
            <input matInput placeholder="Secondary Phone" formControlName='secondary_phone_number'>
            <mat-error>Enter the valid Phone No.</mat-error>
          </mat-form-field>

        </div>

        <div fxLayoutAlign="space-between center" *ngIf="!isAddingEmployee">
          <mat-form-field [color]="'accent'" class="width45">
            <input matInput placeholder="Mailing Address" formControlName="mailing_address">
          </mat-form-field>

          <mat-form-field [color]="'accent'" class="width45">
            <input matInput placeholder="SSN/EIN" formControlName="identification_number">
          </mat-form-field>
        </div>


        <div fxLayoutAlign="space-between center" fxLayoutAlign.lt-sm="space-between flex-start" fxLayout.lt-sm="column"
             style="width: 100% !important;">
          <sd-select class="width-1" placeholder="Add To A Listing" [multiple]="true"
                     [control]="listingIds" [options]="listingsOptions"></sd-select>

          <sd-select class="width-1" *ngIf="!isAddingEmployee" error="This field is required"
                     placeholder="Type" [control]="category" [options]="categoryOptions"></sd-select>

          <sd-select class="width-1" error="This field is required" placeholder="Contact Method"
                     [control]="preferredContactMethod" [options]="contactMethodsOptions"></sd-select>
        </div>
        <mat-form-field [color]="'accent'">
          <textarea matInput placeholder="Notes" rows="4" formControlName='description'></textarea>
        </mat-form-field>
        <span style="font-size: medium;font-weight: bold;" *ngIf="accounts.length !== 0">Bank Details</span>
        <div fxLayout="column" fxLayoutGap="30px" *ngFor="let item of accounts.controls;let i = index;"
             style="border:1px solid black;">
          <div fxLayoutAlign="space-between center" style="padding:10px">
            <mat-form-field [color]="'accent'" style="width: 30%">
              <input matInput placeholder="Name on account" style="width: 100%"
                     [formControl]="item.get('account_name')">
            </mat-form-field>

            <sd-select style="width: 30%" placeholder="Type of account" error="This field is required"
                       [control]="item.get('account_type')" [options]="accountTypesOptions"></sd-select>

            <mat-form-field [color]="'accent'" style="width: 30%">
              <input matInput placeholder="Bank Name" [formControl]="item.get('bank_name')">
            </mat-form-field>
          </div>


          <div fxLayoutAlign="space-between center" style="padding:10px">
            <mat-form-field [color]="'accent'" style="width: 45%">
              <input matInput placeholder="Routing Number" style="width: 100%" type="text"
                     [formControl]="item.get('routing_number')">
            </mat-form-field>
            <mat-form-field [color]="'accent'" style="width: 45%">
              <input matInput placeholder="Account Number" style="width: 100%" type="text"
                     [formControl]="item.get('account_number')">
            </mat-form-field>
          </div>
          <div fxLayoutAlign="end">
            <button mat-icon-button color="warn" (click)="deleteForm(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>


        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <mat-spinner *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          <button mat-raised-button color="accent" [disabled]="isSaving" type="submit">Save</button>
          <button *ngIf="!isAddingEmployee" mat-raised-button color="accent" [disabled]="isSaving" type="button"
                  (click)="addAccount()">Add
            Account
          </button>
        </div>
      </form>
    </sd-modal-popup-layout>

  `,
  styles: [`
    .width45 {
      width: 45%;
    }

    mat-spinner {
      width: 30px;
      height: 30px;
    }

    .select-button {
      padding: 6px;
      text-align: left;
      font-size: 17px;
      padding-left: 10px;
      font-weight: bolder;
    }

    .width-1 {
      width: 30%;
    }

    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 90%;
      }
    }
  `]
})

export class CreateContactPopupComponent implements OnInit, OnDestroy {

  listings: ListingCompact[] = [];
  listingsOptions: {title: string, value: any}[] = [];
  isSaving = false;
  categories;
  contactMethods;
  contactMethodsOptions: {title: string, value: any}[] = [];
  accountTypes = CommonUtil.getPropsOfEnum<AccountType>(AccountType);
  accountTypesOptions: {title: string, value: any}[] = [];
  @Input() listing: ListingCompact;
  @Input() isAddingEmployee = false;
  @Input() fromOnboarding = false;
  isHouseKeeper = false;
  isVendor = false;
  formGroup: FormGroup;
  firstName: FormControl;
  lastName: FormControl;

  companyName: FormControl;
  positionInTheCompany: FormControl;

  email: FormControl;
  secondaryEmail: FormControl;
  phoneNumber: FormControl;
  secondaryPhoneNumber: FormControl;
  category: FormControl;
  categoryOptions: {title: string, value: any}[] = [];
  preferredContactMethod: FormControl;
  listingIds: FormControl;
  description: FormControl;
  identificationNumber: FormControl;
  mailingAddress: FormControl;
  accounts: FormArray;
  accountForm: FormGroup;
  private isAlive = true;

  constructor(private service: StayDuvetService,
              private dialog: MatDialog,
              private listingRepo: ListingRepository,
              private userRepo: UserRepository,
              private contactRepo: ContactRepository,
              private snackBar: MatSnackBar,
              private router: Router,
              private formBuilder: FormBuilder
  ) {
    this.firstName = new FormControl(null, [Validators.required]);
    this.lastName = new FormControl(null, [Validators.required]);

    this.companyName = new FormControl(null, []);
    this.positionInTheCompany = new FormControl(null, []);
    this.email = new FormControl(null, [Validators.required]);
    this.secondaryEmail = new FormControl(null, []);
    this.phoneNumber = new FormControl(null, [ValidateMobileNumber]);
    this.secondaryPhoneNumber = new FormControl(null, [ValidateMobileNumber]);
    this.category = new FormControl(null, [Validators.required]);
    this.preferredContactMethod = new FormControl(null, [Validators.required]);
    this.listingIds = new FormControl(null, []);
    this.description = new FormControl(null, []);
    this.identificationNumber = new FormControl(null, []);
    this.mailingAddress = new FormControl();

    this.accounts = new FormArray([]);
    this.accountForm = this.formBuilder.group({accounts: this.accounts});

    this.formGroup = new FormGroup({
      first_name: this.firstName,
      last_name: this.lastName,
      company_name: this.companyName,
      position: this.positionInTheCompany,
      email: this.email,
      secondary_email: this.secondaryEmail,
      phone_number: this.phoneNumber,
      secondary_phone_number: this.secondaryPhoneNumber,
      category: this.category,
      preferred_contact_method: this.preferredContactMethod,
      listing_ids: this.listingIds,
      description: this.description,
      identification_number: this.identificationNumber,
      mailing_address: this.mailingAddress,
      bank_info: this.accounts
    });
  }

  ngOnInit() {
    this.categories = getContactMaintenanceCatagoryTypes();
    this.categoryOptions = this.categories.map(category => {
      return {title: category.title, value: category.slug};
    });

    this.contactMethods = getContactMethodTypes();
    this.contactMethodsOptions = this.contactMethods.map(method => {
      return {title: method.title, value: method.slug};
    });

    this.accountTypesOptions = this.accountTypes.map(type => {
      return {title: ContactEnumHelper.getAccountType(type), value: type};
    });

    if (this.listing) {
      this.listingIds.setValue([this.listing.id]);
      this.listings = [this.listing];
      this.listingsOptions = this.listings.map(listing => {
        return {title: listing.title, value: listing.id};
      });
      return;
    }
    this.listingRepo.getAllListings().subscribe((listings) => {
      this.listings = listings;
      this.listingsOptions = this.listings.map(listing => {
        return {title: listing.title, value: listing.id};
      });
    });

    this.userRepo.getUser().pipe(
      takeWhile(() => this.isAlive))
      .subscribe(user => {
        this.isHouseKeeper = CommonUtil.isHouseKeeper(user);
        this.isVendor = CommonUtil.isVendor(user);
        if (this.isVendor) {
          this.category.patchValue(user?.managementContact?.data?.category);
        }
      });
  }

  printButtonClicked() {
    console.log("create contact print");
  }

  saveButtonCLicked() {
    let data = this.formGroup.value;
    data = Utils.removeNullFields(data);

    if (!data.email && !data.phone_number) {
      this.snackBar.open("Email or Phone No is required.", "Dismiss", {
        duration: 1000
      });
      return;
    }

    if (!data.email && data.preferred_contact_method === "email") {
      this.snackBar.open("Invalid contact method.", "Dismiss", {
        duration: 1000
      });
      return;
    }

    if (!data.phone_number && data.preferred_contact_method === "phone") {
      this.snackBar.open("Invalid contact method.", "Dismiss", {
        duration: 1000
      });
      return;
    }

    this.isSaving = true;
    this.contactRepo.createNewContact(data).subscribe((res) => {
      this.isSaving = false;
      if (!this.fromOnboarding) {
        this.redirect(res.id);
      }
      this.dialog.closeAll();
    }, () => {
      this.isSaving = false;
    });
  }

  redirect(id: number) {
    if (!this.isHouseKeeper) {
      this.router.navigate(["/contacts/" + id]);
    } else {
      this.router.navigate(["/employees/" + id]);
    }
  }

  addAccount() {
    this.accounts.push(this.createAccount());
  }

  private createAccount(): FormGroup {
    return new FormGroup({
      bank_name: new FormControl(null, []),
      account_name: new FormControl(null),
      account_number: new FormControl(null, [Validators.required]),
      account_type: new FormControl(null, [Validators.required]),
      routing_number: new FormControl(null, [Validators.required]),
    });
  }

  deleteForm(index) {
    this.accounts.removeAt(index);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
