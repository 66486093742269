import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatest as observableCombineLatest, Observable } from "rxjs";

import { map } from "rxjs/operators";
import { SetLandingUrlAction } from "../actions/app";
import { getAppIsBootstrapped, State } from "../reducers/index";
import { UserRepository } from "../repository/user-repository";
import { AppService } from "../services/app.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private userRepo: UserRepository,
              private store: Store<State>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {

    if (!AppService.hasLoginToken()) {
      this.store.dispatch(new SetLandingUrlAction(state.url));
      console.log("Inside auth", state.url);
      this.router.navigate(["/login"]);
      return false;
    }

    const observables = observableCombineLatest(
      this.userRepo.getIsUserLoggedIn(),
      this.store.select(getAppIsBootstrapped),
      (isLoggedIn, isBootstrapped) => {
        return {
          isLoggedIn: isLoggedIn,
          isBootstrapped: isBootstrapped
        };
      }
    );

    return observables.pipe(map((data) => {
      if (!data.isLoggedIn || !data.isBootstrapped) {
        this.store.dispatch(new SetLandingUrlAction(state.url));
        this.router.navigate(["/"]);
      }

      return data.isLoggedIn;
    }));
  }
}
