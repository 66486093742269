import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { filter, skip, takeUntil } from "rxjs/operators";

import { HomeCreateOwnerBlockPopupComponent } from "../../components/create-owner-block-home-popup";
import { BookingCompact } from "../../models/new/booking/booking-compact.model";
import { ListingCompact } from "../../models/new/listing/listing-compact.model";
import { UserCompact } from "../../models/new/user/user-compact.model";
import { UserFull } from "../../models/new/user/user-full.model";
import { OwnerDashStats } from "../../models/owner-dash-stats";
import { UserModelUtil } from "../../models/utils/user-model.util";
import { HomeOwnerReportDownloadPopupComponent } from "../../modules/shared/components/download-report-home-popup";
import { PropertyIncomeReportPopupComponent } from "../../modules/shared/components/property-income-report-popup";
import { ContactRepository } from "../../repository/contact.repository";
import { ListingRepository } from "../../repository/listing.repository";
import { UserRepository } from "../../repository/user-repository";
import { AppService } from "../../services/app.service";
import { BookingService } from "../../services/booking.service";
import { StatsService } from "../../services/stats.service";
import { StayDuvetService } from "../../services/stayduvet";
import Utils from "../../utils/utils";

@Component({
  selector: "sd-owner-dashboard-view",
  template: `
    <sd-owner-main-layout>
      <div *ngIf="!userLoading && userLoaded" fxFlex="95%" fxLayoutGap="10px" class="requiredHeight main-container">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutAlign="space-between center"
             style="font-size: x-small;">
          <h3>{{UserModelUtil.getFullName(user)}}</h3>
          <span class="space-filler"></span>

          <sd-select [control]="userIdControl"
                     [options]="homeOwnersOptions"
                     (selectionChanged)="onUserChange()"></sd-select>

          <sd-select [multiple]="true"
                     placeholder="Select Listing"
                     [control]="selectedListingsControl"
                     [options]="listingsOptions"
                     (selectionChanged)="selectedListingsChanged()"></sd-select>
        </div>

        <div class="well" fxLayout="row" fxLayoutAlign="space-around center" *ngIf="ownerStatsLoading">
          <mat-spinner color="accent" [diameter]="60" [strokeWidth]="4"></mat-spinner>
        </div>
        <div class="well" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center"
             *ngIf="!ownerStatsLoading">
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Booked Earnings YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Total Revenue For Current year">
              <sd-counter
                [suffix]="''"
                [prefix]="'$'"
                [duration]="1"
                [countFrom]="0"
                [countTo]="aggregatedStats.stats.total_earning"
                [step]="100"></sd-counter>
            </div>
          </div>
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Total Expenses YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Total Expenses Till Date From Beginning Of Year">
              <sd-counter
                [suffix]="''"
                [prefix]="'$'"
                [duration]="1"
                [countFrom]="0"
                [countTo]="aggregatedStats.stats.total_expenses"
                [step]="100"></sd-counter>
            </div>
          </div>
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Confirmed Earnings
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Total Revenue Till Date From Beginning Of Year">
              <sd-counter
                [suffix]="''"
                [prefix]="'$'"
                [duration]="1"
                [countFrom]="0"
                [countTo]="aggregatedStats.stats.paid_earning"
                [step]="100"></sd-counter>
            </div>
          </div>
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Future Earnings YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Total Revenue From Current Date Till Year End">
              <sd-counter
                [suffix]="''"
                [prefix]="'$'"
                [duration]="1"
                [countFrom]="0"
                [countTo]="aggregatedStats.stats.future_earning"
                [step]="100"></sd-counter>
            </div>
          </div>
        </div>
        <div class="well" fxLayout="row" fxLayoutAlign="space-around center" *ngIf="breakdownLoading">
          <mat-spinner color="accent" [diameter]="60" [strokeWidth]="4"></mat-spinner>
        </div>
        <div class="well" fxLayout="row" *ngIf="!breakdownLoading">
          <sd-monthly-breakdown-chart
            [statsData]="aggregatedBreakdown"
            fxFlex="100%" style="height:400px"></sd-monthly-breakdown-chart>
        </div>
        <div class="well" fxLayout="row" fxLayoutAlign="space-around center" *ngIf="ownerStatsLoading">
          <mat-spinner color="accent" [diameter]="60" [strokeWidth]="4"></mat-spinner>
        </div>
        <div class="well" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center"
             *ngIf="!ownerStatsLoading">
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Days Booked YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Reservations confirmed for 2019">
              <sd-counter
                [suffix]="''"
                [prefix]="''"
                [duration]="1"
                [countFrom]="0"
                [countTo]="aggregatedStats.days_booked"
                [step]="100"></sd-counter>
            </div>
          </div>
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Occupancy Rate YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Reflects only reservations to date">
              <sd-counter
                [suffix]="'%'"
                [prefix]="''"
                [duration]="1"
                [countFrom]="0"
                [countTo]="aggregatedStats.occupancy_rate"
                [step]="100"></sd-counter>
            </div>
          </div>
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Revenue per booking YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Booked earnings / Confirmed reservations">
              <sd-counter
                [suffix]="''"
                [prefix]="'$'"
                [duration]="1"
                [countFrom]="0"
                [countTo]="revenuePerBooking"
                [step]="100"></sd-counter>
            </div>
          </div>
          <div class="stat-container">
            <div fxLayout="row" class="stat-header" fxLayoutAlign="center center">
              Revenue per night YTD
            </div>
            <div fxLayout="row" class="stat-footer" fxLayoutAlign="center center"
                 matTooltip="Booked earnings / Total No Of Nights">
              <sd-counter
                [suffix]="''"
                [prefix]="'$'"
                [duration]="1"
                [countFrom]="0"
                [countTo]="revenuePerNight"
                [step]="100"></sd-counter>
            </div>
          </div>
        </div>

        <h4 *ngIf="filteredUpcomingBookings.length > 0">Upcoming Bookings: </h4>
        <div class="well" fxLayout="row" fxLayoutAlign="space-around center" *ngIf="upcomingBookingsLoading">
          <mat-spinner color="accent" [diameter]="60" [strokeWidth]="4"></mat-spinner>
        </div>
        <div class="well"
             fxLayout="row wrap"
             fxLayout.lt-sm="column wrap"
             fxLayoutAlign="space-around center"
             *ngIf="!upcomingBookingsLoading">
          <sd-upcoming-booking-card
            *ngFor="let booking of filteredUpcomingBookings"
            [booking]="booking"
            class="booking-card"
            fxFlex="23%"
            style="cursor: pointer">
          </sd-upcoming-booking-card>
          <!--<span *vaFlexAlignmentHack></span>-->
        </div>
      </div>

      <sd-center-spinner *ngIf="userLoading"></sd-center-spinner>

    </sd-owner-main-layout>
  `,
  styles: [`
    .well {
      background-color: #f5f5f5;
      border-radius: 5px;
      border: 5px black;
      margin: 20px 5px;
    }

    .stat-container {
      padding-top: 10px;
      padding-bottom: 20px;
    }

    .stat-header {
      font-size: 12px;
    }

    .stat-footer {
      padding-top: 5px;
      font-size: 30px;
      font-family: 'Montserrat', sans-serif;
    }

    .booking-card {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .main-container {
      margin: 30px;
    }

    .select-button {
      padding: 6px;
      text-align: left;
      font-size: 17px;
      padding-left: 10px;
      font-weight: bolder;
    }
  `]
})
export class OwnerDashboardViewComponent implements OnInit, AfterViewInit, OnDestroy {
  ownerStatsLoading = false;
  ownerStatsLoaded = false;
  stats: { [id: number]: OwnerDashStats } = {};
  aggregatedStats: OwnerDashStats = {
    stats: {
      total_earning: 0,
      paid_earning: 0,
      future_earning: 0,
      total_expenses: 0,
    },
    days_booked: 0,
    total_bookings: 0,
    occupancy_rate: 0
  };
  breakdownLoading = false;
  breakdownLoaded = false;
  breakdown: {};
  aggregatedBreakdown = [];
  userLoading: boolean = false;
  userLoaded: boolean = false;
  upcomingBookingsLoading = false;
  upcomingBookings = [];
  filteredUpcomingBookings = [];
  selectedListings: ListingCompact[] = [];
  selectedListingsControl: FormControl = new FormControl([]);
  listings: ListingCompact[] = [];
  listingsOptions: { title: string, value: any }[];
  allListings: ListingCompact[] = [];
  user: UserFull;
  homeOwners: UserCompact[] = [];
  homeOwnersOptions: { title: string, value: any }[];
  homeOwnersLoading: boolean = false;
  homeOwnersLoaded: boolean = false;
  revenuePerNight = 0;
  revenuePerBooking = 0;
  userId: number;
  userIdControl: FormControl = new FormControl(null);
  route$: Observable<any>;
  UserModelUtil = UserModelUtil;
  private destroyed$ = new Subject();
  private dialogRef: MatDialogRef<any>;

  constructor(private service: StayDuvetService,
              private bookingService: BookingService,
              private appService: AppService,
              private contactRepo: ContactRepository,
              private userRepo: UserRepository,
              private router: Router,
              private route: ActivatedRoute,
              private statsService: StatsService,
              private listingRepo: ListingRepository,
              private dialog: MatDialog) {

    this.listingRepo.getAcceptedListings()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((listings) => {
        this.allListings = listings;
        console.log("listings", this.listings);
      });

  }

  ngAfterViewInit() {
    this.appService.changeAppTitle("Owner Home");
    window.scrollTo(0, 0);
  }

  ngOnInit() {

    console.log("onInit sd-owner-home");

    this.route$ = this.route.params;

    this.route$.pipe(takeUntil(this.destroyed$)).subscribe(params => {

      if (params.id) {
        this.userId = +params.id;
        this.userIdControl.setValue(this.userId);

        this.contactRepo.getIsFullContactLoading(this.userId)
          .pipe(takeUntil(this.route$.pipe(skip(1))))
          .subscribe(l => this.userLoading = l);

        this.contactRepo.getIsFullContactLoaded(this.userId)
          .pipe(takeUntil(this.route$.pipe(skip(1))))
          .subscribe(l => this.userLoaded = l);

        this.contactRepo.getFullContactById(this.userId)
          .pipe(takeUntil(this.route$.pipe(skip(1))))
          .subscribe(contact => {
            this.user = contact;
            this.processListings();
            this.setupUpcomingBookings();
            this.setupMonthlyBreakdown();
            this.setupStats();
          });
      }
    });


    this.contactRepo.getIsHomeOwnerLoading()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(l => this.homeOwnersLoading = l);

    this.contactRepo.getIsHomeOwnerLoaded()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(l => this.homeOwnersLoaded = l);

    this.contactRepo.getHomeOwners()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(users => {
        this.homeOwners = users;
        this.homeOwnersOptions = this.homeOwners.map(owner => {
          return {title: UserModelUtil.getFullName(owner), value: owner.id};
        });
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setupUpcomingBookings() {
    this.upcomingBookingsLoading = true;
    this.bookingService.getOwnerUpcomingBookings(this.user.id)
      .pipe(filter(r => !!r), takeUntil(this.route$.pipe(skip(1))))
      .subscribe(res => {
        this.upcomingBookings = res;
        this.upcomingBookingsLoading = false;
        this.filterBookings();
      }, error1 => {
        this.upcomingBookingsLoading = false;
      });
  }

  filterBookings() {
    const listingIds = this.selectedListings.map(l => l.id);
    this.filteredUpcomingBookings = this.upcomingBookings
      .filter((b: BookingCompact) => listingIds.indexOf(b.property_id) !== -1);
  }

  setupStats() {
    this.aggregatedStats = {
      stats: {
        total_earning: 0,
        paid_earning: 0,
        future_earning: 0,
        total_expenses: 0,
      },
      days_booked: 0,
      total_bookings: 0,
      occupancy_rate: 0
    };
    this.revenuePerBooking = 0;
    this.revenuePerNight = 0;
    this.ownerStatsLoading = true;
    this.statsService.loadOwnerStats(this.user.id)
      .pipe(takeUntil(this.route$.pipe(skip(1))), filter(v => !!v))
      .subscribe((ownerStats) => {
        if (Object.keys(ownerStats).length > 0) {
          this.stats = ownerStats;
          this.aggregateStats();
        }
        this.ownerStatsLoading = false;
      }, err => {
        this.ownerStatsLoading = false;
      });
  }

  setupMonthlyBreakdown() {
    this.breakdownLoading = true;
    this.statsService.loadMonthlyBreakdown([], this.user.id)
      .pipe(takeUntil(this.route$.pipe(skip(1))), filter(v => !!v))
      .subscribe((breakdown) => {
        this.breakdown = breakdown;
        this.aggregateBreakdown();
        this.breakdownLoading = false;
      }, err => {
        this.breakdownLoading = false;
      });
  }

  selectedListingsChanged() {
    this.selectedListings = this.selectedListingsControl.value;
    this.filterBookings();
    this.aggregateStats();
    this.aggregateBreakdown();
  }

  aggregateStats() {
    let selectedStats: OwnerDashStats[] = [];

    if (this.selectedListings == null) {
      selectedStats = Utils.normalizedObjToArray(this.stats);
    } else {
      for (let i = 0; i < this.selectedListings.length; i++) {
        selectedStats.push(this.stats[this.selectedListings[i].id]);
      }
    }

    const aggregatedStats = {
      stats: {
        total_earning: 0,
        paid_earning: 0,
        future_earning: 0,
        total_expenses: 0,
      },
      days_booked: 0,
      occupancy_rate: 0,
      total_bookings: 0,
    };
    let numberOfDays = 0;
    let sumOccupancyRate = 0;


    for (let i = 0; i < selectedStats.length; i++) {
      aggregatedStats.stats.total_earning += selectedStats[i].stats.total_earning;
      aggregatedStats.stats.paid_earning += selectedStats[i].stats.paid_earning;
      aggregatedStats.stats.future_earning += selectedStats[i].stats.future_earning;
      aggregatedStats.stats.total_expenses += selectedStats[i].stats.total_expenses;
      aggregatedStats.days_booked += selectedStats[i].days_booked;
      aggregatedStats.total_bookings += selectedStats[i].total_bookings;
      sumOccupancyRate += selectedStats[i].occupancy_rate;
      numberOfDays += 1;
    }


    this.revenuePerNight = aggregatedStats.stats.total_earning / aggregatedStats.days_booked || 0;
    this.revenuePerBooking = aggregatedStats.stats.total_earning / aggregatedStats.total_bookings || 0;
    aggregatedStats.occupancy_rate = Math.ceil(sumOccupancyRate / numberOfDays) || 0;

    this.aggregatedStats = aggregatedStats;
  }

  aggregateBreakdown() {
    let selectedListings = this.selectedListings;

    if (selectedListings == null) {
      selectedListings = this.listings;
    }

    const aggregatedBreakdown = [];
    const months = Object.keys(this.breakdown);
    for (let i = 0; i < months.length; i++) {
      const statForMonth = this.breakdown[months[i]];

      const stats = [];
      for (const listing of selectedListings) {
        const value = statForMonth[listing.id].total_payout;
        stats.push({
          name: listing.title,
          id: listing.id,
          stats: statForMonth[listing.id],
          value: value
        });
      }
      aggregatedBreakdown.push({
        name: months[i],
        series: stats
      });
    }

    this.aggregatedBreakdown = aggregatedBreakdown;
  }

  createOwnerBlockClicked() {
    this.dialogRef = this.dialog.open(HomeCreateOwnerBlockPopupComponent);
    this.dialogRef.updateSize("100%");
  }

  requestServiceClicked() {
    this.router.navigate(["/tasks/new", {
      from: "owner-home"
    }]);
  }

  downloadReportClicked() {
    this.dialogRef = this.dialog.open(HomeOwnerReportDownloadPopupComponent);
    this.dialogRef.updateSize("100%");
  }

  propertyIncomeClicked() {
    this.dialogRef = this.dialog.open(PropertyIncomeReportPopupComponent);
    this.dialogRef.componentInstance.listings = this.listings;
    this.dialogRef.updateSize("100%");
  }

  processListings() {
    const listingIds = this.user.managementContact.data.properties;

    this.listings = this.allListings.filter(listing => listingIds.includes(listing.id));
    this.listingsOptions = this.listings.map(listing => {
      return {title: listing.title, value: listing};
    });

    this.selectedListings = this.listings;
    this.selectedListingsControl.setValue(this.selectedListings);
  }

  onUserChange() {
    this.userId = this.userIdControl.value;
    this.router.navigate(["/dashboard-view", this.userId]);
  }
}
