import { Prospect } from "../models/prospect";
import {
  CHANGE_EXPIRED_CURRENT_PAGE,
  CHANGE_PASSED_CURRENT_PAGE, CHANGE_RECENTLY_CREATED_CURRENT_PAGE,
  CHANGE_TODAY_CURRENT_PAGE,
  CHANGE_UPCOMING_CURRENT_PAGE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  INDEX_EXPIRED_REQUEST,
  INDEX_EXPIRED_SUCCESS,
  INDEX_PASSED_REQUEST,
  INDEX_PASSED_SUCCESS, INDEX_RECENTLY_CREATED_REQUEST, INDEX_RECENTLY_CREATED_SUCCESS,
  INDEX_TODAY_REQUEST,
  INDEX_TODAY_SUCCESS,
  INDEX_UPCOMING_REQUEST,
  INDEX_UPCOMING_SUCCESS
} from "../actions/prospect";
import { Action } from "../actions/action";
import { createSelector } from "reselect";
import { getDateObj } from "../utils/calendar-utils";
import * as _ from "lodash";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";


export interface ProspectState extends EntityState<Prospect> {
  todayIds: number[];
  todayCurrentPage: number;
  todayTotalPage: number;
  todayTotalCount: number;

  loadingTodayPagesId: number[];
  loadedTodayPagesId: number[];
  todayIdsForPages: { [key: number]: number[] };

  upcomingIds: number[];
  upcomingCurrentPage: number;
  upcomingTotalPage: number;
  upcomingTotalCount: number;

  loadingUpcomingPagesId: number[];
  loadedUpcomingPagesId: number[];
  upcomingIdsForPages: { [key: number]: number[] };

  expiredIds: number[];
  expiredCurrentPage: number;
  expiredTotalPage: number;
  expiredTotalCount: number;

  loadingExpiredPagesId: number[];
  loadedExpiredPagesId: number[];
  expiredIdsForPages: { [key: number]: number[] };

  passedIds: number[];
  passedCurrentPage: number;
  passedTotalPage: number;
  passedTotalCount: number;

  loadingPassedPagesId: number[];
  loadedPassedPagesId: number[];
  passedIdsForPages: { [key: number]: number[] };

  recentlyCreatedIds: number[];
  recentlyCreatedCurrentPage: number;
  recentlyCreatedTotalPage: number;
  recentlyCreatedTotalCount: number;

  loadingRecentlyCreatedPagesId: number[];
  loadedRecentlyCreatedPagesId: number[];
  recentlyCreatedIdsForPages: { [key: number]: number[] };

}

export const prospectAdapter: EntityAdapter<Prospect> = createEntityAdapter<Prospect>({
  selectId: (prospect: Prospect) => prospect.id,
});

export const initialState: ProspectState = prospectAdapter.getInitialState({
  todayIds: [],
  todayCurrentPage: 0,
  todayTotalPage: 1,
  todayTotalCount: 0,

  loadingTodayPagesId: [],
  loadedTodayPagesId: [],
  todayIdsForPages: {},

  upcomingIds: [],
  upcomingCurrentPage: 0,
  upcomingTotalPage: 1,
  upcomingTotalCount: 0,

  loadingUpcomingPagesId: [],
  loadedUpcomingPagesId: [],
  upcomingIdsForPages: {},

  expiredIds: [],
  expiredCurrentPage: 0,
  expiredTotalPage: 1,
  expiredTotalCount: 0,

  loadingExpiredPagesId: [],
  loadedExpiredPagesId: [],
  expiredIdsForPages: {},

  passedIds: [],
  passedCurrentPage: 0,
  passedTotalPage: 1,
  passedTotalCount: 0,

  loadingPassedPagesId: [],
  loadedPassedPagesId: [],
  passedIdsForPages: {},

  recentlyCreatedIds:[],
  recentlyCreatedCurrentPage: 0,
  recentlyCreatedTotalPage: 1,
  recentlyCreatedTotalCount: 0,

  loadingRecentlyCreatedPagesId: [],
  loadedRecentlyCreatedPagesId: [],
  recentlyCreatedIdsForPages: {},
});

export function prospectReducer(state: ProspectState = initialState, action: Action): ProspectState {
  switch (action.type) {


    case INDEX_TODAY_REQUEST: {
      const pageId = action.payload as number;

      let loadingPagesId = state.loadingTodayPagesId;
      const loadingIndex = _.indexOf(loadingPagesId, pageId);

      // Adding if not present
      if (loadingIndex === -1) {
        loadingPagesId = [
          ...loadingPagesId,
          pageId
        ];
      }

      let loadedPagesId = state.loadedTodayPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, pageId);

      // Removing if already added
      if (loadedIndex !== -1) {
        loadedPagesId = _.remove(loadedPagesId, pageId);
      }

      return {
        ...state,
        loadingTodayPagesId: loadingPagesId,
        loadedTodayPagesId: loadedPagesId
      };

    }

    case INDEX_TODAY_SUCCESS: {
      const prospects = action.payload.prospects;

      const currentPage = action.payload.currentPage;
      const totalPages = action.payload.totalPages;
      const totalCount = action.payload.totalCount;
      const prospectIds = prospects.map(prospect => prospect.id);


      let loadingPageIds = state.loadingTodayPagesId;
      const loadingIndex = _.indexOf(loadingPageIds, currentPage);
      console.log("Loading Page Id", loadingIndex, loadingPageIds);


      // Removing if loading
      if (loadingIndex !== -1) {
        loadingPageIds = _.remove(loadingPageIds, currentPage);
      }

      let loadedPagesId = state.loadedTodayPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, currentPage);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        loadedPagesId = [
          ...loadedPagesId,
          currentPage
        ];
      }

      return prospectAdapter.addMany(prospects, {
        ...state,
        todayIds: [
          ...state.todayIds,
          ...prospectIds
        ],
        todayIdsForPages: {
          ...state.todayIdsForPages,
          [currentPage]: prospectIds

        },
        loadingTodayPagesId: loadingPageIds,
        loadedTodayPagesId: loadedPagesId,
        todayCurrentPage: currentPage,
        todayTotalCount: totalCount,
        todayTotalPage: totalPages
      });
    }


    case INDEX_UPCOMING_REQUEST: {
      const pageId = action.payload as number;

      let loadingPagesId = state.loadingUpcomingPagesId;
      const loadingIndex = _.indexOf(loadingPagesId, pageId);

      // Adding if not present
      if (loadingIndex === -1) {
        loadingPagesId = [
          ...loadingPagesId,
          pageId
        ];
      }

      let loadedPagesId = state.loadedUpcomingPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, pageId);

      // Removing if already added
      if (loadedIndex !== -1) {
        loadedPagesId = _.remove(loadedPagesId, pageId);
      }

      return {
        ...state,
        loadingUpcomingPagesId: loadingPagesId,
        loadedUpcomingPagesId: loadedPagesId
      };

    }

    case INDEX_UPCOMING_SUCCESS: {
      const prospects = action.payload.prospects;

      const currentPage = action.payload.currentPage;
      const totalPages = action.payload.totalPages;
      const totalCount = action.payload.totalCount;
      const prospectIds = prospects.map(prospect => prospect.id);


      let loadingPageIds = state.loadingUpcomingPagesId;
      const loadingIndex = _.indexOf(loadingPageIds, currentPage);
      console.log("Loading Page Id", loadingIndex, loadingPageIds);


      // Removing if loading
      if (loadingIndex !== -1) {
        loadingPageIds = _.remove(loadingPageIds, currentPage);
      }

      let loadedPagesId = state.loadedUpcomingPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, currentPage);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        loadedPagesId = [
          ...loadedPagesId,
          currentPage
        ];
      }

      return prospectAdapter.addMany(prospects, {
        ...state,
        upcomingIds: [
          ...state.upcomingIds,
          ...prospectIds
        ],
        upcomingIdsForPages: {
          ...state.upcomingIdsForPages,
          [currentPage]: prospectIds

        },
        loadingUpcomingPagesId: loadingPageIds,
        loadedUpcomingPagesId: loadedPagesId,
        upcomingCurrentPage: currentPage,
        upcomingTotalCount: totalCount,
        upcomingTotalPage: totalPages
      });
    }


    case INDEX_EXPIRED_REQUEST: {
      const pageId = action.payload as number;

      let loadingPagesId = state.loadingExpiredPagesId;
      const loadingIndex = _.indexOf(loadingPagesId, pageId);

      // Adding if not present
      if (loadingIndex === -1) {
        loadingPagesId = [
          ...loadingPagesId,
          pageId
        ];
      }

      let loadedPagesId = state.loadedExpiredPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, pageId);

      // Removing if already added
      if (loadedIndex !== -1) {
        loadedPagesId = _.remove(loadedPagesId, pageId);
      }

      return {
        ...state,
        loadingExpiredPagesId: loadingPagesId,
        loadedExpiredPagesId: loadedPagesId
      };

    }

    case INDEX_EXPIRED_SUCCESS: {
      const prospects = action.payload.prospects;

      const currentPage = action.payload.currentPage;
      const totalPages = action.payload.totalPages;
      const totalCount = action.payload.totalCount;
      const prospectIds = prospects.map(prospect => prospect.id);


      let loadingPageIds = state.loadingExpiredPagesId;
      const loadingIndex = _.indexOf(loadingPageIds, currentPage);
      console.log("Loading Page Id", loadingIndex, loadingPageIds);


      // Removing if loading
      if (loadingIndex !== -1) {
        loadingPageIds = _.remove(loadingPageIds, currentPage);
      }

      let loadedPagesId = state.loadedExpiredPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, currentPage);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        loadedPagesId = [
          ...loadedPagesId,
          currentPage
        ];
      }

      return prospectAdapter.addMany(prospects, {
        ...state,
        expiredIds: [
          ...state.expiredIds,
          ...prospectIds
        ],
        expiredIdsForPages: {
          ...state.expiredIdsForPages,
          [currentPage]: prospectIds

        },
        loadingExpiredPagesId: loadingPageIds,
        loadedExpiredPagesId: loadedPagesId,
        expiredCurrentPage: currentPage,
        expiredTotalCount: totalCount,
        expiredTotalPage: totalPages
      });
    }


    case INDEX_PASSED_REQUEST: {
      const pageId = action.payload as number;

      let loadingPagesId = state.loadingPassedPagesId;
      const loadingIndex = _.indexOf(loadingPagesId, pageId);

      // Adding if not present
      if (loadingIndex === -1) {
        loadingPagesId = [
          ...loadingPagesId,
          pageId
        ];
      }

      let loadedPagesId = state.loadedPassedPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, pageId);

      // Removing if already added
      if (loadedIndex !== -1) {
        loadedPagesId = _.remove(loadedPagesId, pageId);
      }

      return {
        ...state,
        loadingPassedPagesId: loadingPagesId,
        loadedPassedPagesId: loadedPagesId
      };
    }

    case INDEX_PASSED_SUCCESS: {
      const prospects = action.payload.prospects;

      const currentPage = action.payload.currentPage;
      const totalPages = action.payload.totalPages;
      const totalCount = action.payload.totalCount;
      const prospectIds = prospects.map(prospect => prospect.id);


      let loadingPageIds = state.loadingPassedPagesId;
      const loadingIndex = _.indexOf(loadingPageIds, currentPage);
      console.log("Loading Page Id", loadingIndex, loadingPageIds);


      // Removing if loading
      if (loadingIndex !== -1) {
        loadingPageIds = _.remove(loadingPageIds, currentPage);
      }

      let loadedPagesId = state.loadedPassedPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, currentPage);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        loadedPagesId = [
          ...loadedPagesId,
          currentPage
        ];
      }

      return prospectAdapter.addMany(prospects, {
        ...state,
        passedIds: [
          ...state.passedIds,
          ...prospectIds
        ],
        passedIdsForPages: {
          ...state.passedIdsForPages,
          [currentPage]: prospectIds

        },
        loadingPassedPagesId: loadingPageIds,
        loadedPassedPagesId: loadedPagesId,
        passedCurrentPage: currentPage,
        passedTotalCount: totalCount,
        passedTotalPage: totalPages
      });
    }

    case INDEX_RECENTLY_CREATED_REQUEST: {
      const pageId = action.payload as number;

      let loadingPagesId = state.loadingRecentlyCreatedPagesId;
      const loadingIndex = _.indexOf(loadingPagesId, pageId);

      // Adding if not present
      if (loadingIndex === -1) {
        loadingPagesId = [
          ...loadingPagesId,
          pageId
        ];
      }

      let loadedPagesId = state.loadedRecentlyCreatedPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, pageId);

      // Removing if already added
      if (loadedIndex !== -1) {
        loadedPagesId = _.remove(loadedPagesId, pageId);
      }

      return {
        ...state,
        loadingRecentlyCreatedPagesId: loadingPagesId,
        loadedRecentlyCreatedPagesId: loadedPagesId
      };
    }

    case INDEX_RECENTLY_CREATED_SUCCESS: {
      const prospects = action.payload.prospects;

      const currentPage = action.payload.currentPage;
      const totalPages = action.payload.totalPages;
      const totalCount = action.payload.totalCount;
      const prospectIds = prospects.map(prospect => prospect.id);


      let loadingPageIds = state.loadingRecentlyCreatedPagesId;
      const loadingIndex = _.indexOf(loadingPageIds, currentPage);
      console.log("Loading Page Id", loadingIndex, loadingPageIds);


      // Removing if loading
      if (loadingIndex !== -1) {
        loadingPageIds = _.remove(loadingPageIds, currentPage);
      }

      let loadedPagesId = state.loadedRecentlyCreatedPagesId;
      const loadedIndex = _.indexOf(loadedPagesId, currentPage);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        loadedPagesId = [
          ...loadedPagesId,
          currentPage
        ];
      }

      return prospectAdapter.addMany(prospects, {
        ...state,
        recentlyCreatedIds: [
          ...state.recentlyCreatedIds,
          ...prospectIds
        ],
        recentlyCreatedIdsForPages: {
          ...state.recentlyCreatedIdsForPages,
          [currentPage]: prospectIds

        },
        loadingRecentlyCreatedPagesId: loadingPageIds,
        loadedRecentlyCreatedPagesId: loadedPagesId,
        recentlyCreatedCurrentPage: currentPage,
        recentlyCreatedTotalCount: totalCount,
        recentlyCreatedTotalPage: totalPages
      });
    }


    case CREATE_REQUEST: {
      return state;
    }

    case CREATE_SUCCESS: {
      const prospect = action.payload;

      const date = getDateObj();
      const fullDate = ("0" + date.getDate()).slice(-2);
      const fullMonth = ("0" + (date.getMonth() + 1)).slice(-2);
      const fullYear = date.getFullYear();
      const currentDateString = fullYear + "-" + fullMonth + "-" + fullDate;

      if (currentDateString === prospect.start) {
        // Today Prospect

        // TODO Add For New Prospect Id in loadedIdsforpage
        return prospectAdapter.addOne(prospect.id, {
          ...state,
          todayIds: [prospect.id, ...state.todayIds],

        });

      } else {
        // Upcoming Prospect

        // TODO Add For New Prospect Id in loadedIdsforpage
        return prospectAdapter.addOne(prospect.id, {
          ...state,
          upcomingIds: [prospect.id, ...state.upcomingIds],

        });
      }
    }

    case CHANGE_TODAY_CURRENT_PAGE: {
      const page = action.payload as number;
      return {
        ...state,
        todayCurrentPage: page
      }
    }

    case CHANGE_UPCOMING_CURRENT_PAGE: {
      const page = action.payload as number;
      return {
        ...state,
        upcomingCurrentPage: page
      }
    }

    case CHANGE_EXPIRED_CURRENT_PAGE: {
      const page = action.payload as number;
      return {
        ...state,
        expiredCurrentPage: page
      }
    }

    case CHANGE_PASSED_CURRENT_PAGE: {
      const page = action.payload as number;
      return {
        ...state,
        passedCurrentPage: page
      }
    }

    case CHANGE_RECENTLY_CREATED_CURRENT_PAGE: {
      const page = action.payload as number;
      return {
        ...state,
        recentlyCreatedCurrentPage: page
      }
    }

    default: {
      return state;
    }
  }
}

export const _getEntities = (state: ProspectState) => state.entities;


export const _getTodayProspectCurrentPage = (state: ProspectState) => state.todayCurrentPage;
export const _getTodayProspectTotalPage = (state: ProspectState) => state.todayTotalPage;
export const _getTodayProspectTotalCount = (state: ProspectState) => state.todayTotalCount;

export const _getTodayProspectIds = (state: ProspectState) => state.todayIds;

export const _getTodayProspectAll = createSelector(_getEntities, _getTodayProspectIds, (entities, ids) => {
  return ids.map(id => entities[id]);
});

export const _getTodayLoadingForPageNumber = (state: ProspectState, pageNumber: number) => {
  return state.loadingTodayPagesId.indexOf(pageNumber) !== -1;
};

export const _getTodayLoadedForPageNumber = (state: ProspectState, pageNumber: number) => {
  return state.loadedTodayPagesId.indexOf(pageNumber) !== -1;
};

export const _getAllTodayProspectsForPageNumber = (state: ProspectState, pageNumber: number) => {
  let ids = [];

  if (state.todayIdsForPages[pageNumber]) {
    ids = state.todayIdsForPages[pageNumber];
  }

  return ids;
};


export const _getUpcomingProspectCurrentPage = (state: ProspectState) => state.upcomingCurrentPage;
export const _getUpcomingProspectTotalPage = (state: ProspectState) => state.upcomingTotalPage;
export const _getUpcomingProspectTotalCount = (state: ProspectState) => state.upcomingTotalCount;

export const _getUpcomingProspectIds = (state: ProspectState) => state.upcomingIds;

export const _getUpcomingProspectAll = createSelector(_getEntities, _getUpcomingProspectIds, (entities, ids) => {
  return ids.map(id => entities[id]);
});


export const _getUpcomingLoadingForPageNumber = (state: ProspectState, pageNumber: number) => {
  return state.loadingUpcomingPagesId.indexOf(pageNumber) !== -1;
};

export const _getUpcomingLoadedForPageNumber = (state: ProspectState, pageNumber: number) => {
  return state.loadedUpcomingPagesId.indexOf(pageNumber) !== -1;
};

export const _getAllUpcomingProspectsForPageNumber = (state: ProspectState, pageNumber: number) => {
  let ids = [];

  if (state.upcomingIdsForPages[pageNumber]) {
    ids = state.upcomingIdsForPages[pageNumber];
  }

  return ids;
};


export const _getExpiredProspectCurrentPage = (state: ProspectState) => state.expiredCurrentPage;
export const _getExpiredProspectTotalPage = (state: ProspectState) => state.expiredTotalPage;
export const _getExpiredProspectTotalCount = (state: ProspectState) => state.expiredTotalCount;

export const _getExpiredProspectIds = (state: ProspectState) => state.expiredIds;

export const _getExpiredProspectAll = createSelector(_getEntities, _getExpiredProspectIds, (entities, ids) => {
  return ids.map(id => entities[id]);
});

export const _getExpiredLoadingForPageNumber = (state: ProspectState, pageNumber: number) => {
  return state.loadingExpiredPagesId.indexOf(pageNumber) !== -1;
};

export const _getExpiredLoadedForPageNumber = (state: ProspectState, pageNumber: number) => {
  return state.loadedExpiredPagesId.indexOf(pageNumber) !== -1;
};

export const _getAllExpiredProspectsForPageNumber = (state: ProspectState, pageNumber: number) => {
  let ids = [];

  if (state.expiredIdsForPages[pageNumber]) {
    ids = state.expiredIdsForPages[pageNumber];
  }
  console.log("Inside ids", ids);
  return ids;
};

export const _getPassedProspectCurrentPage = (state: ProspectState) => state.passedCurrentPage;
export const _getPassedProspectTotalPage = (state: ProspectState) => state.passedTotalPage;
export const _getPassedProspectTotalCount = (state: ProspectState) => state.passedTotalCount;

export const _getPassedProspectIds = (state: ProspectState) => state.passedIds;

export const _getPassedProspectAll = createSelector(_getEntities, _getPassedProspectIds, (entities, ids) => {
  return ids.map(id => entities[id]);
});

export const _getPassedLoadingForPageNumber = (state: ProspectState, pageNumber: number) => {
  return state.loadingPassedPagesId.indexOf(pageNumber) !== -1;
};

export const _getPassedLoadedForPageNumber = (state: ProspectState, pageNumber: number) => {
  return state.loadedPassedPagesId.indexOf(pageNumber) !== -1;
};

export const _getAllPassedProspectsForPageNumber = (state: ProspectState, pageNumber: number) => {
  let ids = [];

  if (state.passedIdsForPages[pageNumber]) {
    ids = state.passedIdsForPages[pageNumber];
  }

  return ids;
};


export const _getRecentlyCreatedProspectCurrentPage = (state: ProspectState) => state.recentlyCreatedCurrentPage;
export const _getRecentlyCreatedProspectTotalPage = (state: ProspectState) => state.recentlyCreatedTotalPage;
export const _getRecentlyCreatedProspectTotalCount = (state: ProspectState) => state.recentlyCreatedTotalCount;

export const _getRecentlyCreatedProspectIds = (state: ProspectState) => state.recentlyCreatedIds;

export const _getRecentlyCreatedProspectAll = createSelector(_getEntities, _getRecentlyCreatedProspectIds, (entities, ids) => {
  return ids.map(id => entities[id]);
});

export const _getRecentlyCreatedLoadingForPageNumber = (state: ProspectState, pageNumber: number) => {
  return state.loadingRecentlyCreatedPagesId.indexOf(pageNumber) !== -1;
};

export const _getRecentlyCreatedLoadedForPageNumber = (state: ProspectState, pageNumber: number) => {
  return state.loadedRecentlyCreatedPagesId.indexOf(pageNumber) !== -1;
};

export const _getAllRecentlyCreatedProspectsForPageNumber = (state: ProspectState, pageNumber: number) => {
  let ids = [];

  if (state.recentlyCreatedIdsForPages[pageNumber]) {
    ids = state.recentlyCreatedIdsForPages[pageNumber];
  }

  return ids;
};
