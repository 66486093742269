import { GettingAroundDraft } from "../../../../models/new/drafts/getting-around-draft.model";
import { Action } from "../../../action";

export enum GettingAroundDraftActionTypes {
  INDEX_REQUEST = "[Getting Around Drafts NEW] Index Request",
  INDEX_SUCCESS = "[Getting Around Drafts NEW] Index Success",

  CREATE_REQUEST = "[Getting Around Drafts NEW] Create Request",
  CREATE_SUCCESS = "[Getting Around Drafts NEW] Create Success",

  SHOW_REQUEST = "[Getting Around Drafts NEW] Show Request",
  SHOW_SUCCESS = "[Getting Around Drafts NEW] Show Success",

  UPDATE_REQUEST = "[Getting Around Drafts NEW] Update Request",
  UPDATE_SUCCESS = "[Getting Around Drafts NEW] Update Success",

  DELETE_REQUEST = "[Getting Around Drafts NEW] Delete Request",
  DELETE_SUCCESS = "[Getting Around Drafts NEW] Delete Success"
}

export class GettingAroundDraftIndexRequest implements Action {
  readonly type = GettingAroundDraftActionTypes.INDEX_REQUEST;
}

export class GettingAroundDraftIndexSuccess implements Action {
  readonly type = GettingAroundDraftActionTypes.INDEX_SUCCESS;

  constructor(public payload: GettingAroundDraft[]) {
  }
}

export class GettingAroundDraftCreateRequest implements Action {
  readonly type = GettingAroundDraftActionTypes.CREATE_REQUEST;
}

export class GettingAroundDraftCreateSuccess implements Action {
  readonly type = GettingAroundDraftActionTypes.CREATE_SUCCESS;

  constructor(public payload: GettingAroundDraft) {
  }
}

export class GettingAroundDraftShowRequest implements Action {
  readonly type = GettingAroundDraftActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {

  }
}

export class GettingAroundDraftShowSuccess implements Action {
  readonly type = GettingAroundDraftActionTypes.SHOW_SUCCESS;

  constructor(public payload: GettingAroundDraft) {
  }
}

export class GettingAroundDraftUpdateRequest implements Action {
  readonly type = GettingAroundDraftActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class GettingAroundDraftUpdateSuccess implements Action {
  readonly type = GettingAroundDraftActionTypes.UPDATE_SUCCESS;

  constructor(public payload: GettingAroundDraft) {
  }
}

export class GettingAroundDraftDeleteRequest implements Action {
  readonly type = GettingAroundDraftActionTypes.DELETE_REQUEST;

  constructor(public payload: number) {
  }
}

export class GettingAroundDraftDeleteSuccess implements Action {
  readonly type = GettingAroundDraftActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
