import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { ListingCompact } from "../../models/new/listing/listing-compact.model";
import { UserRepository } from "../../repository/user-repository";
import { CommonUtil } from "../../utils/common.util";
import { getDateObj } from "../../utils/calendar-utils";

@Component({
  selector: "sd-multi-calendar", template: `

    <article class="cal-container">
      <div class="duvet-places-name" fxLayout="column">
        <aside fxFlex="25%"></aside>
        <aside style="height: 46px!important;" *ngFor="let listing of listings" fxFlex="15%" fxLayoutAlign="none center">
            <mat-checkbox *ngIf="!isHouseKeeper"
              (change)="selectionChanged($event,listing)">
              <span [matTooltipPosition]="'above'"
                    [matTooltip]="listing.title" (click)="redirectToListing(listing.id)">{{ listing.title | truncate:'20' }}</span>
            </mat-checkbox>
          
              <span *ngIf="isHouseKeeper" [matTooltipPosition]="'above'" style="padding: 0 20px"
                    [matTooltip]="listing.title" (click)="redirectToListing(listing.id)">{{ listing.title | truncate:'20' }}</span>
          </aside>
      </div>
      <div class="duvet-material-calendar" id="multi-calendar">
        <section>
          <main>
            <span></span>
          </main>
          <main class="test">
            <span *ngFor="let k of array(daysInMonth)">{{ k }}</span>
          </main>

          <main style="height: 46px!important;" *ngFor="let listing of listings">
            <div style="position: relative; justify-content: left" *ngFor="let k of array(daysInMonth)">
              <sd-multi-calendar-date-tile
                [calendarData]="getDataForDay(listing, k)"
                [currentMonth]="currentMonth"
                [currentYear]="currentYear"
                [day]="k"
                [isHouseKeeper]="isHouseKeeper"
                (onPopoverOpen)="onPopoverOpen($event)"
                [listing]="listing">
              </sd-multi-calendar-date-tile>
            </div>
          </main>
        </section>
      </div>
    </article>




  `, styles: [`


  `]
})
export class MultiCalendarComponent implements OnInit {
  loaded = false;
  today;
  isAlive: boolean;
  scrollX;
  startDate;
  calendarData;
  @Output() onScrollXChanged: EventEmitter<any> = new EventEmitter();

  @Input() isHouseKeeper: boolean;
  @Input() currentMonth;
  @Input() currentYear;
  @Input() listing: ListingCompact;
  @Input() listings: ListingCompact[];
  @Input() endDate: number;
  @Input() daysInMonth: number;
  @Input() checked: boolean;
  @Output() onSelectChanged: EventEmitter<any> = new EventEmitter();
  popOver: NgbPopover;

  constructor(private userRepo: UserRepository, private router: Router) {
  }

  @Input() set _calendarData(value) {
    this.calendarData = value;
    this.scrollCalendar(this.startDate);
  };

  @Input() set _startDate(value) {
    this.startDate = value;
    this.scrollCalendar(value);
  };

  ngOnInit(): void {
    document.getElementById("multi-calendar").addEventListener("scroll", () => {
      if (document.getElementById("multi-calendar") && this.scrollX !== document.getElementById("multi-calendar").scrollLeft) {
        this.scrollX = document.getElementById("multi-calendar").scrollLeft;
        this.onScrollXChanged.emit(this.scrollX);
      }
    });
  }

  selectionChanged($event, listing) {
    this.onSelectChanged.emit({
      listing: listing, checked: $event.checked
    });
  }

  array(n: number): number[] {
    return (new Array(n)).fill(1).map((x, i) => i + 1);
  }


  public getDataForDay(listing: ListingCompact, day: number): any {
    if (!this.calendarData[listing.id]) {
      return null;
    }

    const date = CommonUtil.getNumbersInDateFormat(day, this.currentMonth, this.currentYear);
    return this.calendarData[listing.id][date];
  }

  onPopoverOpen(p: NgbPopover) {
    if (this.popOver && this.popOver.isOpen()) {
      this.popOver.close();
    }

    this.popOver = p;
  }

  redirectToListing(id) {
    this.router.navigate(["/listings/" + id]);
  }

  scrollCalendar(date: Date = getDateObj()) {
    if (this.currentYear === date.getFullYear() && date.getMonth() === this.currentMonth - 1) {
      const scrollWidth = (100 * date.getDate()) - 200;
      setTimeout(() => {
        if (document.getElementById("multi-calendar")) {
          document.getElementById("multi-calendar").scrollLeft = scrollWidth;
          this.scrollX = document.getElementById("multi-calendar").scrollLeft;
          this.onScrollXChanged.emit(this.scrollX);
        }
      });
    }
  }
}

