export class DateUtil {
  static convertToDateString(date: Date) {
    const fullDate = ("0" + date.getDate()).slice(-2);
    const fullMonth = ("0" + (date.getMonth() + 1)).slice(-2);
    const fullYear = date.getFullYear();

    return fullYear + "-" + fullMonth + "-" + fullDate;
  }

  static convertToDateTimeString(date: Date) {
    const fullDate = ("0" + date.getDate()).slice(-2);
    const fullMonth = ("0" + (date.getMonth() + 1)).slice(-2);
    const fullYear = date.getFullYear();

    return fullYear + "-" + fullMonth + "-" + fullDate;

    // TODO add code to return time too
  }
}
