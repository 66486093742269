import { Pipe, PipeTransform } from "@angular/core";

/**
 * Created by piyushkantm on 28/08/17.
 */

@Pipe({
  name: "slugToString"
})
export class SlugToStringPipe implements PipeTransform {
  transform(slug: string) {
    return slug.toLowerCase()
      .split("_")
      .map(i => i[0].toUpperCase() + i.substr(1))
      .join(" ");
  }
}
