import { Action } from "../../../actions/action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import * as _ from "lodash";
import { SummaryDraft } from "../../../models/new/drafts/summary-draft.model";
import { SummaryDraftActionTypes } from "../../../actions/new/setttings/drafts/summary-drafts";


export interface SummaryDraftState extends EntityState<SummaryDraft> {
  isLoading: boolean;
  isLoaded: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];
}

export const summaryDraftAdapter: EntityAdapter<SummaryDraft> = createEntityAdapter
  < SummaryDraft > ({
    selectId: (summaryDraft: SummaryDraft) => summaryDraft.id
  });

export const initialState: SummaryDraftState = summaryDraftAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  fullLoadingIds: [],
  fullLoadedIds: [],
});

export function summaryDraftReducer(state: SummaryDraftState = initialState, action: Action): SummaryDraftState {
  switch (action.type) {
    case SummaryDraftActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        isLoaded: false
      }
    }

    case SummaryDraftActionTypes.INDEX_SUCCESS: {
      const summaryDrafts = action.payload as SummaryDraft[];
      return summaryDraftAdapter.addAll(summaryDrafts, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case SummaryDraftActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }

    case SummaryDraftActionTypes.CREATE_SUCCESS: {
      const summaryDraft = action.payload as SummaryDraft;
      return summaryDraftAdapter.addOne(summaryDraft,{
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case SummaryDraftActionTypes.SHOW_REQUEST: {
      const summaryDraftId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, summaryDraftId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          summaryDraftId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, summaryDraftId);

      // Removing if already added
      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, summaryDraftId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      }
    }

    case SummaryDraftActionTypes.SHOW_SUCCESS: {
      const summaryDraft = action.payload as SummaryDraft;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, summaryDraft.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, summaryDraft.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, summaryDraft.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          summaryDraft.id
        ];
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case SummaryDraftActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case SummaryDraftActionTypes.UPDATE_SUCCESS: {
      const summaryDraft = action.payload as SummaryDraft;
      return summaryDraftAdapter.updateOne({
        id: summaryDraft.id,
        changes: summaryDraft
      }, state);
    }

    case SummaryDraftActionTypes.DELETE_REQUEST: {
      return {
        ...state
      };
    }

    case SummaryDraftActionTypes.DELETE_SUCCESS: {
      const summaryDraftId = action.payload as number;

      return summaryDraftAdapter.removeOne(summaryDraftId, state);
    }

    default: {
      return state;
    }

  }
}

export const _getIsSummaryDraftLoading = (state: SummaryDraftState) => state.isLoading;
export const _getIsSummaryDraftLoaded = (state: SummaryDraftState) => state.isLoaded;

export const _getIsFullSummaryDraftLoading = (state: SummaryDraftState, draftId: number) => {
  return state.fullLoadingIds.indexOf(draftId) !== -1;
};
export const _getIsFullSummaryDraftLoaded = (state: SummaryDraftState, draftId: number) => {
  return state.fullLoadedIds.indexOf(draftId) !== -1;
};
