import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { UserFull } from "../../../models/new/user/user-full.model";
import { OptionsRepository } from "../../../repository/options.repository";

@Component({
  selector: "sd-admin-create",
  template: `
    <sd-modal-popup-layout title="Create new Admin">
      <div fxLayout="column" fxLayoutGap="20px">
        <form fxLayout="column" fxLayoutAlign="space-between" [formGroup]="formGroup"
              (ngSubmit)="formGroup.valid && saveButtonCLicked()">

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field [color]="'accent'" fxFlex="45%">
              <input matInput placeholder="First Name" formControlName="first_name">
              <mat-error>first name is required.</mat-error>
            </mat-form-field>

            <mat-form-field [color]="'accent'" fxFlex="45%">
              <input matInput placeholder="Last Name" formControlName="last_name">
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutAlign="flex-start center">
            <mat-form-field [color]="'accent'" fxFlex="100%">
              <input matInput placeholder="Email" formControlName="email">
              <mat-error>email is required.</mat-error>
            </mat-form-field>
          </div>

          <div fxLayoutGap="10px" fxLayoutAlign="center" fxFlexAlign="end">
            <mat-spinner color="accent" *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
            <button mat-raised-button fxFlexAlign="end" [disabled]="isSaving" [color]="'accent'" type="submit">
              Create
            </button>
          </div>
        </form>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`

    textarea {
      resize: vertical;
    }

    mat-error {
      font-size: x-small;
    }

    mat-spinner {
      width: 30px;
      height: 30px;
    }

  `]
})

export class AdminCreatePopup {

  formGroup: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  email: FormControl;

  isSaving = false;

  constructor(private optionsRepo: OptionsRepository,
              private dialogRef: MatDialogRef<AdminCreatePopup>,
              private router: Router) {
    this.firstName = new FormControl("", [Validators.required]);
    this.lastName = new FormControl("", []);
    this.email = new FormControl("", [Validators.required]);

    this.formGroup = new FormGroup({
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
    });
  }

  saveButtonCLicked() {
    this.isSaving = true;
    this.optionsRepo.createAdmin(this.formGroup.value).subscribe((user) => {
      this.isSaving = false;
      this.openAdmin(user);
      this.dialogRef.close();
    }, error => {
      this.isSaving = false;
    });
  }

  openAdmin(admin: UserFull) {
    this.router.navigate(["/contacts/" + admin.id]);
  }
}
