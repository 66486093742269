/**
 * Created by Piyush on 22-Jul-17.
 */
import { MultiCalendar } from "../models/multi-calendar";

import { Action } from "./action";

export const INDEX_REQUEST = "[MULTI CALENDAR] Index Request";
export const INDEX_SUCCESS = "[MULTI CALENDAR] Index Success";
export const DELETE_MONTH_SUCCESS = "[MULTI CALENDAR] Delete Month Success";

export class MultiCalendarIndexRequestAction implements Action {
  readonly type = INDEX_REQUEST;

  constructor(public payload: string) {
  }
}

export class MultiCalendarIndexSuccessAction implements Action {
  readonly type = INDEX_SUCCESS;

  constructor(public payload: { key: string, data: MultiCalendar }) {
  }
}

export class MultiCalendarDeleteMonthSuccess implements Action {
  readonly type = DELETE_MONTH_SUCCESS;

  constructor(public payload: { month: string, year: string }) {

  }
}

export type Actions
  = MultiCalendarIndexRequestAction
  | MultiCalendarIndexSuccessAction
  | MultiCalendarDeleteMonthSuccess;
