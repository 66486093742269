import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { combineLatest as observableCombineLatest, Observable } from "rxjs";

import { map } from "rxjs/operators";
import { GenericConfirmationPopupComponent } from "../modules/shared/components/confirmation-popup";
import { UserRepository } from "../repository/user-repository";

@Injectable()
export class HomeOwnerGuard implements CanActivate {

  private dialogRef: MatDialogRef<any>;

  constructor(private userRepo: UserRepository,
              private dialog: MatDialog,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    const observables = observableCombineLatest(
      this.userRepo.getUser(),
      (user) => {
        return {
          user: user
        };
      }
    );

    return observables.pipe(map((data) => {

      console.log(data);

      if (data.user.type === "management" && data.user?.managementContact?.data?.category === "homeowner") {
        return true;
      } else {
        this.dialogRef = this.dialog.open(GenericConfirmationPopupComponent);
        const instance = this.dialogRef.componentInstance;
        instance.title = "No Access";
        instance.description = "You do not have access to this page. You will be redirected to Home page. please click on OK Button.";
        instance.showCloseButton = false;
        instance.showNoButton = false;
        instance.yesButtonText = "OK";
        instance.yesButtonClicked.subscribe(() => {
          instance.isLoading = true;
          this.router.navigate(["/home"]);
          this.dialog.closeAll();
        });
        this.dialogRef.updateSize("100%");
      }

    }));
  }
}
