import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { AccountType, ContactEnumHelper } from "../../../enums/contact.enum";
import { BankInfoModel } from "../../../models/bank-info.model";
import { ContactRepository } from "../../../repository/contact.repository";
import { CommonUtil } from "../../../utils/common.util";

@Component({
  selector: "sd-add-account", template: `
    <sd-modal-popup-layout title="{{title}}">
    </sd-modal-popup-layout>
    <div fxLayout="column" fxLayoutGap="30px" style="margin-top:20px;">
      <form fxLayoutGap="20px" fxFlex="100%" fxlayout="column" [formGroup]="item" (ngSubmit)="item.valid && done()">
        <div fxLayout="column" fxLayoutGap="20px">
          <mat-form-field [color]="'accent'" dividerColor="accent">
            <mat-icon style="color:#194267" matSuffix>account_balance</mat-icon>
            <input matInput placeholder="Bank Name" formControlName="bank_name">
          </mat-form-field>

          <mat-form-field dividerColor="accent">
            <input matInput placeholder="Name on account" formControlName="account_name">
            <mat-error>Name on account is required</mat-error>
          </mat-form-field>

          <mat-form-field dividerColor="accent">
            <mat-select placeholder="Type of account" formControlName="account_type">
              <mat-option *ngFor="let type of accountTypes" [value]="type">
                {{ContactEnumHelper.getAccountType(type)}}
              </mat-option>
            </mat-select>
            <mat-error>Type of account is required</mat-error>
          </mat-form-field>

          <mat-form-field [color]="'accent'" dividerColor="accent">
            <mat-icon style="color:#194267" matSuffix>confirmation_number</mat-icon>
            <input matInput placeholder="Routing Number" formControlName="routing_number">
            <mat-error> Routing Number is required</mat-error>
          </mat-form-field>

          <mat-form-field [color]="'accent'" dividerColor="accent">
            <mat-icon style="color:#194267" matSuffix>account_balance_wallet</mat-icon>
            <input matInput placeholder="Account Number" formControlName="account_number">
            <mat-error> Account Number is required</mat-error>
          </mat-form-field>
        </div>
        <div fxLayoutAlign="end" fxLayoutGap="5px">
          <mat-spinner [strokeWidth]="2" [diameter]="30" *ngIf="isLoading" color="accent"></mat-spinner>
          <button mat-raised-button color="accent" type="submit" *ngIf="action === 'update'" [disabled]="isLoading">
            Update
          </button>
          <button mat-raised-button color="accent" type="submit" *ngIf="action === 'create'" [disabled]="isLoading">
            Create
          </button>
        </div>
      </form>
    </div>


  `,
  styles: [`

  `]
})
export class AddAccountPopupComponent implements OnInit {

  accountTypes = CommonUtil.getPropsOfEnum<AccountType>(AccountType);
  item: FormGroup;
  ContactEnumHelper = ContactEnumHelper;
  account: BankInfoModel;
  action: string;
  title: string;
  bankName: FormControl;
  accountNumber: FormControl;
  routingNumber: FormControl;
  accountName: FormControl;
  accountType: FormControl;
  isLoading = false;
  contactId: number;
  @Output() refreshAccounts = new EventEmitter<any>();

  constructor(private contactRepo: ContactRepository,
              private dialogRef: MatDialogRef<AddAccountPopupComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) {

    this.account = data.account;
    this.action = data.action;
    this.contactId = this.data.contactId;
    if (this.action === "update") {
      this.title = "Update Account Details";
    } else {
      this.title = "Create Account";
    }
    this.bankName = new FormControl(null, []);
    this.accountNumber = new FormControl(null, []);
    this.routingNumber = new FormControl(null, [Validators.required]);
    this.accountName = new FormControl(null, []);
    this.accountType = new FormControl(null, [Validators.required]);

    this.item = new FormGroup({
      bank_name: this.bankName,
      account_number: this.accountNumber,
      account_name: this.accountName,
      account_type: this.accountType,
      routing_number: this.routingNumber
    });
  }

  ngOnInit() {
    if (this.account) {
      this.bankName.setValue(this.account.bank_name);
      this.accountName.setValue(this.account.account_name);
      this.accountType.setValue(this.account.account_type);
      this.routingNumber.setValue(this.account.routing_number);
    } else {
      this.accountNumber.setValidators([Validators.required]);
    }
  }

  done() {
    this.isLoading = true;
    const data = this.item.value;
    if (this.action === "update") {
      if (!data.account_number) {
        delete data.account_number;
      }
      this.contactRepo.updateBankAccount(this.account.id, data).subscribe(result => {
        console.log(result);
        this.isLoading = false;
        // this.contact.bankDetails.data = this.contact.bankDetails.data.map(item => {
        //   if (item.id === result.data.id) {
        //     item = result.data;
        //   }
        //   return item;
        // });
        console.log(this.contactId);
        // this.store.dispatch(new ContactUpdateSuccess(this.contact));
        this.refreshAccounts.emit();
        this.dialogRef.close();
      }, (error) => {
        this.isLoading = false;
      });
    } else if (this.action === "create") {
      const newData = {
        ...data,
        user_id: this.contactId
      };
      this.contactRepo.createBankAccount(newData).subscribe(res => {
        console.log(res);
        this.isLoading = false;
        // this.contact.bankDetails.data.push(res.data);
        // this.store.dispatch(new ContactUpdateSuccess(this.contact));
        this.dialogRef.close();
        this.refreshAccounts.emit();
      }, (error) => {
        this.isLoading = false;
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
