import { ReviewResponseDraft } from "../../../../models/new/drafts/review-response-draft.model";
import { Action } from "../../../action";

export enum ReviewResponseDraftActionTypes {
  INDEX_REQUEST = "[Review Response Drafts NEW] Index Request",
  INDEX_SUCCESS = "[Review Response Drafts NEW] Index Success",

  CREATE_SUCCESS = "[Review Response Drafts NEW] Create Success",

  UPDATE_SUCCESS = "[Review Response Drafts NEW] Update Success",

  DELETE_SUCCESS = "[Review Response Drafts NEW] Delete Success"
}

export class ReviewResponseDraftIndexRequest implements Action {
  readonly type = ReviewResponseDraftActionTypes.INDEX_REQUEST;
}

export class ReviewResponseDraftIndexSuccess implements Action {
  readonly type = ReviewResponseDraftActionTypes.INDEX_SUCCESS;

  constructor(public payload: ReviewResponseDraft[]) {
  }
}

export class ReviewResponseDraftCreateSuccess implements Action {
  readonly type = ReviewResponseDraftActionTypes.CREATE_SUCCESS;

  constructor(public payload: ReviewResponseDraft) {
  }
}

export class ReviewResponseDraftUpdateSuccess implements Action {
  readonly type = ReviewResponseDraftActionTypes.UPDATE_SUCCESS;

  constructor(public payload: ReviewResponseDraft) {
  }
}

export class ReviewResponseDraftDeleteSuccess implements Action {
  readonly type = ReviewResponseDraftActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}
