import { Component, DoCheck, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { isNullOrUndefined } from "util";

@Component({
  selector: "sd-modal-popup-layout",
  template: `
    <div id="cwidth" fxLayout="column" fxLayoutGap="20px">
      <mat-dialog-content>
        <mat-toolbar style="position: unset;z-index: 9;" [ngStyle]="{'width':clientWidth + 'px'}" color="accent">
          <h2>{{title}}</h2>
          <span class="space-filler"></span>
          <button mat-icon-button *ngIf="showPrintAction" (click)="printButtonClicked()">
            <mat-icon>print</mat-icon>
          </button>
          <button mat-icon-button *ngIf="showRefreshAction" (click)="output()">
            <mat-icon>autorenew</mat-icon>
          </button>
          <button *ngIf="showCloseAction" mat-icon-button (click)="closeButtonClicked()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar>

      </mat-dialog-content>
      <div style="padding-top:40px"></div>
      <ng-content></ng-content>
    </div>

    <ng-content select="[sd-modal-footer]"></ng-content>
  `,
  styles: [`

    mat-dialog-content {
      padding: 0px;
    }

    /deep/ mat-dialog-container {
      padding-top: 0px !important;
      /*padding-left: 24px !important;*/
      /*padding-right: 24px !important;*/
      /*padding-bottom: 24px !important;*/
    }

    .mat-toolbar-single-row {
      width: 100% !important;
    }
  `]
})

export class ModalPopupLayoutComponent implements DoCheck {
  @Input() title: string;

  @Input() showRefreshAction = false;
  @Input() showPrintAction = false;
  @Input() showCloseAction = true;
  @Input() preventDefaultClose = false;

  @Output() printAction = new EventEmitter<any>();
  @Output() closeAction = new EventEmitter<boolean>();

  clientWidth: number;

  constructor(private dialogRef: MatDialog) {
  }

  printButtonClicked() {
    window.print();

    this.printAction.emit();
  }

  closeButtonClicked() {
    if (!this.preventDefaultClose) {
      this.dialogRef.closeAll();
    } else {
      this.closeAction.emit(true);
    }
  }

  ngDoCheck() {
    if (!isNullOrUndefined(document.getElementById("cwidth"))) {
      this.clientWidth = document.getElementById("cwidth").offsetWidth + 48;
    }
  }

  output() {

  }

  close() {

  }


}



