import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { AutoResponse } from "../../../models/new/automation/auto-response.model";
import { Action } from "../../../actions/action";
import { AutoResponseActionTypes } from "../../../actions/new/automation/auto-response";
import { ConstantsUtil } from "../../../utils/constants.util";


export interface AutoResponseState extends EntityState<AutoResponse> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const autoResponseAdapter: EntityAdapter<AutoResponse> = createEntityAdapter<AutoResponse>({
  selectId: (autoResponse: AutoResponse) => autoResponse.id,
  sortComparer: sortByType
});

export function sortByType(first: AutoResponse, second: AutoResponse): number {
  const isFirstConfirmed = first.type.indexOf(ConstantsUtil.AUTO_RES_CONF_TYPE_PREFIX) !== -1;
  const isSecondConfirmed = first.type.indexOf(ConstantsUtil.AUTO_RES_CONF_TYPE_PREFIX) !== -1;

  if (isFirstConfirmed && !isSecondConfirmed) {
    return 1;
  }

  if (!isFirstConfirmed && isSecondConfirmed) {
    return -1;
  }

  if (first.title > second.title) {
    return 1;
  }

  if (first.title < second.title) {
    return -1;
  }

  return 0;
}

export const initialState: AutoResponseState = autoResponseAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
});

export function autoResponseReducer(state: AutoResponseState = initialState, action: Action): AutoResponseState {

  switch (action.type) {
    case AutoResponseActionTypes.INDEX_REQUEST: {
      return {
        ...initialState,
        isLoading: true
      }
    }

    case AutoResponseActionTypes.INDEX_SUCCESS: {
      const autoResponses = action.payload as AutoResponse[];
      return autoResponseAdapter.addAll(autoResponses, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case AutoResponseActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case AutoResponseActionTypes.CREATE_SUCCESS: {
      const autoResponse = action.payload as AutoResponse;
      return autoResponseAdapter.addOne(autoResponse, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case AutoResponseActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
      }
    }

    case AutoResponseActionTypes.UPDATE_SUCCESS: {
      const autoResponse = action.payload as AutoResponse;
      return autoResponseAdapter.updateOne({
        id: autoResponse.id,
        changes: autoResponse
      }, state);
    }

    case AutoResponseActionTypes.DELETE_REQUEST: {
      return {
        ...state
      };
    }

    case AutoResponseActionTypes.DELETE_SUCCESS: {
      const autoResponseId = action.payload as number;

      return autoResponseAdapter.removeOne(autoResponseId, state);
    }

    default:
      return state;
  }
}

export const _getIsAutoResponseLoading = (state: AutoResponseState) => state.isLoading;
export const _getIsAutoResponseLoaded = (state: AutoResponseState) => state.isLoaded;
