import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { AutomatedMessageGetResponse } from "../models/responses/automated-message-get.response";
import { AutomatedMessageShowResponse } from "../models/responses/automated-message-show.response";
import { Injectable } from "@angular/core";
import { __HTTPResponseType } from "../enums/common.enum";

@Injectable()
export class AutomatedMessageService {

  constructor(private apiService: ApiService) {
  }

  getAutomatedMessages(): Observable<AutomatedMessageGetResponse> {
    return this.apiService.get<AutomatedMessageGetResponse>("/admin/alerts", true);
  }

  showAutomatedMessage(automatedMessageId: number): Observable<AutomatedMessageShowResponse> {
    return this.apiService.get<AutomatedMessageShowResponse>("/admin/alerts/" + automatedMessageId, true, null);
  }

  createAutomatedMessage(data: any): Observable<AutomatedMessageShowResponse> {
    return this.apiService.post<AutomatedMessageShowResponse>("/admin/alerts", true, data);
  }

  updateAutomatedMessage(automatedMessageId: number, data: any): Observable<AutomatedMessageShowResponse> {
    return this.apiService.put<AutomatedMessageShowResponse>("/admin/alerts/" + automatedMessageId, true, data);
  }

  deleteAutomatedMessage(automatedMessageId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/alerts/" + automatedMessageId, true, null, null, true,
      __HTTPResponseType.TEXT);
  }
}
