import { ChecklistCategory } from "../../models/new/checklist-category";
import { Country } from "../../models/new/country.model";
import { ListingTag } from "../../models/new/listing/listing-tag.model";
import { StateSubDivision } from "../../models/new/state-subdivision.model";
import { UserCompact } from "../../models/new/user/user-compact.model";
import { UserFull } from "../../models/new/user/user-full.model";
import { Action } from "../action";

export enum OptionActionTypes {
  ADMIN_INDEX_REQUEST = "[OPTION NEW] Admin Index request",
  ADMIN_INDEX_SUCCESS = "[OPTION NEW] Admin Index success",
  ADMIN_CREATE_SUCCESS = "[OPTION NEW] Admin Add success",

  LOCATION_INDEX_REQUEST = "[OPTION NEW] Location Index request",
  LOCATION_INDEX_SUCCESS = "[OPTION NEW] Location Index success",

  TAG_INDEX_REQUEST = "[OPTION NEW] Tag Index request",
  TAG_INDEX_SUCCESS = "[OPTION NEW] Tag Index success",
  TAG_CREATE_SUCCESS = "[OPTION NEW] Tag Add success",
  TAG_DELETE_SUCCESS = "[OPTION NEW] Tag Delete success",
  TAG_ATTACH_SUCCESS = "[OPTION NEW] Tag Attach success",

  COUNTRY_INDEX_REQUEST = "[OPTION NEW] Country Index request",
  COUNTRY_INDEX_SUCCESS = "[OPTION NEW] Country Index success",

  STATE_INDEX_REQUEST = "[OPTION NEW] State Index request",
  STATE_INDEX_SUCCESS = "[OPTION NEW] State Index success",

  CHECKLIST_CATEGORY_INDEX_REQUEST = "[OPTION NEW] Checklist Category Index request",
  CHECKLIST_CATEGORY_INDEX_SUCCESS = "[OPTION NEW] Checklist Category Index success",
  CHECKLIST_CATEGORY_CREATE_REQUEST = "[OPTION NEW] Checklist Category Create request",
  CHECKLIST_CATEGORY_CREATE_SUCCESS = "[OPTION NEW] Checklist Category Create success",
  CHECKLIST_CATEGORY_UPDATE_SUCCESS = "[OPTION NEW] Checklist Category Update success",

  TASK_ASSIGNEES_INDEX_REQUEST = "[OPTION NEW] Task Assignees Index request",
  TASK_ASSIGNEES_INDEX_SUCCESS = "[OPTION NEW] Task Assignees Index success",

  VENDOR_INDEX_REQUEST = "[OPTION NEW] Vendor Index request",
  VENDOR_INDEX_SUCCESS = "[OPTION NEW ] Vendor Index successful",
}

export class OptionAdminIndexRequest implements Action {
  readonly type = OptionActionTypes.ADMIN_INDEX_REQUEST;
}

export class OptionAdminIndexSuccess implements Action {
  readonly type = OptionActionTypes.ADMIN_INDEX_SUCCESS;

  constructor(public payload: UserCompact[]) {
  }
}

export class OptionAdminCreateSuccess implements Action {
  readonly type = OptionActionTypes.ADMIN_CREATE_SUCCESS;

  constructor(public payload: UserFull) {
  }
}


export class OptionLocationIndexRequest implements Action {
  readonly type = OptionActionTypes.LOCATION_INDEX_REQUEST;
}

export class OptionLocationIndexSuccess implements Action {
  readonly type = OptionActionTypes.LOCATION_INDEX_SUCCESS;

  constructor(public payload: string[]) {
  }
}


export class OptionTagIndexRequest implements Action {
  readonly type = OptionActionTypes.TAG_INDEX_REQUEST;
}

export class OptionTagIndexSuccess implements Action {
  readonly type = OptionActionTypes.TAG_INDEX_SUCCESS;

  constructor(public payload: ListingTag[]) {
  }
}

export class OptionTagCreateSuccess implements Action {
  readonly type = OptionActionTypes.TAG_CREATE_SUCCESS;

  constructor(public payload: ListingTag) {
  }
}


export class OptionCountryIndexRequest implements Action {
  readonly type = OptionActionTypes.COUNTRY_INDEX_REQUEST;
}

export class OptionCountryIndexSuccess implements Action {
  readonly type = OptionActionTypes.COUNTRY_INDEX_SUCCESS;

  constructor(public payload: Country[]) {
  }
}


export class OptionStateIndexRequest implements Action {
  readonly type = OptionActionTypes.STATE_INDEX_REQUEST;

  constructor(public payload: string) {
  }
}

export class OptionStateIndexSuccess implements Action {
  readonly type = OptionActionTypes.STATE_INDEX_SUCCESS;

  constructor(public payload: { countryCode: string, states: StateSubDivision[] }) {
  }
}


export class OptionChecklistCategoryIndexRequest implements Action {
  readonly type = OptionActionTypes.CHECKLIST_CATEGORY_INDEX_REQUEST;
}

export class OptionChecklistCategoryIndexSuccess implements Action {
  readonly type = OptionActionTypes.CHECKLIST_CATEGORY_INDEX_SUCCESS;

  constructor(public payload: ChecklistCategory[]) {
  }
}


export class OptionChecklistCategoryCreateRequest implements Action {
  readonly type = OptionActionTypes.CHECKLIST_CATEGORY_CREATE_REQUEST;
}

export class OptionChecklistCategoryCreateSuccess implements Action {
  readonly type = OptionActionTypes.CHECKLIST_CATEGORY_CREATE_SUCCESS;

  constructor(public payload: ChecklistCategory) {
  }
}

export class TaskAssigneesIndexRequest implements Action {
  readonly type = OptionActionTypes.TASK_ASSIGNEES_INDEX_REQUEST;
}

export class TaskAssigneesIndexSuccess implements Action {
  readonly type = OptionActionTypes.TASK_ASSIGNEES_INDEX_SUCCESS;

  constructor(public payload: UserCompact[]) {
  }
}

export class VendorIndexRequest implements Action {
  readonly type = OptionActionTypes.VENDOR_INDEX_REQUEST;
}

export class VendorIndexSuccess implements Action {
  readonly type = OptionActionTypes.VENDOR_INDEX_SUCCESS;

  constructor(public payload: UserCompact[]) {
  }
}





