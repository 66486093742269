import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { RentalAgreement } from "../../models/new/rental-agreement.model";
import { RentalAgreementActionTypes } from "../../actions/new/setttings/rental-agreements";
import { Action } from "../../actions/action";


export interface RentalAgreementState extends EntityState<RentalAgreement> {
  isLoading: boolean;
  isLoaded: boolean;

}

export const rentalAgreementAdapter: EntityAdapter<RentalAgreement> = createEntityAdapter<RentalAgreement>({
  selectId: (agreement: RentalAgreement) => agreement.id
});

export const initialState: RentalAgreementState = rentalAgreementAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

});

export function rentalAgreementReducer(state: RentalAgreementState = initialState, action: Action): RentalAgreementState {
  switch (action.type) {

    case RentalAgreementActionTypes.INDEX_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case RentalAgreementActionTypes.INDEX_SUCCESS: {
      const agreements = action.payload as RentalAgreement[];

      return rentalAgreementAdapter.addMany(agreements, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }


    case RentalAgreementActionTypes.CREATE_SUCCESS: {
      const agreement = action.payload as RentalAgreement;
      return rentalAgreementAdapter.addOne(agreement, state);
    }


    case RentalAgreementActionTypes.SET_AS_DEFAULT: {
      const id = action.payload;
      let allIds = rentalAgreementAdapter.getSelectors().selectIds(state) as number[];
      allIds = allIds.filter(item => item !== id);
      const changes = [{ id: id, changes: { is_default: true } }];
      for (const id of allIds) {
        changes.push({ id: id, changes: { is_default: false } })
      }
      return rentalAgreementAdapter.updateMany(changes, state);
    }


    default: {
      return state;
    }

  }
}


export const _getIsRentalAgreementLoading = (state: RentalAgreementState) => state.isLoading;
export const _getIsRentalAgreementLoaded = (state: RentalAgreementState) => state.isLoaded;

