import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { CheckoutQuoteComponent } from "./components/checkout-quote";
import { CheckoutPageComponent } from "./components/checkout2";
import { BootstrapComponent } from "./components/containers/bootstrap";
import { DashboardComponent } from "./components/containers/dashboard";
import { OwnerHomeComponent } from "./components/containers/owner-home";
import { HomeownerProfileContainerComponent } from "./components/homeowner/homeowner-profile-container";
import { OwnerDashboardViewComponent } from "./components/homeowner/owner-dashboard-view";
import { MultiCalendarContainerComponent } from "./components/multi-calendar/multi-calendar-container";
import { PaymentPageComponent } from "./components/payment";
import { ProfileSettingComponent } from "./components/profile";
import { AuthGuard } from "./guards";
import { AdminGuard } from "./guards/admin";
import { AnonymousGuard } from "./guards/anonymous";
import { BootstrapGuard } from "./guards/bootstrap";
import { HomeOwnerGuard } from "./guards/homeowner";
import { ReservationAuthGuard } from "./guards/reservation-auth";
import { TaskAuthGuard } from "./guards/task-auth";
import { ContactsUserProfileComponent } from "./modules/contacts/components/contacts-user-profile";
import { AutoTaskShowComponent } from "./modules/shared/components/auto-task-show";
import { NotFoundComponent } from "./modules/shared/components/not-found";
import { TaskShowCreateComponent } from "./modules/tasks/components/task-show-create";
import { TasksComponent } from "./modules/tasks/components/tasks-component";
import { TasksReportComponent } from "./modules/tasks/components/tasks-report.component";
import { OauthComponent } from "./components/oauth";

const routes: Routes = [
  {
    path: "checkout/:id", component: CheckoutPageComponent, canActivate: [AnonymousGuard]

  },
  {
    path: "payment/:id", component: PaymentPageComponent, canActivate: [AnonymousGuard]

  },
  {
    path: "checkout-quote/:id", component: CheckoutQuoteComponent, canActivate: [AnonymousGuard]

  },
  {
    path: "", component: BootstrapComponent, canActivate: [BootstrapGuard]
  },
  {
    path: "",
    component: DashboardComponent,
    canActivate: [AuthGuard],

    children: [
      /**
       * All Routes Accessible by both Admin and Owner
       */
      // Listings,
      {
        path: "listings",
        loadChildren: () => import("./modules/listings/listings.module").then(mod => mod.ListingsModule)
      },
      // {path: "automation/:automationId", component: AutoTaskShowComponent, canActivate: [AdminGuard]},
      // Profile
      {
        path: "profile", component: ProfileSettingComponent
      },
      // Multi Calendar
      {
        path: "multi-calendar", component: MultiCalendarContainerComponent
      },
      {
        path: "employees/:id", component: ContactsUserProfileComponent
      },

      {
        path: "housekeeper-profile", component: HomeownerProfileContainerComponent,
      },

      {
        path: "tasks-report", component: TasksReportComponent
      },

      /**
       * All Home Owner Only Routes Here
       */
      {
        path: "",
        component: DashboardComponent,
        canActivate: [HomeOwnerGuard],
        children: [
          {
            path: "homeowner-home", component: OwnerHomeComponent,
          },
          {
            path: "homeowner-profile", component: HomeownerProfileContainerComponent
          }
        ]
      },
      // Tasks
      {
        path: "",
        component: DashboardComponent,
        canActivate: [TaskAuthGuard],
        children: [
          {
            path: "tasks", component: TasksComponent
          },
          {
            path: "tasks/new", component: TaskShowCreateComponent
          },
          {
            path: "tasks/:task_id", component: TaskShowCreateComponent
          }
        ]
      },
      // Reservations
      {
        path: "",
        component: DashboardComponent,
        canActivate: [ReservationAuthGuard],
        children: [
          {
            path: "reservations",
            loadChildren: () => import("./modules/reservations/reservations.module").then(mod => mod.ReservationsModule)
          },
        ]
      },
      /**
       * All Admin Only Routes Here
       */
      {
        path: "",
        component: DashboardComponent,
        canActivate: [AdminGuard],
        children: [
          {
            path: "admin-home",
            loadChildren: () => import("./modules/dashboard/dashboard.module").then(mod => mod.DashboardModule)
          },
          {
            path: "contacts",
            loadChildren: () => import("./modules/contacts/contacts.module").then(mod => mod.ContactsModule)
          },
          {
            path: "inbox",
            loadChildren: () => import("./modules/inbox/inbox.module").then(mod => mod.InboxModule)
          },
          {
            path: "leads",
            loadChildren: () => import("./modules/leads/leads.module").then(mod => mod.LeadsModule)
          },
          {
            path: "settings/tools/auto-tasks/create", component: AutoTaskShowComponent
          },
          {
            path: "settings/tools/auto-tasks/:id", component: AutoTaskShowComponent
          },
          {
            path: "reviews",
            loadChildren: () => import("./modules/reviews/reviews.module").then(m => m.ReviewsModule)
          },
          {
            path: "settings",
            loadChildren: () => import("./modules/settings/settings.module").then(mod => mod.SettingsModule)
          },
          {
            path: "dashboard-view/:id", component: OwnerDashboardViewComponent
          },
          {
            path: "airbnb-oauth-redirect", component: OauthComponent
          }
        ]
      },
    ]
  },
  {
    path: "",
    loadChildren: () => import("./modules/auth/auth.module").then(mod => mod.AuthModule)
  },
  {path: "**", component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
