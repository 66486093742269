import { RentalAgreement } from "../../../models/new/rental-agreement.model";
import { Action } from "../../action";

export enum RentalAgreementActionTypes {
  INDEX_REQUEST = "[Rental Agreement] Index Request",
  INDEX_SUCCESS = "[Rental Agreement] Index Success",

  CREATE_REQUEST = "[Rental Agreement] Create Request",
  CREATE_SUCCESS = "[Rental Agreement] Create Success",

  SET_AS_DEFAULT = "[Rental Agreement] Set as default",

  UPDATE_CHECK_IN_CHECK_OUT = "[Rental Agreement] Update Checkin Checkout",
}

export class RentalAgreementIndexRequest implements Action {
  readonly type = RentalAgreementActionTypes.INDEX_REQUEST;
}

export class RentalAgreementIndexSuccess implements Action {
  readonly type = RentalAgreementActionTypes.INDEX_SUCCESS;

  constructor(public payload: RentalAgreement[]) {
  }
}

export class RentalAgreementCreateRequest implements Action {
  readonly type = RentalAgreementActionTypes.CREATE_REQUEST;
}

export class RentalAgreementCreateSuccess implements Action {
  readonly type = RentalAgreementActionTypes.CREATE_SUCCESS;

  constructor(public payload: RentalAgreement) {
  }
}

export class RentalAgreementSetAsDefault implements Action {
  readonly type = RentalAgreementActionTypes.SET_AS_DEFAULT;

  constructor(public payload: number) {

  }
}

export class UpdateCheckInCheckoutTime implements Action {
  readonly type = RentalAgreementActionTypes.UPDATE_CHECK_IN_CHECK_OUT;

  constructor(public payload: { check_in_time: string, check_out_time: string }) {
  }
}


