import { APP_INITIALIZER, ErrorHandler, NgModule, Injectable } from "@angular/core";
import * as Raven from "raven-js";

import { environment } from "../../../environments/environment";

import { SentryService } from "./sentry.service";

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any) {
    console.error(err);

    if (environment.production) {
      Raven.captureException(err);
    }
  }
}

export const setupSentry = () => {
  if (environment.production) {
    Raven.config(
      "https://2917da8a4f084f4a8056b587865c2a22@sentry.io/1301261", {
        environment: environment.title,
        release: environment.version
      }
    ).install();
  }

  return () => {
  };
};

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    SentryService,
    {
      provide: ErrorHandler,
      useClass: RavenErrorHandler
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupSentry,
      multi: true
    }
  ]
})
export class SentryModule {
}
