import { AutoTaskCompact } from "../../../models/new/tasks/auto-task-compact.model";
import { AutoTaskFull } from "../../../models/new/tasks/auto-task-full.model";
import { Action } from "../../action";

export enum AutoTaskActionTypes {
  INDEX_REQUEST = "[AUTO TASK NEW] Index Request",
  INDEX_SUCCESS = "[AUTO TASK NEW] Index Success",

  CREATE_REQUEST = "[AUTO TASK NEW] Create Request",
  CREATE_SUCCESS = "[AUTO TASK NEW] Create Success",

  SHOW_REQUEST = "[AUTO TASK NEW] Show Request",
  SHOW_SUCCESS = "[AUTO TASK NEW] Show Success",

  UPDATE_REQUEST = "[AUTO TASK NEW] Update Request",
  UPDATE_SUCCESS = "[AUTO TASK NEW] Update Success",

  DELETE_REQUEST = "[AUTO TASK NEW] Delete Request",
  DELETE_SUCCESS = "[AUTO TASK NEW] DELETE Success",

  ADD_AUTO_TASK_TO_NOT_EXIST = "[AUTO TASK NEW] Add Auto Task To Not Exist"

}

export class AutoTaskIndexRequest implements Action {
  readonly type = AutoTaskActionTypes.INDEX_REQUEST;
}

export class AutoTaskIndexSuccess implements Action {
  readonly type = AutoTaskActionTypes.INDEX_SUCCESS;

  constructor(public payload: AutoTaskCompact[]) {
  }
}

export class AutoTaskCreateSuccess implements Action {
  readonly type = AutoTaskActionTypes.CREATE_SUCCESS;

  constructor(public payload: AutoTaskFull) {
  }
}

export class AutoTaskShowRequest implements Action {
  readonly type = AutoTaskActionTypes.SHOW_REQUEST;

  constructor(public payload: number) {
  }
}

export class AutoTaskShowSuccess implements Action {
  readonly type = AutoTaskActionTypes.SHOW_SUCCESS;

  constructor(public payload: AutoTaskFull) {
  }
}

export class AutoTaskUpdateRequest implements Action {
  readonly type = AutoTaskActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class AutoTaskUpdateSuccess implements Action {
  readonly type = AutoTaskActionTypes.UPDATE_SUCCESS;

  constructor(public payload: AutoTaskFull) {
  }
}

export class AutoTaskDeleteSuccess implements Action {
  readonly type = AutoTaskActionTypes.DELETE_SUCCESS;

  constructor(public payload: number) {
  }
}

export class AddAutoTaskToNotExist implements Action {
  readonly type = AutoTaskActionTypes.ADD_AUTO_TASK_TO_NOT_EXIST;

  constructor(public payload: number) {
  }
}

