import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";

import { LoginSuccessAction } from "../../../actions/user";
import { AirbnbAccount } from "../../../models/airbnb_account";
import { UserFull } from "../../../models/new/user/user-full.model";
import { getAppLandingUrl, State } from "../../../reducers";
import { UserRepository } from "../../../repository/user-repository";

@Component({
  selector: "sd-airlock-popup",
  template: `
    <sd-modal-popup-layout title="Verify Airbnb Account">
      <div *ngIf="!account.airbnb_connected" fxLayout="column">
        <h2>Hi {{account.first_name}}</h2>
        <h4>We tried adding your airbnb account but it is locked with 2-factor authentication. To keep using the
          account, we need you to verify the account.</h4>
        <div *ngIf="!verificationSent" fxLayout="column" fxLayoutAlign="start center">
          <p>Please select one of the below options to verify yourself.</p>
          <div fxLayout="row" style="width: 100%" fxLayoutAlign="start center" fxLayoutGap="10%">
            <sd-select style="width: 40%" placeholder="Validation Type"
                       [control]="verificationTypeControl" [options]="verificationTypesOptions"
                       (selectionChanged)="onVerificationTypeChange()"></sd-select>
            <sd-select *ngIf="verificationType && (verificationType.name === 'phone_verification_via_text' || verificationType.name === 'phone_verification_via_call')"
                       style="width: 40%" placeholder="Phone Number"
                       [control]="phoneNumberIdControl" [options]="phoneNumberOptions"
                       (selectionChanged)="onPhoneNumberIdChange()"></sd-select>
          </div>
          <div fxLayout="row">
            <mat-spinner *ngIf="isResending" [diameter]="30" [strokeWidth]="4"></mat-spinner>
            <button mat-raised-button color="accent" [disabled]="isResending" (click)="resendCode()">Resend Code.
            </button>
          </div>
        </div>
        <div *ngIf="verificationSent" fxLayout="column" fxLayoutAlign="start center">
          <p>Please enter the code you've received.</p>
          <div fxLayout="row" style="width: 100%" fxLayoutAlign="space-around center" fxLayoutGap="10%">
            <mat-form-field [color]="'accent'" style="width: 40%">
              <input matInput [(ngModel)]="verificationCode" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-spinner *ngIf="isVerifying" [diameter]="30" [strokeWidth]="4"></mat-spinner>
            <button mat-raised-button color="accent" [disabled]="isVerifying" (click)="sumbitCode()">Submit Code.
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="account.airbnb_connected" fxLayout="column">
        <h2>
          <mat-icon [color]="'accent'">thumb_up</mat-icon>
          Thanks {{account.first_name}}
        </h2>
        <h3>Yay! we have successfully verified your account.</h3>
      </div>
    </sd-modal-popup-layout>
  `,
  styles: [`
    mat-spinner {
      width: 24px;
      height: 24px;
      margin-right: 20px;
    }
  `]
})
export class AirlockPopupComponent implements OnInit, OnDestroy {

  verificationSent = false;

  verificationType: any = null;
  verificationTypeControl: FormControl = new FormControl(null);
  verificationTypes: any[] = [];
  verificationTypesOptions: {title: string, value: any}[];
  phoneNumberId: number = null;
  phoneNumberIdControl: FormControl = new FormControl(null);
  phoneNumberOptions: {title: string, value: any}[];

  verificationCode = "";

  isResending = false;
  isVerifying = false;

  @Input() account: AirbnbAccount;
  @Input() user: UserFull;
  AIRLOCK_BASE_URL = "https://www.airbnb.co.in/airlock?al_id=";
  private isAlive = true;

  constructor(private userRepo: UserRepository,
              private store: Store<State>,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) data: AirlockPopupComponentData,
              private dialogRef: MatDialogRef<AirlockPopupComponent>) {
    this.setAccount(data.airbnbAccount);
    this.user = data.user;
  }

  ngOnInit() {
    console.log("onInit sd-airlock-popup");
  }

  resendCode() {
    if (this.verificationType.name === "email_code_verification") {
      this.isResending = true;
      this.userRepo.resendAirbnbCodeByEmail(this.account.id).subscribe((account) => {
        this.setAccount(account);
        this.isResending = false;
        this.verificationSent = true;
      }, error => {
        this.isResending = false;
      });
    } else if (this.verificationType.name === "phone_verification_via_text") {
      this.isResending = true;
      this.userRepo.resendAirbnbCodeByText(this.account.id, this.phoneNumberId).subscribe((account) => {
        this.setAccount(account);
        this.isResending = false;
        this.verificationSent = true;
      }, error => {
        this.isResending = false;
      });
    } else if (this.verificationType.name === "phone_verification_via_call") {
      this.isResending = true;
      this.userRepo.resendAirbnbCodeByCall(this.account.id, this.phoneNumberId).subscribe((account) => {
        this.setAccount(account);
        this.isResending = false;
        this.verificationSent = true;
      }, error => {
        this.isResending = false;
      });
    }
  }

  sumbitCode() {
    if (this.verificationType.name === "email_code_verification") {
      this.isVerifying = true;
      this.userRepo.verifyAirbnbCodeByEmail(this.account.id, this.verificationCode).subscribe((account) => {
        this.setAccount(account);
        this.isVerifying = false;
      }, error => {
        this.isVerifying = false;
      });
    } else if (this.verificationType.name === "phone_verification_via_text") {
      this.isVerifying = true;
      this.userRepo.verifyAirbnbCodeByText(this.account.id, this.verificationCode, this.phoneNumberId).subscribe((account) => {
        this.setAccount(account);
        this.isVerifying = false;
      }, error => {
        this.isVerifying = false;
      });
    } else if (this.verificationType.name === "phone_verification_via_call") {
      this.isVerifying = true;
      this.userRepo.verifyAirbnbCodeByCall(this.account.id, this.verificationCode, this.phoneNumberId).subscribe((account) => {
        this.setAccount(account);
        this.isVerifying = false;
      }, error => {
        this.isVerifying = false;
      });
    }
  }

  openAirlock() {
    window.open(this.AIRLOCK_BASE_URL + this.account.airlock_id, "_blank");
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  private setAccount(account: AirbnbAccount) {
    this.account = account;

    this.setValidationTypes();

    if (this.account.airbnb_connected) {

      if (this.user) {
        this.store.dispatch(new LoginSuccessAction(this.user));

        return this.store.select(getAppLandingUrl).pipe(take(1)).subscribe((url) => {
          this.router.navigate([url]);
        });
      }

      this.dialogRef.close();
    }
  }

  private setValidationTypes() {
    this.verificationTypes = this.account.airlock.friction_data.filter(d => d.name === "email_code_verification"
      || d.name === "phone_verification_via_text"
      || d.name === "phone_verification_via_call");
    this.verificationTypesOptions = this.verificationTypes.map(type => {
      let title: string;
      if (type.name === "email_code_verification") {
        title = "Send Code by Email to: " + type.data.obfuscated_email_address;
      } else if (type.name === "phone_verification_via_text") {
        title = "Send Code by Text Message.";
      } else if (type.name === "phone_verification_via_call") {
        title = "Send Code by Call.";
      }
      return {title: title, value: type};
    });
  }

  onVerificationTypeChange() {
    this.verificationType = this.verificationTypeControl.value;
    if (this.verificationType.name === "phone_verification_via_text" || this.verificationType.name === "phone_verification_via_call") {
      this.phoneNumberOptions = this.verificationType.data.phone_numbers.map(number => {
        return {title: number.obfuscated, value: number.id};
      });
    }
  }

  onPhoneNumberIdChange() {
    this.phoneNumberId = this.phoneNumberIdControl.value;
  }
}

export interface AirlockPopupComponentData {
  airbnbAccount: AirbnbAccount;
  user?: UserFull;
}
