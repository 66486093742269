export const environment = {
  title: "Testing",
  key: "hostwithduvet.com",
  production: true,
  apiBaseURL: "https://test-api.stayduvet.com",
  dashboardURL: "https://testing.stayduvet.com",
  bookingURL: "https://booking.testing.stayduvet.com",

  version: "1.1.83",

  Stripe: {
    publishableKey: "pk_test_qvlB5xVrxqbiVnqX0I4qfFlV",
    secretKey: "sk_test_joC54ymeUgZIGvTxfAW6Lo9F"
  },

  GoogleMaps: {
    apiKey: "AIzaSyDJ2GnZHQ333huX60UZ_euZX2xq7lFuT2o"
  },

  Intercom: {
    appId: "kn5ti029"
  },
};
