import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { __HTTPResponseType } from "../../enums/common.enum";
import { ApiService } from "../api.service";
import { SavedNeighbourhoodDraftsShowResponse } from "../../models/responses/settings/drafts/saved-neighbourhood-draft-show.response";
import { SavedNeighbourhoodDraft } from "../../models/new/drafts/saved-neighbourhood-draft.model";
import { SavedNeighbourhoodDraftsGetResponse } from "../../models/responses/settings/drafts/saved-neighbourhood-draft-get.response";

@Injectable()
export class SavedNeighbourhoodDraftsService {

  constructor(private apiService: ApiService) {
  }

  getNeighbourhoodDrafts(): Observable<SavedNeighbourhoodDraftsGetResponse> {
    return this.apiService.get<SavedNeighbourhoodDraftsGetResponse>("/admin/saved-neighbourhood-drafts", true);
  }

  getNeighbourhoodDraftById(draftId):Observable<SavedNeighbourhoodDraftsShowResponse> {
    return this.apiService.get<SavedNeighbourhoodDraftsShowResponse>("/admin/saved-neighbourhood-drafts/"+draftId,true);
  }

  createNeighbourhoodDraft(data: Partial<SavedNeighbourhoodDraft>): Observable<SavedNeighbourhoodDraftsShowResponse> {
    return this.apiService.post<SavedNeighbourhoodDraftsShowResponse>("/admin/saved-neighbourhood-drafts", true, data);
  }

  updateNeighbourhoodDraft(draftId: number, data: any): Observable<SavedNeighbourhoodDraftsShowResponse> {
    return this.apiService.put<SavedNeighbourhoodDraftsShowResponse>("/admin/saved-neighbourhood-drafts/" + draftId, true, data);
  }

  deleteNeighbourhoodDraft(draftId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/saved-neighbourhood-drafts/" + draftId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}

