import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { filter, take, takeUntil } from "rxjs/operators";

import { SortOrder } from "../../../enums/common.enum";
import { TaskEnumHelper, TaskSortBy } from "../../../enums/task.enum";
import { UserCategory } from "../../../enums/user.enum";
import { ListingNameOnly } from "../../../models/new/listing/listing-name-only-model";
import { TaskCompact } from "../../../models/new/tasks/task-compact.model";
import { UserCompact } from "../../../models/new/user/user-compact.model";
import { Task } from "../../../models/task";
import { TaskModelUtil } from "../../../models/utils/task-model.util";
import { UserModelUtil } from "../../../models/utils/user-model.util";
import { State } from "../../../reducers";
import { ListingRepository } from "../../../repository/listing.repository";
import { OptionsRepository } from "../../../repository/options.repository";
import { TaskRepository } from "../../../repository/task.repository";
import { UserRepository } from "../../../repository/user-repository";
import { StayDuvetService } from "../../../services/stayduvet";
import { CommonUtil } from "../../../utils/common.util";
import { ListingTaskUtil } from "../../../utils/listing-task.util";
import { DateFixPipe } from "../../shared/pipes/date-fix.pipe";
import { UTCtoLocalTimeZonePipe } from "../../shared/pipes/utc-to-local-time.pipe";

import { DateSelectComponent } from "./date-select";
import { AssigneeChangePopupComponent } from "./popups/assignee-change-popup";
import { EmployeeChangePopupComponent } from "./popups/employee-change-popup";
import { ListingChangePopupComponent } from "./popups/listing-change-popup";

@Component({
  selector: "sd-tasks-list-component",
  template: `
    <mat-spinner [strokeWidth]="4" [diameter]="30" *ngIf="isLoading"></mat-spinner>
    <mat-card fxFlex="100%" id="container" *ngIf="!isLoading">
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" id="heading">
          <span>
            <mat-checkbox *ngIf="!isHomeOwner && !forReport && !isOnboarding && !!tasks.length"
                          [formControl]="selectAll"></mat-checkbox>
          </span>
          <span fxFlex="1%"></span>
          <span class="fs-15-b c-pointer" fxFlex="15%"
                (click)="sortChanged(TaskSortBy.TASK_TITLE)">
            Task Title
            <ng-container *ngIf="sortBy === TaskSortBy.TASK_TITLE">
              <mat-icon class="fs-15" *ngIf="sortOrder === 'asc'">arrow_upward</mat-icon>
              <mat-icon class="fs-15" *ngIf="sortOrder === 'desc'">arrow_downward</mat-icon>
            </ng-container>
          </span>
          <span class="fs-15-b c-pointer" fxFlex="11%"
                (click)="sortChanged(TaskSortBy.LISTING)">
            Listing
            <ng-container *ngIf="sortBy === TaskSortBy.LISTING">
              <mat-icon class="fs-15" *ngIf="sortOrder === 'asc'">arrow_upward</mat-icon>
              <mat-icon class="fs-15" *ngIf="sortOrder === 'desc'">arrow_downward</mat-icon>
            </ng-container>
          </span>
          <span class="fs-15-b c-pointer" fxFlex="7%"
                (click)="sortChanged(TaskSortBy.ASSIGNEE)">
            Assignee
            <ng-container *ngIf="sortBy === TaskSortBy.ASSIGNEE">
              <mat-icon class="fs-15" *ngIf="sortOrder === 'asc'">arrow_upward</mat-icon>
              <mat-icon class="fs-15" *ngIf="sortOrder === 'desc'">arrow_downward</mat-icon>
            </ng-container>
          </span>
          <span class="fs-15-b c-pointer" fxFlex="7%" *ngIf="isHouseKeeper"
                (click)="sortChanged(TaskSortBy.EMPLOYEE)">
            Employee
            <ng-container *ngIf="sortBy === TaskSortBy.EMPLOYEE">
              <mat-icon class="fs-15" *ngIf="sortOrder === 'asc'">arrow_upward</mat-icon>
              <mat-icon class="fs-15" *ngIf="sortOrder === 'desc'">arrow_downward</mat-icon>
            </ng-container>
          </span>
          <span class="fs-15-b c-pointer" fxFlex="7%"
                (click)="sortChanged(TaskSortBy.CREATED_AT)">
            Created On
            <ng-container *ngIf="sortBy === TaskSortBy.CREATED_AT">
              <mat-icon class="fs-15" *ngIf="sortOrder === 'asc'">arrow_upward</mat-icon>
              <mat-icon class="fs-15" *ngIf="sortOrder === 'desc'">arrow_downward</mat-icon>
            </ng-container>
          </span>
          <span class="fs-15-b c-pointer" fxFlex="7%"
                (click)="sortChanged(TaskSortBy.DUE_ON)">
            Due On
            <ng-container *ngIf="sortBy === TaskSortBy.DUE_ON">
              <mat-icon class="fs-15" *ngIf="sortOrder === 'asc'">arrow_upward</mat-icon>
              <mat-icon class="fs-15" *ngIf="sortOrder === 'desc'">arrow_downward</mat-icon>
            </ng-container>
          </span>
          <span class="fs-15-b c-pointer" fxFlex="7%"
                *ngIf="!(isHouseKeeper || isEmployee)"
                (click)="sortChanged(TaskSortBy.PAID_ON)">
            Paid On
            <ng-container *ngIf="sortBy === TaskSortBy.PAID_ON">
              <mat-icon class="fs-15" *ngIf="sortOrder === 'asc'">arrow_upward</mat-icon>
              <mat-icon class="fs-15" *ngIf="sortOrder === 'desc'">arrow_downward</mat-icon>
            </ng-container>
          </span>
          <span class="fs-15-b c-pointer" fxFlex="7%"
                *ngIf="(isHouseKeeper || isEmployee)"
                (click)="sortChanged(TaskSortBy.START)">
            Next Arrival Date
            <ng-container *ngIf="sortBy === TaskSortBy.START">
              <mat-icon class="fs-15" *ngIf="sortOrder === 'asc'">arrow_upward</mat-icon>
              <mat-icon class="fs-15" *ngIf="sortOrder === 'desc'">arrow_downward</mat-icon>
            </ng-container>
          </span>
          <span class="fs-15-b c-pointer center-text" fxFlex="10%"
                (click)="sortChanged(TaskSortBy.CATEGORY)">
            Category
            <ng-container *ngIf="sortBy === TaskSortBy.CATEGORY">
              <mat-icon class="fs-15" *ngIf="sortOrder === 'asc'">arrow_upward</mat-icon>
              <mat-icon class="fs-15" *ngIf="sortOrder === 'desc'">arrow_downward</mat-icon>
            </ng-container>
          </span>
          <span class="fs-15-b c-pointer center-text" fxFlex="13%"
                (click)="sortChanged(TaskSortBy.STATUS)">
            Status
            <ng-container *ngIf="sortBy === TaskSortBy.STATUS">
              <mat-icon class="fs-15" *ngIf="sortOrder === 'asc'">arrow_upward</mat-icon>
              <mat-icon class="fs-15" *ngIf="sortOrder === 'desc'">arrow_downward</mat-icon>
            </ng-container>
          </span>
          <span class="fs-15-b c-pointer center-text" fxFlex="5%">
          <span *ngIf="showExpenses"
                (click)="sortChanged(TaskSortBy.PRICE)">
            Price
            <ng-container *ngIf="sortBy === TaskSortBy.PRICE">
              <mat-icon class="fs-15" *ngIf="sortOrder === 'asc'">arrow_upward</mat-icon>
              <mat-icon class="fs-15" *ngIf="sortOrder === 'desc'">arrow_downward</mat-icon>
            </ng-container>
          </span>
            </span>
          <span class="fs-15-b" fxFlex="5%">
            Details
          </span>
          <span fxFlex="5%"></span>
        </div>
        <div>
          <hr>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <div *ngFor="let task of tasks">
            <div fxLayout="row" fxLayoutAlign="start center">
              <span fxFlex="3%">
              <mat-checkbox *ngIf="!isHomeOwner&&!forReport&&!isOnboarding"
                            [(ngModel)]="checkedTaskIds[task.id]"
                            [ngModelOptions]="{standalone: true}"
                            (change)="selectTask(task, $event)"></mat-checkbox>
                </span>
              <span (click)="showTask(task)"
                    [matTooltipDisabled]="!task.description"
                    [matTooltip]="CommonUtil.trimString(task.description)"
                    class="c-pointer truncate-1l" fxFlex="17%">
                {{task.title}}
              </span>
              <span (click)="openAssigneePopup(task)"
                    [matTooltip]="getListingHeadline(task.property_id)"
                    class="c-pointer truncate-1l" fxFlex="15%">
                {{getListingTitle(task)}}
              </span>
              <span (click)="openPopup(task)"
                    [matTooltip]="UserModelUtil.getFullName(task.assignee?.data)"
                    class="c-pointer truncate-1l" fxFlex="7%">
                  {{ UserModelUtil.getFullName(task.assignee?.data) }}
              </span>
              <span (click)="openEmployeePopup(task)" *ngIf="isHouseKeeper"
                    [matTooltip]="UserModelUtil.getFullName(task.employee?.data)"
                    class="c-pointer truncate-1l" fxFlex="7%">
                  {{ task.employee_id ? UserModelUtil.getFullName(task.employee?.data) : '----' }}
              </span>
              <span (click)="openCalender(task)" [matTooltip]="getText(task)" class="c-pointer" fxFlex="7%">
                {{task.created_at | dateFix | date:'dd-MMM-yyyy'}}
              </span>
              <span (click)="openCalender(task)" [matTooltip]="getText(task)" class="c-pointer" fxFlex="7%">
                {{task.due_date | dateFix | date:'dd-MMM-yyyy'}}
              </span>
              <span *ngIf="!(isHouseKeeper || isEmployee) && task.paid_on!==''" class="c-pointer"
                    [matTooltip]="getPaidOn(task.completed_on)" fxFlex="7%">
                {{task.paid_on | dateFix | date:'dd-MMM-yyyy'}}
              </span>
              <span *ngIf="!(isHouseKeeper || isEmployee) && task.paid_on == ''" class="c-pointer" fxFlex="7%">
                &nbsp;&nbsp;&nbsp; ----
              </span>
              <span *ngIf="(isHouseKeeper || isEmployee) && !!task.next_arriving_date" class="c-pointer"
                    [matTooltip]="getCheckInTime(task)" fxFlex="7%">
                {{task.next_arriving_date | dateFix | date:'dd-MMM-yyyy'}}
              </span>
              <span *ngIf="(isHouseKeeper || isEmployee) && !task.next_arriving_date" class="c-pointer"
                    [matTooltip]="getCheckInTime(task)" fxFlex="7%">
                    ----
              </span>
              <span (click)="showTask(task)" class="c-pointer center-text" fxFlex="10%">
                {{ TaskEnumHelper.getTaskCategoryTitle(task.type) }}
              </span>
              <span (click)="showTask(task)" class="c-pointer center-text" fxFlex="13%">
                <mat-chip-list fxFlex="95%">
                  <mat-chip selected="true"
                            matTooltip="{{ TaskEnumHelper.getTaskStatusEnum(task.status).tooltipText }}"
                            [matTooltipPosition]="'above'"
                            [ngStyle]="{
                                   'background': TaskEnumHelper.getTaskStatusEnum(task.status).color,
                                   'text-align':'center',
                                   'color': task.status === 'scheduled' ? 'black' : 'white'
                                 }">
                          <span style="width: 100%;text-align: center">
                            {{ TaskEnumHelper.getTaskStatusEnum(task.status).title }}
                          </span>
                  </mat-chip>
                </mat-chip-list>
              </span>
              <span (click)="showTask(task)" class="c-pointer center-text"
                    fxFlex="5%">
                <span *ngIf="showExpenses">\${{ task.amount }}</span>
              </span>
              <div fxFlex="5%">
                <a mat-icon-button target="_blank" href="/tasks/{{task.id}}">
                  <mat-icon matTooltip="Overview"
                            [matTooltipPosition]="'above'">
                    search
                  </mat-icon>
                </a>
              </div>

              <div fxFlex="5%" *ngIf="!isUserVendor && isUserAdmin && !isOnboarding">
                <mat-spinner *ngIf="isArchiving[task.id]" class="hw-30" color="accent" [diameter]="30"
                             [strokeWidth]="4"></mat-spinner>
                <button mat-icon-button *ngIf="!isArchiving[task.id] && !task.is_archived" color="accent"
                        [disabled]="task.status !== 'completed'" (click)="archiveStatusChanged(task)">
                  <mat-icon
                    [matTooltip]="task.status === 'completed' ? 'Paid' : 'Task should be complete to marked as PAID'"
                    [matTooltipPosition]="'above'">archive
                  </mat-icon>
                </button>
                <button mat-icon-button *ngIf="!isArchiving[task.id] && task.is_archived" color="danger"
                        (click)="archiveStatusChanged(task)">
                  <mat-icon matTooltip="UnPaid" [matTooltipPosition]="'above'">unarchive</mat-icon>
                </button>
              </div>
              <div style="margin-top: 1%;" fxFlex="3%">
                <mat-icon *ngIf="TaskModelUtil.hasAttachments(task)" [matTooltip]="getAttachmentCount(task)">
                  attachment
                </mat-icon>
              </div>
              <div style="margin-top: 1%;" fxFlex="3%">
                <mat-icon [matTooltip]="getCommentsCount(task)" *ngIf="task.comments_count > 0">comment
                </mat-icon>
              </div>
              <div fxFlex="5%" *ngIf="isUserVendor&&!isUserAdmin"></div>
            </div>
          </div>
          <hr>
        </div>
      </div>


      <div fxLayout="row" *ngIf="tasks.length === 0" fxLayoutAlign="center center">
        <p> No Tasks</p>
      </div>

      <div fxLayout="row" *ngIf="isCurrentPageLoading" fxLayoutAlign="center center">
        <mat-spinner color="accent" [diameter]="70" [strokeWidth]="6"></mat-spinner>
      </div>


    </mat-card>
  `,
  styles: [`

    /deep/ .mat-chip:not(.mat-basic-chip) {
      padding: 8px 4px 8px 4px !important;
      font-size: 13px !important;
    }

    mat-chip {
      width: 150px;
      text-align: center;
    }

    hr {
      width: 100%;
    }

    #container {
      width: 100%;
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
      padding: 30px;
      font-size: 13px;
    }

    b {
      font-size: 18px;
    }

  `]
})
export class TasksListComponent implements OnInit, OnDestroy {

  // ENUMS
  TaskEnumHelper = TaskEnumHelper;
  UserModelUtil = UserModelUtil;
  TaskSortBy = TaskSortBy;
  TaskModelUtil = TaskModelUtil;

  isCurrentPageLoading = false;

  selectAll = new FormControl(false);
  checkedTaskIds: { [id: number]: boolean } = {};

  // OUTPUT
  @Output() sortByUpdate = new EventEmitter<TaskSortBy>();
  @Output() sortOrderUpdate = new EventEmitter<SortOrder>();

  destroyed$ = new Subject();
  isLoading: boolean;
  isArchiving: { [id: number]: boolean } = {};

  sortBy: TaskSortBy;
  sortOrder: SortOrder;

  @Input() tasks: TaskCompact[] = [];
  @Input() listingIds: number[];
  @Input() listings: ListingNameOnly[];
  @Input() forReport = false;
  @Input() showExpenses = true;
  @Input() isOnboarding = true;

  assignees: UserCompact[] = [];
  selectedListingId: number;
  isHomeOwner = false;
  isUserVendor = false;
  isUserAdmin = false;
  isHouseKeeper = false;
  isEmployee = false;
  CommonUtil = CommonUtil;
  private dialogRef: MatDialogRef<any>;

  constructor(private service: StayDuvetService,
              private dialog: MatDialog,
              private router: Router,
              private taskRepo: TaskRepository,
              private userRepo: UserRepository,
              private optionsRepo: OptionsRepository,
              private listingRepo: ListingRepository,
              private store: Store<State>,
              private activatedRoute: ActivatedRoute) {

    this.taskRepo.getTaskSortBy().pipe(take(1)).subscribe(s => this.sortBy = s);
    this.taskRepo.getTaskSortOrder().pipe(take(1)).subscribe(s => this.sortOrder = s);

    this.taskRepo.getIsTaskCurrentPageLoading().pipe(takeUntil(this.destroyed$)).subscribe(l => this.isCurrentPageLoading = l);

    this.taskRepo.getSelectedTaskIds().pipe(
      filter(v => v.length === 0),
      takeUntil(this.destroyed$),)
      .subscribe(q => this.checkedTaskIds = {});

    this.userRepo.getUser().pipe(takeUntil(this.destroyed$))
      .subscribe((user) => {
        if (user.is_management_contact && user?.managementContact?.data?.category !== UserCategory.HOMEOWNER) {
          this.isUserVendor = true;
        }

        this.isHomeOwner = this.CommonUtil.isHomeOwner(user);

        if (user.is_admin || (user.is_management_contact && (user?.managementContact?.data?.category === UserCategory.HOMEOWNER || user?.managementContact?.data?.category === UserCategory.AGENT))) {
          this.isUserAdmin = true;
        }

        this.isHouseKeeper = CommonUtil.isHouseKeeper(user);
        this.isEmployee = CommonUtil.isEmployee(user);
      });

  }

  ngOnInit(): void {
    console.log("[TASK] ngOnInit() sd-tasks-list-component");
    this.isLoading = true;
    this.listingRepo.getActivatedListings().subscribe(listings => {
      this.listings = listings.filter(l => this.listingIds.indexOf(l.id) !== -1);
    });

    this.optionsRepo.getTaskAssignees().pipe(filter(a => !!a), takeUntil(this.destroyed$),)
      .subscribe((assignees) => this.assignees = assignees);

    this.selectAll.valueChanges.subscribe(value => {
      this.tasks.forEach(task => {
        this.checkedTaskIds[task.id] = value;
        if (value) {
          this.taskRepo.selectTask(task);
        } else {
          this.taskRepo.deselectTask(task.id);
        }
      });
    });

    this.isLoading = false;
  }

  ngOnDestroy(): void {
    console.log("[TASK] ngOnDestroy() sd-tasks-list-component");
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getListingTitle(task): string {
    const t = this.listingRepo.getListingTitle(task.property_id);
    if (t === "") {
      return task.property.data.title;
    }
    return t;
  }

  getListingHeadline(propertyId: number): string {
    return this.listingRepo.getListingHeadLine(propertyId);
  }

  showTask(task: Task) {
    const listingId = this.activatedRoute.snapshot.params.id;
    ListingTaskUtil.type = true;
    console.log("[params ]", this.activatedRoute.snapshot.params);

    if (listingId) {
      if (this.isOnboarding) {
        this.router.navigate(["/tasks/" + task.id, {id: listingId, is_onboarding: true}]);
      } else {
        this.router.navigate(["/tasks/" + task.id, {id: listingId}]);
      }
    } else {
      this.router.navigate(["/tasks/" + task.id], {queryParams: this.activatedRoute.snapshot.queryParams});
    }
  }

  /**
   * Click Listeners
   */
  sortChanged(sortBy: TaskSortBy) {
    if (this.sortBy === sortBy) {
      const newOrder = this.sortOrder === SortOrder.ASC ? SortOrder.DSC : SortOrder.ASC;
      this.sortOrderChanged(newOrder);
    } else {
      this.sortByChanged(sortBy);
    }
  }

  openPopup(task: Task) {
    if (!this.isUserVendor && this.isUserAdmin) {
      this.dialogRef = this.dialog.open(AssigneeChangePopupComponent);
      const instance = this.dialogRef.componentInstance;
      instance.listingName = this.getListingTitle(task);
      instance.task = task;
      this.dialogRef.updateSize("100%", "40%");
    } else {
      this.showTask(task);
    }
  }

  openCalender(task: Task) {
    if (this.isUserVendor || this.isUserAdmin) {
      this.dialogRef = this.dialog.open(DateSelectComponent);
      const instance = this.dialogRef.componentInstance;
      instance.task = task;
      this.dialogRef.updateSize("100%");
    } else {
      this.showTask(task);
    }
  }

  openAssigneePopup(task) {
    if (!this.isUserVendor && this.isUserAdmin) {
      this.dialogRef = this.dialog.open(ListingChangePopupComponent);
      const instance = this.dialogRef.componentInstance;
      instance.task = task;
      this.dialogRef.updateSize("100%", "40%");
    } else {
      this.showTask(task);
    }
  }

  openEmployeePopup(task) {
    if (this.isHouseKeeper && !this.isEmployee) {
      this.dialogRef = this.dialog.open(EmployeeChangePopupComponent);
      const instance = this.dialogRef.componentInstance;
      instance.task = task;
      this.dialogRef.updateSize("50%", "25%");
    } else {
      this.showTask(task);
    }
  }

  archiveStatusChanged(task) {
    this.isArchiving[task.id] = true;
    this.taskRepo.updateTaskById(task.id, {is_archived: !task.is_archived}).subscribe(task => {
      this.isArchiving[task.id] = false;
    });
  }

  /**
   * Change Listeners
   */
  selectTask(task: TaskCompact, event: MatCheckboxChange) {
    console.log(event);
    if (event.checked) {
      this.taskRepo.selectTask(task);
    } else {
      this.taskRepo.deselectTask(task.id);
    }
  }

  getAttachmentCount(task: TaskCompact): string {
    return task.attachments_count + " Attachment(s)";
  }

  getCommentsCount(task: TaskCompact): string {
    return task.comments_count + " Comment(s)";
  }

  getText(task: TaskCompact) {
    const datePipe = new DateFixPipe();
    const UTCtoLocal = new UTCtoLocalTimeZonePipe();

    if ((this.isHouseKeeper || this.isEmployee) && task.due_time) {
      return "Due Time: " + task.due_time;
    } else {
      const date = new Date(UTCtoLocal.transform(datePipe.transform(task.created_at)));
      return "Created On:" + " " + date.toDateString();
    }
  }

  getPaidOn(string) {
    if (string) {
      const datePipe = new DateFixPipe();
      const UTCtoLocal = new UTCtoLocalTimeZonePipe();
      const date = new Date(UTCtoLocal.transform(datePipe.transform(string)));
      return "Completed On: " + date.toDateString();
    }
  }

  getCheckInTime(task: TaskCompact) {
    if (task.booking) {
      return "Check In Time: " + task.booking.data.check_in_time;
    }
  }

  private sortByChanged(newSortBy: TaskSortBy) {
    this.sortByUpdate.emit(newSortBy);
    this.sortBy = newSortBy;
  }

  private sortOrderChanged(newSortOrder: SortOrder) {
    this.sortOrderUpdate.emit(newSortOrder);
    this.sortOrder = newSortOrder;
  }
}
