import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {AutoReview} from "../models/new/auto-review";
import {ScheduledReview} from "../models/new/booking/scheduled-review";
import { map } from "rxjs/operators";

@Injectable()
export class AutoReviewService {

  constructor(private apiService: ApiService) {
  }

  getAutoReviews(): Observable<AutoReview[]> {
    return this.apiService.get<{ data: AutoReview[] }>("/auto-reviews", true).pipe(
      map(res => res.data));
  }

  updateAutoReview(id: number, data: any): Observable<AutoReview> {
    return this.apiService.put<{ data: AutoReview }>("/auto-reviews/" + id, true, data).pipe(
      map(res => res.data));
  }

  createAutoReview(data: any): Observable<AutoReview> {
    return this.apiService.post<{ data: AutoReview }>("/auto-reviews", true, data).pipe(
      map(res => res.data));
  }

  deleteAutoReview(id: number): Observable<boolean> {
    return this.apiService.delete("/auto-reviews/" + id, true).pipe(
      map(res => {
       return true;
      }));
  }
}
