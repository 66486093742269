import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SafeResourceUrl } from "@angular/platform-browser";

import { AttachmentType } from "../../../../enums/uploading-enum";
import { TaskAttachment } from "../../../../models/new/tasks/task-attachment.model";
import { TaskModelUtil } from "../../../../models/utils/task-model.util";
import { AppService } from "../../../../services/app.service";

@Component({
  selector: "sd-slide-show-popup",
  template: `
    <div fxLayout="row" fxLayoutAlign="start stretch" class="slide-show-container">
      <div fxLayout="column" fxLayoutAlign="center center" class="prev-button-container">
        <button class="button" color="accent" mat-raised-button [disabled]="currentMedia == 0"
                (click)="previousMedia()">
          <mat-icon>navigate_before</mat-icon>
        </button>
      </div>
      <div class="mat-card-primary"
           fxLayoutAlign.lt-sm="center stretch" fxFlex="100%" fxLayout="row">
        <ng-container *ngFor="let attachment of attachments ; let index = index">
          <div *ngIf="index == (currentMedia%attachments.length)" fxLayout="column" fxLayoutGap="10px"
               fxLayoutAlign="center stretch" fxFlex="100%">
            <div fxFlex="10%" fxLayout="column" fxLayoutAlign="center stretch"
                 style="text-align: center">
              <p class="attachment-index">Preview Images {{ index + 1 + " / " + attachments.length }}</p>
            </div>
            <div fxLayoutAlign="center center" fxLayout="column" fxFlex="80%" [ngSwitch]="attachment.type">

              <img mat-card-image class="responsiveImage"
                   *ngSwitchDefault
                   [src]="attachment.url"/>

              <video mat-card-image class="responsiveImage" controls="controls"
                     *ngSwitchCase="AttachmentType.VIDEO">
                <source [src]="attachment.url" type="video/mp4">
                Your browser does not support the video tag.
              </video>

              <div class="responsiveImage audio-container" *ngSwitchCase="AttachmentType.AUDIO">
                <img class="limit-width" [src]="TaskModelUtil.getThumbnailUrl(attachment)" alt="mic">
                <audio id="music" preload="true" controls controlsList="nodownload"
                       onended="this.currentTime = 0;this.pause();">
                  <source [src]="attachment.url">
                </audio>
              </div>
              <ng-container *ngSwitchCase="AttachmentType.DOCUMENT">
                <img mat-card-image class="limit-width"
                     *ngIf="!TaskModelUtil.isPdf(attachment)"
                     [src]="TaskModelUtil.getThumbnailUrl(attachment)"/>
                <embed type="application/pdf" class="pdf-view"
                       *ngIf="TaskModelUtil.isPdf(attachment)"
                       [src]="getPDFUrl(attachment)"/>
              </ng-container>
            </div>
            <div fxFlex="10%" fxLayout="column" fxLayoutAlign="center stretch"
                 style="text-align: center">
              <p class="attachment-title">{{attachment.title}}</p></div>
          </div>
        </ng-container>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" class="next-button-container">
        <button class="button" color="accent" mat-raised-button [disabled]="currentMedia == attachments.length-1"
                (click)="nextMedia()">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
      <div class="close-button-container" fxLayout="column" fxLayoutAlign="center center">
        <button class="button" color="accent" mat-raised-button
                (click)="closeButtonClicked()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

  `,
  styles: [`

    :host {
      background: transparent;
      height: 100%;
      width: 100%;
    }

    .slide-show-container {
      height: 100%;
      width: 100%;
    }

    .close-button-container {
      z-index: 2;
      background: transparent;
      position: absolute;
      left: 100%;
      width: 10%;
      transform: translateX(-100%);
    }

    .prev-button-container,
    .next-button-container {
      height: 100%;
      z-index: 1;
      width: 10%;
      position: absolute;
      background: transparent;
    }

    .next-button-container {
      left: 100%;
      transform: translateX(-100%);
    }


    .button {
      border: 0px;
      min-width: 50px;
      min-height: 50px;
      border-radius: 50%;
    }

    .button:hover {
      background: rgba(0, 0, 0, 0.4);
    }

    .attachment-index {
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;
      font-size: 28px;
      color: darkgray;
    }

    .attachment-title {
      font-weight: bold;
      font-size: 2rem;
    }

    .responsiveImage {
      display: block;
      max-height: 80vh;
      min-width: 650px;
      width: auto;
      height: auto;
    }

    .audio-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .limit-width {
      height: 150px;
      width: 150px;
    }

    .pdf-view {
      width: 80vw;
      height: 100vh;
    }
  `]
})

export class SlideShowPopupComponent {
  attachments: TaskAttachment[];
  AttachmentType = AttachmentType;
  currentMedia = 0;
  TaskModelUtil = TaskModelUtil;
  placeholder = "url(https://s3-us-west-2.amazonaws.com/stayduvet-content/static/images/background-checker.png)";

  constructor(private dialogRef: MatDialogRef<SlideShowPopupComponent>,
              private appService: AppService,
              @Inject(MAT_DIALOG_DATA) public data: TaskAttachment[]) {
    this.attachments = data;
    console.log(this.attachments);
  }


  nextMedia() {
    this.currentMedia++;
    console.log(this.currentMedia);
  }

  previousMedia() {
    this.currentMedia--;
    console.log(this.currentMedia);
  }

  closeButtonClicked() {
    this.dialogRef.close();
  }

  getPDFUrl(attachment: TaskAttachment): SafeResourceUrl {
    return this.appService.getPDFUrl(attachment);
  }
}
