import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { PaginationMeta } from "../models/new/pagination-meta";
import { GuestReview } from "../models/new/reviews/guest-review";
import { OwnerReview } from "../models/new/reviews/owner-review";

import { ApiService } from "./api.service";

@Injectable()
export class ReviewService {
  constructor(private apiService: ApiService) {
  }

  getRespondReviews(data: {
    sort_type: string,
    sort_order: string,
    per_page: number,
    page: number,
    listing_ids: number[],
    rating: number[],
    include?: string
  }): Observable<{data: GuestReview[], meta: PaginationMeta}> {
    data.include = "booking";
    if(!data.listing_ids.length) {
      delete data.listing_ids;
    }
    if(!data.rating.length) {
      delete data.rating;
    }
    return this.apiService.get<{data: GuestReview[], meta: PaginationMeta}>("/admin/reviews/past", true, data);
  }

  getRatingReviews(data: {
    sort_type: string,
    sort_order: string,
    per_page: number,
    page: number,
    listing_ids: number[],
    include?: string
  }): Observable<{data: OwnerReview[], meta: PaginationMeta}> {
    data.include = "booking";
    if(!data.listing_ids.length) {
      delete data.listing_ids;
    }
    return this.apiService.get<{data: OwnerReview[], meta: PaginationMeta}>("/admin/reviews/rating-required", true, data);
  }

  createOwnerReview(data): Observable<OwnerReview> {
    return this.apiService.post<{data: OwnerReview}>("/admin/review/create-review", true, data)
      .pipe(map(res => res.data));
  }

  createResponse(data, reviewId) {
    return this.apiService.post<{data: any}>(`/admin/review/create-response/${reviewId}`, true, data)
      .pipe(map(res => res.data));
  }
}
