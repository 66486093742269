import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { UserRepository } from "../repository/user-repository";

@Component({
  selector: "sd-oauth",
  template: ``,
  styles: [``]
})

export class OauthComponent implements OnInit {
  constructor(private route: ActivatedRoute,
              private router: Router,
              private userRepo: UserRepository) {
    this.route.queryParams.subscribe(params => {
      const data = {
        code: params.code
      };
      this.userRepo.oauth(data).subscribe(res => {
        this.router.navigate(["/settings/integrations"]);
      });
    });
  }

  ngOnInit() {
  }
}
