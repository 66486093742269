import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import Quill from "quill";
import "quill-mention";

import { QuillUtils } from "../../../../utils/quill.util";

@Component({
  selector: "sd-notes-popup",
  template: `
    <sd-modal-popup-layout [preventDefaultClose]="true" [title]="title" (closeAction)="close($event)">
    </sd-modal-popup-layout>
    <!--<textarea [(ngModel)]="this.data" [formControl]="detailControl" style="font-size: 18px ;height: calc(100% - 110px); width: 100% ; margin-top: 10px"></textarea>-->
    <div #messageTextArea class="message-box"></div>

    <div fxLayout="row" style="margin-top: 10px">
      <span fxFlex="1 1 auto"></span>
      <button (click)="updateNotesDescription()" color="accent" mat-raised-button>Done
        <mat-icon>check</mat-icon>
      </button>
    </div>
  `,
  styles: [`

    .message-box {
      content-editable: true;
      font-size: 18px;
      height: calc(100% - 110px);
      width: 100%;
      margin-top: 10px;
      border-style: solid;
      border-width: 0.1px;
      padding: 5px;
      border-color: #c8c8c8
    }

  `]
})

export class NotesPopupComponent implements OnInit {
  temp;
  title = "Notes:";
  isInbox = false;
  quillBody: Quill;
  titleVariables = QuillUtils.VARIABLES;
  @ViewChild("messageTextArea", { read: ElementRef }) body: ElementRef;

  constructor(public dialog: MatDialogRef<string>,
              @Inject(MAT_DIALOG_DATA) public data: { text: string, isInbox: boolean }) {
    this.temp = data.text;
    this.isInbox = data.isInbox;

    if (this.isInbox) {
      this.title = "Write your message here";
    }
  }

  close(event: any) {
    if (event === true) {
      this.dialog.close(this.temp);
    }
    console.log(event);
  }

  updateNotesDescription() {
    this.dialog.close(this.temp);
  }

  ngOnInit(): void {

    const values = this.titleVariables;
    this.quillBody = new Quill(this.body.nativeElement, {
      theme: "bubble",
      modules: {
        mention: {
          allowedChars: /^[_A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["{"],
          isolatedCharacter: false,
          source: (searchTerm, renderList, mentionChar) => {

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(values[i]);
                }
              }
              renderList(matches, searchTerm);
            }
          },
          renderItem: (item, searchTerm) => {
            return item.value.replace("{", "{{");
          },
          dataAttributes: ["value"]
        },
      },
    });

    if (this.isInbox) {
      this.quillBody.setText(this.temp);
      this.quillBody.root.innerHTML = QuillUtils.replaceMentionWithClass(this.quillBody.root.innerHTML);
    } else {
      this.quillBody.root.innerHTML = this.temp;
    }

    this.quillBody.on("text-change", () => {
      if (this.isInbox) {
        this.temp = QuillUtils.removeExtraLines(this.quillBody.root.innerText);
      } else {
        this.temp = this.quillBody.root.innerHTML;
      }
    });

  }

}
