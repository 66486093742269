import {filter, map, take} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
  getAllInventories,
  getAllInventoryTags,
  getFullInventoryById,
  getIsFullInventoryLoaded,
  getIsFullInventoryLoading,
  getIsInventoryLoaded,
  getIsInventoryLoading, getUnTagged,
  State
} from "../reducers";
import {
  AddTagSuccess,
  InventoryCreateRequest,
  InventoryCreateSuccess,
  InventoryDeleteSuccess,
  InventoryIndexRequest,
  InventoryIndexSuccess,
  InventoryShowRequest,
  InventoryShowSuccess,
  InventoryUpdateRequest,
  InventoryUpdateSuccess,
  TagIndexSuccess
} from "../actions/new/setttings/inventory";
import {Store} from "@ngrx/store";
import {InventoryShowResponse} from "../models/responses/inventory-show.response";
import {InventoryService} from "../services/inventory.service";
import {Inventory} from "../models/new/inventory.model";
import {CommonUtil} from "../utils/common.util";

@Injectable()
export class InventoryRepository {

  constructor(private inventoryService: InventoryService,
              private store: Store<State>) {

  }

  /**
   * Loading & Loaded
   */
  getIsInventoryLoading(): Observable<boolean> {
    return this.store.select(getIsInventoryLoading);
  }

  getIsInventoryLoaded(): Observable<boolean> {
    return this.store.select(getIsInventoryLoaded);
  }

  getUnTagged(): Observable<boolean> {
    return this.store.select(getUnTagged);
  }

  getIsFullInventoryLoading(taskId: number): Observable<boolean> {
    return this.store.select(state => getIsFullInventoryLoading(state, taskId));
  }

  getIsFullInventoryLoaded(taskId: number): Observable<boolean> {
    return this.store.select(state => getIsFullInventoryLoaded(state, taskId));
  }

  /**
   * Service Methods
   */


  getAllInventories(force: boolean, unTagged?: boolean, listingId?: number): Observable<Inventory[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsInventoryLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsInventoryLoaded().pipe(take(1)).subscribe(l => loaded = l);
    console.log(loading, loaded);
    if (!loading && (!loaded || force)) {
      this.store.dispatch(new InventoryIndexRequest());

      this.inventoryService.getInventoryList(unTagged).subscribe(response => {
        this.store.dispatch(new InventoryIndexSuccess({inventories: response.data, unTagged: unTagged}));
      });
    }

    if (listingId) {
      // inventories not related to listings yet.
      // return this.store.select(state => getFullInventoryById(state, listingId));
    }

    return this.store.select(getAllInventories).pipe(filter(i => !!i), map(t => CommonUtil.sortByKey(t, "title") as Inventory[]),);
  }

  getInventoryById(inventoryId: number): Observable<Inventory> {
    let loading = false;
    let loaded = false;

    this.getIsFullInventoryLoading(inventoryId).pipe(take(1)).subscribe(l => loading = l);
    this.getIsFullInventoryLoaded(inventoryId).pipe(take(1)).subscribe(l => loaded = l);
    console.log("LOADING", loading, "LOADED", loaded);
    if (!loading && !loaded) {
      this.store.dispatch(new InventoryShowRequest(inventoryId));
      this.inventoryService.getInventoryById(inventoryId).subscribe(
        response => {
          console.log("API CALL", response);
          this.store.dispatch(new InventoryShowSuccess(response));
        });
    }
    console.log("STORE CALL");
    return this.store.select((state) => getFullInventoryById(state, inventoryId)).pipe(
      filter(v => !!v),
      map(inventory => inventory as Inventory),);
  }

  // the response type of addItem is not known
  createInventory(data: any): Observable<InventoryShowResponse> {
    this.store.dispatch(new InventoryCreateRequest());
    return this.inventoryService.addItem(data).pipe(map(res => {
      this.store.dispatch(new InventoryCreateSuccess(res.data));
      return res;
    }))
  }

  updateInventory(data: any, inventoryId: number): Observable<Inventory> {
    this.store.dispatch(new InventoryUpdateRequest(inventoryId));
    return this.inventoryService.updateItem(data, inventoryId).pipe(map((res) => {
      this.store.dispatch(new InventoryUpdateSuccess(res));
      return res;
    }));
  }

  deleteInventory(inventoryId: number):Observable<boolean> {
    return this.inventoryService.deleteItem(inventoryId).pipe(map((res) => {
    this.store.dispatch(new InventoryDeleteSuccess(inventoryId));
    return res;
    }));
  }

  exportInventory(data: any):Observable<any>{
    return this.inventoryService.exportInventory(data);
  }

  getTags():Observable<any>{
      this.inventoryService.getTags().subscribe(res => {
       console.log(res);
       this.store.dispatch(new TagIndexSuccess(res.data));
     });
    return this.store.select(getAllInventoryTags).pipe(map(tag => tag as {}));
  }

  attachTag(inventory: Inventory,title){
    return this.inventoryService.attachTag(inventory.id, title).pipe(map(res => {
        const newInventory = {...inventory};
          newInventory["tags"]["data"].push(res.data);
          this.store.dispatch(new InventoryUpdateSuccess(newInventory));
    }));
  }

  createTag(tagTitle): Observable<any>{
    return this.inventoryService.createTag(tagTitle).pipe(map(res => {
      this.store.dispatch(new AddTagSuccess(res.data));
    }));
  }

  deleteTag(inventory,tag):Observable<any>{
    return this.inventoryService.deleteTag(inventory.id, tag.title).pipe(map(res => {
      let tagsData = inventory.tags.data;
      tagsData = tagsData.filter(item =>
        item.id !== tag.id
      );
      const newInventory: Inventory  = {
        ...inventory,
        tags: {
          data : tagsData
        }
      };
      this.store.dispatch(new InventoryUpdateSuccess(newInventory));
    }));
  }
}
