import { Setting } from "../models/setting";
import { Action } from "./action";


export const INDEX_REQUEST = "[SETTING] Index Request";
export const INDEX_SUCCESS = "[SETTING] Index Success";

export const UPDATE_REQUEST = "[SETTING] Update Request";
export const UPDATE_SUCCESS = "[SETTING] Update Success";


export class SettingIndexRequestAction implements Action {
  readonly type = INDEX_REQUEST;
}

export class SettingIndexSuccessAction implements Action {
  readonly type = INDEX_SUCCESS;

  constructor(public payload: Setting[]) {
  }
}

export class SettingUpdateRequestAction implements Action {
  readonly type = UPDATE_REQUEST;
}

export class SettingUpdateSuccessAction implements Action {
  readonly type = UPDATE_SUCCESS;

  constructor(public payload: Setting) {
  }
}


export type Actions
  = SettingIndexRequestAction
  | SettingIndexSuccessAction
  | SettingUpdateRequestAction
  | SettingUpdateSuccessAction;
