import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Charity} from "../../models/charity";
import {Action} from "../../actions/action";
import {CharityActionTypes} from "../../actions/new/charity";

export interface CharityState extends EntityState<Charity> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const charityAdapter: EntityAdapter<Charity> = createEntityAdapter<Charity>({
  selectId: (inventory: Charity) => inventory.id,
  sortComparer: sortByTitle
});

export function sortByTitle(a: Charity, b: Charity): number {
  if (a.title.toLowerCase() > b.title.toLowerCase()) {
    return 1;
  }

  if (a.title.toLowerCase() < b.title.toLowerCase()) {
    return -1;
  }

  return 0;
}

const initialState = charityAdapter.getInitialState({
  isLoading: false,
  isLoaded: false
})

export function charityReducer(state: CharityState = initialState, action: Action): CharityState {
  switch (action.type) {
    case CharityActionTypes.INDEX_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      }
    }
    case CharityActionTypes.INDEX_SUCCESS: {
      return charityAdapter.addAll(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true
      })
    }
    case CharityActionTypes.CREATE_SUCCESS: {
      return charityAdapter.addOne(action.payload, state);
    }
    case CharityActionTypes.UPDATE_SUCCESS: {
      return charityAdapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
    }
    case CharityActionTypes.DELETE_SUCCESS: {
      return charityAdapter.removeOne(action.payload, state);
    }
    default: {
      return state;
    }
  }
}

export const _isCharitiesLoading = (state: CharityState) => state.isLoading;
export const _isCharitiesLoaded = (state: CharityState) => state.isLoaded;
