/**
 * Created by aditya on 24/8/17.
 */
import { Component, Input, OnInit } from "@angular/core";

import { UserFull } from "../../../models/new/user/user-full.model";
import { UserModelUtil } from "../../../models/utils/user-model.util";

@Component({
  selector: "sd-contact-log-info",
  template: `
    <div style="margin: 20px;">
      <div style="border: 1px solid #cccccc;padding-top:10px" fxFlex="100%" fxLayout="column">
        <div fxLayout="column" *ngFor="let data of UserModelUtil.getLogs(contact)" style="width:100%">
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
               fxLayoutAlign.lt-sm="center center" fxFlex="100%">
            <div class="width-30" style="margin-left:15px">

              <mat-chip-list>
                <mat-chip [ngStyle]="{'background':getColor(data.type)}"
                          style=" text-align: center;color: white"
                          selected="true">
                  <span style="width: 100%; text-align: center; font-size: xx-small;">
                    <b>{{getStatus(data.type)}}</b>
                  </span>
                </mat-chip>
              </mat-chip-list>

            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                 fxLayoutAlign.lt-sm="center center" fxFlex="80%">
              <div style="margin-top:8px" class="width-60">
                <span>{{getDescription(data.meta)}}</span>
              </div>
              <div style="margin-top:8px" class="width-20">
                <span>{{data.created_at | utcToLocalTime | date: 'medium'}}</span>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxFlex="100%">
            <hr class="contentBorder">
          </div>
        </div>


        <div *ngIf="UserModelUtil.getLogs(contact).length == 0" fxLayout="row"
             fxLayoutAlign="center"
             fxFlex="100%">
          <h5>No Logs Found</h5>
        </div>

      </div>
    </div>

  `,
  styles: [`

    span {
      font-size: 14px !important;
      font-weight: bold;
    }

    .generalCard {
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%); /* Chrome10+,Safari5.1+ */;
      padding: 15px !important;
    }

    .financialsText span {
      font-size: 13px;
    !important;

    }

    .contentBorder {
      border: none;
      /* Set the hr color */
      color: #cccccc; /* old IE */
      background-color: #cccccc; /* Modern Browsers */
      height: 1px;
      width: 100%;
    }

    .width-20 {
      width: 20%;
    }

    .width-30 {
      width: 30%;
    }

    .width-40 {
      width: 40%;
    }

    .width-60 {
      width: 60%;
    }

    @media only screen and (max-width: 700px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 90%;
      }

      .responsivewidth {
        width: 80%;
      }

      .responsiveAlign {
        text-align: center;
      }
    }

  `]

})

export class ContactLogInfoComponent implements OnInit {


  @Input() contact: UserFull;
  UserModelUtil = UserModelUtil;


  ngOnInit() {


  }

  getColor(type: string) {
    switch (type) {
      case "created":
        return "#4F8A10";
      case "updated":
        return "#D17B26";
      case "user_info_updated":
        return "#dc8327";
      case "bank_info_updated":
        return "#d98028";
      case "user_type_updated":
        return "#D17B26";
      case "commission_updated":
        return "#f7912b";
      case "listing_added":
        return "#8a7030";
      case "listing_removed":
        return "#328a70";
      case "bank_account_created":
        return "#4F8A10";
      case "bank_account_updated":
        return "#4F8A10";
      case "bank_account_deleted":
        return "#328a70";
    }
  }

  getStatus(type: string) {
    switch (type) {
      case "updated":
        return "UPDATED";
      case "created":
        return "CREATED";
      case "user_info_updated":
        return "USER INFO UPDATE";
      case "bank_info_updated":
        return "BANK INFO UPDATE";
      case "user_type_updated":
        return "USER TYPE UPDATE";
      case "commission_updated":
        return "COMMISSION UPDATE";
      case "listing_added":
        return "LISTING UPDATE";
      case "listing_removed":
        return "LISTING UPDATE";
      case "bank_account_created":
        return "BANK ACCOUNT CREATED";
      case "bank_account_updated":
        return "BANK ACCOUNT UPDATED";
      case "bank_account_deleted":
        return "BANK ACCOUNT DELETED";
    }
  }

  getDate(meta: string) {
    return JSON.parse(meta).date;
  }

  getDescription(meta: string) {
    return JSON.parse(meta).description;
  }


}


