import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import * as _ from "lodash";
import { createSelector } from "reselect";

import { Action } from "../../../actions/action";
import { BookingActionTypes } from "../../../actions/new/bookings/bookings";
import { TaskActionTypes } from "../../../actions/new/tasks/task";
import { SortOrder } from "../../../enums/common.enum";
import { HomeownerReservationType, ReservationSortType, ReservationType } from "../../../enums/reservation.enum";
import { BookingCompact } from "../../../models/new/booking/booking-compact.model";
import { BookingFull } from "../../../models/new/booking/booking-full.model";
import { ScheduledReview } from "../../../models/new/booking/scheduled-review";
import { TaskCompact } from "../../../models/new/tasks/task-compact.model";
import { TaskFull } from "../../../models/new/tasks/task-full.model";
import { BookingModelUtil } from "../../../models/utils/booking-model.util";
import { getDateObj } from "../../../utils/calendar-utils";
import Utils from "../../../utils/utils";

export interface BookingsState extends EntityState<BookingFull | BookingCompact> {
  isLoading: boolean;
  isLoaded: boolean;
  updating: boolean;
  updated: boolean;

  fullLoadingIds: number[];
  fullLoadedIds: number[];

  sortOrder: SortOrder;
  bookingType: ReservationType | HomeownerReservationType;
  sortType: ReservationSortType;
  review: number[];

  ownerUpcomingIds: number[];
  ownerUpcomingEntities: { [id: number]: BookingCompact };
  ownerUpcomingLoading: boolean;
  ownerUpcomingLoaded: boolean;

  currentPage: number;
  totalPages: number;
  perPageCount: number;
  total: number;

  badges: { [id: number]: number };

  listingIds: number[];
  sources: string[];
  tags: number[];

  bookingsNotExist: number[];
  vendorTasksLoading: { [id: number]: boolean };
  vendorTasksLoaded: { [id: number]: boolean };
  vendorTasks: { [id: number]: TaskCompact[] };

  start?: Date;
  end?: Date;
}

export const bookingAdapter: EntityAdapter<BookingFull | BookingCompact> = createEntityAdapter<BookingFull | BookingCompact>({
  selectId: (booking: BookingCompact | BookingFull) => booking.id
});

export const initialState: BookingsState = bookingAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  updating: false,
  updated: false,

  fullLoadingIds: [],
  fullLoadedIds: [],

  sortOrder: SortOrder.ASC,
  bookingType: ReservationType.TODAYS_BOOKINGS,
  sortType: null,
  review: [],

  currentPage: 1,
  totalPages: 0,
  perPageCount: 100,
  total: 0,

  ownerUpcomingIds: [],
  ownerUpcomingEntities: {},
  ownerUpcomingLoading: false,
  ownerUpcomingLoaded: false,

  badges: {},

  listingIds: [],
  sources: [],
  tags: [],

  bookingsNotExist: [],
  vendorTasksLoading: {},
  vendorTasksLoaded: {},
  vendorTasks: {}
});

export function bookingReducer(state: BookingsState = initialState, action: Action): BookingsState {
  switch (action.type) {

    case BookingActionTypes.CREATE_SUCCESS: {
      const booking = action.payload;

      const date = getDateObj();
      const fullDate = ("0" + date.getDate()).slice(-2);
      const fullMonth = ("0" + (date.getMonth() + 1)).slice(-2);
      const fullYear = date.getFullYear();
      const currentDateString = fullYear + "-" + fullMonth + "-" + fullDate;

      if (currentDateString === booking.start) {
        // Today Booking
        return {
          ...state,
          entities: {
            ...state.entities,
            [booking.id]: booking
          },
        };
      }

      return state;
    }

    case BookingActionTypes.INDEX_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case BookingActionTypes.INDEX_SUCCESS: {
      const bookings = action.payload as {
        bookings: BookingCompact[], currentPage: number,
        totalPages: number, perPageCount: number, total: number
      };

      return bookingAdapter.addAll(bookings.bookings, {
        ...state,
        currentPage: bookings.currentPage,
        totalPages: bookings.totalPages,
        perPageCount: bookings.perPageCount,
        total: bookings.total,
        isLoading: false,
        isLoaded: true,
        fullLoadingIds: [],
        fullLoadedIds: [],
      });
    }

    case BookingActionTypes.SHOW_REQUEST: {
      const bookingId = action.payload as number;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, bookingId);

      // Adding if not present
      if (loadingIndex === -1) {
        fullyLoadingIds = [
          ...fullyLoadingIds,
          bookingId
        ];
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, bookingId);

      // Removing if already added      let fullyLoadedIds = state.fullLoadedIds;

      if (loadedIndex !== -1) {
        fullyLoadedIds = _.remove(fullyLoadedIds, bookingId);
      }

      return {
        ...state,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      };
    }

    case BookingActionTypes.SHOW_SUCCESS: {
      const booking = action.payload as BookingFull;

      let fullyLoadingIds = state.fullLoadingIds;
      const loadingIndex = _.indexOf(fullyLoadingIds, booking.id);

      // Removing if loading
      if (loadingIndex !== -1) {
        fullyLoadingIds = _.remove(fullyLoadingIds, booking.id);
      }

      let fullyLoadedIds = state.fullLoadedIds;
      const loadedIndex = _.indexOf(fullyLoadedIds, booking.id);

      // Adding if not loaded.
      if (loadedIndex === -1) {
        fullyLoadedIds = [
          ...fullyLoadedIds,
          booking.id
        ];
      }

      const addedState = bookingAdapter.addOne(booking, state);

      return bookingAdapter.updateOne({
        id: booking.id,
        changes: booking
      }, {
        ...addedState,
        fullLoadingIds: fullyLoadingIds,
        fullLoadedIds: fullyLoadedIds,
      });
    }

    case BookingActionTypes.SORT_ORDER_CHANGE: {
      return {
        ...state,
        sortOrder: action.payload as SortOrder,
      };
    }

    case BookingActionTypes.TYPE_CHANGE: {
      return {
        ...state,
        bookingType: action.payload as ReservationType | HomeownerReservationType,
      };
    }

    case BookingActionTypes.SORT_TYPE_CHANGE: {
      return {
        ...state,
        sortType: action.payload as ReservationSortType,
      };
    }

    case BookingActionTypes.PER_PAGE_CHANGE: {
      return {
        ...state,
        perPageCount: action.payload as number,
      };
    }

    case BookingActionTypes.PAGE_CHANGE: {
      return {
        ...state,
        currentPage: action.payload as number,
      };
    }

    case BookingActionTypes.REVIEW_RATING_CHANGE: {
      return {
        ...state,
        review: action.payload as number[],
      };
    }

    case BookingActionTypes.SCHEDULED_MESSAGE_UPDATE_SUCCESS: {
      const scheduledMessage = action.payload.scheduledMessage;
      const bookingId = action.payload.bookingId;

      const booking = state.entities[bookingId] as BookingFull;

      if (booking) {
        const index = booking.scheduledMessages.data.findIndex(message => {
          return message.id === scheduledMessage.id;
        });

        booking.scheduledMessages.data = [
          ...booking.scheduledMessages.data.slice(0, index),
          scheduledMessage,
          ...booking.scheduledMessages.data.slice(index + 1),
        ];
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [bookingId]: booking
        }
      };
    }

    case BookingActionTypes.SCHEDULED_REVIEW_UPDATE_SUCCESS: {
      const review = action.payload.review as ScheduledReview;
      const bookingId = action.payload.bookingId;

      return bookingAdapter.updateOne({
        id: bookingId,
        changes: {
          scheduledReview: {
            data: review
          }
        }
      }, state);
    }

    case BookingActionTypes.ADD_GUEST_SUCCESS: {
      const guest = action.payload.guest;
      const bookingId = action.payload.bookingId;
      const booking = state.entities[bookingId] as BookingFull;
      booking.guests.data = [
        ...booking.guests.data,
        guest
      ];

      return {
        ...state,
        entities: {
          ...state.entities,
          [bookingId]: booking
        }
      };
    }

    case BookingActionTypes.GUEST_UPDATE_SUCCESS: {
      const guest = action.payload.guest;
      const bookingId = action.payload.bookingId;
      const booking = state.entities[bookingId] as BookingFull;

      const index = BookingModelUtil.getGuests(booking).findIndex(item => {
        return String(item.id) === guest.id;
      });

      if (index > -1) {
        booking.guests.data = [
          ...booking.guests.data.slice(0, index),
          guest,
          ...booking.guests.data.slice(index + 1),
        ];
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [bookingId]: booking
        }
      };
    }

    case BookingActionTypes.DELETE_GUEST_SUCCESS: {
      const guestId = action.payload.guestId;
      const bookingId = action.payload.bookingId;

      const booking = state.entities[bookingId] as BookingFull;

      const guests = BookingModelUtil.getGuests(booking);

      const index = guests.findIndex(item => {
        return String(item.id) === guestId;
      });

      if (index > -1) {
        [...booking.guests.data].splice(index, 1);
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [bookingId]: booking
        }
      };

    }

    case BookingActionTypes.BOOKING_PAYMENT_CREATE_SUCCESS: {
      const bookingId = action.payload.bookingId;
      const payment = action.payload.payment;

      const booking = state.entities[bookingId] as BookingFull;

      const payments = booking.payments.data;
      payments.push(payment);

      booking.payments.data = payments;
      booking.total_paid += payment.amount;

      return {
        ...state,
        entities: {
          ...state.entities,
          [bookingId]: booking
        }
      };
    }

    case BookingActionTypes.UPCOMING_OWNER_INDEX_REQUEST: {
      return {
        ...state,
        ownerUpcomingLoading: true,
      };
    }

    case BookingActionTypes.UPCOMING_OWNER_INDEX_SUCCESS: {
      const bookings = action.payload;
      const bookingIds = bookings.map(booking => booking.id);
      const entities = Utils.normalize(bookings);
      return {
        ...state,
        ownerUpcomingLoading: false,
        ownerUpcomingIds: bookingIds,
        ownerUpcomingLoaded: true,
        ownerUpcomingEntities: entities
      };
    }

    case BookingActionTypes.SECURITY_DEDUCTION_SUCCESS: {
      const deduction = action.payload.deduction;
      const bookingId = action.payload.bookingId;

      const booking = state.entities[bookingId] as BookingFull;

      booking.securityDeductions.data = [
        ...booking.securityDeductions.data,
        deduction
      ];

      return {
        ...state,
        entities: {
          ...state.entities,
          [bookingId]: booking
        }
      };
    }

    case BookingActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        updating: true
      };
    }

    case BookingActionTypes.UPDATE_SUCCESS: {
      const booking = action.payload as BookingFull;

      return bookingAdapter.updateOne({
        id: booking.id,
        changes: booking
      }, {
        ...state,
        updating: false,
        updated: true
      });
    }

    case TaskActionTypes.CREATE_SUCCESS: {
      const task = action.payload as TaskFull;

      if (task.booking_id) {

        const booking = bookingAdapter.getSelectors().selectEntities(state)[task.booking_id] as BookingFull;

        if (booking) {
          const newTasks = [
            ...booking.tasks.data,
            task
          ];

          return bookingAdapter.updateOne({
            id: task.booking_id,
            changes: {
              tasks: {
                data: newTasks
              }
            }
          }, state);
        }
        return state;
      }

      return state;
    }

    case TaskActionTypes.UPDATE_SUCCESS: {
      const task = action.payload as TaskFull;

      if (task.booking_id) {

        const booking = bookingAdapter.getSelectors().selectEntities(state)[task.booking_id] as BookingFull;

        if (booking) {
          const index = booking.tasks.data.map(task => task.id).indexOf(task.id);

          let newTasks = booking.tasks.data;

          if (index !== -1) {
            newTasks = [
              ...booking.tasks.data.slice(0, index),
              ...booking.tasks.data.slice(index + 1),
              task
            ];
          } else {
            newTasks = [
              ...booking.tasks.data,
              task
            ];
          }

          return bookingAdapter.updateOne({
            id: task.booking_id,
            changes: {
              tasks: {
                data: newTasks
              }
            }
          }, state);
        }
        return state;
      }

      return state;
    }

    case BookingActionTypes.GUEST_SET_BADGE: {
      return {
        ...state,
        badges: {
          ...state.badges,
          [action.payload.bookingId]: action.payload.count
        }
      };
    }

    case BookingActionTypes.LISTINGS_CHANGE: {
      const listingIds = action.payload as number[];

      return {
        ...state,
        listingIds: listingIds,
      };
    }

    case BookingActionTypes.START_CHANGE: {
      const date = action.payload as Date;
      return {
        ...state,
        start: date,
      };
    }

    case BookingActionTypes.END_CHANGE: {
      const date = action.payload as Date;
      return {
        ...state,
        end: date,
      };
    }

    case BookingActionTypes.SOURCES_CHANGE: {
      const sources = action.payload as string[];

      return {
        ...state,
        sources: sources,
      };
    }

    case BookingActionTypes.TAGS_CHANGE: {
      const tag = action.payload as number[];

      return {
        ...state,
        tags: tag
      };
    }

    case BookingActionTypes.ADD_BOOKING_TO_NOT_EXIST: {
      const bookingId = action.payload as number;
      const data = state.bookingsNotExist;
      data.push(bookingId);
      return {
        ...state,
        bookingsNotExist: data
      };
    }

    case BookingActionTypes.VENDOR_TASKS_INDEX_REQUEST: {
      const bookingId = action.payload as number;
      return {
        ...state,
        vendorTasksLoading: {
          ...state.vendorTasksLoading,
          [bookingId]: true
        },
        vendorTasksLoaded: {
          ...state.vendorTasksLoaded,
          [bookingId]: false
        },
      };
    }

    case BookingActionTypes.VENDOR_TASKS_INDEX_SUCCESS: {
      const payload = action.payload as { bookingId: number, tasks: TaskCompact[] };
      return {
        ...state,
        vendorTasksLoading: {
          ...state.vendorTasksLoading,
          [payload.bookingId]: false
        },
        vendorTasksLoaded: {
          ...state.vendorTasksLoaded,
          [payload.bookingId]: true
        },
        vendorTasks: {
          ...state.vendorTasks,
          [payload.bookingId]: payload.tasks
        }
      };
    }

    default: {
      return state;
    }

  }
}

export const _getIsBookingsLoading = (state: BookingsState) => {
  return state.isLoading;
};
export const _getIsBookingsLoaded = (state: BookingsState) => {
  return state.isLoaded;
};
export const _getIsFullBookingsLoading = (state: BookingsState, bookingId: number) => {
  return state.fullLoadingIds.indexOf(bookingId) !== -1;
};
export const _getIsFullBookingsLoaded = (state: BookingsState, bookingId: number) => {
  return state.fullLoadedIds.indexOf(bookingId) !== -1;
};
export const _getSortOrder = (state: BookingsState) => {
  return state.sortOrder;
};
export const _getReviewRating = (state: BookingsState) => {
  return state.review;
};
export const _getReservationType = (state: BookingsState) => {
  return state.bookingType;
};
export const _getReservationSortType = (state: BookingsState) => {
  return state.sortType;
};
export const _getTotalCount = (state: BookingsState) => {
  return state.total;
};
export const _getPerPageCount = (state: BookingsState) => {
  return state.perPageCount;
};
export const _getTotalPages = (state: BookingsState) => {
  return state.totalPages;
};
export const _getCurrentPage = (state: BookingsState) => {
  return state.currentPage;
};

export const _getIsOwnerUpcomingLoading = (state: BookingsState) => state.ownerUpcomingLoading;
export const _getIsOwnerUpcomingLoaded = (state: BookingsState) => state.ownerUpcomingLoaded;

export const _getOwnerUpcomingEntities = (state: BookingsState) => state.ownerUpcomingEntities;

export const _getOwnerUpcomingIds = (state: BookingsState) => state.ownerUpcomingIds;

export const _getOwnerUpcomingAll = createSelector(_getOwnerUpcomingEntities, _getOwnerUpcomingIds, (entities, ids) => {
  return ids.map(id => entities[id]);
});

export const _getBadgeCount = (state: BookingsState, bookingId: number) => {
  return state.badges[bookingId];
};

export const _getListingIds = (state: BookingsState) => {
  return state.listingIds;
};

export const _getSources = (state: BookingsState) => {
  return state.sources;
};

export const _getTags = (state: BookingsState) => {
  return state.tags;
};

export const _getStart = (state: BookingsState) => state.start;
export const _getEnd = (state: BookingsState) => state.end;

export const _getBookingExist = (bookingId: number, state: BookingsState) => {
  return state.bookingsNotExist.indexOf(bookingId) !== -1;
};

export const _getVendorTasksLoading = (state: BookingsState) => state.vendorTasksLoading;
export const _getVendorTasksLoaded = (state: BookingsState) => state.vendorTasksLoaded;
export const _getVendorTasks = (state: BookingsState) => state.vendorTasks;
