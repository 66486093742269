import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Task } from "app/models/task";

import { UserCompact } from "../../../../models/new/user/user-compact.model";
import { getAssigness, State } from "../../../../reducers";
import { TaskRepository } from "../../../../repository/task.repository";
import { StayDuvetService } from "../../../../services/stayduvet";

@Component({
  selector: "sd-assignee-change-popup",
  template: `
    <sd-modal-popup-layout title="Change Assignee">
    </sd-modal-popup-layout>
    <div fxLayout="column" style="margin-top: 2%">
      <form [formGroup]="formGroup" (ngSubmit)="this.formGroup.valid">
        <sd-select placeholder="Assignee" [control]="assigneeId" [options]="assigneeOptions"></sd-select>
      </form>
      <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%">
        <button mat-raised-button color="primary" matDialogClose>Cancel</button>
        <button [disabled]="isUpdating" mat-raised-button color="accent" (click)="assigneeChanged()">Save</button>
        <mat-spinner [diameter]="30" [strokeWidth]="4" color="accent" *ngIf="isUpdating"></mat-spinner>
      </div>
    </div>
  `,
  styles: [`

  `]
})

export class AssigneeChangePopupComponent implements OnInit {
  @Input() task: Task;
  assignees: UserCompact[] = [];
  assigneeOptions: {title: string, value: any}[];
  isUpdating: boolean = false;
  assigneeId: FormControl;
  formGroup: FormGroup;

  constructor(private taskRepo: TaskRepository,
              private stayDuvetService: StayDuvetService,
              private dialog: MatDialog,
              private store: Store<State>) {
  }

  ngOnInit() {
    console.log(this.task.assignee_id);

    this.store.select(getAssigness).subscribe((assignees) => {
      this.assignees = assignees;
      this.assigneeOptions = this.assignees.map(assignee => {
        return {title: assignee.first_name + " " + assignee.last_name, value: assignee.id};
      });
    });
    this.assigneeId = new FormControl(this.task.assignee_id, [Validators.required]);
    this.formGroup = new FormGroup({
      assignee_id: this.assigneeId,
    });
  }

  assigneeChanged() {
    this.isUpdating = true;
    console.log("function is called");
    const data = this.formGroup.value;
    this.taskRepo.updateTaskById(this.task.id, data)
      .subscribe((task) => {
          this.isUpdating = false;
          this.dialog.closeAll();
          console.log("works");
          console.log(task);
        },
        (error) => {
          this.isUpdating = false;
          console.log("error");
        });
  }
}
