import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { UserCompact } from "../../../../models/new/user/user-compact.model";
import { UserFull } from "../../../../models/new/user/user-full.model";
import { UserModelUtil } from "../../../../models/utils/user-model.util";
import { ContactRepository } from "../../../../repository/contact.repository";
import { UserRepository } from "../../../../repository/user-repository";
import { CreateContactPopupComponent } from "../popups/create-contact-popup";

@Component({
  selector: "sd-contact-employee",
  template: `
    <div fxLayoutAlign="space-between center" style="margin-top:50px;">
      <span style="font-size: large;font-weight: bold;">Employees:</span>
      <button *ngIf="!isAdmin" mat-raised-button color="accent" (click)="addEmployee()">Add Employee</button>
    </div>
    <div style="margin-top:20px;" fxLayoutGap="20px" fxLayout="column">
      <div *ngFor="let contact of managementContacts">
        <div fxLayout="column" fxLayoutGap="20px">
          <mat-card fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="flex-start center" class="background">

            <div fxLayout="column" class="image" style="margin-top:15%" fxLayoutAlign="center center"
                 (click)="redirect(contact.id)">
              <img class="profileImage" [src]="UserModelUtil.getProfilePic(contact)">
            </div>

            <div fxLayout="column" (click)="redirect(contact.id)">
              <div fxLayout="row" fxLayoutGap="30px">
                <span class="text">Name:</span>
                <span>{{UserModelUtil.getFullName(contact)}}</span>
              </div>

              <div fxLayout="row" fxLayoutGap="30px">
                <span class="text">Email:</span>
                <span>{{contact.email}}</span>
              </div>

              <div fxLayout="row" fxLayoutGap="30px">
                <span class="text">Role:</span>
                <span>{{UserModelUtil.getCategoryTitle(contact.managementContact.data.category)}}</span>
              </div>
            </div>
            <span class="space-filler"></span>
            <div fxFlexAlign="center" fxLayoutAlign="center center">
              <mat-slide-toggle *ngIf="!isDeactivating[contact.id]" [checked]="contact.is_active"
                                (change)="onActiveStatusChanged(contact.id, $event)">Active
              </mat-slide-toggle>
              <mat-spinner *ngIf="isDeactivating[contact.id]" color="accent" [strokeWidth]="4"
                           [diameter]="30"></mat-spinner>

            </div>

          </mat-card>
        </div>
      </div>

      <mat-card *ngIf="managementContacts.length === 0" fxLayout="column" fxLayoutGap="20px" class="background">
        <div fxLayout="row" fxLayoutAlign="center center" style="height: 100px">
          <span> No Employees Found</span>
        </div>
      </mat-card>
    </div>
  `,
  styles: [`
    .background {
      background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }

    .text {
      width: 75px;
      font-weight: bold;
      font-size: medium;
    }

    .profileImage {
      display: block;
      width: 75px;
      height: 75px;
      margin: 1em auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-border-radius: 99em;
      -moz-border-radius: 99em;
      border-radius: 99em;
      border: 3px solid #eee;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
    }
  `]
})

export class ContactEmployeeComponent implements OnInit, OnDestroy {

  isAdmin = false;
  isDeactivating: { [id: number]: boolean } = {};
  managementContacts: UserCompact[] = [];
  UserModelUtil = UserModelUtil;
  private destroyed$ = new Subject();
  private matDialogRef: any;

  constructor(private contactRepo: ContactRepository,
              private userRepo: UserRepository,
              private router: Router,
              private dialog: MatDialog) {
  }

  private _contact: UserFull;

  get contact(): UserFull {
    return this._contact;
  }

  @Input() set contact(contact: UserFull) {
    this._contact = contact;
    this.managementContacts = contact.managementContacts.data;
  }

  ngOnInit() {
    this.userRepo.getUser().pipe(
      takeUntil(this.destroyed$))
      .subscribe(u => {
        this.isAdmin = u.type === "admin";
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  addEmployee() {
    this.matDialogRef = this.dialog.open(CreateContactPopupComponent);
    const instance = this.matDialogRef.componentInstance as CreateContactPopupComponent;

    instance.isAddingEmployee = true;
    this.matDialogRef.updateSize("100%", "100%");
  }

  redirect(id: number) {
    this.router.navigate(["/employees/", id]);
  }

  onActiveStatusChanged(contactId: number, $event) {
    this.isDeactivating[contactId] = true;
    if ($event.checked) {
      this.contactRepo.markContactActive(contactId).subscribe(res => {
        delete this.isDeactivating[contactId];
      });
    } else {
      this.contactRepo.markContactInactive(contactId).subscribe(res => {
        delete this.isDeactivating[contactId];
      });
    }
  }
}
