import { Comment } from "../../../models/new/tasks/comment";
import { Action } from "../../action";

export enum TaskCommentActionTypes {
  INDEX_REQUEST = "[TASK COMMENT NEW] Index Request",
  INDEX_SUCCESS = "[TASK COMMENT NEW] Index Success",

  CREATE_REQUEST = "[TASK COMMENT NEW] Create Request",
  CREATE_SUCCESS = "[TASK COMMENT NEW] Create Success",

  UPDATE_REQUEST = "[TASK COMMENT NEW] Update Request",
  UPDATE_SUCCESS = "[TASK COMMENT NEW] Update Success",

  DELETE_REQUEST = "[TASK COMMENT NEW] Delete Request",
  DELETE_SUCCESS = "[TASK COMMENT NEW] Delete Success",

}

export class TaskCommentIndexRequest implements Action {
  readonly type = TaskCommentActionTypes.INDEX_REQUEST;

  constructor(public payload: number) {
  }
}

export class TaskCommentIndexSuccess implements Action {
  readonly type = TaskCommentActionTypes.INDEX_SUCCESS;

  constructor(public payload: { comments: Comment[], taskId: number }) {
  }
}

export class TaskCommentCreateRequest implements Action {
  readonly type = TaskCommentActionTypes.CREATE_REQUEST;
}

export class TaskCommentCreateSuccess implements Action {
  readonly type = TaskCommentActionTypes.CREATE_SUCCESS;

  constructor(public payload: { comment: Comment, taskId: number }) {
  }
}

export class TaskCommentUpdateRequest implements Action {
  readonly type = TaskCommentActionTypes.UPDATE_REQUEST;

  constructor(public payload: number) {
  }
}

export class TaskCommentUpdateSuccess implements Action {
  readonly type = TaskCommentActionTypes.UPDATE_SUCCESS;

  constructor(public payload: { comment: Comment }) {
  }
}

export class TaskCommentDeleteRequest implements Action {
  readonly type = TaskCommentActionTypes.DELETE_REQUEST;

  constructor(public payload: number) {
  }
}

export class TaskCommentDeleteSuccess implements Action {
  readonly type = TaskCommentActionTypes.DELETE_SUCCESS;

  constructor(public payload: any) {
  }
}
