import {filter, map, take} from "rxjs/operators";
import {
  getAllAutomatedMessage,
  getAutomatedMessagesForListingId,
  getFullAutomatedMessageById,
  getIsAutomatedMessageLoaded,
  getIsAutomatedMessageLoading,
  getIsFullAutomatedMessageLoaded,
  getIsFullAutomatedMessageLoading,
  State
} from "../reducers";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {AutomatedMessageCompact} from "../models/new/automation/automated-messages-compact.model";
import {AutomatedMessageService} from "../services/automated-message.service";
import {
  AutomatedMessageCreateRequest,
  AutomatedMessageCreateSuccess,
  AutomatedMessageDeleteSuccess,
  AutomatedMessageIndexRequest,
  AutomatedMessageIndexSuccess,
  AutomatedMessageShowRequest,
  AutomatedMessageShowSuccess,
  AutomatedMessageUpdateRequest,
  AutomatedMessageUpdateSuccess
} from "../actions/new/automation/automated-messages";
import {AutomatedMessageFull} from "../models/new/automation/automated-messages-full.model";
import {Injectable} from "@angular/core";

@Injectable()
export class AutomatedMessageRepository {

  constructor(private store: Store<State>,
              private automatedMessageService: AutomatedMessageService) {
  }

  /**
   * Loading & Loaded
   */
  getIsAutomatedMessageLoading(): Observable<boolean> {
    return this.store.select(getIsAutomatedMessageLoading);
  }

  getIsAutomatedMessageLoaded(): Observable<boolean> {
    return this.store.select(getIsAutomatedMessageLoaded);
  }

  getIsFullAutomatedMessageLoading(messageId: number): Observable<boolean> {
    return this.store.select(state => getIsFullAutomatedMessageLoading(state, messageId));
  }

  getIsFullAutomatedMessageLoaded(messageId: number): Observable<boolean> {
    return this.store.select(state => getIsFullAutomatedMessageLoaded(state, messageId));
  }

  /**
   * Service Methods
   */
  getAllAutomatedMessages(force: boolean, listingId: number): Observable<AutomatedMessageCompact[]> {
    let loading: boolean;
    let loaded: boolean;
    this.getIsAutomatedMessageLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getIsAutomatedMessageLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new AutomatedMessageIndexRequest());

      this.automatedMessageService.getAutomatedMessages().subscribe(message => {
        this.store.dispatch(new AutomatedMessageIndexSuccess(message.data));
      });
    }

    if (listingId) {
      return this.store.select(state => getAutomatedMessagesForListingId(state, listingId));
    }

    return this.store.select(getAllAutomatedMessage).pipe(map(t => t as AutomatedMessageCompact[]));
  }

  getAutomatedMessageById(messageId: number): Observable<AutomatedMessageFull> {
    let loading = false;
    let loaded = false;

    this.getIsFullAutomatedMessageLoading(messageId).pipe(take(1)).subscribe(l => loading = l);
    this.getIsFullAutomatedMessageLoaded(messageId).pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && !loaded) {
      this.store.dispatch(new AutomatedMessageShowRequest(messageId));
      this.automatedMessageService.showAutomatedMessage(messageId).subscribe(message => this.store.dispatch(
        new AutomatedMessageShowSuccess(message.data)));
    }

    return this.store.select((state) => getFullAutomatedMessageById(state, messageId)).pipe(
      filter(v => !!v),
      map(message => message as AutomatedMessageFull),);
  }

  createAutomatedMessage(data: any): Observable<AutomatedMessageFull> {
    this.store.dispatch(new AutomatedMessageCreateRequest());
    return this.automatedMessageService.createAutomatedMessage(data).pipe(map(res => {
      this.store.dispatch(new AutomatedMessageCreateSuccess(res.data));
      return res.data;
    }));
  }

  updateAutomatedMessage(automatedMessageId: number, data: any): Observable<AutomatedMessageFull> {
    this.store.dispatch(new AutomatedMessageUpdateRequest(automatedMessageId));
    return this.automatedMessageService.updateAutomatedMessage(automatedMessageId, data).pipe(map(res => {
      this.store.dispatch(new AutomatedMessageUpdateSuccess(res.data));
      return res.data;
    }));
  }


  deleteAutomatedMessage(automatedMessageId: number): Observable<null> {
    return this.automatedMessageService.deleteAutomatedMessage(automatedMessageId).pipe(map(res => {
      this.store.dispatch(new AutomatedMessageDeleteSuccess(automatedMessageId));
      return res;
    }));
  }

}
