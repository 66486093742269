import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { __HTTPResponseType } from "../enums/common.enum";
import { AutoTaskGetResponse } from "../models/responses/tasks/auto-task-get.response";
import { AutoTaskShowResponse } from "../models/responses/tasks/auto-task-show.response";

import { ApiService } from "./api.service";

@Injectable()
export class AutoTaskService {

  constructor(private apiService: ApiService) {
  }

  getAutoTasks(): Observable<AutoTaskGetResponse> {
    return this.apiService.get<AutoTaskGetResponse>("/admin/auto-tasks", true);
  }

  showAutoTask(autoTaskId: number): Observable<AutoTaskShowResponse> {
    return this.apiService.get<AutoTaskShowResponse>("/admin/auto-tasks/" + autoTaskId, true);
  }

  createAutoTask(data: any): Observable<AutoTaskShowResponse> {
    return this.apiService.post<AutoTaskShowResponse>("/admin/auto-tasks", true, data);
  }

  updateAutoTask(autoTaskId: number, data: any): Observable<AutoTaskShowResponse> {
    return this.apiService.put<AutoTaskShowResponse>("/admin/auto-tasks/" + autoTaskId, true, data);
  }

  deleteAutoTask(autoTaskId: number): Observable<boolean> {
    return this.apiService.delete("/admin/auto-tasks/" + autoTaskId, true, null, null, true, __HTTPResponseType.BLOB, true)
      .pipe(map((res: any) => {
        console.log("entered map");
        const contentDispositionHeader = res.headers.get("Content-Disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        const fileName = result.replace(/"/g, "");
        saveAs(res.body, fileName);
        console.log("return");
        return true;
      }));
  }
}
