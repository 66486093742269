import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { __HTTPResponseType } from "../../enums/common.enum";
import { ApiService } from "../api.service";
import { OwnerStoryDraft } from "../../models/new/drafts/owner-story-draft.model";
import { OwnerStoryDraftShowResponse } from "../../models/responses/settings/drafts/owner-story-draft-show.response";
import { OwnerStoryDraftGetResponse } from "../../models/responses/settings/drafts/owner-story-draft-get.response";

@Injectable()
export class OwnerStoryDraftsService {

  constructor(private apiService: ApiService) {
  }

  getOwnerStoryDrafts(): Observable<OwnerStoryDraftGetResponse> {
    return this.apiService.get<OwnerStoryDraftGetResponse>("/admin/owner-story-drafts", true);
  }

  getOwnerStoryDraftById(draftId): Observable<OwnerStoryDraftShowResponse> {
    return this.apiService.get<OwnerStoryDraftShowResponse>("/admin/owner-story-drafts/" + draftId, true);
  }

  createOwnerStoryDraft(data: Partial<OwnerStoryDraft>): Observable<OwnerStoryDraftShowResponse> {
    return this.apiService.post<OwnerStoryDraftShowResponse>("/admin/owner-story-drafts", true, data);
  }

  updateOwnerStoryDraft(draftId: number, data: any): Observable<OwnerStoryDraftShowResponse> {
    return this.apiService.put<OwnerStoryDraftShowResponse>("/admin/owner-story-drafts/" + draftId, true, data);
  }

  deleteOwnerStoryDraft(draftId: number): Observable<null> {
    return this.apiService.delete<null>("/admin/owner-story-drafts/" + draftId, true, null,
      null, true, __HTTPResponseType.TEXT);
  }
}

