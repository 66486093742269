import { SortOrder } from "../../../enums/common.enum";
import { OwnerReview } from "../../../models/new/reviews/owner-review";
import { Action } from "../../action";

export enum RatingReviewsActionTypes {
  INDEX_REQUEST = "[Rating Reviews NEW] Index Request",
  INDEX_SUCCESS = "[Rating Reviews NEW] Index Success",

  PAGE_CHANGE = "[Rating Reviews NEW] Page Change",
  PER_PAGE_CHANGE = "[Rating Reviews NEW] Per Page Change",

  SORT_ORDER_CHANGE = "[Rating Reviews NEW] Sort Order Change",
  LISTINGS_CHANGE = "[Rating Reviews NEW] Listings Change",

  CREATE_REVIEW = "[Rating Reviews NEW] Create Review"
}

export class RatingReviewsIndexRequest implements Action {
  readonly type = RatingReviewsActionTypes.INDEX_REQUEST;
}

export class RatingReviewsIndexSuccess implements Action {
  readonly type = RatingReviewsActionTypes.INDEX_SUCCESS;

  constructor(public payload: { reviews: OwnerReview[], currentPage: number, perPage: number, total: number }) {
  }
}

export class RatingReviewsPageChange implements Action {
  readonly type = RatingReviewsActionTypes.PAGE_CHANGE;

  constructor(public payload: number) {
  }
}

export class RatingReviewsPerPageChange implements Action {
  readonly type = RatingReviewsActionTypes.PER_PAGE_CHANGE;

  constructor(public payload: number) {
  }
}

export class RatingReviewsSortOrderChange implements Action {
  readonly type = RatingReviewsActionTypes.SORT_ORDER_CHANGE;

  constructor(public payload: SortOrder) {
  }
}

export class RatingReviewsListingsChange implements Action {
  readonly type = RatingReviewsActionTypes.LISTINGS_CHANGE;

  constructor(public payload: number[]) {
  }
}

export class RatingReviewsCreateReview implements Action {
  readonly type = RatingReviewsActionTypes.CREATE_REVIEW;

  constructor(public payload: number) {
  }
}
