import {take, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {Injectable} from "@angular/core";
import {getIsRentalAgreementLoaded, getIsRentalAgreementLoading, getRentalAgreements, State} from "../reducers";
import {RentalAgreement} from "../models/new/rental-agreement.model";
import {
  RentalAgreementCreateRequest,
  RentalAgreementCreateSuccess,
  RentalAgreementIndexRequest,
  RentalAgreementIndexSuccess,
  RentalAgreementSetAsDefault,
  UpdateCheckInCheckoutTime
} from "../actions/new/setttings/rental-agreements";
import {RentalAgreementService} from "../services/rental-agreement.service";
import {AWSService} from "../services/aws.service";


@Injectable()
export class RentalAgreementRepository {

  constructor(private store: Store<State>,
              private rentalAgreementService: RentalAgreementService,
              private awsService: AWSService) {
  }

  getRentalAgreementLoading(): Observable<boolean> {
    return this.store.select(getIsRentalAgreementLoading);
  }

  getRentalAgreementLoaded(): Observable<boolean> {
    return this.store.select(getIsRentalAgreementLoaded);
  }

  getRentalAgreements(force: boolean = false): Observable<RentalAgreement[]> {
    let loading = false;
    let loaded = false;

    this.getRentalAgreementLoading().pipe(take(1)).subscribe(l => loading = l);
    this.getRentalAgreementLoaded().pipe(take(1)).subscribe(l => loaded = l);

    if (!loading && (!loaded || force)) {
      this.store.dispatch(new RentalAgreementIndexRequest());
      this.rentalAgreementService.getRentalAgreements().subscribe(res => {
        this.store.dispatch(new RentalAgreementIndexSuccess(res));
      });
    }
    return this.store.select(getRentalAgreements);
  }


  createRentalAgreement(fileName: string): Observable<RentalAgreement> {
    return this.rentalAgreementService.createRentalAgreement(fileName);
  }

  completeRentalAgreement(id: number): Observable<RentalAgreement> {
    this.store.dispatch(new RentalAgreementCreateRequest());
    return this.rentalAgreementService.completeRentalAgreement(id).pipe(tap(res => {
      this.store.dispatch(new RentalAgreementCreateSuccess(res));
    }));
  }

  setAsDefault(id: number): Observable<boolean> {
    return this.rentalAgreementService.setAsDefault(id).pipe(tap(res => {
      this.store.dispatch(new RentalAgreementSetAsDefault(id));
    }));
  }

  updateTime(data: { checkInHour: number, checkInMin: number, checkOutHour: number, checkOutMin: number }): Observable<boolean> {
    const params = {
      check_in_time: data.checkInHour + ":" + data.checkInMin,
      check_out_time: data.checkOutHour + ":" + data.checkOutMin,
    };
    return this.rentalAgreementService.updateTime(params).pipe(tap(res => {
      this.store.dispatch(new UpdateCheckInCheckoutTime(params));
    }));
  }

  uploadRentalAgreement(uploadURL: string, file: any) {
    return this.awsService.uploadToAWS(uploadURL, file);
  }

}
