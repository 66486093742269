/**
 * Created by aditya on 18/9/17.
 */
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { startOfMonth } from "date-fns";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { getDateObj } from "../../../../utils/calendar-utils";
import { ListingCompact } from "../../../../models/new/listing/listing-compact.model";
import { State } from "../../../../reducers";
import { UserRepository } from "../../../../repository/user-repository";
import { ReportService } from "../../../../services/report.service";

@Component({
  selector: "sd-property-income-report-popup",
  template: `
    <sd-modal-popup-layout title="Vendor Reservation Report">
      <form fxLayout="column" fxLayoutGap="20px" [formGroup]="formGroup"
            (ngSubmit)="formGroup.valid && saveButtonCLicked()">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
          <mat-form-field [color]="'accent'" class="width-1">
            <input matInput [matDatepicker]="startPicker" [(ngModel)]="minEndDate" [min]="minDate"
                   placeholder="Start Date"
                   formControlName='start_date'>
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
          <mat-icon>arrow_forward</mat-icon>
          <mat-form-field [color]="'accent'" class="width-1">
            <input matInput [matDatepicker]="endPicker" [min]="minEndDate" placeholder="End Date"
                   formControlName='end_date'>
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field [color]="'accent'" class="width-2">
            <mat-select multiple placeholder="Select Listings" [(ngModel)]="selectedListingIds"
                        formControlName='property_ids'
                        (openedChange)="!$event&&onMatSelectClose()">


              <mat-form-field [color]="'accent'" style="width: 100%; padding:0 10px 0 10px">
                <input matInput placeholder="Search Listing" [formControl]="listingFilter">
              </mat-form-field>

              <div fxLayout="column">
                <button class="select-button" mat-button (click)="onSelectAll()">Select All</button>
                <button class="select-button" mat-button (click)="onSelectNone()">Select None</button>
              </div>
              <mat-option *ngFor="let listing of filteredListings" [value]="listing.id">
                {{ listing.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [color]="'accent'" class="width-1">
            <mat-select placeholder="Format" formControlName='format'>
              <mat-option *ngFor="let option of formats" [value]="option.value">
                {{ option.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [color]="'accent'" class="width-1">
            <mat-select placeholder="Offset" formControlName='offset'>
              <mat-option value="start">
                Check In
              </mat-option>
              <mat-option value="end">
                Check Out
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-raised-button [disabled]="!formGroup.valid || isSaving" color="accent" type="submit">
            <span>Download</span>
          </button>
          <div fxLayoutAlign="center center">
            <mat-spinner *ngIf="isSaving" [diameter]="30" [strokeWidth]="4"></mat-spinner>
          </div>
        </div>
      </form>
      <sd-date-picker-options (thisWeek)="setDate($event)"
                              (lastWeek)="setDate($event)"
                              (thisMonth)="setDate($event)"
                              (lastMonth)="setDate($event)"
                              (thisYear)="setDate($event)"
                              (lastYear)="setDate($event)"></sd-date-picker-options>
    </sd-modal-popup-layout>
  `,
  styles: [`
    hr {
      width: 100%;
    }

    mat-spinner {
      height: 30px;
      width: 30px;
    }

    .container-box {
      border-style: solid;
      border-width: 0.1px;
      padding: 10px;
      border-color: #c8c8c8
    }

    .half-width {
      width: 45%;
    }

    .select-button {
      padding: 6px;
      text-align: left;
      font-size: 17px;
      padding-left: 10px;
      font-weight: bolder;
    }

    .entryInputs {
      width: 45%
    }

    .width-1 {
      width: 16.6%
    }

    .width-2 {
      width: 30%;
    }

    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      [class*="width-"] {
        width: 90%;
      }
    }
  `]
})
export class VendorReservationReportPopupComponent implements OnInit, OnDestroy {

  formats = [
    {value: "xlsx", viewValue: "xlsx"},
    {value: "csv", viewValue: "csv"}
  ];

  formGroup: FormGroup;
  startDate: FormControl;
  endDate: FormControl;
  option: FormControl;
  properties: FormControl;
  offset: FormControl;
  filteredListings: ListingCompact[] = [];
  listingFilter: FormControl = new FormControl();
  minEndDate: Date;
  minDate: Date;

  selectedListingIds = [];
  @Input() listings: ListingCompact[] = [];

  isSaving = false;

  constructor(private service: ReportService, private userRepo: UserRepository, private store: Store<State>, private dialog: MatDialog) {
    this.startDate = new FormControl(null, [Validators.required]);
    this.endDate = new FormControl(null, [Validators.required]);
    this.option = new FormControl("xlsx", [Validators.required]);
    this.properties = new FormControl([], [Validators.required]);
    this.offset = new FormControl("end");

    this.formGroup = new FormGroup({
      start_date: this.startDate,
      end_date: this.endDate,
      format: this.option,
      property_ids: this.properties,
      offset: this.offset,
    });

  }

  ngOnInit() {
    setTimeout(() => {
      this.onSelectAll();
      this.startDate.setValue(startOfMonth(getDateObj()));
    });
    this.filteredListings = this.listings;
    this.listingFilter.valueChanges.pipe(debounceTime(100), distinctUntilChanged(),).subscribe((value) => {

      if (value) {
        this.filteredListings = this.listings.filter(l => l.title.toLowerCase().includes(value));
      } else {
        this.filteredListings = this.listings;
      }

      if (this.filteredListings.length === 0) {
        this.filteredListings = this.listings;
      }

    });

    this.startDate.setValue(startOfMonth(getDateObj()));
    this.endDate.setValue(getDateObj());

  }

  onMatSelectClose() {
    this.filteredListings = this.listings;
  }

  saveButtonCLicked() {
    this.isSaving = true;
    const data = {
      ...this.formGroup.value,
      start_date: this.startDate.value,
      end_date: this.endDate.value
    };
    data.property_ids = this.selectedListingIds;

    this.service.downloadVendorReservationReport(data).subscribe(res => {
      this.dialog.closeAll();
      this.isSaving = false;
    }, err => {
      this.isSaving = false;
    });

  }

  onSelectAll() {
    this.properties.setValue(this.listings.map(listing => listing.id));
  }

  onSelectNone() {
    this.properties.setValue([]);
  }

  setDate(data) {
    this.startDate.patchValue(data.start);
    this.endDate.patchValue(data.end);
  }

  ngOnDestroy(): void {
  }

}
