export enum TaskRepeatFrequency {
  DAILY = "daily",
  WEEKLY = "weekly",
  BI_WEEKLY = "bi_weekly",
  MONTHLY = "monthly",
  EVERY_TWO_MONTHS = "every_two_months",
  EVERY_FOUR_MONTHS = "every_four_months",
  EVERY_SIX_MONTHS = "every_six_months",
  ANNUALLY = "yearly",
  NEVER = "never",
}

export enum TaskType {
  TODAY = "today_tasks",
  UPCOMING = "upcoming_tasks",
  LATE = "late_tasks",
  COMPLETED = "completed_tasks",
  PAID = "paid_tasks",
  RECENT = "recent_tasks",
  OBSOLETE = "obsolete_tasks",
  ALL = "all_tasks",
}

export enum TaskTypeForReport {
  LATE = "late_tasks",
  COMPLETED = "completed_tasks",
  PAID = "paid_tasks",
  ALL = "all_tasks",
}


export enum TaskCategory {
  CLEANING = "cleaning",
  INSPECTION = "inspection",
  CHECK_IN = "check_in",
  RENTAL_EXPENSE = "rental_expense",
  WORK_ORDER = "work_order",
  REMINDER = "reminder",
  INSURANCE_CLAIM = "insurance_claim",
  SALES = "sales",
  MAINTENANCE = "maintenance",
  PREVENTATIVE_MAINTENANCE = "preventative_maintenance",
  LANDSCAPING = "landscaping",
  ONBOARDING = "onboarding",
  CONCIERGE = "concierge",
  CLEANING_MISTAKES = "cleaning_mistakes",
  GUEST_COMPLAINT = "guest_complaint",
  LOST_AND_FOUND = "lost_and_found",
  BAG_DROP = "bag_drop",
  LINEN_EXPENSE="linen_expense",
  STORAGE_PICK_UP="storage_pick_up"
}

export enum HomeOwnerTaskCategory {
  CLEANING = "cleaning",
  INSPECTION = "inspection",
  CHECK_IN = "check_in",
  RENTAL_EXPENSE = "rental_expense",
  WORK_ORDER = "work_order",
  REMINDER = "reminder",
  INSURANCE_CLAIM = "insurance_claim",
  PREVENTATIVE_MAINTENANCE = "preventative_maintenance",
  LANDSCAPING = "landscaping",
  ONBOARDING = "onboarding",
  CONCIERGE = "concierge",
  EMERGENGY_MAINTENANCE = "emergency_maintenance",
  LOST_AND_FOUND = "lost_and_found",
  LINEN_EXPENSE="linen_expense",
  STORAGE_PICK_UP="storage_pickup"
}

export enum TaskPaymentBy {
  GUESTS = "guests",
  MANAGEMENT = "management",
  OWNER_CHARGE = "owner_charge",
  INSURANCE_CLAIM = "insurance_claim",
  PDP = "pdp"
}

export enum TaskStatus {
  SCHEDULED = "scheduled",
  WAITING_FOR_APPROVAL = "waiting_for_approval",
  COMPLETED = "completed",
  PAID = "paid",
  CANCELLED = "cancelled",
  LATE = "late",
  URGENT = "urgent",
  ACCOUNTS_RECEIVABLE = "accounts_receivable",
  AUTO_CANCELLED = "cancelled_by_system",
  APPROVED = "approved",
  IN_PROGRESS = "in_progress",
  NOT_COMPLETED = "not_completed",
}

export enum HouseKeeperTaskStatus {
  SCHEDULED = "scheduled",
  COMPLETED = "completed",
  PAID = "paid",
  CANCELLED = "cancelled",
  LATE = "late",
  AUTO_CANCELLED = "cancelled_by_system",
  IN_PROGRESS = "in_progress",
  NOT_COMPLETED = "not_completed",
}


export enum TaskSortBy {
  TASK_TITLE = "task_title",
  LISTING = "listing",
  ASSIGNEE = "assignee",
  EMPLOYEE = "employee",
  DUE_ON = "due_on",
  CREATED_AT = "created_at",
  CATEGORY = "category",
  STATUS = "status",
  PRICE = "price",
  PAID_ON = "paid_on",
  START = "start",
}

export class TaskEnumHelper {

  static getTaskRepeatFrequencyTitle(category: TaskRepeatFrequency): string {
    let title = "";
    switch (category) {
      case TaskRepeatFrequency.DAILY : {
        title = "Daily";
        break;
      }

      case TaskRepeatFrequency.WEEKLY : {
        title = "Weekly";
        break;
      }

      case TaskRepeatFrequency.BI_WEEKLY : {
        title = "Bi-Weekly";
        break;
      }

      case TaskRepeatFrequency.MONTHLY : {
        title = "Monthly";
        break;
      }

      case TaskRepeatFrequency.EVERY_TWO_MONTHS : {
        title = "Every Two Months";
        break;
      }

      case TaskRepeatFrequency.ANNUALLY : {
        title = "Annually";
        break;
      }

      case TaskRepeatFrequency.EVERY_FOUR_MONTHS : {
        title = "Every Four Months";
        break;
      }

      case TaskRepeatFrequency.EVERY_SIX_MONTHS : {
        title = "Every Six Months";
        break;
      }
      case TaskRepeatFrequency.NEVER : {
        title = "Never";
        break;
      }

      default: {
        break;
      }
    }

    return title;
  }

  static getTaskTypeTitle(type: TaskType): string {
    let title = "";

    switch (type) {
      case TaskType.TODAY : {
        title = "Today\'s Tasks";
        break;
      }

      case TaskType.UPCOMING : {
        title = "Upcoming Tasks";
        break;
      }

      case TaskType.LATE : {
        title = "Late Tasks";
        break;
      }

      case TaskType.COMPLETED : {
        title = "Completed Tasks";
        break;
      }

      case TaskType.PAID : {
        title = "Paid Tasks";
        break;
      }

      case TaskType.RECENT : {
        title = "Recently Created/Updated";
        break;
      }

      case TaskType.ALL : {
        title = "All Tasks";
        break;
      }

      case TaskType.OBSOLETE : {
        title = "Cancelled/Obsolete";
        break;
      }
    }

    return title;
  }

  static getTaskCategoryTitle(category: TaskCategory | HomeOwnerTaskCategory): string {
    let title = "";

    switch (category) {
      case TaskCategory.CLEANING : {
        title = "Cleaning";
        break;
      }
      case TaskCategory.INSPECTION : {
        title = "Inspection";
        break;
      }
      case TaskCategory.CHECK_IN : {
        title = "Check In";
        break;
      }
      case TaskCategory.RENTAL_EXPENSE : {
        title = "Rental Expenses";
        break;
      }
      case TaskCategory.INSURANCE_CLAIM : {
        title = "Insurance Claim";
        break;
      }
      case TaskCategory.WORK_ORDER : {
        title = "Work Order";
        break;
      }
      case TaskCategory.REMINDER : {
        title = "Reminder";
        break;
      }

      case TaskCategory.SALES: {
        title = "Sales";
        break;
      }
      case TaskCategory.MAINTENANCE: {
        title = "Maintenance";
        break;
      }
      case TaskCategory.PREVENTATIVE_MAINTENANCE: {
        title = "Preventative Maintenance";
        break;
      }
      case TaskCategory.LANDSCAPING: {
        title = "Landscaping";
        break;
      }
      case TaskCategory.ONBOARDING : {
        title = "Onboarding";
        break;
      }
      case TaskCategory.CONCIERGE : {
        title = "Concierge";
        break;
      }
      case TaskCategory.CLEANING_MISTAKES : {
        title = "Cleaning Mistakes";
        break;
      }
      case TaskCategory.GUEST_COMPLAINT : {
        title = "Guest Complaint";
        break;
      }
      case TaskCategory.LOST_AND_FOUND : {
        title = "Lost And Found";
        break;
      }
      case TaskCategory.BAG_DROP : {
        title = "Bag Drop";
        break;
      }
      case TaskCategory.LINEN_EXPENSE: {
        title = "Linen Expense";
        break;
      }
      case TaskCategory.STORAGE_PICK_UP: {
        title = "Storage Pick Up";
        break;
      }
    }

    return title;
  }

  static getTaskPaymentByTitle(paymentBy: TaskPaymentBy): string {
    let title = "";

    switch (paymentBy) {
      case TaskPaymentBy.GUESTS : {
        title = "Guests";
        break;
      }

      case TaskPaymentBy.MANAGEMENT : {
        title = "Management";
        break;
      }

      case TaskPaymentBy.OWNER_CHARGE : {
        title = "Owner";
        break;
      }

      case TaskPaymentBy.INSURANCE_CLAIM : {
        title = "Insurance Claim";
        break;
      }

      case TaskPaymentBy.PDP : {
        title = "PDP";
        break;
      }
    }

    return title;
  }

  static getTaskStatusEnum(status: TaskStatus): { title: string, color: string, tooltipText: string } {
    let title;
    let color;
    let tooltipText;

    switch (status) {
      case TaskStatus.IN_PROGRESS: {
        title = "In Progress";
        color = "#13304b";
        tooltipText = "In Progress";
        break;
      }

      case TaskStatus.WAITING_FOR_APPROVAL: {
        title = "Awaiting Approval";
        color = "#ADD8E6";
        tooltipText = "Awaiting Approval";
        break;
      }

      case TaskStatus.SCHEDULED: {
        title = "Scheduled";
        color = "#ffff00";
        tooltipText = "Scheduled";
        break;
      }

      case TaskStatus.PAID: {
        title = "Paid";
        color = "#a9a9a9";
        tooltipText = "Paid";
        break;
      }

      case TaskStatus.COMPLETED: {
        title = "Completed";
        color = "#4F8A10";
        tooltipText = "Completed";
        break;
      }

      case TaskStatus.NOT_COMPLETED: {
        title = "Not Completed";
        color = "#7b008a";
        tooltipText = "Not Completed";
        break;
      }

      case TaskStatus.CANCELLED: {
        title = "Cancelled";
        color = "#ff0000";
        tooltipText = "Cancelled";
        break;
      }

      case TaskStatus.LATE: {
        title = "Late";
        color = "#ff0000";
        tooltipText = "Late";
        break;
      }

      case TaskStatus.AUTO_CANCELLED: {
        title = "Obsolete";
        color = "#ff0000";
        tooltipText = "No longer applies to new Auto Task Rules";
        break;
      }

      case TaskStatus.URGENT: {
        title = "Urgent";
        color = "#ff5900";
        tooltipText = "Urgent";
        break;
      }

      case TaskStatus.ACCOUNTS_RECEIVABLE: {
        title = "Accounts Receivable";
        color = "#cf4a00";
        tooltipText = "Account Receivable";
        break;
      }

      case TaskStatus.APPROVED: {
        title = "Approved";
        color = "#4F8A10";
        tooltipText = "Approved";
        break;
      }
    }

    return {title: title, color: color, tooltipText: tooltipText};
  }

  static getTaskSortByTitle(sortBy: TaskSortBy): string {
    let title = "";

    switch (sortBy) {
      case TaskSortBy.TASK_TITLE : {
        title = "Task Title";
        break;
      }

      case TaskSortBy.LISTING : {
        title = "Listing";
        break;
      }

      case TaskSortBy.ASSIGNEE : {
        title = "Assignee";
        break;
      }

      case TaskSortBy.DUE_ON : {
        title = "Due On";
        break;
      }

      case TaskSortBy.CATEGORY : {
        title = "Category";
        break;
      }

      case TaskSortBy.STATUS : {
        title = "Status";
        break;
      }

      case TaskSortBy.PRICE : {
        title = "Price";
        break;
      }

      case TaskSortBy.CREATED_AT : {
        title = "Created At";
        break;
      }
    }

    return title;
  }

  static getTaskRepeatSlug(repeatFrequency: TaskRepeatFrequency): string {
    let slug = "";

    switch (repeatFrequency) {
      case TaskRepeatFrequency.DAILY : {
        slug = "daily";
        break;
      }

      case TaskRepeatFrequency.WEEKLY : {
        slug = "weekly";
        break;
      }

      case TaskRepeatFrequency.BI_WEEKLY : {
        slug = "weekly";
        break;
      }

      case TaskRepeatFrequency.MONTHLY : {
        slug = "monthly";
        break;
      }

      case TaskRepeatFrequency.EVERY_TWO_MONTHS : {
        slug = "monthly";
        break;
      }

      case TaskRepeatFrequency.ANNUALLY : {
        slug = "yearly";
        break;
      }
    }
    return slug;
  }

  static getTaskRepeatSlugFull(repeatFrequency: TaskRepeatFrequency): string {
    let slug = "";

    switch (repeatFrequency) {
      case TaskRepeatFrequency.DAILY : {
        slug = "daily";
        break;
      }

      case TaskRepeatFrequency.WEEKLY : {
        slug = "weekly";
        break;
      }

      case TaskRepeatFrequency.BI_WEEKLY : {
        slug = "bi_weekly";
        break;
      }

      case TaskRepeatFrequency.MONTHLY : {
        slug = "monthly";
        break;
      }

      case TaskRepeatFrequency.EVERY_TWO_MONTHS : {
        slug = "every_two_months";
        break;
      }

      case TaskRepeatFrequency.EVERY_FOUR_MONTHS: {
        slug = "every_four_months";
        break;
      }

      case TaskRepeatFrequency.EVERY_SIX_MONTHS: {
        slug = "every_six_months";
        break;
      }

      case TaskRepeatFrequency.ANNUALLY : {
        slug = "yearly";
        break;
      }
    }
    return slug;
  }


  static getTaskRepeatValue(repeatFrequency: TaskRepeatFrequency): number {
    let repeatCount = 1;
    switch (repeatFrequency) {
      case TaskRepeatFrequency.DAILY: {
        repeatCount = 1;
        break;
      }
      case TaskRepeatFrequency.WEEKLY: {
        repeatCount = 1;
        break;
      }
      case TaskRepeatFrequency.BI_WEEKLY: {
        repeatCount = 2;
        break;
      }
      case TaskRepeatFrequency.MONTHLY: {
        repeatCount = 1;
        break;
      }
      case TaskRepeatFrequency.EVERY_TWO_MONTHS: {
        repeatCount = 2;
        break;
      }

      case TaskRepeatFrequency.EVERY_FOUR_MONTHS: {
        repeatCount = 4;
        break;
      }

      case TaskRepeatFrequency.EVERY_SIX_MONTHS: {
        repeatCount = 6;
        break;
      }

      case TaskRepeatFrequency.ANNUALLY: {
        repeatCount = 1;
        break;
      }
    }
    return repeatCount;
  }
}
